// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Nanoid from "nanoid";
import * as NameInput from "./NameInput.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function NewPropertyGroupModal(Props) {
  var onClose = Props.onClose;
  var name = Props.name;
  var onCreate = Props.onCreate;
  var model = ModelStore.Mapped.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var sendActions = SendActionsContext.use(undefined);
  var propertyGroupId = React.useMemo((function () {
          return Nanoid.nanoid();
        }), []);
  React.useEffect((function () {
          AnalyticsRe.propertyGroupInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, propertyGroupId, undefined, 0, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(NameInput.make, {
                  name: Belt_Option.getWithDefault(name, ""),
                  existingNames: ModelUtils.getPropertyNamespace__mapped(model),
                  expectedCase: match.expectedPropertyCase,
                  forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case),
                  autoFocus: true,
                  onChange: (function (name, _namingConvention, _correctCase, _onReset) {
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                Belt_Option.forEach(onCreate, (function (cb) {
                                        Curry._1(cb, propertyGroupId);
                                      }));
                                Router.Schema.pushDrawerItem(undefined, undefined, {
                                      NAME: "propertyGroup",
                                      VAL: [
                                        propertyGroupId,
                                        undefined
                                      ]
                                    });
                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                AnalyticsRe.propertyGroupCreated(propertyGroupId, name, 0, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, schemaBundle$1.branchId, schemaBundle$1.schemaId);
                                Curry._1(onClose, undefined);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "CreatePropertyGroup",
                                  VAL: [
                                    propertyGroupId,
                                    name
                                  ]
                                },
                                {
                                  propertyGroupId: propertyGroupId,
                                  propertyGroupQuery: propertyGroupId
                                }
                              ]]
                          ]);
                    }),
                  submitLabel: "Create Property Bundle",
                  placeholder: "Property Bundle Name",
                  onClose: (function (param) {
                      Curry._1(onClose, undefined);
                    }),
                  fullscreen: true,
                  itemType: "PropertyGroup",
                  actionType: "Create",
                  getPossibleItemLink: (function (propertyGroupName) {
                      var match = Curry._2(TrackingPlanMappedModel.PropertyBundles.Utils.getByName, model.propertyBundles, propertyGroupName);
                      if (match !== undefined) {
                        return [
                                Router.Link.addDrawerItem(undefined, {
                                      NAME: "propertyGroup",
                                      VAL: [
                                        match.id,
                                        undefined
                                      ]
                                    }),
                                undefined
                              ];
                      }
                      var match$1 = Curry._2(TrackingPlanMappedModel.Properties.Utils.getByName, model.properties, propertyGroupName);
                      if (match$1 !== undefined) {
                        return [
                                Router.Link.addDrawerItem(undefined, {
                                      NAME: "property",
                                      VAL: [
                                        match$1.id,
                                        undefined
                                      ]
                                    }),
                                "property"
                              ];
                      }
                      
                    })
                }));
}

var make = NewPropertyGroupModal;

export {
  make ,
}
/* Css Not a pure module */
