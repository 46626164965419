// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as GlobalPill from "./GlobalPill.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function DiffEventGlobalPill(Props) {
  var eventId = Props.eventId;
  var globalRequirements = Props.globalRequirements;
  if (globalRequirements === undefined) {
    return null;
  }
  var mappedGlobalRequirements = TrackingPlanMappedModel.GlobalRequirements.fromOld(globalRequirements);
  var match = Curry._2(TrackingPlanMappedModel.Events.get, mappedGlobalRequirements.events, eventId);
  if (match !== undefined) {
    return React.createElement(GlobalPill.make, {});
  } else {
    return null;
  }
}

var make = DiffEventGlobalPill;

export {
  make ,
}
/* react Not a pure module */
