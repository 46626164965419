// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Make(KBar) {
  var set = function (action, item, id, name, scoreMultiplier, aiResult, score, shortcut, keywords, section, icon, perform, parent, command, priority, ancestors, children, param) {
    var tmp = {
      item: Belt_Option.getWithDefault(item, action.item),
      id: Belt_Option.getWithDefault(id, action.id),
      name: Belt_Option.getWithDefault(name, action.name),
      ancestors: Belt_Option.getWithDefault(ancestors, action.ancestors),
      children: Belt_Option.getWithDefault(children, action.children)
    };
    var tmp$1 = scoreMultiplier !== undefined ? scoreMultiplier : Caml_option.undefined_to_opt(action.scoreMultiplier);
    if (tmp$1 !== undefined) {
      tmp.scoreMultiplier = Caml_option.valFromOption(tmp$1);
    }
    var tmp$2 = aiResult !== undefined ? aiResult : Caml_option.undefined_to_opt(action.aiResult);
    if (tmp$2 !== undefined) {
      tmp.aiResult = Caml_option.valFromOption(tmp$2);
    }
    var tmp$3 = score !== undefined ? score : Caml_option.undefined_to_opt(action.score);
    if (tmp$3 !== undefined) {
      tmp.score = Caml_option.valFromOption(tmp$3);
    }
    var tmp$4 = shortcut !== undefined ? shortcut : Caml_option.undefined_to_opt(action.shortcut);
    if (tmp$4 !== undefined) {
      tmp.shortcut = Caml_option.valFromOption(tmp$4);
    }
    var tmp$5 = keywords !== undefined ? keywords : Caml_option.undefined_to_opt(action.keywords);
    if (tmp$5 !== undefined) {
      tmp.keywords = Caml_option.valFromOption(tmp$5);
    }
    var tmp$6 = section !== undefined ? section : Caml_option.undefined_to_opt(action.section);
    if (tmp$6 !== undefined) {
      tmp.section = Caml_option.valFromOption(tmp$6);
    }
    var tmp$7 = icon !== undefined ? icon : Caml_option.undefined_to_opt(action.icon);
    if (tmp$7 !== undefined) {
      tmp.icon = Caml_option.valFromOption(tmp$7);
    }
    var tmp$8 = perform !== undefined ? perform : Caml_option.undefined_to_opt(action.perform);
    if (tmp$8 !== undefined) {
      tmp.perform = Caml_option.valFromOption(tmp$8);
    }
    var tmp$9 = parent !== undefined ? parent : Caml_option.undefined_to_opt(action.parent);
    if (tmp$9 !== undefined) {
      tmp.parent = Caml_option.valFromOption(tmp$9);
    }
    var tmp$10 = command !== undefined ? command : Caml_option.undefined_to_opt(action.command);
    if (tmp$10 !== undefined) {
      tmp.command = Caml_option.valFromOption(tmp$10);
    }
    var tmp$11 = priority !== undefined ? priority : Caml_option.undefined_to_opt(action.priority);
    if (tmp$11 !== undefined) {
      tmp.priority = Caml_option.valFromOption(tmp$11);
    }
    return tmp;
  };
  var ActionImpl = {
    set: set
  };
  var t = function (id, item, scoreMultiplier, shortcut, keywords, icon, perform, parent, param) {
    var tmp = {
      item: item,
      id: id,
      name: Curry._1(KBar.itemToString, item)
    };
    if (scoreMultiplier !== undefined) {
      tmp.scoreMultiplier = Caml_option.valFromOption(scoreMultiplier);
    }
    if (shortcut !== undefined) {
      tmp.shortcut = Caml_option.valFromOption(shortcut);
    }
    if (keywords !== undefined) {
      tmp.keywords = Caml_option.valFromOption(keywords);
    }
    var tmp$1 = Curry._1(KBar.itemToSectionString, item);
    if (tmp$1 !== undefined) {
      tmp.section = Caml_option.valFromOption(tmp$1);
    }
    if (icon !== undefined) {
      tmp.icon = Caml_option.valFromOption(icon);
    }
    if (perform !== undefined) {
      tmp.perform = Caml_option.valFromOption(perform);
    }
    if (parent !== undefined) {
      tmp.parent = Caml_option.valFromOption(parent);
    }
    return tmp;
  };
  var Action = {
    t: t
  };
  var convert = function (t) {
    if ((typeof t == 'string')) {
      return {
              TAG: /* Section */0,
              _0: t
            };
    } else {
      return {
              TAG: /* Action */1,
              _0: t
            };
    }
  };
  var Result = {
    convert: convert
  };
  var Provider = {};
  var Portal = {};
  var Positioner = {};
  var Animator = {};
  var Search = {};
  var convert$1 = function (t) {
    return {
            results: Belt_Array.map(t.results, convert),
            rootActionId: Caml_option.nullable_to_opt(t.rootActionId)
          };
  };
  var useMatches = function (param) {
    return convert$1(Kbar.useMatches());
  };
  var Matches = {
    convert: convert$1,
    useMatches: useMatches
  };
  var Internal = {};
  var KBar$Make$Results = function (Props) {
    var items = Props.items;
    var onRender = Props.onRender;
    var items$1 = Belt_Array.map(items, (function (item) {
            return item._0;
          }));
    var onRender$1 = function (item) {
      return Curry._1(onRender, {
                  item: convert(item.item),
                  active: item.active
                });
    };
    return React.createElement(Kbar.KBarResults, {
                items: items$1,
                onRender: onRender$1
              });
  };
  var Results = {
    Internal: Internal,
    make: KBar$Make$Results
  };
  var State = {};
  var Query = {};
  var HookState = {};
  return {
          ActionImpl: ActionImpl,
          Action: Action,
          Result: Result,
          Provider: Provider,
          Portal: Portal,
          Positioner: Positioner,
          Animator: Animator,
          Search: Search,
          Matches: Matches,
          Results: Results,
          State: State,
          Query: Query,
          HookState: HookState
        };
}

export {
  Make ,
}
/* kbar Not a pure module */
