// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

function getIncludedSources(filters, sources, modelSources) {
  var includedSources = Belt_List.keepMap(filters, (function (filter) {
          if (typeof filter === "object" && filter.NAME === "Source") {
            return filter.VAL;
          }
          
        }));
  var includedSources$1 = includedSources !== /* [] */0 ? includedSources : Belt_List.map(sources, (function (source) {
            return source.id;
          }));
  if (includedSources$1 !== /* [] */0) {
    return includedSources$1;
  } else {
    return Belt_List.map(modelSources, (function (source) {
                  return source.id;
                }));
  }
}

function trimEventAndPropertyNames(model) {
  return {
          types: model.types,
          properties: Belt_List.map(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return property;
                  }
                  var property$1 = property._0;
                  var newrecord = Caml_obj.obj_dup(property$1);
                  return {
                          TAG: /* Property */1,
                          _0: (newrecord.name = property$1.name.trim(), newrecord)
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: Belt_List.map(model.events, (function ($$event) {
                  return {
                          id: $$event.id,
                          name: $$event.name.trim(),
                          uniqueName: $$event.uniqueName,
                          description: $$event.description,
                          directPropertyRefs: $$event.directPropertyRefs,
                          propertyBundles: $$event.propertyBundles,
                          variants: $$event.variants,
                          types: $$event.types,
                          tags: $$event.tags,
                          excludeSourcesDeprecated: $$event.excludeSourcesDeprecated,
                          includeSources: $$event.includeSources,
                          includeDestinations: $$event.includeDestinations,
                          hashes: $$event.hashes,
                          propertyWhitelist: $$event.propertyWhitelist,
                          eventGroupTypeIdsWithArchive: $$event.eventGroupTypeIdsWithArchive,
                          userGroupTypeIdsWithArchive: $$event.userGroupTypeIdsWithArchive,
                          triggers: $$event.triggers,
                          globalRequirementsMetadata: $$event.globalRequirementsMetadata
                        };
                })),
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

export {
  getIncludedSources ,
  trimEventAndPropertyNames ,
}
/* No side effect */
