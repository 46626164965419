// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PropertyType from "./PropertyType.mjs";
import * as PropertyConstraints from "./PropertyConstraints.mjs";

function GroupType(Props) {
  var model = Props.model;
  var groupType = Props.groupType;
  var idDescription = Props.idDescription;
  return React.createElement(React.Fragment, undefined, React.createElement(PropertyType.TypePill.make, {
                  children: "string"
                }), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement("span", {
                  className: PropertyConstraints.styles
                }, "id of the " + (ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(groupType.id, model) + (" group " + idDescription))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("inlineFlex"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.light10,
                      children: "Always sent"
                    })));
}

var make = GroupType;

export {
  make ,
}
/* Css Not a pure module */
