// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, language) {
  if (language !== undefined) {
    if (language === "java") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "avoInspector.trackSchemaFromEvent(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Event name\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ", new"), " HashMap<String", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), " Object>() {{", "\n   put(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"id\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, " \"...\""), ")", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ";"), "\n   put(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"number\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, " 41"), ")", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ";"), "\n}})", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ";")), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "avoInspector.trackSchemaFromEvent(\"Event name\", new HashMap<String, Object>() {{\n            put(\"id\", \"...\");\n            put(\"number\", 41);\n        }});"
                        })));
    } else if (language === "kt") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "avoInspector.trackSchemaFromEvent(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"Event name\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ", "), "mapOf(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"id\""), React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, " to "), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"...\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"number\""), React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, " to "), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"41\""), "))"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "avoInspector.trackSchemaFromEvent(\"Event name\",\n                mapOf(\"id\" to \"...\", \"number\" to 41))"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export {
  code ,
}
/* react Not a pure module */
