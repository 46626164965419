// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Input from "./Input.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as DocsLink from "./DocsLink.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as DomainStore from "./DomainStore.mjs";
import * as DomainUtils from "./DomainUtils.mjs";
import * as DomainMembers from "../../model/src/domains/DomainMembers.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as CollaboratorsView from "./CollaboratorsView.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var headerItem_0 = Css.firstChild({
      hd: Css.paddingLeft(Css.px(16)),
      tl: /* [] */0
    });

var headerItem_1 = {
  hd: Css.nthChild({
        NAME: "n",
        VAL: 2
      }, {
        hd: Css.paddingRight(Css.px(8)),
        tl: /* [] */0
      }),
  tl: /* [] */0
};

var headerItem = {
  hd: headerItem_0,
  tl: headerItem_1
};

var grid = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: {
                NAME: "minmax",
                VAL: [
                  Css.px(0),
                  Css.fr(1.0)
                ]
              },
              tl: {
                hd: {
                  NAME: "minmax",
                  VAL: [
                    Css.px(0),
                    Css.px(100)
                  ]
                },
                tl: {
                  hd: "maxContent",
                  tl: {
                    hd: {
                      NAME: "minmax",
                      VAL: [
                        Css.px(60),
                        Css.maxContent
                      ]
                    },
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: {
          hd: Css.gridTemplateRows({
                hd: "auto",
                tl: /* [] */0
              }),
          tl: {
            hd: Css.gridAutoRows({
                  NAME: "minmax",
                  VAL: [
                    Css.px(72),
                    Css.auto
                  ]
                }),
            tl: {
              hd: Css.margin2("zero", Css.px(-16)),
              tl: {
                hd: Css.selector(" > div", headerItem),
                tl: {
                  hd: Css.maxWidth(Css.px(650)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var rowWrapper = Curry._1(Css.style, {
      hd: Css.display("contents"),
      tl: {
        hd: Css.hover({
              hd: Css.selector(" > [class$=grid-item]", {
                    hd: Css.cursor("pointer"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light02),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.lastChild({
                hd: Css.selector(" > [class$=grid-item]", {
                      hd: Css.borderBottomWidth(Css.zero),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

var rowItem = Curry._1(Css.style, {
      hd: Css.label("grid-item"),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light03),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$short
                    }, undefined, undefined, "background-color"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var rowItemNoPadding = Curry._1(Css.style, {
      hd: Css.padding("zero"),
      tl: /* [] */0
    });

var Style = {
  headerItem: headerItem,
  grid: grid,
  rowWrapper: rowWrapper,
  rowItem: rowItem,
  rowItemNoPadding: rowItemNoPadding
};

function WorkspaceDomains$Domain(Props) {
  var domains = Props.domains;
  var domain = Props.domain;
  var onClose = Props.onClose;
  var domainMembers = DomainStore.useDomainMembers(domain.id, undefined);
  var domainMembersInitialized = DomainStore.useMembersInitialized(undefined);
  var schemaId = WorkspaceContext.use(undefined).id;
  var viewerId = ViewerContext.use(undefined).id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var role = WorkspaceContext.useMemberRole(undefined);
  var addToast = Toast.useAddToast(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var handleArchiveDomain = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.archiveDomain((function (param) {
              Curry._1(addToast, {
                    message: "Failed to archive the domain \"" + domain.name + "\"",
                    toastType: /* Error */1
                  });
            }), (function (param) {
              Curry._1(addToast, {
                    message: "The domain \"" + domain.name + "\" was archived",
                    toastType: /* Success */0
                  });
              AnalyticsRe.domainArchived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains) - 1 | 0, schemaId);
            }), schemaId, sendActions, viewerId, domain.id).finally(function (param) {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
        });
  };
  var domainEvents = React.useMemo((function () {
          return Belt_Array.keepMapU(domain.items.events, (function (eventId) {
                        return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                      }));
        }), [
        domain.items.events,
        events
      ]);
  var daominEventVariants = React.useMemo((function () {
          return Belt_Array.keepMapU(domain.items.eventVariants, (function (eventVariantId) {
                        return ModelUtils_mapped.getEventVariantByIdentifier(events, {
                                    baseEventId: VariantEventIdUseCase.baseEventIdFromVariantEventId(eventVariantId),
                                    variantId: VariantEventIdUseCase.variantIdFromVariantEventId(eventVariantId)
                                  });
                      }));
        }), [
        domain.items.eventVariants,
        events
      ]);
  if (!domainMembersInitialized) {
    return null;
  }
  var match$1 = domain.description.trim();
  var tmp = match$1 === "" ? React.createElement("i", undefined, "No description") : domain.description;
  var tmp$1;
  if (Curry._1(DomainMembers.size, domainMembers) > 0) {
    var membersToDisplay = DomainMembers.getUserIds(Curry._2(DomainMembers.take, domainMembers, 3));
    tmp$1 = React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
          ids: membersToDisplay,
          notDisplayedCount: Curry._1(DomainMembers.size, domainMembers) - membersToDisplay.length | 0
        });
  } else {
    tmp$1 = React.createElement($$Text.make, {
          size: "Small",
          weight: "Semi",
          color: Styles.Color.light10,
          children: "-"
        });
  }
  return React.createElement(Link.make, {
              path: Router.Link.getSchemaRouteLink(undefined, {
                    NAME: "domain",
                    VAL: domain.id
                  }),
              className: rowWrapper,
              onClick: (function (e) {
                  if (e.metaKey || e.ctrlKey) {
                    return ;
                  } else {
                    return Curry._1(onClose, undefined);
                  }
                }),
              children: null
            }, React.createElement("span", {
                  className: Curry._1(Css.merge, {
                        hd: rowItem,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: {
                                      hd: Css.alignItems("flexStart"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Link.make, {
                      path: Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "domain",
                            VAL: domain.id
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.hover({
                                  hd: Css.color(Styles.Color.darkBlue),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }),
                      onClick: (function (e) {
                          e.stopPropagation();
                          if (e.metaKey || e.ctrlKey) {
                            return ;
                          } else {
                            return Curry._1(onClose, undefined);
                          }
                        }),
                      children: React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            children: domain.name
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Styles.prettyWrap,
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          lines: 2,
                          color: Styles.Color.light11,
                          title: domain.description,
                          children: tmp
                        }))), React.createElement("span", {
                  className: rowItem
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: String(domainEvents.length + daominEventVariants.length | 0)
                    })), React.createElement("span", {
                  className: Curry._1(Css.merge, {
                        hd: rowItem,
                        tl: {
                          hd: rowItemNoPadding,
                          tl: /* [] */0
                        }
                      })
                }, tmp$1), React.createElement("span", {
                  className: rowItem
                }, match[0] ? React.createElement(LoadingCircle.make, {
                        size: 28,
                        color: Styles.Color.light10
                      }) : React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.pointerEvents("auto"),
                              tl: /* [] */0
                            }),
                        onClick: (function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                          })
                      }, React.createElement(ContextMenu.V2.make, {
                            children: null
                          }, React.createElement(ContextMenu.V2.Item.make, {
                                label: "View/Edit",
                                onClick: (function (param) {
                                    Router.Schema.pushSchemaRoute(undefined, undefined, {
                                          NAME: "domain",
                                          VAL: domain.id
                                        });
                                    Curry._1(onClose, undefined);
                                  })
                              }), React.createElement(ContextMenu.V2.Item.make, {
                                label: "Remove this domain",
                                onClick: handleArchiveDomain,
                                style: "Warning",
                                disabled: role !== "Admin"
                              })))));
}

var Domain = {
  Style: Style,
  make: WorkspaceDomains$Domain
};

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingBottom(Css.px(0)),
        tl: /* [] */0
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.minHeight(Css.px(400)),
              tl: {
                hd: Css.textAlign("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var textBoxStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(420)),
      tl: {
        hd: Css.marginTop(Css.px(12)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: /* [] */0
        }
      }
    });

var docsBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("baseline"),
        tl: {
          hd: Css.gap(Css.px(3)),
          tl: {
            hd: Css.padding(Css.px(16)),
            tl: {
              hd: Css.paddingBottom(Css.px(18)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function WorkspaceDomains$NewDomainModal(Props) {
  var availability = Props.availability;
  var opened = Props.opened;
  var onCreate = Props.onCreate;
  var onClose = Props.onClose;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var schemaId = WorkspaceContext.use(undefined).id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var domains = DomainStore.useDomains(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setDomainName = match[1];
  var domainName = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(setDomainName, (function (param) {
                  return "";
                }));
        }), [opened]);
  var domainNameExists = Belt_Option.isSome(Curry._2(Domains.getBy, domains, (function (param) {
              return param.name === domainName;
            }))) && !loading;
  var handleCreate = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      Curry._1(setLoading, (function (param) {
              return true;
            }));
      DomainUtils.Firebase.createDomain("", {
              events: [],
              eventVariants: []
            }, domainName, (function (param) {
                Curry._1(addToast, {
                      message: "Failed to create domain",
                      toastType: /* Error */1
                    });
              }), (function (domainId) {
                Curry._1(addToast, {
                      message: React.createElement(React.Fragment, undefined, "Created domain ", React.createElement("em", undefined, domainName)),
                      toastType: /* Success */0
                    });
                Curry._1(onClose, undefined);
                Curry._1(onCreate, domainId);
                AnalyticsRe.domainCreated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domainId, domainName, Curry._1(Domains.size, domains) + 1 | 0, schemaId);
              }), sendActions, viewerId, schemaId).then(function (domainId) {
            return Promise.resolve(domainId);
          });
      return ;
    }
    
  };
  var isValid = React.useMemo((function () {
          if (Belt_Option.isNone(Curry._2(Domains.getBy, domains, (function (param) {
                        return param.name === domainName;
                      })))) {
            return domainName.trim() !== "";
          } else {
            return false;
          }
        }), [
        domains,
        domainName
      ]);
  return React.createElement(Mantine.Modal.make, {
              closeOnClickOutside: true,
              onClose: onClose,
              opened: opened,
              title: "New domain",
              withCloseButton: true,
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll + 100 | 0,
              overlayStyles: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2)),
                tl: /* [] */0
              },
              titleStyles: {
                hd: Css.fontSize(Styles.FontSize.large),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: /* [] */0
                }
              },
              yOffset: 150,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin2(Css.px(0), Css.px(-16)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light03),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.borderLeftWidth(Css.px(0)),
                                tl: {
                                  hd: Css.borderRightWidth(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Input.make, {
                      label: "Name",
                      value: domainName,
                      onChange: Curry.__1(setDomainName),
                      autoFocus: true,
                      onEnter: (function (param) {
                          if (isValid) {
                            return handleCreate(undefined);
                          }
                          
                        }),
                      placeholder: "Domain name...",
                      className: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      },
                      required: true
                    }), domainNameExists ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.darkRed,
                            children: "Domain with the name “" + domainName + "” already exists"
                          }), React.createElement(Spacer.make, {
                            height: 16
                          })) : null, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light11,
                      children: null
                    }, React.createElement("b", undefined, "Domains "), "are a way to organize the events in your tracking plan based on the people who rely on them.")), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      style: "outline"
                    }), React.createElement(Button.make, {
                      disabled: !isValid,
                      label: "Create",
                      loading: loading,
                      onClick: handleCreate
                    })));
}

var NewDomainModal = {
  make: WorkspaceDomains$NewDomainModal
};

function WorkspaceDomains(Props) {
  var onClose = Props.onClose;
  var domains = DomainStore.useDomains(undefined);
  var domainStoreIsInitialized = DomainStore.useIsInitialized(undefined);
  var role = WorkspaceContext.useMemberRole(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = Hooks.useDisclosure(false);
  var modalAction = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setFilterString = match$1[1];
  var filterString = match$1[0];
  var filteredDomains = React.useMemo((function () {
          if (filterString.trim() === "") {
            return domains;
          } else {
            return Curry._2(Domains.keep, domains, (function (param) {
                          return Fuzzaldrin.score(param.name, filterString) > 0.0015;
                        }));
          }
        }), [
        domains,
        filterString
      ]);
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var domainsLimits = AvoLimits.Domain.computeEntityNumericLimits(workspace, Curry._1(Domains.size, domains));
  var domainsAvailability = AvoLimits.Domain.createActionStatus(domainsLimits);
  var match$2 = Curry._1(Domains.size, domains);
  var tmp;
  if (domainStoreIsInitialized) {
    if (match$2 !== 0) {
      var match$3 = workspace.plan.domainsIncluded;
      tmp = React.createElement("div", undefined, React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: "Using " + Curry._1(Domains.sizeToString, domains) + " out of " + (
                      typeof match$3 === "object" ? AvoUtils.plural(undefined, undefined, match$3.VAL, "domain") : "unlimited domains"
                    ) + " available on " + Workspace.printPlanName(workspace.plan.name) + ""
                  }), React.createElement(Spacer.make, {
                    width: 0,
                    grow: 1.0
                  }), role === "Admin" ? React.createElement(Button.make, {
                      icon: domainsAvailability === "AvailableDuringTrial" || domainsAvailability === "Available" ? "plus" : "lightning",
                      label: "New domain",
                      onClick: (function (param) {
                          if (domainsAvailability === "AvailableDuringTrial" || domainsAvailability === "Available") {
                            return Curry._1(modalAction.open_, undefined);
                          } else {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "AddDomain"
                                        }
                                      });
                          }
                        }),
                      size: "tiny"
                    }) : null), React.createElement(Spacer.make, {
                height: 8
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.gap(Css.px(100)),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement(Input.make, {
                    value: filterString,
                    onChange: (function (v) {
                        Curry._1(setFilterString, (function (param) {
                                return v;
                              }));
                      }),
                    placeholder: "Find domain...",
                    className: {
                      hd: Css.maxWidth(Css.px(360)),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: /* [] */0
                      }
                    }
                  })), React.createElement(Spacer.make, {
                height: 16
              }), React.createElement("div", {
                className: grid
              }, React.createElement($$Text.make, {
                    element: "Div",
                    size: "Small",
                    color: Styles.Color.light09,
                    children: "Domains (" + Curry._1(Domains.sizeToString, filteredDomains) + ")"
                  }), React.createElement($$Text.make, {
                    element: "Div",
                    size: "Small",
                    color: Styles.Color.light09,
                    children: "Events"
                  }), React.createElement($$Text.make, {
                    element: "Div",
                    size: "Small",
                    color: Styles.Color.light09,
                    children: "Members"
                  }), React.createElement("div", undefined), Curry._2(Domains.mapToArray, filteredDomains, (function (domain) {
                      return React.createElement(WorkspaceDomains$Domain, {
                                  domains: domains,
                                  domain: domain,
                                  onClose: (function (param) {
                                      Curry._1(modalAction.close, undefined);
                                      Curry._1(onClose, undefined);
                                    }),
                                  key: domain.id
                                });
                    }))));
    } else {
      tmp = React.createElement("div", {
            className: emptyStyles
          }, React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.maxWidth(Css.px(250)),
                      tl: /* [] */0
                    })
              }, React.createElement(Title.make, {
                    children: "Manage your tracking plan with domains",
                    size: "Tiny"
                  })), React.createElement(Spacer.make, {
                height: 16
              }), React.createElement($$Text.make, {
                size: "Medium",
                color: Styles.Color.light10,
                maxWidth: Css.px(360),
                children: "Domains are used to connect events to all of the stakeholders who rely on them."
              }), React.createElement(Spacer.make, {
                height: 24
              }), role === "Admin" ? React.createElement(Button.make, {
                  label: "Create domain",
                  onClick: (function (param) {
                      if (domainsAvailability === "AvailableDuringTrial" || domainsAvailability === "Available") {
                        Curry._1(modalAction.open_, undefined);
                      } else {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "AddDomain"
                              }
                            });
                      }
                      AnalyticsRe.domainInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.schemaId);
                    }),
                  size: "large"
                }) : React.createElement(SimpleTooltip.make, {
                  tooltip: "Only workspace admins can create domains",
                  children: React.createElement(Button.make, {
                        label: "Create domain",
                        onClick: (function (param) {
                            
                          }),
                        size: "large"
                      })
                }));
    }
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.height(Css.px(100)),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.justifyContent("center"),
                      tl: /* [] */0
                    }
                  }
                }
              })
        }, React.createElement(LoadingCircle.make, {
              size: 28,
              color: Styles.Color.light10
            }));
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: rootStyles
                }, tmp), React.createElement("div", {
                  className: docsBoxStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: "Learn more about domains in the "
                    }), React.createElement(DocsLink.make, {
                      path: "workspace-management/domains",
                      label: "Avo docs"
                    })), React.createElement(WorkspaceDomains$NewDomainModal, {
                  availability: domainsAvailability,
                  opened: match[0],
                  onCreate: (function (id) {
                      Curry._1(onClose, undefined);
                      Router.Schema.pushSchemaRoute(undefined, undefined, {
                            NAME: "domain",
                            VAL: id
                          });
                    }),
                  onClose: (function (param) {
                      Curry._1(modalAction.close, undefined);
                    })
                }));
}

var make = WorkspaceDomains;

export {
  Domain ,
  rootStyles ,
  emptyStyles ,
  textBoxStyles ,
  docsBoxStyles ,
  NewDomainModal ,
  make ,
}
/* headerItem Not a pure module */
