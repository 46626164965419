// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"addYourFirstEventToTheTrackingPlan":"addYourFirstEventToTheTrackingPlan","setUpInspector":"setUpInspector","addEventsFromInspector":"addEventsFromInspector","setUpAlertsForYourMostImportantEvents":"setUpAlertsForYourMostImportantEvents","triageAnIssue":"triageAnIssue","completeDemoBranchTour":"completeDemoBranchTour"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toDisplayString(t) {
  if (t === "setUpAlertsForYourMostImportantEvents") {
    return "Set up Slack alerts for your most important events";
  } else if (t === "triageAnIssue") {
    return "Resolve your first issue";
  } else if (t === "addYourFirstEventToTheTrackingPlan") {
    return "Add an event to the Tracking Plan";
  } else if (t === "addEventsFromInspector") {
    return "Import events from Inspector";
  } else if (t === "completeDemoBranchTour") {
    return "Learn how to design and implement your event tracking";
  } else {
    return "Set up Inspector";
  }
}

function toAnalytics(step) {
  if (step === "setUpAlertsForYourMostImportantEvents") {
    return "GettingStartedSetUpAlerts";
  } else if (step === "triageAnIssue") {
    return "GettingStartedTriageAnIssue";
  } else if (step === "addYourFirstEventToTheTrackingPlan") {
    return "GettingStartedAddYourFirstEvent";
  } else if (step === "addEventsFromInspector") {
    return "GettingStartedAddEventsFromInspector";
  } else if (step === "completeDemoBranchTour") {
    return "GettingStartedMergeABranch";
  } else {
    return "GettingStartedSetUpInspector";
  }
}

var manualSteps = [
  "addYourFirstEventToTheTrackingPlan",
  "setUpInspector",
  "setUpAlertsForYourMostImportantEvents",
  "triageAnIssue",
  "completeDemoBranchTour"
];

var liveDataSteps = [
  "setUpInspector",
  "addEventsFromInspector",
  "setUpAlertsForYourMostImportantEvents",
  "triageAnIssue",
  "completeDemoBranchTour"
];

var StepId = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toDisplayString: toDisplayString,
  toAnalytics: toAnalytics,
  manualSteps: manualSteps,
  liveDataSteps: liveDataSteps
};

export {
  StepId ,
}
/* No side effect */
