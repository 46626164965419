// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, apiKey, language) {
  if (language !== undefined) {
    if (language === "objc") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "AvoInspector * avoInspector = [[AvoInspector ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "alloc"), "] ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "initWithApiKey:"), "@", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"" + apiKey + "\""), React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, " env:"), "AvoInspectorEnvDev];"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "AvoInspector *avoInspector = [[AvoInspector alloc] initWithApiKey:@\"" + apiKey + "\" env: AvoInspectorEnvDev];"
                        })));
    } else if (language === "swift") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "let "), "avoInspector = ", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "AvoInspector"), "(apiKey: ", React.createElement("span", {
                          className: docsCodeOrangeStyles
                        }, "\"" + apiKey + "\""), ", isDev: true)"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "let avoInspector = AvoInspector(apiKey: \"" + apiKey + "\", env: AvoInspectorEnv.dev)"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export {
  code ,
}
/* react Not a pure module */
