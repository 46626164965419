// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Actions from "./actions.mjs";
import * as DateFns from "./DateFns.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import PLimit from "p-limit";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainUtils from "./DomainUtils.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as InspectorViewApi from "./inspector/InspectorViewApi.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";

function prepareImportData(parsedTrackingPlan, newAndUpdatedProperties) {
  var allEvents = Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents);
  var eventIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(allEvents, (function (param) {
                  return param.id;
                }))));
  var eventNames = Belt_MapString.fromArray(Belt_Array.map(allEvents, (function ($$event) {
              return [
                      $$event.name,
                      $$event
                    ];
            })));
  var propertyIds = Belt_Array.map(newAndUpdatedProperties, (function (property) {
          return property.id;
        }));
  var propertyNames = Belt_MapString.fromArray(Belt_Array.map(newAndUpdatedProperties, (function (property) {
              return [
                      property.name,
                      property
                    ];
            })));
  var sourceIds = parsedTrackingPlan.sources;
  var propertyGroupIds = Belt_Array.map(parsedTrackingPlan.propertyBundles, (function (propertyGroup) {
          return propertyGroup.id;
        }));
  return [
          eventIds,
          eventNames,
          propertyIds,
          propertyNames,
          sourceIds,
          propertyGroupIds
        ];
}

function handleImportError(error, eventNames, propertyNames, issuesToResolve, branch, schemaBundle, importStartedLocation, addToast) {
  Sentry.captureException(error, {
        message: "Failed to import events and properties",
        schemaId: schemaBundle.schemaId,
        branchId: NamedBranch.getId(branch),
        importStartedLocation: AnalyticsRe.importStartedLocationToJs(importStartedLocation),
        eventNames: eventNames,
        propertyNames: propertyNames,
        issueIdsToResolve: Curry._1(Issues.size, issuesToResolve)
      });
  return Curry._1(addToast, {
              message: "Failed to import",
              toastType: /* Error */1,
              persist: true
            });
}

function resolveIssues(issuesToResolve, branch, workspace, schemaBundle, importStartedLocation, eventNames, propertyNames, addToast, onSuccess, resolve) {
  if (branch === /* Master */0 && Curry._1(Issues.size, issuesToResolve) > 0 && !workspace.isSandbox) {
    var resolvedUntil = DateFns.addHours(2.0, new Date());
    $$Promise.$$catch(InspectorViewApi.multiUpdateIssueStatus({
                TAG: /* Resolved */1,
                _0: {
                  TAG: /* Date */3,
                  _0: resolvedUntil
                }
              }, "importSideEffect", Curry._2(Issues.mapToArray, issuesToResolve, (function (param) {
                      return param.id;
                    }))).then(function (resolvedTimescaleIssues) {
              var resolvedIssues = Belt_Array.keepMapU(resolvedTimescaleIssues, (function (param) {
                      var match = param.issueStatus.status;
                      if (typeof match === "number" || match.TAG === /* Ignored */0) {
                        return ;
                      } else {
                        return Curry._2(Issues.get, issuesToResolve, param.issueId);
                      }
                    }));
              Curry._2(onSuccess, resolvedIssues, branch);
              resolve(branch);
            }), (function (e) {
            Sentry.captureException(e, {
                  message: "Failed to resolve issues after inspector import",
                  schemaId: schemaBundle.schemaId,
                  branchId: NamedBranch.getId(branch),
                  importStartedLocation: AnalyticsRe.importStartedLocationToJs(importStartedLocation),
                  eventNames: eventNames,
                  propertyNames: propertyNames,
                  issueIdsToResolve: Curry._1(Issues.size, issuesToResolve)
                });
            Curry._1(addToast, {
                  message: "Failed to auto resolve issues",
                  toastType: /* Error */1,
                  persist: true
                });
            resolve(branch);
            return Promise.resolve(undefined);
          }));
    return ;
  }
  Curry._2(onSuccess, [], branch);
  resolve(branch);
}

function snapshotAndWriteImportActions(workspace, branch, modelWithImport, lastAction, parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, importStartedLocation, viewerId, onSuccess, schemaBundle, issues, model, addToast) {
  var limitCreateDomain = PLimit(16);
  var createDomainPromises = Belt_Array.keepMapU(importActions, (function (importAction) {
          if (typeof importAction !== "object") {
            return ;
          }
          if (importAction.NAME !== "CreateDomain") {
            return ;
          }
          var match = importAction.VAL;
          var name = match[1];
          var oldDomainId = match[0];
          return Caml_option.some(Curry._1(limitCreateDomain, (function (param) {
                            return DomainUtils.Firebase.createDomain("", {
                                          events: [],
                                          eventVariants: []
                                        }, name, (function (param) {
                                            
                                          }), (function (param) {
                                            
                                          }), sendActions, viewerId, workspace.id).then(function (newDomainId) {
                                        return Promise.resolve([
                                                    oldDomainId,
                                                    newDomainId
                                                  ]);
                                      });
                          })));
        }));
  var limitUpdateDomain = PLimit(16);
  return $$Promise.$$catch(Promise.all(createDomainPromises).then(function (oldDomainIdsToNewDomainIds) {
                      return Promise.all(Belt_Array.keepMapU(importActions, (function (importAction) {
                                        if (typeof importAction !== "object") {
                                          return ;
                                        }
                                        if (importAction.NAME !== "AddDomainItems") {
                                          return ;
                                        }
                                        var match = importAction.VAL;
                                        var domainEvents = match[1];
                                        var oldDomainId = match[0];
                                        var domainId = Belt_Option.getWithDefault(Belt_MapString.get(Belt_MapString.fromArray(oldDomainIdsToNewDomainIds), oldDomainId), oldDomainId);
                                        return Caml_option.some(Curry._1(limitUpdateDomain, (function (param) {
                                                          return DomainUtils.Firebase.addDomainItems(domainEvents, (function (param) {
                                                                        
                                                                      }), (function (param) {
                                                                        
                                                                      }), workspace.id, sendActions, viewerId, domainId);
                                                        })));
                                      })));
                    }), (function (error) {
                    console.error("Import stakeholder domains failed with error:", error);
                    Curry._1(addToast, {
                          message: "Failed to import Stakeholders, proceeding with the rest of the import",
                          toastType: /* Error */1,
                          persist: false
                        });
                    return Promise.resolve([]);
                  })).then(function (param) {
                return Actions.snapshot(workspace.id, NamedBranch.getId(branch), modelWithImport, Belt_Option.getExn(lastAction));
              }).then(function (param) {
              var match = prepareImportData(parsedTrackingPlan, newAndUpdatedProperties);
              var propertyGroupIds = match[5];
              var sourceIds = match[4];
              var propertyNames = match[3];
              var propertyIds = match[2];
              var eventNames = match[1];
              var eventIds = match[0];
              var numNewEvents = eventIds.length;
              var numNewProperties = propertyIds.length;
              var numNewSources = Belt_Array.keepU(importActions, (function (importAction) {
                      if (typeof importAction === "object") {
                        return importAction.NAME === "CreateSource";
                      } else {
                        return false;
                      }
                    })).length;
              var numNewStakeholderDomains = Belt_Array.keepU(importActions, (function (importAction) {
                      if (typeof importAction === "object") {
                        return importAction.NAME === "CreateDomain";
                      } else {
                        return false;
                      }
                    })).length;
              var issuesToResolve = branch === /* Master */0 ? ImportReview__V2__Utils.getRelatedIssues(eventNames, propertyNames, issues) : Issues.empty;
              return new Promise((function (resolve, reject) {
                            Curry.app(sendActions, [
                                  NamedBranch.toUnnamed(branch),
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (_branch) {
                                      resolveIssues(issuesToResolve, branch, workspace, schemaBundle, importStartedLocation, eventNames, propertyNames, addToast, Curry._4(onSuccess, numNewEvents, numNewProperties, numNewSources, numNewStakeholderDomains), resolve);
                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      AnalyticsRe.importCompleted(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_List.length(model.events) + Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length | 0, Belt_List.length(model.properties) + newAndUpdatedProperties.length | 0, newAndUpdatedProperties.length, Belt_Array.concat(parsedTrackingPlan.newEvents, parsedTrackingPlan.updatedEvents).length, parsedTrackingPlan.newEvents.length, parsedTrackingPlan.updatedEvents.length, importStartedLocation, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                    }),
                                  (function (error) {
                                      handleImportError(error, eventNames, propertyNames, issuesToResolve, branch, schemaBundle, importStartedLocation, addToast);
                                      reject(error);
                                    }),
                                  [[
                                      {
                                        NAME: "CompletedImport",
                                        VAL: [
                                          numNewEvents,
                                          numNewProperties,
                                          numNewSources,
                                          numNewStakeholderDomains,
                                          importStartedLocation === "OnboardingChecklist" ? "onboardingChecklist" : (
                                              importStartedLocation === "TrackingPlanEventsEmptyState" ? "trackingPlanEventsEmptyState" : (
                                                  importStartedLocation === "InspectorIssuesHeader" ? "inspectorIssuesHeader" : (
                                                      importStartedLocation === "DirectLink" ? "directLink" : (
                                                          importStartedLocation === "InspectorEventsHeader" ? "inspectorEventsHeader" : (
                                                              importStartedLocation === "InspectorIssueDetails" ? "inspectorIssueDetails" : (
                                                                  importStartedLocation === "InspectorEventsSidebar" ? "inspectorEventsSidebar" : (
                                                                      importStartedLocation === "InspectorEventsTableRow" ? "inspectorEventsTableRow" : (
                                                                          importStartedLocation === "CmdPalette" ? "cmdPalette" : "sidebarSettingsCog"
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        ]
                                      },
                                      {
                                        eventIds: eventIds,
                                        eventsQuery: eventIds,
                                        propertyIds: propertyIds,
                                        propertiesQuery: propertyIds,
                                        sourceIds: sourceIds,
                                        sourcesQuery: sourceIds,
                                        propertyGroupIds: propertyGroupIds,
                                        propertyGroupsQuery: propertyGroupIds
                                      }
                                    ]]
                                ]);
                          }));
            });
}

export {
  prepareImportData ,
  handleImportError ,
  resolveIssues ,
  snapshotAndWriteImportActions ,
}
/* Issues Not a pure module */
