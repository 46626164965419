// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as GetRegexValidationUseCase from "./GetRegexValidationUseCase.mjs";

function update(property, maybeEventId, maybeRegexRule) {
  var isEmpty = function (param) {
    if (param.propertyRule === undefined) {
      return param.eventOverrides === undefined;
    } else {
      return false;
    }
  };
  var existingRegexValidation = GetRegexValidationUseCase.get(property);
  var nextRegexValidation = existingRegexValidation !== undefined ? (
      maybeEventId !== undefined ? ({
            propertyRule: existingRegexValidation.propertyRule,
            eventOverrides: Belt_MapString.update(existingRegexValidation.eventOverrides, maybeEventId, (function (param) {
                    return maybeRegexRule;
                  }))
          }) : ({
            propertyRule: maybeRegexRule,
            eventOverrides: existingRegexValidation.eventOverrides
          })
    ) : (
      maybeEventId !== undefined ? (
          maybeRegexRule !== undefined ? ({
                propertyRule: undefined,
                eventOverrides: Belt_MapString.fromArray([[
                        maybeEventId,
                        maybeRegexRule
                      ]])
              }) : undefined
        ) : (
          maybeRegexRule !== undefined ? ({
                propertyRule: maybeRegexRule,
                eventOverrides: undefined
              }) : undefined
        )
    );
  var nextRegexValidation$1 = Belt_Option.flatMap(nextRegexValidation, (function (validation) {
          if (isEmpty(validation)) {
            return ;
          } else {
            return validation;
          }
        }));
  if (existingRegexValidation !== undefined) {
    if (nextRegexValidation$1 !== undefined) {
      var newrecord = Caml_obj.obj_dup(property);
      newrecord.validations = Belt_List.mapU(property.validations, (function (validation) {
              if (typeof validation === "object" && validation.NAME === "Regex") {
                return {
                        NAME: "Regex",
                        VAL: nextRegexValidation$1
                      };
              } else {
                return validation;
              }
            }));
      return newrecord;
    }
    var newrecord$1 = Caml_obj.obj_dup(property);
    newrecord$1.validations = Belt_List.keepU(property.validations, (function (validation) {
            if (typeof validation === "object") {
              return validation.NAME !== "Regex";
            } else {
              return true;
            }
          }));
    return newrecord$1;
  }
  if (nextRegexValidation$1 === undefined) {
    return property;
  }
  var newrecord$2 = Caml_obj.obj_dup(property);
  newrecord$2.validations = Belt_List.concat(property.validations, {
        hd: {
          NAME: "Regex",
          VAL: nextRegexValidation$1
        },
        tl: /* [] */0
      });
  return newrecord$2;
}

export {
  update ,
}
/* No side effect */
