// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as AppVersionsTypes from "../../shared/models/AppVersionsTypes.mjs";

function get(limitVersionsPerSource, param) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var url = "/api/workspaces/" + schemaId + "/inspector/appVersions/latest/v2" + Belt_Option.mapWithDefault(limitVersionsPerSource, "", (function (l) {
          return "?limitVersionsPerSource=" + String(l) + "";
        })) + "";
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
              return fetch(url, Fetch.RequestInit.make(/* Get */0, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token + ""
                                    ]
                                  ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(Fetch.$$Response.json).then(AppVersionsTypes.Decode.response);
            });
}

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, getStore) {
      return {
              appVersions: undefined,
              latestTimestamp: undefined,
              fetchStatus: "idle",
              fetch: (function (param) {
                  var match = Curry._1(getStore, undefined);
                  if (match.fetchStatus !== "fetching") {
                    set(function (state) {
                          return {
                                  appVersions: state.appVersions,
                                  latestTimestamp: state.latestTimestamp,
                                  fetchStatus: "fetching",
                                  fetch: state.fetch
                                };
                        });
                    return $$Promise.$$catch(get(undefined, undefined).then(function (appVersions) {
                                    set(function (state) {
                                          return {
                                                  appVersions: appVersions,
                                                  latestTimestamp: Date.now(),
                                                  fetchStatus: "success",
                                                  fetch: state.fetch
                                                };
                                        });
                                  }), (function (param) {
                                  set(function (state) {
                                        return {
                                                appVersions: state.appVersions,
                                                latestTimestamp: undefined,
                                                fetchStatus: "error",
                                                fetch: state.fetch
                                              };
                                      });
                                  return Promise.resolve(undefined);
                                }));
                  } else {
                    return Promise.resolve(undefined);
                  }
                })
            };
    });

function use(newerThan, takeLast, param) {
  var $$fetch = Curry._2(ZustandStore.use, store, (function (state) {
          return state.fetch;
        }));
  var appVersions = Curry._2(ZustandStore.use, store, (function (state) {
          return state.appVersions;
        }));
  var fetchStatus = Curry._2(ZustandStore.use, store, (function (state) {
          return state.fetchStatus;
        }));
  var latestTimestamp = Curry._2(ZustandStore.use, store, (function (state) {
          return state.latestTimestamp;
        }));
  var newerThan$1 = React.useMemo((function () {
          return Belt_Option.getWithDefault(newerThan, Date.now());
        }), [newerThan]);
  React.useEffect((function () {
          if (latestTimestamp !== undefined && newerThan$1 <= latestTimestamp) {
            
          } else {
            Curry._1($$fetch, undefined);
          }
        }), [newerThan$1]);
  return React.useMemo((function () {
                return [
                        takeLast !== undefined ? Belt_MapString.mapU(appVersions, (function (appVersions) {
                                  return Belt_Array.slice(appVersions, 0, takeLast);
                                })) : appVersions,
                        fetchStatus
                      ];
              }), [
              appVersions,
              takeLast,
              fetchStatus
            ]);
}

export {
  get ,
  StoreConfig ,
  ZustandStore ,
  store ,
  use ,
}
/* ZustandStore Not a pure module */
