// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Confetti from "./Confetti.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FramerMotion from "framer-motion";
import * as GettingStarted from "./GettingStarted.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";
import * as GettingStarted__Progress from "./GettingStarted__Progress.mjs";
import * as GettingStarted__ProgressStore from "./GettingStarted__ProgressStore.mjs";

function getPulseKeyframeStyles(color, step) {
  var alpha = Styles.Color.setAlpha(color, 0.0);
  if (step === 1) {
    return {
            hd: Css.boxShadows({
                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(6), undefined, Styles.Color.setAlpha(color, 0.6)),
                  tl: {
                    hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(0), undefined, color),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          };
  } else if (step === 2) {
    return {
            hd: Css.boxShadows({
                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(12), undefined, Styles.Color.setAlpha(color, 0.3)),
                  tl: {
                    hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(6), undefined, Styles.Color.setAlpha(color, 0.3)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          };
  } else if (step === 3) {
    return {
            hd: Css.boxShadows({
                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(12), undefined, Styles.Color.setAlpha(color, 0.15)),
                  tl: {
                    hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(12), undefined, Styles.Color.setAlpha(color, 0.15)),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          };
  } else if (step === 4) {
    return {
            hd: Css.boxShadows({
                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(0), undefined, alpha),
                  tl: {
                    hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(0), undefined, alpha),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          };
  } else {
    return {
            hd: Css.boxShadows({
                  hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(0), undefined, color),
                  tl: {
                    hd: Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(0), undefined, color),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          };
  }
}

var dismiss = Curry._1(Css.style, {
      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
      tl: {
        hd: Css.padding(Css.px(8)),
        tl: {
          hd: Css.position("absolute"),
          tl: {
            hd: Css.top(Css.px(6)),
            tl: {
              hd: Css.right(Css.px(3)),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$short
                    }, undefined, undefined, "all"),
                tl: {
                  hd: Css.focus({
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                        tl: {
                          hd: Css.outlineStyle("none"),
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.hover({
                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.deepBlue),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function button(animating) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("flexStart"),
                          tl: {
                            hd: Css.color(Styles.Color.white),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.5)),
                                  tl: {
                                    hd: Css.padding(Css.px(12)),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.minHeight(Css.px(116)),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$default
                                                  }, undefined, undefined, "all"),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.transform(Css.translateY(Css.px(-4))),
                                                      tl: {
                                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(20), Css.px(40), undefined, undefined, Styles.Color.setAlpha(Styles.Color.darkBlue, 0.2))),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.position("relative"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: animating ? Curry._1(Css.style, {
                          hd: Css.animations({
                                hd: Css_Legacy_Core.Animation.shorthand({
                                      NAME: "ms",
                                      VAL: 1000.0
                                    }, undefined, undefined, undefined, "forwards", undefined, {
                                      NAME: "count",
                                      VAL: 6
                                    }, Curry._1(Css.keyframes, {
                                          hd: [
                                            0,
                                            getPulseKeyframeStyles(Styles.Color.deepBlue, 0)
                                          ],
                                          tl: {
                                            hd: [
                                              30,
                                              getPulseKeyframeStyles(Styles.Color.deepBlue, 1)
                                            ],
                                            tl: {
                                              hd: [
                                                60,
                                                getPulseKeyframeStyles(Styles.Color.deepBlue, 2)
                                              ],
                                              tl: {
                                                hd: [
                                                  90,
                                                  getPulseKeyframeStyles(Styles.Color.deepBlue, 3)
                                                ],
                                                tl: {
                                                  hd: [
                                                    100,
                                                    getPulseKeyframeStyles(Styles.Color.deepBlue, 4)
                                                  ],
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })),
                                tl: {
                                  hd: Css_Legacy_Core.Animation.shorthand({
                                        NAME: "ms",
                                        VAL: 1000.0
                                      }, undefined, undefined, undefined, undefined, undefined, {
                                        NAME: "count",
                                        VAL: 6
                                      }, Curry._1(Css.keyframes, {
                                            hd: [
                                              75,
                                              {
                                                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.darkBlue, 0.7)),
                                                tl: /* [] */0
                                              }
                                            ],
                                            tl: /* [] */0
                                          })),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }) : Curry._1(Css.style, /* [] */0),
                  tl: /* [] */0
                }
              }
            });
}

var instructions = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  getPulseKeyframeStyles: getPulseKeyframeStyles,
  dismiss: dismiss,
  button: button,
  instructions: instructions
};

function getProgress(completedSteps, stepIds) {
  return Belt_Array.keep(stepIds, (function (stepId) {
                return Belt_Option.getWithDefault(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(stepId)), false);
              })).length / stepIds.length * 100;
}

function containerStyles(complete) {
  return Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.top(Css.px(0)),
                  tl: {
                    hd: Css.width(Css.pct(100.0)),
                    tl: {
                      hd: Css.height(Css.pct(100.0)),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.zIndex(2),
                          tl: {
                            hd: Css.opacity(complete ? 1.0 : 0.0),
                            tl: {
                              hd: Css.pointerEvents(complete ? "auto" : "none"),
                              tl: {
                                hd: Css.cursor(complete ? "pointer" : "default"),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.transitions({
                                            hd: Css_Legacy_Core.Transition.shorthand({
                                                  NAME: "ms",
                                                  VAL: complete ? 350.0 : 150.0
                                                }, {
                                                  NAME: "ms",
                                                  VAL: complete ? 2000.0 : 0.0
                                                }, undefined, "opacity"),
                                            tl: {
                                              hd: Css_Legacy_Core.Transition.shorthand({
                                                    NAME: "ms",
                                                    VAL: 250.0
                                                  }, undefined, undefined, "transform"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector(" .congrats,.content", {
                                              hd: Css.display("inlineBlock"),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: 150.0
                                                    }, undefined, undefined, "all"),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.selector(" .content", {
                                                      hd: Css.transform(Css.scale(1.03, 1.03)),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.selector(" .congrats", {
                                                        hd: Css.transforms({
                                                              hd: Css.scale(1.4, 1.4),
                                                              tl: {
                                                                hd: Css.translate(Css.px(4), Css.px(0)),
                                                                tl: {
                                                                  hd: Css.rotate(Css.deg(8.0)),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function dismissStyles(complete) {
  return Curry._1(Css.style, {
              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.top(Css.px(6)),
                    tl: {
                      hd: Css.right(Css.px(0)),
                      tl: {
                        hd: Css.cursor("pointer"),
                        tl: {
                          hd: Css.opacity(complete ? 1.0 : 0.0),
                          tl: {
                            hd: Css.zIndex(3),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.transitions({
                                      hd: Css_Legacy_Core.Transition.shorthand({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "all"),
                                      tl: {
                                        hd: Css_Legacy_Core.Transition.shorthand({
                                              NAME: "ms",
                                              VAL: 350.0
                                            }, {
                                              NAME: "ms",
                                              VAL: complete ? 1350.0 : 0.0
                                            }, undefined, "opacity"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                        tl: {
                                          hd: Css.outlineStyle("none"),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.deepBlue),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function GettingStarted__SidebarProgress$Congratulations(Props) {
  var complete = Props.complete;
  var onDismiss = Props.onDismiss;
  var currentStep = Props.currentStep;
  return React.createElement("div", {
              className: containerStyles(complete),
              onClick: (function (param) {
                  Router.Schema.pushSchemaRoute(undefined, undefined, "gettingStarted");
                })
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "content"
                    }, React.createElement($$Text.make, {
                          element: "Div",
                          weight: "Semi",
                          color: Styles.Color.light02,
                          children: null
                        }, "Congratulations ", React.createElement("div", {
                              className: "congrats"
                            }, "🎉")), React.createElement($$Text.make, {
                          weight: "Semi",
                          color: Styles.Color.light02,
                          children: StringExt.widowless("You have completed all the steps!")
                        }))), React.createElement("div", {
                  className: dismissStyles(complete),
                  onClick: Curry._1(onDismiss, currentStep)
                }, React.createElement(IconX.make, {
                      size: 12
                    })));
}

var Congratulations = {
  containerStyles: containerStyles,
  dismissStyles: dismissStyles,
  make: GettingStarted__SidebarProgress$Congratulations
};

var containerStyles$1 = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.transition({
              NAME: "ms",
              VAL: 350.0
            }, undefined, undefined, "height"),
        tl: /* [] */0
      }
    });

function curtainStyles(collapsed) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.unsafe("content", "''"),
                tl: {
                  hd: Css.position("absolute"),
                  tl: {
                    hd: Css.left(collapsed ? Css.pct(0.0) : Css.pct(4.0)),
                    tl: {
                      hd: Css.top(collapsed ? Css.pct(0.0) : Css.pct(50.0)),
                      tl: {
                        hd: Css.opacity(collapsed ? 1.0 : 0.5),
                        tl: {
                          hd: Css.width(collapsed ? Css.pct(100.0) : Css.pct(92.0)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.darkBlue),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: collapsed ? 350.0 : 350.0
                                    }, {
                                      NAME: "ms",
                                      VAL: collapsed ? 1350.0 : 0.0
                                    }, "easeInOut", "all"),
                                tl: {
                                  hd: Css.height(collapsed ? Css.pct(100.0) : Css.pct(0.0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function GettingStarted__SidebarProgress$CollapsingContainer(Props) {
  var collapsed = Props.collapsed;
  var onAnimationEnd = Props.onAnimationEnd;
  var children = Props.children;
  return React.createElement("div", {
              className: containerStyles$1
            }, React.createElement("div", {
                  className: curtainStyles(collapsed),
                  onTransitionEnd: onAnimationEnd
                }), children);
}

var CollapsingContainer = {
  containerStyles: containerStyles$1,
  curtainStyles: curtainStyles,
  make: GettingStarted__SidebarProgress$CollapsingContainer
};

function GettingStarted__SidebarProgress$ProgressBar(Props) {
  var progress = Props.progress;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.deepBlue, 0.5)),
                      tl: {
                        hd: Css.borderRadius(Css.px(100)),
                        tl: {
                          hd: Css.height(Css.px(16)),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: 350.0
                                }, {
                                  NAME: "ms",
                                  VAL: 1000.0
                                }, undefined, "width"),
                            tl: {
                              hd: Css.width(Css.pct(progress)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.darkBlue),
                                tl: {
                                  hd: Css.height(Css.pct(100)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    })));
}

var ProgressBar = {
  make: GettingStarted__SidebarProgress$ProgressBar
};

function useProgressReducer(completedSteps, onboardingChoice, complete, loading) {
  return React.useReducer((function (state, action) {
                if (typeof action === "object") {
                  if (action.NAME === "showCompletionModal") {
                    return {
                            onboardingChoice: state.onboardingChoice,
                            initialized: state.initialized,
                            stepIds: state.stepIds,
                            progress: state.progress,
                            animating: state.animating,
                            completeState: state.completeState,
                            showConfetti: state.showConfetti,
                            completionModalActive: action.VAL
                          };
                  } else {
                    return {
                            onboardingChoice: state.onboardingChoice,
                            initialized: state.initialized,
                            stepIds: state.stepIds,
                            progress: state.progress,
                            animating: state.animating,
                            completeState: state.completeState,
                            showConfetti: action.VAL,
                            completionModalActive: state.completionModalActive
                          };
                  }
                }
                if (action === "calculateProgress") {
                  if (loading) {
                    return state;
                  }
                  var newProgress = getProgress(completedSteps, onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps);
                  return {
                          onboardingChoice: onboardingChoice,
                          initialized: state.initialized,
                          stepIds: onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps,
                          progress: newProgress,
                          animating: state.initialized && (newProgress > state.progress && state.onboardingChoice === onboardingChoice || newProgress === 100.0),
                          completeState: complete ? "complete" : "incomplete",
                          showConfetti: state.showConfetti,
                          completionModalActive: state.completionModalActive
                        };
                }
                if (action === "removeAnimation") {
                  return {
                          onboardingChoice: state.onboardingChoice,
                          initialized: state.initialized,
                          stepIds: state.stepIds,
                          progress: state.progress,
                          animating: false,
                          completeState: state.completeState,
                          showConfetti: state.showConfetti,
                          completionModalActive: state.completionModalActive
                        };
                }
                if (action === "animateCompleteOut") {
                  return {
                          onboardingChoice: state.onboardingChoice,
                          initialized: state.initialized,
                          stepIds: state.stepIds,
                          progress: state.progress,
                          animating: false,
                          completeState: "animateOut",
                          showConfetti: state.showConfetti,
                          completionModalActive: state.completionModalActive
                        };
                }
                if (action === "resetProgress") {
                  if (loading) {
                    return state;
                  }
                  var newProgress$1 = getProgress(completedSteps, onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps);
                  return {
                          onboardingChoice: onboardingChoice,
                          initialized: state.initialized,
                          stepIds: onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps,
                          progress: newProgress$1,
                          animating: state.initialized && (newProgress$1 > state.progress && state.onboardingChoice === onboardingChoice || newProgress$1 === 100.0),
                          completeState: complete ? "complete" : "incomplete",
                          showConfetti: state.showConfetti,
                          completionModalActive: state.completionModalActive
                        };
                }
                var newProgress$2 = getProgress(completedSteps, onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps);
                return {
                        onboardingChoice: onboardingChoice,
                        initialized: true,
                        stepIds: onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps,
                        progress: newProgress$2,
                        animating: state.animating,
                        completeState: complete ? "complete" : "incomplete",
                        showConfetti: state.showConfetti,
                        completionModalActive: state.completionModalActive
                      };
              }), {
              onboardingChoice: "liveData",
              initialized: false,
              stepIds: onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps,
              progress: getProgress(completedSteps, onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps),
              animating: false,
              completeState: complete ? "complete" : "incomplete",
              showConfetti: false,
              completionModalActive: false
            });
}

function GettingStarted__SidebarProgress(Props) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = GettingStarted__ProgressStore.use(undefined);
  var loading = match.loading;
  var handleDismiss = match.handleDismiss;
  var complete = match.complete;
  var completedSteps = match.completedSteps;
  var onboardingChoice = match.onboardingChoice;
  var globalSend = GlobalSendContext.use(undefined);
  var match$1 = useProgressReducer(completedSteps, onboardingChoice, complete, loading);
  var reduce = match$1[1];
  var state = match$1[0];
  var previousLoading = Hooks.usePrevious1(loading);
  var previousInitialized = Hooks.usePrevious1(state.initialized);
  var hasGettingStarted = GettingStarted.useHasGettingStarted(undefined);
  var steps = Belt_Array.mapWithIndex(state.stepIds, (function (index, stepId) {
          return {
                  index: index,
                  completed: Belt_Option.getWithDefault(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(stepId)), false),
                  title: GettingStarted__Types.StepId.toDisplayString(stepId),
                  subtitle: ""
                };
        }));
  var currentStep = Belt_Array.getIndexBy(steps, (function (param) {
          return !param.completed;
        }));
  var step = Belt_Array.get(steps, Belt_Option.getWithDefault(currentStep, 0));
  var instructionsText = state.progress === 100.0 ? "Finished!" : (
      step !== undefined ? "" + (
          step.index === 0 ? "First" : "Next"
        ) + ": " + step.title + "" : ""
    );
  var onDismiss = function (_step, $$event) {
    $$event.stopPropagation();
    AnalyticsRe.onboardingChecklistDismissed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Array.mapU(Belt_Array.keep(state.stepIds, (function (stepId) {
                    return Belt_Option.mapWithDefaultU(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(stepId)), true, (function (completed) {
                                  return !completed;
                                }));
                  })), (function (stepId) {
                return GettingStarted__Types.StepId.toAnalytics(stepId);
              })), Belt_Array.keep(steps, (function (param) {
                return !param.completed;
              })).length, "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId);
    Curry._1(handleDismiss, undefined);
  };
  React.useEffect((function () {
          if (previousLoading && !loading) {
            Curry._1(reduce, "initialize");
          }
          
        }), [loading]);
  React.useEffect((function () {
          if (state.progress === 100.0 && getProgress(completedSteps, onboardingChoice === "manual" ? GettingStarted__Types.StepId.manualSteps : GettingStarted__Types.StepId.liveDataSteps) !== 100.0) {
            Curry._1(reduce, "animateCompleteOut");
          } else if (previousInitialized && state.initialized) {
            Curry._1(reduce, "calculateProgress");
          }
          
        }), [completedSteps]);
  return React.createElement(React.Fragment, undefined, state.showConfetti ? React.createElement(Confetti.make, {
                    numberOfPieces: 600,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    colors: Belt_Array.map([
                          Styles.Color.lightPink,
                          Styles.Color.palePink,
                          Styles.Color.pink,
                          Styles.Color.avoPink,
                          Styles.Color.avoPinkShade2
                        ], Styles.Color.toString),
                    run: state.showConfetti,
                    recycle: false,
                    onConfettiComplete: (function (param) {
                        Curry._1(reduce, {
                              NAME: "showConfetti",
                              VAL: false
                            });
                      })
                  }) : null, !match.dismissed && !loading && state.initialized && hasGettingStarted ? React.createElement(GettingStarted__SidebarProgress$CollapsingContainer, {
                    collapsed: state.completeState === "complete",
                    onAnimationEnd: (function (param) {
                        var match = state.completeState;
                        if (match === "complete") {
                          if (!state.completionModalActive) {
                            Curry._1(reduce, {
                                  NAME: "showCompletionModal",
                                  VAL: true
                                });
                            setTimeout((function (param) {
                                    Curry._1(reduce, {
                                          NAME: "showConfetti",
                                          VAL: true
                                        });
                                    Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "ComposableModal",
                                            VAL: [
                                              {
                                                NAME: "string",
                                                VAL: "Checklist complete!"
                                              },
                                              {
                                                NAME: "component",
                                                VAL: React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                                          children: "Congratulations, you've completed every step in your onboarding checklist! You’re now ready to continue the journey to better data on your own."
                                                        }), React.createElement($$Text.make, {
                                                          children: "If you get stuck, you know where to find us!"
                                                        }))
                                              },
                                              [{
                                                  NAME: "primary",
                                                  VAL: {
                                                    label: "Let’s go!",
                                                    onClick: (function (closeModal) {
                                                        Curry._1(reduce, {
                                                              NAME: "showCompletionModal",
                                                              VAL: false
                                                            });
                                                        Curry._1(closeModal, undefined);
                                                      })
                                                  }
                                                }],
                                              undefined
                                            ]
                                          }
                                        });
                                  }), 750);
                            return ;
                          } else {
                            return ;
                          }
                        } else if (match === "animateOut") {
                          setTimeout((function (param) {
                                  Curry._1(reduce, "resetProgress");
                                }), 250);
                          return ;
                        } else {
                          return ;
                        }
                      }),
                    children: null
                  }, complete ? null : React.createElement(GettingStarted__Progress.make, {}), React.createElement("button", {
                        className: button(state.animating),
                        onClick: (function (param) {
                            Router.Schema.pushSchemaRoute(undefined, undefined, "gettingStarted");
                          }),
                        onAnimationEnd: (function (param) {
                            Curry._1(reduce, "removeAnimation");
                          })
                      }, React.createElement("div", {
                            className: dismiss,
                            onClick: (function (param) {
                                return onDismiss(currentStep, param);
                              })
                          }, React.createElement(IconX.make, {
                                size: 12
                              })), React.createElement($$Text.make, {
                            weight: "Semi",
                            children: "Getting started checklist"
                          }), React.createElement(Spacer.make, {
                            height: 12
                          }), React.createElement(GettingStarted__SidebarProgress$ProgressBar, {
                            progress: state.progress
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement($$Text.make, {
                            color: Styles.Color.white,
                            children: "" + String(Js_math.floor(state.progress)) + "% complete"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement(FramerMotion.motion.div, {
                            animate: {
                              opacity: 1
                            },
                            initial: {
                              opacity: 0
                            },
                            exit: {
                              opacity: 0
                            },
                            className: instructions,
                            key: instructionsText,
                            children: React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: StringExt.widowless(instructionsText)
                                }),
                            layout: true
                          })), React.createElement(GettingStarted__SidebarProgress$Congratulations, {
                        complete: state.completeState === "complete",
                        onDismiss: onDismiss,
                        currentStep: currentStep
                      })) : null);
}

var make = GettingStarted__SidebarProgress;

export {
  Style ,
  getProgress ,
  Congratulations ,
  CollapsingContainer ,
  ProgressBar ,
  useProgressReducer ,
  make ,
}
/* dismiss Not a pure module */
