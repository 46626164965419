// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as SsoUtils from "./SsoUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import LodashDebounce from "lodash.debounce";

function getSsoSchemasForDomainFromFirestore(domain) {
  return Firebase.app(undefined).firestore().collection("ssoSchemaIdByDomain").doc(domain).get().then(function (snapshot) {
              return Promise.resolve(snapshot.exists ? Caml_option.some(snapshot.data()) : undefined);
            });
}

function getSsoProviderForSchemaFromFirestore(schemaId) {
  return Firebase.app(undefined).firestore().collection("authProviderBySchemaId").doc(schemaId).get().then(function (snapshot) {
              return Promise.resolve(snapshot.exists ? Caml_option.some(snapshot.data()) : undefined);
            });
}

function getSsoProviderForDomainFromFirestore(domain) {
  return Firebase.app(undefined).firestore().collection("ssoSchemaIdByDomain").doc(domain).get().then(function (snapshot) {
                return Promise.resolve(snapshot.exists ? Caml_option.some(snapshot.data()) : undefined);
              }).then(function (domain) {
              if (domain !== undefined) {
                return Belt_Option.mapWithDefault(Belt_Array.get(Caml_option.valFromOption(domain).schemaIds, 0), Promise.resolve(undefined), getSsoProviderForSchemaFromFirestore);
              } else {
                return Promise.resolve(undefined);
              }
            });
}

function ssoEmailCheck(email) {
  var domain = SsoUtils.getEmailDomain(email);
  if (domain !== undefined) {
    return $$Promise.$$catch(getSsoProviderForDomainFromFirestore(domain).then(function (ssoProvider) {
                    return Promise.resolve(SsoUtils.parseSsoProvider(ssoProvider));
                  }), (function (error) {
                  console.log(error);
                  return Promise.resolve(/* NotSso */0);
                }));
  } else {
    return Promise.resolve(/* NotSso */0);
  }
}

function ssoSchemaCheck(schemaId) {
  return $$Promise.$$catch(getSsoProviderForSchemaFromFirestore(schemaId).then(function (ssoProvider) {
                  return Promise.resolve(SsoUtils.parseSsoProvider(ssoProvider));
                }), (function (error) {
                console.log(error);
                return Promise.resolve(/* NotSso */0);
              }));
}

var debouncedSsoEmailCheck = LodashDebounce((function (email, callback) {
        ssoEmailCheck(email).then(function (ssoMode) {
              return Promise.resolve(callback(ssoMode));
            });
      }), 200, {
      leading: false,
      trailing: true
    });

function checkIsCurrentProviderAllowed(user, schemaId) {
  return ssoSchemaCheck(schemaId).then(function (ssoMode) {
              if (typeof ssoMode === "number") {
                if (ssoMode !== 0) {
                  return SsoUtils.checkUserAuthProviderToMatch(user, SsoUtils.googleAuthMethodLowercaseString);
                } else {
                  return Promise.resolve(/* ProviderAllowed */0);
                }
              } else {
                return SsoUtils.checkUserAuthProviderToMatch(user, ssoMode._0);
              }
            });
}

export {
  getSsoSchemasForDomainFromFirestore ,
  getSsoProviderForSchemaFromFirestore ,
  getSsoProviderForDomainFromFirestore ,
  ssoEmailCheck ,
  ssoSchemaCheck ,
  debouncedSsoEmailCheck ,
  checkIsCurrentProviderAllowed ,
}
/* debouncedSsoEmailCheck Not a pure module */
