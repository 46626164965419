// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as InspectorVersionValidationIntentHelpers from "./InspectorVersionValidationIntentHelpers.mjs";

function BranchActionBox__MergeBranchModalBody(Props) {
  var breakingChanges = Props.breakingChanges;
  var message = Props.message;
  var hasSharingLink = Props.hasSharingLink;
  var setShouldDeleteSharingLink = Props.setShouldDeleteSharingLink;
  var toggleText = Props.toggleText;
  var toggleMessage = Props.toggleMessage;
  var validationIntent = Props.validationIntent;
  var match = Hooks.useDisclosure(false);
  var actions = match[1];
  var value = match[0];
  var onToggle = function (param) {
    Curry._1(setShouldDeleteSharingLink, !value);
    Curry._1(actions.toggle, undefined);
  };
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var tmp;
  if (isGlobalWorkspace) {
    tmp = React.createElement($$Text.make, {
          color: Styles.Color.light10,
          children: null
        }, "When this branch is merged, the changes will be reflected on the main branch of the global tracking plan.", React.createElement(Spacer.make, {
              height: 16
            }), "Changes impacting each workspace will be pushed as branches ready for review and implementation.");
  } else {
    var tmp$1;
    if (Belt_MapString.isEmpty(breakingChanges.breakingEventIdsBySource)) {
      tmp$1 = null;
    } else {
      var tmp$2;
      if (InspectorVersionValidationIntentHelpers.noSourcesHaveIntent(Belt_SetString.fromArray(breakingChanges.breakingSources), validationIntent)) {
        tmp$2 = React.createElement(React.Fragment, undefined, "Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, cancel merging and configure Inspector to temporarily ignore issues for ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: "" + AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source") + ""
                }), ".");
      } else if (InspectorVersionValidationIntentHelpers.allSourcesHaveIntent(Belt_SetString.fromArray(breakingChanges.breakingSources), validationIntent)) {
        tmp$2 = React.createElement(React.Fragment, undefined, "For those changes, Inspector has been configured to temporarily ignore issues for ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: "" + AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source") + ""
                }), ".");
      } else {
        var match$1 = InspectorVersionValidationIntentHelpers.getValidationReport(breakingChanges, validationIntent);
        tmp$2 = React.createElement(React.Fragment, undefined, "For those changes, Inspector has been configured to temporarily ignore issues for\n                          " + AvoUtils.plural(undefined, undefined, match$1.ignoredEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$1.ignoredSources.length, "source") + " on this branch but ", React.createElement($$Text.make, {
                  element: "Span",
                  weight: "Semi",
                  children: null
                }, "" + AvoUtils.plural(undefined, undefined, match$1.passingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$1.passingSources.length, "source") + "", " remain to be configured."));
      }
      tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, React.createElement(Spacer.make, {
                height: 16
              }));
    }
    tmp = React.createElement($$Text.make, {
          color: Styles.Color.light10,
          children: null
        }, "When this branch is merged, the changes made on this branch will be reflected in the main branch.", React.createElement(Spacer.make, {
              height: 16
            }), tmp$1);
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement($$Text.make, {
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: message
                }), hasSharingLink ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement($$Text.make, {
                        size: "Small",
                        color: Styles.Color.light10,
                        children: null
                      }, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("inlineFlex"),
                                  tl: {
                                    hd: Css.transform({
                                          NAME: "translateY",
                                          VAL: Css.px(3)
                                        }),
                                    tl: {
                                      hd: Css.paddingRight(Css.px(4)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Icon.make, {
                                type_: "information",
                                color: Styles.Color.light09
                              })), toggleMessage), React.createElement(Spacer.make, {
                        height: 4
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement("input", {
                            id: "delete-public-link",
                            name: "delete-public-link",
                            type: "checkbox",
                            onClick: onToggle
                          }), React.createElement("label", {
                            htmlFor: "delete-public-link"
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: toggleText
                              })))) : null);
}

var make = BranchActionBox__MergeBranchModalBody;

export {
  make ,
}
/* Css Not a pure module */
