// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainMembers from "../../../model/src/domains/DomainMembers.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as CollaboratorsView from "../CollaboratorsView.mjs";
import * as ManageMembersModal from "./ManageMembersModal.mjs";
import * as DomainDetails__Components from "./DomainDetails__Components.mjs";

function DomainDetails__Members(Props) {
  var domain = Props.domain;
  var role = WorkspaceContext.useMemberRole(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var domainMembers = DomainStore.useDomainMembers(domain.id, undefined);
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var match = Hooks.useDisclosure(false);
  var manageMembersModalActions = match[1];
  var viewerIsDomainMember = Curry._2(Domains.some, memberDomains, (function (param) {
          return param.id === domain.id;
        }));
  var tmp;
  if (Curry._1(DomainMembers.size, domainMembers) > 0) {
    var membersToDisplay = DomainMembers.getUserIds(Curry._2(DomainMembers.take, domainMembers, 6));
    tmp = React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
          ids: membersToDisplay,
          notDisplayedCount: Curry._1(DomainMembers.size, domainMembers) - membersToDisplay.length | 0
        });
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(DomainDetails__Components.Card.make, {
                  children: React.createElement(DomainDetails__Components.CardSection.make, {
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("grid"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.gridGap(Css.px(8)),
                                        tl: {
                                          hd: Css.gridTemplateColumns({
                                                hd: {
                                                  NAME: "fr",
                                                  VAL: 1.0
                                                },
                                                tl: {
                                                  hd: "maxContent",
                                                  tl: {
                                                    hd: "maxContent",
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  children: null
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      weight: "Semi",
                                      children: "Domain team members "
                                    }), "(" + String(Curry._1(DomainMembers.size, domainMembers)) + ")"), tmp, React.createElement(Button.make, {
                                  label: role === "Admin" || viewerIsDomainMember ? "Manage" : "View members",
                                  onClick: (function (param) {
                                      Curry._1(manageMembersModalActions.open_, undefined);
                                    }),
                                  size: "small",
                                  style: "ghost"
                                }))
                      })
                }), React.createElement(ManageMembersModal.make, {
                  domain: domain,
                  opened: match[0],
                  onClose: (function (param) {
                      Curry._1(manageMembersModalActions.close, undefined);
                    }),
                  viewerIsDomainMember: viewerIsDomainMember
                }));
}

var make = DomainDetails__Members;

export {
  make ,
}
/* Css Not a pure module */
