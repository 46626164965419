// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Portal from "./Portal.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as AlertModal from "./AlertModal.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as ConfirmModal from "./ConfirmModal.mjs";
import * as GenericModal from "./GenericModal.mjs";
import * as NewUserModal from "./NewUserModal.mjs";
import * as ProfileModal from "./ProfileModal.mjs";
import * as NewEventModal from "./NewEventModal.mjs";
import * as SettingsModal from "./SettingsModal.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NewBranchModal from "./NewBranchModal.mjs";
import * as ComposableModal from "./ComposableModal.mjs";
import * as AccessTokenModal from "./AccessTokenModal.mjs";
import * as NewPropertyModal from "./NewPropertyModal.mjs";
import * as SourceSetupModal from "./SourceSetupModal.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as AddExtraSeatModal from "./AddExtraSeatModal.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ImportReviewModal from "./ImportReviewModal.mjs";
import * as LockedBranchModal from "./LockedBranchModal.mjs";
import * as NewGroupTypeModal from "./NewGroupTypeModal.mjs";
import * as SourceEventsModal from "./SourceEventsModal.mjs";
import * as BillingPromptModal from "./BillingPromptModal.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as ImportSuccessModal from "./import/ImportSuccessModal.mjs";
import * as MasterEditingModal from "./MasterEditingModal.mjs";
import * as WebhookSecretModal from "./WebhookSecretModal.mjs";
import * as CreateCategoryModal from "./CreateCategoryModal.mjs";
import * as SandboxWelcomeModal from "./SandboxWelcomeModal.mjs";
import * as MergePropertiesModal from "./MergePropertiesModal.mjs";
import * as SettingsDestinations from "./SettingsDestinations.mjs";
import * as AddPropertyValueModal from "./AddPropertyValueModal.mjs";
import * as NewPropertyGroupModal from "./NewPropertyGroupModal.mjs";
import * as PinPropertyValueModal from "./PinPropertyValueModal.mjs";
import * as CreateIntegrationModal from "./CreateIntegrationModal.mjs";
import * as SandboxLimitationModal from "./SandboxLimitationModal.mjs";
import * as AddRegexValidationModal from "./AddRegexValidationModal.mjs";
import * as CreateEventVariantModal from "./CreateEventVariantModal.mjs";
import * as ServiceAccountSecretModal from "./ServiceAccountSecretModal.mjs";
import * as PinNestedPropertyValueModal from "./PinNestedPropertyValueModal.mjs";
import * as ConnectionsSourceCreateModal from "./ConnectionsSourceCreateModal.mjs";
import * as InspectorIssueAlertsSetupModal from "./InspectorIssueAlertsSetupModal.mjs";
import * as InspectorIssuesSourceBreakdown from "./InspectorIssuesSourceBreakdown.mjs";
import * as EnableDestinationForSourceModal from "./EnableDestinationForSourceModal.mjs";
import * as InspectorVersionValidationModal from "./InspectorVersionValidationModal.mjs";
import * as EditEventSpecificPropertyValuesModal from "./EditEventSpecificPropertyValuesModal.mjs";

function backdropStyles(isDismissable) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.top(Css.px(0)),
                tl: {
                  hd: Css.right(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.1)),
                        tl: {
                          hd: Css.cursor(isDismissable ? "pointer" : "default"),
                          tl: {
                            hd: Css.zIndex(Styles.ZIndex.aboveAll),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function containerStyles(alignment) {
  return Curry._1(Css.style, Belt_List.concatMany([
                  {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.top(Css.px(0)),
                      tl: {
                        hd: Css.right(Css.px(0)),
                        tl: {
                          hd: Css.bottom(Css.px(0)),
                          tl: {
                            hd: Css.left(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  },
                  alignment ? ({
                        hd: Css.paddingTop(Css.vh(10)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: /* [] */0
                          }
                        }
                      }) : ({
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                ]));
}

function isDismissableFromOutside(modals) {
  return Belt_Option.mapWithDefault(Belt_List.head(Belt_List.reverse(modals)), true, (function (modal) {
                var match = modal.modal;
                if (typeof match !== "object") {
                  return match !== "NewUser";
                }
                var variant = match.NAME;
                return !(variant === "InspectorVersionValidation" || variant === "NewEvent" || variant === "ConfirmModal" || variant === "AddRegexValidation" || variant === "ImportReview");
              }));
}

function ModalsContainer(Props) {
  var currentBranch = Props.currentBranch;
  var events = Props.events;
  var masterModel = Props.masterModel;
  var model = Props.model;
  var role = Props.role;
  var globalSend = GlobalSendContext.use(undefined);
  var match = GlobalStateContext.use(undefined);
  var modals = match.modals;
  var globalStateFilters = match.filters;
  var currentFilters = AnalyticsUtils.currentFilters(model, globalStateFilters, match.eventSort);
  var filters = Belt_List.fromArray(Belt_Array.keepMapU(globalStateFilters, StateFilterUtils.toModel));
  var onClose = React.useCallback((function (modalId) {
          return function (param) {
            return Curry._1(globalSend, {
                        TAG: /* CloseModal */5,
                        _0: modalId
                      });
          };
        }), [globalSend]);
  var backdropRef = React.useRef(null);
  var containerRef = React.useRef(null);
  return React.createElement(FramerMotion.AnimatePresence, {
              children: React.createElement(Portal.make, {
                    children: Belt_List.toArray(Belt_List.map(Belt_List.reverse(modals), (function (modal) {
                                var match = modal.modal;
                                var match$1 = modal.modal;
                                var tmp;
                                if (typeof match$1 === "object") {
                                  var variant = match$1.NAME;
                                  if (variant === "Profile") {
                                    tmp = React.createElement(ProfileModal.make, {
                                          userId: match$1.VAL
                                        });
                                  } else if (variant === "PinPropertyValue") {
                                    var match$2 = match$1.VAL;
                                    tmp = React.createElement(PinPropertyValueModal.make, {
                                          model: model,
                                          eventId: match$2[0],
                                          propertyId: match$2[1],
                                          onClose: Curry._1(onClose, modal.id),
                                          maybeVariantId: match$2[2]
                                        });
                                  } else if (variant === "ImportReview") {
                                    var match$3 = match$1.VAL;
                                    tmp = React.createElement(ImportReviewModal.make, {
                                          currentBranch: currentBranch,
                                          importModel: match$3[0],
                                          importStartedLocation: match$3[2],
                                          model: model,
                                          onClose: Curry._1(onClose, modal.id),
                                          openBranches: masterModel.openBranches,
                                          viewerRole: role,
                                          importMethod: match$3[1]
                                        });
                                  } else if (variant === "IssueSourcesBreakdown") {
                                    tmp = React.createElement(ComposableModal.make, {
                                          children: null,
                                          withCloseButton: true,
                                          paddingTop: 32,
                                          width: 700,
                                          onClose: Curry._1(onClose, modal.id)
                                        }, React.createElement(ComposableModal.Title.make, {
                                              children: {
                                                NAME: "component",
                                                VAL: React.createElement($$Text.make, {
                                                      size: "Large",
                                                      weight: "Semi",
                                                      children: "Releases seen in the past 24 hours"
                                                    })
                                              }
                                            }), React.createElement(Spacer.make, {
                                              height: 16
                                            }), React.createElement(ComposableModal.Body.make, {
                                              children: {
                                                NAME: "component",
                                                VAL: React.createElement(InspectorIssuesSourceBreakdown.make, {
                                                      report: match$1.VAL
                                                    })
                                              }
                                            }));
                                  } else if (variant === "AddExtraSeat") {
                                    var match$4 = match$1.VAL;
                                    tmp = React.createElement(AddExtraSeatModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          submitInvite: match$4.submitInvite,
                                          email: match$4.email,
                                          plan: match$4.plan,
                                          extraAdminEditorSeatCost: match$4.extraAdminEditorSeatCost,
                                          existingAdminEditorExtraSeats: match$4.existingAdminEditorExtraSeats,
                                          extraCommenterSeatCost: match$4.extraCommenterSeatCost,
                                          existingCommenterExtraSeats: match$4.existingCommenterExtraSeats,
                                          role: match$4.role
                                        });
                                  } else if (variant === "MergeProperties") {
                                    tmp = React.createElement(MergePropertiesModal.make, {
                                          propertyId: match$1.VAL,
                                          onClose: Curry._1(onClose, modal.id),
                                          currentFilters: currentFilters,
                                          filters: filters
                                        });
                                  } else if (variant === "SourceEvents") {
                                    tmp = React.createElement(SourceEventsModal.make, {
                                          sourceId: match$1.VAL,
                                          model: model,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "Settings") {
                                    var tmp$1 = {
                                      onClose: Curry._1(onClose, modal.id),
                                      role: role,
                                      model: model,
                                      openBranches: masterModel.openBranches
                                    };
                                    if (match$1.VAL !== undefined) {
                                      tmp$1.openOnTab = Caml_option.valFromOption(match$1.VAL);
                                    }
                                    tmp = React.createElement(SettingsModal.make, tmp$1);
                                  } else if (variant === "EditEventSpecificPropertyValues") {
                                    var match$5 = match$1.VAL;
                                    tmp = React.createElement(EditEventSpecificPropertyValuesModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          eventId: match$5[0],
                                          propertyId: match$5[1],
                                          maybeVariantId: match$5[2]
                                        });
                                  } else if (variant === "AlertModal") {
                                    var match$6 = match$1.VAL;
                                    tmp = React.createElement(AlertModal.make, {
                                          title: match$6[0],
                                          message: match$6[1],
                                          ctaText: match$6[2],
                                          onConfirm: match$6[3],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "IssueAlertsSetup") {
                                    tmp = React.createElement(InspectorIssueAlertsSetupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          savedView: match$1.VAL
                                        });
                                  } else if (variant === "AddRegexValidation") {
                                    var match$7 = match$1.VAL;
                                    var maybeVariant = match$7[2];
                                    var maybeEvent = match$7[1];
                                    var propertyId = match$7[0];
                                    var regexTarget = maybeEvent !== undefined ? (
                                        maybeVariant !== undefined ? ({
                                              TAG: /* EventVariant */2,
                                              _0: propertyId,
                                              _1: maybeEvent,
                                              _2: maybeVariant
                                            }) : ({
                                              TAG: /* Event */1,
                                              _0: propertyId,
                                              _1: maybeEvent
                                            })
                                      ) : ({
                                          TAG: /* Property */0,
                                          _0: propertyId
                                        });
                                    tmp = React.createElement(AddRegexValidationModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          regexTarget: regexTarget
                                        });
                                  } else if (variant === "ConfirmModal") {
                                    var match$8 = match$1.VAL;
                                    tmp = React.createElement(ConfirmModal.make, {
                                          title: match$8[0],
                                          message: match$8[1],
                                          ctaText: match$8[2],
                                          onConfirm: match$8[3],
                                          onCancel: match$8[4],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "NewBranch") {
                                    var match$9 = match$1.VAL;
                                    var tmp$2 = {
                                      openBranches: masterModel.openBranches,
                                      intialName: match$9[1]
                                    };
                                    var tmp$3 = match$9[0];
                                    if (tmp$3 !== undefined) {
                                      tmp$2.onCreate = Caml_option.valFromOption(tmp$3);
                                    }
                                    tmp = React.createElement(NewBranchModal.make, tmp$2);
                                  } else if (variant === "NewEvent") {
                                    var match$10 = match$1.VAL;
                                    var tmp$4 = {
                                      currentFilters: currentFilters,
                                      events: events,
                                      model: model,
                                      name: match$10[0],
                                      onClose: Curry._1(onClose, modal.id),
                                      createMoreToggled: match$10[5],
                                      eventOrigin: match$10[4],
                                      role: role
                                    };
                                    var tmp$5 = match$10[1];
                                    if (tmp$5 !== undefined) {
                                      tmp$4.onCreate = Caml_option.valFromOption(tmp$5);
                                    }
                                    var tmp$6 = match$10[2];
                                    if (tmp$6 !== undefined) {
                                      tmp$4.onSuccess = Caml_option.valFromOption(tmp$6);
                                    }
                                    var tmp$7 = match$10[3];
                                    if (tmp$7 !== undefined) {
                                      tmp$4.extraCreateActions = Caml_option.valFromOption(tmp$7);
                                    }
                                    tmp = React.createElement(NewEventModal.make, tmp$4);
                                  } else if (variant === "SetAccessToken") {
                                    var match$11 = match$1.VAL;
                                    tmp = React.createElement(AccessTokenModal.make, {
                                          model: model,
                                          integrationId: match$11[0],
                                          onClose: Curry._1(onClose, modal.id),
                                          onEncryptedToken: match$11[1]
                                        });
                                  } else if (variant === "ComposableModal") {
                                    var match$12 = match$1.VAL;
                                    var tmp$8 = {
                                      children: null,
                                      onClose: Curry._1(onClose, modal.id)
                                    };
                                    var tmp$9 = match$12[3];
                                    if (tmp$9 !== undefined) {
                                      tmp$8.width = Caml_option.valFromOption(tmp$9);
                                    }
                                    tmp = React.createElement(ComposableModal.make, tmp$8, React.createElement(ComposableModal.Title.make, {
                                              children: match$12[0]
                                            }), React.createElement(ComposableModal.Body.make, {
                                              children: match$12[1]
                                            }), React.createElement(ComposableModal.Buttons.make, {
                                              children: match$12[2]
                                            }));
                                  } else if (variant === "UpdateWebhookSecret") {
                                    var match$13 = match$1.VAL;
                                    tmp = React.createElement(WebhookSecretModal.make, {
                                          integrationId: match$13[0],
                                          integrationConfig: match$13[1],
                                          onClose: Curry._1(onClose, modal.id),
                                          onEncryptedToken: match$13[2],
                                          hasSecret: match$13[3],
                                          userIsEditor: role === "Admin" || role === "Editor"
                                        });
                                  } else if (variant === "ServiceAccountSecretCreated") {
                                    tmp = React.createElement(ServiceAccountSecretModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          serviceAccount: match$1.VAL
                                        });
                                  } else if (variant === "LockedBranch") {
                                    tmp = React.createElement(LockedBranchModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          branchStatus: match$1.VAL
                                        });
                                  } else if (variant === "ImportSuccess") {
                                    var match$14 = match$1.VAL;
                                    tmp = React.createElement(ImportSuccessModal.make, {
                                          eventCount: match$14.eventCount,
                                          propertyCount: match$14.propertyCount,
                                          sourceCount: match$14.sourceCount,
                                          newStakeholderDomainCount: match$14.newStakeholderDomainCount,
                                          resolvedIssuesCount: match$14.resolvedIssuesCount,
                                          importOrigin: match$14.importOrigin,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "EnableDestinationForSource") {
                                    var match$15 = match$1.VAL;
                                    tmp = React.createElement(EnableDestinationForSourceModal.make, {
                                          sourceId: match$15[0],
                                          destinationId: match$15[1],
                                          model: model,
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "InspectorVersionValidationDetails") {
                                    var match$16 = match$1.VAL;
                                    tmp = React.createElement(InspectorVersionValidationModal.MergedDetails.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          eventIds: match$16[0],
                                          sourceValidation: match$16[1],
                                          branchModel: match$16[2]
                                        });
                                  } else if (variant === "SourceSetup") {
                                    var match$17 = match$1.VAL;
                                    tmp = React.createElement(SourceSetupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          model: model,
                                          tabAfterChoosing: match$17[0],
                                          fromSchemaRoute: match$17[1]
                                        });
                                  } else if (variant === "MasterEditing") {
                                    var match$18 = match$1.VAL;
                                    tmp = React.createElement(MasterEditingModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          onContinue: match$18[0],
                                          onGoToBranch: match$18[1],
                                          openBranches: masterModel.openBranches,
                                          role: role
                                        });
                                  } else if (variant === "AddPropertyValue") {
                                    tmp = React.createElement(AddPropertyValueModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          propertyId: match$1.VAL,
                                          viewerCanEdit: RoleUtils.canEdit(role)
                                        });
                                  } else if (variant === "InspectorVersionValidation") {
                                    tmp = React.createElement(InspectorVersionValidationModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          model: model,
                                          changes: match$1.VAL
                                        });
                                  } else if (variant === "CreateEventVariant") {
                                    tmp = React.createElement(CreateEventVariantModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          eventId: match$1.VAL
                                        });
                                  } else if (variant === "CreateSource") {
                                    tmp = React.createElement(ConnectionsSourceCreateModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          tabAfterCreate: match$1.VAL
                                        });
                                  } else if (variant === "NewPropertyGroup") {
                                    var match$19 = match$1.VAL;
                                    tmp = React.createElement(NewPropertyGroupModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          name: match$19[0],
                                          onCreate: match$19[1]
                                        });
                                  } else if (variant === "GenericModal") {
                                    tmp = React.createElement(GenericModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          children: match$1.VAL
                                        });
                                  } else if (variant === "CreateCategory") {
                                    var match$20 = match$1.VAL;
                                    var tmp$10 = {
                                      categories: model.goals,
                                      onClose: Curry._1(onClose, modal.id)
                                    };
                                    var tmp$11 = match$20[1];
                                    if (tmp$11 !== undefined) {
                                      tmp$10.actionsOnCreate = Caml_option.valFromOption(tmp$11);
                                    }
                                    var tmp$12 = match$20[0];
                                    if (tmp$12 !== undefined) {
                                      tmp$10.categoryName = Caml_option.valFromOption(tmp$12);
                                    }
                                    tmp = React.createElement(CreateCategoryModal.make, tmp$10);
                                  } else if (variant === "BillingPrompt") {
                                    tmp = React.createElement(BillingPromptModal.make, {
                                          onClose: Curry._1(onClose, modal.id),
                                          onCloseAll: (function (param) {
                                              Belt_List.forEach(modals, (function (modal) {
                                                      Curry._1(globalSend, {
                                                            TAG: /* CloseModal */5,
                                                            _0: modal.id
                                                          });
                                                    }));
                                            }),
                                          paywallName: match$1.VAL,
                                          viewerRole: role
                                        });
                                  } else if (variant === "PinNestedPropertyValue") {
                                    var match$21 = match$1.VAL;
                                    tmp = React.createElement(PinNestedPropertyValueModal.make, {
                                          model: model,
                                          propertyId: match$21[0],
                                          nestedPropertyId: match$21[1],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else if (variant === "NewProperty") {
                                    var match$22 = match$1.VAL;
                                    var tmp$13 = {
                                      currentFilters: currentFilters,
                                      filters: filters,
                                      model: model,
                                      name: match$22[0],
                                      onClose: Curry._1(onClose, modal.id),
                                      parent: match$22[2],
                                      propertyLocation: match$22[4],
                                      sendAs: match$22[1]
                                    };
                                    var tmp$14 = match$22[3];
                                    if (tmp$14 !== undefined) {
                                      tmp$13.onCreate = Caml_option.valFromOption(tmp$14);
                                    }
                                    tmp = React.createElement(NewPropertyModal.make, tmp$13);
                                  } else if (variant === "AreYouSureModal") {
                                    var match$23 = match$1.VAL;
                                    tmp = React.createElement(ConfirmModal.AreYouSure.make, {
                                          title: match$23[0],
                                          message: match$23[1],
                                          ctaText: match$23[2],
                                          onConfirm: match$23[3],
                                          onCancel: match$23[4],
                                          onClose: Curry._1(onClose, modal.id)
                                        });
                                  } else {
                                    var match$24 = match$1.VAL;
                                    var tmp$15 = {
                                      onClose: Curry._1(onClose, modal.id),
                                      currentBranch: currentBranch,
                                      model: model
                                    };
                                    var tmp$16 = match$24[1];
                                    if (tmp$16 !== undefined) {
                                      tmp$15.afterCreateCallback = Caml_option.valFromOption(tmp$16);
                                    }
                                    var tmp$17 = match$24[0];
                                    if (tmp$17 !== undefined) {
                                      tmp$15.groupTypeName = Caml_option.valFromOption(tmp$17);
                                    }
                                    tmp = React.createElement(NewGroupTypeModal.make, tmp$15);
                                  }
                                } else {
                                  tmp = match$1 === "SandboxWelcome" ? React.createElement(SandboxWelcomeModal.make, {
                                          onClose: Curry._1(onClose, modal.id)
                                        }) : (
                                      match$1 === "CreateDestination" ? React.createElement(SettingsDestinations.CreateDestinationModal.make, {
                                              onClose: Curry._1(onClose, modal.id)
                                            }) : (
                                          match$1 === "CreateIntegration" ? React.createElement(CreateIntegrationModal.make, {
                                                  onClose: Curry._1(onClose, modal.id),
                                                  integrations: model.integrations
                                                }) : (
                                              match$1 === "SandboxLimitation" ? React.createElement(SandboxLimitationModal.make, {
                                                      onClose: Curry._1(onClose, modal.id)
                                                    }) : React.createElement(NewUserModal.make, {
                                                      onClose: Curry._1(onClose, modal.id)
                                                    })
                                            )
                                        )
                                    );
                                }
                                return React.createElement(FramerMotion.motion.div, {
                                            animate: {
                                              opacity: 1
                                            },
                                            transition: {
                                              duration: 0.2
                                            },
                                            initial: {
                                              opacity: 0
                                            },
                                            exit: {
                                              opacity: 0
                                            },
                                            className: backdropStyles(isDismissableFromOutside(modals)),
                                            key: modal.id,
                                            onClick: (function ($$event) {
                                                var targetIsSelf = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(backdropRef.current), false, (function (ref) {
                                                        return Caml_obj.equal($$event.target, ref);
                                                      })) || Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(containerRef.current), false, (function (ref) {
                                                        return Caml_obj.equal($$event.target, ref);
                                                      }));
                                                if (isDismissableFromOutside(modals) && targetIsSelf) {
                                                  return Curry._2(onClose, modal.id, undefined);
                                                }
                                                
                                              }),
                                            children: null,
                                            ref: backdropRef
                                          }, React.createElement(KeyListener.make, {
                                                keyName: "Escape",
                                                onPress: (function (param) {
                                                    Belt_Option.forEach(Belt_List.head(Belt_List.reverse(modals)), (function (lastModal) {
                                                            if (lastModal.id === modal.id && isDismissableFromOutside(modals)) {
                                                              return Curry._2(onClose, modal.id, undefined);
                                                            }
                                                            
                                                          }));
                                                  })
                                              }), React.createElement(FramerMotion.motion.div, {
                                                animate: {
                                                  scale: 1.0
                                                },
                                                transition: {
                                                  duration: 0.2
                                                },
                                                initial: {
                                                  scale: 0.95
                                                },
                                                exit: {
                                                  scale: 0.95
                                                },
                                                className: containerStyles(typeof match === "object" && match.NAME === "Settings" ? /* Top */1 : /* Center */0),
                                                key: modal.id,
                                                children: React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.cursor("default"),
                                                            tl: /* [] */0
                                                          }),
                                                      onClick: (function (prim) {
                                                          prim.stopPropagation();
                                                        })
                                                    }, tmp),
                                                ref: containerRef
                                              }));
                              })))
                  })
            });
}

var make = ModalsContainer;

export {
  backdropStyles ,
  containerStyles ,
  isDismissableFromOutside ,
  make ,
}
/* Css Not a pure module */
