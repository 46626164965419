// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Shallow from "zustand/react/shallow";

function MakeStore(C) {
  var getState = function (store) {
    return Curry._1(store.getState, undefined);
  };
  var use = function (store, selector) {
    return store(selector);
  };
  var useShallow = function (store, selector) {
    return store(Shallow.useShallow(selector));
  };
  return {
          getState: getState,
          use: use,
          useShallow: useShallow
        };
}

export {
  MakeStore ,
}
/* zustand/react/shallow Not a pure module */
