// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "./Hover.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as Core from "@mantine/core";
import * as PropertyInput from "./PropertyInput.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowDownEast from "./IconArrowDownEast.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetRegexValidationUseCase from "./regex/GetRegexValidationUseCase.mjs";
import * as ModalConstraintEditorMatches from "./ModalConstraintEditorMatches.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function PropertyDetailsConstraints$NestedPropertyValidation(Props) {
  var propertyRefs = Props.propertyRefs;
  var model = Props.model;
  var role = Props.role;
  var property = Props.property;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var items = Belt_List.keepMap(propertyRefs, (function (propertyRef) {
          return Belt_Option.map(AvoModel.getPropertyById(model, propertyRef.id), (function (property) {
                        return [
                                propertyRef,
                                property
                              ];
                      }));
        }));
  var tmp;
  if (RoleUtils.canEdit(role)) {
    var match = property.sendAs;
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.width(Css.pct(100)),
                tl: /* [] */0
              })
        }, React.createElement(PropertyInput.make, {
              propertyType: match === 0 ? /* EventProperty */1 : property.sendAs,
              currentProperties: Belt_List.concat({
                    hd: {
                      TAG: /* Property */1,
                      _0: property
                    },
                    tl: /* [] */0
                  }, Belt_List.map(propertyRefs, (function (propRef) {
                          return {
                                  TAG: /* PropertyRef */0,
                                  _0: propRef
                                };
                        }))),
              currentGroups: [],
              options: [[
                  undefined,
                  Belt_Array.map(Belt_List.toArray(AvoModel.splitProperties(model.properties).props), (function (item) {
                          return {
                                  NAME: "Property",
                                  VAL: item
                                };
                        }))
                ]],
              onSelect: (function (item, param) {
                  if (item.NAME === "Group") {
                    return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                  } else {
                    return Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "AddPropertyValidation",
                                      VAL: [
                                        property.id,
                                        {
                                          NAME: "NestedProperty",
                                          VAL: {
                                            hd: {
                                              id: item.VAL.id,
                                              description: "",
                                              pinnedValue: undefined
                                            },
                                            tl: /* [] */0
                                          }
                                        }
                                      ]
                                    },
                                    {
                                      propertyId: property.id,
                                      propertyQuery: property.id
                                    }
                                  ]]
                              ]);
                  }
                }),
              canCreate: true,
              currentFilters: currentFilters,
              renderButton: (function (onClick, buttonRef, _isOpen) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("button", {
                                  ref: buttonRef,
                                  className: Curry._1(Css.merge, {
                                        hd: Curry._1(Css.style, Styles.button),
                                        tl: {
                                          hd: Curry._1(Css.style, {
                                                hd: Css.width(Css.px(200)),
                                                tl: {
                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.deepBlue),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: {
                                                          hd: Css.focus({
                                                                hd: Css.textDecoration("underline"),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: {
                                                            hd: Css.margin(Css.px(0)),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(15), Css.px(5)),
                                                              tl: {
                                                                hd: Css.textAlign("left"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }),
                                  onClick: onClick
                                }, "+ Add Property"));
                }),
              parent: {
                NAME: "Property",
                VAL: property.id
              },
              sendActions: sendActions,
              filters: filters,
              propertyLocation: "PropertyDetails"
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              })
                        }, "Object should contain:")), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(items, (function (param) {
                                var item = param[1];
                                var propertyRef = param[0];
                                var pinnedValue = propertyRef.pinnedValue;
                                var maybePinnedValue = pinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, item, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) ? pinnedValue : undefined;
                                var tmp;
                                if (RoleUtils.canEdit(role)) {
                                  var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                                  tmp = React.createElement(ContextMenu.make, {
                                        options: Belt_Array.concatMany([
                                              Belt_Array.concat([{
                                                      NAME: "Option",
                                                      VAL: {
                                                        label: (
                                                          maybePinnedValue !== undefined ? "Update Pinned Value" : "Pin Property Value"
                                                        ) + (
                                                          match === "Available" ? "" : " ⚡️"
                                                        ),
                                                        onClick: (function (param) {
                                                            var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                                                            Curry._1(globalSend, {
                                                                  TAG: /* OpenModal */4,
                                                                  _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                                                                        NAME: "PinNestedPropertyValue",
                                                                        VAL: [
                                                                          property.id,
                                                                          item.id
                                                                        ]
                                                                      }) : ({
                                                                        NAME: "BillingPrompt",
                                                                        VAL: "PinnedProperties"
                                                                      })
                                                                });
                                                          })
                                                      }
                                                    }], maybePinnedValue !== undefined ? [{
                                                        NAME: "Option",
                                                        VAL: {
                                                          label: "Remove Pinned Value",
                                                          onClick: (function (param) {
                                                              Curry.app(sendActions, [
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    undefined,
                                                                    [[
                                                                        {
                                                                          NAME: "RemoveNestedPropertyPinnedValue",
                                                                          VAL: [
                                                                            property.id,
                                                                            item.id
                                                                          ]
                                                                        },
                                                                        context
                                                                      ]]
                                                                  ]);
                                                            })
                                                        }
                                                      }] : []),
                                              [{
                                                  NAME: "Option",
                                                  VAL: {
                                                    label: "Remove Property",
                                                    onClick: (function (param) {
                                                        Curry.app(sendActions, [
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              undefined,
                                                              [[
                                                                  {
                                                                    NAME: "RemovePropertyValidation",
                                                                    VAL: [
                                                                      property.id,
                                                                      {
                                                                        NAME: "NestedProperty",
                                                                        VAL: {
                                                                          hd: {
                                                                            id: item.id,
                                                                            description: "",
                                                                            pinnedValue: undefined
                                                                          },
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    ]
                                                                  },
                                                                  context
                                                                ]]
                                                            ]);
                                                      })
                                                  }
                                                }]
                                            ])
                                      });
                                } else {
                                  tmp = null;
                                }
                                return React.createElement("div", {
                                            key: propertyRef.id + item.id,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: {
                                                      hd: Css.maxWidth(Css.pct(100)),
                                                      tl: {
                                                        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: {
                                                            hd: Css.justifyContent("spaceBetween"),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Hover.make, {
                                                className: {
                                                  hd: Css.width(Css.pct(90)),
                                                  tl: {
                                                    hd: Css.flexGrow(0.0),
                                                    tl: /* [] */0
                                                  }
                                                },
                                                children: (function (isHover) {
                                                    var tmp;
                                                    if (maybePinnedValue !== undefined) {
                                                      tmp = React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.padding2(Css.px(15), Css.px(10)),
                                                                  tl: {
                                                                    hd: Css.color(isHover ? Styles.Color.darkBlue : Styles.Color.light10),
                                                                    tl: {
                                                                      hd: Css.textOverflow("ellipsis"),
                                                                      tl: {
                                                                        hd: Css.whiteSpace("nowrap"),
                                                                        tl: {
                                                                          hd: Css.overflow("hidden"),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement($$Text.make, {
                                                                element: "Span",
                                                                size: "Small",
                                                                weight: "Regular",
                                                                children: "pinned to"
                                                              }), React.createElement(Spacer.make, {
                                                                display: "inlineBlock",
                                                                width: 4
                                                              }), React.createElement($$Text.make, {
                                                                element: "Span",
                                                                size: "Small",
                                                                weight: "Semi",
                                                                children: PropertyUtils.printPinnedValue(maybePinnedValue)
                                                              }), React.createElement(Spacer.make, {
                                                                display: "inlineBlock",
                                                                width: 4
                                                              }), React.createElement($$Text.make, {
                                                                element: "Span",
                                                                size: "Small",
                                                                weight: "Regular",
                                                                children: "on all events"
                                                              }));
                                                    } else {
                                                      var propertyType = PropertyUtils.printPropertyType(item, item.optionalWhenInObject, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined);
                                                      tmp = React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.padding2(Css.px(15), Css.px(10)),
                                                                  tl: {
                                                                    hd: Css.color(isHover ? Styles.Color.darkBlue : Styles.Color.light10),
                                                                    tl: {
                                                                      hd: Css.textOverflow("ellipsis"),
                                                                      tl: {
                                                                        hd: Css.whiteSpace("nowrap"),
                                                                        tl: {
                                                                          hd: Css.overflow("hidden"),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement($$Text.make, {
                                                                size: "Small",
                                                                weight: "Regular",
                                                                singleLine: true,
                                                                title: propertyType,
                                                                children: propertyType
                                                              }));
                                                    }
                                                    return React.createElement(Link.make, {
                                                                path: Router.Link.addDrawerItem(undefined, {
                                                                      NAME: "property",
                                                                      VAL: [
                                                                        item.id,
                                                                        undefined
                                                                      ]
                                                                    }),
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.flexGrow(0.0),
                                                                      tl: {
                                                                        hd: Css.width(Css.pct(100)),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }),
                                                                children: React.createElement("div", {
                                                                      className: Curry._1(Css.style, {
                                                                            hd: Css.display("flex"),
                                                                            tl: {
                                                                              hd: Css.alignItems("center"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          })
                                                                    }, React.createElement("span", {
                                                                          className: Curry._1(Css.style, {
                                                                                hd: Css.marginRight(Css.px(4)),
                                                                                tl: /* [] */0
                                                                              })
                                                                        }, React.createElement(IconArrowDownEast.make, {
                                                                              size: 12
                                                                            })), React.createElement("div", {
                                                                          className: Curry._1(Css.style, {
                                                                                hd: Css.display("flex"),
                                                                                tl: {
                                                                                  hd: Css.alignItems("center"),
                                                                                  tl: {
                                                                                    hd: Css.padding2(Css.px(15), Css.px(5)),
                                                                                    tl: {
                                                                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                      tl: {
                                                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                                                        tl: {
                                                                                          hd: Css.color(isHover ? Styles.Color.darkBlue : Styles.Color.light12),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              })
                                                                        }, React.createElement($$Text.make, {
                                                                              size: "Medium",
                                                                              weight: "Semi",
                                                                              children: item.name
                                                                            })), tmp)
                                                              });
                                                  })
                                              }), tmp);
                              }))))), tmp);
}

var NestedPropertyValidation = {
  make: PropertyDetailsConstraints$NestedPropertyValidation
};

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.marginLeft(Css.px(-29)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginBottom(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var headerIconStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(3)),
      tl: {
        hd: Css.marginRight(Css.px(5)),
        tl: /* [] */0
      }
    });

var regexStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.medium),
        tl: {
          hd: Css.fontSize(Styles.FontSize.medium),
          tl: {
            hd: Css.margin(Css.px(0)),
            tl: /* [] */0
          }
        }
      }
    });

var subHeaderStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingBottom(Css.px(8)),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: /* [] */0
          }
        }
      }
    });

var contextMenuRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.gap(Css.px(8)),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.marginTop(Css.px(8)),
              tl: {
                hd: Css.marginBottom(Css.px(8)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function PropertyDetailsConstraints$RegexValidation(Props) {
  var property = Props.property;
  var eventsSendingProperty = Props.eventsSendingProperty;
  var regexValidation = Props.regexValidation;
  var viewerCanEdit = Props.viewerCanEdit;
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var variantRegexOverrides = Belt_List.reduceU(eventsSendingProperty, [], (function (acc, $$event) {
          var variantsWithPropetyRegexOverride = Belt_Array.keepMap($$event.variants, (function (variant) {
                  var match = Belt_MapString.get(variant.propertyOverrides, property.id);
                  if (match === undefined) {
                    return ;
                  }
                  if (!match) {
                    return ;
                  }
                  var regexRule = match._0.regexValidation;
                  if (!regexRule) {
                    return ;
                  }
                  var regexRule$1 = regexRule._0;
                  return [
                          TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                          $$event.name + " - " + variant.nameSuffix,
                          regexRule$1 !== undefined ? regexRule$1.regex : "Default regex"
                        ];
                }));
          return Belt_Array.concat(acc, variantsWithPropetyRegexOverride);
        }));
  var handleRemoveRegexValidation = function (param) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              AnalyticsRe.regexInteraction(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Removed", property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch));
            }),
          undefined,
          [[
              {
                NAME: "UpdatePropertyRegexValidation",
                VAL: [
                  property.id,
                  undefined,
                  undefined
                ]
              },
              {
                propertyId: property.id,
                propertyQuery: property.id
              }
            ]]
        ]);
  };
  var match = regexValidation.propertyRule;
  var eventOverrides = Belt_MapString.toArray(regexValidation.eventOverrides);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: headerIconStyles
                    }, React.createElement(Icon.make, {
                          type_: "equalizer",
                          color: Styles.Color.light07
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Property Value Constraints"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: property.id,
                        itemName: property.name,
                        itemType: "Property"
                      },
                      title: "Property Value Constraints",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.propertyValueRules
                                })
                          })
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), viewerCanEdit ? React.createElement(Button.make, {
                        label: "Remove Regex Constraints",
                        onClick: handleRemoveRegexValidation,
                        style: "ghost"
                      }) : null), React.createElement("div", {
                  className: subHeaderStyles
                }, React.createElement($$Text.make, {
                      size: "Tiny",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "DEFAULT PROPERTY REGEX"
                    })), React.createElement(Spacer.make, {
                  height: 8
                }), match !== undefined ? React.createElement("div", {
                    className: contextMenuRow
                  }, React.createElement("div", undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Regular Expression"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement("p", {
                            className: regexStyles
                          }, match.regex), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Sample string"
                          }), React.createElement(Spacer.make, {
                            height: 4
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light12,
                            children: match.testValue
                          })), viewerCanEdit ? React.createElement(ContextMenu.make, {
                          options: [
                            {
                              NAME: "Option",
                              VAL: {
                                label: "Edit Default Property Regex",
                                onClick: (function (param) {
                                    Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "AddRegexValidation",
                                            VAL: [
                                              property.id,
                                              undefined,
                                              undefined
                                            ]
                                          }
                                        });
                                  })
                              }
                            },
                            {
                              NAME: "WarningOption",
                              VAL: {
                                label: "Remove Default Property Regex",
                                onClick: handleRemoveRegexValidation
                              }
                            }
                          ]
                        }) : null) : (
                viewerCanEdit ? React.createElement(Button.make, {
                        label: "Add Default Regex Validation",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "AddRegexValidation",
                                    VAL: [
                                      property.id,
                                      undefined,
                                      undefined
                                    ]
                                  }
                                });
                            AnalyticsRe.regexInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Initiated", property.id, schemaBundle.schemaId, schemaBundle.branchId);
                          }),
                        style: "outline"
                      }) : null
              ), eventOverrides.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: subHeaderStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "EVENT-SPECIFIC REGEX OVERRIDES"
                          })), Belt_Array.keepMapU(eventOverrides, (function (param) {
                          var eventId = param[0];
                          var $$event = Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                          if ($$event !== undefined) {
                            return Caml_option.some(React.createElement("div", {
                                            className: contextMenuRow
                                          }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                                    size: "Small",
                                                    color: Styles.Color.light11,
                                                    children: null
                                                  }, "Custom regex for ", React.createElement(TextButton.make, {
                                                        onClick: (function (param) {
                                                            Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                  NAME: "event",
                                                                  VAL: [
                                                                    $$event.id,
                                                                    undefined,
                                                                    undefined,
                                                                    false
                                                                  ]
                                                                });
                                                          }),
                                                        style: "Black",
                                                        size: "Small",
                                                        children: $$event.name
                                                      }), ": ", React.createElement($$Text.make, {
                                                        element: "Span",
                                                        size: "Small",
                                                        weight: "Semi",
                                                        color: Styles.Color.light11,
                                                        children: param[1].regex
                                                      }))), viewerCanEdit ? React.createElement(ContextMenu.make, {
                                                  options: [
                                                    {
                                                      NAME: "Option",
                                                      VAL: {
                                                        label: "Edit Regex Override",
                                                        onClick: (function (param) {
                                                            Curry._1(globalSend, {
                                                                  TAG: /* OpenModal */4,
                                                                  _0: {
                                                                    NAME: "AddRegexValidation",
                                                                    VAL: [
                                                                      property.id,
                                                                      $$event,
                                                                      undefined
                                                                    ]
                                                                  }
                                                                });
                                                          })
                                                      }
                                                    },
                                                    {
                                                      NAME: "WarningOption",
                                                      VAL: {
                                                        label: "Remove Regex Override",
                                                        onClick: (function (param) {
                                                            Curry.app(sendActions, [
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  undefined,
                                                                  (function (branch) {
                                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                      AnalyticsRe.regexInteractionEventSpecific(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Removed", property.id, eventId, schemaBundle$1.schemaId, NamedBranch.getId(branch));
                                                                    }),
                                                                  undefined,
                                                                  [[
                                                                      {
                                                                        NAME: "UpdatePropertyRegexValidation",
                                                                        VAL: [
                                                                          property.id,
                                                                          eventId,
                                                                          undefined
                                                                        ]
                                                                      },
                                                                      {
                                                                        propertyId: property.id,
                                                                        propertyQuery: property.id
                                                                      }
                                                                    ]]
                                                                ]);
                                                          })
                                                      }
                                                    }
                                                  ]
                                                }) : null));
                          }
                          
                        }))) : null, variantRegexOverrides.length !== 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: subHeaderStyles
                      }, React.createElement($$Text.make, {
                            size: "Tiny",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "VARIANT-SPECIFIC REGEX OVERRIDES"
                          })), Belt_Array.keepMapU(variantRegexOverrides, (function (param) {
                          var variantIdentifier = param[0];
                          return Caml_option.some(React.createElement("div", {
                                          className: contextMenuRow
                                        }, React.createElement("div", undefined, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.light11,
                                                  children: null
                                                }, "Custom regex for ", React.createElement(TextButton.make, {
                                                      onClick: (function (param) {
                                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                NAME: "eventVariant",
                                                                VAL: [
                                                                  variantIdentifier,
                                                                  undefined
                                                                ]
                                                              });
                                                        }),
                                                      style: "Black",
                                                      size: "Small",
                                                      children: param[1]
                                                    }), ": ", React.createElement($$Text.make, {
                                                      element: "Span",
                                                      size: "Small",
                                                      weight: "Semi",
                                                      color: Styles.Color.light11,
                                                      children: param[2]
                                                    })))));
                        }))) : null);
}

var RegexValidation = {
  headerStyles: headerStyles,
  headerIconStyles: headerIconStyles,
  regexStyles: regexStyles,
  subHeaderStyles: subHeaderStyles,
  contextMenuRow: contextMenuRow,
  make: PropertyDetailsConstraints$RegexValidation
};

var _map = {"AllowedValues":"AllowedValues","Matches":"Matches","Regex":"Regex","Min":"Min","Max":"Max","Shape":"Shape","NestedProperty":"NestedProperty"};

function constraintTypeToJs(param) {
  return param;
}

function constraintTypeFromJs(param) {
  return _map[param];
}

function valueValidations(item) {
  var match = item.value;
  return match.VAL.validations;
}

function mapValidationType(x) {
  if (typeof x !== "object") {
    return Pervasives.failwith("Will never happen.");
  }
  var variant = x.NAME;
  if (variant === "Max") {
    return "Max";
  } else if (variant === "Min") {
    return "Min";
  } else {
    return Pervasives.failwith("Will never happen.");
  }
}

function emptyShape(key) {
  return {
          NAME: "Shape",
          VAL: {
            hd: {
              key: key,
              list: false,
              optional: false,
              value: {
                NAME: "Value",
                VAL: {
                  type_: "String",
                  validations: /* [] */0
                }
              }
            },
            tl: /* [] */0
          }
        };
}

function shapeRemover(validations, removeItem) {
  return Belt_List.keepMap(validations, (function (x) {
                if (typeof x !== "object") {
                  return x;
                }
                if (x.NAME !== "Shape") {
                  return x;
                }
                var nextItems = Belt_List.keep(x.VAL, (function (item) {
                        return removeItem.key !== item.key;
                      }));
                if (Belt_List.length(nextItems) === 0) {
                  return ;
                } else {
                  return {
                          NAME: "Shape",
                          VAL: nextItems
                        };
                }
              }));
}

function shapeReducer(validation, item) {
  if (typeof validation !== "object") {
    return valueValidations(item);
  }
  if (validation.NAME !== "Shape") {
    return valueValidations(item);
  }
  var newItemTypes = validation.VAL;
  var maybeShape = Belt_List.getBy(valueValidations(item), (function (item) {
          if (typeof item === "object") {
            return item.NAME === "Shape";
          } else {
            return false;
          }
        }));
  if (maybeShape !== undefined) {
    return Belt_List.map(valueValidations(item), (function (item) {
                  if (typeof item !== "object") {
                    return item;
                  }
                  if (item.NAME !== "Shape") {
                    return item;
                  }
                  var currentItemTypes = item.VAL;
                  var addingItemTypes = Belt_List.keep(newItemTypes, (function (newItemType) {
                          return !Belt_List.some(currentItemTypes, (function (currentItemType) {
                                        return currentItemType.key === newItemType.key;
                                      }));
                        }));
                  var currentItemTypes$1 = Belt_List.map(currentItemTypes, (function (currentItemType) {
                          var updatedItemType = Belt_List.getBy(newItemTypes, (function (newItemType) {
                                  return currentItemType.key === newItemType.key;
                                }));
                          return Belt_Option.getWithDefault(updatedItemType, currentItemType);
                        }));
                  var nextItemTypes = Belt_List.concat(currentItemTypes$1, addingItemTypes);
                  return {
                          NAME: "Shape",
                          VAL: nextItemTypes
                        };
                }));
  } else {
    return Belt_List.concat(valueValidations(item), {
                hd: validation,
                tl: /* [] */0
              });
  }
}

function renderItem(parentItems, item, role, sendActions, property, context, hasLongValuesFeatureFlag) {
  var tmp;
  if (PropertyUtils.valueType(item) === "Object") {
    var itemTypes = Belt_List.head(Belt_List.keepMap(valueValidations(item), (function (x) {
                if (typeof x === "object" && x.NAME === "Shape") {
                  return x.VAL;
                }
                
              })));
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.padding4(Css.px(0), Css.px(2), Css.px(0), Css.px(20)),
                tl: /* [] */0
              })
        }, React.createElement("div", undefined, itemTypes !== undefined ? Belt_List.toArray(Belt_List.map(itemTypes, (function (subItem) {
                          return renderItem({
                                      hd: item,
                                      tl: parentItems
                                    }, subItem, role, sendActions, property, context, hasLongValuesFeatureFlag);
                        }))) : null), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(2)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(OpsWithState.make, {
                  value: "",
                  onFlush: (function (param) {
                      
                    }),
                  children: (function (value, onChange, param, param$1) {
                      var handleAddValue = function (param) {
                        if (value.length !== 0) {
                          Curry._1(onChange, "");
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "AddPropertyValidation",
                                      VAL: [
                                        property.id,
                                        Belt_List.reduce(parentItems, {
                                              NAME: "Shape",
                                              VAL: {
                                                hd: {
                                                  key: item.key,
                                                  list: item.list,
                                                  optional: item.optional,
                                                  value: {
                                                    NAME: "Value",
                                                    VAL: {
                                                      type_: "Object",
                                                      validations: shapeReducer(emptyShape(value), item)
                                                    }
                                                  }
                                                },
                                                tl: /* [] */0
                                              }
                                            }, (function (validations, parentItem) {
                                                return {
                                                        NAME: "Shape",
                                                        VAL: {
                                                          hd: {
                                                            key: parentItem.key,
                                                            list: parentItem.list,
                                                            optional: parentItem.optional,
                                                            value: {
                                                              NAME: "Value",
                                                              VAL: {
                                                                type_: PropertyUtils.valueType(parentItem),
                                                                validations: shapeReducer(validations, parentItem)
                                                              }
                                                            }
                                                          },
                                                          tl: /* [] */0
                                                        }
                                                      };
                                              }))
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                          return ;
                        }
                        
                      };
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.margin(Css.px(2)),
                                            tl: /* [] */0
                                          }
                                        }
                                      })
                                }, React.createElement("input", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.input),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.margin4(Css.px(5), Css.px(5), Css.px(5), Css.px(0)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.borderRadius(Styles.Border.radius),
                                                          tl: {
                                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      placeholder: "Add a key...",
                                      value: value,
                                      onKeyDown: (function ($$event) {
                                          var match = $$event.key;
                                          if (match === "Enter") {
                                            return handleAddValue(undefined);
                                          }
                                          
                                        }),
                                      onChange: (function ($$event) {
                                          Curry._1(onChange, $$event.target.value);
                                        })
                                    }), React.createElement("div", undefined, React.createElement(Button.make, {
                                          label: "Add",
                                          onClick: (function (param) {
                                              handleAddValue(undefined);
                                            })
                                        })));
                    })
                })));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              key: item.key
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(2)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.margin(Css.px(5)),
                            tl: /* [] */0
                          })
                    }, React.createElement(IconArrowDownEast.make, {
                          size: 12,
                          color: Styles.Color.light08
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          })
                    }, item.key), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(0), Css.px(15), Css.px(0), Css.px(15)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("select", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.select),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.fontSize(Css.px(12)),
                                        tl: {
                                          hd: Css.backgroundColor(Css.transparent),
                                          tl: {
                                            hd: Css.maxWidth(Css.px(100)),
                                            tl: {
                                              hd: Css.marginRight(Css.px(15)),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          disabled: !RoleUtils.canEdit(role),
                          value: PropertyUtils.printValueType(item),
                          onChange: (function (domEvent) {
                              var nextType = domEvent.target.value;
                              var type_ = TrackingPlanModel.Type.tFromJs(nextType);
                              if (type_ !== undefined) {
                                return Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddPropertyValidation",
                                                  VAL: [
                                                    property.id,
                                                    Belt_List.reduce(parentItems, {
                                                          NAME: "Shape",
                                                          VAL: {
                                                            hd: {
                                                              key: item.key,
                                                              list: item.list,
                                                              optional: item.optional,
                                                              value: {
                                                                NAME: "Value",
                                                                VAL: {
                                                                  type_: type_,
                                                                  validations: /* [] */0
                                                                }
                                                              }
                                                            },
                                                            tl: /* [] */0
                                                          }
                                                        }, (function (validations, parentItem) {
                                                            return {
                                                                    NAME: "Shape",
                                                                    VAL: {
                                                                      hd: {
                                                                        key: parentItem.key,
                                                                        list: parentItem.list,
                                                                        optional: parentItem.optional,
                                                                        value: {
                                                                          NAME: "Value",
                                                                          VAL: {
                                                                            type_: PropertyUtils.valueType(parentItem),
                                                                            validations: shapeReducer(validations, parentItem)
                                                                          }
                                                                        }
                                                                      },
                                                                      tl: /* [] */0
                                                                    }
                                                                  };
                                                          }))
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                              }
                              
                            })
                        }, React.createElement("option", {
                              key: "string",
                              value: "string"
                            }, "string"), React.createElement("option", {
                              key: "int",
                              value: "int"
                            }, "int"), hasLongValuesFeatureFlag ? React.createElement("option", {
                                key: "long",
                                value: "long"
                              }, "long") : null, React.createElement("option", {
                              key: "float",
                              value: "float"
                            }, "float"), React.createElement("option", {
                              key: "bool",
                              value: "bool"
                            }, "bool"), React.createElement("option", {
                              key: "object",
                              value: "object"
                            }, "object")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(15)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.light11),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("input", {
                              id: "optional-key-" + item.key + "",
                              checked: item.optional,
                              disabled: !RoleUtils.canEdit(role),
                              type: "checkbox",
                              onChange: (function ($$event) {
                                  var isOptional = $$event.target.checked;
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddPropertyValidation",
                                              VAL: [
                                                property.id,
                                                Belt_List.reduce(parentItems, {
                                                      NAME: "Shape",
                                                      VAL: {
                                                        hd: {
                                                          key: item.key,
                                                          list: item.list,
                                                          optional: isOptional,
                                                          value: item.value
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, (function (validations, parentItem) {
                                                        return {
                                                                NAME: "Shape",
                                                                VAL: {
                                                                  hd: {
                                                                    key: parentItem.key,
                                                                    list: parentItem.list,
                                                                    optional: parentItem.optional,
                                                                    value: {
                                                                      NAME: "Value",
                                                                      VAL: {
                                                                        type_: PropertyUtils.valueType(parentItem),
                                                                        validations: shapeReducer(validations, parentItem)
                                                                      }
                                                                    }
                                                                  },
                                                                  tl: /* [] */0
                                                                }
                                                              };
                                                      }))
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                })
                            }), React.createElement("label", {
                              htmlFor: "optional-key-" + item.key + ""
                            }, "Optional")), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginRight(Css.px(15)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.color(Styles.Color.light11),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement("input", {
                              id: "list-key-" + item.key + "",
                              checked: item.list,
                              type: "checkbox",
                              onChange: (function ($$event) {
                                  var isList = $$event.target.checked;
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        [[
                                            {
                                              NAME: "AddPropertyValidation",
                                              VAL: [
                                                property.id,
                                                Belt_List.reduce(parentItems, {
                                                      NAME: "Shape",
                                                      VAL: {
                                                        hd: {
                                                          key: item.key,
                                                          list: isList,
                                                          optional: item.optional,
                                                          value: item.value
                                                        },
                                                        tl: /* [] */0
                                                      }
                                                    }, (function (validations, parentItem) {
                                                        return {
                                                                NAME: "Shape",
                                                                VAL: {
                                                                  hd: {
                                                                    key: parentItem.key,
                                                                    list: parentItem.list,
                                                                    optional: parentItem.optional,
                                                                    value: {
                                                                      NAME: "Value",
                                                                      VAL: {
                                                                        type_: PropertyUtils.valueType(parentItem),
                                                                        validations: shapeReducer(validations, parentItem)
                                                                      }
                                                                    }
                                                                  },
                                                                  tl: /* [] */0
                                                                }
                                                              };
                                                      }))
                                              ]
                                            },
                                            context
                                          ]]
                                      ]);
                                })
                            }), React.createElement("label", {
                              htmlFor: "list-key-" + item.key + ""
                            }, "List")), React.createElement("button", {
                          className: Curry._1(Css.merge, {
                                hd: Curry._1(Css.style, Styles.button),
                                tl: {
                                  hd: Curry._1(Css.style, {
                                        hd: Css.padding2(Css.px(5), Css.px(5)),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(5)),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.tiny),
                                              tl: {
                                                hd: Css.color(Styles.Color.light10),
                                                tl: {
                                                  hd: Css.hover({
                                                        hd: Css.color(Styles.Color.red),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              if (Belt_List.length(parentItems) === 0) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "RemovePropertyValidation",
                                            VAL: [
                                              property.id,
                                              {
                                                NAME: "Shape",
                                                VAL: {
                                                  hd: item,
                                                  tl: /* [] */0
                                                }
                                              }
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              } else {
                                var parentItem = Belt_List.headExn(parentItems);
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "AddPropertyValidation",
                                            VAL: [
                                              property.id,
                                              Belt_List.reduce(Belt_List.tailExn(parentItems), {
                                                    NAME: "Shape",
                                                    VAL: {
                                                      hd: {
                                                        key: parentItem.key,
                                                        list: parentItem.list,
                                                        optional: parentItem.optional,
                                                        value: {
                                                          NAME: "Value",
                                                          VAL: {
                                                            type_: PropertyUtils.valueType(parentItem),
                                                            validations: shapeRemover(valueValidations(parentItem), item)
                                                          }
                                                        }
                                                      },
                                                      tl: /* [] */0
                                                    }
                                                  }, (function (validations, parentItem) {
                                                      return {
                                                              NAME: "Shape",
                                                              VAL: {
                                                                hd: {
                                                                  key: parentItem.key,
                                                                  list: parentItem.list,
                                                                  optional: parentItem.optional,
                                                                  value: {
                                                                    NAME: "Value",
                                                                    VAL: {
                                                                      type_: PropertyUtils.valueType(parentItem),
                                                                      validations: shapeReducer(validations, parentItem)
                                                                    }
                                                                  }
                                                                },
                                                                tl: /* [] */0
                                                              }
                                                            };
                                                    }))
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              }
                            })
                        }, "Remove"))), tmp);
}

var popup_0 = Css.paddingTop(Css.px(8));

var popup_1 = {
  hd: Css.paddingBottom(Css.px(8)),
  tl: {
    hd: Css.boxShadow(Styles.Shadow.standard),
    tl: {
      hd: Css.borderWidth(Css.px(0)),
      tl: {
        hd: Css.maxWidth(Css.px(280)),
        tl: /* [] */0
      }
    }
  }
};

var popup = {
  hd: popup_0,
  tl: popup_1
};

var popupItem = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textAlign("left"),
              tl: {
                hd: Css.paddingTop(Css.px(8)),
                tl: {
                  hd: Css.paddingRight(Css.px(12)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(8)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(12)),
                      tl: {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.color(Styles.Color.light11),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$short
                                }, undefined, undefined, "all"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.cursor(Css.pointer),
                                    tl: {
                                      hd: Css.color(Styles.Color.light12),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light02),
                                        tl: /* [] */0
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  popup: popup,
  popupItem: popupItem
};

function PropertyDetailsConstraints(Props) {
  var property = Props.property;
  var eventsSendingProperty = Props.eventsSendingProperty;
  var sendActions = Props.sendActions;
  var role = Props.role;
  var addToast = Props.addToast;
  var model = Props.model;
  var schemaBundle = Props.schemaBundle;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var context = {
    propertyId: property.id,
    propertyQuery: property.id
  };
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var globalSend = GlobalSendContext.use(undefined);
  var match = property.type_;
  var supportedValidations;
  switch (match) {
    case "bool" :
        supportedValidations = [];
        break;
    case "float" :
    case "int" :
        supportedValidations = [
          "Min",
          "Max"
        ];
        break;
    case "object" :
        supportedValidations = [
          "Shape",
          "NestedProperty"
        ];
        break;
    case "string" :
        supportedValidations = [
          "AllowedValues",
          "Regex"
        ];
        break;
    default:
      supportedValidations = [];
  }
  var availableValidationsToAdd = Belt_Array.keep(supportedValidations, (function (supportedValidation) {
          if (supportedValidation === "Regex") {
            return !Belt_List.some(property.validations, (function (x) {
                          if (typeof x === "object") {
                            return x.NAME === "Regex";
                          } else {
                            return false;
                          }
                        }));
          } else if (supportedValidation === "Max") {
            return !Belt_List.some(property.validations, (function (x) {
                          if (typeof x === "object") {
                            return x.NAME === "Max";
                          } else {
                            return false;
                          }
                        }));
          } else if (supportedValidation === "Min") {
            return !Belt_List.some(property.validations, (function (x) {
                          if (typeof x === "object") {
                            return x.NAME === "Min";
                          } else {
                            return false;
                          }
                        }));
          } else if (supportedValidation === "NestedProperty") {
            return !Belt_List.some(property.validations, (function (x) {
                          if (typeof x === "object") {
                            return x.NAME === "NestedProperty";
                          } else {
                            return false;
                          }
                        }));
          } else if (supportedValidation === "AllowedValues" && Caml_obj.equal(property.eventSpecificAllowedPropertyValues, [])) {
            return Belt_Option.isNone(GetRegexValidationUseCase.get(property));
          } else {
            return false;
          }
        }));
  if (Caml_obj.equal(supportedValidations, [])) {
    return null;
  }
  var tmp;
  if (!RoleUtils.canEdit(role) || Caml_obj.equal(availableValidationsToAdd, [])) {
    tmp = null;
  } else {
    var regexMenuButton = React.createElement("button", {
          className: popupItem,
          onClick: (function (param) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "AddRegexValidation",
                      VAL: [
                        property.id,
                        undefined,
                        undefined
                      ]
                    }
                  });
            })
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              children: "Regex (regular expression)"
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement($$Text.make, {
              size: "Small",
              children: "Use to enforce a certain string pattern (e.g. date, email address, phone number)."
            }));
    var allowedValuesMenuButton = React.createElement("button", {
          className: popupItem,
          onClick: (function (param) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "AddPropertyValue",
                      VAL: property.id
                    }
                  });
            })
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              children: "Allowed values"
            }), React.createElement(Spacer.make, {
              height: 2
            }), React.createElement($$Text.make, {
              size: "Small",
              children: "Use to enforce a fixed set of allowed values."
            }));
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  children: "Property value constraints"
                }), React.createElement(Tooltip.WithAnalytics.make, {
                  fromItem: {
                    itemId: property.id,
                    itemName: property.name,
                    itemType: "Property"
                  },
                  title: "Property value constraints",
                  children: React.createElement(Tooltip.Content.make, {
                        children: React.createElement(Markdown.make, {
                              source: Tooltips.propertyValueRules
                            })
                      })
                })), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement(Mantine.Menu.make, {
              position: "bottom-start",
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement(TextButton.make, {
                        style: "Blue",
                        size: "Medium",
                        children: "+ Add rule"
                      })
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: popup,
                  children: Belt_Array.mapU(availableValidationsToAdd, (function (availableValidation) {
                          if (availableValidation === "Regex") {
                            return regexMenuButton;
                          }
                          if (availableValidation === "AllowedValues") {
                            return allowedValuesMenuButton;
                          }
                          var validation = availableValidation === "AllowedValues" || availableValidation === "Regex" ? undefined : (
                              availableValidation === "Max" ? ({
                                    NAME: "Max",
                                    VAL: property.type_ === "int" ? ({
                                          NAME: "IntLit",
                                          VAL: 0
                                        }) : ({
                                          NAME: "FloatLit",
                                          VAL: 0
                                        })
                                  }) : (
                                  availableValidation === "Min" ? ({
                                        NAME: "Min",
                                        VAL: property.type_ === "int" ? ({
                                              NAME: "IntLit",
                                              VAL: 0
                                            }) : ({
                                              NAME: "FloatLit",
                                              VAL: 0
                                            })
                                      }) : (
                                      availableValidation === "NestedProperty" ? ({
                                            NAME: "NestedProperty",
                                            VAL: /* [] */0
                                          }) : (
                                          availableValidation === "Shape" ? ({
                                                NAME: "Shape",
                                                VAL: /* [] */0
                                              }) : ({
                                                NAME: "Matches",
                                                VAL: /* [] */0
                                              })
                                        )
                                    )
                                )
                            );
                          if (validation === undefined) {
                            return null;
                          }
                          var variant = validation.NAME;
                          var validationName = variant === "Max" ? "Max rule" : (
                              variant === "Min" ? "Min rule" : (
                                  variant === "Shape" || variant === "NestedProperty" ? "Object rule" : "Allowed Values"
                                )
                            );
                          return React.createElement("button", {
                                      className: popupItem,
                                      onClick: (function (param) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                    var variant = validation.NAME;
                                                    AnalyticsRe.constraintTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, variant === "Max" ? "Max" : (
                                                            variant === "Min" ? "Min" : (
                                                                variant === "NestedProperty" ? "NestedProperty" : (
                                                                    variant === "Shape" ? "Shape" : "Matches"
                                                                  )
                                                              )
                                                          ), "Add", "None", property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "AddPropertyValidation",
                                                      VAL: [
                                                        property.id,
                                                        validation
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                        })
                                    }, React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          children: validationName
                                        }));
                        }))
                })));
  }
  return React.createElement("div", undefined, Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.keep(property.validations, (function (x) {
                            var variant = x.NAME;
                            if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                              return false;
                            }
                            if (variant === "Regex") {
                              return Belt_Array.some(supportedValidations, (function (x) {
                                            return x === "Regex";
                                          }));
                            }
                            if (variant === "Max") {
                              var match = x.VAL;
                              if (typeof match !== "object") {
                                return false;
                              }
                              var variant$1 = match.NAME;
                              if (variant$1 === "IntLit") {
                                if (property.type_ === "int") {
                                  return Belt_Array.some(supportedValidations, (function (x) {
                                                return x === "Max";
                                              }));
                                } else {
                                  return false;
                                }
                              } else if (variant$1 === "FloatLit" && property.type_ === "float") {
                                return Belt_Array.some(supportedValidations, (function (x) {
                                              return x === "Max";
                                            }));
                              } else {
                                return false;
                              }
                            }
                            if (variant !== "Min") {
                              if (variant === "NestedProperty") {
                                if (property.type_ === "object") {
                                  return Belt_Array.some(supportedValidations, (function (x) {
                                                return x === "NestedProperty";
                                              }));
                                } else {
                                  return false;
                                }
                              } else if (variant === "Shape") {
                                if (property.type_ === "object") {
                                  return Belt_Array.some(supportedValidations, (function (x) {
                                                return x === "Shape";
                                              }));
                                } else {
                                  return false;
                                }
                              } else {
                                return Belt_Array.some(supportedValidations, (function (x) {
                                              return x === "Matches";
                                            }));
                              }
                            }
                            var match$1 = x.VAL;
                            if (typeof match$1 !== "object") {
                              return false;
                            }
                            var variant$2 = match$1.NAME;
                            if (variant$2 === "IntLit") {
                              if (property.type_ === "int") {
                                return Belt_Array.some(supportedValidations, (function (x) {
                                              return x === "Min";
                                            }));
                              } else {
                                return false;
                              }
                            } else if (variant$2 === "FloatLit" && property.type_ === "float") {
                              return Belt_Array.some(supportedValidations, (function (x) {
                                            return x === "Min";
                                          }));
                            } else {
                              return false;
                            }
                          })), (function (index, validation) {
                        var exit = 0;
                        var value;
                        var validation$1;
                        var value$1;
                        var validation$2;
                        var variant = validation.NAME;
                        if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                          return Pervasives.failwith("Validation type not supported.");
                        }
                        if (variant === "Regex") {
                          return React.createElement(PropertyDetailsConstraints$RegexValidation, {
                                      property: property,
                                      eventsSendingProperty: eventsSendingProperty,
                                      regexValidation: validation.VAL,
                                      viewerCanEdit: RoleUtils.canEdit(role),
                                      key: "regex"
                                    });
                        }
                        if (variant === "Max") {
                          var match = validation.VAL;
                          if (typeof match !== "object") {
                            return Pervasives.failwith("Validation type not supported.");
                          }
                          var variant$1 = match.NAME;
                          if (variant$1 === "IntLit") {
                            value = match.VAL;
                            validation$1 = validation;
                            exit = 1;
                          } else {
                            if (variant$1 !== "FloatLit") {
                              return Pervasives.failwith("Validation type not supported.");
                            }
                            value$1 = match.VAL;
                            validation$2 = validation;
                            exit = 2;
                          }
                        } else {
                          if (variant !== "Min") {
                            if (variant === "NestedProperty") {
                              return React.createElement(PropertyDetailsConstraints$NestedPropertyValidation, {
                                          propertyRefs: validation.VAL,
                                          model: model,
                                          role: role,
                                          property: property,
                                          currentFilters: currentFilters,
                                          filters: filters,
                                          key: "nestedProperty" + String(index)
                                        });
                            } else if (variant === "Shape") {
                              return React.createElement("div", {
                                          key: "shape" + String(index)
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: {
                                                        hd: Css.justifyContent("spaceBetween"),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  })
                                            }, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.regular),
                                                          tl: /* [] */0
                                                        }
                                                      })
                                                }, "Object:")), React.createElement("div", undefined, Belt_List.toArray(Belt_List.map(validation.VAL, (function (item) {
                                                        return renderItem(/* [] */0, item, role, sendActions, property, context, hasLongValuesFeatureFlag);
                                                      })))), React.createElement(OpsWithState.make, {
                                              value: "",
                                              onFlush: (function (param) {
                                                  
                                                }),
                                              children: (function (value, onChange, param, param$1) {
                                                  var handleAddValue = function (param) {
                                                    if (value.length !== 0) {
                                                      Curry._1(onChange, "");
                                                      Curry.app(sendActions, [
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            undefined,
                                                            [[
                                                                {
                                                                  NAME: "AddPropertyValidation",
                                                                  VAL: [
                                                                    property.id,
                                                                    emptyShape(value)
                                                                  ]
                                                                },
                                                                context
                                                              ]]
                                                          ]);
                                                      return ;
                                                    }
                                                    
                                                  };
                                                  return React.createElement("div", {
                                                              className: Curry._1(Css.style, {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.alignItems("center"),
                                                                      tl: {
                                                                        hd: Css.margin(Css.px(2)),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  })
                                                            }, React.createElement("input", {
                                                                  className: Curry._1(Css.merge, {
                                                                        hd: Curry._1(Css.style, Styles.input),
                                                                        tl: {
                                                                          hd: Curry._1(Css.style, {
                                                                                hd: Css.margin4(Css.px(5), Css.px(5), Css.px(5), Css.px(0)),
                                                                                tl: {
                                                                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                  tl: {
                                                                                    hd: Css.fontSize(Styles.FontSize.regular),
                                                                                    tl: {
                                                                                      hd: Css.borderRadius(Styles.Border.radius),
                                                                                      tl: {
                                                                                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }),
                                                                  placeholder: "Add a key...",
                                                                  value: value,
                                                                  onKeyDown: (function ($$event) {
                                                                      var match = $$event.key;
                                                                      if (match === "Enter") {
                                                                        return handleAddValue(undefined);
                                                                      }
                                                                      
                                                                    }),
                                                                  onChange: (function ($$event) {
                                                                      Curry._1(onChange, $$event.target.value);
                                                                    })
                                                                }), React.createElement("div", undefined, React.createElement(Button.make, {
                                                                      label: "Add",
                                                                      onClick: (function (param) {
                                                                          handleAddValue(undefined);
                                                                        })
                                                                    })));
                                                })
                                            }));
                            } else {
                              return React.createElement(ModalConstraintEditorMatches.make, {
                                          property: property,
                                          sendActions: sendActions,
                                          role: role,
                                          model: model,
                                          addToast: addToast,
                                          key: "matches" + String(index)
                                        });
                            }
                          }
                          var match$1 = validation.VAL;
                          if (typeof match$1 !== "object") {
                            return Pervasives.failwith("Validation type not supported.");
                          }
                          var variant$2 = match$1.NAME;
                          if (variant$2 === "IntLit") {
                            value = match$1.VAL;
                            validation$1 = validation;
                            exit = 1;
                          } else {
                            if (variant$2 !== "FloatLit") {
                              return Pervasives.failwith("Validation type not supported.");
                            }
                            value$1 = match$1.VAL;
                            validation$2 = validation;
                            exit = 2;
                          }
                        }
                        switch (exit) {
                          case 1 :
                              return React.createElement("div", {
                                          key: String(value) + ((
                                              validation$1.NAME === "Min" ? "-min-" : "-max-"
                                            ) + String(index)),
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.marginBottom(Css.px(7)),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement("select", {
                                              className: Curry._1(Css.merge, {
                                                    hd: Curry._1(Css.style, Styles.select),
                                                    tl: {
                                                      hd: Curry._1(Css.style, {
                                                            hd: Css.fontSize(Styles.FontSize.small),
                                                            tl: {
                                                              hd: Css.backgroundColor(Css.transparent),
                                                              tl: {
                                                                hd: Css.maxWidth(Css.px(100)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              disabled: !RoleUtils.canEdit(role),
                                              value: mapValidationType(validation$1),
                                              onChange: (function (domEvent) {
                                                  var nextValidationType = Belt_Option.getWithDefault(TrackingPlanModel.constraint_FromJs(domEvent.target.value), "Min");
                                                  var handleSuccess = function (branch) {
                                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                    var tmp;
                                                    if (typeof validation$1 === "object") {
                                                      var variant = validation$1.NAME;
                                                      tmp = variant === "Max" ? "Max" : (
                                                          variant === "Min" ? "Min" : "None"
                                                        );
                                                    } else {
                                                      tmp = "None";
                                                    }
                                                    AnalyticsRe.constraintTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.getWithDefault(AnalyticsRe.toConstraintTypeFromJs(domEvent.target.value), "None"), "Change", tmp, property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                  };
                                                  if (nextValidationType === "Max") {
                                                    return Curry.app(sendActions, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                handleSuccess,
                                                                undefined,
                                                                [
                                                                  [
                                                                    {
                                                                      NAME: "RemovePropertyValidation",
                                                                      VAL: [
                                                                        property.id,
                                                                        validation$1
                                                                      ]
                                                                    },
                                                                    context
                                                                  ],
                                                                  [
                                                                    {
                                                                      NAME: "AddPropertyValidation",
                                                                      VAL: [
                                                                        property.id,
                                                                        {
                                                                          NAME: "Max",
                                                                          VAL: {
                                                                            NAME: "IntLit",
                                                                            VAL: value
                                                                          }
                                                                        }
                                                                      ]
                                                                    },
                                                                    context
                                                                  ]
                                                                ]
                                                              ]);
                                                  } else if (nextValidationType === "Min") {
                                                    return Curry.app(sendActions, [
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                handleSuccess,
                                                                undefined,
                                                                [
                                                                  [
                                                                    {
                                                                      NAME: "RemovePropertyValidation",
                                                                      VAL: [
                                                                        property.id,
                                                                        validation$1
                                                                      ]
                                                                    },
                                                                    context
                                                                  ],
                                                                  [
                                                                    {
                                                                      NAME: "AddPropertyValidation",
                                                                      VAL: [
                                                                        property.id,
                                                                        {
                                                                          NAME: "Min",
                                                                          VAL: {
                                                                            NAME: "IntLit",
                                                                            VAL: value
                                                                          }
                                                                        }
                                                                      ]
                                                                    },
                                                                    context
                                                                  ]
                                                                ]
                                                              ]);
                                                  } else {
                                                    return ;
                                                  }
                                                })
                                            }, React.createElement("option", {
                                                  value: mapValidationType(validation$1)
                                                }, mapValidationType(validation$1)), Belt_Array.map(availableValidationsToAdd, (function (validation) {
                                                    return React.createElement("option", {
                                                                key: validation,
                                                                value: validation
                                                              }, validation);
                                                  }))), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: /* [] */0
                                                  })
                                            }, React.createElement(OpsWithState.make, {
                                                  value: value.toString(),
                                                  onFlush: (function (value) {
                                                      if (value.length === 0) {
                                                        return ;
                                                      }
                                                      try {
                                                        var match = Caml_format.int_of_string(value);
                                                        if (typeof validation$1 !== "object") {
                                                          return Pervasives.failwith("Will never happen.");
                                                        }
                                                        var variant = validation$1.NAME;
                                                        if (variant === "Max") {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyValidation",
                                                                            VAL: [
                                                                              property.id,
                                                                              {
                                                                                NAME: "Max",
                                                                                VAL: {
                                                                                  NAME: "IntLit",
                                                                                  VAL: match
                                                                                }
                                                                              }
                                                                            ]
                                                                          },
                                                                          context
                                                                        ]]
                                                                    ]);
                                                        } else if (variant === "Min") {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyValidation",
                                                                            VAL: [
                                                                              property.id,
                                                                              {
                                                                                NAME: "Min",
                                                                                VAL: {
                                                                                  NAME: "IntLit",
                                                                                  VAL: match
                                                                                }
                                                                              }
                                                                            ]
                                                                          },
                                                                          context
                                                                        ]]
                                                                    ]);
                                                        } else {
                                                          return Pervasives.failwith("Will never happen.");
                                                        }
                                                      }
                                                      catch (exn){
                                                        return ;
                                                      }
                                                    }),
                                                  children: (function (value, onChange, onFocus, onBlur) {
                                                      return React.createElement("input", {
                                                                  className: Curry._1(Css.merge, {
                                                                        hd: Curry._1(Css.style, Styles.button),
                                                                        tl: {
                                                                          hd: Curry._1(Css.style, {
                                                                                hd: Css.display("block"),
                                                                                tl: {
                                                                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                  tl: {
                                                                                    hd: Css.marginLeft(Css.px(5)),
                                                                                    tl: {
                                                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                                                      tl: {
                                                                                        hd: Css.textAlign("left"),
                                                                                        tl: {
                                                                                          hd: Css.overflow("hidden"),
                                                                                          tl: {
                                                                                            hd: Css.textOverflow("ellipsis"),
                                                                                            tl: {
                                                                                              hd: Css.whiteSpace("nowrap"),
                                                                                              tl: {
                                                                                                hd: Css.borderRadius(Styles.Border.radius),
                                                                                                tl: {
                                                                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }),
                                                                  disabled: !RoleUtils.canEdit(role),
                                                                  step: 1,
                                                                  type: "number",
                                                                  value: value,
                                                                  onFocus: (function ($$event) {
                                                                      Curry._1(onFocus, $$event.target.value);
                                                                    }),
                                                                  onBlur: (function ($$event) {
                                                                      Curry._1(onBlur, $$event.target.value);
                                                                    }),
                                                                  onChange: (function ($$event) {
                                                                      Curry._1(onChange, $$event.target.value);
                                                                    })
                                                                });
                                                    })
                                                })), RoleUtils.canEdit(role) ? React.createElement("button", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.padding2(Css.px(5), Css.px(10)),
                                                              tl: {
                                                                hd: Css.marginLeft(Css.px(5)),
                                                                tl: {
                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                  tl: {
                                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                                    tl: {
                                                                      hd: Css.color(Styles.Color.red),
                                                                      tl: {
                                                                        hd: Css.hover({
                                                                              hd: Css.color(Styles.Color.darkRed),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: (function (param) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "RemovePropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  validation$1
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                    var variant = validation$1.NAME;
                                                    AnalyticsRe.constraintTypeUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "None", "Remove", variant === "Max" ? "Max" : (
                                                            variant === "Min" ? "Min" : "Matches"
                                                          ), property.name, schemaBundle.branchId, schemaBundle.schemaId);
                                                  })
                                              }, "Remove") : null);
                          case 2 :
                              return React.createElement("div", {
                                          key: value$1.toString() + ((
                                              validation$2.NAME === "Min" ? "-min" : "-max"
                                            ) + String(index)),
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.marginBottom(Css.px(7)),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement("select", {
                                              className: Curry._1(Css.merge, {
                                                    hd: Curry._1(Css.style, Styles.select),
                                                    tl: {
                                                      hd: Curry._1(Css.style, {
                                                            hd: Css.fontSize(Styles.FontSize.small),
                                                            tl: {
                                                              hd: Css.backgroundColor(Css.transparent),
                                                              tl: {
                                                                hd: Css.maxWidth(Css.px(100)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              disabled: !RoleUtils.canEdit(role),
                                              value: mapValidationType(validation$2),
                                              onChange: (function (domEvent) {
                                                  var nextValidationType = Belt_Option.getWithDefault(TrackingPlanModel.constraint_FromJs(domEvent.target.value), "Min");
                                                  if (nextValidationType === "Max") {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "RemovePropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  validation$2
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "AddPropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  {
                                                                    NAME: "Max",
                                                                    VAL: {
                                                                      NAME: "FloatLit",
                                                                      VAL: value$1
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                  } else if (nextValidationType === "Min") {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "RemovePropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  validation$2
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "AddPropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  {
                                                                    NAME: "Min",
                                                                    VAL: {
                                                                      NAME: "FloatLit",
                                                                      VAL: value$1
                                                                    }
                                                                  }
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                  }
                                                  var tmp;
                                                  if (typeof validation$2 === "object") {
                                                    var variant = validation$2.NAME;
                                                    tmp = variant === "Max" ? "Max" : (
                                                        variant === "Min" ? "Min" : "None"
                                                      );
                                                  } else {
                                                    tmp = "None";
                                                  }
                                                  AnalyticsRe.constraintTypeUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.getWithDefault(AnalyticsRe.toConstraintTypeFromJs(domEvent.target.value), "None"), "Change", tmp, property.name, schemaBundle.branchId, schemaBundle.schemaId);
                                                })
                                            }, React.createElement("option", {
                                                  value: mapValidationType(validation$2)
                                                }, mapValidationType(validation$2)), Belt_Array.map(availableValidationsToAdd, (function (validation) {
                                                    return React.createElement("option", {
                                                                key: validation,
                                                                value: validation
                                                              }, validation);
                                                  }))), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.width(Css.pct(100)),
                                                    tl: /* [] */0
                                                  })
                                            }, React.createElement(OpsWithState.make, {
                                                  value: value$1.toString(),
                                                  onFlush: (function (value) {
                                                      if (value.length === 0) {
                                                        return ;
                                                      }
                                                      try {
                                                        var match = Number(value);
                                                        if (typeof validation$2 !== "object") {
                                                          return Pervasives.failwith("Will never happen.");
                                                        }
                                                        var variant = validation$2.NAME;
                                                        if (variant === "Max") {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyValidation",
                                                                            VAL: [
                                                                              property.id,
                                                                              {
                                                                                NAME: "Max",
                                                                                VAL: {
                                                                                  NAME: "FloatLit",
                                                                                  VAL: match
                                                                                }
                                                                              }
                                                                            ]
                                                                          },
                                                                          context
                                                                        ]]
                                                                    ]);
                                                        } else if (variant === "Min") {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "AddPropertyValidation",
                                                                            VAL: [
                                                                              property.id,
                                                                              {
                                                                                NAME: "Min",
                                                                                VAL: {
                                                                                  NAME: "FloatLit",
                                                                                  VAL: match
                                                                                }
                                                                              }
                                                                            ]
                                                                          },
                                                                          context
                                                                        ]]
                                                                    ]);
                                                        } else {
                                                          return Pervasives.failwith("Will never happen.");
                                                        }
                                                      }
                                                      catch (exn){
                                                        return ;
                                                      }
                                                    }),
                                                  children: (function (value, onChange, onFocus, onBlur) {
                                                      return React.createElement("input", {
                                                                  className: Curry._1(Css.merge, {
                                                                        hd: Curry._1(Css.style, Styles.button),
                                                                        tl: {
                                                                          hd: Curry._1(Css.style, {
                                                                                hd: Css.display("block"),
                                                                                tl: {
                                                                                  hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                                  tl: {
                                                                                    hd: Css.marginLeft(Css.px(5)),
                                                                                    tl: {
                                                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                                                      tl: {
                                                                                        hd: Css.textAlign("left"),
                                                                                        tl: {
                                                                                          hd: Css.overflow("hidden"),
                                                                                          tl: {
                                                                                            hd: Css.textOverflow("ellipsis"),
                                                                                            tl: {
                                                                                              hd: Css.whiteSpace("nowrap"),
                                                                                              tl: {
                                                                                                hd: Css.borderRadius(Styles.Border.radius),
                                                                                                tl: {
                                                                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                                                  tl: /* [] */0
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }),
                                                                  disabled: !RoleUtils.canEdit(role),
                                                                  type: "number",
                                                                  value: value,
                                                                  onFocus: (function ($$event) {
                                                                      Curry._1(onFocus, $$event.target.value);
                                                                    }),
                                                                  onBlur: (function ($$event) {
                                                                      Curry._1(onBlur, $$event.target.value);
                                                                    }),
                                                                  onChange: (function ($$event) {
                                                                      Curry._1(onChange, $$event.target.value);
                                                                    })
                                                                });
                                                    })
                                                })), RoleUtils.canEdit(role) ? React.createElement("button", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.marginLeft(Css.px(5)),
                                                              tl: {
                                                                hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                tl: {
                                                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                  tl: {
                                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                                    tl: {
                                                                      hd: Css.color(Styles.Color.red),
                                                                      tl: {
                                                                        hd: Css.hover({
                                                                              hd: Css.color(Styles.Color.darkRed),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: (function (param) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          (function (branch) {
                                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                              var variant = validation$2.NAME;
                                                              AnalyticsRe.constraintTypeUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "None", "Remove", variant === "Max" ? "Max" : (
                                                                      variant === "Min" ? "Min" : "Matches"
                                                                    ), property.name, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                            }),
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "RemovePropertyValidation",
                                                                VAL: [
                                                                  property.id,
                                                                  validation$2
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                  })
                                              }, "Remove") : null);
                          
                        }
                      }))), tmp);
}

var make = PropertyDetailsConstraints;

export {
  NestedPropertyValidation ,
  RegexValidation ,
  constraintTypeToJs ,
  constraintTypeFromJs ,
  valueValidations ,
  mapValidationType ,
  emptyShape ,
  shapeRemover ,
  shapeReducer ,
  renderItem ,
  Style ,
  make ,
}
/* headerStyles Not a pure module */
