// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function componentStyle(maxWidth, isDisabled) {
  return Curry._1(Css.style, {
              hd: Css.borderColor(Styles.Color.light04),
              tl: {
                hd: Css.borderWidth(Css.px(1)),
                tl: {
                  hd: Css.borderRadius(Css.px(4)),
                  tl: {
                    hd: Css.borderStyle("solid"),
                    tl: {
                      hd: Css.paddingTop(Css.px(5)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(32)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Css.lineHeight(Css.pct(140)),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.white),
                                      tl: {
                                        hd: Css.maxWidth(maxWidth),
                                        tl: {
                                          hd: Css.backgroundImage({
                                                NAME: "url",
                                                VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuMyAzLjVMMy44IDFsMi41IDIuNU0xLjMgNi41TDMuOCA5bDIuNS0yLjUiIHN0cm9rZT0iI0FBQjJCRCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4='"
                                              }),
                                          tl: {
                                            hd: Css.backgroundRepeat("noRepeat"),
                                            tl: {
                                              hd: Css.backgroundPosition({
                                                    NAME: "hv",
                                                    VAL: [
                                                      "right",
                                                      "center"
                                                    ]
                                                  }),
                                              tl: {
                                                hd: Css.cursor("pointer"),
                                                tl: {
                                                  hd: Css.unsafe("appearance", "none"),
                                                  tl: {
                                                    hd: Css.transition({
                                                          NAME: "ms",
                                                          VAL: Styles.Duration.$$short
                                                        }, undefined, undefined, "all"),
                                                    tl: {
                                                      hd: Css.margin(Css.px(0)),
                                                      tl: {
                                                        hd: Css.disabled({
                                                              hd: Css.borderColor(Styles.Color.light04),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.light01),
                                                                tl: {
                                                                  hd: Css.color(Styles.Color.light08),
                                                                  tl: {
                                                                    hd: Css.cursor("default"),
                                                                    tl: {
                                                                      hd: Css.opacity(1),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: {
                                                          hd: Css.hover(isDisabled ? /* [] */0 : ({
                                                                    hd: Css.color(Styles.Color.light12),
                                                                    tl: {
                                                                      hd: Css.borderColor(Styles.Color.light07),
                                                                      tl: /* [] */0
                                                                    }
                                                                  })),
                                                          tl: {
                                                            hd: Css.active(isDisabled ? /* [] */0 : ({
                                                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                                      tl: /* [] */0
                                                                    })),
                                                            tl: {
                                                              hd: Css.focus(isDisabled ? /* [] */0 : ({
                                                                        hd: Css.outlineStyle("none"),
                                                                        tl: {
                                                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      })),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Select(Props) {
  var additionalStylesOpt = Props.additionalStyles;
  var disabledOpt = Props.disabled;
  var id = Props.id;
  var maxWidthOpt = Props.maxWidth;
  var onSelect = Props.onSelect;
  var onEnterOpt = Props.onEnter;
  var options = Props.options;
  var value = Props.value;
  var autoFocus = Props.autoFocus;
  var additionalStyles = additionalStylesOpt !== undefined ? additionalStylesOpt : /* [] */0;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var maxWidth = maxWidthOpt !== undefined ? maxWidthOpt : "none";
  var onEnter = onEnterOpt !== undefined ? onEnterOpt : (function (param) {
        
      });
  var handleChange = function ($$event) {
    Curry._1(onSelect, $$event.target.value);
  };
  var tmp = {
    className: Curry._1(Css.merge, {
          hd: componentStyle(maxWidth, disabled),
          tl: {
            hd: Curry._1(Css.style, additionalStyles),
            tl: /* [] */0
          }
        }),
    disabled: disabled,
    value: value,
    onKeyDown: (function ($$event) {
        if ($$event.key === "Enter") {
          return Curry._1(onEnter, $$event);
        }
        
      }),
    onChange: handleChange
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = Caml_option.valFromOption(autoFocus);
  }
  return React.createElement("select", tmp, Belt_Array.mapU(options, (function (param) {
                    var value = param[1];
                    var label = param[0];
                    return React.createElement("option", {
                                key: value,
                                disabled: typeof label === "object" && label.NAME === "Disabled" ? true : disabled,
                                value: value
                              }, label.VAL);
                  })));
}

var make = Select;

export {
  componentStyle ,
  make ,
}
/* Css Not a pure module */
