// Generated by ReScript, PLEASE EDIT WITH CARE


function getVercelEnv(param) {
  var match = process.env.VERCEL_ENV;
  if (match == null) {
    return "unknown";
  }
  switch (match) {
    case "development" :
        return "development";
    case "preview" :
        return "preview";
    case "production" :
        return "production";
    default:
      return "unknown";
  }
}

function getEnv(param) {
  var match = process.env.NODE_ENV;
  if (match === "development") {
    return "development";
  } else {
    return "production";
  }
}

export {
  getVercelEnv ,
  getEnv ,
}
/* No side effect */
