// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, copyButtonBoxStyles, apiKey, language) {
  if (language !== undefined) {
    if (language === "java") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, "AvoInspector avoInspector = ", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "new"), " AvoInspector(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"" + apiKey + "\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), " getApplication()", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), " AvoInspectorEnv.", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "Dev"), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, " optionalActivity"), ")", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ";")), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "AvoInspector avoInspector = new AvoInspector(\"" + apiKey + "\", getApplication(), AvoInspectorEnv.Dev, optionalActivity);"
                        })));
    } else if (language === "kt") {
      return React.createElement("div", {
                  className: docsCodeBoxStyles
                }, React.createElement("pre", {
                      className: docsCodeStyles
                    }, React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "val "), "avoInspector = AvoInspector(", React.createElement("span", {
                          className: docsCodeMintGreenStyles
                        }, "\"" + apiKey + "\""), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ", application,"), " AvoInspectorEnv.", React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, "Dev"), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, ","), React.createElement("span", {
                          className: docsCodeLightBlueStyles
                        }, " optionalActivity"), ")"), React.createElement("div", {
                      className: copyButtonBoxStyles
                    }, React.createElement(CopyButton.make, {
                          copyString: "val avoInspector = AvoInspector(\"" + apiKey + "\", application, AvoInspectorEnv.Dev, optionalActivity);"
                        })));
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export {
  code ,
}
/* react Not a pure module */
