// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as CmdK from "./CmdK.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowDownWest from "./IconArrowDownWest.mjs";
import * as SemanticSearchApi from "./search/SemanticSearchApi.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var sectionContainer = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(12)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("flexEnd"),
          tl: {
            hd: Css.maxHeight(Css.px(15)),
            tl: /* [] */0
          }
        }
      }
    });

var actionContainer = Curry._1(Css.style, {
      hd: Css.padding(Css.px(12)),
      tl: {
        hd: Css.borderRadius(Css.px(2)),
        tl: /* [] */0
      }
    });

var actionContent = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.maxHeight(Css.px(45)),
            tl: /* [] */0
          }
        }
      }
    });

var actionContainerActive = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.light02),
      tl: /* [] */0
    });

var shortcut = Curry._1(Css.style, {
      hd: Css.padding(Css.px(4)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.1)),
        tl: {
          hd: Css.borderRadius(Css.px(4)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.setAlpha(Styles.Color.white, 0.5)),
            tl: {
              hd: Css.fontFamily(Styles.FontFamily.polar),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.medium),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.regular),
                  tl: {
                    hd: Css.color(Styles.Color.light11),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Style = {
  sectionContainer: sectionContainer,
  actionContainer: actionContainer,
  actionContent: actionContent,
  actionContainerActive: actionContainerActive,
  shortcut: shortcut
};

function CmdKResults$Subtitle(Props) {
  var subtitle = Props.subtitle;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(8)),
                    tl: {
                      hd: Css.flexShrink(0.0),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  singleLine: true,
                  color: Styles.Color.light10,
                  children: subtitle
                }));
}

var Subtitle = {
  make: CmdKResults$Subtitle
};

function CmdKResults$Results(Props) {
  var items = Props.items;
  return React.createElement(CmdK.Results.make, {
              items: items,
              onRender: (function (param) {
                  var active = param.active;
                  var item = param.item;
                  if (item.TAG === /* Section */0) {
                    return React.createElement("div", {
                                className: sectionContainer
                              }, React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.light10,
                                    children: item._0.toUpperCase()
                                  }));
                  }
                  var action = item._0;
                  var match = action.item;
                  var tmp;
                  switch (match.TAG | 0) {
                    case /* Event */4 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Event"
                            });
                        break;
                    case /* NameMappedEvent */5 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Event name mapping for: " + match._1 + ""
                            });
                        break;
                    case /* Property */6 :
                        var maybeGroupTypeName = match._2;
                        var sendAs = match._1;
                        var tmp$1;
                        if (typeof sendAs === "number") {
                          switch (sendAs) {
                            case /* SystemProperty */0 :
                                tmp$1 = "System Property";
                                break;
                            case /* EventProperty */1 :
                                tmp$1 = "Event Property";
                                break;
                            case /* UserProperty */2 :
                                tmp$1 = "User Property";
                                break;
                            
                          }
                        } else {
                          tmp$1 = maybeGroupTypeName !== undefined ? "" + maybeGroupTypeName + " Property" : "Untitled Group Property";
                        }
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: tmp$1
                            });
                        break;
                    case /* NameMappedProperty */7 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Property name mapping for: " + match._1 + ""
                            });
                        break;
                    case /* Source */8 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Source"
                            });
                        break;
                    case /* Destination */9 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Destination"
                            });
                        break;
                    case /* Integration */10 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Integration"
                            });
                        break;
                    case /* Metric */11 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Metric"
                            });
                        break;
                    case /* Category */12 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Category"
                            });
                        break;
                    case /* PropertyGroup */13 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Property Bundle"
                            });
                        break;
                    case /* GroupType */14 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Group Type"
                            });
                        break;
                    case /* SavedView */15 :
                        tmp = React.createElement(CmdKResults$Subtitle, {
                              subtitle: "Saved View"
                            });
                        break;
                    default:
                      tmp = null;
                  }
                  var match$1 = action.aiResult;
                  var description = CmdK.itemDescription(action.item);
                  return React.createElement("div", {
                              className: Curry._1(Css.merge, {
                                    hd: actionContainer,
                                    tl: {
                                      hd: active ? actionContainerActive : Curry._1(Css.style, {
                                              hd: Css.backgroundColor("transparent"),
                                              tl: /* [] */0
                                            }),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: actionContent
                                }, Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.icon), null, (function (icon) {
                                        return React.createElement(React.Fragment, undefined, Curry._1(icon, undefined), React.createElement(Spacer.make, {
                                                        width: 12
                                                      }));
                                      })), React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      singleLine: true,
                                      color: Styles.Color.light12,
                                      children: CmdK.itemToString(action.item)
                                    }), tmp, match$1 !== undefined && match$1 ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 2
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.backgroundColor(Styles.Color.light03),
                                                  tl: {
                                                    hd: Css.padding(Css.px(2)),
                                                    tl: {
                                                      hd: Css.paddingLeft(Css.px(4)),
                                                      tl: {
                                                        hd: Css.paddingRight(Css.px(4)),
                                                        tl: {
                                                          hd: Css.borderRadius(Css.px(2)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Tiny",
                                                color: Styles.Color.light11,
                                                children: "AI"
                                              }))) : null, React.createElement(Spacer.make, {
                                      grow: 1.0
                                    }), Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.shortcut), null, (function (shortcut$1) {
                                        return Belt_Array.map(shortcut$1, (function (key) {
                                                      var match = key.toLowerCase();
                                                      var tmp;
                                                      switch (match) {
                                                        case "arrowleft" :
                                                            tmp = "←";
                                                            break;
                                                        case "arrowright" :
                                                            tmp = "→";
                                                            break;
                                                        case "escape" :
                                                            tmp = "Esc";
                                                            break;
                                                        default:
                                                          tmp = key.toLocaleUpperCase();
                                                      }
                                                      return React.createElement("div", {
                                                                  key: key,
                                                                  className: shortcut
                                                                }, tmp);
                                                    }));
                                      })), active ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement(IconArrowDownWest.make, {
                                            size: 12,
                                            color: Styles.Color.deepBlue
                                          })) : React.createElement(Spacer.make, {
                                        width: 16
                                      })), description !== undefined ? React.createElement($$Text.make, {
                                    size: "Small",
                                    singleLine: true,
                                    color: Styles.Color.light09,
                                    children: description
                                  }) : null);
                })
            });
}

var Results = {
  make: CmdKResults$Results
};

function score(action, semanticScore, searchQuery) {
  var item = action.item;
  if (semanticScore !== undefined) {
    var stringScore = Math.max(Fuzzaldrin.score(CmdK.itemToString(item), searchQuery), Fuzzaldrin.score(CmdK.itemToStringWithDescription(item), searchQuery) * 0.04);
    var transformedFuzzyScore = Math.log(stringScore + 1.0);
    var transformedSemanticScore = Math.log(semanticScore + 1.0);
    var combinedScore = 0.0 * transformedFuzzyScore + 1.0 * transformedSemanticScore;
    if (searchQuery === "") {
      return 1.0;
    } else {
      return combinedScore * 1.0;
    }
  }
  var multiplier = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(action.scoreMultiplier), 1.0);
  var stringScore$1 = Math.max(Fuzzaldrin.score(CmdK.itemToString(item), searchQuery), Fuzzaldrin.score(CmdK.itemToStringWithDescription(item), searchQuery) * 0.04);
  if (searchQuery === "") {
    return multiplier;
  } else {
    return stringScore$1 * multiplier;
  }
}

function compare(a, b, searchQuery) {
  if (a.TAG === /* Section */0) {
    return 0;
  }
  var a$1 = a._0;
  if (b.TAG === /* Section */0) {
    return 0;
  }
  var b$1 = b._0;
  var bSemanticScore = Belt_Option.flatMap(Caml_option.undefined_to_opt(b$1.aiResult), (function (aiResult) {
          if (aiResult) {
            return Caml_option.undefined_to_opt(b$1.scoreMultiplier);
          }
          
        }));
  var aSemanticScore = Belt_Option.flatMap(Caml_option.undefined_to_opt(a$1.aiResult), (function (aiResult) {
          if (aiResult) {
            return Caml_option.undefined_to_opt(a$1.scoreMultiplier);
          }
          
        }));
  return Caml.float_compare(score(b$1, bSemanticScore, searchQuery), score(a$1, aSemanticScore, searchQuery));
}

function scoreAction(result, semanticScoreOpt, searchQuery) {
  var semanticScore = semanticScoreOpt !== undefined ? Caml_option.valFromOption(semanticScoreOpt) : undefined;
  var action = Curry._1(CmdK.Result.convert, result);
  if (action.TAG === /* Section */0) {
    return ;
  }
  var action$1 = action._0;
  var score$1 = score(action$1, semanticScore, searchQuery);
  if (score$1 <= 0.0015) {
    return ;
  }
  var actionImplWithScore = Curry.app(CmdK.ActionImpl.set, [
        action$1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        score$1,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  return {
          TAG: /* Action */1,
          _0: actionImplWithScore
        };
}

function searchItemMatchesSearchCategory(action, searchCategory) {
  var exit = 0;
  switch (action.TAG | 0) {
    case /* Event */4 :
    case /* NameMappedEvent */5 :
        exit = 1;
        break;
    case /* Property */6 :
    case /* NameMappedProperty */7 :
        exit = 2;
        break;
    case /* Source */8 :
        if (searchCategory === "sources") {
          return true;
        } else {
          return false;
        }
    case /* Destination */9 :
        if (searchCategory === "destinations") {
          return true;
        } else {
          return false;
        }
    case /* Integration */10 :
        if (searchCategory === "integrations") {
          return true;
        } else {
          return false;
        }
    case /* Metric */11 :
        if (searchCategory === "metrics") {
          return true;
        } else {
          return false;
        }
    case /* Category */12 :
        if (searchCategory === "categories") {
          return true;
        } else {
          return false;
        }
    case /* PropertyGroup */13 :
        if (searchCategory === "propertyGroups") {
          return true;
        } else {
          return false;
        }
    case /* SavedView */15 :
        if (searchCategory === "saved-views") {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
  switch (exit) {
    case 1 :
        if (searchCategory === "events") {
          return true;
        } else {
          return false;
        }
    case 2 :
        if (searchCategory === "properties") {
          return true;
        } else {
          return false;
        }
    
  }
}

function unarchiveItemMatchesUnarchiveCategory(action, searchCategory) {
  var exit = 0;
  switch (action.TAG | 0) {
    case /* Event */4 :
    case /* NameMappedEvent */5 :
        exit = 1;
        break;
    case /* Property */6 :
    case /* NameMappedProperty */7 :
        exit = 2;
        break;
    case /* Source */8 :
        if (searchCategory === "unarchiveSources") {
          return true;
        } else {
          return false;
        }
    case /* Destination */9 :
        if (searchCategory === "unarchiveDestinations") {
          return true;
        } else {
          return false;
        }
    case /* Metric */11 :
        if (searchCategory === "unarchiveMetrics") {
          return true;
        } else {
          return false;
        }
    case /* Category */12 :
        if (searchCategory === "unarchiveCategories") {
          return true;
        } else {
          return false;
        }
    case /* PropertyGroup */13 :
        if (searchCategory === "unarchivePropertyGroups") {
          return true;
        } else {
          return false;
        }
    case /* GroupType */14 :
        if (searchCategory === "unarchiveGroupTypes") {
          return true;
        } else {
          return false;
        }
    default:
      return false;
  }
  switch (exit) {
    case 1 :
        if (searchCategory === "unarchiveEvents") {
          return true;
        } else {
          return false;
        }
    case 2 :
        if (searchCategory === "unarchiveProperties") {
          return true;
        } else {
          return false;
        }
    
  }
}

function filterNestedActionBySearchQuery(action, result, rootActionId, semanticScoreOpt, searchQuery) {
  var semanticScore = semanticScoreOpt !== undefined ? Caml_option.valFromOption(semanticScoreOpt) : undefined;
  var match = action.item;
  var match$1 = action.parent;
  var exit = 0;
  if (match.TAG === /* Command */2) {
    if (match$1 === undefined) {
      return ;
    }
    switch (match$1) {
      case "search" :
          if (searchQuery === "") {
            if (rootActionId === "search") {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit = 1;
          } else {
            exit = 1;
          }
          break;
      case "unarchive" :
          if (searchQuery === "") {
            if (rootActionId === "unarchive") {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit = 1;
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$1 === undefined) {
      return ;
    }
    var exit$1 = 0;
    var exit$2 = 0;
    switch (match$1) {
      case "search" :
          if (searchQuery === "") {
            if (searchItemMatchesSearchCategory(match, rootActionId)) {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit$2 = 3;
          } else {
            exit$2 = 3;
          }
          break;
      case "unarchive" :
          if (searchQuery === "") {
            if (unarchiveItemMatchesUnarchiveCategory(match, rootActionId)) {
              return Curry._1(CmdK.Result.convert, result);
            }
            exit$2 = 3;
          } else {
            exit$2 = 3;
          }
          break;
      default:
        if (searchQuery === "") {
          if (match$1 === rootActionId) {
            return Curry._1(CmdK.Result.convert, result);
          }
          exit$1 = 2;
        } else {
          exit$1 = 2;
        }
    }
    if (exit$2 === 3) {
      if (searchQuery === "") {
        return ;
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (match$1 === rootActionId) {
        return scoreAction(result, Caml_option.some(semanticScore), searchQuery);
      }
      switch (match$1) {
        case "search" :
            if (searchItemMatchesSearchCategory(match, rootActionId)) {
              return scoreAction(result, Caml_option.some(semanticScore), searchQuery);
            } else {
              return ;
            }
        case "unarchive" :
            if (unarchiveItemMatchesUnarchiveCategory(match, rootActionId)) {
              return scoreAction(result, Caml_option.some(semanticScore), searchQuery);
            } else {
              return ;
            }
        default:
          return ;
      }
    }
    
  }
  
}

function filterRootActionBySearchQuery(action, result, semanticScoreOpt, searchQuery) {
  var semanticScore = semanticScoreOpt !== undefined ? Caml_option.valFromOption(semanticScoreOpt) : undefined;
  var match = action.item;
  var match$1 = action.parent;
  var exit = 0;
  if (searchQuery === "") {
    if ((
        match$1 === undefined ? undefined : Caml_option.some(match$1)
      ) === undefined) {
      return Curry._1(CmdK.Result.convert, result);
    } else {
      return ;
    }
  }
  if (match.TAG === /* Command */2) {
    if (searchQuery !== "") {
      return ;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (match$1 !== undefined) {
      if (match$1 !== "search") {
        return ;
      }
      exit$1 = 2;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      var score$1 = score(action, semanticScore, searchQuery);
      if (score$1 <= 0.0015) {
        return ;
      }
      var actionImplWithScore = Curry.app(CmdK.ActionImpl.set, [
            action,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            score$1,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          ]);
      return {
              TAG: /* Action */1,
              _0: actionImplWithScore
            };
    }
    
  }
  
}

function filterActionBySearchQuery(result, rootActionId, searchQuery) {
  var match = Curry._1(CmdK.Result.convert, result);
  var match$1 = searchQuery.trim();
  if (rootActionId !== undefined) {
    if (match.TAG === /* Section */0) {
      return ;
    }
    var action = match._0;
    var semanticScore = Caml_option.undefined_to_opt(action.aiResult) === true ? Caml_option.undefined_to_opt(action.score) : undefined;
    return filterNestedActionBySearchQuery(action, result, rootActionId, Caml_option.some(semanticScore), match$1);
  }
  if (match.TAG === /* Section */0) {
    return ;
  }
  var action$1 = match._0;
  var semanticScore$1 = Caml_option.undefined_to_opt(action$1.aiResult) === true ? Caml_option.undefined_to_opt(action$1.score) : undefined;
  return filterRootActionBySearchQuery(action$1, result, Caml_option.some(semanticScore$1), match$1);
}

function filterAndSortActions(actions, rootActionId, searchQuery) {
  return Belt_Array.keepMap(actions, (function (param) {
                  return filterActionBySearchQuery(param[1], rootActionId, searchQuery);
                })).sort(function (param, param$1) {
              return compare(param, param$1, searchQuery);
            });
}

function convertSearchToAction(result, search, rootActionId, searchQuery) {
  var match = Curry._1(CmdK.Result.convert, result);
  var match$1 = searchQuery.trim();
  var tmp;
  tmp = rootActionId !== undefined ? (
      match.TAG === /* Section */0 ? undefined : filterNestedActionBySearchQuery(match._0, result, rootActionId, Caml_option.some(1 - search.distance), match$1)
    ) : (
      match.TAG === /* Section */0 ? undefined : filterRootActionBySearchQuery(match._0, result, Caml_option.some(1 - search.distance), match$1)
    );
  return Belt_Option.map(tmp, (function (result) {
                if (result.TAG === /* Section */0) {
                  return result;
                }
                var action = result._0;
                var tmp = {
                  item: action.item,
                  id: action.id,
                  name: action.name,
                  scoreMultiplier: 1.0,
                  aiResult: true,
                  ancestors: action.ancestors,
                  children: action.children
                };
                var tmp$1 = Caml_option.undefined_to_opt(action.score);
                if (tmp$1 !== undefined) {
                  tmp.score = Caml_option.valFromOption(tmp$1);
                }
                var tmp$2 = Caml_option.undefined_to_opt(action.shortcut);
                if (tmp$2 !== undefined) {
                  tmp.shortcut = Caml_option.valFromOption(tmp$2);
                }
                var tmp$3 = Caml_option.undefined_to_opt(action.keywords);
                if (tmp$3 !== undefined) {
                  tmp.keywords = Caml_option.valFromOption(tmp$3);
                }
                var tmp$4 = Caml_option.undefined_to_opt(action.section);
                if (tmp$4 !== undefined) {
                  tmp.section = Caml_option.valFromOption(tmp$4);
                }
                var tmp$5 = Caml_option.undefined_to_opt(action.icon);
                if (tmp$5 !== undefined) {
                  tmp.icon = Caml_option.valFromOption(tmp$5);
                }
                var tmp$6 = Caml_option.undefined_to_opt(action.perform);
                if (tmp$6 !== undefined) {
                  tmp.perform = Caml_option.valFromOption(tmp$6);
                }
                var tmp$7 = Caml_option.undefined_to_opt(action.parent);
                if (tmp$7 !== undefined) {
                  tmp.parent = Caml_option.valFromOption(tmp$7);
                }
                var tmp$8 = Caml_option.undefined_to_opt(action.command);
                if (tmp$8 !== undefined) {
                  tmp.command = Caml_option.valFromOption(tmp$8);
                }
                var tmp$9 = Caml_option.undefined_to_opt(action.priority);
                if (tmp$9 !== undefined) {
                  tmp.priority = Caml_option.valFromOption(tmp$9);
                }
                var a = tmp;
                return {
                        TAG: /* Action */1,
                        _0: a
                      };
              }));
}

function convertActions(actions, rootActionId, searchQuery) {
  return Belt_Array.keepMap(actions, (function (param) {
                return convertSearchToAction(param[0], param[1], rootActionId, searchQuery);
              }));
}

function trackCmdKresults(searchQuery, rootActionId, schemaBundle, visibleFuzzyMatches, visibleSmartMatches) {
  if (searchQuery !== "" && rootActionId !== "branch") {
    return AnalyticsRe.cmdPaletteResultsReceivedTrackingPlanItemSearch(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, visibleFuzzyMatches.length, visibleSmartMatches.length, Belt_Array.map(visibleFuzzyMatches, (function (result) {
                      if (result.TAG === /* Section */0) {
                        return result._0;
                      } else {
                        return result._0.name;
                      }
                    })), Belt_Array.map(visibleSmartMatches, (function (result) {
                      if (result.TAG === /* Section */0) {
                        return result._0;
                      } else {
                        return result._0.name;
                      }
                    })), Belt_Array.keepMapU(Belt_Array.mapWithIndexU(visibleSmartMatches, (function (index, smartMatch) {
                          if (smartMatch.TAG === /* Section */0) {
                            return ;
                          }
                          var actionImpl = smartMatch._0;
                          return {
                                  "Item Name": actionImpl.name,
                                  "Item Type": AnalyticsUtils.sectionStringToAnalyticsItemType(Caml_option.undefined_to_opt(actionImpl.section)),
                                  "Search Result Position": index + 1 | 0,
                                  "Search Result Ranking": Caml_option.undefined_to_opt(actionImpl.score),
                                  "Search Term": searchQuery
                                };
                        })), (function (i) {
                      return i;
                    })), Belt_Array.keepMapU(Belt_Array.mapWithIndexU(visibleFuzzyMatches, (function (index, fuzzyMatch) {
                          if (fuzzyMatch.TAG === /* Section */0) {
                            return ;
                          }
                          var actionImpl = fuzzyMatch._0;
                          return {
                                  "Item Name": actionImpl.name,
                                  "Item Type": AnalyticsUtils.sectionStringToAnalyticsItemType(Caml_option.undefined_to_opt(actionImpl.section)),
                                  "Search Result Position": index + 1 | 0,
                                  "Search Result Ranking": Caml_option.undefined_to_opt(actionImpl.score),
                                  "Search Term": searchQuery
                                };
                        })), (function (i) {
                      return i;
                    })));
  }
  var tmp;
  tmp = rootActionId !== undefined ? (
      rootActionId === "branch" ? "Branches" : "TrackingPlanItems"
    ) : (
      searchQuery === "" ? "Actions" : "TrackingPlanItems"
    );
  AnalyticsRe.cmdPaletteResultsReceived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, visibleFuzzyMatches.length, visibleSmartMatches.length, Belt_Array.map(visibleFuzzyMatches, (function (result) {
              if (result.TAG === /* Section */0) {
                return result._0;
              } else {
                return result._0.name;
              }
            })), Belt_Array.map(visibleSmartMatches, (function (result) {
              if (result.TAG === /* Section */0) {
                return result._0;
              } else {
                return result._0.name;
              }
            })), Belt_Array.keepMapU(Belt_Array.mapWithIndexU(visibleSmartMatches, (function (index, smartMatch) {
                  if (smartMatch.TAG === /* Section */0) {
                    return ;
                  }
                  var actionImpl = smartMatch._0;
                  return {
                          "Item Name": actionImpl.name,
                          "Item Type": AnalyticsUtils.sectionStringToAnalyticsItemType(Caml_option.undefined_to_opt(actionImpl.section)),
                          "Search Result Position": index + 1 | 0,
                          "Search Result Ranking": Caml_option.undefined_to_opt(actionImpl.score),
                          "Search Term": searchQuery
                        };
                })), (function (i) {
              return i;
            })), Belt_Array.keepMapU(Belt_Array.mapWithIndexU(visibleFuzzyMatches, (function (index, fuzzyMatch) {
                  if (fuzzyMatch.TAG === /* Section */0) {
                    return ;
                  }
                  var actionImpl = fuzzyMatch._0;
                  return {
                          "Item Name": actionImpl.name,
                          "Item Type": AnalyticsUtils.sectionStringToAnalyticsItemType(Caml_option.undefined_to_opt(actionImpl.section)),
                          "Search Result Position": index + 1 | 0,
                          "Search Result Ranking": Caml_option.undefined_to_opt(actionImpl.score),
                          "Search Term": searchQuery
                        };
                })), (function (i) {
              return i;
            })), tmp);
}

function CmdKResults(Props) {
  var match = Curry._1(CmdK.Matches.useMatches, undefined);
  var rootActionId = match.rootActionId;
  var kBarState = Kbar.useKBar(function (state) {
        return {
                searchQuery: state.searchQuery,
                actions: state.actions
              };
      });
  var match$1 = Kbar.useKBar();
  var query = match$1.query;
  var workspace = WorkspaceContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var memberRole = WorkspaceContext.useMemberRole(undefined);
  var memberIsAdmin = memberRole === "Admin";
  var smartSearchEnabled = workspace.settings.smartSearchEnabled;
  var schemaId = RouterStore.Schema.useSchemaId(undefined);
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowingPromotion = match$2[1];
  var showingPromotion = match$2[0];
  var searchQuery = kBarState.searchQuery;
  var match$3 = React.useState(function () {
        return 3;
      });
  var setVisibleFuzzyCount = match$3[1];
  var visibleFuzzyCount = match$3[0];
  var debouncedSearchQuery = Hooks.useDebounced((function (param) {
          return searchQuery;
        }), 200);
  var actions = kBarState.actions;
  var match$4 = React.useState(function () {
        return false;
      });
  var setAiLoading = match$4[1];
  var aiLoading = match$4[0];
  var match$5 = React.useState(function () {
        return [];
      });
  var updateSmartResults = match$5[1];
  var smartResults = match$5[0];
  var latestQueryRef = React.useRef(searchQuery);
  React.useEffect((function () {
          latestQueryRef.current = debouncedSearchQuery;
          if (Caml_obj.equal(smartSearchEnabled, true)) {
            Curry._1(setAiLoading, (function (param) {
                    return true;
                  }));
            SemanticSearchApi.getSemanticSearchResults(schemaId, debouncedSearchQuery).then(function (results) {
                  if (results.NAME === "Error") {
                    if (searchQuery.trim() === latestQueryRef.current.trim()) {
                      Curry._1(setAiLoading, (function (param) {
                              return false;
                            }));
                    }
                    
                  } else if (searchQuery.trim() === latestQueryRef.current.trim()) {
                    Curry._1(setAiLoading, (function (param) {
                            return false;
                          }));
                    var actions$1 = Belt_Array.keepMap(results.VAL, (function (result) {
                            var action = Js_dict.get(actions, result.itemId);
                            if (action !== undefined) {
                              return [
                                      Caml_option.valFromOption(action),
                                      result
                                    ];
                            }
                            
                          }));
                    var smartResults = convertActions(actions$1, rootActionId, debouncedSearchQuery);
                    Curry._1(updateSmartResults, (function (param) {
                            return smartResults;
                          }));
                  }
                  return Promise.resolve(undefined);
                });
          }
          
        }), [debouncedSearchQuery]);
  var filteredResults = filterAndSortActions(Js_dict.entries(actions), rootActionId, searchQuery);
  var visibleFuzzyMatches = filteredResults.slice(0, visibleFuzzyCount);
  var sectionFuzzyMatches = Curry._1(CmdK.Result.convert, "Fuzzy matches");
  var sectionSmartMatches = Curry._1(CmdK.Result.convert, "Smart matches");
  var smartResultsOrdered = React.useMemo((function () {
          return smartResults.sort(function (param, param$1) {
                      return compare(param, param$1, searchQuery);
                    });
        }), [
        smartResults,
        debouncedSearchQuery
      ]);
  var finalResults = React.useMemo((function () {
          if (searchQuery === "") {
            return filteredResults;
          } else if (smartSearchEnabled !== undefined) {
            if (smartSearchEnabled) {
              return Belt_Array.concatMany([
                          [sectionFuzzyMatches],
                          visibleFuzzyMatches,
                          [sectionSmartMatches],
                          smartResultsOrdered
                        ]);
            } else {
              return filteredResults;
            }
          } else if (memberIsAdmin) {
            return Belt_Array.concat([sectionFuzzyMatches], filteredResults);
          } else {
            return filteredResults;
          }
        }), [
        smartSearchEnabled,
        searchQuery,
        filteredResults
      ]);
  React.useEffect((function () {
          if (smartResultsOrdered.length !== 0 && searchQuery === "") {
            Curry._1(updateSmartResults, (function (param) {
                    return [];
                  }));
            Curry._1(setVisibleFuzzyCount, (function (param) {
                    return 3;
                  }));
          }
          
        }), [
        smartResultsOrdered,
        searchQuery
      ]);
  var fuzzyMatchesKey = smartSearchEnabled === undefined ? "fuzzy-matches-promotion" : "fuzzy-matches";
  React.useEffect((function () {
          trackCmdKresults(searchQuery, rootActionId, schemaBundle, visibleFuzzyMatches, smartResultsOrdered);
        }), [
        visibleFuzzyMatches,
        trackCmdKresults
      ]);
  React.useEffect((function () {
          if (showingPromotion) {
            AnalyticsRe.overlayItemDisplayed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "InlineInProduct", "EnableSmartSearch", "ProductInfo", schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [showingPromotion]);
  var onOverlayItemClick = function (clickType) {
    AnalyticsRe.overlayItemInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, clickType, undefined, "InlineInProduct", "EnableSmartSearch", "ProductInfo", schemaBundle.branchId, schemaBundle.schemaId);
  };
  React.useEffect((function () {
          if (visibleFuzzyCount > 3) {
            Curry._1(query.setActiveIndex, (function (param) {
                    return visibleFuzzyCount;
                  }));
          }
          
        }), [visibleFuzzyCount]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(8)),
                        tl: {
                          hd: Css.marginBottom(Css.px(8)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(CmdK.Results.make, {
                      items: finalResults,
                      onRender: (function (param) {
                          var active = param.active;
                          var item = param.item;
                          if (item.TAG === /* Section */0) {
                            switch (item._0) {
                              case "Fuzzy matches" :
                                  if (searchQuery === "") {
                                    return null;
                                  } else {
                                    return React.createElement("div", {
                                                key: fuzzyMatchesKey
                                              }, smartSearchEnabled === undefined && memberIsAdmin ? (Curry._1(setShowingPromotion, (function (param) {
                                                          return true;
                                                        })), React.createElement("div", {
                                                        key: "smart-search-promotion",
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.padding(Css.px(6)),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.lightPink),
                                                                tl: {
                                                                  hd: Css.borderRadius(Css.px(4)),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.flexDirection("row"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css.alignSelf("center"),
                                                                          tl: {
                                                                            hd: Css.width(Css.pct(98)),
                                                                            tl: {
                                                                              hd: Css.height("inherit_"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            })
                                                      }, React.createElement(Icon.make, {
                                                            type_: "sparkle",
                                                            size: {
                                                              NAME: "int",
                                                              VAL: 16
                                                            },
                                                            color: Styles.Color.avoPink
                                                          }), React.createElement(Spacer.make, {
                                                            width: 4
                                                          }), React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Regular",
                                                            color: Styles.Color.avoPink,
                                                            children: "Enable Avo Intelligence to get smarter search results."
                                                          }), React.createElement(Spacer.make, {
                                                            grow: 1
                                                          }), React.createElement(TextButton.make, {
                                                            onClick: (function (param) {
                                                                onOverlayItemClick("ClickLink");
                                                                Curry._1(globalSend, {
                                                                      TAG: /* OpenModal */4,
                                                                      _0: {
                                                                        NAME: "Settings",
                                                                        VAL: /* Permissions */1
                                                                      }
                                                                    });
                                                              }),
                                                            children: React.createElement($$Text.make, {
                                                                  size: "Small",
                                                                  weight: "Semi",
                                                                  color: Styles.Color.avoPink,
                                                                  children: "Go to settings"
                                                                })
                                                          }), React.createElement(Spacer.make, {
                                                            width: 8
                                                          }), React.createElement("button", {
                                                            className: Curry._1(Css.merge, {
                                                                  hd: Curry._1(Css.style, Styles.buttonReset),
                                                                  tl: {
                                                                    hd: Curry._1(Css.style, {
                                                                          hd: Css.backgroundColor(Styles.Color.avoPink),
                                                                          tl: {
                                                                            hd: Css.borderRadius(Css.pct(100)),
                                                                            tl: {
                                                                              hd: Css.height(Css.px(16)),
                                                                              tl: {
                                                                                hd: Css.width(Css.px(16)),
                                                                                tl: {
                                                                                  hd: Css.display("flex"),
                                                                                  tl: {
                                                                                    hd: Css.alignItems("center"),
                                                                                    tl: {
                                                                                      hd: Css.justifyContent("center"),
                                                                                      tl: {
                                                                                        hd: Css.hover({
                                                                                              hd: Css.backgroundColor(Styles.Color.avoPinkShade2),
                                                                                              tl: /* [] */0
                                                                                            }),
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }),
                                                            onClick: (function (param) {
                                                                onOverlayItemClick("Dismiss");
                                                                Workspace.initializeSmartSearchAsFalse(workspace, Firebase.app(undefined), schemaBundle);
                                                              })
                                                          }, React.createElement(Icon.make, {
                                                                type_: "close",
                                                                size: {
                                                                  NAME: "int",
                                                                  VAL: 12
                                                                },
                                                                color: Styles.Color.white
                                                              })), React.createElement(Spacer.make, {
                                                            width: 2
                                                          }))) : null, React.createElement("div", {
                                                    key: "fuzzy-matches-section",
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.padding(Css.px(8)),
                                                          tl: {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.flexDirection("row"),
                                                              tl: {
                                                                hd: Css.alignItems("flexStart"),
                                                                tl: {
                                                                  hd: Css.justifyContent("flexStart"),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100.0)),
                                                                    tl: {
                                                                      hd: Css.height(Css.px(32)),
                                                                      tl: {
                                                                        hd: Css.overflow("hidden"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement(Icon.make, {
                                                        type_: "search",
                                                        color: Styles.Color.light10
                                                      }), React.createElement(Spacer.make, {
                                                        width: 4
                                                      }), React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        color: Styles.Color.light10,
                                                        children: "Fuzzy matches"
                                                      })), filteredResults.length === 0 ? React.createElement("div", {
                                                      key: "fuzzy-matches-no-results",
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.padding(Css.px(16)),
                                                            tl: {
                                                              hd: Css.display("flex"),
                                                              tl: {
                                                                hd: Css.flexDirection("column"),
                                                                tl: {
                                                                  hd: Css.justifySelf("center"),
                                                                  tl: {
                                                                    hd: Css.alignSelf("center"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("center"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement($$Text.make, {
                                                          weight: "Semi",
                                                          color: Styles.Color.light08,
                                                          children: "No results"
                                                        })) : null);
                                  }
                              case "Smart matches" :
                                  return React.createElement("div", {
                                              key: "smart-matches-section"
                                            }, visibleFuzzyMatches.length < filteredResults.length ? React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.padding(Css.px(8)),
                                                          tl: {
                                                            hd: Css.paddingBottom(Css.px(12)),
                                                            tl: {
                                                              hd: Css.paddingLeft(Css.px(12)),
                                                              tl: {
                                                                hd: Css.display("flex"),
                                                                tl: {
                                                                  hd: Css.alignItems("center"),
                                                                  tl: {
                                                                    hd: Css.justifyContent("flexStart"),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        })
                                                  }, React.createElement(TextButton.make, {
                                                        onClick: (function (param) {
                                                            Curry._1(setVisibleFuzzyCount, (function (count) {
                                                                    return count + 3 | 0;
                                                                  }));
                                                          }),
                                                        size: "Medium",
                                                        children: React.createElement($$Text.make, {
                                                              size: "Small",
                                                              weight: "Semi",
                                                              color: Styles.Color.darkBlue,
                                                              children: "Show more"
                                                            })
                                                      })) : null, React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.padding(Css.px(8)),
                                                        tl: {
                                                          hd: Css.paddingTop(Css.px(12)),
                                                          tl: {
                                                            hd: Css.display("flex"),
                                                            tl: {
                                                              hd: Css.flexDirection("row"),
                                                              tl: {
                                                                hd: Css.alignItems("flexStart"),
                                                                tl: {
                                                                  hd: Css.justifyContent("flexStart"),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100.0)),
                                                                    tl: {
                                                                      hd: Css.overflow("hidden"),
                                                                      tl: {
                                                                        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, React.createElement(Icon.make, {
                                                      type_: "sparkle",
                                                      color: Styles.Color.avoPink
                                                    }), React.createElement(Spacer.make, {
                                                      width: 4
                                                    }), React.createElement($$Text.make, {
                                                      size: "Small",
                                                      weight: "Semi",
                                                      color: Styles.Color.avoPink,
                                                      children: "Smart matches"
                                                    }), aiLoading ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                            grow: 1
                                                          }), React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Regular",
                                                            color: Styles.Color.light08,
                                                            children: "Thinking..."
                                                          }), React.createElement(Spacer.make, {
                                                            width: 4
                                                          }), React.createElement(LoadingCircle.make, {
                                                            color: Styles.Color.light08
                                                          })) : null), smartResults.length !== 0 ? null : (
                                                aiLoading ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                            className: Curry._1(Css.merge, {
                                                                  hd: Curry._1(Css.style, Styles.shimmer),
                                                                  tl: {
                                                                    hd: Curry._1(Css.style, {
                                                                          hd: Css.height(Css.px(16)),
                                                                          tl: {
                                                                            hd: Css.width(Css.px(223)),
                                                                            tl: {
                                                                              hd: Css.margin2(Css.px(4), Css.px(12)),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                })
                                                          }), React.createElement("div", {
                                                            className: Curry._1(Css.merge, {
                                                                  hd: Curry._1(Css.style, Styles.shimmer),
                                                                  tl: {
                                                                    hd: Curry._1(Css.style, {
                                                                          hd: Css.height(Css.px(12)),
                                                                          tl: {
                                                                            hd: Css.width(Css.px(353)),
                                                                            tl: {
                                                                              hd: Css.margin2(Css.px(4), Css.px(12)),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                })
                                                          })) : (
                                                    searchQuery !== "" ? React.createElement("div", {
                                                            key: "smart-matches-no-results",
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.padding(Css.px(16)),
                                                                  tl: {
                                                                    hd: Css.display("flex"),
                                                                    tl: {
                                                                      hd: Css.flexDirection("column"),
                                                                      tl: {
                                                                        hd: Css.justifySelf("center"),
                                                                        tl: {
                                                                          hd: Css.alignSelf("center"),
                                                                          tl: {
                                                                            hd: Css.justifyContent("center"),
                                                                            tl: {
                                                                              hd: Css.alignItems("center"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement($$Text.make, {
                                                                weight: "Semi",
                                                                color: Styles.Color.light08,
                                                                children: "No results"
                                                              }), React.createElement($$Text.make, {
                                                                size: "Tiny",
                                                                weight: "Regular",
                                                                color: Styles.Color.light08,
                                                                children: "It can take about 5 minutes to index your workspace after enabling Avo Intelligence."
                                                              })) : null
                                                  )
                                              ));
                              default:
                                return React.createElement("div", undefined);
                            }
                          } else {
                            var action = item._0;
                            var key = Caml_option.undefined_to_opt(action.aiResult) === true ? "smart-" + action.id + "" : action.id;
                            var match = action.item;
                            var tmp;
                            switch (match.TAG | 0) {
                              case /* Event */4 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Event"
                                      });
                                  break;
                              case /* NameMappedEvent */5 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Event name mapping for: " + match._1 + ""
                                      });
                                  break;
                              case /* Property */6 :
                                  var maybeGroupTypeName = match._2;
                                  var sendAs = match._1;
                                  var tmp$1;
                                  if (typeof sendAs === "number") {
                                    switch (sendAs) {
                                      case /* SystemProperty */0 :
                                          tmp$1 = "System Property";
                                          break;
                                      case /* EventProperty */1 :
                                          tmp$1 = "Event Property";
                                          break;
                                      case /* UserProperty */2 :
                                          tmp$1 = "User Property";
                                          break;
                                      
                                    }
                                  } else {
                                    tmp$1 = maybeGroupTypeName !== undefined ? "" + maybeGroupTypeName + " Property" : "Untitled Group Property";
                                  }
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: tmp$1
                                      });
                                  break;
                              case /* NameMappedProperty */7 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Property name mapping for: " + match._1 + ""
                                      });
                                  break;
                              case /* Source */8 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Source"
                                      });
                                  break;
                              case /* Destination */9 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Destination"
                                      });
                                  break;
                              case /* Integration */10 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Integration"
                                      });
                                  break;
                              case /* Metric */11 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Metric"
                                      });
                                  break;
                              case /* Category */12 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Category"
                                      });
                                  break;
                              case /* PropertyGroup */13 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Property Bundle"
                                      });
                                  break;
                              case /* GroupType */14 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Group Type"
                                      });
                                  break;
                              case /* SavedView */15 :
                                  tmp = React.createElement(CmdKResults$Subtitle, {
                                        subtitle: "Saved View"
                                      });
                                  break;
                              default:
                                tmp = null;
                            }
                            var description = CmdK.itemDescription(action.item);
                            return React.createElement("div", {
                                        key: key,
                                        className: Curry._1(Css.merge, {
                                              hd: actionContainer,
                                              tl: {
                                                hd: active ? actionContainerActive : Curry._1(Css.style, {
                                                        hd: Css.backgroundColor("transparent"),
                                                        tl: /* [] */0
                                                      }),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: actionContent
                                          }, Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.icon), null, (function (icon) {
                                                  return React.createElement(React.Fragment, undefined, Curry._1(icon, undefined), React.createElement(Spacer.make, {
                                                                  width: 12
                                                                }));
                                                })), React.createElement($$Text.make, {
                                                size: "Medium",
                                                weight: "Semi",
                                                singleLine: true,
                                                color: Styles.Color.light12,
                                                children: CmdK.itemToString(action.item)
                                              }), tmp, React.createElement(Spacer.make, {
                                                grow: 1.0
                                              }), Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(action.shortcut), null, (function (shortcut$1) {
                                                  return Belt_Array.map(shortcut$1, (function (key) {
                                                                var match = key.toLowerCase();
                                                                var tmp;
                                                                switch (match) {
                                                                  case "arrowleft" :
                                                                      tmp = "←";
                                                                      break;
                                                                  case "arrowright" :
                                                                      tmp = "→";
                                                                      break;
                                                                  case "escape" :
                                                                      tmp = "Esc";
                                                                      break;
                                                                  default:
                                                                    tmp = key.toLocaleUpperCase();
                                                                }
                                                                return React.createElement("div", {
                                                                            key: key,
                                                                            className: shortcut
                                                                          }, tmp);
                                                              }));
                                                })), active ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                      width: 4
                                                    }), React.createElement(IconArrowDownWest.make, {
                                                      size: 12,
                                                      color: Styles.Color.deepBlue
                                                    })) : React.createElement(Spacer.make, {
                                                  width: 16
                                                })), description !== undefined ? React.createElement($$Text.make, {
                                              size: "Small",
                                              singleLine: true,
                                              color: Styles.Color.light09,
                                              children: description
                                            }) : null);
                          }
                        })
                    })));
}

var threshold = 0.0015;

var make = CmdKResults;

export {
  Style ,
  Subtitle ,
  Results ,
  threshold ,
  score ,
  compare ,
  scoreAction ,
  searchItemMatchesSearchCategory ,
  unarchiveItemMatchesUnarchiveCategory ,
  filterNestedActionBySearchQuery ,
  filterRootActionBySearchQuery ,
  filterActionBySearchQuery ,
  filterAndSortActions ,
  convertSearchToAction ,
  convertActions ,
  trackCmdKresults ,
  make ,
}
/* sectionContainer Not a pure module */
