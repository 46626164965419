// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Timestamp from "./Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconBranch from "./IconBranch.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function ObjectActivityLogAction$User(Props) {
  var user = Props.user;
  return React.createElement("b", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(0), Css.px(4)),
                    tl: /* [] */0
                  })
            }, AvoConfig.getUserDisplayName(user));
}

var User = {
  make: ObjectActivityLogAction$User
};

function ObjectActivityLogAction$Content(Props) {
  var hideBranchInfoOpt = Props.hideBranchInfo;
  var action = Props.action;
  var users = Props.users;
  var openBranches = Props.openBranches;
  var children = Props.children;
  var hideBranchInfo = hideBranchInfoOpt !== undefined ? hideBranchInfoOpt : false;
  var match = WorkspaceContext.use(undefined);
  var branchId = action.branchId;
  var maybeOpenBranchName = Belt_List.getByU(openBranches, (function (param) {
          return Caml_obj.equal(param[0], Caml_option.undefined_to_opt(action.branchId));
        }));
  var tmp;
  tmp = maybeOpenBranchName !== undefined || !(branchId !== undefined && branchId !== "master") ? "Skip" : "Fetch";
  var branchState = FirebaseFetcherHooks.useBranchState(tmp, match.id, Belt_Option.getWithDefault(branchId === undefined ? undefined : Caml_option.some(branchId), "NA"));
  var branchName = maybeOpenBranchName !== undefined ? maybeOpenBranchName[1] : (
      branchState !== undefined ? branchState.branchName : undefined
    );
  var match$1 = React.useState(function () {
        return false;
      });
  var setContentPopoverVisible = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setBranchNamePopoverVisible = match$2[1];
  var actionUsers = users.length !== 0 ? React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.marginLeft(Css.px(-4)),
                tl: /* [] */0
              })
        }, Belt_Array.mapWithIndexU(users, (function (index, user) {
                if (index === (users.length - 2 | 0)) {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: user.id
                            }, React.createElement(ObjectActivityLogAction$User, {
                                  user: user
                                }), "and");
                } else if (index === (users.length - 1 | 0)) {
                  return React.createElement(ObjectActivityLogAction$User, {
                              user: user,
                              key: user.id
                            });
                } else {
                  return React.createElement(React.Fragment, {
                              children: null,
                              key: user.id
                            }, React.createElement(ObjectActivityLogAction$User, {
                                  user: user
                                }), ",");
                }
              }))) : null;
  var tmp$1;
  if (hideBranchInfo) {
    tmp$1 = null;
  } else {
    var tmp$2;
    if (branchId !== undefined) {
      if (branchId === "master") {
        tmp$2 = React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.marginLeft(Css.px(5)),
                      tl: /* [] */0
                    }
                  })
            }, "on", React.createElement(Spacer.make, {
                  width: 3
                }), React.createElement(IconBranch.make, {
                  size: 8,
                  color: Styles.Color.light07
                }), React.createElement(Spacer.make, {
                  width: 4
                }), "main");
      } else if (branchName !== undefined) {
        var tmp$3 = branchId === "master" ? "master" : ({
              NAME: "branch",
              VAL: branchId
            });
        tmp$2 = React.createElement(Link.make, {
              path: Router.Link.getSchemaRouteLink(tmp$3, {
                    NAME: "diff",
                    VAL: "index"
                  }),
              children: React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.marginLeft(Css.px(5)),
                            tl: /* [] */0
                          }
                        })
                  }, "on", React.createElement(Spacer.make, {
                        width: 3
                      }), React.createElement(IconBranch.make, {
                        size: 8,
                        color: Styles.Color.light07
                      }), React.createElement(Spacer.make, {
                        width: 4
                      }), React.createElement(Mantine.Popover.make, {
                        opened: match$2[0],
                        position: "bottom",
                        children: null
                      }, React.createElement(Core.Popover.Target, {
                            children: React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.textOverflow("ellipsis"),
                                        tl: {
                                          hd: Css.whiteSpace("nowrap"),
                                          tl: {
                                            hd: Css.overflow("hidden"),
                                            tl: {
                                              hd: Css.hover({
                                                    hd: Css.textDecoration("underline"),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }),
                                  onMouseEnter: (function (param) {
                                      Curry._1(setBranchNamePopoverVisible, (function (param) {
                                              return true;
                                            }));
                                    }),
                                  onMouseLeave: (function (param) {
                                      Curry._1(setBranchNamePopoverVisible, (function (param) {
                                              return false;
                                            }));
                                    })
                                }, branchName)
                          }), React.createElement(Mantine.Popover.Dropdown.make, {
                            children: React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.width("auto"),
                                        tl: {
                                          hd: Css.padding2(Css.px(3), Css.px(6)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.light11),
                                            tl: {
                                              hd: Css.color(Styles.Color.white),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: {
                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                  tl: {
                                                    hd: Css.zIndex(3),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, branchName)
                          })))
            });
      } else {
        tmp$2 = null;
      }
    } else {
      tmp$2 = null;
    }
    tmp$1 = React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.flexShrink(2.0),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.color(Styles.Color.light08),
                    tl: /* [] */0
                  }
                }
              })
        }, tmp$2);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(2), Css.px(45)),
                    tl: {
                      hd: Css.fontSize(Css.px(11)),
                      tl: {
                        hd: Css.color(Styles.Color.light11),
                        tl: {
                          hd: Css.display("flex"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(Mantine.Popover.make, {
                  opened: match$1[0],
                  position: "bottom",
                  children: null
                }, React.createElement(Core.Popover.Target, {
                      children: React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.textOverflow("ellipsis"),
                                  tl: {
                                    hd: Css.whiteSpace("nowrap"),
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: {
                                        hd: Css.flexShrink(1.0),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }),
                            onMouseEnter: (function (param) {
                                Curry._1(setContentPopoverVisible, (function (param) {
                                        return true;
                                      }));
                              }),
                            onMouseLeave: (function (param) {
                                Curry._1(setContentPopoverVisible, (function (param) {
                                        return false;
                                      }));
                              })
                          }, actionUsers, React.createElement("span", undefined, children))
                    }), React.createElement(Mantine.Popover.Dropdown.make, {
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.maxWidth({
                                        NAME: "px",
                                        VAL: 500
                                      }),
                                  tl: {
                                    hd: Css.overflowWrap("breakWord"),
                                    tl: {
                                      hd: Css.padding2(Css.px(3), Css.px(6)),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light11),
                                        tl: {
                                          hd: Css.color(Styles.Color.white),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.borderRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css.zIndex(3),
                                                tl: {
                                                  hd: Css.selector(" b", {
                                                        hd: Css.padding2(Css.px(0), Css.px(3)),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.selector(" i", {
                                                          hd: Css.padding2(Css.px(0), Css.px(3)),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, actionUsers, children)
                    })), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.marginLeft(Css.px(5)),
                            tl: {
                              hd: Css.flexShrink(0.0),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Timestamp.make, {
                      date: action.createdAt
                    })), tmp$1);
}

var Content = {
  make: ObjectActivityLogAction$Content
};

function ObjectActivityLogAction(Props) {
  var action = Props.action;
  var users = Props.users;
  var path = Props.path;
  var openBranches = Props.openBranches;
  var children = Props.children;
  return React.createElement(Link.make, {
              path: path,
              children: React.createElement(ObjectActivityLogAction$Content, {
                    action: action,
                    users: users,
                    openBranches: openBranches,
                    children: children
                  })
            });
}

var make = ObjectActivityLogAction;

export {
  User ,
  Content ,
  make ,
}
/* Css Not a pure module */
