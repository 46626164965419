// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as PlanLightning from "./PlanLightning.mjs";
import * as ContextBoxLazy from "./ContextBoxLazy.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";

var authMethodModalContainerStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(25)),
      tl: {
        hd: Css.textAlign("center"),
        tl: {
          hd: Css.selector("& h3", {
                hd: Css.fontWeight(Styles.FontWeight.bold),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.medium),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.selector("& p", {
                  hd: Css.fontWeight(Styles.FontWeight.regular),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.regular),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var upgradeModal = {
  TAG: /* OpenModal */4,
  _0: {
    NAME: "GenericModal",
    VAL: (function (closeModal) {
        return React.createElement("div", {
                    className: authMethodModalContainerStyles
                  }, React.createElement("h3", undefined, "Enabling new authentication method"), Intercom.isAvailable(undefined) ? React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Reach out in the chat in the bottom right corner or on ", React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: /* [] */0
                                      }),
                                  href: "mailto:support@avo.app",
                                  rel: "noopener",
                                  target: "_blank"
                                }, "support@avo.app"), " and we will help you configure new authentication methods."), React.createElement(Spacer.make, {
                              height: 8
                            }), React.createElement(Button.make, {
                              label: "Contact us",
                              onClick: (function (param) {
                                  Intercom.showNewMessage("Hey! I'd like to enable SSO authentication for my workspace.");
                                  Curry._1(closeModal, undefined);
                                })
                            })) : React.createElement("p", undefined, "Reach out on ", React.createElement("a", {
                              className: Curry._1(Css.style, {
                                    hd: Css.color(Styles.Color.darkBlue),
                                    tl: /* [] */0
                                  }),
                              href: "mailto:support@avo.app",
                              rel: "noopener",
                              target: "_blank"
                            }, "support@avo.app"), " and we will help you configure new authentication methods."));
      })
  }
};

var buttonStyle = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.padding(Css.px(0)),
                  tl: {
                    hd: Css.backgroundImage({
                          NAME: "url",
                          VAL: "'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS4zIDMuNUwzLjggMWwyLjUgMi41TTEuMyA2LjVMMy44IDlsMi41LTIuNSIgc3Ryb2tlPSIjQUFCMkJEIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=='"
                        }),
                    tl: {
                      hd: Css.backgroundRepeat("noRepeat"),
                      tl: {
                        hd: Css.backgroundPosition({
                              NAME: "hv",
                              VAL: [
                                {
                                  NAME: "percent",
                                  VAL: 96.0
                                },
                                "center"
                              ]
                            }),
                        tl: {
                          hd: Css.color(Styles.Color.light10),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: 150.0
                                }, undefined, undefined, "all"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.borderColor(Styles.Color.light07),
                                    tl: {
                                      hd: Css.color(Styles.Color.light12),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.active({
                                      hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.focus({
                                        hd: Css.outlineStyle("none"),
                                        tl: {
                                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: {
                                    hd: Css.width(Css.px(280)),
                                    tl: {
                                      hd: Css.margin(Css.px(0)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var selectedAuthMethodStyle = Curry._1(Css.style, {
      hd: Css.textAlign("left"),
      tl: {
        hd: Css.padding2(Css.px(12), Css.px(16)),
        tl: /* [] */0
      }
    });

var contextBoxStyle = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var contextBoxHeaderStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(1)),
      tl: /* [] */0
    });

function authMethodOptionStyle(isSelected) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.padding4(Css.px(12), Css.px(12), Css.px(12), Css.px(16)),
                              tl: {
                                hd: Css.borderTop(Css.px(1), "solid", isSelected ? "transparent" : Styles.Color.light02),
                                tl: {
                                  hd: Css.color(isSelected ? Styles.Color.darkBlue : Styles.Color.light10),
                                  tl: {
                                    hd: Css.backgroundColor(isSelected ? Styles.Color.light03 : "transparent"),
                                    tl: {
                                      hd: Css_Legacy_Core.SVG.fill("transparent"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.stroke(isSelected ? Styles.Color.darkBlue : Styles.Color.light07),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: 150.0
                                                  }, undefined, undefined, "all"),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.color(Styles.Color.light11),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.darkBlue),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.active({
                                                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                                        tl: /* [] */0
                                                      }),
                                                  tl: {
                                                    hd: Css.focus({
                                                          hd: Css.outlineStyle("none"),
                                                          tl: {
                                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                            tl: {
                                                              hd: Css.zIndex(2),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.selector("& + button", isSelected ? ({
                                                                hd: Css.borderColor("transparent"),
                                                                tl: /* [] */0
                                                              }) : /* [] */0),
                                                      tl: {
                                                        hd: Css.textAlign("left"),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function printAuthMethod(x) {
  if (x === "Google") {
    return "Google SSO";
  } else if (x === "Saml") {
    return "SAML SSO";
  } else {
    return "Email/Password + Google Login";
  }
}

function printAuthMethodDescription(x) {
  if (x === "Google") {
    return "Users can access the workspace with Google Login using their Google org account.";
  } else if (x === "Saml") {
    return "Users can access the workspace with SAML SSO provider.";
  } else {
    return "Invited users can access the workspace with Email/Password or Google Login.";
  }
}

function AuthMethodPicker(Props) {
  var workspace = Props.workspace;
  var globalSend = GlobalSendContext.use(undefined);
  var activeAuthMethod = workspace.authMethod;
  var ssoAvailability = AvoLimits.Sso.computeAvailability(workspace);
  var selectedAuthMethod = React.createElement("div", {
        className: selectedAuthMethodStyle
      }, React.createElement($$Text.make, {
            size: "Small",
            weight: "Semi",
            children: printAuthMethod(activeAuthMethod)
          }));
  return React.createElement(ContextBoxLazy.make, {
              button: React.createElement("button", {
                    className: buttonStyle
                  }, selectedAuthMethod),
              children: (function (close) {
                  return React.createElement("div", {
                              className: contextBoxStyle
                            }, React.createElement("div", {
                                  className: contextBoxHeaderStyle,
                                  onClick: (function (param) {
                                      Curry._1(close, undefined);
                                    })
                                }, selectedAuthMethod), Belt_List.toArray(Belt_List.map({
                                      hd: "Default",
                                      tl: {
                                        hd: "Google",
                                        tl: {
                                          hd: "Saml",
                                          tl: /* [] */0
                                        }
                                      }
                                    }, (function (authMethod) {
                                        return React.createElement("button", {
                                                    key: printAuthMethod(authMethod),
                                                    className: authMethodOptionStyle(authMethod === activeAuthMethod),
                                                    onClick: (function (param) {
                                                        if (authMethod === activeAuthMethod) {
                                                          
                                                        } else if (ssoAvailability === "AvailableDuringTrial" || ssoAvailability === "Available") {
                                                          Curry._1(globalSend, upgradeModal);
                                                        } else {
                                                          Curry._1(globalSend, {
                                                                TAG: /* OpenModal */4,
                                                                _0: {
                                                                  NAME: "BillingPrompt",
                                                                  VAL: "AuthenticationMethods"
                                                                }
                                                              });
                                                        }
                                                        Curry._1(close, undefined);
                                                      })
                                                  }, React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.paddingRight(Css.px(8)),
                                                              tl: /* [] */0
                                                            })
                                                      }, React.createElement($$Text.make, {
                                                            size: "Medium",
                                                            weight: "Semi",
                                                            children: printAuthMethod(authMethod)
                                                          }), React.createElement($$Text.make, {
                                                            size: "Small",
                                                            weight: "Regular",
                                                            children: printAuthMethodDescription(authMethod)
                                                          })), authMethod === "Default" || ssoAvailability === "Available" ? null : (
                                                      ssoAvailability === "AvailableDuringTrial" ? React.createElement(PlanLightning.make, {
                                                              tooltipText: "Available During Trial"
                                                            }) : React.createElement(PlanLightning.make, {
                                                              tooltipText: "Requires Plan Upgrade"
                                                            })
                                                    ));
                                      }))));
                })
            });
}

var make = AuthMethodPicker;

export {
  authMethodModalContainerStyles ,
  upgradeModal ,
  buttonStyle ,
  selectedAuthMethodStyle ,
  contextBoxStyle ,
  contextBoxHeaderStyle ,
  authMethodOptionStyle ,
  printAuthMethod ,
  printAuthMethodDescription ,
  make ,
}
/* authMethodModalContainerStyles Not a pure module */
