import Quill from "quill";
import React from "react";
import ReactDOM from "react-dom";
import EventBlot from "../../../app/src/EventBlot";

const Embed = Quill.import("blots/embed");

class EventMention extends Embed {
  static create(data) {
    const node = super.create();
    node.dataset.id = data.id;
    node.dataset.value = data.value;
    node.dataset.denotationChar = data.denotationChar;
    node.dataset.type = data.type;
    node.dataset.schemaId = data.schemaId;
    ReactDOM.render(
      React.createElement(EventBlot, {
        eventName: data.value,
        eventId: data.id,
        schemaId: data.schemaId,
      }),
      node
    );
    return node;
  }

  static value(domNode) {
    return {
      id: domNode.dataset.id,
      value: domNode.dataset.value,
      denotationChar: domNode.dataset.denotationChar,
      type: domNode.dataset.type,
      schemaId: domNode.dataset.schemaId,
    };
  }
}

EventMention.blotName = "event-mention";
EventMention.tagName = "span";
EventMention.className = "event-mention";

Quill.register(EventMention);
