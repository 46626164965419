// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ModelUtils from "../ModelUtils.mjs";
import * as PropertyUtils from "../PropertyUtils.mjs";
import * as GetRegexValidationUseCase from "../regex/GetRegexValidationUseCase.mjs";
import * as GetAllPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";

function get(model, property) {
  var regexValidation = GetRegexValidationUseCase.get(property);
  if (regexValidation !== undefined) {
    return PropertyUtils.allPropertyRegex(model, regexValidation).join(", ");
  } else {
    return GetAllPropertyValuesUseCase.getWithLegacyFallbackAsString(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), property);
  }
}

export {
  get ,
}
/* ModelUtils Not a pure module */
