// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function appendWorkspaceNameAsNameField(groupProperties) {
  var maybeWorkspaceName = Json_decode.optional((function (param) {
          return Json_decode.field("Workspace Name", Json_decode.string, param);
        }), groupProperties);
  if (maybeWorkspaceName === undefined) {
    return groupProperties;
  }
  var groupPropsDict = Belt_Option.getWithDefault(Js_json.decodeObject(groupProperties), {});
  groupPropsDict["name"] = maybeWorkspaceName;
  return groupPropsDict;
}

function filterNoneFromJsonObject(json) {
  return Js_dict.fromArray(Belt_Array.keep(Js_dict.entries(json), (function (param) {
                    return param[1] !== undefined;
                  })));
}

function appendGroupTypeAsObjectTypeIdField(groupProperties, groupType) {
  var groupTypeId = groupType === "Workspace" ? "1" : "-1";
  var groupPropsDict = Belt_Option.getWithDefault(Js_json.decodeObject(groupProperties), {});
  groupPropsDict["object_type_id"] = groupTypeId;
  return groupPropsDict;
}

export {
  appendWorkspaceNameAsNameField ,
  filterNoneFromJsonObject ,
  appendGroupTypeAsObjectTypeIdField ,
}
/* No side effect */
