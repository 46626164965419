// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Printer from "./Printer.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MappedModules from "../../shared/MappedModules.mjs";
import * as TrackingPlanModel from "./TrackingPlanModel.mjs";

function MakeOrderedMapModuleWithName(C) {
  var include = MappedModules.MakeOrderedMapModule({
        getId: C.getId,
        eq: C.eq
      });
  var get = include.get;
  var getName = function (items, id) {
    return Belt_Option.mapU(Curry._2(get, items, id), (function (item) {
                  return Curry._1(C.getName, item);
                }));
  };
  return {
          MapModule: include.MapModule,
          has: include.has,
          get: get,
          getExn: include.getExn,
          getAtIndex: include.getAtIndex,
          getBy: include.getBy,
          toArray: include.toArray,
          fromArray: include.fromArray,
          toList: include.toList,
          fromList: include.fromList,
          keys: include.keys,
          empty: include.empty,
          update: include.update,
          updateExisting: include.updateExisting,
          prepend: include.prepend,
          append: include.append,
          updateMany: include.updateMany,
          updateManyExisting: include.updateManyExisting,
          mergeMany: include.mergeMany,
          remove: include.remove,
          reduce: include.reduce,
          mapToArray: include.mapToArray,
          mapToMap: include.mapToMap,
          mapToT: include.mapToT,
          forEach: include.forEach,
          keep: include.keep,
          keepMap: include.keepMap,
          some: include.some,
          every: include.every,
          size: include.size,
          sizeToString: include.sizeToString,
          reorderV2: include.reorderV2,
          reorderBuggy: include.reorderBuggy,
          eq: include.eq,
          toArrayWithKeys: include.toArrayWithKeys,
          encodeDict: include.encodeDict,
          slice: include.slice,
          take: include.take,
          getName: getName
        };
}

function MakeOrderedMapModuleForModel(C) {
  var include = MappedModules.MakeOrderedMapModule({
        getId: C.getId,
        eq: C.eq
      });
  var get = include.get;
  var getName = function (items, id) {
    return Belt_Option.mapU(Curry._2(get, items, id), (function (item) {
                  return Curry._1(C.getName, item);
                }));
  };
  var getDescription = function (items, id) {
    return Belt_Option.mapU(Curry._2(get, items, id), (function (item) {
                  return Curry._1(C.getDescription, item);
                }));
  };
  return {
          MapModule: include.MapModule,
          has: include.has,
          get: get,
          getExn: include.getExn,
          getAtIndex: include.getAtIndex,
          getBy: include.getBy,
          toArray: include.toArray,
          fromArray: include.fromArray,
          toList: include.toList,
          fromList: include.fromList,
          keys: include.keys,
          empty: include.empty,
          update: include.update,
          updateExisting: include.updateExisting,
          prepend: include.prepend,
          append: include.append,
          updateMany: include.updateMany,
          updateManyExisting: include.updateManyExisting,
          mergeMany: include.mergeMany,
          remove: include.remove,
          reduce: include.reduce,
          mapToArray: include.mapToArray,
          mapToMap: include.mapToMap,
          mapToT: include.mapToT,
          forEach: include.forEach,
          keep: include.keep,
          keepMap: include.keepMap,
          some: include.some,
          every: include.every,
          size: include.size,
          sizeToString: include.sizeToString,
          reorderV2: include.reorderV2,
          reorderBuggy: include.reorderBuggy,
          eq: include.eq,
          toArrayWithKeys: include.toArrayWithKeys,
          encodeDict: include.encodeDict,
          slice: include.slice,
          take: include.take,
          getName: getName,
          getDescription: getDescription
        };
}

function getId(property) {
  return property.id;
}

function eq(a, b) {
  return JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: a
                })) === JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: b
                }));
}

var include = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

var get = include.get;

var getBy = include.getBy;

var toArray = include.toArray;

var fromArray = include.fromArray;

var fromList = include.fromList;

var empty = include.empty;

var mapToArray = include.mapToArray;

var keep = include.keep;

var eq$1 = include.eq;

function getName(items, id) {
  return Belt_Option.mapU(Curry._2(get, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription(items, id) {
  return Belt_Option.mapU(Curry._2(get, items, id), (function (item) {
                return item.description;
              }));
}

function getPreviousLocalId(property) {
  return Belt_Option.mapU(property.globalRequirementsMetadata, (function (param) {
                return param.fromLocalItemId;
              }));
}

function getPropertiesContainingPropertyId(properties, propertyId) {
  return Curry._2(keep, properties, (function (param) {
                return Belt_List.someU(param.validations, (function (validation) {
                              if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                                return Belt_List.someU(validation.VAL, (function (param) {
                                              return param.id === propertyId;
                                            }));
                              } else {
                                return false;
                              }
                            }));
              }));
}

function getDictKeyedByLocalItemId(properties) {
  return Js_dict.fromArray(Belt_Array.keepMapU(Curry._2(mapToArray, properties, (function (property) {
                        var localId = getPreviousLocalId(property);
                        if (localId !== undefined) {
                          return [
                                  localId,
                                  property
                                ];
                        }
                        
                      })), (function (x) {
                    return x;
                  })));
}

function fromOld(oldProperties) {
  return Curry._1(fromList, Belt_List.keepMapU(oldProperties, (function (property) {
                    if (property.TAG === /* PropertyRef */0) {
                      return ;
                    } else {
                      return property._0;
                    }
                  })));
}

function toOld(properties) {
  return Belt_List.fromArray(Belt_Array.mapU(Curry._1(toArray, properties), (function (property) {
                    return {
                            TAG: /* Property */1,
                            _0: property
                          };
                  })));
}

function getByName(properties, propertyName) {
  return Curry._2(getBy, properties, (function (param) {
                return propertyName === param.name;
              }));
}

function getNestedProperties(properties, propertyId) {
  return Belt_Option.mapWithDefaultU(Curry._2(get, properties, propertyId), empty, (function (property) {
                return Curry._1(fromList, Belt_List.flatten(Belt_List.keepMapU(property.validations, (function (validation) {
                                      if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                                        return Belt_List.keepMapU(validation.VAL, (function (param) {
                                                      return Curry._2(get, properties, param.id);
                                                    }));
                                      }
                                      
                                    }))));
              }));
}

function getNestedProperty(properties, propertyId, nestedPropertyId) {
  return Curry._2(get, getNestedProperties(properties, propertyId), nestedPropertyId);
}

function getObjectChildren(properties, propertyId) {
  var property = Curry._2(get, properties, propertyId);
  if (property !== undefined && property.type_ === "object") {
    return getNestedProperties(properties, property.id);
  } else {
    return empty;
  }
}

var Utils = {
  getDictKeyedByLocalItemId: getDictKeyedByLocalItemId,
  fromOld: fromOld,
  toOld: toOld,
  getByName: getByName,
  getNestedProperties: getNestedProperties,
  getNestedProperty: getNestedProperty,
  getObjectChildren: getObjectChildren
};

var Properties_MapModule = include.MapModule;

var Properties_has = include.has;

var Properties_getExn = include.getExn;

var Properties_getAtIndex = include.getAtIndex;

var Properties_toList = include.toList;

var Properties_keys = include.keys;

var Properties_update = include.update;

var Properties_updateExisting = include.updateExisting;

var Properties_prepend = include.prepend;

var Properties_append = include.append;

var Properties_updateMany = include.updateMany;

var Properties_updateManyExisting = include.updateManyExisting;

var Properties_mergeMany = include.mergeMany;

var Properties_remove = include.remove;

var Properties_reduce = include.reduce;

var Properties_mapToMap = include.mapToMap;

var Properties_mapToT = include.mapToT;

var Properties_forEach = include.forEach;

var Properties_keepMap = include.keepMap;

var Properties_some = include.some;

var Properties_every = include.every;

var Properties_size = include.size;

var Properties_sizeToString = include.sizeToString;

var Properties_reorderV2 = include.reorderV2;

var Properties_reorderBuggy = include.reorderBuggy;

var Properties_toArrayWithKeys = include.toArrayWithKeys;

var Properties_encodeDict = include.encodeDict;

var Properties_slice = include.slice;

var Properties_take = include.take;

var Properties = {
  MapModule: Properties_MapModule,
  has: Properties_has,
  get: get,
  getExn: Properties_getExn,
  getAtIndex: Properties_getAtIndex,
  getBy: getBy,
  toArray: toArray,
  fromArray: fromArray,
  toList: Properties_toList,
  fromList: fromList,
  keys: Properties_keys,
  empty: empty,
  update: Properties_update,
  updateExisting: Properties_updateExisting,
  prepend: Properties_prepend,
  append: Properties_append,
  updateMany: Properties_updateMany,
  updateManyExisting: Properties_updateManyExisting,
  mergeMany: Properties_mergeMany,
  remove: Properties_remove,
  reduce: Properties_reduce,
  mapToArray: mapToArray,
  mapToMap: Properties_mapToMap,
  mapToT: Properties_mapToT,
  forEach: Properties_forEach,
  keep: keep,
  keepMap: Properties_keepMap,
  some: Properties_some,
  every: Properties_every,
  size: Properties_size,
  sizeToString: Properties_sizeToString,
  reorderV2: Properties_reorderV2,
  reorderBuggy: Properties_reorderBuggy,
  eq: eq$1,
  toArrayWithKeys: Properties_toArrayWithKeys,
  encodeDict: Properties_encodeDict,
  slice: Properties_slice,
  take: Properties_take,
  getName: getName,
  getDescription: getDescription,
  getPreviousLocalId: getPreviousLocalId,
  getPropertiesContainingPropertyId: getPropertiesContainingPropertyId,
  Utils: Utils
};

function getId$1(propertyBundle) {
  return propertyBundle.id;
}

function eq$2(a, b) {
  return JSON.stringify(Printer.printPropertyBundle(a)) === JSON.stringify(Printer.printPropertyBundle(b));
}

var include$1 = MappedModules.MakeOrderedMapModule({
      getId: getId$1,
      eq: eq$2
    });

var get$1 = include$1.get;

var getBy$1 = include$1.getBy;

var toArray$1 = include$1.toArray;

var fromList$1 = include$1.fromList;

var empty$1 = include$1.empty;

var mapToArray$1 = include$1.mapToArray;

var eq$3 = include$1.eq;

function getName$1(items, id) {
  return Belt_Option.mapU(Curry._2(get$1, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription$1(items, id) {
  return Belt_Option.mapU(Curry._2(get$1, items, id), (function (item) {
                return item.description;
              }));
}

function getPreviousLocalId$1(propertyBundle) {
  return Belt_Option.mapU(propertyBundle.globalRequirementsMetadata, (function (param) {
                return param.fromLocalItemId;
              }));
}

function getDictKeyedByLocalItemId$1(propertyBundles) {
  return Js_dict.fromArray(Belt_Array.keepMapU(Curry._2(mapToArray$1, propertyBundles, (function (propertyBundle) {
                        var localId = getPreviousLocalId$1(propertyBundle);
                        if (localId !== undefined) {
                          return [
                                  localId,
                                  propertyBundle
                                ];
                        }
                        
                      })), (function (x) {
                    return x;
                  })));
}

function getByName$1(propertyBundles, propertyBundleName) {
  return Curry._2(getBy$1, propertyBundles, (function (param) {
                return param.name === propertyBundleName;
              }));
}

function getProperties(propertyBundles, properties, propertyBundleId) {
  return Belt_Option.mapU(Curry._2(get$1, propertyBundles, propertyBundleId), (function (param) {
                return Belt_List.keepMapU(param.properties, (function (propertyId) {
                              return Curry._2(get, properties, propertyId);
                            }));
              }));
}

var Utils$1 = {
  getDictKeyedByLocalItemId: getDictKeyedByLocalItemId$1,
  getByName: getByName$1,
  getProperties: getProperties
};

var PropertyBundles_MapModule = include$1.MapModule;

var PropertyBundles_has = include$1.has;

var PropertyBundles_getExn = include$1.getExn;

var PropertyBundles_getAtIndex = include$1.getAtIndex;

var PropertyBundles_fromArray = include$1.fromArray;

var PropertyBundles_toList = include$1.toList;

var PropertyBundles_keys = include$1.keys;

var PropertyBundles_update = include$1.update;

var PropertyBundles_updateExisting = include$1.updateExisting;

var PropertyBundles_prepend = include$1.prepend;

var PropertyBundles_append = include$1.append;

var PropertyBundles_updateMany = include$1.updateMany;

var PropertyBundles_updateManyExisting = include$1.updateManyExisting;

var PropertyBundles_mergeMany = include$1.mergeMany;

var PropertyBundles_remove = include$1.remove;

var PropertyBundles_reduce = include$1.reduce;

var PropertyBundles_mapToMap = include$1.mapToMap;

var PropertyBundles_mapToT = include$1.mapToT;

var PropertyBundles_forEach = include$1.forEach;

var PropertyBundles_keep = include$1.keep;

var PropertyBundles_keepMap = include$1.keepMap;

var PropertyBundles_some = include$1.some;

var PropertyBundles_every = include$1.every;

var PropertyBundles_size = include$1.size;

var PropertyBundles_sizeToString = include$1.sizeToString;

var PropertyBundles_reorderV2 = include$1.reorderV2;

var PropertyBundles_reorderBuggy = include$1.reorderBuggy;

var PropertyBundles_toArrayWithKeys = include$1.toArrayWithKeys;

var PropertyBundles_encodeDict = include$1.encodeDict;

var PropertyBundles_slice = include$1.slice;

var PropertyBundles_take = include$1.take;

var PropertyBundles = {
  MapModule: PropertyBundles_MapModule,
  has: PropertyBundles_has,
  get: get$1,
  getExn: PropertyBundles_getExn,
  getAtIndex: PropertyBundles_getAtIndex,
  getBy: getBy$1,
  toArray: toArray$1,
  fromArray: PropertyBundles_fromArray,
  toList: PropertyBundles_toList,
  fromList: fromList$1,
  keys: PropertyBundles_keys,
  empty: empty$1,
  update: PropertyBundles_update,
  updateExisting: PropertyBundles_updateExisting,
  prepend: PropertyBundles_prepend,
  append: PropertyBundles_append,
  updateMany: PropertyBundles_updateMany,
  updateManyExisting: PropertyBundles_updateManyExisting,
  mergeMany: PropertyBundles_mergeMany,
  remove: PropertyBundles_remove,
  reduce: PropertyBundles_reduce,
  mapToArray: mapToArray$1,
  mapToMap: PropertyBundles_mapToMap,
  mapToT: PropertyBundles_mapToT,
  forEach: PropertyBundles_forEach,
  keep: PropertyBundles_keep,
  keepMap: PropertyBundles_keepMap,
  some: PropertyBundles_some,
  every: PropertyBundles_every,
  size: PropertyBundles_size,
  sizeToString: PropertyBundles_sizeToString,
  reorderV2: PropertyBundles_reorderV2,
  reorderBuggy: PropertyBundles_reorderBuggy,
  eq: eq$3,
  toArrayWithKeys: PropertyBundles_toArrayWithKeys,
  encodeDict: PropertyBundles_encodeDict,
  slice: PropertyBundles_slice,
  take: PropertyBundles_take,
  getName: getName$1,
  getDescription: getDescription$1,
  getPreviousLocalId: getPreviousLocalId$1,
  Utils: Utils$1
};

function getId$2($$event) {
  return $$event.id;
}

function eq$4(a, b) {
  return JSON.stringify(Printer.printEvent(a)) === JSON.stringify(Printer.printEvent(b));
}

var include$2 = MappedModules.MakeOrderedMapModule({
      getId: getId$2,
      eq: eq$4
    });

var get$2 = include$2.get;

var getBy$2 = include$2.getBy;

var toArray$2 = include$2.toArray;

var fromArray$1 = include$2.fromArray;

var fromList$2 = include$2.fromList;

var empty$2 = include$2.empty;

var mapToArray$2 = include$2.mapToArray;

var eq$5 = include$2.eq;

function getName$2(items, id) {
  return Belt_Option.mapU(Curry._2(get$2, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription$2(items, id) {
  return Belt_Option.mapU(Curry._2(get$2, items, id), (function (item) {
                return item.description;
              }));
}

function getPreviousLocalId$2($$event) {
  return Belt_Option.mapU($$event.globalRequirementsMetadata, (function (param) {
                return param.fromLocalItemId;
              }));
}

function getDictKeyedByLocalItemId$2(events) {
  return Js_dict.fromArray(Belt_Array.keepMapU(Curry._2(mapToArray$2, events, (function ($$event) {
                        var localId = getPreviousLocalId$2($$event);
                        if (localId !== undefined) {
                          return [
                                  localId,
                                  $$event
                                ];
                        }
                        
                      })), (function (x) {
                    return x;
                  })));
}

function getByName$2(events, eventName) {
  return Curry._2(getBy$2, events, (function (param) {
                return param.name === eventName;
              }));
}

function getTrigger(triggerId, $$event) {
  return Belt_Array.getBy($$event.triggers, (function (trigger) {
                return trigger.id === triggerId;
              }));
}

function getTriggerByEventId(events, eventId, triggerId) {
  return Belt_Option.flatMapU(Curry._2(get$2, events, eventId), (function ($$event) {
                return getTrigger(triggerId, $$event);
              }));
}

function getDirectProperties($$event, properties) {
  return Belt_List.keepMapU($$event.directPropertyRefs, (function (p) {
                if (p.TAG === /* PropertyRef */0) {
                  return Curry._2(get, properties, p._0.id);
                }
                
              }));
}

var Utils$2 = {
  getDictKeyedByLocalItemId: getDictKeyedByLocalItemId$2,
  getByName: getByName$2,
  getTrigger: getTrigger,
  getTriggerByEventId: getTriggerByEventId,
  getDirectProperties: getDirectProperties
};

var Events_MapModule = include$2.MapModule;

var Events_has = include$2.has;

var Events_getExn = include$2.getExn;

var Events_getAtIndex = include$2.getAtIndex;

var Events_toList = include$2.toList;

var Events_keys = include$2.keys;

var Events_update = include$2.update;

var Events_updateExisting = include$2.updateExisting;

var Events_prepend = include$2.prepend;

var Events_append = include$2.append;

var Events_updateMany = include$2.updateMany;

var Events_updateManyExisting = include$2.updateManyExisting;

var Events_mergeMany = include$2.mergeMany;

var Events_remove = include$2.remove;

var Events_reduce = include$2.reduce;

var Events_mapToMap = include$2.mapToMap;

var Events_mapToT = include$2.mapToT;

var Events_forEach = include$2.forEach;

var Events_keep = include$2.keep;

var Events_keepMap = include$2.keepMap;

var Events_some = include$2.some;

var Events_every = include$2.every;

var Events_size = include$2.size;

var Events_sizeToString = include$2.sizeToString;

var Events_reorderV2 = include$2.reorderV2;

var Events_reorderBuggy = include$2.reorderBuggy;

var Events_toArrayWithKeys = include$2.toArrayWithKeys;

var Events_encodeDict = include$2.encodeDict;

var Events_slice = include$2.slice;

var Events_take = include$2.take;

var Events = {
  MapModule: Events_MapModule,
  has: Events_has,
  get: get$2,
  getExn: Events_getExn,
  getAtIndex: Events_getAtIndex,
  getBy: getBy$2,
  toArray: toArray$2,
  fromArray: fromArray$1,
  toList: Events_toList,
  fromList: fromList$2,
  keys: Events_keys,
  empty: empty$2,
  update: Events_update,
  updateExisting: Events_updateExisting,
  prepend: Events_prepend,
  append: Events_append,
  updateMany: Events_updateMany,
  updateManyExisting: Events_updateManyExisting,
  mergeMany: Events_mergeMany,
  remove: Events_remove,
  reduce: Events_reduce,
  mapToArray: mapToArray$2,
  mapToMap: Events_mapToMap,
  mapToT: Events_mapToT,
  forEach: Events_forEach,
  keep: Events_keep,
  keepMap: Events_keepMap,
  some: Events_some,
  every: Events_every,
  size: Events_size,
  sizeToString: Events_sizeToString,
  reorderV2: Events_reorderV2,
  reorderBuggy: Events_reorderBuggy,
  eq: eq$5,
  toArrayWithKeys: Events_toArrayWithKeys,
  encodeDict: Events_encodeDict,
  slice: Events_slice,
  take: Events_take,
  getName: getName$2,
  getDescription: getDescription$2,
  getPreviousLocalId: getPreviousLocalId$2,
  Utils: Utils$2
};

function resolveName(source) {
  return Belt_Option.mapWithDefault(source.name, "Untitled Source", (function (name) {
                if (name.trim() === "") {
                  return "Untitled Source";
                } else {
                  return name;
                }
              }));
}

function getId$3(source) {
  return source.id;
}

function eq$6(a, b) {
  return JSON.stringify(Printer.printSource(a)) === JSON.stringify(Printer.printSource(b));
}

var include$3 = MappedModules.MakeOrderedMapModule({
      getId: getId$3,
      eq: eq$6
    });

var get$3 = include$3.get;

var toArray$3 = include$3.toArray;

var fromList$3 = include$3.fromList;

var empty$3 = include$3.empty;

var eq$7 = include$3.eq;

function getName$3(items, id) {
  return Belt_Option.mapU(Curry._2(get$3, items, id), resolveName);
}

function getDestinationIds(sources, sourceId) {
  return Belt_Option.mapU(Curry._2(get$3, sources, sourceId), (function (param) {
                return Belt_Array.mapU(Belt_List.toArray(param.destinations), (function (param) {
                              return param.destinationId;
                            }));
              }));
}

function getDestinationRef(sources, sourceId, destinationId) {
  return Belt_Option.flatMapU(Curry._2(get$3, sources, sourceId), (function (source) {
                return Belt_List.getByU(source.destinations, (function (param) {
                              return param.destinationId === destinationId;
                            }));
              }));
}

var Utils$3 = {
  resolveName: resolveName,
  getDestinationIds: getDestinationIds,
  getDestinationRef: getDestinationRef
};

var Sources_MapModule = include$3.MapModule;

var Sources_has = include$3.has;

var Sources_getExn = include$3.getExn;

var Sources_getAtIndex = include$3.getAtIndex;

var Sources_getBy = include$3.getBy;

var Sources_fromArray = include$3.fromArray;

var Sources_toList = include$3.toList;

var Sources_keys = include$3.keys;

var Sources_update = include$3.update;

var Sources_updateExisting = include$3.updateExisting;

var Sources_prepend = include$3.prepend;

var Sources_append = include$3.append;

var Sources_updateMany = include$3.updateMany;

var Sources_updateManyExisting = include$3.updateManyExisting;

var Sources_mergeMany = include$3.mergeMany;

var Sources_remove = include$3.remove;

var Sources_reduce = include$3.reduce;

var Sources_mapToArray = include$3.mapToArray;

var Sources_mapToMap = include$3.mapToMap;

var Sources_mapToT = include$3.mapToT;

var Sources_forEach = include$3.forEach;

var Sources_keep = include$3.keep;

var Sources_keepMap = include$3.keepMap;

var Sources_some = include$3.some;

var Sources_every = include$3.every;

var Sources_size = include$3.size;

var Sources_sizeToString = include$3.sizeToString;

var Sources_reorderV2 = include$3.reorderV2;

var Sources_reorderBuggy = include$3.reorderBuggy;

var Sources_toArrayWithKeys = include$3.toArrayWithKeys;

var Sources_encodeDict = include$3.encodeDict;

var Sources_slice = include$3.slice;

var Sources_take = include$3.take;

var Sources = {
  MapModule: Sources_MapModule,
  has: Sources_has,
  get: get$3,
  getExn: Sources_getExn,
  getAtIndex: Sources_getAtIndex,
  getBy: Sources_getBy,
  toArray: toArray$3,
  fromArray: Sources_fromArray,
  toList: Sources_toList,
  fromList: fromList$3,
  keys: Sources_keys,
  empty: empty$3,
  update: Sources_update,
  updateExisting: Sources_updateExisting,
  prepend: Sources_prepend,
  append: Sources_append,
  updateMany: Sources_updateMany,
  updateManyExisting: Sources_updateManyExisting,
  mergeMany: Sources_mergeMany,
  remove: Sources_remove,
  reduce: Sources_reduce,
  mapToArray: Sources_mapToArray,
  mapToMap: Sources_mapToMap,
  mapToT: Sources_mapToT,
  forEach: Sources_forEach,
  keep: Sources_keep,
  keepMap: Sources_keepMap,
  some: Sources_some,
  every: Sources_every,
  size: Sources_size,
  sizeToString: Sources_sizeToString,
  reorderV2: Sources_reorderV2,
  reorderBuggy: Sources_reorderBuggy,
  eq: eq$7,
  toArrayWithKeys: Sources_toArrayWithKeys,
  encodeDict: Sources_encodeDict,
  slice: Sources_slice,
  take: Sources_take,
  getName: getName$3,
  Utils: Utils$3
};

function getId$4(destination) {
  return destination.id;
}

function eq$8(a, b) {
  return JSON.stringify(Printer.printDestination(a)) === JSON.stringify(Printer.printDestination(b));
}

var include$4 = MappedModules.MakeOrderedMapModule({
      getId: getId$4,
      eq: eq$8
    });

var get$4 = include$4.get;

var toArray$4 = include$4.toArray;

var fromList$4 = include$4.fromList;

var empty$4 = include$4.empty;

var eq$9 = include$4.eq;

function getName$4(items, id) {
  return Belt_Option.mapU(Curry._2(get$4, items, id), (function (item) {
                var defaultDestinationName = "Untitled Destination";
                var name = item.name;
                if (name !== undefined && name.trim() === "") {
                  return name;
                } else {
                  return defaultDestinationName;
                }
              }));
}

var Destinations_MapModule = include$4.MapModule;

var Destinations_has = include$4.has;

var Destinations_getExn = include$4.getExn;

var Destinations_getAtIndex = include$4.getAtIndex;

var Destinations_getBy = include$4.getBy;

var Destinations_fromArray = include$4.fromArray;

var Destinations_toList = include$4.toList;

var Destinations_keys = include$4.keys;

var Destinations_update = include$4.update;

var Destinations_updateExisting = include$4.updateExisting;

var Destinations_prepend = include$4.prepend;

var Destinations_append = include$4.append;

var Destinations_updateMany = include$4.updateMany;

var Destinations_updateManyExisting = include$4.updateManyExisting;

var Destinations_mergeMany = include$4.mergeMany;

var Destinations_remove = include$4.remove;

var Destinations_reduce = include$4.reduce;

var Destinations_mapToArray = include$4.mapToArray;

var Destinations_mapToMap = include$4.mapToMap;

var Destinations_mapToT = include$4.mapToT;

var Destinations_forEach = include$4.forEach;

var Destinations_keep = include$4.keep;

var Destinations_keepMap = include$4.keepMap;

var Destinations_some = include$4.some;

var Destinations_every = include$4.every;

var Destinations_size = include$4.size;

var Destinations_sizeToString = include$4.sizeToString;

var Destinations_reorderV2 = include$4.reorderV2;

var Destinations_reorderBuggy = include$4.reorderBuggy;

var Destinations_toArrayWithKeys = include$4.toArrayWithKeys;

var Destinations_encodeDict = include$4.encodeDict;

var Destinations_slice = include$4.slice;

var Destinations_take = include$4.take;

var Destinations = {
  MapModule: Destinations_MapModule,
  has: Destinations_has,
  get: get$4,
  getExn: Destinations_getExn,
  getAtIndex: Destinations_getAtIndex,
  getBy: Destinations_getBy,
  toArray: toArray$4,
  fromArray: Destinations_fromArray,
  toList: Destinations_toList,
  fromList: fromList$4,
  keys: Destinations_keys,
  empty: empty$4,
  update: Destinations_update,
  updateExisting: Destinations_updateExisting,
  prepend: Destinations_prepend,
  append: Destinations_append,
  updateMany: Destinations_updateMany,
  updateManyExisting: Destinations_updateManyExisting,
  mergeMany: Destinations_mergeMany,
  remove: Destinations_remove,
  reduce: Destinations_reduce,
  mapToArray: Destinations_mapToArray,
  mapToMap: Destinations_mapToMap,
  mapToT: Destinations_mapToT,
  forEach: Destinations_forEach,
  keep: Destinations_keep,
  keepMap: Destinations_keepMap,
  some: Destinations_some,
  every: Destinations_every,
  size: Destinations_size,
  sizeToString: Destinations_sizeToString,
  reorderV2: Destinations_reorderV2,
  reorderBuggy: Destinations_reorderBuggy,
  eq: eq$9,
  toArrayWithKeys: Destinations_toArrayWithKeys,
  encodeDict: Destinations_encodeDict,
  slice: Destinations_slice,
  take: Destinations_take,
  getName: getName$4
};

function getId$5(groupType) {
  return groupType.id;
}

function eq$10(a, b) {
  return JSON.stringify(Printer.printGroupType(a)) === JSON.stringify(Printer.printGroupType(b));
}

var include$5 = MappedModules.MakeOrderedMapModule({
      getId: getId$5,
      eq: eq$10
    });

var get$5 = include$5.get;

var toArray$5 = include$5.toArray;

var fromArray$2 = include$5.fromArray;

var empty$5 = include$5.empty;

var eq$11 = include$5.eq;

function getName$5(items, id) {
  return Belt_Option.mapU(Curry._2(get$5, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription$3(items, id) {
  return Belt_Option.mapU(Curry._2(get$5, items, id), (function (item) {
                return item.description;
              }));
}

var GroupTypes_MapModule = include$5.MapModule;

var GroupTypes_has = include$5.has;

var GroupTypes_getExn = include$5.getExn;

var GroupTypes_getAtIndex = include$5.getAtIndex;

var GroupTypes_getBy = include$5.getBy;

var GroupTypes_toList = include$5.toList;

var GroupTypes_fromList = include$5.fromList;

var GroupTypes_keys = include$5.keys;

var GroupTypes_update = include$5.update;

var GroupTypes_updateExisting = include$5.updateExisting;

var GroupTypes_prepend = include$5.prepend;

var GroupTypes_append = include$5.append;

var GroupTypes_updateMany = include$5.updateMany;

var GroupTypes_updateManyExisting = include$5.updateManyExisting;

var GroupTypes_mergeMany = include$5.mergeMany;

var GroupTypes_remove = include$5.remove;

var GroupTypes_reduce = include$5.reduce;

var GroupTypes_mapToArray = include$5.mapToArray;

var GroupTypes_mapToMap = include$5.mapToMap;

var GroupTypes_mapToT = include$5.mapToT;

var GroupTypes_forEach = include$5.forEach;

var GroupTypes_keep = include$5.keep;

var GroupTypes_keepMap = include$5.keepMap;

var GroupTypes_some = include$5.some;

var GroupTypes_every = include$5.every;

var GroupTypes_size = include$5.size;

var GroupTypes_sizeToString = include$5.sizeToString;

var GroupTypes_reorderV2 = include$5.reorderV2;

var GroupTypes_reorderBuggy = include$5.reorderBuggy;

var GroupTypes_toArrayWithKeys = include$5.toArrayWithKeys;

var GroupTypes_encodeDict = include$5.encodeDict;

var GroupTypes_slice = include$5.slice;

var GroupTypes_take = include$5.take;

var GroupTypes = {
  MapModule: GroupTypes_MapModule,
  has: GroupTypes_has,
  get: get$5,
  getExn: GroupTypes_getExn,
  getAtIndex: GroupTypes_getAtIndex,
  getBy: GroupTypes_getBy,
  toArray: toArray$5,
  fromArray: fromArray$2,
  toList: GroupTypes_toList,
  fromList: GroupTypes_fromList,
  keys: GroupTypes_keys,
  empty: empty$5,
  update: GroupTypes_update,
  updateExisting: GroupTypes_updateExisting,
  prepend: GroupTypes_prepend,
  append: GroupTypes_append,
  updateMany: GroupTypes_updateMany,
  updateManyExisting: GroupTypes_updateManyExisting,
  mergeMany: GroupTypes_mergeMany,
  remove: GroupTypes_remove,
  reduce: GroupTypes_reduce,
  mapToArray: GroupTypes_mapToArray,
  mapToMap: GroupTypes_mapToMap,
  mapToT: GroupTypes_mapToT,
  forEach: GroupTypes_forEach,
  keep: GroupTypes_keep,
  keepMap: GroupTypes_keepMap,
  some: GroupTypes_some,
  every: GroupTypes_every,
  size: GroupTypes_size,
  sizeToString: GroupTypes_sizeToString,
  reorderV2: GroupTypes_reorderV2,
  reorderBuggy: GroupTypes_reorderBuggy,
  eq: eq$11,
  toArrayWithKeys: GroupTypes_toArrayWithKeys,
  encodeDict: GroupTypes_encodeDict,
  slice: GroupTypes_slice,
  take: GroupTypes_take,
  getName: getName$5,
  getDescription: getDescription$3
};

function getId$6(item) {
  return item.id;
}

function eq$12(a, b) {
  return JSON.stringify(Printer.printGoal(a)) === JSON.stringify(Printer.printGoal(b));
}

var include$6 = MappedModules.MakeOrderedMapModule({
      getId: getId$6,
      eq: eq$12
    });

var get$6 = include$6.get;

var toArray$6 = include$6.toArray;

var fromList$5 = include$6.fromList;

var empty$6 = include$6.empty;

var eq$13 = include$6.eq;

function getName$6(items, id) {
  return Belt_Option.mapU(Curry._2(get$6, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription$4(items, id) {
  return Belt_Option.mapU(Curry._2(get$6, items, id), (function (item) {
                return item.description;
              }));
}

var Categories_MapModule = include$6.MapModule;

var Categories_has = include$6.has;

var Categories_getExn = include$6.getExn;

var Categories_getAtIndex = include$6.getAtIndex;

var Categories_getBy = include$6.getBy;

var Categories_fromArray = include$6.fromArray;

var Categories_toList = include$6.toList;

var Categories_keys = include$6.keys;

var Categories_update = include$6.update;

var Categories_updateExisting = include$6.updateExisting;

var Categories_prepend = include$6.prepend;

var Categories_append = include$6.append;

var Categories_updateMany = include$6.updateMany;

var Categories_updateManyExisting = include$6.updateManyExisting;

var Categories_mergeMany = include$6.mergeMany;

var Categories_remove = include$6.remove;

var Categories_reduce = include$6.reduce;

var Categories_mapToArray = include$6.mapToArray;

var Categories_mapToMap = include$6.mapToMap;

var Categories_mapToT = include$6.mapToT;

var Categories_forEach = include$6.forEach;

var Categories_keep = include$6.keep;

var Categories_keepMap = include$6.keepMap;

var Categories_some = include$6.some;

var Categories_every = include$6.every;

var Categories_size = include$6.size;

var Categories_sizeToString = include$6.sizeToString;

var Categories_reorderV2 = include$6.reorderV2;

var Categories_reorderBuggy = include$6.reorderBuggy;

var Categories_toArrayWithKeys = include$6.toArrayWithKeys;

var Categories_encodeDict = include$6.encodeDict;

var Categories_slice = include$6.slice;

var Categories_take = include$6.take;

var Categories = {
  MapModule: Categories_MapModule,
  has: Categories_has,
  get: get$6,
  getExn: Categories_getExn,
  getAtIndex: Categories_getAtIndex,
  getBy: Categories_getBy,
  toArray: toArray$6,
  fromArray: Categories_fromArray,
  toList: Categories_toList,
  fromList: fromList$5,
  keys: Categories_keys,
  empty: empty$6,
  update: Categories_update,
  updateExisting: Categories_updateExisting,
  prepend: Categories_prepend,
  append: Categories_append,
  updateMany: Categories_updateMany,
  updateManyExisting: Categories_updateManyExisting,
  mergeMany: Categories_mergeMany,
  remove: Categories_remove,
  reduce: Categories_reduce,
  mapToArray: Categories_mapToArray,
  mapToMap: Categories_mapToMap,
  mapToT: Categories_mapToT,
  forEach: Categories_forEach,
  keep: Categories_keep,
  keepMap: Categories_keepMap,
  some: Categories_some,
  every: Categories_every,
  size: Categories_size,
  sizeToString: Categories_sizeToString,
  reorderV2: Categories_reorderV2,
  reorderBuggy: Categories_reorderBuggy,
  eq: eq$13,
  toArrayWithKeys: Categories_toArrayWithKeys,
  encodeDict: Categories_encodeDict,
  slice: Categories_slice,
  take: Categories_take,
  getName: getName$6,
  getDescription: getDescription$4
};

function getId$7(metric) {
  return metric.id;
}

function eq$14(a, b) {
  return JSON.stringify(Printer.printMetric(a)) === JSON.stringify(Printer.printMetric(b));
}

var include$7 = MappedModules.MakeOrderedMapModule({
      getId: getId$7,
      eq: eq$14
    });

var get$7 = include$7.get;

var toArray$7 = include$7.toArray;

var fromList$6 = include$7.fromList;

var empty$7 = include$7.empty;

var eq$15 = include$7.eq;

function getName$7(items, id) {
  return Belt_Option.mapU(Curry._2(get$7, items, id), (function (item) {
                return item.name;
              }));
}

function getDescription$5(items, id) {
  return Belt_Option.mapU(Curry._2(get$7, items, id), (function (item) {
                return item.description;
              }));
}

var Metrics_MapModule = include$7.MapModule;

var Metrics_has = include$7.has;

var Metrics_getExn = include$7.getExn;

var Metrics_getAtIndex = include$7.getAtIndex;

var Metrics_getBy = include$7.getBy;

var Metrics_fromArray = include$7.fromArray;

var Metrics_toList = include$7.toList;

var Metrics_keys = include$7.keys;

var Metrics_update = include$7.update;

var Metrics_updateExisting = include$7.updateExisting;

var Metrics_prepend = include$7.prepend;

var Metrics_append = include$7.append;

var Metrics_updateMany = include$7.updateMany;

var Metrics_updateManyExisting = include$7.updateManyExisting;

var Metrics_mergeMany = include$7.mergeMany;

var Metrics_remove = include$7.remove;

var Metrics_reduce = include$7.reduce;

var Metrics_mapToArray = include$7.mapToArray;

var Metrics_mapToMap = include$7.mapToMap;

var Metrics_mapToT = include$7.mapToT;

var Metrics_forEach = include$7.forEach;

var Metrics_keep = include$7.keep;

var Metrics_keepMap = include$7.keepMap;

var Metrics_some = include$7.some;

var Metrics_every = include$7.every;

var Metrics_size = include$7.size;

var Metrics_sizeToString = include$7.sizeToString;

var Metrics_reorderV2 = include$7.reorderV2;

var Metrics_reorderBuggy = include$7.reorderBuggy;

var Metrics_toArrayWithKeys = include$7.toArrayWithKeys;

var Metrics_encodeDict = include$7.encodeDict;

var Metrics_slice = include$7.slice;

var Metrics_take = include$7.take;

var Metrics = {
  MapModule: Metrics_MapModule,
  has: Metrics_has,
  get: get$7,
  getExn: Metrics_getExn,
  getAtIndex: Metrics_getAtIndex,
  getBy: Metrics_getBy,
  toArray: toArray$7,
  fromArray: Metrics_fromArray,
  toList: Metrics_toList,
  fromList: fromList$6,
  keys: Metrics_keys,
  empty: empty$7,
  update: Metrics_update,
  updateExisting: Metrics_updateExisting,
  prepend: Metrics_prepend,
  append: Metrics_append,
  updateMany: Metrics_updateMany,
  updateManyExisting: Metrics_updateManyExisting,
  mergeMany: Metrics_mergeMany,
  remove: Metrics_remove,
  reduce: Metrics_reduce,
  mapToArray: Metrics_mapToArray,
  mapToMap: Metrics_mapToMap,
  mapToT: Metrics_mapToT,
  forEach: Metrics_forEach,
  keep: Metrics_keep,
  keepMap: Metrics_keepMap,
  some: Metrics_some,
  every: Metrics_every,
  size: Metrics_size,
  sizeToString: Metrics_sizeToString,
  reorderV2: Metrics_reorderV2,
  reorderBuggy: Metrics_reorderBuggy,
  eq: eq$15,
  toArrayWithKeys: Metrics_toArrayWithKeys,
  encodeDict: Metrics_encodeDict,
  slice: Metrics_slice,
  take: Metrics_take,
  getName: getName$7,
  getDescription: getDescription$5
};

function getId$8(prim) {
  return prim[0];
}

function eq$16(a, b) {
  if (Caml_obj.equal(a[0], b[0])) {
    return Caml_obj.equal(a[1], b[1]);
  } else {
    return false;
  }
}

var include$8 = MappedModules.MakeOrderedMapModule({
      getId: getId$8,
      eq: eq$16
    });

var get$8 = include$8.get;

var toArray$8 = include$8.toArray;

var fromList$7 = include$8.fromList;

var eq$17 = include$8.eq;

function getName$8(items, id) {
  return Belt_Option.mapU(Curry._2(get$8, items, id), (function (item) {
                return item[1];
              }));
}

var OpenBranches_MapModule = include$8.MapModule;

var OpenBranches_has = include$8.has;

var OpenBranches_getExn = include$8.getExn;

var OpenBranches_getAtIndex = include$8.getAtIndex;

var OpenBranches_getBy = include$8.getBy;

var OpenBranches_fromArray = include$8.fromArray;

var OpenBranches_toList = include$8.toList;

var OpenBranches_keys = include$8.keys;

var OpenBranches_empty = include$8.empty;

var OpenBranches_update = include$8.update;

var OpenBranches_updateExisting = include$8.updateExisting;

var OpenBranches_prepend = include$8.prepend;

var OpenBranches_append = include$8.append;

var OpenBranches_updateMany = include$8.updateMany;

var OpenBranches_updateManyExisting = include$8.updateManyExisting;

var OpenBranches_mergeMany = include$8.mergeMany;

var OpenBranches_remove = include$8.remove;

var OpenBranches_reduce = include$8.reduce;

var OpenBranches_mapToArray = include$8.mapToArray;

var OpenBranches_mapToMap = include$8.mapToMap;

var OpenBranches_mapToT = include$8.mapToT;

var OpenBranches_forEach = include$8.forEach;

var OpenBranches_keep = include$8.keep;

var OpenBranches_keepMap = include$8.keepMap;

var OpenBranches_some = include$8.some;

var OpenBranches_every = include$8.every;

var OpenBranches_size = include$8.size;

var OpenBranches_sizeToString = include$8.sizeToString;

var OpenBranches_reorderV2 = include$8.reorderV2;

var OpenBranches_reorderBuggy = include$8.reorderBuggy;

var OpenBranches_toArrayWithKeys = include$8.toArrayWithKeys;

var OpenBranches_encodeDict = include$8.encodeDict;

var OpenBranches_slice = include$8.slice;

var OpenBranches_take = include$8.take;

var OpenBranches = {
  MapModule: OpenBranches_MapModule,
  has: OpenBranches_has,
  get: get$8,
  getExn: OpenBranches_getExn,
  getAtIndex: OpenBranches_getAtIndex,
  getBy: OpenBranches_getBy,
  toArray: toArray$8,
  fromArray: OpenBranches_fromArray,
  toList: OpenBranches_toList,
  fromList: fromList$7,
  keys: OpenBranches_keys,
  empty: OpenBranches_empty,
  update: OpenBranches_update,
  updateExisting: OpenBranches_updateExisting,
  prepend: OpenBranches_prepend,
  append: OpenBranches_append,
  updateMany: OpenBranches_updateMany,
  updateManyExisting: OpenBranches_updateManyExisting,
  mergeMany: OpenBranches_mergeMany,
  remove: OpenBranches_remove,
  reduce: OpenBranches_reduce,
  mapToArray: OpenBranches_mapToArray,
  mapToMap: OpenBranches_mapToMap,
  mapToT: OpenBranches_mapToT,
  forEach: OpenBranches_forEach,
  keep: OpenBranches_keep,
  keepMap: OpenBranches_keepMap,
  some: OpenBranches_some,
  every: OpenBranches_every,
  size: OpenBranches_size,
  sizeToString: OpenBranches_sizeToString,
  reorderV2: OpenBranches_reorderV2,
  reorderBuggy: OpenBranches_reorderBuggy,
  eq: eq$17,
  toArrayWithKeys: OpenBranches_toArrayWithKeys,
  encodeDict: OpenBranches_encodeDict,
  slice: OpenBranches_slice,
  take: OpenBranches_take,
  getName: getName$8
};

function getId$9(integration) {
  return integration.id;
}

function eq$18(a, b) {
  return JSON.stringify(Printer.printIntegration(a)) === JSON.stringify(Printer.printIntegration(b));
}

var include$9 = MappedModules.MakeOrderedMapModule({
      getId: getId$9,
      eq: eq$18
    });

var get$9 = include$9.get;

var toArray$9 = include$9.toArray;

var fromList$8 = include$9.fromList;

var eq$19 = include$9.eq;

function getName$9(items, id) {
  return Belt_Option.mapU(Curry._2(get$9, items, id), (function (item) {
                return Belt_Option.getWithDefault(item.name, "Untitled Integration");
              }));
}

var Integrations_MapModule = include$9.MapModule;

var Integrations_has = include$9.has;

var Integrations_getExn = include$9.getExn;

var Integrations_getAtIndex = include$9.getAtIndex;

var Integrations_getBy = include$9.getBy;

var Integrations_fromArray = include$9.fromArray;

var Integrations_toList = include$9.toList;

var Integrations_keys = include$9.keys;

var Integrations_empty = include$9.empty;

var Integrations_update = include$9.update;

var Integrations_updateExisting = include$9.updateExisting;

var Integrations_prepend = include$9.prepend;

var Integrations_append = include$9.append;

var Integrations_updateMany = include$9.updateMany;

var Integrations_updateManyExisting = include$9.updateManyExisting;

var Integrations_mergeMany = include$9.mergeMany;

var Integrations_remove = include$9.remove;

var Integrations_reduce = include$9.reduce;

var Integrations_mapToArray = include$9.mapToArray;

var Integrations_mapToMap = include$9.mapToMap;

var Integrations_mapToT = include$9.mapToT;

var Integrations_forEach = include$9.forEach;

var Integrations_keep = include$9.keep;

var Integrations_keepMap = include$9.keepMap;

var Integrations_some = include$9.some;

var Integrations_every = include$9.every;

var Integrations_size = include$9.size;

var Integrations_sizeToString = include$9.sizeToString;

var Integrations_reorderV2 = include$9.reorderV2;

var Integrations_reorderBuggy = include$9.reorderBuggy;

var Integrations_toArrayWithKeys = include$9.toArrayWithKeys;

var Integrations_encodeDict = include$9.encodeDict;

var Integrations_slice = include$9.slice;

var Integrations_take = include$9.take;

var Integrations = {
  MapModule: Integrations_MapModule,
  has: Integrations_has,
  get: get$9,
  getExn: Integrations_getExn,
  getAtIndex: Integrations_getAtIndex,
  getBy: Integrations_getBy,
  toArray: toArray$9,
  fromArray: Integrations_fromArray,
  toList: Integrations_toList,
  fromList: fromList$8,
  keys: Integrations_keys,
  empty: Integrations_empty,
  update: Integrations_update,
  updateExisting: Integrations_updateExisting,
  prepend: Integrations_prepend,
  append: Integrations_append,
  updateMany: Integrations_updateMany,
  updateManyExisting: Integrations_updateManyExisting,
  mergeMany: Integrations_mergeMany,
  remove: Integrations_remove,
  reduce: Integrations_reduce,
  mapToArray: Integrations_mapToArray,
  mapToMap: Integrations_mapToMap,
  mapToT: Integrations_mapToT,
  forEach: Integrations_forEach,
  keep: Integrations_keep,
  keepMap: Integrations_keepMap,
  some: Integrations_some,
  every: Integrations_every,
  size: Integrations_size,
  sizeToString: Integrations_sizeToString,
  reorderV2: Integrations_reorderV2,
  reorderBuggy: Integrations_reorderBuggy,
  eq: eq$19,
  toArrayWithKeys: Integrations_toArrayWithKeys,
  encodeDict: Integrations_encodeDict,
  slice: Integrations_slice,
  take: Integrations_take,
  getName: getName$9
};

function getId$10(variant) {
  return variant.id;
}

function eq$20(a, b) {
  return JSON.stringify(Printer.printEventVariant(a)) === JSON.stringify(Printer.printEventVariant(b));
}

var EventVariants = MappedModules.MakeOrderedMapModule({
      getId: getId$10,
      eq: eq$20
    });

function fromOld$1(old) {
  return {
          metadata: old.metadata,
          events: Curry._1(fromList$2, old.events),
          properties: fromOld(old.properties),
          propertyBundles: Curry._1(fromList$1, old.propertyBundles),
          categories: Curry._1(fromList$5, old.goals),
          groupTypes: Curry._1(fromArray$2, old.groupTypes),
          rules: Belt_List.toArray(old.rules)
        };
}

function toOld$1(t) {
  return {
          metadata: t.metadata,
          events: Belt_List.fromArray(Curry._1(toArray$2, t.events)),
          properties: toOld(t.properties),
          propertyBundles: Belt_List.fromArray(Curry._1(toArray$1, t.propertyBundles)),
          goals: Belt_List.fromArray(Curry._1(toArray$6, t.categories)),
          groupTypes: Curry._1(toArray$5, t.groupTypes),
          rules: Belt_List.fromArray(t.rules)
        };
}

function eq$21(a, b) {
  if (Curry._2(eq$5, a.events, b.events) && Curry._2(eq$1, a.properties, b.properties) && Curry._2(eq$3, a.propertyBundles, b.propertyBundles) && Curry._2(eq$13, a.categories, b.categories) && Curry._2(eq$11, a.groupTypes, b.groupTypes) && Belt_Array.eqU(a.rules, b.rules, (function (a, b) {
            return Caml_obj.equal(Printer.printRule(a), Printer.printRule(b));
          }))) {
    return Caml_obj.equal(a.metadata, b.metadata);
  } else {
    return false;
  }
}

var GlobalRequirements = {
  fromOld: fromOld$1,
  toOld: toOld$1,
  eq: eq$21
};

var empty_eventVariants = EventVariants.empty;

var empty$8 = {
  categories: empty$6,
  destinations: empty$4,
  events: empty$2,
  groupTypes: empty$5,
  metrics: empty$7,
  properties: empty,
  propertyBundles: empty$1,
  sources: empty$3,
  eventVariants: empty_eventVariants
};

function fromOld$2(old) {
  return {
          categories: Curry._1(fromList$5, old.goals),
          destinations: Curry._1(fromList$4, old.destinations),
          events: Curry._1(fromList$2, old.events),
          groupTypes: Curry._1(fromArray$2, old.groupTypes),
          metrics: Curry._1(fromList$6, old.metrics),
          properties: fromOld(old.properties),
          propertyBundles: Curry._1(fromList$1, old.propertyBundles),
          sources: Curry._1(fromList$3, old.sources),
          eventVariants: Curry._1(EventVariants.fromArray, old.eventVariants)
        };
}

function toOld$2(model) {
  return {
          events: Belt_List.fromArray(Curry._1(toArray$2, model.events)),
          properties: toOld(model.properties),
          propertyBundles: Belt_List.fromArray(Curry._1(toArray$1, model.propertyBundles)),
          sources: Belt_List.fromArray(Curry._1(toArray$3, model.sources)),
          destinations: Belt_List.fromArray(Curry._1(toArray$4, model.destinations)),
          goals: Belt_List.fromArray(Curry._1(toArray$6, model.categories)),
          metrics: Belt_List.fromArray(Curry._1(toArray$7, model.metrics)),
          groupTypes: Curry._1(toArray$5, model.groupTypes),
          eventVariants: Curry._1(EventVariants.toArray, model.eventVariants)
        };
}

var Archive = {
  empty: empty$8,
  fromOld: fromOld$2,
  toOld: toOld$2,
  Events: undefined,
  Properties: undefined,
  PropertyBundles: undefined,
  Sources: undefined,
  Destinations: undefined,
  Categories: undefined,
  Metrics: undefined,
  GroupTypes: undefined,
  EventVariants: undefined
};

function getEvent(model, eventId) {
  var $$event = Curry._2(get$2, model.events, eventId);
  if ($$event !== undefined) {
    return $$event;
  } else {
    return Curry._2(get$2, model.archive.events, eventId);
  }
}

function getProperty(model, propertyId) {
  var property = Curry._2(get, model.properties, propertyId);
  if (property !== undefined) {
    return property;
  } else {
    return Curry._2(get, model.archive.properties, propertyId);
  }
}

function getPropertyBundle(model, propertyBundleId) {
  var propertyBundle = Curry._2(get$1, model.propertyBundles, propertyBundleId);
  if (propertyBundle !== undefined) {
    return propertyBundle;
  } else {
    return Curry._2(get$1, model.archive.propertyBundles, propertyBundleId);
  }
}

function getSource(model, sourceId) {
  var source = Curry._2(get$3, model.sources, sourceId);
  if (source !== undefined) {
    return source;
  } else {
    return Curry._2(get$3, model.archive.sources, sourceId);
  }
}

function getDestination(model, destinationId) {
  var destination = Curry._2(get$4, model.destinations, destinationId);
  if (destination !== undefined) {
    return destination;
  } else {
    return Curry._2(get$4, model.archive.destinations, destinationId);
  }
}

function getCategory(model, categoryId) {
  var category = Curry._2(get$6, model.categories, categoryId);
  if (category !== undefined) {
    return category;
  } else {
    return Curry._2(get$6, model.archive.categories, categoryId);
  }
}

function getMetric(model, metricId) {
  var metric = Curry._2(get$7, model.metrics, metricId);
  if (metric !== undefined) {
    return metric;
  } else {
    return Curry._2(get$7, model.archive.metrics, metricId);
  }
}

function getGroupType(model, groupTypeId) {
  var groupType = Curry._2(get$5, model.groupTypes, groupTypeId);
  if (groupType !== undefined) {
    return groupType;
  } else {
    return Curry._2(get$5, model.archive.groupTypes, groupTypeId);
  }
}

function getEventVariant(model, variantIdentifier) {
  var match = Curry._2(get$2, model.events, variantIdentifier.baseEventId);
  if (match !== undefined) {
    return Belt_Array.getByU(match.variants, (function (param) {
                  return param.id === variantIdentifier.variantId;
                }));
  } else {
    return Curry._2(EventVariants.get, model.archive.eventVariants, variantIdentifier.variantId);
  }
}

var WithArchive = {
  getEvent: getEvent,
  getProperty: getProperty,
  getPropertyBundle: getPropertyBundle,
  getSource: getSource,
  getDestination: getDestination,
  getCategory: getCategory,
  getMetric: getMetric,
  getGroupType: getGroupType,
  getEventVariant: getEventVariant
};

function fromOld$3(oldModel) {
  return {
          archive: fromOld$2(oldModel.archive),
          branchPointer: oldModel.branchPointer,
          migrations: Belt_List.toArray(oldModel.migrations),
          rules: Belt_List.toArray(oldModel.rules),
          types: Belt_List.toArray(oldModel.types),
          categories: Curry._1(fromList$5, oldModel.goals),
          destinations: Curry._1(fromList$4, oldModel.destinations),
          events: Curry._1(fromList$2, oldModel.events),
          groupTypes: Curry._1(fromArray$2, oldModel.groupTypes),
          integrations: Curry._1(fromList$8, oldModel.integrations),
          metrics: Curry._1(fromList$6, oldModel.metrics),
          openBranches: Curry._1(fromList$7, oldModel.openBranches),
          properties: fromOld(oldModel.properties),
          propertyBundles: Curry._1(fromList$1, oldModel.propertyBundles),
          sources: Curry._1(fromList$3, oldModel.sources),
          globalRequirements: Belt_Option.map(oldModel.globalRequirements, fromOld$1)
        };
}

function toOld$3(model) {
  return {
          types: Belt_List.fromArray(model.types),
          properties: Belt_List.fromArray(Belt_Array.mapU(Curry._1(toArray, model.properties), (function (property) {
                      return {
                              TAG: /* Property */1,
                              _0: property
                            };
                    }))),
          propertyBundles: Belt_List.fromArray(Curry._1(toArray$1, model.propertyBundles)),
          events: Belt_List.fromArray(Curry._1(toArray$2, model.events)),
          migrations: Belt_List.fromArray(model.migrations),
          sources: Belt_List.fromArray(Curry._1(toArray$3, model.sources)),
          destinations: Belt_List.fromArray(Curry._1(toArray$4, model.destinations)),
          groupTypes: Curry._1(toArray$5, model.groupTypes),
          goals: Belt_List.fromArray(Curry._1(toArray$6, model.categories)),
          metrics: Belt_List.fromArray(Curry._1(toArray$7, model.metrics)),
          archive: toOld$2(model.archive),
          openBranches: Belt_List.fromArray(Curry._1(toArray$8, model.openBranches)),
          branchPointer: model.branchPointer,
          rules: Belt_List.fromArray(model.rules),
          integrations: Belt_List.fromArray(Curry._1(toArray$9, model.integrations)),
          globalRequirements: Belt_Option.map(model.globalRequirements, toOld$1)
        };
}

var empty$9 = fromOld$3(TrackingPlanModel.empty);

function unboxModel(modelType) {
  return modelType._0;
}

function eq$22(a, b) {
  if (!(Curry._2(eq$5, a.events, b.events) && Curry._2(eq$1, a.properties, b.properties) && Curry._2(eq$3, a.propertyBundles, b.propertyBundles) && Curry._2(eq$7, a.sources, b.sources) && Curry._2(eq$9, a.destinations, b.destinations) && Curry._2(eq$13, a.categories, b.categories) && Curry._2(eq$15, a.metrics, b.metrics) && Curry._2(eq$11, a.groupTypes, b.groupTypes) && Curry._2(eq$19, a.integrations, b.integrations) && Curry._2(eq$17, a.openBranches, b.openBranches) && Caml_obj.equal(a.branchPointer, b.branchPointer) && Belt_Array.eqU(a.migrations, b.migrations, (function (a, b) {
              return TrackingPlanModel.migrationToJs(a) === TrackingPlanModel.migrationToJs(b);
            })) && Belt_Array.eqU(a.rules, b.rules, (function (a, b) {
              return Caml_obj.equal(Printer.printRule(a), Printer.printRule(b));
            })) && Belt_Array.eqU(a.types, b.types, (function (a, b) {
              return Caml_obj.equal(Printer.printType(a), Printer.printType(b));
            })))) {
    return false;
  }
  var match = a.globalRequirements;
  var match$1 = b.globalRequirements;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return eq$21(match, match$1);
    } else {
      return false;
    }
  } else {
    return match$1 === undefined;
  }
}

function getId$11($$event) {
  return $$event.name;
}

function eq$23(a, b) {
  return JSON.stringify(Printer.printEvent(a)) === JSON.stringify(Printer.printEvent(b));
}

var OrderedMapModule = MappedModules.MakeOrderedMapModule({
      getId: getId$11,
      eq: eq$23
    });

function fromIdMap(events) {
  return Curry._1(OrderedMapModule.fromArray, Curry._1(toArray$2, events));
}

function toIdMap(events) {
  return Curry._1(fromArray$1, Curry._1(OrderedMapModule.toArray, events));
}

var EventsByName_MapModule = OrderedMapModule.MapModule;

var EventsByName_has = OrderedMapModule.has;

var EventsByName_get = OrderedMapModule.get;

var EventsByName_getExn = OrderedMapModule.getExn;

var EventsByName_getAtIndex = OrderedMapModule.getAtIndex;

var EventsByName_getBy = OrderedMapModule.getBy;

var EventsByName_toArray = OrderedMapModule.toArray;

var EventsByName_fromArray = OrderedMapModule.fromArray;

var EventsByName_toList = OrderedMapModule.toList;

var EventsByName_fromList = OrderedMapModule.fromList;

var EventsByName_keys = OrderedMapModule.keys;

var EventsByName_empty = OrderedMapModule.empty;

var EventsByName_update = OrderedMapModule.update;

var EventsByName_updateExisting = OrderedMapModule.updateExisting;

var EventsByName_prepend = OrderedMapModule.prepend;

var EventsByName_append = OrderedMapModule.append;

var EventsByName_updateMany = OrderedMapModule.updateMany;

var EventsByName_updateManyExisting = OrderedMapModule.updateManyExisting;

var EventsByName_mergeMany = OrderedMapModule.mergeMany;

var EventsByName_remove = OrderedMapModule.remove;

var EventsByName_reduce = OrderedMapModule.reduce;

var EventsByName_mapToArray = OrderedMapModule.mapToArray;

var EventsByName_mapToMap = OrderedMapModule.mapToMap;

var EventsByName_mapToT = OrderedMapModule.mapToT;

var EventsByName_forEach = OrderedMapModule.forEach;

var EventsByName_keep = OrderedMapModule.keep;

var EventsByName_keepMap = OrderedMapModule.keepMap;

var EventsByName_some = OrderedMapModule.some;

var EventsByName_every = OrderedMapModule.every;

var EventsByName_size = OrderedMapModule.size;

var EventsByName_sizeToString = OrderedMapModule.sizeToString;

var EventsByName_reorderV2 = OrderedMapModule.reorderV2;

var EventsByName_reorderBuggy = OrderedMapModule.reorderBuggy;

var EventsByName_eq = OrderedMapModule.eq;

var EventsByName_toArrayWithKeys = OrderedMapModule.toArrayWithKeys;

var EventsByName_encodeDict = OrderedMapModule.encodeDict;

var EventsByName_slice = OrderedMapModule.slice;

var EventsByName_take = OrderedMapModule.take;

var EventsByName = {
  OrderedMapModule: OrderedMapModule,
  fromIdMap: fromIdMap,
  toIdMap: toIdMap,
  MapModule: EventsByName_MapModule,
  has: EventsByName_has,
  get: EventsByName_get,
  getExn: EventsByName_getExn,
  getAtIndex: EventsByName_getAtIndex,
  getBy: EventsByName_getBy,
  toArray: EventsByName_toArray,
  fromArray: EventsByName_fromArray,
  toList: EventsByName_toList,
  fromList: EventsByName_fromList,
  keys: EventsByName_keys,
  empty: EventsByName_empty,
  update: EventsByName_update,
  updateExisting: EventsByName_updateExisting,
  prepend: EventsByName_prepend,
  append: EventsByName_append,
  updateMany: EventsByName_updateMany,
  updateManyExisting: EventsByName_updateManyExisting,
  mergeMany: EventsByName_mergeMany,
  remove: EventsByName_remove,
  reduce: EventsByName_reduce,
  mapToArray: EventsByName_mapToArray,
  mapToMap: EventsByName_mapToMap,
  mapToT: EventsByName_mapToT,
  forEach: EventsByName_forEach,
  keep: EventsByName_keep,
  keepMap: EventsByName_keepMap,
  some: EventsByName_some,
  every: EventsByName_every,
  size: EventsByName_size,
  sizeToString: EventsByName_sizeToString,
  reorderV2: EventsByName_reorderV2,
  reorderBuggy: EventsByName_reorderBuggy,
  eq: EventsByName_eq,
  toArrayWithKeys: EventsByName_toArrayWithKeys,
  encodeDict: EventsByName_encodeDict,
  slice: EventsByName_slice,
  take: EventsByName_take
};

function getId$12(property) {
  return property.name;
}

function eq$24(a, b) {
  return JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: a
                })) === JSON.stringify(Printer.printProperty({
                  TAG: /* Property */1,
                  _0: b
                }));
}

var OrderedMapModule$1 = MappedModules.MakeOrderedMapModule({
      getId: getId$12,
      eq: eq$24
    });

function fromIdMap$1(properties) {
  return Curry._1(OrderedMapModule$1.fromArray, Curry._1(toArray, properties));
}

function toIdMap$1(properties) {
  return Curry._1(fromArray, Curry._1(OrderedMapModule$1.toArray, properties));
}

var PropertiesByName_MapModule = OrderedMapModule$1.MapModule;

var PropertiesByName_has = OrderedMapModule$1.has;

var PropertiesByName_get = OrderedMapModule$1.get;

var PropertiesByName_getExn = OrderedMapModule$1.getExn;

var PropertiesByName_getAtIndex = OrderedMapModule$1.getAtIndex;

var PropertiesByName_getBy = OrderedMapModule$1.getBy;

var PropertiesByName_toArray = OrderedMapModule$1.toArray;

var PropertiesByName_fromArray = OrderedMapModule$1.fromArray;

var PropertiesByName_toList = OrderedMapModule$1.toList;

var PropertiesByName_fromList = OrderedMapModule$1.fromList;

var PropertiesByName_keys = OrderedMapModule$1.keys;

var PropertiesByName_empty = OrderedMapModule$1.empty;

var PropertiesByName_update = OrderedMapModule$1.update;

var PropertiesByName_updateExisting = OrderedMapModule$1.updateExisting;

var PropertiesByName_prepend = OrderedMapModule$1.prepend;

var PropertiesByName_append = OrderedMapModule$1.append;

var PropertiesByName_updateMany = OrderedMapModule$1.updateMany;

var PropertiesByName_updateManyExisting = OrderedMapModule$1.updateManyExisting;

var PropertiesByName_mergeMany = OrderedMapModule$1.mergeMany;

var PropertiesByName_remove = OrderedMapModule$1.remove;

var PropertiesByName_reduce = OrderedMapModule$1.reduce;

var PropertiesByName_mapToArray = OrderedMapModule$1.mapToArray;

var PropertiesByName_mapToMap = OrderedMapModule$1.mapToMap;

var PropertiesByName_mapToT = OrderedMapModule$1.mapToT;

var PropertiesByName_forEach = OrderedMapModule$1.forEach;

var PropertiesByName_keep = OrderedMapModule$1.keep;

var PropertiesByName_keepMap = OrderedMapModule$1.keepMap;

var PropertiesByName_some = OrderedMapModule$1.some;

var PropertiesByName_every = OrderedMapModule$1.every;

var PropertiesByName_size = OrderedMapModule$1.size;

var PropertiesByName_sizeToString = OrderedMapModule$1.sizeToString;

var PropertiesByName_reorderV2 = OrderedMapModule$1.reorderV2;

var PropertiesByName_reorderBuggy = OrderedMapModule$1.reorderBuggy;

var PropertiesByName_eq = OrderedMapModule$1.eq;

var PropertiesByName_toArrayWithKeys = OrderedMapModule$1.toArrayWithKeys;

var PropertiesByName_encodeDict = OrderedMapModule$1.encodeDict;

var PropertiesByName_slice = OrderedMapModule$1.slice;

var PropertiesByName_take = OrderedMapModule$1.take;

var PropertiesByName = {
  OrderedMapModule: OrderedMapModule$1,
  fromIdMap: fromIdMap$1,
  toIdMap: toIdMap$1,
  MapModule: PropertiesByName_MapModule,
  has: PropertiesByName_has,
  get: PropertiesByName_get,
  getExn: PropertiesByName_getExn,
  getAtIndex: PropertiesByName_getAtIndex,
  getBy: PropertiesByName_getBy,
  toArray: PropertiesByName_toArray,
  fromArray: PropertiesByName_fromArray,
  toList: PropertiesByName_toList,
  fromList: PropertiesByName_fromList,
  keys: PropertiesByName_keys,
  empty: PropertiesByName_empty,
  update: PropertiesByName_update,
  updateExisting: PropertiesByName_updateExisting,
  prepend: PropertiesByName_prepend,
  append: PropertiesByName_append,
  updateMany: PropertiesByName_updateMany,
  updateManyExisting: PropertiesByName_updateManyExisting,
  mergeMany: PropertiesByName_mergeMany,
  remove: PropertiesByName_remove,
  reduce: PropertiesByName_reduce,
  mapToArray: PropertiesByName_mapToArray,
  mapToMap: PropertiesByName_mapToMap,
  mapToT: PropertiesByName_mapToT,
  forEach: PropertiesByName_forEach,
  keep: PropertiesByName_keep,
  keepMap: PropertiesByName_keepMap,
  some: PropertiesByName_some,
  every: PropertiesByName_every,
  size: PropertiesByName_size,
  sizeToString: PropertiesByName_sizeToString,
  reorderV2: PropertiesByName_reorderV2,
  reorderBuggy: PropertiesByName_reorderBuggy,
  eq: PropertiesByName_eq,
  toArrayWithKeys: PropertiesByName_toArrayWithKeys,
  encodeDict: PropertiesByName_encodeDict,
  slice: PropertiesByName_slice,
  take: PropertiesByName_take
};

var Utils$4 = {
  EventsByName: EventsByName,
  PropertiesByName: PropertiesByName
};

export {
  MakeOrderedMapModuleWithName ,
  MakeOrderedMapModuleForModel ,
  Properties ,
  PropertyBundles ,
  Events ,
  Sources ,
  Destinations ,
  GroupTypes ,
  Categories ,
  Metrics ,
  OpenBranches ,
  Integrations ,
  EventVariants ,
  GlobalRequirements ,
  Archive ,
  WithArchive ,
  fromOld$3 as fromOld,
  toOld$3 as toOld,
  empty$9 as empty,
  unboxModel ,
  eq$22 as eq,
  Utils$4 as Utils,
}
/* include Not a pure module */
