// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as Quill from "./externals/quill.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as TriggerFullscreenModal from "./TriggerFullscreenModal.mjs";

var wrapper = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.width(Css.px(116)),
              tl: {
                hd: Css.height(Css.px(196)),
                tl: {
                  hd: Css.padding(Css.px(8)),
                  tl: {
                    hd: Css.marginLeft(Css.px(8)),
                    tl: {
                      hd: Css.marginBottom(Css.px(8)),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", "transparent"),
                          tl: {
                            hd: Css.hover({
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.boxShadow(Styles.Shadow.subtle),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var image = Curry._1(Css.style, {
      hd: Css.objectFit("contain"),
      tl: {
        hd: Css.width(Css.px(100)),
        tl: {
          hd: Css.height(Css.px(100)),
          tl: {
            hd: Css.minWidth(Css.px(100)),
            tl: {
              hd: Css.minHeight(Css.px(100)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.light02),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var noImage = Curry._1(Css.style, {
      hd: Css.width(Css.px(100)),
      tl: {
        hd: Css.height(Css.px(100)),
        tl: {
          hd: Css.minWidth(Css.px(100)),
          tl: {
            hd: Css.minHeight(Css.px(100)),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light02),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var pills = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.height(Css.px(30)),
            tl: {
              hd: Css.maxHeight(Css.px(30)),
              tl: {
                hd: Css.marginLeft(Css.px(-2)),
                tl: {
                  hd: Css.width({
                        NAME: "add",
                        VAL: [
                          Css.pct(100.0),
                          Css.px(6)
                        ]
                      }),
                  tl: {
                    hd: Css.overflow("hidden"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var pillsOverflowFade = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(0)),
        tl: {
          hd: Css.height(Css.pct(100)),
          tl: {
            hd: Css.width(Css.px(10)),
            tl: {
              hd: Css.background({
                    NAME: "linearGradient",
                    VAL: [
                      {
                        NAME: "deg",
                        VAL: 90
                      },
                      [
                        [
                          {
                            NAME: "percent",
                            VAL: 0
                          },
                          "transparent"
                        ],
                        [
                          {
                            NAME: "percent",
                            VAL: 100
                          },
                          Styles.Color.white
                        ]
                      ]
                    ]
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

function pillStyle(fill) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(2), Css.px(6)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(fill ? Styles.Color.light04 : Styles.Color.white),
                  tl: {
                    hd: Css.marginRight(Css.px(2)),
                    tl: {
                      hd: Css.maxWidth(Css.pct(100)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var description = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.maxLines(3)),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.fontSize(Styles.FontSize.small),
              tl: {
                hd: Css.maxHeight(Css.px(60)),
                tl: {
                  hd: Css.width(Css.pct(100)),
                  tl: {
                    hd: Css.textAlign("left"),
                    tl: {
                      hd: Css.textOverflow("ellipsis"),
                      tl: {
                        hd: Css.wordBreak("normal"),
                        tl: {
                          hd: Css.wordWrap("breakWord"),
                          tl: {
                            hd: Css.overflow("hidden"),
                            tl: {
                              hd: Css.selector(" > p", {
                                    hd: Css.margin(Css.px(0)),
                                    tl: {
                                      hd: Css.lineHeight(Styles.LineHeight.regular),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var S = {
  wrapper: wrapper,
  image: image,
  noImage: noImage,
  pills: pills,
  pillsOverflowFade: pillsOverflowFade,
  pillStyle: pillStyle,
  description: description
};

function EventDetailTriggers$Trigger(Props) {
  var trigger = Props.trigger;
  var modelSources = Props.modelSources;
  var onClick = Props.onClick;
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var match = trigger.content;
  var tmp;
  var exit = 0;
  if (match !== undefined && match.TAG === /* Image */0) {
    var match$1 = match._0;
    tmp = React.createElement("img", {
          className: image,
          height: String(match$1.height),
          src: match$1.url + "=w200",
          width: String(match$1.width)
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: noImage
        }, React.createElement(Icons.AddImage.make, {
              size: 16,
              color: Styles.Color.light08
            }));
  }
  var sourceIds = trigger.sources;
  return React.createElement("button", {
              className: wrapper,
              onClick: onClick
            }, tmp, React.createElement("div", {
                  className: pills
                }, sourceIds ? Belt_Array.map(Belt_Array.keepMap(sourceIds._0, (function (sourceId) {
                              return Belt_List.getBy(modelSources, (function (modelSource) {
                                            return modelSource.id === sourceId;
                                          }));
                            })), (function (source) {
                          return React.createElement("span", {
                                      key: source.id,
                                      className: pillStyle(true)
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          singleLine: true,
                                          color: Styles.Color.light11,
                                          children: AvoConfig.getSourceName(source)
                                        }));
                        })) : React.createElement("span", {
                        key: "source-independent",
                        className: pillStyle(false)
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            singleLine: true,
                            color: Styles.Color.light08,
                            children: isGlobalWorkspace ? "Workspace Independent" : "Source Independent"
                          })), React.createElement("div", {
                      className: pillsOverflowFade
                    })), React.createElement("div", {
                  className: description,
                  dangerouslySetInnerHTML: {
                    __html: Quill.toHTML(JSON.parse(trigger.description), undefined, undefined)
                  }
                }));
}

var Trigger = {
  S: S,
  make: EventDetailTriggers$Trigger
};

function EventDetailTriggers$EmptyTrigger(Props) {
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(8)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(16)),
                      tl: {
                        hd: Css.children({
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "background-color"),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.children({
                                      hd: Css.backgroundColor(Styles.Color.light04),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: image,
                  onClick: onClick
                }, React.createElement(Icon.make, {
                      type_: "addImage",
                      size: "large",
                      color: Styles.Color.light10
                    })));
}

var EmptyTrigger = {
  make: EventDetailTriggers$EmptyTrigger
};

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginLeft(Css.px(30)),
            tl: {
              hd: Css.marginTop(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var triggers = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.paddingLeft(Css.px(60)),
        tl: {
          hd: Css.selector(" > button:first-child", {
                hd: Css.marginLeft(Css.px(-8)),
                tl: /* [] */0
              }),
          tl: {
            hd: Css.overflowX("auto"),
            tl: {
              hd: Css.position("relative"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var triggerFade = Curry._1(Css.style, {
      hd: Css.height(Css.px(0)),
      tl: {
        hd: Css.width(Css.pct(100)),
        tl: {
          hd: Css.pointerEvents("none"),
          tl: {
            hd: Css.position("relative"),
            tl: {
              hd: Css.after({
                    hd: Css.unsafe("content", "' '"),
                    tl: {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.display("block"),
                        tl: {
                          hd: Css.right(Css.px(0)),
                          tl: {
                            hd: Css.top(Css.px(-196)),
                            tl: {
                              hd: Css.height(Css.px(196)),
                              tl: {
                                hd: Css.width(Css.px(80)),
                                tl: {
                                  hd: Css.background({
                                        NAME: "linearGradient",
                                        VAL: [
                                          Css.deg(90.0),
                                          [
                                            [
                                              Css.pct(0.0),
                                              {
                                                NAME: "rgba",
                                                VAL: [
                                                  255,
                                                  255,
                                                  255,
                                                  {
                                                    NAME: "num",
                                                    VAL: 0
                                                  }
                                                ]
                                              }
                                            ],
                                            [
                                              Css.pct(100.0),
                                              {
                                                NAME: "rgba",
                                                VAL: [
                                                  255,
                                                  255,
                                                  255,
                                                  {
                                                    NAME: "num",
                                                    VAL: 1
                                                  }
                                                ]
                                              }
                                            ]
                                          ]
                                        ]
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  header: header,
  triggers: triggers,
  triggerFade: triggerFade
};

function EventDetailTriggers(Props) {
  var $$event = Props.event;
  var modelSources = Props.modelSources;
  var schemaBundle = Props.schemaBundle;
  var viewerCanEdit = Props.viewerCanEdit;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.Triggers.computeAvailability(workspace);
  var globalSend = GlobalSendContext.use(undefined);
  var handleNewTrigger = function (_event) {
    if (availability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddEventTrigger"
                  }
                });
    }
    var match = Router.getStaticRoute(undefined);
    Belt_Option.forEach(Belt_List.head(match.drawerItems), (function (drawerItem) {
            if (typeof drawerItem !== "object") {
              return ;
            }
            var variant = drawerItem.NAME;
            if (variant !== "eventVariant") {
              if (variant === "event") {
                AnalyticsRe.eventTriggerInitiatedOnEvent($$event.id, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.triggers.length, schemaBundle.branchId, schemaBundle.schemaId);
                return Router.Schema.swapTopDrawerItem({
                            NAME: "event",
                            VAL: [
                              drawerItem.VAL[0],
                              undefined,
                              "new",
                              false
                            ]
                          });
              } else {
                return ;
              }
            }
            var variantIdentifier = drawerItem.VAL[0];
            AnalyticsRe.eventTriggerInitiatedOnVariant(variantIdentifier.baseEventId, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.triggers.length, variantIdentifier.variantId, schemaBundle.branchId, schemaBundle.schemaId);
            Router.Schema.swapTopDrawerItem({
                  NAME: "eventVariant",
                  VAL: [
                    variantIdentifier,
                    "new"
                  ]
                });
          }));
  };
  return React.createElement("section", {
              id: "triggers-product-tour"
            }, React.createElement("header", {
                  className: header
                }, React.createElement(Icons.TapScreen.make, {
                      color: Styles.Color.light07
                    }), React.createElement(Spacer.make, {
                      width: 14
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Triggered when"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: $$event.id,
                        itemName: $$event.name,
                        itemType: "Event"
                      },
                      title: "Event Triggers",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: Tooltips.screenshots
                                })
                          })
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), viewerCanEdit ? React.createElement(TextButton.make, {
                        onClick: handleNewTrigger,
                        style: "Blue",
                        size: "Small",
                        children: null
                      }, React.createElement(TriggerFullscreenModal.Lightning.make, {
                            color: Styles.Color.darkBlue
                          }), "+ New Trigger") : null), React.createElement("div", {
                  className: triggers
                }, Caml_obj.equal($$event.triggers, []) ? React.createElement(EventDetailTriggers$EmptyTrigger, {
                        onClick: handleNewTrigger
                      }) : null, Belt_Array.map($$event.triggers, (function (trigger) {
                        return React.createElement(EventDetailTriggers$Trigger, {
                                    trigger: trigger,
                                    modelSources: modelSources,
                                    onClick: (function (param) {
                                        Router.Schema.swapTopDrawerItem({
                                              NAME: "event",
                                              VAL: [
                                                $$event.id,
                                                undefined,
                                                trigger.id,
                                                false
                                              ]
                                            });
                                      }),
                                    key: trigger.id
                                  });
                      })), React.createElement(Spacer.make, {
                      width: 80
                    })), React.createElement("div", {
                  className: triggerFade
                }));
}

var make = EventDetailTriggers;

export {
  Trigger ,
  EmptyTrigger ,
  Style ,
  make ,
}
/* wrapper Not a pure module */
