// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RouterStore from "./RouterStore.mjs";
import * as Discrepancies from "./Discrepancies.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";

function getLabel(item) {
  switch (item) {
    case /* TrackingPlan */0 :
        return "Tracking Plan";
    case /* Properties */2 :
        return "Properties";
    case /* Metrics */3 :
        return "Metrics";
    case /* Publishing */4 :
        return "Publishing";
    case /* Sources */5 :
        return "Sources";
    case /* Workspaces */6 :
        return "Workspaces";
    case /* Codegen */7 :
        return "Codegen";
    case /* Inspector */8 :
        return "Inspector";
    case /* Events */1 :
    case /* InspectorEvents */9 :
        return "Events";
    case /* InspectorIssues */10 :
        return "Issues";
    case /* InspectorSavedViews */11 :
        return "Saved Views";
    
  }
}

function iconContainerStyles(isActiveCategory) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(20)),
              tl: {
                hd: Css.height(Css.px(20)),
                tl: {
                  hd: Css.borderRadius(Css.px(10)),
                  tl: {
                    hd: Css.backgroundColor(isActiveCategory ? Styles.Color.avoPink : Styles.Color.light08),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "all"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SidebarNavigation$CategoryIcon(Props) {
  var item = Props.item;
  var isActive = Props.isActive;
  var tmp;
  var exit = 0;
  switch (item) {
    case /* TrackingPlan */0 :
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(-3)),
                    tl: /* [] */0
                  })
            }, React.createElement(Icons.Plan.make, {
                  color: isActive ? Styles.Color.white : Styles.Color.light12
                }));
        break;
    case /* Publishing */4 :
        tmp = React.createElement("div", undefined, React.createElement(Icons.Publishing.make, {
                  color: isActive ? Styles.Color.white : Styles.Color.light12
                }));
        break;
    case /* Sources */5 :
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingLeft(Css.px(2)),
                    tl: /* [] */0
                  })
            }, React.createElement(Icons.Sources.make, {
                  color: isActive ? Styles.Color.white : Styles.Color.light12
                }));
        break;
    case /* Workspaces */6 :
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Icons.AvoLogoAInRoundedSquare.make, {
                  width: 16,
                  squareFill: "transparent",
                  logoFill: isActive ? Styles.Color.white : Styles.Color.light12
                }));
        break;
    case /* Inspector */8 :
        tmp = React.createElement(Icons.Inspector.make, {
              color: isActive ? Styles.Color.white : Styles.Color.light12
            });
        break;
    case /* Events */1 :
    case /* Properties */2 :
    case /* Metrics */3 :
    case /* Codegen */7 :
    case /* InspectorEvents */9 :
    case /* InspectorIssues */10 :
    case /* InspectorSavedViews */11 :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.marginLeft(Css.px(2)),
                tl: {
                  hd: Css.marginBottom(Css.px(3)),
                  tl: /* [] */0
                }
              })
        }, React.createElement(Icons.Deliver.make, {
              color: isActive ? Styles.Color.white : Styles.Color.light12
            }));
  }
  return React.createElement("div", {
              className: iconContainerStyles(isActive),
              id: "nav-icon-container-" + getLabel(item)
            }, tmp);
}

var CategoryIcon = {
  iconContainerStyles: iconContainerStyles,
  make: SidebarNavigation$CategoryIcon
};

var navContainerStyles = Curry._1(Css.style, {
      hd: Css.media("(max-height: 660px)", {
            hd: Css.marginTop(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.color(Styles.Color.light10),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.medium),
          tl: /* [] */0
        }
      }
    });

var navItemContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var navCategoryItemStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.fontSize(Styles.FontSize.large),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.semi),
          tl: {
            hd: Css.color(Styles.Color.white),
            tl: {
              hd: Css.display("grid"),
              tl: {
                hd: Css.gridTemplateColumns({
                      hd: Css.px(20),
                      tl: {
                        hd: "auto",
                        tl: /* [] */0
                      }
                    }),
                tl: {
                  hd: Css.gridGap(Css.px(6)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var subNavItemBaseStyles_0 = Css.display("block");

var subNavItemBaseStyles_1 = {
  hd: Css.fontSize(Styles.FontSize.regular),
  tl: {
    hd: Css.selector(":last-child", {
          hd: Css.paddingBottom(Css.px(12)),
          tl: /* [] */0
        }),
    tl: {
      hd: Css.paddingRight(Css.px(8)),
      tl: {
        hd: Css.paddingLeft(Css.px(34)),
        tl: {
          hd: Css.paddingBottom(Css.px(4)),
          tl: {
            hd: Css.borderRadius(Css.px(2)),
            tl: {
              hd: Css.active({
                    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.color(Styles.Color.light08),
                tl: {
                  hd: Css.focus({
                        hd: Css.outlineStyle("none"),
                        tl: {
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    }
  }
};

var subNavItemBaseStyles = {
  hd: subNavItemBaseStyles_0,
  tl: subNavItemBaseStyles_1
};

var subNavItemStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, subNavItemBaseStyles),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.hover({
                    hd: Css.color(Styles.Color.light07),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var activeSubNavItemStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, subNavItemBaseStyles),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.avoPink),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

function navCategoryContainerStyles(isActiveCategory) {
  return Curry._1(Css.style, {
              hd: Css.marginBottom(Css.px(8)),
              tl: {
                hd: Css.backgroundColor(isActiveCategory ? Styles.Color.light12 : Css.transparent),
                tl: {
                  hd: Css.border(Css.px(1), "solid", isActiveCategory ? Styles.Color.dark07 : Css.transparent),
                  tl: {
                    hd: Css.borderRadius(Css.px(10)),
                    tl: {
                      hd: Css.padding(Css.px(0)),
                      tl: {
                        hd: Css.hover({
                              hd: Css.selector(" a > div[id^=\"nav-icon-container\"]", {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$long
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.backgroundColor(Styles.Color.avoPink),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Css.selector(" div[id^=\"nav-icon-container\"] path", {
                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.white),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.transition({
                                NAME: "ms",
                                VAL: Styles.Duration.$$long
                              }, undefined, undefined, "all"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function isActiveRoute(item, activeRoute) {
  switch (item) {
    case /* TrackingPlan */0 :
        if (activeRoute === "metrics" || activeRoute === "events") {
          return true;
        } else {
          return activeRoute === "properties";
        }
    case /* Events */1 :
        return activeRoute === "events";
    case /* Properties */2 :
        return activeRoute === "properties";
    case /* Metrics */3 :
        return activeRoute === "metrics";
    case /* Publishing */4 :
        return activeRoute === "integrations";
    case /* Sources */5 :
    case /* Workspaces */6 :
        return activeRoute === "settings";
    case /* Codegen */7 :
        return activeRoute === "implement";
    case /* Inspector */8 :
        if (typeof activeRoute === "object") {
          return activeRoute.NAME === "inspector";
        } else {
          return false;
        }
    case /* InspectorEvents */9 :
        if (typeof activeRoute === "object" && activeRoute.NAME === "inspector") {
          return activeRoute.VAL === "events";
        } else {
          return false;
        }
    case /* InspectorIssues */10 :
        if (typeof activeRoute !== "object") {
          return false;
        }
        if (activeRoute.NAME !== "inspector") {
          return false;
        }
        var match = activeRoute.VAL;
        if (typeof match === "object") {
          return match.NAME === "savedView";
        } else {
          return match === "issues";
        }
    case /* InspectorSavedViews */11 :
        if (typeof activeRoute === "object" && activeRoute.NAME === "inspector") {
          return activeRoute.VAL === "savedViews";
        } else {
          return false;
        }
    
  }
}

function SidebarNavigation(Props) {
  var activeRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var navItems = isGlobalWorkspace ? ({
        hd: [
          /* TrackingPlan */0,
          "events",
          {
            hd: [
              /* Events */1,
              "events"
            ],
            tl: {
              hd: [
                /* Properties */2,
                "properties"
              ],
              tl: /* [] */0
            }
          }
        ],
        tl: {
          hd: [
            /* Workspaces */6,
            "settings",
            /* [] */0
          ],
          tl: {
            hd: [
              /* Publishing */4,
              "integrations",
              /* [] */0
            ],
            tl: /* [] */0
          }
        }
      }) : ({
        hd: [
          /* TrackingPlan */0,
          "events",
          {
            hd: [
              /* Events */1,
              "events"
            ],
            tl: {
              hd: [
                /* Properties */2,
                "properties"
              ],
              tl: {
                hd: [
                  /* Metrics */3,
                  "metrics"
                ],
                tl: /* [] */0
              }
            }
          }
        ],
        tl: {
          hd: [
            /* Inspector */8,
            {
              NAME: "inspector",
              VAL: "issues"
            },
            {
              hd: [
                /* InspectorIssues */10,
                {
                  NAME: "inspector",
                  VAL: "issues"
                }
              ],
              tl: {
                hd: [
                  /* InspectorSavedViews */11,
                  {
                    NAME: "inspector",
                    VAL: "savedViews"
                  }
                ],
                tl: {
                  hd: [
                    /* InspectorEvents */9,
                    {
                      NAME: "inspector",
                      VAL: "events"
                    }
                  ],
                  tl: /* [] */0
                }
              }
            }
          ],
          tl: {
            hd: [
              /* Publishing */4,
              "integrations",
              /* [] */0
            ],
            tl: {
              hd: [
                /* Codegen */7,
                "implement",
                /* [] */0
              ],
              tl: {
                hd: [
                  /* Sources */5,
                  "settings",
                  /* [] */0
                ],
                tl: /* [] */0
              }
            }
          }
        }
      });
  return React.createElement("nav", {
              className: navContainerStyles
            }, Belt_List.toArray(Belt_List.mapU(navItems, (function (param) {
                        var item = param[0];
                        var isActiveCategory = isActiveRoute(item, activeRoute);
                        return React.createElement("div", {
                                    key: getLabel(item),
                                    className: navCategoryContainerStyles(isActiveCategory)
                                  }, React.createElement("div", undefined, React.createElement("div", {
                                            className: navItemContainerStyles
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.getSchemaRouteLink(undefined, param[1]),
                                                className: navCategoryItemStyles,
                                                id: "sidebar-tour-" + getLabel(item),
                                                children: null
                                              }, React.createElement(SidebarNavigation$CategoryIcon, {
                                                    item: item,
                                                    isActive: isActiveCategory
                                                  }), getLabel(item)), item === /* TrackingPlan */0 ? React.createElement("span", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.paddingRight(Css.px(8)),
                                                        tl: /* [] */0
                                                      }),
                                                  id: "sidebar-tour-issues"
                                                }, React.createElement(Discrepancies.make, {})) : null), Belt_List.toArray(Belt_List.mapU(param[2], (function (param) {
                                                  var subItem = param[0];
                                                  return React.createElement(Link.make, {
                                                              path: Router.Link.getSchemaRouteLink(undefined, param[1]),
                                                              className: isActiveRoute(subItem, activeRoute) ? activeSubNavItemStyles : subNavItemStyles,
                                                              id: "intercom-sidebar-item" + (getLabel(item) + getLabel(subItem)).replace(/[^a-zA-Z0-9]/g, ""),
                                                              children: getLabel(subItem),
                                                              key: getLabel(subItem)
                                                            });
                                                })))));
                      }))));
}

var make = SidebarNavigation;

export {
  getLabel ,
  CategoryIcon ,
  navContainerStyles ,
  navItemContainerStyles ,
  navCategoryItemStyles ,
  subNavItemBaseStyles ,
  subNavItemStyles ,
  activeSubNavItemStyles ,
  navCategoryContainerStyles ,
  isActiveRoute ,
  make ,
}
/* navContainerStyles Not a pure module */
