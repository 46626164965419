// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconMoney(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("path", {
                          d: "M11.853,3.28c-1.694-.932-7.029-1.626-7.029,1.581,0,3.838,6.775,2.484,6.775,5.871s-4.4,3.133-7.452,2.032",
                          fill: "none",
                          strokeLinecap: "round"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "8.5",
                          x2: "8.5",
                          y1: "0.5",
                          y2: "15.5"
                        }))));
}

var make = IconMoney;

export {
  make ,
}
/* Css Not a pure module */
