// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconListAdd(Props) {
  var colorOpt = Props.color;
  var hoverColorOpt = Props.hoverColor;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light07;
  var hoverColor = hoverColorOpt !== undefined ? hoverColorOpt : color;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(color),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "fill"),
                        tl: {
                          hd: Css.hover({
                                hd: Css_Legacy_Core.SVG.fill(hoverColor),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("svg", {
                  height: "14",
                  width: "19",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "M11 4H0v2h11V4zm4-4H0v2h15V0zm0 8V4h-2v4H9v2h4v4h2v-4h4V8h-4zM0 10h7V8H0v2z"
                    })));
}

var make = IconListAdd;

export {
  make ,
}
/* Css Not a pure module */
