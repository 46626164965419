// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as AvoModel from "../../app/src/avoModel.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MutableMap from "rescript/lib/es6/belt_MutableMap.js";

function cmp(arrayA, arrayB) {
  return Belt_Array.cmpU(arrayA, arrayB, $$String.compare);
}

var Comparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function empty(param) {
  return Belt_MutableMap.make(Comparator);
}

var MutableMap = {
  Int: Belt_MutableMap.Int,
  $$String: Belt_MutableMap.$$String,
  make: Belt_MutableMap.make,
  clear: Belt_MutableMap.clear,
  isEmpty: Belt_MutableMap.isEmpty,
  has: Belt_MutableMap.has,
  cmpU: Belt_MutableMap.cmpU,
  cmp: Belt_MutableMap.cmp,
  eqU: Belt_MutableMap.eqU,
  eq: Belt_MutableMap.eq,
  forEachU: Belt_MutableMap.forEachU,
  forEach: Belt_MutableMap.forEach,
  reduceU: Belt_MutableMap.reduceU,
  reduce: Belt_MutableMap.reduce,
  everyU: Belt_MutableMap.everyU,
  every: Belt_MutableMap.every,
  someU: Belt_MutableMap.someU,
  some: Belt_MutableMap.some,
  size: Belt_MutableMap.size,
  toList: Belt_MutableMap.toList,
  toArray: Belt_MutableMap.toArray,
  fromArray: Belt_MutableMap.fromArray,
  keysToArray: Belt_MutableMap.keysToArray,
  valuesToArray: Belt_MutableMap.valuesToArray,
  minKey: Belt_MutableMap.minKey,
  minKeyUndefined: Belt_MutableMap.minKeyUndefined,
  maxKey: Belt_MutableMap.maxKey,
  maxKeyUndefined: Belt_MutableMap.maxKeyUndefined,
  minimum: Belt_MutableMap.minimum,
  minUndefined: Belt_MutableMap.minUndefined,
  maximum: Belt_MutableMap.maximum,
  maxUndefined: Belt_MutableMap.maxUndefined,
  get: Belt_MutableMap.get,
  getUndefined: Belt_MutableMap.getUndefined,
  getWithDefault: Belt_MutableMap.getWithDefault,
  getExn: Belt_MutableMap.getExn,
  checkInvariantInternal: Belt_MutableMap.checkInvariantInternal,
  remove: Belt_MutableMap.remove,
  removeMany: Belt_MutableMap.removeMany,
  set: Belt_MutableMap.set,
  updateU: Belt_MutableMap.updateU,
  update: Belt_MutableMap.update,
  mergeMany: Belt_MutableMap.mergeMany,
  mapU: Belt_MutableMap.mapU,
  map: Belt_MutableMap.map,
  mapWithKeyU: Belt_MutableMap.mapWithKeyU,
  mapWithKey: Belt_MutableMap.mapWithKey,
  empty: empty
};

var EventPropertiesFlattenedByNamesMap = {
  Comparator: Comparator,
  MutableMap: MutableMap
};

function get(inspectorObjectsEnabled, $$event, model, sourceId) {
  var eventProperties = AvoModel.getResolvedPropertiesForEvent(model, $$event);
  var map = Belt_MutableMap.make(Comparator);
  var eventProperties$1 = Belt_List.keep(eventProperties, (function (property) {
          return ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, $$event.id, sourceId, model)(undefined);
        }));
  Belt_List.forEach(eventProperties$1, (function (property) {
          var propertyPossibleNames = ModelUtils.getPropertyPossibleNames(property, $$event, model);
          Belt_List.forEachU(propertyPossibleNames, (function (name) {
                  Belt_MutableMap.set(map, [name], property);
                }));
          var match = property.type_;
          if (match !== "object") {
            return ;
          }
          if (!inspectorObjectsEnabled) {
            return ;
          }
          var children = ModelUtils.getObjectPropertyChildren(property.id, model);
          Belt_List.forEach(children, (function (child) {
                  var childPossibleNames = ModelUtils.getPropertyPossibleNames(child, undefined, model);
                  var match = child.type_;
                  if (match !== "object") {
                    return Belt_List.forEachU(propertyPossibleNames, (function (propertyName) {
                                  Belt_List.forEachU(childPossibleNames, (function (childName) {
                                          Belt_MutableMap.set(map, [
                                                propertyName,
                                                childName
                                              ], child);
                                        }));
                                }));
                  }
                  var grandChildren = ModelUtils.getObjectPropertyChildren(child.id, model);
                  Belt_List.forEach(grandChildren, (function (grandChild) {
                          var grandChildrenPossibleNames = ModelUtils.getPropertyPossibleNames(grandChild, undefined, model);
                          Belt_List.forEachU(propertyPossibleNames, (function (propertyName) {
                                  Belt_List.forEachU(childPossibleNames, (function (childName) {
                                          Belt_List.forEachU(grandChildrenPossibleNames, (function (grandChildName) {
                                                  Belt_MutableMap.set(map, [
                                                        propertyName,
                                                        childName,
                                                        grandChildName
                                                      ], grandChild);
                                                }));
                                          Belt_MutableMap.set(map, [
                                                propertyName,
                                                childName
                                              ], child);
                                        }));
                                }));
                        }));
                }));
        }));
  return map;
}

function getPropertiesActualNames(propertyNames, trackingPlanEventPropertiesMap) {
  var parent = Belt_Array.getExn(propertyNames, 0);
  var child = Belt_Array.get(propertyNames, 1);
  var grandChild = Belt_Array.get(propertyNames, 2);
  var keysForProperty = child !== undefined ? (
      grandChild !== undefined ? [
          [parent],
          [
            parent,
            child
          ],
          [
            parent,
            child,
            grandChild
          ]
        ] : [
          [parent],
          [
            parent,
            child
          ]
        ]
    ) : [[parent]];
  return Belt_Array.mapWithIndex(keysForProperty, (function (index, key) {
                return Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMap.get(trackingPlanEventPropertiesMap, key), (function (property) {
                                  return property.name;
                                })), Belt_Array.getExn(key, index));
              }));
}

function actualPropertyNamesAsString(actualPropertyNames) {
  return Belt_Array.joinWith(actualPropertyNames, ".", (function (propertyName) {
                return propertyName;
              }));
}

export {
  EventPropertiesFlattenedByNamesMap ,
  get ,
  getPropertiesActualNames ,
  actualPropertyNamesAsString ,
}
/* Comparator Not a pure module */
