// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function boot(options) {
  window.Intercom("boot", options);
}

function update(options) {
  window.Intercom("update", options);
}

function showNewMessage(message) {
  window.Intercom("showNewMessage", message);
}

function shutdown(param) {
  window.Intercom("shutdown", {});
}

function startTour(tourId) {
  window.Intercom("startTour", tourId);
}

function startSurvey(surveyId) {
  window.Intercom("startSurvey", surveyId);
}

function getVisitorId(param) {
  return window.Intercom("getVisitorId");
}

function isAvailable(param) {
  return Belt_Option.isSome(Caml_option.nullable_to_opt(window.Intercom("getVisitorId")));
}

function decodeAuthJson(json) {
  return {
          userId: Json_decode.field("userId", Json_decode.string, json),
          userHash: Json_decode.field("userHash", Json_decode.string, json)
        };
}

var appId = "q694k0ra";

export {
  appId ,
  boot ,
  update ,
  showNewMessage ,
  shutdown ,
  startTour ,
  startSurvey ,
  getVisitorId ,
  isAvailable ,
  decodeAuthJson ,
}
/* No side effect */
