// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function setValueAtIndex(index, value, listToUpdate) {
  var match = Belt_List.splitAt(listToUpdate, index);
  if (match === undefined) {
    return listToUpdate;
  }
  var match$1 = match[1];
  if (match$1) {
    return Belt_List.concat(match[0], Belt_List.concat({
                    hd: value,
                    tl: /* [] */0
                  }, match$1.tl));
  } else {
    return listToUpdate;
  }
}

function removeAtIndex(index, listToUpdate) {
  var match = Belt_List.splitAt(listToUpdate, index);
  if (match === undefined) {
    return listToUpdate;
  }
  var match$1 = match[1];
  if (match$1) {
    return Belt_List.concat(match[0], match$1.tl);
  } else {
    return listToUpdate;
  }
}

function flatMap(list, f) {
  return Belt_List.flatten(Belt_List.map(list, f));
}

function flatMapU(list, f) {
  return Belt_List.flatten(Belt_List.mapU(list, f));
}

function groupConsecutive(l, f) {
  var _acc = /* [] */0;
  var _x = l;
  while(true) {
    var x = _x;
    var acc = _acc;
    if (!x) {
      return acc;
    }
    var hd = x.hd;
    var match = Belt_List.partitionU(x.tl, (function(hd){
        return function (item) {
          return Curry._2(f, hd, item);
        }
        }(hd)));
    _x = match[1];
    _acc = {
      hd: {
        hd: hd,
        tl: match[0]
      },
      tl: acc
    };
    continue ;
  };
}

function group(cmp, lst) {
  var sorted = Belt_List.sortU(lst, Curry.__2(cmp));
  var fold = function (first, rest) {
    return Belt_List.reduceU(rest, [
                /* [] */0,
                {
                  hd: first,
                  tl: /* [] */0
                },
                first
              ], (function (param, elem) {
                  var agr = param[1];
                  var acc = param[0];
                  if (Curry._2(cmp, param[2], elem) === 0) {
                    return [
                            acc,
                            {
                              hd: elem,
                              tl: agr
                            },
                            elem
                          ];
                  } else {
                    return [
                            {
                              hd: agr,
                              tl: acc
                            },
                            {
                              hd: elem,
                              tl: /* [] */0
                            },
                            elem
                          ];
                  }
                }));
  };
  if (!sorted) {
    return /* [] */0;
  }
  var match = fold(sorted.hd, sorted.tl);
  return Belt_List.mapReverseU({
              hd: match[1],
              tl: match[0]
            }, Belt_List.reverse);
}

function dedupeSlow(items) {
  return Belt_List.reduceU(items, /* [] */0, (function (nextItems, item) {
                if (Belt_List.hasU(nextItems, item, Caml_obj.equal)) {
                  return nextItems;
                } else {
                  return Belt_List.concat(nextItems, {
                              hd: item,
                              tl: /* [] */0
                            });
                }
              }));
}

function Dedupe(Config) {
  var ItemComparator = Belt_Id.MakeComparable({
        cmp: Config.compare
      });
  var dedupe = function (items) {
    return Belt_Set.toList(Belt_Set.fromArray(Belt_List.toArray(items), ItemComparator));
  };
  var sort = function (items) {
    return Belt_List.sort(items, Config.compare);
  };
  return {
          ItemComparator: ItemComparator,
          dedupe: dedupe,
          sort: sort
        };
}

function dedupeString(items) {
  return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(items)));
}

function dedupeOrdered(items, getId) {
  return Belt_List.reverse(Belt_List.reduceU(items, [
                    /* [] */0,
                    undefined
                  ], (function (param, item) {
                      var counted = param[1];
                      var uniqes = param[0];
                      if (Belt_SetString.has(counted, Curry._1(getId, item))) {
                        return [
                                uniqes,
                                counted
                              ];
                      } else {
                        return [
                                {
                                  hd: item,
                                  tl: uniqes
                                },
                                Belt_SetString.add(counted, Curry._1(getId, item))
                              ];
                      }
                    }))[0]);
}

function dedupeSlowBy(items, compare) {
  return Belt_List.reduceU(items, /* [] */0, (function (nextItems, item) {
                if (Belt_List.hasU(nextItems, item, Curry.__2(compare))) {
                  return nextItems;
                } else {
                  return Belt_List.concat(nextItems, {
                              hd: item,
                              tl: /* [] */0
                            });
                }
              }));
}

function hasUniqueElements(firstList, secondList) {
  return Belt_List.some(firstList, (function (listElement) {
                return !Belt_List.some(secondList, (function (secondListElement) {
                              return Caml_obj.equal(secondListElement, listElement);
                            }));
              }));
}

function splitIntoChunks(chunksOpt, list, chunkSize, param) {
  var chunks = chunksOpt !== undefined ? chunksOpt : /* [] */0;
  if (!list) {
    return chunks;
  }
  if (Belt_List.length(list) <= chunkSize) {
    return Belt_List.concat(chunks, {
                hd: list,
                tl: /* [] */0
              });
  }
  var maybeChunkAndList = Belt_List.splitAt(list, chunkSize);
  return Belt_Option.getWithDefault(Belt_Option.map(maybeChunkAndList, (function (param) {
                    return splitIntoChunks(Belt_List.concat(chunks, {
                                    hd: param[0],
                                    tl: /* [] */0
                                  }), param[1], chunkSize, undefined);
                  })), Belt_List.concat(chunks, {
                  hd: list,
                  tl: /* [] */0
                }));
}

function findAndMap(list, findAndMap$1) {
  return Belt_Option.flatMapU(Belt_List.getByU(list, (function (item) {
                    return Belt_Option.isSome(Curry._1(findAndMap$1, item));
                  })), Curry.__1(findAndMap$1));
}

export {
  setValueAtIndex ,
  removeAtIndex ,
  flatMap ,
  flatMapU ,
  groupConsecutive ,
  group ,
  dedupeSlow ,
  Dedupe ,
  dedupeString ,
  dedupeOrdered ,
  dedupeSlowBy ,
  hasUniqueElements ,
  splitIntoChunks ,
  findAndMap ,
}
/* No side effect */
