// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as $$Number from "./Number.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as StripeBilling from "./StripeBilling.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as SegmentControl from "./SegmentControl.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconCheckmarkCircle from "./IconCheckmarkCircle.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as UsageBasedPricingTiers from "../../shared/utils/UsageBasedPricingTiers.mjs";
import * as CreateSubscriptionEndpoint from "../../shared/endpoints/CreateSubscriptionEndpoint.mjs";

function createTeamV3Subscription(data) {
  return Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                  return fetch("" + Firebase.apiUrl + "/billing/createSubscription", Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + userToken + ""
                                    ]
                                  ], Caml_option.some(JSON.stringify(CreateSubscriptionEndpoint.$$Request.encode(data))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (response) {
                return Fetch.$$Response.json(response).then(function (json) {
                            return [
                                    response,
                                    json
                                  ];
                          });
              }).then(function (param) {
              var response = param[0];
              if (response.status === 200) {
                return Promise.resolve(CreateSubscriptionEndpoint.$$Response.decode(param[1]));
              } else {
                return Promise.reject(Js_exn.raiseError("Failed to create subscription. Got status: " + String(response.status) + ""));
              }
            });
}

var root = Curry._1(Css.style, {
      hd: Css.width(Css.px(720)),
      tl: {
        hd: Css.paddingTop(Css.px(60)),
        tl: {
          hd: Css.paddingRight(Css.px(40)),
          tl: {
            hd: Css.paddingBottom(Css.px(40)),
            tl: {
              hd: Css.paddingLeft(Css.px(40)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var checkoutRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var checkoutSeparator = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.marginTop(Css.px(16)),
        tl: {
          hd: Css.marginBottom(Css.px(16)),
          tl: /* [] */0
        }
      }
    });

var paymentReceived = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(16)),
      tl: {
        hd: Css.maxWidth(Css.px(400)),
        tl: {
          hd: Css.paddingTop(Css.px(8)),
          tl: {
            hd: Css.paddingRight(Css.px(16)),
            tl: {
              hd: Css.paddingBottom(Css.px(8)),
              tl: {
                hd: Css.paddingLeft(Css.px(16)),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.green),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var paymentReceivedRow = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginBottom(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

var $$package = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("baseline"),
          tl: /* [] */0
        }
      }
    });

var packageDetail = Curry._1(Css.style, {
      hd: Css.width(Css.px(480)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.marginBottom(Css.px(6)),
            tl: /* [] */0
          }
        }
      }
    });

var textButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: $$Text.sizeStyles("Medium"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.textDecoration("underline"),
                  tl: {
                    hd: Css.transition({
                          NAME: "ms",
                          VAL: Styles.Duration.$$short
                        }, undefined, undefined, "color"),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.light12),
                            tl: /* [] */0
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  checkoutRow: checkoutRow,
  checkoutSeparator: checkoutSeparator,
  paymentReceived: paymentReceived,
  paymentReceivedRow: paymentReceivedRow,
  $$package: $$package,
  packageDetail: packageDetail,
  textButton: textButton
};

function BillingPlansCheckoutTeamV3$PeriodSwitcher(Props) {
  var onSelect = Props.onSelect;
  var period = Props.period;
  var monthlyPrices = Props.monthlyPrices;
  var annualPrices = Props.annualPrices;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginLeft(Css.px(-16)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(SegmentControl.make, {
                  options: [
                    [
                      "Annual billing",
                      "annual"
                    ],
                    [
                      "Monthly billing",
                      "monthly"
                    ]
                  ],
                  onSelect: onSelect,
                  selected: period,
                  circular: true
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: period === "annual" ? Styles.Color.green : Styles.Color.light10,
                  children: null
                }, period === "annual" ? "You're saving " : "You can save ", React.createElement($$Text.make, {
                      element: "Strong",
                      weight: "Semi",
                      children: "$" + $$Number.toLocaleString(monthlyPrices.total * 12.0 - annualPrices.total) + "/yr"
                    }), " on annual billing"));
}

var PeriodSwitcher = {
  make: BillingPlansCheckoutTeamV3$PeriodSwitcher
};

function BillingPlansCheckoutTeamV3(Props) {
  var plans = Props.pricingPlans;
  var onContactSupport = Props.onContactSupport;
  var initialPeriod = Props.initialPeriod;
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useState(function () {
        return /* Build */0;
      });
  var setState = match[1];
  var state = match[0];
  var lastState = Hooks.usePrevious1(state);
  var match$1 = React.useState(function () {
        return initialPeriod;
      });
  var setPeriod = match$1[1];
  var period = match$1[0];
  var globalSend = GlobalSendContext.use(undefined);
  var match$2 = React.useState(function () {
        return 5;
      });
  var setEditors = match$2[1];
  var editors = match$2[0];
  var match$3 = React.useState(function () {
        return 100000;
      });
  var setInspectorVolume = match$3[1];
  var inspectorVolume = match$3[0];
  var match$4 = React.useState(function () {
        return 0;
      });
  var setImplementers = match$4[1];
  var implementers = match$4[0];
  var match$5 = React.useState(function () {
        return false;
      });
  var setCheckoutLoading = match$5[1];
  var schemaBundle = SchemaBundleContext.use(undefined);
  var getPricesPerMonth = function (period) {
    var pricingTier = period === "annual" ? plans.teamAnnual : plans.team;
    var editors$1 = UsageBasedPricingTiers.calculatePrice(pricingTier.editors, editors);
    var inspectorVolume$1 = UsageBasedPricingTiers.calculatePrice(pricingTier.inspectorEvents, inspectorVolume * (
          period === "annual" ? 12 : 1
        ));
    var implementers$1 = UsageBasedPricingTiers.calculatePrice(pricingTier.implementers, implementers);
    var total = editors$1.total + inspectorVolume$1.total + implementers$1.total;
    return {
            editors: editors$1,
            inspectorVolume: inspectorVolume$1,
            implementers: implementers$1,
            total: total
          };
  };
  var trackInteraction = function (interaction) {
    AnalyticsRe.subscriptionUpgradeModalInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, AnalyticsUtils.planToAnalyticsPlan(period === "annual" ? /* TeamV3Annual */12 : /* TeamV3 */11), AnalyticsUtils.planToAnalyticsPlanSlug(period === "annual" ? /* TeamV3Annual */12 : /* TeamV3 */11), getPricesPerMonth(period).total, period === "annual" ? "Annual" : "Monthly", interaction, editors, inspectorVolume, implementers, schemaBundle.branchId, schemaBundle.schemaId);
  };
  var handleSelectPeriod = function (newPeriod) {
    trackInteraction("ToggleTimeUnit");
    Curry._1(setPeriod, (function (param) {
            return newPeriod;
          }));
  };
  var handleContinueToPayment = function (param) {
    trackInteraction("ContinueToPaymentDetails");
    Curry._1(setState, (function (param) {
            return /* Payment */1;
          }));
  };
  var handleBackToBuild = function (param) {
    trackInteraction("BackToPlanBuilder");
    Curry._1(setState, (function (param) {
            return /* Build */0;
          }));
  };
  var handlePaymentInputSuccess = function (token, param) {
    var planName = period === "annual" ? /* TeamV3Annual */12 : /* TeamV3 */11;
    trackInteraction("ClickCompleteUpgrade");
    return $$Promise.$$catch(createTeamV3Subscription({
                      schemaId: workspace.id,
                      plan: planName,
                      stripeToken: token.id,
                      editors: editors,
                      monthlyInspectorVolume: inspectorVolume,
                      monthlyImplementers: implementers
                    }).then(function (param) {
                    Curry._1(setCheckoutLoading, (function (param) {
                            return false;
                          }));
                    Curry._1(setState, (function (param) {
                            return /* ThankYou */2;
                          }));
                  }), (function (error) {
                  Curry._1(setCheckoutLoading, (function (param) {
                          return false;
                        }));
                  Curry._1(setState, (function (param) {
                          return /* Payment */1;
                        }));
                  console.log("Failed to upgrade", error);
                  Sentry.captureException(error, {});
                  Curry._1(globalSend, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "AlertModal",
                          VAL: [
                            "Upgrade failed",
                            "It looks like something went wrong when upgrading the plan. If the issue persists, please contact support.",
                            "Ok",
                            (function (param) {
                                
                              })
                          ]
                        }
                      });
                  return Promise.resolve(undefined);
                }));
  };
  var prices = getPricesPerMonth(period);
  var tmp;
  switch (state) {
    case /* Build */0 :
        tmp = "b";
        break;
    case /* Payment */1 :
        tmp = "p";
        break;
    case /* ThankYou */2 :
        tmp = "t";
        break;
    
  }
  var tmp$1;
  switch (state) {
    case /* Build */0 :
        var renderMarks = function (marks) {
          return Belt_Array.map(marks, (function (n) {
                        return {
                                value: n,
                                label: React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginTop(Css.px(2)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light09,
                                          children: $$Number.shortFormat(n | 0)
                                        }))
                              };
                      }));
        };
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  children: "Build your Team plan",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(BillingPlansCheckoutTeamV3$PeriodSwitcher, {
                  onSelect: handleSelectPeriod,
                  period: period,
                  monthlyPrices: getPricesPerMonth("monthly"),
                  annualPrices: getPricesPerMonth("annual")
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How many Tracking Plan editors are on your team?"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(480),
                  children: "Select as many editors as you need and secure a discounted price per seat when your team grows."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: $$package
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: packageDetail
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              color: Styles.Color.light12,
                              children: null
                            }, "Editors: ", React.createElement($$Text.make, {
                                  element: "Strong",
                                  weight: "Semi",
                                  children: String(editors)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.textAlign("right"),
                                    tl: /* [] */0
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.light12,
                                  children: null
                                }, React.createElement($$Text.make, {
                                      element: "Strong",
                                      weight: "Semi",
                                      children: "$" + $$Number.toLocaleString(prices.editors.unitPrice / (
                                            period === "annual" ? 12.0 : 1.0
                                          )) + ""
                                    }), " per seat"), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light09,
                                  children: "Secured price for additonal seats"
                                }))), React.createElement(Core.Slider, {
                          onChange: (function (n) {
                              Curry._1(setEditors, (function (param) {
                                      return n | 0;
                                    }));
                            }),
                          onChangeEnd: (function (param) {
                              trackInteraction("ConfigureEditors");
                            }),
                          value: editors,
                          min: 5.0,
                          max: 50.0,
                          color: "magenta100",
                          label: null,
                          step: 1.0,
                          thumbChildren: null,
                          thumbSize: 18,
                          size: 6,
                          marks: renderMarks([
                                5.0,
                                10.0,
                                15.0,
                                20.0,
                                25.0,
                                30.0,
                                35.0,
                                40.0,
                                45.0,
                                50.0
                              ]),
                          styles: {
                            thumb: Css.toJson({
                                  hd: Css.backgroundColor(Styles.Color.avoPink),
                                  tl: /* [] */0
                                })
                          }
                        })), React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      textAlign: "right",
                      color: Styles.Color.light12,
                      children: null
                    }, "$" + $$Number.toLocaleString(prices.editors.total / (
                          period === "annual" ? 12.0 : 1.0
                        )) + "", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Regular",
                          children: "/mo"
                        }))), React.createElement(Spacer.make, {
                  height: 40
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How much event volume will you use each month?"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(480),
                  children: null
                }, "Get full access to Avo Inspector without a limit on your event volume. Select your expected monthly volume to secure a discounted unit price for any additional events. Need more than 20M events/mo? ", React.createElement("button", {
                      className: textButton,
                      onClick: onContactSupport
                    }, "Contact us")), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: $$package
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: packageDetail
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              color: Styles.Color.light12,
                              children: null
                            }, "Monthly event volume: ", React.createElement($$Text.make, {
                                  element: "Strong",
                                  weight: "Semi",
                                  children: $$Number.shortFormat(inspectorVolume | 0)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.textAlign("right"),
                                    tl: /* [] */0
                                  })
                            }, inspectorVolume === 100000 ? React.createElement($$Text.make, {
                                    size: "Medium",
                                    weight: "Semi",
                                    color: Styles.Color.light12,
                                    children: "Limited"
                                  }) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                        size: "Medium",
                                        color: Styles.Color.light12,
                                        children: null
                                      }, React.createElement($$Text.make, {
                                            element: "Strong",
                                            weight: "Semi",
                                            children: "$" + String(prices.inspectorVolume.unitPrice) + ""
                                          }), " per event")), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light09,
                                  children: inspectorVolume === 100000 ? String.fromCodePoint(160) : "Secured price for additional events"
                                }))), React.createElement(Core.Slider, {
                          onChange: (function (n) {
                              Curry._1(setInspectorVolume, (function (param) {
                                      return n;
                                    }));
                            }),
                          onChangeEnd: (function (param) {
                              trackInteraction("ConfigureInspectorVolume");
                            }),
                          value: inspectorVolume,
                          min: 100000.0,
                          max: 20000000.0,
                          color: "magenta100",
                          label: null,
                          step: 100000.0,
                          thumbChildren: null,
                          thumbSize: 18,
                          size: 6,
                          marks: renderMarks([
                                100000,
                                2000000,
                                5000000,
                                10000000,
                                15000000,
                                20000000
                              ]),
                          styles: {
                            thumb: Css.toJson({
                                  hd: Css.backgroundColor(Styles.Color.avoPink),
                                  tl: /* [] */0
                                })
                          }
                        })), React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      textAlign: "right",
                      color: Styles.Color.light12,
                      children: null
                    }, "$" + $$Number.toLocaleString(prices.inspectorVolume.total / (
                          period === "annual" ? 12.0 : 1.0
                        )) + "", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Regular",
                          children: "/mo"
                        }))), React.createElement(Spacer.make, {
                  height: 40
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How many developers implement tracking on your team each month?"
                }), React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(480),
                  children: "Unlock monthly implementers to allow workspace viewers access to Avo Codegen. Select the number of expected monthly implementers to secure a discounted price per any additional active Avo Codegen users."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: $$package
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: packageDetail
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              color: Styles.Color.light12,
                              children: null
                            }, "Monthly active implementers: ", React.createElement($$Text.make, {
                                  element: "Strong",
                                  weight: "Semi",
                                  children: String(implementers)
                                })), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.textAlign("right"),
                                    tl: /* [] */0
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  color: Styles.Color.light12,
                                  children: null
                                }, React.createElement($$Text.make, {
                                      element: "Strong",
                                      weight: "Semi",
                                      children: "$" + $$Number.toLocaleString(prices.implementers.unitPrice / (
                                            period === "annual" ? 12.0 : 1.0
                                          )) + ""
                                    }), " per implementer"), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light09,
                                  children: "Secured price for additional implementers"
                                }))), React.createElement(Core.Slider, {
                          onChange: (function (n) {
                              Curry._1(setImplementers, (function (param) {
                                      return n | 0;
                                    }));
                            }),
                          onChangeEnd: (function (param) {
                              trackInteraction("ConfigureActiveImplementers");
                            }),
                          value: implementers,
                          min: 0.0,
                          max: 50.0,
                          color: "magenta100",
                          label: null,
                          step: 1.0,
                          thumbChildren: null,
                          thumbSize: 18,
                          size: 6,
                          marks: renderMarks([
                                0.0,
                                5.0,
                                10.0,
                                15.0,
                                20.0,
                                25.0,
                                30.0,
                                35.0,
                                40.0,
                                45.0,
                                50.0
                              ]),
                          styles: {
                            thumb: Css.toJson({
                                  hd: Css.backgroundColor(Styles.Color.avoPink),
                                  tl: /* [] */0
                                })
                          }
                        })), React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      textAlign: "right",
                      color: Styles.Color.light12,
                      children: null
                    }, "$" + $$Number.toLocaleString(prices.implementers.total / (
                          period === "annual" ? 12.0 : 1.0
                        )) + "", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Regular",
                          children: "/mo"
                        }))), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: checkoutSeparator
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("div", {
                      className: checkoutRow
                    }, React.createElement($$Text.make, {
                          size: "Large",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Total " + (
                            period === "annual" ? "annual" : "monthly"
                          ) + " price:"
                        }), React.createElement($$Text.make, {
                          size: "Large",
                          color: Styles.Color.light12,
                          children: null
                        }, React.createElement($$Text.make, {
                              element: "Strong",
                              weight: "Semi",
                              children: "$" + $$Number.toLocaleString(prices.total) + ""
                            }), period === "annual" ? "/yr" : "/mo")), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.$$float("right"),
                            tl: {
                              hd: Css.marginTop(Css.px(32)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(40)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(Button.make, {
                          label: "Continue to payment details",
                          onClick: handleContinueToPayment,
                          size: "large"
                        }))));
        break;
    case /* Payment */1 :
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                  children: "Payment details",
                  size: "Medium"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(BillingPlansCheckoutTeamV3$PeriodSwitcher, {
                  onSelect: handleSelectPeriod,
                  period: period,
                  monthlyPrices: getPricesPerMonth("monthly"),
                  annualPrices: getPricesPerMonth("annual")
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Your Team plan"
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: checkoutRow
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Editors: " + String(editors) + ""
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "$" + $$Number.toLocaleString(prices.editors.total / (
                                period === "annual" ? 12.0 : 1.0
                              )) + ""
                        }), "/mo")), React.createElement("div", {
                  className: checkoutSeparator
                }), React.createElement("div", {
                  className: checkoutRow
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Monthly event volume: " + $$Number.shortFormat(inspectorVolume | 0) + ""
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "$" + $$Number.toLocaleString(prices.inspectorVolume.total / (
                                period === "annual" ? 12.0 : 1.0
                              )) + ""
                        }), "/mo")), React.createElement("div", {
                  className: checkoutSeparator
                }), React.createElement("div", {
                  className: checkoutRow
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Monthly active implementers: " + String(implementers) + ""
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "$" + $$Number.toLocaleString(prices.implementers.total / (
                                period === "annual" ? 12.0 : 1.0
                              )) + ""
                        }), "/mo")), React.createElement("div", {
                  className: checkoutSeparator
                }), React.createElement("div", {
                  className: checkoutRow
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      color: Styles.Color.avoPink,
                      children: "Total " + (
                        period === "annual" ? "annual" : "monthly"
                      ) + " price:"
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.avoPink,
                      children: null
                    }, React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "$" + $$Number.toLocaleString(prices.total) + ""
                        }), period === "annual" ? "/yr" : "/mo")), React.createElement(Spacer.make, {
                  height: 60
                }), React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Payment method"
                }), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(StripeBilling.PaymentInput.make, {
                  schemaId: workspace.id,
                  onSubmitStart: (function (param) {
                      Curry._1(setCheckoutLoading, (function (param) {
                              return true;
                            }));
                    }),
                  onSubmitError: (function (param) {
                      Curry._1(setState, (function (param) {
                              return /* Payment */1;
                            }));
                    }),
                  onSubmitSuccess: handlePaymentInputSuccess,
                  isLoading: match$5[0],
                  label: "Complete upgrade",
                  couponInput: false,
                  onBack: handleBackToBuild
                }));
        break;
    case /* ThankYou */2 :
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: {
                          hd: Css.marginBottom(Css.px(8)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Title.make, {
                      children: "Thank you!",
                      size: "Medium"
                    })), React.createElement("img", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(340)),
                        tl: /* [] */0
                      }),
                  src: Asset.getPath("AvoShipIt.png")
                }), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: paymentReceived
                    }, React.createElement("div", {
                          className: paymentReceivedRow
                        }, React.createElement(IconCheckmarkCircle.make, {
                              color: Styles.Color.green
                            }), React.createElement(Spacer.make, {
                              width: 4
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light11,
                              children: "Payment Received!"
                            })), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light10,
                          children: "Look for a payment from Avo Software Inc. on your card"
                        })), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(480),
                      children: null
                    }, "We’re excited you’ve decided to upgrade to the ", React.createElement($$Text.make, {
                          element: "Strong",
                          weight: "Semi",
                          children: "Team Plan"
                        }), ". Our team is here to support you in your journey toward better analytics governance. If you have any questions please ", React.createElement(TextButton.make, {
                          onClick: onContactSupport,
                          style: "Blue",
                          size: "Large",
                          children: "reach out"
                        }), ".")));
        break;
    
  }
  return React.createElement(FramerMotion.AnimatePresence, {
              exitBeforeEnter: true,
              initial: false,
              children: React.createElement(FramerMotion.motion.div, {
                    animate: {
                      opacity: 1,
                      x: 0
                    },
                    transition: {
                      duration: 0.3
                    },
                    initial: {
                      opacity: 0,
                      x: state === /* Build */0 && lastState === /* Payment */1 ? -100 : 100
                    },
                    exit: {
                      opacity: 0,
                      x: -100
                    },
                    className: root,
                    key: tmp,
                    children: tmp$1
                  })
            });
}

var make = BillingPlansCheckoutTeamV3;

export {
  createTeamV3Subscription ,
  Style ,
  PeriodSwitcher ,
  make ,
}
/* root Not a pure module */
