// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";

var MergePropertyAllowedValuesException = /* @__PURE__ */Caml_exceptions.create("MergePropertyAllowedValuesUseCase.MergePropertyAllowedValuesException");

function merge(local, $$global) {
  if ($$global.length === 0) {
    return local;
  }
  var globalValuesMap = Belt_MapString.fromArray(Belt_Array.keepMap($$global, (function (param) {
              var stringLit = param[0];
              if (typeof stringLit === "object" && stringLit.NAME === "StringLit") {
                return [
                        stringLit.VAL,
                        param[1]
                      ];
              }
              
            })));
  var localValuesMap = Belt_MapString.fromArray(Belt_Array.keepMap(local, (function (param) {
              var stringLit = param[0];
              if (typeof stringLit === "object" && stringLit.NAME === "StringLit") {
                return [
                        stringLit.VAL,
                        param[1]
                      ];
              }
              
            })));
  var allValues = Belt_SetString.union(Belt_SetString.fromArray(Belt_MapString.keysToArray(globalValuesMap)), Belt_SetString.fromArray(Belt_MapString.keysToArray(localValuesMap)));
  return Belt_Array.map(Belt_SetString.toArray(allValues), (function (value) {
                var globalDisallowedEvents = Belt_MapString.get(globalValuesMap, value);
                var localDisallowedEvents = Belt_MapString.get(localValuesMap, value);
                var mergedDisallowedEvents;
                if (globalDisallowedEvents !== undefined) {
                  var globalEvents = Caml_option.valFromOption(globalDisallowedEvents);
                  if (localDisallowedEvents !== undefined) {
                    var localEvents = Caml_option.valFromOption(localDisallowedEvents);
                    mergedDisallowedEvents = Belt_Array.reduce(Belt_Array.concat(Belt_MapString.toArray(globalEvents), Belt_MapString.toArray(localEvents)), undefined, (function (acc, param) {
                            var eventId = param[0];
                            var match = Belt_MapString.get(globalEvents, eventId);
                            var match$1 = Belt_MapString.get(localEvents, eventId);
                            var mergedState;
                            if (match !== undefined) {
                              mergedState = match ? (
                                  match$1 !== undefined ? (
                                      match$1 ? /* DisallowedSources */({
                                            _0: match$1._0
                                          }) : /* Disallowed */0
                                    ) : /* DisallowedSources */({
                                        _0: match._0
                                      })
                                ) : /* Disallowed */0;
                            } else if (match$1 !== undefined) {
                              mergedState = match$1 ? /* DisallowedSources */({
                                    _0: match$1._0
                                  }) : /* Disallowed */0;
                            } else {
                              throw {
                                    RE_EXN_ID: MergePropertyAllowedValuesException,
                                    _1: "Unexpected error when set events have unknown configuration",
                                    Error: new Error()
                                  };
                            }
                            return Belt_MapString.set(acc, eventId, mergedState);
                          }));
                  } else {
                    mergedDisallowedEvents = globalEvents;
                  }
                } else {
                  mergedDisallowedEvents = localDisallowedEvents !== undefined ? Caml_option.valFromOption(localDisallowedEvents) : undefined;
                }
                return [
                        {
                          NAME: "StringLit",
                          VAL: value
                        },
                        mergedDisallowedEvents
                      ];
              }));
}

function mergeAll(globalRequirementsProperties, localRequirementsProperties, properties) {
  return Curry._2(TrackingPlanMappedModel.Properties.mapToT, properties, (function (property) {
                var newrecord = Caml_obj.obj_dup(property);
                var match = Curry._2(TrackingPlanMappedModel.Properties.get, globalRequirementsProperties, property.id);
                var match$1 = Curry._2(TrackingPlanMappedModel.Properties.get, localRequirementsProperties, property.id);
                newrecord.eventSpecificAllowedPropertyValues = match !== undefined ? (
                    match$1 !== undefined ? merge(match$1.eventSpecificAllowedPropertyValues, match.eventSpecificAllowedPropertyValues) : match.eventSpecificAllowedPropertyValues
                  ) : property.eventSpecificAllowedPropertyValues;
                return newrecord;
              }));
}

export {
  merge ,
  mergeAll ,
}
/* TrackingPlanMappedModel Not a pure module */
