// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function initialState_travelTo(_time) {
  
}

var initialState = {
  whenAmI: "Now",
  travelTo: initialState_travelTo
};

var context = React.createContext(initialState);

var provider = context.Provider;

function TimeMachine$Provider(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return "Now";
      });
  var setTravelTo = match[1];
  var travelTo = function (time) {
    Curry._1(setTravelTo, (function (param) {
            return time;
          }));
  };
  return React.createElement(provider, {
              value: {
                whenAmI: match[0],
                travelTo: travelTo
              },
              children: children
            });
}

var Provider = {
  make: TimeMachine$Provider
};

function use(param) {
  return React.useContext(context);
}

export {
  initialState ,
  context ,
  provider ,
  Provider ,
  use ,
}
/* context Not a pure module */
