// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as $$Window from "./externals/window.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StateTypes from "./StateTypes.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as QueryString from "query-string";
import * as StateFilterUtils from "./StateFilterUtils.mjs";

function getValues(keyValues) {
  return Belt_Option.mapWithDefault((keyValues == null) ? undefined : Caml_option.some(keyValues), [], (function (items) {
                return Js_string.split(",", items);
              }));
}

function parseQuery(param) {
  var query = window.location.search.replace("?", "");
  var querySort = Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(QueryString.parse(query).sort), "NoSorting", (function (sort) {
          return Belt_Option.getWithDefault(StateTypes.eventSortFromJs(sort), "NoSorting");
        }));
  return [
          getValues(QueryString.parse(query).sources),
          getValues(QueryString.parse(query).destinations),
          getValues(QueryString.parse(query).tags),
          getValues(QueryString.parse(query).property_groups),
          getValues(QueryString.parse(query).properties),
          getValues(QueryString.parse(query).actions),
          getValues(QueryString.parse(query).categories),
          getValues(QueryString.parse(query).stakeholders),
          querySort
        ];
}

function renderQueryStringForFilter(filterName, filters) {
  if (filters.length !== 0) {
    return "" + filterName + "=" + filters.join(",") + "";
  }
  
}

function renderQueryString(filters, sort, emptyStateOpt, param) {
  var emptyState = emptyStateOpt !== undefined ? emptyStateOpt : "";
  var sourceFilters = StateFilterUtils.getSourceFilters(filters);
  var tagFilters = StateFilterUtils.getTagFilters(filters);
  var destinationFilters = StateFilterUtils.getDestinationFilters(filters);
  var propertyGroupFilters = StateFilterUtils.getPropertyGroupFilters(filters);
  var propertyFilters = StateFilterUtils.getPropertyFilters(filters);
  var actionFilters = StateFilterUtils.getActionFilters(filters);
  var categoryFilters = StateFilterUtils.getCategoryFilters(filters);
  var domainFilters = StateFilterUtils.getDomainFilters(filters);
  var renderSortQuery = function (x) {
    if (x === "NoSorting") {
      return ;
    } else {
      return "sort=" + StateTypes.eventSortToJs(x) + "";
    }
  };
  var maybeQueries = [
    renderQueryStringForFilter("sources", sourceFilters),
    renderQueryStringForFilter("destinations", destinationFilters),
    renderQueryStringForFilter("tags", tagFilters),
    renderQueryStringForFilter("property_groups", propertyGroupFilters),
    renderQueryStringForFilter("properties", propertyFilters),
    renderQueryStringForFilter("actions", actionFilters),
    renderQueryStringForFilter("categories", categoryFilters),
    renderQueryStringForFilter("stakeholders", domainFilters),
    renderSortQuery(sort)
  ];
  var queries = Belt_Array.keep(maybeQueries, Belt_Option.isSome);
  if (queries.length !== 0) {
    return encodeURI("?" + Js_array.joinWith("&", queries));
  } else {
    return emptyState;
  }
}

function useSetFilterQueryParams(filters, sort) {
  var match = Hooks.usePrevious2([
        filters,
        sort
      ]);
  var previousSort = match[1];
  var previousFilters = match[0];
  React.useEffect((function () {
          if (Caml_obj.notequal(filters, previousFilters) || sort !== previousSort) {
            $$Window.$$History.replaceStateSilent(renderQueryString(filters, sort, "?", undefined));
          }
          
        }), [
        filters,
        sort
      ]);
  React.useEffect((function () {
          $$Window.$$History.replaceStateSilent(renderQueryString(filters, sort, undefined, undefined));
        }), []);
}

export {
  getValues ,
  parseQuery ,
  renderQueryStringForFilter ,
  renderQueryString ,
  useSetFilterQueryParams ,
}
/* Hooks Not a pure module */
