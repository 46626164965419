// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../Text.mjs";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as InspectorViewInstallDocs from "./InspectorViewInstallDocs.mjs";

function InspectorViewInstallDocsPosthog(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Follow the steps below to stream your data from PostHog to Avo, no need to add any code to your codebase."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Once you’ve connected the official Avo Inspector plugin, the schemas of all events seen in Posthog will automatically be sent to Inspector!"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(InspectorViewInstallDocs.Link.make, {
                  path: "workspace/connect-inspector-to-posthog",
                  label: "Inspector PostHog integration docs"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How to set up Inspector with PostHog:"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 1,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "You finished this step by creating this source. Well done! 🎉"
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 2,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: null
                      }, "In your PostHog workspace, click ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "Plugins"
                          }), " in the sidebar and search for ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "Avo Inspector"
                          }), ". Enable the plugin and paste in the API key. Save it by pressing the ", React.createElement($$Text.make, {
                            element: "Span",
                            weight: "Semi",
                            children: "Save"
                          }), " button under the code editor.")
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 3,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "Test the PostHog Integration."
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 3,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "Set env key in PostHog plugin to prod and you are ready!"
                      })
                }));
}

var make = InspectorViewInstallDocsPosthog;

export {
  make ,
}
/* Text Not a pure module */
