// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";

function Make(T) {
  var getNameWithoutLastBrackets = function (name) {
    var match = Belt_List.fromArray(Belt_Array.reverse(name.split(" ")));
    if (match && new RegExp("^\\((\\d)+\\)$").test(match.hd)) {
      return Belt_Array.reverse(Belt_List.toArray(match.tl)).join(" ");
    } else {
      return name;
    }
  };
  var duplicateNameFromList = function (name, items) {
    var nameSuffixNumber = Belt_List.reduce(Belt_List.keepMap(Belt_List.keep(items, (function (item) {
                    return Curry._1(T.getName, item).startsWith(getNameWithoutLastBrackets(name));
                  })), (function (item) {
                var match = Belt_List.fromArray(Belt_Array.reverse(Curry._1(T.getName, item).split(" ")));
                if (!match) {
                  return ;
                }
                var maybeNumWithBrackets = match.hd;
                if (new RegExp("^\\((\\d)+\\)$").test(maybeNumWithBrackets)) {
                  return maybeNumWithBrackets.replace(new RegExp("[\\(\\)]", "g"), "");
                }
                
              })), 0, (function (highestNumber, numString) {
            var num = Pervasives.int_of_string_opt(numString);
            if (num !== undefined && num > highestNumber) {
              return num;
            } else {
              return highestNumber;
            }
          })) + 1 | 0;
    return getNameWithoutLastBrackets(name) + " (" + String(nameSuffixNumber) + ")";
  };
  var duplicateNameFromArray = function (name, items) {
    return duplicateNameFromList(name, Belt_List.fromArray(items));
  };
  return {
          duplicateNameFromList: duplicateNameFromList,
          duplicateNameFromArray: duplicateNameFromArray
        };
}

export {
  Make ,
}
/* No side effect */
