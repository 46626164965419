// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import Downloadjs from "downloadjs";
import * as IconChevron from "./IconChevron.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.maxWidth(Css.pct(100)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light10),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var expandToggleStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.hover({
                    hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.05)),
                    tl: /* [] */0
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("spaceBetween"),
                    tl: {
                      hd: Css.paddingTop(Css.px(12)),
                      tl: {
                        hd: Css.paddingRight(Css.px(12)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(12)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(12)),
                            tl: {
                              hd: Css.backgroundColor("transparent"),
                              tl: {
                                hd: Css.borderRadius(Css.px(3)),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$short
                                        }, undefined, undefined, "background-color"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function expandToggleChevronStyles(expanded) {
  return Curry._1(Css.style, {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.transform({
                      NAME: "rotate",
                      VAL: {
                        NAME: "deg",
                        VAL: expanded ? 180.0 : 0.0
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var loadingStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.height(Css.px(200)),
            tl: {
              hd: Css.marginLeft(Css.px(12)),
              tl: {
                hd: Css.marginRight(Css.px(12)),
                tl: {
                  hd: Css.paddingTop(Css.px(12)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(12)),
                    tl: {
                      hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light08),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.marginLeft(Css.px(12)),
        tl: {
          hd: Css.marginRight(Css.px(12)),
          tl: {
            hd: Css.paddingTop(Css.px(12)),
            tl: {
              hd: Css.paddingBottom(Css.px(12)),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.height(Css.px(200)),
                  tl: {
                    hd: Css.color(Styles.Color.light04),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light10),
                      tl: {
                        hd: Css.fontSize(Css.px(11)),
                        tl: {
                          hd: Css.fontFamily(Styles.FontFamily.polar),
                          tl: {
                            hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light08),
                            tl: {
                              hd: Css.borderWidth(Css.px(0)),
                              tl: {
                                hd: Css.outlineStyle("none"),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(12)),
        tl: {
          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.white),
          tl: {
            hd: Css.zIndex(1),
            tl: {
              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(-2), Css.px(8), undefined, undefined, Css.rgba(0, 0, 0, {
                            NAME: "num",
                            VAL: 0.16
                          }))),
              tl: /* [] */0
            }
          }
        }
      }
    });

function FileView(Props) {
  var mimeTypeOpt = Props.mimeType;
  var filename = Props.filename;
  var code = Props.code;
  var loadingOpt = Props.loading;
  var startExpandedOpt = Props.startExpanded;
  var onExpandOpt = Props.onExpand;
  var onCopyOpt = Props.onCopy;
  var onDownloadOpt = Props.onDownload;
  var mimeType = mimeTypeOpt !== undefined ? mimeTypeOpt : "text/plain";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var startExpanded = startExpandedOpt !== undefined ? startExpandedOpt : false;
  var onExpand = onExpandOpt !== undefined ? onExpandOpt : (function (param) {
        
      });
  var onCopy = onCopyOpt !== undefined ? onCopyOpt : (function (param) {
        
      });
  var onDownload = onDownloadOpt !== undefined ? onDownloadOpt : (function (param) {
        
      });
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return startExpanded;
      });
  var setExpanded = match[1];
  var expanded = match[0];
  var handleDownloadClick = function (param) {
    Curry._1(onDownload, undefined);
    Downloadjs(new Blob([code], {
              encoding: "UTF-8",
              type: mimeType
            }), filename, mimeType);
  };
  var handleCopyClick = function (param) {
    Curry._1(onCopy, undefined);
    CopyTextToClipboard(code);
    Curry._1(addToast, {
          message: "Copied!",
          toastType: /* Success */0
        });
  };
  return React.createElement("section", {
              className: rootStyles
            }, React.createElement("button", {
                  className: expandToggleStyles,
                  onClick: (function (param) {
                      if (!expanded) {
                        Curry._1(onExpand, undefined);
                      }
                      Curry._1(setExpanded, (function (expanded) {
                              return !expanded;
                            }));
                    })
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.white,
                      children: filename
                    }), React.createElement("div", {
                      className: expandToggleChevronStyles(expanded)
                    }, React.createElement(IconChevron.make, {}))), expanded ? (
                loading ? React.createElement("div", {
                        className: loadingStyles
                      }, React.createElement(LoadingCircle.make, {
                            color: Styles.Color.white
                          })) : React.createElement("textarea", {
                        key: filename,
                        className: contentStyles,
                        readOnly: true,
                        value: code
                      })
              ) : null, expanded ? React.createElement("footer", {
                    className: footerStyles
                  }, React.createElement(Button.make, {
                        disabled: loading,
                        label: "Download",
                        onClick: handleDownloadClick
                      }), React.createElement(Spacer.make, {
                        width: 8
                      }), React.createElement(Button.make, {
                        disabled: loading,
                        label: "Copy",
                        onClick: handleCopyClick
                      })) : null);
}

var make = FileView;

export {
  rootStyles ,
  expandToggleStyles ,
  expandToggleChevronStyles ,
  loadingStyles ,
  contentStyles ,
  footerStyles ,
  make ,
}
/* rootStyles Not a pure module */
