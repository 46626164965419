// Generated by ReScript, PLEASE EDIT WITH CARE

import SetupMd from "./setup.md";
import TermsMd from "./terms.md";
import JsHowtoMd from "./js-howto.md";
import ReHowtoMd from "./re-howto.md";
import TsHowtoMd from "./ts-howto.md";
import IosBrazeMd from "./ios-braze.md";
import PhpHowtoMd from "./php-howto.md";
import JavaHowtoMd from "./java-howto.md";
import ObjcHowtoMd from "./objc-howto.md";
import RubyHowtoMd from "./ruby-howto.md";
import IosSegmentMd from "./ios-segment.md";
import JsV2HowtoMd from "./js-v2-howto.md";
import ReV2HowtoMd from "./re-v2-howto.md";
import SwiftHowtoMd from "./swift-howto.md";
import ExpoSegmentMd from "./expo-segment.md";
import IosMixpanelMd from "./ios-mixpanel.md";
import KotlinHowtoMd from "./kotlin-howto.md";
import NodeSegmentMd from "./node-segment.md";
import PythonHowtoMd from "./python-howto.md";
import AndroidBrazeMd from "./android-braze.md";
import IosAmplitudeMd from "./ios-amplitude.md";
import NodeIntercomMd from "./node-intercom.md";
import NodeMixpanelMd from "./node-mixpanel.md";
import ExpoAmplitudeMd from "./expo-amplitude.md";
import NodeAmplitudeMd from "./node-amplitude.md";
import AndroidSegmentMd from "./android-segment.md";
import GtmJsV2HowtoMd from "./gtm-js-v2-howto.md";
import PythonMixpanelMd from "./python-mixpanel.md";
import AndroidMixpanelMd from "./android-mixpanel.md";
import AndroidAmplitudeMd from "./android-amplitude.md";
import JavaAndroidHowtoMd from "./java-android-howto.md";
import ReactNativeSegmentMd from "./react-native-segment.md";
import IosFirebaseAnalyticsMd from "./ios-firebase-analytics.md";
import ReactNativeAmplitudeMd from "./react-native-amplitude.md";
import KotlinConstructorHowtoMd from "./kotlin-constructor-howto.md";
import AndroidFirebaseAnalyticsMd from "./android-firebase-analytics.md";
import ReactNativeFirebaseAnalyticsMd from "./react-native-firebase-analytics.md";

var setup = SetupMd;

var reactNativeFirebase = ReactNativeFirebaseAnalyticsMd;

var jsHowTo = JsHowtoMd;

var reHowTo = ReHowtoMd;

var jsV2HowTo = JsV2HowtoMd;

var gtmJsV2HowTo = GtmJsV2HowtoMd;

var tsHowTo = TsHowtoMd;

var reV2HowTo = ReV2HowtoMd;

var swiftHowTo = SwiftHowtoMd;

var objcHowTo = ObjcHowtoMd;

var kotlinHowTo = KotlinHowtoMd;

var kotlinConstructorHowTo = KotlinConstructorHowtoMd;

var javaHowTo = JavaHowtoMd;

var javaAndroidHowTo = JavaAndroidHowtoMd;

var pythonHowTo = PythonHowtoMd;

var phpHowTo = PhpHowtoMd;

var rubyHowTo = RubyHowtoMd;

var reactNativeAmplitude = ReactNativeAmplitudeMd;

var nodeAmplitude = NodeAmplitudeMd;

var nodeMixpanel = NodeMixpanelMd;

var nodeSegment = NodeSegmentMd;

var nodeIntercom = NodeIntercomMd;

var terms = TermsMd;

var reactNativeSegment = ReactNativeSegmentMd;

var iosSegment = IosSegmentMd;

var iosMixpanel = IosMixpanelMd;

var iosAmplitude = IosAmplitudeMd;

var androidSegment = AndroidSegmentMd;

var androidAmplitude = AndroidAmplitudeMd;

var androidFirebaseAnalytics = AndroidFirebaseAnalyticsMd;

var iosFirebaseAnalytics = IosFirebaseAnalyticsMd;

var androidBraze = AndroidBrazeMd;

var androidMixpanel = AndroidMixpanelMd;

var iosBraze = IosBrazeMd;

var expoAmplitude = ExpoAmplitudeMd;

var expoSegment = ExpoSegmentMd;

var pythonMixpanel = PythonMixpanelMd;

export {
  expoAmplitude ,
  expoSegment ,
  setup ,
  reactNativeFirebase ,
  jsHowTo ,
  reHowTo ,
  jsV2HowTo ,
  gtmJsV2HowTo ,
  tsHowTo ,
  reV2HowTo ,
  swiftHowTo ,
  objcHowTo ,
  reactNativeAmplitude ,
  nodeAmplitude ,
  nodeMixpanel ,
  nodeSegment ,
  nodeIntercom ,
  terms ,
  reactNativeSegment ,
  iosSegment ,
  iosMixpanel ,
  iosAmplitude ,
  androidSegment ,
  androidAmplitude ,
  kotlinHowTo ,
  kotlinConstructorHowTo ,
  javaHowTo ,
  javaAndroidHowTo ,
  pythonHowTo ,
  phpHowTo ,
  rubyHowTo ,
  androidFirebaseAnalytics ,
  iosFirebaseAnalytics ,
  androidBraze ,
  androidMixpanel ,
  iosBraze ,
  pythonMixpanel ,
}
/* setup Not a pure module */
