// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "./Hover.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as $$Window from "./externals/window.mjs";
import * as DateFns from "./DateFns.mjs";
import * as IconLink from "./IconLink.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as IconArrowDown from "./IconArrowDown.mjs";
import * as IconCheckmark from "./IconCheckmark.mjs";
import * as SourceSnippet from "./SourceSnippet.mjs";
import ReactLazyload from "react-lazyload";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as ImplementEventDoc from "./ImplementEventDoc.mjs";

function isInView(id) {
  var element = $$Window.$$Document.getElementById(id);
  if (element === undefined) {
    return false;
  }
  var box = Caml_option.valFromOption(element).getBoundingClientRect();
  if (box.top < window.innerHeight) {
    return box.bottom >= 0;
  } else {
    return false;
  }
}

function scrollIntoView(id) {
  Belt_Option.forEach($$Window.$$Document.getElementById(id), (function (element) {
          element.scrollIntoView({
                inline: "center"
              });
        }));
}

var scrollContainerId = "source-implementation-instructions-container";

function ImplementEvent(Props) {
  var $$event = Props.event;
  var source = Props.source;
  var model = Props.model;
  var enrichedModel = Props.enrichedModel;
  var completedOpt = Props.completed;
  var lastSeen = Props.lastSeen;
  var validSource = Props.validSource;
  var isActive = Props.isActive;
  var completed = completedOpt !== undefined ? completedOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setScrolledIntoView = match[1];
  var scrolledIntoView = match[0];
  React.useEffect((function () {
          if (!(isActive && !scrolledIntoView)) {
            return ;
          }
          var intervalId = setInterval((function (param) {
                  if (isInView($$event.id)) {
                    return Curry._1(setScrolledIntoView, (function (param) {
                                  return true;
                                }));
                  } else {
                    return scrollIntoView($$event.id);
                  }
                }), 200);
          return (function (param) {
                    clearInterval(intervalId);
                  });
        }), [scrolledIntoView]);
  var tmp;
  if (lastSeen !== undefined) {
    var distance = DateFns.formatDistanceToNow(false, false, Caml_option.valFromOption(lastSeen));
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.marginRight(Css.px(10)),
                tl: {
                  hd: Css.fontSize(Styles.FontSize.small),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.color(Styles.Color.light08),
                      tl: /* [] */0
                    }
                  }
                }
              })
        }, "Last seen in dev " + distance + " ago");
  } else {
    tmp = null;
  }
  var source$1 = AvoConfig.validateSource(source, model);
  var tmp$1;
  if (source$1.TAG === /* Ok */0) {
    var source$2 = source$1._0;
    tmp$1 = source$2.platform !== "JsonSchema" ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.fontSize(Styles.FontSize.regular),
                  tl: {
                    hd: Css.padding(Css.px(15)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", Styles.Color.light01),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement(CollapsedContent.make, {
                button: (function (onClick, isCollapsed) {
                    return React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("flexStart"),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    Curry._1(onClick, undefined);
                                  })
                              }, isCollapsed ? "Show code snippet" : "Hide code snippet", React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft(Css.px(10)),
                                          tl: {
                                            hd: Css.transform({
                                                  NAME: "rotate",
                                                  VAL: isCollapsed ? ({
                                                        NAME: "deg",
                                                        VAL: 0.0
                                                      }) : ({
                                                        NAME: "deg",
                                                        VAL: 180.0
                                                      })
                                                }),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(IconArrowDown.make, {
                                        size: 12,
                                        color: Styles.Color.darkBlue
                                      })));
                  }),
                children: (function (param) {
                    return React.createElement(SourceSnippet.make, {
                                model: model,
                                source: source$2,
                                event: $$event
                              });
                  })
              })) : null;
  } else {
    tmp$1 = React.createElement("div", undefined, "Finish configuring the source");
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.padding2(Css.px(24), Css.px(0)),
                        tl: {
                          hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.width(Css.pct(100)),
                              tl: {
                                hd: Css.padding4(Css.px(12), Css.px(4), Css.px(8), Css.px(0)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: {
                                      hd: Css.textAlign("left"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.margin(Css.px(0)),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.medium),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.color(completed ? Styles.Color.darkGreen : Styles.Color.light12),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement("a", {
                              className: Curry._1(Css.style, {
                                    hd: Css.paddingRight(Css.px(4)),
                                    tl: /* [] */0
                                  }),
                              id: $$event.id,
                              href: "#" + $$event.id + "",
                              name: $$event.id
                            }, React.createElement(Hover.make, {
                                  children: (function (isHover) {
                                      return React.createElement(IconLink.make, {
                                                  size: 12,
                                                  color: isHover || isActive ? Styles.Color.darkBlue : Styles.Color.light07
                                                });
                                    })
                                })), completed ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginRight(Css.px(5)),
                                      tl: {
                                        hd: Css.display("inline"),
                                        tl: {
                                          hd: Css.marginTop(Css.px(-1)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(IconCheckmark.make, {
                                    size: 12,
                                    color: Styles.Color.darkGreen
                                  })) : null, $$event.name), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              })
                        }, tmp)), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginRight(Css.px(5)),
                            tl: /* [] */0
                          })
                    }, React.createElement(ContextMenu.make, {
                          options: [{
                              NAME: "Option",
                              VAL: {
                                label: "View Event",
                                onClick: (function (param) {
                                    Router.Schema.pushDrawerItem(undefined, undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        });
                                  })
                              }
                            }]
                        }))), React.createElement(ReactLazyload, {
                  once: true,
                  offset: 750,
                  placeholder: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.minHeight(Css.px(50)),
                              tl: {
                                hd: Css.padding(Css.px(30)),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.light07),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.small),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      }, "Loading documentation..."),
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(0), Css.px(10)),
                              tl: /* [] */0
                            })
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(0), Css.px(4)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, $$event.description), React.createElement(ImplementEventDoc.make, {
                            enrichedModel: enrichedModel,
                            eventId: $$event.id,
                            validSource: validSource
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding4(Css.px(0), Css.px(0), Css.px(16), Css.px(0)),
                                  tl: /* [] */0
                                })
                          }, tmp$1)),
                  scrollContainer: "#" + scrollContainerId + "",
                  key: $$event.id + source.id
                }));
}

var make = ImplementEvent;

export {
  isInView ,
  scrollIntoView ,
  scrollContainerId ,
  make ,
}
/* Css Not a pure module */
