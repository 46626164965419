// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function decodeRequestBody(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          branchId: Json_decode.field("branchId", Json_decode.string, json),
          integrationId: Json_decode.field("integrationId", Json_decode.string, json)
        };
}

function encodeRequestBody(requestBody) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                requestBody.schemaId
              ],
              tl: {
                hd: [
                  "branchId",
                  requestBody.branchId
                ],
                tl: {
                  hd: [
                    "integrationId",
                    requestBody.integrationId
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function encodeResponseBody(responseBody) {
  return Json_encode.object_(Belt_List.concatMany([
                  {
                    hd: [
                      "ok",
                      responseBody.ok
                    ],
                    tl: /* [] */0
                  },
                  Belt_Option.mapWithDefault(responseBody.versions, /* [] */0, (function (versions) {
                          return {
                                  hd: [
                                    "versions",
                                    Json_encode.array((function (prim) {
                                            return prim;
                                          }), versions)
                                  ],
                                  tl: /* [] */0
                                };
                        })),
                  Belt_Option.mapWithDefault(responseBody.error, /* [] */0, (function (error) {
                          return {
                                  hd: [
                                    "error",
                                    error
                                  ],
                                  tl: /* [] */0
                                };
                        }))
                ]));
}

function decodeResponseBody(json) {
  return {
          ok: Json_decode.field("ok", Json_decode.bool, json),
          versions: Json_decode.optional((function (param) {
                  return Json_decode.field("versions", (function (param) {
                                return Json_decode.array(Json_decode.$$int, param);
                              }), param);
                }), json),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

export {
  decodeRequestBody ,
  encodeRequestBody ,
  encodeResponseBody ,
  decodeResponseBody ,
}
/* No side effect */
