// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as DiffGlobalRequirementsEvent from "./DiffGlobalRequirementsEvent.mjs";
import * as DiffGlobalRequirementsProperty from "./DiffGlobalRequirementsProperty.mjs";
import * as DiffGlobalRequirementsPropertyBundle from "./DiffGlobalRequirementsPropertyBundle.mjs";

var container = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: /* [] */0
    });

function innerContainer(withComments) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("baseline"),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.marginTop(Css.px(20)),
                      tl: {
                        hd: Css.marginRight(Css.px(withComments ? (DiffComments.commentsWidth + 20 | 0) - 1 | 0 : 0)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-1)),
                          tl: {
                            hd: Css.marginBottom(Css.px(2)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.large),
                              tl: {
                                hd: Css.color(Styles.Color.light11),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.padding2(Css.px(13), Css.px(20)),
                                    tl: {
                                      hd: Css.maxWidth(DiffComponents.maxWidthPx),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light02, 0.5)),
                                        tl: {
                                          hd: Css.zIndex(1),
                                          tl: {
                                            hd: Css.backdropFilter({
                                                  hd: {
                                                    NAME: "blur",
                                                    VAL: Css.px(10)
                                                  },
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function DiffGlobalRequirements(Props) {
  var actions = Props.actions;
  var toModel = Props.toModel;
  var withComments = Props.withComments;
  var mappedModel = React.useMemo((function () {
          return TrackingPlanMappedModel.fromOld(toModel);
        }), [toModel]);
  var eventsConverted = Belt_List.keep(actions, (function (objectActions) {
          if (!objectActions) {
            return false;
          }
          var match = objectActions.hd;
          var match$1 = match[0];
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "Event") {
            return false;
          }
          var match$2 = match[1];
          if (typeof match$2 !== "object") {
            return false;
          }
          var variant = match$2.NAME;
          if (variant === "ConvertEventToGlobalEventV2") {
            return true;
          } else {
            return variant === "ConvertEventToGlobalEvent";
          }
        }));
  var n = Belt_List.size(eventsConverted);
  var eventsConvertedText = n !== 0 ? AvoUtils.plural(undefined, undefined, n, "event") : undefined;
  var propertiesConverted = Belt_List.keep(actions, (function (objectActions) {
          if (!objectActions) {
            return false;
          }
          var match = objectActions.hd;
          var match$1 = match[0];
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "Property") {
            return false;
          }
          var match$2 = match[1];
          if (typeof match$2 !== "object") {
            return false;
          }
          var variant = match$2.NAME;
          if (variant === "ConvertPropertyToGlobalPropertyV2") {
            return true;
          } else {
            return variant === "ConvertPropertyToGlobalProperty";
          }
        }));
  var n$1 = Belt_List.size(propertiesConverted);
  var propertiesConvertedText = n$1 !== 0 ? AvoUtils.plural("properties", undefined, n$1, "property") : undefined;
  var propertyBundlesConverted = Belt_List.keep(actions, (function (objectActions) {
          if (!objectActions) {
            return false;
          }
          var match = objectActions.hd;
          var match$1 = match[0];
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "PropertyGroup") {
            return false;
          }
          var match$2 = match[1];
          if (typeof match$2 === "object") {
            return match$2.NAME === "ConvertPropertyBundleToGlobalPropertyBundle";
          } else {
            return false;
          }
        }));
  var n$2 = Belt_List.size(propertyBundlesConverted);
  var propertyBundlesConvertedText = n$2 !== 0 ? AvoUtils.plural(undefined, undefined, n$2, "property bundle") : undefined;
  var convertedText = StringExt.joinToSentence(Belt_Array.keepMap([
            eventsConvertedText,
            propertyBundlesConvertedText,
            propertiesConvertedText
          ], (function (i) {
              return i;
            })));
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: Curry._1(Css.merge, {
                        hd: innerContainer(withComments),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.blue),
                                tl: {
                                  hd: Css.borderRadius(Css.px(8)),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(24)),
                                        tl: {
                                          hd: Css.paddingBottom(Css.px(24)),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.flexDirection("column"),
                                              tl: {
                                                hd: Css.gap(Css.px(16)),
                                                tl: {
                                                  hd: Css.width(Css.pct(100.0)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      weight: "Semi",
                      children: "" + convertedText + " converted to Global requirements"
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(24)),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          })
                    }, Belt_List.size(eventsConverted) > 0 ? React.createElement(DiffGlobalRequirementsEvent.make, {
                            eventsConverted: eventsConverted,
                            events: mappedModel.events,
                            globalRequirements: mappedModel.globalRequirements
                          }) : null), Belt_List.size(propertyBundlesConverted) > 0 ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingLeft(Css.px(24)),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.size(propertyBundlesConverted) > 0 ? React.createElement(DiffGlobalRequirementsPropertyBundle.make, {
                              propertyBundlesConverted: propertyBundlesConverted,
                              propertyBundles: mappedModel.propertyBundles,
                              globalRequirements: mappedModel.globalRequirements
                            }) : null) : null, Belt_List.size(propertiesConverted) > 0 ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.paddingLeft(Css.px(24)),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.size(propertiesConverted) > 0 ? React.createElement(DiffGlobalRequirementsProperty.make, {
                              propertiesConverted: propertiesConverted,
                              properties: mappedModel.properties,
                              globalRequirements: mappedModel.globalRequirements
                            }) : null) : null));
}

var make = DiffGlobalRequirements;

export {
  make ,
}
/* container Not a pure module */
