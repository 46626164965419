// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as IconPhoto from "./IconPhoto.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProfilePhoto from "./ProfilePhoto.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as InputWithState from "./InputWithState.mjs";
import ReactDropzone from "react-dropzone";
import LodashDebounce from "lodash.debounce";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

var debouncedUpdateName = LodashDebounce((function (userId, name) {
        FirebaseUtils.updateUserName(userId, name);
      }), 500, {
      leading: false,
      trailing: true
    });

function ProfileModal(Props) {
  var userId = Props.userId;
  var match = ViewerContext.use(undefined);
  var match$1 = React.useState(function () {
        return /* Idle */0;
      });
  var setUploadStatus = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setIsLoggingOut = match$2[1];
  var userState = FirebaseFetcherHooks.useUser(userId);
  var addToast = Toast.useAddToast(undefined);
  var isViewer = userId === match.id;
  var tmp;
  if (typeof userState === "object") {
    var user = userState.VAL;
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DidMount.make, {
              didMount: (function (param) {
                  AnalyticsRe.profileViewed(user.id, Belt_Option.flatMap(Caml_option.nullable_to_opt(user.picture), (function (picture) {
                              return picture.url;
                            })), AvoConfig.getUserDisplayName(user), isViewer);
                })
            }), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.borderRadius(Css.pct(50)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.marginTop(Css.px(-76)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement(ProfilePhoto.make, {
                  user: user,
                  size: 112
                }), isViewer ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(0)),
                            tl: {
                              hd: Css.right(Css.px(0)),
                              tl: {
                                hd: Css.bottom(Css.px(0)),
                                tl: {
                                  hd: Css.left(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(ReactDropzone, {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.pct(100)),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: {
                                        hd: Css.borderRadius(Css.pct(50)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light10),
                                          tl: {
                                            hd: Css.opacity(0.0),
                                            tl: {
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$default
                                                  }, undefined, undefined, "all"),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.opacity(0.75),
                                                      tl: /* [] */0
                                                    }),
                                                tl: {
                                                  hd: Css.cursor("pointer"),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        onDrop: (function (files) {
                            var file = Belt_Array.get(files, 0);
                            if (file !== undefined) {
                              var file$1 = Caml_option.valFromOption(file);
                              var userName = Caml_option.nullable_to_opt(user.name);
                              var hadProfilePic = Belt_Option.isSome(Caml_option.nullable_to_opt(user.picture));
                              Curry._1(setUploadStatus, (function (param) {
                                      return /* Uploading */1;
                                    }));
                              var storagePath = "/users/" + userId + "/pictures/";
                              var dbRef = Firebase.app(undefined).firestore().collection("users").doc(userId);
                              FirebaseUtils.uploadPicture(storagePath, dbRef, file$1).then(function (picture) {
                                    AnalyticsRe.profileUpdated(userId, userName, "ProfilePic", undefined, undefined, hadProfilePic, true, true, picture.url, picture.url);
                                    Curry._1(setUploadStatus, (function (param) {
                                            return /* Idle */0;
                                          }));
                                    return Promise.resolve(undefined);
                                  });
                              return ;
                            }
                            
                          }),
                        children: match$1[0] ? React.createElement(LoadingCircle.make, {}) : React.createElement(IconPhoto.make, {})
                      })) : null), React.createElement(Spacer.make, {
              height: 16
            }), isViewer ? React.createElement(InputWithState.make, {
                value: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), ""),
                onChange: (function (name) {
                    debouncedUpdateName(userId, name);
                  }),
                onChangeCompleted: (function (value, valueBefore) {
                    AnalyticsRe.profileUpdated(user.id, value, "UserName", valueBefore.length === 0 ? undefined : valueBefore, value, undefined, undefined, Belt_Option.isSome(Caml_option.nullable_to_opt(user.picture)), Belt_Option.flatMap(Caml_option.nullable_to_opt(user.picture), (function (picture) {
                                return picture.url;
                              })), undefined);
                  }),
                children: (function (value, onChange, onBlur, onFocus) {
                    return React.createElement(Input.make, {
                                value: value,
                                onChange: onChange,
                                placeholder: "Add name...",
                                onBlur: onBlur,
                                onFocus: onFocus,
                                className: {
                                  hd: Css.borderColor(Styles.Color.light04),
                                  tl: {
                                    hd: Css.textAlign("center"),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.huge),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              });
                  })
              }) : React.createElement($$Text.make, {
                size: "Huge",
                weight: "Semi",
                color: Styles.Color.light12,
                children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(user.name), "")
              }), React.createElement(Spacer.make, {
              height: 8
            }), React.createElement($$Text.make, {
              size: "Large",
              weight: "Medium",
              color: Styles.Color.light09,
              children: Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(user.email), null, (function (prim) {
                      return prim;
                    }))
            }));
  } else {
    tmp = userState === "NotFound" ? null : React.createElement(LoadingCircle.make, {
            color: Styles.Color.avoPink
          });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(Css.vw(90)),
                    tl: {
                      hd: Css.minWidth(Css.px(350)),
                      tl: {
                        hd: Css.maxHeight(Css.pct(100)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.padding(Css.px(20)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.boxShadow(Styles.Shadow.box),
                                    tl: {
                                      hd: Css.borderRadius(Styles.Border.radius),
                                      tl: {
                                        hd: Css.cursor("default"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, tmp), isViewer ? React.createElement("div", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.marginTop(Css.px(8)),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("column"),
                                        tl: {
                                          hd: Css.alignItems("stretch"),
                                          tl: {
                                            hd: Css.justifyContent("stretch"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Button.make, {
                        label: "Sign Out",
                        loading: match$2[0],
                        onClick: (function (param) {
                            Curry._1(setIsLoggingOut, (function (param) {
                                    return true;
                                  }));
                            AuthenticationMethods.signOut(Firebase.app(undefined).auth()).then(function (result) {
                                  if (result.TAG === /* Ok */0) {
                                    return ;
                                  }
                                  Curry._1(setIsLoggingOut, (function (param) {
                                          return false;
                                        }));
                                  Curry._1(addToast, {
                                        message: result._0,
                                        toastType: /* Error */1
                                      });
                                });
                          }),
                        size: "large",
                        style: "outline"
                      })) : null);
}

var make = ProfileModal;

export {
  debouncedUpdateName ,
  make ,
}
/* debouncedUpdateName Not a pure module */
