// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "../../app/src/App.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Router from "../../app/src/Router.mjs";
import * as Iubenda from "../../app/src/externals/iubenda.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Posthog from "../../app/src/externals/Posthog.mjs";
import * as Version from "../../app/src/version.mjs";
import * as Clearbit from "../../app/src/externals/clearbit.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "../../app/src/externals/intercom.mjs";
import PosthogJs from "posthog-js";
import * as AnalyticsRe from "../../app/src/analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as AvoInspector from "avo-inspector/reason/AvoInspector.mjs";
import * as Client from "react-dom/client";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as DestinationInterfaceUtils from "../../shared/utils/DestinationInterfaceUtils.mjs";
import * as WebSegmentDestinationInterface from "./WebSegmentDestinationInterface.mjs";

import "../../app/src/index.css"
;

var fb = Firebase.app(undefined);

try {
  Iubenda.init(undefined);
}
catch (raw_error){
  var error = Caml_js_exceptions.internalToOCamlException(raw_error);
  console.warn("Could not initialize Iubenda", error);
}

((window.fb = fb));

((window.AVO_ENV = 'web'));

var posthog = PosthogJs;

Posthog.init(posthog, "phc_KuimORATdTX1DNZhtMqD0PxV9M48VNHq9qybNfH0rEO");

var inspector = process.env.NODE_ENV === "production" ? AvoInspector.make("vROIBfqRYqc4W8KLyfGf", "Prod", Version.current, undefined) : AvoInspector.make("vROIBfqRYqc4W8KLyfGf", "Dev", Version.current, undefined);

function inspectorCustomDestination_make(_env, _apiKey) {
  
}

function inspectorCustomDestination_logEvent(eventName, eventProperties) {
  AvoInspector.trackSchemaFromEvent(inspector, eventName, eventProperties);
}

function inspectorCustomDestination_logPage(_eventName, _eventProperties) {
  
}

function inspectorCustomDestination_revenue(_eventName, _eventProperties) {
  
}

function inspectorCustomDestination_setUserProperties(_userId, _userProperties) {
  
}

function inspectorCustomDestination_identify(_userId) {
  
}

function inspectorCustomDestination_unidentify(param) {
  
}

function inspectorCustomDestination_setGroupProperties(_groupType, _groupId, _groupProperties) {
  
}

function inspectorCustomDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function inspectorCustomDestination_logEventWithGroups(eventName, eventProperties, _groupTypesToGroupIds) {
  AvoInspector.trackSchemaFromEvent(inspector, eventName, eventProperties);
}

var inspectorCustomDestination = {
  make: inspectorCustomDestination_make,
  logEvent: inspectorCustomDestination_logEvent,
  logPage: inspectorCustomDestination_logPage,
  revenue: inspectorCustomDestination_revenue,
  setUserProperties: inspectorCustomDestination_setUserProperties,
  identify: inspectorCustomDestination_identify,
  unidentify: inspectorCustomDestination_unidentify,
  setGroupProperties: inspectorCustomDestination_setGroupProperties,
  addCurrentUserToGroup: inspectorCustomDestination_addCurrentUserToGroup,
  logEventWithGroups: inspectorCustomDestination_logEventWithGroups
};

function updateIntercomCompanyProps(companyId, companyPropertiesOpt, companyName) {
  var companyProperties = companyPropertiesOpt !== undefined ? Caml_option.valFromOption(companyPropertiesOpt) : undefined;
  if (companyProperties === undefined) {
    return Intercom.update({
                company: {
                  id: companyId,
                  name: companyName
                }
              });
  }
  var intercomUpdate = (function (companyId, companyName, companyProperties) {
          if (typeof window === 'undefined') { return; }
          window.Intercom('update', {"company": Object.assign(companyProperties, {
            "id": companyId,
            "name": companyName
          })});
        });
  intercomUpdate(companyId, Belt_Option.getWithDefault(companyName, companyId), Caml_option.valFromOption(companyProperties));
}

function trackCompanyGroupProps(groupName, groupProperties, onDetected) {
  if (groupName.toLowerCase() !== "workspace") {
    return ;
  }
  var groupPropertiesDict = Js_json.decodeObject(groupProperties);
  if (groupPropertiesDict === undefined) {
    return ;
  }
  var workspaceName = Js_dict.get(Caml_option.valFromOption(groupPropertiesDict), "Workspace Name");
  if (workspaceName !== undefined) {
    return Curry._1(onDetected, Js_json.decodeString(Caml_option.valFromOption(workspaceName)));
  }
  
}

function intercomGroupAnalyticsCustomDestination_make(_env, _apiKey) {
  
}

function intercomGroupAnalyticsCustomDestination_logEvent(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_logPage(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_revenue(_eventName, _eventProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_setUserProperties(_userId, _userProperties) {
  
}

function intercomGroupAnalyticsCustomDestination_identify(_userId) {
  
}

function intercomGroupAnalyticsCustomDestination_unidentify(param) {
  
}

function intercomGroupAnalyticsCustomDestination_setGroupProperties(groupName, groupId, groupProperties) {
  trackCompanyGroupProps(groupName, DestinationInterfaceUtils.filterNoneFromJsonObject(groupProperties), (function (companyName) {
          updateIntercomCompanyProps(groupId, Caml_option.some(Caml_option.some(groupProperties)), companyName);
        }));
}

function intercomGroupAnalyticsCustomDestination_addCurrentUserToGroup(groupName, groupId, groupProperties) {
  trackCompanyGroupProps(groupName, DestinationInterfaceUtils.filterNoneFromJsonObject(groupProperties), (function (companyName) {
          updateIntercomCompanyProps(groupId, Caml_option.some(Caml_option.some(groupProperties)), companyName);
        }));
}

function intercomGroupAnalyticsCustomDestination_logEventWithGroups(_eventName, eventProperties, groupTypesToGroupIds) {
  var match = Js_json.decodeObject(groupTypesToGroupIds);
  var match$1 = Js_json.decodeObject(eventProperties);
  if (match !== undefined && match$1 !== undefined) {
    var match$2 = Js_dict.get(Caml_option.valFromOption(match), "Workspace");
    var match$3 = Js_dict.get(Caml_option.valFromOption(match$1), "Schema Name");
    if (match$2 !== undefined && match$3 !== undefined) {
      Intercom.update({
            company: {
              id: Caml_option.valFromOption(match$2),
              name: Caml_option.valFromOption(match$3)
            }
          });
    }
    
  }
  
}

var intercomGroupAnalyticsCustomDestination = {
  make: intercomGroupAnalyticsCustomDestination_make,
  logEvent: intercomGroupAnalyticsCustomDestination_logEvent,
  logPage: intercomGroupAnalyticsCustomDestination_logPage,
  revenue: intercomGroupAnalyticsCustomDestination_revenue,
  setUserProperties: intercomGroupAnalyticsCustomDestination_setUserProperties,
  identify: intercomGroupAnalyticsCustomDestination_identify,
  unidentify: intercomGroupAnalyticsCustomDestination_unidentify,
  setGroupProperties: intercomGroupAnalyticsCustomDestination_setGroupProperties,
  addCurrentUserToGroup: intercomGroupAnalyticsCustomDestination_addCurrentUserToGroup,
  logEventWithGroups: intercomGroupAnalyticsCustomDestination_logEventWithGroups
};

function postHogDestination_make(_env, _apiKey) {
  
}

function postHogDestination_logEvent(eventName, eventProperties) {
  posthog.capture(eventName, Caml_option.some(eventProperties));
}

function postHogDestination_logPage(_eventName, _eventProperties) {
  posthog.capture("$pageview", undefined);
}

function postHogDestination_revenue(_eventName, _eventProperties) {
  
}

function postHogDestination_setUserProperties(userId, userProperties) {
  posthog.identify(userId, Caml_option.some(DestinationInterfaceUtils.filterNoneFromJsonObject(userProperties)), undefined);
}

function postHogDestination_identify(userId) {
  posthog.identify(userId, undefined, undefined);
}

function postHogDestination_unidentify(param) {
  
}

function postHogDestination_setGroupProperties(_eventName, _groupId, _groupProperties) {
  
}

function postHogDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function postHogDestination_logEventWithGroups(_eventName, _eventProperties, _groupTypesToGroupIds) {
  
}

var postHogDestination = {
  make: postHogDestination_make,
  logEvent: postHogDestination_logEvent,
  logPage: postHogDestination_logPage,
  revenue: postHogDestination_revenue,
  setUserProperties: postHogDestination_setUserProperties,
  identify: postHogDestination_identify,
  unidentify: postHogDestination_unidentify,
  setGroupProperties: postHogDestination_setGroupProperties,
  addCurrentUserToGroup: postHogDestination_addCurrentUserToGroup,
  logEventWithGroups: postHogDestination_logEventWithGroups
};

function clearbitDestination_make(_env, _apiKey) {
  
}

function clearbitDestination_logEvent(_eventName, _eventProperties) {
  
}

function clearbitDestination_logPage(_eventName, _eventProperties) {
  
}

function clearbitDestination_revenue(_eventName, _eventProperties) {
  
}

function clearbitDestination_setUserProperties(_userId, userProperties) {
  var maybeEmail = Json_decode.optional((function (param) {
          return Json_decode.field("Email", Json_decode.string, param);
        }), DestinationInterfaceUtils.filterNoneFromJsonObject(userProperties));
  try {
    var match = Clearbit.makeOption(undefined);
    if (maybeEmail !== undefined && match !== undefined) {
      Caml_option.valFromOption(match).identify(maybeEmail, {
            email: maybeEmail
          });
      return ;
    } else {
      return ;
    }
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    console.warn("Could not identify user with Clearbit", error);
    return ;
  }
}

function clearbitDestination_identify(_userId) {
  
}

function clearbitDestination_unidentify(param) {
  
}

function clearbitDestination_setGroupProperties(_eventName, _groupId, _groupProperties) {
  
}

function clearbitDestination_addCurrentUserToGroup(_groupType, _groupId, _groupProperties) {
  
}

function clearbitDestination_logEventWithGroups(_eventName, _eventProperties, _groupTypesToGroupIds) {
  
}

var clearbitDestination = {
  make: clearbitDestination_make,
  logEvent: clearbitDestination_logEvent,
  logPage: clearbitDestination_logPage,
  revenue: clearbitDestination_revenue,
  setUserProperties: clearbitDestination_setUserProperties,
  identify: clearbitDestination_identify,
  unidentify: clearbitDestination_unidentify,
  setGroupProperties: clearbitDestination_setGroupProperties,
  addCurrentUserToGroup: clearbitDestination_addCurrentUserToGroup,
  logEventWithGroups: clearbitDestination_logEventWithGroups
};

AnalyticsRe.initAvo(undefined, {
      position: {
        TAG: /* BottomRight */0,
        _0: {
          bottom: 76,
          right: 26
        }
      }
    }, undefined, undefined, process.env.NODE_ENV === "production" ? "prod" : "dev", {
      client: "Web",
      version: Version.current
    }, undefined, postHogDestination, WebSegmentDestinationInterface.destination, clearbitDestination, intercomGroupAnalyticsCustomDestination, inspectorCustomDestination, undefined);

var container = document.querySelector("#root");

if (!(container == null)) {
  var root = Client.createRoot(container);
  root.render(JsxRuntime.jsx(Router.Context.Provider.make, {
            children: JsxRuntime.jsx(App.JSX4.make, {})
          }));
}

export {
  fb ,
  posthog ,
  inspector ,
  inspectorCustomDestination ,
  updateIntercomCompanyProps ,
  trackCompanyGroupProps ,
  intercomGroupAnalyticsCustomDestination ,
  postHogDestination ,
  clearbitDestination ,
}
/*  Not a pure module */
