// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DidMount from "../DidMount.mjs";
import * as IconImport from "../IconImport.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as NewEventModal from "../NewEventModal.mjs";
import * as CreateButtonIcon from "./CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./EmptyStatesStyles.mjs";

function EventsEmpty(Props) {
  var viewerCanEdit = Props.viewerCanEdit;
  var schemaBundle = Props.schemaBundle;
  var propertiesCount = Props.propertiesCount;
  var openNewEventModal = NewEventModal.useOpen("EventsView");
  return React.createElement("main", {
              className: EmptyStatesStyles.rootStyles
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(420)),
                        tl: /* [] */0
                      })
                }, React.createElement(Title.make, {
                      children: "Align your team behind industry analytics leading workflows",
                      size: "Medium"
                    })), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light10,
                  maxWidth: Css.px(520),
                  children: null
                }, "Your Tracking Plan is the source of truth for what data you track, where you track it and how. To get started, import your existing tracking plan (we support most common formats) or create an event. Learn more in our ", React.createElement("a", {
                      className: EmptyStatesStyles.linkStyles,
                      href: "https://www.avo.app/docs/workflow/overview",
                      rel: "noopener",
                      target: "_blank"
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "docs"
                        })), "."), viewerCanEdit ? React.createElement("div", {
                    className: EmptyStatesStyles.actionsStyles,
                    id: "empty-events-actions"
                  }, React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Events", "Setup", undefined);
                            Router.push(undefined, Router.Schema.getImportRoute(undefined, "TrackingPlanEventsEmptyState", {
                                      NAME: "import",
                                      VAL: "index"
                                    }));
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, React.createElement(IconImport.make, {
                                color: Styles.Color.light08,
                                size: 60
                              })), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Import Existing Tracking Plan"
                          })), React.createElement(DidMount.make, {
                        didMount: (function (param) {
                            AnalyticsRe.importCallToActionSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, 0, propertiesCount, schemaBundle.branchId, schemaBundle.schemaId);
                          })
                      }), React.createElement("button", {
                        className: EmptyStatesStyles.actionStyles,
                        onClick: (function (param) {
                            Curry._1(openNewEventModal, undefined);
                            AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Events", "Setup", undefined);
                          })
                      }, React.createElement("div", {
                            className: EmptyStatesStyles.iconStyles
                          }, CreateButtonIcon.icon), React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Create Your First Event"
                          }))) : null);
}

var make = EventsEmpty;

export {
  make ,
}
/* Css Not a pure module */
