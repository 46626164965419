// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as $$Window from "./externals/window.mjs";
import * as Iubenda from "./externals/iubenda.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PromiseExt from "./externals/PromiseExt.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App from "firebase/app";
import * as QueryString from "query-string";
import * as React from "@sentry/react";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as Cookie$JustgageReasonCookie from "@justgage/reason-cookie/src/Cookie.mjs";

function getFormString(param) {
  var formElement = document.createElement("form");
  formElement.innerHTML = document.querySelector("#form").innerHTML;
  var passwordInputElement = Curry._2($$Window.Dom.$$Element.querySelector, formElement, "input[type=password]");
  Belt_Option.forEach(passwordInputElement, (function (passwordInputElement) {
          passwordInputElement.parentNode.removeChild(passwordInputElement);
        }));
  return formElement.innerHTML;
}

function getMaybeAnonymousUser(auth) {
  var user = auth.currentUser;
  if (!(user == null) && user.isAnonymous) {
    return Caml_option.some(user);
  }
  
}

function processLogin(userCredential, authMethod, isNewUser, wasAnonymousUser, forceReloadUser) {
  if (wasAnonymousUser) {
    Curry._1(forceReloadUser, undefined);
  }
  var user = userCredential.user;
  var email = user.email;
  var email$1 = (email == null) ? undefined : Caml_option.some(email);
  var initialReferrer = Cookie$JustgageReasonCookie.getAsString("initialReferrer");
  React.setUser({
        id: user.uid
      });
  if (isNewUser || wasAnonymousUser) {
    var username = user.displayName;
    if (!(username == null)) {
      FirebaseUtils.updateUserName(user.uid, username);
    }
    AnalyticsRe.signedUp(user.uid, email$1, authMethod, initialReferrer, Caml_option.nullable_to_opt(user.displayName));
    AnalyticsRe.signedUpIntercom(user.uid, authMethod, email$1, Caml_option.nullable_to_opt(user.displayName));
    Iubenda.submit(userCredential.user.uid, email$1, getFormString(undefined));
  } else {
    AnalyticsRe.signedIn(user.uid, email$1, authMethod);
  }
  return Promise.resolve(undefined);
}

function redirectToWelcome(param) {
  var maybeRedirect = QueryString.parse(Router.getSearch(undefined).replace("?", "")).redirect;
  Router.replace(Belt_Option.getWithDefault(Belt_Option.map((maybeRedirect == null) ? undefined : Caml_option.some(maybeRedirect), (function (redirect) {
                  if (redirect.startsWith("/welcome")) {
                    return "/welcome" + Belt_Option.mapWithDefault(Belt_Array.get(redirect.split("?"), 1), "", (function (query) {
                                  return "?" + query;
                                }));
                  } else {
                    return "/welcome?redirect=" + encodeURIComponent(redirect);
                  }
                })), "/welcome?redirect=" + Router.buildRedirectPath(undefined)));
}

function getAuthenticationErrorFromException(error) {
  var match = PromiseExt.getErrorCodeOptional(error);
  var match$1 = PromiseExt.getErrorMessageOptional(error);
  if (match !== undefined) {
    switch (match) {
      case "auth/credential-already-in-use" :
          return [
                  "CredentialAlreadyInUse",
                  "Hmm, the credentials you provided are already in use. Please try a different method."
                ];
      case "auth/email-already-in-use" :
          return [
                  "EmailAlreadyInUse",
                  "Hmm, that email is already taken."
                ];
      case "auth/internal-error" :
          if (match$1 !== undefined && match$1.startsWith("Missing password requirements:")) {
            var missingRequirements = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Belt_Array.get(match$1.split("["), 1), (function (str) {
                            return str.slice(0, str.length - 1 | 0);
                          })), (function (str) {
                        return str.split(",");
                      })), []);
            return [
                    "PasswordDoesNotMeetRequirements",
                    "The password you provided does not fit the following requirements: " + missingRequirements.join(", ") + ""
                  ];
          }
          break;
      case "auth/invalid-email" :
          return [
                  "InvalidEmail",
                  "Hmm, the email address you provided is not valid."
                ];
      case "auth/token-revoked" :
          return [
                  "RefreshTokenRevoked",
                  "Session is expired, please sign in again to continue."
                ];
      case "auth/operation-not-allowed" :
      case "auth/user-disabled" :
      case "auth/user-not-found" :
          return [
                  "OperationNotAllowed",
                  "We couldn't finish the authentication process using the details you provided."
                ];
      case "auth/weak-password" :
          return [
                  "WeakPassword",
                  "Please use a stronger password."
                ];
      case "auth/wrong-password" :
          return [
                  "WrongPassword",
                  "The password you provided is incorrect."
                ];
      default:
        
    }
    Sentry.captureException(error, {});
    console.error("Unhandled authentication error: " + match, error);
    return [
            "Unknown",
            "Hmm, this is awkward, something unexpected happened while trying to sign up. If the issue persists, please contact support."
          ];
  }
  Sentry.captureException(error, {});
  console.error("Unknown authentication error", error);
  return [
          "Unknown",
          "Hmm, this is awkward, something unexpected happened while trying to sign up. If the issue persists, please contact support."
        ];
}

var Utils = {
  redirectToWelcome: redirectToWelcome,
  getAuthenticationErrorFromException: getAuthenticationErrorFromException
};

function signInViaGoogle(auth, emailHint, forceReloadUser) {
  var provider = new (App.default.auth.GoogleAuthProvider)();
  Belt_List.forEach(Firebase.Auth.googleOauthScopes, (function (scope) {
          provider.addScope(scope);
        }));
  provider.setCustomParameters({
        login_hint: emailHint
      });
  var maybeAnonymousUser = getMaybeAnonymousUser(auth);
  return (
            maybeAnonymousUser !== undefined ? $$Promise.$$catch(Caml_option.valFromOption(maybeAnonymousUser).linkWithPopup(provider), (function (error) {
                      var match = getAuthenticationErrorFromException(error);
                      if (match[0] === "CredentialAlreadyInUse") {
                        return auth.signInWithPopup(provider);
                      } else {
                        return Promise.reject(error);
                      }
                    })) : auth.signInWithPopup(provider)
          ).then(function (userCredential) {
              return processLogin(userCredential, "Google", userCredential.additionalUserInfo.isNewUser, Belt_Option.isSome(maybeAnonymousUser), forceReloadUser);
            });
}

function signInViaSaml(auth, authProviderId, forceReloadUser) {
  var provider = new (App.default.auth.SAMLAuthProvider)(authProviderId);
  var maybeAnonymousUser = getMaybeAnonymousUser(auth);
  return (
            maybeAnonymousUser !== undefined ? $$Promise.$$catch(Caml_option.valFromOption(maybeAnonymousUser).linkWithPopup(provider), (function (error) {
                      var match = getAuthenticationErrorFromException(error);
                      if (match[0] === "CredentialAlreadyInUse") {
                        return auth.signInWithPopup(provider);
                      } else {
                        return Promise.reject(error);
                      }
                    })) : auth.signInWithPopup(provider)
          ).then(function (userCredential) {
              return processLogin(userCredential, "Sso", userCredential.additionalUserInfo.isNewUser, Belt_Option.isSome(maybeAnonymousUser), forceReloadUser);
            });
}

function signUpViaEmailAndPassword(auth, email, password, forceReloadUser) {
  var maybeAnonymousUser = getMaybeAnonymousUser(auth);
  var tmp;
  if (maybeAnonymousUser !== undefined) {
    var credentials = App.default.auth.EmailAuthProvider.credential(email, password);
    tmp = Caml_option.valFromOption(maybeAnonymousUser).linkWithCredential(credentials);
  } else {
    tmp = auth.createUserWithEmailAndPassword(email, password);
  }
  return tmp.then(function (userCredential) {
              return processLogin(userCredential, "Email", userCredential.additionalUserInfo.isNewUser, Belt_Option.isSome(maybeAnonymousUser), forceReloadUser);
            });
}

function signInViaEmailAndPassword(auth, email, password) {
  return auth.signInWithEmailAndPassword(email, password).then(function (user) {
              AnalyticsRe.signedIn(user.user.uid, Caml_option.nullable_to_opt(user.user.email), "Email");
              React.setUser({
                    id: user.user.uid
                  });
              return Promise.resolve(undefined);
            });
}

function signInAsAnonymous(auth) {
  return auth.signInAnonymously().then(function (_userCreds) {
              AnalyticsRe.anonymousUserCreated(Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).uid);
            });
}

function signOutFromApi(param) {
  var url = "" + Firebase.apiUrl + "/auth/signout";
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                    return fetch(url, Fetch.RequestInit.make(/* Post */2, [
                                      [
                                        "Accept",
                                        "application/json"
                                      ],
                                      [
                                        "Content-Type",
                                        "application/json"
                                      ],
                                      [
                                        "Authorization",
                                        "Bearer " + token + ""
                                      ]
                                    ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                  }).then(function (resp) {
                  var match = resp.status;
                  if (match !== 200) {
                    return Promise.resolve({
                                TAG: /* Error */1,
                                _0: "Unexpected error occurred while signing out, please try again."
                              });
                  } else {
                    return Promise.resolve({
                                TAG: /* Ok */0,
                                _0: undefined
                              });
                  }
                }), (function (error) {
                console.error("Failed to sign out from API", error);
                Sentry.captureException(error, {});
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: "Unexpected error occurred while signing out, please try again."
                          });
              }));
}

function signOut(auth) {
  return signOutFromApi(undefined).then(function (result) {
              if (result.TAG === /* Ok */0) {
                return auth.signOut().then(function (param) {
                            React.setUser(null);
                            AnalyticsRe.signedOut(undefined);
                            return Promise.resolve({
                                        TAG: /* Ok */0,
                                        _0: undefined
                                      });
                          });
              } else {
                return Promise.resolve(result);
              }
            });
}

function refreshIdToken(param) {
  var auth = Firebase.app(undefined).auth();
  return $$Promise.$$catch(Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser)).getIdToken(true).then(function (_token) {
                  return Promise.resolve({
                              TAG: /* Ok */0,
                              _0: undefined
                            });
                }), (function (error) {
                console.error("Failed to refresh id token", error);
                Sentry.captureException(error, {});
                return signOut(auth);
              }));
}

export {
  Utils ,
  signInViaGoogle ,
  signInViaSaml ,
  signUpViaEmailAndPassword ,
  signInViaEmailAndPassword ,
  signInAsAnonymous ,
  signOut ,
  refreshIdToken ,
}
/* Router Not a pure module */
