// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Import from "./import/Import.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as Billing from "./Billing.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Metrics from "./Metrics.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as AuditLog from "./AuditLog.mjs";
import * as CmdKRoot from "./CmdKRoot.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as LearnHub from "./LearnHub.mjs";
import * as NoAccess from "./NoAccess.mjs";
import * as Redirect from "./Redirect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as BranchBar from "./BranchBar.mjs";
import * as Implement from "./Implement.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as BranchView from "./BranchView.mjs";
import * as RolePrompt from "./RolePrompt.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CmdKResults from "./CmdKResults.mjs";
import * as Connections from "./Connections.mjs";
import * as EventsSheet from "./EventsSheet.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as Announcement from "./Announcement.mjs";
import * as BranchStatus from "./externals/BranchStatus.mjs";
import * as Integrations from "./integration/Integrations.mjs";
import * as NewUserModal from "./NewUserModal.mjs";
import * as App from "firebase/app";
import * as QueryString from "query-string";
import * as ReactHelmet from "react-helmet";
import * as DomainDetails from "./domains/DomainDetails.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as BranchOverview from "./branchOverview/BranchOverview.mjs";
import * as GettingStarted from "./GettingStarted.mjs";
import * as ModalsContainer from "./ModalsContainer.mjs";
import * as PropertiesSheet from "./PropertiesSheet.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as ImportSuccessModal from "./import/ImportSuccessModal.mjs";
import * as SlideOverContainer from "./SlideOverContainer.mjs";
import * as InspectorEventsView from "./inspector/InspectorEventsView.mjs";
import * as InspectorIssuesView from "./InspectorIssuesView.mjs";
import * as PropertyGroupsSheet from "./PropertyGroupsSheet.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GuardRailsConfigView from "./guardrails/GuardRailsConfigView.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";
import * as InspectorIssuesSavedViews from "./inspector/InspectorIssuesSavedViews.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as GettingStarted__ProgressStore from "./GettingStarted__ProgressStore.mjs";
import * as OnboardingAndTrialProgressSlideover from "./OnboardingAndTrialProgressSlideover.mjs";

function getCurrentBranchName(currentBranch, masterModel) {
  if (typeof currentBranch !== "object") {
    return "main";
  }
  var branchId = currentBranch.VAL;
  return Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.openBranches, (function (param) {
                    return branchId === param[0];
                  })), "N/A", (function (param) {
                return param[1];
              }));
}

function useUpdateSchemaLastUsedAt(userId, schemaId) {
  React.useEffect((function () {
          $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(userId).collection("schemas").doc(schemaId).update({
                    lastUsedAt: App.default.firestore.FieldValue.serverTimestamp()
                  }), (function (e) {
                  return Promise.resolve((console.error("could not update schemaLastUsedAt", e), undefined));
                }));
        }), []);
}

function useBranchOpenedAnalytics(branchStatus, integrations, currentBranch, model) {
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          var maybeDotNewRedirect = QueryString.parse(Router.getSearch(undefined).replace("?", "")).new;
          var tmp;
          if (maybeDotNewRedirect == null) {
            tmp = undefined;
          } else {
            switch (maybeDotNewRedirect) {
              case "branch" :
                  tmp = "NewBranch";
                  break;
              case "event" :
                  tmp = "NewEvent";
                  break;
              case "metric" :
                  tmp = "NewMetric";
                  break;
              case "property" :
                  tmp = "NewProperty";
                  break;
              default:
                tmp = undefined;
            }
          }
          AnalyticsRe.branchOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid, BranchStatus.toAnalytics(branchStatus), Belt_List.length(integrations), tmp, Caml_option.nullable_to_opt(Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).email), Belt_List.size(model.events), Belt_List.size(model.properties), schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.branchId, BranchStatus.toAnalytics(branchStatus), schemaBundle.schemaId, currentBranch === "Master" ? Belt_List.length(model.events) : undefined, currentBranch === "Master" ? Belt_List.length(model.properties) : undefined, schemaBundle.schemaName, schemaBundle.schemaId, schemaBundle.schemaTrialPlan, schemaBundle.schemaBillingStatus, schemaBundle.schemaSubscriptionPlan, currentBranch === "Master" ? Belt_List.length(model.destinations) : undefined, currentBranch === "Master" ? Belt_List.length(model.sources) : undefined);
          Intercom.update({
                alignment: "right"
              });
        }), []);
}

function useIntercomAlignment(alignment) {
  React.useEffect((function () {
          Intercom.update({
                alignment: alignment
              });
        }), []);
}

function useNewUserPrompt(hasOpenDialog) {
  var globalSend = GlobalSendContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var match = WorkspaceContext.use(undefined);
  var isSandbox = match.isSandbox;
  var hasShownMessageThisSession = React.useRef(false);
  React.useEffect((function () {
          var modalTimeoutId = {
            contents: undefined
          };
          if (NewUserModal.shouldShowModal(user) && !hasShownMessageThisSession.current && !hasOpenDialog && !isSandbox) {
            modalTimeoutId.contents = Caml_option.some(setTimeout((function (param) {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: "NewUser"
                            });
                        hasShownMessageThisSession.current = true;
                      }), 1000));
          }
          return (function (param) {
                    Belt_Option.forEach(modalTimeoutId.contents, (function (prim) {
                            clearTimeout(prim);
                          }));
                  });
        }), [hasOpenDialog]);
}

function AvoRoutes$IntercomVisibility(Props) {
  var drawerItems = RouterStore.Schema.useDrawerItems(undefined);
  Hooks.useIntercomVisibility(Belt_Option.mapWithDefault(Belt_List.head(drawerItems), true, (function (item) {
              if (typeof item !== "object") {
                return true;
              }
              var variant = item.NAME;
              if (variant === "eventVariant") {
                return item.VAL[1] === undefined;
              } else if (variant === "event") {
                return item.VAL[2] === undefined;
              } else {
                return true;
              }
            })));
  return null;
}

var IntercomVisibility = {
  make: AvoRoutes$IntercomVisibility
};

function AvoRoutes$EnrichedSlideOverContainer(Props) {
  var schema = Props.schema;
  var model = Props.model;
  var masterModel = Props.masterModel;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                Belt_Option.getExn(param.schemaId),
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var drawerItems = match[2];
  var schemaRoute = match[1];
  var globalSend = GlobalSendContext.use(undefined);
  var globalState = GlobalStateContext.use(undefined);
  var match$1 = globalState.slideOver;
  var tmp;
  var exit = 0;
  if (schemaRoute === "settings" && drawerItems) {
    var match$2 = drawerItems.hd;
    if (typeof match$2 === "object") {
      var variant = match$2.NAME;
      if (variant === "source" || variant === "destination") {
        tmp = undefined;
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$1 !== undefined) {
      if (typeof match$1 === "object") {
        var tmp$1 = {
          onClose: (function (param) {
              Curry._1(globalSend, /* CloseSlideOver */0);
            }),
          model: renderModel,
          viewerRole: role,
          openBranches: masterModel.openBranches
        };
        if (match$1.VAL !== undefined) {
          tmp$1.overlay = Caml_option.valFromOption(match$1.VAL);
        }
        tmp = [
          React.createElement(GuardRailsConfigView.make, tmp$1),
          "discrepancies"
        ];
      } else {
        tmp = [
          React.createElement(OnboardingAndTrialProgressSlideover.make, {
                schema: schema,
                onClose: (function (param) {
                    Curry._1(globalSend, /* CloseSlideOver */0);
                  }),
                globalSend: globalSend,
                integrations: model.integrations,
                openBranches: masterModel.openBranches
              }),
          "onboarding"
        ];
      }
    } else {
      tmp = undefined;
    }
  }
  return React.createElement(SlideOverContainer.make, {
              onClose: (function (param) {
                  if (schemaRoute !== "settings") {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  if (!drawerItems) {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  var match = drawerItems.hd;
                  if (typeof match !== "object") {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                  var variant = match.NAME;
                  if (variant === "source" || variant === "destination") {
                    return Router.Schema.pushSchemaRoute(undefined, undefined, "settings");
                  } else {
                    return Curry._1(globalSend, /* CloseSlideOver */0);
                  }
                }),
              slideOver: tmp
            });
}

var EnrichedSlideOverContainer = {
  make: AvoRoutes$EnrichedSlideOverContainer
};

function AvoRoutes$EnrichedImportCompleteModal(Props) {
  var latestAction = Props.latestAction;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var importSuccessQueryParam = ImportReview__V2__Utils.useImportSuccessQueryParam(undefined);
  var importStartedLocationQueryParam = ImportReview__V2__Utils.useImportStartedLocationQueryParam(undefined);
  var eventsImportedCountQueryParam = ImportReview__V2__Utils.useEventsImportedCountQueryParam(undefined);
  var propertiesImportedCountQueryParam = ImportReview__V2__Utils.usePropertiesImportedCountQueryParam(undefined);
  var sourcesImportedCountQueryParam = ImportReview__V2__Utils.useSourcesImportedCountQueryParam(undefined);
  var resolvedIssuesCountQueryParam = ImportReview__V2__Utils.useResolvedIssuesCountQueryParam(undefined);
  var newStakeholderDomainCountQueryParam = ImportReview__V2__Utils.useNewStakeholderDomainCountQueryParam(undefined);
  var openModal = GlobalSendContext.useOpenModal(undefined);
  React.useEffect((function () {
          if (importSuccessQueryParam.value) {
            Curry._1(importSuccessQueryParam.removeByReplace, undefined);
            Curry._1(openModal, {
                  NAME: "ImportSuccess",
                  VAL: {
                    eventCount: Belt_Option.getWithDefault(eventsImportedCountQueryParam.value, 0),
                    propertyCount: Belt_Option.getWithDefault(propertiesImportedCountQueryParam.value, 0),
                    sourceCount: Belt_Option.getWithDefault(sourcesImportedCountQueryParam.value, 0),
                    resolvedIssuesCount: Belt_Option.getWithDefault(resolvedIssuesCountQueryParam.value, 0),
                    importOrigin: importStartedLocationQueryParam.value,
                    newStakeholderDomainCount: Belt_Option.getWithDefault(newStakeholderDomainCountQueryParam.value, 0)
                  }
                });
            Router.QueryParams.removeQueryParamsByReplace([
                  ImportReview__V2__Utils.QueryParams.importStartedLocation,
                  ImportReview__V2__Utils.QueryParams.resolvedIssuesCount,
                  ImportReview__V2__Utils.QueryParams.eventsImportedCount,
                  ImportReview__V2__Utils.QueryParams.propertiesImportedCount,
                  ImportReview__V2__Utils.QueryParams.sourcesImportedCount
                ]);
          }
          
        }), [importSuccessQueryParam]);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.zIndex(Styles.ZIndex.eventDetailsFullScreen),
                    tl: /* [] */0
                  })
            }, typeof schemaRoute === "object" && schemaRoute.NAME === "import" && schemaRoute.VAL === "success" ? Belt_Option.getWithDefault(Belt_Option.map(latestAction, (function (latestAction) {
                          var match = latestAction.contents;
                          if (typeof match !== "object") {
                            return null;
                          }
                          if (match.NAME !== "CompletedImport") {
                            return null;
                          }
                          var match$1 = match.VAL;
                          var importOrigin = importStartedLocationQueryParam.value;
                          return React.createElement(ImportSuccessModal.make, {
                                      eventCount: match$1[0],
                                      propertyCount: match$1[1],
                                      sourceCount: match$1[2],
                                      newStakeholderDomainCount: match$1[3],
                                      resolvedIssuesCount: 0,
                                      importOrigin: importOrigin,
                                      onMount: (function (param) {
                                          Curry._1(importStartedLocationQueryParam.removeByReplace, undefined);
                                        }),
                                      onClose: (function (param) {
                                          
                                        })
                                    });
                        })), null) : null);
}

var EnrichedImportCompleteModal = {
  make: AvoRoutes$EnrichedImportCompleteModal
};

function getInitialRedirect(hasGettingStarted, param) {
  if (param.loading) {
    return "wait";
  } else if (!(param.dismissed || param.complete) && hasGettingStarted) {
    return "gettingStarted";
  } else {
    return "dashboard";
  }
}

function useInitialRedirect(param) {
  var initialTimestamp = React.useMemo((function () {
          return DateFns.addMilliseconds(3000.0, new Date());
        }), []);
  var mayRedirectInitially = React.useRef(true);
  var hasGettingStarted = GettingStarted.useHasGettingStarted(undefined);
  var onboardingProgress = GettingStarted__ProgressStore.use(undefined);
  var match = React.useState(function () {
        return getInitialRedirect(hasGettingStarted, onboardingProgress);
      });
  var setInitialRedirect = match[1];
  var initialRedirect = match[0];
  var match$1 = React.useState(function () {
        if (initialRedirect === "wait") {
          return !DateFns.isAfter(initialTimestamp, new Date());
        } else {
          return false;
        }
      });
  var setShouldWait = match$1[1];
  React.useEffect((function () {
          if (initialRedirect !== "wait") {
            return ;
          }
          var intervalId = setInterval((function (param) {
                  var newInitialRedirect = getInitialRedirect(hasGettingStarted, onboardingProgress);
                  var shouldWait = newInitialRedirect === "wait" && !DateFns.isAfter(initialTimestamp, new Date());
                  Curry._1(setShouldWait, (function (param) {
                          return shouldWait;
                        }));
                  Curry._1(setInitialRedirect, (function (param) {
                          if (shouldWait) {
                            return newInitialRedirect;
                          } else {
                            return "dashboard";
                          }
                        }));
                }), 250);
          return (function (param) {
                    clearInterval(intervalId);
                  });
        }), [
        hasGettingStarted,
        onboardingProgress
      ]);
  React.useEffect((function () {
          if (initialRedirect === "wait" && !onboardingProgress.loading) {
            var newInitialRedirect = getInitialRedirect(hasGettingStarted, onboardingProgress);
            Curry._1(setInitialRedirect, (function (param) {
                    return newInitialRedirect;
                  }));
            Curry._1(setShouldWait, (function (param) {
                    return newInitialRedirect === "wait";
                  }));
          }
          
        }), [onboardingProgress.loading]);
  React.useEffect(function () {
        if (initialRedirect !== "wait") {
          mayRedirectInitially.current = false;
        }
        
      });
  return [
          mayRedirectInitially,
          initialRedirect,
          match$1[0]
        ];
}

function getPageTitle(workspaceName, schemaRoute) {
  var tmp;
  if (typeof schemaRoute === "object") {
    var variant = schemaRoute.NAME;
    if (variant === "inspector") {
      var match = schemaRoute.VAL;
      tmp = typeof match === "object" ? "Inspector Saved View" : (
          match === "issues" ? "Inspector Issues" : (
              match === "events" ? "Inspector Events" : "Inspector Saved Views"
            )
        );
    } else if (variant === "import") {
      var match$1 = schemaRoute.VAL;
      tmp = match$1 === "success" ? "Import success" : (
          match$1 === "index" ? "Import" : "Import review"
        );
    } else if (variant === "domain") {
      tmp = "Domain";
    } else {
      var match$2 = schemaRoute.VAL;
      tmp = typeof match$2 === "object" && match$2.NAME !== "comment" ? "Branch Code Changes" : "Branch changes";
    }
  } else {
    tmp = schemaRoute === "billing" ? "Billing" : (
        schemaRoute === "properties" ? "Properties" : (
            schemaRoute === "auditLog" ? "Audit log" : (
                schemaRoute === "settings" ? "Sources" : (
                    schemaRoute === "dashboard" ? "Branches" : (
                        schemaRoute === "gettingStarted" ? "Onboarding" : (
                            schemaRoute === "integrations" ? "Publishing" : (
                                schemaRoute === "events" ? "Events" : (
                                    schemaRoute === "metrics" ? "Metrics" : (
                                        schemaRoute === "implement" ? "Codegen" : "Property Groups"
                                      )
                                  )
                              )
                          )
                      )
                  )
              )
          )
      );
  }
  return tmp + (" · " + workspaceName + "");
}

function AvoRoutes(Props) {
  var branchStatus = Props.branchStatus;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var masterModel = Props.masterModel;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  var match = RouterStore.Schema.useSlices(function (param) {
        return [
                Belt_Option.getExn(param.schemaId),
                param.baseRoute,
                param.branch,
                param.schemaRoute,
                param.drawerItems
              ];
      });
  var schemaRoute = match[3];
  var branch = match[2];
  var baseRoute = match[1];
  var schemaId = match[0];
  var globalSend = GlobalSendContext.use(undefined);
  var user = ViewerContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var hasGettingStarted = GettingStarted.useHasGettingStarted(undefined);
  var match$1 = useInitialRedirect(undefined);
  var match$2 = React.useState(function () {
        return true;
      });
  var setUserJustOpeningApp = match$2[1];
  var userJustOpeningApp = match$2[0];
  React.useEffect(function () {
        if (userJustOpeningApp === true) {
          Curry._1(setUserJustOpeningApp, (function (param) {
                  return false;
                }));
        }
        
      });
  var match$3 = RolePrompt.useRoleDialog(schemaId);
  var showRoleDialog = match$3[0];
  var currentBranch = React.useMemo((function () {
          if (typeof branch === "object") {
            return {
                    NAME: "Branch",
                    VAL: branch.VAL
                  };
          } else {
            return "Master";
          }
        }), [branch]);
  useUpdateSchemaLastUsedAt(user.id, schemaId);
  useBranchOpenedAnalytics(branchStatus, model.integrations, currentBranch, model);
  useIntercomAlignment("right");
  var currentBranchId = Actions.branchToId(currentBranch);
  var currentBranchName = getCurrentBranchName(currentBranch, masterModel);
  var branchOpenActionExists = FirebaseFetcherHooks.useBranchOpenActionExists(schemaId, undefined, "All", currentBranchId);
  var isUnknownBranch = branchOpenActionExists === "NotFound" && currentBranchId !== "master";
  var branchModel = typeof currentBranch === "object" ? ({
        TAG: /* Branch */1,
        _0: renderModel,
        _1: masterModel
      }) : ({
        TAG: /* Main */0,
        _0: renderModel
      });
  useNewUserPrompt(showRoleDialog);
  InspectorIssuesFilterHooks.SavedViewStore.useSavedViewManager(undefined);
  var match$4 = Belt_List.head(match[4]);
  var sidebarVisible;
  var exit = 0;
  if (match$4 !== undefined && typeof match$4 === "object") {
    var variant = match$4.NAME;
    if (variant === "event") {
      if (match$4.VAL[3]) {
        sidebarVisible = false;
      } else {
        exit = 1;
      }
    } else if (variant === "metric" && match$4.VAL[2]) {
      sidebarVisible = false;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
      var match$5 = schemaRoute.VAL;
      sidebarVisible = !(match$5 === "index" || match$5 === "review");
    } else {
      sidebarVisible = true;
    }
  }
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var tmp;
  if (isUnknownBranch) {
    tmp = React.createElement(NoAccess.NoBranches.make, {
          schemaId: schemaId
        });
  } else {
    var tmp$1;
    var exit$1 = 0;
    var exit$2 = 0;
    if (role === "BillingOnly") {
      tmp$1 = schemaRoute === "billing" ? React.createElement(Billing.make, {}) : React.createElement(Redirect.make, {
              path: Curry._4(Router.Link.get, {
                    NAME: "schema",
                    VAL: schema.id
                  }, {
                    NAME: "branch",
                    VAL: currentBranchId
                  }, "billing", /* [] */0)
            });
    } else if (role === "Admin") {
      if (schemaRoute === "billing") {
        tmp$1 = React.createElement(Billing.make, {});
      } else if (schemaRoute === "auditLog") {
        tmp$1 = React.createElement(AuditLog.make, {});
      } else {
        exit$2 = 2;
      }
    } else {
      exit$2 = 2;
    }
    if (exit$2 === 2) {
      var exit$3 = 0;
      if (typeof schemaRoute === "object") {
        var variant$1 = schemaRoute.NAME;
        if (variant$1 === "inspector") {
          var match$6 = schemaRoute.VAL;
          tmp$1 = typeof match$6 === "object" ? React.createElement(InspectorIssuesView.make, {
                  model: model,
                  viewerRole: role
                }) : (
              match$6 === "issues" ? React.createElement(InspectorIssuesView.make, {
                      model: model,
                      viewerRole: role
                    }) : (
                  match$6 === "events" ? React.createElement(InspectorEventsView.make, {
                          model: model
                        }) : React.createElement(InspectorIssuesSavedViews.make, {})
                )
            );
        } else if (variant$1 === "import") {
          if (schemaRoute.VAL === "success") {
            exit$3 = 3;
          } else if (role === "Admin" || role === "Editor") {
            tmp$1 = React.createElement(Import.make, {
                  currentBranch: currentBranch,
                  openBranches: masterModel.openBranches,
                  model: model,
                  viewerRole: role
                });
          } else {
            exit$1 = 1;
          }
        } else {
          tmp$1 = variant$1 === "domain" ? React.createElement(DomainDetails.make, {
                  domainId: schemaRoute.VAL
                }) : (
              typeof branch === "object" ? React.createElement(BranchView.make, {
                      masterModel: masterModel,
                      masterModelLatestAction: latestMasterAction,
                      branchModelLatestAction: latestAction,
                      branchModel: model,
                      branchId: branch.VAL,
                      role: role,
                      openBranches: model.openBranches
                    }) : React.createElement(Redirect.make, {
                      path: Curry._4(Router.Link.get, baseRoute, branch, "dashboard", /* [] */0)
                    })
            );
        }
      } else if (schemaRoute === "auditLog" || schemaRoute === "billing") {
        exit$1 = 1;
      } else if (schemaRoute === "properties") {
        tmp$1 = React.createElement(PropertiesSheet.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              model: model,
              user: user,
              viewerCanEdit: RoleUtils.canEdit(role)
            });
      } else if (schemaRoute === "settings") {
        tmp$1 = React.createElement(Connections.make, {
              model: renderModel,
              globalSend: globalSend
            });
      } else if (schemaRoute === "dashboard") {
        tmp$1 = match$1[2] ? null : (
            match$1[1] === "gettingStarted" && match$1[0].current ? React.createElement(Redirect.make, {
                    path: Router.Link.getSchemaRouteLink(undefined, "gettingStarted")
                  }) : (
                model.events === /* [] */0 && model.openBranches === /* [] */0 && userJustOpeningApp ? React.createElement(Redirect.make, {
                        path: Router.Link.getSchemaRouteLink(undefined, "events")
                      }) : React.createElement(BranchOverview.make, {
                        model: renderModel,
                        openBranches: masterModel.openBranches,
                        currentBranch: currentBranch,
                        latestMasterAction: latestMasterAction,
                        userId: user.id
                      })
              )
          );
      } else if (schemaRoute === "gettingStarted") {
        tmp$1 = hasGettingStarted ? React.createElement(GettingStarted.make, {
                key: schemaId
              }) : React.createElement(Redirect.make, {
                path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
              });
      } else if (schemaRoute === "integrations") {
        tmp$1 = React.createElement(Integrations.make, {
              integrations: renderModel.integrations,
              model: model,
              role: role
            });
      } else if (schemaRoute === "events") {
        exit$3 = 3;
      } else {
        tmp$1 = schemaRoute === "metrics" ? React.createElement(Metrics.make, {
                model: renderModel,
                role: role
              }) : (
            schemaRoute === "implement" ? React.createElement(Implement.make, {
                    model: renderModel
                  }) : React.createElement(PropertyGroupsSheet.make, {
                    currentBranch: currentBranch,
                    globalSend: globalSend,
                    model: model,
                    user: user,
                    viewerCanEdit: RoleUtils.canEdit(role)
                  })
          );
      }
      if (exit$3 === 3) {
        tmp$1 = React.createElement(EventsSheet.make, {
              currentBranch: currentBranch,
              globalSend: globalSend,
              branchName: currentBranchName,
              branchModel: branchModel,
              viewerCanEdit: RoleUtils.canEdit(role),
              user: user
            });
      }
      
    }
    if (exit$1 === 1) {
      tmp$1 = React.createElement(Redirect.make, {
            path: Curry._4(Router.Link.get, baseRoute, branch, "dashboard", /* [] */0)
          });
    }
    tmp = React.createElement(React.Fragment, undefined, branchBarVisible ? React.createElement(BranchBar.make, {
                currentBranch: currentBranch,
                branchStatus: branchStatus
              }) : null, tmp$1);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.minWidth(Css.px(1024)),
                    tl: {
                      hd: Css.height(Css.vh(100.0)),
                      tl: {
                        hd: Css.width(Css.vw(100.0)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.overflow("auto"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("title", undefined, getPageTitle(workspace.name, schemaRoute))
                }), React.createElement(AvoRoutes$IntercomVisibility, {}), React.createElement(RolePrompt.make, {
                  showDialog: showRoleDialog,
                  closeDialog: match$3[1]
                }), React.createElement(CmdKRoot.make, {
                  model: model,
                  openBranches: masterModel.openBranches,
                  role: role,
                  children: React.createElement(CmdKResults.make, {})
                }), React.createElement(Announcement.make, {}), workspace.isSandbox ? React.createElement(SandboxBanner.make, {}) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: {
                            hd: Css.justifyContent("stretch"),
                            tl: {
                              hd: Css.overflow("auto"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, sidebarVisible ? React.createElement(Sidebar.make, {
                        currentBranch: currentBranch,
                        openBranches: masterModel.openBranches,
                        role: role,
                        schema: schema,
                        model: model,
                        branchStatus: branchStatus
                      }) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("column"),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: {
                                  hd: Css.flexGrow(1.0),
                                  tl: {
                                    hd: Css.overflow("auto"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, tmp)), React.createElement(LearnHub.make, {}), React.createElement(AvoRoutes$EnrichedImportCompleteModal, {
                  latestAction: latestAction
                }), React.createElement(AvoRoutes$EnrichedSlideOverContainer, {
                  schema: schema,
                  model: model,
                  masterModel: masterModel,
                  renderModel: renderModel,
                  role: role
                }), React.createElement(ModalsContainer.make, {
                  currentBranch: currentBranch,
                  events: model.events,
                  masterModel: masterModel,
                  model: renderModel,
                  role: role
                }), React.createElement(Toast.Manager.make, {}));
}

var make = React.memo(AvoRoutes);

export {
  getCurrentBranchName ,
  useUpdateSchemaLastUsedAt ,
  useBranchOpenedAnalytics ,
  useIntercomAlignment ,
  useNewUserPrompt ,
  IntercomVisibility ,
  EnrichedSlideOverContainer ,
  EnrichedImportCompleteModal ,
  getInitialRedirect ,
  useInitialRedirect ,
  getPageTitle ,
  make ,
}
/* make Not a pure module */
