// Generated by ReScript, PLEASE EDIT WITH CARE


function schema(schemaId, schemaName, schemaBillingStatus, branchId, branchName, schemaSubscriptionPlan, schemaTrialPlan, authenticationConfig, schemaSubscriptionPlanSlug, schemaTrialPlanSlug, schemaType) {
  return {
          schemaId: schemaId,
          schemaName: schemaName,
          schemaBillingStatus: schemaBillingStatus,
          branchId: branchId,
          branchName: branchName,
          schemaSubscriptionPlan: schemaSubscriptionPlan,
          schemaTrialPlan: schemaTrialPlan,
          authenticationConfig: authenticationConfig,
          schemaSubscriptionPlanSlug: schemaSubscriptionPlanSlug,
          schemaTrialPlanSlug: schemaTrialPlanSlug,
          schemaType: schemaType
        };
}

function propertyGroup(propertyGroupId, propertyGroupName, numProperties) {
  return {
          propertyGroupId: propertyGroupId,
          propertyGroupName: propertyGroupName,
          numProperties: numProperties
        };
}

function property(propertyId, propertyName, namingConvention, propertyType, correctCase) {
  return {
          propertyId: propertyId,
          propertyName: propertyName,
          namingConvention: namingConvention,
          propertyType: propertyType,
          correctCase: correctCase
        };
}

function source(sourceId, sourceName) {
  return {
          sourceId: sourceId,
          sourceName: sourceName
        };
}

function onboarding(onboardingSources, onboardingDestinations, onboardingJobFunction, onboardingSeenJoinInstructions, onboardingTrackingContributions, onboardingCompanyRole, onboardingCompanySize) {
  return {
          onboardingSources: onboardingSources,
          onboardingDestinations: onboardingDestinations,
          onboardingJobFunction: onboardingJobFunction,
          onboardingSeenJoinInstructions: onboardingSeenJoinInstructions,
          onboardingTrackingContributions: onboardingTrackingContributions,
          onboardingCompanyRole: onboardingCompanyRole,
          onboardingCompanySize: onboardingCompanySize
        };
}

function currentFilters(currentTagFilters, currentSourceFilters, currentDestinationFilters, currentSorting, currentPropertyGroupFilters, currentPropertyFilters, currentActionFilters, currentCategoryFilters) {
  return {
          currentTagFilters: currentTagFilters,
          currentSourceFilters: currentSourceFilters,
          currentDestinationFilters: currentDestinationFilters,
          currentSorting: currentSorting,
          currentPropertyGroupFilters: currentPropertyGroupFilters,
          currentPropertyFilters: currentPropertyFilters,
          currentActionFilters: currentActionFilters,
          currentCategoryFilters: currentCategoryFilters
        };
}

function inspectorEventsViewFilters(inspectorEventNameFilter, inspectorOrder, inspectorColumnOrderedBy, inspectorSourceIdOrderedBy, inspectorTimeWindow, inspectorEnvironment, inspectorSourceNameOrderedBy) {
  return {
          inspectorEventNameFilter: inspectorEventNameFilter,
          inspectorOrder: inspectorOrder,
          inspectorColumnOrderedBy: inspectorColumnOrderedBy,
          inspectorSourceIdOrderedBy: inspectorSourceIdOrderedBy,
          inspectorTimeWindow: inspectorTimeWindow,
          inspectorEnvironment: inspectorEnvironment,
          inspectorSourceNameOrderedBy: inspectorSourceNameOrderedBy
        };
}

function inspectorTablePosition(inspectorEventNameSelected, inspectorColumnSelected, inspectorPropertyNameSelected, inspectorSourceIdSelected, inspectorSourceNameSelected, numPropertiesNotFoundOnEventInTrackingPlan) {
  return {
          inspectorEventNameSelected: inspectorEventNameSelected,
          inspectorColumnSelected: inspectorColumnSelected,
          inspectorPropertyNameSelected: inspectorPropertyNameSelected,
          inspectorSourceIdSelected: inspectorSourceIdSelected,
          inspectorSourceNameSelected: inspectorSourceNameSelected,
          numPropertiesNotFoundOnEventInTrackingPlan: numPropertiesNotFoundOnEventInTrackingPlan
        };
}

function integrationConfig(integrationType, integrationFilters, integrationName, integrationId, integrationPayloadFormat) {
  return {
          integrationType: integrationType,
          integrationFilters: integrationFilters,
          integrationName: integrationName,
          integrationId: integrationId,
          integrationPayloadFormat: integrationPayloadFormat
        };
}

function propertyConfiguration(propertyComponent, propertyConfigureAction, propertyValueTypeBefore, propertyPresenceTypeBefore, propertyListToggleAfter, propertyPresenceTypeAfter, propertyValueTypeAfter, propertyListToggleBefore, propertyValueConstraintType, propertyPresenceWhenInObjectToggleBefore, propertyPresenceWhenInObjectToggleAfter) {
  return {
          propertyComponent: propertyComponent,
          propertyConfigureAction: propertyConfigureAction,
          propertyValueTypeBefore: propertyValueTypeBefore,
          propertyPresenceTypeBefore: propertyPresenceTypeBefore,
          propertyListToggleAfter: propertyListToggleAfter,
          propertyPresenceTypeAfter: propertyPresenceTypeAfter,
          propertyValueTypeAfter: propertyValueTypeAfter,
          propertyListToggleBefore: propertyListToggleBefore,
          propertyValueConstraintType: propertyValueConstraintType,
          propertyPresenceWhenInObjectToggleBefore: propertyPresenceWhenInObjectToggleBefore,
          propertyPresenceWhenInObjectToggleAfter: propertyPresenceWhenInObjectToggleAfter
        };
}

function cmdPalette(cmdPaletteActivePath, cmdPalettePathDepth, cmdPaletteSearchTerm) {
  return {
          cmdPaletteActivePath: cmdPaletteActivePath,
          cmdPalettePathDepth: cmdPalettePathDepth,
          cmdPaletteSearchTerm: cmdPaletteSearchTerm
        };
}

function tooltipInfo(tooltipName, tooltipPurpose, tooltipDisplayMethod) {
  return {
          tooltipName: tooltipName,
          tooltipPurpose: tooltipPurpose,
          tooltipDisplayMethod: tooltipDisplayMethod
        };
}

function inspectorIssueDetails(inspectorIssueProportion, inspectorIssueVolume, inspectorEventVolume, inspectorIssueSourceCount, inspectorIssueFirstSeenHoursAgo, inspectorIssueLastSeenHoursAgo, inspectorIssueType, inspectorIssueId, inspectorIssueSharedId, inspectorIssueDetailsInteractionType, inspectorIssueStatus) {
  return {
          inspectorIssueProportion: inspectorIssueProportion,
          inspectorIssueVolume: inspectorIssueVolume,
          inspectorEventVolume: inspectorEventVolume,
          inspectorIssueSourceCount: inspectorIssueSourceCount,
          inspectorIssueFirstSeenHoursAgo: inspectorIssueFirstSeenHoursAgo,
          inspectorIssueLastSeenHoursAgo: inspectorIssueLastSeenHoursAgo,
          inspectorIssueType: inspectorIssueType,
          inspectorIssueId: inspectorIssueId,
          inspectorIssueSharedId: inspectorIssueSharedId,
          inspectorIssueDetailsInteractionType: inspectorIssueDetailsInteractionType,
          inspectorIssueStatus: inspectorIssueStatus
        };
}

function inspectorSavedViews(inspectorSavedViewName, inspectorSavedViewId, inspectorSavedViewRelationship, inspectorSavedViewCount) {
  return {
          inspectorSavedViewName: inspectorSavedViewName,
          inspectorSavedViewId: inspectorSavedViewId,
          inspectorSavedViewRelationship: inspectorSavedViewRelationship,
          inspectorSavedViewCount: inspectorSavedViewCount
        };
}

function productFlows(productFlowName, productFlowId, productFlowVersion) {
  return {
          productFlowName: productFlowName,
          productFlowId: productFlowId,
          productFlowVersion: productFlowVersion
        };
}

function inspectorBreakingChangeInfo(breakingChangeImpactedEventCount, breakingChangeImpactedEventIds, breakingChangeImpactedSourceIds, breakingChangeImpactedSourceCount) {
  return {
          breakingChangeImpactedEventCount: breakingChangeImpactedEventCount,
          breakingChangeImpactedEventIds: breakingChangeImpactedEventIds,
          breakingChangeImpactedSourceIds: breakingChangeImpactedSourceIds,
          breakingChangeImpactedSourceCount: breakingChangeImpactedSourceCount
        };
}

function domain(domainId, domainName, workspaceDomainCount) {
  return {
          domainId: domainId,
          domainName: domainName,
          workspaceDomainCount: workspaceDomainCount
        };
}

export {
  schema ,
  propertyGroup ,
  property ,
  source ,
  onboarding ,
  currentFilters ,
  inspectorEventsViewFilters ,
  inspectorTablePosition ,
  integrationConfig ,
  propertyConfiguration ,
  cmdPalette ,
  tooltipInfo ,
  inspectorIssueDetails ,
  inspectorSavedViews ,
  productFlows ,
  inspectorBreakingChangeInfo ,
  domain ,
}
/* No side effect */
