// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Semver from "semver";
import * as DateFns from "./DateFns.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns$1 from "date-fns";
import * as AvoLimits from "./AvoLimits.mjs";
import * as AvoSelect from "./AvoSelect.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Collapsible from "./Collapsible.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AppVersionsTypes from "../../shared/models/AppVersionsTypes.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";

var valueContext = React.createContext(false);

var valueProvider = valueContext.Provider;

var setContext = React.createContext(function (param) {
      
    });

var setProvider = setContext.Provider;

function use(param) {
  var isEditing = React.useContext(valueContext);
  var setIsEditing = React.useContext(setContext);
  return React.useMemo((function () {
                return {
                        isEditing: isEditing,
                        setIsEditing: setIsEditing
                      };
              }), [
              isEditing,
              setIsEditing
            ]);
}

function IssueStatusSelect$IsEditingContext(Props) {
  var initialValueOpt = Props.initialValue;
  var children = Props.children;
  var initialValue = initialValueOpt !== undefined ? initialValueOpt : false;
  var match = React.useState(function () {
        return initialValue;
      });
  return React.createElement(valueProvider, {
              value: match[0],
              children: React.createElement(setProvider, {
                    value: match[1],
                    children: children
                  })
            });
}

var context = React.createContext(undefined);

var provider = context.Provider;

function IssueStatusSelect$LatestVersionContext(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

function IssueStatusSelect$IssueStatusMessage(Props) {
  var workspace = WorkspaceContext.use(undefined);
  var blocked = AvoLimits.isInspectorVolumeBlocked(workspace);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin(Css.px(-12)),
                    tl: {
                      hd: Css.marginLeft(Css.px(-24)),
                      tl: {
                        hd: Css.marginRight(Css.px(-40)),
                        tl: {
                          hd: Css.marginTop(Css.px(-10)),
                          tl: {
                            hd: Css.maxWidth(Css.px(240)),
                            tl: {
                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.padding(Css.px(12)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(20)),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: {
                                        hd: Css.flexDirection("row"),
                                        tl: {
                                          hd: Icon.color(Styles.Color.light09),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: (function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                })
            }, React.createElement(Icon.make, {
                  type_: blocked ? "warning" : "information",
                  size: "small"
                }), React.createElement($$Text.make, {
                  size: "Small",
                  color: Styles.Color.light10,
                  children: blocked ? "Issue status changed are blocked because you've reached your event volume limit within our free plan. Upgrade your plan to continue." : "Changes you apply here will be applied to this issue across all Inspector issues views"
                }));
}

function isValid(str) {
  var match = AppVersionsTypes.classify(str.trim());
  if (typeof match === "object") {
    return match.NAME !== "invalid";
  } else {
    return true;
  }
}

function IssueStatusSelect$SpecifyRelease(Props) {
  var nodeId = Props.nodeId;
  var isActive = Props.isActive;
  var onClick = Props.onClick;
  var update = Props.update;
  var setIsEditing = React.useContext(setContext);
  var inputRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setInput = match[1];
  var input = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowError = match$1[1];
  var showError = match$1[0];
  var errorTimeoutId = React.useRef(undefined);
  var match$2 = React.useState(function () {
        return false;
      });
  var setOpened = match$2[1];
  var opened = match$2[0];
  var latestVersion = React.useContext(context);
  var handleChange = function ($$event) {
    Belt_Option.forEach(errorTimeoutId.current, (function (prim) {
            clearTimeout(prim);
          }));
    var input = $$event.target.value;
    Curry._1(setInput, (function (param) {
            return input;
          }));
    if (!isValid(input) && input.trim() !== "") {
      errorTimeoutId.current = Caml_option.some(setTimeout((function (param) {
                  Curry._1(setShowError, (function (param) {
                          return true;
                        }));
                }), 1500));
      return ;
    } else {
      return Curry._1(setShowError, (function (param) {
                    return false;
                  }));
    }
  };
  React.useEffect((function () {
          if (opened) {
            Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                    prim.focus();
                  }));
          }
          
        }), [opened]);
  React.useEffect((function () {
          var handleKeyDown = function ($$event) {
            if ($$event.key === "Escape") {
              $$event.preventDefault();
              $$event.stopPropagation();
              Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                      prim.blur();
                    }));
              return Curry._1(setOpened, (function (param) {
                            return false;
                          }));
            } else if ($$event.key === "Enter") {
              $$event.preventDefault();
              $$event.stopPropagation();
              return Curry._1(setOpened, (function (param) {
                            return true;
                          }));
            } else {
              return ;
            }
          };
          if (isActive) {
            window.addEventListener("keydown", handleKeyDown);
          }
          return (function (param) {
                    window.removeEventListener("keydown", handleKeyDown);
                  });
        }), [isActive]);
  var tmp = {
    ref: inputRef,
    className: Curry._1(Css.style, {
          hd: Css.width(Css.pct(100.0)),
          tl: {
            hd: Css.height(Css.px(32)),
            tl: {
              hd: Css.padding2(Css.px(2), Css.px(8)),
              tl: {
                hd: Css.margin2("zero", Css.px(-8)),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: {
                          hd: Css.focus({
                                hd: Css.unsafe("outline", "0"),
                                tl: {
                                  hd: Css.borderColor(Belt_Option.mapWithDefault(input, true, (function (input) {
                                              if (isValid(input)) {
                                                return true;
                                              } else {
                                                return input.trim() === "";
                                              }
                                            })) ? Styles.Color.paleBlue : Styles.Color.paleOrange),
                                  tl: /* [] */0
                                }
                              }),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    id: "releaseInput-" + nodeId + "",
    name: "releaseInput-" + nodeId + "",
    type: "text",
    value: Belt_Option.getWithDefault(input, ""),
    onFocus: (function (param) {
        Curry._1(setIsEditing, (function (param) {
                return true;
              }));
      }),
    onBlur: (function (param) {
        Curry._1(setIsEditing, (function (param) {
                return false;
              }));
      }),
    onChange: handleChange
  };
  var tmp$1 = Belt_Option.map(latestVersion, (function (v) {
          return Semver.inc(v, "patch");
        }));
  if (tmp$1 !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(tmp$1);
  }
  var disabled = Belt_Option.mapWithDefault(input, true, (function (i) {
          return i.length === 0;
        }));
  var disabled$1 = !Belt_Option.mapWithDefault(input, false, isValid);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin(Css.px(-10)),
                    tl: {
                      hd: Css.marginLeft(Css.px(-24)),
                      tl: {
                        hd: Css.marginRight(Css.px(-40)),
                        tl: {
                          hd: Css.minWidth(Css.px(240)),
                          tl: {
                            hd: Css.padding(Css.px(12)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(12)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("column"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.maxWidth(Css.pct(100.0)),
                                      tl: {
                                        hd: Css.focusWithin({
                                              hd: Css.backgroundColor(Styles.Color.light02),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              id: "releaseInputContainer-" + nodeId + "",
              onClick: (function (e) {
                  Curry._1(setOpened, (function (opened) {
                          return !opened;
                        }));
                  Curry._1(onClick, e);
                  if (e.target.closest("#releaseInputContainer-" + nodeId + "") !== null) {
                    e.preventDefault();
                    e.stopPropagation();
                    return ;
                  }
                  
                })
            }, React.createElement("label", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: /* [] */0
                      }),
                  htmlFor: "releaseInput-" + nodeId + ""
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      children: "Specify release..."
                    })), React.createElement(Collapsible.make, {
                  collapsed: !opened,
                  collapsedHeight: 0,
                  containerStyles: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: /* [] */0
                      }),
                  loadingStyle: "eager",
                  children: null
                }, React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(SimpleTooltip.make, {
                      disabled: !showError,
                      maxWidth: 200,
                      offset: 16,
                      opened: showError,
                      position: "left",
                      tooltip: "Please enter a valid release, for example: 1.2.3 or v123",
                      children: React.createElement("input", tmp)
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(16)),
                                tl: {
                                  hd: Css.marginTop(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(disabled ? Styles.Color.light07 : Styles.Color.light10),
                                tl: {
                                  hd: Css.hover({
                                        hd: disabled ? Styles.emptyStyle : Css.color(Styles.Color.light11),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          role: "button",
                          tabIndex: 0,
                          disabled: disabled,
                          onClick: (function (param) {
                              if (disabled) {
                                return ;
                              } else {
                                Curry._1(setInput, (function (param) {
                                        
                                      }));
                                Curry._1(setShowError, (function (param) {
                                        return false;
                                      }));
                                return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (prim) {
                                              prim.focus();
                                            }));
                              }
                            })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: "Clear"
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.color(disabled$1 ? Styles.Color.setAlpha(Styles.Color.darkBlue, 0.5) : Styles.Color.darkBlue),
                                tl: {
                                  hd: Css.hover({
                                        hd: disabled$1 ? Styles.emptyStyle : Css.color(Styles.Color.deepBlue),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }),
                          role: "button",
                          tabIndex: 0,
                          disabled: disabled$1,
                          onClick: (function (param) {
                              if (disabled$1 || input === undefined) {
                                return ;
                              } else {
                                return Curry._1(update, {
                                            NAME: "resolved",
                                            VAL: {
                                              NAME: "customAppVersion",
                                              VAL: input.trim()
                                            }
                                          });
                              }
                            })
                        }, React.createElement($$Text.make, {
                              size: "Small",
                              weight: "Semi",
                              children: "Done"
                            })))));
}

var FloatingSelect = AvoSelect.MakeFloatingSelect({});

function IssueStatusSelect$IssueStatusSelect(Props) {
  var issue = Props.issue;
  var onUpdate = Props.onUpdate;
  var match = InspectorIssuesStore.useAppVersionsInSyncWithIssues(undefined, undefined, undefined);
  var appVersions = match[0];
  var hasVersions = Belt_Option.isSome(AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id));
  var match$1 = use(undefined);
  var setIsEditing = match$1.setIsEditing;
  var isEditing = match$1.isEditing;
  var latestVersion = Belt_Option.map(AppVersionsTypes.getLatestVersion(undefined, appVersions, issue.source.id), AppVersionsTypes.print);
  var regression = issue.regression && issue.issueStatus.status === /* Unresolved */0;
  var workspace = WorkspaceContext.use(undefined);
  var blocked = AvoLimits.isInspectorVolumeBlocked(workspace);
  var match$2 = issue.issueStatus.status;
  var pillColor;
  pillColor = typeof match$2 === "number" ? (
      regression ? Styles.Color.lightRed : Styles.Color.lightOrange
    ) : (
      match$2.TAG === /* Ignored */0 ? Styles.Color.light04 : Styles.Color.lightGreen
    );
  var mainColor = regression ? Styles.Color.darkRed : Styles.Color.light11;
  var match$3 = issue.issueStatus.status;
  var tmp;
  if (typeof match$3 === "number") {
    tmp = regression ? "Regression" : "Unresolved";
  } else if (match$3.TAG === /* Ignored */0) {
    var validateIn = match$3._0;
    var tmp$1;
    if (typeof validateIn === "number") {
      tmp$1 = "";
    } else {
      switch (validateIn.TAG | 0) {
        case /* NextAppVersion */1 :
            tmp$1 = " after " + validateIn._0 + "";
            break;
        case /* CurrentAppVersion */0 :
        case /* CustomAppVersion */2 :
            tmp$1 = " as of " + validateIn._0 + "";
            break;
        case /* Date */3 :
            var date = validateIn._0;
            tmp$1 = " " + (
              DateFns$1.isPast(date) ? "since" : "in"
            ) + " " + DateFns.formatDistanceToNow(undefined, undefined, date) + "";
            break;
        
      }
    }
    tmp = "Ignored" + tmp$1;
  } else {
    var validateIn$1 = match$3._0;
    var tmp$2;
    if (typeof validateIn$1 === "number") {
      tmp$2 = "";
    } else {
      switch (validateIn$1.TAG | 0) {
        case /* NextAppVersion */1 :
            tmp$2 = " after " + validateIn$1._0 + "";
            break;
        case /* CurrentAppVersion */0 :
        case /* CustomAppVersion */2 :
            tmp$2 = " as of " + validateIn$1._0 + "";
            break;
        case /* Date */3 :
            var date$1 = validateIn$1._0;
            tmp$2 = " " + (
              DateFns$1.isPast(date$1) ? "since" : "in"
            ) + " " + DateFns.formatDistanceToNow(undefined, undefined, date$1) + "" + (
              DateFns$1.isPast(date$1) ? " ago" : ""
            ) + "";
            break;
        
      }
    }
    tmp = "Resolved" + tmp$2;
  }
  var tmp$3 = {
    label: "Latest release",
    value: {
      NAME: "resolved",
      VAL: "currentAppVersion"
    },
    disabled: !hasVersions || blocked
  };
  if (latestVersion !== undefined) {
    tmp$3.description = latestVersion;
  }
  return React.createElement(IssueStatusSelect$LatestVersionContext, {
              value: latestVersion,
              children: React.createElement(FloatingSelect.Menu.make, {
                    keepOpen: isEditing,
                    label: tmp,
                    color: pillColor,
                    onUpdate: onUpdate,
                    rootMenuStyles: (function (c) {
                        return Curry._1(Css.merge, {
                                    hd: Curry._1(FloatingSelect.Style.rootMenu, c),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.selector("&.root", {
                                                  hd: Css.color(mainColor),
                                                  tl: /* [] */0
                                                }),
                                            tl: {
                                              hd: Css.selector(" .root-menu-chevron-down", {
                                                    hd: Css.height(Css.px(16)),
                                                    tl: {
                                                      hd: Icon.color(mainColor),
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  });
                      }),
                    children: null
                  }, issue.issueStatus.status !== /* Unresolved */0 ? React.createElement(FloatingSelect.MenuItem.make, {
                          label: "Revert to Unresolved",
                          value: "unresolved",
                          disabled: blocked
                        }) : null, Caml_obj.equal(issue.issueStatus.status, {
                        TAG: /* Ignored */0,
                        _0: /* Never */0
                      }) ? null : React.createElement(FloatingSelect.MenuItem.make, {
                          label: "Ignored",
                          value: "ignored",
                          disabled: blocked
                        }), React.createElement(FloatingSelect.Menu.make, {
                        keepOpen: isEditing,
                        label: "Resolved in...",
                        onOpenChange: (function (open_) {
                            if (!open_) {
                              return Curry._1(setIsEditing, (function (param) {
                                            return false;
                                          }));
                            }
                            
                          }),
                        children: null
                      }, React.createElement(FloatingSelect.MenuItem.make, tmp$3), React.createElement(FloatingSelect.MenuItem.make, {
                            label: "Next release",
                            value: {
                              NAME: "resolved",
                              VAL: "nextAppVersion"
                            },
                            disabled: !hasVersions || blocked
                          }), React.createElement(FloatingSelect.MenuItem.make, {
                            label: "",
                            labelComponent: IssueStatusSelect$SpecifyRelease,
                            disabled: blocked
                          }), React.createElement(FloatingSelect.MenuItem.make, {
                            label: "1 hour",
                            value: {
                              NAME: "resolved",
                              VAL: "in1Hour"
                            },
                            disabled: blocked
                          }), React.createElement(FloatingSelect.MenuItem.make, {
                            label: "24 hours",
                            value: {
                              NAME: "resolved",
                              VAL: "in24Hours"
                            },
                            disabled: blocked
                          })), React.createElement(FloatingSelect.MenuItem.make, {
                        label: "",
                        labelComponent: IssueStatusSelect$IssueStatusMessage,
                        disabled: true
                      }))
            });
}

function IssueStatusSelect(Props) {
  var issue = Props.issue;
  var onUpdate = Props.onUpdate;
  return React.createElement(IssueStatusSelect$IsEditingContext, {
              children: React.createElement(IssueStatusSelect$IssueStatusSelect, {
                    issue: issue,
                    onUpdate: onUpdate
                  })
            });
}

var make = IssueStatusSelect;

export {
  make ,
}
/* valueContext Not a pure module */
