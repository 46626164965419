// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as FramerMotion from "framer-motion";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function isIconMaybeMulticolor(maybeIcon) {
  return Belt_Option.mapWithDefault(maybeIcon, false, Icon.isMulticolor);
}

function getStyles(style, label, icon) {
  if (typeof style !== "object") {
    if (style === "primary") {
      return {
              hd: Css.color(Styles.Color.white),
              tl: {
                hd: Css.backgroundColor(Styles.Color.avoPink),
                tl: {
                  hd: Css.disabled({
                        hd: Css.backgroundColor(Styles.Color.light07),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(":hover:not([disabled])", {
                          hd: Css.backgroundColor(Styles.Color.avoPinkShade2),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(Styles.Color.white),
                      tl: /* [] */0
                    }
                  }
                }
              }
            };
    } else if (style === "ghost") {
      return {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(label ? Styles.Color.light08 : Styles.Color.light10),
                tl: {
                  hd: Css.disabled({
                        hd: Css.color(Styles.Color.light07),
                        tl: {
                          hd: Icon.color(Styles.Color.light07),
                          tl: /* [] */0
                        }
                      }),
                  tl: {
                    hd: Css.selector(":hover:not([disabled])", {
                          hd: Css.color(Styles.Color.light12),
                          tl: {
                            hd: Css.borderColor(Styles.Color.light04),
                            tl: {
                              hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(label ? Styles.Color.light10 : Styles.Color.light12),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            };
    } else {
      return {
              hd: Css.color(Styles.Color.light11),
              tl: {
                hd: Css.borderColor(Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: {
                    hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(label ? Styles.Color.light09 : Styles.Color.light10),
                    tl: {
                      hd: Css.disabled({
                            hd: Css.borderColor(Styles.Color.light02),
                            tl: {
                              hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(Styles.Color.light07),
                              tl: {
                                hd: Css.color(Styles.Color.light07),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: {
                        hd: Css.selector(":hover:not([disabled])", {
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.borderColor(Styles.Color.light07),
                                tl: {
                                  hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(label ? Styles.Color.light11 : Styles.Color.light12),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            };
    }
  }
  var match = style.VAL;
  var optionalCustomOutlineColor = match.optionalCustomOutlineColor;
  var hoverColor = match.hoverColor;
  var customColor = match.customColor;
  return {
          hd: Css.color(customColor),
          tl: {
            hd: Css.backgroundColor(match.customBackgroundColor),
            tl: {
              hd: Belt_Option.mapWithDefault(optionalCustomOutlineColor, Styles.emptyStyle, Css.borderColor),
              tl: {
                hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(customColor),
                tl: {
                  hd: Css.disabled({
                        hd: Belt_Option.mapWithDefault(optionalCustomOutlineColor, Styles.emptyStyle, (function (outlineColor) {
                                return Css.borderColor(Styles.Color.setAlpha(outlineColor, 0.7));
                              })),
                        tl: {
                          hd: Css.color(Styles.Color.setAlpha(customColor, 0.7)),
                          tl: {
                            hd: Icon.color(Styles.Color.setAlpha(customColor, 0.7)),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.selector(":hover:not(disabled)", {
                          hd: Css.color(hoverColor),
                          tl: {
                            hd: Css.backgroundColor(match.customBackgroundHoverColor),
                            tl: {
                              hd: Belt_Option.mapWithDefault(match.optionalCustomOutlineHoverColor, Styles.emptyStyle, Css.borderColor),
                              tl: {
                                hd: Belt_Option.mapWithDefault(icon, false, Icon.isMulticolor) ? Styles.emptyStyle : Icon.color(hoverColor),
                                tl: /* [] */0
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        };
}

var loadingStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function getPadding(size, label, icon) {
  if (size === "large") {
    if (label) {
      if (icon) {
        return Css.padding4(Css.px(9), Css.px(19), Css.px(9), Css.px(15));
      } else {
        return Css.padding4(Css.px(9), Css.px(19), Css.px(9), Css.px(19));
      }
    } else if (icon) {
      return Css.padding4(Css.px(7), Css.px(9), Css.px(7), Css.px(9));
    } else {
      return Css.padding4(Css.px(18), Css.px(20), Css.px(18), Css.px(20));
    }
  } else if (size === "small") {
    if (label) {
      if (icon) {
        return Css.padding4(Css.px(7), Css.px(15), Css.px(7), Css.px(9));
      } else {
        return Css.padding4(Css.px(7), Css.px(15), Css.px(7), Css.px(15));
      }
    } else if (icon) {
      return Css.padding4(Css.px(5), Css.px(7), Css.px(5), Css.px(7));
    } else {
      return Css.padding4(Css.px(14), Css.px(16), Css.px(14), Css.px(16));
    }
  } else if (label) {
    if (icon) {
      return Css.padding4(Css.px(5), Css.px(11), Css.px(5), Css.px(5));
    } else {
      return Css.padding4(Css.px(5), Css.px(11), Css.px(5), Css.px(11));
    }
  } else if (icon) {
    return Css.padding4(Css.px(3), Css.px(5), Css.px(3), Css.px(5));
  } else {
    return Css.padding4(Css.px(11), Css.px(13), Css.px(11), Css.px(13));
  }
}

var make = React.forwardRef(function (Props, ref_) {
      var disabled = Props.disabled;
      var icon = Props.icon;
      var id = Props.id;
      var label = Props.label;
      var loadingOpt = Props.loading;
      var onClick = Props.onClick;
      var onMouseEnter = Props.onMouseEnter;
      var onMouseLeave = Props.onMouseLeave;
      var sizeOpt = Props.size;
      var title = Props.title;
      var buttonStyleOpt = Props.style;
      var minWidth = Props.minWidth;
      var singleLineOpt = Props.singleLine;
      var tabIndex = Props.tabIndex;
      var loading = loadingOpt !== undefined ? loadingOpt : false;
      var size = sizeOpt !== undefined ? sizeOpt : "small";
      var buttonStyle = buttonStyleOpt !== undefined ? buttonStyleOpt : "primary";
      var singleLine = singleLineOpt !== undefined ? singleLineOpt : false;
      var tmp = {
        className: Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: $$Text.sizeStyles(size === "large" ? "Medium" : "Small"),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: getPadding(size, Belt_Option.isSome(label), Belt_Option.isSome(icon)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.fontWeight($$Text.getFontWeight("Semi")),
                              tl: {
                                hd: Css.lineHeight(Css.px(size === "large" ? 20 : 16)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", "transparent"),
                                  tl: {
                                    hd: Css.transitionList({
                                          hd: Css_Legacy_Core.Transition.shorthand({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "all"),
                                          tl: {
                                            hd: Css_Legacy_Core.Transition.shorthand({
                                                  NAME: "ms",
                                                  VAL: 0.0
                                                }, undefined, undefined, "padding-top"),
                                            tl: {
                                              hd: Css_Legacy_Core.Transition.shorthand({
                                                    NAME: "ms",
                                                    VAL: 0.0
                                                  }, undefined, undefined, "padding-bottom"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: {
                                      hd: Css.disabled({
                                            hd: Css.cursor("default"),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.flexShrink(0.0),
                                        tl: {
                                          hd: Css.selector(" path", {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$short
                                                    }, undefined, undefined, "fill"),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Curry._1(Css.style, getStyles(buttonStyle, Belt_Option.isSome(label), icon)),
                    tl: {
                      hd: Belt_Option.mapWithDefault(minWidth, Curry._1(Css.style, /* [] */0), (function (mw) {
                              return Curry._1(Css.style, {
                                          hd: Css.minWidth(Css.px(mw)),
                                          tl: /* [] */0
                                        });
                            })),
                      tl: /* [] */0
                    }
                  }
                }
              }
            })
      };
      var tmp$1 = Belt_Option.map((ref_ == null) ? undefined : Caml_option.some(ref_), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (id !== undefined) {
        tmp.id = Caml_option.valFromOption(id);
      }
      if (tabIndex !== undefined) {
        tmp.tabIndex = Caml_option.valFromOption(tabIndex);
      }
      if (title !== undefined) {
        tmp.title = Caml_option.valFromOption(title);
      }
      if (disabled !== undefined) {
        tmp.disabled = Caml_option.valFromOption(disabled);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      if (onMouseEnter !== undefined) {
        tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
      }
      if (onMouseLeave !== undefined) {
        tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
      }
      return React.createElement("button", tmp, React.createElement(FramerMotion.AnimatePresence, {
                      exitBeforeEnter: true,
                      initial: false,
                      children: loading ? React.createElement(FramerMotion.motion.div, {
                              animate: {
                                opacity: 1,
                                scale: 1
                              },
                              transition: {
                                duration: 0.2
                              },
                              initial: {
                                opacity: 0,
                                scale: 0.95
                              },
                              exit: {
                                opacity: 0,
                                scale: 0.95
                              },
                              className: loadingStyles,
                              key: "loading",
                              children: React.createElement(LoadingCircle.make, {
                                    size: size === "large" ? 24 : (
                                        size === "small" ? 20 : 16
                                      ),
                                    color: buttonStyle === "primary" ? Styles.Color.white : Styles.Color.light09
                                  })
                            }) : null
                    }), React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$default
                                    }, undefined, undefined, "opacity"),
                                tl: {
                                  hd: Css.opacity(loading ? 0.0 : 1.0),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, icon !== undefined ? React.createElement(Icon.make, {
                            type_: icon,
                            size: size === "large" ? (
                                label !== undefined ? "medium" : "large"
                              ) : (
                                size === "small" && label === undefined ? "medium" : "small"
                              )
                          }) : null, Belt_Option.isSome(icon) && Belt_Option.isSome(label) ? React.createElement(Spacer.make, {
                            width: 8
                          }) : null, Belt_Option.mapWithDefault(label, null, (function (label) {
                            if (singleLine) {
                              return React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.textOverflow("ellipsis"),
                                                tl: {
                                                  hd: Css.whiteSpace("nowrap"),
                                                  tl: {
                                                    hd: Css.overflow("hidden"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, label);
                            } else {
                              return label;
                            }
                          }))));
    });

export {
  isIconMaybeMulticolor ,
  getStyles ,
  loadingStyles ,
  getPadding ,
  make ,
}
/* loadingStyles Not a pure module */
