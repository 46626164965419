// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactDom from "react-dom";

function Portal(Props) {
  var children = Props.children;
  return ReactDom.createPortal(children, document.body);
}

var make = Portal;

export {
  make ,
}
/* react-dom Not a pure module */
