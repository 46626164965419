// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as FloatingUI from "./externals/FloatingUI.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as FloatingUIHelpers from "./FloatingUIHelpers.mjs";
import * as React$1 from "@floating-ui/react";
import * as FloatingUIHelperComponents from "./FloatingUIHelperComponents.mjs";

function useTooltip(param) {
  var withPortal = param.withPortal;
  var withArrow = param.withArrow;
  var placement = param.placement;
  var controlledOpen = param.open_;
  var onShow = param.onShow;
  var setControlledOpen = param.onOpenChange;
  var onHide = param.onHide;
  var offset = param.offset;
  var initialOpen = param.initialOpen;
  var distance = param.distance;
  var arrowOptions = param.arrowOptions;
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(initialOpen, false);
      });
  var setUncontrolledOpen = match[1];
  var open_ = Belt_Option.getWithDefault(controlledOpen, match[0]);
  var setOpen = Belt_Option.getWithDefault(setControlledOpen, (function (opened) {
          Curry._1(setUncontrolledOpen, (function (param) {
                  return opened;
                }));
        }));
  var arrowRef = React.useRef(null);
  var data = React$1.useFloating({
        placement: Belt_Option.getWithDefault(placement, "top"),
        strategy: "absolute",
        middleware: [
          React$1.offset(function (param) {
                var placement = param.placement;
                var arrowOutstanding = FloatingUIHelperComponents.Arrow.getDistanceFromElement(placement, arrowOptions, withArrow) | 0;
                var tmp = {
                  mainAxis: distance + arrowOutstanding | 0,
                  crossAxis: offset
                };
                var tmp$1 = !Curry._1(FloatingUI.T.Placement.isRootPlacement, placement) && offset !== 0 ? offset : undefined;
                if (tmp$1 !== undefined) {
                  tmp.alignmentAxis = Caml_option.valFromOption(tmp$1);
                }
                return tmp;
              }),
          React$1.flip({
                crossAxis: Curry._1(FloatingUI.T.Placement.isRootPlacement, Belt_Option.getWithDefault(placement, "top")),
                fallbackAxisSideDirection: "start"
              }),
          React$1.shift(),
          React$1.hide(),
          React$1.arrow(function (param) {
                return {
                        element: arrowRef,
                        padding: 5
                      };
              })
        ],
        open: open_,
        onOpenChange: (function (opened) {
            if (opened) {
              Curry._1(onShow, undefined);
            } else {
              Curry._1(onHide, undefined);
            }
            Belt_Option.forEach(setControlledOpen, (function (fn) {
                    Curry._1(fn, opened);
                  }));
            Curry._1(setUncontrolledOpen, (function (param) {
                    return opened;
                  }));
          }),
        whileElementsMounted: (function (prim0, prim1, prim2, prim3) {
            return React$1.autoUpdate(prim0, prim1, prim2, prim3);
          })
      });
  var context = data.context;
  var hover = React$1.useHover(context, {
        enabled: Belt_Option.isNone(controlledOpen),
        delay: {
          open: param.delayIn,
          close: param.delayOut
        },
        move: false,
        handleClose: React$1.safePolygon({
              requireIntent: false,
              blockPointerEvents: true
            })
      });
  var focus = React$1.useFocus(context, {
        enabled: Belt_Option.isNone(controlledOpen)
      });
  var dismiss = React$1.useDismiss(context);
  var role = React$1.useRole(context, {
        role: "tooltip"
      });
  var interactions = React$1.useInteractions([
        hover,
        focus,
        dismiss,
        role
      ]);
  var arrow = React.useMemo((function () {
          return React.createElement(FloatingUIHelperComponents.Arrow.make, {
                      arrowRef: arrowRef,
                      context: context,
                      arrowOptions: arrowOptions,
                      withArrow: withArrow
                    });
        }), [
        context,
        arrowOptions,
        arrowRef,
        withArrow
      ]);
  return React.useMemo((function () {
                return {
                        arrow: arrow,
                        floatingData: data,
                        interactions: interactions,
                        open_: open_,
                        withPortal: withPortal
                      };
              }), [
              open_,
              setOpen,
              arrow,
              data,
              interactions,
              withPortal
            ]);
}

var NotWrappedInProviderException = /* @__PURE__ */Caml_exceptions.create("ItemTooltip.Context.NotWrappedInProviderException");

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  var context$1 = React.useContext(context);
  if (context$1 !== undefined) {
    return context$1;
  }
  throw {
        RE_EXN_ID: NotWrappedInProviderException,
        Error: new Error()
      };
}

function ItemTooltip$Context(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Context = {
  NotWrappedInProviderException: NotWrappedInProviderException,
  context: context,
  provider: provider,
  use: use,
  make: ItemTooltip$Context
};

var propsList = ["children"];

var make = React.forwardRef(function (Props, ref_) {
      var children = Props.children;
      var context = use(undefined);
      var childrenRef = children.ref;
      var ref = React$1.useMergeRefs([
            context.floatingData.refs.setReference,
            ref_,
            childrenRef
          ]);
      var referenceProps = Object.assign(Object.assign(Object.assign({
                    ref: ref
                  }, FloatingUIHelpers.removeProps(Props, propsList)), children.props), {
            "data-state": context.open_ ? "open" : "closed"
          });
      return React.cloneElement(children, Curry._1(context.interactions.getReferenceProps, referenceProps));
    });

var Trigger = {
  propsList: propsList,
  make: make
};

var propsList$1 = [
  "className",
  "children"
];

var make$1 = React.forwardRef(function (Props, ref_) {
      var className = Props.className;
      var children = Props.children;
      var match = use(undefined);
      var floatingData = match.floatingData;
      var ref = React$1.useMergeRefs([
            floatingData.refs.setFloating,
            ref_
          ]);
      if (!match.open_) {
        return null;
      }
      var tmp = {
        forwardRef: ref,
        style: floatingData.floatingStyles,
        floatingProps: Curry._1(match.interactions.getFloatingProps, FloatingUIHelpers.removeProps(Props, propsList$1)),
        children: null
      };
      if (className !== undefined) {
        tmp.className = className;
      }
      return React.createElement(FloatingUIHelperComponents.WithPortal.make, {
                  withPortal: match.withPortal,
                  children: React.createElement(FloatingUIHelperComponents.FloatingElement.make, tmp, match.arrow, children)
                });
    });

var Content = {
  propsList: propsList$1,
  make: make$1
};

function ItemTooltip(Props) {
  var arrowColor = Props.arrowColor;
  var arrowHeight = Props.arrowHeight;
  var arrowOffset = Props.arrowOffset;
  var arrowStrokeColor = Props.arrowStrokeColor;
  var arrowStrokeWidth = Props.arrowStrokeWidth;
  var arrowWidth = Props.arrowWidth;
  var delayInOpt = Props.delayIn;
  var delayOutOpt = Props.delayOut;
  var distanceOpt = Props.distance;
  var initialOpen = Props.initialOpen;
  var offsetOpt = Props.offset;
  var onHideOpt = Props.onHide;
  var onOpenChange = Props.onOpenChange;
  var onShowOpt = Props.onShow;
  var opened = Props.opened;
  var placement = Props.placement;
  var withArrowOpt = Props.withArrow;
  var withPortalOpt = Props.withPortal;
  var children = Props.children;
  var delayIn = delayInOpt !== undefined ? delayInOpt : 0;
  var delayOut = delayOutOpt !== undefined ? delayOutOpt : 0;
  var distance = distanceOpt !== undefined ? distanceOpt : 0;
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  var onHide = onHideOpt !== undefined ? onHideOpt : (function (param) {
        
      });
  var onShow = onShowOpt !== undefined ? onShowOpt : (function (param) {
        
      });
  var withArrow = withArrowOpt !== undefined ? withArrowOpt : false;
  var withPortal = withPortalOpt !== undefined ? withPortalOpt : true;
  var tmp = {};
  if (arrowWidth !== undefined) {
    tmp.width = Caml_option.valFromOption(arrowWidth);
  }
  if (arrowHeight !== undefined) {
    tmp.height = Caml_option.valFromOption(arrowHeight);
  }
  if (arrowOffset !== undefined) {
    tmp.staticOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (arrowColor !== undefined) {
    tmp.fill = Caml_option.valFromOption(arrowColor);
  }
  if (arrowStrokeColor !== undefined) {
    tmp.stroke = Caml_option.valFromOption(arrowStrokeColor);
  }
  var tmp$1 = Belt_Option.map(arrowStrokeWidth, (function (prim) {
          return prim;
        }));
  if (tmp$1 !== undefined) {
    tmp.strokeWidth = Caml_option.valFromOption(tmp$1);
  }
  var tooltip = useTooltip({
        arrowOptions: tmp,
        delayIn: delayIn,
        delayOut: delayOut,
        distance: distance,
        initialOpen: initialOpen,
        offset: offset,
        onHide: onHide,
        onOpenChange: onOpenChange,
        onShow: onShow,
        open_: opened,
        placement: placement,
        withArrow: withArrow,
        withPortal: withPortal
      });
  return React.createElement(ItemTooltip$Context, {
              value: tooltip,
              children: children
            });
}

var make$2 = ItemTooltip;

export {
  useTooltip ,
  Context ,
  Trigger ,
  Content ,
  make$2 as make,
}
/* context Not a pure module */
