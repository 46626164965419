// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";

var pill = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(3), Css.px(10), Css.px(3), Css.px(8)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(2)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.5)),
              tl: {
                hd: Css.borderRadius(Css.px(10)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function GlobalPill(Props) {
  var lockedOpt = Props.locked;
  var locked = lockedOpt !== undefined ? lockedOpt : false;
  return React.createElement("span", {
              className: pill
            }, locked ? React.createElement(Icon.make, {
                    type_: "lock",
                    size: {
                      NAME: "int",
                      VAL: 12
                    },
                    color: Styles.Color.deepBlue
                  }) : null, React.createElement($$Text.make, {
                  size: "Tiny",
                  color: Styles.Color.deepBlue,
                  children: "Global"
                }));
}

var make = GlobalPill;

export {
  make ,
}
/* pill Not a pure module */
