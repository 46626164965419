// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ChangesModel from "../../shared/ChangesModel.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var context = React.createContext(undefined);

var provider = context.Provider;

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function useSafe(param) {
  var changeState = React.useContext(context);
  if (changeState !== undefined) {
    return changeState;
  } else {
    return ChangesModel.emptyState;
  }
}

function useIsEventModified(eventId) {
  var changeState = useSafe(undefined);
  return Belt_MapString.get(changeState.changes.changed, eventId) !== undefined;
}

function BranchChangesContext(Props) {
  var changes = Props.changes;
  var children = Props.children;
  return React.createElement(provider, {
              value: changes,
              children: children
            });
}

var make = BranchChangesContext;

export {
  context ,
  provider ,
  use ,
  useSafe ,
  useIsEventModified ,
  make ,
}
/* context Not a pure module */
