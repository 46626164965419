// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Core from "@mantine/core";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as Hooks$1 from "@mantine/hooks";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function InspectorIssuesView__WhatAreIssues(Props) {
  var match = AvoEngagement.Engagement.use("inspector-issues-onboarding-flow-v2");
  var intent = match[1];
  var match$1 = Hooks.useDisclosure(false);
  var actions = match$1[1];
  var opened = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setJustClosed = match$2[1];
  var justClosed = match$2[0];
  React.useEffect((function () {
          if (!justClosed) {
            return ;
          }
          var timeoutId = setTimeout((function (param) {
                  Curry._1(setJustClosed, (function (param) {
                          return false;
                        }));
                }), 100);
          return (function (param) {
                    clearTimeout(timeoutId);
                  });
        }), [justClosed]);
  var ref = Hooks$1.useClickOutside(function (param) {
        Curry._1(actions.close, undefined);
        Curry._1(setJustClosed, (function (param) {
                return true;
              }));
      });
  return React.createElement(Mantine.Popover.make, {
              arrowSize: 12,
              arrowOffset: 82,
              arrowStyles: {
                hd: Css.backgroundColor(Styles.Color.light11),
                tl: {
                  hd: Css.boxSizing("unset"),
                  tl: {
                    hd: Css.borderColor("transparent"),
                    tl: {
                      hd: Css.zIndex(-1),
                      tl: /* [] */0
                    }
                  }
                }
              },
              closeOnClickOutside: true,
              dropdownStyles: {
                hd: Css.borderRadius(Css.px(6)),
                tl: {
                  hd: Css.backgroundColor("transparent"),
                  tl: {
                    hd: Css.boxShadow(Styles.Shadow.box),
                    tl: /* [] */0
                  }
                }
              },
              opened: opened,
              position: "bottom-end",
              withArrow: true,
              zIndex: Styles.ZIndex.aboveAll + 1 | 0,
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement(Button.make, {
                        label: "What are Inspector issues?",
                        onClick: (function (param) {
                            if (opened || !justClosed) {
                              return Curry._1(actions.toggle, undefined);
                            }
                            
                          }),
                        style: "ghost"
                      })
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        ref: ref,
                        className: Curry._1(Css.style, {
                              hd: Css.backgroundColor(Styles.Color.light11),
                              tl: {
                                hd: Css.padding(Css.px(32)),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.maxWidth(Css.px(320)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(Title.make, {
                            children: "Inspector Issues",
                            color: Styles.Color.white,
                            size: "Tiny"
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            element: "Div",
                            size: "Medium",
                            color: Styles.Color.white,
                            children: React.createElement(Markdown.make, {
                                  source: "\nInspector issues are anomalies and discrepancies found on events and properties in your production data.\n\nIn this view you can:\n\n- Filter your issues to those most relevant to you\n- Save your filtered view for fast access\n- Set up custom alerts based on your filtering, to never miss a critical data bug again"
                                })
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: {
                                        hd: Css.justifyContent("flexEnd"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement("a", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("row"),
                                        tl: {
                                          hd: Css.color(Styles.Color.light07),
                                          tl: {
                                            hd: Css.textDecoration("none"),
                                            tl: {
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: {
                                                  hd: Button.getPadding("small", true, true),
                                                  tl: {
                                                    hd: Icon.styles({
                                                          hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                                                          tl: {
                                                            hd: Css.transition({
                                                                  NAME: "ms",
                                                                  VAL: 300.0
                                                                }, undefined, undefined, "all"),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.transition({
                                                            NAME: "ms",
                                                            VAL: 300.0
                                                          }, undefined, undefined, "all"),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.white),
                                                              tl: {
                                                                hd: Icon.color(Styles.Color.white),
                                                                tl: {
                                                                  hd: Css.borderColor(Styles.Color.white),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }),
                                href: "https://www.avo.app/docs/inspector/inspector-issues-view"
                              }, React.createElement(Icon.make, {
                                    type_: "book"
                                  }), React.createElement(Spacer.make, {
                                    width: 8
                                  }), React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Small",
                                    weight: "Semi",
                                    children: "Read Docs"
                                  })), React.createElement(Button.make, {
                                icon: "presentation",
                                label: "Take tour",
                                onClick: (function (param) {
                                    Curry._1(actions.toggle, undefined);
                                    Curry._1(intent.start, undefined);
                                  })
                              })))
                }));
}

var make = InspectorIssuesView__WhatAreIssues;

export {
  make ,
}
/* Css Not a pure module */
