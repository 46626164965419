// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as IconWeb from "./IconWeb.mjs";
import * as IconExpo from "./IconExpo.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as IconMobile from "./IconMobile.mjs";
import * as IconServer from "./IconServer.mjs";

function SourceIcon(Props) {
  var source = Props.source;
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  var match = source.platform;
  var match$1 = source.language;
  var match$2 = source.platform;
  var tmp;
  var exit = 0;
  if (match$2 !== undefined) {
    if (match$2 === "Unity" || match$2 === "Flutter" || match$2 === "KotlinMultiplatform" || match$2 === "IOS" || match$2 === "Android" || match$2 === "ReactNative") {
      tmp = React.createElement(IconMobile.make, {
            size: size,
            color: color
          });
    } else if (match$2 === "Ruby" || match$2 === "Node" || match$2 === "Java" || match$2 === "Php" || match$2 === "Go" || match$2 === "Python" || match$2 === "DotNet") {
      tmp = React.createElement(IconServer.make, {
            size: size,
            color: color
          });
    } else if (match$2 === "MacOS" || match$2 === "Web" || match$2 === "Gtm" || match$2 === "JsonSchema") {
      tmp = React.createElement(IconWeb.make, {
            size: size,
            color: color
          });
    } else if (match$2 === "Expo") {
      tmp = React.createElement(IconExpo.make, {
            size: size,
            color: color
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.width(Css.px(16)),
                tl: {
                  hd: Css.height(Css.px(16)),
                  tl: /* [] */0
                }
              })
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.marginTop(Css.px(2)),
                        tl: /* [] */0
                      }
                    }
                  }),
              title: match !== undefined ? (
                  match$1 !== undefined ? "" + AvoConfig.devPlatformLabel(match) + " - " + AvoConfig.languageLabel(match$1) + "" : AvoConfig.devPlatformLabel(match)
                ) : (
                  match$1 !== undefined ? AvoConfig.languageLabel(match$1) : ""
                )
            }, tmp);
}

var make = SourceIcon;

export {
  make ,
}
/* Css Not a pure module */
