// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

var supportedOperations = [
  "Set",
  "SetOnce"
];

function operationLabel(operation) {
  if (operation === "Increment") {
    return "increment";
  } else if (operation === "SetOnce") {
    return "set once";
  } else if (operation === "Set") {
    return "set";
  } else if (operation === "Prepend") {
    return "prepend";
  } else if (operation === "SnowplowContext") {
    return "Snowplow Context";
  } else {
    return "append";
  }
}

var devPlatforms = [
  "Web",
  "IOS",
  "MacOS",
  "Android",
  "ReactNative",
  "Expo",
  "KotlinMultiplatform",
  "Node",
  "Python",
  "Php",
  "Unity",
  "Ruby",
  "DotNet",
  "Java",
  "Flutter",
  "Go",
  "Other"
];

function devPlatformLabel(platform) {
  if (platform === "ReactNative") {
    return "React Native";
  } else if (platform === "DotNet") {
    return ".NET";
  } else if (platform === "Android") {
    return "Android";
  } else if (platform === "Python") {
    return "Python";
  } else if (platform === "JsonSchema") {
    return "JSON Schema";
  } else if (platform === "Go") {
    return "Go";
  } else if (platform === "Gtm") {
    return "Google Tag Manager";
  } else if (platform === "IOS") {
    return "iOS";
  } else if (platform === "Php") {
    return "PHP";
  } else if (platform === "Web") {
    return "Web";
  } else if (platform === "KotlinMultiplatform") {
    return "Kotlin Multiplatform";
  } else if (platform === "MacOS") {
    return "macOS";
  } else if (platform === "Expo") {
    return "Expo";
  } else if (platform === "Java") {
    return "Java";
  } else if (platform === "Node") {
    return "Node.js";
  } else if (platform === "Ruby") {
    return "Ruby";
  } else if (platform === "Flutter") {
    return "Flutter";
  } else if (platform === "Unity") {
    return "Unity";
  } else {
    return "Other";
  }
}

var inspectorSupportedDevPlatforms = [
  "IOS",
  "Android",
  "Web",
  "ReactNative",
  "Expo",
  "Node",
  "Java",
  "Go",
  "Flutter"
];

function inspectorSupportedLanguages(platform) {
  if (platform !== undefined) {
    if (platform === "Expo" || platform === "Web" || platform === "ReactNative") {
      return [
              "js_v2",
              "ts",
              "re_v2"
            ];
    } else if (platform === "Android") {
      return [
              "java",
              "kt"
            ];
    } else if (platform === "Go") {
      return ["go"];
    } else if (platform === "IOS") {
      return [
              "swift",
              "objc"
            ];
    } else if (platform === "KotlinMultiplatform") {
      return ["kt"];
    } else if (platform === "Java") {
      return ["java"];
    } else if (platform === "Node") {
      return [
              "js_v2",
              "ts"
            ];
    } else if (platform === "Flutter") {
      return ["dart"];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

var analyticsTools = [
  "Amplitude",
  "AppsFlyer",
  "FacebookAnalytics",
  "FirebaseAnalytics",
  "FullStory",
  "Intercom",
  "Mixpanel",
  "Segment",
  "Rudderstack",
  "GoogleAnalytics4",
  "Freshpaint",
  "AdobeAnalytics",
  "PostHog",
  "Heap",
  "Pendo",
  "MParticle",
  "Snowplow",
  "Permutive",
  "Braze",
  "ZendeskConnect",
  "Custom"
];

var groupedAnalyticsTools = [
  [
    "Analytics Tools",
    [
      "GoogleAnalytics4",
      "Mixpanel",
      "Amplitude",
      "FirebaseAnalytics",
      "AdobeAnalytics",
      "FullStory",
      "PostHog",
      "Pendo",
      "Heap"
    ]
  ],
  [
    "Customer Data Platforms",
    [
      "Segment",
      "Freshpaint",
      "Rudderstack",
      "Snowplow",
      "MParticle"
    ]
  ],
  [
    "Other",
    [
      "AppsFlyer",
      "Intercom",
      "Braze",
      "ZendeskConnect",
      "Permutive",
      "Custom"
    ]
  ]
];

function analyticsToolsLabel(tool) {
  if (tool === "Braze") {
    return "Braze";
  } else if (tool === "Pendo") {
    return "Pendo";
  } else if (tool === "AppsFlyer") {
    return "AppsFlyer";
  } else if (tool === "PostHog") {
    return "PostHog";
  } else if (tool === "Rudderstack") {
    return "RudderStack";
  } else if (tool === "AdobeAnalytics") {
    return "Adobe Analytics";
  } else if (tool === "Segment") {
    return "Segment";
  } else if (tool === "Custom") {
    return "Other";
  } else if (tool === "ZendeskConnect") {
    return "Zendesk Connect";
  } else if (tool === "Mixpanel") {
    return "Mixpanel";
  } else if (tool === "Intercom") {
    return "Intercom";
  } else if (tool === "GoogleAnalytics4") {
    return "Google Analytics 4";
  } else if (tool === "Amplitude") {
    return "Amplitude";
  } else if (tool === "FirebaseAnalytics") {
    return "Firebase Analytics";
  } else if (tool === "Freshpaint") {
    return "Freshpaint";
  } else if (tool === "Snowplow") {
    return "Snowplow";
  } else if (tool === "FullStory") {
    return "FullStory";
  } else if (tool === "Heap") {
    return "Heap";
  } else if (tool === "FacebookAnalytics") {
    return "Facebook Analytics";
  } else if (tool === "MParticle") {
    return "mParticle";
  } else {
    return "Permutive";
  }
}

function supportsObjects(destination, propertyName) {
  if (destination === "FullStory" || destination === "Snowplow" || destination === "GoogleAnalytics4" || destination === "Mixpanel" || destination === "Custom" || destination === "Segment" || destination === "Rudderstack") {
    return true;
  }
  if (destination !== "Intercom") {
    return false;
  }
  switch (propertyName) {
    case "article" :
    case "companies" :
    case "pages" :
    case "price" :
        return true;
    default:
      return false;
  }
}

function codegenSupportsPlainObjects(param) {
  return Belt_Array.concat([
              [
                "kt",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "java",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "swift",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "js_v2",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "ts",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "php",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "py",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "py3",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "rb",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "re_v2",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "json",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]
            ], [[
                "cs",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]]);
}

function codegenSupportsNameMappingAndPinningInObjects(param) {
  return Belt_Array.concat([
              [
                "kt",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "swift",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "js_v2",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "ts",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "php",
                [/* EventProperty */1]
              ]
            ], [[
                "cs",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]]);
}

function codegenSupportsNameMappingAndPinningInListsOfObjects(param) {
  return Belt_Array.concat([
              [
                "kt",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "swift",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "js_v2",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "ts",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]
            ], [[
                "cs",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]]);
}

function codegenSupportsNameMappingAndPinningInNestedObjects(param) {
  return Belt_Array.concat([
              [
                "kt",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "swift",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ],
              [
                "js_v2",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]
            ], [[
                "cs",
                [
                  /* EventProperty */1,
                  /* UserProperty */2,
                  /* SystemProperty */0
                ]
              ]]);
}

var languagePlatformDestinationsThatSupportGroupAnalytics = [
  [
    "re_v2",
    "Web",
    "Custom"
  ],
  [
    "re_v2",
    "Node",
    "Custom"
  ],
  [
    "ts",
    "Web",
    "Custom"
  ],
  [
    "ts",
    "ReactNative",
    "Custom"
  ],
  [
    "ts",
    "Expo",
    "Custom"
  ],
  [
    "ts",
    "Node",
    "Custom"
  ],
  [
    "js_v2",
    "Node",
    "Custom"
  ],
  [
    "js_v2",
    "Web",
    "Custom"
  ],
  [
    "cs",
    "DotNet",
    "Custom"
  ],
  [
    "py3",
    "Python",
    "Custom"
  ],
  [
    "py",
    "Python",
    "Custom"
  ],
  [
    "rb",
    "Ruby",
    "Custom"
  ],
  [
    "re_v2",
    "Node",
    "Mixpanel"
  ],
  [
    "re_v2",
    "Web",
    "Mixpanel"
  ],
  [
    "ts",
    "Web",
    "Mixpanel"
  ],
  [
    "js_v2",
    "Web",
    "Mixpanel"
  ],
  [
    "py3",
    "Python",
    "Mixpanel"
  ],
  [
    "py",
    "Python",
    "Mixpanel"
  ],
  [
    "java",
    "Android",
    "Mixpanel"
  ],
  [
    "kt",
    "Android",
    "Mixpanel"
  ],
  [
    "swift",
    "IOS",
    "Mixpanel"
  ]
];

function doesLanguagePlatformDestinationSupportGroups(language, platform, destinationType, destinationInterface) {
  return Belt_Array.someU(languagePlatformDestinationsThatSupportGroupAnalytics, (function (param) {
                var dest = param[2];
                if (param[0] === language && param[1] === platform) {
                  if (dest === destinationType && !destinationInterface) {
                    return true;
                  } else if (dest === "Custom") {
                    return destinationInterface;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }));
}

function areGroupPropertiesSupported(source, destinations) {
  return Belt_List.someU(destinations, (function (destination) {
                return doesLanguagePlatformDestinationSupportGroups(source.language, source.platform, destination.type_, Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                                      return destinationRef.destinationId === destination.id;
                                    })), false, (function (destinationRef) {
                                  return destinationRef.destinationMode === /* DestinationInterface */1;
                                })));
              }));
}

function hasAtLeastOneDestinationThatDoesNotSupportGroupProperties(source, destinations) {
  return Belt_List.someU(destinations, (function (destination) {
                return !doesLanguagePlatformDestinationSupportGroups(source.language, source.platform, destination.type_, Belt_Option.mapWithDefault(Belt_List.getBy(source.destinations, (function (destinationRef) {
                                      return destinationRef.destinationId === destination.id;
                                    })), false, (function (destinationRef) {
                                  return destinationRef.destinationMode === /* DestinationInterface */1;
                                })));
              }));
}

function getUnsupportedSetOnceDestinationLabels(destination) {
  if (destination === "Mixpanel") {
    return "Mixpanel (except JavaScript and Reason sources)";
  } else if (destination === "Amplitude") {
    return ;
  } else {
    return analyticsToolsLabel(destination);
  }
}

function getSupportedLanguages(platform) {
  if (platform !== undefined) {
    if (platform === "Node" || platform === "Web" || platform === "ReactNative") {
      return [
              "js_v2",
              "re_v2",
              "ts",
              "js",
              "re"
            ];
    } else if (platform === "Unity" || platform === "DotNet") {
      return ["cs"];
    } else if (platform === "Android") {
      return [
              "java",
              "kt"
            ];
    } else if (platform === "Python") {
      return [
              "py",
              "py3"
            ];
    } else if (platform === "JsonSchema") {
      return ["json"];
    } else if (platform === "Go") {
      return ["go"];
    } else if (platform === "Gtm") {
      return ["js_v2"];
    } else if (platform === "IOS") {
      return [
              "swift",
              "objc"
            ];
    } else if (platform === "Php") {
      return ["php"];
    } else if (platform === "KotlinMultiplatform") {
      return ["kt"];
    } else if (platform === "MacOS") {
      return ["swift"];
    } else if (platform === "Expo") {
      return [
              "js_v2",
              "re_v2",
              "ts"
            ];
    } else if (platform === "Java") {
      return ["java"];
    } else if (platform === "Ruby") {
      return ["rb"];
    } else if (platform === "Flutter") {
      return ["dart"];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function supportsInvocations(source) {
  var match = source.platform;
  var match$1 = source.language;
  if (match !== undefined) {
    if (match === "Node" || match === "Expo" || match === "Web" || match === "ReactNative") {
      if (match$1 !== undefined) {
        if (match$1 === "ts" || match$1 === "re_v2") {
          return true;
        } else {
          return match$1 === "js_v2";
        }
      } else {
        return false;
      }
    } else if (match === "Unity" || match === "DotNet") {
      if (match$1 !== undefined) {
        return match$1 === "cs";
      } else {
        return false;
      }
    } else if (match === "Android") {
      if (match$1 !== undefined) {
        if (match$1 === "kt") {
          return true;
        } else {
          return match$1 === "java";
        }
      } else {
        return false;
      }
    } else if (match === "Python") {
      if (match$1 !== undefined) {
        if (match$1 === "py3") {
          return true;
        } else {
          return match$1 === "py";
        }
      } else {
        return false;
      }
    } else if (match === "IOS") {
      if (match$1 !== undefined) {
        if (match$1 === "swift") {
          return true;
        } else {
          return match$1 === "objc";
        }
      } else {
        return false;
      }
    } else if (match === "Php") {
      if (match$1 !== undefined) {
        return match$1 === "php";
      } else {
        return false;
      }
    } else if (match === "KotlinMultiplatform") {
      return false;
    } else if (match === "Java") {
      if (match$1 !== undefined) {
        return match$1 === "java";
      } else {
        return false;
      }
    } else if (match === "Ruby" && match$1 !== undefined) {
      return match$1 === "rb";
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function filenameHint(x) {
  if (x === "json") {
    return "AvoSchema";
  } else if (x === "py3" || x === "rb" || x === "py") {
    return "avo";
  } else {
    return "Avo";
  }
}

function languageExtensionHint(x) {
  if (x === "java") {
    return "java";
  } else if (x === "json") {
    return "json";
  } else if (x === "objc") {
    return "m";
  } else if (x === "js" || x === "js_v2") {
    return "js";
  } else if (x === "re" || x === "re_v2") {
    return "re";
  } else if (x === "cs") {
    return "cs";
  } else if (x === "go") {
    return "go";
  } else if (x === "kt") {
    return "kt";
  } else if (x === "py3" || x === "py") {
    return "py";
  } else if (x === "rb") {
    return "rb";
  } else if (x === "ts") {
    return "ts";
  } else if (x === "php") {
    return "php";
  } else if (x === "swift") {
    return "swift";
  } else {
    return "dart";
  }
}

function languageLabel(language) {
  if (language === "java") {
    return "Java";
  } else if (language === "json") {
    return "JSON Schema";
  } else if (language === "objc") {
    return "Objective-C";
  } else if (language === "js_v2") {
    return "JavaScript";
  } else if (language === "re_v2") {
    return "Reason";
  } else if (language === "cs") {
    return "C#";
  } else if (language === "go") {
    return "Go";
  } else if (language === "js") {
    return "JavaScript (deprecated)";
  } else if (language === "kt") {
    return "Kotlin";
  } else if (language === "py") {
    return "Python2";
  } else if (language === "rb") {
    return "Ruby";
  } else if (language === "re") {
    return "Reason (deprecated)";
  } else if (language === "ts") {
    return "TypeScript";
  } else if (language === "php") {
    return "PHP";
  } else if (language === "py3") {
    return "Python3";
  } else if (language === "swift") {
    return "Swift";
  } else {
    return "Dart";
  }
}

function languageFromLabel(language) {
  switch (language) {
    case "C#" :
        return "cs";
    case "Dart" :
        return "dart";
    case "Go" :
        return "go";
    case "JSON Schema" :
        return "json";
    case "Java" :
        return "java";
    case "JavaScript" :
        return "js_v2";
    case "JavaScript (deprecated)" :
        return "js";
    case "Kotlin" :
        return "kt";
    case "Objective-C" :
        return "objc";
    case "PHP" :
        return "php";
    case "Python2" :
        return "py";
    case "Python3" :
        return "py3";
    case "Reason" :
        return "re_v2";
    case "Reason (deprecated)" :
        return "re";
    case "Ruby" :
        return "rb";
    case "Swift" :
        return "swift";
    case "TypeScript" :
        return "ts";
    default:
      return ;
  }
}

var languagesThatSupportPropertyBundleObjects = [
  "swift",
  "objc",
  "kt",
  "re_v2"
];

function doesLanguageSupportPropertyBundleObjects(language) {
  return Belt_Array.someU(languagesThatSupportPropertyBundleObjects, (function (supportingLanguage) {
                if (language !== undefined) {
                  return language === supportingLanguage;
                } else {
                  return false;
                }
              }));
}

function languagePlatformDestinationLabel(param) {
  var destinationsString = Belt_Array.mapU(param[1], (function (destination) {
            var destinationName = destination.name;
            if (destinationName !== undefined) {
              return destinationName;
            } else {
              return "Unnamed destination";
            }
          })).join(", ");
  return "\n- " + param[0] + " sending to " + destinationsString + "";
}

function getSupportedAvoManagedDestinations(platform, lang) {
  if (platform === undefined) {
    return [];
  }
  if (platform === "ReactNative") {
    if (lang !== undefined) {
      if (lang === "ts" || lang === "js_v2") {
        return [
                "Amplitude",
                "Segment",
                "FirebaseAnalytics",
                "AppsFlyer",
                "Snowplow"
              ];
      } else if (lang === "re_v2") {
        return [
                "Amplitude",
                "Segment",
                "FirebaseAnalytics",
                "AppsFlyer"
              ];
      } else if (lang === "re" || lang === "js") {
        return ["Segment"];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (platform === "DotNet") {
    if (lang !== undefined) {
      if (lang === "cs") {
        return ["Mixpanel"];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (platform === "Android") {
    if (lang !== undefined) {
      if (lang === "java") {
        return [
                "Amplitude",
                "Segment",
                "Mixpanel"
              ];
      } else if (lang === "kt") {
        return [
                "Amplitude",
                "Segment",
                "FirebaseAnalytics",
                "Braze",
                "Snowplow",
                "Mixpanel"
              ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (platform === "Python") {
    if (lang !== undefined) {
      if (lang === "py3" || lang === "py") {
        return ["Mixpanel"];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  if (platform === "JsonSchema") {
    return [];
  }
  if (platform !== "Gtm") {
    if (platform === "IOS") {
      if (lang !== undefined) {
        if (lang === "objc") {
          return [
                  "Amplitude",
                  "FirebaseAnalytics",
                  "Segment",
                  "Mixpanel"
                ];
        } else if (lang === "swift") {
          return [
                  "Amplitude",
                  "Snowplow",
                  "Mixpanel",
                  "Segment",
                  "MParticle",
                  "FirebaseAnalytics",
                  "Braze",
                  "MParticle"
                ];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (platform === "Php") {
      if (lang !== undefined) {
        if (lang === "php") {
          return [
                  "MParticle",
                  "Snowplow",
                  "Custom"
                ];
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (platform !== "Web") {
      if (platform === "MacOS") {
        return [
                "Amplitude",
                "Custom"
              ];
      } else if (platform === "Expo") {
        return [
                "Amplitude",
                "Segment"
              ];
      } else if (platform === "Java") {
        if (lang !== undefined) {
          if (lang === "java") {
            return [
                    "Segment",
                    "Custom"
                  ];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else if (platform === "Node") {
        if (lang !== undefined) {
          if (lang === "js_v2") {
            return [
                    "Amplitude",
                    "Mixpanel",
                    "Segment",
                    "Intercom",
                    "Snowplow"
                  ];
          } else if (lang === "re_v2") {
            return [
                    "Amplitude",
                    "Segment",
                    "Mixpanel"
                  ];
          } else if (lang === "re" || lang === "js") {
            return [
                    "Amplitude",
                    "Segment"
                  ];
          } else if (lang === "ts") {
            return [
                    "Amplitude",
                    "Segment",
                    "Custom",
                    "Snowplow"
                  ];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else if (platform === "Ruby") {
        if (lang !== undefined) {
          if (lang === "rb") {
            return [
                    "Segment",
                    "Amplitude",
                    "Custom"
                  ];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else if (platform === "Unity") {
        if (lang !== undefined) {
          if (lang === "cs") {
            return ["Mixpanel"];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (lang === undefined) {
      return [];
    }
    if (lang !== "js_v2") {
      if (lang === "re_v2") {
        return [
                "Amplitude",
                "Mixpanel",
                "Segment",
                "FullStory",
                "Intercom"
              ];
      } else if (lang === "js") {
        return [
                "Amplitude",
                "Segment",
                "Mixpanel",
                "FacebookAnalytics"
              ];
      } else if (lang === "re") {
        return [
                "Amplitude",
                "Segment"
              ];
      } else if (lang === "ts") {
        return [
                "Amplitude",
                "Mixpanel",
                "Segment",
                "FacebookAnalytics",
                "MParticle",
                "Snowplow",
                "FullStory",
                "Intercom",
                "ZendeskConnect"
              ];
      } else {
        return [];
      }
    }
    
  }
  return [
          "Amplitude",
          "Mixpanel",
          "Segment",
          "FacebookAnalytics",
          "MParticle",
          "FullStory",
          "Intercom",
          "Permutive",
          "Snowplow"
        ];
}

function getSupportedDestinations(platform, lang) {
  var hasSnowplowSupport = Belt_Array.some(getSupportedAvoManagedDestinations(platform, lang), (function (destination) {
          return destination === "Snowplow";
        }));
  if (hasSnowplowSupport) {
    return analyticsTools;
  } else {
    return Belt_Array.keep(analyticsTools, (function (tool) {
                  return tool !== "Snowplow";
                }));
  }
}

function setDestinationModeForCodegen(source, destinations) {
  return Belt_List.keepMapU(destinations, (function (destination) {
                var destinationMode = Belt_Option.mapU(ModelUtils.getSourceDestinationRef(source, destination.id), (function (destinationRef) {
                        return destinationRef.destinationMode;
                      }));
                var isSnowplowDestination = destination.type_ === "Snowplow";
                if (destinationMode === 1) {
                  if (isSnowplowDestination) {
                    return destination;
                  } else {
                    return {
                            id: destination.id,
                            name: destination.name,
                            type_: "Custom",
                            apiKeys: destination.apiKeys,
                            includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                            disabledByDefault: destination.disabledByDefault
                          };
                  }
                }
                if (destination.type_ === "Custom" || Belt_Array.someU(getSupportedAvoManagedDestinations(source.platform, source.language), (function (analyticsTool) {
                          return analyticsTool === destination.type_;
                        }))) {
                  return destination;
                }
                
              }));
}

function convertDestinationInterfacesToCustomInModel(model, source) {
  return {
          types: model.types,
          properties: model.properties,
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: Belt_List.map(model.destinations, (function (destination) {
                  var destinationMode = Belt_Option.map(Belt_List.getBy(source.destinations, (function (destinationRef) {
                              return destinationRef.destinationId === destination.id;
                            })), (function (destinationRef) {
                          return destinationRef.destinationMode;
                        }));
                  if (destinationMode !== undefined && !(destinationMode !== 1 || Caml_obj.equal(destination.type_, "Snowplow"))) {
                    return {
                            id: destination.id,
                            name: destination.name,
                            type_: "Custom",
                            apiKeys: destination.apiKeys,
                            includeUserPropsWithEventProps: destination.includeUserPropsWithEventProps,
                            disabledByDefault: destination.disabledByDefault
                          };
                  } else {
                    return destination;
                  }
                })),
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

function hasAnalyticsTool(destinations, tool) {
  return Belt_List.some(destinations, (function (dest) {
                return dest.type_ === tool;
              }));
}

function hasDevPlatform(sources, platform) {
  return Belt_List.some(sources, (function (source) {
                return source.platform === platform;
              }));
}

function nonEmptyStringOrError(maybeString, error) {
  return Belt_Option.mapWithDefaultU(maybeString, [error], (function (value) {
                if (value.length !== 0) {
                  return [];
                } else {
                  return [error];
                }
              }));
}

function nonOptionalOrError(maybeValue, error) {
  return Belt_Option.mapWithDefault(maybeValue, [error], (function (param) {
                return [];
              }));
}

function sourcesByDestination(model, destinationId) {
  return Belt_List.keep(model.sources, (function (source) {
                var destinationMap = source.destinations;
                return Belt_List.hasU(destinationMap, destinationId, (function (sourceDestination, destinationId) {
                              return sourceDestination.destinationId === destinationId;
                            }));
              }));
}

function sourceById(model, sourceId) {
  return Belt_List.getBy(model.sources, (function (source) {
                return source.id === sourceId;
              }));
}

function destinationById(model, destinationId) {
  return Belt_List.getBy(model.destinations, (function (destination) {
                return destination.id === destinationId;
              }));
}

function validateSource(source, model) {
  var destinationsArray = Belt_List.toArray(model.destinations);
  var maybeDestinationError = Caml_obj.equal(source.platform, "Gtm") || Caml_obj.equal(source.platform, "JsonSchema") ? [] : (
      Belt_List.length(ModelUtils.destinationIdsWithoutArchived(Belt_List.map(source.destinations, (function (param) {
                      return param.destinationId;
                    })), destinationsArray)) === 0 ? ["DestinationsMissing"] : []
    );
  var maybeDevPlatformError = nonOptionalOrError(source.platform, "DevPlatformMissing");
  var maybeNameError = nonEmptyStringOrError(source.name, "NameMissing");
  var maybeLanguageError = nonOptionalOrError(source.language, "LanguageMissing");
  var errors = Belt_Array.concatMany([
        maybeDestinationError,
        maybeDevPlatformError,
        maybeNameError,
        maybeLanguageError
      ]);
  if (errors.length !== 0) {
    return {
            TAG: /* Error */1,
            _0: errors
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: TrackingPlanModel.Source.makeValid(source)
          };
  }
}

function validateCustom(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateApiKeys(apiKeys) {
  return Belt_Array.concat(Belt_Option.mapWithDefaultU(apiKeys.dev, [{
                    NAME: "ApiKeyMissing",
                    VAL: "Dev"
                  }], (function (apiKey) {
                    if (apiKey === "") {
                      return [{
                                NAME: "ApiKeyMissing",
                                VAL: "Dev"
                              }];
                    } else {
                      return [];
                    }
                  })), Belt_Option.mapWithDefaultU(apiKeys.prod, [{
                    NAME: "ApiKeyMissing",
                    VAL: "Prod"
                  }], (function (apiKey) {
                    if (apiKey === "") {
                      return [{
                                NAME: "ApiKeyMissing",
                                VAL: "Prod"
                              }];
                    } else {
                      return [];
                    }
                  })));
}

function validateFacebookAnalytics(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateFirebaseAnalytics(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateAmplitude(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateMixpanel(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateSegment(destination) {
  var maybeApiKeyErrors = validateApiKeys(destination.apiKeys);
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeApiKeyErrors,
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateMParticle(destination) {
  var maybeNameError = nonEmptyStringOrError(destination.name, "NameMissing");
  var maybeAnalyticsPlatformError = nonOptionalOrError(destination.type_, "AnalyticsPlatformMissing");
  return Belt_Array.concatMany([
              maybeNameError,
              maybeAnalyticsPlatformError
            ]);
}

function validateDestination(sources, destination) {
  var isAvoManagedonGivenSources = Belt_List.someU(sources, (function (source) {
          var sourceDestinations = source.destinations;
          return Belt_List.someU(sourceDestinations, (function (param) {
                        var destinationMode = param.destinationMode;
                        if (destination.id === param.destinationId) {
                          if (/* AvoManaged */0 === destinationMode) {
                            return true;
                          } else {
                            return /* Legacy */2 === destinationMode;
                          }
                        } else {
                          return false;
                        }
                      }));
        }));
  var errors;
  if (isAvoManagedonGivenSources) {
    var match = destination.type_;
    errors = match !== undefined ? (
        match === "Segment" ? validateSegment(destination) : (
            match === "Mixpanel" ? validateMixpanel(destination) : (
                match === "Amplitude" ? validateAmplitude(destination) : (
                    match === "FirebaseAnalytics" ? validateFirebaseAnalytics(destination) : (
                        match === "FacebookAnalytics" ? validateFacebookAnalytics(destination) : (
                            match === "MParticle" ? validateMParticle(destination) : validateCustom(destination)
                          )
                      )
                  )
              )
          )
      ) : ["AnalyticsPlatformMissing"];
  } else {
    var match$1 = destination.type_;
    errors = match$1 !== undefined ? validateCustom(destination) : ["AnalyticsPlatformMissing"];
  }
  if (Caml_obj.equal(errors, [])) {
    return {
            TAG: /* Ok */0,
            _0: TrackingPlanModel.Destination.makeValid(destination)
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: errors
          };
  }
}

function isProjectSettingsValid(sources, destinations, model) {
  var sourceValidations = Belt_List.mapU(sources, (function (source) {
          return validateSource(source, model);
        }));
  var destinationValidations = Belt_List.map(destinations, (function (param) {
          return validateDestination(sources, param);
        }));
  if (Belt_List.length(sources) === 0 || Belt_List.length(destinations) === 0 || Belt_List.some(sourceValidations, Belt_Result.isError)) {
    return false;
  } else {
    return !Belt_List.some(destinationValidations, Belt_Result.isError);
  }
}

function getValidSources(sources, model) {
  return Belt_List.keepMapU(Belt_List.mapU(sources, (function (source) {
                    return validateSource(source, model);
                  })), (function (source) {
                if (Belt_Result.isOk(source)) {
                  return Belt_Result.getExn(source);
                }
                
              }));
}

function getSourceName(source) {
  return Belt_Option.mapWithDefault(source.name, "Untitled Source", (function (name) {
                if (name === "") {
                  return "Untitled Source";
                } else {
                  return name;
                }
              }));
}

function getSourceNameById(model, sourceId) {
  var source = sourceById(model, sourceId);
  if (source !== undefined) {
    return getSourceName(source);
  } else {
    return "";
  }
}

function getSourceValidName(source) {
  if (source.name === "") {
    return "Untitled Source";
  } else {
    return source.name;
  }
}

function getIntegrationName(integration) {
  return Belt_Option.mapWithDefault(integration.name, "Untitled Integration", (function (x) {
                if (x === "") {
                  return "Untitled Integration";
                } else {
                  return x;
                }
              }));
}

function getIntegrationFilename(integration) {
  var match = integration.config;
  if (match !== undefined) {
    if (match.TAG === /* Taxonomy */1) {
      return Belt_Option.getWithDefault(integration.name, "avo-schema") + ".csv";
    } else {
      return Belt_Option.getWithDefault(integration.name, "avo-schema") + ".json";
    }
  } else {
    return Belt_Option.getWithDefault(integration.name, "avo-schema");
  }
}

function getIntegrationMimeType(integration) {
  var match = integration.config;
  if (match === undefined) {
    return "text/plain";
  }
  switch (match.TAG | 0) {
    case /* Taxonomy */1 :
        return "text/csv;charset=UTF-8";
    case /* Protocols */0 :
    case /* Lexicon */2 :
    case /* RudderstackTrackingPlans */6 :
        return "application/json;charset=UTF-8";
    default:
      return "text/plain";
  }
}

function getSourceById(id, sources) {
  return Belt_List.getByU(sources, (function (source) {
                return source.id === id;
              }));
}

function sourceIdToName(id, sources) {
  return Belt_Option.map(getSourceById(id, sources), getSourceName);
}

function isEventIncludedInSource(sourceId, $$event) {
  return Belt_List.someU($$event.includeSources, (function (includedSource) {
                return includedSource.id === sourceId;
              }));
}

function determineSendingToSources(sources, $$event) {
  var eventIncludeSourcesSet = Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray($$event.includeSources), (function (param) {
              return param.id;
            })));
  var filteredSources = Belt_List.keepU(sources, (function (source) {
          return Belt_SetString.has(eventIncludeSourcesSet, source.id);
        }));
  if (sources === /* [] */0) {
    return "None";
  } else if (Caml_obj.equal(filteredSources, sources)) {
    return "All";
  } else if (filteredSources === /* [] */0) {
    return "None";
  } else {
    return {
            NAME: "Some",
            VAL: filteredSources
          };
  }
}

function getValidDestinations(sources, destinations) {
  return Belt_List.keepMapU(Belt_List.map(destinations, (function (param) {
                    return validateDestination(sources, param);
                  })), (function (destination) {
                if (Belt_Result.isOk(destination)) {
                  return Belt_Result.getExn(destination);
                }
                
              }));
}

function tryToConvertSourceToValidSourceMaybe(source, model) {
  var validSource = validateSource(source, model);
  if (validSource.TAG === /* Ok */0) {
    return validSource._0;
  }
  
}

function tryToConvertDestinationToValidDestination(sources, destination) {
  var maybeValidDestination = validateDestination(sources, destination);
  return Belt_Result.mapWithDefaultU(maybeValidDestination, {
              TAG: /* Error */1,
              _0: destination
            }, (function (validDestination) {
                return {
                        TAG: /* Ok */0,
                        _0: validDestination
                      };
              }));
}

function tryToConvertDestinationToValidDestinationMaybe(model, destination) {
  var validDestination = tryToConvertDestinationToValidDestination(model, destination);
  if (validDestination.TAG === /* Ok */0) {
    return validDestination._0;
  }
  
}

function canLanguageHaveInterfaceFile(language) {
  if (language === "swift" || language === "kt") {
    return true;
  } else {
    return language === "objc";
  }
}

var segmentIntegrations = [
  "Redshift",
  "BigQuery",
  "Postgres",
  "Snowflake",
  "IBM Db2 Warehouse",
  "Azure SQL Data Warehouse",
  "Apptimize",
  "Evergage",
  "Leanplum",
  "Monetate",
  "Optimizely Web",
  "Optimizely Full Stack",
  "Split",
  "Taplytics",
  "Visual Website Optimizer",
  "AdLearn Open Platform",
  "AdRoll",
  "AdWords",
  "AdWords Remarketing Lists",
  "AppNexus",
  "Bing Ads",
  "Criteo",
  "DoubleClick Floodlight",
  "Facebook App Events",
  "Facebook Offline Conversions",
  "Facebook Pixel",
  "Google AdWords New",
  "HasOffers",
  "LinkedIn Insight Tag",
  "MediaMath",
  "Millennial Media",
  "Nanigans",
  "Perfect Audience",
  "Personas Facebook Custom Audiences",
  "Pinterest Tag",
  "Quantcast",
  "QuanticMind",
  "Quora Conversion Pixel",
  "RadiumOne Connect",
  "ShareASale",
  "SimpleReach",
  "TV Squared",
  "Twitter Ads",
  "Yellowhammer",
  "Adobe Analytics",
  "Alexa",
  "Amazon Kinesis",
  "Amazon Kinesis Firehose",
  "Amazon S3",
  "Amplitude",
  "Aptrinsic",
  "Calq",
  "Clicky",
  "Countly",
  "EPICA",
  "Firebase",
  "Flurry",
  "FoxMetrics",
  "Gauges",
  "goedle.io",
  "Google Analytics",
  "GoSquared",
  "Heap",
  "HitTail",
  "HubSpot",
  "IBM UBX",
  "Indicative",
  "Infinario",
  "Keen",
  "KISSmetrics",
  "Librato",
  "Localytics",
  "Lytics",
  "Madkudu",
  "Mixpanel",
  "MoEngage",
  "New Relic",
  "Pendo",
  "Piwik",
  "Pointillist",
  "ScopeAI",
  "Sherlock",
  "Swrve",
  "Tractionboard",
  "Unwaffle",
  "Whale Watch",
  "Wigzo",
  "Woopra",
  "Yandex Metrica",
  "Adjust",
  "AppsFlyer",
  "Attribution",
  "Convertro",
  "Dreamdata IO",
  "Rockerbox",
  "Improvely",
  "Kochava",
  "Tapstream",
  "TUNE",
  "Freshsales",
  "Sailthru",
  "Salesforce",
  "Whale Alerts",
  "Wishpond",
  "ChurnZero",
  "ClientSuccess",
  "Elevio",
  "Gainsight",
  "Help Scout",
  "Kustomer",
  "Natero",
  "Planhat",
  "Ramen",
  "Salesmachine",
  "Slack",
  "Totango",
  "Vitally",
  "Zendesk",
  "Zopim",
  "Branch Metrics",
  "ActiveCampaign",
  "AutopilotHQ",
  "Bronto",
  "Customer.io",
  "Drip",
  "Eloqua",
  "Email Aptitude",
  "Klaviyo",
  "MailChimp",
  "Mailjet",
  "Marketo V2",
  "Moosend",
  "Nudgespot",
  "Pardot",
  "Responsys",
  "Salesforce Marketing Cloud",
  "Seg",
  "Vero",
  "Clearbit Enrichment",
  "Clearbit Reveal",
  "Crazy Egg",
  "FullStory",
  "Hotjar",
  "Inspectlet",
  "Lucky Orange",
  "Mouseflow",
  "MouseStats",
  "Navilytics",
  "Drift",
  "Intercom",
  "LiveChat",
  "Olark",
  "SnapEngage",
  "Userlike",
  "PersistIQ",
  "Upcall",
  "Atatus",
  "BugHerd",
  "Bugsnag",
  "Crittercism",
  "Errorception",
  "Pingdom",
  "Rollbar",
  "Sentry",
  "Track JS",
  "Appcues",
  "Chameleon",
  "ClearBrain",
  "Hello Bar",
  "Hull",
  "Intellimize",
  "Mutiny",
  "Personyze",
  "Proof Experiences",
  "Spinnakr",
  "tray.io",
  "UserEngage",
  "Blendo",
  "Google Cloud PubSub",
  "Iron.io",
  "Repeater",
  "Stitch Data",
  "Treasure Data",
  "Webhooks",
  "Xplenty",
  "Zapier",
  "Ambassador",
  "Curebit",
  "Friendbuy",
  "SaaSquatch",
  "Trustpilot",
  "Braze",
  "Batch",
  "Blueshift",
  "CleverTap",
  "Iterable",
  "Kahuna",
  "OneSignal",
  "Outbound",
  "Urban Airship",
  "WebEngage",
  "Zaius",
  "Castle",
  "Watchtower",
  "Delighted",
  "Promoter.io",
  "Qualaroo",
  "SatisMeter",
  "Savio",
  "UserVoice",
  "Wootric",
  "Google Tag Manager",
  "Chartbeat",
  "comScore",
  "Parsely",
  "Youbora"
];

var anonymousUserDisplayName = "Anonymous user";

function getUserDisplayName(user) {
  var match = user.name;
  var match$1 = user.email;
  if (match == null) {
    if (match$1 == null) {
      return anonymousUserDisplayName;
    } else {
      return match$1;
    }
  } else {
    return match;
  }
}

var supportedEventSpecificPropertyValuesPlatforms = [];

var intercomPropertyLimit = 5;

var defaultFilename = "AvoAnalytics";

var defaultPath = "./src";

export {
  supportedOperations ,
  operationLabel ,
  devPlatforms ,
  devPlatformLabel ,
  inspectorSupportedDevPlatforms ,
  inspectorSupportedLanguages ,
  analyticsTools ,
  groupedAnalyticsTools ,
  analyticsToolsLabel ,
  supportsObjects ,
  codegenSupportsPlainObjects ,
  codegenSupportsNameMappingAndPinningInObjects ,
  codegenSupportsNameMappingAndPinningInListsOfObjects ,
  codegenSupportsNameMappingAndPinningInNestedObjects ,
  languagePlatformDestinationsThatSupportGroupAnalytics ,
  doesLanguagePlatformDestinationSupportGroups ,
  areGroupPropertiesSupported ,
  hasAtLeastOneDestinationThatDoesNotSupportGroupProperties ,
  getUnsupportedSetOnceDestinationLabels ,
  getSupportedLanguages ,
  supportsInvocations ,
  filenameHint ,
  languageExtensionHint ,
  languageLabel ,
  languageFromLabel ,
  languagesThatSupportPropertyBundleObjects ,
  doesLanguageSupportPropertyBundleObjects ,
  languagePlatformDestinationLabel ,
  intercomPropertyLimit ,
  getSupportedAvoManagedDestinations ,
  getSupportedDestinations ,
  setDestinationModeForCodegen ,
  convertDestinationInterfacesToCustomInModel ,
  hasAnalyticsTool ,
  hasDevPlatform ,
  defaultFilename ,
  defaultPath ,
  nonEmptyStringOrError ,
  nonOptionalOrError ,
  sourcesByDestination ,
  sourceById ,
  destinationById ,
  validateSource ,
  validateCustom ,
  validateApiKeys ,
  validateFacebookAnalytics ,
  validateFirebaseAnalytics ,
  validateAmplitude ,
  validateMixpanel ,
  validateSegment ,
  validateMParticle ,
  validateDestination ,
  isProjectSettingsValid ,
  getValidSources ,
  getSourceName ,
  getSourceNameById ,
  getSourceValidName ,
  getIntegrationName ,
  getIntegrationFilename ,
  getIntegrationMimeType ,
  getSourceById ,
  sourceIdToName ,
  isEventIncludedInSource ,
  determineSendingToSources ,
  getValidDestinations ,
  tryToConvertSourceToValidSourceMaybe ,
  tryToConvertDestinationToValidDestination ,
  tryToConvertDestinationToValidDestinationMaybe ,
  canLanguageHaveInterfaceFile ,
  segmentIntegrations ,
  anonymousUserDisplayName ,
  getUserDisplayName ,
  supportedEventSpecificPropertyValuesPlatforms ,
}
/* ModelUtils Not a pure module */
