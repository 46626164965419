// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconSend from "./IconSend.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DrawerUtils from "./DrawerUtils.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as EventSources from "./EventSources.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as Core from "@mantine/core";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as IconSideEffect from "./IconSideEffect.mjs";
import * as IconDescription from "./IconDescription.mjs";
import * as EventNameMapping from "./EventNameMapping.mjs";
import * as EventSideEffects from "./EventSideEffects.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as ObjectActivityLog from "./ObjectActivityLog.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as EventDetailTriggers from "./EventDetailTriggers.mjs";
import * as EventDetailsDomains from "./detailviews/EventDetailsDomains.mjs";
import * as EventImplementation from "./detailviews/EventImplementation.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchChangesContext from "./BranchChangesContext.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import ReactTextareaAutosize from "react-textarea-autosize";

function content(topOffset) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.overflowY("auto"),
                  tl: {
                    hd: Css.paddingBottom(Css.px(40)),
                    tl: {
                      hd: Css.maxHeight({
                            NAME: "subtract",
                            VAL: [
                              Css.vh(100),
                              Css.px(topOffset)
                            ]
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var baseEvent = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.light02),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(60)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.marginBottom(Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var sectionHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginTop(Css.px(16)),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var description = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.input),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("block"),
              tl: {
                hd: Css.flex({
                      NAME: "num",
                      VAL: 1.0
                    }),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", "transparent"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.selector(":not(:disabled)", {
                                      hd: Css.focus({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var descriptionLabel = Curry._1(Css.style, {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.color(Styles.Color.light12),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: /* [] */0
        }
      }
    });

var Style = {
  content: content,
  baseEvent: baseEvent,
  sectionHeader: sectionHeader,
  description: description,
  descriptionLabel: descriptionLabel
};

function EventVariant(Props) {
  var baseEvent$1 = Props.baseEvent;
  var variant = Props.variant;
  var viewerCanEdit = Props.viewerCanEdit;
  var model = Props.model;
  var currentFilters = Props.currentFilters;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var filters = Props.filters;
  var branchStatus = Props.branchStatus;
  var role = Props.role;
  var flattenedVariantModel = React.useMemo((function () {
          return FlattenVariantsUseCase.flattenOneVariant(model, baseEvent$1, variant);
        }), [
        model,
        baseEvent$1,
        variant
      ]);
  var flattenedVariantEvent = Belt_Option.getExn(Belt_List.getByU(flattenedVariantModel.events, (function ($$event) {
              return $$event.id === VariantEventIdUseCase.variantEventId({
                          baseEventId: baseEvent$1.id,
                          variantId: variant.id
                        });
            })));
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var isEventModified = BranchChangesContext.useIsEventModified(baseEvent$1.id);
  var fromItem_itemId = variant.id;
  var fromItem_itemName = variant.nameSuffix;
  var fromItem = {
    itemId: fromItem_itemId,
    itemName: fromItem_itemName,
    itemType: "EventVariant"
  };
  var hasIncludedSource = Belt_List.some(baseEvent$1.includeSources, (function (param) {
          return ModelUtils.getSourceById(param.id, model) !== undefined;
        }));
  var organization = OrganizationContext.useOrganization(undefined);
  var globalVariant = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalEventVariant, variant.id);
  var match = AvoConfig.determineSendingToSources(model.sources, baseEvent$1);
  var baseEventSendingSources = typeof match === "object" ? match.VAL : (
      match === "None" ? /* [] */0 : model.sources
    );
  var tmp;
  if (organization !== undefined) {
    var match$1 = organization.globalWorkspaceIds;
    if (match$1.length !== 1) {
      tmp = null;
    } else {
      var id = match$1[0];
      tmp = globalVariant !== undefined ? React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.margin(Css.px(16)),
                    tl: {
                      hd: Css.marginBottom(Css.px(0)),
                      tl: {
                        hd: Css.padding(Css.px(16)),
                        tl: {
                          hd: Css.borderRadius(Css.px(8)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.5)),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(10)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", undefined, React.createElement(IconDescription.make, {
                      fill: Styles.Color.blue
                    })), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.deepBlue,
                          children: "Global event variant"
                        }), React.createElement(SimpleTooltip.make, {
                          maxWidth: 240,
                          tooltip: "This is a globally defined event variant so editing is limited",
                          children: React.createElement(Icon.make, {
                                type_: "information",
                                size: "medium",
                                color: Styles.Color.light09
                              })
                        })), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(Link.make, {
                      path: Curry._4(Router.Link.get, {
                            NAME: "schema",
                            VAL: id
                          }, undefined, "events", {
                            hd: {
                              NAME: "eventVariant",
                              VAL: [
                                TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                undefined
                              ]
                            },
                            tl: /* [] */0
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.color(Styles.Color.deepBlue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.textDecoration("underline"),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }),
                      children: React.createElement($$Text.make, {
                            children: "View in global workspace"
                          })
                    }))) : null;
    }
  } else {
    tmp = null;
  }
  var tmp$1;
  if (globalVariant !== undefined) {
    var description$1 = globalVariant.description;
    var tmp$2 = {
      className: description,
      maxRows: 15,
      minRows: 1,
      placeholder: "Global description missing...",
      value: description$1.trim(),
      id: "global-description-input",
      disabled: true
    };
    var tmp$3 = description$1.trim() === "" ? 1 : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.rows = tmp$3;
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.padding(Css.px(10)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light09,
                  children: "Global description"
                }), React.createElement(Icon.make, {
                  type_: "lock",
                  size: "small",
                  color: Styles.Color.light09
                })), React.createElement(ReactTextareaAutosize, tmp$2), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.padding(Css.px(10)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light09,
                  children: "Description"
                })));
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
                  kind: (
                    globalVariant !== undefined || isGlobalWorkspace ? "Global " : ""
                  ) + "Event Variant",
                  options: Belt_Array.concatMany([
                        [{
                            NAME: "Option",
                            VAL: {
                              label: "Copy link to variant",
                              onClick: (function (param) {
                                  CopyTextToClipboard(Router.Link.getSimple(undefined));
                                  Curry._1(addToast, {
                                        message: "Link copied!",
                                        toastType: /* Success */0
                                      });
                                })
                            }
                          }],
                        viewerCanEdit ? [{
                              NAME: "WarningOption",
                              VAL: {
                                label: "Archive",
                                onClick: (function (param) {
                                    Router.Schema.popDrawerItem(undefined, undefined);
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              Curry._1(addToast, {
                                                    message: "Variant has been archived",
                                                    toastType: /* Success */0
                                                  });
                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                              AnalyticsRe.eventVariantArchived(baseEvent$1.id, baseEvent$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, variant.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), baseEvent$1.id);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "Archive",
                                                VAL: {
                                                  NAME: "EventVariant",
                                                  VAL: TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)
                                                }
                                              },
                                              {
                                                eventId: variant.baseEventId,
                                                eventQuery: variant.baseEventId,
                                                eventVariantId: variant.id,
                                                eventVariantQuery: variant.id
                                              }
                                            ]]
                                        ]);
                                  })
                              }
                            }] : []
                      ]),
                  children: React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        color: Styles.Color.light09,
                        children: null
                      }, baseEvent$1.name, React.createElement($$Text.make, {
                            element: "Span",
                            color: Styles.Color.light12,
                            children: " - " + variant.nameSuffix + ""
                          }))
                }), tmp, React.createElement("div", {
                  className: content(DrawerUtils.getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute))
                }, React.createElement("div", {
                      className: baseEvent
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: null
                        }, "Variation of ", React.createElement(TextButton.make, {
                              onClick: (function (param) {
                                  Router.Schema.pushDrawerItem(undefined, undefined, {
                                        NAME: "event",
                                        VAL: [
                                          baseEvent$1.id,
                                          undefined,
                                          undefined,
                                          false
                                        ]
                                      });
                                }),
                              style: "Blue",
                              children: baseEvent$1.name
                            })), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light11,
                          children: baseEvent$1.description
                        })), React.createElement("div", {
                      className: sectionHeader
                    }, React.createElement(Spacer.make, {
                          width: 24
                        }), React.createElement(IconDescription.make, {}), React.createElement(Spacer.make, {
                          width: 18
                        }), React.createElement("label", {
                          className: descriptionLabel,
                          htmlFor: "description-input"
                        }, "Description"), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Description",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.eventDescription
                                    })
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(48)),
                            tl: /* [] */0
                          })
                    }, tmp$1, React.createElement(OpsWithState.make, {
                          value: variant.description,
                          onFlush: (function (description) {
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        AnalyticsRe.eventVariantUpdated(baseEvent$1.id, baseEvent$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Description", variant.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), baseEvent$1.id);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "UpdateEventVariantDescription",
                                          VAL: [
                                            TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                            description
                                          ]
                                        },
                                        {
                                          eventId: baseEvent$1.id,
                                          eventQuery: baseEvent$1.id,
                                          eventVariantId: variant.id,
                                          eventVariantQuery: variant.id
                                        }
                                      ]]
                                  ]);
                            }),
                          children: (function (value, onChange, onBlur, onFocus) {
                              return React.createElement(ReactTextareaAutosize, {
                                          className: description,
                                          maxRows: 15,
                                          minRows: 1,
                                          onChange: (function (domEvent) {
                                              Curry._1(onChange, domEvent.target.value);
                                            }),
                                          placeholder: "Describe the event variant...",
                                          onFocus: (function (domEvent) {
                                              Curry._1(onFocus, domEvent.target.value);
                                            }),
                                          onBlur: (function (domEvent) {
                                              Curry._1(onBlur, domEvent.target.value);
                                            }),
                                          value: value,
                                          autoFocus: value === "",
                                          id: "description-input",
                                          disabled: !viewerCanEdit
                                        });
                            })
                        })), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement(EventDetailTriggers.make, {
                      event: flattenedVariantEvent,
                      modelSources: flattenedVariantModel.sources,
                      schemaBundle: schemaBundle,
                      viewerCanEdit: viewerCanEdit
                    }), React.createElement(EventNameMapping.make, {
                      event: flattenedVariantEvent,
                      slowModelMaybeFlattened: flattenedVariantModel,
                      fromItem: fromItem,
                      sendActions: sendActions,
                      role: role,
                      isGlobalWorkspace: isGlobalWorkspace,
                      disabled: true
                    }), React.createElement("div", {
                      className: sectionHeader
                    }, React.createElement(Spacer.make, {
                          width: 24
                        }), React.createElement(IconSend.make, {
                          color: Styles.Color.light07
                        }), React.createElement(Spacer.make, {
                          width: 18
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: isGlobalWorkspace ? "Workspaces" : "Sources"
                        }), viewerCanEdit ? React.createElement(Mantine.Popover.make, {
                            dropdownStyles: {
                              hd: Css.transform({
                                    NAME: "translateY",
                                    VAL: Css.px(3)
                                  }),
                              tl: /* [] */0
                            },
                            offset: 0,
                            position: "right-start",
                            children: null
                          }, React.createElement(Core.Popover.Target, {
                                children: React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.buttonReset),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.padding(Css.px(8)),
                                                    tl: {
                                                      hd: Css.color(Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.marginBottom(Css.px(-1)),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.color(Styles.Color.deepBlue),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: {
                                                            hd: Css.transition({
                                                                  NAME: "ms",
                                                                  VAL: 200.0
                                                                }, undefined, undefined, "color"),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          children: "Edit on variant"
                                        }))
                              }), React.createElement(Mantine.Popover.Dropdown.make, {
                                additionalStyles: {
                                  hd: Css.transform(Css.translateY(Css.px(-5))),
                                  tl: /* [] */0
                                },
                                children: React.createElement("aside", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.white),
                                              tl: {
                                                hd: Css.overflow("hidden"),
                                                tl: {
                                                  hd: Css.width(Css.px(240)),
                                                  tl: {
                                                    hd: Css.boxShadow(Styles.Shadow.subtle),
                                                    tl: {
                                                      hd: Css.maxHeight({
                                                            NAME: "subtract",
                                                            VAL: [
                                                              Css.vh(100.0),
                                                              Css.px(32)
                                                            ]
                                                          }),
                                                      tl: {
                                                        hd: Css.overflow("scroll"),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement("header", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding(Css.px(12)),
                                                tl: {
                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light03),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement($$Text.make, {
                                              element: "H1",
                                              size: "Medium",
                                              weight: "Medium",
                                              color: Styles.Color.light12,
                                              children: "Available " + (
                                                isGlobalWorkspace ? "Workspaces" : "Sources"
                                              )
                                            }), React.createElement(Spacer.make, {
                                              height: 8
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: "Set the appropriate " + (
                                                isGlobalWorkspace ? "workspaces" : "sources"
                                              ) + " for this variaton of the event."
                                            })), React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding(Css.px(12)),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexDirection("column"),
                                                    tl: {
                                                      hd: Css.gap(Css.px(8)),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              })
                                        }, baseEventSendingSources === /* [] */0 ? React.createElement("div", undefined, React.createElement($$Text.make, {
                                                    size: "Medium",
                                                    children: "⚠️"
                                                  }), React.createElement(Spacer.make, {
                                                    width: 8
                                                  }), React.createElement($$Text.make, {
                                                    size: "Small",
                                                    color: Styles.Color.light10,
                                                    children: "Add " + (
                                                      isGlobalWorkspace ? "workspaces" : "sources"
                                                    ) + " to your event to configure them for this variant"
                                                  })) : null, Belt_List.toArray(Belt_List.mapU(baseEventSendingSources, (function (source) {
                                                    return React.createElement("div", {
                                                                key: source.id,
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: /* [] */0
                                                                    })
                                                              }, React.createElement(Switch.make, {
                                                                    value: Caml_obj.notequal(Belt_MapString.get(variant.sourceOverrides, source.id), /* Removed */0),
                                                                    onChange: (function (isSet) {
                                                                        Curry.app(sendActions, [
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              undefined,
                                                                              (function (branch) {
                                                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                  AnalyticsRe.eventVariantUpdatedSource(baseEvent$1.id, baseEvent$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, isSet ? "ClearSourceOverrides" : "RemoveSource", variant.id, source.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), baseEvent$1.id);
                                                                                }),
                                                                              undefined,
                                                                              [[
                                                                                  isSet ? ({
                                                                                        NAME: "ClearSourceOverrideOnVariant",
                                                                                        VAL: [
                                                                                          TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                                                                          source.id
                                                                                        ]
                                                                                      }) : ({
                                                                                        NAME: "SetSourceOverrideOnVariant",
                                                                                        VAL: [
                                                                                          TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                                                                          source.id,
                                                                                          /* Removed */0
                                                                                        ]
                                                                                      }),
                                                                                  {
                                                                                    eventId: baseEvent$1.id,
                                                                                    eventQuery: baseEvent$1.id,
                                                                                    eventVariantId: variant.id,
                                                                                    eventVariantQuery: variant.id
                                                                                  }
                                                                                ]]
                                                                            ]);
                                                                      }),
                                                                    size: "Small"
                                                                  }), React.createElement(Spacer.make, {
                                                                    width: 8
                                                                  }), React.createElement($$Text.make, {
                                                                    size: "Medium",
                                                                    weight: "Medium",
                                                                    singleLine: true,
                                                                    color: Styles.Color.light10,
                                                                    children: AvoConfig.getSourceName(source)
                                                                  }));
                                                  })))))
                              })) : null), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(48)),
                            tl: /* [] */0
                          })
                    }, React.createElement(EventSources.make, {
                          event: flattenedVariantEvent,
                          model: flattenedVariantModel,
                          variant: variant,
                          viewerCanEdit: viewerCanEdit,
                          isEventModified: isEventModified
                        })), React.createElement("div", {
                      className: sectionHeader
                    }, React.createElement(Spacer.make, {
                          width: 24
                        }), React.createElement(IconSideEffect.make, {}), React.createElement(Spacer.make, {
                          width: 18
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: "Actions"
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: fromItem,
                          title: "Actions",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.trackingMethods
                                    })
                              })
                        })), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(48)),
                            tl: {
                              hd: Css.marginBottom(Css.px(12)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(EventSideEffects.make, {
                          currentFilters: currentFilters,
                          event: flattenedVariantEvent,
                          filters: filters,
                          viewerCanEdit: viewerCanEdit,
                          variant: variant,
                          model: flattenedVariantModel
                        })), React.createElement(EventDetailsDomains.make, {
                      event: flattenedVariantEvent,
                      viewerCanEdit: viewerCanEdit,
                      viewerRole: role,
                      variant: variant
                    }), hasIncludedSource && !isGlobalWorkspace ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.marginBottom(Css.px(12)),
                              tl: /* [] */0
                            })
                      }, React.createElement(EventImplementation.make, {
                            branchStatus: branchStatus,
                            event: flattenedVariantEvent,
                            model: flattenedVariantModel
                          })) : null, React.createElement(ObjectActivityLog.make, {
                      object_: {
                        NAME: "EventVariant",
                        VAL: TrackingPlanModel.EventVariant.makeVariantIdentifier(variant)
                      },
                      objectId: variant.id,
                      currentBranch: currentBranch,
                      openBranches: openBranches
                    })));
}

var make = EventVariant;

export {
  Style ,
  make ,
}
/* baseEvent Not a pure module */
