// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";

function compare(a, b) {
  var value = a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
  if (value < 0.0) {
    return -1;
  } else if (value > 0.0) {
    return 1;
  } else {
    return 0;
  }
}

var containerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var pillStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(8)),
              tl: {
                hd: Css.borderRadius(Css.px(16)),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light08),
                  tl: {
                    hd: Css.border(Css.px(1), "solid", Styles.Color.light08),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "background-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.backgroundColor(Styles.Color.light10),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(8)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$default
                          }, undefined, undefined, "border-color"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.borderColor(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function PropertyDetailsEvents$RestRow(Props) {
  var partialItemsCount = Props.partialItemsCount;
  var totalItemsCount = Props.totalItemsCount;
  var showAllItems = Props.showAllItems;
  var itemLabel = Props.itemLabel;
  var onClick = Props.onClick;
  var count = showAllItems ? totalItemsCount : partialItemsCount;
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement("button", {
                  className: pillStyles,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light01,
                      children: "" + String(count) + "" + (
                        showAllItems ? "" : " more"
                      ) + " " + itemLabel + "" + (
                        count > 1 ? "s" : ""
                      ) + ""
                    })), React.createElement("button", {
                  className: buttonStyles,
                  onClick: onClick
                }, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: "" + (
                        showAllItems ? "Show fewer" : "Show all"
                      ) + " " + itemLabel + "s"
                    })));
}

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(12), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopRightRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.borderTopLeftRadius(Styles.Border.radius),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var nameRowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding4(Css.px(12), Css.px(16), Css.px(12), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var listItemStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.gap(Css.px(4)),
            tl: {
              hd: Css.padding4(Css.px(8), Css.px(8), Css.px(8), Css.px(16)),
              tl: {
                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.borderTopWidth(Css.px(0)),
                  tl: {
                    hd: Css.selector(":nth-last-child(2)", {
                          hd: Css.borderBottomRightRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var listItemConfigStyles = Curry._1(Css.style, {
      hd: Css.maxWidth(Css.px(200)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

var planLightningStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css_Legacy_Core.SVG.fill("transparent"),
        tl: {
          hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
          tl: {
            hd: Css.hover({
                  hd: Css_Legacy_Core.SVG.stroke(Styles.Color.darkBlue),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.display("block"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.flexShrink(1.0),
          tl: {
            hd: Css.minWidth(Css.px(1)),
            tl: {
              hd: Css.wordBreak("breakAll"),
              tl: {
                hd: Css.color(Styles.Color.light10),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.darkBlue),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.padding2(Css.px(8), Css.px(0)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function tableBoxStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.pct(100.0)),
              tl: {
                hd: Css.overflow("auto"),
                tl: {
                  hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
                    tl: {
                      hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                      tl: {
                        hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function tableStyles(useBottomRadius) {
  return Curry._1(Css.style, {
              hd: Css.borderSpacing(Css.px(0)),
              tl: {
                hd: Css.minWidth(Css.pct(100.0)),
                tl: {
                  hd: Css.selector(" tbody tr:last-child td:first-child", {
                        hd: Css.borderBottomLeftRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(" tbody tr:last-child td:last-child", {
                          hd: Css.borderBottomRightRadius(useBottomRadius ? Styles.Border.radius : "zero"),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var tableHeaderCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light02),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.selector(":first-child", {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.left(Css.px(0)),
                      tl: /* [] */0
                    }
                  }),
              tl: {
                hd: Css.selector(":last-child", {
                      hd: Css.borderRightWidth(Css.px(0)),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.selector(":not(:first-child)", {
                        hd: Css.textAlign("center"),
                        tl: {
                          hd: Css.verticalAlign("middle"),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var tableNameCellStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
      tl: {
        hd: Css.borderLeftWidth(Css.px(0)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.position("sticky"),
              tl: {
                hd: Css.left(Css.px(0)),
                tl: {
                  hd: Css.background(Styles.Color.white),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var tableSelectCellStyles = Curry._1(Css.style, {
      hd: Css.selector(":last-child", {
            hd: Css.borderRightWidth(Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.padding(Css.px(4)),
        tl: {
          hd: Css.borderRight(Css.px(1), "solid", Styles.Color.light04),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.textAlign("center"),
              tl: {
                hd: Css.verticalAlign("middle"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function PropertyDetailsEvents(Props) {
  var viewerCanEdit = Props.viewerCanEdit;
  var property = Props.property;
  var events = Props.events;
  var model = ModelStore.Mapped.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var sourcesList = Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Sources.toArray, model.sources));
  var match = AvoLimits.PropertyAbsenceByEventAndSource.computeAvailability(workspace);
  var available = match === "AvailableDuringTrial" || match === "Available";
  var initialMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
  var match$1 = React.useState(function () {
        return initialMode;
      });
  var pickMode = match$1[1];
  var pickedMode = match$1[0];
  var match$2 = React.useState(function () {
        return initialMode;
      });
  var setComputedMode = match$2[1];
  var computedMode = match$2[0];
  React.useEffect((function () {
          Curry._1(setComputedMode, (function (param) {
                  var modelMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
                  switch (pickedMode) {
                    case /* Always */0 :
                        switch (modelMode) {
                          case /* Always */0 :
                              return /* Always */0;
                          case /* SometimesAll */1 :
                              return /* SometimesAll */1;
                          case /* SometimesByEvent */2 :
                              return /* SometimesByEvent */2;
                          case /* BySource */3 :
                              return /* BySource */3;
                          
                        }
                    case /* SometimesAll */1 :
                        switch (modelMode) {
                          case /* Always */0 :
                              return /* Always */0;
                          case /* SometimesAll */1 :
                              return /* SometimesAll */1;
                          case /* SometimesByEvent */2 :
                              return /* SometimesByEvent */2;
                          case /* BySource */3 :
                              return /* BySource */3;
                          
                        }
                    case /* SometimesByEvent */2 :
                        switch (modelMode) {
                          case /* Always */0 :
                              return /* Always */0;
                          case /* SometimesAll */1 :
                              return /* SometimesAll */1;
                          case /* SometimesByEvent */2 :
                              return /* SometimesByEvent */2;
                          case /* BySource */3 :
                              return /* BySource */3;
                          
                        }
                    case /* BySource */3 :
                        return /* BySource */3;
                    
                  }
                }));
        }), [
        events,
        sourcesList,
        property.absence,
        pickedMode
      ]);
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowAllEvents = match$3[1];
  var showAllEvents = match$3[0];
  var eventsToPinnedValuesMap = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap(events, (function ($$event) {
                                var pinnedValues = PropertyUtils.getPinnedValues_mapped(undefined, property.id, $$event.id, false, model, undefined);
                                if (pinnedValues === /* [] */0) {
                                  return ;
                                } else {
                                  return [
                                          $$event.id,
                                          pinnedValues
                                        ];
                                }
                              }))));
        }), [events]);
  var tmp;
  if (computedMode >= 3) {
    var allEventSources = PropertyAbsenceViewHelpers.getAllEventSources(sourcesList, events);
    var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
    var allEvents = Belt_List.sortU(Belt_List.keepMapU(Belt_MapString.toList(stateMatrix), (function (param) {
                var sourceMap = param[1];
                var eventId = param[0];
                return Belt_Option.map(Belt_List.getBy(events, (function ($$event) {
                                  return $$event.id === eventId;
                                })), (function ($$event) {
                              return [
                                      $$event,
                                      sourceMap
                                    ];
                            }));
              })), (function (param, param$1) {
            return compare(param[0].name, param$1[0].name);
          }));
    var allEventsLength = Belt_List.length(allEvents);
    var mayCollapse = allEventsLength > 15;
    var eventsToTake = mayCollapse ? 5 : allEventsLength;
    var maybePartialEvents = mayCollapse ? Belt_List.take(allEvents, eventsToTake) : undefined;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: tableBoxStyles(!mayCollapse)
            }, React.createElement("table", {
                  className: tableStyles(!mayCollapse)
                }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("td", {
                              className: tableHeaderCellStyles
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light11,
                                  children: "Event Name"
                                })), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_List.head(events), (function ($$event) {
                                        return Belt_MapString.get(stateMatrix, $$event.id);
                                      })), (function (sourceIdMap) {
                                    return Belt_Array.map(Belt_Array.keepMap(Belt_MapString.keysToArray(sourceIdMap), (function (sourceId) {
                                                      return Belt_List.getBy(allEventSources, (function (source) {
                                                                    return source.id === sourceId;
                                                                  }));
                                                    })), (function (source) {
                                                  return React.createElement("td", {
                                                              className: tableHeaderCellStyles
                                                            }, React.createElement(SimpleTooltip.make, {
                                                                  delayIn: 0,
                                                                  position: "top",
                                                                  tooltip: Belt_Option.getWithDefault(source.name, "Untitled"),
                                                                  children: React.createElement($$Text.make, {
                                                                        size: "Small",
                                                                        weight: "Semi",
                                                                        singleLine: true,
                                                                        color: Styles.Color.light11,
                                                                        maxWidth: Css.px(120),
                                                                        title: Belt_Option.getWithDefault(source.name, "Untitled"),
                                                                        withWordBreak: false,
                                                                        children: Belt_Option.getWithDefault(source.name, "Untitled")
                                                                      })
                                                                }));
                                                }));
                                  })), null))), React.createElement("tbody", undefined, Belt_List.toArray(Belt_List.mapU(maybePartialEvents !== undefined && !showAllEvents ? maybePartialEvents : allEvents, (function (param) {
                                var $$event = param[0];
                                var pinnedValues = Belt_Option.getWithDefault(Belt_MapString.get(eventsToPinnedValuesMap, $$event.id), /* [] */0);
                                return React.createElement("tr", undefined, React.createElement("td", {
                                                className: tableNameCellStyles
                                              }, React.createElement(Link.make, {
                                                    path: Router.Link.addDrawerItem(undefined, {
                                                          NAME: "event",
                                                          VAL: [
                                                            $$event.id,
                                                            undefined,
                                                            undefined,
                                                            false
                                                          ]
                                                        }),
                                                    className: linkStyles,
                                                    children: React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Semi",
                                                          singleLine: true,
                                                          color: Styles.Color.light12,
                                                          maxWidth: Css.px(250),
                                                          title: $$event.name,
                                                          withWordBreak: false,
                                                          children: $$event.name
                                                        })
                                                  })), Belt_MapString.valuesToArray(Belt_MapString.mapWithKey(param[1], (function (sourceId, state) {
                                                      var tmp;
                                                      var exit = 0;
                                                      if (pinnedValues && state !== 0) {
                                                        tmp = React.createElement($$Text.make, {
                                                              size: "Small",
                                                              singleLine: true,
                                                              color: Styles.Color.light10,
                                                              maxWidth: Css.px(200),
                                                              title: "Pinned to " + Belt_List.toArray(pinnedValues).join(", ") + "",
                                                              withWordBreak: false,
                                                              children: "Pinned"
                                                            });
                                                      } else {
                                                        exit = 1;
                                                      }
                                                      if (exit === 1) {
                                                        tmp = React.createElement(Select.make, {
                                                              disabled: state === /* Unavailable */0 || !available,
                                                              onSelect: (function (newState) {
                                                                  var eventId = $$event.id;
                                                                  var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                                                                  Curry.app(sendActions, [
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        undefined,
                                                                        (function (branch) {
                                                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                            var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                                                            var match = property.absence;
                                                                            var tmp;
                                                                            tmp = match !== undefined && typeof match !== "number" ? (
                                                                                match.TAG === /* SometimesSent */0 ? "SometimesSent" : "DependingOnSource"
                                                                              ) : "AlwaysSent";
                                                                            AnalyticsRe.propertyConfigured(propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceByEventAndSource", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                                          }),
                                                                        undefined,
                                                                        [[
                                                                            {
                                                                              NAME: "UpdatePropertyAbsence",
                                                                              VAL: [
                                                                                property.id,
                                                                                {
                                                                                  TAG: /* EventSource */2,
                                                                                  _0: eventId,
                                                                                  _1: sourceId
                                                                                },
                                                                                state !== 2 ? (
                                                                                    state >= 3 ? undefined : /* NeverSent */0
                                                                                  ) : /* SometimesSent */({
                                                                                      _0: ""
                                                                                    })
                                                                              ]
                                                                            },
                                                                            {
                                                                              eventId: eventId,
                                                                              eventQuery: eventId,
                                                                              propertyId: property.id,
                                                                              propertyQuery: property.id
                                                                            }
                                                                          ]]
                                                                      ]);
                                                                }),
                                                              options: Belt_Array.map(state === /* Unavailable */0 ? [/* Unavailable */0] : [
                                                                      /* Never */1,
                                                                      /* Sometimes */2,
                                                                      /* Always */3
                                                                    ], (function (mode) {
                                                                      return [
                                                                              {
                                                                                NAME: "Label",
                                                                                VAL: PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                              },
                                                                              PropertyAbsenceViewHelpers.getEventAndSourceStateString(mode)
                                                                            ];
                                                                    })),
                                                              value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(state)
                                                            });
                                                      }
                                                      return React.createElement("td", {
                                                                  className: tableSelectCellStyles
                                                                }, tmp);
                                                    }))));
                              })))))), maybePartialEvents !== undefined ? React.createElement(PropertyDetailsEvents$RestRow, {
                partialItemsCount: allEventsLength - Belt_List.length(maybePartialEvents) | 0,
                totalItemsCount: allEventsLength,
                showAllItems: showAllEvents,
                itemLabel: "event",
                onClick: (function (param) {
                    Curry._1(setShowAllEvents, (function (previous) {
                            return !previous;
                          }));
                  })
              }) : null);
  } else {
    var allEvents$1 = Belt_List.sortU(events, (function (a, b) {
            return compare(a.name, b.name);
          }));
    var allEventsLength$1 = Belt_List.length(allEvents$1);
    var mayCollapse$1 = allEventsLength$1 > 15;
    var eventsToTake$1 = mayCollapse$1 ? 5 : allEventsLength$1;
    var maybePartialEvents$1 = mayCollapse$1 ? Belt_List.take(allEvents$1, eventsToTake$1) : undefined;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: nameRowStyles
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Event name"
                })), Belt_Array.mapU(Belt_List.toArray(maybePartialEvents$1 !== undefined && !showAllEvents ? maybePartialEvents$1 : allEvents$1), (function ($$event) {
                var pinnedValues = Belt_Option.getWithDefault(Belt_MapString.get(eventsToPinnedValuesMap, $$event.id), /* [] */0);
                var tmp;
                if (pinnedValues === /* [] */0) {
                  var match = AvoConfig.determineSendingToSources(sourcesList, $$event);
                  var eventSources = typeof match === "object" ? match.VAL : (
                      match === "None" ? /* [] */0 : sourcesList
                    );
                  var value;
                  if (eventSources === /* [] */0) {
                    value = /* Unavailable */0;
                  } else {
                    var match$1 = property.absence;
                    if (match$1 !== undefined && typeof match$1 !== "number") {
                      if (match$1.TAG === /* SometimesSent */0) {
                        value = /* Sometimes */2;
                      } else {
                        var match$2 = Belt_MapString.get(match$1._0, $$event.id);
                        if (match$2 !== undefined) {
                          if (match$2.TAG === /* AllSources */0) {
                            value = match$2._0 ? /* Sometimes */2 : /* Never */1;
                          } else {
                            var sourceAbsences = match$2._0;
                            var states = Belt_List.map(eventSources, (function (source) {
                                    var match = Belt_MapString.get(sourceAbsences, source.id);
                                    if (match !== undefined) {
                                      if (match) {
                                        return /* Sometimes */2;
                                      } else {
                                        return /* Never */1;
                                      }
                                    } else {
                                      return /* Always */3;
                                    }
                                  }));
                            value = Belt_List.every(states, (function (state) {
                                    return state === /* Always */3;
                                  })) ? /* Always */3 : (
                                Belt_List.every(states, (function (state) {
                                        return state === /* Sometimes */2;
                                      })) ? /* Sometimes */2 : (
                                    Belt_List.every(states, (function (state) {
                                            return state === /* Never */1;
                                          })) ? /* Never */1 : Pervasives.failwith("Absence cannot be Mixed when the mode is Sometimes")
                                  )
                              );
                          }
                        } else {
                          value = /* Always */3;
                        }
                      }
                    } else {
                      value = /* Always */3;
                    }
                  }
                  tmp = React.createElement(Select.make, {
                        disabled: !available || !viewerCanEdit || value === /* Unavailable */0,
                        onSelect: (function (newState) {
                            var eventId = $$event.id;
                            var state = PropertyAbsenceViewHelpers.parseEventAndSourceStateString(newState);
                            var tmp;
                            if (state !== 0) {
                              switch (state) {
                                case /* Never */1 :
                                    tmp = /* NeverSent */0;
                                    break;
                                case /* Sometimes */2 :
                                    tmp = /* SometimesSent */{
                                      _0: ""
                                    };
                                    break;
                                case /* Always */3 :
                                    tmp = undefined;
                                    break;
                                
                              }
                            } else {
                              tmp = undefined;
                            }
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                      var match = property.absence;
                                      var tmp;
                                      tmp = match !== undefined && typeof match !== "number" ? (
                                          match.TAG === /* SometimesSent */0 ? "SometimesSent" : "DependingOnSource"
                                        ) : "AlwaysSent";
                                      AnalyticsRe.propertyConfigured(propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Presence", "UpdatePropertyPresenceByEvent", undefined, tmp, undefined, "DependingOnSource", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schema.schemaId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          property.id,
                                          {
                                            TAG: /* Event */0,
                                            _0: eventId
                                          },
                                          tmp
                                        ]
                                      },
                                      {
                                        eventId: eventId,
                                        eventQuery: eventId,
                                        propertyId: property.id,
                                        propertyQuery: property.id
                                      }
                                    ]]
                                ]);
                            if (state !== /* Always */3) {
                              return ;
                            }
                            var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
                            var simplestModeAfterChange = PropertyAbsenceViewHelpers.getSimplestModeForStateMatrix(Belt_MapString.remove(stateMatrix, eventId), property.absence);
                            if (simplestModeAfterChange === /* Always */0) {
                              return Curry._1(pickMode, (function (param) {
                                            return /* Always */0;
                                          }));
                            }
                            
                          }),
                        options: value === /* Unavailable */0 ? [[
                              {
                                NAME: "Label",
                                VAL: "Never sent"
                              },
                              PropertyAbsenceViewHelpers.getEventAndSourceStateString(/* Unavailable */0)
                            ]] : Belt_Array.concat([
                                [
                                  {
                                    NAME: "Label",
                                    VAL: "Always sent"
                                  },
                                  PropertyAbsenceViewHelpers.getEventAndSourceStateString(/* Always */3)
                                ],
                                [
                                  {
                                    NAME: "Label",
                                    VAL: "Sometimes sent"
                                  },
                                  PropertyAbsenceViewHelpers.getEventAndSourceStateString(/* Sometimes */2)
                                ]
                              ], value === /* Never */1 ? [[
                                    {
                                      NAME: "Label",
                                      VAL: "Never sent"
                                    },
                                    PropertyAbsenceViewHelpers.getEventAndSourceStateString(/* Never */1)
                                  ]] : []),
                        value: PropertyAbsenceViewHelpers.getEventAndSourceStateString(value)
                      });
                } else {
                  tmp = React.createElement($$Text.make, {
                        size: "Small",
                        singleLine: true,
                        color: Styles.Color.light10,
                        maxWidth: Css.px(200),
                        title: "Pinned to " + Belt_List.toArray(pinnedValues).join(", ") + "",
                        withWordBreak: false,
                        children: null
                      }, "Pinned to ", Belt_Array.map(Belt_List.toArray(pinnedValues), (function (prim) {
                              return prim;
                            })));
                }
                var tmp$1;
                if (viewerCanEdit) {
                  var tmp$2;
                  if (property.sendAs === /* SystemProperty */0) {
                    tmp$2 = [];
                  } else {
                    var tmp$3;
                    if (pinnedValues === /* [] */0) {
                      tmp$3 = "Pin Property Value";
                    } else {
                      var match$3 = AvoLimits.PinnedProperties.computeAvailability(workspace);
                      tmp$3 = "Update Pinned Value" + (
                        match$3 === "Available" ? "" : " ⚡️"
                      );
                    }
                    tmp$2 = [{
                        NAME: "Option",
                        VAL: {
                          label: tmp$3,
                          onClick: (function (param) {
                              var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                              Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                                          NAME: "PinPropertyValue",
                                          VAL: [
                                            $$event.id,
                                            property.id,
                                            undefined
                                          ]
                                        }) : ({
                                          NAME: "BillingPrompt",
                                          VAL: "PinnedProperties"
                                        })
                                  });
                            })
                        }
                      }];
                  }
                  tmp$1 = React.createElement(ContextMenu.make, {
                        options: Belt_Array.concatMany([
                              [{
                                  NAME: "WarningOption",
                                  VAL: {
                                    label: "Remove property from event",
                                    onClick: (function (param) {
                                        var eventId = $$event.id;
                                        var eventName = $$event.name;
                                        if (window.confirm("Are you sure you want to stop sending this property with the " + eventName + " event?")) {
                                          return Curry.app(sendActions, [
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      (function (branch) {
                                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                          var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                                          AnalyticsRe.propertyRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, eventId, eventName, "PropertyDetails", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                        }),
                                                      undefined,
                                                      [[
                                                          {
                                                            NAME: "RemovePropertyRefV3",
                                                            VAL: [
                                                              eventId,
                                                              property.id
                                                            ]
                                                          },
                                                          {
                                                            eventId: eventId,
                                                            eventQuery: eventId,
                                                            propertyId: property.id,
                                                            propertyQuery: property.id
                                                          }
                                                        ]]
                                                    ]);
                                        }
                                        
                                      })
                                  }
                                }],
                              tmp$2
                            ])
                      });
                } else {
                  tmp$1 = null;
                }
                return React.createElement("div", {
                            key: $$event.id,
                            className: listItemStyles
                          }, React.createElement("div", undefined, React.createElement(Link.make, {
                                    path: Router.Link.addDrawerItem(undefined, {
                                          NAME: "event",
                                          VAL: [
                                            $$event.id,
                                            undefined,
                                            undefined,
                                            false
                                          ]
                                        }),
                                    className: linkStyles,
                                    children: React.createElement($$Text.make, {
                                          size: "Medium",
                                          weight: "Semi",
                                          singleLine: true,
                                          color: Styles.Color.light12,
                                          maxWidth: Css.px(380),
                                          withWordBreak: false,
                                          children: $$event.name
                                        })
                                  })), React.createElement("div", {
                                className: listItemConfigStyles
                              }, available ? null : React.createElement("div", {
                                      className: planLightningStyles
                                    }, React.createElement(PlanLightning.make, {
                                          tooltipText: "Requires Plan Upgrade"
                                        })), tmp, React.createElement(Spacer.make, {
                                    width: 4
                                  }), tmp$1));
              })), maybePartialEvents$1 !== undefined ? React.createElement(PropertyDetailsEvents$RestRow, {
                partialItemsCount: allEventsLength$1 - Belt_List.length(maybePartialEvents$1) | 0,
                totalItemsCount: allEventsLength$1,
                showAllItems: showAllEvents,
                itemLabel: "event",
                onClick: (function (param) {
                    Curry._1(setShowAllEvents, (function (previous) {
                            return !previous;
                          }));
                  })
              }) : null);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("header", {
                  className: headerStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Sent In " + String(Belt_List.length(events)) + " " + (
                            Belt_List.length(events) === 1 ? "Event" : "Events"
                          ) + ""
                        }), React.createElement(Tooltip.WithAnalytics.make, {
                          fromItem: {
                            itemId: property.id,
                            itemName: property.name,
                            itemType: "Property"
                          },
                          title: "Sending the Property to Events",
                          children: React.createElement(Tooltip.Content.make, {
                                children: React.createElement(Markdown.make, {
                                      source: Tooltips.sentInEvents
                                    })
                              })
                        })), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Select.make, {
                      disabled: !viewerCanEdit,
                      onSelect: (function (modeString) {
                          var newMode = PropertyAbsenceViewHelpers.parseModeString(modeString);
                          if (!available && newMode === /* BySource */3) {
                            return Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "ConfigureEventsPerPlatform"
                                        }
                                      });
                          }
                          var absenceDefaultMode = PropertyAbsenceViewHelpers.getDefaultModeForAbsence(events, sourcesList, property.absence);
                          var confirmationCopy = "Switching to " + (PropertyAbsenceViewHelpers.getModeString(newMode) + " will cause your config to change. Are you sure you want to proceed?");
                          var stateMatrix = PropertyAbsenceViewHelpers.getFullStateMatrix(events, sourcesList, property.absence);
                          var showConfirm = !PropertyAbsenceViewHelpers.emptyOrEverythingSometimes(stateMatrix) && PropertyAbsenceViewHelpers.getMoreComplex(absenceDefaultMode, newMode) !== newMode;
                          var shouldProceed = showConfirm ? window.confirm(confirmationCopy) : true;
                          if (!shouldProceed) {
                            return ;
                          }
                          var exit = 0;
                          switch (computedMode) {
                            case /* Always */0 :
                                if (newMode !== 0) {
                                  switch (newMode) {
                                    case /* SometimesAll */1 :
                                        exit = 1;
                                        break;
                                    case /* SometimesByEvent */2 :
                                        exit = 2;
                                        break;
                                    case /* BySource */3 :
                                        break;
                                    
                                  }
                                }
                                break;
                            case /* SometimesAll */1 :
                                if (newMode < 3) {
                                  switch (newMode) {
                                    case /* Always */0 :
                                        exit = 3;
                                        break;
                                    case /* SometimesAll */1 :
                                        break;
                                    case /* SometimesByEvent */2 :
                                        exit = 2;
                                        break;
                                    
                                  }
                                }
                                break;
                            case /* SometimesByEvent */2 :
                                if (newMode !== 1) {
                                  if (newMode === 0) {
                                    exit = 3;
                                  }
                                  
                                } else {
                                  exit = 1;
                                }
                                break;
                            case /* BySource */3 :
                                switch (newMode) {
                                  case /* Always */0 :
                                      if (!Caml_obj.equal(property.absence, /* AlwaysSent */0)) {
                                        exit = 3;
                                      }
                                      break;
                                  case /* SometimesAll */1 :
                                      var match = property.absence;
                                      var tmp;
                                      tmp = match !== undefined && !(typeof match === "number" || match.TAG !== /* SometimesSent */0) ? true : false;
                                      if (!tmp) {
                                        exit = 1;
                                      }
                                      break;
                                  case /* SometimesByEvent */2 :
                                      exit = 2;
                                      break;
                                  case /* BySource */3 :
                                      break;
                                  
                                }
                                break;
                            
                          }
                          switch (exit) {
                            case 1 :
                                var id = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          var property$1 = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                          AnalyticsRe.propertyConfigured(property$1.propertyId, Belt_Option.getWithDefault(property$1.propertyName, ""), property$1.namingConvention, property$1.propertyType, property$1.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "SometimesSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id,
                                              /* All */0,
                                              /* SometimesSent */{
                                                _0: ""
                                              }
                                            ]
                                          },
                                          {
                                            propertyId: id,
                                            propertyQuery: id
                                          }
                                        ]]
                                    ]);
                                break;
                            case 2 :
                                var id$1 = property.id;
                                var absence = /* SometimesSent */{
                                  _0: ""
                                };
                                var $$event = Curry._2(TrackingPlanMappedModel.Events.getAtIndex, model.events, 0);
                                if ($$event !== undefined) {
                                  var actions = [
                                    [
                                      {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          id$1,
                                          /* All */0,
                                          absence
                                        ]
                                      },
                                      {
                                        propertyId: id$1,
                                        propertyQuery: id$1
                                      }
                                    ],
                                    [
                                      {
                                        NAME: "UpdatePropertyAbsence",
                                        VAL: [
                                          id$1,
                                          {
                                            TAG: /* Event */0,
                                            _0: $$event.id
                                          },
                                          absence
                                        ]
                                      },
                                      {
                                        propertyId: id$1,
                                        propertyQuery: id$1
                                      }
                                    ]
                                  ];
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                            var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                            AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "SometimesSentByEvent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                          }),
                                        undefined,
                                        actions
                                      ]);
                                }
                                break;
                            case 3 :
                                var id$2 = property.id;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                          AnalyticsRe.propertyConfigured(propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Presence", "UpdatePropertyPresenceGlobally", undefined, PropertyAbsenceViewHelpers.analyticsPresenceTypeBefore(computedMode), undefined, "AlwaysSent", undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdatePropertyAbsence",
                                            VAL: [
                                              id$2,
                                              /* All */0,
                                              undefined
                                            ]
                                          },
                                          {
                                            propertyId: id$2,
                                            propertyQuery: id$2
                                          }
                                        ]]
                                    ]);
                                break;
                            
                          }
                          Curry._1(pickMode, (function (param) {
                                  return newMode;
                                }));
                        }),
                      options: Belt_Array.map(PropertyAbsenceViewHelpers.modes, (function (mode) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: available || mode < 3 ? PropertyAbsenceViewHelpers.getModeString(mode) : "⚡️ " + PropertyAbsenceViewHelpers.getModeString(/* BySource */3)
                                      },
                                      PropertyAbsenceViewHelpers.getModeString(mode)
                                    ];
                            })),
                      value: PropertyAbsenceViewHelpers.getModeString(computedMode)
                    })), tmp);
}

var make = PropertyDetailsEvents;

export {
  make ,
}
/* containerStyles Not a pure module */
