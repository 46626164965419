// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoFormat from "./AvoFormat.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CodegenRules from "../../codegen/src/CodegenRules.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as CodegenCommons from "../../codegen/src/CodegenCommons.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as GetRegexValidationUseCase from "./regex/GetRegexValidationUseCase.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import * as ComputeUnionAllowedValuesUseCase from "../../model/src/eventSpecificPropertyValues/ComputeUnionAllowedValuesUseCase.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function formatDescription(description) {
  if (description.trim().length > 0) {
    return CodegenCommons.escapeDoubleQuotes(description);
  } else {
    return "no description";
  }
}

function printBasicInfo(textBuilder, property) {
  var description = formatDescription(property.description);
  AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "*    @Property " + property.name + ": " + description + ""));
}

function classifyPropertiesWithSystemPropsByEventAndProperties($$event, model, sourceId) {
  var propertyGroupProperties = AvoModel.resolvePropertyBundlesToProperties($$event, model);
  var eventProperties = AvoModel.resolveProperties(model, $$event.directPropertyRefs);
  var match = CodegenCommons.classifyProperties(BeltListExtensions.dedupeOrdered(Belt_List.concat(eventProperties, propertyGroupProperties), (function (property) {
              return property.id;
            })));
  var systemProperties = Belt_List.keepMap(AvoModel.getSystemProperties(model.properties), (function (property) {
          return AvoModel.keepIncludedSystemPropsOnSource(property, sourceId, model);
        }));
  return [
          Belt_List.toArray(match[0]),
          Belt_List.toArray(match[1]),
          Belt_List.toArray(match[2]),
          Belt_List.toArray(match[3]),
          Belt_List.toArray(systemProperties)
        ];
}

function remapString(nameMaps) {
  if (nameMaps.length === 0) {
    return "";
  }
  var list = Belt_Array.map(nameMaps, (function (param) {
            return "\"" + param[1] + "\" to " + Case.pascal(param[0]) + "";
          })).join(", ");
  return "// Sent as [" + list + "]";
}

function printPropertyTypeInPseudocode(property, optionalOverwrite, eventId, model, propertyNameMaps, sourceId, param) {
  var pinnedValue = Belt_Option.flatMap(Belt_Option.flatMap(eventId, (function (eventId) {
              return ModelUtils.getPropertyRefById(eventId, property.id, model);
            })), (function (propertyRef) {
          return CodegenCommons.printPinnedValueOption(propertyRef.pinnedValue);
        }));
  var eventSpecificAllowedPropertyValues;
  if (ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
    var allowedValues;
    if (eventId !== undefined) {
      if (property.sendAs === /* SystemProperty */0) {
        allowedValues = GetEventSpecificPropertyValuesUseCase.getAllowedValuesOnSourceForSystemProperty(property.eventSpecificAllowedPropertyValues, sourceId, model.sources);
      } else {
        var maybeEvent = ModelUtils.getEventByIdWithArchive(eventId, model);
        allowedValues = Belt_Option.mapWithDefault(maybeEvent, [], (function ($$event) {
                return GetEventSpecificPropertyValuesUseCase.getAllowedValuesOnSource(property.eventSpecificAllowedPropertyValues, $$event, sourceId);
              }));
      }
    } else {
      allowedValues = ComputeUnionAllowedValuesUseCase.computeWithSlowModel(model, [sourceId], property);
    }
    var allowedValueStrings = Belt_Array.map(allowedValues, (function (value) {
            return "\"" + value + "\"";
          }));
    eventSpecificAllowedPropertyValues = Caml_obj.equal(allowedValueStrings, []) ? /* [] */0 : ({
          hd: "allowed values: " + allowedValueStrings.join(", "),
          tl: /* [] */0
        });
  } else {
    eventSpecificAllowedPropertyValues = /* [] */0;
  }
  var hasRegex = GetRegexValidationUseCase.get(property) !== undefined;
  var validations = property.validations;
  var validations$1 = Belt_List.toArray(Belt_List.concat(validations ? Belt_List.keepMap(validations, (function (x) {
                      var variant = x.NAME;
                      if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
                        return ;
                      }
                      if (variant === "Regex") {
                        if (property.type_ !== "string") {
                          return ;
                        }
                        var match = x.VAL;
                        var eventOverrides = match.eventOverrides;
                        var propertyRule = match.propertyRule;
                        var match$1 = Belt_Option.flatMap(eventId, (function (param) {
                                return Belt_MapString.get(eventOverrides, param);
                              }));
                        if (match$1 !== undefined) {
                          return "should match regex: \"" + match$1.regex + "\"";
                        } else if (propertyRule !== undefined) {
                          return "should match regex: \"" + propertyRule.regex + "\"";
                        } else {
                          return ;
                        }
                      }
                      if (variant === "Max") {
                        var match$2 = x.VAL;
                        var variant$1 = match$2.NAME;
                        if (variant$1 === "StringLit" || variant$1 === "BooleanLit") {
                          return ;
                        } else if (variant$1 === "FloatLit") {
                          return "max " + String(match$2.VAL) + "";
                        } else {
                          return "max " + String(match$2.VAL) + "";
                        }
                      }
                      if (variant === "Min") {
                        var match$3 = x.VAL;
                        var variant$2 = match$3.NAME;
                        if (variant$2 === "StringLit" || variant$2 === "BooleanLit") {
                          return ;
                        } else if (variant$2 === "FloatLit") {
                          return "min " + String(match$3.VAL) + "";
                        } else {
                          return "min " + String(match$3.VAL) + "";
                        }
                      }
                      if (variant === "NestedProperty") {
                        var nestedPropertyRefs = x.VAL;
                        if (nestedPropertyRefs) {
                          return "{\n" + Belt_List.toArray(Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                                              var maybeNestedProperty = ModelUtils.resolvePropertyById(nestedPropertyRef.id, model);
                                              if (maybeNestedProperty === undefined) {
                                                return ;
                                              }
                                              var maybeValidPinnedValue = eventId !== undefined ? Belt_Option.map(CodegenRules.getNestedPropertyPinnedValue(nestedPropertyRef, property.id, eventId, model), (function (param) {
                                                        return PropertyUtils.printPinnedValue(param[0]) + (
                                                                param[1] === /* EventSpecific */0 ? " (on this event)" : " (on all events)"
                                                              );
                                                      })) : Belt_Option.flatMap(nestedPropertyRef.pinnedValue, (function (pinnedValue) {
                                                        if (ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, maybeNestedProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
                                                          return PropertyUtils.printPinnedValue(pinnedValue) + " (pinned)";
                                                        }
                                                        
                                                      }));
                                              var value = Belt_Option.getWithDefault(maybeValidPinnedValue, printPropertyTypeInPseudocode(maybeNestedProperty, maybeNestedProperty.optionalWhenInObject, undefined, model, propertyNameMaps, sourceId, undefined));
                                              var propertyNameMap = Belt_MapString.getWithDefault(propertyNameMaps, maybeNestedProperty.id, /* [] */0);
                                              if (!propertyNameMap) {
                                                return "    \"" + maybeNestedProperty.name + "\": " + value + ",";
                                              }
                                              var mainName = CodegenRules.getMainNameMappedName(maybeNestedProperty.name, propertyNameMap);
                                              var destinationSpecificMappings = CodegenRules.getDestinationSpecificRules(propertyNameMap);
                                              if (Caml_obj.equal(destinationSpecificMappings, [])) {
                                                return "    \"" + mainName + "\": " + value + ",";
                                              } else {
                                                return "    \"" + mainName + "\": " + value + ", " + remapString(destinationSpecificMappings) + "";
                                              }
                                            }))).join("\n") + "\n  }";
                        } else {
                          return ;
                        }
                      }
                      if (variant === "Shape") {
                        var items = x.VAL;
                        if (items) {
                          return "{" + Belt_List.toArray(Belt_List.map(items, (function (item) {
                                              return "" + item.key + ": " + PropertyUtils.printValue(item) + "";
                                            }))).join(", ") + "}";
                        } else {
                          return ;
                        }
                      }
                      if (ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues")) {
                        return ;
                      }
                      if (hasRegex) {
                        return ;
                      }
                      var stringMatches = Belt_List.toArray(Belt_List.keepMap(x.VAL, (function (param) {
                                  var match = param[0].VAL;
                                  if (typeof match === "object" && match.NAME === "StringLit" && Belt_List.every(param[1], (function (id) {
                                            return id !== sourceId;
                                          }))) {
                                    return "\"" + match.VAL + "\"";
                                  }
                                  
                                })));
                      if (Caml_obj.equal(stringMatches, [])) {
                        return ;
                      } else {
                        return "allowed values: " + stringMatches.join(", ");
                      }
                    })) : /* [] */0, hasRegex || property.type_ !== "string" ? /* [] */0 : eventSpecificAllowedPropertyValues)).join(" ");
  var optionality = optionalOverwrite !== undefined ? (
      optionalOverwrite ? "nullable " : ""
    ) : (
      eventId !== undefined ? (
          ModelUtils.isPropertyOptionalOnEventAndSource(property, eventId, sourceId, model) ? "nullable " : ""
        ) : Belt_Option.mapWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, "nullable", property, undefined), "", (function (string) {
                return "" + string + " ";
              }))
    );
  if (pinnedValue !== undefined) {
    return pinnedValue;
  } else {
    return "" + optionality + "" + (
            property.list ? "list of " : ""
          ) + "" + property.type_ + "" + (
            validations$1 === "" ? "" : " (" + validations$1 + ")"
          ) + "";
  }
}

var Utils = {
  formatDescription: formatDescription,
  printBasicInfo: printBasicInfo,
  classifyPropertiesWithSystemPropsByEventAndProperties: classifyPropertiesWithSystemPropsByEventAndProperties,
  remapString: remapString,
  printPropertyTypeInPseudocode: printPropertyTypeInPseudocode
};

function getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId) {
  var propertyTypeString = printPropertyTypeInPseudocode(property, undefined, $$event.id, model, propertyNameMaps, sourceId, undefined);
  var propertyNameMap = Belt_MapString.getWithDefault(propertyNameMaps, property.id, /* [] */0);
  if (propertyNameMap) {
    var mainName = CodegenRules.getMainNameMappedName(property.name, propertyNameMap);
    var destinationSpecificMappings = CodegenRules.getDestinationSpecificRules(propertyNameMap);
    if (Caml_obj.equal(destinationSpecificMappings, [])) {
      AvoFormat.addText(textBuilder, "  \"" + mainName + "\": " + propertyTypeString + ",");
    } else {
      AvoFormat.addText(textBuilder, "  \"" + mainName + "\": " + propertyTypeString + ", " + remapString(destinationSpecificMappings) + "");
    }
  } else {
    AvoFormat.addText(textBuilder, "  \"" + property.name + "\": " + propertyTypeString + ",");
  }
  AvoFormat.newLine(textBuilder);
}

function printPropertyDocComment(property, textBuilder) {
  printBasicInfo(textBuilder, property);
}

function printIdentify(destinations, textBuilder) {
  AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// The function name and signature can be different depending on SDK platform")), "" + destinations + ".identify(<userId>) "));
}

function printUnidentify(destinations, textBuilder) {
  AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// The function name and signature can be different depending on SDK platform")), "" + destinations + ".identify(null) "));
}

function printPageTrackCode(destinations, textBuilder) {
  AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// The function name and signature can be different depending on SDK platform")), "" + destinations + ".logPage(<pageName>) "));
}

function printRevenueTrackCode(destinations, textBuilder) {
  AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// The function name and signature can be different depending on SDK platform")), "" + destinations + ".logRevenue(<revenueAmount>) "));
}

function printSetUserPropsCodeAlternativeOps($$event, properties, model, destinationNames, propertyNameMaps, textBuilder, sourceId) {
  var printSetOnce = function (setOnceProps) {
    if (setOnceProps.length !== 0) {
      AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// This function can be different between SKD's, mixpanel: people.setOnce, amplitude: identify().setOnce")), "" + destinationNames + ".setOnce(<userId>, {"));
      Belt_Array.forEach(setOnceProps, (function (property) {
              getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
            }));
      return AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "})"));
    }
    
  };
  var printIncrement = function (incrementProperties) {
    if (incrementProperties.length !== 0) {
      AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "// Increment function can be different between SDK's, mixpanel: people.increment, amplitude: identify().add"));
      return Belt_Array.forEach(incrementProperties, (function (property) {
                    var propertyNameMap = Belt_MapString.getWithDefault(propertyNameMaps, property.id, /* [] */0);
                    if (propertyNameMap) {
                      var mainName = CodegenRules.getMainNameMappedName(property.name, propertyNameMap);
                      var destinationSpecificMappings = CodegenRules.getDestinationSpecificRules(propertyNameMap);
                      if (Caml_obj.equal(destinationSpecificMappings, [])) {
                        AvoFormat.addText(textBuilder, "" + destinationNames + ".increment(\"" + mainName + "\": 1)");
                      } else {
                        AvoFormat.addText(textBuilder, "" + destinationNames + ".increment(\"" + mainName + "\": 1) " + remapString(destinationSpecificMappings) + "");
                      }
                    } else {
                      AvoFormat.addText(textBuilder, "" + destinationNames + ".increment(\"" + property.name + "\": 1)");
                    }
                    AvoFormat.addText(textBuilder, "" + destinationNames + ".increment(\"" + property.name + "\", 1)");
                  }));
    }
    
  };
  printSetOnce(Belt_Array.keep(properties, (function (property) {
              return property.operation === "SetOnce";
            })));
  printIncrement(Belt_Array.keep(properties, (function (property) {
              return property.operation === "Increment";
            })));
  var appendProperties = Belt_Array.keep(properties, (function (property) {
          return property.operation === "Append";
        }));
  if (appendProperties.length !== 0) {
    AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "// Append function can be different between SDK's, Mixpanel: people.append, amplitude: identify().append")), "" + destinationNames + ".append(<userId>, {"));
    Belt_Array.forEach(appendProperties, (function (property) {
            getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
          }));
    return AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "})"));
  }
  
}

function printSetUserPropsCode($$event, properties, model, destinationNames, propertyNameMaps, textBuilder, sourceId) {
  if (properties.length !== 0) {
    AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "" + destinationNames + ".setUserProperties(<userId>, {"));
    Belt_Array.forEach(properties, (function (property) {
            getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
          }));
    return AvoFormat.newLine(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "})")));
  }
  
}

function printUpdateGroupCode($$event, properties, model, propertyNameMaps, destinationNames, textBuilder, sourceId) {
  var groupsToAssociateUserWith = AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes);
  Belt_Array.forEachU(groupsToAssociateUserWith, (function (userGroupType) {
          AvoFormat.newLine(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "" + destinationNames + ".addCurrentUserToGroup(\"" + ModelUtils.getGroupTypeNameWithArchiveWithDefaultGroupTypeId(userGroupType.id, model) + "\", " + CodegenCommons.Reason.groupTypeVarName("<", userGroupType.id, model) + "GroupId>)")));
        }));
  Belt_Array.forEachU(groupsToAssociateUserWith, (function (param) {
          var groupName = param.name;
          var groupId = param.id;
          var groupProperties = Belt_Array.keepMap(properties, (function (property) {
                  var id = property.sendAs;
                  if (typeof id === "number" || id._0 !== groupId) {
                    return ;
                  } else {
                    return property;
                  }
                }));
          if (groupProperties.length !== 0) {
            AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "" + destinationNames + ".setGroupProperties(\"" + groupName + "\", <" + groupName + "GroupId>, {"));
            Belt_Array.forEach(groupProperties, (function (property) {
                    getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
                  }));
            return AvoFormat.newLine(AvoFormat.newLineC(AvoFormat.addTextC(textBuilder, "})")));
          }
          
        }));
}

function printTrackCode($$event, properties, model, destinationNames, eventNameMaps, propertyNameMaps, textBuilder, sourceId) {
  var groupsToAssociateEventWith = AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes);
  var match = groupsToAssociateEventWith.length;
  if (match !== 0) {
    AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "" + destinationNames + ".trackEventWithGroups(\"" + $$event.name + "\", {"));
    Belt_Array.forEach(properties, (function (property) {
            getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
          }));
    AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "}, {"));
    Belt_Array.forEach(groupsToAssociateEventWith, (function (param) {
            var groupName = param.name;
            AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "  \"" + groupName + "\": " + groupName + "GroupId,"));
          }));
    return AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "})"));
  }
  var nameMappedEventName = remapString(eventNameMaps);
  if (nameMappedEventName !== "") {
    AvoFormat.addText(textBuilder, "let eventName = \"" + $$event.name + "\" " + nameMappedEventName + "");
  }
  AvoFormat.newLine(AvoFormat.addTextC(AvoFormat.newLineC(textBuilder), "" + destinationNames + ".track(" + (
            nameMappedEventName !== "" ? "eventName" : "\"" + $$event.name + "\""
          ) + ", {"));
  Belt_Array.forEach(properties, (function (property) {
          getPropertyLine($$event, property, model, propertyNameMaps, textBuilder, sourceId);
        }));
  AvoFormat.newLine(AvoFormat.addTextC(textBuilder, "})"));
}

function generateEventPseudoCode(enrichedModel, $$event, model, source) {
  var model$1 = enrichedModel !== undefined ? enrichedModel : AvoModel.enrichModelForCodegen(undefined, undefined, true, model, TrackingPlanModel.Source.makeValidWithDefaultValues(source, "Unknown Name", "js_v2", "Web"));
  var $$event$1 = Belt_Option.getWithDefault(Belt_List.getBy(model$1.events, (function (enrichedEvent) {
              return enrichedEvent.id === $$event.id;
            })), $$event);
  var sourceId = source.id;
  var match = classifyPropertiesWithSystemPropsByEventAndProperties($$event$1, model$1, sourceId);
  var trackProperties = Belt_Array.keepU(Belt_Array.concat(match[0], match[4]), (function (property) {
          return ModelUtils.isPropertyIncludedOnEventAndSources(true, property, $$event$1.id, {
                      hd: sourceId,
                      tl: /* [] */0
                    }, model$1, undefined);
        }));
  var destinationExists = function (destinationId) {
    return Belt_List.some(model$1.destinations, (function (destination) {
                  return destination.id === destinationId;
                }));
  };
  var destinationsSendingEventFromSource = Belt_List.keep(ModelUtils.getSourceDestinationIds(source.destinations), (function (destinationId) {
          if (destinationExists(destinationId)) {
            return Belt_List.some($$event$1.includeDestinations, (function (includedDestinationPerSource) {
                          var match = includedDestinationPerSource.VAL;
                          if (match[0] === source.id) {
                            return Belt_List.some(match[1], (function (id) {
                                          return id === destinationId;
                                        }));
                          } else {
                            return false;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var textBuilder = AvoFormat.newTextBuilder(undefined);
  var destinationsList = Belt_List.size(destinationsSendingEventFromSource) === 0 ? "analyticsSDK" : "[" + Belt_List.toArray(Belt_List.map(destinationsSendingEventFromSource, (function (destinationId) {
                  return Case.pascal(ModelUtils.getDestinationNameById(model$1, destinationId));
                }))).join(", ") + "]";
  var eventNameMaps = Belt_List.toArray(Belt_List.keepMap(destinationsSendingEventFromSource, (function (destinationId) {
              var newName = CodegenRules.getNameMappedNameForDestination(undefined, model$1, destinationId, $$event$1);
              if (newName === undefined) {
                return ;
              }
              var destinationName = ModelUtils.getDestinationNameById(model$1, destinationId);
              return [
                      destinationName,
                      newName
                    ];
            })));
  var propertyNameMaps = CodegenRules.getPropertyNameMaps(Belt_List.toArray(PropertyUtils.getAllPropertiesFromEvents((function (e) {
                  return e.id === $$event$1.id;
                }), model$1)), $$event$1.id, destinationsSendingEventFromSource, model$1);
  if (Belt_List.some($$event$1.types, (function (eventType) {
            return eventType === "Identify";
          }))) {
    printIdentify(destinationsList, textBuilder);
  }
  if (Belt_List.some($$event$1.types, (function (eventType) {
            return eventType === "Unidentify";
          }))) {
    printUnidentify(destinationsList, textBuilder);
  }
  var userPropertiesAlternativeOps = Belt_Array.keep(match[2], (function (property) {
          return ModelUtils.isPropertyIncludedOnEventAndSources(false, property, $$event$1.id, {
                      hd: sourceId,
                      tl: /* [] */0
                    }, model$1, undefined);
        }));
  printSetUserPropsCodeAlternativeOps($$event$1, userPropertiesAlternativeOps, model$1, destinationsList, propertyNameMaps, textBuilder, sourceId);
  var userPropertiesSet = Belt_Array.keep(match[1], (function (property) {
          return ModelUtils.isPropertyIncludedOnEventAndSources(false, property, $$event$1.id, {
                      hd: sourceId,
                      tl: /* [] */0
                    }, model$1, undefined);
        }));
  printSetUserPropsCode($$event$1, userPropertiesSet, model$1, destinationsList, propertyNameMaps, textBuilder, sourceId);
  var groupProperties = Belt_Array.keep(match[3], (function (property) {
          return ModelUtils.isPropertyIncludedOnEventAndSources(false, property, $$event$1.id, {
                      hd: sourceId,
                      tl: /* [] */0
                    }, model$1, undefined);
        }));
  printUpdateGroupCode($$event$1, groupProperties, model$1, propertyNameMaps, destinationsList, textBuilder, sourceId);
  if (!Belt_List.some($$event$1.types, (function (eventType) {
            return eventType === "SkipLogEvent";
          }))) {
    printTrackCode($$event$1, trackProperties, model$1, destinationsList, eventNameMaps, propertyNameMaps, textBuilder, sourceId);
  }
  if (Belt_List.some($$event$1.types, (function (eventType) {
            return eventType === "Page";
          }))) {
    printPageTrackCode(destinationsList, textBuilder);
  }
  if (Belt_List.some($$event$1.types, (function (eventType) {
            return eventType === "Revenue";
          }))) {
    printRevenueTrackCode(destinationsList, textBuilder);
  }
  return AvoFormat.buildText(textBuilder);
}

export {
  Utils ,
  getPropertyLine ,
  printPropertyDocComment ,
  printIdentify ,
  printUnidentify ,
  printPageTrackCode ,
  printRevenueTrackCode ,
  printSetUserPropsCodeAlternativeOps ,
  printSetUserPropsCode ,
  printUpdateGroupCode ,
  printTrackCode ,
  generateEventPseudoCode ,
}
/* Case Not a pure module */
