// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoModel from "../../../app/src/avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CodegenRules from "../../../codegen/src/CodegenRules.mjs";
import * as CodegenCommons from "../../../codegen/src/CodegenCommons.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../../app/src/BeltListExtensions.mjs";

function validateEventName(minOpt, max, eventName, destination) {
  var min = minOpt !== undefined ? minOpt : 1;
  if (eventName.length < min) {
    return {
            hd: {
              TAG: /* EventNameTooShort */2,
              eventName: eventName,
              lengthLimit: min,
              analyticsTool: destination
            },
            tl: /* [] */0
          };
  } else if (max !== undefined && eventName.length > max) {
    return {
            hd: {
              TAG: /* EventNameTooLong */1,
              eventName: eventName,
              lengthLimit: max,
              analyticsTool: destination
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function validatePropertyName(minOpt, max, propertyName, destination) {
  var min = minOpt !== undefined ? minOpt : 1;
  if (propertyName.length < min) {
    return {
            hd: {
              TAG: /* PropertyNameTooShort */4,
              propertyName: propertyName,
              lengthLimit: min,
              analyticsTool: destination
            },
            tl: /* [] */0
          };
  } else if (max !== undefined && propertyName.length > max) {
    return {
            hd: {
              TAG: /* PropertyNameTooLong */3,
              propertyName: propertyName,
              lengthLimit: max,
              analyticsTool: destination
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function validatePropertyNames(min, max, propertyNames, destination) {
  return BeltListExtensions.flatMapU(propertyNames, (function (propertyName) {
                return validatePropertyName(min, max, propertyName, destination);
              }));
}

function validateEvent(model, $$event, sourceId) {
  var destinations = AvoModel.resolveDestinations(model, Belt_List.flatten(Belt_List.keepMap($$event.includeDestinations, (function (x) {
                  var match = x.VAL;
                  if (match[0] === sourceId) {
                    return match[1];
                  }
                  
                }))));
  return BeltListExtensions.flatMapU(AvoConfig.getValidDestinations(Belt_List.keepU(model.sources, (function (source) {
                        return source.id === sourceId;
                      })), destinations), (function (destination) {
                var eventName = CodegenRules.remapEventName(model, destination.id, $$event);
                var properties = AvoModel.resolveProperties(model, $$event.directPropertyRefs);
                var eventProperties = Belt_List.mapU(Belt_List.keepU(properties, (function (property) {
                            return property.sendAs === /* EventProperty */1;
                          })), (function (property) {
                        return CodegenRules.remapPropertyName(model, destination.id, undefined, $$event.id, property);
                      }));
                var userProperties = Belt_List.mapU(Belt_List.keepU(properties, (function (property) {
                            return property.sendAs === /* UserProperty */2;
                          })), (function (property) {
                        return CodegenRules.remapPropertyName(model, destination.id, undefined, $$event.id, property);
                      }));
                var match = destination.type_;
                if (match === "Mixpanel") {
                  return Belt_List.concatMany([
                              validateEventName(undefined, 1024, eventName, "Mixpanel"),
                              validatePropertyNames(undefined, 255, eventProperties, "Mixpanel"),
                              validatePropertyNames(undefined, 255, userProperties, "Mixpanel")
                            ]);
                }
                if (match === "Amplitude") {
                  return Belt_List.concatMany([
                              validateEventName(undefined, 1024, eventName, "Amplitude"),
                              validatePropertyNames(undefined, 1024, eventProperties, "Amplitude"),
                              validatePropertyNames(undefined, 1024, userProperties, "Amplitude")
                            ]);
                }
                if (match === "FirebaseAnalytics") {
                  return Belt_List.concatMany([
                              validateEventName(undefined, 40, eventName, "FirebaseAnalytics"),
                              validatePropertyNames(undefined, 40, eventProperties, "FirebaseAnalytics"),
                              validatePropertyNames(undefined, 24, userProperties, "FirebaseAnalytics")
                            ]);
                }
                if (match !== "Snowplow") {
                  if (match === "FullStory") {
                    return Belt_List.concatMany([validateEventName(undefined, 250, eventName, "FullStory")]);
                  } else if (match === "FacebookAnalytics") {
                    return Belt_List.concatMany([validateEventName(2, 40, eventName, "FacebookAnalytics")]);
                  } else if (match === "MParticle") {
                    return Belt_List.concatMany([
                                validateEventName(undefined, 256, eventName, "MParticle"),
                                validatePropertyNames(undefined, 256, eventProperties, "MParticle"),
                                validatePropertyNames(undefined, 256, userProperties, "MParticle")
                              ]);
                  } else {
                    return /* [] */0;
                  }
                }
                if (Belt_List.some($$event.types, (function (eventAction) {
                          return eventAction === "SkipLogEvent";
                        }))) {
                  return /* [] */0;
                }
                var match$1 = CodegenCommons.classifyProperties(AvoModel.resolveProperties(model, $$event.directPropertyRefs));
                var propertiesWithRefs = CodegenCommons.enrichWithRef(match$1[0], $$event);
                var maybeSchemaPropertyWithRef = CodegenCommons.getSnowplowSchemaPropertyRef(propertiesWithRefs);
                if (maybeSchemaPropertyWithRef !== undefined) {
                  if (maybeSchemaPropertyWithRef[1].type_ !== "string") {
                    return {
                            hd: {
                              TAG: /* SnowplowSchemaPropertyIsNotOfTypeString */14,
                              eventName: eventName,
                              eventId: $$event.id
                            },
                            tl: /* [] */0
                          };
                  } else {
                    return /* [] */0;
                  }
                } else {
                  return {
                          hd: {
                            TAG: /* MissingSnowplowSchemaProperty */13,
                            eventName: eventName,
                            eventId: $$event.id
                          },
                          tl: /* [] */0
                        };
                }
              }));
}

function validateSourceDestinations(model, sourceId, source, destinations) {
  var match = ModelUtils.getSourceEvents(undefined, model, sourceId);
  var match$1 = Caml_obj.equal(source.platform, "JsonSchema");
  if (Belt_Option.isNone(source.language)) {
    return {
            hd: {
              TAG: /* SourceLanguageNotDefined */7,
              sourceId: sourceId,
              sourceName: AvoConfig.getSourceName(source)
            },
            tl: /* [] */0
          };
  }
  if (!match) {
    return /* [] */0;
  }
  if (!destinations && !match$1) {
    return {
            hd: {
              TAG: /* SourceNoDestinations */6,
              sourceId: sourceId,
              sourceName: AvoConfig.getSourceName(source)
            },
            tl: /* [] */0
          };
  }
  return BeltListExtensions.flatMap(destinations, (function (param) {
                var destinationMode = param[1];
                var destination = param[0];
                var match = destination.type_;
                if (match === undefined) {
                  return {
                          hd: {
                            TAG: /* DestinationMissingType */11,
                            destinationId: destination.id,
                            destinationName: ModelUtils.getDestinationName(destination)
                          },
                          tl: /* [] */0
                        };
                }
                if (match === "FirebaseAnalytics" || match === "Braze") {
                  return /* [] */0;
                }
                if (match === "MParticle" || match === "Snowplow" || match === "Custom") {
                  return /* [] */0;
                }
                if (!(destinationMode === /* AvoManaged */0 || destinationMode === /* Legacy */2)) {
                  return /* [] */0;
                }
                var match$1 = destination.apiKeys.dev;
                var match$2 = destination.apiKeys.prod;
                var exit = 0;
                if (match$1 !== undefined) {
                  if (match$1 === "") {
                    exit = match$2 !== undefined ? 2 : 1;
                  } else if (match$2 !== undefined) {
                    if (match$2 !== "") {
                      return /* [] */0;
                    }
                    exit = 3;
                  } else {
                    exit = 3;
                  }
                } else {
                  exit = match$2 !== undefined && match$2 !== "" ? 2 : 1;
                }
                switch (exit) {
                  case 1 :
                      return {
                              hd: {
                                TAG: /* DestinationMissingDevAndProdApiKey */10,
                                destinationId: destination.id,
                                destinationName: ModelUtils.getDestinationName(destination)
                              },
                              tl: /* [] */0
                            };
                  case 2 :
                      return {
                              hd: {
                                TAG: /* DestinationMissingDevApiKey */9,
                                destinationId: destination.id,
                                destinationName: ModelUtils.getDestinationName(destination)
                              },
                              tl: /* [] */0
                            };
                  case 3 :
                      return {
                              hd: {
                                TAG: /* DestinationMissingProdApiKey */8,
                                destinationId: destination.id,
                                destinationName: ModelUtils.getDestinationName(destination)
                              },
                              tl: /* [] */0
                            };
                  
                }
              }));
}

function doesSourceAndPropertyTypeNotSupportNonListObjectWithNestedNameMappingOrPinning(sourceLanguages, property) {
  var sendAs = property.sendAs;
  var isList = property.list;
  if (isList) {
    return false;
  } else {
    return Belt_Array.someU(sourceLanguages, (function (sourceLanguage) {
                  return !Belt_Array.someU(AvoConfig.codegenSupportsNameMappingAndPinningInObjects(undefined), (function (param) {
                                var isSupportedLanguage = param[0] === sourceLanguage;
                                var isSupportedPropertyType = Belt_Option.isSome(Belt_Array.getByU(param[1], (function (supportedSendAs) {
                                            return Caml_obj.equal(supportedSendAs, sendAs);
                                          })));
                                if (isSupportedLanguage) {
                                  return isSupportedPropertyType;
                                } else {
                                  return false;
                                }
                              }));
                }));
  }
}

function doesSourceAndPropertyTypeNotSupportNestedObjectsWithNameMappingOrPinning(sourceLanguages, sendAs) {
  return Belt_Array.someU(sourceLanguages, (function (sourceLanguage) {
                return !Belt_Array.someU(AvoConfig.codegenSupportsNameMappingAndPinningInNestedObjects(undefined), (function (param) {
                              var isSupportedLanguage = param[0] === sourceLanguage;
                              var isSupportedPropertyType = Belt_Option.isSome(Belt_Array.getByU(param[1], (function (supportedSendAs) {
                                          return Caml_obj.equal(supportedSendAs, sendAs);
                                        })));
                              if (isSupportedLanguage) {
                                return isSupportedPropertyType;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function doesSourceAndPropertyTypeNotSupportListObjectWithNestedNameMappingOrPinning(sourceLanguages, property) {
  var sendAs = property.sendAs;
  var isList = property.list;
  if (isList) {
    return Belt_Array.someU(sourceLanguages, (function (sourceLanguage) {
                  return !Belt_Array.someU(AvoConfig.codegenSupportsNameMappingAndPinningInListsOfObjects(undefined), (function (param) {
                                var isSupportedLanguage = param[0] === sourceLanguage;
                                var isSupportedPropertyType = Belt_Option.isSome(Belt_Array.getByU(param[1], (function (supportedSendAs) {
                                            return Caml_obj.equal(supportedSendAs, sendAs);
                                          })));
                                if (isSupportedLanguage) {
                                  return isSupportedPropertyType;
                                } else {
                                  return false;
                                }
                              }));
                }));
  } else {
    return false;
  }
}

function getMaybeNestedPropWithNameMapping(model, nestedPropRefs) {
  return Belt_Option.flatMapU(Belt_List.getByU(nestedPropRefs, (function (nestedPropRef) {
                    var propertyNameMappings = Belt_Array.keepMapU(Belt_List.toArray(ModelUtils.getPropertyNameMappingsOnAllEvents(model, nestedPropRef.id, undefined, undefined)), (function (rule) {
                            var match = rule.definition;
                            if (typeof match !== "object") {
                              return ;
                            }
                            if (match.NAME !== "NameMapping") {
                              return ;
                            }
                            var match$1 = match.VAL;
                            if (match$1.destination === undefined) {
                              return ;
                            }
                            var name = match$1.name;
                            if (name !== undefined) {
                              return name;
                            }
                            
                          }));
                    return propertyNameMappings.length !== 0;
                  })), (function (nestedPropRef) {
                return AvoModel.getPropertyById(model, nestedPropRef.id);
              }));
}

function getMaybeNestedObjectPropertyWithNestedNameMapping(model, nestedPropRefs) {
  var findNestedObjectPropertyWithNestedNameMapping = function (nestedPropRef) {
    var maybeNestedObjectProperty = Belt_Option.flatMapU(AvoModel.getPropertyById(model, nestedPropRef.id), (function (nestedProp) {
            var match = nestedProp.type_;
            if (match === "object") {
              return nestedProp;
            }
            
          }));
    if (maybeNestedObjectProperty === undefined) {
      return ;
    }
    var nestedPropRefs = BeltListExtensions.flatMap(maybeNestedObjectProperty.validations, (function (validation) {
            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
              return validation.VAL;
            } else {
              return /* [] */0;
            }
          }));
    var maybeNestedPropWithNameMapping = getMaybeNestedPropWithNameMapping(model, nestedPropRefs);
    if (maybeNestedPropWithNameMapping !== undefined) {
      return [
              maybeNestedObjectProperty,
              maybeNestedPropWithNameMapping
            ];
    }
    
  };
  return BeltListExtensions.findAndMap(nestedPropRefs, findNestedObjectPropertyWithNestedNameMapping);
}

function getMaybeNestedPropWithPinning(model, nestedPropRefs) {
  return Belt_Option.flatMapU(Belt_List.getByU(nestedPropRefs, (function (nestedPropRef) {
                    return nestedPropRef.pinnedValue !== undefined;
                  })), (function (nestedPropRef) {
                return AvoModel.getPropertyById(model, nestedPropRef.id);
              }));
}

function getMaybeNestedObjectPropertyWithNestedPining(model, nestedPropRefs) {
  var findNestedObjectPropertyWithNestedPinning = function (nestedPropRef) {
    var maybeNestedObjectProperty = Belt_Option.flatMapU(AvoModel.getPropertyById(model, nestedPropRef.id), (function (nestedProp) {
            var match = nestedProp.type_;
            if (match === "object") {
              return nestedProp;
            }
            
          }));
    if (maybeNestedObjectProperty === undefined) {
      return ;
    }
    var nestedPropRefs = BeltListExtensions.flatMap(maybeNestedObjectProperty.validations, (function (validation) {
            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
              return validation.VAL;
            } else {
              return /* [] */0;
            }
          }));
    var maybeNestedPropWithPinning = getMaybeNestedPropWithPinning(model, nestedPropRefs);
    if (maybeNestedPropWithPinning !== undefined) {
      return [
              maybeNestedObjectProperty,
              maybeNestedPropWithPinning
            ];
    }
    
  };
  return BeltListExtensions.findAndMap(nestedPropRefs, findNestedObjectPropertyWithNestedPinning);
}

function doesSourceAndPropertyTypeNotSupportObjects(sourceLanguages, sendAs) {
  return Belt_Array.someU(sourceLanguages, (function (sourceLanguage) {
                return !Belt_Array.someU(AvoConfig.codegenSupportsPlainObjects(undefined), (function (param) {
                              var isSupportedLanguage = param[0] === sourceLanguage;
                              var isSupportedPropertyType = Belt_Option.isSome(Belt_Array.getByU(param[1], (function (supportedSendAs) {
                                          return Caml_obj.equal(supportedSendAs, sendAs);
                                        })));
                              if (isSupportedLanguage) {
                                return isSupportedPropertyType;
                              } else {
                                return false;
                              }
                            }));
              }));
}

function validateObjectSupportForProperty(property, sources, model) {
  var isPropertyAnObjectWithNestedProps = property.type_ === "object" && Belt_List.someU(property.validations, (function (validation) {
          if (typeof validation === "object") {
            return validation.NAME === "NestedProperty";
          } else {
            return false;
          }
        }));
  if (isPropertyAnObjectWithNestedProps) {
    return Belt_Array.keepMapU(sources, (function (source) {
                  var language = source.language;
                  var sourceLanguages = language !== undefined ? [language] : [];
                  if (doesSourceAndPropertyTypeNotSupportObjects(sourceLanguages, property.sendAs)) {
                    return [
                            source,
                            {
                              TAG: /* ObjectPropertyNotSupported */12,
                              propertyName: property.name,
                              propertySendAs: property.sendAs,
                              propertyId: property.id
                            }
                          ];
                  }
                  if (doesSourceAndPropertyTypeNotSupportNonListObjectWithNestedNameMappingOrPinning(sourceLanguages, property)) {
                    var nestedPropRefs = BeltListExtensions.flatMap(property.validations, (function (validation) {
                            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                              return validation.VAL;
                            } else {
                              return /* [] */0;
                            }
                          }));
                    var maybeNestedPropWithNameMapping = getMaybeNestedPropWithNameMapping(model, nestedPropRefs);
                    if (maybeNestedPropWithNameMapping !== undefined) {
                      return [
                              source,
                              {
                                TAG: /* NameMappingInObjectsNotSupported */15,
                                propertyName: property.name,
                                propertySendAs: property.sendAs,
                                propertyId: property.id,
                                nestedPropertyName: maybeNestedPropWithNameMapping.name,
                                nestedPropertyId: maybeNestedPropWithNameMapping.id
                              }
                            ];
                    }
                    var maybeNestedPropWithPinning = getMaybeNestedPropWithPinning(model, nestedPropRefs);
                    return Belt_Option.mapU(maybeNestedPropWithPinning, (function (nestedPropWithPinning) {
                                  return [
                                          source,
                                          {
                                            TAG: /* PinningInObjectsNotSupported */16,
                                            propertyName: property.name,
                                            propertySendAs: property.sendAs,
                                            propertyId: property.id,
                                            nestedPropertyName: nestedPropWithPinning.name,
                                            nestedPropertyId: nestedPropWithPinning.id
                                          }
                                        ];
                                }));
                  }
                  if (doesSourceAndPropertyTypeNotSupportListObjectWithNestedNameMappingOrPinning(sourceLanguages, property)) {
                    var nestedPropRefs$1 = BeltListExtensions.flatMap(property.validations, (function (validation) {
                            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                              return validation.VAL;
                            } else {
                              return /* [] */0;
                            }
                          }));
                    var maybeNestedPropWithNameMapping$1 = getMaybeNestedPropWithNameMapping(model, nestedPropRefs$1);
                    if (maybeNestedPropWithNameMapping$1 !== undefined) {
                      return [
                              source,
                              {
                                TAG: /* NameMappingInListsOfObjectsNotSupported */17,
                                propertyName: property.name,
                                propertySendAs: property.sendAs,
                                propertyId: property.id,
                                nestedPropertyName: maybeNestedPropWithNameMapping$1.name,
                                nestedPropertyId: maybeNestedPropWithNameMapping$1.id
                              }
                            ];
                    }
                    var maybeNestedPropWithPinning$1 = getMaybeNestedPropWithPinning(model, nestedPropRefs$1);
                    return Belt_Option.mapU(maybeNestedPropWithPinning$1, (function (nestedPropWithPinning) {
                                  return [
                                          source,
                                          {
                                            TAG: /* PinningInListsOfObjectsNotSupported */18,
                                            propertyName: property.name,
                                            propertySendAs: property.sendAs,
                                            propertyId: property.id,
                                            nestedPropertyName: nestedPropWithPinning.name,
                                            nestedPropertyId: nestedPropWithPinning.id
                                          }
                                        ];
                                }));
                  }
                  if (!doesSourceAndPropertyTypeNotSupportNestedObjectsWithNameMappingOrPinning(sourceLanguages, property.sendAs)) {
                    return ;
                  }
                  var nestedPropRefs$2 = BeltListExtensions.flatMap(property.validations, (function (validation) {
                          if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                            return validation.VAL;
                          } else {
                            return /* [] */0;
                          }
                        }));
                  var maybeNestedObjectPropertyWithNestedNameMapping = getMaybeNestedObjectPropertyWithNestedNameMapping(model, nestedPropRefs$2);
                  if (maybeNestedObjectPropertyWithNestedNameMapping !== undefined) {
                    var nestedNestedPropWithNameMapping = maybeNestedObjectPropertyWithNestedNameMapping[1];
                    var nestedObjectProperty = maybeNestedObjectPropertyWithNestedNameMapping[0];
                    return [
                            source,
                            {
                              TAG: /* NameMappingInNestedObjectsNotSupported */19,
                              propertyName: property.name,
                              propertySendAs: property.sendAs,
                              propertyId: property.id,
                              nestedObjectPropertyName: nestedObjectProperty.name,
                              nestedObjectPropertyId: nestedObjectProperty.id,
                              nestedNestedNameMappedPropertyName: nestedNestedPropWithNameMapping.name,
                              nestedNestedNameMappedPropertyId: nestedNestedPropWithNameMapping.id
                            }
                          ];
                  }
                  var maybeNestedObjectPropertyWithNestedPining = getMaybeNestedObjectPropertyWithNestedPining(model, nestedPropRefs$2);
                  return Belt_Option.mapU(maybeNestedObjectPropertyWithNestedPining, (function (param) {
                                var nestedNestedPropWithPinning = param[1];
                                var nestedObjectProperty = param[0];
                                return [
                                        source,
                                        {
                                          TAG: /* PinningInNestedObjectsNotSupported */20,
                                          propertyName: property.name,
                                          propertySendAs: property.sendAs,
                                          propertyId: property.id,
                                          nestedObjectPropertyName: nestedObjectProperty.name,
                                          nestedObjectPropertyId: nestedObjectProperty.id,
                                          nestedNestedPinnedPropertyName: nestedNestedPropWithPinning.name,
                                          nestedNestedPinnedPropertyId: nestedNestedPropWithPinning.id
                                        }
                                      ];
                              }));
                }));
  } else {
    return [];
  }
}

function validatePropertyTypes(model, source) {
  return Belt_List.keepMapU(Curry._1(TrackingPlanModel.DedupeProperties.dedupe, Belt_List.flatten(Belt_List.mapU(ModelUtils.getSourceEvents(undefined, model, source.id), (function ($$event) {
                            return Belt_List.keepU(AvoModel.resolveProperties(model, $$event.directPropertyRefs), (function (property) {
                                          return ModelUtils.isPropertyIncludedOnEventAndSource(undefined, property, $$event.id, source.id, model)(undefined);
                                        }));
                          })))), (function (property) {
                return Belt_Option.mapU(Belt_Array.get(validateObjectSupportForProperty(property, [source], model), 0), (function (param) {
                              return param[1];
                            }));
              }));
}

function validateGroupAnalytics(model, source, destinations) {
  var eventNamesWithGroupAnalytics = Belt_Array.keepMapU(Belt_List.toArray(ModelUtils.getSourceEvents(undefined, model, source.id)), (function ($$event) {
          if (Caml_obj.notequal(AvoModel.resolveGroupTypes($$event.userGroupTypeIdsWithArchive, model.groupTypes), []) || Caml_obj.notequal(AvoModel.resolveGroupTypes($$event.eventGroupTypeIdsWithArchive, model.groupTypes), []) || Belt_List.someU(AvoModel.resolveProperties(model, $$event.directPropertyRefs), (function (prop) {
                    var match = prop.sendAs;
                    return typeof match === "number" ? false : true;
                  }))) {
            return $$event.name;
          }
          
        }));
  if (!Caml_obj.notequal(eventNamesWithGroupAnalytics, [])) {
    return /* [] */0;
  }
  var hasLanguagePlatformDestinationThatDoesNotSupportGroupAnalytics = Belt_List.someU(destinations, (function (param) {
          var match = source.language;
          var match$1 = source.platform;
          var match$2 = param[0].type_;
          if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
            return !AvoConfig.doesLanguagePlatformDestinationSupportGroups(match, match$1, match$2, param[1] === /* DestinationInterface */1);
          } else {
            return true;
          }
        }));
  if (hasLanguagePlatformDestinationThatDoesNotSupportGroupAnalytics) {
    return {
            hd: {
              TAG: /* GroupAnalyticsNotSupported */0,
              eventNames: eventNamesWithGroupAnalytics
            },
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function validateSource(model, sourceId) {
  return Belt_Option.mapWithDefault(ModelUtils.getSourceById(sourceId, model), {
              hd: {
                TAG: /* SourceNotFound */5,
                sourceId: sourceId
              },
              tl: /* [] */0
            }, (function (source) {
                var destinations = Belt_List.keepMapU(source.destinations, (function (param) {
                        var destination = ModelUtils.getDestinationById(param.destinationId, model);
                        if (destination !== undefined) {
                          return [
                                  destination,
                                  param.destinationMode
                                ];
                        }
                        
                      }));
                var destinationErrors = validateSourceDestinations(model, sourceId, source, destinations);
                var eventErrors = BeltListExtensions.flatMapU(ModelUtils.getSourceEvents(undefined, model, sourceId), (function ($$event) {
                        return validateEvent(model, $$event, sourceId);
                      }));
                var propertyErrors = validatePropertyTypes(model, source);
                var groupAnalyticsErrors = validateGroupAnalytics(model, source, destinations);
                return Belt_List.concatMany([
                            destinationErrors,
                            eventErrors,
                            propertyErrors,
                            groupAnalyticsErrors
                          ]);
              }));
}

function printErrors(schemaId, branchId, errors) {
  return Belt_List.toArray(Belt_List.mapU(errors, (function (error) {
                      var tmp;
                      switch (error.TAG | 0) {
                        case /* GroupAnalyticsNotSupported */0 :
                            tmp = "Group analytics defined in events [" + error.eventNames.join(", ") + "] is not currently supported and will be ignored, please contact us at support@avo.app if you want to use group analytics with this programming language, platform and analytics destination";
                            break;
                        case /* EventNameTooLong */1 :
                            var eventName = error.eventName;
                            var length = String(eventName.length);
                            var tool = AvoConfig.analyticsToolsLabel(error.analyticsTool);
                            tmp = "Event name exceeds length limit: The event name \"" + eventName + "\" is " + length + " characters but " + tool + " only supports event names up to " + String(error.lengthLimit) + " characters long.";
                            break;
                        case /* EventNameTooShort */2 :
                            var eventName$1 = error.eventName;
                            var length$1 = String(eventName$1.length);
                            var tool$1 = AvoConfig.analyticsToolsLabel(error.analyticsTool);
                            tmp = "Event name below minimum length: The event name \"" + eventName$1 + "\" is " + length$1 + " characters but " + tool$1 + " only supports event names that are at least " + String(error.lengthLimit) + " characters long.";
                            break;
                        case /* PropertyNameTooLong */3 :
                            var propertyName = error.propertyName;
                            var length$2 = String(propertyName.length);
                            var tool$2 = AvoConfig.analyticsToolsLabel(error.analyticsTool);
                            tmp = "Property name exceeds length limit: The property name \"" + propertyName + "\" is " + length$2 + " characters but " + tool$2 + " only supports property names up to " + String(error.lengthLimit) + " characters long.";
                            break;
                        case /* PropertyNameTooShort */4 :
                            var propertyName$1 = error.propertyName;
                            var length$3 = String(propertyName$1.length);
                            var tool$3 = AvoConfig.analyticsToolsLabel(error.analyticsTool);
                            tmp = "Property name below minimum length: The proprety name \"" + propertyName$1 + "\" is " + length$3 + " characters but " + tool$3 + " only supports property names that are at least " + String(error.lengthLimit) + " characters long.";
                            break;
                        case /* SourceNotFound */5 :
                            tmp = "Could not find a source in your Avo workspace with the id \"" + error.sourceId + "\". To configure your sources please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings";
                            break;
                        case /* SourceNoDestinations */6 :
                            tmp = "Source \"" + error.sourceName + "\" (" + error.sourceId + ") doesn't have any destinations configured. To add destinations please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings";
                            break;
                        case /* SourceLanguageNotDefined */7 :
                            var sourceId = error.sourceId;
                            tmp = "Source \"" + error.sourceName + "\" (" + sourceId + ") doesn't have programming language defined. To define the programming language please visit the \"Avo Codegen Setup\" tab at https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings/s/" + sourceId + "";
                            break;
                        case /* DestinationMissingProdApiKey */8 :
                            tmp = "Destination \"" + error.destinationName + "\" is missing a production API key. To add a production API key please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings/destinations/" + error.destinationId + "";
                            break;
                        case /* DestinationMissingDevApiKey */9 :
                            tmp = "Destination \"" + error.destinationName + "\" is missing a development API key. To add a development API key please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings/destinations/" + error.destinationId + "";
                            break;
                        case /* DestinationMissingDevAndProdApiKey */10 :
                            tmp = "Destination \"" + error.destinationName + "\" is missing API keys. To add API keys please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings/destinations/" + error.destinationId + "";
                            break;
                        case /* DestinationMissingType */11 :
                            tmp = "Incomplete setup for destination \"" + error.destinationName + "\". To complete setup or remove destination please visit https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/settings/destinations/" + error.destinationId + "";
                            break;
                        case /* ObjectPropertyNotSupported */12 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is of type 'object' which is currently not supported by this source type. Reach out to support@avo.app and we'll help you resolve this issue!";
                            break;
                        case /* MissingSnowplowSchemaProperty */13 :
                            tmp = "The event \"" + error.eventName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/events/" + error.eventId + ") is missing a pinned string property named \"schema\", required for all events sent to Snowplow.";
                            break;
                        case /* SnowplowSchemaPropertyIsNotOfTypeString */14 :
                            tmp = "The event \"" + error.eventName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/events/" + error.eventId + ") requires the pinned \"schema\" property to be of type string. Update the schema property type to ensure compatibility with Snowplow.";
                            break;
                        case /* NameMappingInObjectsNotSupported */15 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is an 'object' and has a nested property \"" + error.nestedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedPropertyId + ") with name mapping, which is currently not supported by this source type.";
                            break;
                        case /* PinningInObjectsNotSupported */16 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is an 'object' and has a nested property \"" + error.nestedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedPropertyId + ") with a pinned value, which is currently not supported by this source type.";
                            break;
                        case /* NameMappingInListsOfObjectsNotSupported */17 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is of type 'object' and has a nested property \"" + error.nestedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedPropertyId + ") that is a 'list' with name mapping. This feature is not currently supported by the source type.";
                            break;
                        case /* PinningInListsOfObjectsNotSupported */18 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is of type 'object' and has a nested property \"" + error.nestedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedPropertyId + ") that is a 'list' with a pinned value. This feature is not currently supported by the source type.";
                            break;
                        case /* NameMappingInNestedObjectsNotSupported */19 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is an 'object' and has a nested 'object' property \"" + error.nestedObjectPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedObjectPropertyId + ") with nested property \"" + error.nestedNestedNameMappedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedNestedNameMappedPropertyId + ") with name mapping. This feature is not currently supported by the source type.";
                            break;
                        case /* PinningInNestedObjectsNotSupported */20 :
                            tmp = "The property \"" + error.propertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.propertyId + ") is an 'object' and has a nested 'object' property \"" + error.nestedObjectPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedObjectPropertyId + ") with nested property \"" + error.nestedNestedPinnedPropertyName + "\" (https://www.avo.app/schemas/" + schemaId + "/branches/" + branchId + "/properties/" + error.nestedNestedPinnedPropertyId + ") with a pinned value. This feature is not currently supported by the source type.";
                            break;
                        
                      }
                      return "- " + tmp;
                    }))).join("\n");
}

export {
  validateEventName ,
  validatePropertyName ,
  validatePropertyNames ,
  validateEvent ,
  validateSourceDestinations ,
  doesSourceAndPropertyTypeNotSupportNonListObjectWithNestedNameMappingOrPinning ,
  doesSourceAndPropertyTypeNotSupportNestedObjectsWithNameMappingOrPinning ,
  doesSourceAndPropertyTypeNotSupportListObjectWithNestedNameMappingOrPinning ,
  getMaybeNestedPropWithNameMapping ,
  getMaybeNestedObjectPropertyWithNestedNameMapping ,
  getMaybeNestedPropWithPinning ,
  getMaybeNestedObjectPropertyWithNestedPining ,
  doesSourceAndPropertyTypeNotSupportObjects ,
  validateObjectSupportForProperty ,
  validatePropertyTypes ,
  validateGroupAnalytics ,
  validateSource ,
  printErrors ,
}
/* AvoModel Not a pure module */
