// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AvoEngagement from "../avoEngagement/AvoEngagement.mjs";

var defaultContext_engagement = AvoEngagement.Engagement.Container.empty;

function defaultContext_getStepNumber(param) {
  
}

var defaultContext_intent = AvoEngagement.Engagement.Intent.noIntent;

var defaultContext = {
  engagement: defaultContext_engagement,
  engagementId: "",
  getStepNumber: defaultContext_getStepNumber,
  intent: defaultContext_intent,
  offerReplayAtEnd: false,
  showStepCount: false,
  totalSteps: 0
};

var context = React.createContext(defaultContext);

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function AvoProductTour__TourProvider(Props) {
  var engagement = Props.engagement;
  var getStepNumber = Props.getStepNumber;
  var intent = Props.intent;
  var offerReplayAtEndOpt = Props.offerReplayAtEnd;
  var showStepCountOpt = Props.showStepCount;
  var totalStepsOverride = Props.totalStepsOverride;
  var children = Props.children;
  var offerReplayAtEnd = offerReplayAtEndOpt !== undefined ? offerReplayAtEndOpt : false;
  var showStepCount = showStepCountOpt !== undefined ? showStepCountOpt : false;
  return React.createElement(provider, {
              value: {
                engagement: engagement,
                engagementId: engagement.id,
                getStepNumber: getStepNumber,
                intent: intent,
                offerReplayAtEnd: offerReplayAtEnd,
                showStepCount: showStepCount,
                totalSteps: Belt_Option.getWithDefault(totalStepsOverride, Curry._1(AvoEngagement.Engagement.SortedBlocks.getOrderedBlocks, engagement).numSteps)
              },
              children: children
            });
}

var make = AvoProductTour__TourProvider;

export {
  defaultContext ,
  context ,
  provider ,
  use ,
  make ,
}
/* context Not a pure module */
