// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ImportModel from "./ImportModel.mjs";

var context = React.createContext({
      eventConflictInfo: undefined,
      importModel: ImportModel.empty,
      resolvedProperties: undefined
    });

var provider = context.Provider;

function use(param) {
  return React.useContext(context);
}

function ImportReview__Context(Props) {
  var eventConflictInfo = Props.eventConflictInfo;
  var importModel = Props.importModel;
  var resolvedProperties = Props.resolvedProperties;
  var children = Props.children;
  var value = React.useMemo((function () {
          return {
                  eventConflictInfo: eventConflictInfo,
                  importModel: importModel,
                  resolvedProperties: resolvedProperties
                };
        }), [
        eventConflictInfo,
        importModel,
        resolvedProperties
      ]);
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var make = ImportReview__Context;

export {
  context ,
  provider ,
  use ,
  make ,
}
/* context Not a pure module */
