// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Parser from "../../model/src/Parser.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TimeMachine from "./TimeMachine.mjs";
import * as BranchStatusModel from "../../shared/models/BranchStatusModel.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function FetchModel$Error(Props) {
  var message = Props.message;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icons.AvoLogo.make, {
                  width: 140,
                  fill: Styles.Color.light04
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(25), Css.px(30)),
                        tl: {
                          hd: Css.width(Css.px(500)),
                          tl: {
                            hd: Css.margin2(Css.px(50), "auto"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Huge",
                      weight: "Semi",
                      children: message
                    }), React.createElement(Spacer.make, {
                      height: 25
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      children: "If this issue persists please contact support in the chat bubble in the bottom right corner of the screen or at support@avo.app"
                    })));
}

var $$Error = {
  make: FetchModel$Error
};

function computeCurrentModelFromActions(model, actions) {
  return Belt_List.reduceU(actions, model, (function (nextModel, action) {
                return Actions.reduce(nextModel, action.contents);
              }));
}

function computeCurrentMappedModelFromActions(model, actions) {
  return TrackingPlanMappedModel.unboxModel(Belt_List.reduceU(actions, {
                  TAG: /* Mapped */1,
                  _0: model
                }, (function (nextModel, action) {
                    return Actions.reduceBoxed(nextModel, action.contents);
                  })));
}

function countActions(actions) {
  return Belt_List.reduceU(actions, 0, (function (sum, action) {
                var match = action.contents;
                if (typeof match !== "object") {
                  return sum + 1 | 0;
                }
                var variant = match.NAME;
                if (variant === "MergeBranch") {
                  return sum + Belt_List.length(match.VAL[2]) | 0;
                } else if (variant === "ImportDeprecated") {
                  return sum + Belt_List.length(match.VAL) | 0;
                } else {
                  return sum + 1 | 0;
                }
              }));
}

function use(beforeDate, beforeAndEqualsDate, modeOpt, branch) {
  var mode = modeOpt !== undefined ? modeOpt : "Fetch";
  var match = TimeMachine.use(undefined);
  var whenAmI = match.whenAmI;
  var beforeAndEqualsDate$1 = beforeAndEqualsDate !== undefined ? Caml_option.some(Caml_option.valFromOption(beforeAndEqualsDate)) : (
      beforeDate !== undefined || typeof whenAmI !== "object" ? undefined : Caml_option.some(whenAmI.VAL)
    );
  var schemaId = Router.Schema.getSchemaId(undefined);
  var branchId = typeof branch === "object" ? branch.VAL : "master";
  var currentBranchSnapshots = FirebaseFetcherHooks.useLatestVersion(schemaId, beforeDate, beforeAndEqualsDate$1, branchId, mode, undefined);
  var match$1 = mode === "Skip" ? (
      typeof currentBranchSnapshots === "object" && currentBranchSnapshots.NAME === "Loaded" ? [
          Belt_List.head(currentBranchSnapshots.VAL),
          "Skip"
        ] : [
          undefined,
          "Skip"
        ]
    ) : (
      typeof currentBranchSnapshots === "object" ? [
          Belt_List.head(currentBranchSnapshots.VAL),
          "Fetch"
        ] : [
          undefined,
          "Wait"
        ]
    );
  var match$2 = FirebaseFetcherHooks.useActions(match$1[0], schemaId, branchId, beforeDate, beforeAndEqualsDate$1, false, "TrackingPlanModelChange", match$1[1], undefined);
  var currentBranchActions = match$2[1];
  var currentBranchSnapshot = match$2[0];
  var branchSpecificFetchMode = typeof branch === "object" ? "Fetch" : "Skip";
  var match$3 = FirebaseFetcherHooks.useBranchLifecycleActions(schemaId, branchId, branchSpecificFetchMode, undefined);
  var setBranchStatusActions = match$3[3];
  var maybeCloseAction = match$3[2];
  var maybeMergeAction = match$3[1];
  var maybeBranchOpenAction = match$3[0];
  var branchStatus = React.useMemo((function () {
          if (maybeBranchOpenAction !== undefined) {
            return BranchStatusModel.get(Caml_option.valFromOption(maybeBranchOpenAction), maybeMergeAction, maybeCloseAction, setBranchStatusActions);
          } else {
            return /* Loading */1;
          }
        }), [
        maybeBranchOpenAction,
        maybeMergeAction,
        maybeCloseAction,
        setBranchStatusActions
      ]);
  var match$4 = typeof branch === "object" ? (
      currentBranchSnapshot !== undefined ? (
          typeof currentBranchActions === "object" && currentBranchActions.NAME === "Success" ? [
              Belt_List.head(currentBranchActions.VAL),
              "Skip"
            ] : [
              undefined,
              "Wait"
            ]
        ) : (
          typeof currentBranchActions === "object" && currentBranchActions.NAME === "Success" ? [
              Belt_List.head(currentBranchActions.VAL),
              "Fetch"
            ] : [
              undefined,
              "Wait"
            ]
        )
    ) : [
      undefined,
      "Skip"
    ];
  var masterSnapshotsFetchMode = match$4[1];
  var maybeFirstBranchActionCreatedAt = Belt_Option.flatMap(match$4[0], (function (firstBranchAction) {
          return Belt_Option.map(Caml_option.nullable_to_opt(firstBranchAction.createdAt), (function (prim) {
                        return prim.toDate();
                      }));
        }));
  var masterSnapshotsBeforeBranchOpen = FirebaseFetcherHooks.useLatestVersion(schemaId, maybeFirstBranchActionCreatedAt, undefined, undefined, masterSnapshotsFetchMode, undefined);
  var match$5 = typeof branch === "object" && masterSnapshotsFetchMode !== "Skip" ? (
      typeof masterSnapshotsBeforeBranchOpen === "object" ? [
          Belt_List.head(masterSnapshotsBeforeBranchOpen.VAL),
          "Fetch"
        ] : [
          undefined,
          "Wait"
        ]
    ) : [
      undefined,
      "Skip"
    ];
  var masterActionsFetchMode = match$5[1];
  var match$6 = FirebaseFetcherHooks.useActions(match$5[0], schemaId, "master", maybeFirstBranchActionCreatedAt, undefined, false, "TrackingPlanModelChange", masterActionsFetchMode, undefined);
  var masterActionsBeforeBranchOpen = match$6[1];
  var maybeSnapshot = typeof branch === "object" && currentBranchSnapshot === undefined ? match$6[0] : currentBranchSnapshot;
  var actions = typeof branch === "object" ? (
      currentBranchSnapshot !== undefined ? (
          typeof currentBranchActions === "object" ? currentBranchActions.VAL : /* [] */0
        ) : (
          typeof currentBranchActions === "object" && typeof masterActionsBeforeBranchOpen === "object" && masterActionsBeforeBranchOpen.NAME === "Success" ? Belt_List.concat(masterActionsBeforeBranchOpen.VAL, currentBranchActions.VAL) : /* [] */0
        )
    ) : (
      typeof currentBranchActions === "object" ? currentBranchActions.VAL : /* [] */0
    );
  var maybeSnapshotRef = React.useRef(undefined);
  var reducedLoadingStatus;
  if (typeof currentBranchSnapshots === "object" && currentBranchSnapshots.NAME === "Loaded" && typeof currentBranchActions === "object" && currentBranchActions.NAME === "Success") {
    var exit = 0;
    if (typeof masterSnapshotsBeforeBranchOpen === "object" && masterSnapshotsBeforeBranchOpen.NAME === "Loaded") {
      if (typeof masterActionsBeforeBranchOpen === "object") {
        if (masterActionsBeforeBranchOpen.NAME === "Success" || masterActionsFetchMode === "Skip") {
          reducedLoadingStatus = "Loaded";
        } else {
          exit = 1;
        }
      } else if (masterActionsFetchMode === "Skip") {
        reducedLoadingStatus = "Loaded";
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      reducedLoadingStatus = masterSnapshotsFetchMode === "Skip" ? (
          typeof masterActionsBeforeBranchOpen === "object" ? (
              masterActionsBeforeBranchOpen.NAME === "Success" || masterActionsFetchMode === "Skip" ? "Loaded" : "Loading"
            ) : (
              masterActionsFetchMode === "Skip" ? "Loaded" : "Loading"
            )
        ) : "Loading";
    }
    
  } else {
    reducedLoadingStatus = "Loading";
  }
  var match$7 = React.useState(function () {
        return [
                computeCurrentModelFromActions(TrackingPlanModel.empty, actions),
                computeCurrentMappedModelFromActions(TrackingPlanMappedModel.empty, actions),
                actions,
                false
              ];
      });
  var setState = match$7[1];
  var match$8 = match$7[0];
  var isReady = match$8[3];
  var actionsState = match$8[2];
  var mappedModelState = match$8[1];
  var modelState = match$8[0];
  var latestAction = Belt_List.head(Belt_List.reverse(actionsState));
  var actionsCount = countActions(actionsState);
  React.useEffect((function () {
          Curry._1(setState, (function (param) {
                  return [
                          TrackingPlanModel.empty,
                          TrackingPlanMappedModel.empty,
                          param[2],
                          param[3]
                        ];
                }));
        }), [whenAmI]);
  React.useEffect((function () {
          var isReady$1 = isReady || reducedLoadingStatus === "Loaded";
          var newActions = Belt_List.keepU(actions, (function (action) {
                  return !Belt_List.someU(actionsState, (function (retainedAction) {
                                return retainedAction.id === action.id;
                              }));
                }));
          var match = maybeSnapshotRef.current;
          var exit = 0;
          if (maybeSnapshot !== undefined) {
            var newVersion = Caml_option.valFromOption(maybeSnapshot);
            if (match !== undefined) {
              var version = Caml_option.valFromOption(match);
              var exit$1 = 0;
              if (newActions || newVersion.id !== version.id) {
                exit$1 = 2;
              } else {
                Curry._1(setState, (function (param) {
                        return [
                                param[0],
                                param[1],
                                actions,
                                isReady$1
                              ];
                      }));
              }
              if (exit$1 === 2) {
                if (newVersion.id !== version.id) {
                  maybeSnapshotRef.current = Caml_option.some(newVersion);
                  Curry._1(setState, (function (param) {
                          var parsedTrackingPlanModel = Parser.parseSchemaExn(newVersion.contents);
                          return [
                                  computeCurrentModelFromActions(parsedTrackingPlanModel, actions),
                                  computeCurrentMappedModelFromActions(TrackingPlanMappedModel.fromOld(parsedTrackingPlanModel), actions),
                                  actions,
                                  isReady$1
                                ];
                        }));
                } else {
                  exit = 1;
                }
              }
              
            } else {
              maybeSnapshotRef.current = Caml_option.some(newVersion);
              Curry._1(setState, (function (param) {
                      var parsedTrackingPlanModel = Parser.parseSchemaExn(newVersion.contents);
                      return [
                              computeCurrentModelFromActions(parsedTrackingPlanModel, actions),
                              computeCurrentMappedModelFromActions(TrackingPlanMappedModel.fromOld(parsedTrackingPlanModel), actions),
                              actions,
                              isReady$1
                            ];
                    }));
            }
          } else if (match !== undefined) {
            maybeSnapshotRef.current = undefined;
            Curry._1(setState, (function (param) {
                    return [
                            computeCurrentModelFromActions(TrackingPlanModel.empty, actions),
                            computeCurrentMappedModelFromActions(TrackingPlanMappedModel.empty, actions),
                            actions,
                            isReady$1
                          ];
                  }));
          } else if (newActions) {
            exit = 1;
          } else {
            Curry._1(setState, (function (param) {
                    return [
                            param[0],
                            param[1],
                            actions,
                            isReady$1
                          ];
                  }));
          }
          if (exit === 1) {
            Curry._1(setState, (function (param) {
                    return [
                            computeCurrentModelFromActions(param[0], newActions),
                            computeCurrentMappedModelFromActions(param[1], newActions),
                            actions,
                            isReady$1
                          ];
                  }));
          }
          
        }), [
        maybeSnapshot,
        JSON.stringify(Belt_List.toArray(Belt_List.mapU(actions, (function (action) {
                        return action.id + Belt_Option.getWithDefault(Belt_Option.map(Caml_option.nullable_to_opt(action.createdAt), (function (date) {
                                          return date.toDate().toISOString();
                                        })), "N/A");
                      })))),
        reducedLoadingStatus
      ]);
  if (typeof branch === "object") {
    if (typeof currentBranchSnapshots === "object") {
      if (typeof currentBranchActions === "object") {
        if (currentBranchSnapshot !== undefined) {
          if (isReady) {
            return {
                    TAG: /* Result */2,
                    _0: modelState,
                    _1: mappedModelState,
                    _2: latestAction,
                    _3: actionsCount,
                    _4: branchStatus
                  };
          } else {
            return {
                    TAG: /* Loading */0,
                    _0: "Preparing workspace..."
                  };
          }
        } else if (typeof masterSnapshotsBeforeBranchOpen === "object") {
          if (typeof masterActionsBeforeBranchOpen === "object") {
            if (isReady) {
              return {
                      TAG: /* Result */2,
                      _0: modelState,
                      _1: mappedModelState,
                      _2: latestAction,
                      _3: actionsCount,
                      _4: branchStatus
                    };
            } else {
              return {
                      TAG: /* Loading */0,
                      _0: "Preparing workspace..."
                    };
            }
          } else if (masterActionsBeforeBranchOpen === "Initial") {
            return {
                    TAG: /* Loading */0,
                    _0: "Loading main branch actions..."
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: "Oh noes, failed to fetch main branch actions."
                  };
          }
        } else if (masterSnapshotsBeforeBranchOpen === "Initial") {
          return {
                  TAG: /* Loading */0,
                  _0: "Loading latest main branch..."
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Oh noes! Error fetching schema versions"
                };
        }
      } else if (currentBranchActions === "Initial") {
        return {
                TAG: /* Loading */0,
                _0: "Loading branch actions..."
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Oh noes, failed to fetch branch actions."
              };
      }
    } else if (currentBranchSnapshots === "Initial") {
      return {
              TAG: /* Loading */0,
              _0: "Loading latest branch..."
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: "Oh noes, failed to fetch branch actions."
            };
    }
  } else if (typeof currentBranchSnapshots === "object") {
    if (typeof currentBranchActions === "object") {
      if (isReady) {
        return {
                TAG: /* Result */2,
                _0: modelState,
                _1: mappedModelState,
                _2: latestAction,
                _3: actionsCount,
                _4: /* Master */0
              };
      } else {
        return {
                TAG: /* Loading */0,
                _0: "Preparing workspace..."
              };
      }
    } else if (currentBranchActions === "Initial") {
      return {
              TAG: /* Loading */0,
              _0: "Loading main branch actions..."
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: "Oh noes, failed to fetch main branch actions."
            };
    }
  } else if (currentBranchSnapshots === "Initial") {
    return {
            TAG: /* Loading */0,
            _0: "Loading latest main branch..."
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Oh noes, failed to fetch snapshots."
          };
  }
}

export {
  $$Error ,
  computeCurrentModelFromActions ,
  computeCurrentMappedModelFromActions ,
  countActions ,
  use ,
}
/* Css Not a pure module */
