// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Zustand from "./Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              initialized: false,
              main: {
                model: TrackingPlanModel.empty,
                mappedModel: TrackingPlanMappedModel.empty,
                latestActionId: undefined,
                updateModels: (function (model, mappedModel, latestActionId) {
                    set(function (state) {
                          var init = state.main;
                          return {
                                  initialized: true,
                                  main: {
                                    model: model,
                                    mappedModel: mappedModel,
                                    latestActionId: latestActionId,
                                    updateModels: init.updateModels
                                  },
                                  currentBranch: state.currentBranch
                                };
                        });
                  })
              },
              currentBranch: {
                model: undefined,
                mappedModel: undefined,
                latestActionId: undefined,
                updateModels: (function (maybeModels, latestActionId) {
                    set(function (state) {
                          var init = state.currentBranch;
                          return {
                                  initialized: true,
                                  main: state.main,
                                  currentBranch: {
                                    model: Belt_Option.map(maybeModels, (function (prim) {
                                            return prim[0];
                                          })),
                                    mappedModel: Belt_Option.map(maybeModels, (function (prim) {
                                            return prim[1];
                                          })),
                                    latestActionId: latestActionId,
                                    updateModels: init.updateModels,
                                    diffInformation: init.diffInformation,
                                    updateDiffInformation: init.updateDiffInformation
                                  }
                                };
                        });
                  }),
                diffInformation: undefined,
                updateDiffInformation: (function (diffInformation) {
                    set(function (state) {
                          var init = state.currentBranch;
                          return {
                                  initialized: state.initialized,
                                  main: state.main,
                                  currentBranch: {
                                    model: init.model,
                                    mappedModel: init.mappedModel,
                                    latestActionId: init.latestActionId,
                                    updateModels: init.updateModels,
                                    diffInformation: diffInformation,
                                    updateDiffInformation: init.updateDiffInformation
                                  }
                                };
                        });
                  })
              }
            };
    });

function useIsOnBranch(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.currentBranch.model !== undefined;
              }));
}

function useIsInitialized(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.initialized;
              }));
}

function useModelIsInitialized(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.initialized;
              }));
}

function useUpdateMainModels(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.main.updateModels;
              }));
}

function useUpdateCurrentBranchModels(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.currentBranch.updateModels;
              }));
}

function use(param) {
  var branchModel = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentBranch.mappedModel;
        }));
  var mainModel = Curry._2(ZustandStore.use, store, (function (state) {
          return state.main.mappedModel;
        }));
  return React.useMemo((function () {
                if (branchModel !== undefined) {
                  return branchModel;
                } else {
                  return mainModel;
                }
              }), [
              branchModel,
              mainModel
            ]);
}

function useModelHelper(getItem) {
  var state = Curry._2(ZustandStore.use, store, (function (state) {
          return state;
        }));
  return React.useMemo((function () {
                var mappedModel = state.currentBranch.mappedModel;
                if (mappedModel !== undefined) {
                  return Curry._1(getItem, mappedModel);
                } else {
                  return Curry._1(getItem, state.main.mappedModel);
                }
              }), [
              state.currentBranch.mappedModel,
              state.main.mappedModel,
              getItem
            ]);
}

function useDestinations(param) {
  return useModelHelper(function (model) {
              return model.destinations;
            });
}

function useEvents(param) {
  return useModelHelper(function (model) {
              return model.events;
            });
}

function useCategories(param) {
  return useModelHelper(function (model) {
              return model.categories;
            });
}

function useGroupTypes(param) {
  return useModelHelper(function (model) {
              return model.groupTypes;
            });
}

function useIntegrations(param) {
  return useModelHelper(function (model) {
              return model.integrations;
            });
}

function useMetrics(param) {
  return useModelHelper(function (model) {
              return model.metrics;
            });
}

function useOpenBranches(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.main.model.openBranches;
              }));
}

function useProperties(param) {
  return useModelHelper(function (model) {
              return model.properties;
            });
}

function usePropertyBundles(param) {
  return useModelHelper(function (model) {
              return model.propertyBundles;
            });
}

function useSources(param) {
  return useModelHelper(function (model) {
              return model.sources;
            });
}

function useRules(param) {
  return useModelHelper(function (model) {
              return model.rules;
            });
}

function useMigrations(param) {
  return useModelHelper(function (model) {
              return model.migrations;
            });
}

function use$1(param) {
  return useModelHelper(function (model) {
              return model.globalRequirements;
            });
}

function useEvents$1(param) {
  return useModelHelper(function (model) {
              return Belt_Option.map(model.globalRequirements, (function (param) {
                            return param.events;
                          }));
            });
}

function useGlobalEvent(id) {
  var globalEvents = useEvents$1(undefined);
  return Belt_Option.flatMap(globalEvents, (function (globalEvents) {
                return Curry._2(TrackingPlanMappedModel.Events.get, globalEvents, id);
              }));
}

function useProperties$1(param) {
  return useModelHelper(function (model) {
              return Belt_Option.map(model.globalRequirements, (function (param) {
                            return param.properties;
                          }));
            });
}

function useGlobalProperty(id) {
  var globalProperties = useProperties$1(undefined);
  return Belt_Option.flatMap(globalProperties, (function (globalProperties) {
                return Curry._2(TrackingPlanMappedModel.Properties.get, globalProperties, id);
              }));
}

function usePropertyBundles$1(param) {
  return useModelHelper(function (model) {
              return Belt_Option.map(model.globalRequirements, (function (param) {
                            return param.propertyBundles;
                          }));
            });
}

function useGlobalPropertyBundle(id) {
  var globalPropertyBundles = usePropertyBundles$1(undefined);
  return Belt_Option.flatMap(globalPropertyBundles, (function (globalPropertyBundles) {
                return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, globalPropertyBundles, id);
              }));
}

function useEventVariants(param) {
  return useModelHelper(function (model) {
              return Belt_Option.map(model.globalRequirements, (function (param) {
                            return Curry._1(TrackingPlanMappedModel.EventVariants.fromArray, Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, param.events, (function (param) {
                                                  return param.variants;
                                                }))));
                          }));
            });
}

function useGlobalEventVariant(id) {
  var globalEventVariants = useEventVariants(undefined);
  return Belt_Option.flatMap(globalEventVariants, (function (globalEventVariants) {
                return Curry._2(TrackingPlanMappedModel.EventVariants.get, globalEventVariants, id);
              }));
}

var GlobalRequirements = {
  use: use$1,
  useEvents: useEvents$1,
  useGlobalEvent: useGlobalEvent,
  useProperties: useProperties$1,
  useGlobalProperty: useGlobalProperty,
  usePropertyBundles: usePropertyBundles$1,
  useGlobalPropertyBundle: useGlobalPropertyBundle,
  useEventVariants: useEventVariants,
  useGlobalEventVariant: useGlobalEventVariant
};

var Mapped = {
  use: use,
  useDestinations: useDestinations,
  useEvents: useEvents,
  useCategories: useCategories,
  useGroupTypes: useGroupTypes,
  useIntegrations: useIntegrations,
  useMetrics: useMetrics,
  useOpenBranches: useOpenBranches,
  useProperties: useProperties,
  usePropertyBundles: usePropertyBundles,
  useSources: useSources,
  useRules: useRules,
  useMigrations: useMigrations,
  GlobalRequirements: GlobalRequirements
};

function useModelHelper$1(getItem) {
  var branchModel = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentBranch.model;
        }));
  var mainModel = Curry._2(ZustandStore.use, store, (function (state) {
          return state.main.model;
        }));
  return React.useMemo((function () {
                if (branchModel !== undefined) {
                  return Curry._1(getItem, branchModel);
                } else {
                  return Curry._1(getItem, mainModel);
                }
              }), [
              branchModel,
              mainModel,
              getItem
            ]);
}

function useModel(param) {
  return useModelHelper$1(function (model) {
              return model;
            });
}

function useLatestActionId(param) {
  var branch = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentBranch;
        }));
  var main = Curry._2(ZustandStore.use, store, (function (state) {
          return state.main;
        }));
  return React.useMemo((function () {
                var match = branch.model;
                if (match !== undefined) {
                  return branch.latestActionId;
                } else {
                  return main.latestActionId;
                }
              }), [
              branch,
              main
            ]);
}

function useDestinations$1(param) {
  return useModelHelper$1(function (model) {
              return model.destinations;
            });
}

function useEvents$2(param) {
  return useModelHelper$1(function (model) {
              return model.events;
            });
}

function useGoals(param) {
  return useModelHelper$1(function (model) {
              return model.goals;
            });
}

function useGroupTypes$1(param) {
  return useModelHelper$1(function (model) {
              return model.groupTypes;
            });
}

function useIntegrations$1(param) {
  return useModelHelper$1(function (model) {
              return model.integrations;
            });
}

function useMetrics$1(param) {
  return useModelHelper$1(function (model) {
              return model.metrics;
            });
}

function useOpenBranches$1(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.main.model.openBranches;
              }));
}

function useProperties$2(param) {
  return useModelHelper$1(function (model) {
              return model.properties;
            });
}

function usePropertyBundles$2(param) {
  return useModelHelper$1(function (model) {
              return model.propertyBundles;
            });
}

function useRules$1(param) {
  return useModelHelper$1(function (model) {
              return model.rules;
            });
}

function useSources$1(param) {
  return useModelHelper$1(function (model) {
              return model.sources;
            });
}

function useMigrations$1(param) {
  return useModelHelper$1(function (model) {
              return model.migrations;
            });
}

function useFromAndToModels(param) {
  var diffInformation = Curry._2(ZustandStore.use, store, (function (state) {
          return state.currentBranch.diffInformation;
        }));
  return React.useMemo((function () {
                if (diffInformation !== undefined) {
                  return diffInformation.fromAndToModels;
                }
                
              }), [diffInformation]);
}

function useDiffInformation(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.currentBranch.diffInformation;
              }));
}

function useUpdateDiffInformation(param) {
  return Curry._2(ZustandStore.use, store, (function (state) {
                return state.currentBranch.updateDiffInformation;
              }));
}

export {
  StoreConfig ,
  ZustandStore ,
  store ,
  useIsOnBranch ,
  useIsInitialized ,
  useModelIsInitialized ,
  useUpdateMainModels ,
  useUpdateCurrentBranchModels ,
  Mapped ,
  useModel ,
  useLatestActionId ,
  useDestinations$1 as useDestinations,
  useEvents$2 as useEvents,
  useGoals ,
  useGroupTypes$1 as useGroupTypes,
  useIntegrations$1 as useIntegrations,
  useMetrics$1 as useMetrics,
  useOpenBranches$1 as useOpenBranches,
  useProperties$2 as useProperties,
  usePropertyBundles$2 as usePropertyBundles,
  useRules$1 as useRules,
  useSources$1 as useSources,
  useMigrations$1 as useMigrations,
  useFromAndToModels ,
  useDiffInformation ,
  useUpdateDiffInformation ,
}
/* ZustandStore Not a pure module */
