// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var removeProp = ((p, key) => {
  if (p.hasOwnProperty(key)) {
    const { ...newProps } = p
    delete newProps[key]
    return newProps;
  } else {
    return p;
  }
});

function removeProps(props, propsKeys) {
  return Belt_Array.reduceU(propsKeys, props, removeProp);
}

export {
  removeProp ,
  removeProps ,
}
/* No side effect */
