// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as WorkspaceMemberBillingUtils from "../../api/functions/src/WorkspaceMemberBillingUtils.mjs";

var legacyTeamDiscounted = {
  dev: {
    planId: "plan_EjyJGetpW2XZhV",
    productId: "prod_EwmgwJv9lGXh8W",
    codegenAccessPlanId: undefined
  },
  prod: {
    planId: "plan_ExVfP9qw7jrX82",
    productId: "prod_EwoUUSVKiOY2uU",
    codegenAccessPlanId: undefined
  },
  planBaseline: {
    priceInUsd: 0,
    userCount: 0
  },
  pricePerSeat: 15,
  pricePerCommentOnlySeat: 15,
  pricePerCodegenAccessSeat: 0,
  planName: undefined
};

var legacyTeam = {
  dev: {
    planId: "plan_FXm2KjUAOdnfp4",
    productId: "prod_EwoUUSVKiOY2uU",
    codegenAccessPlanId: undefined
  },
  prod: {
    planId: "plan_FXm2KjUAOdnfp4",
    productId: "prod_EwoUUSVKiOY2uU",
    codegenAccessPlanId: undefined
  },
  planBaseline: {
    priceInUsd: 0,
    userCount: 0
  },
  pricePerSeat: 29,
  pricePerCommentOnlySeat: 29,
  pricePerCodegenAccessSeat: 0,
  planName: undefined
};

var starter = {
  dev: {
    planId: "price_1ILtWQIp8ruBZxVd3IS0E6p7",
    productId: "prod_IxpAGnjDuKzJSp",
    codegenAccessPlanId: undefined
  },
  prod: {
    planId: "price_1IQFU5Ip8ruBZxVdAmBtLh2V",
    productId: "prod_J2K8YTveKRy2M2",
    codegenAccessPlanId: undefined
  },
  planBaseline: {
    priceInUsd: 300,
    userCount: 5
  },
  pricePerSeat: 40,
  pricePerCommentOnlySeat: 20,
  pricePerCodegenAccessSeat: 0,
  planName: /* Starter */7
};

var growth = {
  dev: {
    planId: "price_1IQFU5Ip8ruBZxVdAmBtLh2V",
    productId: "prod_HutDGZTxaiTKPr",
    codegenAccessPlanId: undefined
  },
  prod: {
    planId: "price_1IQFU5Ip8ruBZxVdAmBtLh2V",
    productId: "prod_HutDGZTxaiTKPr",
    codegenAccessPlanId: undefined
  },
  planBaseline: {
    priceInUsd: 2000,
    userCount: 30
  },
  pricePerSeat: 60,
  pricePerCommentOnlySeat: 0,
  pricePerCodegenAccessSeat: 0,
  planName: /* GrowthV2 */5
};

var teamV2 = {
  dev: {
    planId: "price_1JpuxsIp8ruBZxVdGd81v3JZ",
    productId: "prod_KUunMpaSeyaIco",
    codegenAccessPlanId: "price_1Lr689Ip8ruBZxVd4SR6yWM6"
  },
  prod: {
    planId: "price_1JrNLwIp8ruBZxVdFef8RxxY",
    productId: "prod_KWBZgusLY99ZKh",
    codegenAccessPlanId: "price_1N3LnPIp8ruBZxVdRoJv7BXm"
  },
  planBaseline: {
    priceInUsd: 300,
    userCount: 5
  },
  pricePerSeat: 60,
  pricePerCommentOnlySeat: 60,
  pricePerCodegenAccessSeat: 30,
  planName: /* TeamV2 */8
};

var teamV2Annual = {
  dev: {
    planId: "price_1KPDRmIp8ruBZxVdxNp0q31t",
    productId: "prod_KUunMpaSeyaIco",
    codegenAccessPlanId: "price_1Lr68yIp8ruBZxVdSIsC6scD"
  },
  prod: {
    planId: "price_1JrNODIp8ruBZxVdewiUqWWf",
    productId: "prod_KWBZgusLY99ZKh",
    codegenAccessPlanId: "price_1N3LnPIp8ruBZxVdCzZD2fHh"
  },
  planBaseline: {
    priceInUsd: 250,
    userCount: 5
  },
  pricePerSeat: 50,
  pricePerCommentOnlySeat: 50,
  pricePerCodegenAccessSeat: 25,
  planName: /* TeamV2Annual */9
};

var plans_1 = {
  hd: legacyTeam,
  tl: {
    hd: starter,
    tl: {
      hd: growth,
      tl: {
        hd: teamV2,
        tl: {
          hd: teamV2Annual,
          tl: /* [] */0
        }
      }
    }
  }
};

var plans = {
  hd: legacyTeamDiscounted,
  tl: plans_1
};

function getKnownPlanTypeFromId(planId) {
  return Belt_List.head(Belt_List.keepMap(plans, (function (plan) {
                    if (plan.dev.planId === planId || plan.prod.planId === planId) {
                      return plan.planName;
                    }
                    
                  })));
}

function getCodegenAccessPlanIdFromSeatPlanId(planId) {
  return Belt_List.head(Belt_List.keepMap(plans, (function (plan) {
                    if (plan.dev.planId === planId) {
                      return plan.dev.codegenAccessPlanId;
                    } else if (plan.prod.planId === planId) {
                      return plan.prod.codegenAccessPlanId;
                    } else {
                      return ;
                    }
                  })));
}

var StripePlans = {
  legacyTeamDiscounted: legacyTeamDiscounted,
  legacyTeam: legacyTeam,
  starter: starter,
  growth: growth,
  teamV2: teamV2,
  teamV2Annual: teamV2Annual,
  plans: plans,
  getKnownPlanTypeFromId: getKnownPlanTypeFromId,
  getCodegenAccessPlanIdFromSeatPlanId: getCodegenAccessPlanIdFromSeatPlanId
};

function planPrice(stripePlan, seatCount, codegenAccessCount) {
  var payingOverBaseline = Math.max(0, seatCount - stripePlan.planBaseline.userCount | 0);
  return (stripePlan.planBaseline.priceInUsd + Math.imul(payingOverBaseline, stripePlan.pricePerSeat) | 0) + Math.imul(codegenAccessCount, stripePlan.pricePerCodegenAccessSeat) | 0;
}

function getPlan(planId, isDev) {
  return Belt_List.getBy(plans, (function (stripePlan) {
                return (
                        isDev ? stripePlan.dev.planId : stripePlan.prod.planId
                      ) === planId;
              }));
}

function getPlanPrice(planId) {
  return Belt_Option.map(getPlan(planId, false), (function (stripePlan) {
                return [
                        stripePlan.pricePerSeat,
                        stripePlan.planBaseline
                      ];
              }));
}

function filterAvoMembers(members) {
  return Belt_Array.keep(members, (function (member) {
                return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(member.email), true, (function (email) {
                              if (email.endsWith("@avo.sh")) {
                                return false;
                              } else {
                                return !email.endsWith("@avo.app");
                              }
                            }));
              }));
}

function countPayingMembersForPlan(members, plan) {
  var members$1 = filterAvoMembers(members);
  var match = WorkspaceMemberBillingUtils.countPayingAndAdditionalWorkspaceMembers(members$1, {
        NAME: "Limited",
        VAL: plan.planBaseline.userCount
      }, Belt_Option.getWithDefault(Belt_Option.map(plan.planName, (function (planName) {
                  return Workspace.Instances.planFromPlanName(planName);
                })), Workspace.Instances.enterprise));
  var commentersOverBaseline = match.commentersOverBaseline;
  var editorsOverBaseline = match.editorsOverBaseline;
  var adminsOverBaseline = match.adminsOverBaseline;
  var totalPayingCodegenAccess = match.totalPayingCodegenAccess;
  var totalViewers = match.totalViewers;
  var amount = (((plan.planBaseline.priceInUsd + Math.imul(adminsOverBaseline, plan.pricePerSeat) | 0) + Math.imul(editorsOverBaseline, plan.pricePerSeat) | 0) + Math.imul(commentersOverBaseline, plan.pricePerCommentOnlySeat) | 0) + Math.imul(totalPayingCodegenAccess, plan.pricePerCodegenAccessSeat) | 0;
  return {
          totalPayingSeats: match.totalPayingSeats + totalPayingCodegenAccess | 0,
          totalPayingAdminsAndEditors: match.totalAdmins + match.totalEditors | 0,
          totalPayingCommenters: commentersOverBaseline,
          totalPayingCodegenAccess: totalPayingCodegenAccess,
          amount: amount,
          breakdown: Belt_List.concatMany([
                plan.planBaseline.userCount > 0 || plan.planBaseline.priceInUsd > 0 ? ({
                      hd: [
                        "Plan Base",
                        plan.planBaseline.userCount,
                        plan.planBaseline.priceInUsd
                      ],
                      tl: /* [] */0
                    }) : /* [] */0,
                adminsOverBaseline > 0 ? ({
                      hd: [
                        "Additional Admins",
                        adminsOverBaseline,
                        Math.imul(adminsOverBaseline, plan.pricePerSeat)
                      ],
                      tl: /* [] */0
                    }) : /* [] */0,
                editorsOverBaseline > 0 ? ({
                      hd: [
                        "Additional Editors",
                        editorsOverBaseline,
                        Math.imul(editorsOverBaseline, plan.pricePerSeat)
                      ],
                      tl: /* [] */0
                    }) : /* [] */0,
                commentersOverBaseline > 0 ? ({
                      hd: [
                        "Additional Commenters",
                        commentersOverBaseline,
                        Math.imul(commentersOverBaseline, plan.pricePerCommentOnlySeat)
                      ],
                      tl: /* [] */0
                    }) : /* [] */0,
                totalPayingCodegenAccess > 0 ? ({
                      hd: [
                        "Codegen Access",
                        totalPayingCodegenAccess,
                        Math.imul(totalPayingCodegenAccess, plan.pricePerCodegenAccessSeat)
                      ],
                      tl: /* [] */0
                    }) : /* [] */0,
                totalViewers > 0 ? ({
                      hd: [
                        "Viewers",
                        totalViewers,
                        0
                      ],
                      tl: /* [] */0
                    }) : /* [] */0
              ])
        };
}

function getPrimaryPlanFromPlanIds(planIds, isDev) {
  return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.map(planIds, (function (planId) {
                        return getPlan(planId, isDev);
                      })), 0), undefined);
}

function getWorkspacePlanFromSeatSubscriptionItem(seatSubscriptionItem) {
  var seatPlanId = seatSubscriptionItem.price.id;
  var maybeKnownWorkspacePlanName = getKnownPlanTypeFromId(seatPlanId);
  return Belt_Option.getWithDefault(Belt_Option.map(maybeKnownWorkspacePlanName, (function (planName) {
                    return Workspace.Instances.planFromPlanName(planName);
                  })), Workspace.Instances.enterprise);
}

var teamPlanPrice = 15;

var devStarterCommentOnlyPriceId = "price_1JPc3vIp8ruBZxVdNdWyteHu";

var starterCommentOnlyPriceId = "price_1JPc7jIp8ruBZxVdf3e4gvkv";

export {
  teamPlanPrice ,
  StripePlans ,
  devStarterCommentOnlyPriceId ,
  starterCommentOnlyPriceId ,
  planPrice ,
  getPlan ,
  getPlanPrice ,
  filterAvoMembers ,
  countPayingMembersForPlan ,
  getPrimaryPlanFromPlanIds ,
  getWorkspacePlanFromSeatSubscriptionItem ,
}
/* Workspace Not a pure module */
