// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var circle = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.pct(50.0)),
        tl: {
          hd: Css.top(Css.pct(50.0)),
          tl: {
            hd: Css.transforms({
                  hd: Css.translateX(Css.pct(-50.0)),
                  tl: {
                    hd: Css.translateY(Css.pct(-50.0)),
                    tl: /* [] */0
                  }
                }),
            tl: {
              hd: Css.width(Css.px(10)),
              tl: {
                hd: Css.height(Css.px(10)),
                tl: {
                  hd: Css.before({
                        hd: Css.unsafe("content", "''"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.width(Css.pct(600.0)),
                              tl: {
                                hd: Css.height(Css.pct(600.0)),
                                tl: {
                                  hd: Css.boxSizing("borderBox"),
                                  tl: {
                                    hd: Css.marginLeft(Css.pct(-250.0)),
                                    tl: {
                                      hd: Css.marginTop(Css.pct(-250.0)),
                                      tl: {
                                        hd: Css.borderRadius(Css.px(45)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.avoPinkShade2),
                                          tl: {
                                            hd: Css.animation({
                                                  NAME: "ms",
                                                  VAL: 2000.0
                                                }, undefined, undefined, {
                                                  NAME: "cubicBezier",
                                                  VAL: [
                                                    0.215,
                                                    0.61,
                                                    0.355,
                                                    1.0
                                                  ]
                                                }, undefined, undefined, "infinite", Curry._1(Css.keyframes, {
                                                      hd: [
                                                        0,
                                                        {
                                                          hd: Css.transform(Css.scale(0.33, 0.33)),
                                                          tl: /* [] */0
                                                        }
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          80,
                                                          {
                                                            hd: Css.opacity(0.0),
                                                            tl: /* [] */0
                                                          }
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            100,
                                                            {
                                                              hd: Css.opacity(0.0),
                                                              tl: /* [] */0
                                                            }
                                                          ],
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    })),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.after({
                          hd: Css.unsafe("content", "''"),
                          tl: {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.left("zero"),
                              tl: {
                                hd: Css.top("zero"),
                                tl: {
                                  hd: Css.display("block"),
                                  tl: {
                                    hd: Css.width(Css.pct(100.0)),
                                    tl: {
                                      hd: Css.height(Css.pct(100.0)),
                                      tl: {
                                        hd: Css.backgroundColor("transparent"),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(15)),
                                          tl: {
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(10), undefined, undefined, Css.rgba(0, 0, 0, {
                                                          NAME: "num",
                                                          VAL: 0.8
                                                        }))),
                                            tl: {
                                              hd: Css.animation({
                                                    NAME: "ms",
                                                    VAL: 2000.0
                                                  }, {
                                                    NAME: "ms",
                                                    VAL: -400.0
                                                  }, undefined, {
                                                    NAME: "cubicBezier",
                                                    VAL: [
                                                      0.455,
                                                      0.03,
                                                      0.515,
                                                      0.955
                                                    ]
                                                  }, undefined, undefined, "infinite", Curry._1(Css.keyframes, {
                                                        hd: [
                                                          0,
                                                          {
                                                            hd: Css.transform(Css.scale(0.7, 0.7)),
                                                            tl: /* [] */0
                                                          }
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            50,
                                                            {
                                                              hd: Css.transform(Css.scale(1.0, 1.0)),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.avoPink),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          ],
                                                          tl: {
                                                            hd: [
                                                              100,
                                                              {
                                                                hd: Css.transform(Css.scale(0.7, 0.7)),
                                                                tl: /* [] */0
                                                              }
                                                            ],
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function ProductTour__ClickSimulator(Props) {
  var posOpt = Props.pos;
  var pos = posOpt !== undefined ? posOpt : "center";
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.pointerEvents("none"),
                    tl: {
                      hd: Css.position("absolute"),
                      tl: {
                        hd: Css.width(Css.pct(100.0)),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("flexStart"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("absolute"),
                        tl: {
                          hd: Css.top(Css.pct(50.0)),
                          tl: {
                            hd: typeof pos === "object" ? (
                                pos.NAME === "right" ? Css.right(pos.VAL) : Css.left(pos.VAL)
                              ) : Css.left(Css.pct(50.0)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement("div", {
                      className: circle
                    })));
}

var make = ProductTour__ClickSimulator;

export {
  circle ,
  make ,
}
/* circle Not a pure module */
