// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as SourceIcon from "./SourceIcon.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function root(borderColor) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.padding4(Css.px(6), Css.px(5), Css.px(6), Css.px(15)),
                  tl: {
                    hd: Css.margin2(Css.px(5), Css.px(0)),
                    tl: {
                      hd: Css.border(Css.px(1), "solid", borderColor),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function pill(bgColor) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(2), Css.px(8)),
              tl: {
                hd: Css.backgroundColor(bgColor),
                tl: {
                  hd: Css.color(Styles.Color.white),
                  tl: {
                    hd: Css.borderRadius(Css.px(100)),
                    tl: {
                      hd: Css.margin2(Css.px(2), Css.px(2)),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SourceRowDiff(Props) {
  var source = Props.source;
  var colors = Props.colors;
  var actions = Props.actions;
  var branchModel = Props.branchModel;
  var primary = colors[0];
  var addedDestinations = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          if (x.NAME !== "IncludeDestinationInEventSource") {
            return ;
          }
          var match = x.VAL;
          if (source.id === match[1]) {
            return match[2];
          }
          
        }));
  var removedDestinations = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          if (x.NAME !== "ExcludeDestinationFromEventSource") {
            return ;
          }
          var match = x.VAL;
          if (source.id === match[1]) {
            return match[2];
          }
          
        }));
  var toggledIncludeInCodegen = Belt_List.keepMap(actions, (function (x) {
          if (typeof x !== "object") {
            return ;
          }
          var variant = x.NAME;
          if (variant === "ToggleIncludeEventInCodegenForSource") {
            var match = x.VAL;
            if (source.id !== match[1]) {
              return ;
            }
            var includedInCodegen = match[2];
            return [
                    !includedInCodegen,
                    includedInCodegen
                  ];
          }
          if (variant !== "IncludeEventInSourceV2") {
            return ;
          }
          var match$1 = x.VAL;
          if (source.id !== match$1[1]) {
            return ;
          }
          var includedInCodegen$1 = match$1[3];
          return [
                  includedInCodegen$1,
                  includedInCodegen$1
                ];
        }));
  var match = Belt_List.head(toggledIncludeInCodegen);
  return React.createElement("div", {
              className: root(colors[1])
            }, React.createElement(SourceIcon.make, {
                  source: source,
                  color: primary
                }), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Bold",
                  color: primary,
                  children: AvoConfig.getSourceName(source)
                }), React.createElement(Spacer.make, {
                  width: 8
                }), Belt_List.toArray(Belt_List.map(removedDestinations, (function (removedDestination) {
                        return React.createElement("div", {
                                    key: removedDestination,
                                    className: pill(Styles.Color.darkRed)
                                  }, Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getDestinationById(removedDestination, branchModel), ModelUtils.getDestinationName), ""));
                      }))), Belt_List.toArray(Belt_List.map(addedDestinations, (function (addedDestination) {
                        return React.createElement("div", {
                                    key: addedDestination,
                                    className: pill(Styles.Color.darkGreen)
                                  }, Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getDestinationById(addedDestination, branchModel), ModelUtils.getDestinationName), ""));
                      }))), React.createElement(Spacer.make, {
                  width: 8,
                  grow: 1
                }), match !== undefined ? React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.darkGreen,
                    children: JsDiff.renderStringDiff(undefined, true, false, match[0] ? "Included in Codegen" : "Not included in Codegen", match[1] ? "Included in Codegen" : "Not included in Codegen")
                  }) : null);
}

var make = SourceRowDiff;

export {
  make ,
}
/* Css Not a pure module */
