// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Metric from "./Metric.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function MetricsRow(Props) {
  var metric = Props.metric;
  var maybeOpenMetric = Props.maybeOpenMetric;
  var match = Router.getStaticRoute(undefined).branch;
  var demoId = match !== undefined && typeof match === "object" && match.NAME === "branch" && match.VAL.startsWith("demo-") && metric.name === "Detailed Onboarding funnel (Demo)" ? "new-workspace-demo-metrics-row" : undefined;
  var metricIsOpen = maybeOpenMetric !== undefined && typeof maybeOpenMetric === "object" && maybeOpenMetric.NAME === "metric" ? maybeOpenMetric.VAL[0] === metric.id : false;
  var tmp = {
    path: Router.Link.addDrawerItem(undefined, {
          NAME: "metric",
          VAL: [
            metric.id,
            undefined,
            false
          ]
        }),
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding2(Css.px(15), Css.px(25)),
              tl: {
                hd: Css.backgroundColor(metricIsOpen ? Styles.Color.light02 : Styles.Color.white),
                tl: {
                  hd: Css.color(metricIsOpen ? Styles.Color.deepBlue : Styles.Color.light12),
                  tl: {
                    hd: Css.hover({
                          hd: Css.backgroundColor(Styles.Color.light02),
                          tl: {
                            hd: Css.color(Styles.Color.darkBlue),
                            tl: /* [] */0
                          }
                        }),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }),
    children: null
  };
  if (demoId !== undefined) {
    tmp.id = Caml_option.valFromOption(demoId);
  }
  return React.createElement(Link.make, tmp, React.createElement(Metric.FunnelTypeIcon.make, {
                  type_: metric.type_
                }), React.createElement(Spacer.make, {
                  width: 12
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  children: metric.name === "" ? "Untitled Metric" : metric.name
                }));
}

var make = MetricsRow;

export {
  make ,
}
/* Css Not a pure module */
