// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SegmentAnalyticsJs from "../../app/src/externals/SegmentAnalyticsJs.mjs";
import * as DestinationInterfaceUtils from "../../shared/utils/DestinationInterfaceUtils.mjs";

function destination_make(_env, apiKey) {
  var apply = (function (apiKey) {
        if (typeof window === 'undefined') { console.warn('window.analytics is not available in Node.js'); return; }
        var analytics = window.analytics = window.analytics || [];
        if (analytics.initialize) return;
        if (analytics.invoked) {
          if (window.console && console.error) {
            console.error('Segment snippet included twice.');
          }
          return;
        }
        analytics.invoked = true;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware'
        ];
        analytics.factory = function(method){
          return function(){
            var args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            analytics.push(args);
            return analytics;
          };
        };
        for (var i = 0; i < analytics.methods.length; i++) {
          var key = analytics.methods[i];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function(key){
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = 'https://cdn.segment.com/analytics.js/v1/'
            + key + '/analytics.min.js';
          var first = document.getElementsByTagName('script')[0];
          first.parentNode.insertBefore(script, first);
        };
        analytics._writeKey = apiKey;
        analytics.SNIPPET_VERSION = '4.15.3';

        analytics.load(apiKey);
      });
  apply(apiKey);
}

var destination_logEvent = SegmentAnalyticsJs.track;

var destination_logPage = SegmentAnalyticsJs.page;

function destination_revenue(param, param$1) {
  
}

function destination_setUserProperties(_userId, userProperties) {
  SegmentAnalyticsJs.setUserProperties(DestinationInterfaceUtils.filterNoneFromJsonObject(userProperties));
}

var destination_identify = SegmentAnalyticsJs.identify;

function destination_unidentify(param) {
  SegmentAnalyticsJs.unidentify(undefined);
}

function destination_setGroupProperties(groupType, groupId, groupProperties) {
  var groupPropertiesWithGroupNameAndObjectTypeId = DestinationInterfaceUtils.filterNoneFromJsonObject(DestinationInterfaceUtils.appendGroupTypeAsObjectTypeIdField(DestinationInterfaceUtils.appendWorkspaceNameAsNameField(groupProperties), groupType));
  SegmentAnalyticsJs.group(groupId, groupPropertiesWithGroupNameAndObjectTypeId);
}

function destination_addCurrentUserToGroup(groupType, groupId, groupProperties) {
  var groupPropertiesWithGroupNameAndObjectTypeId = DestinationInterfaceUtils.filterNoneFromJsonObject(DestinationInterfaceUtils.appendGroupTypeAsObjectTypeIdField(DestinationInterfaceUtils.appendWorkspaceNameAsNameField(groupProperties), groupType));
  SegmentAnalyticsJs.group(groupId, groupPropertiesWithGroupNameAndObjectTypeId);
}

function destination_logEventWithGroups(eventName, eventProperties, _groupProperties) {
  SegmentAnalyticsJs.track(eventName, eventProperties);
}

var destination = {
  make: destination_make,
  logEvent: destination_logEvent,
  logPage: destination_logPage,
  revenue: destination_revenue,
  setUserProperties: destination_setUserProperties,
  identify: destination_identify,
  unidentify: destination_unidentify,
  setGroupProperties: destination_setGroupProperties,
  addCurrentUserToGroup: destination_addCurrentUserToGroup,
  logEventWithGroups: destination_logEventWithGroups
};

export {
  destination ,
}
/* No side effect */
