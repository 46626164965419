// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getBaseUrl(param) {
  return Caml_option.nullable_to_opt(import.meta.env.BASE_URL);
}

function getPath(assetPath) {
  var removeLeadingSlashRegex = new RegExp("^\/?");
  var removeTrailingSlashRegex = new RegExp("\/?$");
  var strippedAssetPath = assetPath.replace(removeLeadingSlashRegex, "").replace(removeTrailingSlashRegex, "");
  var baseUrl = import.meta.env.BASE_URL;
  if (baseUrl == null) {
    return "/" + strippedAssetPath;
  }
  var strippedBaseUrl = baseUrl.replace(removeLeadingSlashRegex, "").replace(removeTrailingSlashRegex, "");
  return "/" + strippedBaseUrl + "/" + strippedAssetPath + "";
}

export {
  getBaseUrl ,
  getPath ,
}
/* No side effect */
