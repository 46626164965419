// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as PortalTooltip from "../PortalTooltip.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as IconQuestionMark from "../IconQuestionMark.mjs";
import * as IntegrationInput from "./IntegrationInput.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as IntegrationAnalytics from "./IntegrationAnalytics.mjs";
import * as WebhookIntegrationConfig from "./WebhookIntegrationConfig.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: /* [] */0
        }
      }
    });

var rowStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginTop(Css.px(16)),
          tl: {
            hd: Css.marginBottom(Css.px(4)),
            tl: {
              hd: Css.marginLeft(Css.px(8)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function IntegrationConfig(Props) {
  var integrations = Props.integrations;
  var integration = Props.integration;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = integration.config;
  if (match === undefined) {
    return null;
  }
  switch (match.TAG | 0) {
    case /* Protocols */0 :
        var config = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "segment-workspace-slug",
                        value: Belt_Option.getWithDefault(config.workspace, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/segment-protocols",
                        onFlush: (function (workspace) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectName");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Protocols */0,
                                            _0: {
                                              accessKey: config.accessKey,
                                              trackingPlanId: config.trackingPlanId,
                                              workspace: workspace,
                                              usePublicApi: config.usePublicApi,
                                              euDataResidency: config.euDataResidency
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter workspace slug…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Segment Workspace Slug"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "protocols-tracking-plan-id",
                        value: Belt_Option.getWithDefault(config.trackingPlanId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/segment-protocols",
                        onFlush: (function (trackingPlanId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamUsername");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Protocols */0,
                                            _0: {
                                              accessKey: config.accessKey,
                                              trackingPlanId: trackingPlanId,
                                              workspace: config.workspace,
                                              usePublicApi: config.usePublicApi,
                                              euDataResidency: config.euDataResidency
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter id…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Protocols Tracking Plan ID"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Segment Access Token",
                        docsUrl: "https://www.avo.app/docs/publishing/segment-protocols",
                        hasValue: Belt_Option.isSome(config.accessKey),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* Protocols */0,
                                                          _0: {
                                                            accessKey: encryptedToken,
                                                            trackingPlanId: config.trackingPlanId,
                                                            workspace: config.workspace,
                                                            usePublicApi: config.usePublicApi,
                                                            euDataResidency: config.euDataResidency
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }), React.createElement("div", {
                        className: rowStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "EU Data Residency"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: config.euDataResidency && config.usePublicApi,
                            onChange: (function (euDataResidency) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamRegion");
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateIntegrationConfig",
                                            VAL: [
                                              integration.id,
                                              {
                                                TAG: /* Protocols */0,
                                                _0: {
                                                  accessKey: config.accessKey,
                                                  trackingPlanId: config.trackingPlanId,
                                                  workspace: config.workspace,
                                                  usePublicApi: config.usePublicApi,
                                                  euDataResidency: euDataResidency
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            integrationId: integration.id,
                                            integrationQuery: integration.id
                                          }
                                        ]]
                                    ]);
                              }),
                            size: "Small",
                            disabled: !config.usePublicApi
                          })), React.createElement("div", {
                        className: rowStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Use Public API"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: config.usePublicApi,
                            onChange: (function (usePublicApi) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "APIType");
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateIntegrationConfig",
                                            VAL: [
                                              integration.id,
                                              {
                                                TAG: /* Protocols */0,
                                                _0: {
                                                  accessKey: config.accessKey,
                                                  trackingPlanId: config.trackingPlanId,
                                                  workspace: config.workspace,
                                                  usePublicApi: usePublicApi,
                                                  euDataResidency: config.euDataResidency
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            integrationId: integration.id,
                                            integrationQuery: integration.id
                                          }
                                        ]]
                                    ]);
                              }),
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding(Css.px(4)),
                                                  tl: {
                                                    hd: Css.paddingTop(Css.px(6)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.white,
                                                maxWidth: Css.px(250),
                                                children: "Turn this on if you are using Segment public API, turn this off if you are using legacy Segment config API"
                                              }));
                              }),
                            position: "Right",
                            children: React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(IconQuestionMark.make, {
                                      color: Styles.Color.light10
                                    }))
                          })));
    case /* Taxonomy */1 :
        var config$1 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "taxonomy-api-key",
                        value: Belt_Option.getWithDefault(config$1.apiKey, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/amplitude-govern",
                        onFlush: (function (apiKey) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Taxonomy */1,
                                            _0: {
                                              apiKey: apiKey,
                                              secretKey: config$1.secretKey,
                                              euDataResidency: config$1.euDataResidency
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter API key...",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Amplitude API Key"
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(12)),
                              tl: /* [] */0
                            })
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Amplitude Secret Key",
                        docsUrl: "https://www.avo.app/docs/publishing/amplitude-govern",
                        hasValue: Belt_Option.isSome(config$1.secretKey),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* Taxonomy */1,
                                                          _0: {
                                                            apiKey: config$1.apiKey,
                                                            secretKey: encryptedToken,
                                                            euDataResidency: config$1.euDataResidency
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }), React.createElement("div", {
                        className: rowStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "EU Data Residency"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: config$1.euDataResidency,
                            onChange: (function (euDataResidency) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamRegion");
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateIntegrationConfig",
                                            VAL: [
                                              integration.id,
                                              {
                                                TAG: /* Taxonomy */1,
                                                _0: {
                                                  apiKey: config$1.apiKey,
                                                  secretKey: config$1.secretKey,
                                                  euDataResidency: euDataResidency
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            integrationId: integration.id,
                                            integrationQuery: integration.id
                                          }
                                        ]]
                                    ]);
                              }),
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding(Css.px(4)),
                                                  tl: {
                                                    hd: Css.paddingTop(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.white,
                                                maxWidth: Css.px(250),
                                                children: "Turn this on to publish to the Amplitude EU servers. Only turn on if you opted to store your data in the EU when you created your Amplitude organisation"
                                              }));
                              }),
                            position: "Right",
                            children: React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }),
                                  href: "https://www.avo.app/docs/publishing/amplitude-govern",
                                  rel: "noopener",
                                  target: "_blank"
                                }, React.createElement(IconQuestionMark.make, {
                                      color: Styles.Color.light10
                                    }))
                          })));
    case /* Lexicon */2 :
        var config$2 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "mixpanel-project-id",
                        value: Belt_Option.getWithDefault(config$2.projectId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                        onFlush: (function (projectId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectID");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Lexicon */2,
                                            _0: {
                                              userName: config$2.userName,
                                              secretKey: config$2.secretKey,
                                              projectId: projectId,
                                              euDataResidency: config$2.euDataResidency,
                                              categoriesAsTags: config$2.categoriesAsTags
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter id…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Mixpanel Project Id"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "mixpanel-service-account-username",
                        value: Belt_Option.getWithDefault(config$2.userName, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                        onFlush: (function (userName) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamUsername");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Lexicon */2,
                                            _0: {
                                              userName: userName,
                                              secretKey: config$2.secretKey,
                                              projectId: config$2.projectId,
                                              euDataResidency: config$2.euDataResidency,
                                              categoriesAsTags: config$2.categoriesAsTags
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter username…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Mixpanel Service Account Username"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Mixpanel Service Account Secret",
                        docsUrl: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                        hasValue: Belt_Option.isSome(config$2.secretKey),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* Lexicon */2,
                                                          _0: {
                                                            userName: config$2.userName,
                                                            secretKey: encryptedToken,
                                                            projectId: config$2.projectId,
                                                            euDataResidency: config$2.euDataResidency,
                                                            categoriesAsTags: config$2.categoriesAsTags
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }), React.createElement("div", {
                        className: rowStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "EU Data Residency"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: config$2.euDataResidency,
                            onChange: (function (euDataResidency) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamRegion");
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateIntegrationConfig",
                                            VAL: [
                                              integration.id,
                                              {
                                                TAG: /* Lexicon */2,
                                                _0: {
                                                  userName: config$2.userName,
                                                  secretKey: config$2.secretKey,
                                                  projectId: config$2.projectId,
                                                  euDataResidency: euDataResidency,
                                                  categoriesAsTags: config$2.categoriesAsTags
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            integrationId: integration.id,
                                            integrationQuery: integration.id
                                          }
                                        ]]
                                    ]);
                              }),
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding(Css.px(4)),
                                                  tl: {
                                                    hd: Css.paddingTop(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.white,
                                                maxWidth: Css.px(250),
                                                children: "Turn this on if you opted to store your data in the EU when you created your Mixpanel project"
                                              }));
                              }),
                            position: "Right",
                            children: React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }),
                                  href: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                                  rel: "noopener",
                                  target: "_blank"
                                }, React.createElement(IconQuestionMark.make, {
                                      color: Styles.Color.light10
                                    }))
                          })), React.createElement("div", {
                        className: rowStyles
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "Publish categories as tags"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: config$2.categoriesAsTags,
                            onChange: (function (categoriesAsTags) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "CategoriesAsTags");
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateIntegrationConfig",
                                            VAL: [
                                              integration.id,
                                              {
                                                TAG: /* Lexicon */2,
                                                _0: {
                                                  userName: config$2.userName,
                                                  secretKey: config$2.secretKey,
                                                  projectId: config$2.projectId,
                                                  euDataResidency: config$2.euDataResidency,
                                                  categoriesAsTags: categoriesAsTags
                                                }
                                              }
                                            ]
                                          },
                                          {
                                            integrationId: integration.id,
                                            integrationQuery: integration.id
                                          }
                                        ]]
                                    ]);
                              }),
                            size: "Small"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(PortalTooltip.make, {
                            renderTooltip: (function (param) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.padding(Css.px(4)),
                                                  tl: {
                                                    hd: Css.paddingTop(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                weight: "Semi",
                                                color: Styles.Color.white,
                                                maxWidth: Css.px(250),
                                                children: "Lexicon doesn't have support for categories but you can enable sending the categories an event belongs to as tags to Lexicon. They will be on the format \"Category: <category>\". If the event has tags, the categories will be added along with the tags."
                                              }));
                              }),
                            position: "Right",
                            children: React.createElement("a", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      }),
                                  href: "https://www.avo.app/docs/publishing/mixpanel-lexicon",
                                  rel: "noopener",
                                  target: "_blank"
                                }, React.createElement(IconQuestionMark.make, {
                                      color: Styles.Color.light10
                                    }))
                          })));
    case /* Webhook */3 :
        return React.createElement(WebhookIntegrationConfig.make, {
                    sendActions: sendActions,
                    integrations: integrations,
                    globalSend: globalSend,
                    integration: integration,
                    schemaBundle: schemaBundle,
                    config: match._0,
                    userIsEditor: role === "Admin" || role === "Editor"
                  });
    case /* SnowplowDataStructures */4 :
        var config$3 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "snowplow-organization-id",
                        value: Belt_Option.getWithDefault(config$3.organizationId, ""),
                        onFlush: (function (organizationId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectID");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* SnowplowDataStructures */4,
                                            _0: {
                                              accessToken: config$3.accessToken,
                                              organizationId: organizationId,
                                              vendor: config$3.vendor
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter Organization Id…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Snowplow Organization ID"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "snowplow-vendor",
                        value: Belt_Option.getWithDefault(config$3.vendor, ""),
                        onFlush: (function (vendor) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamUsername");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* SnowplowDataStructures */4,
                                            _0: {
                                              accessToken: config$3.accessToken,
                                              organizationId: config$3.organizationId,
                                              vendor: vendor
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter Vendor…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Snowplow Vendor Name"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Snowplow Access Token",
                        hasValue: Belt_Option.isSome(config$3.accessToken),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* SnowplowDataStructures */4,
                                                          _0: {
                                                            accessToken: encryptedToken,
                                                            organizationId: config$3.organizationId,
                                                            vendor: config$3.vendor
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                            IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, schemaBundle, "DownstreamSecretToken");
                          })
                      }));
    case /* MParticleDataMaster */5 :
        var config$4 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "mparticle-client-id",
                        value: Belt_Option.getWithDefault(config$4.clientId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/mparticle-data-master",
                        onFlush: (function (clientId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamUsername");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* MParticleDataMaster */5,
                                            _0: {
                                              clientId: clientId,
                                              clientSecret: config$4.clientSecret,
                                              workspaceId: config$4.workspaceId,
                                              planId: config$4.planId
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter Client ID...",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "mParticle Client ID"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "mParticle Client Secret",
                        docsUrl: "https://www.avo.app/docs/publishing/mparticle-data-master",
                        hasValue: Belt_Option.isSome(config$4.clientSecret),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedClientSecret) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* MParticleDataMaster */5,
                                                          _0: {
                                                            clientId: config$4.clientId,
                                                            clientSecret: encryptedClientSecret,
                                                            workspaceId: config$4.workspaceId,
                                                            planId: config$4.planId
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "mparticle-workspace-id",
                        value: Belt_Option.getWithDefault(config$4.workspaceId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/mparticle-data-master",
                        onFlush: (function (workspaceId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectID");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* MParticleDataMaster */5,
                                            _0: {
                                              clientId: config$4.clientId,
                                              clientSecret: config$4.clientSecret,
                                              workspaceId: workspaceId,
                                              planId: config$4.planId
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter Workspace ID...",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "mParticle Workspace ID"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "mparticle-plan-id",
                        value: Belt_Option.getWithDefault(config$4.planId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/mparticle-data-master",
                        onFlush: (function (planId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectID");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* MParticleDataMaster */5,
                                            _0: {
                                              clientId: config$4.clientId,
                                              clientSecret: config$4.clientSecret,
                                              workspaceId: config$4.workspaceId,
                                              planId: planId
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter Plan ID...",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "mParticle Plan ID"
                      }));
    case /* RudderstackTrackingPlans */6 :
        var config$5 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "rudderstack-email-address",
                        value: Belt_Option.getWithDefault(config$5.emailAddress, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/rudderstack",
                        onFlush: (function (emailAddress) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamUsername");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* RudderstackTrackingPlans */6,
                                            _0: {
                                              accessToken: config$5.accessToken,
                                              trackingPlanDisplayName: config$5.trackingPlanDisplayName,
                                              emailAddress: emailAddress
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter email address…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Rudderstack Account Email Address"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "rudderstack-tracking-plan-name",
                        value: Belt_Option.getWithDefault(config$5.trackingPlanDisplayName, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/rudderstack",
                        onFlush: (function (trackingPlanDisplayName) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectName");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* RudderstackTrackingPlans */6,
                                            _0: {
                                              accessToken: config$5.accessToken,
                                              trackingPlanDisplayName: trackingPlanDisplayName,
                                              emailAddress: config$5.emailAddress
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter tracking plan name…",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Rudderstack Tracking Plan Name"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Rudderstack Access Token",
                        docsUrl: "https://www.avo.app/docs/publishing/rudderstack",
                        hasValue: Belt_Option.isSome(config$5.accessToken),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* RudderstackTrackingPlans */6,
                                                          _0: {
                                                            accessToken: encryptedToken,
                                                            trackingPlanDisplayName: config$5.trackingPlanDisplayName,
                                                            emailAddress: config$5.emailAddress
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }));
    case /* RudderstackDataCatalog */7 :
        var config$6 = match._0;
        return React.createElement("div", {
                    className: rootStyles
                  }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "rudderstack-tracking-plan-id",
                        value: Belt_Option.getWithDefault(config$6.trackingPlanId, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/rudderstack",
                        onFlush: (function (trackingPlanId) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectName");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* RudderstackDataCatalog */7,
                                            _0: {
                                              accessToken: config$6.accessToken,
                                              trackingPlanId: trackingPlanId
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "Enter tracking id",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Rudderstack Tracking Id"
                      }), React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.SecretInput.make, {
                        label: "Rudderstack Access Token",
                        docsUrl: "https://www.avo.app/docs/publishing/rudderstack",
                        hasValue: Belt_Option.isSome(config$6.accessToken),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "SetAccessToken",
                                    VAL: [
                                      integration.id,
                                      (function (encryptedToken) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamSecretToken");
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateIntegrationConfig",
                                                      VAL: [
                                                        integration.id,
                                                        {
                                                          TAG: /* RudderstackDataCatalog */7,
                                                          _0: {
                                                            accessToken: encryptedToken,
                                                            trackingPlanId: config$6.trackingPlanId
                                                          }
                                                        }
                                                      ]
                                                    },
                                                    {
                                                      integrationId: integration.id,
                                                      integrationQuery: integration.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    ]
                                  }
                                });
                          })
                      }));
    
  }
}

var make = IntegrationConfig;

export {
  rootStyles ,
  rowStyles ,
  make ,
}
/* rootStyles Not a pure module */
