// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "date-fns";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Timestamp from "../Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ProfilePhoto from "../ProfilePhoto.mjs";
import * as App from "firebase/app";
import * as PortalTooltip from "../PortalTooltip.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: /* [] */0
      }
    });

function BranchOverviewRow__Extras$Creator(Props) {
  var id = Props.id;
  var withImageOpt = Props.withImage;
  var withImage = withImageOpt !== undefined ? withImageOpt : true;
  var userState = FirebaseFetcherHooks.useUser(id);
  if (typeof userState !== "object") {
    return null;
  }
  var user = userState.VAL;
  return React.createElement("div", {
              className: rootStyles
            }, withImage ? React.createElement(React.Fragment, undefined, React.createElement(ProfilePhoto.make, {
                        user: user,
                        size: 24
                      }), React.createElement(Spacer.make, {
                        width: 8
                      })) : null, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  singleLine: true,
                  children: AvoConfig.getUserDisplayName(user)
                }));
}

var Creator = {
  rootStyles: rootStyles,
  make: BranchOverviewRow__Extras$Creator
};

function BranchOverviewRow__Extras$LastCodegens(Props) {
  var lastCodegenBySource = Props.lastCodegenBySource;
  var sources = ModelStore.Mapped.useSources(undefined);
  var codegens = Belt_SortArray.stableSortByU(Belt_Array.map(Belt_MapString.toArray(lastCodegenBySource), (function (param) {
              var match = param[1];
              var lastCodegenDate = match.lastCodegenDate;
              return {
                      userId: match.lastCodegenUserId,
                      sourceName: Belt_Option.getWithDefault(TrackingPlanMappedModel.Sources.getName(sources, param[0]), "Untitled Source"),
                      date: lastCodegenDate,
                      lastCodegen: Timestamp.renderTimestamp(App.default.firestore.Timestamp.fromDate(lastCodegenDate))
                    };
            })), (function (a, b) {
          return DateFns.compareDesc(a.date, b.date);
        }));
  var users = FirebaseFetcherHooks.useUsers(Belt_Array.mapU(codegens, (function (codegen) {
              return codegen.userId;
            })));
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var codegenByUserAndSource = Belt_Array.keepMap(codegens, (function (info) {
          var user = Belt_Array.getByU(users$1, (function (param) {
                  return param.id === info.userId;
                }));
          if (user !== undefined) {
            return "" + AvoConfig.getUserDisplayName(user) + " on " + info.sourceName + " " + info.lastCodegen + "";
          }
          
        }));
  return React.createElement($$Text.make, {
              size: "Small",
              lines: 3,
              color: Styles.Color.light11,
              title: codegenByUserAndSource.join("\n"),
              children: codegenByUserAndSource.join(", ")
            });
}

var LastCodegens = {
  make: BranchOverviewRow__Extras$LastCodegens
};

function BranchOverviewRow__Extras$InformationMissing(Props) {
  return React.createElement(PortalTooltip.make, {
              renderTooltip: (function (param) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 4
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.white,
                                  maxWidth: Css.px(250),
                                  children: "Value will be calculated on the next branch interaction (e.g. change or comment)"
                                }));
                }),
              position: "Bottom",
              children: React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingRight(Css.px(16)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        element: "Em",
                        size: "Small",
                        color: Styles.Color.light10,
                        children: "-"
                      }))
            });
}

var InformationMissing = {
  make: BranchOverviewRow__Extras$InformationMissing
};

export {
  Creator ,
  LastCodegens ,
  InformationMissing ,
}
/* rootStyles Not a pure module */
