// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RouterStore from "./RouterStore.mjs";
import * as FramerMotion from "framer-motion";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as BranchImplementationUtils from "./branchImplementation/BranchImplementationUtils.mjs";

var containerStyle = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.borderLeft(Css.px(1), "solid", Styles.Color.light02),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.zIndex(2),
              tl: {
                hd: Css.flexShrink(0.0),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var mainInfoStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.paddingLeft(Css.px(20)),
            tl: {
              hd: Css.minWidth(Css.px(0)),
              tl: {
                hd: Css.flexShrink(10000.0),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var tabs = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: /* [] */0
    });

function tab(isCurrent) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.paddingBottom(Css.px(5)),
                            tl: {
                              hd: Css.color(isCurrent ? Styles.Color.avoPink : Styles.Color.light10),
                              tl: {
                                hd: Css.transition({
                                      NAME: "ms",
                                      VAL: Styles.Duration.$$short
                                    }, undefined, undefined, "color"),
                                tl: {
                                  hd: Css.marginRight(Css.px(16)),
                                  tl: {
                                    hd: Css.lastChild({
                                          hd: Css.marginRight(Css.px(0)),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: Css.hover({
                                            hd: Css.color(Styles.Color.avoPink),
                                            tl: {
                                              hd: Css.before({
                                                    hd: Css.color(Styles.Color.light10),
                                                    tl: /* [] */0
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.selector(":hover path", {
                                              hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.backgroundColor("transparent"),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.unsafe("content", "' '"),
                                                  tl: {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.bottom(Css.px(-1)),
                                                      tl: {
                                                        hd: Css.right(Css.px(0)),
                                                        tl: {
                                                          hd: Css.left(Css.px(0)),
                                                          tl: {
                                                            hd: Css.height(Css.px(2)),
                                                            tl: {
                                                              hd: Css.backgroundColor(isCurrent ? Styles.Color.avoPink : "transparent"),
                                                              tl: {
                                                                hd: Css.transition({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$short
                                                                    }, undefined, undefined, "background-color"),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

var loadingBar = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.bottom(Css.px(-3)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.overflow("hidden"),
              tl: {
                hd: Css.height(Css.px(2)),
                tl: {
                  hd: Css.zIndex(1),
                  tl: {
                    hd: Css.after({
                          hd: Css.unsafe("content", ""),
                          tl: {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.top(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(90)),
                                  tl: {
                                    hd: Css.height(Css.pct(100)),
                                    tl: {
                                      hd: Css.backgroundImage({
                                            NAME: "linearGradient",
                                            VAL: [
                                              {
                                                NAME: "deg",
                                                VAL: 90.0
                                              },
                                              [
                                                [
                                                  Css.pct(0.0),
                                                  Styles.Color.light02
                                                ],
                                                [
                                                  Css.pct(50),
                                                  Styles.Color.light08
                                                ],
                                                [
                                                  Css.pct(100.0),
                                                  Styles.Color.light02
                                                ]
                                              ]
                                            ]
                                          }),
                                      tl: {
                                        hd: Css.animation({
                                              NAME: "ms",
                                              VAL: 1100.0
                                            }, undefined, undefined, "linear", "forwards", undefined, "infinite", Curry._1(Css.keyframes, {
                                                  hd: [
                                                    0,
                                                    {
                                                      hd: Css.left(Css.pct(-90)),
                                                      tl: /* [] */0
                                                    }
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      100,
                                                      {
                                                        hd: Css.left(Css.pct(90)),
                                                        tl: /* [] */0
                                                      }
                                                    ],
                                                    tl: /* [] */0
                                                  }
                                                })),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function BranchHeader(Props) {
  var changeStatus = Props.changeStatus;
  var sourceUpdates = Props.sourceUpdates;
  var branchDiffStatus = Props.branchDiffStatus;
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var totalCodeChanges = Belt_List.length(Belt_List.flatten(Belt_List.map(sourceUpdates.sourceUpdates, (function (param) {
                  return BranchImplementationUtils.filterEvents("any", undefined, undefined, param[0].id, param[1]);
                }))));
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var tmp;
  if (isGlobalWorkspace) {
    tmp = null;
  } else {
    var tmp$1;
    if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
      var match = schemaRoute.VAL;
      tmp$1 = typeof match === "object" ? match.NAME === "implementation" : false;
    } else {
      tmp$1 = false;
    }
    var tmp$2 = false;
    if (totalCodeChanges > 0) {
      var tmp$3;
      if (typeof schemaRoute === "object" && schemaRoute.NAME === "diff") {
        var match$1 = schemaRoute.VAL;
        tmp$3 = typeof match$1 === "object" ? match$1.NAME === "implementation" : false;
      } else {
        tmp$3 = false;
      }
      tmp$2 = tmp$3;
    }
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: /* [] */0
                }
              })
        }, React.createElement("button", {
              className: tab(tmp$1),
              id: "code-changes-tab",
              onClick: (function (param) {
                  Router.push(true, Router.Link.getSchemaRouteLink(undefined, {
                            NAME: "diff",
                            VAL: {
                              NAME: "implementation",
                              VAL: "overview"
                            }
                          }));
                })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  weight: "Semi",
                  children: "Code Changes"
                }), React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(4)),
                        tl: {
                          hd: Css.padding2(Css.px(1), Css.px(4)),
                          tl: {
                            hd: Css.backgroundColor(tmp$2 ? Styles.Color.avoPink : Styles.Color.pink),
                            tl: {
                              hd: Css.borderRadius(Css.px(16)),
                              tl: {
                                hd: Css.color(Styles.Color.white),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.justifyContent("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      weight: "Semi",
                      singleLine: true,
                      children: String(totalCodeChanges)
                    }))));
  }
  var tmp$4;
  var exit = 0;
  if (branchDiffStatus === "updating" || changeStatus === "loading") {
    exit = 1;
  } else {
    tmp$4 = null;
  }
  if (exit === 1) {
    tmp$4 = React.createElement(FramerMotion.motion.div, {
          animate: {
            opacity: 1
          },
          transition: {
            duration: 0.3
          },
          initial: {
            opacity: 1
          },
          exit: {
            opacity: 0
          },
          children: React.createElement("div", {
                className: loadingBar
              })
        });
  }
  return React.createElement("header", {
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(-9)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: containerStyle,
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: mainInfoStyles
                        }, React.createElement("div", {
                              className: tabs
                            }, React.createElement("button", {
                                  className: tab(Caml_obj.equal(schemaRoute, {
                                            NAME: "diff",
                                            VAL: "index"
                                          })),
                                  onClick: (function (param) {
                                      Router.Schema.pushSchemaRoute(undefined, undefined, {
                                            NAME: "diff",
                                            VAL: "index"
                                          });
                                    })
                                }, React.createElement($$Text.make, {
                                      element: "Span",
                                      size: "Small",
                                      weight: "Semi",
                                      children: "Tracking Plan Changes"
                                    })), tmp)), React.createElement(Spacer.make, {
                          width: 8,
                          grow: 1.0
                        }))), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  children: tmp$4
                }));
}

var make = BranchHeader;

export {
  make ,
}
/* containerStyle Not a pure module */
