// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "../../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as VariantEventIdUseCase from "./variantEventIdUseCase.mjs";

function createVariantEventProperties(baseEvent, variantPropertiesOverrides) {
  var notOverrideProperties = Belt_List.keepU(baseEvent.directPropertyRefs, (function (property) {
          var propertyId = ModelUtils.getPropertyId(property);
          return Belt_MapString.get(variantPropertiesOverrides, propertyId) === undefined;
        }));
  var newProperties = Belt_List.keepMapU(Belt_MapString.toList(variantPropertiesOverrides), (function (param) {
          var propertyOverride = param[1];
          var overridePropertyId = param[0];
          var isPropertyIdInBase = Belt_List.someU(baseEvent.directPropertyRefs, (function (baseProperty) {
                  var basePropertyId = ModelUtils.getPropertyId(baseProperty);
                  return basePropertyId === overridePropertyId;
                }));
          if (isPropertyIdInBase) {
            return ;
          }
          if (!propertyOverride) {
            return ;
          }
          var pinnedValue = propertyOverride._0.pinnedValue;
          return {
                  TAG: /* PropertyRef */0,
                  _0: {
                    id: overridePropertyId,
                    description: "",
                    pinnedValue: pinnedValue ? pinnedValue._0 : undefined
                  }
                };
        }));
  var updatedProperties = Belt_List.mapU(Belt_List.keepMapU(baseEvent.directPropertyRefs, (function (property) {
              var propertyId = ModelUtils.getPropertyId(property);
              var match = Belt_MapString.get(variantPropertiesOverrides, propertyId);
              if (match === undefined) {
                return ;
              }
              if (!match) {
                return ;
              }
              var propertyOverride = match._0;
              return Belt_Option.mapU(ModelUtils.getPropertyRefFromProperty(property), (function (propertyRef) {
                            return [
                                    propertyRef,
                                    propertyOverride
                                  ];
                          }));
            })), (function (param) {
          var propertyRefToOverride = param[0];
          var pinnedValue = param[1].pinnedValue;
          return {
                  TAG: /* PropertyRef */0,
                  _0: {
                    id: propertyRefToOverride.id,
                    description: propertyRefToOverride.description,
                    pinnedValue: pinnedValue ? pinnedValue._0 : propertyRefToOverride.pinnedValue
                  }
                };
        }));
  return Belt_List.concatMany([
              notOverrideProperties,
              newProperties,
              updatedProperties
            ]);
}

function createVariantEventIncludedSources(baseEvent, variantSourcesOverrides) {
  var notChangedSources = Belt_List.keepU(baseEvent.includeSources, (function (baseEventSource) {
          return Belt_MapString.get(variantSourcesOverrides, baseEventSource.id) === undefined;
        }));
  var newSources = Belt_List.keepMapU(Belt_MapString.toList(variantSourcesOverrides), (function (param) {
          var sourceOverride = param[1];
          var overrideSourceId = param[0];
          var isSourceIdInBase = Belt_List.someU(baseEvent.includeSources, (function (baseIncludedSource) {
                  var baseSourceId = baseIncludedSource.id;
                  return baseSourceId === overrideSourceId;
                }));
          if (isSourceIdInBase) {
            return ;
          }
          if (!sourceOverride) {
            return ;
          }
          var includeInCodegen = sourceOverride._0.includeInCodegen;
          if (includeInCodegen) {
            return {
                    id: overrideSourceId,
                    includeInCodegen: includeInCodegen._0,
                    inspectorValidation: undefined
                  };
          } else {
            return {
                    id: overrideSourceId,
                    includeInCodegen: false,
                    inspectorValidation: undefined
                  };
          }
        }));
  var overriddenSourcesWithoutRemoved = Belt_List.mapU(Belt_List.keepMapU(baseEvent.includeSources, (function (baseEventSource) {
              var match = Belt_MapString.get(variantSourcesOverrides, baseEventSource.id);
              if (match !== undefined && match) {
                return [
                        baseEventSource,
                        match._0
                      ];
              }
              
            })), (function (param) {
          var baseSourceToOverride = param[0];
          var overrideIncludeInCodegen = param[1].includeInCodegen;
          return {
                  id: baseSourceToOverride.id,
                  includeInCodegen: overrideIncludeInCodegen ? overrideIncludeInCodegen._0 : baseSourceToOverride.includeInCodegen,
                  inspectorValidation: baseSourceToOverride.inspectorValidation
                };
        }));
  return Belt_List.concatMany([
              notChangedSources,
              newSources,
              overriddenSourcesWithoutRemoved
            ]);
}

function variantEventDisplayName(baseEvent, eventVariantSuffix) {
  return "" + baseEvent.name + " - " + eventVariantSuffix + "";
}

function variantEventUniqueName(baseEvent, eventVariantSuffix) {
  var uniqueName = baseEvent.uniqueName;
  if (uniqueName !== undefined) {
    return uniqueName + " " + eventVariantSuffix;
  } else {
    return baseEvent.name + " " + eventVariantSuffix;
  }
}

function create(eventVariant, baseEvent) {
  var match = baseEvent.description.trim();
  var match$1 = eventVariant.description.trim();
  var tmp;
  var exit = 0;
  if (match === "" && match$1 === "") {
    tmp = "Event variant";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = match$1 === "" ? match + ", event variant" : (
        match === "" ? "Event variant: " + match$1 : match + ", variant description: " + match$1
      );
  }
  return {
          id: VariantEventIdUseCase.variantEventId({
                baseEventId: baseEvent.id,
                variantId: eventVariant.id
              }),
          name: baseEvent.name,
          uniqueName: variantEventUniqueName(baseEvent, eventVariant.nameSuffix),
          description: tmp.trim(),
          directPropertyRefs: createVariantEventProperties(baseEvent, eventVariant.propertyOverrides),
          propertyBundles: baseEvent.propertyBundles,
          variants: [],
          types: baseEvent.types,
          tags: baseEvent.tags,
          excludeSourcesDeprecated: baseEvent.excludeSourcesDeprecated,
          includeSources: createVariantEventIncludedSources(baseEvent, eventVariant.sourceOverrides),
          includeDestinations: baseEvent.includeDestinations,
          hashes: baseEvent.hashes,
          propertyWhitelist: baseEvent.propertyWhitelist,
          eventGroupTypeIdsWithArchive: baseEvent.eventGroupTypeIdsWithArchive,
          userGroupTypeIdsWithArchive: baseEvent.userGroupTypeIdsWithArchive,
          triggers: eventVariant.triggers,
          globalRequirementsMetadata: baseEvent.globalRequirementsMetadata
        };
}

export {
  variantEventDisplayName ,
  variantEventUniqueName ,
  create ,
}
/* ModelUtils Not a pure module */
