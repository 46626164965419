// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";

function getInvocationsFromList(querySourceId, valid, anyHashOpt, afterDate, invocations) {
  var anyHash = anyHashOpt !== undefined ? anyHashOpt : false;
  return BeltListExtensions.flatMapU(invocations, (function (param) {
                var hash = param[2];
                var sourceId = param[1];
                if (Belt_Option.mapWithDefaultU(querySourceId, true, (function (querySourceId) {
                          return querySourceId === sourceId;
                        }))) {
                  return Belt_List.fromArray(Belt_Array.keepU(param[3], (function (invocationGroup) {
                                    if ((anyHash || invocationGroup.hash === hash) && Belt_Option.mapWithDefaultU(valid, true, (function (valid) {
                                              return valid === invocationGroup.valid;
                                            }))) {
                                      return Belt_Option.mapWithDefaultU(afterDate, true, (function (afterDate) {
                                                    return afterDate.toISOString() < invocationGroup.lastSeen.value;
                                                  }));
                                    } else {
                                      return false;
                                    }
                                  })));
                } else {
                  return /* [] */0;
                }
              }));
}

function getInvocations(queryEventId, sourceId, valid, anyHashOpt, afterDate, invocations) {
  var anyHash = anyHashOpt !== undefined ? anyHashOpt : false;
  var invocations$1 = Belt_Option.mapWithDefaultU(queryEventId, BeltListExtensions.flatMapU(Belt_List.fromArray(Js_dict.entries(invocations)), (function (param) {
              return param[1];
            })), (function (queryEventId) {
          return Belt_Option.getWithDefault(Js_dict.get(invocations, queryEventId), /* [] */0);
        }));
  return getInvocationsFromList(sourceId, valid, anyHash, afterDate, invocations$1);
}

function reduceInvocations(invocations) {
  return Belt_List.reduce(invocations, undefined, (function (reducedInvocations, invocation) {
                if (reducedInvocations !== undefined) {
                  return {
                          count: reducedInvocations.count + invocation.count | 0,
                          lastSeen: reducedInvocations.lastSeen.value > invocation.lastSeen.value ? reducedInvocations.lastSeen : invocation.lastSeen,
                          firstSeen: reducedInvocations.firstSeen.value < invocation.firstSeen.value ? reducedInvocations.firstSeen : invocation.firstSeen
                        };
                } else {
                  return {
                          count: invocation.count,
                          lastSeen: invocation.lastSeen,
                          firstSeen: invocation.firstSeen
                        };
                }
              }));
}

function getInvocationsWithoutStatus(param) {
  var invocations = param[1];
  if (param[0] === "Ready" || Caml_obj.notequal(invocations, {})) {
    return {
            NAME: "Success",
            VAL: invocations
          };
  } else {
    return "Loading";
  }
}

export {
  getInvocationsFromList ,
  getInvocations ,
  reduceInvocations ,
  getInvocationsWithoutStatus ,
}
/* No side effect */
