// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";

function getPropertyMatchesLegacy(property) {
  return Belt_List.keepMap(ModelUtils.getMatchesFromPropertyLegacy(property), (function (param) {
                var match = param[0].VAL;
                if (typeof match === "object" && match.NAME === "StringLit") {
                  return match.VAL;
                }
                
              }));
}

function getPropertyMatchesForSourceLegacy(sourceId, property) {
  return Belt_List.keepMap(ModelUtils.getMatchesFromPropertyLegacy(property), (function (param) {
                var excludedSources = param[1];
                var match = param[0].VAL;
                if (typeof match !== "object") {
                  return ;
                }
                if (match.NAME !== "StringLit") {
                  return ;
                }
                var match_ = match.VAL;
                if (excludedSources && Belt_List.someU(excludedSources, (function (excludedSourceId) {
                          return excludedSourceId === sourceId;
                        }))) {
                  return ;
                } else {
                  return match_;
                }
              }));
}

function getMinInt(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Min") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "IntLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMaxInt(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Max") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "IntLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMinFloat(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Min") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "FloatLit") {
                      return match.VAL;
                    }
                    
                  })));
}

function getMaxFloat(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation !== "object") {
                      return ;
                    }
                    if (validation.NAME !== "Max") {
                      return ;
                    }
                    var match = validation.VAL;
                    if (typeof match === "object" && match.NAME === "FloatLit") {
                      return match.VAL;
                    }
                    
                  })));
}

export {
  getPropertyMatchesLegacy ,
  getPropertyMatchesForSourceLegacy ,
  getMinInt ,
  getMaxInt ,
  getMinFloat ,
  getMaxFloat ,
}
/* ModelUtils Not a pure module */
