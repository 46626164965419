// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

var SchemaMetadata = {};

function toMetadata(organization) {
  return {
          organizationId: organization.id
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          globalWorkspaceIds: Json_decode.field("globalWorkspaceIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          localWorkspaceIds: Json_decode.field("localWorkspaceIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

var Json = {
  decode: decode
};

export {
  SchemaMetadata ,
  toMetadata ,
  Json ,
}
/* No side effect */
