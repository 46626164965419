// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Styles from "./styles.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Mantine from "./Mantine.mjs";
import * as DateFns$1 from "date-fns";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";

function renderTimestamp(date) {
  return Belt_Option.mapWithDefault(Models.toDateOption(date), "few seconds", (function (eta) {
                return DateFns.formatDistanceToNow(undefined, undefined, eta);
              })) + " ago";
}

function renderJsTimestamp(date) {
  return "" + DateFns.formatDistanceToNow(undefined, undefined, date) + " ago";
}

function use(date) {
  var match = React.useState(function () {
        return renderJsTimestamp(date);
      });
  var setTimestamp = match[1];
  var timestamp = match[0];
  React.useEffect((function () {
          var minute = 60 * 1000;
          var hour = 60 * minute;
          var day = 24 * hour;
          var timeoutId = setTimeout((function (param) {
                  Curry._1(setTimestamp, (function (param) {
                          return renderJsTimestamp(date);
                        }));
                }), DateFns$1.differenceInMilliseconds(new Date(), date) < hour ? minute | 0 : (
                  DateFns$1.differenceInMilliseconds(new Date(), date) < day ? 3600000 : (day | 0) / 2 | 0
                ));
          return (function (param) {
                    clearTimeout(timeoutId);
                  });
        }), [timestamp]);
  React.useEffect((function () {
          Curry._1(setTimestamp, (function (param) {
                  return renderJsTimestamp(date);
                }));
        }), [date]);
  return timestamp;
}

function renderTimestamp$1(date) {
  return "" + DateFns.formatDistanceToNow(undefined, undefined, date) + " ago";
}

function Timestamp$NonNull(Props) {
  var date = Props.date;
  var timestamp = use(date);
  var match = React.useState(function () {
        return false;
      });
  var setPopoverVisible = match[1];
  return React.createElement(Mantine.Popover.make, {
              opened: match[0],
              position: "bottom",
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("inline"),
                              tl: {
                                hd: Css.position("relative"),
                                tl: /* [] */0
                              }
                            }),
                        onMouseEnter: (function (param) {
                            Curry._1(setPopoverVisible, (function (param) {
                                    return true;
                                  }));
                          }),
                        onMouseLeave: (function (param) {
                            Curry._1(setPopoverVisible, (function (param) {
                                    return false;
                                  }));
                          })
                      }, timestamp)
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("span", {
                        className: Curry._1(Css.style, {
                              hd: Css.width("auto"),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.padding2(Css.px(3), Css.px(6)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light11),
                                    tl: {
                                      hd: Css.color(Styles.Color.white),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                            tl: {
                                              hd: Css.zIndex(3),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, "" + DateFns.format("d MMM, yyyy", date) + " at " + DateFns.format("HH:mm", date) + "")
                }));
}

var NonNull = {
  renderTimestamp: renderTimestamp$1,
  make: Timestamp$NonNull
};

function Timestamp(Props) {
  var date = Props.date;
  var date$1 = Models.toDateOption(date);
  if (date$1 !== undefined) {
    return React.createElement(Timestamp$NonNull, {
                date: Caml_option.valFromOption(date$1)
              });
  } else {
    return null;
  }
}

var make = Timestamp;

export {
  renderTimestamp ,
  renderJsTimestamp ,
  use ,
  NonNull ,
  make ,
}
/* Css Not a pure module */
