// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as TrackingPlanModel from "../TrackingPlanModel.mjs";

function create(modelSources) {
  var init = TrackingPlanModel.emptyEvent("system", "System Event");
  return {
          id: init.id,
          name: init.name,
          uniqueName: init.uniqueName,
          description: init.description,
          directPropertyRefs: init.directPropertyRefs,
          propertyBundles: init.propertyBundles,
          variants: init.variants,
          types: init.types,
          tags: init.tags,
          excludeSourcesDeprecated: init.excludeSourcesDeprecated,
          includeSources: Belt_List.map(modelSources, (function (param) {
                  return {
                          id: param.id,
                          includeInCodegen: false,
                          inspectorValidation: undefined
                        };
                })),
          includeDestinations: init.includeDestinations,
          hashes: init.hashes,
          propertyWhitelist: init.propertyWhitelist,
          eventGroupTypeIdsWithArchive: init.eventGroupTypeIdsWithArchive,
          userGroupTypeIdsWithArchive: init.userGroupTypeIdsWithArchive,
          triggers: init.triggers,
          globalRequirementsMetadata: init.globalRequirementsMetadata
        };
}

export {
  create ,
}
/* TrackingPlanModel Not a pure module */
