// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as IconSources from "./IconSources.mjs";

function PlatformIcon(Props) {
  var platform = Props.platform;
  var sizeWithBorder = Props.sizeWithBorder;
  var customBackgroundColorOpt = Props.backgroundColor;
  var borderColorOpt = Props.borderColor;
  var customBackgroundColor = customBackgroundColorOpt !== undefined ? customBackgroundColorOpt : Styles.Color.light02;
  var borderColor = borderColorOpt !== undefined ? borderColorOpt : Styles.Color.light04;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.width(Css.px(sizeWithBorder)),
                          tl: {
                            hd: Css.height(Css.px(sizeWithBorder)),
                            tl: {
                              hd: Css.borderRadius(Css.px(100)),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", borderColor),
                                tl: {
                                  hd: Css.backgroundColor(customBackgroundColor),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(IconSources.make, {
                  platform: Belt_Option.getWithDefault(platform, "Other"),
                  size: sizeWithBorder - 2 | 0
                }));
}

var make = PlatformIcon;

export {
  make ,
}
/* Css Not a pure module */
