// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function getColor(color) {
  if (color === "avoPink") {
    return Styles.Color.avoPink;
  } else if (color === "green") {
    return Styles.Color.green;
  } else {
    return Styles.Color.darkRed;
  }
}

function rootStyles(size, on, color, customDisabled, faded) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.flexShrink(0.0),
                      tl: {
                        hd: Css.padding(Css.px(2)),
                        tl: {
                          hd: Css.backgroundColor(on ? getColor(color) : Styles.Color.light07),
                          tl: {
                            hd: Css.width(Css.px(size === "Small" ? 30 : (
                                        size === "Medium" ? 34 : (
                                            size === "Tiny" ? 26 : 36
                                          )
                                      ))),
                            tl: {
                              hd: Css.height(Css.px(size === "Small" ? 18 : (
                                          size === "Medium" ? 20 : (
                                              size === "Tiny" ? 16 : 22
                                            )
                                        ))),
                              tl: {
                                hd: Css.borderRadius(Css.px(100)),
                                tl: {
                                  hd: Css.opacity(customDisabled || faded ? 0.5 : 1.0),
                                  tl: {
                                    hd: Css.disabled({
                                          hd: Css.cursor("default"),
                                          tl: {
                                            hd: Css.backgroundColor(on ? getColor(color) : Styles.Color.light04),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: {
                                      hd: Css.focus({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                            tl: {
                                              hd: Css.outlineStyle("none"),
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(on ? Styles.Color.darken(getColor(color), 16) : Styles.Color.light08),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$default
                                              }, undefined, undefined, "background-color"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function knobStyles(size, on, disabled, faded) {
  return Curry._1(Css.style, {
              hd: Css.width(Css.px(size === "Small" ? 14 : (
                          size === "Medium" ? 16 : (
                              size === "Tiny" ? 12 : 18
                            )
                        ))),
              tl: {
                hd: Css.height(Css.px(size === "Small" ? 14 : (
                            size === "Medium" ? 16 : (
                                size === "Tiny" ? 12 : 18
                              )
                          ))),
                tl: {
                  hd: Css.borderRadius(Css.px(100)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.white),
                    tl: {
                      hd: Css.opacity(disabled || faded ? 0.5 : 1.0),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$default
                            }, undefined, undefined, "transform"),
                        tl: {
                          hd: Css.transform({
                                NAME: "translateX",
                                VAL: Css.px(on ? (
                                        size === "Small" ? 12 : (
                                            size === "Tiny" ? 10 : 14
                                          )
                                      ) : 0)
                              }),
                          tl: {
                            hd: Css.boxShadow(Styles.Shadow.box),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function Switch(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var fadedOpt = Props.faded;
  var colorOpt = Props.color;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var tabIndex = Props.tabIndex;
  var faded = fadedOpt !== undefined ? fadedOpt : false;
  var color = colorOpt !== undefined ? colorOpt : "green";
  var size = sizeOpt !== undefined ? sizeOpt : "Large";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: rootStyles(size, value, color, disabled, faded),
    disabled: disabled,
    onClick: (function (param) {
        Curry._1(onChange, !value);
      })
  };
  if (tabIndex !== undefined) {
    tmp.tabIndex = Caml_option.valFromOption(tabIndex);
  }
  return React.createElement("button", tmp, React.createElement("div", {
                  className: knobStyles(size, value, disabled, faded)
                }));
}

var make = Switch;

export {
  getColor ,
  rootStyles ,
  knobStyles ,
  make ,
}
/* Css Not a pure module */
