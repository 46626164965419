// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconWeb(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("path", {
                          d: "M14.5,15.5h-13 c-0.552,0-1-0.448-1-1v-13c0-0.552,0.448-1,1-1h13c0.552,0,1,0.448,1,1v13C15.5,15.052,15.052,15.5,14.5,15.5z",
                          fill: "none",
                          strokeLinecap: "round"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "0.5",
                          x2: "15.5",
                          y1: "4.5",
                          y2: "4.5"
                        }), React.createElement("line", {
                          fill: "none",
                          strokeLinecap: "round",
                          x1: "2.5",
                          x2: "4.5",
                          y1: "2.5",
                          y2: "2.5"
                        }))));
}

var make = IconWeb;

export {
  make ,
}
/* Css Not a pure module */
