// Generated by ReScript, PLEASE EDIT WITH CARE

import * as KBar from "./KBar.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function itemToString(item) {
  switch (item.TAG | 0) {
    case /* NameMappedEvent */5 :
        var mappedItemName = item._0;
        if (mappedItemName !== undefined) {
          return mappedItemName;
        } else {
          return "Untitled Event";
        }
    case /* NameMappedProperty */7 :
        var mappedItemName$1 = item._0;
        if (mappedItemName$1 !== undefined) {
          return mappedItemName$1;
        } else {
          return "Untitled Property";
        }
    case /* Source */8 :
        return Belt_Option.getWithDefault(item._0, "Untitled Source");
    case /* Destination */9 :
        return Belt_Option.getWithDefault(item._0, "Untitled Destination");
    case /* Integration */10 :
        return Belt_Option.getWithDefault(item._0, "Untitled Integration");
    default:
      return item._0;
  }
}

function itemToStringWithDescription(item) {
  var exit = 0;
  var name;
  var description;
  switch (item.TAG | 0) {
    case /* Event */4 :
        name = item._0;
        description = item._1;
        exit = 2;
        break;
    case /* Property */6 :
        name = item._0;
        description = item._3;
        exit = 2;
        break;
    case /* NameMappedEvent */5 :
    case /* NameMappedProperty */7 :
        exit = 1;
        break;
    default:
      return itemToString(item);
  }
  switch (exit) {
    case 1 :
        return "" + Belt_Option.getWithDefault(item._0, item._1) + " " + item._2 + "";
    case 2 :
        return "" + name + " " + description + "";
    
  }
}

function itemToSectionString(item) {
  switch (item.TAG | 0) {
    case /* RootItem */0 :
    case /* Create */1 :
        return ;
    case /* Command */2 :
        return "Command";
    case /* Branch */3 :
        return "Branches";
    case /* Event */4 :
    case /* NameMappedEvent */5 :
        return "Events";
    case /* Property */6 :
    case /* NameMappedProperty */7 :
        return "Properties";
    case /* Source */8 :
        return "Sources";
    case /* Destination */9 :
        return "Destinations";
    case /* Integration */10 :
        return "Integrations";
    case /* Metric */11 :
        return "Metrics";
    case /* Category */12 :
        return "Categories";
    case /* PropertyGroup */13 :
        return "Property Bundles";
    case /* GroupType */14 :
        return "Group Types";
    case /* SavedView */15 :
        return "Saved Views";
    
  }
}

function itemDescription(item) {
  switch (item.TAG | 0) {
    case /* Event */4 :
        return item._1;
    case /* Property */6 :
        return item._3;
    case /* NameMappedEvent */5 :
    case /* NameMappedProperty */7 :
        return item._2;
    default:
      return ;
  }
}

var include = KBar.Make({
      itemToSectionString: itemToSectionString,
      itemToString: itemToString
    });

var ActionImpl = include.ActionImpl;

var Action = include.Action;

var Result = include.Result;

var Provider = include.Provider;

var Portal = include.Portal;

var Positioner = include.Positioner;

var Animator = include.Animator;

var Search = include.Search;

var Matches = include.Matches;

var Results = include.Results;

var State = include.State;

var Query = include.Query;

var HookState = include.HookState;

export {
  itemToString ,
  itemToStringWithDescription ,
  itemToSectionString ,
  itemDescription ,
  ActionImpl ,
  Action ,
  Result ,
  Provider ,
  Portal ,
  Positioner ,
  Animator ,
  Search ,
  Matches ,
  Results ,
  State ,
  Query ,
  HookState ,
}
/* include Not a pure module */
