// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DidMount from "./DidMount.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as LoadingCircle from "./LoadingCircle.mjs";

function NameInput__Validations(Props) {
  var validation = Props.validation;
  var onNamingFeedbackReceived = Props.onNamingFeedbackReceived;
  var maybeGetItemLink = Props.maybeGetItemLink;
  var onFixCasing = Props.onFixCasing;
  var expectedCase = Props.expectedCase;
  var forceCase = Props.forceCase;
  var onLinkOpened = Props.onLinkOpened;
  var name = Props.name;
  if (typeof validation !== "object") {
    if (validation === "Init") {
      return null;
    } else {
      return React.createElement(LoadingCircle.make, {
                  size: 12,
                  color: Styles.Color.light08
                });
    }
  }
  var match = validation.VAL;
  var name$1 = match.conflictingName;
  if (match.isEmpty) {
    return React.createElement($$Text.make, {
                size: "Small",
                weight: "Semi",
                color: Styles.Color.red,
                children: null
              }, React.createElement(DidMount.make, {
                    didMount: (function (param) {
                        Curry._2(onNamingFeedbackReceived, "Error", "EmptyName");
                      })
                  }), "Name can't be empty.");
  }
  if (name$1 !== undefined) {
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: null,
                    key: name$1
                  }, React.createElement(DidMount.make, {
                        didMount: (function (param) {
                            Curry._2(onNamingFeedbackReceived, "Error", "AlreadyExists");
                          })
                      }), "\"" + name$1 + "\" already exists."), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(maybeGetItemLink, (function (getLink) {
                              return Curry._1(getLink, name$1);
                            })), (function (param) {
                          return React.createElement(Link.make, {
                                      path: param[0],
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.flexShrink(0.0),
                                                tl: {
                                                  hd: Css.marginLeft(Css.px(4)),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.darkBlue),
                                                    tl: {
                                                      hd: Icon.color(Styles.Color.darkBlue),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.deepBlue),
                                                              tl: {
                                                                hd: Icon.color(Styles.Color.deepBlue),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      onClick: onLinkOpened,
                                      children: null
                                    }, React.createElement($$Text.make, {
                                          element: "Span",
                                          size: "Small",
                                          children: "Go to" + Belt_Option.mapWithDefault(param[1], "", (function (t) {
                                                  return " " + t + "";
                                                })) + ""
                                        }), React.createElement(Icon.make, {
                                          type_: "arrowRight",
                                          size: "small"
                                        }));
                        })), null));
  }
  var similarName = Belt_Array.get(match.similarNames, 0);
  var warnings = Belt_Array.concatMany([
        match.inconsistentCase ? [React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(onFixCasing, NameUtils.getCorrectCase(expectedCase, name));
                    }),
                  style: "Blue",
                  size: "Small",
                  children: null
                }, React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          Curry._2(onNamingFeedbackReceived, "Suggestion", "ConsistentCasing");
                        })
                    }), "" + (
                  forceCase ? "Use" : "Try"
                ) + " \"" + NameUtils.getCorrectCase(expectedCase, name) + "\" for consistent casing.")] : [],
        similarName !== undefined ? [React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: null,
                      key: similarName
                    }, React.createElement(DidMount.make, {
                          didMount: (function (param) {
                              Curry._2(onNamingFeedbackReceived, "Warning", "SimilarToExisting");
                            })
                        }), "Similar to \"" + similarName + "\"."), Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(maybeGetItemLink, (function (getLink) {
                                return Curry._1(getLink, similarName);
                              })), (function (param) {
                            return React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.color(Styles.Color.darkBlue),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: {
                                                            hd: Icon.color(Styles.Color.deepBlue),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement(Spacer.make, {
                                            width: 6
                                          }), React.createElement(Link.make, {
                                            path: param[0],
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            onClick: onLinkOpened,
                                            children: null
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Small",
                                                children: "Go to" + Belt_Option.mapWithDefault(param[1], "", (function (t) {
                                                        return " " + t + "";
                                                      })) + ""
                                              }), React.createElement(Icon.make, {
                                                type_: "arrowRight",
                                                size: "small"
                                              })));
                          })), null))] : []
      ]);
  return React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light10,
              children: Belt_Array.map(warnings, (function (warning) {
                      return React.createElement("div", undefined, warning);
                    }))
            });
}

var make = NameInput__Validations;

export {
  make ,
}
/* Css Not a pure module */
