// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SimpleTable from "../SimpleTable.mjs";
import * as IssuesTableConfig from "./IssuesTableConfig.mjs";

var IssuesTable = SimpleTable.Make({
      tableName: IssuesTableConfig.tableName,
      getRowId: IssuesTableConfig.getRowId,
      defaultOrder: IssuesTableConfig.defaultOrder
    });

var getColumnName = IssuesTable.getColumnName;

var getColumnId = IssuesTable.getColumnId;

var getColumnWidth = IssuesTable.getColumnWidth;

var getColumnCompare = IssuesTable.getColumnCompare;

var getColumnDefaultOrder = IssuesTable.getColumnDefaultOrder;

var getColumnAlignment = IssuesTable.getColumnAlignment;

var getColumnCompareFromId = IssuesTable.getColumnCompareFromId;

var getDefaultOrder = IssuesTable.getDefaultOrder;

var isOrderedBy = IssuesTable.isOrderedBy;

var getStyles = IssuesTable.getStyles;

var addCentered = IssuesTable.addCentered;

var addHoverable = IssuesTable.addHoverable;

var TableContext = IssuesTable.TableContext;

var ActiveRowIdContext = IssuesTable.ActiveRowIdContext;

var VirtualizedTable = IssuesTable.VirtualizedTable;

var ColumnHeader = IssuesTable.ColumnHeader;

var debounceFunction = IssuesTable.debounceFunction;

var make = IssuesTable.make;

export {
  IssuesTable ,
  getColumnName ,
  getColumnId ,
  getColumnWidth ,
  getColumnCompare ,
  getColumnDefaultOrder ,
  getColumnAlignment ,
  getColumnCompareFromId ,
  getDefaultOrder ,
  isOrderedBy ,
  getStyles ,
  addCentered ,
  addHoverable ,
  TableContext ,
  ActiveRowIdContext ,
  VirtualizedTable ,
  ColumnHeader ,
  debounceFunction ,
  make ,
}
/* IssuesTable Not a pure module */
