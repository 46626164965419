// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Mantine from "../Mantine.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "../Markdown.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainUtils from "../DomainUtils.mjs";
import * as Core from "@mantine/core";
import * as DomainMembers from "../../../model/src/domains/DomainMembers.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as CollaboratorsView from "../CollaboratorsView.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../../model/src/variants/variantEventIdUseCase.mjs";
import * as CreateEventForVariantUseCase from "../../../model/src/variants/createEventForVariantUseCase.mjs";

var popupOptionStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("block"),
              tl: {
                hd: Css.padding2(Css.px(6), Css.px(16)),
                tl: {
                  hd: Css.textAlign("left"),
                  tl: {
                    hd: Css.color(Styles.Color.light11),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.transition({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "all"),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(Styles.Color.light02),
                                tl: {
                                  hd: Css.color(Styles.Color.light12),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.focus({
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.borderRadius(Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var root = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.box),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light05),
            tl: {
              hd: Css.paddingTop(Css.px(4)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: {
                  hd: Css.width(Css.px(240)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var empty = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(12)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var workspaceLinkBox = Curry._1(Css.style, {
      hd: Css.padding(Css.px(4)),
      tl: {
        hd: Css.paddingBottom(Css.px(2)),
        tl: {
          hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light06),
          tl: /* [] */0
        }
      }
    });

var allDomains = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: {
          hd: Css.paddingBottom(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: /* [] */0
          }
        }
      }
    });

var textBox = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(16)),
        tl: /* [] */0
      }
    });

var disabledOption = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(6), Css.px(16)),
      tl: /* [] */0
    });

function EventDetailsDomains$AddDomainPopup(Props) {
  var domains = Props.domains;
  var selected = Props.selected;
  var onAdd = Props.onAdd;
  var viewerRole = Props.viewerRole;
  var viewerId = ViewerContext.use(undefined).id;
  var remaining = Curry._2(Domains.keep, domains, (function (domain) {
          return !Curry._2(Domains.has, selected, domain.id);
        }));
  var globalSend = GlobalSendContext.use(undefined);
  var viewerDomains = DomainStore.useMemberDomains(viewerId);
  var viewerDomainIds = Belt_SetString.fromArray(Curry._1(Domains.keys, viewerDomains));
  var match = Belt_Array.partition(Curry._1(Domains.toArray, remaining), (function (domain) {
          return Belt_SetString.has(viewerDomainIds, domain.id);
        }));
  var otherRemainingDomains = match[1];
  var remainingViewerDomains = match[0];
  return React.createElement("div", {
              className: root
            }, Caml_obj.equal(domains, Domains.empty) ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: empty
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: "There are no domains defined in your workspace yet. "
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "Admins can define domains in Workspace settings."
                          })), viewerRole === "Admin" ? React.createElement("div", {
                          className: workspaceLinkBox
                        }, React.createElement(Button.make, {
                              icon: "settings",
                              label: "Open Workspace Settings",
                              onClick: (function (param) {
                                  Curry._1(globalSend, {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "Settings",
                                          VAL: /* Domains */4
                                        }
                                      });
                                }),
                              size: "small",
                              style: "ghost"
                            })) : null) : (
                Caml_obj.equal(remaining, Domains.empty) ? React.createElement("div", {
                        className: allDomains
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light11,
                            children: "All domains have been added on this event."
                          })) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: textBox
                          }, React.createElement(Spacer.make, {
                                height: 12
                              }), React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: null
                              }, "YOUR DOMAINS", React.createElement(Spacer.make, {
                                    height: 4
                                  }))), Caml_obj.equal(remainingViewerDomains, []) ? React.createElement("div", {
                              className: textBox
                            }, React.createElement(Spacer.make, {
                                  height: 4
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light10,
                                  children: Caml_obj.equal(viewerDomains, Domains.empty) ? "You are not a member of any domains." : "All of your domains have been added on this event."
                                })) : Belt_Array.map(remainingViewerDomains, (function (domain) {
                                return React.createElement("button", {
                                            key: domain.id,
                                            className: popupOptionStyles,
                                            onClick: (function (param) {
                                                Curry._2(onAdd, domain, "DomainTeamMember");
                                              })
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Medium",
                                                weight: "Semi",
                                                children: domain.name
                                              }));
                              })), Caml_obj.equal(otherRemainingDomains, []) ? null : React.createElement("div", {
                              className: textBox
                            }, React.createElement(Spacer.make, {
                                  height: 12
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  color: Styles.Color.light10,
                                  children: null
                                }, "OTHER DOMAINS", React.createElement(Spacer.make, {
                                      height: 4
                                    }))), Belt_Array.map(otherRemainingDomains, (function (domain) {
                              if (viewerRole === "Admin") {
                                return React.createElement("button", {
                                            key: domain.id,
                                            className: popupOptionStyles,
                                            onClick: (function (param) {
                                                Curry._2(onAdd, domain, "Admin");
                                              })
                                          }, React.createElement($$Text.make, {
                                                element: "Span",
                                                size: "Medium",
                                                weight: "Semi",
                                                children: domain.name
                                              }));
                              } else {
                                return React.createElement(SimpleTooltip.make, {
                                            tooltip: "Only domain team members and workspace Admins can add domains as Stakeholders on an event",
                                            children: React.createElement("div", {
                                                  className: disabledOption
                                                }, React.createElement($$Text.make, {
                                                      size: "Medium",
                                                      weight: "Semi",
                                                      color: Styles.Color.light08,
                                                      children: domain.name
                                                    }))
                                          });
                              }
                            })))
              ));
}

var button = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.paddingTop(Css.px(2)),
              tl: {
                hd: Css.paddingRight(Css.px(2)),
                tl: {
                  hd: Css.paddingBottom(Css.px(2)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(8)),
                    tl: {
                      hd: Css.borderRadius(Css.px(4)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light03),
                        tl: {
                          hd: Css.hover({
                                hd: Css.backgroundColor(Styles.Color.light04),
                                tl: /* [] */0
                              }),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var popup = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.box),
      tl: {
        hd: Css.borderRadius(Styles.Border.radius),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light05),
            tl: {
              hd: Css.paddingTop(Css.px(4)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: {
                  hd: Css.width(Css.px(240)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var popupMembers = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light05),
        tl: {
          hd: Css.marginBottom(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

function EventDetailsDomains$DomainPopup(Props) {
  var domain = Props.domain;
  var onRemove = Props.onRemove;
  var viewerId = ViewerContext.use(undefined).id;
  var viewerRole = WorkspaceContext.useMemberRole(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var domainMembers = DomainStore.useDomainMembers(domain.id, undefined);
  var domainMembersInitialized = DomainStore.useMembersInitialized(undefined);
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var viewerIsMember = Curry._2(Domains.has, memberDomains, domain.id);
  var viewerCanRemove = viewerRole === "Admin" || viewerIsMember;
  var match$1 = Curry._1(DomainMembers.size, domainMembers);
  var tmp;
  if (domainMembersInitialized) {
    if (match$1 !== 0) {
      var membersToDisplay = DomainMembers.getUserIds(Curry._2(DomainMembers.take, domainMembers, 6));
      tmp = React.createElement(CollaboratorsView.CollaboratorProfilePhotos.make, {
            ids: membersToDisplay,
            notDisplayedCount: Curry._1(DomainMembers.size, domainMembers) - membersToDisplay.length | 0
          });
    } else {
      tmp = React.createElement($$Text.make, {
            size: "Small",
            color: Styles.Color.light11,
            children: "No members"
          });
    }
  } else {
    tmp = React.createElement($$Text.make, {
          size: "Small",
          children: "Loading..."
        });
  }
  return React.createElement(Mantine.Popover.make, {
              onChange: (function (isOpen) {
                  Curry._1(setIsOpen, (function (param) {
                          return isOpen;
                        }));
                }),
              opened: match[0],
              position: "bottom-start",
              children: null,
              key: domain.id
            }, React.createElement(Core.Popover.Target, {
                  children: React.createElement("button", {
                        className: button,
                        onClick: (function (param) {
                            Curry._1(setIsOpen, (function (isOpen) {
                                    return !isOpen;
                                  }));
                          })
                      }, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Semi",
                            children: domain.name
                          }), React.createElement(Icon.make, {
                            type_: "chevronDown",
                            size: {
                              NAME: "int",
                              VAL: 20
                            },
                            color: Styles.Color.light10
                          }))
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        className: popup
                      }, React.createElement("div", {
                            className: popupMembers
                          }, tmp), React.createElement(Link.make, {
                            path: Router.Link.getSchemaRouteLink(undefined, {
                                  NAME: "domain",
                                  VAL: domain.id
                                }),
                            className: popupOptionStyles,
                            children: React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Small",
                                  weight: "Semi",
                                  children: "Go to domain details"
                                })
                          }), viewerCanRemove ? React.createElement("button", {
                              className: popupOptionStyles,
                              onClick: (function (param) {
                                  Curry._2(onRemove, domain, viewerIsMember ? "DomainTeamMember" : "Admin");
                                })
                            }, React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Small",
                                  weight: "Semi",
                                  children: "Remove from Stakeholders"
                                })) : null)
                }));
}

var root$1 = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: /* [] */0
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginLeft(Css.px(30)),
            tl: /* [] */0
          }
        }
      }
    });

var domains = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(60)),
            tl: {
              hd: Css.paddingBottom(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(20)),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: {
                    hd: Css.marginTop(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

function EventDetailsDomains(Props) {
  var $$event = Props.event;
  var viewerCanEdit = Props.viewerCanEdit;
  var viewerRole = Props.viewerRole;
  var variant = Props.variant;
  var domains$1 = DomainStore.useDomains(undefined);
  var domainsWithEvent = DomainStore.useEventOrEventVariantDomains($$event.id, Belt_Option.isSome(variant));
  var schemaId = WorkspaceContext.use(undefined).id;
  var viewerId = ViewerContext.use(undefined).id;
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setShowAddDomains = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setOptimisticallyAddedDomains = match$1[1];
  var optimisticallyAddedDomains = match$1[0];
  React.useEffect((function () {
          Curry._1(setOptimisticallyAddedDomains, (function (set) {
                  return Belt_SetString.keep(set, (function (id) {
                                return Curry._2(Domains.has, domainsWithEvent, id);
                              }));
                }));
        }), [domainsWithEvent]);
  var domainsWithEventOptimistic = Curry._2(Domains.mergeMany, domainsWithEvent, Curry._2(Domains.keep, domains$1, (function (domain) {
              return Belt_SetString.has(optimisticallyAddedDomains, domain.id);
            })));
  var handleAdd = function (domain, actionTakerDomainRelationship) {
    Curry._1(setOptimisticallyAddedDomains, (function (set) {
            return Belt_SetString.add(set, domain.id);
          }));
    DomainUtils.Firebase.addDomainItems([Belt_Option.mapWithDefault(variant, {
                NAME: "event",
                VAL: $$event.id
              }, (function (_variant) {
                  return {
                          NAME: "eventVariant",
                          VAL: $$event.id
                        };
                }))], (function (param) {
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Error adding event" + Belt_Option.mapWithDefault(variant, "", (function (param) {
                              return " variant";
                            })) + " to ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Error */1
                });
          }), (function (param) {
            AnalyticsRe.domainItemAddedSingleItem(domain.id, domain.name, Curry._1(Domains.size, domains$1), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.mapWithDefault(variant, "EventDetails", (function (param) {
                        return "EventVariantDetails";
                      })), actionTakerDomainRelationship, "Stakeholder", Belt_Option.mapWithDefault(variant, $$event.name, (function (variant) {
                        return CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix);
                      })), Belt_Option.mapWithDefault(variant, $$event.id, (function (variant) {
                        return VariantEventIdUseCase.variantEventId({
                                    baseEventId: $$event.id,
                                    variantId: variant.id
                                  });
                      })), "Event", schemaId);
          }), schemaId, sendActions, viewerId, domain.id);
  };
  var handleRemove = function (domain, actionTakerDomainRelationship) {
    DomainUtils.Firebase.removeDomainItems([Belt_Option.mapWithDefault(variant, {
                NAME: "event",
                VAL: $$event.id
              }, (function (_variant) {
                  return {
                          NAME: "eventVariant",
                          VAL: $$event.id
                        };
                }))], (function (param) {
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Error removing event" + Belt_Option.mapWithDefault(variant, "", (function (param) {
                              return " variant";
                            })) + " from ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Error */1
                });
          }), (function (param) {
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Event" + Belt_Option.mapWithDefault(variant, "", (function (param) {
                              return " variant";
                            })) + " removed from ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Success */0
                });
            AnalyticsRe.domainItemRemovedSingleItem(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains$1), Belt_Option.mapWithDefault(variant, "EventDetails", (function (param) {
                        return "EventVariantDetails";
                      })), actionTakerDomainRelationship, "Stakeholder", Belt_Option.mapWithDefault(variant, "Event", (function (param) {
                        return "EventVariant";
                      })), Belt_Option.mapWithDefault(variant, $$event.name, (function (variant) {
                        return CreateEventForVariantUseCase.variantEventDisplayName($$event, variant.nameSuffix);
                      })), Belt_Option.mapWithDefault(variant, $$event.id, (function (variant) {
                        return VariantEventIdUseCase.variantEventId({
                                    baseEventId: $$event.id,
                                    variantId: variant.id
                                  });
                      })), schemaId);
          }), schemaId, sendActions, viewerId, domain.id);
  };
  return React.createElement("section", {
              className: root$1
            }, React.createElement("header", {
                  className: header
                }, React.createElement(Icon.make, {
                      type_: "usersFour",
                      color: Styles.Color.light07
                    }), React.createElement(Spacer.make, {
                      width: 14
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Stakeholders"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: $$event.id,
                        itemName: $$event.name,
                        itemType: "Event"
                      },
                      title: "Stakeholders",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: "Stakeholders are the domain teams who rely on specific events and their data. Some examples of domain teams are:\n\n- A single product team (e.g. checkout)\n- A central governance team\n- A data consumer (e.g. experiments)\n\nConnecting domain teams to the events they are leveraging offers a clear view of which events they are involved with, helping everyone understand who will be affected by changes.\n\n[Open Workspace Settings](/schemas/default?settings=domains) to get an overview of all existing domains and their team members.\n\n[Learn more in our documentation](https://www.avo.app/docs/data-design/avo-tracking-plan/stakeholder-domains).\n\nComing soon: Domain specific reviews and alerts!"
                                })
                          })
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), viewerCanEdit ? React.createElement(Mantine.Popover.make, {
                        onChange: (function (popupOpen) {
                            Curry._1(setShowAddDomains, (function (param) {
                                    return popupOpen;
                                  }));
                          }),
                        opened: match[0],
                        position: "bottom-start",
                        children: null
                      }, React.createElement(Core.Popover.Target, {
                            children: React.createElement(TextButton.make, {
                                  onClick: (function (param) {
                                      Curry._1(setShowAddDomains, (function (shown) {
                                              return !shown;
                                            }));
                                    }),
                                  style: "Blue",
                                  size: "Small",
                                  children: "+ Add Stakeholder Domain"
                                })
                          }), React.createElement(Mantine.Popover.Dropdown.make, {
                            children: React.createElement(EventDetailsDomains$AddDomainPopup, {
                                  domains: domains$1,
                                  selected: domainsWithEventOptimistic,
                                  onAdd: handleAdd,
                                  viewerRole: viewerRole
                                })
                          })) : null), React.createElement("div", {
                  className: domains
                }, Curry._2(Domains.mapToArray, domainsWithEventOptimistic, (function (domain) {
                        return React.createElement(EventDetailsDomains$DomainPopup, {
                                    domain: domain,
                                    onRemove: handleRemove,
                                    key: domain.id
                                  });
                      }))));
}

var make = EventDetailsDomains;

export {
  make ,
}
/* popupOptionStyles Not a pure module */
