// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Property from "./Property.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as AutofillInputNew from "./AutofillInputNew.mjs";
import * as NewPropertyModal from "./NewPropertyModal.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as AutoFillCasingDetail from "./AutoFillCasingDetail.mjs";
import * as AutoFillInputCreateItem from "./AutoFillInputCreateItem.mjs";

function getId(item) {
  return item.VAL.id;
}

function messageToString(msg) {
  if (msg.NAME === "AlreadyExists") {
    var match = msg.VAL;
    return "" + match[0] + "-" + getId(match[1]) + "";
  }
  var match$1 = msg.VAL;
  return "" + match$1[0] + "-" + match$1[1].id + "";
}

var PropertyAutofillInput = AutofillInputNew.Make({
      getId: getId,
      messageToString: messageToString
    });

var rowHoverStyle_0 = Css.backgroundColor(Styles.Color.light02);

var rowHoverStyle_1 = {
  hd: Css.color(Styles.Color.deepBlue),
  tl: /* [] */0
};

var rowHoverStyle = {
  hd: rowHoverStyle_0,
  tl: rowHoverStyle_1
};

function getAllOptions(options) {
  return Belt_Array.concatMany(Belt_Array.mapU(options, (function (param) {
                    return param[1];
                  })));
}

function queryProperties(options, currentProperties, currentGroups, propertyType, isGroupProperty, groupTypes, query) {
  var options$1 = Belt_Array.mapU(options, (function (param) {
          return [
                  param[0],
                  Belt_Array.keepU(param[1], (function (option) {
                          if (option.NAME === "Group") {
                            var group = option.VAL;
                            return !Belt_Array.someU(currentGroups, (function (item) {
                                          return item.id === group.id;
                                        }));
                          }
                          var property = option.VAL;
                          return !Belt_Array.some(currentProperties, (function (prop) {
                                        if (prop.id === property.id) {
                                          return true;
                                        } else {
                                          return prop.name === property.name;
                                        }
                                      }));
                        }))
                ];
        }));
  var results = Belt_Array.map(options$1, (function (param) {
          var options = param[1];
          return [
                  param[0],
                  Belt_Array.map(Belt_Array.keepMap(Fuzzaldrin.filter(Belt_Array.map(Belt_Array.keep(options, (function (option) {
                                          if (propertyType !== undefined) {
                                            if (option.NAME === "Group") {
                                              return /* EventProperty */1 === propertyType;
                                            } else {
                                              return Caml_obj.equal(option.VAL.sendAs, propertyType);
                                            }
                                          } else {
                                            return true;
                                          }
                                        })), (function (x) {
                                      if (x.NAME === "Group") {
                                        var group = x.VAL;
                                        return {
                                                type_: "group",
                                                id: group.id,
                                                name: group.name
                                              };
                                      }
                                      var property = x.VAL;
                                      return {
                                              type_: "property",
                                              id: property.id,
                                              name: property.name
                                            };
                                    })), query, {
                                key: "name",
                                maxResults: 100
                              }), (function (item) {
                              var match = item.type_;
                              switch (match) {
                                case "group" :
                                    return Belt_Array.getBy(options, (function (x) {
                                                  if (typeof x === "object" && x.NAME === "Group") {
                                                    return item.id === x.VAL.id;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                case "property" :
                                    return Belt_Array.getBy(options, (function (x) {
                                                  if (typeof x === "object" && x.NAME === "Property") {
                                                    return item.id === x.VAL.id;
                                                  } else {
                                                    return false;
                                                  }
                                                }));
                                default:
                                  return ;
                              }
                            })), (function (item) {
                          return {
                                  NAME: "Item",
                                  VAL: item
                                };
                        }))
                ];
        }));
  var alreadyDefined = Belt_Option.isSome(Belt_Array.getBy(getAllOptions(options$1), (function (x) {
              if (x.NAME === "Group") {
                return false;
              } else {
                return Case.camel(x.VAL.name) === Case.camel(query);
              }
            })));
  var nameConflict = Belt_Array.getBy(getAllOptions(options$1), (function (x) {
          if (x.NAME === "Group") {
            return false;
          }
          var property = x.VAL;
          var match = property.sendAs;
          var match$1 = Case.camel(property.name) === Case.camel(query);
          if (propertyType !== undefined && typeof propertyType === "number") {
            switch (propertyType) {
              case /* SystemProperty */0 :
                  if (match !== 0) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case /* EventProperty */1 :
                  if (match !== 1) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              case /* UserProperty */2 :
                  if (typeof match !== "number") {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  if (match < 2) {
                    if (match$1) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  break;
              
            }
          }
          if (typeof match === "number" && match$1 && isGroupProperty) {
            return true;
          } else {
            return false;
          }
        }));
  var alreadyAdded = Belt_Array.getBy(currentProperties, (function (prop) {
          return Case.camel(prop.name) === Case.camel(query);
        }));
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (alreadyDefined || nameConflict !== undefined) {
    exit$1 = 2;
  } else if (alreadyAdded !== undefined) {
    exit = 1;
  } else {
    tmp = query !== "" && isGroupProperty ? Belt_Array.map(groupTypes, (function (param) {
              return {
                      NAME: "CreateNewWithGrouping",
                      VAL: [
                        query,
                        param.id
                      ]
                    };
            })) : (
        query !== "" ? [{
              NAME: "CreateNew",
              VAL: query
            }] : []
      );
  }
  if (exit$1 === 2) {
    if (nameConflict !== undefined) {
      tmp = [{
          NAME: "Message",
          VAL: {
            NAME: "AlreadyExists",
            VAL: [
              query,
              nameConflict
            ]
          }
        }];
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = alreadyAdded !== undefined ? [{
          NAME: "Message",
          VAL: {
            NAME: "AlreadyAdded",
            VAL: [
              query,
              alreadyAdded
            ]
          }
        }] : [];
  }
  return Belt_Array.concat(results, [[
                undefined,
                tmp
              ]]);
}

function PropertyInput(Props) {
  var propertyType = Props.propertyType;
  var isGroupPropertyOpt = Props.isGroupProperty;
  var currentPropertiesOpt = Props.currentProperties;
  var currentGroupsOpt = Props.currentGroups;
  var eventName = Props.eventName;
  var eventId = Props.eventId;
  var options = Props.options;
  var onSelect = Props.onSelect;
  var canCreateOpt = Props.canCreate;
  var extraCreateActions = Props.extraCreateActions;
  var onCreateStartOpt = Props.onCreateStart;
  var onCreateSuccessOpt = Props.onCreateSuccess;
  var currentFilters = Props.currentFilters;
  var getIndexOfValue = Props.getIndexOfValue;
  var renderButton = Props.renderButton;
  var keepExpandedOpt = Props.keepExpanded;
  var parent = Props.parent;
  var sendActions = Props.sendActions;
  var filters = Props.filters;
  var propertyLocation = Props.propertyLocation;
  var isGroupProperty = isGroupPropertyOpt !== undefined ? isGroupPropertyOpt : false;
  var currentProperties = currentPropertiesOpt !== undefined ? currentPropertiesOpt : /* [] */0;
  var currentGroups = currentGroupsOpt !== undefined ? currentGroupsOpt : [];
  var canCreate = canCreateOpt !== undefined ? canCreateOpt : false;
  var onCreateStart = onCreateStartOpt !== undefined ? onCreateStartOpt : (function (param) {
        
      });
  var onCreateSuccess = onCreateSuccessOpt !== undefined ? onCreateSuccessOpt : (function (_id, _name, _branch) {
        
      });
  var keepExpanded = keepExpandedOpt !== undefined ? keepExpandedOpt : false;
  var model = ModelStore.useModel(undefined);
  var modelMapped = ModelStore.Mapped.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedPropertyCase = match.expectedPropertyCase;
  var hasAnyValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("AnyValues");
  var hasLongValuesFeatureFlag = AppFeatureFlag.useFeatureFlag("LongValues");
  var match$1 = GlobalStateContext.use(undefined);
  var match$2 = React.useState(function () {
        return true;
      });
  var setAutoCorrect = match$2[1];
  var autoCorrect = match$2[0];
  var canDisableAutoCorrect = !Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case);
  var sourceFilterNames = StateFilterUtils.getSourceFilterNames__mapped(match$1.filters, modelMapped.sources);
  var currentProperties$1 = React.useMemo((function () {
          return Belt_List.toArray(AvoModel.resolveProperties(model, currentProperties));
        }), [
        model.properties,
        currentProperties
      ]);
  var tmp;
  if (propertyType !== undefined) {
    if (typeof propertyType === "number") {
      switch (propertyType) {
        case /* SystemProperty */0 :
            tmp = "Add System Property...";
            break;
        case /* EventProperty */1 :
            tmp = "Add Event Property...";
            break;
        case /* UserProperty */2 :
            tmp = "Add User Property...";
            break;
        
      }
    } else {
      tmp = "Add Group Property...";
    }
  } else {
    tmp = "Add Property...";
  }
  var partial_arg = model.groupTypes;
  var tmp$1 = {
    onSelect: (function (item, query, param) {
        if (item.NAME === "Group") {
          return Curry._2(onSelect, {
                      NAME: "Group",
                      VAL: item.VAL
                    }, query);
        }
        var property = item.VAL;
        var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
        Curry._2(onSelect, {
              NAME: "Property",
              VAL: property
            }, NameUtils.getCorrectCase(expectedPropertyCase, query));
        AnalyticsRe.propertyAdded(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_Option.getWithDefault(eventId, "N/A"), Belt_Option.getWithDefault(eventName, "N/A"), propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, property.name.length, "Existing", NameUtils.getCorrectCase("Sentence", property.name).split(" ").length, query.length, "EventDetails", schemaBundle.branchId, schemaBundle.schemaId);
      }),
    onCreate: (function (name, maybeGroupTypeId) {
        var sendAs = maybeGroupTypeId !== undefined ? (
            isGroupProperty ? /* GroupProperty */({
                  _0: maybeGroupTypeId
                }) : (
                propertyType !== undefined ? propertyType : /* EventProperty */1
              )
          ) : (
            propertyType !== undefined ? propertyType : /* EventProperty */1
          );
        var name$1 = autoCorrect ? NameUtils.getCorrectCase(expectedPropertyCase, name) : name;
        var isValid = autoCorrect ? NewPropertyModal.isValid(name$1, expectedPropertyCase, modelMapped) : NewPropertyModal.isValid(name$1, Case.of_(name$1), modelMapped);
        if (!isValid) {
          return Pervasives.failwith("Can't create property from autofillinput with invalid name");
        }
        var propertyId = Nanoid.nanoid();
        var correctCase = NameUtils.isCorrectCase(expectedPropertyCase, name$1);
        Curry._1(onCreateStart, undefined);
        NewPropertyModal.createProperty(propertyId, parent, sendActions, sendAs, name$1, Case.of_(name$1), correctCase, addToast, schemaBundle, filters, sourceFilterNames, propertyLocation, model, onCreateSuccess, extraCreateActions, currentFilters, undefined);
      }),
    placeholder: tmp,
    search: (function (param) {
        return queryProperties(options, currentProperties$1, currentGroups, propertyType, isGroupProperty, partial_arg, param);
      }),
    renderItem: (function (item, isActive) {
        var variant = item.NAME;
        if (variant === "CreateNew") {
          if (canCreate) {
            return React.createElement(AutoFillInputCreateItem.make, {
                        name: item.VAL,
                        autoCorrect: autoCorrect,
                        correctCasing: expectedPropertyCase,
                        isActive: isActive
                      });
          } else {
            return null;
          }
        }
        if (variant === "CreateNewWithGrouping") {
          if (!canCreate) {
            return null;
          }
          var match = item.VAL;
          return React.createElement("div", {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.button),
                            tl: {
                              hd: Curry._1(Css.style, Belt_List.concat(isActive ? rowHoverStyle : ({
                                            hd: Css.hover(rowHoverStyle),
                                            tl: /* [] */0
                                          }), {
                                        hd: Css.width(Css.pct(100)),
                                        tl: {
                                          hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.light01),
                                            tl: {
                                              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light01),
                                              tl: {
                                                hd: Css.textAlign("left"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.color(Styles.Color.light10),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.small),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, "\"" + match[0] + "\" not found"), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Css.px(14)),
                                  tl: {
                                    hd: Css.paddingTop(Css.px(2)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, "Create New " + Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(match[1], model), "") + " Property"));
        }
        if (variant === "Item") {
          var match$1 = item.VAL;
          if (match$1.NAME === "Group") {
            var group = match$1.VAL;
            return React.createElement("div", {
                        className: Curry._1(Css.style, Belt_List.concat({
                                  hd: Css.padding(Css.px(12)),
                                  tl: {
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", isActive ? Styles.Color.lightBlue : Styles.Color.light02),
                                      tl: {
                                        hd: Css.margin2(Css.px(4), Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }, isActive ? rowHoverStyle : ({
                                      hd: Css.hover(rowHoverStyle),
                                      tl: /* [] */0
                                    })))
                      }, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("row"),
                                    tl: {
                                      hd: Css.alignItems("baseline"),
                                      tl: {
                                        hd: Css.flexWrap("wrap"),
                                        tl: {
                                          hd: Css.color(isActive ? Styles.Color.darkBlue : Styles.Color.light12),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: group.name
                              }), React.createElement(Spacer.make, {
                                width: 4
                              }), React.createElement($$Text.make, {
                                size: "Tiny",
                                weight: "Semi",
                                color: isActive ? Styles.Color.darkBlue : Styles.Color.light08,
                                children: "(" + String(Belt_List.length(group.properties)) + " Properties)"
                              })), group.description === "" ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                  height: 2
                                }), React.createElement($$Text.make, {
                                  size: "Small",
                                  children: group.description
                                })));
          }
          var item$1 = match$1.VAL;
          var groupTypeId = item$1.sendAs;
          var tmp;
          if (typeof groupTypeId === "number") {
            switch (groupTypeId) {
              case /* SystemProperty */0 :
                  tmp = "System Property";
                  break;
              case /* EventProperty */1 :
                  tmp = "Event Property";
                  break;
              case /* UserProperty */2 :
                  tmp = "User Property";
                  break;
              
            }
          } else {
            tmp = Belt_Option.mapWithDefault(ModelUtils.getGroupTypeName(groupTypeId._0, model), "", (function (groupTypeName) {
                    return groupTypeName + " ";
                  })) + "Group Property";
          }
          return React.createElement("div", {
                      className: Curry._1(Css.style, Belt_List.concat({
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.flexDirection("row"),
                                  tl: {
                                    hd: Css.alignItems("baseline"),
                                    tl: {
                                      hd: Css.flexWrap("wrap"),
                                      tl: {
                                        hd: Css.paddingTop(Css.px(12)),
                                        tl: {
                                          hd: Css.paddingRight(Css.px(12)),
                                          tl: {
                                            hd: Css.paddingBottom(Css.px(12)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(20)),
                                              tl: {
                                                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }, isActive ? rowHoverStyle : ({
                                    hd: Css.hover(rowHoverStyle),
                                    tl: /* [] */0
                                  })))
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          children: item$1.name
                        }), Belt_Option.mapWithDefault(item$1.uniqueName, null, (function (uniqueName) {
                            return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light08,
                                            children: "(" + uniqueName + ")"
                                          }));
                          })), React.createElement(Spacer.make, {
                          width: 4
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          color: Styles.Color.light07,
                          children: "– " + tmp
                        }));
        }
        var message = item.VAL;
        var tmp$1;
        if (message.NAME === "AlreadyExists") {
          var match$2 = message.VAL[1];
          if (match$2.NAME === "Group") {
            tmp$1 = "\"" + match$2.VAL.name + "\" already exists as a property bundle.";
          } else {
            var property = match$2.VAL;
            var groupTypeId$1 = property.sendAs;
            var type_;
            if (typeof groupTypeId$1 === "number") {
              switch (groupTypeId$1) {
                case /* SystemProperty */0 :
                    type_ = "a system";
                    break;
                case /* EventProperty */1 :
                    type_ = "an event";
                    break;
                case /* UserProperty */2 :
                    type_ = "an user";
                    break;
                
              }
            } else {
              var groupTypeName = ModelUtils.getGroupTypeName(groupTypeId$1._0, model);
              type_ = groupTypeName !== undefined ? "a " + groupTypeName + " group" : "a group";
            }
            tmp$1 = "\"" + property.name + "\" already exists as " + type_ + " property.";
          }
        } else {
          var match$3 = message.VAL;
          var property$1 = match$3[1];
          if (match$3[0] === property$1.name) {
            var groupTypeId$2 = property$1.sendAs;
            var type_$1;
            if (typeof groupTypeId$2 === "number") {
              switch (groupTypeId$2) {
                case /* SystemProperty */0 :
                    type_$1 = "system";
                    break;
                case /* EventProperty */1 :
                    type_$1 = "event";
                    break;
                case /* UserProperty */2 :
                    type_$1 = "user";
                    break;
                
              }
            } else {
              var groupTypeName$1 = ModelUtils.getGroupTypeName(groupTypeId$2._0, model);
              type_$1 = groupTypeName$1 !== undefined ? "" + groupTypeName$1 + " group" : "group";
            }
            tmp$1 = "The " + type_$1 + " property \"" + property$1.name + "\" has already been added.";
          } else {
            var groupTypeId$3 = property$1.sendAs;
            var type_$2;
            if (typeof groupTypeId$3 === "number") {
              switch (groupTypeId$3) {
                case /* SystemProperty */0 :
                    type_$2 = "system";
                    break;
                case /* EventProperty */1 :
                    type_$2 = "event";
                    break;
                case /* UserProperty */2 :
                    type_$2 = "user";
                    break;
                
              }
            } else {
              var groupTypeName$2 = ModelUtils.getGroupTypeName(groupTypeId$3._0, model);
              type_$2 = groupTypeName$2 !== undefined ? "" + groupTypeName$2 + " group" : "group";
            }
            tmp$1 = "The " + type_$2 + " property \"" + property$1.name + "\" has already been added.";
          }
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding4(Css.px(12), Css.px(15), Css.px(10), Css.px(15)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.light01),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, tmp$1);
      }),
    renderDetails: (function (item) {
        var variant = item.NAME;
        var tmp;
        if (variant === "CreateNew") {
          var label = item.VAL;
          tmp = NameUtils.isCorrectCase(expectedPropertyCase, label) ? null : React.createElement(AutoFillCasingDetail.make, {
                  autoCorrect: autoCorrect,
                  toggleAutoCorrect: (function (param) {
                      Curry._1(setAutoCorrect, (function (v) {
                              return !v;
                            }));
                    }),
                  canDisableAutoCorrect: canDisableAutoCorrect,
                  expectedCase: expectedPropertyCase,
                  name: label,
                  kind: /* Property */0
                });
        } else if (variant === "CreateNewWithGrouping") {
          var match = item.VAL;
          var groupTypeId = match[1];
          tmp = React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.justifyContent("spaceBetween"),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width("fitContent"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Property.GroupTypePill.make, {
                            groupTypeName: Belt_Option.getWithDefault(ModelUtils.getGroupTypeName(groupTypeId, model), groupTypeId)
                          })), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.regular),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.padding2(Css.px(5), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Create New Property \"" + match[0] + "\"")));
        } else if (variant === "Item") {
          var match$1 = item.VAL;
          if (match$1.NAME === "Group") {
            tmp = null;
          } else {
            var item$1 = match$1.VAL;
            var usageCount = Belt_List.length(ModelUtils.eventsSendingProperty(model, undefined, item$1.id));
            tmp = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.height(Css.pct(100)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              })
                        }, item$1.name), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.padding2(Css.px(5), Css.px(0)),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.regular),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, PropertyUtils.printPropertyType(item$1, undefined, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.small),
                                  tl: {
                                    hd: Css.padding2(Css.px(5), Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, item$1.description)), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(5), Css.px(0)),
                              tl: /* [] */0
                            }
                          })
                    }, usageCount !== 0 ? (
                        usageCount !== 1 ? "Used in " + String(usageCount) + " Events" : "Used in 1 Event"
                      ) : "Never used"));
          }
        } else {
          tmp = item.VAL.NAME === "AlreadyExists" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: "Why can't different types of properties have same or similar names in Avo?"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light12,
                      children: "In some analytics platforms and databases it can be very confusing to have different types of properties with the same or similar name. Therefor Avo is preventing you from creating multiple properties with same or similar names."
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light12,
                      children: "If you truly need to have properties with the same name, you can use the name-mapping feature to map two properties to the same name."
                    })) : null;
        }
        return React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(15)),
                          tl: {
                            hd: Css.height(Css.pct(100)),
                            tl: /* [] */0
                          }
                        })
                  }, tmp);
      }),
    refreshKey: [
      currentProperties$1,
      currentGroups,
      getAllOptions(options)
    ],
    keepExpanded: keepExpanded,
    onClose: (function (param) {
        Curry._1(setAutoCorrect, (function (param) {
                return true;
              }));
      })
  };
  if (getIndexOfValue !== undefined) {
    tmp$1.getIndexOfValue = Caml_option.valFromOption(getIndexOfValue);
  }
  if (renderButton !== undefined) {
    tmp$1.renderButton = Caml_option.valFromOption(renderButton);
  }
  return React.createElement(PropertyAutofillInput.make, tmp$1);
}

var make = PropertyInput;

export {
  PropertyAutofillInput ,
  rowHoverStyle ,
  getAllOptions ,
  queryProperties ,
  make ,
}
/* PropertyAutofillInput Not a pure module */
