// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";

function get($$event) {
  var name = $$event.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : $$event.name;
  return Js_string.replaceByRe(/[^\w\s\-]/ig, "", name$1);
}

export {
  get ,
}
/* No side effect */
