// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconTrees(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 48;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 48 48",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPink),
                            tl: /* [] */0
                          }),
                      d: "M36,37H12c-4.9624,0-9-4.0376-9-9c0-3.87988,2.52881-7.31836,6.1416-8.53076 C9.0459,18.95801,9,18.47754,9,18c0-3.06836,1.57373-5.89209,4.15527-7.54346C13.05029,9.94775,13,9.47314,13,9 c0-4.41113,3.58887-8,8-8c3.65186,0,6.83398,2.53809,7.72363,6.02881C33.34961,7.39844,37,11.28076,37,16 c0,1.03076-0.18115,2.03857-0.53955,3.01172C41.20947,19.25244,45,23.19189,45,28C45,32.9624,40.9624,37,36,37z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.avoPinkShade2),
                            tl: /* [] */0
                          }),
                      d: "M28.29297,25.29297L25,28.58594V17c0-0.55225-0.44775-1-1-1s-1,0.44775-1,1v6.58594l-3.29297-3.29297 c-0.39062-0.39062-1.02344-0.39062-1.41406,0s-0.39062,1.02344,0,1.41406L23,26.41406V46c0,0.55225,0.44775,1,1,1s1-0.44775,1-1 V31.41406l4.70703-4.70703c0.39062-0.39062,0.39062-1.02344,0-1.41406S28.68359,24.90234,28.29297,25.29297z"
                    }), React.createElement("path", {
                      className: Curry._1(Css.style, {
                            hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                            tl: /* [] */0
                          }),
                      d: "M34,47H14c-0.55225,0-1-0.44775-1-1s0.44775-1,1-1h20c0.55225,0,1,0.44775,1,1S34.55225,47,34,47z"
                    })));
}

var make = IconTrees;

export {
  make ,
}
/* Css Not a pure module */
