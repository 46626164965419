// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";

var userAgent = window.navigator.userAgent.toLowerCase();

var os = Js_string.includes("windows", userAgent) ? "Windows" : (
    /(mac|ipod|iphone|ipad)/g.test(userAgent) ? "MacOS" : "Linux"
  );

export {
  os ,
}
/* userAgent Not a pure module */
