// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Markdown from "../Markdown.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../../model/src/TrackingPlanModel.mjs";
import * as TriggerFullscreenModal from "../TriggerFullscreenModal.mjs";

var root = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(4)),
      tl: /* [] */0
    });

var header = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.marginLeft(Css.px(30)),
            tl: /* [] */0
          }
        }
      }
    });

var variants = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.gap(Css.px(16)),
          tl: {
            hd: Css.paddingLeft(Css.px(60)),
            tl: {
              hd: Css.paddingBottom(Css.px(16)),
              tl: {
                hd: Css.paddingRight(Css.px(20)),
                tl: {
                  hd: Css.overflowX("auto"),
                  tl: {
                    hd: Css.marginTop(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var variant = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.minWidth(Css.px(160)),
              tl: {
                hd: Css.height(Css.px(108)),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.flexDirection("column"),
                    tl: {
                      hd: Css.gap(Css.px(8)),
                      tl: {
                        hd: Css.padding(Css.px(12)),
                        tl: {
                          hd: Css.borderRadius(Css.px(8)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light01),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.borderColor(Styles.Color.light06),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function EventDetailsVariants(Props) {
  var $$event = Props.event;
  var viewerCanEdit = Props.viewerCanEdit;
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var availability = AvoLimits.Triggers.computeAvailability(workspace);
  var handleNewVariant = function (param) {
    if (availability === "Unavailable") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "AddEventVariant"
                  }
                });
    } else {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "CreateEventVariant",
                    VAL: $$event.id
                  }
                });
    }
  };
  return React.createElement("section", {
              className: root
            }, React.createElement("header", {
                  className: header
                }, React.createElement(Icon.make, {
                      type_: "equalizer",
                      color: Styles.Color.light07
                    }), React.createElement(Spacer.make, {
                      width: 14
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: "Variants"
                    }), React.createElement(Tooltip.WithAnalytics.make, {
                      fromItem: {
                        itemId: $$event.id,
                        itemName: $$event.name,
                        itemType: "Event"
                      },
                      title: "Event Variants",
                      children: React.createElement(Tooltip.Content.make, {
                            children: React.createElement(Markdown.make, {
                                  source: "Event Variants allow you to create context specific variations of your event. You can tailor the structure of your event to a specific context without impacting other instances of the event.\n\nYou can add properties to only apply to that variant and define variant-specific property presence and value rules.\n\nRead more in our [event variant docs](https://www.avo.app/docs/data-design/avo-tracking-plan/event-variants)."
                                })
                          })
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), viewerCanEdit ? React.createElement(TextButton.make, {
                        onClick: handleNewVariant,
                        style: "Blue",
                        size: "Small",
                        children: null
                      }, React.createElement(TriggerFullscreenModal.Lightning.make, {
                            color: Styles.Color.darkBlue
                          }), "+ New Variant") : null), React.createElement("div", {
                  className: variants
                }, Belt_Array.map($$event.variants, (function (variant$1) {
                        return React.createElement("button", {
                                    key: variant$1.id,
                                    className: variant,
                                    onClick: (function (param) {
                                        Router.Schema.pushDrawerItem(undefined, undefined, {
                                              NAME: "eventVariant",
                                              VAL: [
                                                TrackingPlanModel.EventVariant.makeVariantIdentifier(variant$1),
                                                undefined
                                              ]
                                            });
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        children: variant$1.nameSuffix
                                      }));
                      }))));
}

var make = EventDetailsVariants;

export {
  make ,
}
/* root Not a pure module */
