// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Mantine from "./Mantine.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AvoProductTour__Root from "./productTour/AvoProductTour__Root.mjs";
import * as AvoProductTour__Step from "./productTour/AvoProductTour__Step.mjs";
import * as AvoProductTour__GenericStep from "./productTour/AvoProductTour__GenericStep.mjs";

function markAsSeen(param) {
  var userId = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).uid;
  return Firebase.app(undefined).firestore().collection("users").doc(userId).set({
              createMoreTourSeen: true
            }, {"merge": true});
}

function use(param) {
  var match = AvoEngagement.Engagement.use("create-more");
  var intent = match[1];
  var viewer = ViewerContext.use(undefined);
  React.useEffect((function () {
          if (Belt_Option.getWithDefault(Caml_option.nullable_to_opt(viewer.createMoreTourSeen), false)) {
            return ;
          }
          var id = setTimeout((function (param) {
                  Curry._1(intent.start, undefined);
                }), 300);
          return (function (param) {
                    clearTimeout(id);
                  });
        }), [viewer]);
}

function CreateMoreProductTour(Props) {
  return React.createElement(AvoProductTour__Root.make, {
              engagementId: "create-more",
              onComplete: (function (param) {
                  markAsSeen(undefined);
                }),
              showStepCount: false,
              children: React.createElement(AvoProductTour__Step.make, {
                    blockId: "step-1",
                    borderRadius: 5,
                    elementPadding: 6,
                    position: "bottom",
                    stepType: {
                      TAG: /* OnElementId */0,
                      _0: "create-more"
                    },
                    transition: Mantine.Transition.subtleUpSlide,
                    transitionDuration: 400,
                    transitions: [{
                        key: "complete",
                        label: "Got it!",
                        icon: undefined
                      }],
                    children: React.createElement(AvoProductTour__GenericStep.make, {
                          withExit: false
                        })
                  })
            });
}

var make = CreateMoreProductTour;

export {
  markAsSeen ,
  use ,
  make ,
}
/* react Not a pure module */
