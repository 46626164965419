// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ViewerContext from "./ViewerContext.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as GettingStarted__Types from "./GettingStarted__Types.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as GettingStarted__ProgressStore from "./GettingStarted__ProgressStore.mjs";

function GettingStarted__Progress$WatchAddYourFirstEventToTheTrackingPlan(Props) {
  var onCompleted = Props.onCompleted;
  var match = SchemaBundleContext.use(undefined);
  var match$1 = ViewerContext.use(undefined);
  var userId = match$1.id;
  var alreadyCompleted = React.useRef(false);
  var addEventAction = FirebaseFetcherHooks.useAction(match.schemaId, (function (q) {
          return q.where("createdBy", "==", userId).limit(1);
        }), "AddEvent");
  React.useEffect((function () {
          if (typeof addEventAction === "object" && addEventAction.VAL !== undefined && !alreadyCompleted.current) {
            Curry._1(onCompleted, undefined);
            alreadyCompleted.current = true;
          }
          
        }), [addEventAction]);
  return null;
}

var WatchAddYourFirstEventToTheTrackingPlan = {
  make: GettingStarted__Progress$WatchAddYourFirstEventToTheTrackingPlan
};

function GettingStarted__Progress$WatchSetUpInspector(Props) {
  var onCompleted = Props.onCompleted;
  var stats = InspectorStats.useStatsWithCache(undefined);
  var alreadyCompleted = React.useRef(false);
  React.useEffect((function () {
          if (typeof stats === "number" || !(stats._0.length !== 0 && !alreadyCompleted.current)) {
            
          } else {
            Curry._1(onCompleted, undefined);
            alreadyCompleted.current = true;
          }
        }), [stats]);
  return null;
}

var WatchSetUpInspector = {
  make: GettingStarted__Progress$WatchSetUpInspector
};

function GettingStarted__Progress$WatchAddEventsFromInspector(Props) {
  var onCompleted = Props.onCompleted;
  var match = SchemaBundleContext.use(undefined);
  var match$1 = ViewerContext.use(undefined);
  var userId = match$1.id;
  var alreadyCompleted = React.useRef(false);
  var importAction = FirebaseFetcherHooks.useAction(match.schemaId, (function (q) {
          return q.where("createdBy", "==", userId).limit(1);
        }), "CompletedImport");
  React.useEffect((function () {
          if (typeof importAction === "object" && importAction.NAME === "Loaded") {
            var rawAction = importAction.VAL;
            if (rawAction !== undefined && !alreadyCompleted.current) {
              var match = Parser.parseAction(Caml_option.valFromOption(rawAction).contentsJson);
              if (typeof match === "object" && match.NAME === "CompletedImport") {
                var match$1 = match.VAL[4];
                if (match$1 === "inspectorIssuesHeader" || match$1 === "inspectorEventsTableRow" || match$1 === "inspectorEventsSidebar" || match$1 === "inspectorIssueDetails" || match$1 === "inspectorEventsHeader") {
                  Curry._1(onCompleted, undefined);
                  alreadyCompleted.current = true;
                }
                
              }
              
            }
            
          }
          
        }), [importAction]);
  return null;
}

var WatchAddEventsFromInspector = {
  make: GettingStarted__Progress$WatchAddEventsFromInspector
};

function GettingStarted__Progress$WatchSetUpAlertsForYourMostImportantEvents(Props) {
  var onCompleted = Props.onCompleted;
  var savedViews = InspectorIssuesFilterHooks.SavedViewStore.useSavedViews(undefined);
  var match = React.useState(function () {
        
      });
  var setEventTypes = match[1];
  var eventTypes = match[0];
  var match$1 = ViewerContext.use(undefined);
  var userId = match$1.id;
  var match$2 = SchemaBundleContext.use(undefined);
  var alreadyCompleted = React.useRef(false);
  React.useEffect((function () {
          if (typeof savedViews === "object") {
            var savedViews$1 = Belt_List.keepU(savedViews.VAL, (function (savedView) {
                    return savedView.createdBy === userId;
                  }));
            if (savedViews$1 !== /* [] */0) {
              Curry._1(setEventTypes, (function (param) {
                      return Belt_List.toArray(Belt_List.mapU(savedViews$1, (function (param) {
                                        return /* SavedView */{
                                                _0: param.id
                                              };
                                      })));
                    }));
            }
            
          }
          
        }), [savedViews]);
  var channels = FirebaseFetcherHooks.useNotificationsChannels(eventTypes === undefined ? "Wait" : "Fetch", match$2.schemaId, eventTypes !== undefined ? Belt_Array.map(eventTypes, Models.NotificationChannel.printEventType).join(",") : "none", Belt_Option.getWithDefault(eventTypes, []));
  React.useEffect((function () {
          if (typeof channels === "object" && channels.VAL && !alreadyCompleted.current) {
            Curry._1(onCompleted, undefined);
            alreadyCompleted.current = true;
          }
          
        }), [channels]);
  return null;
}

var WatchSetUpAlertsForYourMostImportantEvents = {
  make: GettingStarted__Progress$WatchSetUpAlertsForYourMostImportantEvents
};

function GettingStarted__Progress$WatchTriageAnIssue(Props) {
  var onCompleted = Props.onCompleted;
  var match = SchemaBundleContext.use(undefined);
  var match$1 = ViewerContext.use(undefined);
  var userId = match$1.id;
  var alreadyCompleted = React.useRef(false);
  var triageAction = FirebaseFetcherHooks.useAction(match.schemaId, (function (q) {
          return q.where("createdBy", "==", userId).where("contentsJson.UpdateInspectorIssueStatus.trigger", "==", "user").limit(1);
        }), "UpdateInspectorIssueStatus");
  React.useEffect((function () {
          if (typeof triageAction === "object" && triageAction.VAL !== undefined && !alreadyCompleted.current) {
            Curry._1(onCompleted, undefined);
            alreadyCompleted.current = true;
          }
          
        }), [triageAction]);
  return null;
}

var WatchTriageAnIssue = {
  make: GettingStarted__Progress$WatchTriageAnIssue
};

function GettingStarted__Progress$WatchMergeYourFirstBranch(Props) {
  return null;
}

var WatchMergeYourFirstBranch = {
  make: GettingStarted__Progress$WatchMergeYourFirstBranch
};

function GettingStarted__Progress(Props) {
  var match = WorkspaceContext.use(undefined);
  var schemaId = match.id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = GettingStarted__ProgressStore.use(undefined);
  var handleCompleteStep = match$1.handleCompleteStep;
  var completedSteps = match$1.completedSteps;
  var onboardingChoice = match$1.onboardingChoice;
  var stepCompleted = function (step) {
    return Belt_Option.getWithDefault(Js_dict.get(completedSteps, GettingStarted__Types.StepId.tToJs(step)), false);
  };
  var handleCompleted = function (step, param) {
    Curry._1(handleCompleteStep, step);
    var stepsByChoice = [
      onboardingChoice === "manual" ? "addYourFirstEventToTheTrackingPlan" : "addEventsFromInspector",
      "setUpInspector",
      "setUpAlertsForYourMostImportantEvents",
      "triageAnIssue",
      "completeDemoBranchTour"
    ];
    var installStatus = stepCompleted("setUpInspector") ? "EventsReceived" : undefined;
    var stepsCompletedCount = Belt_Array.keep(stepsByChoice, stepCompleted).length;
    AnalyticsRe.onboardingChecklistProgressed(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, stepsCompletedCount / stepsByChoice.length, stepsByChoice.length - stepsCompletedCount | 0, GettingStarted__Types.StepId.toAnalytics(step), Belt_Array.map(Belt_Array.keep(stepsByChoice, (function (step) {
                    return !stepCompleted(step);
                  })), GettingStarted__Types.StepId.toAnalytics), "GettingStarted", schemaBundle.branchId, schemaBundle.schemaId, schemaId, installStatus);
  };
  return React.createElement(React.Fragment, undefined, stepCompleted("addYourFirstEventToTheTrackingPlan") ? null : React.createElement(GettingStarted__Progress$WatchAddYourFirstEventToTheTrackingPlan, {
                    onCompleted: (function (param) {
                        return handleCompleted("addYourFirstEventToTheTrackingPlan", param);
                      })
                  }), stepCompleted("setUpInspector") ? null : React.createElement(GettingStarted__Progress$WatchSetUpInspector, {
                    onCompleted: (function (param) {
                        return handleCompleted("setUpInspector", param);
                      })
                  }), stepCompleted("addEventsFromInspector") ? null : React.createElement(GettingStarted__Progress$WatchAddEventsFromInspector, {
                    onCompleted: (function (param) {
                        return handleCompleted("addEventsFromInspector", param);
                      })
                  }), stepCompleted("setUpAlertsForYourMostImportantEvents") ? null : React.createElement(GettingStarted__Progress$WatchSetUpAlertsForYourMostImportantEvents, {
                    onCompleted: (function (param) {
                        return handleCompleted("setUpAlertsForYourMostImportantEvents", param);
                      })
                  }), stepCompleted("triageAnIssue") ? null : React.createElement(GettingStarted__Progress$WatchTriageAnIssue, {
                    onCompleted: (function (param) {
                        return handleCompleted("triageAnIssue", param);
                      })
                  }), stepCompleted("completeDemoBranchTour") ? null : React.createElement(GettingStarted__Progress$WatchMergeYourFirstBranch, {
                    onCompleted: (function (param) {
                        return handleCompleted("completeDemoBranchTour", param);
                      })
                  }));
}

var make = GettingStarted__Progress;

export {
  WatchAddYourFirstEventToTheTrackingPlan ,
  WatchSetUpInspector ,
  WatchAddEventsFromInspector ,
  WatchSetUpAlertsForYourMostImportantEvents ,
  WatchTriageAnIssue ,
  WatchMergeYourFirstBranch ,
  make ,
}
/* react Not a pure module */
