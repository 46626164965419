// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Asset from "./Asset.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function EmptyState$EmptyStateArtwork(Props) {
  var artwork = Props.artwork;
  if (artwork === "publishing") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(411)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: Asset.getPath("PublishingIntegrationsArtwork.png")
              });
  } else if (artwork === "inspector") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(410)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: Asset.getPath("InspectorArtwork.png")
              });
  } else if (artwork === "inspectorIssues") {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(441)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: Asset.getPath("InspectorIssuesArtwork.png")
              });
  } else {
    return React.createElement("img", {
                className: Curry._1(Css.style, {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.maxWidth(Css.px(441)),
                        tl: {
                          hd: Css.objectFit("cover"),
                          tl: /* [] */0
                        }
                      }
                    }),
                src: Asset.getPath("CodegenArtwork.png")
              });
  }
}

var EmptyStateArtwork = {
  make: EmptyState$EmptyStateArtwork
};

function EmptyState$LinkButtonWithIcon(Props) {
  var url = Props.url;
  var icon = Props.icon;
  var label = Props.label;
  var emptyStateLocation = Props.emptyStateLocation;
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement("a", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.padding(Css.px(4)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(8)),
                            tl: {
                              hd: Css.background(Styles.Color.white),
                              tl: {
                                hd: Css.borderRadius(Css.px(5)),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.textDecoration("none"),
                                    tl: {
                                      hd: Css.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.borderColor(Styles.Color.light07),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: {
                                          hd: Css.transition({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "border-color"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              href: url,
              rel: "noopener",
              target: "_blank",
              onClick: (function (param) {
                  AnalyticsRe.emptyStateInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, emptyStateLocation, "OpenDocs", url);
                })
            }, icon, React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  size: "Tiny",
                  weight: "Semi",
                  children: label
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(2)),
                        tl: /* [] */0
                      })
                }, React.createElement("svg", {
                      height: "15",
                      width: "11",
                      fill: Styles.Color.toString(Styles.Color.light08),
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("path", {
                          d: "M2.95 10.1l4.14-4.14-.01 3.13h1.14V4h-5.1v1.13h3.14L2.13 9.27l.82.83z"
                        }))));
}

var LinkButtonWithIcon = {
  make: EmptyState$LinkButtonWithIcon
};

function EmptyState$Container(Props) {
  var artwork = Props.artwork;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width({
                          NAME: "subtract",
                          VAL: [
                            Css.pct(100.0),
                            Css.px(96)
                          ]
                        }),
                    tl: {
                      hd: Css.maxWidth(Css.px(1200)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.borderRadius(Css.px(5)),
                            tl: {
                              hd: Css.padding(Css.px(48)),
                              tl: {
                                hd: Css.paddingBottom(Css.px(60)),
                                tl: {
                                  hd: Css.display("grid"),
                                  tl: {
                                    hd: Css.columnGap(Css.px(32)),
                                    tl: {
                                      hd: Css.gridTemplateColumns({
                                            hd: {
                                              NAME: "minmax",
                                              VAL: [
                                                Css.px(440),
                                                {
                                                  NAME: "fr",
                                                  VAL: 1.0
                                                }
                                              ]
                                            },
                                            tl: {
                                              hd: {
                                                NAME: "minmax",
                                                VAL: [
                                                  Css.px(164),
                                                  {
                                                    NAME: "fr",
                                                    VAL: 1.0
                                                  }
                                                ]
                                              },
                                              tl: /* [] */0
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(440)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.alignItems("flexStart"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, children), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(EmptyState$EmptyStateArtwork, {
                      artwork: artwork
                    })));
}

var Container = {
  make: EmptyState$Container
};

export {
  EmptyStateArtwork ,
  LinkButtonWithIcon ,
  Container ,
}
/* Css Not a pure module */
