// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function cmp(a, b) {
  return a === b;
}

function widowless(str) {
  return str.replace(/(\s|^)(\S+)$/, "" + String.fromCharCode(160) + "$2");
}

function addZeroWidthBreaksAfterChar($$char, re, str) {
  var re$1 = Belt_Option.getWithDefault(re, new RegExp($$char, "g"));
  return str.replace(re$1, "" + $$char + "" + String.fromCharCode(8203) + "");
}

function normalizeNewlines(string) {
  return string.replace(/(\r\n|\n|\r)/g, "\n");
}

function joinToSentence(stringsToJoin) {
  if (stringsToJoin.length <= 1) {
    return Belt_Option.getWithDefault(Belt_Array.get(stringsToJoin, 0), "");
  }
  var allButLast = Belt_Array.slice(stringsToJoin, 0, stringsToJoin.length - 1 | 0).join(", ");
  var last = Belt_Array.getExn(Belt_Array.slice(stringsToJoin, -1, 1), 0);
  return "" + allButLast + " and " + last + "";
}

function capitalizeFirstLetter(str) {
  return str.substr(0, 1).toLocaleUpperCase() + str.substring(1);
}

export {
  cmp ,
  widowless ,
  addZeroWidthBreaksAfterChar ,
  normalizeNewlines ,
  joinToSentence ,
  capitalizeFirstLetter ,
}
/* No side effect */
