// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Changes from "../../shared/Changes.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";
import * as Collaborators from "./Collaborators.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as AppVersionsStore from "./AppVersionsStore.mjs";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchActionBox__Utils from "./BranchActionBox__Utils.mjs";
import * as EditCollaboratorsPopup from "./EditCollaboratorsPopup.mjs";
import * as ImplementationStatusContext from "./ImplementationStatusContext.mjs";
import * as FetchImplementationStatusHooks from "./implementationStatus/FetchImplementationStatusHooks.mjs";
import * as InspectorVersionValidationIntentHelpers from "./InspectorVersionValidationIntentHelpers.mjs";

function BranchActionBox__Components$Container(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.border(Css.px(1), Css.solid, Styles.Color.light06),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.overflow(Css.hidden),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, children);
}

var Container = {
  make: BranchActionBox__Components$Container
};

function BranchActionBox__Components$MainContent(Props) {
  var icon = Props.icon;
  var colorSchemeOpt = Props.colorScheme;
  var children = Props.children;
  var colorScheme = colorSchemeOpt !== undefined ? colorSchemeOpt : "black";
  var match = colorScheme === "orange" ? [
      Styles.Color.deepOrange,
      Styles.Color.orange
    ] : (
      colorScheme === "purple" ? [
          Styles.Color.deepPurple,
          Styles.Color.palePurple
        ] : (
          colorScheme === "red" ? [
              Styles.Color.deepRed,
              Styles.Color.paleRed
            ] : (
              colorScheme === "green" ? [
                  Styles.Color.white,
                  Styles.Color.darkGreen
                ] : (
                  colorScheme === "black" ? [
                      Styles.Color.light12,
                      Styles.Color.light06
                    ] : [
                      Styles.Color.deepBlue,
                      Styles.Color.paleBlue
                    ]
                )
            )
        )
    );
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.gap(Css.px(12)),
                          tl: /* [] */0
                        }
                      })
                }, icon !== undefined ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding(Css.px(4)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: {
                                      hd: Css.borderRadius(Css.px(100)),
                                      tl: {
                                        hd: Css.backgroundColor(match[1]),
                                        tl: {
                                          hd: Css.height(Css.pct(100.0)),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, React.createElement(Icon.make, {
                            type_: icon,
                            size: {
                              NAME: "int",
                              VAL: 24
                            },
                            color: match[0]
                          })) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }
                          })
                    }, children)));
}

var MainContent = {
  make: BranchActionBox__Components$MainContent
};

function BranchActionBox__Components$ApprovalState$Container(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var Container$1 = {
  make: BranchActionBox__Components$ApprovalState$Container
};

function BranchActionBox__Components$ApprovalState$InfoText(Props) {
  var weight = Props.weight;
  var children = Props.children;
  var tmp = {
    size: "Small",
    color: Styles.Color.light11,
    children: children
  };
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement($$Text.make, tmp);
}

var InfoText = {
  make: BranchActionBox__Components$ApprovalState$InfoText
};

function BranchActionBox__Components$ApprovalState$ApprovedWithBranchDiscrepancies(Props) {
  var branchAuditWarningCount = Props.branchAuditWarningCount;
  return React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.darkRed,
              children: "Fix " + AvoUtils.plural(undefined, undefined, branchAuditWarningCount, "audit issue") + " before merging"
            });
}

var ApprovedWithBranchDiscrepancies = {
  make: BranchActionBox__Components$ApprovalState$ApprovedWithBranchDiscrepancies
};

function BranchActionBox__Components$ApprovalState$ApprovedWithMergeConflicts(Props) {
  return React.createElement($$Text.make, {
              size: "Small",
              weight: "Semi",
              color: Styles.Color.light11,
              children: "Resolve conflicts before merging"
            });
}

var ApprovedWithMergeConflicts = {
  make: BranchActionBox__Components$ApprovalState$ApprovedWithMergeConflicts
};

function BranchActionBox__Components$ApprovalState$Approvals(Props) {
  var numAdminApprovals = Props.numAdminApprovals;
  var numApprovals = Props.numApprovals;
  var numNonEditorApprovals = Props.numNonEditorApprovals;
  var requireAdminApproval = Props.requireAdminApproval;
  if (requireAdminApproval) {
    return React.createElement(React.Fragment, undefined, numNonEditorApprovals > 0 ? React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                      children: null
                    }, React.createElement(Icon.make, {
                          type_: "checkmark",
                          size: "small",
                          color: Styles.Color.light11
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: AvoUtils.plural(undefined, undefined, numNonEditorApprovals, "editor approval")
                        })) : null, numAdminApprovals > 0 ? React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                      children: null
                    }, React.createElement(Icon.make, {
                          type_: "checkmark",
                          size: "small",
                          color: Styles.Color.darkGreen
                        }), React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: AvoUtils.plural(undefined, undefined, numAdminApprovals, "admin approval")
                        })) : null);
  } else if (numApprovals > 0) {
    return React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                children: null
              }, React.createElement(Icon.make, {
                    type_: "checkmark",
                    size: "small",
                    color: Styles.Color.darkGreen
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: AvoUtils.plural(undefined, undefined, numApprovals, "approval")
                  }));
  } else {
    return null;
  }
}

var Approvals = {
  make: BranchActionBox__Components$ApprovalState$Approvals
};

function BranchActionBox__Components$ApprovalState$Pending(Props) {
  var numAdminApprovals = Props.numAdminApprovals;
  var numApprovals = Props.numApprovals;
  var numRequiredApprovals = Props.numRequiredApprovals;
  var requireAdminApproval = Props.requireAdminApproval;
  var reviewerLevel = Props.reviewerLevel;
  if (requireAdminApproval && numAdminApprovals < numRequiredApprovals) {
    return React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                children: "" + AvoUtils.plural(undefined, undefined, numRequiredApprovals - numAdminApprovals | 0, "" + reviewerLevel + " approval") + " needed"
              });
  } else if (numApprovals < numRequiredApprovals) {
    return React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                children: "" + AvoUtils.plural(undefined, undefined, numRequiredApprovals - numApprovals | 0, "" + reviewerLevel + " approval") + " needed"
              });
  } else {
    return null;
  }
}

var Pending = {
  make: BranchActionBox__Components$ApprovalState$Pending
};

function BranchActionBox__Components$ApprovalState$Approved(Props) {
  var approveActions = Props.approveActions;
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchStatus = Props.branchStatus;
  var includeApprovals = Props.includeApprovals;
  var members = Props.members;
  var mergeConflicts = Props.mergeConflicts;
  var numRequiredApprovals = Props.numRequiredApprovals;
  var requireAdminApproval = Props.requireAdminApproval;
  var match = BranchDiscrepancies.use(undefined);
  var branchAuditWarningCount = match.branchAuditWarningCount;
  var numApprovals = Belt_List.length(approveActions);
  var numAdminApprovals = Belt_List.length(Belt_List.keep(approveActions, (function (action) {
              return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_List.getByU(members, (function (member) {
                                    return member.id === action.createdBy;
                                  })), (function (member) {
                                return Caml_obj.equal(Models.Role.tFromJs(member.role), "Admin");
                              })), false);
            })));
  var numNonEditorApprovals = numApprovals - numAdminApprovals | 0;
  var reviewerLevel = requireAdminApproval ? "admin" : "editor/admin";
  var isFullyApproved;
  if (typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6) {
    isFullyApproved = false;
  } else {
    var approveActions$1 = branchStatus._0;
    if (requireAdminApproval) {
      var adminApprovals = Belt_List.keep(approveActions$1, (function (action) {
              return Belt_Option.getWithDefault(Belt_Option.mapU(Belt_List.getByU(members, (function (member) {
                                    return member.id === action.createdBy;
                                  })), (function (member) {
                                return Caml_obj.equal(Models.Role.tFromJs(member.role), "Admin");
                              })), false);
            }));
      isFullyApproved = Belt_List.size(adminApprovals) >= numRequiredApprovals;
    } else {
      isFullyApproved = Belt_List.size(approveActions$1) >= numRequiredApprovals;
    }
  }
  var hasMergeConflicts = mergeConflicts !== /* [] */0;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, isFullyApproved && branchAuditWarningCount > 0 && blockBranchWithEnforcement !== undefined ? React.createElement(BranchActionBox__Components$ApprovalState$ApprovedWithBranchDiscrepancies, {
                    branchAuditWarningCount: branchAuditWarningCount
                  }) : (
                isFullyApproved && hasMergeConflicts ? React.createElement(BranchActionBox__Components$ApprovalState$ApprovedWithMergeConflicts, {}) : React.createElement(React.Fragment, undefined, includeApprovals ? React.createElement(BranchActionBox__Components$ApprovalState$Approvals, {
                              numAdminApprovals: numAdminApprovals,
                              numApprovals: numApprovals,
                              numNonEditorApprovals: numNonEditorApprovals,
                              requireAdminApproval: requireAdminApproval
                            }) : null, React.createElement(BranchActionBox__Components$ApprovalState$Pending, {
                            numAdminApprovals: numAdminApprovals,
                            numApprovals: numApprovals,
                            numRequiredApprovals: numRequiredApprovals,
                            requireAdminApproval: requireAdminApproval,
                            reviewerLevel: reviewerLevel
                          }))
              ));
}

var Approved = {
  make: BranchActionBox__Components$ApprovalState$Approved
};

function BranchActionBox__Components$ApprovalState(Props) {
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchStatus = Props.branchStatus;
  var includeApprovalsOpt = Props.includeApprovals;
  var members = Props.members;
  var mergeConflicts = Props.mergeConflicts;
  var numRequiredApprovals = Props.numRequiredApprovals;
  var requireAdminApproval = Props.requireAdminApproval;
  var role = Props.role;
  var includeApprovals = includeApprovalsOpt !== undefined ? includeApprovalsOpt : true;
  var match = BranchDiscrepancies.use(undefined);
  var branchAuditWarningCount = match.branchAuditWarningCount;
  if (!RoleUtils.canEdit(role)) {
    return React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                children: React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                      weight: "Semi",
                      children: "Only admins and editors can merge branches"
                    })
              });
  }
  if (typeof branchStatus === "number") {
    return null;
  }
  switch (branchStatus.TAG | 0) {
    case /* Open */0 :
    case /* Draft */3 :
        break;
    case /* ReadyForReview */4 :
        return React.createElement(BranchActionBox__Components$ApprovalState$Approved, {
                    approveActions: /* [] */0,
                    blockBranchWithEnforcement: blockBranchWithEnforcement,
                    branchStatus: branchStatus,
                    includeApprovals: includeApprovals,
                    members: members,
                    mergeConflicts: mergeConflicts,
                    numRequiredApprovals: numRequiredApprovals,
                    requireAdminApproval: requireAdminApproval
                  });
    case /* ChangesRequested */5 :
        if (Caml_obj.equal(blockBranchWithEnforcement, "beforeRequestForReview") && branchAuditWarningCount > 0) {
          return React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                      children: React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                            weight: "Semi",
                            children: "Fix " + AvoUtils.plural(undefined, undefined, branchAuditWarningCount, "audit issue") + " before re-requesting a review"
                          })
                    });
        } else {
          return React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                      children: null
                    }, React.createElement(Icon.make, {
                          type_: "warningCircle",
                          size: "small",
                          color: Styles.Color.darkRed
                        }), React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                          weight: "Semi",
                          children: "1 request for changes"
                        }));
        }
    case /* Approved */6 :
        return React.createElement(BranchActionBox__Components$ApprovalState$Approved, {
                    approveActions: branchStatus._0,
                    blockBranchWithEnforcement: blockBranchWithEnforcement,
                    branchStatus: branchStatus,
                    includeApprovals: includeApprovals,
                    members: members,
                    mergeConflicts: mergeConflicts,
                    numRequiredApprovals: numRequiredApprovals,
                    requireAdminApproval: requireAdminApproval
                  });
    default:
      return null;
  }
  if (Caml_obj.equal(blockBranchWithEnforcement, "beforeRequestForReview") && branchAuditWarningCount > 0) {
    return React.createElement(BranchActionBox__Components$ApprovalState$Container, {
                children: React.createElement(BranchActionBox__Components$ApprovalState$InfoText, {
                      weight: "Semi",
                      children: "Fix " + AvoUtils.plural(undefined, undefined, branchAuditWarningCount, "audit issue") + " before requesting a review"
                    })
              });
  } else {
    return null;
  }
}

var ApprovalState = {
  Container: Container$1,
  InfoText: InfoText,
  ApprovedWithBranchDiscrepancies: ApprovedWithBranchDiscrepancies,
  ApprovedWithMergeConflicts: ApprovedWithMergeConflicts,
  Approvals: Approvals,
  Pending: Pending,
  Approved: Approved,
  make: BranchActionBox__Components$ApprovalState
};

function BranchActionBox__Components$MergeButtonSection$MergeButton(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var approvalWorkflow = Props.approvalWorkflow;
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var infoPositionOpt = Props.infoPosition;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var onMergePossible = Props.onMergePossible;
  var role = Props.role;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var infoPosition = infoPositionOpt !== undefined ? infoPositionOpt : "right";
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var match = AppVersionsStore.use(undefined, 2, undefined);
  var appVersions = match[0];
  var match$1 = BranchDiscrepancies.use(undefined);
  var branchAuditWarningCount = match$1.branchAuditWarningCount;
  var branchAuditChecks = match$1.branchAuditChecks;
  var breakingChanges = Hooks.useBreakingChangesReport(changes);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var eventComponentsWithNames = match$2.eventComponentsWithNames;
  var discrepancyConfig = match$2.config;
  var invocationsContext = React.useContext(ImplementationStatusContext.context).codegen;
  var sourceInitStatus = invocationsContext[2];
  var invocationsPromise = FetchImplementationStatusHooks.useInvocationsWithPromise(invocationsContext);
  var maybeSharingLink = FirebaseFetcherHooks.useSharingLink(schemaBundle.schemaId, branchId);
  var match$3 = FirebaseFetcherHooks.useValidationIntents(schemaBundle.schemaId, branchId);
  var validationIntent = match$3[0];
  var blockBranchwithEnforcement = React.useMemo((function () {
          var match = ValidationConfigV2.EventsV2.getCase(discrepancyConfig.events);
          if (DiscrepancyUtils.hasDiscrepencyErrors(typeof match === "object" ? true : false, ValidationConfigV2.EventsV2.hasValueChecking(discrepancyConfig.events), branchAuditChecks)) {
            return discrepancyConfig.pointOfBlock;
          }
          
        }), [
        branchModelWithPulledMaster,
        masterModel,
        discrepancyConfig
      ]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoading = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setAdminMerge = match$5[1];
  var adminMerge = match$5[0];
  var shouldDeleteSharingLink = React.useRef(false);
  var protectedMainBranch = workspace.settings.protectedMainBranch;
  var requiredApprovals = workspace.settings.numRequiredApprovals;
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  var hasFulfilledApprovalsRequirement = protectedMainBranch ? numApprovals >= requiredApprovals : true;
  var noConflicts = mergeConflicts === /* [] */0;
  var hasUpToDateDiff = branchDiffStatus === "loaded";
  var branchMayBeMergedTechnically = noConflicts && hasUpToDateDiff;
  var hasAuditBlockingMerge = Belt_Option.isSome(blockBranchwithEnforcement) && branchAuditWarningCount > 0;
  var branchMayBeMergedNormally = branchMayBeMergedTechnically && hasFulfilledApprovalsRequirement && !hasAuditBlockingMerge;
  var branchMergeMayBeOverridden = role === "Admin" && branchMayBeMergedTechnically;
  var showAdminMergeOverride = !branchMayBeMergedNormally && branchMergeMayBeOverridden;
  var disabled = adminMerge ? !branchMergeMayBeOverridden : !(RoleUtils.canEdit(role) && branchMayBeMergedNormally);
  var onToggleAdminMerge = function (param) {
    Curry._1(setAdminMerge, (function (adminMerge) {
            return !adminMerge;
          }));
  };
  React.useEffect((function () {
          Belt_Option.forEach(onMergePossible, (function (cb) {
                  Curry._1(cb, !disabled);
                }));
        }), [disabled]);
  var tmp;
  tmp = adminMerge ? "primary" : (
      typeof branchStatus === "number" ? (
          approvalWorkflow === "Unavailable" ? "primary" : "outline"
        ) : (
          branchStatus.TAG === /* Approved */6 || approvalWorkflow === "Unavailable" ? "primary" : "outline"
        )
    );
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, showAdminMergeOverride && infoPosition === "left" ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("row"),
                            tl: {
                              hd: Css.alignItems("flexEnd"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light10,
                            children: "Merge without required approval"
                          }), React.createElement(Switch.make, {
                            value: adminMerge,
                            onChange: onToggleAdminMerge,
                            color: "avoPink",
                            size: "Tiny",
                            disabled: !branchMayBeMergedTechnically
                          }))) : (
                infoPosition === "left" ? React.createElement(BranchActionBox__Components$ApprovalState, {
                        blockBranchWithEnforcement: blockBranchWithEnforcement,
                        branchStatus: branchStatus,
                        includeApprovals: false,
                        members: workspace.members,
                        mergeConflicts: mergeConflicts,
                        numRequiredApprovals: workspace.settings.numRequiredApprovals,
                        requireAdminApproval: workspace.settings.protectedMainBranch,
                        role: role
                      }) : null
              ), React.createElement(Button.make, {
                  disabled: disabled,
                  label: isGlobalWorkspace ? "Merge and push" : "Merge branch",
                  loading: match$4[0],
                  onClick: (function (param) {
                      BranchActionBox__Utils.handleMerge(actionDiff, actions, addToast, adminMerge && !branchMayBeMergedNormally, appVersions, branchAuditWarningCount, branchId, branchModel, branchModelWithPulledMaster, branchName, breakingChanges, discrepancyConfig, eventComponentsWithNames, globalSend, invocationsPromise, masterModel, masterModelBeforeBranch, maybeSharingLink, numApprovals, role, schemaBundle, sendActions, setLoading, sourceInitStatus, workspace, shouldDeleteSharingLink, validationIntent, isGlobalWorkspace, masterModelLatestAction);
                    }),
                  size: "small",
                  style: tmp
                }), showAdminMergeOverride && infoPosition === "right" ? React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.gap(Css.px(4)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light10,
                            children: "Merge without required approval"
                          }), React.createElement(Switch.make, {
                            value: adminMerge,
                            onChange: onToggleAdminMerge,
                            color: "avoPink",
                            size: "Tiny",
                            disabled: !branchMayBeMergedTechnically
                          }))) : (
                infoPosition === "right" ? React.createElement(BranchActionBox__Components$ApprovalState, {
                        blockBranchWithEnforcement: blockBranchWithEnforcement,
                        branchStatus: branchStatus,
                        includeApprovals: false,
                        members: workspace.members,
                        mergeConflicts: mergeConflicts,
                        numRequiredApprovals: workspace.settings.numRequiredApprovals,
                        requireAdminApproval: workspace.settings.protectedMainBranch,
                        role: role
                      }) : null
              ));
}

var MergeButton = {
  make: BranchActionBox__Components$MergeButtonSection$MergeButton
};

function BranchActionBox__Components$MergeButtonSection(Props) {
  var actionDiff = Props.actionDiff;
  var actions = Props.actions;
  var approvalWorkflow = Props.approvalWorkflow;
  var blockBranchWithEnforcement = Props.blockBranchWithEnforcement;
  var branchCreatorId = Props.branchCreatorId;
  var branchDiffStatus = Props.branchDiffStatus;
  var branchId = Props.branchId;
  var branchModel = Props.branchModel;
  var branchModelWithPulledMaster = Props.branchModelWithPulledMaster;
  var branchName = Props.branchName;
  var branchStatus = Props.branchStatus;
  var changes = Props.changes;
  var collaborators = Props.collaborators;
  var masterModel = Props.masterModel;
  var masterModelBeforeBranch = Props.masterModelBeforeBranch;
  var mergeConflicts = Props.mergeConflicts;
  var onMergePossible = Props.onMergePossible;
  var role = Props.role;
  var masterModelLatestAction = Props.masterModelLatestAction;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var viewerEmail = match.email;
  var viewerId = match.id;
  var isBranchCreator = branchCreatorId === viewerId;
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var numApprovals;
  numApprovals = typeof branchStatus === "number" || branchStatus.TAG !== /* Approved */6 ? 0 : Belt_List.length(branchStatus._0);
  var tmp = {
    actionDiff: actionDiff,
    actions: actions,
    approvalWorkflow: approvalWorkflow,
    blockBranchWithEnforcement: blockBranchWithEnforcement,
    branchId: branchId,
    branchModel: branchModel,
    branchModelWithPulledMaster: branchModelWithPulledMaster,
    branchName: branchName,
    branchDiffStatus: branchDiffStatus,
    branchStatus: branchStatus,
    changes: changes,
    masterModel: masterModel,
    masterModelBeforeBranch: masterModelBeforeBranch,
    mergeConflicts: mergeConflicts,
    role: role,
    masterModelLatestAction: masterModelLatestAction
  };
  if (onMergePossible !== undefined) {
    tmp.onMergePossible = Caml_option.valFromOption(onMergePossible);
  }
  var tmp$1;
  tmp$1 = typeof branchStatus === "number" || !(branchStatus.TAG === /* ChangesRequested */5 && RoleUtils.canEdit(role) && !isBranchCreator) ? null : React.createElement(Button.make, {
          disabled: role !== "Admin" || Caml_obj.equal(blockBranchWithEnforcement, "beforeRequestForReview") || !RoleUtils.canEdit(role),
          label: "Ready for review",
          loading: match$1[0],
          onClick: (function (param) {
              BranchActionBox__Utils.handleSetBranchStatus(branchCreatorId, branchId, "BottomBranchActionBox", branchName, collaborators, "ReadyForReview", numApprovals, schemaBundle, sendActions, setLoading, branchStatus, viewerEmail, viewerId, role === "Admin" ? "Admin" : "Editor", workspace);
            }),
          size: "small",
          style: isBranchCreator ? "primary" : "outline",
          singleLine: true
        });
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(16)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light03),
                      tl: {
                        hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light06),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.gap(Css.px(8)),
                            tl: {
                              hd: Css.justifyContent("spaceBetween"),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(BranchActionBox__Components$MergeButtonSection$MergeButton, tmp), tmp$1));
}

var MergeButtonSection = {
  MergeButton: MergeButton,
  make: BranchActionBox__Components$MergeButtonSection
};

function BranchActionBox__Components$EditReviewers(Props) {
  var type_ = Props.type_;
  var branchId = Props.branchId;
  var branchCreatorId = Props.branchCreatorId;
  var branchInteractionLocation = Props.branchInteractionLocation;
  var branchStatus = Props.branchStatus;
  var collaborators = Props.collaborators;
  var numApprovals = Props.numApprovals;
  var requireAdminApproval = Props.requireAdminApproval;
  var requestedReviewerIds = Props.requestedReviewerIds;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var viewerId = match.id;
  var workspace = WorkspaceContext.use(undefined);
  var canEdit = RoleUtils.canEdit(role);
  var tmp;
  var exit = 0;
  if (numApprovals === 0 || numApprovals === 1) {
    if (requireAdminApproval) {
      exit = 1;
    } else {
      tmp = "Assign reviewers to let them know you want a branch review immediately";
    }
  } else if (requireAdminApproval) {
    exit = 1;
  } else {
    tmp = "Assign reviewers to let them know you want a branch review immediately. " + AvoUtils.plural(undefined, undefined, numApprovals, "Reviewer") + " required.";
  }
  if (exit === 1) {
    tmp = numApprovals === 0 || numApprovals === 1 ? "Assign reviwers to let them know you want a branch review immediately. Admin approval required." : "Assign reviewers to let them know you want a branch review immediately. " + AvoUtils.plural(undefined, undefined, numApprovals, "Admin") + " approvals required.";
  }
  var tmp$1 = {
    collaboratorIds: Belt_List.fromArray(requestedReviewerIds),
    ignoreIds: [branchCreatorId],
    onlyRoles: [
      "Admin",
      "Editor"
    ],
    onToggle: (function (reviewer, isToggled) {
        var isAlreadyCollaborator = Belt_List.some(collaborators, (function (c) {
                return c.id === reviewer.id;
              }));
        if (isAlreadyCollaborator) {
          Collaborators.updateCollaboratorIsReviewer(schemaBundle.schemaId, branchId, reviewer.id, viewerId, sendActions, isToggled, (function (param) {
                  var member = Belt_List.getBy(workspace.members, (function (member) {
                          return member.id === viewerId;
                        }));
                  if (member !== undefined) {
                    var arg = workspace.settings.numRequiredApprovals;
                    var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                    var arg$2 = requestedReviewerIds.length;
                    var match = Models.Role.tFromJs(Caml_option.valFromOption(member).role);
                    var arg$3 = match === "Admin" ? "Admin" : "Editor";
                    var arg$4 = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(reviewer.email), "");
                    var arg$5 = schemaBundle.schemaId;
                    AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                              return AnalyticsRe.branchReviewerAdded(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                return Curry.app(func$9, [
                                                            param,
                                                            branchStatus,
                                                            numApprovals,
                                                            arg,
                                                            arg$1,
                                                            branchInteractionLocation,
                                                            arg$2,
                                                            arg$3,
                                                            arg$4,
                                                            branchId,
                                                            branchId,
                                                            arg$5
                                                          ]);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle);
                    return ;
                  }
                  console.error("No member found when adding branch reviewer");
                }), (function (param) {
                  var member = Belt_List.getBy(workspace.members, (function (member) {
                          return member.id === viewerId;
                        }));
                  if (member !== undefined) {
                    var arg = workspace.settings.numRequiredApprovals;
                    var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                    var arg$2 = requestedReviewerIds.length;
                    var match = Models.Role.tFromJs(Caml_option.valFromOption(member).role);
                    var arg$3 = match === "Admin" ? "Admin" : "Editor";
                    var arg$4 = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(reviewer.email), "");
                    var arg$5 = schemaBundle.schemaId;
                    AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                              return AnalyticsRe.branchReviewerRemoved(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                return Curry.app(func$9, [
                                                            param,
                                                            branchStatus,
                                                            numApprovals,
                                                            arg,
                                                            arg$1,
                                                            branchInteractionLocation,
                                                            arg$2,
                                                            arg$3,
                                                            arg$4,
                                                            branchId,
                                                            branchId,
                                                            arg$5
                                                          ]);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle);
                    return ;
                  }
                  console.error("No member found when adding branch reviewer");
                }));
        } else {
          Collaborators.addCollaborator(schemaBundle, viewerId, {
                NAME: "Branch",
                VAL: branchId
              }, "Branch", branchId, Caml_option.nullable_to_opt(reviewer.email), "AddCollaborator", sendActions, true, (function (param) {
                  var member = Belt_List.getBy(workspace.members, (function (member) {
                          return member.id === viewerId;
                        }));
                  if (member !== undefined) {
                    var arg = workspace.settings.numRequiredApprovals;
                    var arg$1 = workspace.settings.requireAdminApproval ? "Admin" : "Editor";
                    var arg$2 = requestedReviewerIds.length;
                    var match = Models.Role.tFromJs(Caml_option.valFromOption(member).role);
                    var arg$3 = match === "Admin" ? "Admin" : "Editor";
                    var arg$4 = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(reviewer.email), "");
                    var arg$5 = schemaBundle.schemaId;
                    AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
                              return AnalyticsRe.branchReviewerAdded(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                return Curry.app(func$9, [
                                                            param,
                                                            branchStatus,
                                                            numApprovals,
                                                            arg,
                                                            arg$1,
                                                            branchInteractionLocation,
                                                            arg$2,
                                                            arg$3,
                                                            arg$4,
                                                            branchId,
                                                            branchId,
                                                            arg$5
                                                          ]);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle);
                    return ;
                  }
                  console.error("No member found when adding branch reviewer");
                }), reviewer.id);
        }
      }),
    infoText: tmp
  };
  var tmp$2 = requireAdminApproval ? (function (memberA, memberB, _a, _b) {
        if (memberA === undefined) {
          return 0;
        }
        if (memberB === undefined) {
          return 0;
        }
        var match = Models.Role.tFromJs(Caml_option.valFromOption(memberA).role);
        var match$1 = Models.Role.tFromJs(Caml_option.valFromOption(memberB).role);
        if (match !== undefined && match === "Admin" && match$1 !== undefined && match$1 !== "Admin") {
          return -1;
        } else {
          return 0;
        }
      }) : undefined;
  if (tmp$2 !== undefined) {
    tmp$1.sortBy = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(Mantine.Popover.make, {
              position: "bottom-end",
              children: null
            }, React.createElement(Core.Popover.Target, {
                  children: type_ === "textButton" ? React.createElement(TextButton.make, {
                          style: "Blue",
                          disabled: !canEdit,
                          children: React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                children: "Assign reviewer"
                              })
                        }) : React.createElement(Button.make, {
                          disabled: !canEdit,
                          label: "Assign reviewer",
                          style: "outline"
                        })
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  additionalStyles: {
                    hd: Css.borderRadius(Css.px(5)),
                    tl: {
                      hd: Css.padding2(Css.px(4), Css.px(0)),
                      tl: {
                        hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                                      NAME: "num",
                                      VAL: 0.10
                                    }))),
                        tl: /* [] */0
                      }
                    }
                  },
                  children: React.createElement(EditCollaboratorsPopup.make, tmp$1)
                }));
}

var EditReviewers = {
  make: BranchActionBox__Components$EditReviewers
};

var infoBoxStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingTop(Css.px(12)),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.paddingLeft(Css.px(20)),
                tl: {
                  hd: Css.paddingRight(Css.px(20)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(12)),
                    tl: {
                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light10),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light01),
                        tl: {
                          hd: Css.borderBottomLeftRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.borderBottomRightRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function BranchActionBox__Components$InspectorValidation$Icon(Props) {
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css.flexShrink(0.0),
                    tl: /* [] */0
                  }),
              height: "16",
              width: "15",
              viewBox: "0 0 15 16"
            }, React.createElement("path", {
                  d: "M11.2694 10.8855L13.9461 13.5622L13.0622 14.4461L10.3855 11.7694C9.42306 12.5394 8.2025 13 6.875 13C3.77 13 1.25 10.48 1.25 7.375C1.25 4.27 3.77 1.75 6.875 1.75C9.98 1.75 12.5 4.27 12.5 7.375C12.5 8.7025 12.0394 9.92306 11.2694 10.8855ZM10.0154 10.4218C10.7797 9.63412 11.25 8.55975 11.25 7.375C11.25 4.95781 9.29219 3 6.875 3C4.45781 3 2.5 4.95781 2.5 7.375C2.5 9.79219 4.45781 11.75 6.875 11.75C8.05975 11.75 9.13412 11.2797 9.92175 10.5154L10.0154 10.4218ZM7.61119 4.98515C7.17712 5.18114 6.875 5.61779 6.875 6.125C6.875 6.81537 7.43463 7.375 8.125 7.375C8.63219 7.375 9.06887 7.07288 9.26488 6.63882C9.33644 6.87156 9.375 7.11875 9.375 7.375C9.375 8.75569 8.25569 9.875 6.875 9.875C5.49429 9.875 4.375 8.75569 4.375 7.375C4.375 5.99429 5.49429 4.875 6.875 4.875C7.13125 4.875 7.37844 4.91354 7.61119 4.98515Z",
                  fill: "#6D7783"
                }));
}

var Icon$1 = {
  make: BranchActionBox__Components$InspectorValidation$Icon
};

function getActionDetails(contentsJson) {
  try {
    var match = Parser.parseAction(contentsJson);
    if (typeof match !== "object") {
      return ;
    }
    if (match.NAME !== "ConfigureInspectorValidation") {
      return ;
    }
    var match$1 = match.VAL;
    return [
            match$1[0],
            match$1[1]
          ];
  }
  catch (exn){
    return ;
  }
}

function getActionData(action) {
  if (typeof action !== "object") {
    return ;
  }
  if (action.NAME !== "Loaded") {
    return ;
  }
  var actionData = action.VAL;
  if (actionData !== undefined) {
    return getActionDetails(Caml_option.valFromOption(actionData).contentsJson);
  }
  
}

function BranchActionBox__Components$InspectorValidation(Props) {
  var branchStatus = Props.branchStatus;
  var branchModel = Props.branchModel;
  var breakingChanges = Props.breakingChanges;
  var validationIntent = Props.validationIntent;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var inspectorValidationPromptRef = match.ref;
  var member = WorkspaceContext.useMember(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var configureInspectorValidationAction = FirebaseFetcherHooks.useAction(schemaBundle.schemaId, (function (q) {
          return q.where("branchId", "==", schemaBundle.branchId);
        }), "ConfigureInspectorValidation");
  var isIntersecting = React.useMemo((function () {
          if (entry !== undefined) {
            return entry.isIntersecting;
          } else {
            return false;
          }
        }), [entry]);
  React.useEffect((function () {
          var match = getActionData(configureInspectorValidationAction);
          if (entry !== undefined && entry.isIntersecting) {
            var exit = 0;
            if (typeof branchStatus === "number" || !(branchStatus.TAG === /* Merged */1 && match !== undefined)) {
              exit = 1;
            } else {
              var sourceValidation = match[1];
              var eventIds = match[0];
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.size(eventIds), Belt_List.toArray(eventIds), Belt_MapString.keysToArray(sourceValidation), Belt_MapString.size(sourceValidation), schemaBundle.branchId, schemaBundle.schemaId);
            }
            if (exit === 1) {
              var inspectorBreakingChangeInfo = Changes.getAnalyticsBreakingChangeInfo(breakingChanges);
              AnalyticsRe.releaseVersionConfigurationPromptSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, inspectorBreakingChangeInfo.breakingChangeImpactedEventCount, inspectorBreakingChangeInfo.breakingChangeImpactedEventIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceIds, inspectorBreakingChangeInfo.breakingChangeImpactedSourceCount, schemaBundle.branchId, schemaBundle.schemaId);
            }
            
          }
          
        }), [isIntersecting]);
  var match$1 = getActionData(configureInspectorValidationAction);
  if (typeof branchStatus !== "number" && branchStatus.TAG === /* Merged */1) {
    if (match$1 === undefined) {
      return null;
    }
    var sourceValidation = match$1[1];
    var eventIds = match$1[0];
    return React.createElement("div", {
                ref: inspectorValidationPromptRef,
                className: infoBoxStyle
              }, React.createElement(BranchActionBox__Components$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                    width: 13
                  }), React.createElement($$Text.make, {
                    size: "Small",
                    color: Styles.Color.light10,
                    children: null
                  }, "Inspector temporarily ignored issues for ", React.createElement($$Text.make, {
                        element: "Span",
                        weight: "Semi",
                        children: "" + AvoUtils.plural(undefined, undefined, Belt_List.size(eventIds), "event") + " from " + AvoUtils.plural(undefined, undefined, Belt_MapString.size(sourceValidation), "source") + ""
                      }), ". View details to see when validation for those events will resume."), React.createElement(Spacer.make, {
                    width: 8,
                    grow: 1.0
                  }), React.createElement(Button.make, {
                    label: "View Details",
                    onClick: (function (param) {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "InspectorVersionValidationDetails",
                                VAL: [
                                  eventIds,
                                  sourceValidation,
                                  branchModel
                                ]
                              }
                            });
                      }),
                    size: "small",
                    style: "outline"
                  }));
  }
  var breakingSourcesSet = Belt_SetString.fromArray(breakingChanges.breakingSources);
  var tmp;
  if (InspectorVersionValidationIntentHelpers.noSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, configure Inspector to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: "" + AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source") + ""
            }), ".");
  } else if (InspectorVersionValidationIntentHelpers.allSourcesHaveIntent(breakingSourcesSet, validationIntent)) {
    tmp = React.createElement(React.Fragment, undefined, "Inspector has been configured to temporarily ignore new issues for ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: "" + AvoUtils.plural(undefined, undefined, breakingChanges.breakingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, breakingChanges.breakingSources.length, "source") + ""
            }), " when this branch is merged. To see how each source is configured and to make changes, click configure.");
  } else {
    var match$2 = InspectorVersionValidationIntentHelpers.getValidationReport(breakingChanges, validationIntent);
    tmp = React.createElement(React.Fragment, undefined, "When merging this branch, Inspector may surface irrelevant issues and alerts from older release versions. To prevent that, Inspector has been configured to temporarily ignore new issues for\n                          " + AvoUtils.plural(undefined, undefined, match$2.ignoredEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$2.ignoredSources.length, "source") + " on this branch but ", React.createElement($$Text.make, {
              element: "Span",
              weight: "Semi",
              children: "" + AvoUtils.plural(undefined, undefined, match$2.passingEvents.length, "event") + " from " + AvoUtils.plural(undefined, undefined, match$2.passingSources.length, "source") + ""
            }), " remain to be configured.");
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: inspectorValidationPromptRef,
                  className: infoBoxStyle,
                  id: "inspector-validation-action-box"
                }, React.createElement(BranchActionBox__Components$InspectorValidation$Icon, {}), React.createElement(Spacer.make, {
                      width: 13
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      width: 8,
                      grow: 1.0
                    }), React.createElement(Button.make, {
                      label: Belt_Option.mapWithDefault(member, "View", (function (member) {
                              if (RoleUtils.canEdit(Belt_Option.getExn(Models.Role.tFromJs(member.role)))) {
                                return "Configure";
                              } else {
                                return "View";
                              }
                            })),
                      onClick: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "InspectorVersionValidation",
                                  VAL: breakingChanges
                                }
                              });
                        }),
                      size: "small",
                      style: "outline"
                    })));
}

var InspectorValidation = {
  infoBoxStyle: infoBoxStyle,
  Icon: Icon$1,
  getActionDetails: getActionDetails,
  getActionData: getActionData,
  make: BranchActionBox__Components$InspectorValidation
};

export {
  Container ,
  MainContent ,
  ApprovalState ,
  MergeButtonSection ,
  EditReviewers ,
  InspectorValidation ,
}
/* infoBoxStyle Not a pure module */
