// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Router from "./Router.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function toString(inspectorValidation) {
  if (inspectorValidation === undefined) {
    return "Don’t ignore";
  }
  var variant = inspectorValidation.NAME;
  if (variant === "oneHourAfterMerge") {
    var date = inspectorValidation.VAL;
    return "1h after merge (" + DateFns.format("d MMM, yyyy", date) + " at " + DateFns.format("HH:mm", date) + ")";
  }
  if (variant === "currentVersion") {
    return "Current version at merge (" + inspectorValidation.VAL + ")";
  }
  if (variant === "twentyFourHoursAfterMerge") {
    var date$1 = inspectorValidation.VAL;
    return "24h after merge (" + DateFns.format("d MMM, yyyy", date$1) + " at " + DateFns.format("HH:mm", date$1) + ")";
  }
  if (variant === "customVersion") {
    return "Custom version at merge (" + inspectorValidation.VAL + ")";
  }
  if (variant !== "customTime") {
    return "Next version at merge (" + inspectorValidation.VAL + ")";
  }
  var date$2 = inspectorValidation.VAL;
  return "Custom time (" + DateFns.format("d MMM, yyyy", date$2) + " at " + DateFns.format("HH:mm", date$2) + ")";
}

function getIntents(param) {
  var schemaId = Router.Schema.getSchemaId(undefined);
  var branchId = Router.Schema.getBranchId(undefined);
  return Promise.all([
                FirebaseUtils.fetchInspectorValidationIntents(undefined, schemaId),
                FirebaseUtils.fetchInspectorValidationIntents(branchId, schemaId)
              ]).then(function (param) {
              return Promise.resolve(Belt_MapString.mergeU(param[0], param[1], (function (_sourceId, maybeDefaultIntent, maybeBranchIntent) {
                                if (maybeBranchIntent !== undefined) {
                                  return maybeBranchIntent;
                                } else if (maybeDefaultIntent !== undefined) {
                                  return maybeDefaultIntent;
                                } else {
                                  return ;
                                }
                              })));
            });
}

function getIgnoredSources(breakingSources, validation) {
  return Belt_MapString.keysToArray(Belt_MapString.keep(validation, (function (sourceId, validation) {
                    if (validation !== "dontIgnore") {
                      return Belt_SetString.has(breakingSources, sourceId);
                    } else {
                      return false;
                    }
                  })));
}

function allSourcesHaveIntent(breakingSources, validation) {
  return Belt_SetString.eq(breakingSources, Belt_SetString.fromArray(getIgnoredSources(breakingSources, validation)));
}

function noSourcesHaveIntent(breakingSources, validation) {
  return Belt_SetString.isEmpty(Belt_SetString.fromArray(getIgnoredSources(breakingSources, validation)));
}

function getValidationReport(changeReport, validation) {
  var ignoredSources = Belt_SetString.fromArray(getIgnoredSources(Belt_SetString.fromArray(changeReport.breakingSources), validation));
  var match = Belt_MapString.partition(changeReport.breakingEventIdsBySource, (function (sourceId, param) {
          return Belt_SetString.has(ignoredSources, sourceId);
        }));
  var passingEventsBySource = match[1];
  var ignoredEvents = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(Belt_MapString.valuesToArray(match[0]), Belt_SetString.toArray))));
  var passingEvents = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(Belt_MapString.valuesToArray(passingEventsBySource), Belt_SetString.toArray))));
  return {
          passingEvents: passingEvents,
          passingSources: Belt_MapString.keysToArray(passingEventsBySource),
          ignoredEvents: ignoredEvents,
          ignoredSources: Belt_SetString.toArray(ignoredSources)
        };
}

export {
  toString ,
  getIntents ,
  getIgnoredSources ,
  allSourcesHaveIntent ,
  noSourcesHaveIntent ,
  getValidationReport ,
}
/* Router Not a pure module */
