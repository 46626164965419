// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as EditableCell from "./EditableCell.mjs";
import * as CellEditPopup from "./CellEditPopup.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as EditableCellListItem from "./EditableCellListItem.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as CreateSystemEventUseCase from "../../model/src/base/CreateSystemEventUseCase.mjs";
import * as ConvertSourcesAndEventsToDisallowedEventsUseCase from "../../model/src/eventSpecificPropertyValues/ConvertSourcesAndEventsToDisallowedEventsUseCase.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

var root = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.padding(Css.px(24)),
          tl: {
            hd: Css.width(Css.px(580)),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(32)
                    ]
                  }),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.alignItems("flexStart"),
                    tl: {
                      hd: Css.overflowY("auto"),
                      tl: {
                        hd: Css.boxShadows({
                              hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(18), Css.px(-6), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                              tl: {
                                hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(12), Css.px(42), Css.px(-4), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                                tl: /* [] */0
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var sources = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.gridTemplateColumns({
                hd: Css.px(36),
                tl: {
                  hd: {
                    NAME: "minmax",
                    VAL: [
                      "auto",
                      Css.fr(1.0)
                    ]
                  },
                  tl: {
                    hd: {
                      NAME: "minmax",
                      VAL: [
                        "auto",
                        Css.fr(1.0)
                      ]
                    },
                    tl: /* [] */0
                  }
                }
              }),
          tl: {
            hd: Css.gridAutoRows(Css.px(42)),
            tl: {
              hd: Css.maxHeight({
                    NAME: "subtract",
                    VAL: [
                      Css.vh(100.0),
                      Css.px(400)
                    ]
                  }),
              tl: {
                hd: Css.minHeight(Css.px(108)),
                tl: {
                  hd: Css.overflowY("auto"),
                  tl: {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerCell = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(2), "solid", Styles.Color.light06),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.zIndex(1),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var headerInputCell = Curry._1(Css.merge, {
      hd: headerCell,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var matchCellBase = Curry._1(Css.style, {
      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
      tl: {
        hd: Css.paddingTop(Css.px(8)),
        tl: {
          hd: Css.paddingLeft(Css.px(8)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var matchCell = Curry._1(Css.merge, {
      hd: matchCellBase,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.marginLeft(Css.px(-12)),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var matchCellDropdownContainer = Curry._1(Css.style, {
      hd: Css.minWidth(Css.px(100)),
      tl: /* [] */0
    });

var inputCell = Curry._1(Css.merge, {
      hd: matchCellBase,
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.justifyContent("center"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignSelf("stretch"),
        tl: {
          hd: Css.flexDirection("row"),
          tl: {
            hd: Css.justifyContent("flexEnd"),
            tl: {
              hd: Css.gap(Css.px(16)),
              tl: {
                hd: Css.marginTop(Css.px(16)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var tableItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding(Css.px(12)),
            tl: /* [] */0
          }
        }
      }
    });

var EditablePropertyEventsCell = CellEditPopup.MakeEditableCell({
      Cell: EditableCell.PropertyEvents,
      make: EditableCellListItem.PropertyEvents.make
    });

function AddPropertyValueModal(Props) {
  var onClose = Props.onClose;
  var propertyId = Props.propertyId;
  var viewerCanEdit = Props.viewerCanEdit;
  var model = ModelStore.Mapped.use(undefined);
  var properties = ModelStore.Mapped.useProperties(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var property = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId));
  var eventIdsSendingPropertyForEventSpecificValues = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromMappedModel(model, property);
  var eventIdsSendingProperty;
  switch (eventIdsSendingPropertyForEventSpecificValues.TAG | 0) {
    case /* SystemProperty */0 :
        eventIdsSendingProperty = [eventIdsSendingPropertyForEventSpecificValues._0];
        break;
    case /* SystemAndNotSystemProperty */1 :
    case /* NotSystemProperty */2 :
        eventIdsSendingProperty = eventIdsSendingPropertyForEventSpecificValues._0;
        break;
    
  }
  var propertyEvents = Belt_Array.keepMap(eventIdsSendingProperty, (function (eventId) {
          if (eventId === GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey) {
            return CreateSystemEventUseCase.create(Curry._1(TrackingPlanMappedModel.Sources.toList, model.sources));
          } else {
            return Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
          }
        }));
  var isSystemProperty = property.sendAs === /* SystemProperty */0;
  var allSources = Curry._1(TrackingPlanMappedModel.Sources.keys, model.sources);
  var systemPropertySourceIds = Belt_SetString.fromArray(allSources);
  var sourceIdsSendingProperty;
  switch (eventIdsSendingPropertyForEventSpecificValues.TAG | 0) {
    case /* SystemProperty */0 :
    case /* SystemAndNotSystemProperty */1 :
        sourceIdsSendingProperty = systemPropertySourceIds;
        break;
    case /* NotSystemProperty */2 :
        sourceIdsSendingProperty = Belt_SetString.fromArray(Belt_Array.keepU(Belt_Array.concatMany(Belt_Array.map(propertyEvents, (function ($$event) {
                            return Belt_List.toArray(ModelUtils.getIncludedSourceIds($$event.includeSources));
                          }))), (function (sourceId) {
                    return Curry._2(TrackingPlanMappedModel.Sources.has, model.sources, sourceId);
                  })));
        break;
    
  }
  var sourcesNotSendingProperty = Curry._2(TrackingPlanMappedModel.Sources.keep, model.sources, (function (param) {
          return !Belt_SetString.has(sourceIdsSendingProperty, param.id);
        }));
  var propertyEventsSendingSourceMap = Belt_MapString.fromArray(Belt_Array.map(Belt_SetString.toArray(sourceIdsSendingProperty), (function (sourceId) {
              var propertyEventIdsSendingSource = Belt_Array.keepMap(propertyEvents, (function ($$event) {
                      if (Belt_List.some($$event.includeSources, (function (source) {
                                return source.id === sourceId;
                              }))) {
                        return $$event;
                      }
                      
                    }));
              return [
                      sourceId,
                      propertyEventIdsSendingSource
                    ];
            })));
  var match = React.useState(function () {
        return [""];
      });
  var setValues = match[1];
  var values = match[0];
  var match$1 = React.useState(function () {
        return sourceIdsSendingProperty;
      });
  var setSelectedSources = match$1[1];
  var selectedSources = match$1[0];
  var match$2 = React.useState(function () {
        return Belt_MapString.fromArray(Belt_Array.map(Belt_MapString.toArray(propertyEventsSendingSourceMap), (function (param) {
                          return [
                                  param[0],
                                  Belt_SetString.fromArray(Belt_Array.map(param[1], (function ($$event) {
                                              return $$event.id;
                                            })))
                                ];
                        })));
      });
  var setEventsSendingPropertyValueOnSourceMap = match$2[1];
  var eventsSendingPropertyValueOnSourceMap = match$2[0];
  var handleToggleSource = function (sourceId) {
    Curry._1(setSelectedSources, (function (selectedSources) {
            if (Belt_SetString.has(selectedSources, sourceId)) {
              return Belt_SetString.remove(selectedSources, sourceId);
            } else {
              return Belt_SetString.add(selectedSources, sourceId);
            }
          }));
  };
  var handleSave = function (param) {
    var sourceIdsToAllowedEventIds = Belt_MapString.keep(eventsSendingPropertyValueOnSourceMap, (function (sourceId, _allowedEvents) {
            return Belt_SetString.has(selectedSources, sourceId);
          }));
    var disallowedEvents = ConvertSourcesAndEventsToDisallowedEventsUseCase.convert(model, property, sourceIdsToAllowedEventIds);
    var impactedEventIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.map(Belt_MapString.valuesToArray(sourceIdsToAllowedEventIds), Belt_SetString.toArray))));
    var values$1 = Belt_Array.keep(values, (function (value) {
            return value !== "";
          }));
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (param) {
              var valuesBefore = Belt_Array.keepMap(property.eventSpecificAllowedPropertyValues, (function (param) {
                      var literal = param[0];
                      if (typeof literal === "object" && literal.NAME === "StringLit") {
                        return literal.VAL;
                      }
                      
                    }));
              Belt_Array.forEachWithIndex(values$1, (function (index, value) {
                      var newValues = Belt_Array.slice(values$1, 0, index + 1 | 0);
                      var valuesAfter = Belt_Array.concat(valuesBefore, newValues);
                      var activePropertySources = Belt_Array.keepMap(Belt_MapString.toArray(sourceIdsToAllowedEventIds), (function (param) {
                              if (Belt_SetString.isEmpty(param[1])) {
                                return ;
                              } else {
                                return param[0];
                              }
                            }));
                      var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroupFromCommonCase(undefined, property);
                      AnalyticsRe.matchUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, propertyBundle.propertyId, Belt_Option.getWithDefault(propertyBundle.propertyName, ""), propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, value, valuesAfter.length, valuesAfter, "Add", Belt_SetString.size(sourceIdsSendingProperty), activePropertySources.length, "Property", schemaBundle.branchId, schemaBundle.schemaId);
                    }));
            }),
          undefined,
          Belt_Array.concatMany(Belt_Array.map(values$1, (function (value) {
                      return [[
                                Belt_MapString.isEmpty(disallowedEvents) ? ({
                                      NAME: "AddEventSpecificPropertyValueForAllEvents",
                                      VAL: [
                                        property.id,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        }
                                      ]
                                    }) : ({
                                      NAME: "AddEventSpecificPropertyValueForSomeEvents",
                                      VAL: [
                                        property.id,
                                        {
                                          NAME: "StringLit",
                                          VAL: value
                                        },
                                        disallowedEvents
                                      ]
                                    }),
                                {
                                  eventIds: impactedEventIds,
                                  eventsQuery: impactedEventIds,
                                  propertyId: property.id,
                                  propertyQuery: property.id
                                }
                              ]];
                    })))
        ]);
    Curry._1(onClose, undefined);
  };
  var existingPropertyValues = Belt_SetString.fromArray(Belt_Array.keepMap(property.eventSpecificAllowedPropertyValues, (function (param) {
              var literal = param[0];
              if (typeof literal === "object" && literal.NAME === "StringLit") {
                return literal.VAL;
              }
              
            })));
  var isValueValid = function (index, value) {
    var valueAlreadyExistsInModal = Belt_Option.mapWithDefault(Belt_Array.getIndexBy(values, (function (existingValue) {
                return existingValue === value;
              })), false, (function (existingValueIndex) {
            return existingValueIndex !== index;
          }));
    if (Belt_SetString.has(existingPropertyValues, value)) {
      return false;
    } else {
      return !valueAlreadyExistsInModal;
    }
  };
  var lastValue = Belt_Array.get(values, values.length - 1 | 0);
  var canAddValue = lastValue !== undefined ? (
      lastValue === "" ? false : isValueValid(values.length - 1 | 0, lastValue)
    ) : true;
  var handleRemoveValue = function (index) {
    Curry._1(setValues, (function (values) {
            return Belt_Array.keepWithIndex(values, (function (param, i) {
                          return i !== index;
                        }));
          }));
  };
  var eventAndSourceSpecificnessLimited = Belt_SetString.size(existingPropertyValues) > PropertyUtils.eventSpecificValuesLimit;
  var hasInvalidValue = Belt_Array.some(Belt_Array.mapWithIndexU(values, (function (index, value) {
              return !isValueValid(index, value);
            })), (function (value) {
          return value;
        }));
  var noValidValue = !Belt_Array.some(Belt_Array.mapWithIndexU(values, (function (index, value) {
              if (isValueValid(index, value)) {
                return value !== "";
              } else {
                return false;
              }
            })), (function (value) {
          return value;
        }));
  var numValues = Belt_Array.keep(values, (function (value) {
          return value !== "";
        })).length;
  return React.createElement("section", {
              className: root
            }, React.createElement(Title.make, {
                  children: "Add allowed value",
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Large",
                  color: Styles.Color.light11,
                  children: "Add one or more allowed values." + (
                    eventAndSourceSpecificnessLimited ? "" : " You can limit the allowed values to specific sources and events the property is attached to."
                  ) + ""
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: null
                }, React.createElement($$Text.make, {
                      element: "Span",
                      weight: "Semi",
                      children: property.name
                    }), " property should match:"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginLeft(Css.px(11)),
                        tl: {
                          hd: Css.marginTop(Css.px(16)),
                          tl: {
                            hd: Css.marginBottom(Css.px(2)),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Allowed value/s:"
                    })), Belt_Array.mapWithIndex(values, (function (index, value) {
                    var isValid = value === "" || isValueValid(index, value);
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: index
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.gap(Css.px(8)),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.width(Css.pct(100.0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement(Input.make, {
                                        value: value,
                                        onChange: (function (value) {
                                            Curry._1(setValues, (function (values) {
                                                    return Belt_Array.mapWithIndexU(values, (function (i, prev) {
                                                                  if (i === index) {
                                                                    return value;
                                                                  } else {
                                                                    return prev;
                                                                  }
                                                                }));
                                                  }));
                                          }),
                                        autoFocus: true,
                                        onEnter: (function (param) {
                                            if (canAddValue) {
                                              return Curry._1(setValues, (function (values) {
                                                            return Belt_Array.concat(values, [""]);
                                                          }));
                                            }
                                            
                                          }),
                                        onBackspace: (function ($$event) {
                                            var value = $$event.target.value;
                                            if (index > 0 && value === "") {
                                              return handleRemoveValue(index);
                                            }
                                            
                                          }),
                                        onPaste: (function ($$event) {
                                            var pastedValues = Belt_Array.map(Belt_Array.keepMap((event.clipboardData.getData('text/plain')).split(/,|\n/), (function (value) {
                                                        return value;
                                                      })), (function (prim) {
                                                    return prim.trim();
                                                  }));
                                            if (pastedValues.length <= 1) {
                                              return ;
                                            } else {
                                              $$event.preventDefault();
                                              return Curry._1(setValues, (function (values) {
                                                            var valuesBeforeIndex = Belt_Array.slice(values, 0, index + (
                                                                  value === "" ? 0 : 1
                                                                ) | 0);
                                                            var valuesAfterIndex = Belt_Array.slice(values, index + 1 | 0, (values.length - index | 0) - 1 | 0);
                                                            return Belt_Array.concatMany([
                                                                        valuesBeforeIndex,
                                                                        pastedValues,
                                                                        valuesAfterIndex
                                                                      ]);
                                                          }));
                                            }
                                          }),
                                        placeholder: "Value...",
                                        className: isValid ? /* [] */0 : ({
                                              hd: Css.borderColor(Styles.Color.red),
                                              tl: /* [] */0
                                            })
                                      }), values.length > 1 ? React.createElement(Button.make, {
                                          icon: "trash",
                                          onClick: (function (param) {
                                              handleRemoveValue(index);
                                            }),
                                          style: "ghost"
                                        }) : null), isValid ? null : React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginBottom(Css.px(4)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: Styles.Color.red,
                                          children: "“" + value + "” value already exists on this property."
                                        })));
                  })), React.createElement(Button.make, {
                  disabled: !canAddValue,
                  icon: "plus",
                  label: "Value",
                  onClick: (function (param) {
                      Curry._1(setValues, (function (values) {
                              return Belt_Array.concat(values, [""]);
                            }));
                    }),
                  style: "ghost"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), sourceIdsSendingProperty === undefined || eventAndSourceSpecificnessLimited ? null : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "When sent from:"
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement("div", {
                        className: sources
                      }, React.createElement("div", {
                            className: headerInputCell
                          }, Belt_SetString.size(sourceIdsSendingProperty) > 1 ? React.createElement(Mantine.Checkbox.make, {
                                  checked: !Belt_SetString.isEmpty(selectedSources),
                                  color: "blue100",
                                  indeterminate: Belt_SetString.size(selectedSources) !== Belt_SetString.size(sourceIdsSendingProperty) && !Belt_SetString.isEmpty(selectedSources),
                                  onChange: (function (param) {
                                      Curry._1(setSelectedSources, (function (selectedSources) {
                                              if (Belt_SetString.isEmpty(selectedSources)) {
                                                return sourceIdsSendingProperty;
                                              }
                                              
                                            }));
                                    }),
                                  size: {
                                    NAME: "num",
                                    VAL: 16
                                  }
                                }) : null), React.createElement("div", {
                            className: headerCell
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: "Sources (" + String(Belt_SetString.size(selectedSources)) + ")"
                              })), React.createElement("div", {
                            className: headerCell
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: "Events"
                              })), Belt_Array.mapU(Belt_Array.keepMapU(Belt_SetString.toArray(sourceIdsSendingProperty), (function (sourceId) {
                                  return Curry._2(TrackingPlanMappedModel.Sources.get, model.sources, sourceId);
                                })), (function (source) {
                              var propertyEventsSendingSource = Belt_MapString.getWithDefault(propertyEventsSendingSourceMap, source.id, []);
                              var eventsSendingPropertyValueOnSource = Belt_Array.keepMap(Belt_SetString.toArray(Belt_MapString.getWithDefault(eventsSendingPropertyValueOnSourceMap, source.id, undefined)), (function (eventId) {
                                      return Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
                                    }));
                              return React.createElement(React.Fragment, {
                                          children: null,
                                          key: source.id
                                        }, React.createElement("div", {
                                              className: inputCell
                                            }, React.createElement(Mantine.Checkbox.make, {
                                                  checked: Belt_SetString.has(selectedSources, source.id),
                                                  color: "blue100",
                                                  onChange: (function (param) {
                                                      handleToggleSource(source.id);
                                                    }),
                                                  size: {
                                                    NAME: "num",
                                                    VAL: 16
                                                  },
                                                  id: source.id
                                                })), React.createElement("div", {
                                              className: matchCellBase
                                            }, React.createElement($$Text.make, {
                                                  element: "Label",
                                                  size: "Medium",
                                                  color: Styles.Color.light12,
                                                  htmlFor: source.id,
                                                  children: AvoConfig.getSourceName(source)
                                                })), React.createElement("div", {
                                              className: matchCell
                                            }, React.createElement("div", {
                                                  className: matchCellDropdownContainer
                                                }, React.createElement(EditablePropertyEventsCell.make, {
                                                      allItems: propertyEventsSendingSource,
                                                      children: React.createElement($$Text.make, {
                                                            size: "Small",
                                                            color: Styles.Color.light12,
                                                            children: isSystemProperty || eventsSendingPropertyValueOnSource.length === propertyEventsSendingSource.length ? "All events" : AvoUtils.plural(undefined, undefined, eventsSendingPropertyValueOnSource.length, "event")
                                                          }),
                                                      className: tableItem,
                                                      onCreates: (function (param) {
                                                          return [];
                                                        }),
                                                      disabled: isSystemProperty || !Belt_SetString.has(selectedSources, source.id),
                                                      onViewItem: (function (id) {
                                                          Router.Schema.pushDrawerItem(undefined, undefined, {
                                                                NAME: "event",
                                                                VAL: [
                                                                  id,
                                                                  undefined,
                                                                  undefined,
                                                                  false
                                                                ]
                                                              });
                                                        }),
                                                      onToggle: (function (param, eventId, selected) {
                                                          var sourceId = source.id;
                                                          Curry._1(setEventsSendingPropertyValueOnSourceMap, (function (sourceMap) {
                                                                  return Belt_MapString.update(sourceMap, sourceId, (function (maybeAllowedSourceEvents) {
                                                                                var allowedSourceEvents = Belt_Option.getWithDefault(maybeAllowedSourceEvents, undefined);
                                                                                if (selected) {
                                                                                  return Caml_option.some(Belt_SetString.add(allowedSourceEvents, eventId));
                                                                                } else {
                                                                                  return Caml_option.some(Belt_SetString.remove(allowedSourceEvents, eventId));
                                                                                }
                                                                              }));
                                                                }));
                                                        }),
                                                      viewerCanEdit: viewerCanEdit,
                                                      selectedItems: eventsSendingPropertyValueOnSource,
                                                      onItemClicked: (function (param) {
                                                          
                                                        }),
                                                      onHover: (function (param) {
                                                          
                                                        }),
                                                      onSearch: (function (_value) {
                                                          
                                                        }),
                                                      onCreateInitiated: (function (_value) {
                                                          
                                                        }),
                                                      key: "events-selector-" + source.id + ""
                                                    }))));
                            })), Curry._2(TrackingPlanMappedModel.Sources.mapToArray, sourcesNotSendingProperty, (function (source) {
                              return React.createElement(React.Fragment, {
                                          children: null,
                                          key: source.id
                                        }, React.createElement("div", {
                                              className: inputCell
                                            }, React.createElement(Mantine.Checkbox.make, {
                                                  checked: Belt_SetString.has(selectedSources, source.id),
                                                  color: "blue100",
                                                  disabled: true,
                                                  onChange: (function (param) {
                                                      handleToggleSource(source.id);
                                                    }),
                                                  size: {
                                                    NAME: "num",
                                                    VAL: 16
                                                  },
                                                  id: source.id
                                                })), React.createElement("div", {
                                              className: matchCellBase
                                            }, React.createElement($$Text.make, {
                                                  element: "Label",
                                                  size: "Medium",
                                                  color: Styles.Color.light12,
                                                  htmlFor: source.id,
                                                  children: Curry._1(TrackingPlanMappedModel.Sources.Utils.resolveName, source)
                                                })), React.createElement("div", {
                                              className: Curry._1(Css.merge, {
                                                    hd: matchCell,
                                                    tl: {
                                                      hd: Curry._1(Css.style, {
                                                            hd: Css.paddingLeft(Css.px(20)),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: /* [] */0
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  color: Styles.Color.light10,
                                                  children: "No events sending property"
                                                })));
                            })))), React.createElement("div", {
                  className: buttons
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      size: "large",
                      style: "ghost"
                    }), React.createElement(Button.make, {
                      disabled: hasInvalidValue || noValidValue,
                      label: numValues <= 1 ? "Add value" : "Add " + AvoUtils.plural(undefined, undefined, numValues, "value") + "",
                      onClick: handleSave,
                      size: "large"
                    })));
}

var make = AddPropertyValueModal;

export {
  make ,
}
/* root Not a pure module */
