// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Input from "../Input.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Mantine from "../Mantine.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as TextButton from "../TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainUtils from "../DomainUtils.mjs";
import * as ReactWindow from "react-window";
import * as ViewerContext from "../ViewerContext.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../../model/src/variants/variantEventIdUseCase.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as DomainDetails__Components from "./DomainDetails__Components.mjs";
import * as CreateEventForVariantUseCase from "../../../model/src/variants/createEventForVariantUseCase.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";

var modalBody_0 = Css.padding(Css.px(24));

var modalBody_1 = {
  hd: Css.paddingBottom(Css.px(0)),
  tl: /* [] */0
};

var modalBody = {
  hd: modalBody_0,
  tl: modalBody_1
};

var modalContent_0 = Css.important(Css.maxHeight({
          NAME: "subtract",
          VAL: [
            Css.vh(97.0),
            Css.px(100)
          ]
        }));

var modalContent = {
  hd: modalContent_0,
  tl: /* [] */0
};

var modalOverlay_0 = Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2));

var modalOverlay = {
  hd: modalOverlay_0,
  tl: /* [] */0
};

var modalTitle_0 = Css.padding(Css.px(8));

var modalTitle_1 = {
  hd: Css.paddingTop(Css.px(16)),
  tl: {
    hd: Css.fontSize(Styles.FontSize.huge),
    tl: {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: /* [] */0
    }
  }
};

var modalTitle = {
  hd: modalTitle_0,
  tl: modalTitle_1
};

var multiSelectLabel_0 = Css.paddingLeft(Css.px(12));

var multiSelectLabel_1 = {
  hd: Css.paddingBottom(Css.pxFloat(4.5)),
  tl: {
    hd: Css.fontSize(Styles.FontSize.small),
    tl: {
      hd: Css.fontWeight(Styles.FontWeight.semi),
      tl: {
        hd: Css.color(Styles.Color.light11),
        tl: /* [] */0
      }
    }
  }
};

var multiSelectLabel = {
  hd: multiSelectLabel_0,
  tl: multiSelectLabel_1
};

var multiSelectInput_0 = Css.minHeight("unset");

var multiSelectInput_1 = {
  hd: Css.outlineOffset(Css.px(-2)),
  tl: {
    hd: Css.outline(Css.px(1), "solid", "transparent"),
    tl: {
      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
      tl: {
        hd: Css.focusWithin({
              hd: Css.outline(Css.px(2), "solid", Styles.Color.darkBlue),
              tl: {
                hd: Css.borderColor("transparent"),
                tl: /* [] */0
              }
            }),
        tl: /* [] */0
      }
    }
  }
};

var multiSelectInput = {
  hd: multiSelectInput_0,
  tl: multiSelectInput_1
};

var multiSelectSearchInput_0 = Css.placeholder({
      hd: Css.color(Styles.Color.light10),
      tl: /* [] */0
    });

var multiSelectSearchInput = {
  hd: multiSelectSearchInput_0,
  tl: /* [] */0
};

var multiSelectSeparatorLabel_0 = Css.color(Styles.Color.light09);

var multiSelectSeparatorLabel_1 = {
  hd: Css.textTransform("uppercase"),
  tl: {
    hd: Css.fontWeight(Styles.FontWeight.semi),
    tl: {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.after({
              hd: Css.display("none"),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    }
  }
};

var multiSelectSeparatorLabel = {
  hd: multiSelectSeparatorLabel_0,
  tl: multiSelectSeparatorLabel_1
};

var topStickySection = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(80)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.zIndex(1),
            tl: /* [] */0
          }
        }
      }
    });

var searchSection = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridGap(Css.px(8)),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gridTemplateColumns({
                  hd: {
                    NAME: "fr",
                    VAL: 0.5
                  },
                  tl: {
                    hd: {
                      NAME: "fr",
                      VAL: 0.5
                    },
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

var list = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: "maxContent",
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: /* [] */0
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gridGap(Css.px(4)),
            tl: {
              hd: Css.marginTop(Css.px(-4)),
              tl: {
                hd: Css.paddingTop(Css.px(26)),
                tl: {
                  hd: Css.paddingBottom(Css.px(14)),
                  tl: {
                    hd: Css.borderBottom(Css.px(2), "solid", Styles.Color.light06),
                    tl: {
                      hd: Css.position("sticky"),
                      tl: {
                        hd: Css.top(Css.px(178)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.zIndex(1),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listRow = Curry._1(Css.style, {
      hd: Css.display("grid"),
      tl: {
        hd: Css.gridTemplateColumns({
              hd: "maxContent",
              tl: {
                hd: {
                  NAME: "fr",
                  VAL: 1.0
                },
                tl: /* [] */0
              }
            }),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gridGap(Css.px(4)),
            tl: {
              hd: Css.paddingTop(Css.px(14)),
              tl: {
                hd: Css.paddingBottom(Css.px(14)),
                tl: {
                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var listRowContainer = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(8)),
        tl: /* [] */0
      }
    });

var listRowItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(8)),
          tl: /* [] */0
        }
      }
    });

var bottomStickySection = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.bottom(Css.px(-1)),
        tl: {
          hd: Css.marginTop(Css.px(-70)),
          tl: {
            hd: Css.paddingTop(Css.px(70)),
            tl: {
              hd: Css.paddingBottom(Css.px(24)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.backgroundImage({
                        NAME: "linearGradient",
                        VAL: [
                          {
                            NAME: "deg",
                            VAL: 180.0
                          },
                          [
                            [
                              Css.pct(15.0),
                              "transparent"
                            ],
                            [
                              Css.pct(70.0),
                              Styles.Color.white
                            ]
                          ]
                        ]
                      }),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: {
                      hd: Css.pointerEvents("none"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var context = React.createContext({
      checked: undefined,
      setChecked: (function (param) {
          
        })
    });

var provider = context.Provider;

function AddEventsModal$Context(Props) {
  var checked = Props.checked;
  var setChecked = Props.setChecked;
  var children = Props.children;
  return React.createElement(provider, {
              value: {
                checked: checked,
                setChecked: setChecked
              },
              children: children
            });
}

function AddEventsModal$Item(Props) {
  var checked = Props.checked;
  var disabled = Props.disabled;
  var item = Props.item;
  var itemId = Props.itemId;
  var setChecked = Props.setChecked;
  var tmp;
  if (item.NAME === "event") {
    tmp = item.VAL.name;
  } else {
    var match = item.VAL;
    tmp = CreateEventForVariantUseCase.variantEventDisplayName(match[0], match[1].nameSuffix);
  }
  return React.createElement("div", {
              className: listRow
            }, React.createElement("div", {
                  className: listRowContainer
                }, React.createElement(Mantine.Checkbox.make, {
                      checked: Belt_SetString.has(checked, itemId),
                      color: "blue100",
                      disabled: disabled,
                      label: React.createElement("div", {
                            className: listRowItem
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                children: tmp
                              }), typeof item === "object" && item.NAME === "eventVariant" ? React.createElement(DomainDetails__Components.VariantPill.make, {}) : null),
                      onChange: (function (param) {
                          Curry._1(setChecked, (function (checkList) {
                                  if (Belt_SetString.has(checkList, itemId)) {
                                    return Belt_SetString.remove(checkList, itemId);
                                  } else {
                                    return Belt_SetString.add(checkList, itemId);
                                  }
                                }));
                        }),
                      size: {
                        NAME: "num",
                        VAL: 19
                      }
                    })));
}

function AddEventsModal$ReactWindowRow(Props) {
  var index = Props.index;
  var style = Props.style;
  var data = Props.data;
  var match = React.useContext(context);
  var match$1 = Belt_Array.getExn(data, index);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.lastChild({
                          hd: Css.selector(" > div", {
                                hd: Css.borderBottomWidth(Css.px(0)),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }),
              style: {
                height: style.height,
                left: style.left,
                position: style.position,
                top: String(style.top) + "px",
                width: style.width
              }
            }, React.createElement(AddEventsModal$Item, {
                  checked: match.checked,
                  disabled: false,
                  item: match$1[1],
                  itemId: match$1[0],
                  setChecked: match.setChecked
                }));
}

function partitionFilters(categories, categoryAndTagFilters) {
  var match = Belt_Array.partitionU(categoryAndTagFilters, (function (categoryAndTagFilter) {
          return categoryAndTagFilter.startsWith("category:");
        }));
  var categoryIds = Belt_Array.mapU(match[0], (function (filter) {
          return filter.replace(new RegExp("^category:"), "");
        }));
  var categoryEventIds = Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.keepMapU(categoryIds, (function (categoryId) {
                  var match = Curry._2(TrackingPlanMappedModel.Categories.get, categories, categoryId);
                  if (match !== undefined) {
                    return Belt_List.toArray(match.events);
                  }
                  
                }))));
  var tagIds = Belt_SetString.fromArray(Belt_Array.mapU(match[1], (function (filter) {
              return filter.replace(new RegExp("^tag:"), "");
            })));
  return {
          categoryEventIds: categoryEventIds,
          tagIds: tagIds
        };
}

function getItemsToShow(itemsNotInDomain, categories, categoryAndTagFilters, searchFilter) {
  var match = partitionFilters(categories, categoryAndTagFilters);
  var tagIds = match.tagIds;
  var categoryEventIds = match.categoryEventIds;
  return Belt_MapString.keep(itemsNotInDomain, (function (_itemId, item) {
                if (item.NAME === "event") {
                  var $$event = item.VAL;
                  var isInFilteredCategory = Belt_SetString.size(categoryEventIds) > 0 ? Belt_SetString.has(categoryEventIds, $$event.id) : true;
                  var hasFilteredTag = Belt_SetString.size(tagIds) > 0 ? Belt_List.someU($$event.tags, (function (tag) {
                            return Belt_SetString.has(tagIds, tag);
                          })) : true;
                  var passesSearchFilter = searchFilter.trim() !== "" ? Fuzzaldrin.score($$event.name, searchFilter) > 0.0015 : true;
                  if (isInFilteredCategory && hasFilteredTag) {
                    return passesSearchFilter;
                  } else {
                    return false;
                  }
                }
                var match = item.VAL;
                var $$event$1 = match[0];
                var isInFilteredCategory$1 = Belt_SetString.size(categoryEventIds) > 0 ? Belt_SetString.has(categoryEventIds, $$event$1.id) : true;
                var hasFilteredTag$1 = Belt_SetString.size(tagIds) > 0 ? Belt_List.someU($$event$1.tags, (function (tag) {
                          return Belt_SetString.has(tagIds, tag);
                        })) : true;
                var passesSearchFilter$1 = searchFilter.trim() !== "" ? Fuzzaldrin.score(CreateEventForVariantUseCase.variantEventDisplayName($$event$1, match[1].nameSuffix), searchFilter) > 0.0015 : true;
                if (isInFilteredCategory$1 && hasFilteredTag$1) {
                  return passesSearchFilter$1;
                } else {
                  return false;
                }
              }));
}

function hasMoreSelectedThanShown(checked, itemsToShow) {
  return !Belt_SetString.everyU(checked, (function (itemId) {
                return Belt_MapString.has(itemsToShow, itemId);
              }));
}

function AddEventsModal$Content(Props) {
  var domain = Props.domain;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var opened = Props.opened;
  var onClose = Props.onClose;
  var schemaId = WorkspaceContext.use(undefined).id;
  var viewerId = ViewerContext.use(undefined).id;
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var domains = DomainStore.useDomains(undefined);
  var viewerRole = WorkspaceContext.useMemberRole(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var categories = ModelStore.Mapped.useCategories(undefined);
  var tags = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, events, (function (param) {
                                    return Belt_List.toArray(param.tags);
                                  })))));
        }), [events]);
  var canManage = viewerRole === "Admin" || viewerIsDomainMember;
  var eventsNotInDomainMap = React.useMemo((function () {
          var domainEventsSet = Belt_SetString.fromArray(domain.items.events);
          return Curry._2(TrackingPlanMappedModel.Events.keep, events, (function (param) {
                        return !Belt_SetString.has(domainEventsSet, param.id);
                      }));
        }), [
        domain.items.events,
        events
      ]);
  var eventVariantsNotInDomainMap = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_Array.concatMany(Curry._2(TrackingPlanMappedModel.Events.mapToArray, events, (function ($$event) {
                                return Belt_Array.map($$event.variants, (function (variant) {
                                              return [
                                                      VariantEventIdUseCase.variantEventId({
                                                            baseEventId: $$event.id,
                                                            variantId: variant.id
                                                          }),
                                                      [
                                                        $$event,
                                                        variant
                                                      ]
                                                    ];
                                            }));
                              }))));
        }), [
        domain.items.eventVariants,
        events
      ]);
  var itemsNotInDomain = React.useMemo((function () {
          var eventsNotInDomain = Curry._1(TrackingPlanMappedModel.Events.toArray, eventsNotInDomainMap);
          var domainEventVariantsSet = Belt_SetString.fromArray(domain.items.eventVariants);
          var variantsNotInDomain = Belt_Array.keep(Belt_MapString.valuesToArray(eventVariantsNotInDomainMap), (function (param) {
                  return !Belt_SetString.has(domainEventVariantsSet, VariantEventIdUseCase.variantEventId({
                                  baseEventId: param[0].id,
                                  variantId: param[1].id
                                }));
                }));
          var eventItems = Belt_Array.map(eventsNotInDomain, (function ($$event) {
                  return [
                          $$event.id,
                          {
                            NAME: "event",
                            VAL: $$event
                          }
                        ];
                }));
          var variantItems = Belt_Array.map(variantsNotInDomain, (function (param) {
                  var variant = param[1];
                  var $$event = param[0];
                  return [
                          VariantEventIdUseCase.variantEventId({
                                baseEventId: $$event.id,
                                variantId: variant.id
                              }),
                          {
                            NAME: "eventVariant",
                            VAL: [
                              $$event,
                              variant
                            ]
                          }
                        ];
                }));
          return Belt_MapString.fromArray(Belt_Array.concat(eventItems, variantItems));
        }), [
        domain.items.events,
        domain.items.eventVariants,
        events
      ]);
  var match = React.useState(function () {
        
      });
  var setChecked = match[1];
  var checked = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchFilter = match$1[1];
  var searchFilter = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setCategoryAndTagFilters = match$2[1];
  var categoryAndTagFilters = match$2[0];
  var match$3 = React.useState(function () {
        return getItemsToShow(itemsNotInDomain, categories, categoryAndTagFilters, searchFilter);
      });
  var setItemsToShow = match$3[1];
  var itemsToShow = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setLoading = match$4[1];
  var loading = match$4[0];
  React.useEffect((function () {
          if (!loading) {
            Curry._1(setItemsToShow, (function (param) {
                    return getItemsToShow(itemsNotInDomain, categories, categoryAndTagFilters, searchFilter);
                  }));
          }
          
        }), [
        domain.items.events,
        events,
        categories,
        searchFilter,
        categoryAndTagFilters,
        loading
      ]);
  var clearFilters = function (param) {
    Curry._1(setSearchFilter, (function (param) {
            return "";
          }));
    Curry._1(setCategoryAndTagFilters, (function (param) {
            return [];
          }));
  };
  Hooks.useDidUpdate1((function (param) {
          if (!opened) {
            Curry._1(setSearchFilter, (function (param) {
                    return "";
                  }));
            Curry._1(setCategoryAndTagFilters, (function (param) {
                    return [];
                  }));
            Curry._1(setChecked, (function (param) {
                    
                  }));
          }
          
        }), [opened]);
  var handleAddEvents = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.addDomainItems(Belt_Array.mapU(Belt_SetString.toArray(checked), (function (itemId) {
                if (VariantEventIdUseCase.isVariantEventId(itemId)) {
                  return {
                          NAME: "eventVariant",
                          VAL: itemId
                        };
                } else {
                  return {
                          NAME: "event",
                          VAL: itemId
                        };
                }
              })), (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Error adding " + AvoUtils.plural(undefined, undefined, Belt_SetString.size(checked), "event") + " to ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Error */1
                });
          }), (function (param) {
            Curry._1(onClose, undefined);
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "" + AvoUtils.plural(undefined, undefined, Belt_SetString.size(checked), "event") + " added to ", React.createElement("i", undefined, domain.name)),
                  toastType: /* Success */0
                });
            var match = Belt_SetString.partitionU(checked, VariantEventIdUseCase.isVariantEventId);
            var eventItems = match[1];
            var variantItems = match[0];
            if (Belt_SetString.size(variantItems) <= 1) {
              Belt_SetString.forEach(variantItems, (function (itemId) {
                      AnalyticsRe.domainItemAddedSingleItem(domain.id, domain.name, Curry._1(Domains.size, domains), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", Belt_Option.mapWithDefault(Belt_MapString.get(eventVariantsNotInDomainMap, itemId), "", (function (param) {
                                  return CreateEventForVariantUseCase.variantEventDisplayName(param[0], param[1].nameSuffix);
                                })), itemId, "EventVariant", schemaId);
                    }));
            } else {
              AnalyticsRe.domainItemAddedMultipleItems(domain.id, domain.name, Curry._1(Domains.size, domains), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", Belt_SetString.size(variantItems), "EventVariant", schemaId);
            }
            if (Belt_SetString.size(eventItems) <= 1) {
              return Belt_SetString.forEach(eventItems, (function (itemId) {
                            AnalyticsRe.domainItemAddedSingleItem(domain.id, domain.name, Curry._1(Domains.size, domains), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, events, itemId), "", (function ($$event) {
                                        return $$event.name;
                                      })), itemId, "Event", schemaId);
                          }));
            } else {
              return AnalyticsRe.domainItemAddedMultipleItems(domain.id, domain.name, Curry._1(Domains.size, domains), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DomainSettings", viewerIsDomainMember ? "DomainTeamMember" : "Admin", "Stakeholder", Belt_SetString.size(eventItems), "Event", schemaId);
            }
          }), schemaId, sendActions, viewerId, domain.id);
  };
  var match$5 = React.useState(function () {
        return window.innerHeight;
      });
  var setWindowHeight = match$5[1];
  React.useEffect((function () {
          var resizeHandler = function (param) {
            Curry._1(setWindowHeight, (function (param) {
                    return window.innerHeight;
                  }));
          };
          window.addEventListener("resize", resizeHandler);
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                  });
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: topStickySection
                }, React.createElement($$Text.make, {
                      color: Styles.Color.light11,
                      children: "Select tracking plan events that are used by your domain"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement("div", {
                      className: searchSection
                    }, React.createElement(Input.make, {
                          label: "Search events",
                          value: searchFilter,
                          onChange: (function (v) {
                              Curry._1(setSearchFilter, (function (param) {
                                      return v;
                                    }));
                            }),
                          placeholder: "Type in event name...",
                          className: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: /* [] */0
                          },
                          type_: "search"
                        }), React.createElement(Mantine.MultiSelect.make, {
                          data: Belt_Array.concat(Curry._2(TrackingPlanMappedModel.Categories.mapToArray, categories, (function (param) {
                                      return {
                                              value: "category:" + param.id + "",
                                              label: param.name,
                                              group: "Categories"
                                            };
                                    })), Belt_Array.mapU(tags, (function (tag) {
                                      return {
                                              value: "tag:" + tag + "",
                                              label: tag,
                                              group: "Tags"
                                            };
                                    }))),
                          inputStyles: multiSelectInput,
                          label: "Filter by category or tag",
                          labelStyles: multiSelectLabel,
                          onChange: (function (filters) {
                              Curry._1(setCategoryAndTagFilters, (function (param) {
                                      return filters;
                                    }));
                            }),
                          placeholder: "Filter...",
                          searchable: true,
                          searchInputStyles: multiSelectSearchInput,
                          separatorLabelStyles: multiSelectSeparatorLabel,
                          value: categoryAndTagFilters,
                          valuesStyles: {
                            hd: Css.minHeight(Css.pxFloat(30.5)),
                            tl: /* [] */0
                          },
                          withinPortal: true,
                          zIndex: Styles.ZIndex.aboveAll + 1 | 0
                        }))), React.createElement("div", {
                  className: list
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.paddingLeft(Css.px(8)),
                            tl: {
                              hd: Css.paddingRight(Css.px(8)),
                              tl: /* [] */0
                            }
                          })
                    }, React.createElement(Mantine.Checkbox.make, {
                          checked: Belt_SetString.size(checked) > 0 && Belt_MapString.every(itemsToShow, (function (id, _item) {
                                  return Belt_SetString.has(checked, id);
                                })),
                          color: "blue100",
                          disabled: Belt_MapString.size(itemsToShow) === 0 || loading,
                          indeterminate: hasMoreSelectedThanShown(checked, itemsToShow),
                          label: React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light11,
                                children: "Select all (" + String(Belt_MapString.size(itemsToShow)) + ")"
                              }),
                          onChange: (function (param) {
                              Curry._1(setChecked, (function (currentSet) {
                                      var itemsToShowIds = Belt_MapString.keysToArray(itemsToShow);
                                      var itemsNotInDomainIds = Belt_MapString.keysToArray(itemsNotInDomain);
                                      if (Belt_SetString.eq(Belt_SetString.fromArray(itemsToShowIds), Belt_SetString.fromArray(itemsNotInDomainIds))) {
                                        if (Belt_MapString.every(itemsNotInDomain, (function (itemId, _item) {
                                                  return Belt_SetString.has(currentSet, itemId);
                                                }))) {
                                          return ;
                                        } else {
                                          return Belt_SetString.mergeMany(currentSet, itemsNotInDomainIds);
                                        }
                                      } else if (Belt_MapString.every(itemsToShow, (function (itemId, _item) {
                                                return Belt_SetString.has(currentSet, itemId);
                                              }))) {
                                        return Belt_SetString.removeMany(currentSet, itemsToShowIds);
                                      } else {
                                        return Belt_SetString.mergeMany(currentSet, itemsToShowIds);
                                      }
                                    }));
                            }),
                          size: {
                            NAME: "num",
                            VAL: 19
                          }
                        }))), React.createElement(AddEventsModal$Context, {
                  checked: checked,
                  setChecked: setChecked,
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.height(Css.px(Math.min(match$5[0] - 500 | 0, Math.imul(48, Belt_MapString.size(itemsToShow))))),
                              tl: /* [] */0
                            })
                      }, React.createElement(ReactVirtualizedAutoSizer, {
                            children: (function (param) {
                                return React.createElement(ReactWindow.VariableSizeList, {
                                            estimatedItemSize: 48,
                                            height: param.height,
                                            itemCount: Belt_MapString.size(itemsToShow),
                                            itemData: Belt_MapString.toArray(itemsToShow),
                                            itemKey: (function (index) {
                                                return Belt_Array.getExn(Belt_MapString.toArray(itemsToShow), index)[0];
                                              }),
                                            itemSize: (function (param) {
                                                return 48;
                                              }),
                                            overscanCount: 30,
                                            width: param.width,
                                            children: AddEventsModal$ReactWindowRow
                                          });
                              })
                          }))
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("span", {
                  className: bottomStickySection
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: {
                                hd: Css.width(Css.pct(100.0)),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.justifyContent("flexEnd"),
                                    tl: {
                                      hd: Css.pointerEvents("auto"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, hasMoreSelectedThanShown(checked, itemsToShow) ? React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.light11,
                            children: null
                          }, React.createElement(TextButton.make, {
                                onClick: clearFilters,
                                style: "Blue",
                                children: "Clear filters"
                              }), " to see all selected") : null, React.createElement(Spacer.make, {
                          width: 0,
                          grow: 1.0
                        }), React.createElement(Button.make, {
                          label: "Cancel",
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            }),
                          style: "outline"
                        }), React.createElement(Button.make, {
                          disabled: Belt_SetString.size(checked) === 0 || !canManage,
                          label: "Add " + AvoUtils.plural(undefined, undefined, Belt_SetString.size(checked), "event") + "",
                          loading: loading,
                          onClick: handleAddEvents
                        }))));
}

function AddEventsModal(Props) {
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var domain = Props.domain;
  var opened = Props.opened;
  var onClose = Props.onClose;
  return React.createElement(Mantine.Modal.make, {
              closeOnClickOutside: false,
              onClose: onClose,
              opened: opened,
              size: {
                NAME: "num",
                VAL: 600
              },
              title: "Add events to domain",
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll,
              bodyStyles: modalBody,
              contentStyles: modalContent,
              overlayStyles: modalOverlay,
              titleStyles: modalTitle,
              yOffset: 100,
              children: React.createElement(AddEventsModal$Content, {
                    domain: domain,
                    viewerIsDomainMember: viewerIsDomainMember,
                    opened: opened,
                    onClose: onClose
                  })
            });
}

var make = AddEventsModal;

export {
  make ,
}
/* modalBody Not a pure module */
