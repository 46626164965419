// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ActionDiff from "../../../shared/ActionDiff.mjs";

var instance = new ComlinkWorker(new URL("./ActionDiffWorker.mjs", import.meta.url), {});

function diffInWorker(fromModelJson, toModelJson) {
  return instance.diff(fromModelJson, toModelJson);
}

function diff(fromModel, fromModelJson, toModel, toModelJson) {
  return $$Promise.$$catch(diffInWorker(fromModelJson, toModelJson).then(Belt_List.fromArray), (function (error) {
                console.error("Error calculating action diff in web worker", error);
                return Promise.resolve(ActionDiff.diff(undefined, fromModel, toModel, undefined));
              }));
}

export {
  diff ,
}
/* instance Not a pure module */
