// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"js":"JavaScript","js_v2":"JavaScript_V2","re":"Reason","re_v2":"Reason_V2","java":"Java","swift":"Swift","json":"JSON","py":"Python","py3":"Python3","php":"PHP","kt":"Kotlin","cs":"C#","ts":"TypeScript","objc":"Objective-C","rb":"Ruby","dart":"Dart","go":"Go"};

var _revMap = {"JavaScript":"js","JavaScript_V2":"js_v2","Reason":"re","Reason_V2":"re_v2","Java":"java","Swift":"swift","JSON":"json","Python":"py","Python3":"py3","PHP":"php","Kotlin":"kt","C#":"cs","TypeScript":"ts","Objective-C":"objc","Ruby":"rb","Dart":"dart","Go":"go"};

function languageToJs(param) {
  return _map[param];
}

function languageFromJs(param) {
  return _revMap[param];
}

var _map$1 = {"Web":"Web","ReactNative":"ReactNative","Expo":"Expo","Unity":"Unity","Node":"Node","Android":"Android","IOS":"IOS","KotlinMultiplatform":"KotlinMultiplatform","MacOS":"MacOS","Python":"Python","Php":"Php","Ruby":"Ruby","Java":"Java","JsonSchema":"JsonSchema","Gtm":"Gtm","DotNet":"DotNet","Flutter":"Flutter","Go":"Go","Other":"Other"};

function devPlatformToJs(param) {
  return param;
}

function devPlatformFromJs(param) {
  return _map$1[param];
}

var _map$2 = {"Amplitude":"Amplitude","Mixpanel":"Mixpanel","Segment":"Segment","FacebookAnalytics":"Facebook Analytics","FirebaseAnalytics":"Firebase Analytics","MParticle":"mParticle","Custom":"Custom","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","ZendeskConnect":"Zendesk Connect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","GoogleAnalytics4":"Google Analytics 4","AdobeAnalytics":"Adobe Analytics","Freshpaint":"Freshpaint","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

var _revMap$1 = {"Amplitude":"Amplitude","Mixpanel":"Mixpanel","Segment":"Segment","Facebook Analytics":"FacebookAnalytics","Firebase Analytics":"FirebaseAnalytics","mParticle":"MParticle","Custom":"Custom","FullStory":"FullStory","Intercom":"Intercom","AppsFlyer":"AppsFlyer","Permutive":"Permutive","Braze":"Braze","Zendesk Connect":"ZendeskConnect","Snowplow":"Snowplow","Rudderstack":"Rudderstack","Google Analytics 4":"GoogleAnalytics4","Adobe Analytics":"AdobeAnalytics","Freshpaint":"Freshpaint","PostHog":"PostHog","Heap":"Heap","Pendo":"Pendo"};

function analyticsToolToJs(param) {
  return _map$2[param];
}

function analyticsToolFromJs(param) {
  return _revMap$1[param];
}

export {
  languageToJs ,
  languageFromJs ,
  devPlatformToJs ,
  devPlatformFromJs ,
  analyticsToolToJs ,
  analyticsToolFromJs ,
}
/* No side effect */
