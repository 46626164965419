// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconArrowTopRight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 12 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: /* [] */0
                      }),
                  d: "M1.62308 12L9.76923 3.85138L9.75385 10.0243H12V0H1.97692L1.96154 2.22934H8.14615L0 10.378L1.62308 12Z"
                }));
}

var make = IconArrowTopRight;

export {
  make ,
}
/* Css Not a pure module */
