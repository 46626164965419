// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as AvoModel from "../../app/src/avoModel.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoFormat from "../../app/src/AvoFormat.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../../app/src/ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as CodegenRules from "./CodegenRules.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as CodegenCommons from "./CodegenCommons.mjs";
import * as PublishingUtils from "./PublishingUtils.mjs";
import * as ModelUtils_mapped from "../../app/src/ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "../../app/src/BeltListExtensions.mjs";
import * as Belt_MutableMapString from "rescript/lib/es6/belt_MutableMapString.js";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as JsonSchemaPropertiesPrinter from "./JsonSchemaPropertiesPrinter.mjs";

function encodeGroup(group) {
  return Json_encode.object_({
              hd: [
                "id",
                group.id
              ],
              tl: {
                hd: [
                  "name",
                  group.name
                ],
                tl: {
                  hd: [
                    "description",
                    group.description
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printProperties(model, legacyModel, properties, $$event, modelPropertiesArray, includedSources, isGlobalWorkspace) {
  var includedSources$1 = Belt_List.keep(includedSources, (function (includedSource) {
          return Belt_List.someU($$event.includeSources, (function (includedEventSource) {
                        return includedEventSource.id === includedSource;
                      }));
        }));
  return Json_encode.object_({
              hd: [
                "type",
                "object"
              ],
              tl: {
                hd: [
                  "properties",
                  Json_encode.object_(Belt_List.mapWithIndex(properties, (function (index, property) {
                              return [
                                      property.name,
                                      JsonSchemaPropertiesPrinter.printProperty(model, legacyModel, modelPropertiesArray, /* AvoJsonSchema */2, false, $$event.id, undefined, ModelUtils_mapped.getPropertyRefById($$event.id, property.id, model.events), property, Belt_List.toArray(includedSources$1), ModelUtils_mapped.isPropertyOptionalOnEventsAndSources({
                                                hd: $$event.id,
                                                tl: /* [] */0
                                              }, includedSources$1, model.events, property), isGlobalWorkspace, true, index, undefined)
                                    ];
                            })))
                ],
                tl: {
                  hd: [
                    "additionalProperties",
                    false
                  ],
                  tl: {
                    hd: [
                      "required",
                      Belt_List.toArray(Belt_List.keepMap(properties, (function (property) {
                                  if (!ModelUtils_mapped.isPropertyOptionalOnEventsAndSources({
                                          hd: $$event.id,
                                          tl: /* [] */0
                                        }, includedSources$1, model.events, property)) {
                                    return Caml_option.some(property.name);
                                  }
                                  
                                })))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printEvent(model, legacyModel, systemProperties, $$event, filterIncludedSources, modelPropertiesArray, isGlobalWorkspace) {
  var inputProperties = ModelUtils_mapped.resolveProperties(Belt_List.toArray($$event.directPropertyRefs), model.properties);
  var match = CodegenCommons.classifyProperties(Belt_List.fromArray(inputProperties));
  var groupProperties = match[3];
  var userProperties = match[1];
  var match$1 = Belt_List.partition(match[0], (function (property) {
          return property.builtIn === "NotBuiltIn";
        }));
  var eventAndSystemProperties = Belt_List.concat(match$1[0], systemProperties);
  var destinationIds = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.flatten(Belt_List.map($$event.includeDestinations, (function (sourceDestinationMap) {
                          var match = sourceDestinationMap.VAL;
                          if (isGlobalWorkspace) {
                            return {
                                    hd: match[0],
                                    tl: /* [] */0
                                  };
                          } else {
                            return match[1];
                          }
                        }))))));
  var eventNameMappings = Belt_Array.keepMap(destinationIds, (function (destinationId) {
          return Belt_Option.map(CodegenRules.getNameMappedNameForDestination(isGlobalWorkspace, legacyModel, destinationId, $$event), (function (mappedName) {
                        return [
                                mappedName,
                                destinationId
                              ];
                      }));
        }));
  return Json_encode.object_({
              hd: [
                "$schema",
                "http://json-schema.org/draft-06/schema#"
              ],
              tl: {
                hd: [
                  "type",
                  "object"
                ],
                tl: {
                  hd: [
                    "required",
                    [
                      "properties",
                      "nameMapping"
                    ]
                  ],
                  tl: {
                    hd: [
                      "nameMapping",
                      Belt_Array.mapU(eventNameMappings, (function (param) {
                              return Json_encode.object_({
                                          hd: [
                                            "name",
                                            param[0]
                                          ],
                                          tl: {
                                            hd: [
                                              isGlobalWorkspace ? "workspaceId" : "destinationId",
                                              param[1]
                                            ],
                                            tl: /* [] */0
                                          }
                                        });
                            }))
                    ],
                    tl: {
                      hd: [
                        "properties",
                        Json_encode.object_(Belt_List.concatMany([
                                  Belt_List.length(eventAndSystemProperties) > 0 ? ({
                                        hd: [
                                          "properties",
                                          printProperties(model, legacyModel, eventAndSystemProperties, $$event, modelPropertiesArray, filterIncludedSources, isGlobalWorkspace)
                                        ],
                                        tl: /* [] */0
                                      }) : /* [] */0,
                                  Belt_List.length(userProperties) > 0 ? ({
                                        hd: [
                                          "userProperties",
                                          printProperties(model, legacyModel, userProperties, $$event, modelPropertiesArray, filterIncludedSources, isGlobalWorkspace)
                                        ],
                                        tl: /* [] */0
                                      }) : /* [] */0,
                                  Belt_List.length(groupProperties) > 0 ? ({
                                        hd: [
                                          "groupProperties",
                                          printProperties(model, legacyModel, groupProperties, $$event, modelPropertiesArray, filterIncludedSources, isGlobalWorkspace)
                                        ],
                                        tl: /* [] */0
                                      }) : /* [] */0
                                ]))
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printMetricWhere(metricWhere) {
  return Json_encode.object_({
              hd: [
                "propertyId",
                metricWhere.propertyId
              ],
              tl: {
                hd: [
                  "operation",
                  TrackingPlanModel.encodeBinOp(metricWhere.binOp)
                ],
                tl: {
                  hd: [
                    "values",
                    Json_encode.array(JsonSchemaPropertiesPrinter.printLiteral, Belt_List.toArray(metricWhere.literals))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printMetricGroupBy(metricGroupBy) {
  return Json_encode.object_({
              hd: [
                "propertyId",
                metricGroupBy.propertyId
              ],
              tl: /* [] */0
            });
}

function printMetricEvent(item) {
  var metricEvent = item.VAL;
  return Json_encode.object_({
              hd: [
                "eventId",
                metricEvent.eventId
              ],
              tl: {
                hd: [
                  "where",
                  Json_encode.array(printMetricWhere, Belt_List.toArray(metricEvent.where))
                ],
                tl: {
                  hd: [
                    "groupBy",
                    Json_encode.array(printMetricGroupBy, Belt_List.toArray(metricEvent.groupBy))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printMetric(metric, metricGoals) {
  return Json_encode.object_({
              hd: [
                "id",
                metric.id
              ],
              tl: {
                hd: [
                  "name",
                  metric.name
                ],
                tl: {
                  hd: [
                    "description",
                    metric.description
                  ],
                  tl: {
                    hd: [
                      "categories",
                      Json_encode.array((function (prim) {
                              return prim;
                            }), Belt_MutableMapString.getWithDefault(metricGoals, metric.id, []))
                    ],
                    tl: {
                      hd: [
                        "type",
                        TrackingPlanModel.metricTypeToJs(metric.type_)
                      ],
                      tl: {
                        hd: [
                          "items",
                          Json_encode.array(printMetricEvent, Belt_List.toArray(metric.items))
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function printSource(destinations, source) {
  return Json_encode.object_({
              hd: [
                "id",
                source.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), source.name)
                ],
                tl: {
                  hd: [
                    "programmingLanguage",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(source.language, AvoConfigTypes.languageToJs))
                  ],
                  tl: {
                    hd: [
                      "developmentPlatform",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), Belt_Option.map(source.platform, AvoConfigTypes.devPlatformToJs))
                    ],
                    tl: {
                      hd: [
                        "destinations",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), Belt_List.toArray(ModelUtils.destinationIdsWithoutArchived(ModelUtils.getSourceDestinationIds(source.destinations), destinations)))
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function printDestination(destination) {
  return Json_encode.object_({
              hd: [
                "id",
                destination.id
              ],
              tl: {
                hd: [
                  "name",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), destination.name)
                ],
                tl: {
                  hd: [
                    "type",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(destination.type_, AvoConfigTypes.analyticsToolToJs))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function printGoal(metrics, events, goal) {
  return Json_encode.object_({
              hd: [
                "id",
                goal.id
              ],
              tl: {
                hd: [
                  "name",
                  goal.name
                ],
                tl: {
                  hd: [
                    "description",
                    goal.description
                  ],
                  tl: {
                    hd: [
                      "metrics",
                      Json_encode.array((function (prim) {
                              return prim;
                            }), Belt_List.toArray(ModelUtils.metricIdsWithoutArchived(goal.metrics, metrics)))
                    ],
                    tl: {
                      hd: [
                        "events",
                        Json_encode.array((function (prim) {
                                return prim;
                              }), Belt_List.toArray(ModelUtils.eventIdsWithoutArchived(goal.events, events)))
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function getCategoriesForMetricsAndEvents(goals) {
  var eventGoals = Belt_MutableMapString.make(undefined);
  var metricGoals = Belt_MutableMapString.make(undefined);
  Belt_List.forEach(goals, (function (goal) {
          Belt_List.forEach(goal.events, (function (eventId) {
                  var a = Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMapString.get(eventGoals, eventId), (function (arr) {
                              return Belt_Array.concat(arr, [goal.id]);
                            })), [goal.id]);
                  Belt_MutableMapString.set(eventGoals, eventId, a);
                }));
          Belt_List.forEach(goal.metrics, (function (metricId) {
                  var a = Belt_Option.getWithDefault(Belt_Option.map(Belt_MutableMapString.get(metricGoals, metricId), (function (arr) {
                              return Belt_Array.concat(arr, [goal.id]);
                            })), [goal.id]);
                  Belt_MutableMapString.set(metricGoals, metricId, a);
                }));
          return goal;
        }));
  return [
          eventGoals,
          metricGoals
        ];
}

function encodeEventSourceDestination(destinationId) {
  return Json_encode.object_({
              hd: [
                "id",
                destinationId
              ],
              tl: /* [] */0
            });
}

function encodeEventSource($$event, param) {
  var source = param[1];
  return Json_encode.object_({
              hd: [
                "id",
                source.id
              ],
              tl: {
                hd: [
                  "name",
                  AvoConfig.getSourceName(source)
                ],
                tl: {
                  hd: [
                    "implementWithCodegen",
                    param[0].includeInCodegen
                  ],
                  tl: {
                    hd: [
                      "destinations",
                      Json_encode.array(encodeEventSourceDestination, Belt_List.toArray(Belt_List.map(BeltListExtensions.dedupeOrdered(Belt_List.flatten(Belt_List.mapU($$event.includeDestinations, (function (destinationConfig) {
                                                  var match = destinationConfig.VAL;
                                                  if (match[0] === source.id) {
                                                    return match[1];
                                                  } else {
                                                    return /* [] */0;
                                                  }
                                                }))), (function (id) {
                                          return id;
                                        })), (function (destinationId) {
                                      return destinationId;
                                    }))))
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function printSchema(schemaName, model, legacyModel, systemProperties, filterIncludedSources, schemaId, branchId, isGlobalWorkspace, additionalContext) {
  var modelPropertiesArray = Belt_List.toArray(AvoModel.resolveProperties(legacyModel, legacyModel.properties));
  var match = getCategoriesForMetricsAndEvents(Belt_List.fromArray(Curry._1(TrackingPlanMappedModel.Categories.toArray, model.categories)));
  var metricGoals = match[1];
  var eventGoals = match[0];
  var partial_arg = Curry._1(TrackingPlanMappedModel.Destinations.toArray, model.destinations);
  var partial_arg$1 = Curry._1(TrackingPlanMappedModel.Events.toList, model.events);
  var partial_arg$2 = Curry._1(TrackingPlanMappedModel.Metrics.toList, model.metrics);
  return Json_encode.object_(Belt_List.concat({
                  hd: [
                    "name",
                    schemaName
                  ],
                  tl: {
                    hd: [
                      "events",
                      Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Events.toArray, model.events), (function ($$event) {
                              var triggersJson = CodegenCommons.eventTriggersForSourcesJson(legacyModel, $$event.id, filterIncludedSources, schemaId, branchId);
                              var description_1 = $$event.description;
                              var description = [
                                "description",
                                description_1
                              ];
                              var globalDescription = Belt_Option.flatMapU(model.globalRequirements, (function (param) {
                                      return Belt_Option.mapU(Curry._2(TrackingPlanMappedModel.Events.get, param.events, $$event.id), (function (param) {
                                                    return [
                                                            "globalDescription",
                                                            param.description
                                                          ];
                                                  }));
                                    }));
                              var descriptions = Belt_Option.mapWithDefaultU(globalDescription, {
                                    hd: description,
                                    tl: /* [] */0
                                  }, (function (globalDescription) {
                                      return {
                                              hd: description,
                                              tl: {
                                                hd: globalDescription,
                                                tl: /* [] */0
                                              }
                                            };
                                    }));
                              var isVariant = VariantEventIdUseCase.isVariantEventId($$event.id);
                              var includedSources = BeltListExtensions.dedupeOrdered(Belt_List.keepMap($$event.includeSources, (function (sourceRef) {
                                          return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Sources.get, model.sources, sourceRef.id), (function (source) {
                                                        return [
                                                                sourceRef,
                                                                source
                                                              ];
                                                      }));
                                        })), (function (param) {
                                      return param[0].id;
                                    }));
                              return Json_encode.object_(Belt_List.concatMany([
                                              {
                                                hd: [
                                                  "id",
                                                  $$event.id
                                                ],
                                                tl: {
                                                  hd: [
                                                    "name",
                                                    $$event.name
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "eventType",
                                                      isVariant ? "variant" : "event"
                                                    ],
                                                    tl: /* [] */0
                                                  }
                                                }
                                              },
                                              isVariant ? ({
                                                    hd: [
                                                      "variantName",
                                                      Belt_Option.getWithDefault($$event.uniqueName, $$event.name)
                                                    ],
                                                    tl: /* [] */0
                                                  }) : /* [] */0,
                                              descriptions,
                                              {
                                                hd: [
                                                  "triggers",
                                                  triggersJson
                                                ],
                                                tl: {
                                                  hd: [
                                                    "tags",
                                                    Json_encode.array((function (prim) {
                                                            return prim;
                                                          }), Belt_List.toArray($$event.tags))
                                                  ],
                                                  tl: {
                                                    hd: [
                                                      "categories",
                                                      Json_encode.array((function (prim) {
                                                              return prim;
                                                            }), Belt_MutableMapString.getWithDefault(eventGoals, $$event.id, []))
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        "actions",
                                                        Json_encode.array((function (prim) {
                                                                return prim;
                                                              }), Belt_List.has($$event.types, "SkipLogEvent", (function (prim0, prim1) {
                                                                    return prim0 === prim1;
                                                                  })) ? Belt_Array.map(Belt_List.toArray($$event.types), TrackingPlanModel.eventTypeToCamelCase) : Belt_Array.concat(["logEvent"], Belt_Array.map(Belt_List.toArray($$event.types), TrackingPlanModel.eventTypeToCamelCase)))
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          "sources",
                                                          Json_encode.array((function (param) {
                                                                  return encodeEventSource($$event, param);
                                                                }), Belt_List.toArray(Belt_List.map(includedSources, (function (param) {
                                                                          return [
                                                                                  param[0],
                                                                                  param[1]
                                                                                ];
                                                                        }))))
                                                        ],
                                                        tl: {
                                                          hd: [
                                                            "rules",
                                                            printEvent(model, legacyModel, systemProperties, $$event, filterIncludedSources, modelPropertiesArray, isGlobalWorkspace)
                                                          ],
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            ]));
                            }))
                    ],
                    tl: {
                      hd: [
                        "metrics",
                        Json_encode.dict((function (__x) {
                                return printMetric(__x, metricGoals);
                              }), Js_dict.fromArray(Curry._1(TrackingPlanMappedModel.Metrics.toArrayWithKeys, model.metrics)))
                      ],
                      tl: {
                        hd: [
                          "sources",
                          Curry._2(TrackingPlanMappedModel.Sources.encodeDict, model.sources, (function (param) {
                                  return printSource(partial_arg, param);
                                }))
                        ],
                        tl: {
                          hd: [
                            "destinations",
                            Curry._2(TrackingPlanMappedModel.Destinations.encodeDict, model.destinations, printDestination)
                          ],
                          tl: {
                            hd: [
                              "categories",
                              Curry._2(TrackingPlanMappedModel.Categories.encodeDict, model.categories, (function (param) {
                                      return printGoal(partial_arg$2, partial_arg$1, param);
                                    }))
                            ],
                            tl: {
                              hd: [
                                "propertyGroups",
                                Curry._2(TrackingPlanMappedModel.GroupTypes.encodeDict, model.groupTypes, encodeGroup)
                              ],
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }, Belt_Option.mapWithDefault(additionalContext, /* [] */0, (function (context) {
                        return {
                                hd: [
                                  "context",
                                  context
                                ],
                                tl: /* [] */0
                              };
                      }))));
}

function generateLib(schemaName, model, legacyModel, includedSources, schemaId, branchId, additionalContext, isGlobalWorkspace) {
  var ppf = AvoFormat.newTextBuilder(undefined);
  var allSystemProperties = AvoModel.getSystemProperties__mappedModel(model.properties);
  var match = Belt_Array.partition(allSystemProperties, (function (property) {
          return property.auto;
        }));
  AvoFormat.fprintF(ppf, "%s", undefined, [JSON.stringify(printSchema(schemaName, model, legacyModel, Belt_List.fromArray(match[1]), includedSources, schemaId, branchId, isGlobalWorkspace, additionalContext), null, 2)]);
  return AvoFormat.buildText(ppf);
}

function generateLibForCodegen(schemaName, source, model) {
  var legacyModelEnriched = AvoModel.enrichPropertyBundlesForCodegen(AvoModel.enrichModelForCodegen(undefined, undefined, undefined, AvoModel.enrichModel(AvoModel.filterUnusedPropertyBundles(AvoModel.filterExcludedEventsFromModel(FlattenVariantsUseCase.flatten(TrackingPlanMappedModel.toOld(model)), source)), {
                hd: source,
                tl: /* [] */0
              }), source), undefined, {
        hd: source.id,
        tl: /* [] */0
      });
  var model$1 = TrackingPlanMappedModel.fromOld(legacyModelEnriched);
  var partial_arg_0 = source.id;
  var partial_arg = {
    hd: partial_arg_0,
    tl: /* [] */0
  };
  var func = function (param, param$1, param$2, param$3) {
    return generateLib(schemaName, model$1, legacyModelEnriched, partial_arg, param, param$1, param$2, param$3);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      return Curry._2(func$1, param, undefined);
    };
  };
}

function generateJsonSchema(schemaName, sources, context, includedSourcesOpt, forceIncludeAllPropertyBundlesOpt, model) {
  var includedSources = includedSourcesOpt !== undefined ? includedSourcesOpt : /* [] */0;
  var forceIncludeAllPropertyBundles = forceIncludeAllPropertyBundlesOpt !== undefined ? forceIncludeAllPropertyBundlesOpt : false;
  var legacyModelEnriched = AvoModel.enrichPropertyBundlesForCodegen(AvoModel.enrichModel(AvoModel.filterUnusedPropertyBundles(FlattenVariantsUseCase.flatten(TrackingPlanMappedModel.toOld(model))), sources), forceIncludeAllPropertyBundles, includedSources);
  var model$1 = TrackingPlanMappedModel.fromOld(legacyModelEnriched);
  return function (param) {
    var func = function (param$1, param$2, param$3) {
      return generateLib(schemaName, model$1, legacyModelEnriched, includedSources, param, param$1, param$2, param$3);
    };
    return function (param) {
      return Curry._2(func, param, context);
    };
  };
}

function generateLibForWebhook(schemaName, sources, filters, context, model) {
  var model$1 = filters ? ({
        archive: model.archive,
        branchPointer: model.branchPointer,
        migrations: model.migrations,
        rules: model.rules,
        types: model.types,
        categories: model.categories,
        destinations: model.destinations,
        events: Curry._1(TrackingPlanMappedModel.Events.fromList, AvoModel.filterExcludedEvents(Curry._1(TrackingPlanMappedModel.Events.toList, model.events), filters)),
        groupTypes: model.groupTypes,
        integrations: model.integrations,
        metrics: model.metrics,
        openBranches: model.openBranches,
        properties: model.properties,
        propertyBundles: model.propertyBundles,
        sources: model.sources,
        globalRequirements: model.globalRequirements
      }) : ({
        archive: model.archive,
        branchPointer: model.branchPointer,
        migrations: model.migrations,
        rules: model.rules,
        types: model.types,
        categories: model.categories,
        destinations: model.destinations,
        events: Curry._1(TrackingPlanMappedModel.Events.fromList, AvoModel.filterExcludedEvents(Curry._1(TrackingPlanMappedModel.Events.toList, model.events), Belt_List.map(sources, (function (source) {
                        return {
                                NAME: "Source",
                                VAL: source.id
                              };
                      })))),
        groupTypes: model.groupTypes,
        integrations: model.integrations,
        metrics: model.metrics,
        openBranches: model.openBranches,
        properties: model.properties,
        propertyBundles: model.propertyBundles,
        sources: model.sources,
        globalRequirements: model.globalRequirements
      });
  var includedSources = PublishingUtils.getIncludedSources(filters, sources, Curry._1(TrackingPlanMappedModel.Sources.toList, model$1.sources));
  return generateJsonSchema(schemaName, sources, context, includedSources, undefined, model$1);
}

export {
  encodeGroup ,
  printProperties ,
  printEvent ,
  printMetricWhere ,
  printMetricGroupBy ,
  printMetricEvent ,
  printMetric ,
  printSource ,
  printDestination ,
  printGoal ,
  getCategoriesForMetricsAndEvents ,
  encodeEventSourceDestination ,
  encodeEventSource ,
  printSchema ,
  generateLib ,
  generateLibForCodegen ,
  generateJsonSchema ,
  generateLibForWebhook ,
}
/* AvoModel Not a pure module */
