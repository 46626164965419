// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Select from "./Select.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_format from "rescript/lib/es6/caml_format.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyValidationUtils from "./PropertyValidationUtils.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";

function PinPropertyValueModalTypeInput(Props) {
  var pinnedValue = Props.pinnedValue;
  var onChange = Props.onChange;
  var property = Props.property;
  var model = Props.model;
  var onEnterOpt = Props.onEnter;
  var $$event = Props.event;
  var onEnter = onEnterOpt !== undefined ? onEnterOpt : (function (param) {
        
      });
  var allowedValues = Belt_List.fromArray(Belt_Array.map(GetEventSpecificPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), property, $$event), (function (param) {
              return param[0];
            })));
  var match = property.type_;
  switch (match) {
    case "bool" :
        return React.createElement(Select.make, {
                    onSelect: (function (value) {
                        Curry._1(onChange, (function (param) {
                                if (value === "") {
                                  return ;
                                } else {
                                  return {
                                          NAME: "BooleanLit",
                                          VAL: Pervasives.bool_of_string(value)
                                        };
                                }
                              }));
                      }),
                    onEnter: onEnter,
                    options: Belt_Array.concat(pinnedValue !== undefined ? (
                            typeof pinnedValue === "object" ? (
                                pinnedValue.NAME === "BooleanLit" ? [[
                                      {
                                        NAME: "Label",
                                        VAL: "Remove value"
                                      },
                                      ""
                                    ]] : [[
                                      {
                                        NAME: "Label",
                                        VAL: "Pick a value"
                                      },
                                      ""
                                    ]]
                              ) : [[
                                  {
                                    NAME: "Label",
                                    VAL: "Pick a value"
                                  },
                                  ""
                                ]]
                          ) : [[
                              {
                                NAME: "Label",
                                VAL: "Pick a value"
                              },
                              ""
                            ]], [
                          [
                            {
                              NAME: "Label",
                              VAL: "true"
                            },
                            "true"
                          ],
                          [
                            {
                              NAME: "Label",
                              VAL: "false"
                            },
                            "false"
                          ]
                        ]),
                    value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "BooleanLit" ? Pervasives.string_of_bool(pinnedValue.VAL) : "",
                    autoFocus: true
                  });
    case "float" :
        var tmp = {
          value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "FloatLit" ? pinnedValue.VAL.toString() : "",
          onChange: (function (value) {
              Curry._1(onChange, (function (param) {
                      if (value === "") {
                        return ;
                      } else {
                        return {
                                NAME: "FloatLit",
                                VAL: Caml_format.float_of_string(value)
                              };
                      }
                    }));
            }),
          autoFocus: true,
          onEnter: onEnter,
          placeholder: Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                          if (typeof validation !== "object") {
                            return ;
                          }
                          if (validation.NAME !== "Min") {
                            return ;
                          }
                          var match = validation.VAL;
                          if (typeof match === "object" && match.NAME === "FloatLit") {
                            return match.VAL.toString();
                          }
                          
                        }))), "42"),
          step: 0.001,
          type_: "number"
        };
        var tmp$1 = PropertyValidationUtils.getMinFloat(property);
        if (tmp$1 !== undefined) {
          tmp.min = tmp$1;
        }
        var tmp$2 = PropertyValidationUtils.getMaxFloat(property);
        if (tmp$2 !== undefined) {
          tmp.max = tmp$2;
        }
        return React.createElement(Input.make, tmp);
    case "int" :
        var tmp$3 = {
          value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "IntLit" ? String(pinnedValue.VAL) : "",
          onChange: (function (value) {
              Curry._1(onChange, (function (param) {
                      if (value === "") {
                        return ;
                      } else {
                        return {
                                NAME: "IntLit",
                                VAL: Caml_format.int_of_string(value)
                              };
                      }
                    }));
            }),
          autoFocus: true,
          onEnter: onEnter,
          placeholder: Belt_Option.getWithDefault(Belt_Option.map(PropertyValidationUtils.getMinInt(property), (function (prim) {
                      return String(prim);
                    })), "42"),
          step: 1,
          type_: "number"
        };
        var tmp$4 = PropertyValidationUtils.getMinFloat(property);
        if (tmp$4 !== undefined) {
          tmp$3.min = tmp$4;
        }
        var tmp$5 = PropertyValidationUtils.getMaxFloat(property);
        if (tmp$5 !== undefined) {
          tmp$3.max = tmp$5;
        }
        return React.createElement(Input.make, tmp$3);
    case "object" :
        var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(property.validations, (function (validation) {
                        if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                          return validation.VAL;
                        }
                        
                      }))), /* [] */0);
        if (propertyRefs) {
          return React.createElement("div", undefined, Belt_List.toArray(Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                                var maybeProperty = ModelUtils.getPropertyById(propertyRef.id, model);
                                if (maybeProperty === undefined) {
                                  return ;
                                }
                                if (maybeProperty.TAG === /* PropertyRef */0) {
                                  return ;
                                }
                                var property = maybeProperty._0;
                                return Caml_option.some(React.createElement("div", undefined, React.createElement("div", undefined, property.name), React.createElement("div", undefined, property.type_)));
                              }))));
        } else {
          return "No child properties defined for this object";
        }
    case "string" :
        if (allowedValues) {
          return React.createElement(Select.make, {
                      onSelect: (function (value) {
                          Curry._1(onChange, (function (param) {
                                  if (value === "") {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "StringLit",
                                            VAL: value
                                          };
                                  }
                                }));
                        }),
                      onEnter: onEnter,
                      options: Belt_List.toArray(Belt_List.add(Belt_List.map(allowedValues, (function (match_) {
                                      return [
                                              {
                                                NAME: "Label",
                                                VAL: match_
                                              },
                                              match_
                                            ];
                                    })), [
                                {
                                  NAME: "Label",
                                  VAL: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? "Remove pinned value" : "Pick a value"
                                },
                                ""
                              ])),
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      autoFocus: true
                    });
        } else {
          return React.createElement(Input.make, {
                      value: pinnedValue !== undefined && typeof pinnedValue === "object" && pinnedValue.NAME === "StringLit" ? pinnedValue.VAL : "",
                      onChange: (function (value) {
                          Curry._1(onChange, (function (param) {
                                  if (value === "") {
                                    return ;
                                  } else {
                                    return {
                                            NAME: "StringLit",
                                            VAL: value
                                          };
                                  }
                                }));
                        }),
                      autoFocus: true,
                      onEnter: onEnter,
                      placeholder: "Pinned Value"
                    });
        }
    default:
      return "Cannot pin property of type " + match + "";
  }
}

var make = PinPropertyValueModalTypeInput;

export {
  make ,
}
/* Input Not a pure module */
