// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";

function toString(t) {
  switch (t) {
    case /* Prod */0 :
        return "prod";
    case /* Staging */1 :
        return "staging";
    case /* Dev */2 :
        return "dev";
    
  }
}

function fromString(str) {
  var match = str.toLowerCase();
  switch (match) {
    case "dev" :
    case "development" :
        return /* Dev */2;
    case "prod" :
    case "production" :
        return /* Prod */0;
    case "staging" :
        return /* Staging */1;
    default:
      return ;
  }
}

function decode(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "dev" :
        return /* Dev */2;
    case "prod" :
        return /* Prod */0;
    case "staging" :
        return /* Staging */1;
    default:
      return ;
  }
}

function toFormattedString(t) {
  switch (t) {
    case /* Prod */0 :
        return "Production";
    case /* Staging */1 :
        return "Staging";
    case /* Dev */2 :
        return "Development";
    
  }
}

export {
  toString ,
  fromString ,
  decode ,
  toFormattedString ,
}
/* No side effect */
