// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "rescript/lib/es6/string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getEmailDomain(email) {
  var atIndex = email.indexOf("@");
  var lastDotIndex = email.lastIndexOf(".");
  if (atIndex > 0 && lastDotIndex > atIndex && lastDotIndex < (email.length - 1 | 0)) {
    return email.substr(atIndex + 1 | 0);
  }
  
}

var googleAuthMethodLowercaseString = "google.com";

var samlAuthMethodLowercaseString = "saml";

function checkUserAuthProviderToMatch(user, providerToMatch) {
  var providerData = user.providerData;
  var len = providerData.length;
  if (len !== 1) {
    if (len !== 0) {
      return user.getIdTokenResult().then(function (tokenResult) {
                  var providerId = tokenResult.signInProvider;
                  if ($$String.lowercase_ascii(providerId) !== providerToMatch) {
                    return Promise.resolve(/* ProviderNotAllowed */1);
                  } else {
                    return Promise.resolve(/* ProviderAllowed */0);
                  }
                });
    } else {
      return Promise.resolve(/* ProviderNotAllowed */1);
    }
  }
  var item = providerData[0];
  var providerId = item.providerId;
  if ($$String.lowercase_ascii(providerId) !== providerToMatch) {
    return Promise.resolve(/* ProviderNotAllowed */1);
  } else {
    return Promise.resolve(/* ProviderAllowed */0);
  }
}

function ssoModeToAuthMethod(ssoMode) {
  if (typeof ssoMode === "number") {
    if (ssoMode !== 0) {
      return "Google";
    } else {
      return "Default";
    }
  } else {
    return "Saml";
  }
}

function parseSsoProvider(ssoProvider) {
  if (ssoProvider === undefined) {
    return /* NotSso */0;
  }
  var provider = Caml_option.valFromOption(ssoProvider);
  if ($$String.lowercase_ascii(provider.authMethod) === samlAuthMethodLowercaseString) {
    return /* SAML */{
            _0: provider.authProviderId
          };
  } else if ($$String.lowercase_ascii(provider.authMethod) === googleAuthMethodLowercaseString) {
    return /* Google */1;
  } else {
    return /* NotSso */0;
  }
}

export {
  getEmailDomain ,
  googleAuthMethodLowercaseString ,
  samlAuthMethodLowercaseString ,
  checkUserAuthProviderToMatch ,
  ssoModeToAuthMethod ,
  parseSsoProvider ,
}
/* No side effect */
