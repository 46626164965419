// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as DateFns from "../DateFns.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as InspectorViewEventsList__V2 from "../inspector/InspectorViewEventsList__V2.mjs";

var unknownColor = Styles.Color.light07;

var alwaysCorrectColor = Styles.Color.green;

var sometimesCorrectColor = Styles.Color.orange;

var neverCorrectColor = Styles.Color.red;

function statusStyles(loadStatus) {
  if (loadStatus.TAG === /* Inspector */0) {
    var match = loadStatus._1;
    if (typeof match !== "number") {
      var tmp = match._0.eventStatus;
      if (typeof tmp === "number") {
        return {
                hd: Css.backgroundColor(unknownColor),
                tl: {
                  hd: Css.color(Styles.Color.white),
                  tl: /* [] */0
                }
              };
      }
      switch (tmp.TAG | 0) {
        case /* AlwaysSeenCorrectlyLatestVersion */0 :
            return {
                    hd: Css.backgroundColor(alwaysCorrectColor),
                    tl: {
                      hd: Css.paddingTop({
                            NAME: "px",
                            VAL: 1
                          }),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  };
        case /* SometimesSeenCorrectlyLatestVersion */1 :
            return {
                    hd: Css.backgroundColor(sometimesCorrectColor),
                    tl: {
                      hd: Css.paddingTop({
                            NAME: "px",
                            VAL: 1
                          }),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  };
        case /* NeverSeenCorrectlyLatestVersion */2 :
            return {
                    hd: Css.backgroundColor(neverCorrectColor),
                    tl: {
                      hd: Css.paddingTop({
                            NAME: "px",
                            VAL: 1
                          }),
                      tl: {
                        hd: Css.color(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  };
        case /* AlwaysSeenCorrectlyInOlderVersion */3 :
            return {
                    hd: Css.border(Css.px(1), "solid", alwaysCorrectColor),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.color(alwaysCorrectColor),
                        tl: /* [] */0
                      }
                    }
                  };
        case /* SometimesSeenCorrectlyInOlderVersion */4 :
            return {
                    hd: Css.border(Css.px(1), "solid", sometimesCorrectColor),
                    tl: {
                      hd: Css.color(sometimesCorrectColor),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  };
        case /* NeverSeenCorrectlyInOlderVersion */5 :
            return {
                    hd: Css.border(Css.px(1), "solid", neverCorrectColor),
                    tl: {
                      hd: Css.color(neverCorrectColor),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: /* [] */0
                      }
                    }
                  };
        
      }
    }
    
  } else {
    var match$1 = loadStatus._0;
    if (typeof match$1 !== "number") {
      if (match$1._0) {
        if (match$1._1) {
          return {
                  hd: Css.backgroundColor(sometimesCorrectColor),
                  tl: {
                    hd: Css.color(Styles.Color.white),
                    tl: /* [] */0
                  }
                };
        } else {
          return {
                  hd: Css.backgroundColor(alwaysCorrectColor),
                  tl: {
                    hd: Css.color(Styles.Color.white),
                    tl: /* [] */0
                  }
                };
        }
      } else if (match$1._1) {
        return {
                hd: Css.backgroundColor(neverCorrectColor),
                tl: {
                  hd: Css.color(Styles.Color.white),
                  tl: /* [] */0
                }
              };
      } else {
        return {
                hd: Css.backgroundColor(unknownColor),
                tl: {
                  hd: Css.color(Styles.Color.white),
                  tl: /* [] */0
                }
              };
      }
    }
    
  }
  return {
          hd: Css.border(Css.px(1), "solid", unknownColor),
          tl: {
            hd: Css.backgroundColor(Styles.Color.light02),
            tl: {
              hd: Css.color(unknownColor),
              tl: /* [] */0
            }
          }
        };
}

function renderWeightedParagraph(texts) {
  return Belt_Array.map(texts, (function (text) {
                var variant = text.NAME;
                if (variant === "RegularWithTitle") {
                  var match = text.VAL;
                  var text$1 = match[0];
                  return React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Regular",
                              color: Styles.Color.light11,
                              title: match[1],
                              children: text$1,
                              key: text$1
                            });
                }
                if (variant === "Link") {
                  var match$1 = text.VAL;
                  var text$2 = match$1[0];
                  return React.createElement(Link.make, {
                              path: match$1[1],
                              children: null,
                              key: text$2
                            }, React.createElement($$Text.make, {
                                  element: "Span",
                                  size: "Small",
                                  weight: "Medium",
                                  color: Styles.Color.darkBlue,
                                  children: text$2
                                }), React.createElement("span", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("inlineBlock"),
                                        tl: {
                                          hd: Css.transform(Css.rotate(Css.deg(180.0))),
                                          tl: {
                                            hd: Css.marginLeft(Css.px(2)),
                                            tl: {
                                              hd: Css.height(Css.px(24)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(Icons.ArrowLeft.make, {
                                      size: 8,
                                      color: Styles.Color.darkBlue
                                    })));
                }
                if (variant === "Medium") {
                  var text$3 = text.VAL;
                  return React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              weight: "Medium",
                              color: Styles.Color.light11,
                              children: text$3,
                              key: text$3
                            });
                }
                var text$4 = text.VAL;
                return React.createElement($$Text.make, {
                            element: "Span",
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.light11,
                            children: text$4,
                            key: text$4
                          });
              }));
}

function percentageString(a, b) {
  return String(Math.round(a / b * 100) | 0);
}

function linkToInspectorEvent(eventName, env) {
  var envQuery;
  switch (env) {
    case /* Prod */0 :
        envQuery = "Production";
        break;
    case /* Dev */1 :
        envQuery = "Development";
        break;
    case /* Staging */2 :
        envQuery = "Staging";
        break;
    
  }
  var selected = Belt_Option.getWithDefault(InspectorViewEventsList__V2.stringifyActiveCell({
            eventName: eventName,
            propertyNames: undefined,
            column: 0
          }), "");
  return Router.Link.getSchemaRouteLink(undefined, {
              NAME: "inspector",
              VAL: "events"
            }) + ("?filterByEventName=" + eventName + "&environment=" + envQuery + "&selected=" + selected + "");
}

function ImplementationStatusStates$ImplementationStatusMessage(Props) {
  var implementationStatus = Props.implementationStatus;
  var eventName = Props.eventName;
  var env = Props.env;
  if (implementationStatus.TAG === /* Inspector */0) {
    var match = implementationStatus._1;
    if (typeof match === "number") {
      if (match !== 0) {
        return renderWeightedParagraph([{
                      NAME: "Regular",
                      VAL: "Inspector not installed"
                    }]);
      } else {
        return renderWeightedParagraph([{
                      NAME: "Regular",
                      VAL: "Loading..."
                    }]);
      }
    }
    var match$1 = match._0;
    var match$2 = match$1.eventStatus;
    var period = match$1.period;
    if (typeof match$2 === "number") {
      var tmp;
      tmp = period.TAG === /* Version */0 ? "during past 30 days" : "during past " + period._0;
      return renderWeightedParagraph([{
                    NAME: "Regular",
                    VAL: "Never seen " + tmp
                  }]);
    }
    switch (match$2.TAG | 0) {
      case /* AlwaysSeenCorrectlyLatestVersion */0 :
          var lastSeenCorrectly = match$2._2;
          var tmp$1;
          tmp$1 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$2;
          tmp$2 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "Always sent according to current tracking plan in " + tmp$1,
                          "" + String(match$2._0) + " out of " + String(match$2._1) + " sent correctly"
                        ]
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "" + tmp$2 + ", last seen " + DateFns.formatDistanceToNow(undefined, undefined, lastSeenCorrectly) + " ago",
                          lastSeenCorrectly.toString()
                        ]
                      }
                    ]);
      case /* SometimesSeenCorrectlyLatestVersion */1 :
          var lastSeenIncorrectly = match$2._3;
          var lastSeenCorrectly$1 = match$2._2;
          var totalCount = match$2._1;
          var correctCount = match$2._0;
          var tmp$3;
          tmp$3 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$4;
          tmp$4 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "Sometimes sent according to current tracking plan (" + percentageString(correctCount, totalCount) + "% correct) in " + tmp$3 + " ",
                          "" + String(correctCount) + " out of " + String(totalCount) + " sent correctly (" + percentageString(correctCount, totalCount) + "% correct)"
                        ]
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "" + tmp$4 + ", last seen correct " + DateFns.formatDistanceToNow(undefined, undefined, lastSeenCorrectly$1) + " ago, last seen incorrect " + DateFns.formatDistanceToNow(undefined, undefined, lastSeenIncorrectly) + " ago. ",
                          "Last seen correct:\n" + lastSeenCorrectly$1.toString() + "\n\nLast seen incorrect\n" + lastSeenIncorrectly.toString() + ""
                        ]
                      },
                      {
                        NAME: "Link",
                        VAL: [
                          "View issues in Inspector",
                          linkToInspectorEvent(eventName, env)
                        ]
                      }
                    ]);
      case /* NeverSeenCorrectlyLatestVersion */2 :
          var lastSeenIncorrectly$1 = match$2._2;
          var totalCount$1 = match$2._1;
          var correctCount$1 = match$2._0;
          var tmp$5;
          tmp$5 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$6;
          tmp$6 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "Never sent according to current tracking plan in " + tmp$5,
                          "" + String(correctCount$1) + " out of " + String(totalCount$1) + " sent correctly (" + percentageString(correctCount$1, totalCount$1) + "% correct)"
                        ]
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          "" + tmp$6 + ", last seen incorrect " + DateFns.formatDistanceToNow(undefined, undefined, lastSeenIncorrectly$1) + " ago. ",
                          lastSeenIncorrectly$1.toString()
                        ]
                      },
                      {
                        NAME: "Link",
                        VAL: [
                          "View issues in Inspector",
                          linkToInspectorEvent(eventName, env)
                        ]
                      }
                    ]);
      case /* AlwaysSeenCorrectlyInOlderVersion */3 :
          var lastSeen = match$2._3;
          var tmp$7;
          tmp$7 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$8;
          tmp$8 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "Regular",
                        VAL: "Never seen according to current tracking plan in " + tmp$7
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "Regular",
                        VAL: tmp$8 + ", last seen in version "
                      },
                      {
                        NAME: "Medium",
                        VAL: match$2._2
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          " " + DateFns.formatDistanceToNow(undefined, undefined, lastSeen) + " ago where it was always sent correctly",
                          lastSeen.toString()
                        ]
                      }
                    ]);
      case /* SometimesSeenCorrectlyInOlderVersion */4 :
          var lastSeenIncorrectly$2 = match$2._4;
          var lastSeenCorrectly$2 = match$2._3;
          var tmp$9;
          tmp$9 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$10;
          tmp$10 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "Regular",
                        VAL: "Never seen according to current tracking plan in " + tmp$9
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "Regular",
                        VAL: tmp$10 + ", last seen in version "
                      },
                      {
                        NAME: "Medium",
                        VAL: match$2._2
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          " " + DateFns.formatDistanceToNow(undefined, undefined, new Date(Math.max(lastSeenCorrectly$2.getTime(), lastSeenIncorrectly$2.getTime()))) + " ago where it was sometimes sent correctly (" + percentageString(match$2._0, match$2._1) + "% correct). ",
                          "Last seen correct:\n" + lastSeenCorrectly$2.toString() + "\n\nLast seen incorrect:\n" + lastSeenIncorrectly$2.toString() + ". "
                        ]
                      },
                      {
                        NAME: "Link",
                        VAL: [
                          "View issues in Inspector",
                          linkToInspectorEvent(eventName, env)
                        ]
                      }
                    ]);
      case /* NeverSeenCorrectlyInOlderVersion */5 :
          var lastSeenIncorrectly$3 = match$2._3;
          var tmp$11;
          tmp$11 = period.TAG === /* Version */0 ? "version " : "past ";
          var tmp$12;
          tmp$12 = period.TAG === /* Version */0 ? " (latest)" : "";
          return renderWeightedParagraph([
                      {
                        NAME: "Regular",
                        VAL: "Never seen according to current tracking plan in " + tmp$11
                      },
                      {
                        NAME: "Medium",
                        VAL: period._0
                      },
                      {
                        NAME: "Regular",
                        VAL: tmp$12 + ", last seen in version "
                      },
                      {
                        NAME: "Medium",
                        VAL: match$2._2
                      },
                      {
                        NAME: "RegularWithTitle",
                        VAL: [
                          " " + DateFns.formatDistanceToNow(undefined, undefined, lastSeenIncorrectly$3) + " ago where it was never sent correctly. ",
                          lastSeenIncorrectly$3.toString()
                        ]
                      },
                      {
                        NAME: "Link",
                        VAL: [
                          "View issues in Inspector",
                          linkToInspectorEvent(eventName, env)
                        ]
                      }
                    ]);
      
    }
  } else {
    var match$3 = implementationStatus._0;
    if (typeof match$3 === "number") {
      if (match$3 !== 0) {
        return renderWeightedParagraph([{
                      NAME: "Regular",
                      VAL: "Not implemented with Avo Codegen"
                    }]);
      } else {
        return renderWeightedParagraph([{
                      NAME: "Regular",
                      VAL: "Loading..."
                    }]);
      }
    }
    var match$4 = match$3._0;
    if (match$4) {
      var match$5 = match$3._1;
      var validGroup = match$4.hd;
      if (match$5) {
        var invalidGroup = match$5.hd;
        return renderWeightedParagraph([{
                      NAME: "RegularWithTitle",
                      VAL: [
                        "Sometimes sent according to current tracking plan (" + percentageString(validGroup.count, validGroup.count + invalidGroup.count | 0) + "% correct), last seen correct " + DateFns.formatDistanceToNow(undefined, undefined, new Date(validGroup.lastSeen.value)) + " ago, last seen incorrect " + DateFns.formatDistanceToNow(undefined, undefined, new Date(invalidGroup.lastSeen.value)) + " ago.",
                        "Last seen correct: " + new Date(validGroup.lastSeen.value).toString() + ", last seen incorrect: " + new Date(invalidGroup.lastSeen.value).toString() + ""
                      ]
                    }]);
      }
      var lastSeen$1 = new Date(validGroup.lastSeen.value);
      return renderWeightedParagraph([{
                    NAME: "RegularWithTitle",
                    VAL: [
                      "Always sent correctly, last seen " + DateFns.formatDistanceToNow(undefined, undefined, lastSeen$1) + " ago",
                      lastSeen$1.toString()
                    ]
                  }]);
    }
    var match$6 = match$3._1;
    if (!match$6) {
      return renderWeightedParagraph([{
                    NAME: "Regular",
                    VAL: "Never seen"
                  }]);
    }
    var distace = DateFns.formatDistanceToNow(undefined, undefined, new Date(match$6.hd.lastSeen.value));
    return renderWeightedParagraph([{
                  NAME: "Regular",
                  VAL: "Never sent correct, last seen incorrect " + distace + " ago. "
                }]);
  }
}

var ImplementationStatusMessage = {
  make: ImplementationStatusStates$ImplementationStatusMessage
};

export {
  unknownColor ,
  alwaysCorrectColor ,
  sometimesCorrectColor ,
  neverCorrectColor ,
  statusStyles ,
  renderWeightedParagraph ,
  percentageString ,
  linkToInspectorEvent ,
  ImplementationStatusMessage ,
}
/* Css Not a pure module */
