// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as DestinationSource from "./DestinationSource.mjs";

function isDestinationSupported(destination, source) {
  var destinationType = destination.type_;
  if (destinationType !== undefined) {
    return Belt_Array.some(AvoConfig.getSupportedDestinations(source.platform, source.language), (function (destination) {
                  return destination === destinationType;
                }));
  } else {
    return false;
  }
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.borderRadius(Css.px(6)),
        tl: {
          hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
          tl: /* [] */0
        }
      }
    });

var emptyStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(30)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.flexDirection("column"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function DestinationSources(Props) {
  var destination = Props.destination;
  var sources = Props.sources;
  var onEnableAll = Props.onEnableAll;
  var onAddSource = Props.onAddSource;
  var globalSend = Props.globalSend;
  var onToggleSource = Props.onToggleSource;
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var availability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  var allSupportedSourcesAreSelected = Belt_List.every(sources, (function (source) {
          if (isDestinationSupported(destination, source)) {
            return Belt_List.someU(source.destinations, (function (param) {
                          return param.destinationId === destination.id;
                        }));
          } else {
            return true;
          }
        }));
  var match = destination.type_;
  return React.createElement("section", undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginLeft(Css.px(20)),
                            tl: /* [] */0
                          })
                    }, React.createElement($$Text.make, {
                          element: "H1",
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light11,
                          children: "Sources"
                        })), availability === "Unavailable" && !allSupportedSourcesAreSelected ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Button.make, {
                            label: "Enable all",
                            onClick: (function (param) {
                                var name = destination.name;
                                if (window.confirm(name !== undefined ? "Enabling all sources will add " + name + " as a destination to all sources. This cannot be undone unless you upgrade your plan. Are you sure that you want to enable all sources for " + name + "?" : "You are about to add this destination to all sources. This cannot be undone unless you upgrade your plan. Are you sure that you want to enable all sources?")) {
                                  return Curry._1(onEnableAll, undefined);
                                }
                                
                              }),
                            style: "outline"
                          })) : null), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement("div", {
                  className: rootStyles
                }, match !== undefined ? (
                    sources ? Belt_List.toArray(Belt_List.map(sources, (function (source) {
                                  return React.createElement(DestinationSource.make, {
                                              source: source,
                                              destination: destination,
                                              globalSend: globalSend,
                                              onChange: (function (isSelected) {
                                                  Curry._2(onToggleSource, source, isSelected);
                                                }),
                                              availability: availability,
                                              key: source.path
                                            });
                                }))) : React.createElement("div", {
                            className: emptyStyles
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "No sources defined"
                              }), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.maxWidth(Css.px(600)),
                                      tl: {
                                        hd: Css.marginTop(Css.px(10)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(Button.make, {
                                    label: "Add Source",
                                    onClick: onAddSource
                                  })))
                  ) : React.createElement("div", {
                        className: emptyStyles
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light10,
                            children: "You must select analytics tool before configuring sources."
                          }))), availability === "Available" ? null : React.createElement("footer", {
                    className: footerStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light10,
                        children: availability === "Unavailable" ? "Upgrade plan to make changes" : "Upgrade plan to keep destinations editable"
                      }), React.createElement(Button.make, {
                        label: "Learn more",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "BillingPrompt",
                                    VAL: "ConfigureEventsPerPlatform"
                                  }
                                });
                          }),
                        style: availability === "Unavailable" ? "primary" : "outline"
                      })));
}

var make = DestinationSources;

export {
  isDestinationSupported ,
  rootStyles ,
  emptyStyles ,
  footerStyles ,
  make ,
}
/* rootStyles Not a pure module */
