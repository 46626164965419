// Generated by ReScript, PLEASE EDIT WITH CARE


var devApiKey = "pk_test_bd8FQeoe0Fn4drWt9OIO2GA700fODCwXNq";

var prodApiKey = "pk_live_DSJrx0ajB0ritPHHFDKdO6Wz001ULVgJNO";

function getApiKey(param) {
  if (process.env.NODE_ENV === "development") {
    return devApiKey;
  } else {
    return prodApiKey;
  }
}

var $$Error = {};

var Token = {};

var TokenResult = {};

export {
  devApiKey ,
  prodApiKey ,
  getApiKey ,
  $$Error ,
  Token ,
  TokenResult ,
}
/* No side effect */
