// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../../shared/models/Env.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as InspectorViewApi from "./InspectorViewApi.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as AuthenticationMethods from "../AuthenticationMethods.mjs";

var context = React.createContext([
      /* Initial */0,
      (function (_status) {
          
        })
    ]);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var ContextProvider = {
  makeProps: makeProps,
  make: make
};

function InspectorStats$Context(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return /* Initial */0;
      });
  return React.createElement(make, makeProps([
                  match[0],
                  match[1]
                ], children, undefined));
}

var Context = {
  make: InspectorStats$Context
};

function refreshAuthToken(addToast) {
  return AuthenticationMethods.refreshIdToken(undefined).then(function (result) {
              if (result.TAG === /* Ok */0) {
                return Promise.resolve(undefined);
              }
              Curry._1(addToast, {
                    message: "Session expired. Please log in again to continue.",
                    toastType: /* Error */1
                  });
              return Promise.resolve(undefined);
            });
}

function useStatsWithCache(param) {
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useContext(context);
  var setStats = match[1];
  var stats = match[0];
  var addToast = Toast.useAddToast(undefined);
  var fetchStats = function (param) {
    InspectorViewApi.getStatsWithCache(workspace.id).then(function (response) {
          if (response.NAME === "Error") {
            Curry._1(setStats, (function (currentStats) {
                    if (typeof currentStats === "number" && currentStats < 2) {
                      return /* Error */2;
                    } else {
                      return currentStats;
                    }
                  }));
            if (response.VAL === "InvalidToken") {
              return refreshAuthToken(addToast);
            } else {
              return Promise.resolve(undefined);
            }
          }
          var payload = response.VAL;
          Curry._1(setStats, (function (param) {
                  return /* Success */{
                          _0: payload
                        };
                }));
          return Promise.resolve(undefined);
        });
  };
  React.useEffect((function () {
          fetchStats(undefined);
        }), []);
  var interval = typeof stats === "number" ? (
      stats >= 2 ? 60000 : 15000
    ) : (
      Caml_obj.notequal(stats._0, []) ? 0 : 15000
    );
  Hooks.useInterval(interval, fetchStats);
  return stats;
}

function useHasStats(param) {
  var stats = useStatsWithCache(undefined);
  if (typeof stats === "number" || !Caml_obj.notequal(stats._0, [])) {
    return false;
  } else {
    return true;
  }
}

function useStatsWithPolling(initialStats, param) {
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useState(function () {
        return Belt_Option.mapWithDefault(initialStats, /* Loading */1, (function (stats) {
                      return /* Success */{
                              _0: stats
                            };
                    }));
      });
  var setStats = match[1];
  var addToast = Toast.useAddToast(undefined);
  var documentVisibility = Hooks.useDocumentVisibility(undefined);
  var fetchStats = React.useCallback((function (param) {
          InspectorViewApi.getFreshStats(workspace.id).then(function (response) {
                if (response.NAME === "Error") {
                  Curry._1(setStats, (function (currentStats) {
                          if (typeof currentStats === "number" && currentStats < 2) {
                            return /* Error */2;
                          } else {
                            return currentStats;
                          }
                        }));
                  if (response.VAL === "InvalidToken") {
                    return refreshAuthToken(addToast);
                  } else {
                    return Promise.resolve(undefined);
                  }
                }
                var payload = response.VAL;
                Curry._1(setStats, (function (param) {
                        return /* Success */{
                                _0: payload
                              };
                      }));
                return Promise.resolve(undefined);
              });
        }), []);
  React.useEffect((function () {
          Curry._1(fetchStats, undefined);
        }), []);
  Hooks.useInterval(documentVisibility === "visible" ? 15000 : 0, fetchStats);
  return match[0];
}

function getSourceStatus(source, inspectorStats) {
  if (typeof inspectorStats === "number") {
    if (inspectorStats >= 2) {
      return /* Waiting */1;
    } else {
      return /* Loading */0;
    }
  }
  var stats = Belt_Array.getByU(inspectorStats._0, (function (param) {
          return param.sourceId === source.id;
        }));
  if (stats === undefined) {
    return /* Waiting */1;
  }
  var env = Belt_Array.keepMap(stats.envs, (function (env) {
          return Env.fromString(env.env);
        }));
  return /* Receiving */{
          _0: env
        };
}

export {
  context ,
  ContextProvider ,
  Context ,
  refreshAuthToken ,
  useStatsWithCache ,
  useHasStats ,
  useStatsWithPolling ,
  getSourceStatus ,
}
/* context Not a pure module */
