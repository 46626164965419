// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as OnboardingConfig from "./OnboardingConfig.mjs";

var pillBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexWrap("wrap"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.columnGap(Css.px(8)),
            tl: {
              hd: Css.rowGap(Css.px(16)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var selectorStyles_0 = Css.fontFamily(Styles.FontFamily.polar);

var selectorStyles_1 = {
  hd: Css.fontWeight("normal"),
  tl: {
    hd: Css.fontSize(Css.px(16)),
    tl: {
      hd: Css.color(Styles.Color.light12),
      tl: {
        hd: Css.paddingTop(Css.px(12)),
        tl: {
          hd: Css.paddingBottom(Css.px(12)),
          tl: {
            hd: Css.paddingLeft(Css.px(16)),
            tl: {
              hd: Css.backgroundPosition({
                    NAME: "hv",
                    VAL: [
                      "right",
                      "center"
                    ]
                  }),
              tl: {
                hd: Css.backgroundImage({
                      NAME: "url",
                      VAL: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIj48cGF0aCBmaWxsPSIjMTkxNzE5IiBkPSJtMTIgMTMuMiA1LTUgMS40IDEuNEwxMiAxNiA1LjYgOS42bDEuNS0xLjQgNC45IDVaIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGgyNHYyNEgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg=="
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    }
  }
};

var selectorStyles = {
  hd: selectorStyles_0,
  tl: selectorStyles_1
};

function OnboardingStep$Pill(Props) {
  var selected = Props.selected;
  var label = Props.label;
  var style = Props.style;
  var onClick = Props.onClick;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.paddingTop(Css.px(8)),
                          tl: {
                            hd: Css.paddingRight(Css.px(24)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(9)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(24)),
                                tl: {
                                  hd: Css.borderRadius(Css.px(32)),
                                  tl: {
                                    hd: Css.cursor(Css.pointer),
                                    tl: {
                                      hd: Css.fontSize(Css.px(16)),
                                      tl: {
                                        hd: Css.fontFamily(Styles.FontFamily.polar),
                                        tl: {
                                          hd: Css.fontWeight(selected ? "medium" : "normal"),
                                          tl: {
                                            hd: Css.backgroundColor(selected ? Styles.Color.getSecondaryColor(style, "pale") : Styles.Color.light03),
                                            tl: {
                                              hd: Css.color(selected ? Styles.Color.getSecondaryColor(style, "deep") : Styles.Color.light12),
                                              tl: {
                                                hd: Css.border(Css.px(1), "solid", selected ? Styles.Color.getSecondaryColor(style, "dark") : Styles.Color.light03),
                                                tl: {
                                                  hd: Css.transition({
                                                        NAME: "ms",
                                                        VAL: Styles.Duration.$$short
                                                      }, undefined, undefined, "all"),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.backgroundColor(Styles.Color.getSecondaryColor(style, "pale")),
                                                          tl: {
                                                            hd: Css.borderColor(selected ? Styles.Color.getSecondaryColor(style, "dark") : Styles.Color.getSecondaryColor(style, "pale")),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: onClick
            }, label);
}

var Pill = {
  make: OnboardingStep$Pill
};

function headerStyles(style) {
  return Curry._1(Css.style, {
              hd: Css.fontSize(Css.px(20)),
              tl: {
                hd: Css.fontFamily(Styles.FontFamily.acid),
                tl: {
                  hd: Css.fontWeight("light"),
                  tl: {
                    hd: Css.margin(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.getSecondaryColor(style, "deep")),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("stretch"),
        tl: /* [] */0
      }
    });

function left(step) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.width(Css.pct(50)),
                  tl: {
                    hd: Css.paddingTop(Css.px(184)),
                    tl: {
                      hd: Css.paddingRight(Css.vw(5.0)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(60)),
                        tl: {
                          hd: Css.paddingLeft(Css.vw(5.0)),
                          tl: {
                            hd: Css.backgroundColor(OnboardingConfig.getColor(step)),
                            tl: {
                              hd: Css.color(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var title = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(32)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.acid),
        tl: {
          hd: Css.fontWeight("normal"),
          tl: /* [] */0
        }
      }
    });

var subtitle = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(20)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.acid),
        tl: {
          hd: Css.fontWeight("light"),
          tl: /* [] */0
        }
      }
    });

var comfortText = Curry._1(Css.style, {
      hd: Css.fontSize(Css.px(16)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.acid),
        tl: {
          hd: Css.fontWeight("normal"),
          tl: /* [] */0
        }
      }
    });

var right = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.width(Css.pct(50)),
          tl: {
            hd: Css.height(Css.vh(100.0)),
            tl: /* [] */0
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.paddingTop(Css.px(184)),
            tl: {
              hd: Css.paddingRight(Css.vw(5.0)),
              tl: {
                hd: Css.paddingBottom(Css.px(60)),
                tl: {
                  hd: Css.paddingLeft(Css.vw(5.0)),
                  tl: {
                    hd: Css.overflowY("auto"),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var navigation = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.height(Css.px(54)),
          tl: {
            hd: Css.flexShrink(0.0),
            tl: {
              hd: Css.paddingRight(Css.px(24)),
              tl: {
                hd: Css.paddingLeft(Css.px(24)),
                tl: {
                  hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var back = Curry._1(Css.style, {
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginTop(Css.px(-4)),
          tl: {
            hd: Css.fontFamily(Styles.FontFamily.polar),
            tl: {
              hd: Css.fontSize(Css.px(16)),
              tl: {
                hd: Css.selector(" path", {
                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light12),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: Css.disabled({
                        hd: Css.cursor("default"),
                        tl: {
                          hd: Css.color(Styles.Color.light07),
                          tl: {
                            hd: Css.selector(" path", {
                                  hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                                  tl: /* [] */0
                                }),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var continueButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("inlineFlex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.cursor(Css.pointer),
                  tl: {
                    hd: Css.fontSize(Css.px(16)),
                    tl: {
                      hd: Css.fontFamily(Styles.FontFamily.polar),
                      tl: {
                        hd: Css.margin(Css.px(0)),
                        tl: {
                          hd: Css.padding(Css.px(0)),
                          tl: {
                            hd: Css.marginTop(Css.px(-4)),
                            tl: {
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.selector(" path", {
                                      hd: Css_Legacy_Core.SVG.fill(Styles.Color.light12),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.disabled({
                                        hd: Css.cursor("default"),
                                        tl: {
                                          hd: Css.color(Styles.Color.light07),
                                          tl: {
                                            hd: Css.selector(" path", {
                                                  hd: Css_Legacy_Core.SVG.fill(Styles.Color.light07),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var Style = {
  root: root,
  left: left,
  title: title,
  subtitle: subtitle,
  comfortText: comfortText,
  right: right,
  content: content,
  navigation: navigation,
  back: back,
  continueButton: continueButton
};

function OnboardingStep(Props) {
  var step = Props.step;
  var title$1 = Props.title;
  var subtitle$1 = Props.subtitle;
  var comfortTextOpt = Props.comfortText;
  var isValid = Props.isValid;
  var onNext = Props.onNext;
  var loadingOpt = Props.loading;
  var maySkipOpt = Props.maySkip;
  var children = Props.children;
  var comfortText$1 = comfortTextOpt !== undefined ? comfortTextOpt : "";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var maySkip = maySkipOpt !== undefined ? maySkipOpt : false;
  var back$1 = OnboardingConfig.getBack(step);
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: left(step)
                }, React.createElement("div", {
                      className: title
                    }, title$1), React.createElement(Spacer.make, {
                      height: 40
                    }), React.createElement("div", {
                      className: subtitle
                    }, subtitle$1), React.createElement(Spacer.make, {
                      height: 16,
                      grow: 1.0
                    }), React.createElement("div", {
                      className: comfortText
                    }, comfortText$1)), React.createElement("div", {
                  className: right
                }, React.createElement("div", {
                      className: content
                    }, children), React.createElement("div", {
                      className: navigation
                    }, back$1 !== undefined ? React.createElement(Link.make, {
                            path: OnboardingConfig.getUrlFragmentFromStep(back$1),
                            className: back,
                            children: null
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.transform(Css.rotate(Css.deg(180.0))),
                                      tl: {
                                        hd: Css.marginRight(Css.px(8)),
                                        tl: {
                                          hd: Css.marginTop(Css.px(-2)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement("svg", {
                                    height: "16",
                                    width: "18",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }, React.createElement("path", {
                                        d: "m17.78 8.53-6.75 6.75a.75.75 0 0 1-1.06-1.06l5.47-5.47H.75a.75.75 0 0 1 0-1.5h14.69L9.97 1.78A.75.75 0 0 1 11.03.72l6.75 6.75a.75.75 0 0 1 0 1.06Z"
                                      }))), "Back") : null, React.createElement(Spacer.make, {
                          grow: 1.0
                        }), loading ? React.createElement(LoadingCircle.make, {
                            color: Styles.Color.light07
                          }) : null, React.createElement(Spacer.make, {
                          width: 16
                        }), React.createElement("button", {
                          className: continueButton,
                          disabled: maySkip && isValid === "ValidEmptyState" ? false : isValid !== "Valid" || loading,
                          onClick: onNext
                        }, maySkip && isValid !== "Valid" ? "Skip for now" : "Continue", React.createElement(Spacer.make, {
                              width: 8
                            }), React.createElement("span", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(6)),
                                    tl: {
                                      hd: Css.display("inlineBlock"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("svg", {
                                  height: "16",
                                  width: "18",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, React.createElement("path", {
                                      d: "m17.78 8.53-6.75 6.75a.75.75 0 0 1-1.06-1.06l5.47-5.47H.75a.75.75 0 0 1 0-1.5h14.69L9.97 1.78A.75.75 0 0 1 11.03.72l6.75 6.75a.75.75 0 0 1 0 1.06Z"
                                    })))))));
}

var make = OnboardingStep;

export {
  pillBoxStyles ,
  selectorStyles ,
  Pill ,
  headerStyles ,
  Style ,
  make ,
}
/* pillBoxStyles Not a pure module */
