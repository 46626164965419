// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";

var container = Curry._1(Css.style, {
      hd: Css.margin2(Css.px(6), Css.px(0)),
      tl: {
        hd: Css.fontSize(Styles.FontSize.small),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.width(Css.pct(100)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var editNameButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.margin2(Css.px(0), Css.px(5)),
              tl: {
                hd: Css.fontWeight(Styles.FontWeight.semi),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.padding2(Css.px(4), Css.px(8)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.lightBlue),
                      tl: {
                        hd: Css.color(Styles.Color.darkBlue),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.textAlign("center"),
                            tl: {
                              hd: Css.position("relative"),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.deepBlue),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("text"),
                                  tl: {
                                    hd: Css.minHeight(Css.px(25)),
                                    tl: {
                                      hd: Css.minWidth(Css.px(150)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var removeButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(5), Css.px(10)),
              tl: {
                hd: Css.marginLeft(Css.px(5)),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.light08),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.red),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var editNameContainer = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var nameInputContainer = Curry._1(Css.style, {
      hd: Css.width(Css.px(500)),
      tl: {
        hd: Css.maxWidth(Css.pct(100)),
        tl: {
          hd: Css.height(Css.px(70)),
          tl: {
            hd: Css.position("relative"),
            tl: /* [] */0
          }
        }
      }
    });

var Styles$1 = {
  Color: Styles.Color,
  FontFamily: Styles.FontFamily,
  FontWeight: Styles.FontWeight,
  FontSize: Styles.FontSize,
  LineHeight: Styles.LineHeight,
  Border: Styles.Border,
  Shadow: Styles.Shadow,
  ZIndex: Styles.ZIndex,
  Duration: Styles.Duration,
  input: Styles.input,
  button: Styles.button,
  buttonReset: Styles.buttonReset,
  linkReset: Styles.linkReset,
  select: Styles.select,
  shimmer: Styles.shimmer,
  backgroundColorGuarantee: Styles.backgroundColorGuarantee,
  backgroundColorWithOverlayGuarantee: Styles.backgroundColorWithOverlayGuarantee,
  maxLines: Styles.maxLines,
  prettyWrap: Styles.prettyWrap,
  emptyStyle: Styles.emptyStyle,
  container: container,
  editNameButton: editNameButton,
  removeButton: removeButton,
  editNameContainer: editNameContainer,
  nameInputContainer: nameInputContainer
};

function getPropertyNameMappings(rules, propertyId) {
  return Belt_List.reduce(rules, {
              thisPropertyInSchema: /* [] */0,
              allPropertiesInSchema: /* [] */0
            }, (function (nameMappingsForProperties, item) {
                var thisPropertyInSchema = nameMappingsForProperties.thisPropertyInSchema;
                var match = item.item;
                var match$1 = item.definition;
                var nameMappedPropertyId;
                var variant = match.NAME;
                if (variant === "PropertyRef") {
                  nameMappedPropertyId = match.VAL[1];
                } else {
                  if (variant !== "Property") {
                    return nameMappingsForProperties;
                  }
                  nameMappedPropertyId = match.VAL;
                }
                if (typeof match$1 !== "object") {
                  return nameMappingsForProperties;
                }
                var variant$1 = match$1.NAME;
                if (variant$1 === "NameInLocalWorkspace" || variant$1 === "NameMapping") {
                  return {
                          thisPropertyInSchema: nameMappedPropertyId === propertyId ? ({
                                hd: nameMappedPropertyId,
                                tl: thisPropertyInSchema
                              }) : thisPropertyInSchema,
                          allPropertiesInSchema: {
                            hd: nameMappedPropertyId,
                            tl: nameMappingsForProperties.allPropertiesInSchema
                          }
                        };
                } else {
                  return nameMappingsForProperties;
                }
              }));
}

function sendUpdateSuccessTracking(schemaBundle, branch, propertyId, propertyGroup, modelProperties, modelRules, ruleType) {
  var match = getPropertyNameMappings(modelRules, propertyId);
  var propertyNameMappingsInSchema = match.allPropertiesInSchema;
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.propertyUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyGroup.propertyId, Belt_Option.getWithDefault(propertyGroup.propertyName, ""), propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, "UpdateRule", undefined, ruleType, Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.size(match.thisPropertyInSchema), Belt_List.length(modelProperties), NamedBranch.getId(branch), schemaBundle$1.schemaId);
}

function sendRemoveSuccessTracking(branch, modelProperties, modelRules, propertyGroup, propertyId, ruleType, schemaBundle) {
  var match = getPropertyNameMappings(modelRules, propertyId);
  var propertyNameMappingsInSchema = match.allPropertiesInSchema;
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.propertyUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, propertyGroup.propertyId, Belt_Option.getWithDefault(propertyGroup.propertyName, ""), propertyGroup.namingConvention, propertyGroup.propertyType, propertyGroup.correctCase, "RemoveRule", undefined, ruleType, Belt_List.length(BeltListExtensions.dedupeString(propertyNameMappingsInSchema)), Belt_List.length(propertyNameMappingsInSchema), Belt_List.length(match.thisPropertyInSchema) - 1 | 0, Belt_List.length(modelProperties), NamedBranch.getId(branch), schemaBundle$1.schemaId);
}

function PropertyRule$NameMappingControl(Props) {
  var name = Props.name;
  var onEvent = Props.onEvent;
  var onRemoveSuccess = Props.onRemoveSuccess;
  var onDestinationSelect = Props.onDestinationSelect;
  var onNameUpdateSuccess = Props.onNameUpdateSuccess;
  var destinationOptions = Props.destinationOptions;
  var onRemove = Props.onRemove;
  var onNameUpdate = Props.onNameUpdate;
  var destinationValue = Props.destinationValue;
  var viewerCanEdit = Props.viewerCanEdit;
  var sendActions = SendActionsContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var match = React.useState(function () {
        return "Closed";
      });
  var setStatus = match[1];
  return React.createElement("div", {
              className: container
            }, "Send as", React.createElement("button", {
                  className: editNameButton,
                  disabled: !viewerCanEdit,
                  onClick: (function (param) {
                      Curry._1(setStatus, (function (param) {
                              return "EditingName";
                            }));
                    })
                }, Belt_Option.getWithDefault(name, "")), "to", React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(4)),
                        tl: /* [] */0
                      })
                }), React.createElement(Select.make, {
                  disabled: !viewerCanEdit,
                  maxWidth: Css.px(200),
                  onSelect: onDestinationSelect,
                  options: Belt_List.toArray(destinationOptions),
                  value: destinationValue
                }), onEvent ? "on this event" : null, viewerCanEdit ? React.createElement("button", {
                    className: removeButton,
                    onClick: (function (param) {
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              onRemoveSuccess,
                              undefined,
                              [onRemove]
                            ]);
                      })
                  }, "Remove") : null, match[0] === "EditingName" ? React.createElement("div", {
                    className: editNameContainer
                  }, React.createElement("div", {
                        className: nameInputContainer
                      }, React.createElement(NameInput.make, {
                            name: Belt_Option.getWithDefault(name, ""),
                            autoFocus: true,
                            onChange: (function (nextName, _namingConvention, _correctCase, _onReset) {
                                if (Caml_obj.notequal(name, nextName)) {
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        onNameUpdateSuccess,
                                        undefined,
                                        [Curry._1(onNameUpdate, nextName)]
                                      ]);
                                }
                                Curry._1(setStatus, (function (param) {
                                        return "Closed";
                                      }));
                              }),
                            submitLabel: "Update Mapping",
                            placeholder: "Property Name",
                            onClose: (function (param) {
                                Curry._1(setStatus, (function (param) {
                                        return "Closed";
                                      }));
                              }),
                            fullscreen: true,
                            dirty: false,
                            itemType: "PropertyNameMapping",
                            actionType: Belt_Option.isSome(name) ? "Rename" : "Create",
                            getPossibleItemLink: (function (propertyName) {
                                return Belt_Option.map(ModelUtils.getPropertyByName(propertyName, model), (function (param) {
                                              return [
                                                      Router.Link.addDrawerItem(undefined, {
                                                            NAME: "property",
                                                            VAL: [
                                                              param.id,
                                                              undefined
                                                            ]
                                                          }),
                                                      undefined
                                                    ];
                                            }));
                              })
                          }))) : null);
}

var NameMappingControl = {
  make: PropertyRule$NameMappingControl
};

var allWorkspacesSelectId = "All Workspaces";

function PropertyRule(Props) {
  var rule = Props.rule;
  var sendActions = Props.sendActions;
  var property = Props.property;
  var context = Props.context;
  var viewerCanEdit = Props.viewerCanEdit;
  var model = ModelStore.useModel(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var propertyGroup = AnalyticsUtils.getAnalyticsPropertyGroup(model, property);
  var match = rule.definition;
  var variant = match.NAME;
  if (variant === "NameMapping") {
    var match$1 = match.VAL;
    var name = match$1.name;
    var destination = match$1.destination;
    var currentDestinationSelectId = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? destination.VAL : undefined;
    var destinationOptions = Belt_List.concatMany([
          Belt_Option.isNone(currentDestinationSelectId) ? ({
                hd: [
                  {
                    NAME: "Label",
                    VAL: "Select Destination"
                  },
                  "None"
                ],
                tl: /* [] */0
              }) : /* [] */0,
          Belt_List.map(Belt_List.keep(model.destinations, (function (destination) {
                      return !Belt_List.some(model.rules, (function (rule) {
                                    var match = rule.item;
                                    var match$1 = rule.definition;
                                    if (typeof match !== "object") {
                                      return false;
                                    }
                                    if (match.NAME !== "Property") {
                                      return false;
                                    }
                                    if (typeof match$1 !== "object") {
                                      return false;
                                    }
                                    if (match$1.NAME !== "NameMapping") {
                                      return false;
                                    }
                                    var match$2 = match$1.VAL.destination;
                                    if (match$2 === undefined) {
                                      return false;
                                    }
                                    if (typeof match$2 !== "object") {
                                      return false;
                                    }
                                    if (match$2.NAME !== "Destination") {
                                      return false;
                                    }
                                    var destinationRuleId = match$2.VAL;
                                    var isCurrentlySelected = Caml_obj.equal(destinationRuleId, currentDestinationSelectId);
                                    var isSameDestination = destinationRuleId === destination.id;
                                    var isSameProperty = match.VAL === property.id;
                                    if (!isCurrentlySelected && isSameDestination) {
                                      return isSameProperty;
                                    } else {
                                      return false;
                                    }
                                  }));
                    })), (function (destination) {
                  return [
                          {
                            NAME: "Label",
                            VAL: "" + ModelUtils.getDestinationName(destination) + "" + (
                              ModelUtils.isPropertySentToDestination(model, property.id, destination.id) || property.sendAs === /* SystemProperty */0 ? "" : " (property never sent here)"
                            ) + "\n              "
                          },
                          destination.id
                        ];
                })),
          !Belt_List.some(model.rules, (function (item) {
                  var match = rule.item;
                  var match$1 = item.item;
                  var match$2 = item.definition;
                  if (typeof match !== "object") {
                    return false;
                  }
                  if (match.NAME !== "Property") {
                    return false;
                  }
                  if (typeof match$1 !== "object") {
                    return false;
                  }
                  if (match$1.NAME !== "Property") {
                    return false;
                  }
                  if (typeof match$2 !== "object") {
                    return false;
                  }
                  if (match$2.NAME !== "NameMapping") {
                    return false;
                  }
                  var match$3 = match$2.VAL.destination;
                  if (match$3 !== undefined && match$3 === "AllDestinations" && match$1.VAL === property.id) {
                    return Caml_obj.notequal(destination, "AllDestinations");
                  } else {
                    return false;
                  }
                })) ? ({
                hd: [
                  {
                    NAME: "Label",
                    VAL: "All destinations"
                  },
                  "all-destinations"
                ],
                tl: /* [] */0
              }) : /* [] */0
        ]);
    var onDestinationSelect = function (nextDestinationSelectId) {
      var nextDestinationRule;
      switch (nextDestinationSelectId) {
        case "None" :
            nextDestinationRule = undefined;
            break;
        case "all-destinations" :
            nextDestinationRule = "AllDestinations";
            break;
        default:
          nextDestinationRule = {
            NAME: "Destination",
            VAL: nextDestinationSelectId
          };
      }
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                sendUpdateSuccessTracking(schemaBundle, branch, property.id, propertyGroup, model.properties, model.rules, "NameMapping");
              }),
            undefined,
            [[
                {
                  NAME: "UpdateRule",
                  VAL: [
                    rule.id,
                    rule.item,
                    {
                      NAME: "NameMapping",
                      VAL: {
                        destination: nextDestinationRule,
                        name: name
                      }
                    }
                  ]
                },
                context
              ]]
          ]);
    };
    var onNameUpdate = function (nextName) {
      return [
              {
                NAME: "UpdateRule",
                VAL: [
                  rule.id,
                  rule.item,
                  {
                    NAME: "NameMapping",
                    VAL: {
                      destination: destination,
                      name: nextName
                    }
                  }
                ]
              },
              context
            ];
    };
    var onNameUpdateSuccess = function (branch) {
      sendUpdateSuccessTracking(schemaBundle, branch, property.id, propertyGroup, model.properties, model.rules, "NameMapping");
    };
    var onRemove_0 = {
      NAME: "RemoveRule",
      VAL: [
        rule.id,
        rule.item
      ]
    };
    var onRemove = [
      onRemove_0,
      context
    ];
    var onRemoveSuccess = function (branch) {
      sendRemoveSuccessTracking(branch, model.properties, model.rules, propertyGroup, property.id, "NameMapping", schemaBundle);
    };
    var match$2 = rule.item;
    return React.createElement(React.Fragment, undefined, React.createElement(PropertyRule$NameMappingControl, {
                    name: name,
                    onEvent: typeof match$2 === "object" ? match$2.NAME === "PropertyRef" : false,
                    onRemoveSuccess: onRemoveSuccess,
                    onDestinationSelect: onDestinationSelect,
                    onNameUpdateSuccess: onNameUpdateSuccess,
                    destinationOptions: destinationOptions,
                    onRemove: onRemove,
                    onNameUpdate: onNameUpdate,
                    destinationValue: destination !== undefined ? (
                        typeof destination === "object" ? destination.VAL : "all-destinations"
                      ) : "none",
                    viewerCanEdit: viewerCanEdit
                  }));
  }
  if (variant !== "NameInLocalWorkspace") {
    return null;
  }
  var match$3 = match.VAL;
  var name$1 = match$3.name;
  var workspace = match$3.workspace;
  var workspaces = model.sources;
  var currentWorkspaceSelectId = workspace !== undefined ? (
      typeof workspace === "object" ? workspace.VAL : allWorkspacesSelectId
    ) : undefined;
  var matchesNoWorkspace = function (id) {
    return Belt_List.every(workspaces, (function (workspace) {
                  return workspace.id !== id;
                }));
  };
  var tmp = false;
  if (!Belt_List.some(model.rules, (function (item) {
            var match = rule.item;
            var match$1 = item.item;
            var match$2 = item.definition;
            if (typeof match !== "object") {
              return false;
            }
            if (match.NAME !== "Property") {
              return false;
            }
            if (typeof match$1 !== "object") {
              return false;
            }
            if (match$1.NAME !== "Property") {
              return false;
            }
            if (typeof match$2 !== "object") {
              return false;
            }
            if (match$2.NAME !== "NameInLocalWorkspace") {
              return false;
            }
            var match$3 = match$2.VAL.workspace;
            if (match$3 !== undefined && match$3 === "AllWorkspaces" && match$1.VAL === property.id) {
              return Caml_obj.notequal(workspace, "AllWorkspaces");
            } else {
              return false;
            }
          }))) {
    var match$4 = rule.item;
    var variant$1 = match$4.NAME;
    tmp = variant$1 === "PropertyRef" ? false : variant$1 === "Property";
  }
  var destinationOptions$1 = Belt_List.concatMany([
        currentWorkspaceSelectId !== undefined && !matchesNoWorkspace(currentWorkspaceSelectId) ? /* [] */0 : ({
              hd: [
                {
                  NAME: "Label",
                  VAL: "Select Workspace"
                },
                "None"
              ],
              tl: /* [] */0
            }),
        tmp ? ({
              hd: [
                {
                  NAME: "Label",
                  VAL: allWorkspacesSelectId
                },
                allWorkspacesSelectId
              ],
              tl: /* [] */0
            }) : /* [] */0,
        Belt_List.map(Belt_List.keep(Belt_List.map(workspaces, (function (param) {
                        return param.id;
                      })), (function (workspaceId) {
                    return !Belt_List.some(model.rules, (function (rule) {
                                  var match = rule.item;
                                  var match$1 = rule.definition;
                                  if (typeof match !== "object") {
                                    return false;
                                  }
                                  if (match.NAME !== "Property") {
                                    return false;
                                  }
                                  if (typeof match$1 !== "object") {
                                    return false;
                                  }
                                  if (match$1.NAME !== "NameInLocalWorkspace") {
                                    return false;
                                  }
                                  var workspaceRuleId = match$1.VAL.workspace;
                                  if (workspaceRuleId === undefined) {
                                    return false;
                                  }
                                  var selectId = typeof workspaceRuleId === "object" ? workspaceRuleId.VAL : allWorkspacesSelectId;
                                  var isCurrentlySelected = Caml_obj.equal(selectId, currentWorkspaceSelectId);
                                  var isSameWorkspace = selectId === workspaceId;
                                  var isSameProperty = match.VAL === property.id;
                                  if (!isCurrentlySelected && isSameWorkspace) {
                                    return isSameProperty;
                                  } else {
                                    return false;
                                  }
                                }));
                  })), (function (wid) {
                if (wid === allWorkspacesSelectId) {
                  return [
                          {
                            NAME: "Label",
                            VAL: allWorkspacesSelectId
                          },
                          wid
                        ];
                } else {
                  return [
                          {
                            NAME: "Label",
                            VAL: GlobalRequirementsUtils.getWorkspaceNameById(model, wid)
                          },
                          wid
                        ];
                }
              }))
      ]);
  var onDestinationSelect$1 = function (nextWorkspaceSelectId) {
    var nextWorkspaceRule = nextWorkspaceSelectId === "None" ? undefined : (
        nextWorkspaceSelectId === allWorkspacesSelectId ? "AllWorkspaces" : ({
              NAME: "Workspace",
              VAL: nextWorkspaceSelectId
            })
      );
    if (Caml_obj.notequal(nextWorkspaceRule, workspace)) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      sendUpdateSuccessTracking(schemaBundle, branch, property.id, propertyGroup, model.properties, model.rules, "NameInLocalWorkspace");
                    }),
                  undefined,
                  [[
                      {
                        NAME: "UpdateRule",
                        VAL: [
                          rule.id,
                          rule.item,
                          {
                            NAME: "NameInLocalWorkspace",
                            VAL: {
                              workspace: nextWorkspaceRule,
                              name: name$1
                            }
                          }
                        ]
                      },
                      context
                    ]]
                ]);
    }
    
  };
  var onNameUpdate$1 = function (nextName) {
    var tmp;
    tmp = currentWorkspaceSelectId !== undefined && currentWorkspaceSelectId !== "None" ? (
        currentWorkspaceSelectId === allWorkspacesSelectId ? "AllWorkspaces" : ({
              NAME: "Workspace",
              VAL: currentWorkspaceSelectId
            })
      ) : undefined;
    return [
            {
              NAME: "UpdateRule",
              VAL: [
                rule.id,
                rule.item,
                {
                  NAME: "NameInLocalWorkspace",
                  VAL: {
                    workspace: tmp,
                    name: nextName
                  }
                }
              ]
            },
            context
          ];
  };
  var onNameUpdateSuccess$1 = function (branch) {
    sendUpdateSuccessTracking(schemaBundle, branch, property.id, propertyGroup, model.properties, model.rules, "NameInLocalWorkspace");
  };
  var onRemove_0$1 = {
    NAME: "RemoveRule",
    VAL: [
      rule.id,
      rule.item
    ]
  };
  var onRemove$1 = [
    onRemove_0$1,
    context
  ];
  var onRemoveSuccess$1 = function (branch) {
    sendRemoveSuccessTracking(branch, model.properties, model.rules, propertyGroup, property.id, "NameInLocalWorkspace", schemaBundle);
  };
  var match$5 = rule.item;
  return React.createElement(PropertyRule$NameMappingControl, {
              name: name$1,
              onEvent: typeof match$5 === "object" ? match$5.NAME === "PropertyRef" : false,
              onRemoveSuccess: onRemoveSuccess$1,
              onDestinationSelect: onDestinationSelect$1,
              onNameUpdateSuccess: onNameUpdateSuccess$1,
              destinationOptions: destinationOptions$1,
              onRemove: onRemove$1,
              onNameUpdate: onNameUpdate$1,
              destinationValue: workspace !== undefined ? (
                  typeof workspace === "object" ? workspace.VAL : allWorkspacesSelectId
                ) : "None",
              viewerCanEdit: viewerCanEdit
            });
}

var make = PropertyRule;

export {
  Styles$1 as Styles,
  getPropertyNameMappings ,
  sendUpdateSuccessTracking ,
  sendRemoveSuccessTracking ,
  NameMappingControl ,
  allWorkspacesSelectId ,
  make ,
}
/* container Not a pure module */
