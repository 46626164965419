// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as ComputeLines from "./computeLines";

function computeLineInformation(prim0, prim1, prim2, prim3, prim4) {
  return ComputeLines.computeLineInformation(prim0, prim1, prim2, prim3, prim4);
}

function decodeDiffInformation(json) {
  var match = Json_decode.field("type", Json_decode.$$int, json);
  var tmp;
  switch (match) {
    case 0 :
        tmp = "default";
        break;
    case 1 :
        tmp = "added";
        break;
    case 2 :
        tmp = "removed";
        break;
    default:
      tmp = Js_exn.raiseError("");
  }
  return {
          value: Json_decode.field("value", decodeValue, json),
          type_: tmp
        };
}

function decodeValue(json) {
  try {
    return {
            NAME: "string",
            VAL: Json_decode.string(json)
          };
  }
  catch (exn){
    return {
            NAME: "diffInformation",
            VAL: Json_decode.array(decodeDiffInformation, json)
          };
  }
}

function decodeLineInformation(json) {
  return {
          left: Json_decode.field("left", (function (param) {
                  return Json_decode.optional(decodeDiffInformation, param);
                }), json),
          right: Json_decode.field("right", (function (param) {
                  return Json_decode.optional(decodeDiffInformation, param);
                }), json)
        };
}

function decode(json) {
  return {
          linesInformation: Json_decode.field("lineInformation", (function (param) {
                  return Json_decode.array(decodeLineInformation, param);
                }), json),
          diffLines: Json_decode.field("diffLines", (function (param) {
                  return Json_decode.array(Json_decode.$$int, param);
                }), json)
        };
}

var ComputedLinesDecoder = {
  decodeDiffInformation: decodeDiffInformation,
  decodeValue: decodeValue,
  decodeLineInformation: decodeLineInformation,
  decode: decode
};

function computeLineInformation$1(oldString, newString) {
  return decode(computeLineInformation(oldString, newString, false, "diffLines", 0));
}

function getDiffValueForLines(diffInformation) {
  var match = diffInformation.value;
  if (match.NAME === "string") {
    return match.VAL;
  } else {
    return Belt_Array.map(match.VAL, getDiffValueForLines).join("");
  }
}

function getPrefix(diffInformation) {
  var match = diffInformation.type_;
  if (match === "default") {
    return " ";
  } else if (match === "added") {
    return "+";
  } else {
    return "-";
  }
}

function getLines(param) {
  return Belt_Array.map(param.linesInformation, (function (param) {
                var right = param.right;
                var left = param.left;
                return {
                        left: left !== undefined ? "" + getPrefix(left) + "" + getDiffValueForLines(left) + "" : "",
                        right: right !== undefined ? "" + getPrefix(right) + "" + getDiffValueForLines(right) + "" : ""
                      };
              }));
}

function partitionLines(lines) {
  return Belt_Array.reduce(lines, {
              left: "",
              right: ""
            }, (function (stitchedLines, line) {
                return {
                        left: "" + stitchedLines.left + "\n" + line.left + "",
                        right: "" + stitchedLines.right + "\n" + line.right + ""
                      };
              }));
}

export {
  ComputedLinesDecoder ,
  computeLineInformation$1 as computeLineInformation,
  getDiffValueForLines ,
  getPrefix ,
  getLines ,
  partitionLines ,
}
/* ./computeLines Not a pure module */
