// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Input from "../Input.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Mantine from "../Mantine.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainUtils from "../DomainUtils.mjs";
import * as LoadingCircle from "../LoadingCircle.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as DomainDetails__Events from "./DomainDetails__Events.mjs";
import * as DomainDetails__Members from "./DomainDetails__Members.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import ReactTextareaAutosize from "react-textarea-autosize";
import * as DomainDetails__Components from "./DomainDetails__Components.mjs";

function DomainDetails$UpdateDomainNameModal(Props) {
  var opened = Props.opened;
  var domain = Props.domain;
  var onClose = Props.onClose;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var schemaId = schemaBundle.schemaId;
  var domains = DomainStore.useDomains(undefined);
  var match = React.useState(function () {
        return domain.name;
      });
  var setDomainName = match[1];
  var domainName = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(setDomainName, (function (param) {
                  return domain.name;
                }));
        }), [opened]);
  var domainNameExists = Belt_Option.isSome(Curry._2(Domains.getBy, domains, (function (param) {
              if (param.name === domainName) {
                return param.id !== domain.id;
              } else {
                return false;
              }
            }))) && !loading;
  var handleUpdateDomainName = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.updateDomainName(domainName, (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: "Failed to update domain name",
                  toastType: /* Error */1
                });
          }), (function (_domainId) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Updated domain name to ", React.createElement("em", undefined, domainName)),
                  toastType: /* Success */0
                });
            Curry._1(onClose, undefined);
            AnalyticsRe.domainUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domainName, Curry._1(Domains.size, domains) + 1 | 0, "UpdateName", viewerIsDomainMember ? "DomainTeamMember" : "Admin", schemaId);
          }), schemaId, sendActions, viewerId, domain.id);
  };
  return React.createElement(Mantine.Modal.make, {
              closeOnClickOutside: true,
              onClose: onClose,
              opened: opened,
              title: "Update domain name",
              withCloseButton: true,
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll + 100 | 0,
              overlayStyles: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2)),
                tl: /* [] */0
              },
              titleStyles: {
                hd: Css.fontSize(Styles.FontSize.large),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: /* [] */0
                }
              },
              yOffset: 150,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin2(Css.px(0), Css.px(-16)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light03),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.borderLeftWidth(Css.px(0)),
                                tl: {
                                  hd: Css.borderRightWidth(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(Input.make, {
                      label: "Name",
                      value: domainName,
                      onChange: Curry.__1(setDomainName),
                      autoFocus: true,
                      placeholder: "Domain name...",
                      className: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: /* [] */0
                      },
                      required: true
                    }), domainNameExists ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Semi",
                            color: Styles.Color.darkRed,
                            children: "Domain with the name “" + domainName + "” already exists"
                          }), React.createElement(Spacer.make, {
                            height: 16
                          })) : null), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      style: "outline"
                    }), React.createElement(Button.make, {
                      disabled: Belt_Option.isSome(Curry._2(Domains.getBy, domains, (function (param) {
                                  return param.name === domainName;
                                }))) || domainName.trim() === "",
                      label: "Update",
                      loading: loading,
                      onClick: handleUpdateDomainName
                    })));
}

function DomainDetails$UpdateDomainDescriptionModal(Props) {
  var opened = Props.opened;
  var domain = Props.domain;
  var onClose = Props.onClose;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var addToast = Toast.useAddToast(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var domains = DomainStore.useDomains(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var schemaId = schemaBundle.schemaId;
  var match = React.useState(function () {
        return domain.description;
      });
  var setDomainDescription = match[1];
  var domainDescription = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  React.useEffect((function () {
          Curry._1(setLoading, (function (param) {
                  return false;
                }));
          Curry._1(setDomainDescription, (function (param) {
                  return domain.description;
                }));
        }), [opened]);
  var handleUpdateDomainDescription = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    DomainUtils.Firebase.updateDomainDescription(domainDescription, (function (param) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: "Failed to update domain description",
                  toastType: /* Error */1
                });
          }), (function (_domainId) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Updated domain description for ", React.createElement("em", undefined, domain.name)),
                  toastType: /* Success */0
                });
            AnalyticsRe.domainUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains), "UpdateDescription", viewerIsDomainMember ? "DomainTeamMember" : "Admin", schemaId);
            Curry._1(onClose, undefined);
          }), schemaId, sendActions, viewerId, domain.id);
  };
  return React.createElement(Mantine.Modal.make, {
              closeOnClickOutside: true,
              onClose: onClose,
              opened: opened,
              title: "Update domain description",
              withCloseButton: true,
              withinPortal: true,
              zIndex: Styles.ZIndex.aboveAll + 100 | 0,
              overlayStyles: {
                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2)),
                tl: /* [] */0
              },
              titleStyles: {
                hd: Css.fontSize(Styles.FontSize.large),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: /* [] */0
                }
              },
              yOffset: 150,
              children: null
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.margin2(Css.px(0), Css.px(-16)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light03),
                            tl: {
                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.borderLeftWidth(Css.px(0)),
                                tl: {
                                  hd: Css.borderRightWidth(Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(ReactTextareaAutosize, {
                      className: Curry._1(Css.merge, {
                            hd: Curry._1(Css.style, Styles.input),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.display("block"),
                                    tl: {
                                      hd: Css.padding(Css.px(8)),
                                      tl: {
                                        hd: Css.borderRadius(Styles.Border.radius),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", "transparent"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.regular),
                                            tl: {
                                              hd: Css.fontSize(Styles.FontSize.regular),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      maxRows: 15,
                      minRows: 3,
                      onChange: (function (domEvent) {
                          var value = domEvent.target.value;
                          Curry._1(setDomainDescription, (function (param) {
                                  return value;
                                }));
                        }),
                      value: domainDescription
                    })), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: {
                              hd: Css.gap(Css.px(4)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      style: "outline"
                    }), React.createElement(Button.make, {
                      label: "Update",
                      loading: match$1[0],
                      onClick: handleUpdateDomainDescription
                    })));
}

var header = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(16), Css.px(32)),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("spaceBetween"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function DomainDetails$DomainHeader(Props) {
  var domain = Props.domain;
  var viewerId = Props.viewerId;
  var viewerRole = Props.viewerRole;
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var domains = DomainStore.useDomains(undefined);
  var onArchive = function (param) {
    DomainUtils.Firebase.archiveDomain((function (param) {
            Curry._1(addToast, {
                  message: "Failed to archive the domain \"" + domain.name + "\"",
                  toastType: /* Error */1
                });
          }), (function (param) {
            Curry._1(addToast, {
                  message: "The domain \"" + domain.name + "\" was archived",
                  toastType: /* Success */0
                });
            Router.push(undefined, "" + Router.Link.getSchemaRouteLink(undefined, "dashboard") + "?settings=domains");
            AnalyticsRe.domainArchived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, domain.id, domain.name, Curry._1(Domains.size, domains) - 1 | 0, schemaBundle.schemaId);
          }), schemaBundle.schemaId, sendActions, viewerId, domain.id);
  };
  return React.createElement("header", {
              className: header
            }, React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  children: "Domain details" + (
                    domain.archived ? " (Archived)" : ""
                  )
                }), React.createElement(ContextMenu.V2.make, {
                  position: "bottom-end",
                  children: null
                }, React.createElement(ContextMenu.V2.Item.make, {
                      label: "See all domains",
                      onClick: (function (param) {
                          Curry._1(globalSend, {
                                TAG: /* OpenModal */4,
                                _0: {
                                  NAME: "Settings",
                                  VAL: /* Domains */4
                                }
                              });
                        })
                    }), React.createElement(ContextMenu.V2.Item.make, {
                      label: "Copy domain URL",
                      onClick: (function (param) {
                          CopyTextToClipboard(Router.Link.getSimple(undefined));
                        })
                    }), React.createElement(ContextMenu.V2.Item.make, {
                      label: "Remove this domain",
                      onClick: onArchive,
                      style: "Warning",
                      disabled: viewerRole !== "Admin" || domain.archived
                    })));
}

function DomainDetails$DomainName(Props) {
  var domain = Props.domain;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var viewerRole = Props.viewerRole;
  var match = Hooks.useDisclosure(false);
  var updateDomainNameModalActions = match[1];
  var tmp = {
    size: "Huge",
    weight: "Semi",
    children: domain.name
  };
  var tmp$1 = domain.archived ? "Em" : undefined;
  if (tmp$1 !== undefined) {
    tmp.element = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = domain.archived ? Styles.Color.light10 : undefined;
  if (tmp$2 !== undefined) {
    tmp.color = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("baseline"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement($$Text.make, tmp), React.createElement(TextButton.make, {
                  onClick: (function (param) {
                      Curry._1(updateDomainNameModalActions.open_, undefined);
                    }),
                  style: "Blue",
                  disabled: !viewerIsDomainMember && viewerRole !== "Admin" || domain.archived,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        children: "Edit"
                      })
                }), React.createElement(DomainDetails$UpdateDomainNameModal, {
                  opened: match[0],
                  domain: domain,
                  onClose: (function (param) {
                      Curry._1(updateDomainNameModalActions.close, undefined);
                    }),
                  viewerIsDomainMember: viewerIsDomainMember
                }));
}

function DomainDetails$DomainDescription(Props) {
  var domain = Props.domain;
  var viewerIsDomainMember = Props.viewerIsDomainMember;
  var viewerRole = Props.viewerRole;
  var match = Hooks.useDisclosure(false);
  var updateDomainDescriptionModalActions = match[1];
  return React.createElement(DomainDetails__Components.Card.make, {
              children: null
            }, React.createElement(DomainDetails__Components.CardSection.make, {
                  className: Curry._1(Css.style, {
                        hd: Css.margin(Css.px(0)),
                        tl: {
                          hd: Css.padding2(Css.px(16), Css.px(32)),
                          tl: {
                            hd: Css.paddingTop(Css.px(20)),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light06),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  children: React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("spaceBetween"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            children: "Description"
                          }), React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                Curry._1(updateDomainDescriptionModalActions.open_, undefined);
                              }),
                            style: "Grey",
                            disabled: !viewerIsDomainMember && viewerRole !== "Admin" || domain.archived,
                            children: React.createElement($$Text.make, {
                                  size: "Medium",
                                  children: "Edit"
                                })
                          }))
                }), React.createElement(DomainDetails__Components.CardSection.make, {
                  children: domain.description.trim() === "" ? React.createElement($$Text.make, {
                          element: "Em",
                          size: "Medium",
                          children: "No description"
                        }) : React.createElement($$Text.make, {
                          size: "Medium",
                          children: domain.description
                        })
                }), React.createElement(DomainDetails$UpdateDomainDescriptionModal, {
                  opened: match[0],
                  domain: domain,
                  onClose: (function (param) {
                      Curry._1(updateDomainDescriptionModalActions.close, undefined);
                    }),
                  viewerIsDomainMember: viewerIsDomainMember
                }));
}

function DomainDetails$Retrieved(Props) {
  var domain = Props.domain;
  var viewerId = ViewerContext.use(undefined).id;
  var viewerRole = WorkspaceContext.useMemberRole(undefined);
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var viewerIsDomainMember = React.useMemo((function () {
          return Curry._2(Domains.some, memberDomains, (function (param) {
                        return param.id === domain.id;
                      }));
        }), [memberDomains]);
  return React.createElement(React.Fragment, undefined, React.createElement(DomainDetails$DomainHeader, {
                  domain: domain,
                  viewerId: viewerId,
                  viewerRole: viewerRole
                }), React.createElement("section", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(32)),
                        tl: {
                          hd: Css.flexGrow(1.0),
                          tl: {
                            hd: Css.overflowY("auto"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(DomainDetails$DomainName, {
                      domain: domain,
                      viewerIsDomainMember: viewerIsDomainMember,
                      viewerRole: viewerRole
                    }), React.createElement(Spacer.make, {
                      height: 32
                    }), React.createElement(DomainDetails$DomainDescription, {
                      domain: domain,
                      viewerIsDomainMember: viewerIsDomainMember,
                      viewerRole: viewerRole
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(DomainDetails__Members.make, {
                      domain: domain
                    }), React.createElement(Spacer.make, {
                      height: 16
                    }), React.createElement(DomainDetails__Events.make, {
                      domain: domain,
                      viewerIsDomainMember: viewerIsDomainMember
                    })));
}

function DomainDetails$Archived(Props) {
  var domainId = Props.domainId;
  var match = DomainStore.useDomainWithArchive(domainId);
  if (typeof match === "object") {
    return React.createElement(DomainDetails$Retrieved, {
                domain: match.VAL
              });
  } else if (match === "NotFound") {
    return React.createElement($$Text.make, {
                children: "Domain not found"
              });
  } else {
    return React.createElement(LoadingCircle.make, {
                size: 24,
                color: Styles.Color.light10
              });
  }
}

function DomainDetails(Props) {
  var domainId = Props.domainId;
  var isDomainStoreInitialized = DomainStore.useIsInitialized(undefined);
  var domain = DomainStore.useDomain(domainId);
  if (domain !== undefined) {
    return React.createElement(DomainDetails$Retrieved, {
                domain: domain
              });
  } else if (isDomainStoreInitialized) {
    return React.createElement(DomainDetails$Archived, {
                domainId: domainId
              });
  } else {
    return React.createElement(LoadingCircle.make, {
                size: 24,
                color: Styles.Color.light10
              });
  }
}

var make = DomainDetails;

export {
  make ,
}
/* header Not a pure module */
