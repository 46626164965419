// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function DiffGlobalRequirementsPropertyBundle(Props) {
  var propertyBundlesConverted = Props.propertyBundlesConverted;
  var propertyBundles = Props.propertyBundles;
  var globalRequirements = Props.globalRequirements;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: {
                          hd: Css.width(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  children: "Property Bundles"
                }), Belt_List.toArray(Belt_List.map(propertyBundlesConverted, (function (objectActions) {
                        if (!objectActions) {
                          return null;
                        }
                        var match = objectActions.hd[0];
                        if (typeof match !== "object") {
                          return null;
                        }
                        if (match.NAME !== "PropertyGroup") {
                          return null;
                        }
                        var propertyBundleId = match.VAL;
                        var propertyBundle = Curry._2(TrackingPlanMappedModel.PropertyBundles.get, propertyBundles, propertyBundleId);
                        if (propertyBundle === undefined) {
                          return null;
                        }
                        var globalPropertyBundle = Belt_Option.flatMap(globalRequirements, (function (param) {
                                return Curry._2(TrackingPlanMappedModel.PropertyBundles.get, param.propertyBundles, propertyBundleId);
                              }));
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.backgroundColor(Styles.Color.light01),
                                          tl: {
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.blue),
                                            tl: {
                                              hd: Css.borderRadius(Css.px(6)),
                                              tl: {
                                                hd: Css.padding2(Css.px(8), Css.px(16)),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexDirection("column"),
                                                    tl: {
                                                      hd: Css.width(Css.pct(100.0)),
                                                      tl: {
                                                        hd: Css.cursor("pointer"),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.light02),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    onClick: (function (param) {
                                        Router.Schema.pushDrawerItem(undefined, undefined, {
                                              NAME: "propertyGroup",
                                              VAL: [
                                                propertyBundle.id,
                                                undefined
                                              ]
                                            });
                                      })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: {
                                                  hd: Css.gap(Css.px(8)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light11,
                                            children: propertyBundle.name
                                          }), React.createElement(GlobalPill.make, {})), globalPropertyBundle !== undefined ? React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light11,
                                          children: globalPropertyBundle.description
                                        }) : null);
                      }))));
}

var make = DiffGlobalRequirementsPropertyBundle;

export {
  make ,
}
/* Css Not a pure module */
