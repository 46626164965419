// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Models from "../../app/src/Models.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var _map = {"Merged":"Merged","Open":"Open","Closed":"Closed","Draft":"Draft","ReadyForReview":"ReadyForReview","ChangesRequested":"ChangesRequested","Approved":"Approved","Unknown":"Unknown"};

function branchStatusToJs(param) {
  return param;
}

function branchStatusFromJs(param) {
  return _map[param];
}

var branchStatuses = [
  "Merged",
  "Open",
  "Closed",
  "Draft",
  "ReadyForReview",
  "ChangesRequested",
  "Approved",
  "Unknown"
];

function inClosedState(branchStatus) {
  return branchStatus === "Closed";
}

function inOpenState(branchStatus) {
  return !(branchStatus === "Merged" || branchStatus === "Closed");
}

var archivedStateBranchStatuses = Belt_Array.keep(branchStatuses, inClosedState);

var openStateBranchStatuses = Belt_Array.keep(branchStatuses, inOpenState);

function branchStatusModelToBranchStateBranchStatus(branchStatus) {
  if (typeof branchStatus === "number") {
    if (branchStatus === /* Master */0) {
      return "Approved";
    } else {
      return "Unknown";
    }
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
        return "Merged";
    case /* Closed */2 :
        return "Closed";
    case /* Open */0 :
    case /* Draft */3 :
        return "Draft";
    case /* ReadyForReview */4 :
        return "ReadyForReview";
    case /* ChangesRequested */5 :
        return "ChangesRequested";
    case /* Approved */6 :
        return "Approved";
    
  }
}

function encodeOptional(value, isShallow) {
  if (value !== undefined) {
    return Caml_option.valFromOption(value);
  } else if (isShallow) {
    return ;
  } else {
    return null;
  }
}

function parseFirebaseType(firebaseBranchModel) {
  return {
          schemaId: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(firebaseBranchModel.schemaId), ""),
          branchId: firebaseBranchModel.branchId,
          lastActivityDate: Models.toDateOption(firebaseBranchModel.lastActivityDate),
          lastActiveUserId: Caml_option.nullable_to_opt(firebaseBranchModel.lastActiveUserId),
          branchStatus: Belt_Option.getWithDefault(branchStatusFromJs(firebaseBranchModel.branchStatus), "Draft"),
          approvalsCount: Caml_option.nullable_to_opt(firebaseBranchModel.approvalsCount),
          creatorUserId: firebaseBranchModel.creatorUserId,
          impactedSourceIds: Caml_option.nullable_to_opt(firebaseBranchModel.impactedSourceIds),
          creationDate: Belt_Option.getWithDefault(Models.toDateOption(firebaseBranchModel.creationDate), new Date()),
          branchName: firebaseBranchModel.branchName,
          branchLongName: firebaseBranchModel.branchLongName,
          commentsCount: Caml_option.nullable_to_opt(firebaseBranchModel.commentsCount),
          collaboratorIds: Caml_option.nullable_to_opt(firebaseBranchModel.collaboratorIds),
          reviewerIds: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(firebaseBranchModel.reviewerIds), []),
          lastCodegenBySource: Belt_Option.mapU(Caml_option.nullable_to_opt(firebaseBranchModel.lastCodegenBySource), (function (lastCodegenInfo) {
                  return Belt_MapString.fromArray(Belt_Array.mapU(Js_dict.entries(lastCodegenInfo), (function (param) {
                                    var lastCodegenInfo = param[1];
                                    return [
                                            param[0],
                                            {
                                              lastCodegenDate: Belt_Option.getExn(Models.toDateOption(lastCodegenInfo.lastCodegenDate)),
                                              lastCodegenUserId: lastCodegenInfo.lastCodegenUserId
                                            }
                                          ];
                                  })));
                })),
          lastPublishedAt: Models.toDateOption(firebaseBranchModel.lastPublishedAt),
          isShallow: firebaseBranchModel.isShallow,
          globalBranchId: Caml_option.nullable_to_opt(firebaseBranchModel.globalBranchId),
          eventWorkbenchOrder: Caml_option.nullable_to_opt(firebaseBranchModel.eventWorkbenchOrder)
        };
}

function createShallowState(branchId, branchName, creatorUserId, createdAt, branchStatus, branchApprovalsCount, schemaId) {
  return {
          schemaId: schemaId,
          branchId: branchId,
          lastActivityDate: undefined,
          lastActiveUserId: undefined,
          branchStatus: branchStatus,
          approvalsCount: branchApprovalsCount,
          creatorUserId: creatorUserId,
          impactedSourceIds: undefined,
          creationDate: createdAt,
          branchName: branchName,
          branchLongName: branchName,
          commentsCount: undefined,
          collaboratorIds: undefined,
          reviewerIds: [],
          lastCodegenBySource: undefined,
          lastPublishedAt: undefined,
          isShallow: true,
          globalBranchId: undefined,
          eventWorkbenchOrder: []
        };
}

export {
  branchStatusToJs ,
  branchStatusFromJs ,
  branchStatuses ,
  inClosedState ,
  inOpenState ,
  archivedStateBranchStatuses ,
  openStateBranchStatuses ,
  branchStatusModelToBranchStateBranchStatus ,
  encodeOptional ,
  parseFirebaseType ,
  createShallowState ,
}
/* archivedStateBranchStatuses Not a pure module */
