// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as IconChevronLight from "./IconChevronLight.mjs";

function propertyGroupContainerStyles(primary, secondary) {
  return Curry._1(Css.style, {
              hd: Css.margin4(Css.px(5), Css.px(20), Css.px(5), Css.px(0)),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: {
                  hd: Css.border(Css.px(1), "solid", secondary),
                  tl: {
                    hd: Css.color(primary),
                    tl: {
                      hd: Css.overflow("hidden"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var propertyGroupHeaderStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("spaceBetween"),
                  tl: {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.padding2(Css.px(10), Css.px(15)),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var propertyGroupExpandedContainerStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.marginTop(Css.px(10)),
          tl: {
            hd: Css.padding2(Css.px(10), Css.px(15)),
            tl: /* [] */0
          }
        }
      }
    });

function DiffEventPropertyGroups$Group(Props) {
  var group = Props.group;
  var colors = Props.colors;
  var branchModel = Props.branchModel;
  var primary = colors[0];
  var properties = Belt_List.keepMapU(group.properties, (function (propertyId) {
          return ModelUtils.getPropertyById(propertyId, branchModel);
        }));
  return React.createElement("div", {
              className: propertyGroupContainerStyles(primary, colors[1]),
              onClick: (function (prim) {
                  prim.stopPropagation();
                })
            }, React.createElement(CollapsedContent.make, {
                  button: (function (onClick, isCollapsed) {
                      return React.createElement("button", {
                                  className: propertyGroupHeaderStyles,
                                  onClick: (function (param) {
                                      Curry._1(onClick, undefined);
                                    })
                                }, React.createElement($$Text.make, {
                                      size: "Medium",
                                      weight: "Semi",
                                      color: primary,
                                      children: group.name
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          weight: "Semi",
                                          color: primary,
                                          children: "Bundle of " + String(Belt_List.length(properties)) + " properties"
                                        }), React.createElement(IconChevronLight.make, {
                                          size: 12,
                                          color: primary,
                                          direction: isCollapsed ? "Right" : "Down"
                                        })));
                    }),
                  children: (function (param) {
                      return React.createElement("div", {
                                  className: propertyGroupExpandedContainerStyles
                                }, Belt_List.toArray(Belt_List.mapU(properties, (function (action) {
                                            if (action.TAG === /* PropertyRef */0) {
                                              return null;
                                            }
                                            var property = action._0;
                                            return React.createElement("div", {
                                                        key: property.id
                                                      }, property.name);
                                          }))));
                    })
                }));
}

var Group = {
  propertyGroupContainerStyles: propertyGroupContainerStyles,
  propertyGroupHeaderStyles: propertyGroupHeaderStyles,
  propertyGroupExpandedContainerStyles: propertyGroupExpandedContainerStyles,
  make: DiffEventPropertyGroups$Group
};

function DiffEventPropertyGroups(Props) {
  var added = Props.added;
  var removed = Props.removed;
  var branchModel = Props.branchModel;
  var modified = Belt_List.concat(added, removed);
  if (Belt_List.length(modified) === 0) {
    return null;
  } else {
    return React.createElement("div", undefined, Belt_List.toArray(Belt_List.concatMany([
                        Belt_List.mapU(removed, (function (group) {
                                return React.createElement(DiffEventPropertyGroups$Group, {
                                            group: group,
                                            colors: [
                                              Styles.Color.darkRed,
                                              Styles.Color.darkRed
                                            ],
                                            branchModel: branchModel,
                                            key: group.id + "-removed"
                                          });
                              })),
                        Belt_List.mapU(added, (function (group) {
                                return React.createElement(DiffEventPropertyGroups$Group, {
                                            group: group,
                                            colors: [
                                              Styles.Color.darkGreen,
                                              Styles.Color.darkGreen
                                            ],
                                            branchModel: branchModel,
                                            key: group.id + "-added"
                                          });
                              }))
                      ])));
  }
}

var make = DiffEventPropertyGroups;

export {
  Group ,
  make ,
}
/* propertyGroupHeaderStyles Not a pure module */
