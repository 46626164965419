// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as Mantine from "./Mantine.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FetchActivity from "./FetchActivity.mjs";
import * as LoadingCircle from "./LoadingCircle.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as EntityLightning from "./EntityLightning.mjs";
import * as GoalActivityItem from "./GoalActivityItem.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as EventActivityItem from "./EventActivityItem.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as BranchActivityItem from "./BranchActivityItem.mjs";
import * as MetricActivityItem from "./MetricActivityItem.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as PropertyActivityItem from "./PropertyActivityItem.mjs";
import * as IntegrationActivityItem from "./integration/IntegrationActivityItem.mjs";
import * as EventVariantActivityItem from "./EventVariantActivityItem.mjs";
import * as InspectorIssueActivityItem from "./InspectorIssueActivityItem.mjs";
import * as PropertyBundleActivityItem from "./PropertyBundleActivityItem.mjs";
import * as WorkspaceSettingsActivityItem from "./WorkspaceSettingsActivityItem.mjs";

function isCommentAction(action) {
  var match = action.contents;
  if (typeof match !== "object") {
    return false;
  }
  var variant = match.NAME;
  if (variant === "PropertyComment" || variant === "GoalComment" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "MetricComment" || variant === "PropertyGroupComment" || variant === "EventComment") {
    return true;
  } else {
    return variant === "BranchComment";
  }
}

function ObjectActivityLog$ActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var showCommentsOpt = Props.showComments;
  var goToId = Props.goToId;
  var pageSize = Props.pageSize;
  var showComments = showCommentsOpt !== undefined ? showCommentsOpt : true;
  var model = ModelStore.useModel(undefined);
  var workspace = WorkspaceContext.use(undefined);
  var match = ViewerContext.use(undefined);
  var userId = match.id;
  var globalSend = GlobalSendContext.use(undefined);
  var availability = AvoLimits.ActivityLogs.computeAvailability(workspace);
  var planHint = AvoLimits.ActivityLogs.availableOnPlan(workspace);
  var tmp;
  if (typeof object_ === "object") {
    var variant = object_.NAME;
    tmp = variant === "Integration" ? "context.integrationQuery" : (
        variant === "Property" ? "context.propertyQuery" : (
            variant === "Event" ? "context.eventQuery" : (
                variant === "PropertyGroup" ? "context.propertyGroupQuery" : (
                    variant === "Goal" ? "context.goalQuery" : (
                        variant === "InspectorIssue" ? "context.inspectorIssueQuery" : (
                            variant === "EventVariant" ? "context.eventVariantQuery" : (
                                variant === "Metric" ? "context.metricQuery" : "context.branchQuery"
                              )
                          )
                      )
                  )
              )
          )
      );
  } else {
    tmp = "context.workspaceSettingsQuery";
  }
  return React.createElement(FetchActivity.make, {
              schemaId: workspace.id,
              currentBranch: currentBranch,
              queryBy: {
                hd: [
                  tmp,
                  objectId
                ],
                tl: /* [] */0
              },
              pageSize: Belt_Option.getWithDefault(pageSize, 25),
              children: (function (items, status, param, getMore) {
                  var actions = Belt_List.flatten(Belt_List.mapU(Actions.groupActionsByDate(Belt_List.reverse(Actions.filterSystemActions(items))), Actions.groupActions));
                  var limitedActions = availability === "Unavailable" ? Belt_List.reduce(Belt_List.reverse(actions), /* [] */0, (function (selected, actionGroup) {
                            var action = Belt_List.headExn(actionGroup);
                            if (Belt_List.length(selected) < 4 || isCommentAction(action)) {
                              return {
                                      hd: actionGroup,
                                      tl: selected
                                    };
                            } else {
                              return selected;
                            }
                          })) : actions;
                  var hasHiddenActions = availability === "Unavailable" && Belt_List.length(actions) > 4;
                  var renderLoadMoreButton = function (param) {
                    return React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.small),
                                                  tl: {
                                                    hd: Css.color(Styles.Color.darkBlue),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(15), Css.px(0)),
                                                      tl: {
                                                        hd: Css.justifyContent("center"),
                                                        tl: {
                                                          hd: Css.width(Css.pct(100)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    if (availability === "Unavailable") {
                                      return Curry._1(globalSend, {
                                                  TAG: /* OpenModal */4,
                                                  _0: {
                                                    NAME: "BillingPrompt",
                                                    VAL: "ExpandActivityLog"
                                                  }
                                                });
                                    } else {
                                      return Curry._1(getMore, undefined);
                                    }
                                  })
                              }, React.createElement(EntityLightning.make, {
                                    availability: availability,
                                    planHint: planHint,
                                    stroke: Styles.Color.darkBlue
                                  }), React.createElement(Spacer.make, {
                                    display: "inlineBlock",
                                    width: 4
                                  }), "Load older activity...");
                  };
                  return React.createElement(React.Fragment, undefined, status === "Loading" ? React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("center"),
                                              tl: {
                                                hd: Css.padding2(Css.px(10), Css.px(0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement(LoadingCircle.make, {
                                        color: Styles.Color.darkBlue
                                      })) : (
                                status === "HasMore" || hasHiddenActions ? renderLoadMoreButton(undefined) : null
                              ), Belt_List.toArray(Belt_List.mapU(limitedActions, (function (actionGroup) {
                                        var action = Belt_List.headExn(actionGroup);
                                        var firstAction = Belt_List.headExn(Belt_List.reverse(actionGroup));
                                        var userIds = Belt_List.toArray(Belt_List.mapU(BeltListExtensions.groupConsecutive(actionGroup, (function (a, b) {
                                                        return a.createdBy === b.createdBy;
                                                      })), (function (userActions) {
                                                    return Belt_List.headExn(userActions).createdBy;
                                                  })));
                                        if (typeof object_ !== "object") {
                                          return React.createElement(WorkspaceSettingsActivityItem.make, {
                                                      action: action,
                                                      userIds: userIds,
                                                      openBranches: openBranches,
                                                      key: action.id
                                                    });
                                        }
                                        var variant = object_.NAME;
                                        if (variant === "Integration") {
                                          var tmp = {
                                            events: model.events,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            integrationName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(IntegrationActivityItem.make, tmp);
                                        }
                                        if (variant === "Property") {
                                          var tmp$1 = {
                                            property: object_.VAL,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if ($$event !== undefined) {
                                            tmp$1.event = Caml_option.valFromOption($$event);
                                          }
                                          if (goToId !== undefined) {
                                            tmp$1.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(PropertyActivityItem.make, tmp$1);
                                        }
                                        if (variant === "Event") {
                                          var tmp$2 = {
                                            event: object_.VAL,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: firstAction.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$2.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(EventActivityItem.make, tmp$2);
                                        }
                                        if (variant === "PropertyGroup") {
                                          var tmp$3 = {
                                            group: object_.VAL,
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if ($$event !== undefined) {
                                            tmp$3.event = Caml_option.valFromOption($$event);
                                          }
                                          if (goToId !== undefined) {
                                            tmp$3.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(PropertyBundleActivityItem.make, tmp$3);
                                        }
                                        if (variant === "Goal") {
                                          var tmp$4 = {
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            goalName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$4.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(GoalActivityItem.make, tmp$4);
                                        }
                                        if (variant === "InspectorIssue") {
                                          var tmp$5 = {
                                            events: model.events,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            inspectorIssueName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$5.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(InspectorIssueActivityItem.make, tmp$5);
                                        }
                                        if (variant === "EventVariant") {
                                          return React.createElement(EventVariantActivityItem.make, {
                                                      model: model,
                                                      action: action,
                                                      userIds: userIds,
                                                      openBranches: openBranches,
                                                      key: action.id
                                                    });
                                        }
                                        if (variant === "Metric") {
                                          var tmp$6 = {
                                            model: model,
                                            action: action,
                                            userId: userId,
                                            userIds: userIds,
                                            openBranches: openBranches,
                                            metricName: object_.VAL[1],
                                            showComments: showComments,
                                            key: action.id
                                          };
                                          if (goToId !== undefined) {
                                            tmp$6.goToId = Caml_option.valFromOption(goToId);
                                          }
                                          return React.createElement(MetricActivityItem.make, tmp$6);
                                        }
                                        var tmp$7 = {
                                          model: model,
                                          action: action,
                                          userId: userId,
                                          userIds: userIds,
                                          openBranches: openBranches,
                                          branchName: object_.VAL[1],
                                          showComments: showComments,
                                          key: action.id
                                        };
                                        if (goToId !== undefined) {
                                          tmp$7.goToId = Caml_option.valFromOption(goToId);
                                        }
                                        return React.createElement(BranchActivityItem.make, tmp$7);
                                      }))));
                })
            });
}

var ActivityLog = {
  make: ObjectActivityLog$ActivityLog
};

function ObjectActivityLog$ActivityLogLoadedWhenVisible(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var showComments = Props.showComments;
  var goToId = Props.goToId;
  var match = Curry._1(Mantine.Hooks.Intersection.useWithoutOptions, undefined);
  var entry = match.entry;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHasSeenActivityLog = match$1[1];
  React.useEffect((function () {
          if (entry !== undefined && entry.isIntersecting) {
            Curry._1(setHasSeenActivityLog, (function (param) {
                    return true;
                  }));
          }
          
        }), [entry]);
  var tmp;
  if (match$1[0]) {
    var tmp$1 = {
      object_: object_,
      objectId: objectId,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$1.event = Caml_option.valFromOption($$event);
    }
    if (showComments !== undefined) {
      tmp$1.showComments = Caml_option.valFromOption(showComments);
    }
    if (goToId !== undefined) {
      tmp$1.goToId = Caml_option.valFromOption(goToId);
    }
    tmp = React.createElement(ObjectActivityLog$ActivityLog, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("span", {
              ref: match.ref
            }, tmp);
}

var ActivityLogLoadedWhenVisible = {
  make: ObjectActivityLog$ActivityLogLoadedWhenVisible
};

function ObjectActivityLog$GoToCommentActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var commentId = Props.commentId;
  var currentBranch = Props.currentBranch;
  var showComments = Props.showComments;
  var openBranches = Props.openBranches;
  var workspace = WorkspaceContext.use(undefined);
  var tmp;
  if (typeof object_ === "object") {
    var variant = object_.NAME;
    tmp = variant === "Integration" ? "Integration" : (
        variant === "Property" ? "Property" : (
            variant === "Event" ? "Event" : (
                variant === "PropertyGroup" ? "PropertyGroup" : (
                    variant === "Goal" ? "Goal" : (
                        variant === "InspectorIssue" ? "InspectorIssue" : (
                            variant === "EventVariant" ? "EventVariant" : (
                                variant === "Metric" ? "Metric" : "Branch"
                              )
                          )
                      )
                  )
              )
          )
      );
  } else {
    tmp = Pervasives.failwith("WorkspaceSetting does not support comments");
  }
  var action = FirebaseFetcherHooks.useActionFromCommentId(workspace.id, commentId, tmp);
  if (typeof action === "object") {
    var match = action.VAL;
    if (match && !match.tl) {
      var tmp$1 = {
        object_: object_,
        objectId: objectId,
        currentBranch: currentBranch,
        openBranches: openBranches,
        goToId: match.hd.id
      };
      if ($$event !== undefined) {
        tmp$1.event = Caml_option.valFromOption($$event);
      }
      if (showComments !== undefined) {
        tmp$1.showComments = Caml_option.valFromOption(showComments);
      }
      return React.createElement(ObjectActivityLog$ActivityLogLoadedWhenVisible, tmp$1);
    }
    
  } else if (action === "Initial") {
    return React.createElement(LoadingCircle.make, {
                color: Styles.Color.darkBlue
              });
  }
  var tmp$2 = {
    object_: object_,
    objectId: objectId,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if ($$event !== undefined) {
    tmp$2.event = Caml_option.valFromOption($$event);
  }
  if (showComments !== undefined) {
    tmp$2.showComments = Caml_option.valFromOption(showComments);
  }
  return React.createElement(ObjectActivityLog$ActivityLog, tmp$2);
}

var GoToCommentActivityLog = {
  make: ObjectActivityLog$GoToCommentActivityLog
};

function ObjectActivityLog(Props) {
  var object_ = Props.object_;
  var objectId = Props.objectId;
  var $$event = Props.event;
  var goToCommentId = Props.goToCommentId;
  var currentBranch = Props.currentBranch;
  var showComments = Props.showComments;
  var openBranches = Props.openBranches;
  var pageSize = Props.pageSize;
  if (goToCommentId !== undefined) {
    var tmp = {
      object_: object_,
      objectId: objectId,
      commentId: goToCommentId,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp.event = Caml_option.valFromOption($$event);
    }
    if (showComments !== undefined) {
      tmp.showComments = Caml_option.valFromOption(showComments);
    }
    return React.createElement(ObjectActivityLog$GoToCommentActivityLog, tmp);
  }
  var tmp$1 = {
    object_: object_,
    objectId: objectId,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if ($$event !== undefined) {
    tmp$1.event = Caml_option.valFromOption($$event);
  }
  if (showComments !== undefined) {
    tmp$1.showComments = Caml_option.valFromOption(showComments);
  }
  if (pageSize !== undefined) {
    tmp$1.pageSize = Caml_option.valFromOption(pageSize);
  }
  return React.createElement(ObjectActivityLog$ActivityLog, tmp$1);
}

var make = ObjectActivityLog;

export {
  isCommentAction ,
  ActivityLog ,
  ActivityLogLoadedWhenVisible ,
  GoToCommentActivityLog ,
  make ,
}
/* Css Not a pure module */
