// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../../bs-case/src/case.mjs";
import * as CmdK from "../CmdK.mjs";
import * as Icon from "../Icon.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Nanoid from "nanoid";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as IconSend from "../IconSend.mjs";
import * as IconTags from "../IconTags.mjs";
import * as Markdown from "../Markdown.mjs";
import * as Tooltips from "../markdown/tooltips/tooltips.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as NameInput from "../NameInput.mjs";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as RoleUtils from "../RoleUtils.mjs";
import * as TagsInput from "../TagsInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconMetric from "../IconMetric.mjs";
import * as ModelStore from "../ModelStore.mjs";
import * as ModelUtils from "../ModelUtils.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as DomainUtils from "../DomainUtils.mjs";
import * as DrawerUtils from "../DrawerUtils.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as CommentInput from "../CommentInput.mjs";
import * as DrawerHeader from "./DrawerHeader.mjs";
import * as EventMetrics from "../EventMetrics.mjs";
import * as EventSources from "../EventSources.mjs";
import * as OpsWithState from "../OpsWithState.mjs";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as IconIdentifier from "../IconIdentifier.mjs";
import * as IconSideEffect from "../IconSideEffect.mjs";
import * as IconDescription from "../IconDescription.mjs";
import * as EventNameMapping from "../EventNameMapping.mjs";
import * as EventSideEffects from "../EventSideEffects.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as BeltListExtensions from "../BeltListExtensions.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as ValidationConfigV2 from "../guardrails/ValidationConfigV2.mjs";
import * as EventDetailTriggers from "../EventDetailTriggers.mjs";
import * as EventDetailsDomains from "./EventDetailsDomains.mjs";
import * as EventImplementation from "./EventImplementation.mjs";
import * as OrganizationContext from "../OrganizationContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as BranchChangesContext from "../BranchChangesContext.mjs";
import * as EventDetailsVariants from "./EventDetailsVariants.mjs";
import * as GuardRailsConfigUtils from "../guardrails/GuardRailsConfigUtils.mjs";
import * as EventDetailsCategories from "./EventDetailsCategories.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import ReactTextareaAutosize from "react-textarea-autosize";
import * as GetEventNamespaceUseCase from "../getEventNamespaceUseCase.mjs";
import * as AdvancedNameInputPlaceholder from "../AdvancedNameInputPlaceholder.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top(Css.px(16)),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.gap(Css.px(10)),
              tl: {
                hd: Css.alignItems("flexStart"),
                tl: {
                  hd: Css.margin(Css.px(16)),
                  tl: {
                    hd: Css.marginBottom(Css.px(0)),
                    tl: {
                      hd: Css.padding(Css.px(16)),
                      tl: {
                        hd: Css.borderRadius(Css.px(8)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.lighten(Styles.Color.lightBlue, 2)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var linkStyles = Curry._1(Css.style, {
      hd: Css.fontSize(Styles.FontSize.small),
      tl: {
        hd: Css.color(Styles.Color.darkBlue),
        tl: {
          hd: Css.hover({
                hd: Css.textDecoration("underline"),
                tl: /* [] */0
              }),
          tl: /* [] */0
        }
      }
    });

function EventDetails$GlobalWorkspaceSection(Props) {
  var globalEventPath = Props.globalEventPath;
  return React.createElement("section", {
              className: rootStyles
            }, React.createElement(IconDescription.make, {
                  fill: Styles.Color.darkBlue
                }), React.createElement("div", undefined, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.deepBlue,
                          children: "Global event"
                        }), React.createElement(SimpleTooltip.make, {
                          tooltip: "This is a globally defined event so editing is limited",
                          children: React.createElement(Icon.make, {
                                type_: "information",
                                size: "medium",
                                color: Styles.Color.darkBlue
                              })
                        })), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(Link.make, {
                      path: globalEventPath,
                      className: linkStyles,
                      children: React.createElement($$Text.make, {
                            children: "View in global workspace"
                          })
                    })));
}

function scrollToDomRef(domRef) {
  Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(domRef.current), undefined, (function (anchor) {
          anchor.scrollIntoView({
                inline: "center"
              });
        }));
}

var description = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.input),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("block"),
              tl: {
                hd: Css.flex({
                      NAME: "num",
                      VAL: 1.0
                    }),
                tl: {
                  hd: Css.padding(Css.px(10)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", "transparent"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.color(Styles.Color.light12),
                              tl: {
                                hd: Css.selector(":not(:disabled)", {
                                      hd: Css.focus({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var sectionHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginTop(Css.px(16)),
          tl: {
            hd: Css.marginBottom(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

function EventDetails$EventDetailsDrawerHeader(Props) {
  var currentFilters = Props.currentFilters;
  var $$event = Props.event;
  var role = Props.role;
  var isFullscreen = Props.isFullscreen;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var model = ModelStore.useModel(undefined);
  var globalEvent = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalEvent, $$event.id);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events);
  var viewerId = ViewerContext.use(undefined).id;
  var domainsWithEvent = DomainStore.useEventOrEventVariantDomains($$event.id, false);
  var viewerCanEdit = RoleUtils.canEdit(role);
  var correctCase = hasBasicNameCasing ? NameUtils.isCorrectCase(expectedEventCase, $$event.name) : undefined;
  var namingConvention = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events) ? Case.toAnalytics(Case.getCasingWithWorkspaceCase($$event.name, expectedEventCase)) : "Advanced";
  var eventMetrics = Belt_List.keepU(model.metrics, (function (metric) {
          return Belt_List.some(metric.items, (function (x) {
                        return x.VAL.eventId === $$event.id;
                      }));
        }));
  var handleDuplicateEvent = function (param) {
    var match = AvoLimits.DuplicateItems.computeAvailability(workspace);
    if (!(match === "AvailableDuringTrial" || match === "Available")) {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "BillingPrompt",
                    VAL: "DuplicateItems"
                  }
                });
    }
    var toEventId = Nanoid.nanoid();
    var triggerIdTuples = Belt_Array.map($$event.triggers, (function (param) {
            return [
                    param.id,
                    Nanoid.nanoid()
                  ];
          }));
    var ruleIdTuples = Belt_List.toArray(Belt_List.keepMap(model.rules, (function (param) {
                var item = param.item;
                var eventId;
                if (typeof item !== "object") {
                  return ;
                }
                var variant = item.NAME;
                if (variant === "PropertyRef" || variant === "EventObjectField") {
                  eventId = item.VAL[0];
                } else {
                  if (variant !== "Event") {
                    return ;
                  }
                  eventId = item.VAL;
                }
                if (eventId === $$event.id) {
                  return [
                          param.id,
                          Nanoid.nanoid()
                        ];
                }
                
              })));
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (_branch) {
              Curry._1(addToast, {
                    message: "The event was duplicated",
                    toastType: /* Success */0
                  });
              Router.Schema.pushDrawerItem(undefined, undefined, {
                    NAME: "event",
                    VAL: [
                      toEventId,
                      undefined,
                      undefined,
                      false
                    ]
                  });
            }),
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              AnalyticsRe.eventCreated(currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, correctCase, namingConvention, "Duplicated", GuardRailsConfigUtils.nameComponentsToAnalyticsCase(ValidationConfigV2.EventsV2.getNameComponents(config.events)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(ValidationConfigV2.EventsV2.getNameComponents(config.events)), false, 1, "Systematic", undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
              Curry._2(Domains.forEach, domainsWithEvent, (function (domain) {
                      DomainUtils.Firebase.addDomainItems([{
                              NAME: "event",
                              VAL: toEventId
                            }], undefined, undefined, schemaBundle$1.schemaId, sendActions, viewerId, domain.id);
                    }));
            }),
          (function (param) {
              Curry._1(addToast, {
                    message: "There was an error duplicating the event",
                    toastType: /* Error */1
                  });
            }),
          [[
              {
                NAME: "DuplicateEvent",
                VAL: [
                  [
                    $$event.id,
                    toEventId
                  ],
                  triggerIdTuples,
                  ruleIdTuples
                ]
              },
              {
                eventId: toEventId,
                eventQuery: toEventId
              }
            ]]
        ]);
  };
  var handleArchive = function (param) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (_branch) {
              var match = Router.getStaticRoute(undefined);
              var newDrawerItems = Belt_List.keep(match.drawerItems, (function (drawerItem) {
                      if (typeof drawerItem === "object" && drawerItem.NAME === "event") {
                        return drawerItem.VAL[0] !== $$event.id;
                      } else {
                        return true;
                      }
                    }));
              Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
            }),
          (function (branch) {
              Curry._1(addToast, {
                    message: "The event has been archived"
                  });
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              AnalyticsRe.eventRemoved(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Belt_List.length($$event.directPropertyRefs), correctCase, namingConvention, NamedBranch.getId(branch), schemaBundle$1.schemaId);
            }),
          undefined,
          [[
              {
                NAME: "Archive",
                VAL: {
                  NAME: "Event",
                  VAL: $$event.id
                }
              },
              {
                eventId: $$event.id,
                eventQuery: $$event.id
              }
            ]]
        ]);
  };
  var onChange = function (name, param, correctCase, _onReset) {
    if (name !== $$event.name) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      AnalyticsRe.eventNameUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, expectedEventCase)), $$event.name, name.length, Case.to_("Sentence", name).split(" ").length, correctCase, "EventDetails", $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, $$event.name);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "UpdateEventName",
                        VAL: [
                          $$event.id,
                          name
                        ]
                      },
                      {
                        eventId: $$event.id,
                        eventQuery: $$event.id
                      }
                    ]]
                ]);
    }
    
  };
  var onChangeAdvanced = function (name, _eventNameComponents, nameComponents, _onReset) {
    var match = ValidationConfigV2.NameComponent.getCase(nameComponents);
    var simpleCase = typeof match === "object" ? match.VAL : "None";
    var commonCases = ValidationConfigV2.NameComponent.findCommonCaseValues(Belt_Array.concat([ValidationConfigV2.NameComponent.getCaseVariances([name])], [[simpleCase]]));
    var _case = Belt_Array.getBy(commonCases, (function (commonCase) {
            return commonCase === simpleCase;
          }));
    var correctCase = _case !== undefined ? true : undefined;
    if (name !== $$event.name) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                      AnalyticsRe.eventNameUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, simpleCase === "None" ? undefined : Case.toAnalytics(Case.getCasingWithWorkspaceCase(name, simpleCase)), $$event.name, name.length, Case.to_("Sentence", name).split(" ").length, correctCase, "EventDetails", $$event.id, NamedBranch.getId(branch), schemaBundle$1.schemaId, $$event.id, $$event.name);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "UpdateEventName",
                        VAL: [
                          $$event.id,
                          name
                        ]
                      },
                      {
                        eventId: $$event.id,
                        eventQuery: $$event.id
                      }
                    ]]
                ]);
    }
    
  };
  var advancedNamingConventionsToggled = Curry._1(ValidationConfigV2.Rule.isChecked, config.events.nameComponents);
  var tmp;
  if (viewerCanEdit) {
    var match$1 = AvoLimits.NameMapping.computeAvailability(workspace);
    tmp = [{
        NAME: "Option",
        VAL: {
          label: "Add Name Mapping" + (
            match$1 === "Available" ? "" : " ⚡️"
          ),
          onClick: (function (param) {
              if (AvoLimits.NameMapping.isAvailable(workspace)) {
                return Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                var eventsWithMapping = Belt_List.concat(ModelUtils.eventsWithNameMapping(model), {
                                      hd: $$event.id,
                                      tl: /* [] */0
                                    });
                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "AddRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(eventsWithMapping)), Belt_List.length(eventsWithMapping), Belt_List.length(Belt_List.keepU(eventsWithMapping, (function (id) {
                                                return id === $$event.id;
                                              }))), Belt_List.length(model.events), isGlobalWorkspace ? "NameInLocalWorkspace" : "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, namingConvention, "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "AddRule",
                                  VAL: [
                                    Nanoid.nanoid(),
                                    {
                                      NAME: "Event",
                                      VAL: $$event.id
                                    },
                                    isGlobalWorkspace ? ({
                                          NAME: "NameInLocalWorkspace",
                                          VAL: {
                                            workspace: undefined,
                                            name: undefined
                                          }
                                        }) : ({
                                          NAME: "NameMapping",
                                          VAL: {
                                            destination: undefined,
                                            name: undefined
                                          }
                                        })
                                  ]
                                },
                                {
                                  eventId: $$event.id,
                                  eventQuery: $$event.id
                                }
                              ]]
                          ]);
              } else {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "NameMapping"
                            }
                          });
              }
            })
        }
      }];
  } else {
    tmp = [];
  }
  var tmp$1;
  if (viewerCanEdit) {
    var match$2 = AvoLimits.DuplicateItems.computeAvailability(workspace);
    tmp$1 = [{
        NAME: "Option",
        VAL: {
          label: "Duplicate event" + (
            match$2 === "Available" ? "" : " ⚡️"
          ),
          onClick: handleDuplicateEvent
        }
      }];
  } else {
    tmp$1 = [];
  }
  return React.createElement(DrawerHeader.make, {
              kind: (
                globalEvent !== undefined || isGlobalWorkspace ? "Global " : ""
              ) + "Event",
              options: Belt_Array.concatMany([
                    tmp,
                    [
                      {
                        NAME: "Option",
                        VAL: {
                          label: isFullscreen ? "Exit Full Screen" : "Full Screen",
                          onClick: (function (param) {
                              Router.Schema.pushDrawerItem(undefined, undefined, {
                                    NAME: "event",
                                    VAL: [
                                      $$event.id,
                                      undefined,
                                      undefined,
                                      !isFullscreen
                                    ]
                                  });
                            })
                        }
                      },
                      {
                        NAME: "Option",
                        VAL: {
                          label: "Copy URL",
                          onClick: (function (param) {
                              CopyTextToClipboard(Router.Link.getSimple(undefined));
                            })
                        }
                      }
                    ],
                    tmp$1,
                    viewerCanEdit ? [{
                          NAME: "WarningOption",
                          VAL: {
                            label: "Archive Event",
                            onClick: (function (param) {
                                if (eventMetrics === /* [] */0) {
                                  return handleArchive(undefined);
                                }
                                var count = String(Belt_List.length(eventMetrics));
                                var plural = count === "1" ? "" : "s";
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "AreYouSureModal",
                                        VAL: [
                                          "Are you sure you want to archive this event?",
                                          "This event belongs to " + count + " metric" + plural + " and archiving it will remove it from the metric" + plural + ". You can restore the event from the activity log which will also restore its place in the metric" + plural + ".",
                                          "Archive",
                                          handleArchive,
                                          (function (param) {
                                              
                                            })
                                        ]
                                      }
                                    });
                              })
                          }
                        }] : []
                  ]),
              children: ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events) || !advancedNamingConventionsToggled ? React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("relative"),
                            tl: {
                              hd: Css.height(Css.px(60)),
                              tl: {
                                hd: Css.marginLeft(Css.px(-24)),
                                tl: {
                                  hd: Css.marginTop(Css.px(-19)),
                                  tl: {
                                    hd: Css.marginBottom(Css.px(-19)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement(NameInput.make, {
                          name: $$event.name,
                          existingNames: Belt_List.toArray(GetEventNamespaceUseCase.getEventNamespace(model.events)),
                          expectedCase: expectedEventCase,
                          forceCase: ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
                          onChange: onChange,
                          placeholder: "Event Name",
                          disabled: !viewerCanEdit,
                          itemType: "Event",
                          actionType: "Rename",
                          getPossibleItemLink: (function (eventName) {
                              return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                                            return [
                                                    Router.Link.addDrawerItem(undefined, {
                                                          NAME: "event",
                                                          VAL: [
                                                            param.id,
                                                            undefined,
                                                            undefined,
                                                            false
                                                          ]
                                                        }),
                                                    undefined
                                                  ];
                                          }));
                            })
                        })) : React.createElement(AdvancedNameInputPlaceholder.make, {
                      currentName: $$event.name,
                      disabled: !viewerCanEdit,
                      onChange: onChangeAdvanced,
                      role: role
                    })
            });
}

function EventDetails(Props) {
  var branchStatus = Props.branchStatus;
  var $$event = Props.event;
  var schema = Props.schema;
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var role = Props.role;
  var isFullscreen = Props.isFullscreen;
  var model = ModelStore.useModel(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var globalEvent = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalEvent, $$event.id);
  var organization = OrganizationContext.useOrganization(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var isEventModified = BranchChangesContext.useIsEventModified($$event.id);
  var viewerCanEdit = RoleUtils.canEdit(role);
  var maybeDrawerItem = Belt_List.head(RouterStore.Schema.useDrawerItems(undefined));
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var events = model.events;
  var screenIsOnTop;
  if (maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event") {
    var match$1 = maybeDrawerItem.VAL;
    screenIsOnTop = match$1[2] !== undefined ? false : match$1[0] === $$event.id;
  } else {
    screenIsOnTop = false;
  }
  Kbar.useRegisterActions(screenIsOnTop ? [Curry.app(CmdK.Action.t, [
                "event_fullscreen",
                {
                  TAG: /* RootItem */0,
                  _0: "Toggle Full Screen on Event"
                },
                1.1,
                ["f"],
                undefined,
                undefined,
                (function (param) {
                    if (maybeDrawerItem === undefined) {
                      return ;
                    }
                    if (typeof maybeDrawerItem !== "object") {
                      return ;
                    }
                    if (maybeDrawerItem.NAME !== "event") {
                      return ;
                    }
                    var match = maybeDrawerItem.VAL;
                    Router.Schema.swapTopDrawerItem({
                          NAME: "event",
                          VAL: [
                            match[0],
                            match[1],
                            undefined,
                            !match[3]
                          ]
                        });
                  }),
                undefined,
                undefined
              ])] : [], [
        $$event.id,
        screenIsOnTop,
        isFullscreen
      ]);
  var tagsAnchor = React.useRef(null);
  var hasIncludedSource = Belt_List.some($$event.includeSources, (function (param) {
          return ModelUtils.getSourceById(param.id, model) !== undefined;
        }));
  React.useEffect((function () {
          Curry._1(globalSend, {
                TAG: /* SetLastSeenEventId */1,
                _0: $$event.id
              });
          var eventHandler = function ($$event) {
            var match = $$event.altKey;
            var match$1 = $$event.keyCode;
            if (match && match$1 === 84) {
              scrollToDomRef(tagsAnchor);
              $$event.preventDefault();
              return ;
            }
            
          };
          window.addEventListener("keydown", eventHandler);
          return (function (param) {
                    window.removeEventListener("keydown", eventHandler);
                  });
        }), []);
  var previousEventId = Hooks.usePrevious1($$event.id);
  React.useEffect((function () {
          if (previousEventId !== $$event.id) {
            Curry._1(globalSend, {
                  TAG: /* SetLastSeenEventId */1,
                  _0: $$event.id
                });
          }
          
        }), [previousEventId]);
  var eventMetrics = Belt_List.keepU(model.metrics, (function (metric) {
          return Belt_List.some(metric.items, (function (x) {
                        return x.VAL.eventId === $$event.id;
                      }));
        }));
  var fromItem_itemId = $$event.id;
  var fromItem_itemName = $$event.name;
  var fromItem = {
    itemId: fromItem_itemId,
    itemName: fromItem_itemName,
    itemType: "Event"
  };
  var tmp;
  if (organization !== undefined) {
    var match$2 = organization.globalWorkspaceIds;
    if (match$2.length !== 1) {
      tmp = null;
    } else {
      var id = match$2[0];
      if (globalEvent !== undefined) {
        var globalEventPath = Curry._4(Router.Link.get, {
              NAME: "schema",
              VAL: id
            }, undefined, "events", {
              hd: {
                NAME: "event",
                VAL: [
                  $$event.id,
                  undefined,
                  undefined,
                  false
                ]
              },
              tl: /* [] */0
            });
        tmp = React.createElement(EventDetails$GlobalWorkspaceSection, {
              globalEventPath: globalEventPath
            });
      } else {
        tmp = null;
      }
    }
  } else {
    tmp = null;
  }
  var template = schema.onboardingTemplate;
  var tmp$1;
  if (globalEvent !== undefined) {
    var description$1 = globalEvent.description;
    var tmp$2 = {
      className: description,
      maxRows: 15,
      minRows: 1,
      placeholder: "Global description missing...",
      value: description$1.trim(),
      id: "global-description-input",
      disabled: true
    };
    var tmp$3 = description$1.trim() === "" ? 1 : undefined;
    if (tmp$3 !== undefined) {
      tmp$2.rows = tmp$3;
    }
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.padding(Css.px(10)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light09,
                  children: "Global description"
                }), React.createElement(Icon.make, {
                  type_: "lock",
                  size: "small",
                  color: Styles.Color.light09
                })), React.createElement(ReactTextareaAutosize, tmp$2), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: {
                          hd: Css.padding(Css.px(10)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light09,
                  children: "Description"
                })));
  } else {
    tmp$1 = null;
  }
  var match$3 = model.goals;
  var tmp$4;
  if (maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event") {
    var commentId = maybeDrawerItem.VAL[1];
    tmp$4 = commentId !== undefined ? commentId : undefined;
  } else {
    tmp$4 = undefined;
  }
  var tmp$5 = {
    object_: {
      NAME: "Event",
      VAL: $$event
    },
    objectId: $$event.id,
    event: $$event,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if (tmp$4 !== undefined) {
    tmp$5.goToCommentId = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "event" && maybeDrawerItem.VAL[3] ? ({
                        hd: Css.position("fixed"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light01),
                                  tl: {
                                    hd: Css.padding4(Css.px(50), Css.vw(5), Css.px(0), Css.vw(5)),
                                    tl: {
                                      hd: Css.zIndex(Styles.ZIndex.eventDetailsFullScreen),
                                      tl: {
                                        hd: Css.overflowY("auto"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }) : /* [] */0)
            }, React.createElement("div", {
                  key: $$event.id,
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderBottomWidth(Css.px(0)),
                              tl: isFullscreen ? ({
                                    hd: Css.borderRadius(Styles.Border.radius),
                                    tl: {
                                      hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                      tl: /* [] */0
                                    }
                                  }) : /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(EventDetails$EventDetailsDrawerHeader, {
                      currentFilters: currentFilters,
                      event: $$event,
                      role: role,
                      isFullscreen: isFullscreen
                    }), React.createElement("div", {
                      className: "branchContainerTour " + Curry._1(Css.style, {
                            hd: Css.overflowY("auto"),
                            tl: {
                              hd: Css.maxHeight({
                                    NAME: "subtract",
                                    VAL: [
                                      Css.vh(100),
                                      Css.px(DrawerUtils.getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute))
                                    ]
                                  }),
                              tl: /* [] */0
                            }
                          })
                    }, tmp, !(template == null) && Belt_Array.some(template.events, (function (id) {
                            return id === $$event.id;
                          })) ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.padding2(Css.px(10), Css.px(35)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: {
                                      hd: Css.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.regular),
                                          tl: {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.justifyContent("spaceBetween"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, "This is an example event", viewerCanEdit ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.light10),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(0), Css.px(2)),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.green),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                          schemaRef.set({
                                                onboardingTemplate: {
                                                  events: Belt_Array.keep(template.events, (function (id) {
                                                          return id !== $$event.id;
                                                        })),
                                                  properties: template.properties,
                                                  propertyGroups: template.propertyGroups,
                                                  goals: template.goals,
                                                  metrics: template.metrics
                                                }
                                              }, {"merge": true});
                                          AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "KeepCurrentExample", "Event", schemaBundle.branchId, schemaBundle.schemaId);
                                        })
                                    }, "Keep Event"), React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.color(Styles.Color.light10),
                                                        tl: {
                                                          hd: Css.margin2(Css.px(0), Css.px(2)),
                                                          tl: {
                                                            hd: Css.hover({
                                                                  hd: Css.color(Styles.Color.darkRed),
                                                                  tl: /* [] */0
                                                                }),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                    AnalyticsRe.exampleInteraction(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveCurrentExample", "Event", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "Archive",
                                                      VAL: {
                                                        NAME: "Event",
                                                        VAL: $$event.id
                                                      }
                                                    },
                                                    {
                                                      eventId: $$event.id,
                                                      eventQuery: $$event.id
                                                    }
                                                  ]]
                                              ]);
                                        })
                                    }, "Remove Action"), React.createElement("button", {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.button),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.fontSize(Styles.FontSize.small),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.bold),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.red),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.white),
                                                          tl: {
                                                            hd: Css.margin2(Css.px(0), Css.px(5)),
                                                            tl: {
                                                              hd: Css.padding2(Css.px(5), Css.px(10)),
                                                              tl: {
                                                                hd: Css.borderRadius(Styles.Border.radius),
                                                                tl: {
                                                                  hd: Css.hover({
                                                                        hd: Css.backgroundColor(Styles.Color.darkRed),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onClick: (function (param) {
                                          FirebaseUtils.archiveTemplate(template, (function (eta) {
                                                  Curry.app(sendActions, [
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        eta
                                                      ]);
                                                }));
                                          AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "RemoveAllExamples", "Event", schemaBundle.branchId, schemaBundle.schemaId);
                                        })
                                    }, "Remove All Examples")) : null) : null, Belt_Option.mapWithDefault($$event.uniqueName, null, (function (uniqueName) {
                            var eventsWithSameName = Belt_List.keepU(model.events, (function (item) {
                                    if (Case.camel(item.name) === Case.camel($$event.name)) {
                                      return item.id !== $$event.id;
                                    } else {
                                      return false;
                                    }
                                  }));
                            var tmp;
                            if (eventsWithSameName) {
                              if (eventsWithSameName.tl) {
                                tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There are " + String(Belt_List.length(eventsWithSameName)) + " events with the same or similar name:"), React.createElement("span", undefined, Belt_List.toArray(Belt_List.map(eventsWithSameName, (function ($$event) {
                                                    return React.createElement("span", undefined, React.createElement(Link.make, {
                                                                    path: Router.Link.addDrawerItem(undefined, {
                                                                          NAME: "event",
                                                                          VAL: [
                                                                            $$event.id,
                                                                            undefined,
                                                                            undefined,
                                                                            false
                                                                          ]
                                                                        }),
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.color(Styles.Color.darkBlue),
                                                                          tl: {
                                                                            hd: Css.paddingLeft(Css.px(5)),
                                                                            tl: {
                                                                              hd: Css.hover({
                                                                                    hd: Css.color(Styles.Color.deepBlue),
                                                                                    tl: /* [] */0
                                                                                  }),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }),
                                                                    children: $$event.name
                                                                  }), ",");
                                                  })))));
                              } else {
                                var $$event$1 = eventsWithSameName.hd;
                                tmp = React.createElement("div", undefined, React.createElement("span", undefined, "There is one event with same or similar name:"), React.createElement(Link.make, {
                                          path: Router.Link.addDrawerItem(undefined, {
                                                NAME: "event",
                                                VAL: [
                                                  $$event$1.id,
                                                  undefined,
                                                  undefined,
                                                  false
                                                ]
                                              }),
                                          className: Curry._1(Css.style, {
                                                hd: Css.color(Styles.Color.darkBlue),
                                                tl: {
                                                  hd: Css.paddingLeft(Css.px(5)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }),
                                          children: $$event$1.name
                                        }));
                              }
                            } else {
                              tmp = React.createElement(React.Fragment, undefined, "It looks like the naming conflict has been resolved. You can safely remove it but that will change how the event is represented in Codegen. ", React.createElement(TextButton.make, {
                                        onClick: (function (param) {
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "UpdateEventUniqueNameV2",
                                                        VAL: [
                                                          $$event.id,
                                                          ""
                                                        ]
                                                      },
                                                      {
                                                        eventId: $$event.id,
                                                        eventQuery: $$event.id
                                                      }
                                                    ]]
                                                ]);
                                          }),
                                        style: "Blue",
                                        size: "Medium",
                                        children: "Remove identifier"
                                      }));
                            }
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("flexStart"),
                                                tl: {
                                                  hd: Css.margin4(Css.px(10), Css.px(20), Css.px(0), Css.px(30)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.marginTop(Css.px(11)),
                                                  tl: {
                                                    hd: Css.marginRight(Css.px(5)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(IconIdentifier.make, {})), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.width(Css.pct(100)),
                                                  tl: {
                                                    hd: Css.padding(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.light12),
                                                            tl: {
                                                              hd: Css.fontSize(Styles.FontSize.regular),
                                                              tl: {
                                                                hd: Css.marginTop(Css.px(1)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, "Action Identifier"), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light10),
                                                          tl: {
                                                            hd: Css.marginTop(Css.px(5)),
                                                            tl: {
                                                              hd: Css.marginBottom(Css.px(15)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, React.createElement("div", undefined, "The event identifier is used to differentiate between events that have the same name. It does not affect how the event is sent but does change how the event is represented in the code Avo generates. We recommend changing the event identifier to something that describes how this event is different from other events with the same name."), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                          tl: {
                                                            hd: Css.marginTop(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, tmp)), React.createElement("div", {
                                                className: Curry._1(Css.style, {
                                                      hd: Css.position("relative"),
                                                      tl: {
                                                        hd: Css.margin4(Css.px(5), Css.px(0), Css.px(0), Css.px(0)),
                                                        tl: {
                                                          hd: Css.height(Css.px(50)),
                                                          tl: {
                                                            hd: Css.width(Css.pct(100)),
                                                            tl: {
                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                              tl: {
                                                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, React.createElement(NameInput.make, {
                                                    name: uniqueName,
                                                    existingNames: Belt_List.toArray(GetEventNamespaceUseCase.getEventNamespace(events)),
                                                    expectedCase: expectedEventCase,
                                                    forceCase: ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
                                                    onChange: (function (value, param, param$1, _onReset) {
                                                        if (value !== uniqueName) {
                                                          return Curry.app(sendActions, [
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      [[
                                                                          {
                                                                            NAME: "UpdateEventUniqueNameV2",
                                                                            VAL: [
                                                                              $$event.id,
                                                                              value
                                                                            ]
                                                                          },
                                                                          {
                                                                            eventId: $$event.id,
                                                                            eventQuery: $$event.id
                                                                          }
                                                                        ]]
                                                                    ]);
                                                        }
                                                        
                                                      }),
                                                    placeholder: "Event Name",
                                                    disabled: !viewerCanEdit,
                                                    inputStyles: {
                                                      hd: Css.fontSize(Styles.FontSize.regular),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                        tl: /* [] */0
                                                      }
                                                    },
                                                    itemType: "Event",
                                                    actionType: "Rename",
                                                    getPossibleItemLink: (function (eventName) {
                                                        return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                                                                      return [
                                                                              Router.Link.addDrawerItem(undefined, {
                                                                                    NAME: "event",
                                                                                    VAL: [
                                                                                      param.id,
                                                                                      undefined,
                                                                                      undefined,
                                                                                      false
                                                                                    ]
                                                                                  }),
                                                                              undefined
                                                                            ];
                                                                    }));
                                                      })
                                                  }))));
                          })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(15), Css.px(20), Css.px(15), Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(11)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(5)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(IconDescription.make, {})), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.width(Css.pct(100)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("label", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.color(Styles.Color.light12),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.padding4(Css.px(11), Css.px(0), Css.px(5), Css.px(10)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  htmlFor: "description-input"
                                }, "Description", React.createElement(Tooltip.WithAnalytics.make, {
                                      fromItem: fromItem,
                                      title: "Description",
                                      children: React.createElement(Tooltip.Content.make, {
                                            children: React.createElement(Markdown.make, {
                                                  source: Tooltips.eventDescription
                                                })
                                          })
                                    })), tmp$1, React.createElement(OpsWithState.make, {
                                  value: $$event.description,
                                  onFlush: (function (description) {
                                      Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            [[
                                                {
                                                  NAME: "UpdateEventDescription",
                                                  VAL: [
                                                    $$event.id,
                                                    description
                                                  ]
                                                },
                                                {
                                                  eventId: $$event.id,
                                                  eventQuery: $$event.id
                                                }
                                              ]]
                                          ]);
                                    }),
                                  onChangeCompleted: (function (value, valueBefore) {
                                      AnalyticsRe.descriptionUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, valueBefore, "Event", Js_string.split(" ", value).length, value, value.length, $$event.name, schemaBundle.branchId, schemaBundle.schemaId);
                                    }),
                                  children: (function (value, onChange, onBlur, onFocus) {
                                      return React.createElement(ReactTextareaAutosize, {
                                                  className: description,
                                                  maxRows: 15,
                                                  minRows: 1,
                                                  onChange: (function (domEvent) {
                                                      Curry._1(onChange, domEvent.target.value);
                                                    }),
                                                  placeholder: "Describe the event...",
                                                  onFocus: (function (domEvent) {
                                                      Curry._1(onFocus, domEvent.target.value);
                                                    }),
                                                  onBlur: (function (domEvent) {
                                                      Curry._1(onBlur, domEvent.target.value);
                                                    }),
                                                  value: value,
                                                  autoFocus: value === "",
                                                  id: "description-input",
                                                  disabled: !viewerCanEdit
                                                });
                                    })
                                }))), React.createElement(EventDetailsVariants.make, {
                          event: $$event,
                          viewerCanEdit: viewerCanEdit
                        }), React.createElement(EventDetailTriggers.make, {
                          event: $$event,
                          modelSources: model.sources,
                          schemaBundle: schemaBundle,
                          viewerCanEdit: viewerCanEdit
                        }), React.createElement(EventNameMapping.make, {
                          event: $$event,
                          slowModelMaybeFlattened: model,
                          fromItem: fromItem,
                          sendActions: sendActions,
                          role: role,
                          isGlobalWorkspace: isGlobalWorkspace
                        }), React.createElement("div", {
                          className: sectionHeader
                        }, React.createElement(Spacer.make, {
                              width: 24
                            }), React.createElement(IconSend.make, {
                              color: Styles.Color.light07
                            }), React.createElement(Spacer.make, {
                              width: 18
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: isGlobalWorkspace ? "Workspaces" : "Sources"
                            }), isGlobalWorkspace ? null : React.createElement(Tooltip.WithAnalytics.make, {
                                fromItem: fromItem,
                                title: "Adding a source",
                                children: React.createElement(Tooltip.Content.make, {
                                      children: React.createElement(Markdown.make, {
                                            source: Tooltips.excludeSources
                                          })
                                    })
                              })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(48)),
                                tl: /* [] */0
                              })
                        }, React.createElement(EventSources.make, {
                              event: $$event,
                              model: model,
                              viewerCanEdit: viewerCanEdit,
                              isEventModified: isEventModified
                            })), React.createElement("div", {
                          className: sectionHeader
                        }, React.createElement(Spacer.make, {
                              width: 24
                            }), React.createElement(IconSideEffect.make, {}), React.createElement(Spacer.make, {
                              width: 18
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light12,
                              children: "Actions"
                            }), React.createElement(Tooltip.WithAnalytics.make, {
                              fromItem: fromItem,
                              title: "Actions",
                              children: React.createElement(Tooltip.Content.make, {
                                    children: React.createElement(Markdown.make, {
                                          source: Tooltips.trackingMethods
                                        })
                                  })
                            })), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginLeft(Css.px(48)),
                                tl: /* [] */0
                              })
                        }, React.createElement(EventSideEffects.make, {
                              currentFilters: currentFilters,
                              event: $$event,
                              filters: filters,
                              viewerCanEdit: viewerCanEdit,
                              model: model
                            })), eventMetrics !== /* [] */0 ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("flexStart"),
                                    tl: {
                                      hd: Css.padding4(Css.px(0), Css.px(30), Css.px(10), Css.px(30)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop(Css.px(16)),
                                      tl: {
                                        hd: Css.marginRight(Css.px(5)),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(IconMetric.make, {
                                    color: Styles.Color.light07
                                  })), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.width(Css.pct(100)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.padding4(Css.px(12), Css.px(0), Css.px(5), Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, "Related Metrics", React.createElement(Tooltip.WithAnalytics.make, {
                                        fromItem: fromItem,
                                        title: "Related Metrics",
                                        children: React.createElement(Tooltip.Content.make, {
                                              children: React.createElement(Markdown.make, {
                                                    source: Tooltips.relatedMetrics
                                                  })
                                            })
                                      })), React.createElement(EventMetrics.make, {
                                    metrics: eventMetrics
                                  }))) : null, React.createElement(EventDetailsDomains.make, {
                          event: $$event,
                          viewerCanEdit: viewerCanEdit,
                          viewerRole: role
                        }), match$3 ? React.createElement(EventDetailsCategories.make, {
                            event: $$event,
                            fromItem: fromItem,
                            viewerCanEdit: viewerCanEdit
                          }) : null, React.createElement("div", {
                          ref: tagsAnchor
                        }), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("flexStart"),
                                  tl: {
                                    hd: Css.padding4(Css.px(15), Css.px(30), Css.px(15), Css.px(30)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.marginTop(Css.px(11)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(5)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(IconTags.make, {})), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.fontWeight(Styles.FontWeight.bold),
                                    tl: {
                                      hd: Css.color(Styles.Color.light10),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.padding2(Css.px(0), Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                            tl: {
                                              hd: Css.color(Styles.Color.light12),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.padding4(Css.px(10), Css.px(0), Css.px(3), Css.px(3)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, "Tags", React.createElement(Tooltip.WithAnalytics.make, {
                                      fromItem: fromItem,
                                      title: "Tags",
                                      children: React.createElement(Tooltip.Content.make, {
                                            children: React.createElement(Markdown.make, {
                                                  source: Tooltips.tags
                                                })
                                          })
                                    })), React.createElement(TagsInput.make, {
                                  event: $$event,
                                  events: model.events,
                                  role: role
                                }))), hasIncludedSource && !isGlobalWorkspace ? React.createElement(EventImplementation.make, {
                            branchStatus: branchStatus,
                            event: $$event,
                            model: model
                          }) : null, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(15)),
                                tl: {
                                  hd: Css.padding4(Css.px(10), Css.px(0), Css.px(25), Css.px(0)),
                                  tl: {
                                    hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement(ObjectActivityLog.make, tmp$5)), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.flexGrow(1.0),
                                tl: /* [] */0
                              })
                        }), React.createElement(CommentInput.make, {
                          eventId: $$event.id,
                          threadId: $$event.id,
                          itemType: "Event",
                          itemName: $$event.name,
                          mini: false,
                          currentBranch: currentBranch,
                          role: role,
                          location: "Event"
                        }))));
}

var make = EventDetails;

export {
  make ,
}
/* rootStyles Not a pure module */
