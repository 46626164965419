// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PropertyValidationUtils from "../../../app/src/PropertyValidationUtils.mjs";

function get(property) {
  return Belt_Array.keepMap(property.eventSpecificAllowedPropertyValues, (function (param) {
                var literal = param[0];
                if (typeof literal === "object" && literal.NAME === "StringLit") {
                  return literal.VAL;
                }
                
              }));
}

function getWithLegacyFallback(hasEventSpecificAllowedPropertyValuesMigration, property) {
  if (hasEventSpecificAllowedPropertyValuesMigration) {
    return get(property);
  } else {
    return Belt_List.toArray(PropertyValidationUtils.getPropertyMatchesLegacy(property));
  }
}

function getWithLegacyFallbackAsString(hasEventSpecificAllowedPropertyValuesMigration, property) {
  var allowedValues = getWithLegacyFallback(hasEventSpecificAllowedPropertyValuesMigration, property);
  if (Caml_obj.equal(allowedValues, [])) {
    return "";
  } else {
    return "matches " + allowedValues.join(", ") + "";
  }
}

export {
  get ,
  getWithLegacyFallback ,
  getWithLegacyFallbackAsString ,
}
/* PropertyValidationUtils Not a pure module */
