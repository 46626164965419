// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as SandboxBanner from "./SandboxBanner.mjs";
import * as FramerMotion from "framer-motion";
import * as ModalWithArtwork from "./ModalWithArtwork.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function SandboxWelcomeModal(Props) {
  var onClose = Props.onClose;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var isAnonymousUser = SandboxBanner.isAnonymousUser(undefined);
  var handleLetsGo = function (_event) {
    AnalyticsRe.sandboxInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "LetsGo", "WelcomeModal", isAnonymousUser ? "Anonymous" : "Authenticated", schemaBundle.schemaId);
    Curry._1(onClose, undefined);
  };
  React.useEffect((function () {
          AnalyticsRe.sandboxModalSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "WelcomeModal", isAnonymousUser ? "Anonymous" : "Authenticated");
        }), []);
  return React.createElement(ModalWithArtwork.make, {
              artwork: React.createElement(Icons.NewUserIllustration.make, {}),
              noDismiss: true,
              onClose: onClose,
              title: "Welcome to this sandbox workspace for an ecommerce app",
              fixedWidth: 768,
              children: null
            }, React.createElement(Spacer.make, {
                  height: 4
                }), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.2
                        },
                        initial: {
                          opacity: 0,
                          x: 40
                        },
                        exit: {
                          opacity: 0,
                          x: -40
                        },
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.height(Css.pct(100.0)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        children: null
                      }, React.createElement(Spacer.make, {
                            height: 20
                          }), React.createElement($$Text.make, {
                            color: Styles.Color.light10,
                            children: null
                          }, "This is an example of a fully set up Avo workspace. Feel free to poke around and make changes.", React.createElement(Spacer.make, {
                                height: 16
                              }), "Run through the steps in the Getting Started checklist for an overview of what you can do with Avo, or just click around to explore.", React.createElement(Spacer.make, {
                                height: 16
                              }), "Hat tip to our friends at Tasman.ai for the Tracking Plan designs in this sandbox."), React.createElement(Spacer.make, {
                            height: 20,
                            grow: 1.0
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.alignItems("flexEnd"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                label: "Let's go!",
                                onClick: handleLetsGo,
                                size: "large"
                              })))
                }));
}

var make = SandboxWelcomeModal;

export {
  make ,
}
/* Css Not a pure module */
