// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prism from "./externals/Prism.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconArrowDown from "./IconArrowDown.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as CodegenCommons from "../../codegen/src/CodegenCommons.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as PropertyConstraints from "./PropertyConstraints.mjs";
import * as PropertyAbsenceUtils from "../../shared/utils/PropertyAbsenceUtils.mjs";
import * as PropertyAbsenceConfig from "./PropertyAbsenceConfig.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";

var typeStyles = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.regular),
        tl: {
          hd: Css.fontSize(Css.px(11)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(8)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.light02),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: {
                  hd: Css.borderRadius(Css.px(4)),
                  tl: {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.minHeight(Css.px(20)),
                      tl: {
                        hd: Css.lineHeight(Css.em(0.95)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function PropertyType$TypePill(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: typeStyles
            }, children);
}

var TypePill = {
  typeStyles: typeStyles,
  make: PropertyType$TypePill
};

var offsetCodeLine = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(8)),
      tl: {
        hd: Css.fontFamily(Styles.FontFamily.polar),
        tl: /* [] */0
      }
    });

var monoFont = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: /* [] */0
    });

var expandButton = Curry._1(Css.style, {
      hd: Css.backgroundColor(Css.transparent),
      tl: {
        hd: Css.borderWidth(Css.px(0)),
        tl: {
          hd: Css.cursor("pointer"),
          tl: {
            hd: Css.hover({
                  hd: Css.backgroundColor(Styles.Color.light04),
                  tl: /* [] */0
                }),
            tl: {
              hd: Css.borderRadius(Css.px(9)),
              tl: {
                hd: Css.width(Css.px(18)),
                tl: {
                  hd: Css.height(Css.px(18)),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var typeStyles$1 = Curry._1(Css.style, {
      hd: Css.fontFamily(Styles.FontFamily.polar),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.regular),
        tl: {
          hd: Css.fontSize(Css.px(11)),
          tl: {
            hd: Css.padding2(Css.px(4), Css.px(8)),
            tl: {
              hd: Css.paddingRight(Css.px(0)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light02),
                tl: {
                  hd: Css.borderRadius(Css.px(4)),
                  tl: {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.width(Css.pct(100)),
                      tl: {
                        hd: Css.display("flex"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function PropertyType$ObjectPill(Props) {
  var children = Props.children;
  var isList = Props.isList;
  return React.createElement(SimpleTooltip.WithComponent.make, {
              tooltip: (function (param) {
                  return React.createElement(Prism.make, {
                              snippet: Belt_Array.concatMany([
                                      ["{\n"],
                                      Belt_Array.mapU(children, (function (child) {
                                              return "  " + child + ",\n";
                                            })),
                                      [isList ? "}[]" : "}"]
                                    ]).join(""),
                              language: "typescript"
                            });
                }),
              children: React.createElement("span", {
                    className: Curry._1(Css.merge, {
                          hd: typeStyles,
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.gap(Css.px(4)),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.flexWrap("nowrap"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        })
                  }, isList ? "list of objects" : "object", React.createElement("button", {
                        className: expandButton
                      }, React.createElement(IconArrowDown.make, {
                            size: 10,
                            color: Styles.Color.light07
                          })))
            });
}

var ObjectPill = {
  offsetCodeLine: offsetCodeLine,
  monoFont: monoFont,
  expandButton: expandButton,
  typeStyles: typeStyles$1,
  make: PropertyType$ObjectPill
};

function PropertyType$TypeAnnotation(Props) {
  var property = Props.property;
  var properties = Props.properties;
  var rules = Props.rules;
  var language = Props.language;
  var $$event = Props.event;
  var model = ModelStore.Mapped.use(undefined);
  var isObject = property.type_ === "object";
  var validations = property.validations;
  var objectFields = validations ? Belt_List.keepMap(validations, (function (validation) {
            if (typeof validation === "object" && validation.NAME === "NestedProperty") {
              return CodegenCommons.objectPropertyTypeSignature_mapped(validation.VAL, false, language, Caml_option.some(Belt_Option.mapU($$event, (function ($$event) {
                                    return $$event.id;
                                  }))), properties, rules, property.id, model);
            }
            
          })) : /* [] */0;
  if (isObject) {
    return React.createElement(PropertyType$ObjectPill, {
                children: Belt_List.toArray(Belt_List.flatten(objectFields)),
                isList: property.list
              });
  } else {
    return React.createElement(PropertyType$TypePill, {
                children: (
                  property.list ? "list of " : ""
                ) + property.type_
              });
  }
}

var TypeAnnotation = {
  make: PropertyType$TypeAnnotation
};

function PropertyType(Props) {
  var property = Props.property;
  var language = Props.language;
  var $$event = Props.event;
  var skipConstraintsOpt = Props.skipConstraints;
  var variant = Props.variant;
  var skipConstraints = skipConstraintsOpt !== undefined ? skipConstraintsOpt : false;
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var sources = ModelStore.useSources(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var properties = ModelStore.Mapped.useProperties(undefined);
  var propertyBundles = ModelStore.Mapped.usePropertyBundles(undefined);
  var rules = ModelStore.Mapped.useRules(undefined);
  var match = property.type_;
  var match$1 = property.eventSpecificAllowedPropertyValues;
  var match$2 = property.validations;
  var hasPropertyConstraints = match === "string" ? (
      Caml_obj.notequal(match$1, []) || match$2 ? true : false
    ) : (
      match$2 ? true : false
    );
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement(PropertyType$TypeAnnotation, {
                  property: property,
                  properties: properties,
                  rules: rules,
                  language: Belt_Option.getWithDefault(language, "js"),
                  event: $$event
                }), skipConstraints || !hasPropertyConstraints ? null : React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginLeft(Css.px(4)),
                          tl: {
                            hd: Css.marginRight(Css.px(4)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(PropertyConstraints.make, {
                        property: property,
                        event: $$event,
                        variant: variant
                      })), Belt_Option.mapWithDefault(property.absence, null, (function (absence) {
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("inlineFlex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.flexShrink(0.0),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement($$Text.make, {
                                    element: "Span",
                                    size: "Small",
                                    weight: "Regular",
                                    color: Styles.Color.light10,
                                    children: $$event !== undefined ? PropertyAbsenceConfig.absenceModeToLabel(isGlobalWorkspace, PropertyAbsenceUtils.getSimplestAbsenceModeByEvent(absence, sources, $$event, property.sendAs)) : Belt_Option.getWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString_mapped(events, propertyBundles, sources, undefined, property), "Always sent")
                                  }));
                  })));
}

var make = PropertyType;

export {
  TypePill ,
  ObjectPill ,
  TypeAnnotation ,
  make ,
}
/* typeStyles Not a pure module */
