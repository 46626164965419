// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Guides from "./markdown/guides.mjs";
import * as Styles from "./styles.mjs";
import * as Markdown from "./Markdown.mjs";
import * as IconArrowUp from "./IconArrowUp.mjs";
import * as IconArrowDown from "./IconArrowDown.mjs";

function ImplementCLI(Props) {
  var match = React.useState(function () {
        return true;
      });
  var setCollapsed = match[1];
  var collapsed = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.marginTop(Css.px(12)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("button", {
                  className: Curry._1(Css.merge, {
                        hd: Curry._1(Css.style, Styles.button),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.padding(Css.px(12)),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: {
                                        hd: Css.textAlign("left"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      }),
                  onClick: (function (param) {
                      Curry._1(setCollapsed, (function (collapsed) {
                              return !collapsed;
                            }));
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.margin(Css.px(0)),
                                tl: {
                                  hd: Css.width(Css.pct(100)),
                                  tl: {
                                    hd: Css.color(Styles.Color.light12),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          children: "Pull the Avo file with the Avo CLI"
                        })), collapsed ? React.createElement(IconArrowDown.make, {
                        size: 12,
                        color: Styles.Color.light10
                      }) : React.createElement(IconArrowUp.make, {
                        size: 12,
                        color: Styles.Color.light10
                      })), collapsed ? null : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.fontSize(Styles.FontSize.regular),
                          tl: {
                            hd: Css.padding2(Css.px(0), Css.px(15)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(Markdown.make, {
                        source: Guides.setup
                      })));
}

var make = ImplementCLI;

export {
  make ,
}
/* Css Not a pure module */
