// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as IconLightning from "./IconLightning.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";

function PlanLightning(Props) {
  var tooltipText = Props.tooltipText;
  var tooltipDirectionOpt = Props.tooltipDirection;
  var tooltipDirection = tooltipDirectionOpt !== undefined ? tooltipDirectionOpt : "bottom";
  return React.createElement(SimpleTooltip.make, {
              position: tooltipDirection,
              tooltip: tooltipText,
              children: React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(1)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconLightning.make, {
                        size: 12
                      }))
            });
}

var make = PlanLightning;

export {
  make ,
}
/* Css Not a pure module */
