// Generated by ReScript, PLEASE EDIT WITH CARE


var toLocaleString = (x => x.toLocaleString());

function getRoughPercentageString(percentage) {
  if (percentage !== 0.0) {
    if (percentage < 0.001) {
      return "< 0.001";
    } else if (percentage < 0.01) {
      return percentage.toFixed(3);
    } else if (percentage < 0.1) {
      return percentage.toFixed(2);
    } else if (percentage !== 100.0) {
      return percentage.toFixed(1);
    } else {
      return "100";
    }
  } else {
    return "0";
  }
}

function getPercentageString(percentage) {
  if (percentage !== 0.0) {
    if (percentage < 0.0000001) {
      return percentage.toFixed(9);
    } else if (percentage < 0.000001) {
      return percentage.toFixed(8);
    } else if (percentage < 0.00001) {
      return percentage.toFixed(7);
    } else if (percentage < 0.0001) {
      return percentage.toFixed(6);
    } else if (percentage < 0.001) {
      return percentage.toFixed(5);
    } else if (percentage < 0.01) {
      return percentage.toFixed(4);
    } else if (percentage < 0.1) {
      return percentage.toFixed(2);
    } else if (percentage !== 100.0) {
      return percentage.toFixed(1);
    } else {
      return "100";
    }
  } else {
    return "0";
  }
}

function shortFormat(number) {
  if (number < 1000) {
    return String(number);
  } else if (number < 10000) {
    return (number / 1000).toPrecision(2) + "K";
  } else if (number < 1000000) {
    return (number / 1000).toFixed(0) + "K";
  } else if (number < 10000000) {
    return (number / 1000000).toPrecision(2) + "M";
  } else if (number < 100000000) {
    return (number / 1000000).toPrecision(3) + "M";
  } else {
    return (number / 1000000).toFixed(0) + "M";
  }
}

export {
  toLocaleString ,
  getRoughPercentageString ,
  getPercentageString ,
  shortFormat ,
}
/* No side effect */
