// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactConfetti from "react-confetti";

var make = ReactConfetti;

export {
  make ,
}
/* make Not a pure module */
