// Generated by ReScript, PLEASE EDIT WITH CARE

import TagsMd from "./tags.md";
import SourcesMd from "./sources.md";
import CategoriesMd from "./categories.md";
import EventTypesMd from "./eventTypes.md";
import TrackEventMd from "./trackEvent.md";
import UnidentifyMd from "./unidentify.md";
import EventSendAsMd from "./eventSendAs.md";
import ScreenshotsMd from "./screenshots.md";
import CodeSnippetsMd from "./codeSnippets.md";
import DestinationsMd from "./destinations.md";
import IdentifyUserMd from "./identifyUser.md";
import SentInEventsMd from "./sentInEvents.md";
import TrackRevenueMd from "./trackRevenue.md";
import TrackingCodeMd from "./trackingCode.md";
import UpdateGroupsMd from "./updateGroups.md";
import GetTheAvoFileMd from "./getTheAvoFile.md";
import SegmentConfigMd from "./segmentConfig.md";
import TrackPageViewMd from "./trackPageView.md";
import ExcludeSourcesMd from "./excludeSources.md";
import PropertySendAsMd from "./propertySendAs.md";
import RelatedMetricsMd from "./relatedMetrics.md";
import UserPropertiesMd from "./userProperties.md";
import AddUserToGroupsMd from "./addUserToGroups.md";
import EventPropertiesMd from "./eventProperties.md";
import GroupPropertiesMd from "./groupProperties.md";
import OptionalApiKeysMd from "./optionalApiKeys.md";
import TrackingMethodsMd from "./trackingMethods.md";
import EventDescriptionMd from "./eventDescription.md";
import SystemPropertiesMd from "./systemProperties.md";
import PropertyValueTypeMd from "./propertyValueType.md";
import PropertyValueRulesMd from "./propertyValueRules.md";
import AutomaticPropertiesMd from "./automaticProperties.md";
import PropertyDescriptionMd from "./propertyDescription.md";
import UpdateUserPropertiesMd from "./updateUserProperties.md";
import CodeChangesEventVariantMd from "./codeChangesEventVariant.md";

var eventProperties = EventPropertiesMd;

var userProperties = UserPropertiesMd;

var systemProperties = SystemPropertiesMd;

var sources = SourcesMd;

var destinations = DestinationsMd;

var eventTypes = EventTypesMd;

var automaticProperties = AutomaticPropertiesMd;

var excludeSources = ExcludeSourcesMd;

var segmentConfig = SegmentConfigMd;

var optionalApiKeys = OptionalApiKeysMd;

var eventDescription = EventDescriptionMd;

var screenshots = ScreenshotsMd;

var trackingMethods = TrackingMethodsMd;

var categories = CategoriesMd;

var tags = TagsMd;

var eventSendAs = EventSendAsMd;

var updateGroups = UpdateGroupsMd;

var addUserToGroups = AddUserToGroupsMd;

var groupProperties = GroupPropertiesMd;

var identifyUser = IdentifyUserMd;

var updateUserProperties = UpdateUserPropertiesMd;

var trackEvent = TrackEventMd;

var trackRevenue = TrackRevenueMd;

var trackPageView = TrackPageViewMd;

var propertyDescription = PropertyDescriptionMd;

var propertyValueType = PropertyValueTypeMd;

var sentInEvents = SentInEventsMd;

var propertyValueRules = PropertyValueRulesMd;

var propertySendAs = PropertySendAsMd;

var relatedMetrics = RelatedMetricsMd;

var unidentify = UnidentifyMd;

var trackingCode = TrackingCodeMd;

var codeSnippets = CodeSnippetsMd;

var getTheAvoFile = GetTheAvoFileMd;

var codeChangesEventVariant = CodeChangesEventVariantMd;

export {
  eventProperties ,
  userProperties ,
  systemProperties ,
  sources ,
  destinations ,
  eventTypes ,
  automaticProperties ,
  excludeSources ,
  segmentConfig ,
  optionalApiKeys ,
  eventDescription ,
  screenshots ,
  trackingMethods ,
  categories ,
  tags ,
  eventSendAs ,
  updateGroups ,
  addUserToGroups ,
  groupProperties ,
  identifyUser ,
  updateUserProperties ,
  trackEvent ,
  trackRevenue ,
  trackPageView ,
  propertyDescription ,
  propertyValueType ,
  sentInEvents ,
  propertyValueRules ,
  propertySendAs ,
  relatedMetrics ,
  unidentify ,
  trackingCode ,
  codeSnippets ,
  getTheAvoFile ,
  codeChangesEventVariant ,
}
/* eventProperties Not a pure module */
