// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as CodegenRules from "../../codegen/src/CodegenRules.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PinPropertyValueModalTypeInput from "./PinPropertyValueModalTypeInput.mjs";

function PinPropertyValueModal(Props) {
  var model = Props.model;
  var eventId = Props.eventId;
  var propertyId = Props.propertyId;
  var onClose = Props.onClose;
  var maybeVariantId = Props.maybeVariantId;
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = ModelUtils.getPropertyById(propertyId, model);
  var maybeProperty = match !== undefined && match.TAG !== /* PropertyRef */0 ? match._0 : undefined;
  var maybeEvent = ModelUtils.getEventById(eventId, model);
  var maybeVariant = maybeVariantId !== undefined && maybeEvent !== undefined ? Belt_Array.getBy(maybeEvent.variants, (function (variant) {
            return variant.id === maybeVariantId;
          })) : undefined;
  var maybeVariantPropertyOverride = Belt_Option.flatMap(maybeVariant, (function (variant) {
          return Belt_MapString.get(variant.propertyOverrides, propertyId);
        }));
  var match$1 = React.useState(function () {
        
      });
  var setSelectedChildProperty = match$1[1];
  var selectedChildProperty = match$1[0];
  var maybePinnedNestedPropertyRule = Belt_List.getByU(model.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return false;
          }
          if (match.NAME !== "EventObjectField") {
            return false;
          }
          if (typeof match$1 !== "object") {
            return false;
          }
          if (match$1.NAME !== "PinnedValue") {
            return false;
          }
          var match$2 = match.VAL;
          if (match$2[0] === eventId && match$2[1] === propertyId) {
            return Caml_obj.equal(match$2[2], selectedChildProperty);
          } else {
            return false;
          }
        }));
  var getInitialPinnedValue = function (param) {
    var exit = 0;
    if (maybeProperty === undefined) {
      return ;
    }
    if (maybeEvent === undefined) {
      return ;
    }
    if (maybeVariantPropertyOverride !== undefined && maybeVariantPropertyOverride) {
      var maybeLiteral = maybeVariantPropertyOverride._0.pinnedValue;
      if (maybeLiteral) {
        return maybeLiteral._0;
      }
      exit = 1;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var exit$1 = 0;
      if (maybeProperty.type_ === "object") {
        if (selectedChildProperty !== undefined) {
          return CodegenRules.getEventSpecificNestedPropertyPinnedValue(selectedChildProperty, maybeProperty.id, maybeEvent.id, model);
        }
        exit$1 = 2;
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        return Belt_Option.flatMap(ModelUtils.getPropertyRefById(eventId, propertyId, model), (function (propRef) {
                      return propRef.pinnedValue;
                    }));
      }
      
    }
    
  };
  var match$2 = React.useState(function () {
        return getInitialPinnedValue(undefined);
      });
  var setPinnedValue = match$2[1];
  var pinnedValue = match$2[0];
  React.useEffect((function () {
          Curry._1(setPinnedValue, (function (param) {
                  return getInitialPinnedValue(undefined);
                }));
        }), [selectedChildProperty]);
  return Belt_Option.mapWithDefault(maybeProperty, null, (function (property) {
                var match = ModelUtils.getPropertyRefById(eventId, propertyId, model);
                var match$1 = ModelUtils.getEventById(eventId, model);
                var tmp;
                if (match$1 !== undefined) {
                  var eventNameWithVariant = maybeVariant !== undefined ? "" + match$1.name + " - " + maybeVariant.nameSuffix + "" : match$1.name;
                  var handlePinnedValueChange = function (param) {
                    var context = {
                      eventId: eventId,
                      eventQuery: eventId,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    };
                    if (Belt_Option.isSome(pinnedValue) && Belt_Option.isNone(maybeVariant) && Belt_Option.isNone(match)) {
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [[
                                {
                                  NAME: "AddPropertyRef",
                                  VAL: [
                                    eventId,
                                    propertyId
                                  ]
                                },
                                context
                              ]]
                          ]);
                    }
                    if (pinnedValue !== undefined) {
                      if (selectedChildProperty !== undefined) {
                        if (maybePinnedNestedPropertyRule !== undefined) {
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "UpdateRule",
                                      VAL: [
                                        maybePinnedNestedPropertyRule.id,
                                        maybePinnedNestedPropertyRule.item,
                                        {
                                          NAME: "PinnedValue",
                                          VAL: pinnedValue
                                        }
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                        } else {
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "AddRule",
                                      VAL: [
                                        Nanoid.nanoid(),
                                        {
                                          NAME: "EventObjectField",
                                          VAL: [
                                            eventId,
                                            propertyId,
                                            selectedChildProperty
                                          ]
                                        },
                                        {
                                          NAME: "PinnedValue",
                                          VAL: pinnedValue
                                        }
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                        }
                      } else if (maybeVariant !== undefined) {
                        var tmp = {
                          eventId: maybeVariant.baseEventId,
                          eventQuery: maybeVariant.baseEventId,
                          propertyId: propertyId,
                          propertyQuery: propertyId
                        };
                        if (maybeVariantId !== undefined) {
                          tmp.eventVariantId = Caml_option.valFromOption(maybeVariantId);
                        }
                        if (maybeVariantId !== undefined) {
                          tmp.eventVariantQuery = Caml_option.valFromOption(maybeVariantId);
                        }
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (branch) {
                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                  AnalyticsRe.eventVariantUpdatedProperty(maybeVariant.baseEventId, match$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "UpdatePropertyPinnedValue", maybeVariant.id, property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), maybeVariant.baseEventId);
                                }),
                              undefined,
                              [[
                                  maybeVariant !== undefined ? ({
                                        NAME: "UpdatePropertyPinnedValueOnEventVariant",
                                        VAL: [
                                          TrackingPlanModel.EventVariant.makeVariantIdentifier(maybeVariant),
                                          propertyId,
                                          pinnedValue
                                        ]
                                      }) : ({
                                        NAME: "UpdatePropertyPinnedValue",
                                        VAL: [
                                          eventId,
                                          propertyId,
                                          pinnedValue
                                        ]
                                      }),
                                  tmp
                                ]]
                            ]);
                      } else {
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [[
                                  {
                                    NAME: "UpdatePropertyPinnedValue",
                                    VAL: [
                                      eventId,
                                      propertyId,
                                      pinnedValue
                                    ]
                                  },
                                  context
                                ]]
                            ]);
                      }
                    } else if (selectedChildProperty !== undefined) {
                      if (maybePinnedNestedPropertyRule !== undefined) {
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [[
                                  {
                                    NAME: "RemoveRule",
                                    VAL: [
                                      maybePinnedNestedPropertyRule.id,
                                      maybePinnedNestedPropertyRule.item
                                    ]
                                  },
                                  context
                                ]]
                            ]);
                      }
                      
                    } else if (maybeVariant !== undefined) {
                      var tmp$1 = {
                        eventId: maybeVariant.baseEventId,
                        eventQuery: maybeVariant.baseEventId,
                        propertyId: propertyId,
                        propertyQuery: propertyId
                      };
                      if (maybeVariantId !== undefined) {
                        tmp$1.eventVariantId = Caml_option.valFromOption(maybeVariantId);
                      }
                      if (maybeVariantId !== undefined) {
                        tmp$1.eventVariantQuery = Caml_option.valFromOption(maybeVariantId);
                      }
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                AnalyticsRe.eventVariantUpdatedProperty(maybeVariant.baseEventId, match$1.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemovePropertyPinnedValue", maybeVariant.id, property.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), maybeVariant.baseEventId);
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "ClearPropertyPinnedValueFromEventVariant",
                                  VAL: [
                                    TrackingPlanModel.EventVariant.makeVariantIdentifier(maybeVariant),
                                    propertyId
                                  ]
                                },
                                tmp$1
                              ]]
                          ]);
                    } else {
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [[
                                {
                                  NAME: "RemovePropertyPinnedValue",
                                  VAL: [
                                    eventId,
                                    propertyId
                                  ]
                                },
                                context
                              ]]
                          ]);
                    }
                    Curry._1(onClose, undefined);
                  };
                  var handleEnter = function (param) {
                    if (Caml_obj.notequal(getInitialPinnedValue(undefined), pinnedValue)) {
                      return handlePinnedValueChange(undefined);
                    }
                    
                  };
                  var type_ = property.type_;
                  var tmp$1;
                  var exit = 0;
                  switch (type_) {
                    case "object" :
                        var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(property.validations, (function (validation) {
                                        if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                                          return validation.VAL;
                                        }
                                        
                                      }))), /* [] */0);
                        var selectedProperty = Belt_Option.flatMap(selectedChildProperty, (function (propertyId) {
                                return ModelUtils.getPropertyById(propertyId, model);
                              }));
                        if (propertyRefs) {
                          var childProperties = Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                                  var property = ModelUtils.getPropertyById(propertyRef.id, model);
                                  if (property !== undefined && property.TAG !== /* PropertyRef */0) {
                                    return property._0;
                                  }
                                  
                                }));
                          var tmp$2;
                          tmp$2 = selectedProperty !== undefined && selectedProperty.TAG !== /* PropertyRef */0 ? React.createElement(PinPropertyValueModalTypeInput.make, {
                                  pinnedValue: pinnedValue,
                                  onChange: setPinnedValue,
                                  property: selectedProperty._0,
                                  model: model,
                                  onEnter: handleEnter,
                                  event: match$1
                                }) : null;
                          tmp$1 = React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.createElement(Select.make, {
                                    onSelect: (function (propertyId) {
                                        Curry._1(setSelectedChildProperty, (function (param) {
                                                return propertyId;
                                              }));
                                      }),
                                    onEnter: handleEnter,
                                    options: Belt_List.toArray(Belt_List.add(Belt_List.map(childProperties, (function (property) {
                                                    return [
                                                            {
                                                              NAME: "Label",
                                                              VAL: property.name
                                                            },
                                                            property.id
                                                          ];
                                                  })), [
                                              {
                                                NAME: "Label",
                                                VAL: "Pick a key to pin..."
                                              },
                                              "none"
                                            ])),
                                    value: Belt_Option.getWithDefault(selectedChildProperty, "none")
                                  }), React.createElement(Spacer.make, {
                                    width: 8
                                  }), tmp$2);
                        } else {
                          tmp$1 = "No child properties defined for this object";
                        }
                        break;
                    case "bool" :
                    case "float" :
                    case "int" :
                    case "string" :
                        exit = 1;
                        break;
                    default:
                      tmp$1 = "Cannot pin property of type " + type_ + "";
                  }
                  if (exit === 1) {
                    tmp$1 = React.createElement(PinPropertyValueModalTypeInput.make, {
                          pinnedValue: pinnedValue,
                          onChange: setPinnedValue,
                          property: property,
                          model: model,
                          onEnter: handleEnter,
                          event: match$1
                        });
                  }
                  tmp = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            color: Styles.Color.light12,
                            children: React.createElement(Markdown.make, {
                                  source: "When the value of the property **" + property.name + "** is pinned it will always be sent as the pinned value with the **" + eventNameWithVariant + "** event. Developers will not be required to pass in value for the property when the event is sent using Codegen.\n          \nAlways send **" + property.name + "** with **" + eventNameWithVariant + "** as:"
                                })
                          }), React.createElement(Spacer.make, {
                            height: 8
                          }), tmp$1, React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.justifyContent("flexEnd"),
                                    tl: {
                                      hd: Css.gap(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(Button.make, {
                                label: "Cancel",
                                onClick: (function (param) {
                                    Curry._1(onClose, undefined);
                                  }),
                                style: "ghost"
                              }), Caml_obj.notequal(getInitialPinnedValue(undefined), pinnedValue) ? React.createElement(Button.make, {
                                  label: Belt_Option.isNone(pinnedValue) ? "Remove Pinned Value" : "Set Pinned Value",
                                  onClick: handlePinnedValueChange
                                }) : null));
                } else {
                  tmp = match !== undefined ? React.createElement($$Text.make, {
                          weight: "Semi",
                          color: Styles.Color.darkRed,
                          children: "Event not found."
                        }) : React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.darkRed,
                          children: "Property not found on event."
                        });
                }
                return React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.width(Css.px(500)),
                                    tl: {
                                      hd: Css.maxWidth(Css.pct(100)),
                                      tl: {
                                        hd: Css.padding(Css.px(40)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.white),
                                          tl: {
                                            hd: Css.borderRadius(Css.px(10)),
                                            tl: {
                                              hd: Css.cursor("default"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement(Title.make, {
                                children: "Pin Property Value",
                                size: "Small"
                              }), React.createElement(Spacer.make, {
                                height: 16
                              }), tmp);
              }));
}

var make = PinPropertyValueModal;

export {
  make ,
}
/* Css Not a pure module */
