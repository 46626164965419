// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function parseSeenVersions(json) {
  return Json_decode.array((function (json) {
                return {
                        version: Json_decode.field("version", Json_decode.string, json),
                        date: Json_decode.field("date", Json_decode.string, json)
                      };
              }), json);
}

function parseSeenPeriod(json) {
  return {
          firstSeen: Json_decode.optional((function (param) {
                  return Json_decode.field("firstSeen", Json_decode.string, param);
                }), json),
          lastSeen: Json_decode.optional((function (param) {
                  return Json_decode.field("lastSeen", Json_decode.string, param);
                }), json),
          versions: Json_decode.field("versions", parseSeenVersions, json),
          numVersions: Json_decode.field("numVersions", Json_decode.$$int, json)
        };
}

function parseSeen(json) {
  return {
          current: Json_decode.field("current", parseSeenPeriod, json),
          comparison: Json_decode.field("comparison", parseSeenPeriod, json),
          history: Json_decode.field("history", parseSeenPeriod, json)
        };
}

function parsePeriod(json) {
  return {
          numVersions: Json_decode.field("numVersions", Json_decode.$$int, json),
          total: Json_decode.field("total", Json_decode.$$int, json),
          versions: Json_decode.field("versions", (function (param) {
                  return Json_decode.array((function (json) {
                                return {
                                        version: Json_decode.field("version", Json_decode.string, json),
                                        count: Json_decode.field("count", Json_decode.$$int, json)
                                      };
                              }), param);
                }), json)
        };
}

function parseCount(json) {
  return {
          comparison: Json_decode.field("comparison", parsePeriod, json),
          current: Json_decode.field("current", parsePeriod, json),
          history: Json_decode.field("history", parsePeriod, json)
        };
}

function parsePropertyTypes(json) {
  return Json_decode.array((function (json) {
                return {
                        type_: Json_decode.field("type", Json_decode.string, json),
                        count: Json_decode.field("count", parseCount, json),
                        firstSeen: Json_decode.field("firstSeen", parseSeen, json),
                        lastSeen: Json_decode.field("lastSeen", parseSeen, json)
                      };
              }), json);
}

function parsePresences(json) {
  return Json_decode.array((function (json) {
                return {
                        presence: Json_decode.field("presence", Json_decode.string, json),
                        count: Json_decode.field("count", parseCount, json),
                        firstSeen: Json_decode.field("firstSeen", parseSeen, json),
                        lastSeen: Json_decode.field("lastSeen", parseSeen, json)
                      };
              }), json);
}

function parseSource(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          lastSeen: Json_decode.field("lastSeen", parseSeen, json),
          count: Json_decode.field("count", parseCount, json),
          trackingPlanStatus: undefined
        };
}

function parseEvent(json) {
  return {
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          count: Json_decode.field("count", parseCount, json),
          properties: Json_decode.field("properties", (function (param) {
                  return Json_decode.array((function (propertyJson) {
                                return {
                                        propertyName: Json_decode.field("propertyName", Json_decode.string, propertyJson),
                                        sources: Json_decode.field("sources", (function (param) {
                                                return Json_decode.array((function (json) {
                                                              return {
                                                                      sourceId: Json_decode.field("sourceId", Json_decode.string, json),
                                                                      propertyTypes: Json_decode.field("propertyTypes", parsePropertyTypes, json),
                                                                      issues: Belt_Array.keepMap(Json_decode.field("issues", (function (json) {
                                                                                  return Json_decode.array((function (json) {
                                                                                                var other = Json_decode.field("issueType", Json_decode.string, json);
                                                                                                switch (other) {
                                                                                                  case "inconsistent-type" :
                                                                                                      return {
                                                                                                              NAME: "InconsistentType",
                                                                                                              VAL: {
                                                                                                                issueType: Json_decode.field("issueType", Json_decode.string, json),
                                                                                                                object_: "property",
                                                                                                                propertyName: Json_decode.field("propertyName", Json_decode.string, propertyJson),
                                                                                                                types: Json_decode.field("types", parsePropertyTypes, json)
                                                                                                              }
                                                                                                            };
                                                                                                  case "sometimes-missing" :
                                                                                                      return {
                                                                                                              NAME: "SometimesMissing",
                                                                                                              VAL: {
                                                                                                                issueType: Json_decode.field("issueType", Json_decode.string, json),
                                                                                                                object_: "property",
                                                                                                                propertyName: Json_decode.field("propertyName", Json_decode.string, propertyJson),
                                                                                                                presences: Json_decode.field("presences", parsePresences, json)
                                                                                                              }
                                                                                                            };
                                                                                                  default:
                                                                                                    console.log("Unsupported property issue", other);
                                                                                                    return ;
                                                                                                }
                                                                                              }), json);
                                                                                }), json), (function (a) {
                                                                              return a;
                                                                            }))
                                                                    };
                                                            }), param);
                                              }), propertyJson)
                                      };
                              }), param);
                }), json),
          sources: Json_decode.field("sources", (function (param) {
                  return Json_decode.array(parseSource, param);
                }), json)
        };
}

function parseResponse(json) {
  return {
          results: Json_decode.field("results", (function (json) {
                  return {
                          events: Json_decode.field("events", (function (param) {
                                  return Json_decode.array(parseEvent, param);
                                }), json),
                          overview: Json_decode.optional((function (param) {
                                  return Json_decode.field("overview", (function (param) {
                                                return Json_decode.array(Json_decode.string, param);
                                              }), param);
                                }), json),
                          timeWindowEnd: Json_decode.optional((function (param) {
                                  return Json_decode.field("timeWindowEnd", Json_decode.string, param);
                                }), json)
                        };
                }), json)
        };
}

function decodeEnvStats(json) {
  return {
          env: Json_decode.field("env", Json_decode.string, json),
          lastSeen: new Date(Json_decode.field("lastSeen", Json_decode.string, json))
        };
}

function encodeEnvStats(envStats) {
  return Json_encode.object_({
              hd: [
                "env",
                envStats.env
              ],
              tl: {
                hd: [
                  "lastSeen",
                  Json_encode.date(envStats.lastSeen)
                ],
                tl: /* [] */0
              }
            });
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                t.sourceId
              ],
              tl: {
                hd: [
                  "envs",
                  Json_encode.array(encodeEnvStats, t.envs)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          envs: Json_decode.field("envs", (function (param) {
                  return Json_decode.array(decodeEnvStats, param);
                }), json)
        };
}

var InspectorStats = {
  decodeEnvStats: decodeEnvStats,
  encodeEnvStats: encodeEnvStats,
  encode: encode,
  decode: decode
};

var _map = {"Development":"Development","Staging":"Staging","Production":"Production"};

function environmentToJs(param) {
  return param;
}

function environmentFromJs(param) {
  return _map[param];
}

var _map$1 = {"Last1Hour":"Last1Hour","Last24Hours":"Last24Hours","Last7Days":"Last7Days","Last30Days":"Last30Days"};

function timeWindowToJs(param) {
  return param;
}

function timeWindowFromJs(param) {
  return _map$1[param];
}

var timeWindowLadder = [
  "Last1Hour",
  "Last24Hours",
  "Last7Days",
  "Last30Days"
];

var _map$2 = {"EventName":"EventName","EventVolume":"EventVolume","Issues":"Issues","SourceEventVolume":"SourceEventVolume","SourceIssues":"SourceIssues"};

function orderByToJs(param) {
  return param;
}

function orderByFromJs(param) {
  return _map$2[param];
}

var _map$3 = {"Ascending":"Ascending","Descending":"Descending"};

function orderToJs(param) {
  return param;
}

function orderFromJs(param) {
  return _map$3[param];
}

export {
  parseSeenVersions ,
  parseSeenPeriod ,
  parseSeen ,
  parsePeriod ,
  parseCount ,
  parsePropertyTypes ,
  parsePresences ,
  parseSource ,
  parseEvent ,
  parseResponse ,
  InspectorStats ,
  environmentToJs ,
  environmentFromJs ,
  timeWindowToJs ,
  timeWindowFromJs ,
  timeWindowLadder ,
  orderByToJs ,
  orderByFromJs ,
  orderToJs ,
  orderFromJs ,
}
/* No side effect */
