// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MappedModules from "../../../shared/MappedModules.mjs";

var RichText = {};

var Field = {
  RichText: RichText
};

function getId(field) {
  return field.id;
}

function eq(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

var Fields = MappedModules.MakeMapModule({
      getId: getId,
      eq: eq
    });

function goForward(key, target) {
  return {
          key: key,
          target: target,
          advancing: true
        };
}

function goBack(key, target) {
  return {
          key: key,
          target: target,
          advancing: false
        };
}

function next(target) {
  return {
          key: "next",
          target: target,
          advancing: true
        };
}

function previous(target) {
  return {
          key: "previous",
          target: target,
          advancing: false
        };
}

function complete(param) {
  return {
          key: "complete",
          target: "complete",
          advancing: true
        };
}

function isAdvancing(transition) {
  return transition.advancing;
}

function keyAdvances(transitions, transitionKey) {
  return Belt_Option.mapWithDefault(Belt_Array.getBy(transitions, (function (param) {
                    return param.key === transitionKey;
                  })), false, isAdvancing);
}

var Transition = {
  goForward: goForward,
  goBack: goBack,
  next: next,
  previous: previous,
  complete: complete,
  isAdvancing: isAdvancing,
  keyAdvances: keyAdvances
};

var Block = {
  Transition: Transition
};

function getId$1(block) {
  return block.id;
}

function eq$1(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

var Blocks = MappedModules.MakeMapModule({
      getId: getId$1,
      eq: eq$1
    });

var Engagement = {};

function getId$2(engagement) {
  return engagement.id;
}

function eq$2(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

var Engagements = MappedModules.MakeMapModule({
      getId: getId$2,
      eq: eq$2
    });

var State = {};

export {
  Field ,
  Fields ,
  Block ,
  Blocks ,
  Engagement ,
  Engagements ,
  State ,
}
/* Fields Not a pure module */
