// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "../Icon.mjs";
import * as Link from "../Link.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "../IconX.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as AvoUtils from "../../../shared/utils/AvoUtils.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "../NameInput.mjs";
import * as RoleUtils from "../RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "../ContextMenu.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as CommentInput from "../CommentInput.mjs";
import * as DrawerHeader from "./DrawerHeader.mjs";
import * as OpsWithState from "../OpsWithState.mjs";
import * as PropertyType from "../PropertyType.mjs";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as PropertyInput from "../PropertyInput.mjs";
import * as SimpleTooltip from "../SimpleTooltip.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as IconDescription from "../IconDescription.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as ValidationConfigV2 from "../guardrails/ValidationConfigV2.mjs";
import * as OrganizationContext from "../OrganizationContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import ReactTextareaAutosize from "react-textarea-autosize";

function PropertyBundleDetails(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var $$event = Props.event;
  var goToCommentId = Props.goToCommentId;
  var bundleId = Props.bundleId;
  var member = Props.member;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var model = ModelStore.Mapped.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var bundle = Curry._2(TrackingPlanMappedModel.PropertyBundles.getBy, model.propertyBundles, (function (param) {
          return param.id === bundleId;
        }));
  var organization = OrganizationContext.useOrganization(undefined);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var globalPropertyBundle = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalPropertyBundle, bundleId);
  var tmp;
  if (bundle !== undefined) {
    var bundleProperties = Belt_List.toArray(Belt_List.sort(Belt_List.keepMapU(bundle.properties, (function (id) {
                    return Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, id);
                  })), (function (a, b) {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                } else {
                  return 0;
                }
              })));
    var bundleEvents = Curry._2(TrackingPlanMappedModel.Events.keep, model.events, (function ($$event) {
            return Belt_List.some($$event.propertyBundles, (function (bundle) {
                          return bundle.id === bundleId;
                        }));
          }));
    var filter = member.filter;
    var bundleEvents$1 = filter !== undefined ? Curry._2(TrackingPlanMappedModel.Events.keep, bundleEvents, (function ($$event) {
              return Belt_List.some($$event.tags, (function (item) {
                            return Belt_Array.some(filter.tags, (function (tag) {
                                          return item === tag;
                                        }));
                          }));
            })) : bundleEvents;
    var tmp$1 = {
      propertyGroupId: bundle.id,
      propertyGroupQuery: bundle.id
    };
    var tmp$2 = Belt_Option.map($$event, (function ($$event) {
            return $$event.id;
          }));
    if (tmp$2 !== undefined) {
      tmp$1.eventId = Caml_option.valFromOption(tmp$2);
    }
    var context = tmp$1;
    var handleArchivePropertyBundle = function (param) {
      var match = Router.getStaticRoute(undefined);
      var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
              if (typeof item === "object" && item.NAME === "propertyGroup") {
                return item.VAL[0] !== bundle.id;
              } else {
                return true;
              }
            }));
      Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
      Curry.app(sendActions, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            (function (branch) {
                Curry._1(addToast, {
                      message: "The property bundle has been archived"
                    });
                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                AnalyticsRe.propertyGroupArchived(bundle.id, bundle.name, Belt_List.length(bundle.properties), schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, NamedBranch.getId(branch), schemaBundle$1.schemaId);
              }),
            undefined,
            [[
                {
                  NAME: "Archive",
                  VAL: {
                    NAME: "PropertyGroup",
                    VAL: bundle.id
                  }
                },
                context
              ]]
          ]);
    };
    var tmp$3;
    if (organization !== undefined) {
      var match$1 = organization.globalWorkspaceIds;
      if (match$1.length !== 1) {
        tmp$3 = null;
      } else {
        var id = match$1[0];
        tmp$3 = globalPropertyBundle !== undefined ? React.createElement("section", {
                className: Curry._1(Css.style, {
                      hd: Css.margin(Css.px(16)),
                      tl: {
                        hd: Css.marginBottom(Css.px(0)),
                        tl: {
                          hd: Css.padding(Css.px(16)),
                          tl: {
                            hd: Css.borderRadius(Css.px(8)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.5)),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.gap(Css.px(10)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", undefined, React.createElement(IconDescription.make, {
                        fill: Styles.Color.blue
                      })), React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.gap(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.deepBlue,
                            children: "Global property bundle"
                          }), React.createElement(SimpleTooltip.make, {
                            maxWidth: 240,
                            tooltip: "This is a globally defined property bundle so editing is limited",
                            children: React.createElement(Icon.make, {
                                  type_: "information",
                                  size: "medium",
                                  color: Styles.Color.light09
                                })
                          })), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement(Link.make, {
                        path: Curry._4(Router.Link.get, {
                              NAME: "schema",
                              VAL: id
                            }, undefined, "events", {
                              hd: {
                                NAME: "propertyGroup",
                                VAL: [
                                  bundleId,
                                  undefined
                                ]
                              },
                              tl: /* [] */0
                            }),
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.color(Styles.Color.deepBlue),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.textDecoration("underline"),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        children: React.createElement($$Text.make, {
                              children: "View in global workspace"
                            })
                      }))) : null;
      }
    } else {
      tmp$3 = null;
    }
    var template = schema.onboardingTemplate;
    var events = Curry._1(TrackingPlanMappedModel.Events.toArray, bundleEvents$1);
    var tmp$4 = {
      object_: {
        NAME: "PropertyGroup",
        VAL: bundle
      },
      objectId: bundle.id,
      currentBranch: currentBranch,
      openBranches: openBranches
    };
    if ($$event !== undefined) {
      tmp$4.event = Caml_option.valFromOption($$event);
    }
    if (goToCommentId !== undefined) {
      tmp$4.goToCommentId = Caml_option.valFromOption(goToCommentId);
    }
    var tmp$5 = {
      threadId: bundle.id,
      itemType: "PropertyGroup",
      itemName: bundle.name,
      mini: true,
      currentBranch: currentBranch,
      role: role,
      location: "PropertyGroup"
    };
    var tmp$6 = Belt_Option.map($$event, (function ($$event) {
            return $$event.id;
          }));
    if (tmp$6 !== undefined) {
      tmp$5.eventId = Caml_option.valFromOption(tmp$6);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
              kind: (
                globalPropertyBundle !== undefined || isGlobalWorkspace ? "Global " : ""
              ) + "Property Bundle",
              options: [
                {
                  NAME: "Option",
                  VAL: {
                    label: "Copy URL",
                    onClick: (function (param) {
                        CopyTextToClipboard(Router.Link.getSimple(undefined));
                      })
                  }
                },
                {
                  NAME: "WarningOption",
                  VAL: {
                    label: "Remove from all events and archive",
                    onClick: (function (param) {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "ConfirmModal",
                                VAL: [
                                  "Archive property bundle?",
                                  "Are you sure you want to remove the bundle from all events and archive it?",
                                  "Archive",
                                  handleArchivePropertyBundle,
                                  (function (param) {
                                      
                                    })
                                ]
                              }
                            });
                      })
                  }
                }
              ],
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("relative"),
                          tl: {
                            hd: Css.height(Css.px(60)),
                            tl: {
                              hd: Css.marginLeft(Css.px(-24)),
                              tl: {
                                hd: Css.marginTop(Css.px(-19)),
                                tl: {
                                  hd: Css.marginBottom(Css.px(-19)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement(NameInput.make, {
                        name: bundle.name,
                        existingNames: Belt_Array.map(Curry._1(TrackingPlanMappedModel.PropertyBundles.toArray, model.propertyBundles), (function (bundle) {
                                return bundle.name;
                              })),
                        expectedCase: match.expectedPropertyCase,
                        forceCase: Curry._1(ValidationConfigV2.Rule.isEnforced, match.config.properties.case),
                        autoFocus: false,
                        onChange: (function (bundleName, param, param$1, _onReset) {
                            if (bundleName !== bundle.name) {
                              return Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          (function (branch) {
                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                              AnalyticsRe.propertyGroupUpdated(bundle.id, bundleName, Belt_List.length(bundle.properties), schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "UpdateName", bundle.name, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                            }),
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdatePropertyGroupName",
                                                VAL: [
                                                  bundle.id,
                                                  bundleName
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                            }
                            
                          }),
                        submitLabel: "Update Name",
                        placeholder: "Property Bundle Name",
                        fullscreen: false,
                        disabled: !RoleUtils.canEdit(role),
                        inputStyles: {
                          hd: Css.padding2(Css.px(0), Css.px(20)),
                          tl: /* [] */0
                        },
                        itemType: "PropertyGroup",
                        actionType: "Rename",
                        getPossibleItemLink: (function (propertyGroupName) {
                            return Belt_Option.map(Curry._2(TrackingPlanMappedModel.PropertyBundles.Utils.getByName, model.propertyBundles, propertyGroupName), (function (param) {
                                          return [
                                                  Router.Link.addDrawerItem(undefined, {
                                                        NAME: "propertyGroup",
                                                        VAL: [
                                                          param.id,
                                                          undefined
                                                        ]
                                                      }),
                                                  undefined
                                                ];
                                        }));
                          })
                      }))
            }), tmp$3, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.justifyContent("spaceBetween"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.overflowY("auto"),
                          tl: {
                            hd: Css.zIndex(0),
                            tl: {
                              hd: Css.paddingTop(Css.px(20)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, !(template == null) && Belt_Array.some(template.propertyGroups, (function (id) {
                    return id === bundle.id;
                  })) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(10), Css.px(35)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.color(Styles.Color.light10),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.fontSize(Styles.FontSize.regular),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.justifyContent("spaceBetween"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, "This is an example property bundle", RoleUtils.canEdit(role) ? React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: /* [] */0
                              })
                        }, React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.light10),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.green),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                  schemaRef.set({
                                        onboardingTemplate: {
                                          events: template.events,
                                          properties: template.properties,
                                          propertyGroups: Belt_Array.keep(template.propertyGroups, (function (id) {
                                                  return id !== bundle.id;
                                                })),
                                          goals: template.goals,
                                          metrics: template.metrics
                                        }
                                      }, {"merge": true});
                                  AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "KeepCurrentExample", "PropertyGroup", schemaBundle.branchId, schemaBundle.schemaId);
                                })
                            }, "Keep Property Bundle"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.color(Styles.Color.light10),
                                                tl: {
                                                  hd: Css.margin2(Css.px(0), Css.px(2)),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.darkRed),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  var match = Router.getStaticRoute(undefined);
                                  var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                          if (typeof item === "object" && item.NAME === "propertyGroup") {
                                            return item.VAL[0] !== bundle.id;
                                          } else {
                                            return true;
                                          }
                                        }));
                                  Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        (function (branch) {
                                            Curry._1(addToast, {
                                                  message: "The property bundle has been archived"
                                                });
                                            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                            AnalyticsRe.exampleInteraction(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveCurrentExample", "PropertyGroup", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                          }),
                                        undefined,
                                        [[
                                            {
                                              NAME: "Archive",
                                              VAL: {
                                                NAME: "PropertyGroup",
                                                VAL: bundle.id
                                              }
                                            },
                                            {
                                              propertyGroupId: bundle.id,
                                              propertyGroupQuery: bundle.id
                                            }
                                          ]]
                                      ]);
                                })
                            }, "Remove Property Bundle"), React.createElement("button", {
                              className: Curry._1(Css.merge, {
                                    hd: Curry._1(Css.style, Styles.button),
                                    tl: {
                                      hd: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.small),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.bold),
                                              tl: {
                                                hd: Css.backgroundColor(Styles.Color.red),
                                                tl: {
                                                  hd: Css.color(Styles.Color.white),
                                                  tl: {
                                                    hd: Css.margin2(Css.px(0), Css.px(5)),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(5), Css.px(10)),
                                                      tl: {
                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                        tl: {
                                                          hd: Css.hover({
                                                                hd: Css.backgroundColor(Styles.Color.darkRed),
                                                                tl: /* [] */0
                                                              }),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                      tl: /* [] */0
                                    }
                                  }),
                              onClick: (function (param) {
                                  Router.Schema.popDrawerItem(undefined, undefined);
                                  FirebaseUtils.archiveTemplate(template, (function (eta) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                eta
                                              ]);
                                        }));
                                  AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "RemoveAllExamples", "PropertyGroup", schemaBundle.branchId, schemaBundle.schemaId);
                                })
                            }, "Remove All Examples")) : null) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingRight(Css.px(30)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(30)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(OpsWithState.make, {
                      value: bundle.description,
                      onFlush: (function (description) {
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "UpdatePropertyGroupDescription",
                                      VAL: [
                                        bundle.id,
                                        description
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                        }),
                      onChangeCompleted: (function (param, param$1) {
                          AnalyticsRe.propertyGroupUpdated(bundle.id, bundle.name, Belt_List.length(bundle.properties), schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "UpdateDescription", undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(ReactTextareaAutosize, {
                                      className: Curry._1(Css.merge, {
                                            hd: Curry._1(Css.style, Styles.input),
                                            tl: {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.flex({
                                                          NAME: "num",
                                                          VAL: 3.0
                                                        }),
                                                    tl: {
                                                      hd: Css.fontWeight(Styles.FontWeight.regular),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light12),
                                                          tl: {
                                                            hd: Css.backgroundColor(Css.transparent),
                                                            tl: {
                                                              hd: Css.zIndex(100),
                                                              tl: {
                                                                hd: Css.padding2(Css.px(4), Css.px(0)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }),
                                      onChange: (function (domEvent) {
                                          Curry._1(onChange, domEvent.target.value);
                                        }),
                                      placeholder: "Describe the property bundle...",
                                      onFocus: (function (domEvent) {
                                          Curry._1(onFocus, domEvent.target.value);
                                        }),
                                      onBlur: (function (domEvent) {
                                          Curry._1(onBlur, domEvent.target.value);
                                        }),
                                      value: value,
                                      id: "" + bundle.id + "-description",
                                      disabled: !RoleUtils.canEdit(role)
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding4(Css.px(0), Css.px(30), Css.px(0), Css.px(30)),
                        tl: {
                          hd: Css.marginTop(Css.px(15)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.color(Styles.Color.light12),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Properties in bundle (" + String(bundleProperties.length) + ")"), Belt_Array.map(bundleProperties, (function (property) {
                        return React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("flexStart"),
                                            tl: {
                                              hd: Css.paddingTop(Css.px(12)),
                                              tl: {
                                                hd: Css.paddingBottom(Css.px(12)),
                                                tl: {
                                                  hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.flexGrow(1.0),
                                              tl: {
                                                hd: Css.flexShrink(1.0),
                                                tl: {
                                                  hd: Css.width(Css.pct(100.0)),
                                                  tl: {
                                                    hd: Css.minWidth(Css.px(0)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexWrap("wrap"),
                                                    tl: {
                                                      hd: Css.alignItems("baseline"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement(Link.make, {
                                                path: Router.Link.addDrawerItem(undefined, {
                                                      NAME: "property",
                                                      VAL: [
                                                        property.id,
                                                        undefined
                                                      ]
                                                    }),
                                                className: Curry._1(Css.style, {
                                                      hd: Css.color(Styles.Color.light12),
                                                      tl: {
                                                        hd: Css.hover({
                                                              hd: Css.color(Styles.Color.darkBlue),
                                                              tl: /* [] */0
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                children: React.createElement($$Text.make, {
                                                      element: "Span",
                                                      size: "Medium",
                                                      weight: "Semi",
                                                      singleLine: true,
                                                      children: property.name
                                                    })
                                              }), React.createElement(Spacer.make, {
                                                width: 4
                                              }), React.createElement(PropertyType.make, {
                                                property: property
                                              })), React.createElement(Spacer.make, {
                                            height: 2
                                          }), property.description === "" ? React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light08,
                                              children: "No description"
                                            }) : React.createElement($$Text.make, {
                                              size: "Small",
                                              lines: 3,
                                              color: Styles.Color.light11,
                                              children: property.description
                                            })), RoleUtils.canEdit(role) ? React.createElement(ContextMenu.make, {
                                          options: [{
                                              NAME: "Option",
                                              VAL: {
                                                label: "Remove Property",
                                                onClick: (function (param) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          (function (branch) {
                                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                              AnalyticsRe.propertyGroupUpdated(bundle.id, bundle.name, Belt_List.length(bundle.properties), schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveProperty", undefined, property.id, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                            }),
                                                          undefined,
                                                          [[
                                                              {
                                                                NAME: "RemovePropertyFromGroup",
                                                                VAL: [
                                                                  bundleId,
                                                                  property.id
                                                                ]
                                                              },
                                                              context
                                                            ]]
                                                        ]);
                                                  })
                                              }
                                            }]
                                        }) : null);
                      })), RoleUtils.canEdit(role) ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            })
                      }, React.createElement(PropertyInput.make, {
                            propertyType: /* EventProperty */1,
                            currentProperties: Belt_List.flatten(Belt_List.map(Curry._1(TrackingPlanMappedModel.PropertyBundles.toList, model.propertyBundles), (function (bundle) {
                                        return Belt_List.map(bundle.properties, (function (propertyId) {
                                                      return {
                                                              TAG: /* PropertyRef */0,
                                                              _0: {
                                                                id: propertyId,
                                                                description: "",
                                                                pinnedValue: undefined
                                                              }
                                                            };
                                                    }));
                                      }))),
                            currentGroups: [],
                            options: [[
                                undefined,
                                Curry._2(TrackingPlanMappedModel.Properties.mapToArray, model.properties, (function (property) {
                                        return {
                                                NAME: "Property",
                                                VAL: property
                                              };
                                      }))
                              ]],
                            onSelect: (function (item, param) {
                                if (item.NAME === "Group") {
                                  return Pervasives.failwith("Property bundles cannot be added to other property bundles.");
                                }
                                var property = item.VAL;
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          AnalyticsRe.propertyGroupUpdated(bundle.id, bundle.name, Belt_List.length(bundle.properties), schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "AddProperty", undefined, property.id, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "AddPropertyToGroup",
                                            VAL: [
                                              bundleId,
                                              property.id
                                            ]
                                          },
                                          {
                                            propertyId: property.id,
                                            propertyQuery: property.id,
                                            propertyGroupId: bundleId,
                                            propertyGroupQuery: bundleId
                                          }
                                        ]]
                                    ]);
                              }),
                            currentFilters: currentFilters,
                            renderButton: (function (onClick, buttonRef, _isOpen) {
                                return React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.textAlign("left"),
                                                  tl: /* [] */0
                                                })
                                          }, React.createElement("button", {
                                                ref: buttonRef,
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.width(Css.px(200)),
                                                              tl: {
                                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                tl: {
                                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.darkBlue),
                                                                    tl: {
                                                                      hd: Css.hover({
                                                                            hd: Css.color(Styles.Color.deepBlue),
                                                                            tl: /* [] */0
                                                                          }),
                                                                      tl: {
                                                                        hd: Css.focus({
                                                                              hd: Css.textDecoration("underline"),
                                                                              tl: /* [] */0
                                                                            }),
                                                                        tl: {
                                                                          hd: Css.margin(Css.px(0)),
                                                                          tl: {
                                                                            hd: Css.padding2(Css.px(15), Css.px(5)),
                                                                            tl: {
                                                                              hd: Css.textAlign("left"),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                onClick: onClick
                                              }, "+ Add Property"));
                              }),
                            parent: {
                              NAME: "PropertyGroup",
                              VAL: bundleId
                            },
                            sendActions: sendActions,
                            filters: filters,
                            propertyLocation: "PropertyGroupDetails"
                          })) : null), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(30)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.paddingBottom(Css.px(5)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, "Sent In " + AvoUtils.plural(undefined, undefined, Curry._1(TrackingPlanMappedModel.Events.size, bundleEvents$1), "Event") + ""), React.createElement("div", undefined, events.length !== 0 ? Belt_Array.map(events, (function ($$event) {
                              return React.createElement(Link.make, {
                                          path: Router.Link.addDrawerItem(undefined, {
                                                NAME: "event",
                                                VAL: [
                                                  $$event.id,
                                                  undefined,
                                                  undefined,
                                                  false
                                                ]
                                              }),
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.padding2(Css.px(2), Css.px(0)),
                                                      tl: {
                                                        hd: Css.fontSize(Styles.FontSize.regular),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          children: React.createElement("div", undefined, $$event.name),
                                          key: $$event.id
                                        });
                            })) : React.createElement("button", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.red),
                                                tl: {
                                                  hd: Css.fontSize(Styles.FontSize.regular),
                                                  tl: {
                                                    hd: Css.padding(Css.px(0)),
                                                    tl: {
                                                      hd: Css.hover({
                                                            hd: Css.color(Styles.Color.darkRed),
                                                            tl: /* [] */0
                                                          }),
                                                      tl: {
                                                        hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            onClick: (function (param) {
                                handleArchivePropertyBundle(undefined);
                              })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginRight(Css.px(2)),
                                      tl: /* [] */0
                                    })
                              }, React.createElement(IconX.make, {
                                    size: 10
                                  })), "Archive Property Bundle"))), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.height(Css.px(1)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.margin2(Css.px(15), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(25)),
                        tl: /* [] */0
                      })
                }, React.createElement(ObjectActivityLog.make, tmp$4), React.createElement(CommentInput.make, tmp$5))));
  } else {
    tmp = "Property not found";
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.cursor("default"),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexDirection("column"),
                        tl: {
                          hd: Css.height(Css.pct(100.0)),
                          tl: /* [] */0
                        }
                      }
                    }
                  }),
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                })
            }, tmp);
}

var make = PropertyBundleDetails;

export {
  make ,
}
/* Css Not a pure module */
