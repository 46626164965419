// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconExpo(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  }),
              width: String(size),
              viewBox: "-2 -1 26 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5.84439563e-15,18.52072 C0.042601976,19.0415198 0.225318565,19.5638181 0.704130384,20.2650123 C1.27202197,21.0966169 2.24914731,21.5536071 2.9619004,20.8204258 C3.44287509,20.3256353 8.643748,11.2343287 11.1501969,7.78933904 C11.4511295,7.3640867 12.0728268,7.3640867 12.3739317,7.78933904 C14.8803807,11.2343287 20.0812536,20.3256353 20.5622283,20.8204258 C21.2748089,21.5536071 22.2521067,21.0966169 22.8199983,20.2650123 C23.3790947,19.4462456 23.5343035,18.8713971 23.5343035,18.2580353 C23.5343035,17.8402716 15.4344759,2.76636898 14.6187677,1.5116517 C13.8343003,0.304828919 13.5946623,0.042335452 12.268059,0 L11.2560275,1.79395598e-12 C9.92932506,0.042335452 9.68981105,0.304828919 8.90518853,1.5116517 C8.10659328,2.74030565 0.3230204,17.219996 0,18.213285 L5.84439563e-15,18.52072 Z",
                  fillRule: "evenodd"
                }));
}

var make = IconExpo;

export {
  make ,
}
/* Css Not a pure module */
