// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as EventRule from "./EventRule.mjs";
import * as IconSendAs from "./IconSendAs.mjs";

function EventNameMapping(Props) {
  var $$event = Props.event;
  var slowModelMaybeFlattened = Props.slowModelMaybeFlattened;
  var fromItem = Props.fromItem;
  var sendActions = Props.sendActions;
  var role = Props.role;
  var isGlobalWorkspace = Props.isGlobalWorkspace;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var matchesNoDestination = function (id) {
    return Belt_List.every(slowModelMaybeFlattened.destinations, (function (destination) {
                  return destination.id !== id;
                }));
  };
  var matchesNoWorkspace = function (id) {
    return Belt_List.every(slowModelMaybeFlattened.sources, (function (workspace) {
                  return workspace.id !== id;
                }));
  };
  var eventNameMappings = Belt_List.keepU(slowModelMaybeFlattened.rules, (function (rule) {
          var match = rule.item;
          var match$1 = rule.definition;
          if (typeof match !== "object") {
            return false;
          }
          if (match.NAME !== "Event") {
            return false;
          }
          if (typeof match$1 !== "object") {
            return false;
          }
          var variant = match$1.NAME;
          var id = match.VAL;
          if (variant === "NameMapping") {
            var destination = match$1.VAL.destination;
            var destinationMissing = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? matchesNoDestination(destination.VAL) : false;
            if (id === $$event.id) {
              return !destinationMissing;
            } else {
              return false;
            }
          }
          if (variant !== "NameInLocalWorkspace") {
            return false;
          }
          if (!isGlobalWorkspace) {
            return false;
          }
          var workspace = match$1.VAL.workspace;
          var workspaceMissing = workspace !== undefined && typeof workspace === "object" && workspace.NAME === "Workspace" ? matchesNoWorkspace(workspace.VAL) : false;
          if (id === $$event.id) {
            return !workspaceMissing;
          } else {
            return false;
          }
        }));
  if (Belt_List.length(eventNameMappings) > 0) {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.padding4(Css.px(0), Css.px(30), Css.px(20), Css.px(30)),
                        tl: /* [] */0
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginTop(Css.px(10)),
                          tl: {
                            hd: Css.marginRight(Css.px(5)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement(IconSendAs.make, {})), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(10)),
                            tl: /* [] */0
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.paddingTop(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.light12,
                            children: "Send As"
                          }), React.createElement(Tooltip.WithAnalytics.make, {
                            fromItem: fromItem,
                            title: "Name mapping",
                            children: React.createElement(Tooltip.Content.make, {
                                  children: React.createElement(Markdown.make, {
                                        source: Tooltips.eventSendAs
                                      })
                                })
                          })), Belt_List.toArray(Belt_List.mapU(eventNameMappings, (function (rule) {
                              return React.createElement(EventRule.make, {
                                          rule: rule,
                                          event: $$event,
                                          sendActions: sendActions,
                                          model: slowModelMaybeFlattened,
                                          disabled: disabled,
                                          role: role
                                        });
                            })))));
  } else {
    return null;
  }
}

var make = EventNameMapping;

export {
  make ,
}
/* Css Not a pure module */
