// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";

function Make(C) {
  var context = React.createContext(C.initial);
  var make = context.Provider;
  var Provider = {
    make: make
  };
  var use = function (param) {
    return React.useContext(context);
  };
  var StateContext$Make$State = function (props) {
    return JsxRuntime.jsx(make, {
                value: props.state,
                children: props.children
              });
  };
  var State = {
    context: context,
    Provider: Provider,
    use: use,
    make: StateContext$Make$State
  };
  var context$1 = React.createContext(function (param) {
        
      });
  var make$1 = context$1.Provider;
  var Provider$1 = {
    make: make$1
  };
  var use$1 = function (param) {
    return React.useContext(context$1);
  };
  var StateContext$Make$SetState = function (props) {
    return JsxRuntime.jsx(make$1, {
                value: props.setState,
                children: props.children
              });
  };
  var SetState = {
    context: context$1,
    Provider: Provider$1,
    use: use$1,
    make: StateContext$Make$SetState
  };
  var StateContext$Make = function (props) {
    var match = React.useState(function () {
          return C.initial;
        });
    return JsxRuntime.jsx(StateContext$Make$State, {
                state: match[0],
                children: JsxRuntime.jsx(StateContext$Make$SetState, {
                      setState: match[1],
                      children: props.children
                    })
              });
  };
  return {
          State: State,
          SetState: SetState,
          make: StateContext$Make
        };
}

export {
  Make ,
}
/* react Not a pure module */
