// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as CmdK from "./CmdK.mjs";
import * as Kbar from "kbar";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as ArrayExt from "./ArrayExt.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextBox from "./ContextBox.mjs";
import * as IconFunnel from "./IconFunnel.mjs";
import * as MetricItem from "./MetricItem.mjs";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DrawerUtils from "./DrawerUtils.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as RouterStore from "./RouterStore.mjs";
import QuillDelta from "quill-delta";
import * as CommentInput from "./CommentInput.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as IconCategory from "./IconCategory.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as CategoryInput from "./CategoryInput.mjs";
import * as EventAutofill from "./EventAutofill.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as IconArrowDown from "./IconArrowDown.mjs";
import * as IconRetention from "./IconRetention.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as IconProportion from "./IconProportion.mjs";
import * as IconDescription from "./IconDescription.mjs";
import * as QuillWithToolbar from "./QuillWithToolbar.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ObjectActivityLog from "./ObjectActivityLog.mjs";
import * as OpsWithDeltaState from "./OpsWithDeltaState.mjs";
import * as ReactBeautifulDnd from "./ReactBeautifulDnd.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as ReactBeautifulDnd$1 from "react-beautiful-dnd";
import * as IconEventSegmentation from "./IconEventSegmentation.mjs";
import CopyTextToClipboard from "copy-text-to-clipboard";

var funnelTypes = {
  hd: "Funnel",
  tl: {
    hd: "EventSegmentation",
    tl: {
      hd: "Retention",
      tl: {
        hd: "Proportion",
        tl: /* [] */0
      }
    }
  }
};

function Metric$FunnelTypeIcon(Props) {
  var type_ = Props.type_;
  if (type_ === "EventSegmentation") {
    return React.createElement(IconEventSegmentation.make, {
                size: 12,
                color: Styles.Color.light04
              });
  } else if (type_ === "Retention") {
    return React.createElement(IconRetention.make, {
                size: 12,
                color: Styles.Color.light04
              });
  } else if (type_ === "Funnel") {
    return React.createElement(IconFunnel.make, {
                size: 12,
                color: Styles.Color.light04
              });
  } else {
    return React.createElement(IconProportion.make, {
                size: 12,
                color: Styles.Color.light04
              });
  }
}

var FunnelTypeIcon = {
  make: Metric$FunnelTypeIcon
};

function Metric$DraggableMetricItems(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var events = Props.events;
  var goalId = Props.goalId;
  var metric = Props.metric;
  var model = Props.model;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useState(function () {
        return Belt_List.keepMapU(metric.items, (function (item) {
                      var match = item.VAL;
                      var eventId = match.eventId;
                      var id = match.id;
                      return Belt_Option.mapU(Belt_List.getBy(events, (function ($$event) {
                                        return $$event.id === eventId;
                                      })), (function ($$event) {
                                    return [
                                            item,
                                            id,
                                            $$event
                                          ];
                                  }));
                    }));
      });
  var setItems = match[1];
  var items = match[0];
  React.useEffect((function () {
          var newItems = Belt_List.keepMapU(metric.items, (function (item) {
                  var match = item.VAL;
                  var eventId = match.eventId;
                  var id = match.id;
                  return Belt_Option.mapU(Belt_List.getBy(events, (function ($$event) {
                                    return $$event.id === eventId;
                                  })), (function ($$event) {
                                return [
                                        item,
                                        id,
                                        $$event
                                      ];
                              }));
                }));
          if (Caml_obj.notequal(items, newItems)) {
            Curry._1(setItems, (function (param) {
                    return newItems;
                  }));
          }
          
        }), [metric.items]);
  var onDragEnd = function (result) {
    Belt_Option.forEach(Caml_option.nullable_to_opt(result.destination), (function (destination) {
            var predecessor = Belt_Option.map(Belt_List.get(items, destination.index < result.source.index ? destination.index - 1 | 0 : destination.index), (function (param) {
                    return param[1];
                  }));
            var itemMoved = Belt_Option.map(Belt_List.get(items, result.source.index), (function (param) {
                    return param[1];
                  }));
            var mayProceed = itemMoved !== undefined ? (
                predecessor !== undefined ? itemMoved !== predecessor : true
              ) : false;
            if (mayProceed && itemMoved !== undefined) {
              Curry._1(setItems, (function (items) {
                      return Belt_List.fromArray(ArrayExt.reinsertIndex(Belt_List.toArray(items), result.source.index, destination.index));
                    }));
              setTimeout((function (param) {
                      var tmp = {
                        metricId: metric.id,
                        metricQuery: metric.id
                      };
                      if (goalId !== undefined) {
                        tmp.goalId = Caml_option.valFromOption(goalId);
                      }
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [[
                                {
                                  NAME: "ReorderEventsInMetricV2",
                                  VAL: [
                                    metric.id,
                                    itemMoved,
                                    predecessor
                                  ]
                                },
                                tmp
                              ]]
                          ]);
                    }), 0);
              return ;
            }
            
          }));
  };
  return React.createElement(ReactBeautifulDnd$1.DragDropContext, {
              onDragEnd: onDragEnd,
              children: React.createElement(ReactBeautifulDnd$1.Droppable, {
                    droppableId: "metric",
                    children: (function (provided, _snapshot) {
                        return React.createElement(ReactBeautifulDnd.DraggableWrapper.make, {
                                    droppableProps: provided.droppableProps,
                                    children: null,
                                    ref: provided.innerRef
                                  }, Belt_List.toArray(Belt_List.mapWithIndexU(items, (function (index, param) {
                                              var $$event = param[2];
                                              var metricItemId = param[1];
                                              var metricItem = param[0];
                                              return React.createElement(ReactBeautifulDnd$1.Draggable, {
                                                          key: metricItemId,
                                                          draggableId: metricItemId,
                                                          index: index,
                                                          children: (function (provided, _snapshot) {
                                                              var tmp = {
                                                                metric: metric,
                                                                metricItem: metricItem,
                                                                event: $$event,
                                                                index: index,
                                                                sendActions: sendActions,
                                                                model: model,
                                                                currentFilters: currentFilters,
                                                                filters: filters
                                                              };
                                                              if (goalId !== undefined) {
                                                                tmp.goalId = Caml_option.valFromOption(goalId);
                                                              }
                                                              return React.createElement("div", undefined, React.createElement(ReactBeautifulDnd.ItemWrapper.make, {
                                                                              dragHandleProps: provided.dragHandleProps,
                                                                              draggableProps: provided.draggableProps,
                                                                              children: React.createElement(MetricItem.make, tmp),
                                                                              ref: provided.innerRef
                                                                            }), provided.placeholder);
                                                            })
                                                        });
                                            }))), provided.placeholder);
                      })
                  })
            });
}

var DraggableMetricItems = {
  make: Metric$DraggableMetricItems
};

function Metric(Props) {
  var currentBranch = Props.currentBranch;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var goalId = Props.goalId;
  var goToCommentId = Props.goToCommentId;
  var metric = Props.metric;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var role = Props.role;
  var schema = Props.schema;
  var isFullscreen = Props.isFullscreen;
  var workspace = WorkspaceContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var maybeDrawerItem = Belt_List.head(RouterStore.Schema.useDrawerItems(undefined));
  var branchBarVisible = RouterStore.useBranchBarVisible(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var addToast = Toast.useAddToast(undefined);
  var tmp = {
    metricId: metric.id,
    metricQuery: metric.id
  };
  if (goalId !== undefined) {
    tmp.goalId = Caml_option.valFromOption(goalId);
  }
  var context = tmp;
  var match = GlobalStateContext.use(undefined);
  var modals = match.modals;
  var screenIsOnTop = maybeDrawerItem !== undefined && typeof maybeDrawerItem === "object" && maybeDrawerItem.NAME === "metric" ? maybeDrawerItem.VAL[0] === metric.id : false;
  Kbar.useRegisterActions(screenIsOnTop && modals === /* [] */0 ? [Curry.app(CmdK.Action.t, [
                "metric_fullscreen",
                {
                  TAG: /* RootItem */0,
                  _0: "Toggle Full Screen on Metric"
                },
                1.1,
                ["f"],
                undefined,
                undefined,
                (function (param) {
                    Router.Schema.swapTopDrawerItem({
                          NAME: "metric",
                          VAL: [
                            metric.id,
                            undefined,
                            !isFullscreen
                          ]
                        });
                  }),
                undefined,
                undefined
              ])] : [], [
        metric.id,
        screenIsOnTop,
        isFullscreen,
        modals === /* [] */0
      ]);
  var renderEventInput = function (param) {
    return React.createElement(EventAutofill.make, {
                events: model.events,
                onSelectSuccess: (function (eventId, eventName, branch) {
                    var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    AnalyticsRe.metricUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, metric.id, metric.name, eventId, eventName, "EventAdded", metric.type_, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                  }),
                renderButton: (function (onClick, buttonRef, _isOpen) {
                    var index = Belt_List.length(metric.items);
                    var match = metric.type_;
                    return React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("center"),
                                        tl: {
                                          hd: Css.width(Css.pct(100)),
                                          tl: {
                                            hd: Css.marginTop(Css.px(2)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.justifyContent("center"),
                                              tl: {
                                                hd: Css.flexShrink(0.0),
                                                tl: {
                                                  hd: Css.width(Css.px(24)),
                                                  tl: {
                                                    hd: Css.height(Css.px(24)),
                                                    tl: {
                                                      hd: Css.borderRadius(Css.pct(100)),
                                                      tl: {
                                                        hd: Css.marginRight(Css.px(10)),
                                                        tl: {
                                                          hd: Css.fontSize(Styles.FontSize.small),
                                                          tl: {
                                                            hd: Css.fontWeight(Styles.FontWeight.bold),
                                                            tl: {
                                                              hd: Css.color(Styles.Color.white),
                                                              tl: {
                                                                hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.avoPink, 0.1)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        })
                                  }, match === "Funnel" ? String(index + 1 | 0) : React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.textTransform("uppercase"),
                                                tl: /* [] */0
                                              })
                                        }, String.fromCharCode(index + 97 | 0))), React.createElement(Spacer.make, {
                                    height: 10
                                  }), React.createElement(TextButton.make, {
                                    onClick: onClick,
                                    style: "Blue",
                                    size: "Small",
                                    children: "+ Add Event",
                                    ref: buttonRef
                                  }));
                  }),
                currentFilters: currentFilters,
                eventOrigin: "MetricDetails",
                selectActions: (function (eventId) {
                    var tmp = {
                      eventId: eventId,
                      eventQuery: eventId,
                      metricId: metric.id,
                      metricQuery: metric.id
                    };
                    if (goalId !== undefined) {
                      tmp.goalId = Caml_option.valFromOption(goalId);
                    }
                    return [[
                              {
                                NAME: "AddEventToMetric",
                                VAL: [
                                  metric.id,
                                  Nanoid.nanoid(),
                                  eventId
                                ]
                              },
                              tmp
                            ]];
                  })
              });
  };
  var match$1 = Router.getStaticRoute(undefined).branch;
  var demoId = match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "branch" && match$1.VAL.startsWith("demo-") ? "new-workspace-demo-metric" : undefined;
  var duplicateAvailability = AvoLimits.DuplicateItems.computeAvailability(workspace);
  var tmp$1 = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("column"),
            tl: {
              hd: Css.height(Css.pct(100.0)),
              tl: {
                hd: Css.overflowY("auto"),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: isFullscreen ? ({
                        hd: Css.borderRadius(Styles.Border.radius),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: /* [] */0
                        }
                      }) : /* [] */0
                }
              }
            }
          }
        })
  };
  if (demoId !== undefined) {
    tmp$1.id = Caml_option.valFromOption(demoId);
  }
  var template = schema.onboardingTemplate;
  var goals = model.goals;
  var match$2 = metric.type_;
  var match$3 = metric.type_;
  var tmp$2;
  if (match$3 === "Funnel" || match$3 === "EventSegmentation") {
    var tmp$3 = {
      currentFilters: currentFilters,
      filters: filters,
      events: model.events,
      metric: metric,
      model: model
    };
    if (goalId !== undefined) {
      tmp$3.goalId = Caml_option.valFromOption(goalId);
    }
    tmp$2 = React.createElement(Metric$DraggableMetricItems, tmp$3);
  } else if (match$3 === "Retention") {
    var a = Belt_Option.flatMap(Belt_List.get(metric.items, 0), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var b = Belt_Option.flatMap(Belt_List.get(metric.items, 1), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var tmp$4;
    if (a !== undefined) {
      var tmp$5 = {
        metric: metric,
        metricItem: a[0],
        event: a[1],
        index: 0,
        sendActions: sendActions,
        model: model,
        currentFilters: currentFilters,
        filters: filters,
        draggable: false
      };
      if (goalId !== undefined) {
        tmp$5.goalId = Caml_option.valFromOption(goalId);
      }
      tmp$4 = React.createElement(MetricItem.make, tmp$5);
    } else {
      tmp$4 = renderEventInput(undefined);
    }
    var tmp$6;
    if (a !== undefined) {
      if (b !== undefined) {
        var tmp$7 = {
          metric: metric,
          metricItem: b[0],
          event: b[1],
          index: 1,
          sendActions: sendActions,
          model: model,
          currentFilters: currentFilters,
          filters: filters,
          draggable: false
        };
        if (goalId !== undefined) {
          tmp$7.goalId = Caml_option.valFromOption(goalId);
        }
        tmp$6 = React.createElement(MetricItem.make, tmp$7);
      } else {
        tmp$6 = renderEventInput(undefined);
      }
    } else {
      tmp$6 = null;
    }
    tmp$2 = React.createElement("div", undefined, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.width(Css.px(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Born"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, tmp$4)), React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.bold),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.width(Css.px(50)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Return"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, tmp$6)));
  } else {
    var a$1 = Belt_Option.flatMap(Belt_List.get(metric.items, 0), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var b$1 = Belt_Option.flatMap(Belt_List.get(metric.items, 1), (function (x) {
            var eventId = x.VAL.eventId;
            return Belt_Option.map(Belt_List.getBy(model.events, (function ($$event) {
                              return $$event.id === eventId;
                            })), (function ($$event) {
                          return [
                                  x,
                                  $$event
                                ];
                        }));
          }));
    var tmp$8;
    if (a$1 !== undefined) {
      var tmp$9 = {
        metric: metric,
        metricItem: a$1[0],
        event: a$1[1],
        index: 0,
        sendActions: sendActions,
        model: model,
        currentFilters: currentFilters,
        filters: filters,
        draggable: false
      };
      if (goalId !== undefined) {
        tmp$9.goalId = Caml_option.valFromOption(goalId);
      }
      tmp$8 = React.createElement(MetricItem.make, tmp$9);
    } else {
      tmp$8 = renderEventInput(undefined);
    }
    var tmp$10;
    if (a$1 !== undefined) {
      if (b$1 !== undefined) {
        var tmp$11 = {
          metric: metric,
          metricItem: b$1[0],
          event: b$1[1],
          index: 1,
          sendActions: sendActions,
          model: model,
          currentFilters: currentFilters,
          filters: filters,
          draggable: false
        };
        if (goalId !== undefined) {
          tmp$11.goalId = Caml_option.valFromOption(goalId);
        }
        tmp$10 = React.createElement(MetricItem.make, tmp$11);
      } else {
        tmp$10 = renderEventInput(undefined);
      }
    } else {
      tmp$10 = null;
    }
    tmp$2 = React.createElement("div", undefined, tmp$8, React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.color(Styles.Color.light08),
                        tl: {
                          hd: Css.padding2(Css.px(5), Css.px(30)),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.bold),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.small),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.height(Css.px(2)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: /* [] */0
                          }
                        }
                      })
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding2(Css.px(0), Css.px(50)),
                        tl: /* [] */0
                      })
                }, "DIVIDED BY"), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: {
                          hd: Css.height(Css.px(2)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: /* [] */0
                          }
                        }
                      })
                })), tmp$10);
  }
  var match$4 = metric.type_;
  var tmp$12 = {
    object_: {
      NAME: "Metric",
      VAL: [
        metric.id,
        metric.name
      ]
    },
    objectId: metric.id,
    currentBranch: currentBranch,
    openBranches: openBranches
  };
  if (goToCommentId !== undefined) {
    tmp$12.goToCommentId = Caml_option.valFromOption(goToCommentId);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, isFullscreen ? ({
                        hd: Css.position("fixed"),
                        tl: {
                          hd: Css.top(Css.px(0)),
                          tl: {
                            hd: Css.right(Css.px(0)),
                            tl: {
                              hd: Css.bottom(Css.px(0)),
                              tl: {
                                hd: Css.left(Css.px(0)),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light01),
                                  tl: {
                                    hd: Css.padding4(Css.px(50), Css.vw(5), Css.px(0), Css.vw(5)),
                                    tl: {
                                      hd: Css.zIndex(Styles.ZIndex.eventDetailsFullScreen),
                                      tl: {
                                        hd: Css.overflowY("auto"),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }) : /* [] */0),
              label: "metric root"
            }, React.createElement("div", tmp$1, React.createElement(DrawerHeader.make, {
                      kind: "Metric",
                      options: Belt_Array.concatMany([
                            RoleUtils.canEdit(role) ? [{
                                  NAME: "Option",
                                  VAL: {
                                    label: "Duplicate metric" + (
                                      duplicateAvailability === "Available" ? "" : " ⚡️"
                                    ),
                                    onClick: (function (param) {
                                        if (!(duplicateAvailability === "AvailableDuringTrial" || duplicateAvailability === "Available")) {
                                          return Curry._1(globalSend, {
                                                      TAG: /* OpenModal */4,
                                                      _0: {
                                                        NAME: "BillingPrompt",
                                                        VAL: "DuplicateItems"
                                                      }
                                                    });
                                        }
                                        var newMetricId = Nanoid.nanoid();
                                        Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  Curry._1(addToast, {
                                                        message: "The metric was duplicated",
                                                        toastType: /* Success */0
                                                      });
                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                  AnalyticsRe.metricInitiated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, newMetricId, metric.name, "Duplicated", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                  Router.Schema.pushDrawerItem(undefined, undefined, {
                                                        NAME: "metric",
                                                        VAL: [
                                                          newMetricId,
                                                          undefined,
                                                          false
                                                        ]
                                                      });
                                                }),
                                              (function (branch) {
                                                  
                                                }),
                                              (function (param) {
                                                  Curry._1(addToast, {
                                                        message: "There was an error duplicating the metric",
                                                        toastType: /* Error */1
                                                      });
                                                }),
                                              [[
                                                  {
                                                    NAME: "DuplicateMetric",
                                                    VAL: [
                                                      metric.id,
                                                      newMetricId,
                                                      metric.items
                                                    ]
                                                  },
                                                  {
                                                    eventIds: Belt_List.toArray(Belt_List.map(metric.items, (function (metricItem) {
                                                                return metricItem.VAL.eventId;
                                                              }))),
                                                    eventsQuery: Belt_List.toArray(Belt_List.map(metric.items, (function (metricItem) {
                                                                return metricItem.VAL.eventId;
                                                              }))),
                                                    metricId: metric.id,
                                                    metricQuery: metric.id
                                                  }
                                                ]]
                                            ]);
                                      })
                                  }
                                }] : [],
                            [
                              {
                                NAME: "Option",
                                VAL: {
                                  label: "Copy URL",
                                  onClick: (function (param) {
                                      CopyTextToClipboard(Router.Link.getSimple(undefined));
                                    })
                                }
                              },
                              {
                                NAME: "Option",
                                VAL: {
                                  label: isFullscreen ? "Exit Full Screen" : "Full Screen",
                                  onClick: (function (param) {
                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                            NAME: "metric",
                                            VAL: [
                                              metric.id,
                                              undefined,
                                              !isFullscreen
                                            ]
                                          });
                                    })
                                }
                              }
                            ],
                            RoleUtils.canEdit(role) ? [{
                                  NAME: "WarningOption",
                                  VAL: {
                                    label: "Archive Metric",
                                    onClick: (function (param) {
                                        if (window.confirm("Are you sure you would like to archive the metric \"" + metric.name + "\"")) {
                                          var match = Router.getStaticRoute(undefined);
                                          var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                                  if (typeof item === "object" && item.NAME === "metric") {
                                                    return item.VAL[0] !== metric.id;
                                                  } else {
                                                    return true;
                                                  }
                                                }));
                                          Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                (function (branch) {
                                                    AnalyticsRe.metricArchived(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, metric.id, metric.name, NamedBranch.getId(branch), schemaBundle.schemaId);
                                                  }),
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "Archive",
                                                      VAL: {
                                                        NAME: "Metric",
                                                        VAL: metric.id
                                                      }
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                          return Curry._1(addToast, {
                                                      message: "The metric has been archived"
                                                    });
                                        }
                                        
                                      })
                                  }
                                }] : []
                          ]),
                      children: React.createElement(OpsWithState.make, {
                            value: metric.name,
                            onFlush: (function (name) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateMetricName",
                                            VAL: [
                                              metric.id,
                                              name
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              }),
                            onChangeCompleted: (function (param, param$1) {
                                AnalyticsRe.metricUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, metric.id, metric.name, "N/A", "N/A", "NameUpdated", metric.type_, schemaBundle.branchId, schemaBundle.schemaId);
                              }),
                            children: (function (value, onChange, onBlur, onFocus) {
                                return React.createElement("input", {
                                            className: Curry._1(Css.merge, {
                                                  hd: Curry._1(Css.style, Styles.input),
                                                  tl: {
                                                    hd: Curry._1(Css.style, {
                                                          hd: Css.color(Styles.Color.light12),
                                                          tl: {
                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                            tl: {
                                                              hd: Css.fontSize(Styles.FontSize.medium),
                                                              tl: {
                                                                hd: Css.border(Css.px(1), "solid", "transparent"),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: {
                                                                    hd: Css.backgroundColor("transparent"),
                                                                    tl: {
                                                                      hd: Css.paddingLeft(Css.px(4)),
                                                                      tl: {
                                                                        hd: Css.marginLeft(Css.px(-4)),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            placeholder: "Untitled Metric...",
                                            value: value,
                                            onFocus: (function (domEvent) {
                                                Curry._1(onFocus, domEvent.target.value);
                                              }),
                                            onBlur: (function (domEvent) {
                                                Curry._1(onBlur, domEvent.target.value);
                                              }),
                                            onChange: (function (domEvent) {
                                                Curry._1(onChange, domEvent.target.value);
                                              })
                                          });
                              })
                          })
                    }), React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.justifyContent("spaceBetween"),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.overflowY("auto"),
                                    tl: {
                                      hd: Css.zIndex(0),
                                      tl: {
                                        hd: Css.maxHeight({
                                              NAME: "subtract",
                                              VAL: [
                                                Css.vh(100),
                                                Css.px(DrawerUtils.getTopOffsetAndHeaderHeight(workspace, branchBarVisible, schemaRoute))
                                              ]
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", undefined, !(template == null) && Belt_Array.some(template.metrics, (function (id) {
                                return id === metric.id;
                              })) ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.padding2(Css.px(10), Css.px(35)),
                                      tl: {
                                        hd: Css.margin2(Css.px(10), Css.px(-50)),
                                        tl: {
                                          hd: Css.backgroundColor(Styles.Color.light02),
                                          tl: {
                                            hd: Css.color(Styles.Color.light10),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.alignItems("center"),
                                                    tl: {
                                                      hd: Css.justifyContent("spaceBetween"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, "This is an example metric", React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.display("flex"),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement("button", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, Styles.button),
                                              tl: {
                                                hd: Curry._1(Css.style, {
                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.bold),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light10),
                                                          tl: {
                                                            hd: Css.margin2(Css.px(0), Css.px(2)),
                                                            tl: {
                                                              hd: Css.hover({
                                                                    hd: Css.color(Styles.Color.green),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        onClick: (function (param) {
                                            var schemaRef = Firebase.app(undefined).firestore().collection("schemas").doc(schema.id);
                                            schemaRef.set({
                                                  onboardingTemplate: {
                                                    events: template.events,
                                                    properties: template.properties,
                                                    propertyGroups: template.propertyGroups,
                                                    goals: template.goals,
                                                    metrics: Belt_Array.keep(template.metrics, (function (id) {
                                                            return id !== metric.id;
                                                          }))
                                                  }
                                                }, {"merge": true});
                                            AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "KeepCurrentExample", "Metric", schemaBundle.branchId, schemaBundle.schemaId);
                                          })
                                      }, "Keep Metric"), React.createElement("button", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, Styles.button),
                                              tl: {
                                                hd: Curry._1(Css.style, {
                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.bold),
                                                        tl: {
                                                          hd: Css.color(Styles.Color.light10),
                                                          tl: {
                                                            hd: Css.margin2(Css.px(0), Css.px(2)),
                                                            tl: {
                                                              hd: Css.hover({
                                                                    hd: Css.color(Styles.Color.darkRed),
                                                                    tl: /* [] */0
                                                                  }),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        onClick: (function (param) {
                                            var match = Router.getStaticRoute(undefined);
                                            var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                                    if (typeof item === "object" && item.NAME === "metric") {
                                                      return item.VAL[0] !== metric.id;
                                                    } else {
                                                      return true;
                                                    }
                                                  }));
                                            Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.exampleInteraction(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "RemoveCurrentExample", "Metric", NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                      Curry._1(addToast, {
                                                            message: "The metric has been archived"
                                                          });
                                                    }),
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "Archive",
                                                        VAL: {
                                                          NAME: "Metric",
                                                          VAL: metric.id
                                                        }
                                                      },
                                                      {
                                                        metricId: metric.id,
                                                        metricQuery: metric.id
                                                      }
                                                    ]]
                                                ]);
                                          })
                                      }, "Remove Metric"), React.createElement("button", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, Styles.button),
                                              tl: {
                                                hd: Curry._1(Css.style, {
                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                      tl: {
                                                        hd: Css.fontWeight(Styles.FontWeight.bold),
                                                        tl: {
                                                          hd: Css.backgroundColor(Styles.Color.red),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.white),
                                                            tl: {
                                                              hd: Css.margin2(Css.px(0), Css.px(5)),
                                                              tl: {
                                                                hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: {
                                                                    hd: Css.hover({
                                                                          hd: Css.backgroundColor(Styles.Color.darkRed),
                                                                          tl: /* [] */0
                                                                        }),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }),
                                        onClick: (function (param) {
                                            FirebaseUtils.archiveTemplate(template, (function (eta) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          eta
                                                        ]);
                                                  }));
                                            AnalyticsRe.exampleInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "RemoveAllExamples", "Metric", schemaBundle.branchId, schemaBundle.schemaId);
                                          })
                                      }, "Remove All Examples"))) : null, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("flexStart"),
                                      tl: {
                                        hd: Css.padding4(Css.px(15), Css.px(30), Css.px(0), Css.px(18)),
                                        tl: /* [] */0
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.marginTop(Css.px(13)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement(IconDescription.make, {})), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.border(Css.px(1), "solid", "transparent"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                tl: /* [] */0
                                              }),
                                          tl: {
                                            hd: Css.borderRadius(Styles.Border.radius),
                                            tl: {
                                              hd: Css.marginLeft(Css.px(5)),
                                              tl: {
                                                hd: Css.width(Css.pct(100)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement(OpsWithDeltaState.make, {
                                      value: new QuillDelta(JSON.parse(metric.description)),
                                      onFlush: (function (description) {
                                          Curry.app(sendActions, [
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                [[
                                                    {
                                                      NAME: "UpdateMetricDescription",
                                                      VAL: [
                                                        metric.id,
                                                        JSON.stringify(description)
                                                      ]
                                                    },
                                                    context
                                                  ]]
                                              ]);
                                        }),
                                      onChangeCompleted: (function (param, param$1) {
                                          AnalyticsRe.metricUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, metric.id, metric.name, "N/A", "N/A", "DescriptionUpdated", metric.type_, schemaBundle.branchId, schemaBundle.schemaId);
                                        }),
                                      children: (function (value, onChange, onBlur, param) {
                                          return React.createElement(QuillWithToolbar.make, {
                                                      value: value,
                                                      onChange: onChange,
                                                      id: "metric-" + metric.id + "",
                                                      placeholder: "Metric description...",
                                                      onBlur: (function (param) {
                                                          Curry._1(onBlur, value);
                                                        })
                                                    });
                                        })
                                    }))), goals ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("flexStart"),
                                        tl: {
                                          hd: Css.padding4(Css.px(5), Css.px(30), Css.px(0), Css.px(18)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginTop(Css.px(5)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(IconCategory.make, {})), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.width(Css.pct(100)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement(CategoryInput.make, {
                                        categories: goals,
                                        currentCategories: Belt_List.toArray(Belt_List.keep(goals, (function (goal) {
                                                    return Belt_List.some(goal.metrics, (function (id) {
                                                                  return id === metric.id;
                                                                }));
                                                  }))),
                                        viewerCanEdit: RoleUtils.canEdit(role),
                                        onRemove: (function (category) {
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.categoryUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, category.name, category.id, "MetricRemoved", metric.name, metric.id, "N/A", "N/A", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                    }),
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "RemoveMetricFromGoal",
                                                        VAL: [
                                                          category.id,
                                                          metric.id
                                                        ]
                                                      },
                                                      {
                                                        goalId: category.id,
                                                        goalQuery: category.id,
                                                        metricId: metric.id,
                                                        metricQuery: metric.id
                                                      }
                                                    ]]
                                                ]);
                                          }),
                                        onAdd: (function (id, name) {
                                            Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.categoryUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, name, id, "MetricAdded", metric.name, metric.id, "N/A", "N/A", NamedBranch.getId(branch), schemaBundle.schemaId);
                                                    }),
                                                  undefined,
                                                  [[
                                                      {
                                                        NAME: "AddMetricToGoal",
                                                        VAL: [
                                                          id,
                                                          metric.id
                                                        ]
                                                      },
                                                      {
                                                        goalId: id,
                                                        goalQuery: id,
                                                        metricId: metric.id,
                                                        metricQuery: metric.id
                                                      }
                                                    ]]
                                                ]);
                                          })
                                      }))) : null, React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.padding2(Css.px(0), Css.px(15)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontSize(Styles.FontSize.regular),
                                        tl: {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.color(Styles.Color.light12),
                                            tl: {
                                              hd: Css.width(Css.pct(100)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: {
                                                  hd: Css.marginTop(Css.px(15)),
                                                  tl: {
                                                    hd: Css.marginBottom(Css.px(10)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    }, "Metric Type"), React.createElement(ContextBox.make, {
                                      children: (function (param, param$1, close, buttonRef) {
                                          return [
                                                  React.createElement("button", {
                                                        ref: buttonRef,
                                                        className: Curry._1(Css.merge, {
                                                              hd: Curry._1(Css.style, Styles.button),
                                                              tl: {
                                                                hd: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: {
                                                                          hd: Css.color(Styles.Color.darkBlue),
                                                                          tl: {
                                                                            hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                            tl: {
                                                                              hd: Css.borderRadius(Styles.Border.radius),
                                                                              tl: {
                                                                                hd: Css.padding4(Css.px(6), Css.px(16), Css.px(6), Css.px(16)),
                                                                                tl: {
                                                                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                                  tl: {
                                                                                    hd: Css.transition({
                                                                                          NAME: "ms",
                                                                                          VAL: Styles.Duration.$$default
                                                                                        }, undefined, undefined, "all"),
                                                                                    tl: {
                                                                                      hd: Css.hover({
                                                                                            hd: Css.color(Styles.Color.deepBlue),
                                                                                            tl: {
                                                                                              hd: Css.borderColor(Styles.Color.darkBlue),
                                                                                              tl: /* [] */0
                                                                                            }
                                                                                          }),
                                                                                      tl: {
                                                                                        hd: Css.focus({
                                                                                              hd: Css.color(Styles.Color.deepBlue),
                                                                                              tl: {
                                                                                                hd: Css.borderColor(Styles.Color.deepBlue),
                                                                                                tl: /* [] */0
                                                                                              }
                                                                                            }),
                                                                                        tl: {
                                                                                          hd: Css.width(Css.pct(100)),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }),
                                                                tl: /* [] */0
                                                              }
                                                            })
                                                      }, React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.display("flex"),
                                                                  tl: {
                                                                    hd: Css.alignItems("center"),
                                                                    tl: {
                                                                      hd: Css.justifyContent("spaceBetween"),
                                                                      tl: {
                                                                        hd: Css.width(Css.pct(100)),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.display("flex"),
                                                                      tl: {
                                                                        hd: Css.alignItems("center"),
                                                                        tl: /* [] */0
                                                                      }
                                                                    })
                                                              }, React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.display("flex"),
                                                                          tl: {
                                                                            hd: Css.alignItems("center"),
                                                                            tl: {
                                                                              hd: Css.marginRight(Css.px(10)),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        })
                                                                  }, React.createElement(Metric$FunnelTypeIcon, {
                                                                        type_: metric.type_
                                                                      })), React.createElement("div", {
                                                                    className: Curry._1(Css.style, {
                                                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                          tl: {
                                                                            hd: Css.fontSize(Styles.FontSize.regular),
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  }, AvoUtils.getMetricTypeLabel(metric.type_))), React.createElement("div", {
                                                                className: Curry._1(Css.style, {
                                                                      hd: Css.margin(Css.px(5)),
                                                                      tl: /* [] */0
                                                                    })
                                                              }, React.createElement(IconArrowDown.make, {
                                                                    size: 12,
                                                                    color: Styles.Color.darkBlue
                                                                  })))),
                                                  React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.position("absolute"),
                                                              tl: {
                                                                hd: Css.left(Css.px(0)),
                                                                tl: {
                                                                  hd: Css.bottom(Css.px(0)),
                                                                  tl: {
                                                                    hd: Css.transform(Css.translateY(Css.pct(100))),
                                                                    tl: {
                                                                      hd: Css.padding(Css.px(4)),
                                                                      tl: {
                                                                        hd: Css.borderRadius(Styles.Border.radius),
                                                                        tl: {
                                                                          hd: Css.backgroundColor(Styles.Color.white),
                                                                          tl: {
                                                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                                            tl: {
                                                                              hd: Css.width(Css.pct(100)),
                                                                              tl: /* [] */0
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            })
                                                      }, Belt_List.toArray(Belt_List.map(funnelTypes, (function (type_) {
                                                                  return React.createElement("button", {
                                                                              key: TrackingPlanModel.metricTypeToJs(type_),
                                                                              className: Curry._1(Css.merge, {
                                                                                    hd: Curry._1(Css.style, Styles.button),
                                                                                    tl: {
                                                                                      hd: Curry._1(Css.style, {
                                                                                            hd: Css.display("flex"),
                                                                                            tl: {
                                                                                              hd: Css.alignItems("center"),
                                                                                              tl: {
                                                                                                hd: Css.textAlign("left"),
                                                                                                tl: {
                                                                                                  hd: Css.padding2(Css.px(10), Css.px(15)),
                                                                                                  tl: {
                                                                                                    hd: Css.width(Css.pct(100)),
                                                                                                    tl: {
                                                                                                      hd: Css.hover({
                                                                                                            hd: Css.backgroundColor(Styles.Color.light02),
                                                                                                            tl: {
                                                                                                              hd: Css.color(Styles.Color.darkBlue),
                                                                                                              tl: /* [] */0
                                                                                                            }
                                                                                                          }),
                                                                                                      tl: /* [] */0
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }),
                                                                                      tl: /* [] */0
                                                                                    }
                                                                                  }),
                                                                              onClick: (function (param) {
                                                                                  if (type_ !== metric.type_) {
                                                                                    Curry.app(sendActions, [
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          undefined,
                                                                                          (function (branch) {
                                                                                              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                                                              AnalyticsRe.metricUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, metric.id, metric.name, "N/A", "N/A", "MetricTypeChanged", metric.type_, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                                                            }),
                                                                                          undefined,
                                                                                          [[
                                                                                              {
                                                                                                NAME: "UpdateMetricType",
                                                                                                VAL: [
                                                                                                  metric.id,
                                                                                                  type_
                                                                                                ]
                                                                                              },
                                                                                              context
                                                                                            ]]
                                                                                        ]);
                                                                                    return Curry._1(close, undefined);
                                                                                  } else {
                                                                                    return Curry._1(close, undefined);
                                                                                  }
                                                                                })
                                                                            }, React.createElement("div", {
                                                                                  className: Curry._1(Css.style, {
                                                                                        hd: Css.display("flex"),
                                                                                        tl: {
                                                                                          hd: Css.alignItems("center"),
                                                                                          tl: {
                                                                                            hd: Css.marginRight(Css.px(10)),
                                                                                            tl: /* [] */0
                                                                                          }
                                                                                        }
                                                                                      })
                                                                                }, React.createElement(Metric$FunnelTypeIcon, {
                                                                                      type_: type_
                                                                                    })), React.createElement("div", {
                                                                                  className: Curry._1(Css.style, {
                                                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                                        tl: {
                                                                                          hd: Css.fontSize(Styles.FontSize.regular),
                                                                                          tl: /* [] */0
                                                                                        }
                                                                                      })
                                                                                }, AvoUtils.getMetricTypeLabel(type_)));
                                                                }))))
                                                ];
                                        })
                                    })), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.margin2(Css.px(15), Css.px(0)),
                                        tl: /* [] */0
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.color(Styles.Color.light12),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, "Events"), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginTop(Css.px(10)),
                                            tl: {
                                              hd: Css.position("relative"),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, match$2 === "Funnel" ? React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.position("absolute"),
                                                  tl: {
                                                    hd: Css.top(Css.px(15)),
                                                    tl: {
                                                      hd: Css.bottom(Css.px(15)),
                                                      tl: {
                                                        hd: Css.left(Css.px(10)),
                                                        tl: {
                                                          hd: Css.width(Css.px(4)),
                                                          tl: {
                                                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.avoPink, 0.1)),
                                                            tl: {
                                                              hd: Css.borderRadius(Css.px(10)),
                                                              tl: {
                                                                hd: Css.zIndex(0),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                })
                                          }) : null, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.position("relative"),
                                                tl: {
                                                  hd: Css.zIndex(100),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, tmp$2)), match$4 === "Retention" || match$4 === "Proportion" ? null : renderEventInput(undefined)))), React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.marginTop(Css.px(35)),
                                tl: /* [] */0
                              })
                        }, React.createElement("div", {
                              key: metric.id,
                              className: Curry._1(Css.style, {
                                    hd: Css.padding4(Css.px(15), Css.px(0), Css.px(15), Css.px(25)),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(ObjectActivityLog.make, tmp$12)), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: /* [] */0
                                  })
                            }, React.createElement(CommentInput.make, {
                                  threadId: metric.id,
                                  itemType: "Metric",
                                  itemName: metric.name,
                                  mini: true,
                                  currentBranch: currentBranch,
                                  role: role,
                                  location: "Metric"
                                }))))));
}

var make = Metric;

export {
  funnelTypes ,
  FunnelTypeIcon ,
  DraggableMetricItems ,
  make ,
}
/* Css Not a pure module */
