// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";

function handleRuleChange(configItem, customStructsAvailability, predefinedValuesAvailability, isUpdatingAdvancedConfigOpt, role, rule, schemaBundle, warningCount, sendActions, updatedConfig) {
  var isUpdatingAdvancedConfig = isUpdatingAdvancedConfigOpt !== undefined ? isUpdatingAdvancedConfigOpt : false;
  var action = GuardRailsConfigUtils.ruleToAnalyticsAction(rule);
  FirebaseUtils.setValidationConfigV2(schemaBundle.schemaId, ValidationConfigV2.encode(updatedConfig), configItem, rule.severity, undefined, sendActions);
  var advancedNameConfigProperties = AnalyticsUtils.getAdvancedNameConfigProperties(ValidationConfigV2.EventsV2.getNameComponents(updatedConfig.events));
  var updatedNameComponents = ValidationConfigV2.EventsV2.getNameComponents(updatedConfig.events);
  var issueReportEventCasing = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(updatedNameComponents);
  var issueReportEventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(updatedNameComponents);
  var eventNamingConventionsAvailability = predefinedValuesAvailability === "AvailableDuringTrial" || predefinedValuesAvailability === "Available" ? (
      customStructsAvailability === "Unavailable" ? "PredefinedFrameworkWithPredefinedValues" : (
          customStructsAvailability === "AvailableDuringTrial" || customStructsAvailability === "Available" ? "CustomFramework" : "PredefinedFramework"
        )
    ) : "PredefinedFramework";
  var advancedEventNameStructureComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesAsString, undefined);
  var advancedEventNameStructureComponentConfig = Curry._1(advancedNameConfigProperties.getComponentTypesAsString, undefined);
  var advancedEventNameComponentCount = Curry._1(advancedNameConfigProperties.getComponentCount, undefined);
  var advancedEventNameComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesIncludingSeparators, undefined);
  var advancedEventNameComponentTypes = Curry._1(advancedNameConfigProperties.getComponentTypesAsArray, undefined);
  var advancedEventNameComponentCasings = Curry._1(advancedNameConfigProperties.getComponentCasings, undefined);
  if (advancedEventNameStructureComponentNames !== undefined && advancedEventNameStructureComponentConfig !== undefined && advancedEventNameComponentCount !== undefined && advancedEventNameComponentNames !== undefined && advancedEventNameComponentTypes !== undefined && advancedEventNameComponentCasings !== undefined && isUpdatingAdvancedConfig) {
    AnalyticsRe.issueReportConfiguredAdvancedEventNamingConvention(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, action, AnalyticsUtils.roleToAnalyticsRole(role), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), issueReportEventNamingConvention);
  }
  var match = rule.value;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (!(variant === "nameComponents" || variant === "blockedValues" || variant === "allowedValues")) {
      var casingAnalytics = GuardRailsConfigUtils.caseToAnalytics(match.VAL);
      return AnalyticsRe.issueReportConfiguredCasing(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(role), casingAnalytics, schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(updatedNameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(updatedNameComponents));
    }
    
  }
  AnalyticsRe.issueReportConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(role), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), issueReportEventNamingConvention);
}

export {
  handleRuleChange ,
}
/* AnalyticsRe Not a pure module */
