// Generated by ReScript, PLEASE EDIT WITH CARE


var emptyState_changes = {
  new: undefined,
  removed: undefined,
  unchanged: undefined,
  changed: undefined
};

var emptyState = {
  changes: emptyState_changes,
  status: "loading"
};

export {
  emptyState ,
}
/* No side effect */
