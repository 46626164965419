// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Belt_MutableSet from "rescript/lib/es6/belt_MutableSet.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Belt_MutableSetString from "rescript/lib/es6/belt_MutableSetString.js";

function reinsertIndex(items, index, newIndex) {
  var items$1 = items.slice(0);
  var itemToMove = items$1.splice(index, 1);
  Caml_splice_call.spliceObjApply(items$1, "splice", [
        newIndex,
        0,
        itemToMove
      ]);
  return items$1;
}

function splitIntoChunks(items, chunkSize) {
  if (Caml_obj.equal(items, [])) {
    return [];
  }
  var count = Caml_int32.div(items.length - 1 | 0, chunkSize) + 1 | 0;
  return Belt_Array.map(Belt_Array.range(0, count - 1 | 0), (function (index) {
                return Belt_Array.slice(items, Math.imul(index, chunkSize), chunkSize);
              }));
}

function compareUnorderedStringArrays(a, b) {
  return Belt_SetString.eq(Belt_SetString.fromArray(a), Belt_SetString.fromArray(b));
}

function group(cmp, array) {
  var sorted = array.slice().sort(Curry.__2(cmp));
  var fold = function (first, rest) {
    return Belt_Array.reduceU(rest, [
                [],
                [first],
                first
              ], (function (param, elem) {
                  var agr = param[1];
                  var acc = param[0];
                  if (Curry._2(cmp, param[2], elem) === 0) {
                    return [
                            acc,
                            Belt_Array.concat([elem], agr),
                            elem
                          ];
                  } else {
                    return [
                            Belt_Array.concat([agr], acc),
                            [elem],
                            elem
                          ];
                  }
                }));
  };
  if (sorted.length === 0) {
    return [];
  }
  var tl = Belt_Array.sliceToEnd(sorted, 1);
  var hd = Belt_Array.getExn(sorted, 0);
  var match = fold(hd, tl);
  return Belt_Array.mapU(Belt_Array.reverse(Belt_Array.concat([match[1]], match[0])), Belt_Array.reverse);
}

function sliceToEndSafe(array, offset) {
  try {
    return Belt_Array.sliceToEnd(array, offset);
  }
  catch (exn){
    return [];
  }
}

function has(array, value) {
  return Belt_Array.some(array, (function (v) {
                return Caml_obj.equal(v, value);
              }));
}

function Dedupe(Config) {
  var ItemComparator = Belt_Id.MakeComparable({
        cmp: Config.compare
      });
  var dedupe = function (items) {
    return Belt_Set.toArray(Belt_Set.fromArray(items, ItemComparator));
  };
  var sort = function (items) {
    return items.slice().sort(function (param, param$1) {
                return Curry._2(Config.compare, param, param$1);
              });
  };
  return {
          ItemComparator: ItemComparator,
          dedupe: dedupe,
          sort: sort
        };
}

function OrderDedupe(Config) {
  var Comparator = Belt_Id.MakeComparable(Config);
  var dedupe = function (items) {
    var seen = Belt_MutableSet.make(Comparator);
    return Belt_Array.keepU(items, (function (item) {
                  if (Belt_MutableSet.has(seen, item)) {
                    return false;
                  } else {
                    Belt_MutableSet.add(seen, item);
                    return true;
                  }
                }));
  };
  var sort = function (items) {
    return Belt_SortArray.stableSortByU(Belt_Set.toArray(Belt_Set.fromArray(items, Comparator)), (function (a, b) {
                  return Curry._2(Config.cmp, a, b);
                }));
  };
  return {
          Comparator: Comparator,
          dedupe: dedupe,
          sort: sort
        };
}

function dedupe(items) {
  var seen = Belt_MutableSetString.fromArray([]);
  return Belt_Array.keepU(items, (function (item) {
                if (Belt_MutableSetString.has(seen, item)) {
                  return false;
                } else {
                  Belt_MutableSetString.add(seen, item);
                  return true;
                }
              }));
}

var $$String = {
  dedupe: dedupe
};

export {
  reinsertIndex ,
  splitIntoChunks ,
  compareUnorderedStringArrays ,
  group ,
  sliceToEndSafe ,
  has ,
  Dedupe ,
  OrderDedupe ,
  $$String ,
}
/* No side effect */
