// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconCheckmark(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.avoPink;
  return React.createElement("svg", {
              width: String(size),
              version: "1.1",
              viewBox: "0 0 18 14",
              xmlns: "http://www.w3.org/2000/svg",
              xmlnsXlink: "http://www.w3.org/1999/xlink"
            }, React.createElement("g", {
                  id: "Page-1",
                  fill: "none",
                  fillRule: "evenodd",
                  stroke: "none",
                  strokeWidth: "1"
                }, React.createElement("g", {
                      id: "avo-frontpage",
                      transform: "translate(-454.000000, -2618.000000)"
                    }, React.createElement("g", {
                          id: "ic_check_black_24px",
                          transform: "translate(451.000000, 2613.000000)"
                        }, React.createElement("polygon", {
                              id: "Shape",
                              points: "0 0 24 0 24 24 0 24"
                            }), React.createElement("polygon", {
                              className: Curry._1(Css.style, {
                                    hd: Css_Legacy_Core.SVG.fill(color),
                                    tl: /* [] */0
                                  }),
                              id: "Shape",
                              fillRule: "nonzero",
                              points: "9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"
                            })))));
}

var make = IconCheckmark;

export {
  make ,
}
/* Css Not a pure module */
