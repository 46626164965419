// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ImportParserTypes from "./ImportParserTypes.mjs";
import * as InspectorEventShape from "../../../shared/models/InspectorEventShape.mjs";
import * as ImportParserFiltering from "./ImportParserFiltering.mjs";
import * as InspectorPropertyType from "../../../shared/models/InspectorPropertyType.mjs";

function isPropertyEqual(a, b, param) {
  if (a.name === b.name && (a.description === b.description || a.description.trim() === "" || b.description.trim() === "") && (a.type_ === b.type_ || a.type_ === "any" || b.type_ === "any") && a.list === b.list) {
    return Caml_obj.equal(a.sendAs, b.sendAs);
  } else {
    return false;
  }
}

function hasNewMatches(globalProperty, rawProperty) {
  var currentMatchesSet = Belt_SetString.fromArray(globalProperty.matches);
  return !Belt_Array.every(rawProperty.matches, (function (match_) {
                return Belt_SetString.has(currentMatchesSet, match_);
              }));
}

function decodeTypeWarning(s, propertyType, eventId) {
  if (s === "[]") {
    return [{
              NAME: "typeConflictAutoResolved",
              VAL: [
                eventId,
                {
                  type_: "string",
                  propertyPercentage: 0.0
                },
                []
              ]
            }];
  }
  var seenTypes = Json_decode.array(InspectorEventShape.PropertyType.decode, JSON.parse(s));
  var l = Belt_List.fromArray(seenTypes);
  if (l) {
    var match = l.hd;
    var type_ = match.type_;
    if (type_ === /* Number */4) {
      return [{
                NAME: "typeConflictAutoResolved",
                VAL: [
                  eventId,
                  {
                    type_: "float",
                    propertyPercentage: match.percentagePresent
                  },
                  Belt_Array.mapU(seenTypes, (function (param) {
                          return {
                                  type_: InspectorPropertyType.toString(param.type_),
                                  propertyPercentage: param.percentagePresent
                                };
                        }))
                ]
              }];
    }
    if (Caml_obj.equal(type_, InspectorPropertyType.fromString(propertyType))) {
      return [{
                NAME: "mostCommonTypeUsed",
                VAL: [
                  eventId,
                  Belt_Array.mapU(seenTypes, (function (param) {
                          return {
                                  type_: InspectorPropertyType.toString(param.type_),
                                  propertyPercentage: param.percentagePresent
                                };
                        }))
                ]
              }];
    }
    
  }
  var maybeType = Belt_List.getByU(l, (function (param) {
          return Caml_obj.equal(param.type_, InspectorPropertyType.fromString(propertyType));
        }));
  return [{
            NAME: "typeConflictAutoResolved",
            VAL: [
              eventId,
              Belt_Option.mapWithDefaultU(maybeType, {
                    type_: propertyType,
                    propertyPercentage: 0.0
                  }, (function (param) {
                      return {
                              type_: InspectorPropertyType.toString(param.type_),
                              propertyPercentage: param.percentagePresent
                            };
                    })),
              Belt_Array.mapU(seenTypes, (function (param) {
                      return {
                              type_: InspectorPropertyType.toString(param.type_),
                              propertyPercentage: param.percentagePresent
                            };
                    }))
            ]
          }];
}

function decodePresenceWarning(s, eventId) {
  var seenSources = Belt_Array.mapU(Js_dict.entries(Json_decode.dict(InspectorEventShape.SourcePresence.decode, JSON.parse(s))), (function (param) {
          return {
                  sourceId: param[0],
                  percentagePresent: param[1].percentagePresent
                };
        }));
  return [{
            NAME: "presenceInconsistent",
            VAL: [
              eventId,
              seenSources
            ]
          }];
}

function convertWarning(rawEvent, warning, propertyType) {
  var match = Belt_Array.keepU(Belt_Option.mapWithDefault(warning, [], (function (warning) {
              return warning.split("||");
            })), (function (s) {
          return s.trim() !== "";
        }));
  if (rawEvent === undefined) {
    return [];
  }
  var id = rawEvent.id;
  return Belt_Array.concatMany(Belt_Array.mapU(match, (function (warning) {
                    var possibleInspectorImport = warning.split(": ");
                    if (possibleInspectorImport.length !== 2) {
                      return [{
                                NAME: "message",
                                VAL: warning
                              }];
                    }
                    var eventId = possibleInspectorImport[0];
                    var info = possibleInspectorImport[1];
                    if (eventId === id && info.endsWith(" [types]")) {
                      return decodeTypeWarning(info.replace(" [types]", ""), propertyType, eventId);
                    } else if (eventId === id && info.endsWith(" [presence]")) {
                      return decodePresenceWarning(info.replace(" [presence]", ""), eventId);
                    } else {
                      return [{
                                NAME: "message",
                                VAL: warning
                              }];
                    }
                  })));
}

function rawToModelProperty(rawProperty, rawEvent, propertyId, formatName) {
  return {
          id: propertyId,
          name: rawProperty.name,
          uniqueNameIndex: undefined,
          description: rawProperty.description,
          type_: rawProperty.type_,
          list: rawProperty.list,
          matches: rawProperty.matches,
          warnings: convertWarning(rawEvent, rawProperty.typeWarning, rawProperty.type_),
          presence: Belt_Option.getWithDefault(Belt_Option.map(rawEvent, (function ($$event) {
                      return Belt_MapString.fromArray([[
                                    $$event.id,
                                    rawProperty.required ? /* AlwaysSent */0 : /* SometimesSent */1
                                  ]]);
                    })), undefined),
          sendAs: formatName === "Mixpanel CSV Export" && Belt_Option.mapWithDefault(rawEvent, false, (function ($$event) {
                  return $$event.name === "$user";
                })) ? /* UserProperty */2 : rawProperty.sendAs,
          nameMapping: rawProperty.nameMapping
        };
}

function appendRawPropertiesWithDedupe(globalPropertiesOpt, generateId, formatName, importMethod, properties) {
  var globalProperties = globalPropertiesOpt !== undefined ? globalPropertiesOpt : [];
  return Belt_Array.reduceU(properties, globalProperties, (function (globalProperties, param) {
                var prop = param[1];
                var rawEvent = param[0];
                if (Belt_Array.someU(globalProperties, (function (globalProp) {
                          return isPropertyEqual(globalProp, prop, importMethod);
                        }))) {
                  return Belt_Array.map(globalProperties, (function (globalProperty) {
                                if (isPropertyEqual(globalProperty, prop, importMethod)) {
                                  return {
                                          id: globalProperty.id,
                                          name: globalProperty.name,
                                          uniqueNameIndex: globalProperty.uniqueNameIndex,
                                          description: globalProperty.description,
                                          type_: globalProperty.type_ === "any" ? prop.type_ : globalProperty.type_,
                                          list: globalProperty.list,
                                          matches: Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concat(globalProperty.matches, prop.matches))).sort(),
                                          warnings: Belt_Array.concat(globalProperty.warnings, convertWarning(rawEvent, prop.typeWarning, globalProperty.type_)),
                                          presence: Belt_Option.mapWithDefault(rawEvent, globalProperty.presence, (function ($$event) {
                                                  return Belt_MapString.set(globalProperty.presence, $$event.id, prop.required ? /* AlwaysSent */0 : /* SometimesSent */1);
                                                })),
                                          sendAs: globalProperty.sendAs,
                                          nameMapping: globalProperty.nameMapping
                                        };
                                } else {
                                  return globalProperty;
                                }
                              }));
                } else {
                  return Belt_Array.concat(globalProperties, [rawToModelProperty(prop, rawEvent, Curry._1(generateId, undefined), formatName)]);
                }
              }));
}

function rawEventBundleIds($$event, globalPropertyBundles) {
  return Belt_Array.keepMap($$event.propertyBundleNames, (function (propertyBundleName) {
                return Belt_Array.get(Belt_Array.keepMap(globalPropertyBundles, (function (bundle) {
                                  if (bundle.name === propertyBundleName) {
                                    return bundle.id;
                                  }
                                  
                                })), 0);
              }));
}

function findPropertyBundleAndGlobalPropertyForRawProperty(globalProperties, property) {
  var maybeGlobalProperty = Belt_Array.getBy(globalProperties, (function (globalProperty) {
          return globalProperty.name === property.name;
        }));
  var match = property.propertyBundle;
  if (match.name === "" || maybeGlobalProperty === undefined) {
    return ;
  } else {
    return [
            match,
            maybeGlobalProperty
          ];
  }
}

var ParseError = ImportParserTypes.ParseError;

var ParseFormatError = ImportParserTypes.ParseFormatError;

var ParseWarning = ImportParserTypes.ParseWarning;

var getColumnValue = ImportParserTypes.getColumnValue;

var getOptionalColumnValue = ImportParserTypes.getOptionalColumnValue;

var getColumnIndex = ImportParserTypes.getColumnIndex;

var getOptionalColumnIndex = ImportParserTypes.getOptionalColumnIndex;

var StringTupleOp = ImportParserTypes.StringTupleOp;

var StringOp = ImportParserTypes.StringOp;

var BoolOp = ImportParserTypes.BoolOp;

var StringArrayOp = ImportParserTypes.StringArrayOp;

var SendAsOp = ImportParserTypes.SendAsOp;

var Utils = ImportParserFiltering.Utils;

var Filtering = ImportParserFiltering.Filtering;

export {
  ParseError ,
  ParseFormatError ,
  ParseWarning ,
  getColumnValue ,
  getOptionalColumnValue ,
  getColumnIndex ,
  getOptionalColumnIndex ,
  StringTupleOp ,
  StringOp ,
  BoolOp ,
  StringArrayOp ,
  SendAsOp ,
  Utils ,
  Filtering ,
  isPropertyEqual ,
  hasNewMatches ,
  decodeTypeWarning ,
  decodePresenceWarning ,
  convertWarning ,
  rawToModelProperty ,
  appendRawPropertiesWithDedupe ,
  rawEventBundleIds ,
  findPropertyBundleAndGlobalPropertyForRawProperty ,
}
/* ImportParserFiltering Not a pure module */
