// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function use(param) {
  return Belt_Option.getExn(React.useContext(context));
}

function ViewerContext(props) {
  return JsxRuntime.jsx(make, {
              value: props.user,
              children: props.children
            });
}

var make$1 = ViewerContext;

export {
  context ,
  Provider ,
  use ,
  make$1 as make,
}
/* context Not a pure module */
