// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";

var $$Location = {};

var $$Event = {};

var $$DOMRect = {};

function getElementById(id) {
  return Caml_option.nullable_to_opt(document.getElementById(id));
}

var Body = {};

var BodyStyle = {};

function collapse(prim0, prim1) {
  prim0.collapse(prim1);
}

var $$Range = {
  collapse: collapse
};

var $$Document = {
  getElementById: getElementById,
  Body: Body,
  BodyStyle: BodyStyle,
  $$Range: $$Range
};

function get(param) {
  var selection = window.getSelection();
  return {
          focusNode: Caml_option.nullable_to_opt(selection.focusNode),
          t: selection
        };
}

var $$Selection = {
  get: get
};

var Style = {};

function replaceStateSilent(path) {
  var history = window.history;
  history.replaceState(history.state, "avo", path);
}

function replaceState(path) {
  var history = window.history;
  history.replaceState(history.state, "avo", path);
  var $$event = document.createEvent("Event");
  $$event.initEvent("popstate", true, true);
  window.dispatchEvent($$event);
}

var $$History = {
  replaceStateSilent: replaceStateSilent,
  replaceState: replaceState
};

var $$File = {};

var $$FileReader = {};

var $$JSON = {};

var $$Node = {};

function querySelector(el, identifier) {
  return Caml_option.nullable_to_opt(el.querySelector(identifier));
}

var $$Element = {
  querySelector: querySelector
};

var Dom = {
  $$Element: $$Element
};

var $$Image = {};

var $$URL = {};

var $$BeforeUnloadEvent = {};

var $$TextEncoder = {};

var $$TextDecoder = {};

function make(param) {
  return document.createElement("canvas");
}

var Context = {};

var Canvas = {
  make: make,
  Context: Context
};

var DateTimeFormat = {};

var $$Intl = {
  DateTimeFormat: DateTimeFormat
};

var DateExtensions = {};

export {
  $$Location ,
  $$Event ,
  $$DOMRect ,
  $$Document ,
  $$Selection ,
  Style ,
  $$History ,
  $$File ,
  $$FileReader ,
  $$JSON ,
  $$Node ,
  Dom ,
  $$Image ,
  $$URL ,
  $$BeforeUnloadEvent ,
  $$TextEncoder ,
  $$TextDecoder ,
  Canvas ,
  $$Intl ,
  DateExtensions ,
}
/* No side effect */
