// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as ArrayExt from "../ArrayExt.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as GetAllPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";

function propertyHasUpdatedDescription(importMethod, importProperty, modelProperty) {
  if (importMethod === "AddOnly" || importProperty.description === "") {
    return false;
  } else {
    return importProperty.description !== modelProperty.description;
  }
}

function propertyHasUpdatedMatches(importProperty, modelProperty, model) {
  var modelPropertyMatches = GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), modelProperty);
  return !ArrayExt.compareUnorderedStringArrays(modelPropertyMatches, importProperty.matches);
}

function propertyHasUpdatedType(importMethod, importProperty, modelProperty) {
  if (importMethod === "AddOnly" || importProperty.type_ === "any") {
    return false;
  } else {
    return importProperty.type_ !== modelProperty.type_;
  }
}

function propertyHasUpdatedListType(importMethod, importProperty, modelProperty) {
  if (importMethod === "AddOnly") {
    return false;
  } else {
    return importProperty.list !== modelProperty.list;
  }
}

function propertyHasUpdates(importMethod, importProperty, modelProperty, model) {
  if (propertyHasUpdatedDescription(importMethod, importProperty, modelProperty) || propertyHasUpdatedMatches(importProperty, modelProperty, model) || propertyHasUpdatedType(importMethod, importProperty, modelProperty)) {
    return true;
  } else {
    return propertyHasUpdatedListType(importMethod, importProperty, modelProperty);
  }
}

function eventHasUpdatedDescription(importMethod, importEvent, modelEvent) {
  if (importMethod === "AddOnly" || importEvent.description === "") {
    return false;
  } else {
    return importEvent.description !== modelEvent.description;
  }
}

var Utils = {
  propertyHasUpdatedDescription: propertyHasUpdatedDescription,
  propertyHasUpdatedMatches: propertyHasUpdatedMatches,
  propertyHasUpdatedType: propertyHasUpdatedType,
  propertyHasUpdatedListType: propertyHasUpdatedListType,
  propertyHasUpdates: propertyHasUpdates,
  eventHasUpdatedDescription: eventHasUpdatedDescription
};

function filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew(model, parsedModel, importMethod, domainEvents) {
  var getSameEventInModel = function ($$event) {
    return Belt_List.getByU(model.events, (function (modelEvent) {
                  return modelEvent.name === $$event.name;
                }));
  };
  var isPropertyStrictlyEqual = function (importProperty, modelProperty, modelEvent) {
    var modelPropertyAllowedValues = modelEvent !== undefined ? Belt_Array.map(GetEventSpecificPropertyValuesUseCase.get(modelProperty.eventSpecificAllowedPropertyValues, modelEvent), (function (param) {
              return param[0];
            })) : GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), modelProperty);
    if (importProperty.name === modelProperty.name && importProperty.description === modelProperty.description && importProperty.type_ === modelProperty.type_ && importProperty.list === modelProperty.list && Caml_obj.equal(importProperty.sendAs, modelProperty.sendAs)) {
      return ArrayExt.compareUnorderedStringArrays(importProperty.matches, modelPropertyAllowedValues);
    } else {
      return false;
    }
  };
  return Belt_Array.reduceU(parsedModel.events, [
              [],
              [],
              []
            ], (function (param, $$event) {
                var filteredOutEvents = param[2];
                var updatedEvents = param[1];
                var newEvents = param[0];
                var modelEvent = getSameEventInModel($$event);
                if (modelEvent !== undefined) {
                  var importedProperties = Belt_Array.keepU(parsedModel.properties, (function (property) {
                          return Belt_Array.someU($$event.propertyIds, (function (param) {
                                        return param[0] === property.id;
                                      }));
                        }));
                  var existingPropertiesInTrackingPlan = Belt_List.keepMapU(modelEvent.directPropertyRefs, (function (property) {
                          return ModelUtils.resolveProperty(model, property);
                        }));
                  var existingPropertyNamesInTrackingPlan = Belt_List.map(existingPropertiesInTrackingPlan, (function (param) {
                          return param.name;
                        }));
                  var newPropertiesOnEvent = Belt_Array.keepU(importedProperties, (function (property) {
                          return Belt_List.everyU(existingPropertyNamesInTrackingPlan, (function (propertyName) {
                                        return propertyName !== property.name;
                                      }));
                        }));
                  var updatedPropertiesOnEvent = Belt_Array.keepMapU(importedProperties, (function (importProperty) {
                          return Belt_Option.flatMap(Belt_List.getBy(existingPropertiesInTrackingPlan, (function (modelProperty) {
                                            if (modelProperty.name === importProperty.name) {
                                              return !isPropertyStrictlyEqual(importProperty, modelProperty, modelEvent);
                                            } else {
                                              return false;
                                            }
                                          })), (function (modelProperty) {
                                        return {
                                                id: modelProperty.id,
                                                name: importProperty.name,
                                                uniqueNameIndex: importProperty.uniqueNameIndex,
                                                description: importProperty.description,
                                                type_: importProperty.type_,
                                                list: importProperty.list,
                                                matches: importProperty.matches,
                                                warnings: importProperty.warnings,
                                                presence: importProperty.presence,
                                                sendAs: importProperty.sendAs,
                                                nameMapping: importProperty.nameMapping
                                              };
                                      }));
                        }));
                  var existingCategoriesOnEventInTrackingPlan = Belt_Array.map(ModelUtils.getGoalsWithEvent(modelEvent, model), (function (goal) {
                          return goal.name;
                        }));
                  var newCategoriesOnEvent = Belt_Array.keepU($$event.categories, (function (category) {
                          return Belt_Array.everyU(existingCategoriesOnEventInTrackingPlan, (function (existingCategory) {
                                        return existingCategory !== category;
                                      }));
                        }));
                  var newTagsOnEvent = Belt_Array.keepU($$event.tags, (function (tag) {
                          return Belt_List.everyU(modelEvent.tags, (function (existingTag) {
                                        return existingTag !== tag;
                                      }));
                        }));
                  var newSourcesOnEvent = Belt_Array.keepU($$event.sources, (function (importedSourceName) {
                          return Belt_List.everyU(modelEvent.includeSources, (function (param) {
                                        return AvoConfig.getSourceNameById(model, param.id) !== importedSourceName;
                                      }));
                        }));
                  var modelEventStakeholderDomainNames = Belt_Array.mapU(Curry._1(Domains.toArray, Belt_MapString.getWithDefault(domainEvents, modelEvent.id, Domains.empty)), (function (domain) {
                          return domain.name;
                        }));
                  var newStakeholderDomainsOnEvent = Belt_Array.keepU($$event.stakeholderDomains, (function (importedStakeholderDomainName) {
                          return Belt_Array.everyU(modelEventStakeholderDomainNames, (function (existingStakeholderDomainName) {
                                        return existingStakeholderDomainName !== importedStakeholderDomainName;
                                      }));
                        }));
                  var updatedEventDescription = eventHasUpdatedDescription(importMethod, $$event, modelEvent) ? $$event.description : undefined;
                  if (newPropertiesOnEvent.length !== 0 || updatedPropertiesOnEvent.length !== 0 || newCategoriesOnEvent.length !== 0 || newTagsOnEvent.length !== 0 || newSourcesOnEvent.length !== 0 || newStakeholderDomainsOnEvent.length !== 0 || updatedEventDescription !== undefined) {
                    return [
                            newEvents,
                            Belt_Array.concat(updatedEvents, [{
                                    id: modelEvent.id,
                                    name: $$event.name,
                                    uniqueNameIndex: $$event.uniqueNameIndex,
                                    description: Belt_Option.getWithDefault(updatedEventDescription, modelEvent.description),
                                    categories: newCategoriesOnEvent,
                                    tags: newTagsOnEvent,
                                    propertyIds: Belt_Array.map(newPropertiesOnEvent, (function (property) {
                                            return [
                                                    property.id,
                                                    undefined
                                                  ];
                                          })),
                                    updatedPropertyIds: Belt_Array.map(updatedPropertiesOnEvent, (function (param) {
                                            return [
                                                    param.id,
                                                    undefined
                                                  ];
                                          })),
                                    propertyBundleIds: $$event.propertyBundleIds,
                                    sources: newSourcesOnEvent,
                                    stakeholderDomains: newStakeholderDomainsOnEvent,
                                    warnings: $$event.warnings,
                                    nameMapping: $$event.nameMapping
                                  }]),
                            filteredOutEvents
                          ];
                  } else {
                    return [
                            newEvents,
                            updatedEvents,
                            Belt_Array.concat(filteredOutEvents, [/* EventAlreadyInTrackingPlan */{
                                    _0: $$event,
                                    _1: modelEvent
                                  }])
                          ];
                  }
                }
                var importedProperties$1 = Belt_Array.keepU(parsedModel.properties, (function (property) {
                        return Belt_Array.someU($$event.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                var updatedPropertiesOnEvent$1 = Belt_Array.keepMapU(importedProperties$1, (function (importProperty) {
                        return Belt_Option.flatMap(Belt_List.getBy(Belt_List.keepMap(Belt_List.map(model.properties, (function (p) {
                                                  return ModelUtils.resolveProperty(model, p);
                                                })), (function (mp) {
                                              return mp;
                                            })), (function (modelProperty) {
                                          if (modelProperty.name === importProperty.name) {
                                            return !isPropertyStrictlyEqual(importProperty, modelProperty, undefined);
                                          } else {
                                            return false;
                                          }
                                        })), (function (modelProperty) {
                                      return {
                                              id: modelProperty.id,
                                              name: importProperty.name,
                                              uniqueNameIndex: importProperty.uniqueNameIndex,
                                              description: importProperty.description,
                                              type_: importProperty.type_,
                                              list: importProperty.list,
                                              matches: importProperty.matches,
                                              warnings: importProperty.warnings,
                                              presence: importProperty.presence,
                                              sendAs: importProperty.sendAs,
                                              nameMapping: importProperty.nameMapping
                                            };
                                    }));
                      }));
                return [
                        Belt_Array.concat(newEvents, [{
                                id: $$event.id,
                                name: $$event.name,
                                uniqueNameIndex: $$event.uniqueNameIndex,
                                description: $$event.description,
                                categories: $$event.categories,
                                tags: $$event.tags,
                                propertyIds: $$event.propertyIds,
                                updatedPropertyIds: Belt_Array.map(updatedPropertiesOnEvent$1, (function (param) {
                                        return [
                                                param.id,
                                                undefined
                                              ];
                                      })),
                                propertyBundleIds: $$event.propertyBundleIds,
                                sources: $$event.sources,
                                stakeholderDomains: $$event.stakeholderDomains,
                                warnings: $$event.warnings,
                                nameMapping: $$event.nameMapping
                              }]),
                        updatedEvents,
                        filteredOutEvents
                      ];
              }));
}

function filterOutPropertiesThatAreOnlyOnFilteredOutEvents(parsedProperties, parsedEvents, filteredOutEvents) {
  return Belt_Array.reduceU(parsedProperties, [
              [],
              []
            ], (function (param, property) {
                var filteredOutProperties = param[1];
                var newProperties = param[0];
                var isPropertyOnSomeNewEvent = property.sendAs === /* UserProperty */2 || Belt_Array.someU(parsedEvents, (function (newEvent) {
                        return Belt_Array.someU(newEvent.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                var isPropertyOnFilteredOutEvent = Belt_Array.some(filteredOutEvents, (function (filteredEvent) {
                        return Belt_Array.some(filteredEvent._0.propertyIds, (function (param) {
                                      return param[0] === property.id;
                                    }));
                      }));
                if (!isPropertyOnFilteredOutEvent || isPropertyOnSomeNewEvent) {
                  return [
                          Belt_Array.concat(newProperties, [property]),
                          filteredOutProperties
                        ];
                }
                var filteredOutEventsWithThisProperty = Belt_Array.keepMapU(filteredOutEvents, (function (filteredOutEventInfo) {
                        var filteredOutEvent = filteredOutEventInfo._0;
                        var isPropertyOnEvent = Belt_Array.someU(filteredOutEvent.propertyIds, (function (param) {
                                return param[0] === property.id;
                              }));
                        if (isPropertyOnEvent) {
                          return [
                                  filteredOutEvent,
                                  filteredOutEventInfo._1
                                ];
                        }
                        
                      }));
                return [
                        newProperties,
                        Belt_Array.concat(filteredOutProperties, [{
                                TAG: /* AllParentEventsWereFilteredOut */1,
                                _0: property,
                                _1: filteredOutEventsWithThisProperty
                              }])
                      ];
              }));
}

function getSamePropertyInModel(model, property, importMethod) {
  return Belt_List.head(Belt_List.keepMapU(model.properties, (function (modelPropertyRef) {
                    return Belt_Option.flatMapU(ModelUtils.resolveProperty(model, modelPropertyRef), (function (modelProperty) {
                                  if (importMethod === "AddOnly") {
                                    if (modelProperty.name === property.name && modelProperty.type_ === property.type_ && modelProperty.list === property.list) {
                                      return modelProperty;
                                    } else {
                                      return ;
                                    }
                                  } else if (modelProperty.name === property.name) {
                                    return modelProperty;
                                  } else {
                                    return ;
                                  }
                                }));
                  })));
}

function filterOutPropertiesThatAreAlreadyInTheModel(model, parsedProperties, param, importMethod) {
  var match = Belt_Array.reduceU(parsedProperties, [
        [],
        [],
        []
      ], (function (param, property) {
          var filteredOutPropertiesInfo = param[2];
          var updatedProperties = param[1];
          var newProperties = param[0];
          var modelProperty = getSamePropertyInModel(model, property, importMethod);
          if (modelProperty === undefined) {
            return [
                    Belt_Array.concat(newProperties, [property]),
                    updatedProperties,
                    filteredOutPropertiesInfo
                  ];
          }
          var modelPropertyMatches = GetAllPropertyValuesUseCase.getWithLegacyFallback(ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"), modelProperty);
          var hasNewMatches = propertyHasUpdatedMatches(property, modelProperty, model);
          var hasUpdatedDescription = propertyHasUpdatedDescription(importMethod, property, modelProperty);
          var hasTypeUpdate = propertyHasUpdatedType(importMethod, property, modelProperty);
          var hasListTypeUpdate = propertyHasUpdatedListType(importMethod, property, modelProperty);
          var isUpdated = propertyHasUpdates(importMethod, property, modelProperty, model);
          if (isUpdated) {
            return [
                    newProperties,
                    Belt_Array.concat(updatedProperties, [{
                            id: modelProperty.id,
                            name: property.name,
                            uniqueNameIndex: property.uniqueNameIndex,
                            description: hasUpdatedDescription ? property.description : modelProperty.description,
                            type_: hasTypeUpdate ? property.type_ : modelProperty.type_,
                            list: hasListTypeUpdate ? property.list : modelProperty.list,
                            matches: hasNewMatches ? ArrayExt.$$String.dedupe(Belt_Array.concat(modelPropertyMatches, property.matches)) : modelPropertyMatches,
                            warnings: property.warnings,
                            presence: property.presence,
                            sendAs: property.sendAs,
                            nameMapping: property.nameMapping
                          }]),
                    Belt_Array.concat(filteredOutPropertiesInfo, [{
                            TAG: /* PropertyAlreadyInTrackingPlan */0,
                            _0: property,
                            _1: {
                              TAG: /* Property */1,
                              _0: modelProperty
                            }
                          }])
                  ];
          } else {
            return [
                    newProperties,
                    updatedProperties,
                    Belt_Array.concat(filteredOutPropertiesInfo, [{
                            TAG: /* PropertyAlreadyInTrackingPlan */0,
                            _0: property,
                            _1: {
                              TAG: /* Property */1,
                              _0: modelProperty
                            }
                          }])
                  ];
          }
        }));
  return [
          match[0],
          match[1],
          match[2]
        ];
}

function updatePropertyIdsOnEventsAfterFiltering(model, filteredOutParsedProperties, parsedEvents) {
  var getFilteredModelProperty = function (parsedPropertyId) {
    return Belt_Array.get(Belt_Array.keepMap(filteredOutParsedProperties, (function (filteredOutPropertyInfo) {
                      if (filteredOutPropertyInfo.TAG === /* PropertyAlreadyInTrackingPlan */0 && filteredOutPropertyInfo._0.id === parsedPropertyId) {
                        return ModelUtils.resolveProperty(model, filteredOutPropertyInfo._1);
                      }
                      
                    })), 0);
  };
  return Belt_Array.mapU(parsedEvents, (function ($$event) {
                return {
                        id: $$event.id,
                        name: $$event.name,
                        uniqueNameIndex: $$event.uniqueNameIndex,
                        description: $$event.description,
                        categories: $$event.categories,
                        tags: $$event.tags,
                        propertyIds: Belt_Array.mapU($$event.propertyIds, (function (param) {
                                var pinnedValue = param[1];
                                var oldPropId = param[0];
                                var modelProperty = getFilteredModelProperty(oldPropId);
                                if (modelProperty !== undefined) {
                                  return [
                                          modelProperty.id,
                                          pinnedValue
                                        ];
                                } else {
                                  return [
                                          oldPropId,
                                          pinnedValue
                                        ];
                                }
                              })),
                        updatedPropertyIds: $$event.updatedPropertyIds,
                        propertyBundleIds: $$event.propertyBundleIds,
                        sources: $$event.sources,
                        stakeholderDomains: $$event.stakeholderDomains,
                        warnings: $$event.warnings,
                        nameMapping: $$event.nameMapping
                      };
              }));
}

function replacePropertyRefsWithPropertyBundleRefs(model, parsedEvents, parsedPropertyBundles) {
  return Belt_Array.reduceU(parsedEvents, [
              [],
              parsedPropertyBundles
            ], (function (param, $$event) {
                var parsedPropertyBundles = param[1];
                var modelPropertyGroupsForEvent = Belt_Array.keepU(Belt_List.toArray(model.propertyBundles), (function (propertyGroup) {
                        if (Belt_List.length(propertyGroup.properties) > 0) {
                          return Belt_Array.everyU(Belt_List.toArray(propertyGroup.properties), (function (propertyId) {
                                        return Belt_Array.someU($$event.propertyIds, (function (param) {
                                                      return param[0] === propertyId;
                                                    }));
                                      }));
                        } else {
                          return false;
                        }
                      }));
                var parsedPropertyGroupsForEvent = Belt_Array.keepU(parsedPropertyBundles, (function (propertyGroup) {
                        if (propertyGroup.propertiesUsedOnlyInThisPropertyBundle.length !== 0) {
                          return Belt_Array.everyU(propertyGroup.propertiesUsedOnlyInThisPropertyBundle, (function (propertyGroupProperty) {
                                        return Belt_Array.someU($$event.propertyIds, (function (param) {
                                                      return param[0] === propertyGroupProperty.id;
                                                    }));
                                      }));
                        } else {
                          return false;
                        }
                      }));
                var modelPropertyIdsInPropertyGroupsForEvent = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(modelPropertyGroupsForEvent, (function (propertyGroup) {
                                    return Belt_List.toArray(propertyGroup.properties);
                                  })))));
                var parsedPropertyIdsInPropertyGroupsForEvent = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.mapU(parsedPropertyGroupsForEvent, (function (propertyGroup) {
                                    return Belt_Array.map(propertyGroup.propertiesUsedOnlyInThisPropertyBundle, (function (property) {
                                                  return property.id;
                                                }));
                                  })))));
                var propertyIdsInPropertyGroupsForEvent = Belt_Array.concat(modelPropertyIdsInPropertyGroupsForEvent, parsedPropertyIdsInPropertyGroupsForEvent);
                var propertyBundleIdsForEvent = Belt_Array.concat(Belt_Array.map(modelPropertyGroupsForEvent, (function (group) {
                            return group.id;
                          })), Belt_Array.map(parsedPropertyGroupsForEvent, (function (group) {
                            return group.id;
                          })));
                return [
                        Belt_Array.concat(param[0], [{
                                id: $$event.id,
                                name: $$event.name,
                                uniqueNameIndex: $$event.uniqueNameIndex,
                                description: $$event.description,
                                categories: $$event.categories,
                                tags: $$event.tags,
                                propertyIds: Belt_Array.keepU($$event.propertyIds, (function (param) {
                                        var propertyId = param[0];
                                        return Belt_Array.everyU(propertyIdsInPropertyGroupsForEvent, (function (propertyIdInGroup) {
                                                      return propertyIdInGroup !== propertyId;
                                                    }));
                                      })),
                                updatedPropertyIds: $$event.updatedPropertyIds,
                                propertyBundleIds: Belt_Array.concat($$event.propertyBundleIds, propertyBundleIdsForEvent),
                                sources: $$event.sources,
                                stakeholderDomains: $$event.stakeholderDomains,
                                warnings: $$event.warnings,
                                nameMapping: $$event.nameMapping
                              }]),
                        Belt_Array.reduceU(modelPropertyGroupsForEvent, parsedPropertyBundles, (function (parsedPropertyBundles, modelPropertyBundle) {
                                var hasBundle = Belt_Array.someU(parsedPropertyBundles, (function (parsedPropertyBundle) {
                                        return parsedPropertyBundle.id === modelPropertyBundle.id;
                                      }));
                                if (hasBundle) {
                                  return parsedPropertyBundles;
                                } else {
                                  return Belt_Array.concat(parsedPropertyBundles, [{
                                                id: modelPropertyBundle.id,
                                                name: modelPropertyBundle.name,
                                                description: modelPropertyBundle.description,
                                                propertiesUsedOnlyInThisPropertyBundle: Belt_Array.mapU(Belt_List.toArray(modelPropertyBundle.properties), (function (id) {
                                                        return {
                                                                id: id,
                                                                name: Belt_Option.mapWithDefaultU(ModelUtils.resolvePropertyById(id, model), "", (function (param) {
                                                                        return param.name;
                                                                      }))
                                                              };
                                                      })),
                                                propertiesUsedInMultiplePropertyBundles: []
                                              }]);
                                }
                              }))
                      ];
              }));
}

var Filtering = {
  filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew: filterOutEventsThatAreAlreadyInTheModelAndDontContainAnythingNew,
  filterOutPropertiesThatAreOnlyOnFilteredOutEvents: filterOutPropertiesThatAreOnlyOnFilteredOutEvents,
  getSamePropertyInModel: getSamePropertyInModel,
  filterOutPropertiesThatAreAlreadyInTheModel: filterOutPropertiesThatAreAlreadyInTheModel,
  updatePropertyIdsOnEventsAfterFiltering: updatePropertyIdsOnEventsAfterFiltering,
  replacePropertyRefsWithPropertyBundleRefs: replacePropertyRefsWithPropertyBundleRefs
};

export {
  Utils ,
  Filtering ,
}
/* Domains Not a pure module */
