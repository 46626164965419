// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconSend(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light07;
  return React.createElement("svg", {
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", {
                  className: Curry._1(Css.style, {
                        hd: Css_Legacy_Core.SVG.fill(color),
                        tl: {
                          hd: Css_Legacy_Core.SVG.stroke(color),
                          tl: /* [] */0
                        }
                      }),
                  strokeWidth: "1"
                }, React.createElement("polyline", {
                      fill: "none",
                      points: " 4.504,9.503 12.5,15.5 15.5,0.5 0.5,6.5 2.502,8.001 ",
                      strokeLinecap: "round"
                    }), React.createElement("polyline", {
                      fill: "none",
                      points: " 7.63,11.848 4.5,15.5 4.504,9.503 9,6.5 ",
                      strokeLinecap: "round"
                    })));
}

var make = IconSend;

export {
  make ,
}
/* Css Not a pure module */
