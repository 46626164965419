// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";

function get(property) {
  var name = property.uniqueName;
  var name$1 = name !== undefined && name !== "" ? name : property.name;
  return Js_string.replaceByRe(/[^\w\s\-]/ig, "", name$1);
}

export {
  get ,
}
/* No side effect */
