// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NewEventModal from "./NewEventModal.mjs";
import * as IconChevronLight from "./IconChevronLight.mjs";
import * as PublishingEmptyIllustration from "./emptystates/PublishingEmptyIllustration.mjs";

var analyticsSteps = [
  "ImportAndGetFeedbackOnYourTrackingPlan",
  "OpenABranchAndMakeYourFirstChanges",
  "GrabDetailedImplementationDetails",
  "ConfigureAPublishingIntegration"
];

var stepContentStyles = Curry._1(Css.style, {
      hd: Css.selector(" p", {
            hd: Css.padding2(Css.px(12), Css.px(0)),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.paddingBottom(Css.px(16)),
        tl: /* [] */0
      }
    });

var docsContainerStyles = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: /* [] */0
    });

var helpfulLinkStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.linkReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(4), Css.px(12)),
              tl: {
                hd: Css.display("grid"),
                tl: {
                  hd: Css.gridTemplateColumns({
                        hd: Css.px(26),
                        tl: {
                          hd: "auto",
                          tl: {
                            hd: Css.px(16),
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: {
                    hd: Css.gridGap(Css.px(8)),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: {
                            hd: Css.textAlign("left"),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.backgroundColor(Styles.Color.light02),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function OnboardingProgressSteps$DocsLink(Props) {
  var url = Props.url;
  var label = Props.label;
  var onInteraction = Props.onInteraction;
  return React.createElement("div", {
              className: docsContainerStyles
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: Styles.Color.light10,
                  children: "FROM THE DOCS"
                }), React.createElement("a", {
                  className: helpfulLinkStyles,
                  href: url,
                  rel: "noopener",
                  target: "_blank",
                  onClick: (function (param) {
                      Curry._2(onInteraction, label, "ClickLinkToDocs");
                    })
                }, React.createElement(Icons.Docs.make, {}), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      children: label
                    }), React.createElement(IconChevronLight.make, {
                      size: 12,
                      color: Styles.Color.light07,
                      direction: "Right"
                    })));
}

var DocsLink = {
  docsContainerStyles: docsContainerStyles,
  helpfulLinkStyles: helpfulLinkStyles,
  make: OnboardingProgressSteps$DocsLink
};

var videoStyles = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.marginTop(Css.px(24)),
        tl: {
          hd: Css.marginBottom(Css.px(12)),
          tl: {
            hd: Css.width(Css.pct(100)),
            tl: {
              hd: Css.boxShadow(Styles.Shadow.standard),
              tl: /* [] */0
            }
          }
        }
      }
    });

function OnboardingProgressSteps$Video(Props) {
  var url = Props.url;
  return React.createElement("video", {
              className: videoStyles,
              autoPlay: true,
              controls: true,
              loop: true,
              muted: true,
              src: url
            });
}

var Video = {
  videoStyles: videoStyles,
  make: OnboardingProgressSteps$Video
};

function OnboardingProgressSteps$CallToAction(Props) {
  var label = Props.label;
  var onClick = Props.onClick;
  var onInteraction = Props.onInteraction;
  var onClick$1 = function ($$event) {
    Curry._2(onInteraction, label, "ClickCTA");
    Curry._1(onClick, $$event);
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin2(Css.px(8), "auto"),
                    tl: /* [] */0
                  })
            }, React.createElement(Button.make, {
                  label: label,
                  onClick: onClick$1,
                  size: "large"
                }));
}

var CallToAction = {
  make: OnboardingProgressSteps$CallToAction
};

function OnboardingProgressSteps$Tooltip(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.marginTop(Css.px(8)),
                    tl: {
                      hd: Css.padding2(Css.px(4), Css.px(16)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.lightBlue),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.darkBlue),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, children);
}

var Tooltip = {
  make: OnboardingProgressSteps$Tooltip
};

function steps(importAction, createEventAction, openBranchAction, createIntegrationAction, param) {
  return [
          {
            index: 0,
            completed: Belt_Option.isSome(importAction !== undefined ? importAction : createEventAction),
            title: "Import and get feedback on your tracking plan",
            subtitle: "Build your single source of truth"
          },
          {
            index: 1,
            completed: Belt_Option.isSome(openBranchAction),
            title: "Open a branch and make your first changes",
            subtitle: "Design quality data safely on a tracking plan branch"
          },
          {
            index: 2,
            completed: Belt_Option.isSome(createIntegrationAction),
            title: "Configure a publishing integration",
            subtitle: "Keep your tracking plan up-to-date, everywhere"
          }
        ];
}

function stepsContent(closeSlideOver, globalSend, openBranches, onInteraction) {
  var openNewEventModal = NewEventModal.useOpen("OnboardingStep");
  var tmp;
  if (openBranches) {
    var branchId = openBranches.hd[0];
    tmp = React.createElement(OnboardingProgressSteps$CallToAction, {
          label: "Review your changes",
          onClick: (function (param) {
              Router.Schema.pushSchemaRoute({
                    NAME: "branch",
                    VAL: branchId
                  }, undefined, {
                    NAME: "diff",
                    VAL: "index"
                  });
            }),
          onInteraction: onInteraction
        });
  } else {
    tmp = React.createElement(OnboardingProgressSteps$CallToAction, {
          label: "Create a new branch",
          onClick: (function (param) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "NewBranch",
                      VAL: [
                        (function (param, param$1) {
                            Curry._1(closeSlideOver, undefined);
                          }),
                        ""
                      ]
                    }
                  });
            }),
          onInteraction: onInteraction
        });
  }
  var tmp$1;
  if (openBranches) {
    var branchId$1 = openBranches.hd[0];
    tmp$1 = React.createElement(OnboardingProgressSteps$CallToAction, {
          label: "Merge branch",
          onClick: (function (param) {
              Router.Schema.pushSchemaRoute({
                    NAME: "branch",
                    VAL: branchId$1
                  }, undefined, {
                    NAME: "diff",
                    VAL: "index"
                  });
            }),
          onInteraction: onInteraction
        });
  } else {
    tmp$1 = React.createElement(OnboardingProgressSteps$CallToAction, {
          label: "Create a new branch",
          onClick: (function (param) {
              Curry._1(globalSend, {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "NewBranch",
                      VAL: [
                        (function (param, param$1) {
                            Curry._1(closeSlideOver, undefined);
                          }),
                        ""
                      ]
                    }
                  });
            }),
          onInteraction: onInteraction
        });
  }
  return [
          React.createElement("div", {
                className: stepContentStyles
              }, React.createElement($$Text.make, {
                    element: "H1",
                    size: "Large",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Import and get feedback on your tracking plan"
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "First step towards better product analytics is to build a single source of truth for your tracking plan. You can easily get started by importing your plan from Amplitude, Mixpanel, and Google Sheets."
                  }), React.createElement(OnboardingProgressSteps$CallToAction, {
                    label: "Import your tracking plan",
                    onClick: (function (param) {
                        Router.push(undefined, Router.Schema.getImportRoute(undefined, "OnboardingChecklist", {
                                  NAME: "import",
                                  VAL: "index"
                                }));
                        Curry._1(closeSlideOver, undefined);
                      }),
                    onInteraction: onInteraction
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/import_2_1080p.mp4"
                  }), React.createElement(OnboardingProgressSteps$DocsLink, {
                    url: "https://www.avo.app/docs/workspace/tracking-plan/importing",
                    label: "Import Existing Tracking Plan",
                    onInteraction: onInteraction
                  }), React.createElement(Spacer.make, {
                    height: 16
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "Alternatively if you do not have a tracking plan, you can start a new one with Avo."
                  }), React.createElement(OnboardingProgressSteps$CallToAction, {
                    label: "Create your first event",
                    onClick: (function (param) {
                        Curry._1(openNewEventModal, undefined);
                      }),
                    onInteraction: onInteraction
                  }), React.createElement(Spacer.make, {
                    height: 16
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "The issue reporter flags conflicts, inconsistencies, and missing types and descriptions in your tracking plan. It's meant to help teams follow their naming convention and surface issues like when a type is missing from a property. Read more about how to resolve your issues in the docs."
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/issue_reporter_1080p.mp4"
                  }), React.createElement(OnboardingProgressSteps$DocsLink, {
                    url: "https://www.avo.app/docs/audit/rules",
                    label: "The Tracking Plan Audit",
                    onInteraction: onInteraction
                  })),
          React.createElement("div", {
                className: stepContentStyles
              }, React.createElement($$Text.make, {
                    size: "Large",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Open a branch and make your first changes"
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "The first thing you do for a new feature or iteration is to open up a new branch."
                  }), React.createElement(OnboardingProgressSteps$CallToAction, {
                    label: "Create a new branch",
                    onClick: (function (param) {
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "NewBranch",
                                VAL: [
                                  (function (param, param$1) {
                                      Curry._1(closeSlideOver, undefined);
                                    }),
                                  ""
                                ]
                              }
                            });
                      }),
                    onInteraction: onInteraction
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/create_branch_1080p.mp4"
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "Branches allow you to draft changes to the tracking plan in isolation from the main branch."
                  }), React.createElement(OnboardingProgressSteps$CallToAction, {
                    label: "Add new event",
                    onClick: (function (param) {
                        Curry._1(openNewEventModal, undefined);
                      }),
                    onInteraction: onInteraction
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "Add new events and properties to make sure your data stays clean and consistent. With Avo you only define once and use across platforms to prevent duplicates."
                  }), React.createElement(OnboardingProgressSteps$Tooltip, {
                    children: React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          lineHeight: Css.pct(170.0),
                          children: "Try naming it \"accounted_created\" and see how Avo gives you feedback on the inconsistent casing."
                        })
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/event_naming_assistant_1080p.mp4"
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "When you are done defining new events and properties, review your drafted changes to your tracking plan before sharing them with a teammate."
                  }), tmp, React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/view_changes_1080.mp4"
                  })),
          React.createElement("div", {
                className: stepContentStyles
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        })
                  }, React.createElement(PublishingEmptyIllustration.make, {
                        width: 240
                      })), React.createElement($$Text.make, {
                    size: "Large",
                    weight: "Semi",
                    color: Styles.Color.light11,
                    children: "Configure a publishing integration"
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "With Avo, you document your events, properties and definitions once and sync all your metadata straight into Amplitude or Mixpanel by setting up publishing integrations."
                  }), React.createElement(OnboardingProgressSteps$CallToAction, {
                    label: "Set up your first publishing integration",
                    onClick: (function (param) {
                        Router.Schema.pushSchemaRoute(undefined, undefined, "integrations");
                        Curry._1(closeSlideOver, undefined);
                      }),
                    onInteraction: onInteraction
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/manual_integration_publish_1080.mp4"
                  }), React.createElement(OnboardingProgressSteps$DocsLink, {
                    url: "https://www.avo.app/docs/workspace/tracking-plan/publishing",
                    label: "Publishing",
                    onInteraction: onInteraction
                  }), React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "When you are done making changes, either merge them into the main branch or discard your changes by deleting the branch"
                  }), tmp$1, React.createElement($$Text.make, {
                    size: "Medium",
                    weight: "Regular",
                    color: Styles.Color.light10,
                    lineHeight: Css.pct(170.0),
                    children: "When Auto Publishing is enabled, your tracking plan changes are automatically synced when a branch is merged."
                  }), React.createElement(OnboardingProgressSteps$Video, {
                    url: "https://cdn.avo.app/assets/merge_and_auto_publish_1080.mp4"
                  }), React.createElement(OnboardingProgressSteps$Tooltip, {
                    children: React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Regular",
                          color: Styles.Color.light10,
                          lineHeight: Css.pct(170.0),
                          children: "Ask a developer to install the Inspector SDK and get real-time verification of your tracking plan implementation"
                        })
                  }), React.createElement(OnboardingProgressSteps$DocsLink, {
                    url: "https://www.avo.app/docs/implementation/avo-inspector-sdk-reference",
                    label: "Avo Inspector SDK",
                    onInteraction: onInteraction
                  }))
        ];
}

export {
  analyticsSteps ,
  stepContentStyles ,
  DocsLink ,
  Video ,
  CallToAction ,
  Tooltip ,
  steps ,
  stepsContent ,
}
/* stepContentStyles Not a pure module */
