// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";

function renderRow(label, value) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.padding2(Css.px(15), Css.px(20)),
                          tl: {
                            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 1.0
                            }),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: /* [] */0
                          }
                        }
                      })
                }, label), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flex({
                              NAME: "num",
                              VAL: 2.0
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.justifyContent("flexEnd"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, value));
}

function DiffDestination(Props) {
  var masterModel = Props.masterModel;
  var branchModel = Props.branchModel;
  var actions = Props.actions;
  var match = Belt_Option.map(Belt_List.head(actions), (function (prim) {
          return prim[0];
        }));
  if (match === undefined) {
    return null;
  }
  if (typeof match !== "object") {
    return null;
  }
  if (match.NAME !== "Destination") {
    return null;
  }
  var destinationId = match.VAL;
  var actions$1 = Belt_List.map(actions, (function (prim) {
          return prim[1];
        }));
  if (!actions$1) {
    return null;
  }
  var hasCreate = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x === "object") {
            return x.NAME === "CreateDestination";
          } else {
            return false;
          }
        }));
  var hasArchive = Belt_List.getBy(actions$1, (function (x) {
          if (typeof x !== "object") {
            return false;
          }
          if (x.NAME !== "Archive") {
            return false;
          }
          var match = x.VAL;
          if (typeof match === "object") {
            return match.NAME === "Destination";
          } else {
            return false;
          }
        }));
  var changeType = hasCreate !== undefined ? "Create" : (
      hasArchive !== undefined ? "Archive" : "Update"
    );
  var destination = Belt_Option.getWithDefault(Belt_List.getBy(masterModel.destinations, (function (destination) {
              return destination.id === destinationId;
            })), TrackingPlanModel.emptyDestination(destinationId));
  var nextDestination = Belt_Option.getWithDefault(Belt_List.getBy(branchModel.destinations, (function (destination) {
              return destination.id === destinationId;
            })), TrackingPlanModel.emptyDestination(destinationId));
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.maxWidth(DiffComponents.maxWidthPx),
                    tl: {
                      hd: Css.marginRight(Css.px(DiffComments.commentsWidth + 20 | 0)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.white),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.regular),
                            tl: {
                              hd: Css.padding(Css.px(15)),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.backgroundColor(Styles.Color.light02),
                                        tl: /* [] */0
                                      }),
                                  tl: changeType === "Create" ? ({
                                        hd: Css.border(Css.px(1), "solid", Styles.Color.green),
                                        tl: /* [] */0
                                      }) : (
                                      changeType === "Update" ? ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                            tl: /* [] */0
                                          }) : ({
                                            hd: Css.border(Css.px(1), "solid", Styles.Color.red),
                                            tl: /* [] */0
                                          })
                                    )
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: (function (param) {
                  Router.Schema.pushDrawerItem(undefined, undefined, {
                        NAME: "destination",
                        VAL: destination.id
                      });
                })
            }, React.createElement("div", undefined, renderRow("Destination Name", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(destination.name, ""), Belt_Option.getWithDefault(nextDestination.name, ""))), Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateDestinationAnalyticsTool";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Analytics Tool", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.mapWithDefault(destination.type_, "", AvoConfig.analyticsToolsLabel), Belt_Option.mapWithDefault(nextDestination.type_, "", AvoConfig.analyticsToolsLabel))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateDestinationDevApiKey";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Development API Key", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(destination.apiKeys.dev, ""), Belt_Option.getWithDefault(nextDestination.apiKeys.dev, ""))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateDestinationProdApiKey";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Production API Key", JsDiff.renderStringDiff(undefined, undefined, undefined, Belt_Option.getWithDefault(destination.apiKeys.prod, ""), Belt_Option.getWithDefault(nextDestination.apiKeys.prod, ""))) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateDestinationIncludeUserPropsWithEventProps";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Include user properties with event properties", React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.color(nextDestination.includeUserPropsWithEventProps ? Styles.Color.green : Styles.Color.red),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  }
                                })
                          }, nextDestination.includeUserPropsWithEventProps ? "On" : "Off")) : null, Belt_Option.isSome(Belt_List.getBy(actions$1, (function (x) {
                            if (typeof x === "object") {
                              return x.NAME === "UpdateDestinationDisabledByDefault";
                            } else {
                              return false;
                            }
                          }))) ? renderRow("Disabled by default", React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.color(nextDestination.disabledByDefault ? Styles.Color.green : Styles.Color.red),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: /* [] */0
                                  }
                                })
                          }, nextDestination.disabledByDefault ? "On" : "Off")) : null));
}

var make = DiffDestination;

export {
  renderRow ,
  make ,
}
/* Css Not a pure module */
