// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as FileView from "../FileView.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TimeMachine from "../TimeMachine.mjs";
import LodashDebounce from "lodash.debounce";

function fetchIntegrationExport(schemaId, branchId, integrationId, filename, beforeEqualDate) {
  return Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser)).getIdToken().then(function (token) {
                  return fetch(Firebase.apiUrl + "/c/v1/export", Fetch.RequestInit.make(/* Post */2, [
                                    [
                                      "Accept",
                                      "application/json"
                                    ],
                                    [
                                      "Content-Type",
                                      "application/json"
                                    ],
                                    [
                                      "Authorization",
                                      "Bearer " + token
                                    ]
                                  ], Caml_option.some(JSON.stringify(Js_dict.fromArray(Js_array.concat([
                                                    [
                                                      "schemaId",
                                                      schemaId
                                                    ],
                                                    [
                                                      "branchId",
                                                      branchId
                                                    ],
                                                    [
                                                      "integrations",
                                                      [Js_dict.fromArray([
                                                              [
                                                                "id",
                                                                integrationId
                                                              ],
                                                              [
                                                                "path",
                                                                filename
                                                              ]
                                                            ])]
                                                    ],
                                                    [
                                                      "requestClient",
                                                      "web"
                                                    ]
                                                  ], beforeEqualDate !== undefined ? [[
                                                        "beforeEqualDate",
                                                        beforeEqualDate
                                                      ]] : [])))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                }).then(function (response) {
                return Fetch.$$Response.json(response).then(function (json) {
                            return Promise.resolve([
                                        response,
                                        json
                                      ]);
                          });
              }).then(function (param) {
              if (param[0].status < 300) {
                return Promise.resolve(param[1]);
              } else {
                return Pervasives.failwith("Could not fetch export");
              }
            });
}

function IntegrationExport(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var integration = Props.integration;
  var availability = Props.availability;
  var match = React.useState(function () {
        if (availability === "Unavailable") {
          return /* Unavailable */3;
        } else {
          return /* Initial */0;
        }
      });
  var setStatus = match[1];
  var status = match[0];
  var match$1 = TimeMachine.use(undefined);
  var whenAmI = match$1.whenAmI;
  var debouncedUpdateExport = LodashDebounce((function (schemaId, branchId, integration) {
          Curry._1(setStatus, (function (param) {
                  return /* Loading */1;
                }));
          var beforeEqualDate = typeof whenAmI === "object" ? whenAmI.VAL.toISOString() : undefined;
          $$Promise.$$catch(fetchIntegrationExport(schemaId, branchId, integration.id, AvoConfig.getIntegrationFilename(integration), beforeEqualDate).then(function (response) {
                    return Promise.resolve(Curry._1(setStatus, (function (param) {
                                      return /* Success */{
                                              _0: Belt_Array.concatMany(Belt_Array.mapU(response.integrations, (function (integration) {
                                                          return Belt_Array.mapU(integration.code, (function ($$export) {
                                                                        return [
                                                                                $$export.path,
                                                                                $$export.content
                                                                              ];
                                                                      }));
                                                        })))
                                            };
                                    })));
                  }), (function (param) {
                  return Promise.resolve(Curry._1(setStatus, (function (param) {
                                    return /* Error */2;
                                  })));
                }));
        }), 1000, {
        leading: false,
        trailing: true
      });
  var handleFetchExport = function (param) {
    if (availability === "AvailableDuringTrial" || availability === "Available") {
      return debouncedUpdateExport(schemaId, branchId, integration);
    } else {
      return Curry._1(setStatus, (function (param) {
                    return /* Unavailable */3;
                  }));
    }
  };
  React.useEffect((function () {
          if (availability === "Unavailable") {
            Curry._1(setStatus, (function (param) {
                    return /* Unavailable */3;
                  }));
          } else if (typeof status === "number" && !(status === 2 || status === 1)) {
            
          } else {
            handleFetchExport(undefined);
          }
        }), [
        integration,
        availability
      ]);
  var match$2 = integration.config;
  if (match$2 === undefined) {
    return null;
  }
  if (typeof status !== "number") {
    return Belt_Option.mapWithDefault(Belt_Array.get(status._0, 0), null, (function (param) {
                  return React.createElement(FileView.make, {
                              mimeType: AvoConfig.getIntegrationMimeType(integration),
                              filename: param[0],
                              code: param[1],
                              startExpanded: true,
                              key: "fileview"
                            });
                }));
  }
  switch (status) {
    case /* Initial */0 :
        return React.createElement(FileView.make, {
                    filename: "Click to view payload preview...",
                    code: "",
                    loading: true,
                    onExpand: handleFetchExport,
                    key: "fileview"
                  });
    case /* Loading */1 :
        return React.createElement(FileView.make, {
                    filename: "Generating your payload preview...",
                    code: "",
                    loading: true,
                    key: "fileview"
                  });
    case /* Error */2 :
        return "Error";
    case /* Unavailable */3 :
        return React.createElement(FileView.make, {
                    filename: "Payload Preview",
                    code: "Upgrade to payload previewing...",
                    key: "fileview"
                  });
    
  }
}

var make = IntegrationExport;

export {
  fetchIntegrationExport ,
  make ,
}
/* react Not a pure module */
