// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AdvancedNameInput from "./AdvancedNameInput.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";
import * as AdvancedNameInputPlaceholder__Utils from "./AdvancedNameInputPlaceholder__Utils.mjs";

function AdvancedNameInputPlaceholder(Props) {
  var currentName = Props.currentName;
  var disabledOpt = Props.disabled;
  var onChange = Props.onChange;
  var role = Props.role;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setOpened = match[1];
  var match$1 = SchemaBundleContext.use(undefined);
  var match$2 = React.useContext(DiscrepancyContext.context);
  var config = match$2.config;
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(config.events);
  var existingStructure = AdvancedNameInputPlaceholder__Utils.useStructure(match$1.schemaId, ValidationConfigV2.EventsV2.getNameComponents(config.events), currentName);
  var structure = React.useMemo((function () {
          if (typeof existingStructure === "object") {
            return {
                    NAME: "Success",
                    VAL: existingStructure.VAL
                  };
          }
          if (existingStructure !== "NotFound") {
            return "Loading";
          }
          var structure = EventNameComponent.extractComponentsFromName(currentName, nameComponents);
          if (structure !== undefined) {
            return {
                    NAME: "Success",
                    VAL: structure
                  };
          } else {
            return "NotFound";
          }
        }), [
        currentName,
        nameComponents,
        existingStructure
      ]);
  var model = ModelStore.useModel(undefined);
  var existingNamesWithIdAndIsVariant = GetEventNamespaceUseCase.getEventNamespaceWithIdAndIsVariant(model.events);
  var tmp = {
    currentName: currentName,
    getItemLink: (function (eventName) {
        var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
                return param[0] === eventName;
              }));
        if (match === undefined) {
          return ;
        }
        var variantEventId = match[1];
        if (!match[2]) {
          return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                        return Router.Link.addDrawerItem(undefined, {
                                    NAME: "event",
                                    VAL: [
                                      param.id,
                                      undefined,
                                      undefined,
                                      false
                                    ]
                                  });
                      }));
        }
        var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
        var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
        return Router.Link.addDrawerItem(undefined, {
                    NAME: "eventVariant",
                    VAL: [
                      {
                        baseEventId: eventId,
                        variantId: variantId
                      },
                      undefined
                    ]
                  });
      }),
    existingNames: Belt_List.map(existingNamesWithIdAndIsVariant, (function (param) {
            return param[0];
          })),
    onClose: (function (param) {
        Curry._1(setOpened, (function (param) {
                return false;
              }));
      }),
    onChange: (function (a) {
        Curry._1(setOpened, (function (param) {
                return false;
              }));
        return Curry._1(onChange, a);
      }),
    eventEdit: "Update",
    role: role,
    shadow: true
  };
  var tmp$1 = typeof structure === "object" && structure.NAME === "Success" ? structure.VAL : undefined;
  if (tmp$1 !== undefined) {
    tmp.currentStructure = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.wordBreak("breakAll"),
                        tl: {
                          hd: Css.unsafe("whiteSpace", "preserve"),
                          tl: /* [] */0
                        }
                      }),
                  role: "button",
                  title: currentName,
                  onClick: (function (param) {
                      if (structure === "Loading" || disabled) {
                        return ;
                      } else {
                        return Curry._1(setOpened, (function (param) {
                                      return true;
                                    }));
                      }
                    })
                }, React.createElement($$Text.make, {
                      weight: "Semi",
                      lines: 1,
                      children: currentName
                    })), React.createElement(Mantine.Modal.make, {
                  opened: match[0],
                  zIndex: Styles.ZIndex.aboveAll + 1 | 0,
                  bodyStyles: {
                    hd: Css.padding("zero"),
                    tl: {
                      hd: Css.minWidth(Css.px(600)),
                      tl: /* [] */0
                    }
                  },
                  contentStyles: {
                    hd: Css.important(Css.overflowY("visible")),
                    tl: {
                      hd: Css.backgroundColor("transparent"),
                      tl: {
                        hd: Css.boxShadow("none"),
                        tl: /* [] */0
                      }
                    }
                  },
                  overlayStyles: {
                    hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light12, 0.2)),
                    tl: /* [] */0
                  },
                  children: React.createElement(AdvancedNameInput.make, tmp)
                }));
}

var make = AdvancedNameInputPlaceholder;

export {
  make ,
}
/* Css Not a pure module */
