// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as TextInput from "./TextInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";

var root = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(10)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.padding(Css.px(40)),
          tl: {
            hd: Css.width(Css.px(440)),
            tl: {
              hd: Css.boxShadows({
                    hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(18), Css.px(-6), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                    tl: {
                      hd: Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(12), Css.px(42), Css.px(-4), undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.12)),
                      tl: /* [] */0
                    }
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var nameInputBox = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var buttons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.gap(Css.px(16)),
            tl: {
              hd: Css.marginTop(Css.px(24)),
              tl: /* [] */0
            }
          }
        }
      }
    });

function CreateEventVariantModal(Props) {
  var onClose = Props.onClose;
  var eventId = Props.eventId;
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = React.useState(function () {
        return "";
      });
  var setVariantSuffix = match$1[1];
  var variantSuffix = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setCreating = match$2[1];
  var creating = match$2[0];
  var events = ModelStore.Mapped.useEvents(undefined);
  var $$event = Belt_Option.getExn(Curry._2(TrackingPlanMappedModel.Events.get, events, eventId));
  React.useEffect((function () {
          AnalyticsRe.eventVariantInitiated($$event.id, $$event.name, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.schemaId, schemaBundle.branchId, $$event.id);
        }), []);
  var handleCreate = function (param) {
    Curry._1(setCreating, (function (param) {
            return true;
          }));
    var id = Nanoid.nanoid();
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (branch) {
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              Router.Schema.pushDrawerItem(undefined, undefined, {
                    NAME: "eventVariant",
                    VAL: [
                      {
                        baseEventId: eventId,
                        variantId: id
                      },
                      undefined
                    ]
                  });
              AnalyticsRe.eventVariantCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, $$event.id, $$event.name, id, schemaBundle$1.schemaId, schemaBundle$1.branchId, $$event.id);
              Curry._1(onClose, undefined);
            }),
          undefined,
          [[
              {
                NAME: "CreateEventVariant",
                VAL: [
                  {
                    baseEventId: $$event.id,
                    variantId: id
                  },
                  variantSuffix
                ]
              },
              {
                eventId: $$event.id,
                eventQuery: $$event.id,
                eventVariantId: id,
                eventVariantQuery: id
              }
            ]]
        ]);
  };
  var isSuffixEmpty = NameUtils.isEmpty(variantSuffix);
  var existingNamesWithIsVariant = Belt_List.toArray(GetEventNamespaceUseCase.getEventNamespaceWithIsVariant(Curry._1(TrackingPlanMappedModel.Events.toList, events)));
  var globalNameValidation = NameInputUtils.validateInput(Belt_Array.map(existingNamesWithIsVariant, (function (param) {
              return param[0];
            })), "", "" + $$event.name + " " + variantSuffix + "", match.expectedEventCase);
  var match$3 = globalNameValidation.conflictingName;
  var tmp;
  if (creating || isSuffixEmpty || match$3 === undefined) {
    tmp = null;
  } else {
    var existingNameWithIsVariant = Belt_Array.getByU(existingNamesWithIsVariant, (function (param) {
            return param[0] === match$3;
          }));
    tmp = React.createElement($$Text.make, {
          element: "Span",
          color: Styles.Color.darkRed,
          children: existingNameWithIsVariant !== undefined ? (
              existingNameWithIsVariant[1] ? "\"" + match$3 + "\" already exists as an event variant." : "\"" + match$3 + "\" already exists as an event."
            ) : "An event with a conflicting name already exists"
        });
  }
  return React.createElement("section", {
              className: root
            }, React.createElement(Title.make, {
                  children: "Create event variant",
                  size: "Small"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Give the variant a descriptive name. It will not impact how the event name is sent to destinations."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: nameInputBox
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: $$event.name
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light12,
                      children: " -"
                    }), React.createElement(TextInput.make, {
                      autoFocus: true,
                      onChange: (function (newName) {
                          Curry._1(setVariantSuffix, (function (param) {
                                  return newName;
                                }));
                        }),
                      placeholder: "Type a variant name...",
                      value: variantSuffix
                    })), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Small",
                  textAlign: "right",
                  color: Styles.Color.light08,
                  children: tmp
                }), React.createElement("div", {
                  className: buttons
                }, React.createElement(Button.make, {
                      label: "Cancel",
                      onClick: (function (param) {
                          Curry._1(onClose, undefined);
                        }),
                      style: "outline"
                    }), React.createElement(Button.make, {
                      disabled: isSuffixEmpty || Belt_Option.isSome(globalNameValidation.conflictingName),
                      label: "Create variant",
                      loading: creating,
                      onClick: handleCreate
                    })));
}

var make = CreateEventVariantModal;

export {
  make ,
}
/* root Not a pure module */
