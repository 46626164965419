// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";

var context = React.createContext(undefined);

var make = context.Provider;

var Provider = {
  make: make
};

function FocusContext(props) {
  var match = React.useState(function () {
        
      });
  var setFocusElement = match[1];
  var focusElement = match[0];
  React.useEffect((function () {
          var handler = function (e) {
            if (Caml_obj.notequal(Caml_option.nullable_to_opt(document.activeElement), focusElement) && e.type === "focusin") {
              return Curry._1(setFocusElement, (function (param) {
                            return Caml_option.nullable_to_opt(document.activeElement);
                          }));
            } else if (e.type === "focusout") {
              return Curry._1(setFocusElement, (function (param) {
                            
                          }));
            } else {
              return ;
            }
          };
          window.addEventListener("focusin", handler);
          window.addEventListener("focusout", handler);
          return (function (param) {
                    window.removeEventListener("focusin", handler);
                    window.removeEventListener("focusout", handler);
                  });
        }), []);
  return JsxRuntime.jsx(make, {
              value: focusElement,
              children: props.children
            });
}

function use(param) {
  return React.useContext(context);
}

function useHasFocus(element) {
  var focusElement = React.useContext(context);
  return Caml_obj.equal((element == null) ? undefined : Caml_option.some(element), focusElement);
}

function useHasFocusWithin(element) {
  var focusElement = React.useContext(context);
  if (!(element == null) && focusElement !== undefined) {
    return element.contains(Caml_option.valFromOption(focusElement));
  } else {
    return false;
  }
}

var make$1 = FocusContext;

export {
  context ,
  Provider ,
  make$1 as make,
  use ,
  useHasFocus ,
  useHasFocusWithin ,
}
/* context Not a pure module */
