// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as OnboardingProgressSteps from "./OnboardingProgressSteps.mjs";

function useOnboardingProgress(schema) {
  var importAction = FirebaseFetcherHooks.useAction(schema.id, undefined, "CompletedImport");
  var importDeprecated = FirebaseFetcherHooks.useAction(schema.id, undefined, "Import");
  var createEventAction = FirebaseFetcherHooks.useAction(schema.id, undefined, "AddEvent");
  var openBranchAction = FirebaseFetcherHooks.useAction(schema.id, (function (__x) {
          return __x.where("isCreateDemoBranchAutoAction", "==", false);
        }), "OpenBranch");
  var createIntegrationAction = FirebaseFetcherHooks.useAction(schema.id, undefined, "CreateIntegration");
  var hasCopiedBranchDevInstructions = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(schema.onboardingV2), (function (onboarding) {
              return Caml_option.nullable_to_opt(onboarding.branchDevInstructionsDone);
            })), false);
  var actionFetchers = [
    importAction,
    importDeprecated,
    createEventAction,
    openBranchAction,
    createIntegrationAction
  ];
  if (Belt_Array.someU(actionFetchers, (function (status) {
            return status === "Initial";
          }))) {
    return "Initial";
  }
  if (Belt_Array.someU(actionFetchers, (function (status) {
            return status === "Error";
          }))) {
    return "Error";
  }
  if (actionFetchers.length !== 5) {
    return "Error";
  }
  var match = actionFetchers[0];
  if (typeof match !== "object") {
    return "Error";
  }
  if (match.NAME !== "Loaded") {
    return "Error";
  }
  var importAction$1 = match.VAL;
  var match$1 = actionFetchers[1];
  if (typeof match$1 !== "object") {
    return "Error";
  }
  if (match$1.NAME !== "Loaded") {
    return "Error";
  }
  var match$2 = actionFetchers[2];
  if (typeof match$2 !== "object") {
    return "Error";
  }
  if (match$2.NAME !== "Loaded") {
    return "Error";
  }
  var match$3 = actionFetchers[3];
  if (typeof match$3 !== "object") {
    return "Error";
  }
  if (match$3.NAME !== "Loaded") {
    return "Error";
  }
  var match$4 = actionFetchers[4];
  if (typeof match$4 !== "object") {
    return "Error";
  }
  if (match$4.NAME !== "Loaded") {
    return "Error";
  }
  var steps = OnboardingProgressSteps.steps(Belt_Option.isNone(importAction$1) ? match$1.VAL : importAction$1, match$2.VAL, match$3.VAL, match$4.VAL, hasCopiedBranchDevInstructions);
  var currentStep = Belt_Array.getBy(steps, (function (step) {
          return !step.completed;
        }));
  return {
          NAME: "Loaded",
          VAL: [
            steps,
            currentStep
          ]
        };
}

export {
  useOnboardingProgress ,
}
/* FirebaseFetcherHooks Not a pure module */
