// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../Text.mjs";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Actions from "../actions.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorViewInstallDocs from "./InspectorViewInstallDocs.mjs";
import * as InspectorViewInstallDocsJsFamily from "./InspectorViewInstallDocsJsFamily.mjs";
import * as InspectorViewInstallDocsScriptTagCodeSnippet from "./InspectorViewInstallDocsScriptTagCodeSnippet.mjs";
import * as InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet from "./InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.mjs";

function InspectorViewInstallDocsScriptTag(Props) {
  var apiKey = Props.apiKey;
  var sourceGroup = Props.sourceGroup;
  var currentBranch = Props.currentBranch;
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement(React.Fragment, undefined, React.createElement(InspectorViewInstallDocs.Link.make, {
                  path: "implementation/inspector/sdk/web",
                  label: "Inspector HTML tag setup docs"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 1,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Paste the HTML script tag snippet within the <head> tag of your page or add it via Tag Manager."
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Make sure to update __ENV__, __VERSION__ and __APP_NAME__ based on your project."
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), InspectorViewInstallDocsScriptTagCodeSnippet.code(apiKey, schemaBundle, sourceGroup, Actions.branchToId(currentBranch))), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 2,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Send event schemas to Avo"
                    }), InspectorViewInstallDocsJsFamilyTrackEventCodeSnippet.code(InspectorViewInstallDocsJsFamily.docsCodeBoxStyles, InspectorViewInstallDocsJsFamily.docsCodeStyles, InspectorViewInstallDocsJsFamily.docsCodeLightBlueStyles, InspectorViewInstallDocsJsFamily.docsCodeMintGreenStyles, InspectorViewInstallDocsJsFamily.docsCodeOrangeStyles, InspectorViewInstallDocsJsFamily.copyButtonBoxStyles, "js_v2")));
}

var make = InspectorViewInstallDocsScriptTag;

export {
  make ,
}
/* Text Not a pure module */
