// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hover from "./Hover.mjs";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as IconWeb from "./IconWeb.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconExpo from "./IconExpo.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GlobalPill from "./GlobalPill.mjs";
import * as IconMobile from "./IconMobile.mjs";
import * as IconServer from "./IconServer.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ContextMenu from "./ContextMenu.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as PropertyRule from "./PropertyRule.mjs";
import * as PropertyType from "./PropertyType.mjs";
import * as PropertyUtils from "./PropertyUtils.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as IconArrowDownEast from "./IconArrowDownEast.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as PropertyConstraints from "./PropertyConstraints.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as PropertyAbsenceConfig from "./PropertyAbsenceConfig.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as GetEventSpecificPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetEventSpecificPropertyValuesUseCase.mjs";
import * as ViewerCanEditEventOrEventVariantChildUseCase from "./eventVariants/ViewerCanEditEventOrEventVariantChildUseCase.mjs";

function Property$GroupTypePill(Props) {
  var groupTypeName = Props.groupTypeName;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.marginBottom(Css.px(2)),
                    tl: {
                      hd: Css.padding2(Css.px(2), Css.px(8)),
                      tl: {
                        hd: Css.backgroundColor(Styles.Color.light10),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: {
                                  hd: Css.textTransform("uppercase"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icons.Group.make, {
                  color: Styles.Color.light04
                }), React.createElement(Spacer.make, {
                  width: 6
                }), React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Bold",
                  color: Styles.Color.light02,
                  children: groupTypeName
                }));
}

var GroupTypePill = {
  make: Property$GroupTypePill
};

function offerArchivingPropertyIfUnused(events, properties, propertyBundles, propertyId, eventId, schemaBundle, sendActions) {
  var propertyStillInUse = Curry._1(TrackingPlanMappedModel.Events.size, ModelUtils_mapped.eventsSendingProperty(events, propertyBundles, undefined, propertyId)) > 0;
  var propertyName = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId), "Unknown Name", (function (property) {
          return property.name;
        }));
  var $$event = Curry._2(TrackingPlanMappedModel.Events.getExn, events, eventId);
  var property = Curry._2(TrackingPlanMappedModel.Properties.getExn, properties, propertyId);
  var propertyType = AnalyticsUtils.sendAsToPropertyType(property.sendAs);
  var promptRemoveProperty = function (param) {
    AnalyticsRe.propertyDeletePrompted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, $$event.name, propertyName, propertyId, eventId, propertyType, schemaBundle.branchId, schemaBundle.schemaId);
    return window.confirm("The property '" + propertyName + "' is not being used anymore, would you like to permanently delete it from your schema?");
  };
  if (!propertyStillInUse && promptRemoveProperty(undefined)) {
    return Curry.app(sendActions, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                (function (branch) {
                    var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                    AnalyticsRe.propertyDeleted(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, $$event.name, propertyName, eventId, propertyId, propertyType, NamedBranch.getId(branch), schemaBundle.schemaId);
                  }),
                undefined,
                [[
                    {
                      NAME: "Archive",
                      VAL: {
                        NAME: "Property",
                        VAL: propertyId
                      }
                    },
                    {
                      eventId: $$event.id,
                      propertyId: propertyId,
                      propertyQuery: propertyId
                    }
                  ]]
              ]);
  }
  
}

function Property$GlobalPillWithLock(Props) {
  var eventId = Props.eventId;
  var propertyId = Props.propertyId;
  var globalEvents = Curry._1(ModelStore.Mapped.GlobalRequirements.useEvents, undefined);
  var globalProperties = Curry._1(ModelStore.Mapped.GlobalRequirements.useProperties, undefined);
  if (globalEvents === undefined) {
    return null;
  }
  if (globalProperties === undefined) {
    return null;
  }
  var isPropertyLockedForChanges = GlobalRequirementsUtils.propertyIsLockedForChanges(globalEvents, globalProperties, eventId, propertyId);
  return React.createElement(GlobalPill.make, {
              locked: isPropertyLockedForChanges
            });
}

function Property(Props) {
  var property = Props.property;
  var $$event = Props.event;
  var variant = Props.variant;
  var unnecessaryOpt = Props.unnecessary;
  var slowModelMaybeFlattened = Props.slowModelMaybeFlattened;
  var inBundleOpt = Props.inBundle;
  var viewerCanEdit = Props.viewerCanEdit;
  var unnecessary = unnecessaryOpt !== undefined ? unnecessaryOpt : false;
  var inBundle = inBundleOpt !== undefined ? inBundleOpt : false;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalProperty = Curry._1(ModelStore.Mapped.GlobalRequirements.useGlobalProperty, property.id);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var migrations = ModelStore.Mapped.useMigrations(undefined);
  var model = TrackingPlanMappedModel.fromOld(slowModelMaybeFlattened);
  var hasPinnedAndMapBundledProperties = AppFeatureFlag.useFeatureFlag("PinnedAndMapBundledProperties");
  var addedFromVariantOverride = Belt_Option.mapWithDefault(variant, false, (function (variant) {
          if (Belt_MapString.has(variant.propertyOverrides, property.id)) {
            return !Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Events.get, model.events, variant.baseEventId), false, (function ($$event) {
                          return Belt_List.someU($$event.directPropertyRefs, (function (propRef) {
                                        return propRef._0.id === property.id;
                                      }));
                        }));
          } else {
            return false;
          }
        }));
  var isRemovedProperty = !ModelUtils.isPropertyOnEvent_byEvent($$event, property, Curry._1(TrackingPlanMappedModel.PropertyBundles.toList, model.propertyBundles));
  var variantOverridePropertyStatus = variant !== undefined ? (
      addedFromVariantOverride ? "AddedFromVariantOverride" : (
          isRemovedProperty ? "RemovedFromVariantOverride" : "NotVariantOverride"
        )
    ) : "NotVariantOverride";
  var containerRef = React.useRef(null);
  var eventDestinations = AvoModel.destinationsReceivingEvent_mapped(model.destinations, $$event);
  var propertyRef = Belt_List.head(Belt_List.keepMapU($$event.directPropertyRefs, (function (propRef) {
              if (propRef.TAG !== /* PropertyRef */0) {
                return ;
              }
              var propRef$1 = propRef._0;
              if (propRef$1.id === property.id) {
                return propRef$1;
              }
              
            })));
  var pinnedValues = PropertyUtils.getPinnedValues_mapped(propertyRef, property.id, $$event.id, undefined, model, undefined);
  var hasGroupType = React.useMemo((function () {
          var groupTypeId = property.sendAs;
          if (typeof groupTypeId === "number") {
            return ;
          } else {
            return groupTypeId._0;
          }
        }), [property.sendAs]);
  var isUserProperty = property.sendAs === /* UserProperty */2;
  var isGroupProperty = Belt_Option.isSome(hasGroupType);
  var notBuiltIn = property.builtIn === "NotBuiltIn";
  var isSentToIntercom = Belt_List.some(eventDestinations, (function (destination) {
          return Caml_obj.equal(destination.type_, "Intercom");
        }));
  var whitelist = Belt_List.keep($$event.propertyWhitelist, (function (param) {
          return param[0] === "Intercom";
        }));
  var isWhitelisted = isUserProperty || Belt_List.some(whitelist, (function (x) {
          if (x[0] === "Intercom") {
            return x[1] === property.id;
          } else {
            return false;
          }
        }));
  var disabled = isUserProperty || !isWhitelisted && Belt_List.length(whitelist) >= AvoConfig.intercomPropertyLimit;
  var match = property.builtIn;
  var tmp;
  var exit = 0;
  if (match === "NotBuiltIn") {
    if (variantOverridePropertyStatus === "AddedFromVariantOverride") {
      tmp = React.createElement(Tooltip.make, {
            title: "Property added as an override for this variant",
            tooltipPadding: 8,
            withAskUsAnything: false,
            withDismiss: false,
            withTitle: false,
            children: null
          }, React.createElement(Tooltip.Target.make, {
                children: React.createElement(Link.make, {
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "property",
                            VAL: [
                              property.id,
                              undefined
                            ]
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.primaryPurple),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.darkPurple),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      children: React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            singleLine: true,
                            children: property.name
                          })
                    })
              }), React.createElement(Tooltip.Content.make, {
                children: React.createElement($$Text.make, {
                      size: "Small",
                      children: "This property was added on this variant."
                    })
              }));
    } else if (variantOverridePropertyStatus === "RemovedFromVariantOverride") {
      tmp = React.createElement(Tooltip.make, {
            title: "Property excluded as an override for this variant",
            tooltipPadding: 8,
            withAskUsAnything: false,
            withDismiss: false,
            withTitle: false,
            children: null
          }, React.createElement(Tooltip.Target.make, {
                children: React.createElement(Link.make, {
                      path: Router.Link.addDrawerItem(undefined, {
                            NAME: "property",
                            VAL: [
                              property.id,
                              undefined
                            ]
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.color(Styles.Color.paleRed),
                            tl: {
                              hd: Css.hover({
                                    hd: Css.color(Styles.Color.paleRed),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      children: React.createElement($$Text.make, {
                            element: "Span",
                            size: "Medium",
                            weight: "Semi",
                            singleLine: true,
                            color: Styles.Color.light12,
                            children: JsDiff.renderStringDiff(undefined, true, true, property.name, "")
                          })
                    })
              }), React.createElement(Tooltip.Content.make, {
                children: React.createElement($$Text.make, {
                      size: "Small",
                      children: "This property was excluded on this variant."
                    })
              }));
    } else if (variantOverridePropertyStatus === "NotVariantOverride") {
      tmp = React.createElement(Link.make, {
            path: Router.Link.addDrawerItem(undefined, {
                  NAME: "property",
                  VAL: [
                    property.id,
                    undefined
                  ]
                }),
            className: Curry._1(Css.style, {
                  hd: Css.color(Styles.Color.light12),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.darkBlue),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }),
            children: React.createElement($$Text.make, {
                  element: "Span",
                  size: "Medium",
                  weight: "Semi",
                  singleLine: true,
                  children: property.name
                })
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement($$Text.make, {
          element: "Span",
          size: "Medium",
          weight: "Semi",
          singleLine: true,
          color: Styles.Color.light12,
          children: property.name
        });
  }
  var match$1 = Belt_Option.flatMap(variant, (function (v) {
          return Belt_MapString.get(v.propertyOverrides, property.id);
        }));
  var tmp$1;
  var exit$1 = 0;
  if (match$1 !== undefined && match$1) {
    var match$2 = match$1._0.pinnedValue;
    if (match$2) {
      var literal = match$2._0;
      if (literal !== undefined) {
        var pin = PropertyUtils.printPinnedValue(literal);
        tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  color: Styles.Color.darkGreen,
                  backgroundColor: Styles.Color.lightGreen,
                  children: " Pinned to " + pin + " (on this event variant)"
                }));
      } else {
        exit$1 = 1;
      }
    } else {
      exit$1 = 1;
    }
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = pinnedValues ? (
        property.type_ === "object" ? React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.color(Styles.Color.light12),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    color: Styles.Color.light11,
                    children: "Pinned:"
                  }), React.createElement(Spacer.make, {
                    display: "inlineBlock",
                    width: 4
                  }), React.createElement("ul", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin(Css.px(0)),
                          tl: {
                            hd: Css.paddingLeft(Css.px(16)),
                            tl: /* [] */0
                          }
                        })
                  }, Belt_List.toArray(Belt_List.map(pinnedValues, (function (pinnedValue) {
                              return React.createElement("li", {
                                          key: pinnedValue
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              color: Styles.Color.light11,
                                              children: pinnedValue
                                            }));
                            }))))) : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                    display: "inlineBlock",
                    width: 4
                  }), React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    color: Styles.Color.light11,
                    children: "Pinned to"
                  }), React.createElement(Spacer.make, {
                    display: "inlineBlock",
                    width: 4
                  }), React.createElement($$Text.make, {
                    element: "Span",
                    size: "Small",
                    color: Styles.Color.light11,
                    children: Belt_List.toArray(pinnedValues).join(", ")
                  }))
      ) : React.createElement(React.Fragment, undefined, React.createElement(PropertyAbsenceConfig.make, {
                property: property,
                event: $$event,
                viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant),
                variant: variant
              }), Caml_obj.notequal(PropertyConstraints.getConstraints(slowModelMaybeFlattened, model, $$event, property, variant), []) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                      width: 4
                    }), React.createElement(PropertyConstraints.make, {
                      property: property,
                      event: $$event,
                      variant: variant
                    })) : null);
  }
  var unsupportedDestinations = Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(eventDestinations, (function (destination) {
                      var destinationType = destination.type_;
                      if (destinationType !== undefined && !AvoConfig.supportsObjects(destinationType, property.name)) {
                        return AvoConfig.analyticsToolsLabel(destinationType);
                      }
                      
                    })))));
  var isSetOnce = property.operation === "SetOnce";
  var destinations = Belt_List.keepMap(eventDestinations, (function (destination) {
          return destination.type_;
        }));
  var unsupportedDestinationLabels = Belt_List.toArray(BeltListExtensions.dedupeString(Belt_List.keepMap(destinations, AvoConfig.getUnsupportedSetOnceDestinationLabels)));
  var tmp$2;
  if (inBundle && hasPinnedAndMapBundledProperties && variant === undefined) {
    var match$3 = AvoLimits.NameMapping.computeAvailability(workspace);
    tmp$2 = [{
        NAME: "Option",
        VAL: {
          label: "Add Name Mapping" + (
            match$3 === "Available" ? "" : " ⚡️"
          ),
          onClick: (function (param) {
              if (AvoLimits.NameMapping.isAvailable(workspace)) {
                return Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            [[
                                {
                                  NAME: "AddRule",
                                  VAL: [
                                    Nanoid.nanoid(),
                                    {
                                      NAME: "PropertyRef",
                                      VAL: [
                                        $$event.id,
                                        property.id
                                      ]
                                    },
                                    isGlobalWorkspace ? ({
                                          NAME: "NameInLocalWorkspace",
                                          VAL: {
                                            workspace: undefined,
                                            name: undefined
                                          }
                                        }) : ({
                                          NAME: "NameMapping",
                                          VAL: {
                                            destination: undefined,
                                            name: undefined
                                          }
                                        })
                                  ]
                                },
                                {
                                  eventId: $$event.id,
                                  eventQuery: $$event.id,
                                  propertyId: property.id,
                                  propertyQuery: property.id
                                }
                              ]]
                          ]);
              } else {
                return Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "NameMapping"
                            }
                          });
              }
            })
        }
      }];
  } else {
    tmp$2 = [];
  }
  var hasMatches = Caml_obj.notequal(GetEventSpecificPropertyValuesUseCase.getWithLegacyFallback(Belt_Array.some(migrations, (function (migration) {
                  return migration === "EventSpecificAllowedPropertyValues";
                })), property, $$event), []);
  var hasRegexValidation = PropertyUtils.getRegexValidation(property) !== undefined;
  var shouldDisableChangeAllowedValues = !ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) || property.sendAs === /* SystemProperty */0 || property.type_ !== "string" || property.eventSpecificAllowedPropertyValues.length > PropertyUtils.eventSpecificValuesLimit || pinnedValues !== /* [] */0 || hasRegexValidation;
  var shouldDisableEditRegex = !ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) || property.sendAs === /* SystemProperty */0 || property.type_ !== "string" || Caml_obj.notequal(property.eventSpecificAllowedPropertyValues, []) || pinnedValues !== /* [] */0;
  var tmp$3;
  if (!inBundle && property.builtIn === "NotBuiltIn") {
    var tmp$4;
    if (ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant)) {
      var match$4 = AvoLimits.NameMapping.computeAvailability(workspace);
      tmp$4 = [{
          NAME: "Option",
          VAL: {
            label: "Add Name Mapping" + (
              match$4 === "Available" ? "" : " ⚡️"
            ),
            onClick: (function (param) {
                if (AvoLimits.NameMapping.isAvailable(workspace)) {
                  return Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [[
                                  {
                                    NAME: "AddRule",
                                    VAL: [
                                      Nanoid.nanoid(),
                                      {
                                        NAME: "PropertyRef",
                                        VAL: [
                                          $$event.id,
                                          property.id
                                        ]
                                      },
                                      isGlobalWorkspace ? ({
                                            NAME: "NameInLocalWorkspace",
                                            VAL: {
                                              workspace: undefined,
                                              name: undefined
                                            }
                                          }) : ({
                                            NAME: "NameMapping",
                                            VAL: {
                                              destination: undefined,
                                              name: undefined
                                            }
                                          })
                                    ]
                                  },
                                  {
                                    eventId: $$event.id,
                                    eventQuery: $$event.id,
                                    propertyId: property.id,
                                    propertyQuery: property.id
                                  }
                                ]]
                            ]);
                } else {
                  return Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "BillingPrompt",
                                VAL: "NameMapping"
                              }
                            });
                }
              })
          }
        }];
    } else {
      tmp$4 = [];
    }
    var tmp$5;
    if (!ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) || property.sendAs === /* SystemProperty */0) {
      tmp$5 = [];
    } else {
      var match$5 = AvoLimits.PinnedProperties.computeAvailability(workspace);
      tmp$5 = [{
          NAME: "Option",
          VAL: {
            label: (
              pinnedValues ? "Update Pinned Value" : "Pin Property Value"
            ) + (
              match$5 === "Available" ? "" : " ⚡️"
            ),
            onClick: (function (param) {
                var match = AvoLimits.PinnedProperties.computeAvailability(workspace);
                Curry._1(globalSend, {
                      TAG: /* OpenModal */4,
                      _0: match === "AvailableDuringTrial" || match === "Available" ? ({
                            NAME: "PinPropertyValue",
                            VAL: [
                              $$event.id,
                              property.id,
                              undefined
                            ]
                          }) : ({
                            NAME: "BillingPrompt",
                            VAL: "PinnedProperties"
                          })
                    });
              })
          }
        }];
    }
    var tmp$6;
    if (ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant)) {
      if (variant !== undefined) {
        if (property.sendAs === /* EventProperty */1) {
          var match$6 = Belt_MapString.get(variant.propertyOverrides, property.id);
          var hasPinnedValueOnVariant = match$6 !== undefined && match$6 && match$6._0.pinnedValue ? true : false;
          var match$7 = Belt_MapString.get(variant.propertyOverrides, property.id);
          var hasRegexOnVariant = match$7 !== undefined && match$7 && match$7._0.regexValidation ? true : false;
          var hasPropertyRemovedOverride = Caml_obj.equal(Belt_MapString.get(variant.propertyOverrides, property.id), /* Removed */0);
          tmp$6 = Belt_Array.concatMany([
                property.type_ === "object" || hasPropertyRemovedOverride || hasRegexOnVariant ? [] : [{
                      NAME: "Option",
                      VAL: {
                        label: (
                          hasPinnedValueOnVariant ? "Update Pinned Value on Variant" : "Pin Property Value on Variant"
                        ) + (
                          AvoLimits.PinnedProperties.isAvailable(workspace) ? "" : " ⚡️"
                        ),
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: AvoLimits.PinnedProperties.isAvailable(workspace) ? ({
                                        NAME: "PinPropertyValue",
                                        VAL: [
                                          variant.baseEventId,
                                          property.id,
                                          variant.id
                                        ]
                                      }) : ({
                                        NAME: "BillingPrompt",
                                        VAL: "PinnedProperties"
                                      })
                                });
                          })
                      }
                    }],
                !hasPropertyRemovedOverride && Caml_obj.notequal(property.eventSpecificAllowedPropertyValues, []) && !hasPinnedValueOnVariant && !hasRegexOnVariant ? [{
                      NAME: "Option",
                      VAL: {
                        label: "Edit Allowed Property Values on Variant",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "EditEventSpecificPropertyValues",
                                    VAL: [
                                      variant.baseEventId,
                                      property.id,
                                      variant.id
                                    ]
                                  }
                                });
                          })
                      }
                    }] : [],
                !hasPropertyRemovedOverride && !hasPinnedValueOnVariant && Caml_obj.equal(property.eventSpecificAllowedPropertyValues, []) ? [{
                      NAME: "Option",
                      VAL: {
                        label: hasRegexOnVariant ? "Update Regex Rule on Variant" : "Set Regex Rule on Variant",
                        onClick: (function (param) {
                            Curry._1(globalSend, {
                                  TAG: /* OpenModal */4,
                                  _0: {
                                    NAME: "AddRegexValidation",
                                    VAL: [
                                      property.id,
                                      $$event,
                                      variant
                                    ]
                                  }
                                });
                          })
                      }
                    }] : [],
                hasRegexOnVariant ? [{
                      NAME: "Option",
                      VAL: {
                        label: "Clear Regex Rule Override on Variant",
                        onClick: (function (param) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "ClearPropertyRegexOverride", variant.id, property.id, schema.schemaId, schema.branchId, variant.baseEventId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "ClearPropertyRegexOverrideFromEventVariant",
                                        VAL: [
                                          TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                          property.id
                                        ]
                                      },
                                      {
                                        propertyId: property.id,
                                        propertyQuery: property.id,
                                        eventVariantId: variant.id,
                                        eventVariantQuery: variant.id
                                      }
                                    ]]
                                ]);
                          })
                      }
                    }] : [],
                Belt_Option.isSome(Belt_MapString.get(variant.propertyOverrides, property.id)) ? [] : [{
                      NAME: "Option",
                      VAL: {
                        label: "Exclude Property from Variant",
                        onClick: (function (param) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                      AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "RemoveProperty", variant.id, property.id, schemaBundle.schemaId, NamedBranch.getId(branch), variant.baseEventId);
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "RemovePropertyFromEventVariant",
                                        VAL: [
                                          TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                          property.id
                                        ]
                                      },
                                      {
                                        eventId: variant.baseEventId,
                                        eventQuery: variant.baseEventId,
                                        propertyId: property.id,
                                        propertyQuery: property.id,
                                        eventVariantId: variant.id,
                                        eventVariantQuery: variant.id
                                      }
                                    ]]
                                ]);
                          })
                      }
                    }]
              ]);
        } else {
          tmp$6 = [];
        }
      } else {
        tmp$6 = [];
      }
    } else {
      tmp$6 = [];
    }
    tmp$3 = Belt_Array.concatMany([
          tmp$4,
          tmp$5,
          ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant) ? [
              {
                NAME: "Option",
                VAL: {
                  label: "Edit Property",
                  onClick: (function (param) {
                      Router.Schema.pushDrawerItem(undefined, undefined, {
                            NAME: "property",
                            VAL: [
                              property.id,
                              undefined
                            ]
                          });
                    })
                }
              },
              {
                NAME: "Option",
                VAL: {
                  label: "Remove Property",
                  onClick: (function (param) {
                      var match = property.sendAs;
                      if (match === 0 && !window.confirm("This will delete \"" + property.name + "\" from all events. Are you sure you want to continue?")) {
                        return ;
                      } else {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        offerArchivingPropertyIfUnused(model.events, model.properties, model.propertyBundles, property.id, $$event.id, schemaBundle, sendActions);
                                        var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        var propertyBundle = AnalyticsUtils.getAnalyticsPropertyGroup_mapped(model.properties, property);
                                        AnalyticsRe.propertyRemoved(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, propertyBundle.propertyId, property.name, propertyBundle.namingConvention, propertyBundle.propertyType, propertyBundle.correctCase, $$event.id, $$event.name, "PropertyDetails", schema.branchId, schema.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "RemovePropertyRefV3",
                                          VAL: [
                                            $$event.id,
                                            property.id
                                          ]
                                        },
                                        {
                                          eventId: $$event.id,
                                          eventQuery: $$event.id,
                                          propertyId: property.id,
                                          propertyQuery: property.id
                                        }
                                      ]]
                                  ]);
                      }
                    })
                }
              }
            ] : [],
          tmp$6,
          ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, true, variant) ? (
              variant !== undefined ? (
                  Belt_MapString.has(variant.propertyOverrides, property.id) ? [{
                        NAME: "Option",
                        VAL: {
                          label: "Clear Variant Overrides on Property",
                          onClick: (function (param) {
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        AnalyticsRe.eventVariantUpdatedProperty(variant.baseEventId, $$event.name, schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "ClearPropertyOverrides", variant.id, property.id, schemaBundle.schemaId, NamedBranch.getId(branch), variant.baseEventId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "ClearEventVariantPropertyOverride",
                                          VAL: [
                                            TrackingPlanModel.EventVariant.makeVariantIdentifier(variant),
                                            property.id
                                          ]
                                        },
                                        {
                                          eventId: variant.baseEventId,
                                          eventQuery: variant.baseEventId,
                                          propertyId: property.id,
                                          propertyQuery: property.id,
                                          eventVariantId: variant.id,
                                          eventVariantQuery: variant.id
                                        }
                                      ]]
                                  ]);
                            })
                        }
                      }] : []
                ) : []
            ) : []
        ]);
  } else {
    tmp$3 = [];
  }
  var options = Belt_Array.concatMany([
        tmp$2,
        inBundle && hasPinnedAndMapBundledProperties ? [{
              NAME: "Option",
              VAL: {
                label: (
                  pinnedValues === /* [] */0 ? "Pin Property Value" : "Update Pinned Value"
                ) + (
                  AvoLimits.PinnedProperties.isAvailable(workspace) ? "" : " ⚡️"
                ),
                onClick: (function (param) {
                    Curry._1(globalSend, {
                          TAG: /* OpenModal */4,
                          _0: AvoLimits.PinnedProperties.isAvailable(workspace) ? (
                              variant !== undefined ? ({
                                    NAME: "PinPropertyValue",
                                    VAL: [
                                      variant.baseEventId,
                                      property.id,
                                      variant.id
                                    ]
                                  }) : ({
                                    NAME: "PinPropertyValue",
                                    VAL: [
                                      $$event.id,
                                      property.id,
                                      undefined
                                    ]
                                  })
                            ) : ({
                                NAME: "BillingPrompt",
                                VAL: "PinnedProperties"
                              })
                        });
                  })
              }
            }] : [],
        shouldDisableChangeAllowedValues ? [] : [{
              NAME: "Option",
              VAL: {
                label: "" + (
                  hasMatches ? "Edit" : "Add"
                ) + " Allowed Property Values on Event",
                onClick: (function (param) {
                    Curry._1(globalSend, {
                          TAG: /* OpenModal */4,
                          _0: {
                            NAME: "EditEventSpecificPropertyValues",
                            VAL: [
                              $$event.id,
                              property.id,
                              undefined
                            ]
                          }
                        });
                  })
              }
            }],
        shouldDisableEditRegex ? [] : [{
              NAME: "Option",
              VAL: {
                label: "" + (
                  PropertyUtils.getRegexValidation(property) === undefined ? "Add" : "Edit"
                ) + " Regex Validation on Event",
                onClick: (function (param) {
                    if (PropertyUtils.getRegexValidation(property) === undefined) {
                      AnalyticsRe.regexInteractionEventSpecific(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Initiated", property.id, $$event.id, schemaBundle.schemaId, schemaBundle.branchId);
                    }
                    Curry._1(globalSend, {
                          TAG: /* OpenModal */4,
                          _0: {
                            NAME: "AddRegexValidation",
                            VAL: [
                              property.id,
                              $$event,
                              undefined
                            ]
                          }
                        });
                  })
              }
            }],
        tmp$3
      ]);
  return React.createElement("div", {
              ref: containerRef,
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.justifyContent("spaceBetween"),
                        tl: {
                          hd: Css.borderBottomWidth(Css.px(1)),
                          tl: {
                            hd: Css.borderBottomColor(Styles.Color.light04),
                            tl: {
                              hd: Css.borderBottomStyle("solid"),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(10)),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth({
                              NAME: "subtract",
                              VAL: [
                                Css.pct(100),
                                Css.px(24)
                              ]
                            }),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.flexGrow(1.0),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, notBuiltIn && isSentToIntercom && !isGroupProperty ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Hover.make, {
                            children: (function (isHover) {
                                return React.createElement("div", undefined, React.createElement("button", {
                                                className: Curry._1(Css.merge, {
                                                      hd: Curry._1(Css.style, Styles.button),
                                                      tl: {
                                                        hd: Curry._1(Css.style, {
                                                              hd: Css.opacity(isWhitelisted ? 1.0 : (
                                                                      disabled ? 0.0 : 0.5
                                                                    )),
                                                              tl: {
                                                                hd: Css.cursor(disabled ? "default" : "pointer"),
                                                                tl: {
                                                                  hd: Css.filter({
                                                                        hd: {
                                                                          NAME: "grayscale",
                                                                          VAL: isWhitelisted ? 0.0 : 100.0
                                                                        },
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: {
                                                                    hd: Css.marginRight(Css.px(2)),
                                                                    tl: {
                                                                      hd: Css.marginLeft(Css.px(-6)),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                disabled: disabled,
                                                onClick: (function (param) {
                                                    Curry.app(sendActions, [
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          [[
                                                              isWhitelisted ? ({
                                                                    NAME: "RemovePropertyFromWhitelist",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      property.id,
                                                                      "Intercom"
                                                                    ]
                                                                  }) : ({
                                                                    NAME: "AddPropertyToWhitelist",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      property.id,
                                                                      "Intercom"
                                                                    ]
                                                                  }),
                                                              {
                                                                eventId: $$event.id,
                                                                eventQuery: $$event.id,
                                                                propertyId: property.id,
                                                                propertyQuery: property.id
                                                              }
                                                            ]]
                                                        ]);
                                                  })
                                              }, React.createElement(Icons.Destinations.Intercom.make, {
                                                    size: 16
                                                  })), isHover ? React.createElement("div", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.position("absolute"),
                                                        tl: {
                                                          hd: Css.width(Css.px(270)),
                                                          tl: {
                                                            hd: Css.left(Css.px(5)),
                                                            tl: {
                                                              hd: Css.bottom(Css.pct(130)),
                                                              tl: {
                                                                hd: Css.backgroundColor(isWhitelisted ? Css.hex("286efa") : Styles.Color.light10),
                                                                tl: {
                                                                  hd: Css.borderRadius(Styles.Border.radius),
                                                                  tl: {
                                                                    hd: Css.color(Styles.Color.white),
                                                                    tl: {
                                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                                      tl: {
                                                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                                                        tl: {
                                                                          hd: Css.padding2(Css.px(5), Css.px(10)),
                                                                          tl: /* [] */0
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      })
                                                }, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.position("absolute"),
                                                            tl: {
                                                              hd: Css.left(Css.px(5)),
                                                              tl: {
                                                                hd: Css.bottom(Css.px(-10)),
                                                                tl: {
                                                                  hd: Css.border(Css.px(5), "solid", "transparent"),
                                                                  tl: {
                                                                    hd: Css.borderTop(Css.px(5), "solid", isWhitelisted ? Css.hex("286efa") : Styles.Color.light10),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }), isUserProperty ? null : React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.fontSize(Styles.FontSize.tiny),
                                                              tl: /* [] */0
                                                            })
                                                      }, "Intercom accepts 5 properties with each event."), isUserProperty ? "All user properties are sent to Intercom." : (
                                                    isWhitelisted ? "This one is currently sent to Intercom." : "This one is currently not sent to Intercom."
                                                  )) : null);
                              }),
                            key: property.id
                          })) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.width(Css.pct(100)),
                            tl: /* [] */0
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.flexWrap("wrap"),
                                    tl: {
                                      hd: Css.position("relative"),
                                      tl: {
                                        hd: Css.minWidth(Css.px(0)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              })
                        }, Belt_List.toArray(Belt_List.map(property.analyticsTools, (function (destination) {
                                    return React.createElement("div", {
                                                key: AvoConfig.analyticsToolsLabel(destination),
                                                className: Curry._1(Css.style, {
                                                      hd: Css.marginRight(Css.px(5)),
                                                      tl: /* [] */0
                                                    }),
                                                title: "Sent to " + AvoConfig.analyticsToolsLabel(destination)
                                              }, React.createElement(Icons.Destinations.make, {
                                                    destination: destination,
                                                    size: 12
                                                  }));
                                  }))), property.auto ? Belt_List.toArray(Belt_List.map(property.devPlatforms, (function (platform) {
                                      return React.createElement("div", {
                                                  key: AvoConfig.devPlatformLabel(platform),
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.marginRight(Css.px(5)),
                                                        tl: /* [] */0
                                                      })
                                                }, platform === "ReactNative" ? React.createElement("div", {
                                                        title: "Sent from all React Native sources"
                                                      }, React.createElement(IconMobile.make, {
                                                            size: 12
                                                          })) : (
                                                    platform === "DotNet" ? React.createElement("div", {
                                                            title: "Sent from all .NET sources"
                                                          }, React.createElement(IconServer.make, {
                                                                size: 12
                                                              })) : (
                                                        platform === "Android" ? React.createElement("div", {
                                                                title: "Sent from all Android sources"
                                                              }, React.createElement(IconMobile.make, {
                                                                    size: 12
                                                                  })) : (
                                                            platform === "Python" ? React.createElement("div", {
                                                                    title: "Sent from all Python sources"
                                                                  }, React.createElement(IconServer.make, {
                                                                        size: 12
                                                                      })) : (
                                                                platform === "JsonSchema" ? React.createElement("div", {
                                                                        title: "Included in all JSON Schema sources"
                                                                      }, React.createElement(IconWeb.make, {
                                                                            size: 12
                                                                          })) : (
                                                                    platform === "Gtm" ? React.createElement("div", {
                                                                            title: "Included in all Google Tag Manager sources"
                                                                          }, React.createElement(IconWeb.make, {
                                                                                size: 12
                                                                              })) : (
                                                                        platform === "IOS" ? React.createElement("div", {
                                                                                title: "Sent from all iOS sources"
                                                                              }, React.createElement(IconMobile.make, {
                                                                                    size: 12
                                                                                  })) : (
                                                                            platform === "Php" ? React.createElement("div", {
                                                                                    title: "Sent from all PHP sources"
                                                                                  }, React.createElement(IconServer.make, {
                                                                                        size: 12
                                                                                      })) : (
                                                                                platform === "Web" ? React.createElement("div", {
                                                                                        title: "Sent from all web sources"
                                                                                      }, React.createElement(IconWeb.make, {
                                                                                            size: 12
                                                                                          })) : (
                                                                                    platform === "KotlinMultiplatform" ? React.createElement("div", {
                                                                                            title: "Sent from all Kotlin Multiplatform sources"
                                                                                          }, React.createElement(IconMobile.make, {
                                                                                                size: 12
                                                                                              })) : (
                                                                                        platform === "MacOS" ? React.createElement("div", {
                                                                                                title: "Sent from all macOS sources"
                                                                                              }, React.createElement(IconWeb.make, {
                                                                                                    size: 12
                                                                                                  })) : (
                                                                                            platform === "Expo" ? React.createElement("div", {
                                                                                                    title: "Sent from all Expo sources"
                                                                                                  }, React.createElement(IconExpo.make, {
                                                                                                        size: 12
                                                                                                      })) : (
                                                                                                platform === "Java" ? React.createElement("div", {
                                                                                                        title: "Sent from all Java sources"
                                                                                                      }, React.createElement(IconServer.make, {
                                                                                                            size: 12
                                                                                                          })) : (
                                                                                                    platform === "Node" ? React.createElement("div", {
                                                                                                            title: "Sent from all Node sources"
                                                                                                          }, React.createElement(IconServer.make, {
                                                                                                                size: 12
                                                                                                              })) : (
                                                                                                        platform === "Ruby" ? React.createElement("div", {
                                                                                                                title: "Sent from all Ruby sources"
                                                                                                              }, React.createElement(IconServer.make, {
                                                                                                                    size: 12
                                                                                                                  })) : (
                                                                                                            platform === "Unity" ? React.createElement("div", {
                                                                                                                    title: "Sent from all Unity sources"
                                                                                                                  }, React.createElement(IconMobile.make, {
                                                                                                                        size: 12
                                                                                                                      })) : null
                                                                                                          )
                                                                                                      )
                                                                                                  )
                                                                                              )
                                                                                          )
                                                                                      )
                                                                                  )
                                                                              )
                                                                          )
                                                                      )
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  ));
                                    }))) : null, Belt_Option.mapWithDefault(hasGroupType, null, (function (groupTypeId) {
                                return React.createElement(React.Fragment, undefined, React.createElement(Property$GroupTypePill, {
                                                groupTypeName: Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.GroupTypes.get, model.groupTypes, groupTypeId), groupTypeId, (function (param) {
                                                        return param.name;
                                                      }))
                                              }), React.createElement(Spacer.make, {
                                                width: 6
                                              }));
                              })), tmp, React.createElement(Spacer.make, {
                              width: 4
                            }), Belt_Option.mapWithDefault(globalProperty, null, (function (param) {
                                return React.createElement(React.Fragment, undefined, React.createElement(Property$GlobalPillWithLock, {
                                                eventId: $$event.id,
                                                propertyId: property.id
                                              }), React.createElement(Spacer.make, {
                                                width: 4
                                              }));
                              })), Belt_Option.mapWithDefault(property.uniqueName, null, (function (uniqueName) {
                                return React.createElement("div", {
                                            key: uniqueName,
                                            className: Curry._1(Css.style, {
                                                  hd: Css.paddingRight(Css.px(10)),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.fontSize(Styles.FontSize.small),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, "(" + uniqueName + ")");
                              })), React.createElement(PropertyType.TypeAnnotation.make, {
                              property: property,
                              properties: model.properties,
                              rules: model.rules,
                              language: "js",
                              event: undefined
                            }), property.operation !== "Set" && Belt_List.someU(eventDestinations, (function (destination) {
                                return Caml_obj.equal(destination.type_, "Snowplow");
                              })) ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    width: 4
                                  }), React.createElement(PropertyType.TypePill.make, {
                                    children: AvoConfig.operationLabel(property.operation)
                                  })) : null, tmp$1, unnecessary ? React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.orange,
                                children: "Unnecessary property, already in bundle"
                              }) : null, property.type_ === "object" && unsupportedDestinations.length !== 0 ? React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.orange,
                                children: unsupportedDestinations.join(", ") + " does not support objects as properties."
                              }) : null, isSetOnce && unsupportedDestinationLabels.length !== 0 ? React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.color(Styles.Color.orange),
                                      tl: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.small),
                                          tl: {
                                            hd: Css.paddingLeft(Css.px(10)),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    })
                              }, unsupportedDestinationLabels.join(", ") + " does not support set-once properties.") : null), property.description === "" ? React.createElement($$Text.make, {
                            size: "Small",
                            color: Styles.Color.light08,
                            children: "No description"
                          }) : React.createElement($$Text.make, {
                            size: "Small",
                            lines: 3,
                            color: Styles.Color.light11,
                            children: property.description
                          }), React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "property",
                                VAL: [
                                  property.id,
                                  undefined
                                ]
                              }),
                          className: Curry._1(Css.style, {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              }),
                          children: React.createElement("div", undefined, Belt_Array.map(Belt_Array.keepMapU(model.rules, (function (rule) {
                                          var match = rule.item;
                                          var match$1 = rule.definition;
                                          if (typeof match !== "object") {
                                            return ;
                                          }
                                          if (match.NAME !== "Property") {
                                            return ;
                                          }
                                          if (typeof match$1 !== "object") {
                                            return ;
                                          }
                                          var variant = match$1.NAME;
                                          var propertyId = match.VAL;
                                          if (variant === "NameMapping") {
                                            var match$2 = match$1.VAL;
                                            var match$3 = match$2.destination;
                                            if (match$3 === undefined) {
                                              return ;
                                            }
                                            if (typeof match$3 === "object") {
                                              var name = match$2.name;
                                              if (name === undefined) {
                                                return ;
                                              }
                                              if (propertyId !== property.id) {
                                                return ;
                                              }
                                              var destinationName = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Destinations.get, model.destinations, match$3.VAL), "No Destinations", ModelUtils.getDestinationName);
                                              return [
                                                      destinationName,
                                                      name
                                                    ];
                                            }
                                            var name$1 = match$2.name;
                                            if (name$1 !== undefined && propertyId === property.id) {
                                              return [
                                                      "All Destinations",
                                                      name$1
                                                    ];
                                            } else {
                                              return ;
                                            }
                                          }
                                          if (variant !== "NameInLocalWorkspace") {
                                            return ;
                                          }
                                          var match$4 = match$1.VAL;
                                          var match$5 = match$4.workspace;
                                          if (match$5 === undefined) {
                                            return ;
                                          }
                                          if (typeof match$5 === "object") {
                                            var name$2 = match$4.name;
                                            if (name$2 === undefined) {
                                              return ;
                                            }
                                            if (propertyId !== property.id) {
                                              return ;
                                            }
                                            var workspaceName = Belt_Option.mapWithDefault(Curry._2(TrackingPlanMappedModel.Sources.get, model.sources, match$5.VAL), "No Workspaces", AvoConfig.getSourceName);
                                            return [
                                                    workspaceName,
                                                    name$2
                                                  ];
                                          }
                                          var name$3 = match$4.name;
                                          if (name$3 !== undefined && propertyId === property.id) {
                                            return [
                                                    "All Workspaces",
                                                    name$3
                                                  ];
                                          }
                                          
                                        })), (function (param) {
                                      var name = param[1];
                                      var purposeName = param[0];
                                      return React.createElement("div", {
                                                  key: name + purposeName,
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.display("flex"),
                                                        tl: {
                                                          hd: Css.padding4(Css.px(6), Css.px(0), Css.px(5), Css.px(5)),
                                                          tl: {
                                                            hd: Css.width(Css.pct(100)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      })
                                                }, React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.marginTop(Css.px(0)),
                                                            tl: {
                                                              hd: Css.marginRight(Css.px(5)),
                                                              tl: /* [] */0
                                                            }
                                                          })
                                                    }, React.createElement(IconArrowDownEast.make, {
                                                          size: 12
                                                        })), React.createElement("div", {
                                                      className: Curry._1(Css.style, {
                                                            hd: Css.margin2(Css.px(0), Css.px(0)),
                                                            tl: {
                                                              hd: Css.fontSize(Styles.FontSize.small),
                                                              tl: {
                                                                hd: Css.display("flex"),
                                                                tl: {
                                                                  hd: Css.alignItems("center"),
                                                                  tl: {
                                                                    hd: Css.width(Css.pct(100)),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          })
                                                    }, React.createElement($$Text.make, {
                                                          size: "Small",
                                                          color: Styles.Color.light11,
                                                          children: "Send as"
                                                        }), React.createElement(Spacer.make, {
                                                          width: 3
                                                        }), React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Semi",
                                                          color: Styles.Color.light11,
                                                          children: name
                                                        }), React.createElement(Spacer.make, {
                                                          width: 3
                                                        }), React.createElement($$Text.make, {
                                                          size: "Small",
                                                          color: Styles.Color.light11,
                                                          children: "to"
                                                        }), React.createElement(Spacer.make, {
                                                          width: 3
                                                        }), React.createElement($$Text.make, {
                                                          size: "Small",
                                                          weight: "Semi",
                                                          color: Styles.Color.light11,
                                                          children: purposeName
                                                        }), React.createElement(Spacer.make, {
                                                          width: 3
                                                        }), React.createElement($$Text.make, {
                                                          size: "Small",
                                                          color: Styles.Color.light11,
                                                          children: "on all events"
                                                        })));
                                    })))
                        }), Belt_Array.map(ModelUtils.getPropertyNameMappingsOnEvent(model.rules, $$event, property, model.sources), (function (rule) {
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.padding4(Css.px(0), Css.px(0), Css.px(5), Css.px(5)),
                                                tl: {
                                                  hd: Css.width(Css.pct(100)),
                                                  tl: /* [] */0
                                                }
                                              }
                                            })
                                      }, React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.marginTop(Css.px(6)),
                                                  tl: {
                                                    hd: Css.marginRight(Css.px(5)),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement(IconArrowDownEast.make, {
                                                size: 12
                                              })), React.createElement(PropertyRule.make, {
                                            rule: rule,
                                            sendActions: sendActions,
                                            property: property,
                                            context: {
                                              eventId: $$event.id,
                                              eventQuery: $$event.id,
                                              propertyId: property.id,
                                              propertyQuery: property.id
                                            },
                                            viewerCanEdit: ViewerCanEditEventOrEventVariantChildUseCase.can(viewerCanEdit, false, variant)
                                          }));
                          })))), Caml_obj.equal(options, []) ? null : React.createElement(ContextMenu.make, {
                    options: options
                  }));
}

var make = Property;

export {
  GroupTypePill ,
  offerArchivingPropertyIfUnused ,
  make ,
}
/* Css Not a pure module */
