// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Nanoid from "nanoid";
import * as DateFns from "../DateFns.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns$1 from "date-fns";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import LodashDebounce from "lodash.debounce";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as ExponentialBackoff from "./ExponentialBackoff.mjs";
import * as InspectorEventShape from "../../../shared/models/InspectorEventShape.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as InspectorEventsStore from "./InspectorEventsStore.mjs";
import * as InspectorEventViewModel from "./InspectorEventViewModel.mjs";

function useHandleListenerRegistration(schemaId, environment, timeWindow) {
  var match = Curry._2(InspectorEventsStore.ZustandStore.useShallow, InspectorEventsStore.store, (function (state) {
          return [
                  state.registerListener,
                  state.unregisterListener,
                  state.listeners
                ];
        }));
  var listeners = match[2];
  var unregisterListener = match[1];
  var registerListener = match[0];
  var listenerId = React.useMemo((function () {
          return Nanoid.nanoid();
        }), []);
  var listenersForRequest = React.useMemo((function () {
          return Belt_Option.getWithDefault(Curry._2(InspectorEventsStore.Listener.$$Map.get, listeners, {
                          schemaId: schemaId,
                          environment: environment,
                          timeWindow: timeWindow
                        }), []);
        }), [
        schemaId,
        environment,
        timeWindow,
        listeners
      ]);
  var isPrimaryRequestHandler = React.useMemo((function () {
          return Caml_obj.equal(Belt_Array.get(listenersForRequest, 0), listenerId);
        }), [
        listenersForRequest,
        listenerId
      ]);
  React.useEffect((function () {
          Curry._4(registerListener, schemaId, environment, timeWindow, listenerId);
          return (function (param) {
                    Curry._4(unregisterListener, schemaId, environment, timeWindow, listenerId);
                  });
        }), [
        environment,
        timeWindow,
        schemaId
      ]);
  return isPrimaryRequestHandler;
}

function useLatestRequests(schemaId, environment, timeWindow) {
  var requests = Curry._2(InspectorEventsStore.ZustandStore.use, InspectorEventsStore.store, (function (state) {
          return state.requests;
        }));
  var displayedRequest = React.useMemo((function () {
          return InspectorEventsStore.getLatestSuccessfulRequest(true, true, environment, timeWindow, requests)(schemaId);
        }), [
        schemaId,
        environment,
        timeWindow,
        requests
      ]);
  var latestRequest = React.useMemo((function () {
          return InspectorEventsStore.getLatestRequest(false, schemaId, environment, timeWindow, requests);
        }), [
        schemaId,
        environment,
        timeWindow,
        requests
      ]);
  var latestSuccessfulRequest = React.useMemo((function () {
          return InspectorEventsStore.getLatestSuccessfulRequest(false, undefined, environment, timeWindow, requests)(schemaId);
        }), [
        schemaId,
        environment,
        timeWindow,
        requests
      ]);
  return React.useMemo((function () {
                return {
                        displayedRequest: displayedRequest,
                        latestRequest: latestRequest,
                        latestSuccessfulRequest: latestSuccessfulRequest
                      };
              }), [
              displayedRequest,
              latestRequest,
              latestSuccessfulRequest
            ]);
}

function useEvents(displayedRequest, latestRequest, latestSuccessfulRequest) {
  return React.useMemo((function () {
                var match;
                if (displayedRequest !== undefined) {
                  var events = displayedRequest.events;
                  var loadingStatus = displayedRequest.loadingStatus;
                  match = latestRequest !== undefined ? [
                      loadingStatus,
                      latestRequest.loadingStatus,
                      events
                    ] : [
                      loadingStatus,
                      loadingStatus,
                      events
                    ];
                } else if (latestRequest !== undefined) {
                  var loadingStatus$1 = latestRequest.loadingStatus;
                  match = [
                    loadingStatus$1,
                    loadingStatus$1,
                    InspectorEventViewModel.empty
                  ];
                } else {
                  match = [
                    "initial",
                    "initial",
                    InspectorEventViewModel.empty
                  ];
                }
                var canRefreshCurrentViewData;
                if (displayedRequest !== undefined) {
                  var match$1 = displayedRequest.loadingStatus;
                  if (typeof match$1 === "object" && match$1.NAME === "success" && latestSuccessfulRequest !== undefined) {
                    var match$2 = latestSuccessfulRequest.loadingStatus;
                    canRefreshCurrentViewData = typeof match$2 === "object" && match$2.NAME === "success" ? !latestSuccessfulRequest.isCurrentView && match$2.VAL.timestamp > match$1.VAL.timestamp && !Belt_MapString.eqU(Belt_MapString.fromArray(Belt_Array.mapU(displayedRequest.eventShapes, (function (eventShape) {
                                      return [
                                              eventShape.eventName,
                                              eventShape
                                            ];
                                    }))), Belt_MapString.fromArray(Belt_Array.mapU(latestSuccessfulRequest.eventShapes, (function (eventShape) {
                                      return [
                                              eventShape.eventName,
                                              eventShape
                                            ];
                                    }))), (function (a, b) {
                              return JSON.stringify(InspectorEventShape.encode(a)) === JSON.stringify(InspectorEventShape.encode(b));
                            })) : false;
                  } else {
                    canRefreshCurrentViewData = false;
                  }
                } else {
                  canRefreshCurrentViewData = false;
                }
                return {
                        fetchingStatus: match[1],
                        events: match[2],
                        loadingStatus: match[0],
                        canRefreshCurrentViewData: canRefreshCurrentViewData
                      };
              }), [
              displayedRequest,
              latestRequest,
              latestSuccessfulRequest
            ]);
}

function usePolling(loadingStatus, pollIntervalMins) {
  var match = React.useState(function () {
        return Nanoid.nanoid();
      });
  var setPollId = match[1];
  var pollIntervalInMilliseconds = Math.max(5000.0, pollIntervalMins * 60.0 * 1000.0);
  React.useEffect((function () {
          if (typeof loadingStatus !== "object") {
            return ;
          }
          if (loadingStatus.NAME !== "success") {
            return ;
          }
          if (pollIntervalMins <= 0.0) {
            return ;
          }
          var intervalId = setInterval((function (param) {
                  Curry._1(setPollId, (function (param) {
                          return Nanoid.nanoid();
                        }));
                }), pollIntervalInMilliseconds);
          return (function (param) {
                    clearInterval(intervalId);
                  });
        }), [loadingStatus]);
  return match[0];
}

var debounceFunction = LodashDebounce((function (fn) {
        Curry._1(fn, undefined);
      }), 350, {
      leading: false,
      trailing: true
    });

function useLatestIssueTimestamp(schemaId) {
  var latestInspectorIssueCreated = FirebaseFetcherHooks.useLatestInspectorIssueCreated(schemaId);
  var match = React.useState(function () {
        if (typeof latestInspectorIssueCreated === "object" && latestInspectorIssueCreated.NAME === "Success") {
          return Caml_option.some(latestInspectorIssueCreated.VAL.latestIssueTimestamp.toDate());
        }
        
      });
  var setLatestIssueTimestamp = match[1];
  React.useEffect((function () {
          debounceFunction(function (param) {
                Curry._1(setLatestIssueTimestamp, (function (param) {
                        if (typeof latestInspectorIssueCreated === "object" && latestInspectorIssueCreated.NAME === "Success") {
                          return Caml_option.some(latestInspectorIssueCreated.VAL.latestIssueTimestamp.toDate());
                        }
                        
                      }));
              });
        }), [latestInspectorIssueCreated]);
  return match[0];
}

function useRetries(schemaId, environment, timeWindow) {
  var retries = Curry._2(InspectorEventsStore.ZustandStore.use, InspectorEventsStore.store, (function (state) {
          return state.retries;
        }));
  return React.useMemo((function () {
                return Belt_Option.getWithDefault(Curry._2(InspectorEventsStore.Retry.$$Map.get, retries, Curry._3(InspectorEventsStore.$$Request.Utils.makeNewRequestConfig, schemaId, environment, timeWindow)), 0);
              }), [
              schemaId,
              environment,
              timeWindow,
              retries
            ]);
}

function useFetchHandler(isPrimaryRequestHandler, $$fetch, latestRequest, loadingStatus, pollIntervalMins, retriesForRequest, schemaId) {
  var latestInspectorIssueTimestamp = useLatestIssueTimestamp(schemaId);
  var modelStoreIsInitialized = ModelStore.useModelIsInitialized(undefined);
  var pollId = usePolling(loadingStatus, pollIntervalMins);
  var fetching = Curry._2(InspectorEventsStore.ZustandStore.use, InspectorEventsStore.store, (function (state) {
          return state.fetching;
        }));
  React.useEffect((function () {
          var errorRefetchTimeoutId = {
            contents: undefined
          };
          if (modelStoreIsInitialized) {
            var timeBuffer = pollIntervalMins * 60.0 - 1.0;
            if (isPrimaryRequestHandler && !fetching) {
              if (latestRequest !== undefined) {
                var match = latestRequest.loadingStatus;
                if (typeof match === "object") {
                  if (match.NAME === "success") {
                    var timestamp = match.VAL.timestamp;
                    if (latestInspectorIssueTimestamp !== undefined) {
                      var shouldPoll = DateFns$1.differenceInSeconds(new Date(), new Date(timestamp)) >= timeBuffer;
                      var latestIssueTimestampIsNewer = DateFns.isAfter(new Date(timestamp), Caml_option.valFromOption(latestInspectorIssueTimestamp));
                      if (shouldPoll || latestIssueTimestampIsNewer) {
                        Curry._1($$fetch, undefined);
                      }
                      
                    } else if (DateFns$1.differenceInSeconds(new Date(), new Date(timestamp)) >= timeBuffer) {
                      Curry._1($$fetch, undefined);
                    }
                    
                  } else {
                    var isFetching = {
                      contents: fetching
                    };
                    errorRefetchTimeoutId.contents = Caml_option.some(setTimeout((function (param) {
                                if (!isFetching.contents) {
                                  return Curry._1($$fetch, undefined);
                                }
                                
                              }), ExponentialBackoff.getTimeoutValueMs(undefined, retriesForRequest)));
                  }
                }
                
              } else {
                Curry._1($$fetch, undefined);
              }
            }
            
          }
          return (function (param) {
                    Belt_Option.forEach(errorRefetchTimeoutId.contents, (function (prim) {
                            clearTimeout(prim);
                          }));
                  });
        }), [
        isPrimaryRequestHandler,
        pollId,
        modelStoreIsInitialized,
        latestRequest,
        latestInspectorIssueTimestamp,
        fetching
      ]);
}

function useHandleModelUpdates(isPrimaryRequestHandler, displayedRequest) {
  var latestActionId = ModelStore.useLatestActionId(undefined);
  var mappedModel = ModelStore.Mapped.use(undefined);
  var updateOnModelChange = Curry._2(InspectorEventsStore.ZustandStore.use, InspectorEventsStore.store, (function (state) {
          return state.updateOnModelChange;
        }));
  React.useEffect((function () {
          if (displayedRequest !== undefined) {
            var match = displayedRequest.loadingStatus;
            if (typeof match === "object" && match.NAME === "success" && latestActionId !== undefined && isPrimaryRequestHandler) {
              Curry._3(updateOnModelChange, match.VAL, latestActionId, mappedModel);
            }
            
          }
          
        }), [
        latestActionId,
        displayedRequest
      ]);
}

function use(pollIntervalMinsOpt, environment, timeWindow) {
  var pollIntervalMins = pollIntervalMinsOpt !== undefined ? pollIntervalMinsOpt : 5.0;
  var match = Curry._2(InspectorEventsStore.ZustandStore.useShallow, InspectorEventsStore.store, (function (state) {
          return [
                  state.fetch,
                  state.refreshCurrentViewData
                ];
        }));
  var refreshCurrentViewData = match[1];
  var $$fetch = match[0];
  var match$1 = WorkspaceContext.use(undefined);
  var schemaId = match$1.id;
  var latestActionId = ModelStore.useLatestActionId(undefined);
  var mappedModel = ModelStore.Mapped.use(undefined);
  var visibility = Hooks.useDocumentVisibility(undefined);
  var isPrimaryRequestHandler = useHandleListenerRegistration(schemaId, environment, timeWindow) && visibility === "visible";
  var refreshCurrentViewData$1 = React.useCallback((function (param) {
          Curry._5(refreshCurrentViewData, schemaId, environment, timeWindow, latestActionId, mappedModel);
        }), [
        schemaId,
        environment,
        timeWindow,
        latestActionId,
        mappedModel
      ]);
  var $$fetch$1 = React.useCallback((function (param) {
          if (isPrimaryRequestHandler) {
            return Curry._5($$fetch, schemaId, environment, timeWindow, latestActionId, mappedModel);
          }
          
        }), [
        isPrimaryRequestHandler,
        schemaId,
        environment,
        timeWindow,
        latestActionId,
        mappedModel
      ]);
  var retriesForRequest = useRetries(schemaId, environment, timeWindow);
  var match$2 = useLatestRequests(schemaId, environment, timeWindow);
  var latestRequest = match$2.latestRequest;
  var displayedRequest = match$2.displayedRequest;
  var match$3 = useEvents(displayedRequest, latestRequest, match$2.latestSuccessfulRequest);
  var canRefreshCurrentViewData = match$3.canRefreshCurrentViewData;
  var loadingStatus = match$3.loadingStatus;
  var events = match$3.events;
  var fetchingStatus = match$3.fetchingStatus;
  useFetchHandler(isPrimaryRequestHandler, $$fetch$1, latestRequest, loadingStatus, pollIntervalMins, retriesForRequest, schemaId);
  useHandleModelUpdates(isPrimaryRequestHandler, displayedRequest);
  return React.useMemo((function () {
                return {
                        loadingStatus: loadingStatus,
                        fetchingStatus: fetchingStatus,
                        events: events,
                        fetch: $$fetch$1,
                        canRefreshCurrentViewData: canRefreshCurrentViewData,
                        refreshCurrentViewData: refreshCurrentViewData$1
                      };
              }), [
              fetchingStatus,
              events,
              $$fetch$1,
              loadingStatus,
              canRefreshCurrentViewData,
              refreshCurrentViewData$1
            ]);
}

export {
  use ,
}
/* debounceFunction Not a pure module */
