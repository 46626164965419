// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ModelDiffUtils from "../../api/functions/src/ModelDiffUtils.mjs";

function allPropertyChanges(sourceWithChanges) {
  return Belt_List.concat(sourceWithChanges.propertyMatchValidationChanges, sourceWithChanges.otherPropertyChanges);
}

function isSystemProp(propertyId, model) {
  return Caml_obj.equal(Belt_Option.mapU(ModelUtils.resolvePropertyById(propertyId, model), (function (prop) {
                    return prop.sendAs;
                  })), /* SystemProperty */0);
}

function isPropertyRelevantForSource(propertyId, sourceId, model) {
  if (sourceId === "") {
    return true;
  }
  var isSystemProp$1 = isSystemProp(propertyId, model);
  if (isSystemProp$1) {
    return true;
  } else {
    return ModelUtils.isPropertySentFromSource(model, propertyId, sourceId);
  }
}

function isPropertyValidationAction(action) {
  if (typeof action !== "object") {
    return false;
  }
  var variant = action.NAME;
  if (variant === "DeprecatedAddPropertyValidationMinOrMax" || variant === "DeprecatedRemovePropertyValidationMinOrMax" || variant === "AddPropertyValidation" || variant === "RemovePropertyValidation" || variant === "DeprecatedUpdatePropertyValidationMinOrMax" || variant === "DeprecatedRemovePropertyValidationMatches" || variant === "DeprecatedAddPropertyValidationMatch") {
    return true;
  } else {
    return variant === "DeprecatedRemovePropertyValidationMatch";
  }
}

function calculateSourceWithEventsChanges(branchModel, sourcesToAttachedEventsActions, actions) {
  return Belt_Array.keepU(Belt_Array.mapU(sourcesToAttachedEventsActions, (function (param) {
                    var source = param[0];
                    return {
                            source: source,
                            eventsChanges: Belt_List.toArray(Belt_List.keepMapU(param[1], (function (eventActionsById) {
                                        if (!eventActionsById) {
                                          return ;
                                        }
                                        var match = eventActionsById.hd[0];
                                        if (typeof match === "object" && match.NAME === "Event") {
                                          return {
                                                  eventId: match.VAL,
                                                  eventActions: Belt_List.toArray(Belt_List.keepMapU(eventActionsById, (function (eventAndEventAction) {
                                                              var eventAction = eventAndEventAction[1];
                                                              if (ModelDiffUtils.isEventActionRelevantForSource(branchModel, eventAction, source.id)) {
                                                                return eventAction;
                                                              }
                                                              
                                                            })))
                                                };
                                        }
                                        
                                      }))),
                            propertyMatchValidationChanges: Belt_List.flatten(Belt_List.keepMap(actions, (function (action) {
                                        if (!action) {
                                          return ;
                                        }
                                        var match = action.hd[0];
                                        if (typeof match === "object" && match.NAME === "Property" && isPropertyRelevantForSource(match.VAL, source.id, branchModel)) {
                                          return Belt_List.keep(action, (function (param) {
                                                        return isPropertyValidationAction(param[1]);
                                                      }));
                                        }
                                        
                                      }))),
                            otherPropertyChanges: Belt_List.flatten(Belt_List.keepMap(actions, (function (action) {
                                        if (!action) {
                                          return ;
                                        }
                                        var match = action.hd[0];
                                        if (typeof match === "object" && match.NAME === "Property" && isPropertyRelevantForSource(match.VAL, source.id, branchModel)) {
                                          return Belt_List.keep(action, (function (param) {
                                                        return !isPropertyValidationAction(param[1]);
                                                      }));
                                        }
                                        
                                      })))
                          };
                  })), (function (sourceWithChanges) {
                if (sourceWithChanges.eventsChanges.length !== 0 && Belt_Array.someU(sourceWithChanges.eventsChanges, (function (eventWithPropertiesChanges) {
                          return eventWithPropertiesChanges.eventActions.length !== 0;
                        }))) {
                  return true;
                } else {
                  return Belt_List.length(allPropertyChanges(sourceWithChanges)) > 0;
                }
              }));
}

export {
  allPropertyChanges ,
  isSystemProp ,
  isPropertyRelevantForSource ,
  isPropertyValidationAction ,
  calculateSourceWithEventsChanges ,
}
/* ModelUtils Not a pure module */
