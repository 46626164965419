// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as $$Number from "./Number.mjs";
import * as Spacer from "./Spacer.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "./styles.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Sidebar from "./Sidebar.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as BulkContext from "./BulkContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainStore from "./DomainStore.mjs";
import * as DomainUtils from "./DomainUtils.mjs";
import * as ReactWindow from "react-window";
import * as Core from "@mantine/core";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as ViewerContext from "./ViewerContext.mjs";
import * as FramerMotion from "framer-motion";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";

function trackInteraction(schemaBundle, count, action) {
  AnalyticsRe.bulkEditingInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, action, count, schemaBundle.schemaId, schemaBundle.branchId);
}

var container = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.bottom(Css.px(32)),
        tl: {
          hd: Css.left(Css.px(Sidebar.sidebarWidth)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.pointerEvents("none"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.zIndex(1),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var bar = Curry._1(Css.style, {
      hd: Css.borderRadius(Css.px(100)),
      tl: {
        hd: Css.paddingTop(Css.px(7)),
        tl: {
          hd: Css.paddingRight(Css.px(20)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.paddingLeft(Css.px(16)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light12),
                tl: {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.gap(Css.px(12)),
                      tl: {
                        hd: Css.pointerEvents("auto"),
                        tl: {
                          hd: Css.boxShadow(Styles.Shadow.subtle),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var iconButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.height(Css.px(28)),
                    tl: {
                      hd: Css.width(Css.px(28)),
                      tl: {
                        hd: Css.border(Css.px(1), Css.solid, "transparent"),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.marginTop(Css.px(1)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$short
                                  }, undefined, undefined, "border-color"),
                              tl: {
                                hd: Css.selector(" path", {
                                      hd: Css.transition({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$short
                                          }, undefined, undefined, "fill"),
                                      tl: {
                                        hd: Css_Legacy_Core.SVG.fill(Styles.Color.dark11),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.hover({
                                        hd: Icon.color(Styles.Color.dark12),
                                        tl: {
                                          hd: Css.borderColor(Styles.Color.dark06),
                                          tl: /* [] */0
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var popup = Curry._1(Css.style, {
      hd: Css.boxShadow(Styles.Shadow.box),
      tl: {
        hd: Css.borderRadius(Css.px(8)),
        tl: {
          hd: Css.backgroundColor(Styles.Color.white),
          tl: {
            hd: Css.paddingTop(Css.px(8)),
            tl: {
              hd: Css.overflow("hidden"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var popupList = Curry._1(Css.style, {
      hd: Css.paddingBottom(Css.px(8)),
      tl: {
        hd: Css.minWidth(Css.px(300)),
        tl: {
          hd: Css.maxWidth(Css.px(300)),
          tl: {
            hd: Css.maxHeight({
                  NAME: "subtract",
                  VAL: [
                    Css.vh(100.0),
                    Css.px(302)
                  ]
                }),
            tl: {
              hd: Css.overflowY("auto"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var popupOptionStyles_0 = Css.width({
      NAME: "subtract",
      VAL: [
        Css.pct(100.0),
        Css.px(16)
      ]
    });

var popupOptionStyles_1 = {
  hd: Css.color(Styles.Color.light10),
  tl: {
    hd: Css.margin(Css.px(8)),
    tl: {
      hd: Css.marginTop(Css.px(0)),
      tl: {
        hd: Css.marginBottom(Css.px(0)),
        tl: {
          hd: Css.marginRight(Css.px(8)),
          tl: {
            hd: Css.paddingLeft(Css.px(8)),
            tl: {
              hd: Css.paddingRight(Css.px(0)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.deepPurple),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.lightPurple),
                            tl: {
                              hd: Css.selector("[class$='subtitle']", {
                                    hd: Css.color(Styles.Color.darkPurple),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector("[class$='remove-icon']", {
                                      hd: Css.opacity(1.0),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }
                          }
                        }),
                    tl: {
                      hd: Css.disabled({
                            hd: Css.color(Styles.Color.light06),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.cursor("auto"),
                                tl: {
                                  hd: Css.selector("[class$='subtitle']", {
                                        hd: Css.color(Styles.Color.light06),
                                        tl: /* [] */0
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }),
                      tl: {
                        hd: Css.position(Css.relative),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var popupOptionStyles = {
  hd: popupOptionStyles_0,
  tl: popupOptionStyles_1
};

var subtitle = Curry._1(Css.style, {
      hd: Css.label("subtitle"),
      tl: {
        hd: Css.color(Styles.Color.light09),
        tl: /* [] */0
      }
    });

var removeableDot = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(11)),
        tl: {
          hd: Css.top(Css.pct(50.0)),
          tl: {
            hd: Css.marginTop(Css.px(-2)),
            tl: {
              hd: Css.width(Css.px(4)),
              tl: {
                hd: Css.height(Css.px(4)),
                tl: {
                  hd: Css.borderRadius(Css.px(2)),
                  tl: {
                    hd: Css.backgroundColor(Styles.Color.darkPurple),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var removeOuterContainer = Curry._1(Css.style, {
      hd: Css.label("remove-icon"),
      tl: {
        hd: Css.opacity(0.0),
        tl: {
          hd: Css.position("absolute"),
          tl: {
            hd: Css.right(Css.zero),
            tl: {
              hd: Css.top(Css.zero),
              tl: {
                hd: Css.bottom(Css.zero),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function removeContainer(itemsToRemove, finalRemoveTextWidth) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.px(25)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("flexEnd"),
                    tl: {
                      hd: Css.borderRadius(Css.px(4)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(2)),
                        tl: {
                          hd: Css.paddingRight(Css.px(2)),
                          tl: {
                            hd: Css.width(Css.px(45)),
                            tl: {
                              hd: Css.border(Css.px(1), Css.solid, "transparent"),
                              tl: {
                                hd: Css.transitions({
                                      hd: Css_Legacy_Core.Transition.shorthand({
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$default
                                          }, {
                                            NAME: "ms",
                                            VAL: Styles.Duration.$$default
                                          }, undefined, "background-color"),
                                      tl: {
                                        hd: Css_Legacy_Core.Transition.shorthand({
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$default
                                            }, {
                                              NAME: "ms",
                                              VAL: Styles.Duration.$$default
                                            }, undefined, "border-color"),
                                        tl: {
                                          hd: Css_Legacy_Core.Transition.shorthand({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$default
                                              }, {
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$default
                                              }, undefined, "width"),
                                          tl: /* [] */0
                                        }
                                      }
                                    }),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.lightPurple),
                                  tl: {
                                    hd: itemsToRemove === 0 ? Styles.emptyStyle : Css.hover({
                                            hd: Css.border(Css.px(1), Css.solid, Styles.Color.lightPurple),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.darkPurple),
                                              tl: {
                                                hd: Icon.styles({
                                                      hd: Css_Legacy_Core.SVG.fill(itemsToRemove === 0 ? Styles.Color.light08 : Styles.Color.white),
                                                      tl: {
                                                        hd: Css.transition({
                                                              NAME: "ms",
                                                              VAL: Styles.Duration.$$default
                                                            }, undefined, undefined, "all"),
                                                        tl: /* [] */0
                                                      }
                                                    }),
                                                tl: {
                                                  hd: Css.width(Css.px((finalRemoveTextWidth + 25 | 0) + 4 | 0)),
                                                  tl: {
                                                    hd: Css.selector("[class$='remove-text']", {
                                                          hd: Css.opacity(1.0),
                                                          tl: {
                                                            hd: Css.pointerEvents("auto"),
                                                            tl: {
                                                              hd: Css.transition({
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$default
                                                                  }, {
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$default
                                                                  }, undefined, "opacity"),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }),
                                                    tl: {
                                                      hd: Css.selector("[class$='remove-bar']", {
                                                            hd: Css.opacity(0.0),
                                                            tl: {
                                                              hd: Css.transition({
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$short
                                                                  }, undefined, undefined, "opacity"),
                                                              tl: /* [] */0
                                                            }
                                                          }),
                                                      tl: {
                                                        hd: Css.transitions({
                                                              hd: Css_Legacy_Core.Transition.shorthand({
                                                                    NAME: "ms",
                                                                    VAL: Styles.Duration.$$default
                                                                  }, undefined, undefined, "background-color"),
                                                              tl: {
                                                                hd: Css_Legacy_Core.Transition.shorthand({
                                                                      NAME: "ms",
                                                                      VAL: Styles.Duration.$$default
                                                                    }, undefined, undefined, "width"),
                                                                tl: /* [] */0
                                                              }
                                                            }),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }),
                                    tl: {
                                      hd: itemsToRemove === 0 ? Styles.emptyStyle : Css.active({
                                              hd: Css.backgroundColor(Styles.Color.deepPurple),
                                              tl: /* [] */0
                                            }),
                                      tl: {
                                        hd: Icon.styles({
                                              hd: Css_Legacy_Core.SVG.fill(itemsToRemove === 0 ? Styles.Color.light08 : Styles.Color.darkPurple),
                                              tl: {
                                                hd: Css.transition({
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$default
                                                    }, {
                                                      NAME: "ms",
                                                      VAL: Styles.Duration.$$default
                                                    }, undefined, "all"),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var removeText = Curry._1(Css.style, {
      hd: Css.label("remove-text"),
      tl: {
        hd: Css.opacity(0.0),
        tl: {
          hd: Css.paddingLeft(Css.px(4)),
          tl: {
            hd: Css.paddingRight(Css.px(4)),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "opacity"),
              tl: {
                hd: Css.whiteSpace("nowrap"),
                tl: {
                  hd: Css.pointerEvents("none"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var removeBar = Curry._1(Css.style, {
      hd: Css.label("remove-bar"),
      tl: {
        hd: Css.width(Css.px(1)),
        tl: {
          hd: Css.height(Css.px(20)),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.zIndex(1),
              tl: {
                hd: Css.marginRight(Css.px(1)),
                tl: {
                  hd: Css.paddingRight(Css.px(1)),
                  tl: {
                    hd: Css.opacity(1.0),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, {
                            NAME: "ms",
                            VAL: Styles.Duration.$$long
                          }, undefined, "opacity"),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var searchBoxStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.white),
              tl: {
                hd: Css.paddingTop(Css.px(12)),
                tl: {
                  hd: Css.paddingRight(Css.px(16)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(12)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(16)),
                      tl: {
                        hd: Css.borderTop(Css.pxFloat(0.5), Css.solid, Styles.Color.light04),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var filterStyles_0 = Css.minWidth(Css.px(0));

var filterStyles_1 = {
  hd: Css.flexShrink(1.0),
  tl: {
    hd: Css.flexGrow(1.0),
    tl: {
      hd: Css.borderWidth(Css.px(0)),
      tl: {
        hd: Css.fontSize(Styles.FontSize.small),
        tl: {
          hd: Css.fontWeight(Styles.FontWeight.medium),
          tl: {
            hd: Css.lineHeight(Styles.LineHeight.regular),
            tl: {
              hd: Css.color(Styles.Color.light10),
              tl: {
                hd: Css.paddingTop(Css.px(0)),
                tl: {
                  hd: Css.paddingRight(Css.px(8)),
                  tl: {
                    hd: Css.paddingBottom(Css.px(0)),
                    tl: {
                      hd: Css.paddingLeft(Css.px(0)),
                      tl: {
                        hd: Css.margin(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(0)),
                          tl: {
                            hd: Css.placeholder({
                                  hd: Css.color(Styles.Color.light08),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.focus({
                                    hd: Css.outlineStyle("none"),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var filterStyles = {
  hd: filterStyles_0,
  tl: filterStyles_1
};

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(2)),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "opacity"),
                            tl: {
                              hd: Css.opacity(disabled ? 0 : 1),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.active({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.focus({
                                              hd: Css.outlineStyle("none"),
                                              tl: {
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var textButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.color(Styles.Color.dark11),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$short
                    }, undefined, undefined, "color"),
                tl: {
                  hd: Css.hover({
                        hd: Css.color(Styles.Color.dark12),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.disabled({
                          hd: Css.color(Styles.Color.light10),
                          tl: {
                            hd: Css.cursor("auto"),
                            tl: /* [] */0
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

function BulkBar$OptionToAdd(Props) {
  var title = Props.title;
  var searchValueOpt = Props.searchValue;
  var onSearchChange = Props.onSearchChange;
  var children = Props.children;
  var searchValue = searchValueOpt !== undefined ? searchValueOpt : "";
  var match = BulkContext.use(undefined);
  var selectedEventIds = match.selectedEventIds;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsOpen = match$1[1];
  var isOpen = match$1[0];
  React.useEffect((function () {
          if (isOpen) {
            trackInteraction(schemaBundle, Belt_SetString.size(selectedEventIds), "OpenPopup");
          }
          
        }), [isOpen]);
  var tmp = {
    element: "Span",
    size: "Small",
    weight: "Semi",
    children: title
  };
  var tmp$1 = isOpen ? Styles.Color.light01 : undefined;
  if (tmp$1 !== undefined) {
    tmp.color = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Mantine.Menu.make, {
              closeOnItemClick: false,
              dropdownStyles: {
                hd: Css.borderWidth(Css.zero),
                tl: /* [] */0
              },
              onClose: (function (param) {
                  Curry._1(setIsOpen, (function (param) {
                          return false;
                        }));
                }),
              onOpen: (function (param) {
                  Curry._1(setIsOpen, (function (param) {
                          return true;
                        }));
                }),
              position: "top-start",
              withinPortal: false,
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("button", {
                        className: textButtonStyles
                      }, React.createElement($$Text.make, tmp))
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: {
                    hd: Css.marginLeft(Css.px(-16)),
                    tl: /* [] */0
                  },
                  children: React.createElement("div", {
                        className: popup
                      }, React.createElement("div", {
                            className: popupList
                          }, React.Children.count(children) === 0 ? React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.paddingTop(Css.px(24)),
                                        tl: {
                                          hd: Css.paddingBottom(Css.px(24)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      size: "Small",
                                      weight: "Medium",
                                      textAlign: "center",
                                      color: Styles.Color.light09,
                                      children: "No matches found"
                                    })) : children), onSearchChange !== undefined ? React.createElement("div", {
                              className: searchBoxStyles
                            }, React.createElement(Input.make, {
                                  value: searchValue,
                                  onChange: onSearchChange,
                                  autoFocus: true,
                                  placeholder: "Find " + Case.lower(title) + "...",
                                  className: filterStyles,
                                  containerClassName: {
                                    hd: Css.margin(Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }), React.createElement("button", {
                                  className: eraseButtonStyles(searchValue === ""),
                                  disabled: searchValue === "",
                                  onClick: (function (param) {
                                      Curry._1(onSearchChange, (function (param) {
                                              return "";
                                            }));
                                    })
                                }, React.createElement(IconErase.make, {
                                      color: Styles.Color.light07,
                                      hoverColor: Styles.Color.darkRed
                                    }))) : null)
                }));
}

function BulkBar$OptionItem(Props) {
  var title = Props.title;
  var onAdd = Props.onAdd;
  var onRemove = Props.onRemove;
  var itemsToAdd = Props.itemsToAdd;
  var itemsToRemove = Props.itemsToRemove;
  var subtitle$1 = Props.subtitle;
  var disabledOpt = Props.disabled;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var removeTextRef = React.useRef(null);
  var match = React.useState(function () {
        return {
                width: 0,
                text: "Remove from " + String(itemsToRemove) + ""
              };
      });
  var setRemoveTextProps = match[1];
  var removeTextProps = match[0];
  var onEnterRemove = function (param) {
    if (!(removeTextProps.width === 0 || removeTextProps.text !== "Remove from " + String(itemsToRemove) + "")) {
      return ;
    }
    var el = removeTextRef.current;
    if (!(el == null)) {
      return Curry._1(setRemoveTextProps, (function (param) {
                    return {
                            width: el.scrollWidth,
                            text: "Remove from " + String(itemsToRemove) + ""
                          };
                  }));
    }
    
  };
  return React.createElement(Mantine.Menu.Item.make, {
              additionalStyles: Belt_List.concatMany([
                    Styles.buttonReset,
                    popupOptionStyles
                  ]),
              disabled: disabled,
              children: React.createElement("span", {
                    onClick: (function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        if (itemsToAdd > 0) {
                          return Curry._1(onAdd, e);
                        }
                        
                      })
                  }, React.createElement(SimpleTooltip.WithComponent.make, {
                        arrowOffset: 10,
                        containerStyles: Curry._1(Css.style, {
                              hd: Css.width(Css.pct(100.0)),
                              tl: /* [] */0
                            }),
                        offset: 16,
                        position: "left-start",
                        tooltip: (function (param) {
                            return React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.paddingLeft(Css.px(8)),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.dark12,
                                            children: itemsToAdd > 0 ? "Add to " + AvoUtils.plural(undefined, undefined, itemsToAdd, "event") + "" : "Included on all selected events"
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.position("relative"),
                                                  tl: {
                                                    hd: Css.after(itemsToRemove === 0 ? /* [] */0 : ({
                                                              hd: Css.unsafe("content", "''"),
                                                              tl: {
                                                                hd: Css.position("absolute"),
                                                                tl: {
                                                                  hd: Css.right(Css.pct(100.0)),
                                                                  tl: {
                                                                    hd: Css.marginRight(Css.px(6)),
                                                                    tl: {
                                                                      hd: Css.top(Css.pct(50.0)),
                                                                      tl: {
                                                                        hd: Css.marginTop(Css.px(-2)),
                                                                        tl: {
                                                                          hd: Css.width(Css.px(4)),
                                                                          tl: {
                                                                            hd: Css.height(Css.px(4)),
                                                                            tl: {
                                                                              hd: Css.backgroundColor(Styles.Color.purple),
                                                                              tl: {
                                                                                hd: Css.borderRadius(Css.px(2)),
                                                                                tl: /* [] */0
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            })),
                                                    tl: /* [] */0
                                                  }
                                                })
                                          }, React.createElement($$Text.make, {
                                                size: "Small",
                                                color: Styles.Color.dark11,
                                                children: itemsToRemove === 0 ? "Not included in selection" : "Already included in " + String(itemsToRemove) + ""
                                              })));
                          }),
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.paddingBottom(Css.px(4)),
                                        tl: {
                                          hd: Css.paddingTop(Css.px(4)),
                                          tl: {
                                            hd: Css.paddingRight(Css.px(26)),
                                            tl: {
                                              hd: itemsToAdd === 0 ? Css.color(Styles.Color.light08) : Styles.emptyStyle,
                                              tl: {
                                                hd: itemsToAdd === 0 ? Css.cursor("auto") : Styles.emptyStyle,
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      })
                                }, React.createElement($$Text.make, {
                                      element: "Div",
                                      size: "Small",
                                      weight: "Medium",
                                      textAlign: "left",
                                      lines: 1,
                                      title: title,
                                      withWordBreak: true,
                                      children: title
                                    }), subtitle$1 !== undefined ? React.createElement("div", {
                                        className: subtitle
                                      }, React.createElement($$Text.make, {
                                            size: "Tiny",
                                            weight: "Medium",
                                            children: subtitle$1
                                          })) : null), React.createElement(Spacer.make, {
                                  width: 4,
                                  grow: 1.0
                                }), itemsToRemove > 0 ? React.createElement("div", {
                                    className: removeableDot
                                  }) : null, itemsToRemove > 0 ? React.createElement("div", {
                                    className: removeOuterContainer
                                  }, React.createElement("div", {
                                        className: removeContainer(itemsToRemove, removeTextProps.width),
                                        onClick: (function (e) {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if (itemsToRemove > 0) {
                                              return Curry._1(onRemove, e);
                                            }
                                            
                                          }),
                                        onMouseEnter: onEnterRemove
                                      }, React.createElement("div", {
                                            ref: removeTextRef,
                                            className: removeText
                                          }, React.createElement($$Text.make, {
                                                size: "Tiny",
                                                weight: "Semi",
                                                color: Styles.Color.white,
                                                children: "Remove from " + String(itemsToRemove) + ""
                                              })), React.createElement("div", {
                                            className: removeBar
                                          }), React.createElement(Icon.make, {
                                            type_: "close",
                                            size: {
                                              NAME: "int",
                                              VAL: 20
                                            }
                                          }))) : null)
                      }))
            });
}

function getMessage(addOpt, reverseOpt, eventsCount, item) {
  var add = addOpt !== undefined ? addOpt : true;
  var reverse = reverseOpt !== undefined ? reverseOpt : false;
  if (reverse) {
    return React.createElement($$Text.make, {
                children: null
              }, "" + AvoUtils.plural(undefined, undefined, eventsCount, "event") + " " + (
                add ? "added to" : "removed from"
              ) + " ", React.createElement($$Text.make, {
                    element: "Em",
                    weight: "Semi",
                    children: item
                  }));
  } else {
    return React.createElement($$Text.make, {
                children: null
              }, React.createElement($$Text.make, {
                    element: "Em",
                    weight: "Semi",
                    children: item
                  }), " " + (
                add ? "added to" : "removed from"
              ) + " " + AvoUtils.plural(undefined, undefined, eventsCount, "event") + "");
  }
}

function getEventsToAddForProperty(events, properties, propertyBundles, selectedEventIds, id) {
  try {
    var property = Curry._2(TrackingPlanMappedModel.Properties.getExn, properties, id);
    return Belt_Array.keepU(Belt_Array.keepMapU(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                      return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                    })), (function ($$event) {
                  return !ModelUtils_mapped.isPropertyOnEvent_byEvent($$event, property, propertyBundles);
                }));
  }
  catch (exn){
    return [];
  }
}

function getEventsToRemoveForProperty(events, selectedEventIds, id) {
  return Belt_Array.keepU(Belt_Array.keepMapU(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                    return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                  })), (function (param) {
                return Belt_List.someU(param.directPropertyRefs, (function (property) {
                              return ModelUtils_mapped.getPropertyId(property) === id;
                            }));
              }));
}

function getEventsToAddForPropertyBundle(events, selectedEventIds, id) {
  return Belt_Array.keepU(Belt_Array.keepMapU(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                    return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                  })), (function (param) {
                return Belt_List.everyU(param.propertyBundles, (function (param) {
                              return param.id !== id;
                            }));
              }));
}

function getEventsToRemoveForPropertyBundle(events, selectedEventIds, id) {
  return Belt_Array.keepU(Belt_Array.keepMapU(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                    return Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                  })), (function (param) {
                return Belt_List.someU(param.propertyBundles, (function (param) {
                              return param.id === id;
                            }));
              }));
}

function getPropertyAddActions(events, properties, propertyBundles, selectedEventIds, id) {
  var eventsToAdd = getEventsToAddForProperty(events, properties, propertyBundles, selectedEventIds, id);
  return Belt_Array.mapU(eventsToAdd, (function ($$event) {
                return [
                        {
                          NAME: "AddPropertyRef",
                          VAL: [
                            $$event.id,
                            id
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id,
                          propertyId: id,
                          propertyQuery: id
                        }
                      ];
              }));
}

function getPropertyRemoveActions(events, selectedEventIds, id) {
  var eventsToRemove = getEventsToRemoveForProperty(events, selectedEventIds, id);
  return Belt_Array.mapU(eventsToRemove, (function ($$event) {
                return [
                        {
                          NAME: "RemovePropertyRefV3",
                          VAL: [
                            $$event.id,
                            id
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id,
                          propertyId: id,
                          propertyQuery: id
                        }
                      ];
              }));
}

function getPropertyBundleAddActions(events, selectedEventIds, id) {
  var eventsToAdd = getEventsToAddForPropertyBundle(events, selectedEventIds, id);
  return Belt_Array.mapU(eventsToAdd, (function ($$event) {
                return [
                        {
                          NAME: "AddPropertyGroupToEvent",
                          VAL: [
                            $$event.id,
                            id
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id,
                          propertyGroupId: id,
                          propertyGroupQuery: id
                        }
                      ];
              }));
}

function getPropertyBundleRemoveActions(events, selectedEventIds, id) {
  var eventsToRemove = getEventsToRemoveForPropertyBundle(events, selectedEventIds, id);
  return Belt_Array.mapU(eventsToRemove, (function ($$event) {
                return [
                        {
                          NAME: "RemovePropertyGroupFromEvent",
                          VAL: [
                            $$event.id,
                            id
                          ]
                        },
                        {
                          eventId: $$event.id,
                          eventQuery: $$event.id,
                          propertyGroupId: id,
                          propertyGroupQuery: id
                        }
                      ];
              }));
}

function BulkBar$PropertiesAndBundles$Item(Props) {
  var item = Props.item;
  if (item.NAME === "property") {
    var match = item.VAL;
    var match$1 = match[1];
    var selectedEventIds = match$1.selectedEventIds;
    var events = match$1.events;
    var match$2 = match[0];
    var name = match$2.name;
    var id = match$2.id;
    var itemsToAdd = getEventsToAddForProperty(events, match$1.properties, match$1.propertyBundles, selectedEventIds, id).length;
    var itemsToRemove = getEventsToRemoveForProperty(events, selectedEventIds, id).length;
    return React.createElement(BulkBar$OptionItem, {
                title: name,
                onAdd: Curry._2(match$1.handleAddProperty, id, name),
                onRemove: Curry._2(match$1.handleRemoveProperty, id, name),
                itemsToAdd: itemsToAdd,
                itemsToRemove: itemsToRemove,
                key: id
              });
  }
  var match$3 = item.VAL;
  var match$4 = match$3[1];
  var selectedEventIds$1 = match$4.selectedEventIds;
  var events$1 = match$4.events;
  var match$5 = match$3[0];
  var name$1 = match$5.name;
  var id$1 = match$5.id;
  var itemsToAdd$1 = getEventsToAddForPropertyBundle(events$1, selectedEventIds$1, id$1).length;
  var itemsToRemove$1 = getEventsToRemoveForPropertyBundle(events$1, selectedEventIds$1, id$1).length;
  return React.createElement(BulkBar$OptionItem, {
              title: name$1,
              onAdd: Curry._2(match$4.handleAddPropertyBundle, id$1, name$1),
              onRemove: Curry._2(match$4.handleRemovePropertyBundle, id$1, name$1),
              itemsToAdd: itemsToAdd$1,
              itemsToRemove: itemsToRemove$1,
              subtitle: "Bundle of " + AvoUtils.plural("properties", undefined, match$5.propertiesContained, "property") + "",
              key: id$1
            });
}

function BulkBar$PropertiesAndBundles$ReactWindowRow(Props) {
  var index = Props.index;
  var style = Props.style;
  var data = Props.data;
  var item = Belt_Array.getExn(data, index);
  return React.createElement("div", {
              style: {
                height: style.height,
                left: style.left,
                position: style.position,
                top: String(style.top) + "px",
                width: style.width
              }
            }, React.createElement(BulkBar$PropertiesAndBundles$Item, {
                  item: item
                }));
}

function handleAddProperty(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, addToast, id, name, param) {
  var actions = getPropertyAddActions(events, properties, propertyBundles, selectedEventIds, id);
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            Curry._1(addToast, {
                  message: getMessage(undefined, undefined, actions.length, name)
                });
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.eventsBulkUpdatedProperty(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, actions.length, id, name, "Add", schemaBundle$1.schemaId, schemaBundle$1.branchId);
          }),
        undefined,
        actions
      ]);
}

function handleRemoveProperty(events, properties, param, selectedEventIds, schemaBundle, sendActions, addToast, id, name, param$1) {
  var actions = getPropertyRemoveActions(events, selectedEventIds, id);
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (_branch) {
            var propertyName = Belt_Option.getWithDefault(TrackingPlanMappedModel.Properties.getName(properties, id), "property");
            Curry._1(addToast, {
                  message: getMessage(false, undefined, actions.length, propertyName)
                });
            AnalyticsRe.eventsBulkUpdatedProperty(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, actions.length, id, name, "Remove", schemaBundle.schemaId, schemaBundle.branchId);
          }),
        undefined,
        actions
      ]);
}

function handleAddPropertyBundle(events, param, propertyBundles, selectedEventIds, schemaBundle, sendActions, addToast, id, name, param$1) {
  var actions = getPropertyBundleAddActions(events, selectedEventIds, id);
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var propertyBundleName = Belt_Option.getWithDefault(TrackingPlanMappedModel.PropertyBundles.getName(propertyBundles, id), "property bundle");
            Curry._1(addToast, {
                  message: getMessage(undefined, undefined, actions.length, propertyBundleName)
                });
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.eventsBulkUpdatedPropertyBundle(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, actions.length, id, name, "Add", schemaBundle$1.schemaId, schemaBundle$1.branchId);
          }),
        undefined,
        actions
      ]);
}

function handleRemovePropertyBundle(events, param, propertyBundles, selectedEventIds, schemaBundle, sendActions, addToast, id, name, param$1) {
  var actions = getPropertyBundleRemoveActions(events, selectedEventIds, id);
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            var propertyBundleName = Belt_Option.getWithDefault(TrackingPlanMappedModel.PropertyBundles.getName(propertyBundles, id), "property bundle");
            Curry._1(addToast, {
                  message: getMessage(false, undefined, actions.length, propertyBundleName)
                });
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.eventsBulkUpdatedPropertyBundle(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, actions.length, id, name, "Remove", schemaBundle$1.schemaId, schemaBundle$1.branchId);
          }),
        undefined,
        actions
      ]);
}

function withMetadata(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, fn) {
  return Curry._6(fn, events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions);
}

function BulkBar$PropertiesAndBundles(Props) {
  var match = BulkContext.use(undefined);
  var selectedEventIds = match.selectedEventIds;
  var properties = ModelStore.Mapped.useProperties(undefined);
  var propertyBundles = ModelStore.Mapped.usePropertyBundles(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match$1 = React.useState(function () {
        return "";
      });
  var searchValue = match$1[0];
  var handleAddProperty$1 = Curry._1(withMetadata(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, handleAddProperty), addToast);
  var handleRemoveProperty$1 = Curry._1(withMetadata(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, handleRemoveProperty), addToast);
  var handleAddPropertyBundle$1 = Curry._1(withMetadata(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, handleAddPropertyBundle), addToast);
  var handleRemovePropertyBundle$1 = Curry._1(withMetadata(events, properties, propertyBundles, selectedEventIds, schemaBundle, sendActions, handleRemovePropertyBundle), addToast);
  var sortedEventProperties = React.useMemo((function () {
          return Belt_SortArray.stableSortByU(Curry._2(TrackingPlanMappedModel.Properties.keepMap, properties, (function (param) {
                            if (param.sendAs === /* EventProperty */1) {
                              return {
                                      id: param.id,
                                      name: param.name
                                    };
                            }
                            
                          })), (function (param, param$1) {
                        return $$String.compare(param.name.toLowerCase(), param$1.name.toLowerCase());
                      }));
        }), [properties]);
  var sortedPropertyBundles = React.useMemo((function () {
          return Belt_SortArray.stableSortByU(Curry._2(TrackingPlanMappedModel.PropertyBundles.mapToArray, propertyBundles, (function (param) {
                            return {
                                    id: param.id,
                                    name: param.name,
                                    propertiesContained: Belt_List.size(param.properties)
                                  };
                          })), (function (param, param$1) {
                        return $$String.compare(param.name.toLowerCase(), param$1.name.toLowerCase());
                      }));
        }), [propertyBundles]);
  var metadata = React.useMemo((function () {
          return {
                  events: events,
                  properties: properties,
                  propertyBundles: propertyBundles,
                  selectedEventIds: selectedEventIds,
                  handleAddProperty: handleAddProperty$1,
                  handleRemoveProperty: handleRemoveProperty$1,
                  handleAddPropertyBundle: handleAddPropertyBundle$1,
                  handleRemovePropertyBundle: handleRemovePropertyBundle$1
                };
        }), [
        events,
        properties,
        propertyBundles,
        selectedEventIds
      ]);
  var itemData = React.useMemo((function () {
          var propertyItems = searchValue === "" ? Belt_Array.mapU(sortedEventProperties, (function (p) {
                    return {
                            NAME: "property",
                            VAL: [
                              p,
                              metadata
                            ]
                          };
                  })) : Belt_Array.mapU(Fuzzaldrin.filter(sortedEventProperties, searchValue, {
                      key: "name",
                      maxResults: 100
                    }), (function (p) {
                    return {
                            NAME: "property",
                            VAL: [
                              p,
                              metadata
                            ]
                          };
                  }));
          var propertyBundleItems = searchValue === "" ? Belt_Array.mapU(sortedPropertyBundles, (function (pb) {
                    return {
                            NAME: "propertyBundle",
                            VAL: [
                              pb,
                              metadata
                            ]
                          };
                  })) : Belt_Array.mapU(Fuzzaldrin.filter(sortedPropertyBundles, searchValue, {
                      key: "name",
                      maxResults: 100
                    }), (function (pb) {
                    return {
                            NAME: "propertyBundle",
                            VAL: [
                              pb,
                              metadata
                            ]
                          };
                  }));
          return Belt_Array.concat(propertyBundleItems, propertyItems);
        }), [
        sortedEventProperties,
        sortedPropertyBundles,
        searchValue,
        metadata
      ]);
  var itemKey = React.useCallback((function (i) {
          var match = Belt_Array.get(itemData, i);
          if (match !== undefined) {
            return match.VAL[0].id;
          } else {
            return "unknown";
          }
        }), [itemData]);
  var itemSize = React.useCallback((function (i) {
          var match = Belt_Array.get(itemData, i);
          if (match !== undefined) {
            if (match.NAME === "property") {
              return 25;
            } else {
              return 39;
            }
          } else {
            return 0;
          }
        }), [itemData]);
  var dataHeight = React.useMemo((function () {
          return Belt_Array.reduceU(itemData, 0, (function (sum, item) {
                        if (item.NAME === "property") {
                          return sum + 25 | 0;
                        } else {
                          return sum + 39 | 0;
                        }
                      }));
        }), [itemData]);
  return React.createElement(BulkBar$OptionToAdd, {
              title: "Event Property",
              searchValue: searchValue,
              onSearchChange: match$1[1],
              children: React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.height(Css.px(Math.min(dataHeight, match.maxHeight))),
                          tl: /* [] */0
                        })
                  }, React.createElement(ReactVirtualizedAutoSizer, {
                        children: (function (param) {
                            return React.createElement(ReactWindow.VariableSizeList, {
                                        estimatedItemSize: 32,
                                        height: param.height,
                                        itemCount: itemData.length,
                                        itemData: itemData,
                                        itemKey: itemKey,
                                        itemSize: itemSize,
                                        overscanCount: 20,
                                        width: param.width,
                                        children: BulkBar$PropertiesAndBundles$ReactWindowRow,
                                        key: searchValue
                                      });
                          })
                      }))
            });
}

function BulkBar$Sources(Props) {
  var sources = ModelStore.Mapped.useSources(undefined);
  var match = BulkContext.use(undefined);
  var selectedEventIds = match.selectedEventIds;
  var events = ModelStore.Mapped.useEvents(undefined);
  var destinations = ModelStore.Mapped.useDestinations(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var count = Belt_SetString.size(selectedEventIds);
  var match$1 = React.useState(function () {
        return "";
      });
  var searchValue = match$1[0];
  var filteredSources = React.useMemo((function () {
          if (searchValue === "") {
            return Curry._1(TrackingPlanMappedModel.Sources.toArray, sources);
          } else {
            return Fuzzaldrin.filter(Curry._1(TrackingPlanMappedModel.Sources.toArray, sources), searchValue, {
                        key: "name",
                        maxResults: 100
                      });
          }
        }), [
        sources,
        searchValue
      ]);
  return React.createElement(BulkBar$OptionToAdd, {
              title: "Source",
              searchValue: searchValue,
              onSearchChange: match$1[1],
              children: Belt_Array.mapU(filteredSources, (function (source) {
                      var itemsAffected = Belt_Array.keep(Belt_Array.keepMap(Belt_SetString.toArray(selectedEventIds), Curry._1(TrackingPlanMappedModel.Events.get, events)), (function ($$event) {
                              return !ModelUtils_mapped.isEventIncludedInSource(undefined, events, source.id, $$event.id);
                            })).length;
                      return React.createElement(BulkBar$OptionItem, {
                                  title: AvoConfig.getSourceName(source),
                                  onAdd: (function (param) {
                                      var eventsToAdd = Belt_Array.keep(Belt_Array.keepMap(Belt_SetString.toArray(selectedEventIds), Curry._1(TrackingPlanMappedModel.Events.get, events)), (function ($$event) {
                                              return !ModelUtils_mapped.isEventIncludedInSource(undefined, events, source.id, $$event.id);
                                            }));
                                      var destinations$1 = ModelUtils_mapped.getDefaultDestinationsForSource(destinations, source);
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(undefined, true, count, AvoConfig.getSourceName(source))
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedSource(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventsToAdd.length, AvoConfig.getSourceName(source), source.id, "Add", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.map(eventsToAdd, (function ($$event) {
                                                          return [
                                                                  {
                                                                    NAME: "IncludeEventInSourceV2",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      source.id,
                                                                      destinations$1,
                                                                      false
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: $$event.id,
                                                                    eventQuery: $$event.id,
                                                                    sourceId: source.id,
                                                                    sourceQuery: source.id
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  onRemove: (function (param) {
                                      var eventsToRemove = Belt_Array.keep(Belt_Array.keepMap(Belt_SetString.toArray(selectedEventIds), Curry._1(TrackingPlanMappedModel.Events.get, events)), (function ($$event) {
                                              return ModelUtils_mapped.isEventIncludedInSource(undefined, events, source.id, $$event.id);
                                            }));
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(false, true, count, AvoConfig.getSourceName(source))
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedSource(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, count, AvoConfig.getSourceName(source), source.id, "Remove", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.mapU(eventsToRemove, (function ($$event) {
                                                          return [
                                                                  {
                                                                    NAME: "ExcludeEventFromSourceV2",
                                                                    VAL: [
                                                                      $$event.id,
                                                                      source.id
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: $$event.id,
                                                                    eventQuery: $$event.id,
                                                                    sourceId: source.id,
                                                                    sourceQuery: source.id
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  itemsToAdd: itemsAffected,
                                  itemsToRemove: Belt_SetString.size(selectedEventIds) - itemsAffected | 0,
                                  key: source.id
                                });
                    }))
            });
}

function BulkBar$Tags(Props) {
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var tags = Curry._3(TrackingPlanMappedModel.Events.reduce, events, undefined, (function (tagsMap, $$event) {
          return Belt_List.reduceU($$event.tags, tagsMap, (function (tagsMap, tag) {
                        return Belt_MapString.updateU(tagsMap, tag, (function (maybeEventIds) {
                                      if (maybeEventIds !== undefined) {
                                        return {
                                                tag: maybeEventIds.tag,
                                                eventIds: Belt_SetString.add(maybeEventIds.eventIds, $$event.id)
                                              };
                                      } else {
                                        return {
                                                tag: tag,
                                                eventIds: Belt_SetString.fromArray([$$event.id])
                                              };
                                      }
                                    }));
                      }));
        }));
  var match = React.useState(function () {
        return "";
      });
  var searchValue = match[0];
  var filteredTags = React.useMemo((function () {
          var allTags = Belt_SortArray.stableSortByU(Belt_MapString.valuesToArray(tags), (function (param, param$1) {
                  return $$String.compare(param.tag, param$1.tag);
                }));
          if (searchValue === "") {
            return allTags;
          } else {
            return Fuzzaldrin.filter(allTags, searchValue, {
                        key: "tag",
                        maxResults: 100
                      });
          }
        }), [
        tags,
        searchValue
      ]);
  var match$1 = BulkContext.use(undefined);
  var selectedEventIds = match$1.selectedEventIds;
  return React.createElement(BulkBar$OptionToAdd, {
              title: "Tag",
              searchValue: searchValue,
              onSearchChange: match[1],
              children: Belt_Array.mapU(filteredTags, (function (param) {
                      var tag = param.tag;
                      var itemsAffected = Belt_SetString.size(Belt_SetString.diff(selectedEventIds, param.eventIds));
                      return React.createElement(BulkBar$OptionItem, {
                                  title: tag,
                                  onAdd: (function (param) {
                                      var eventsToAdd = Belt_SetString.toArray(Belt_SetString.diff(selectedEventIds, Belt_Option.mapWithDefault(Belt_MapString.get(tags, tag), undefined, (function (param) {
                                                      return param.eventIds;
                                                    }))));
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(undefined, undefined, eventsToAdd.length, tag)
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedTag(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventsToAdd.length, tag, "Add", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.mapU(eventsToAdd, (function (eventId) {
                                                          return [
                                                                  {
                                                                    NAME: "AddTag",
                                                                    VAL: [
                                                                      eventId,
                                                                      tag
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: eventId,
                                                                    eventQuery: eventId
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  onRemove: (function (param) {
                                      var eventsToRemove = Belt_SetString.toArray(Belt_SetString.intersect(Belt_Option.mapWithDefault(Belt_MapString.get(tags, tag), undefined, (function (param) {
                                                      return param.eventIds;
                                                    })), selectedEventIds));
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(false, undefined, eventsToRemove.length, tag)
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedTag(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventsToRemove.length, tag, "Remove", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.mapU(eventsToRemove, (function (eventId) {
                                                          return [
                                                                  {
                                                                    NAME: "RemoveTag",
                                                                    VAL: [
                                                                      eventId,
                                                                      tag
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: eventId,
                                                                    eventQuery: eventId
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  itemsToAdd: itemsAffected,
                                  itemsToRemove: Belt_SetString.size(selectedEventIds) - itemsAffected | 0,
                                  key: tag
                                });
                    }))
            });
}

function BulkBar$Categories(Props) {
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var categories = ModelStore.Mapped.useCategories(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = BulkContext.use(undefined);
  var selectedEventIds = match.selectedEventIds;
  var match$1 = React.useState(function () {
        return "";
      });
  var searchValue = match$1[0];
  var filteredCategories = React.useMemo((function () {
          if (searchValue === "") {
            return Curry._1(TrackingPlanMappedModel.Categories.toArray, categories);
          } else {
            return Fuzzaldrin.filter(Curry._1(TrackingPlanMappedModel.Categories.toArray, categories), searchValue, {
                        key: "name",
                        maxResults: 100
                      });
          }
        }), [
        categories,
        searchValue
      ]);
  return React.createElement(BulkBar$OptionToAdd, {
              title: "Category",
              searchValue: searchValue,
              onSearchChange: match$1[1],
              children: Belt_Array.mapU(filteredCategories, (function (category) {
                      var itemsToAdd = Belt_SetString.size(Belt_SetString.diff(selectedEventIds, Belt_SetString.fromArray(Belt_List.toArray(category.events))));
                      var itemsToRemove = Belt_SetString.size(Belt_SetString.intersect(Belt_SetString.fromArray(Belt_List.toArray(category.events)), selectedEventIds));
                      return React.createElement(BulkBar$OptionItem, {
                                  title: category.name,
                                  onAdd: (function (param) {
                                      var eventsToAdd = Belt_SetString.toArray(Belt_SetString.diff(selectedEventIds, Belt_SetString.fromArray(Belt_List.toArray(category.events))));
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(undefined, true, eventsToAdd.length, category.name)
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedCategory(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventsToAdd.length, category.id, category.name, "Add", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.mapU(eventsToAdd, (function (eventId) {
                                                          return [
                                                                  {
                                                                    NAME: "AddEventToGoal",
                                                                    VAL: [
                                                                      category.id,
                                                                      eventId
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: eventId,
                                                                    eventQuery: eventId,
                                                                    goalId: category.id,
                                                                    goalQuery: category.id
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  onRemove: (function (param) {
                                      var eventsToRemove = Belt_SetString.toArray(Belt_SetString.intersect(Belt_SetString.fromArray(Belt_List.toArray(category.events)), selectedEventIds));
                                      return Curry.app(sendActions, [
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  undefined,
                                                  (function (branch) {
                                                      Curry._1(addToast, {
                                                            message: getMessage(false, true, eventsToRemove.length, category.name)
                                                          });
                                                      var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                      AnalyticsRe.eventsBulkUpdatedCategory(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, eventsToRemove.length, category.id, category.name, "Remove", schemaBundle$1.schemaId, schemaBundle$1.branchId);
                                                    }),
                                                  undefined,
                                                  Belt_Array.mapU(eventsToRemove, (function (eventId) {
                                                          return [
                                                                  {
                                                                    NAME: "RemoveEventFromGoal",
                                                                    VAL: [
                                                                      category.id,
                                                                      eventId
                                                                    ]
                                                                  },
                                                                  {
                                                                    eventId: eventId,
                                                                    eventQuery: eventId,
                                                                    goalId: category.id,
                                                                    goalQuery: category.id
                                                                  }
                                                                ];
                                                        }))
                                                ]);
                                    }),
                                  itemsToAdd: itemsToAdd,
                                  itemsToRemove: itemsToRemove,
                                  key: category.id
                                });
                    }))
            });
}

function BulkBar$Stakeholders(Props) {
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var domains = DomainStore.useDomains(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = BulkContext.use(undefined);
  var selectedEventIds = match.selectedEventIds;
  var viewerId = ViewerContext.use(undefined).id;
  var memberDomains = DomainStore.useMemberDomains(viewerId);
  var viewerRole = WorkspaceContext.useMemberRole(undefined);
  var match$1 = React.useState(function () {
        return "";
      });
  var searchValue = match$1[0];
  var editableDomains = viewerRole === "Admin" ? domains : memberDomains;
  var filteredDomains = React.useMemo((function () {
          if (searchValue === "") {
            return Curry._1(Domains.toArray, editableDomains);
          } else {
            return Fuzzaldrin.filter(Curry._1(Domains.toArray, editableDomains), searchValue, {
                        key: "name",
                        maxResults: 100
                      });
          }
        }), [
        editableDomains,
        searchValue
      ]);
  return React.createElement(BulkBar$OptionToAdd, {
              title: "Stakeholder",
              searchValue: searchValue,
              onSearchChange: match$1[1],
              children: Belt_Array.mapU(filteredDomains, (function (domain) {
                      var itemsToAdd = Belt_SetString.size(Belt_SetString.diff(selectedEventIds, Belt_SetString.fromArray(domain.items.events)));
                      var itemsToRemove = Belt_SetString.size(Belt_SetString.intersect(Belt_SetString.fromArray(domain.items.events), selectedEventIds));
                      return React.createElement(BulkBar$OptionItem, {
                                  title: domain.name,
                                  onAdd: (function (param) {
                                      var eventsToAdd = Belt_SetString.toArray(Belt_SetString.diff(selectedEventIds, Belt_SetString.fromArray(domain.items.events)));
                                      DomainUtils.Firebase.addDomainItems(Belt_Array.map(eventsToAdd, (function (id) {
                                                  return {
                                                          NAME: "event",
                                                          VAL: id
                                                        };
                                                })), (function (param) {
                                              Curry._1(addToast, {
                                                    message: React.createElement(React.Fragment, undefined, "Error adding " + AvoUtils.plural(undefined, undefined, eventsToAdd.length, "event") + " to ", React.createElement("i", undefined, domain.name)),
                                                    toastType: /* Error */1
                                                  });
                                            }), (function (param) {
                                              Curry._1(addToast, {
                                                    message: React.createElement(React.Fragment, undefined, "" + AvoUtils.plural(undefined, undefined, eventsToAdd.length, "event") + " added to ", React.createElement("i", undefined, domain.name)),
                                                    toastType: /* Success */0
                                                  });
                                              var partial_arg = Curry._1(Domains.size, domains);
                                              var partial_arg$1 = domain.name;
                                              var partial_arg$2 = domain.id;
                                              var func = function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16) {
                                                return AnalyticsRe.domainItemAddedMultipleItems(partial_arg$2, partial_arg$1, partial_arg, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16);
                                              };
                                              var arg = Curry._2(Domains.some, memberDomains, (function (param) {
                                                      return param.id === domain.id;
                                                    })) ? "DomainTeamMember" : "Admin";
                                              var arg$1 = eventsToAdd.length;
                                              var arg$2 = schemaBundle.schemaId;
                                              AnalyticsUtils.withSchemaBundle((function (param) {
                                                      var func$1 = Curry._1(func, param);
                                                      return function (param) {
                                                        var func$2 = Curry._1(func$1, param);
                                                        return function (param) {
                                                          var func$3 = Curry._1(func$2, param);
                                                          return function (param) {
                                                            var func$4 = Curry._1(func$3, param);
                                                            return function (param) {
                                                              var func$5 = Curry._1(func$4, param);
                                                              return function (param) {
                                                                var func$6 = Curry._1(func$5, param);
                                                                return function (param) {
                                                                  var func$7 = Curry._1(func$6, param);
                                                                  return function (param) {
                                                                    var func$8 = Curry._1(func$7, param);
                                                                    return function (param) {
                                                                      var func$9 = Curry._1(func$8, param);
                                                                      return function (param) {
                                                                        var func$10 = Curry._1(func$9, param);
                                                                        return function (param) {
                                                                          return Curry._7(func$10, param, "BulkBar", arg, "Stakeholder", arg$1, "Event", arg$2);
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    }), schemaBundle);
                                              var arg$3 = eventsToAdd.length;
                                              var arg$4 = domain.name;
                                              var arg$5 = domain.id;
                                              var arg$6 = schemaBundle.schemaId;
                                              var arg$7 = schemaBundle.branchId;
                                              AnalyticsUtils.withSchemaBundle((function (param) {
                                                      var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16) {
                                                        return AnalyticsRe.eventsBulkUpdatedStakeholder(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16);
                                                      };
                                                      return function (param) {
                                                        var func$1 = Curry._1(func, param);
                                                        return function (param) {
                                                          var func$2 = Curry._1(func$1, param);
                                                          return function (param) {
                                                            var func$3 = Curry._1(func$2, param);
                                                            return function (param) {
                                                              var func$4 = Curry._1(func$3, param);
                                                              return function (param) {
                                                                var func$5 = Curry._1(func$4, param);
                                                                return function (param) {
                                                                  var func$6 = Curry._1(func$5, param);
                                                                  return function (param) {
                                                                    var func$7 = Curry._1(func$6, param);
                                                                    return function (param) {
                                                                      var func$8 = Curry._1(func$7, param);
                                                                      return function (param) {
                                                                        var func$9 = Curry._1(func$8, param);
                                                                        return function (param) {
                                                                          return Curry._7(func$9, param, arg$3, arg$4, arg$5, "Add", arg$6, arg$7);
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    }), schemaBundle);
                                            }), schemaBundle.schemaId, sendActions, viewerId, domain.id);
                                    }),
                                  onRemove: (function (param) {
                                      var eventsToRemove = Belt_SetString.toArray(Belt_SetString.intersect(Belt_SetString.fromArray(domain.items.events), selectedEventIds));
                                      DomainUtils.Firebase.removeDomainItems(Belt_Array.map(eventsToRemove, (function (id) {
                                                  return {
                                                          NAME: "event",
                                                          VAL: id
                                                        };
                                                })), (function (param) {
                                              Curry._1(addToast, {
                                                    message: React.createElement(React.Fragment, undefined, "Error removing " + AvoUtils.plural(undefined, undefined, eventsToRemove.length, "event") + " to ", React.createElement("i", undefined, domain.name)),
                                                    toastType: /* Error */1
                                                  });
                                            }), (function (param) {
                                              Curry._1(addToast, {
                                                    message: getMessage(false, true, eventsToRemove.length, domain.name)
                                                  });
                                              var arg = eventsToRemove.length;
                                              var arg$1 = domain.name;
                                              var arg$2 = domain.id;
                                              var arg$3 = schemaBundle.schemaId;
                                              var arg$4 = schemaBundle.branchId;
                                              AnalyticsUtils.withSchemaBundle((function (param) {
                                                      var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16) {
                                                        return AnalyticsRe.eventsBulkUpdatedStakeholder(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16);
                                                      };
                                                      return function (param) {
                                                        var func$1 = Curry._1(func, param);
                                                        return function (param) {
                                                          var func$2 = Curry._1(func$1, param);
                                                          return function (param) {
                                                            var func$3 = Curry._1(func$2, param);
                                                            return function (param) {
                                                              var func$4 = Curry._1(func$3, param);
                                                              return function (param) {
                                                                var func$5 = Curry._1(func$4, param);
                                                                return function (param) {
                                                                  var func$6 = Curry._1(func$5, param);
                                                                  return function (param) {
                                                                    var func$7 = Curry._1(func$6, param);
                                                                    return function (param) {
                                                                      var func$8 = Curry._1(func$7, param);
                                                                      return function (param) {
                                                                        var func$9 = Curry._1(func$8, param);
                                                                        return function (param) {
                                                                          return Curry._7(func$9, param, arg, arg$1, arg$2, "Remove", arg$3, arg$4);
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    }), schemaBundle);
                                              var arg$5 = domain.id;
                                              var arg$6 = domain.name;
                                              var arg$7 = Curry._1(Domains.size, domains);
                                              var arg$8 = Curry._2(Domains.some, memberDomains, (function (param) {
                                                      return param.id === domain.id;
                                                    })) ? "DomainTeamMember" : "Admin";
                                              var arg$9 = eventsToRemove.length;
                                              var arg$10 = schemaBundle.schemaId;
                                              AnalyticsUtils.withSchemaBundle((function (param) {
                                                      var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19) {
                                                        return AnalyticsRe.domainItemRemovedMultipleItems(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19);
                                                      };
                                                      return function (param) {
                                                        var func$1 = Curry._1(func, param);
                                                        return function (param) {
                                                          var func$2 = Curry._1(func$1, param);
                                                          return function (param) {
                                                            var func$3 = Curry._1(func$2, param);
                                                            return function (param) {
                                                              var func$4 = Curry._1(func$3, param);
                                                              return function (param) {
                                                                var func$5 = Curry._1(func$4, param);
                                                                return function (param) {
                                                                  var func$6 = Curry._1(func$5, param);
                                                                  return function (param) {
                                                                    var func$7 = Curry._1(func$6, param);
                                                                    return function (param) {
                                                                      var func$8 = Curry._1(func$7, param);
                                                                      return function (param) {
                                                                        var func$9 = Curry._1(func$8, param);
                                                                        return function (param) {
                                                                          return Curry.app(func$9, [
                                                                                      param,
                                                                                      arg$5,
                                                                                      arg$6,
                                                                                      arg$7,
                                                                                      "BulkBar",
                                                                                      arg$8,
                                                                                      "Stakeholder",
                                                                                      arg$9,
                                                                                      "Event",
                                                                                      arg$10
                                                                                    ]);
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    }), schemaBundle);
                                            }), schemaBundle.schemaId, sendActions, viewerId, domain.id);
                                    }),
                                  itemsToAdd: itemsToAdd,
                                  itemsToRemove: itemsToRemove,
                                  key: domain.id
                                });
                    }))
            });
}

function BulkBar(Props) {
  var match = BulkContext.use(undefined);
  var clearAll = match.clearAll;
  var selectedEventIds = match.selectedEventIds;
  var sendActions = SendActionsContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var count = Belt_SetString.size(selectedEventIds);
  var handleArchive = function (param) {
    Curry.app(sendActions, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          (function (param) {
              Curry._1(clearAll, undefined);
            }),
          (function (branch) {
              Curry._1(addToast, {
                    message: "" + AvoUtils.plural(undefined, undefined, count, "event") + " have been archived"
                  });
              var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
              AnalyticsRe.eventsBulkUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "Archive", count, schemaBundle$1.schemaId, schemaBundle$1.branchId);
            }),
          undefined,
          Belt_Array.map(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                  return [
                          {
                            NAME: "Archive",
                            VAL: {
                              NAME: "Event",
                              VAL: eventId
                            }
                          },
                          {
                            eventId: eventId,
                            eventQuery: eventId
                          }
                        ];
                }))
        ]);
  };
  var handleClear = function (param) {
    Curry._1(clearAll, undefined);
    trackInteraction(schemaBundle, count, "ClearSelection");
  };
  return React.createElement("div", {
              className: container
            }, React.createElement(FramerMotion.AnimatePresence, {
                  children: Belt_SetString.isEmpty(selectedEventIds) ? null : React.createElement(FramerMotion.motion.div, {
                          animate: {
                            opacity: 1,
                            y: 0
                          },
                          transition: {
                            duration: 0.2
                          },
                          initial: {
                            opacity: 0,
                            y: 10
                          },
                          exit: {
                            opacity: 0,
                            y: 10
                          },
                          key: "BulkBar",
                          children: React.createElement("div", {
                                className: bar
                              }, React.createElement(SimpleTooltip.WithComponent.make, {
                                    offset: 20,
                                    position: "top",
                                    tooltip: (function (param) {
                                        return Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepMapU(Belt_SetString.toArray(selectedEventIds), (function (eventId) {
                                                              var match = Curry._2(TrackingPlanMappedModel.Events.get, events, eventId);
                                                              if (match !== undefined) {
                                                                return match.name;
                                                              }
                                                              
                                                            })), $$String.compare), (function (eventName) {
                                                      return React.createElement($$Text.make, {
                                                                  size: "Small",
                                                                  children: eventName,
                                                                  key: eventName
                                                                });
                                                    }));
                                      }),
                                    children: React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.marginTop(Css.px(1)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.dark11,
                                              children: null
                                            }, React.createElement($$Text.make, {
                                                  element: "Span",
                                                  weight: "Semi",
                                                  color: Styles.Color.dark12,
                                                  children: $$Number.toLocaleString(count)
                                                }), " selected"))
                                  }), React.createElement("button", {
                                    className: Curry._1(Css.merge, {
                                          hd: iconButton,
                                          tl: {
                                            hd: Curry._1(Css.style, {
                                                  hd: Css.marginLeft(Css.px(-8)),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    label: "Clear",
                                    onClick: handleClear
                                  }, React.createElement(Icon.make, {
                                        type_: "close",
                                        size: "medium"
                                      })), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.height(Css.px(16)),
                                          tl: {
                                            hd: Css.width(Css.px(1)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.light11),
                                              tl: /* [] */0
                                            }
                                          }
                                        })
                                  }), React.createElement(BulkBar$PropertiesAndBundles, {}), React.createElement(BulkBar$Sources, {}), React.createElement(BulkBar$Tags, {}), React.createElement(BulkBar$Categories, {}), React.createElement(BulkBar$Stakeholders, {}), React.createElement("button", {
                                    className: iconButton,
                                    label: "Archive",
                                    onClick: (function (param) {
                                        Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "AreYouSureModal",
                                                VAL: [
                                                  "Are you sure you want to archive " + AvoUtils.plural(undefined, undefined, count, "event") + "?",
                                                  "You can restore the events from the activity log one-by-one if you change your mind.",
                                                  "Archive",
                                                  handleArchive,
                                                  (function (param) {
                                                      
                                                    })
                                                ]
                                              }
                                            });
                                      })
                                  }, React.createElement(Icon.make, {
                                        type_: "trash"
                                      })))
                        })
                }));
}

var make = BulkBar;

export {
  make ,
}
/* container Not a pure module */
