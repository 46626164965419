// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Styles from "./styles.mjs";

function LearnHub__DefaultTourButton(Props) {
  var startTour = Props.startTour;
  return React.createElement(Button.make, {
              icon: "presentation",
              label: "Take tour",
              onClick: (function (param) {
                  Curry._1(startTour, undefined);
                }),
              style: {
                NAME: "custom",
                VAL: {
                  customColor: Styles.Color.white,
                  hoverColor: Styles.Color.white,
                  customBackgroundColor: Styles.Color.primaryPurple,
                  customBackgroundHoverColor: Styles.Color.darkPurple,
                  optionalCustomOutlineColor: undefined,
                  optionalCustomOutlineHoverColor: undefined
                }
              },
              minWidth: 132
            });
}

var make = LearnHub__DefaultTourButton;

export {
  make ,
}
/* react Not a pure module */
