// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as NameInputUtils from "./NameInputUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as AdvancedNameInput from "./AdvancedNameInput.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as VariantEventIdUseCase from "../../model/src/variants/variantEventIdUseCase.mjs";
import * as GetEventNamespaceUseCase from "./getEventNamespaceUseCase.mjs";

function openNewEventModal(origin, createMoreToggledOpt, globalSend) {
  var createMoreToggled = createMoreToggledOpt !== undefined ? createMoreToggledOpt : false;
  return Curry._1(globalSend, {
              TAG: /* OpenModal */4,
              _0: {
                NAME: "NewEvent",
                VAL: [
                  "",
                  undefined,
                  undefined,
                  undefined,
                  origin,
                  createMoreToggled
                ]
              }
            });
}

function useOpen(origin) {
  var globalSend = GlobalSendContext.use(undefined);
  return React.useCallback((function (param) {
                openNewEventModal(origin, undefined, globalSend);
              }), [
              globalSend,
              origin
            ]);
}

function isValid(name, expectedEventCase, events) {
  return NameInputUtils.isValid(NameInputUtils.validateInput(Belt_List.toArray(GetEventNamespaceUseCase.getEventNamespace(events)), name, name, expectedEventCase));
}

function createEvent(eventId, name, namingConvention, correctCase, workspace, model, sendActions, addToast, currentFilters, onWriteStart, onSuccessOpt, extraCreateActionsOpt, schemaBundle, eventOrigin, config, createMoreToggledOpt, numEventsCreatedInSequenceOpt, param) {
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (_namedBranch) {
        
      });
  var extraCreateActions = extraCreateActionsOpt !== undefined ? extraCreateActionsOpt : (function (_eventId) {
        return [];
      });
  var createMoreToggled = createMoreToggledOpt !== undefined ? createMoreToggledOpt : false;
  var numEventsCreatedInSequence = numEventsCreatedInSequenceOpt !== undefined ? numEventsCreatedInSequenceOpt : 0;
  var areEventSourcesAndDestinationsConfigurable = AvoLimits.ConfigEventSourcesAndDestinations.isAvailable(workspace);
  var expectedEventCase = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(ValidationConfigV2.EventsV2.getNameComponents(config.events));
  var eventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(ValidationConfigV2.EventsV2.getNameComponents(config.events));
  var codegenAvailability = Belt_List.head(AvoLimits.CodegenEvents.computeEntityNumericLimits(workspace, model));
  var name$1 = name.trim();
  Curry.app(sendActions, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        onWriteStart,
        (function (branch) {
            Curry._1(addToast, {
                  message: React.createElement(React.Fragment, undefined, "Event ", React.createElement("em", undefined, name$1), " created 🎉"),
                  toastType: /* Success */0,
                  action: {
                    message: "View",
                    toastCallback: (function (param) {
                        Router.Schema.pushDrawerItem(undefined, undefined, {
                              NAME: "event",
                              VAL: [
                                eventId,
                                undefined,
                                undefined,
                                false
                              ]
                            });
                      })
                  }
                });
            if (NamedBranch.getId(branch) !== schemaBundle.branchId && createMoreToggled) {
              Router.push(undefined, Router.Link.getCurrentUrl(undefined) + "?new=eventWithMore");
            }
            var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            if (namingConvention === "Advanced") {
              var componentNames = ValidationConfigV2.EventsV2.getNamesAsStructuredString(config.events);
              var componentCases = ValidationConfigV2.EventsV2.getComponentCasesAsStructuredString(config.events);
              AnalyticsRe.eventCreatedAdvancedNamingConvention(currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, eventId, name$1, correctCase, namingConvention, eventOrigin, eventNamingConvention, createMoreToggled, numEventsCreatedInSequence + 1 | 0, "Manual", undefined, componentNames, componentCases, schema.branchId, schemaBundle.schemaId);
              AnalyticsRe.eventNameUpdatedAdvancedStructure(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, eventId, name$1, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, undefined, name$1.length, Case.to_("Sentence", name$1).split(" ").length, correctCase, "EventDetails", componentNames, componentCases, eventId, schemaBundle.branchId, schemaBundle.schemaId, eventId, name$1);
            } else {
              AnalyticsRe.eventCreated(currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, eventId, name$1, correctCase, namingConvention, eventOrigin, expectedEventCase, eventNamingConvention, createMoreToggled, numEventsCreatedInSequence + 1 | 0, "Manual", undefined, schema.branchId, schemaBundle.schemaId);
              AnalyticsRe.eventNameUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, eventId, name$1, currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, namingConvention, undefined, name$1.length, Case.to_("Sentence", name$1).split(" ").length, correctCase, "EventDetails", eventId, schema.branchId, schemaBundle.schemaId, eventId, name$1);
            }
            if (!areEventSourcesAndDestinationsConfigurable) {
              AnalyticsRe.eventUpdated(eventId, name$1, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "N/A", "N/A", "AddSources", undefined, undefined, Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                              return source.id;
                            }))), [], undefined, Belt_List.length(BeltListExtensions.dedupeString(ModelUtils.eventsWithNameMapping(model))), Belt_List.length(ModelUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(ModelUtils.eventsWithNameMapping(model), (function (id) {
                              return id === eventId;
                            }))), Belt_List.length(model.events), undefined, undefined, undefined, undefined, undefined, 0, correctCase, namingConvention, "ItemDetailsView", eventId, schema.schemaId, schema.branchId, eventId, undefined, name$1);
            }
            Curry._1(onSuccess, branch);
          }),
        undefined,
        Belt_Array.concatMany([
              [[
                  {
                    NAME: "AddEvent",
                    VAL: [
                      eventId,
                      name$1
                    ]
                  },
                  {
                    eventId: eventId,
                    eventQuery: eventId
                  }
                ]],
              areEventSourcesAndDestinationsConfigurable ? [] : Belt_Array.map(Belt_List.toArray(model.sources), (function (source) {
                        return [
                                {
                                  NAME: "IncludeEventInSourceV2",
                                  VAL: [
                                    eventId,
                                    source.id,
                                    ModelUtils.getSourceDestinationIds(source.destinations),
                                    codegenAvailability !== undefined ? codegenAvailability.NAME === "AvailableDuringTrial" : true
                                  ]
                                },
                                {
                                  eventId: eventId,
                                  eventQuery: eventId,
                                  sourceId: source.id,
                                  sourceQuery: source.id
                                }
                              ];
                      })),
              Curry._1(extraCreateActions, eventId)
            ])
      ]);
}

function getPossibleItemLink(eventName, existingNamesWithIdAndIsVariant, model) {
  var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
          return param[0] === eventName;
        }));
  if (match === undefined) {
    return ;
  }
  var variantEventId = match[1];
  if (!match[2]) {
    return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                  return [
                          Router.Link.addDrawerItem(undefined, {
                                NAME: "event",
                                VAL: [
                                  param.id,
                                  undefined,
                                  undefined,
                                  false
                                ]
                              }),
                          undefined
                        ];
                }));
  }
  var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
  var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
  return [
          Router.Link.addDrawerItem(undefined, {
                NAME: "eventVariant",
                VAL: [
                  {
                    baseEventId: eventId,
                    variantId: variantId
                  },
                  undefined
                ]
              }),
          undefined
        ];
}

function NewEventModal(Props) {
  var currentFilters = Props.currentFilters;
  var events = Props.events;
  var model = Props.model;
  var name = Props.name;
  var onClose = Props.onClose;
  var onCreateOpt = Props.onCreate;
  var onSuccess = Props.onSuccess;
  var extraCreateActions = Props.extraCreateActions;
  var createMoreToggledOpt = Props.createMoreToggled;
  var eventOrigin = Props.eventOrigin;
  var role = Props.role;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var createMoreToggled = createMoreToggledOpt !== undefined ? createMoreToggledOpt : false;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config.events);
  var advancedNamingConventionToggled = Curry._1(ValidationConfigV2.Rule.isChecked, config.events.nameComponents);
  var sendActions = SendActionsContext.use(undefined);
  var match$1 = React.useState(function () {
        return createMoreToggled;
      });
  var setCreateMore = match$1[1];
  var createMore = match$1[0];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setNumEventsCreatedInSequence = match$2[1];
  var numEventsCreatedInSequence = match$2[0];
  var handleToggleCreateMore = function (param) {
    Curry._1(setCreateMore, (function (createMore) {
            return !createMore;
          }));
    var arg = createMore ? "ToggleCreateMoreOff" : "ToggleCreateMoreOn";
    var arg$1 = schemaBundle.schemaId;
    var arg$2 = schemaBundle.branchId;
    AnalyticsUtils.withSchemaBundle((function (param) {
            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
              return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
            };
            return function (param) {
              var func$1 = Curry._1(func, param);
              return function (param) {
                var func$2 = Curry._1(func$1, param);
                return function (param) {
                  var func$3 = Curry._1(func$2, param);
                  return function (param) {
                    var func$4 = Curry._1(func$3, param);
                    return function (param) {
                      var func$5 = Curry._1(func$4, param);
                      return function (param) {
                        var func$6 = Curry._1(func$5, param);
                        return function (param) {
                          var func$7 = Curry._1(func$6, param);
                          return function (param) {
                            var func$8 = Curry._1(func$7, param);
                            return function (param) {
                              var func$9 = Curry._1(func$8, param);
                              return function (param) {
                                return Curry._4(func$9, param, arg, arg$1, arg$2);
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                };
              };
            };
          }), schemaBundle);
  };
  KeyListener.use(undefined, undefined, true, (function (_e) {
          Curry._1(setCreateMore, (function (createMore) {
                  return !createMore;
                }));
          var arg = createMore ? "ToggleCreateMoreOffUsingKeyboard" : "ToggleCreateMoreOnUsingKeyboard";
          var arg$1 = schemaBundle.schemaId;
          var arg$2 = schemaBundle.branchId;
          AnalyticsUtils.withSchemaBundle((function (param) {
                  var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                    return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                  };
                  return function (param) {
                    var func$1 = Curry._1(func, param);
                    return function (param) {
                      var func$2 = Curry._1(func$1, param);
                      return function (param) {
                        var func$3 = Curry._1(func$2, param);
                        return function (param) {
                          var func$4 = Curry._1(func$3, param);
                          return function (param) {
                            var func$5 = Curry._1(func$4, param);
                            return function (param) {
                              var func$6 = Curry._1(func$5, param);
                              return function (param) {
                                var func$7 = Curry._1(func$6, param);
                                return function (param) {
                                  var func$8 = Curry._1(func$7, param);
                                  return function (param) {
                                    var func$9 = Curry._1(func$8, param);
                                    return function (param) {
                                      return Curry._4(func$9, param, arg, arg$1, arg$2);
                                    };
                                  };
                                };
                              };
                            };
                          };
                        };
                      };
                    };
                  };
                }), schemaBundle);
        }), undefined, "m");
  var eventIdRef = React.useRef(Nanoid.nanoid());
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var eventId = eventIdRef.current;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, name);
  React.useEffect((function () {
          AnalyticsRe.eventInitiated(currentFilters.currentTagFilters, currentFilters.currentSourceFilters, currentFilters.currentDestinationFilters, currentFilters.currentSorting, currentFilters.currentPropertyGroupFilters, currentFilters.currentPropertyFilters, currentFilters.currentActionFilters, currentFilters.currentCategoryFilters, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, eventId, "", correctCase, hasBasicNameCasing ? Case.toAnalytics(expectedEventCase) : "Advanced", numEventsCreatedInSequence > 0 ? "CreateMore" : "ClickCreationButton", eventOrigin, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var existingNamesWithIdAndIsVariant = GetEventNamespaceUseCase.getEventNamespaceWithIdAndIsVariant(events);
  var tmp;
  if (!hasBasicNameCasing && advancedNamingConventionToggled) {
    var tmp$1 = {
      getItemLink: (function (eventName) {
          var match = Belt_List.getByU(existingNamesWithIdAndIsVariant, (function (param) {
                  return param[0] === eventName;
                }));
          if (match === undefined) {
            return ;
          }
          var variantEventId = match[1];
          if (!match[2]) {
            return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                          return Router.Link.addDrawerItem(undefined, {
                                      NAME: "event",
                                      VAL: [
                                        param.id,
                                        undefined,
                                        undefined,
                                        false
                                      ]
                                    });
                        }));
          }
          var eventId = VariantEventIdUseCase.baseEventIdFromVariantEventId(variantEventId);
          var variantId = VariantEventIdUseCase.variantIdFromVariantEventId(variantEventId);
          return Router.Link.addDrawerItem(undefined, {
                      NAME: "eventVariant",
                      VAL: [
                        {
                          baseEventId: eventId,
                          variantId: variantId
                        },
                        undefined
                      ]
                    });
        }),
      existingNames: Belt_List.map(existingNamesWithIdAndIsVariant, (function (param) {
              return param[0];
            })),
      createMore: [
        createMore,
        handleToggleCreateMore
      ],
      onClose: onClose,
      onChange: (function (name, _components, _nameComponents, onReset) {
          Curry._1(setNumEventsCreatedInSequence, (function (param) {
                  return numEventsCreatedInSequence + 1 | 0;
                }));
          createEvent(eventId, name, "Advanced", correctCase, workspace, model, sendActions, addToast, currentFilters, (function (_branch) {
                  Curry._2(onCreate, eventId, name);
                  eventIdRef.current = Nanoid.nanoid();
                  if (createMore) {
                    return Curry._1(onReset, undefined);
                  } else {
                    return Curry._1(onClose, undefined);
                  }
                }), (function (branch) {
                  Belt_Option.forEach(onSuccess, (function (onSuccess) {
                          Curry._3(onSuccess, eventId, name, branch);
                        }));
                }), extraCreateActions, schemaBundle, eventOrigin, config, createMore, numEventsCreatedInSequence, undefined);
        }),
      eventEdit: "Create",
      role: role,
      shadow: true
    };
    var tmp$2 = name.trim() === "" ? undefined : name;
    if (tmp$2 !== undefined) {
      tmp$1.currentName = Caml_option.valFromOption(tmp$2);
    }
    tmp = React.createElement(AdvancedNameInput.make, tmp$1);
  } else {
    tmp = React.createElement(NameInput.make, {
          name: name,
          existingNames: Belt_Array.map(Belt_List.toArray(existingNamesWithIdAndIsVariant), (function (param) {
                  return param[0];
                })),
          expectedCase: expectedEventCase,
          forceCase: ValidationConfigV2.EventsV2.hasEnforcedNameCasing(config.events),
          autoFocus: true,
          onChange: (function (name, namingConvention, correctCase, onReset) {
              Curry._1(setNumEventsCreatedInSequence, (function (param) {
                      return numEventsCreatedInSequence + 1 | 0;
                    }));
              createEvent(eventId, name, Case.toAnalytics(namingConvention), correctCase, workspace, model, sendActions, addToast, currentFilters, (function (_branch) {
                      Curry._2(onCreate, eventId, name);
                      eventIdRef.current = Nanoid.nanoid();
                      if (createMore) {
                        return Curry._1(onReset, undefined);
                      } else {
                        return Curry._1(onClose, undefined);
                      }
                    }), (function (branch) {
                      Belt_Option.forEach(onSuccess, (function (onSuccess) {
                              Curry._3(onSuccess, eventId, name, branch);
                            }));
                    }), undefined, schemaBundle, eventOrigin, config, createMore, numEventsCreatedInSequence, undefined);
            }),
          submitLabel: "Create Event",
          placeholder: "Event Name",
          onClose: (function (param) {
              Curry._1(onClose, undefined);
            }),
          fullscreen: true,
          dirty: name !== "",
          itemType: "Event",
          actionType: "Create",
          getPossibleItemLink: (function (eventName) {
              return getPossibleItemLink(eventName, existingNamesWithIdAndIsVariant, model);
            }),
          createMore: [
            createMore,
            handleToggleCreateMore
          ]
        });
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.minWidth(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: advancedNamingConventionToggled && !hasBasicNameCasing ? Styles.emptyStyle : Css.height(Css.px(75)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, tmp);
}

var make = NewEventModal;

export {
  openNewEventModal ,
  useOpen ,
  isValid ,
  createEvent ,
  getPossibleItemLink ,
  make ,
}
/* Css Not a pure module */
