// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function CollapsedContent(Props) {
  var collapseLabel = Props.collapseLabel;
  var expandLabel = Props.expandLabel;
  var button = Props.button;
  var containerStyleOpt = Props.containerStyle;
  var initiallyCollapsedOpt = Props.initiallyCollapsed;
  var children = Props.children;
  var containerStyle = containerStyleOpt !== undefined ? containerStyleOpt : /* [] */0;
  var initiallyCollapsed = initiallyCollapsedOpt !== undefined ? initiallyCollapsedOpt : true;
  var match = React.useState(function () {
        return initiallyCollapsed;
      });
  var setCollapsed = match[1];
  var collapsed = match[0];
  return React.createElement("div", {
              className: Curry._1(Css.style, containerStyle)
            }, button !== undefined ? Curry._2(button, (function (param) {
                      Curry._1(setCollapsed, (function (collapsed) {
                              return !collapsed;
                            }));
                    }), collapsed) : React.createElement("button", {
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.button),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: {
                                    hd: Css.color(Styles.Color.darkBlue),
                                    tl: {
                                      hd: Css.fontSize(Styles.FontSize.regular),
                                      tl: /* [] */0
                                    }
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }),
                    onClick: (function (param) {
                        Curry._1(setCollapsed, (function (collapsed) {
                                return !collapsed;
                              }));
                      })
                  }, collapsed ? Belt_Option.getWithDefault(expandLabel, "Expand") : Belt_Option.getWithDefault(collapseLabel, "Collapse")), React.createElement("div", undefined, collapsed ? null : Curry._1(children, (function (param) {
                          Curry._1(setCollapsed, (function (collapsed) {
                                  return !collapsed;
                                }));
                        }))));
}

var make = CollapsedContent;

export {
  make ,
}
/* Css Not a pure module */
