// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Printer from "../../model/src/Printer.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as FlattenVariantsUseCase from "../../model/src/variants/flattenVariantsUseCase.mjs";
import * as BranchesDiscrepanciesWorker from "./workers/BranchesDiscrepanciesWorker.mjs";
import * as BranchesDiscrepanciesWorkerInstance from "./workers/BranchesDiscrepanciesWorkerInstance.mjs";

function getDiscrepanciesDiff(mainDiscrepancies, branchDiscrepancies) {
  return Belt_Array.mapU(branchDiscrepancies, (function (branchDiscrepancy) {
                var variant = branchDiscrepancy.NAME;
                if (variant === "SameEventName") {
                  var match = branchDiscrepancy.VAL;
                  var branchL = match[2];
                  var match$1 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SameEventName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList;
                  if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "SameEventName") {
                    var mainDict = Js_dict.fromList(Belt_List.mapU(match$1.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList = Belt_List.keepU(branchL, (function (branchItem) {
                            var match = Js_dict.get(mainDict, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList = branchL;
                  }
                  return {
                          NAME: "SameEventName",
                          VAL: [
                            match[0],
                            match[1],
                            branchList
                          ]
                        };
                }
                if (variant === "SamePropertyName") {
                  var match$2 = branchDiscrepancy.VAL;
                  var branchL$1 = match$2[2];
                  var match$3 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "SamePropertyName";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$1;
                  if (match$3 !== undefined && typeof match$3 === "object" && match$3.NAME === "SamePropertyName") {
                    var mainDict$1 = Js_dict.fromList(Belt_List.mapU(match$3.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$1 = Belt_List.keepU(branchL$1, (function (branchItem) {
                            var match = Js_dict.get(mainDict$1, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$1 = branchL$1;
                  }
                  return {
                          NAME: "SamePropertyName",
                          VAL: [
                            match$2[0],
                            match$2[1],
                            branchList$1
                          ]
                        };
                }
                if (variant === "PropertiesWithAnyType") {
                  var match$4 = branchDiscrepancy.VAL;
                  var branchL$2 = match$4[2];
                  var match$5 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithAnyType";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$2;
                  if (match$5 !== undefined && typeof match$5 === "object" && match$5.NAME === "PropertiesWithAnyType") {
                    var mainDict$2 = Js_dict.fromList(Belt_List.mapU(match$5.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$2 = Belt_List.keepU(branchL$2, (function (branchItem) {
                            var match = Js_dict.get(mainDict$2, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$2 = branchL$2;
                  }
                  return {
                          NAME: "PropertiesWithAnyType",
                          VAL: [
                            match$4[0],
                            match$4[1],
                            branchList$2
                          ]
                        };
                }
                if (variant === "EventsWithoutDescription") {
                  var match$6 = branchDiscrepancy.VAL;
                  var branchL$3 = match$6[2];
                  var match$7 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$3;
                  if (match$7 !== undefined && typeof match$7 === "object" && match$7.NAME === "EventsWithoutDescription") {
                    var mainDict$3 = Js_dict.fromList(Belt_List.mapU(match$7.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$3 = Belt_List.keepU(branchL$3, (function (branchItem) {
                            var match = Js_dict.get(mainDict$3, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$3 = branchL$3;
                  }
                  return {
                          NAME: "EventsWithoutDescription",
                          VAL: [
                            match$6[0],
                            match$6[1],
                            branchList$3
                          ]
                        };
                }
                if (variant === "EventsWithoutExpectedValues") {
                  var match$8 = branchDiscrepancy.VAL;
                  var branchL$4 = match$8[2];
                  var match$9 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithoutExpectedValues";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$4;
                  if (match$9 !== undefined && typeof match$9 === "object" && match$9.NAME === "EventsWithoutExpectedValues") {
                    var mainDict$4 = Js_dict.fromList(Belt_List.mapU(match$9.VAL[2], (function (param) {
                                return [
                                        param[0].id,
                                        "Present"
                                      ];
                              })));
                    branchList$4 = Belt_List.keepU(branchL$4, (function (param) {
                            var match = Js_dict.get(mainDict$4, param[0].id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$4 = branchL$4;
                  }
                  return {
                          NAME: "EventsWithoutExpectedValues",
                          VAL: [
                            match$8[0],
                            match$8[1],
                            branchList$4
                          ]
                        };
                }
                if (variant === "EventsWithUnexpectedCase") {
                  var match$10 = branchDiscrepancy.VAL;
                  var branchL$5 = match$10[2];
                  var match$11 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "EventsWithUnexpectedCase";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$5;
                  if (match$11 !== undefined && typeof match$11 === "object" && match$11.NAME === "EventsWithUnexpectedCase") {
                    var mainDict$5 = Js_dict.fromList(Belt_List.mapU(match$11.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$5 = Belt_List.keepU(branchL$5, (function (branchItem) {
                            var match = Js_dict.get(mainDict$5, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$5 = branchL$5;
                  }
                  return {
                          NAME: "EventsWithUnexpectedCase",
                          VAL: [
                            match$10[0],
                            match$10[1],
                            branchList$5
                          ]
                        };
                }
                if (variant === "PropertyConflictOnEvent") {
                  var match$12 = branchDiscrepancy.VAL;
                  var branchL$6 = match$12[2];
                  var match$13 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertyConflictOnEvent";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$6;
                  if (match$13 !== undefined && typeof match$13 === "object" && match$13.NAME === "PropertyConflictOnEvent") {
                    var mainDict$6 = Js_dict.fromList(Belt_List.mapU(match$13.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$6 = Belt_List.keepU(branchL$6, (function (branchItem) {
                            var match = Js_dict.get(mainDict$6, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$6 = branchL$6;
                  }
                  return {
                          NAME: "PropertyConflictOnEvent",
                          VAL: [
                            match$12[0],
                            match$12[1],
                            branchList$6
                          ]
                        };
                }
                if (variant === "PropertiesWithoutDescription") {
                  var match$14 = branchDiscrepancy.VAL;
                  var branchL$7 = match$14[2];
                  var match$15 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                          if (typeof mainDiscrepancy === "object") {
                            return mainDiscrepancy.NAME === "PropertiesWithoutDescription";
                          } else {
                            return false;
                          }
                        }));
                  var branchList$7;
                  if (match$15 !== undefined && typeof match$15 === "object" && match$15.NAME === "PropertiesWithoutDescription") {
                    var mainDict$7 = Js_dict.fromList(Belt_List.mapU(match$15.VAL[2], (function (param) {
                                return [
                                        param.id,
                                        "Present"
                                      ];
                              })));
                    branchList$7 = Belt_List.keepU(branchL$7, (function (branchItem) {
                            var match = Js_dict.get(mainDict$7, branchItem.id);
                            return match === undefined;
                          }));
                  } else {
                    branchList$7 = branchL$7;
                  }
                  return {
                          NAME: "PropertiesWithoutDescription",
                          VAL: [
                            match$14[0],
                            match$14[1],
                            branchList$7
                          ]
                        };
                }
                var match$16 = branchDiscrepancy.VAL;
                var branchL$8 = match$16[2];
                var match$17 = Belt_Array.getByU(mainDiscrepancies, (function (mainDiscrepancy) {
                        if (typeof mainDiscrepancy === "object") {
                          return mainDiscrepancy.NAME === "PropertiesWithUnexpectedCase";
                        } else {
                          return false;
                        }
                      }));
                var branchList$8;
                if (match$17 !== undefined && typeof match$17 === "object" && match$17.NAME === "PropertiesWithUnexpectedCase") {
                  var mainDict$8 = Js_dict.fromList(Belt_List.mapU(match$17.VAL[2], (function (param) {
                              return [
                                      param.id,
                                      "Present"
                                    ];
                            })));
                  branchList$8 = Belt_List.keepU(branchL$8, (function (branchItem) {
                          var match = Js_dict.get(mainDict$8, branchItem.id);
                          return match === undefined;
                        }));
                } else {
                  branchList$8 = branchL$8;
                }
                return {
                        NAME: "PropertiesWithUnexpectedCase",
                        VAL: [
                          match$16[0],
                          match$16[1],
                          branchList$8
                        ]
                      };
              }));
}

function cmp(a, b) {
  var match = a.issue;
  if (typeof match !== "object") {
    return -1;
  }
  var variant = match.NAME;
  if (variant === "PropertiesWithUnexpectedCase") {
    var match$1 = b.issue;
    if (typeof match$1 === "object" && match$1.NAME === "PropertiesWithUnexpectedCase") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "SameEventName") {
    var match$2 = b.issue;
    if (typeof match$2 === "object" && match$2.NAME === "SameEventName") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "SamePropertyName") {
    var match$3 = b.issue;
    if (typeof match$3 === "object" && match$3.NAME === "SamePropertyName") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "PropertiesWithAnyType") {
    var match$4 = b.issue;
    if (typeof match$4 === "object" && match$4.NAME === "PropertiesWithAnyType") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "EventsWithoutDescription") {
    var match$5 = b.issue;
    if (typeof match$5 === "object" && match$5.NAME === "EventsWithoutDescription") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "EventsWithUnexpectedCase") {
    var match$6 = b.issue;
    if (typeof match$6 === "object" && match$6.NAME === "EventsWithUnexpectedCase") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant === "PropertyConflictOnEvent") {
    var match$7 = b.issue;
    if (typeof match$7 === "object" && match$7.NAME === "PropertyConflictOnEvent") {
      return 0;
    } else {
      return -1;
    }
  }
  if (variant !== "PropertiesWithoutDescription") {
    return -1;
  }
  var match$8 = b.issue;
  if (typeof match$8 === "object" && match$8.NAME === "PropertiesWithoutDescription") {
    return 0;
  } else {
    return -1;
  }
}

var IssueItemComparator = Belt_Id.MakeComparable({
      cmp: cmp
    });

function propertyNameAlreadyExists(existingPropertyNames, property) {
  var normalizedName = Case.to_("Camel", property.name);
  var match = Js_dict.get(existingPropertyNames, normalizedName);
  if (match !== undefined) {
    if (match && !match.tl) {
      return match.hd !== property.id;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function eventNameAlreadyExists(existingEventNames, $$event) {
  var normalizedName = Case.to_("Camel", $$event.name);
  var match = Js_dict.get(existingEventNames, normalizedName);
  if (match !== undefined) {
    if (match && !match.tl) {
      return match.hd !== $$event.id;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function getIssuesById(existingEventNames, expectedEventCase, existingPropertyNames, expectedPropertyCase, issueCategories) {
  var issuesById = {};
  Belt_Array.forEach(issueCategories, (function (issueCategory) {
          var variant = issueCategory.NAME;
          if (variant === "PropertyConflictOnEvent" || variant === "EventsWithoutDescription" || variant === "SameEventName") {
            var match = issueCategory.VAL;
            if (!match[0]) {
              return ;
            }
            var isEnforced = match[1];
            return Belt_Array.forEachU(Belt_List.toArray(match[2]), (function ($$event) {
                          var variant = issueCategory.NAME;
                          var newIssue_issue = variant === "EventsWithoutDescription" ? ({
                                NAME: "EventsWithoutDescription",
                                VAL: [
                                  true,
                                  isEnforced,
                                  /* [] */0
                                ]
                              }) : (
                              variant === "PropertyConflictOnEvent" ? ({
                                    NAME: "PropertyConflictOnEvent",
                                    VAL: [
                                      true,
                                      isEnforced,
                                      /* [] */0
                                    ]
                                  }) : ({
                                    NAME: "SameEventName",
                                    VAL: [
                                      true,
                                      isEnforced,
                                      /* [] */0
                                    ]
                                  })
                            );
                          var newIssue_name = $$event.name;
                          var newIssue_id = $$event.id;
                          var newIssue = {
                            issue: newIssue_issue,
                            name: newIssue_name,
                            id: newIssue_id,
                            type_: "Event",
                            suggestion: undefined,
                            suggestionCallback: undefined
                          };
                          var issues = Js_dict.get(issuesById, $$event.id);
                          if (issues !== undefined) {
                            issuesById[$$event.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                          } else {
                            issuesById[$$event.id] = [newIssue];
                          }
                        }));
          }
          if (variant === "PropertiesWithoutDescription" || variant === "PropertiesWithAnyType" || variant === "SamePropertyName") {
            var match$1 = issueCategory.VAL;
            if (!match$1[0]) {
              return ;
            }
            var isEnforced$1 = match$1[1];
            return Belt_Array.forEachU(Belt_List.toArray(match$1[2]), (function (property) {
                          var variant = issueCategory.NAME;
                          var newIssue_issue = variant === "PropertiesWithAnyType" ? ({
                                NAME: "PropertiesWithAnyType",
                                VAL: [
                                  true,
                                  isEnforced$1,
                                  /* [] */0
                                ]
                              }) : (
                              variant === "PropertiesWithoutDescription" ? ({
                                    NAME: "PropertiesWithoutDescription",
                                    VAL: [
                                      true,
                                      isEnforced$1,
                                      /* [] */0
                                    ]
                                  }) : ({
                                    NAME: "SamePropertyName",
                                    VAL: [
                                      true,
                                      isEnforced$1,
                                      /* [] */0
                                    ]
                                  })
                            );
                          var newIssue_name = property.name;
                          var newIssue_id = property.id;
                          var newIssue = {
                            issue: newIssue_issue,
                            name: newIssue_name,
                            id: newIssue_id,
                            type_: "Property",
                            suggestion: undefined,
                            suggestionCallback: undefined
                          };
                          var issues = Js_dict.get(issuesById, property.id);
                          if (issues !== undefined) {
                            issuesById[property.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                          } else {
                            issuesById[property.id] = [newIssue];
                          }
                        }));
          }
          if (variant === "EventsWithoutExpectedValues") {
            var match$2 = issueCategory.VAL;
            if (!match$2[0]) {
              return ;
            }
            var isEnforced$2 = match$2[1];
            return Belt_Array.forEachU(Belt_List.toArray(match$2[2]), (function (param) {
                          var unexpectedValues = param[1];
                          var $$event = param[0];
                          var missingComponents = Belt_Array.map(unexpectedValues.predefined, (function (c) {
                                  return "\"" + c + "\"";
                                }));
                          var blockedValuesUsed = Belt_Array.map(unexpectedValues.blocked, (function (c) {
                                  return "\"" + c + "\"";
                                }));
                          var valueDiscrepancies;
                          var exit = 0;
                          if (missingComponents.length !== 0 || blockedValuesUsed.length !== 0) {
                            exit = 1;
                          } else {
                            valueDiscrepancies = undefined;
                          }
                          if (exit === 1) {
                            valueDiscrepancies = blockedValuesUsed.length !== 0 ? (
                                missingComponents.length !== 0 ? "Event name does not comply with allowed words for " + StringExt.joinToSentence(missingComponents) + " and " + StringExt.joinToSentence(blockedValuesUsed) + " " + AvoUtils.plural("contain", false, blockedValuesUsed.length, "contains") + " blocked words" : "" + StringExt.joinToSentence(blockedValuesUsed) + " " + AvoUtils.plural("contain", false, blockedValuesUsed.length, "contains") + " blocked words"
                              ) : "Event name does not comply with allowed words for " + StringExt.joinToSentence(missingComponents) + "";
                          }
                          var missingSeparators = Belt_Array.keep(Belt_Option.getWithDefault(unexpectedValues.separators, []), (function (x) {
                                  return !x.present;
                                }));
                          var separatorDiscrepancies = missingSeparators.length !== 0 ? "missing separators: " + StringExt.joinToSentence(Belt_Array.map(missingSeparators, (function (x) {
                                        return "\"" + x.value + "\"";
                                      }))) + "." : undefined;
                          var newIssue_issue = {
                            NAME: "EventsWithoutExpectedValues",
                            VAL: [
                              true,
                              isEnforced$2,
                              /* [] */0
                            ]
                          };
                          var newIssue_name = $$event.name;
                          var newIssue_id = $$event.id;
                          var newIssue_suggestion = valueDiscrepancies !== undefined ? (
                              separatorDiscrepancies !== undefined ? "" + valueDiscrepancies + " and " + separatorDiscrepancies + "" : valueDiscrepancies
                            ) : (
                              separatorDiscrepancies !== undefined ? StringExt.capitalizeFirstLetter(separatorDiscrepancies) : undefined
                            );
                          var newIssue = {
                            issue: newIssue_issue,
                            name: newIssue_name,
                            id: newIssue_id,
                            type_: "Event",
                            suggestion: newIssue_suggestion,
                            suggestionCallback: undefined
                          };
                          var issues = Js_dict.get(issuesById, $$event.id);
                          if (issues !== undefined) {
                            issuesById[$$event.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                          } else {
                            issuesById[$$event.id] = [newIssue];
                          }
                        }));
          }
          if (variant === "EventsWithUnexpectedCase") {
            var match$3 = issueCategory.VAL;
            if (!match$3[0]) {
              return ;
            }
            var isEnforced$3 = match$3[1];
            return Belt_Array.forEachU(Belt_List.toArray(match$3[2]), (function ($$event) {
                          var mayUpdateNameAutomatically = !eventNameAlreadyExists(existingEventNames, $$event);
                          var newIssue_issue = {
                            NAME: "EventsWithUnexpectedCase",
                            VAL: [
                              true,
                              isEnforced$3,
                              /* [] */0
                            ]
                          };
                          var newIssue_name = $$event.name;
                          var newIssue_id = $$event.id;
                          var newIssue_suggestion = mayUpdateNameAutomatically ? "Change to \"" + Case.to_(expectedEventCase, $$event.name) + "\"" : "An event with a name in the expected casing already exists";
                          var newIssue_suggestionCallback = mayUpdateNameAutomatically ? (function (sendActions, addToast) {
                                var action_1 = [
                                  $$event.id,
                                  Case.to_(expectedEventCase, $$event.name)
                                ];
                                var action = {
                                  NAME: "UpdateEventName",
                                  VAL: action_1
                                };
                                var context = {
                                  eventId: $$event.id,
                                  eventQuery: $$event.id
                                };
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (_branch) {
                                          Curry._1(addToast, {
                                                message: "Event name changed to \"" + Case.to_(expectedEventCase, $$event.name) + "\"",
                                                toastType: /* Success */0,
                                                action: {
                                                  message: "Open",
                                                  toastCallback: (function (param) {
                                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                                            NAME: "event",
                                                            VAL: [
                                                              $$event.id,
                                                              undefined,
                                                              undefined,
                                                              false
                                                            ]
                                                          });
                                                    })
                                                }
                                              });
                                        }),
                                      (function (param) {
                                          Curry._1(addToast, {
                                                message: "Unable to change name",
                                                toastType: /* Error */1
                                              });
                                        }),
                                      [[
                                          action,
                                          context
                                        ]]
                                    ]);
                              }) : undefined;
                          var newIssue = {
                            issue: newIssue_issue,
                            name: newIssue_name,
                            id: newIssue_id,
                            type_: "Event",
                            suggestion: newIssue_suggestion,
                            suggestionCallback: newIssue_suggestionCallback
                          };
                          var issues = Js_dict.get(issuesById, $$event.id);
                          if (issues !== undefined) {
                            issuesById[$$event.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                          } else {
                            issuesById[$$event.id] = [newIssue];
                          }
                        }));
          }
          var match$4 = issueCategory.VAL;
          if (!match$4[0]) {
            return ;
          }
          var isEnforced$4 = match$4[1];
          Belt_Array.forEachU(Belt_List.toArray(match$4[2]), (function (property) {
                  var mayUpdateNameAutomatically = !propertyNameAlreadyExists(existingPropertyNames, property);
                  var newIssue_issue = {
                    NAME: "PropertiesWithUnexpectedCase",
                    VAL: [
                      true,
                      isEnforced$4,
                      /* [] */0
                    ]
                  };
                  var newIssue_name = property.name;
                  var newIssue_id = property.id;
                  var newIssue_suggestion = mayUpdateNameAutomatically ? "Change to \"" + Case.to_(expectedEventCase, property.name) + "\"" : "A property with a name in the expected casing already exists";
                  var newIssue_suggestionCallback = mayUpdateNameAutomatically ? (function (sendActions, addToast) {
                        var action_1 = [
                          property.id,
                          Case.to_(expectedPropertyCase, property.name)
                        ];
                        var action = {
                          NAME: "UpdatePropertyName",
                          VAL: action_1
                        };
                        var context = {
                          propertyId: property.id,
                          propertyQuery: property.id
                        };
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              (function (_branch) {
                                  Curry._1(addToast, {
                                        message: "Property name changed to \"" + Case.to_(expectedEventCase, property.name) + "\"",
                                        toastType: /* Success */0,
                                        action: {
                                          message: "Open",
                                          toastCallback: (function (param) {
                                              Router.Schema.pushDrawerItem(undefined, undefined, {
                                                    NAME: "property",
                                                    VAL: [
                                                      property.id,
                                                      undefined
                                                    ]
                                                  });
                                            })
                                        }
                                      });
                                }),
                              (function (param) {
                                  Curry._1(addToast, {
                                        message: "Unable to change name",
                                        toastType: /* Error */1
                                      });
                                }),
                              [[
                                  action,
                                  context
                                ]]
                            ]);
                      }) : undefined;
                  var newIssue = {
                    issue: newIssue_issue,
                    name: newIssue_name,
                    id: newIssue_id,
                    type_: "Property",
                    suggestion: newIssue_suggestion,
                    suggestionCallback: newIssue_suggestionCallback
                  };
                  var issues = Js_dict.get(issuesById, property.id);
                  if (issues !== undefined) {
                    issuesById[property.id] = Belt_Set.toArray(Belt_Set.add(Belt_Set.fromArray(issues, IssueItemComparator), newIssue));
                  } else {
                    issuesById[property.id] = [newIssue];
                  }
                }));
        }));
  return issuesById;
}

var initialValue_branchAuditChecks = [];

var initialValue_issuesById = {};

var initialValue = {
  branchAuditChecks: initialValue_branchAuditChecks,
  branchAuditWarningCount: 0,
  branchAuditErrorCount: 0,
  issuesById: initialValue_issuesById
};

var context = React.createContext(initialValue);

var provider = context.Provider;

function BranchDiscrepancies$Provider(Props) {
  var branchId = Props.branchId;
  var schemaId = Props.schemaId;
  var children = Props.children;
  var match = React.useContext(DiscrepancyContext.context);
  var eventComponentsWithNames = match.eventComponentsWithNames;
  var expectedPropertyCase = match.expectedPropertyCase;
  var expectedEventCase = match.expectedEventCase;
  var config = match.config;
  var match$1 = React.useState(function () {
        return BranchesDiscrepanciesWorker.empty;
      });
  var setDiscrepancies = match$1[1];
  var discrepancies = match$1[0];
  var diffInformation = ModelStore.useDiffInformation(undefined);
  var match$2 = React.useMemo((function () {
          if (diffInformation === undefined) {
            return [
                    undefined,
                    undefined,
                    undefined,
                    undefined
                  ];
          }
          var match = diffInformation.fromAndToModels;
          var fromModel = FlattenVariantsUseCase.flatten(match.from.model);
          var toModel = FlattenVariantsUseCase.flatten(match.to.model);
          return [
                  fromModel,
                  toModel,
                  Caml_option.some(Printer.printModel(fromModel)),
                  Caml_option.some(Printer.printModel(toModel))
                ];
        }), [diffInformation]);
  var branchModelToCompareToJson = match$2[3];
  var mainModelToCompareToJson = match$2[2];
  var branchModelToCompareTo = match$2[1];
  var mainModelToCompareTo = match$2[0];
  React.useEffect((function () {
          if (mainModelToCompareTo !== undefined && branchModelToCompareTo !== undefined && mainModelToCompareToJson !== undefined && branchModelToCompareToJson !== undefined) {
            $$Promise.$$catch(BranchesDiscrepanciesWorkerInstance.getBranchDiscrepancies(Belt_MapString.toArray(eventComponentsWithNames), ValidationConfigV2.encode(config), Caml_option.valFromOption(mainModelToCompareToJson), Caml_option.valFromOption(branchModelToCompareToJson)).then(function (discrepancies) {
                      Curry._1(setDiscrepancies, (function (param) {
                              return discrepancies;
                            }));
                    }), (function (e) {
                    console.error("Failed to resolve discrepancies from Worker", e);
                    Sentry.captureException(e, {
                          message: "Failed to resolve discrepancies from Worker",
                          schemaId: schemaId,
                          branchId: branchId,
                          config: ValidationConfigV2.encode(config)
                        });
                    Curry._1(setDiscrepancies, (function (param) {
                            return DiscrepancyUtils.getBranchDiscrepancies_forWorker(Belt_MapString.toArray(eventComponentsWithNames), config, mainModelToCompareTo, branchModelToCompareTo);
                          }));
                    return Promise.resolve(undefined);
                  }));
          }
          
        }), [
        eventComponentsWithNames,
        config,
        diffInformation
      ]);
  var issuesById = React.useMemo((function () {
          return getIssuesById(discrepancies.existingEventNames, expectedEventCase, discrepancies.existingPropertyNames, expectedPropertyCase, discrepancies.branchAuditChecks);
        }), [discrepancies.branchAuditChecks]);
  return React.createElement(provider, {
              value: {
                branchAuditChecks: discrepancies.branchAuditChecks,
                branchAuditWarningCount: discrepancies.branchAuditChecksCount,
                branchAuditErrorCount: discrepancies.branchAuditErrorCount,
                issuesById: issuesById
              },
              children: children
            });
}

var Provider = {
  initialValue: initialValue,
  context: context,
  provider: provider,
  make: BranchDiscrepancies$Provider
};

function use(param) {
  return React.useContext(context);
}

export {
  getDiscrepanciesDiff ,
  IssueItemComparator ,
  propertyNameAlreadyExists ,
  eventNameAlreadyExists ,
  getIssuesById ,
  Provider ,
  use ,
}
/* IssueItemComparator Not a pure module */
