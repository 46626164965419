// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SheetInfoRow from "./SheetInfoRow.mjs";
import * as CreateButtonIcon from "./emptystates/CreateButtonIcon.mjs";
import * as EmptyStatesStyles from "./emptystates/EmptyStatesStyles.mjs";

function PropertiesSheetCreateGroupType(Props) {
  var columns = Props.columns;
  var globalSend = Props.globalSend;
  var totalColumnWidth = Belt_Array.reduceU(Belt_Array.mapU(Belt_Array.keepU(columns, (function (param) {
                  return param.visible;
                })), (function (param) {
              return param.width;
            })), 0, (function (acc, width) {
          return acc + width | 0;
        }));
  return React.createElement(SheetInfoRow.make, {
              onClick: (function (param) {
                  Curry._1(globalSend, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "NewGroupType",
                          VAL: [
                            undefined,
                            undefined
                          ]
                        }
                      });
                }),
              totalColumnWidth: totalColumnWidth,
              children: null
            }, React.createElement("i", {
                  className: Curry._1(Css.merge, {
                        hd: EmptyStatesStyles.iconStyles,
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.width(Css.px(32)),
                                tl: {
                                  hd: Css.height(Css.px(32)),
                                  tl: {
                                    hd: Css.margin(Css.px(0)),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, CreateButtonIcon.icon), React.createElement(Spacer.make, {
                  width: 8
                }), React.createElement($$Text.make, {
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Create a new Group Type"
                }));
}

var make = PropertiesSheetCreateGroupType;

export {
  make ,
}
/* Css Not a pure module */
