// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function rawTypeToString(rawType) {
  if (typeof rawType !== "number") {
    return "object";
  }
  switch (rawType) {
    case /* String */0 :
        return "string";
    case /* Int */1 :
        return "int";
    case /* Float */2 :
        return "float";
    case /* Bool */3 :
        return "bool";
    case /* Number */4 :
        return "number";
    case /* Any */5 :
    case /* Unknown */6 :
        return "any";
    
  }
}

function toString(t) {
  var rawTypeStr = rawTypeToString(t.rawType);
  if (t.isList) {
    return "list(" + rawTypeStr + ")";
  } else {
    return rawTypeStr;
  }
}

function toStringEnrichedWithTrackingPlan(t, trackingPlanType) {
  var match = t.rawType;
  var rawTypeStr = typeof match === "number" && match >= 6 ? (
      trackingPlanType !== undefined ? trackingPlanType : "any"
    ) : rawTypeToString(match);
  if (t.isList) {
    return "list(" + rawTypeStr + ")";
  } else {
    return rawTypeStr;
  }
}

function toSimpleTypeString(t) {
  if (t !== undefined) {
    return toString(t);
  } else {
    return "null";
  }
}

var PropertyType = {
  rawTypeToString: rawTypeToString,
  toString: toString,
  toStringEnrichedWithTrackingPlan: toStringEnrichedWithTrackingPlan,
  toSimpleTypeString: toSimpleTypeString
};

function jsonToDict(json, depth) {
  var dict = Js_json.classify(json);
  if (typeof dict === "number") {
    return {};
  }
  if (dict.TAG !== /* JSONObject */2) {
    return {};
  }
  var dictToReturn = {};
  Belt_Array.forEachU(Js_dict.entries(dict._0), (function (param) {
          var value = param[1];
          var str = Js_json.classify(value);
          var parsedProperty;
          if (typeof str === "number") {
            parsedProperty = undefined;
          } else {
            switch (str.TAG | 0) {
              case /* JSONString */0 :
                  parsedProperty = consolidatePropertyType(str._0);
                  break;
              case /* JSONObject */2 :
                  if (depth < 2) {
                    var nestedDict = jsonToDict(value, depth + 1 | 0);
                    parsedProperty = {
                      isList: false,
                      rawType: /* Object */{
                        _0: nestedDict
                      }
                    };
                  } else {
                    parsedProperty = {
                      isList: false,
                      rawType: /* Object */{
                        _0: {}
                      }
                    };
                  }
                  break;
              case /* JSONArray */3 :
                  var firstElement = Belt_Array.get(str._0, 0);
                  if (firstElement !== undefined) {
                    var firstElement$1 = Caml_option.valFromOption(firstElement);
                    var match = Js_json.classify(firstElement$1);
                    var exit = 0;
                    if (typeof match === "number" || match.TAG !== /* JSONObject */2) {
                      exit = 1;
                    } else {
                      var nestedDict$1 = jsonToDict(firstElement$1, depth);
                      parsedProperty = {
                        isList: true,
                        rawType: /* Object */{
                          _0: nestedDict$1
                        }
                      };
                    }
                    if (exit === 1) {
                      var propType = consolidatePropertyType(JSON.stringify(firstElement$1));
                      parsedProperty = propType !== undefined ? ({
                            isList: true,
                            rawType: propType.rawType
                          }) : ({
                            isList: true,
                            rawType: /* Unknown */6
                          });
                    }
                    
                  } else {
                    parsedProperty = undefined;
                  }
                  break;
              default:
                parsedProperty = undefined;
            }
          }
          if (parsedProperty !== undefined) {
            dictToReturn[param[0]] = parsedProperty;
            return ;
          }
          
        }));
  return dictToReturn;
}

function consolidatePropertyType(_propertyType) {
  while(true) {
    var propertyType = _propertyType;
    var propertyType$1 = propertyType.toLowerCase();
    switch (propertyType$1) {
      case "any" :
          return {
                  isList: false,
                  rawType: /* Any */5
                };
      case "bool" :
      case "boolean" :
          return {
                  isList: false,
                  rawType: /* Bool */3
                };
      case "float" :
          return {
                  isList: false,
                  rawType: /* Float */2
                };
      case "int" :
      case "integer" :
          return {
                  isList: false,
                  rawType: /* Int */1
                };
      case "list" :
          return {
                  isList: true,
                  rawType: /* Unknown */6
                };
      case "list(object)" :
          return {
                  isList: true,
                  rawType: /* Object */{
                    _0: {}
                  }
                };
      case "number" :
          return {
                  isList: false,
                  rawType: /* Number */4
                };
      case "object" :
          return {
                  isList: false,
                  rawType: /* Object */{
                    _0: {}
                  }
                };
      case "string" :
          return {
                  isList: false,
                  rawType: /* String */0
                };
      default:
        if (Js_string.startsWith(propertyType$1, "list(\")")) {
          try {
            var innerString = propertyType$1.slice(6, -2);
            var parsedJson = JSON.parse(innerString);
            var str = Js_json.classify(parsedJson);
            if (typeof str === "number") {
              return {
                      isList: true,
                      rawType: /* Unknown */6
                    };
            }
            if (str.TAG !== /* JSONString */0) {
              return {
                      isList: true,
                      rawType: /* Unknown */6
                    };
            }
            var propType = consolidatePropertyType(str._0);
            if (propType !== undefined) {
              return {
                      isList: true,
                      rawType: propType.rawType
                    };
            } else {
              return {
                      isList: true,
                      rawType: /* Unknown */6
                    };
            }
          }
          catch (exn){
            console.error("Error Parsing List of stringified json", {
                  propertyType: propertyType$1
                });
            return {
                    isList: true,
                    rawType: /* Unknown */6
                  };
          }
        } else if (propertyType$1.startsWith("{")) {
          try {
            var parsedJson$1 = JSON.parse(propertyType$1);
            var parsedDict = jsonToDict(parsedJson$1, 1);
            return {
                    isList: false,
                    rawType: /* Object */{
                      _0: parsedDict
                    }
                  };
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            console.error("Error Parsing Object", {
                  propertyType: propertyType$1,
                  error: err
                });
            return {
                    isList: false,
                    rawType: /* Any */5
                  };
          }
        } else if (propertyType$1.startsWith("list({")) {
          try {
            var innerObject = propertyType$1.slice(5, -1);
            var objectStrings = innerObject.split("|");
            var firstObjectStr = Belt_Array.get(objectStrings, 0);
            if (firstObjectStr === undefined) {
              return {
                      isList: true,
                      rawType: /* Object */{
                        _0: {}
                      }
                    };
            }
            var parsedJson$2 = JSON.parse(firstObjectStr);
            var parsedDict$1 = jsonToDict(parsedJson$2, 1);
            return {
                    isList: true,
                    rawType: /* Object */{
                      _0: parsedDict$1
                    }
                  };
          }
          catch (raw_err$1){
            var err$1 = Caml_js_exceptions.internalToOCamlException(raw_err$1);
            console.error("Error Parsing List of Objects", {
                  propertyType: propertyType$1,
                  error: err$1
                });
            return {
                    isList: true,
                    rawType: /* Unknown */6
                  };
          }
        } else if (propertyType$1.startsWith("[{")) {
          try {
            var innerObject$1 = propertyType$1.slice(2, -1);
            var objectStrings$1 = innerObject$1.split("|");
            var firstObjectStr$1 = Belt_Array.get(objectStrings$1, 0);
            if (firstObjectStr$1 === undefined) {
              return {
                      isList: true,
                      rawType: /* Object */{
                        _0: {}
                      }
                    };
            }
            var parsedJson$3 = JSON.parse(firstObjectStr$1);
            var parsedDict$2 = jsonToDict(parsedJson$3, 1);
            return {
                    isList: true,
                    rawType: /* Object */{
                      _0: parsedDict$2
                    }
                  };
          }
          catch (raw_err$2){
            var err$2 = Caml_js_exceptions.internalToOCamlException(raw_err$2);
            console.error("Error Parsing List of Objects", {
                  propertyType: propertyType$1,
                  error: err$2
                });
            return {
                    isList: true,
                    rawType: /* Any */5
                  };
          }
        } else if (propertyType$1.startsWith("list")) {
          var listType = propertyType$1.slice(5, -1);
          var innerType;
          try {
            innerType = Caml_option.some(JSON.parse(listType));
          }
          catch (exn$1){
            innerType = undefined;
          }
          if (innerType !== undefined) {
            var json = Caml_option.valFromOption(innerType);
            var str$1 = Js_json.classify(json);
            if (typeof str$1 === "number") {
              console.log("Failed to parse inner type in list, defaulting to Unknown", innerType);
              return {
                      isList: true,
                      rawType: /* Unknown */6
                    };
            }
            switch (str$1.TAG | 0) {
              case /* JSONString */0 :
                  var type_ = consolidatePropertyType(str$1._0);
                  if (type_ !== undefined) {
                    return {
                            isList: true,
                            rawType: type_.rawType
                          };
                  } else {
                    return {
                            isList: true,
                            rawType: /* Unknown */6
                          };
                  }
              case /* JSONObject */2 :
                  return {
                          isList: true,
                          rawType: /* Object */{
                            _0: jsonToDict(json, 1)
                          }
                        };
              default:
                console.log("Failed to parse inner type in list, defaulting to Unknown", innerType);
                return {
                        isList: true,
                        rawType: /* Unknown */6
                      };
            }
          } else {
            var innerType$1 = consolidatePropertyType(listType);
            if (innerType$1 !== undefined) {
              return {
                      isList: true,
                      rawType: innerType$1.rawType
                    };
            } else {
              console.error("Failed to both JS parse and consolidate inner type, defaulting to unknown", listType);
              return {
                      isList: true,
                      rawType: /* Unknown */6
                    };
            }
          }
        } else {
          switch (propertyType$1) {
            case "none" :
            case "null" :
            case "undefined" :
                return ;
            case "" :
            case "unknown" :
                break;
            default:
              if (propertyType$1.startsWith("\"")) {
                _propertyType = propertyType$1.slice(1, -1);
                continue ;
              }
              console.error("Unkown Property Type, Defaulting to any", {
                    propertyType: propertyType$1
                  });
              return {
                      isList: false,
                      rawType: /* Any */5
                    };
          }
        }
    }
    console.error("Empty string or Unknown propertyType, defaulting to Unknown", {
          propertyType: propertyType
        });
    return {
            isList: false,
            rawType: /* Unknown */6
          };
  };
}

function comparePropertyTypes(propertyTypeA, propertyTypeB) {
  var consolidatedPropertyTypeA = consolidatePropertyType(propertyTypeA);
  var consolidatedPropertyTypeB = consolidatePropertyType(propertyTypeB);
  if (consolidatedPropertyTypeA === undefined) {
    return true;
  }
  if (consolidatedPropertyTypeB === undefined) {
    return true;
  }
  if (consolidatedPropertyTypeA.isList !== consolidatedPropertyTypeB.isList) {
    return false;
  }
  var match = consolidatedPropertyTypeA.rawType;
  var match$1 = consolidatedPropertyTypeB.rawType;
  if (typeof match === "number") {
    if (match >= 6) {
      return true;
    }
    
  } else {
    if (typeof match$1 !== "number") {
      return true;
    }
    if (match$1 < 6) {
      return match === match$1;
    }
    
  }
  if (typeof match$1 === "number" && match$1 >= 6) {
    return true;
  } else {
    return match === match$1;
  }
}

var V2 = {
  PropertyType: PropertyType,
  jsonToDict: jsonToDict,
  consolidatePropertyType: consolidatePropertyType,
  comparePropertyTypes: comparePropertyTypes
};

function encode(propertyType) {
  switch (propertyType) {
    case /* String */0 :
        return "string";
    case /* Int */1 :
        return "int";
    case /* Float */2 :
        return "float";
    case /* Bool */3 :
        return "bool";
    case /* Number */4 :
        return "number";
    case /* Object */5 :
        return "object";
    case /* Any */6 :
        return "any";
    
  }
}

function decode(json) {
  var match = Json_decode.string(json);
  switch (match) {
    case "any" :
        return /* Any */6;
    case "bool" :
        return /* Bool */3;
    case "float" :
        return /* Float */2;
    case "int" :
        return /* Int */1;
    case "number" :
        return /* Number */4;
    case "object" :
        return /* Object */5;
    case "string" :
        return /* String */0;
    default:
      return Pervasives.failwith("Unknown Property Type");
  }
}

function toPropertyType(str) {
  switch (str) {
    case "any" :
        return /* Any */6;
    case "bool" :
        return /* Bool */3;
    case "float" :
        return /* Float */2;
    case "int" :
        return /* Int */1;
    case "number" :
        return /* Number */4;
    case "object" :
        return /* Object */5;
    case "string" :
        return /* String */0;
    default:
      return ;
  }
}

function toString$1(t) {
  switch (t) {
    case /* String */0 :
        return "string";
    case /* Int */1 :
        return "int";
    case /* Float */2 :
        return "float";
    case /* Bool */3 :
        return "bool";
    case /* Number */4 :
        return "number";
    case /* Object */5 :
        return "object";
    case /* Any */6 :
        return "any";
    
  }
}

function consolidatePropertyType$1(propertyType) {
  var propertyType$1 = propertyType.toLowerCase();
  switch (propertyType$1) {
    case "any" :
        return "any";
    case "bool" :
    case "boolean" :
        return "bool";
    case "float" :
        return "float";
    case "int" :
    case "integer" :
        return "int";
    case "list(bool)" :
    case "list(boolean)" :
    case "list<bool>" :
    case "list<boolean>" :
        return "list(bool)";
    case "list(float)" :
    case "list<float>" :
        return "list(float)";
    case "list(int)" :
    case "list(integer)" :
    case "list<int>" :
    case "list<integer>" :
        return "list(int)";
    case "list(number)" :
    case "list<number>" :
        return "list(number)";
    case "list(object)" :
    case "list<object>" :
        return "list(object)";
    case "list(string)" :
    case "list<string>" :
        return "list(string)";
    case "number" :
        return "number";
    case "object" :
        return "object";
    case "string" :
        return "string";
    case "none" :
    case "null" :
    case "undefined" :
        return ;
    default:
      if (propertyType$1.startsWith("list({")) {
        return "list(object)";
      }
      if (propertyType$1.startsWith("{")) {
        return "object";
      }
      if (propertyType$1.startsWith("list<{")) {
        return "list(object)";
      }
      if (propertyType$1.startsWith("list(unknown)")) {
        return "list(object)";
      }
      switch (propertyType$1) {
        case "list(any)" :
            return "list(any)";
        case "list" :
        case "list<>" :
            return "list";
        case "unknown" :
            return "any";
        default:
          console.error("Unkown Property Type, Defaulting to any", {
                propertyType: propertyType$1
              });
          return "any";
      }
  }
}

function isList(propertyType) {
  try {
    var type_ = consolidatePropertyType$1(propertyType);
    if (type_ !== undefined) {
      return type_.startsWith("list");
    } else {
      return false;
    }
  }
  catch (_error){
    return false;
  }
}

function extractRawTypeFromList(propertyType) {
  var match = propertyType.split("(");
  if (match.length !== 2) {
    return ;
  }
  var rawType = match[1];
  var match$1 = rawType.split(")");
  if (match$1.length !== 2) {
    return ;
  } else {
    return match$1[0];
  }
}

function enrichListPropertyTypeWithTrackingPlan(propertyType, trackingPlanPropertyType) {
  if (propertyType === "list") {
    if (trackingPlanPropertyType !== undefined) {
      return "list(" + trackingPlanPropertyType + ")";
    } else {
      return "list(any)";
    }
  } else {
    return propertyType;
  }
}

function getRawType(propertyType, trackingPlanPropertyType) {
  var type_ = consolidatePropertyType$1(propertyType);
  if (type_ === undefined) {
    return ;
  }
  var type_$1 = enrichListPropertyTypeWithTrackingPlan(type_, trackingPlanPropertyType);
  if (!type_$1.startsWith("list(")) {
    return type_$1;
  }
  var match = type_$1.split("(");
  if (match.length !== 2) {
    return ;
  }
  var rawType = match[1];
  var match$1 = rawType.split(")");
  if (match$1.length !== 2) {
    return ;
  } else {
    return match$1[0];
  }
}

function fromConsolidatedString(str) {
  if (isList(str)) {
    return Belt_Option.flatMap(extractRawTypeFromList(str), toPropertyType);
  } else {
    return toPropertyType(str);
  }
}

function fromString(str) {
  try {
    return Belt_Option.flatMap(consolidatePropertyType$1(str), fromConsolidatedString);
  }
  catch (_error){
    return ;
  }
}

function validatePropertyType(propertyType) {
  try {
    consolidatePropertyType$1(propertyType);
    return true;
  }
  catch (_error){
    return false;
  }
}

function comparePropertyTypes$1(propertyTypeA, propertyTypeB) {
  var consolidatedPropertyTypeA = consolidatePropertyType$1(propertyTypeA);
  var consolidatedPropertyTypeB = consolidatePropertyType$1(propertyTypeB);
  if (consolidatedPropertyTypeA !== undefined && !(consolidatedPropertyTypeA === "null" || !(consolidatedPropertyTypeB !== undefined && consolidatedPropertyTypeB !== "null"))) {
    return consolidatedPropertyTypeA === consolidatedPropertyTypeB;
  } else {
    return true;
  }
}

function addListToRawType(rawType) {
  return "list(" + rawType + ")";
}

var maximumDepthForObjects = 2;

export {
  maximumDepthForObjects ,
  V2 ,
  encode ,
  decode ,
  toPropertyType ,
  toString$1 as toString,
  consolidatePropertyType$1 as consolidatePropertyType,
  isList ,
  extractRawTypeFromList ,
  enrichListPropertyTypeWithTrackingPlan ,
  getRawType ,
  fromConsolidatedString ,
  fromString ,
  validatePropertyType ,
  comparePropertyTypes$1 as comparePropertyTypes,
  addListToRawType ,
}
/* No side effect */
