// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Issues from "../models/Issues.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns from "date-fns";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as AppVersionsTypes from "../models/AppVersionsTypes.mjs";
import * as InspectorIssuesFilter from "../models/InspectorIssuesFilter.mjs";
import * as AppVersionComparisonUtils from "../../api/functions/src/AppVersionComparisonUtils.mjs";

function groupFilters(filters) {
  return Belt_Array.reduce(filters, [], (function (groupedFilters, currentFilter) {
                var filterType = InspectorIssuesFilter.Filter.toFilterType(currentFilter);
                var groupedFilter = InspectorIssuesFilter.GroupedFilter.fromFilter(currentFilter);
                var groupedFilterIndex = Belt_Array.getIndexBy(groupedFilters, (function (groupedFilter) {
                        return InspectorIssuesFilter.GroupedFilter.toFilterType(groupedFilter) === filterType;
                      }));
                if (groupedFilterIndex === undefined) {
                  return Belt_Array.concat(groupedFilters, [groupedFilter]);
                }
                var groupedFilter$1 = Belt_Array.get(groupedFilters, groupedFilterIndex);
                var maybeNewGroupedFilter;
                if (groupedFilter$1 !== undefined) {
                  var variant = groupedFilter$1.NAME;
                  maybeNewGroupedFilter = variant === "propertyNames" ? (
                      typeof currentFilter === "object" && currentFilter.NAME === "propertyName" ? ({
                            NAME: "propertyNames",
                            VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                          }) : groupedFilter$1
                    ) : (
                      variant === "issueTypes" ? (
                          typeof currentFilter === "object" && currentFilter.NAME === "issueType" ? ({
                                NAME: "issueTypes",
                                VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                              }) : groupedFilter$1
                        ) : (
                          variant === "releases" ? (
                              typeof currentFilter === "object" && currentFilter.NAME === "release" ? ({
                                    NAME: "releases",
                                    VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                                  }) : groupedFilter$1
                            ) : (
                              variant === "categories" ? (
                                  typeof currentFilter === "object" && currentFilter.NAME === "category" ? ({
                                        NAME: "categories",
                                        VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                                      }) : groupedFilter$1
                                ) : (
                                  variant === "eventNames" ? (
                                      typeof currentFilter === "object" && currentFilter.NAME === "eventName" ? ({
                                            NAME: "eventNames",
                                            VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                                          }) : groupedFilter$1
                                    ) : (
                                      variant === "sources" ? (
                                          typeof currentFilter === "object" && currentFilter.NAME === "source" ? ({
                                                NAME: "sources",
                                                VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                                              }) : groupedFilter$1
                                        ) : (
                                          typeof currentFilter === "object" && currentFilter.NAME === "tag" ? ({
                                                NAME: "tags",
                                                VAL: Belt_Array.concat(groupedFilter$1.VAL, [currentFilter.VAL])
                                              }) : groupedFilter$1
                                        )
                                    )
                                )
                            )
                        )
                    );
                } else {
                  maybeNewGroupedFilter = groupedFilter$1;
                }
                return Belt_Option.mapWithDefault(maybeNewGroupedFilter, groupedFilters, (function (newGroupedFilter) {
                              var copy = groupedFilters.slice(0);
                              Belt_Array.set(copy, groupedFilterIndex, newGroupedFilter);
                              return copy;
                            }));
              }));
}

function getScoreThreshold(query) {
  var match = query.trim().length;
  if (match !== 0) {
    if (match !== 1) {
      return 0.07;
    } else {
      return 0.05;
    }
  } else {
    return 0.0;
  }
}

function keepIssueModelByGroupedFilter(latestAppVersionsBySource, issue, groupedFilter) {
  var variant = groupedFilter.NAME;
  if (variant === "propertyNames") {
    var propertyName = issue.propertyName;
    if (propertyName !== undefined) {
      return Belt_SetString.has(Belt_SetString.fromArray(groupedFilter.VAL), propertyName);
    } else {
      return false;
    }
  }
  if (variant === "issueTypes") {
    return Belt_SetString.has(Belt_SetString.fromArray(Belt_Array.map(groupedFilter.VAL, InspectorIssuesFilter.FilterIssueType.toString)), InspectorIssuesFilter.FilterIssueType.toString(InspectorIssuesFilter.FilterIssueType.fromNewIssue(issue)));
  }
  if (variant !== "releases") {
    if (variant === "categories") {
      return Belt_SetString.size(Belt_SetString.intersect(Belt_SetString.fromArray(groupedFilter.VAL), Belt_SetString.fromArray(issue.categories))) > 0;
    } else if (variant === "eventNames") {
      return Belt_SetString.has(Belt_SetString.fromArray(groupedFilter.VAL), issue.eventName);
    } else if (variant === "sources") {
      return Belt_SetString.has(Belt_SetString.fromArray(groupedFilter.VAL), issue.source.id);
    } else {
      return Belt_SetString.size(Belt_SetString.intersect(Belt_SetString.fromArray(groupedFilter.VAL), Belt_SetString.fromArray(issue.tags))) > 0;
    }
  }
  var releases = groupedFilter.VAL;
  if (releases.length === 0) {
    return true;
  }
  var issueVersions = Belt_SetString.fromArray(Belt_Array.map(issue.source.impactedVersions, (function (param) {
              return param.release;
            })));
  var latestVersions = Belt_Option.getWithDefault(Belt_MapString.get(latestAppVersionsBySource, issue.source.id), []);
  var match = Belt_Array.get(releases, 0);
  if (match !== undefined) {
    if (match === "threeLatestReleases") {
      return Belt_Array.some(Belt_Array.slice(latestVersions, 0, 3), (function (version) {
                    return Belt_SetString.has(issueVersions, version);
                  }));
    } else if (match === "twoLatestReleases") {
      return Belt_Array.some(Belt_Array.slice(latestVersions, 0, 2), (function (version) {
                    return Belt_SetString.has(issueVersions, version);
                  }));
    } else {
      return Belt_Option.mapWithDefault(Belt_Array.get(latestVersions, 0), false, (function (version) {
                    return Belt_SetString.has(issueVersions, version);
                  }));
    }
  } else {
    return true;
  }
}

function keepIssueModel(groupedFilters, latestAppVersionsBySource, issue) {
  return Belt_Array.every(groupedFilters, (function (param) {
                return keepIssueModelByGroupedFilter(latestAppVersionsBySource, issue, param);
              }));
}

function recalculateNumbersForReleaseFilters(appVersions, releaseFilter, issue) {
  if (releaseFilter === undefined) {
    return issue;
  }
  var latestReleasesByReleaseFilter = Belt_SetString.fromArray(Belt_Array.mapU(AppVersionsTypes.getLatestVersions(releaseFilter === "threeLatestReleases" ? 3 : (
                  releaseFilter === "twoLatestReleases" ? 2 : 1
                ), appVersions, issue.source.id), AppVersionsTypes.print));
  var versionsToCalculateFrom = Belt_Array.keepU(issue.source.versions, (function (version) {
          return Belt_SetString.has(latestReleasesByReleaseFilter, version.release);
        }));
  var lastSeens = Belt_Array.keepMapU(versionsToCalculateFrom, (function (param) {
          if (param.issueCount > 0) {
            return param.lastSeen;
          }
          
        }));
  var eventCounts = Belt_Array.mapU(versionsToCalculateFrom, (function (param) {
          return param.eventCount;
        }));
  var issueCounts = Belt_Array.mapU(versionsToCalculateFrom, (function (param) {
          return param.issueCount;
        }));
  return {
          id: issue.id,
          sharedId: issue.sharedId,
          issueType: issue.issueType,
          issueStatus: issue.issueStatus,
          eventName: issue.eventName,
          propertyName: issue.propertyName,
          source: issue.source,
          categories: issue.categories,
          tags: issue.tags,
          firstSeen: issue.firstSeen,
          lastSeen: lastSeens.length !== 0 ? DateFns.max(lastSeens) : issue.lastSeen,
          eventCount: eventCounts.length !== 0 ? Belt_Array.reduceU(eventCounts, 0, (function (sum, eventCount) {
                    return sum + eventCount | 0;
                  })) : 0,
          issueCount: issueCounts.length !== 0 ? Belt_Array.reduceU(issueCounts, 0, (function (sum, issueCount) {
                    return sum + issueCount | 0;
                  })) : 0,
          newestAppVersion: issue.newestAppVersion,
          oldestAppVersion: issue.oldestAppVersion,
          regression: issue.regression
        };
}

function filterIssueViewModels(ignoringOpt, appVersions, issues, filters) {
  var ignoring = ignoringOpt !== undefined ? ignoringOpt : [];
  var groupedFilters;
  if (ignoring.length !== 0) {
    var ignoreSet = Belt_Set.fromArray(ignoring, InspectorIssuesFilter.FilterType.Comparator);
    groupedFilters = Belt_Array.keepU(groupFilters(filters), (function (groupFilter) {
            var variant = groupFilter.NAME;
            if (variant === "propertyNames") {
              return !Belt_Set.has(ignoreSet, "propertyName");
            } else if (variant === "issueTypes") {
              return !Belt_Set.has(ignoreSet, "issueType");
            } else if (variant === "releases") {
              return !Belt_Set.has(ignoreSet, "release");
            } else if (variant === "categories") {
              return !Belt_Set.has(ignoreSet, "category");
            } else if (variant === "eventNames") {
              return !Belt_Set.has(ignoreSet, "eventName");
            } else if (variant === "sources") {
              return !Belt_Set.has(ignoreSet, "source");
            } else {
              return !Belt_Set.has(ignoreSet, "tag");
            }
          }));
  } else {
    groupedFilters = groupFilters(filters);
  }
  var releaseFilter = Belt_Option.flatMapU(Belt_Array.getByU(groupedFilters, (function (groupedFilter) {
              if (typeof groupedFilter === "object") {
                return groupedFilter.NAME === "releases";
              } else {
                return false;
              }
            })), (function (groupedFilter) {
          if (typeof groupedFilter === "object" && groupedFilter.NAME === "releases") {
            return Belt_Array.get(groupedFilter.VAL, 0);
          }
          
        }));
  var latestAppVersionsBySource = Belt_MapString.map(appVersions, (function (sourceAppVersions) {
          return Belt_Array.mapU(Belt_Array.slice(Belt_SortArray.stableSortByU(sourceAppVersions, (function (sourceA, sourceB) {
                                var match = AppVersionComparisonUtils.compareToAppVersion(AppVersionsTypes.print(sourceA.appVersion), AppVersionsTypes.print(sourceB.appVersion));
                                return match - 1 | 0;
                              })), 0, 3), (function (source) {
                        return AppVersionsTypes.print(source.appVersion);
                      }));
        }));
  return Curry._1(Issues.fromArray, Curry._2(Issues.keepMap, issues, (function (issue) {
                    if (keepIssueModel(groupedFilters, latestAppVersionsBySource, issue)) {
                      return recalculateNumbersForReleaseFilters(appVersions, releaseFilter, issue);
                    }
                    
                  })));
}

function removeFilters(filters, filterType) {
  return Belt_Array.keep(filters, (function (filter) {
                return InspectorIssuesFilter.Filter.toFilterType(filter) !== filterType;
              }));
}

function toggleFilters(filters, filter) {
  if (typeof filter === "object") {
    var variant = filter.NAME;
    if (variant === "propertyName" || variant === "eventName") {
      var match = Belt_Array.getBy(filters, (function (existingFilter) {
              return InspectorIssuesFilter.Filter.cmpType(existingFilter, filter);
            }));
      if (match !== undefined) {
        return Belt_Array.keep(filters, (function (existingFilter) {
                      return !InspectorIssuesFilter.Filter.cmpType(existingFilter, filter);
                    }));
      } else {
        return Belt_Array.concat(filters, [filter]);
      }
    }
    
  }
  var match$1 = Belt_Array.getBy(filters, (function (existingFilter) {
          return Caml_obj.equal(existingFilter, filter);
        }));
  if (match$1 !== undefined) {
    return Belt_Array.keep(filters, (function (existingFilter) {
                  return Caml_obj.compare(existingFilter, filter) !== 0;
                }));
  } else {
    return Belt_Array.concat(filters, [filter]);
  }
}

function getMissingIssueFilterTypes(currentIssueTypes) {
  return Belt_Array.map(Belt_Array.keep(InspectorIssuesFilter.FilterIssueType.getFilterTypes(undefined).new, (function (availableIssueType) {
                    return Belt_Array.some(currentIssueTypes, (function (param) {
                                  return Caml_obj.equal(param[0], {
                                              NAME: "new",
                                              VAL: availableIssueType
                                            });
                                })) === false;
                  })), (function (issueTypeMissingFromFilter) {
                return [
                        {
                          NAME: "new",
                          VAL: issueTypeMissingFromFilter
                        },
                        0
                      ];
              }));
}

function MakeFilterCounter(C) {
  var getAvailableFilterMaps = function (param) {
    return Belt_Map.fromArray(Belt_Array.keepMap(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (filterType) {
                      if (filterType === "eventName") {
                        return [
                                "eventName",
                                {
                                  NAME: "eventNames",
                                  VAL: []
                                }
                              ];
                      } else if (filterType === "tag") {
                        return [
                                "tag",
                                {
                                  NAME: "tags",
                                  VAL: []
                                }
                              ];
                      } else if (filterType === "issueType") {
                        return [
                                "issueType",
                                {
                                  NAME: "issueTypes",
                                  VAL: []
                                }
                              ];
                      } else if (filterType === "propertyName") {
                        return [
                                "propertyName",
                                {
                                  NAME: "propertyNames",
                                  VAL: []
                                }
                              ];
                      } else if (filterType === "category") {
                        return [
                                "category",
                                {
                                  NAME: "categories",
                                  VAL: []
                                }
                              ];
                      } else if (filterType === "source") {
                        return [
                                "source",
                                {
                                  NAME: "sources",
                                  VAL: []
                                }
                              ];
                      } else {
                        return [
                                "release",
                                {
                                  NAME: "releases",
                                  VAL: [
                                    [
                                      "latestRelease",
                                      0
                                    ],
                                    [
                                      "twoLatestReleases",
                                      0
                                    ],
                                    [
                                      "threeLatestReleases",
                                      0
                                    ]
                                  ]
                                }
                              ];
                      }
                    })), InspectorIssuesFilter.FilterType.Comparator);
  };
  var addVersionsToFilterCount = function (sourceVersionMap, source, version) {
    var versionMap = Js_dict.get(sourceVersionMap, Curry._1(C.getSourceId, source));
    if (versionMap !== undefined) {
      var versionMap$1 = Caml_option.valFromOption(versionMap);
      var count = Js_dict.get(versionMap$1, version);
      if (count !== undefined) {
        versionMap$1[version] = count + 1 | 0;
        return ;
      }
      var newVersionMap = {};
      newVersionMap[version] = 1;
      sourceVersionMap[Curry._1(C.getSourceId, source)] = newVersionMap;
      return ;
    }
    var newVersionMap$1 = {};
    newVersionMap$1[version] = 1;
    sourceVersionMap[Curry._1(C.getSourceId, source)] = newVersionMap$1;
  };
  var addItemToFilterCount = function (map, id) {
    var count = Js_dict.get(map, id);
    if (count !== undefined) {
      map[id] = count + 1 | 0;
    } else {
      map[id] = 1;
    }
  };
  var addSourceItemsToFilterCount = function (sourceMap, sourceVersionMap, source) {
    addItemToFilterCount(sourceMap, Curry._1(C.getSourceId, source));
    Belt_Array.forEach(Curry._1(C.getVersions, source), (function (param) {
            return addVersionsToFilterCount(sourceVersionMap, source, param);
          }));
  };
  var addReleaseVersionToFilterCount = function (releasesMap, appVersions, issueVersions, sourceId) {
    var versionsSet = Belt_SetString.fromArray(issueVersions);
    var latestVersions = Belt_Array.map(AppVersionsTypes.getLatestVersions(3, appVersions, sourceId), AppVersionsTypes.print);
    var latestVersion = Belt_Array.get(latestVersions, 0);
    if (latestVersion !== undefined && Belt_SetString.has(versionsSet, latestVersion)) {
      var init = releasesMap.contents;
      releasesMap.contents = {
        latest: releasesMap.contents.latest + 1 | 0,
        twoLatest: init.twoLatest,
        threeLatest: init.threeLatest
      };
    }
    var secondLatestVersion = Belt_Array.get(latestVersions, 1);
    if (secondLatestVersion !== undefined && (Belt_SetString.has(versionsSet, secondLatestVersion) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 0))))) {
      var init$1 = releasesMap.contents;
      releasesMap.contents = {
        latest: init$1.latest,
        twoLatest: releasesMap.contents.twoLatest + 1 | 0,
        threeLatest: init$1.threeLatest
      };
    }
    var thirdLatestVersion = Belt_Array.get(latestVersions, 2);
    if (thirdLatestVersion === undefined) {
      return ;
    }
    if (!(Belt_SetString.has(versionsSet, thirdLatestVersion) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 1))) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 0))))) {
      return ;
    }
    var init$2 = releasesMap.contents;
    releasesMap.contents = {
      latest: init$2.latest,
      twoLatest: init$2.twoLatest,
      threeLatest: releasesMap.contents.threeLatest + 1 | 0
    };
  };
  var addItemsToFilterCount = function (sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap, appVersions) {
    return function (issue) {
      addSourceItemsToFilterCount(sourceMap, sourceVersionMap, Curry._1(C.getSource, issue));
      Belt_Array.forEach(Curry._1(C.getCategories, issue), (function (param) {
              return addItemToFilterCount(categoriesMap, param);
            }));
      Belt_Array.forEach(Curry._1(C.getTags, issue), (function (param) {
              return addItemToFilterCount(tagsMap, param);
            }));
      addItemToFilterCount(issueTypesMap, Curry._1(C.getIssueTypeString, issue));
      addItemToFilterCount(eventNamesMap, Curry._1(C.getEventName, issue));
      Belt_Option.forEach(Curry._1(C.getPropertyName, issue), (function (param) {
              return addItemToFilterCount(propertyNamesMap, param);
            }));
      addReleaseVersionToFilterCount(releasesMap, appVersions, Curry._1(C.getImpactedReleases, issue), Curry._1(C.getSourceId, Curry._1(C.getSource, issue)));
    };
  };
  var addResultsToMap = function (sourceMap, param, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap) {
    return function (groupedFilter) {
      var variant = groupedFilter.NAME;
      if (variant === "propertyNames") {
        return {
                NAME: "propertyNames",
                VAL: Js_dict.entries(propertyNamesMap)
              };
      } else if (variant === "issueTypes") {
        return {
                NAME: "issueTypes",
                VAL: Belt_Array.keepMap(Js_dict.entries(issueTypesMap), (function (param) {
                        var issueType = InspectorIssuesFilter.FilterIssueType.fromString(param[0]);
                        if (issueType !== undefined) {
                          return [
                                  issueType,
                                  param[1]
                                ];
                        }
                        
                      }))
              };
      } else if (variant === "releases") {
        return {
                NAME: "releases",
                VAL: [
                  [
                    "latestRelease",
                    releasesMap.contents.latest
                  ],
                  [
                    "twoLatestReleases",
                    releasesMap.contents.twoLatest
                  ],
                  [
                    "threeLatestReleases",
                    releasesMap.contents.threeLatest
                  ]
                ]
              };
      } else if (variant === "categories") {
        return {
                NAME: "categories",
                VAL: Js_dict.entries(categoriesMap)
              };
      } else if (variant === "eventNames") {
        return {
                NAME: "eventNames",
                VAL: Js_dict.entries(eventNamesMap)
              };
      } else if (variant === "sources") {
        return {
                NAME: "sources",
                VAL: Js_dict.entries(sourceMap)
              };
      } else {
        return {
                NAME: "tags",
                VAL: Js_dict.entries(tagsMap)
              };
      }
    };
  };
  var addMissingFilters = function (model, inspectorSources) {
    return function (countedGroupFilter) {
      var variant = countedGroupFilter.NAME;
      if (variant === "propertyNames") {
        var propertyNames = countedGroupFilter.VAL;
        var propertyNamesMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(model.properties, (function (property) {
                                    if (property.TAG === /* PropertyRef */0) {
                                      return ;
                                    } else {
                                      return property._0.name;
                                    }
                                  }))))), (function (propertyName) {
                    return Belt_Array.some(propertyNames, (function (param) {
                                  return param[0] === propertyName;
                                })) === false;
                  })), (function (propertyNameMissingFromFilter) {
                return [
                        propertyNameMissingFromFilter,
                        0
                      ];
              }));
        return {
                NAME: "propertyNames",
                VAL: Belt_Array.concat(propertyNames, propertyNamesMissingFromFilter)
              };
      }
      if (variant === "issueTypes") {
        var issueTypes = countedGroupFilter.VAL;
        var issueTypesMissingFromFilter = Curry._1(C.getMissingIssueFilterTypes, issueTypes);
        return {
                NAME: "issueTypes",
                VAL: Belt_Array.concat(issueTypes, issueTypesMissingFromFilter)
              };
      }
      if (variant === "releases") {
        return countedGroupFilter;
      }
      if (variant === "categories") {
        var categories = countedGroupFilter.VAL;
        var categoriesMissingFromFilter = Belt_List.toArray(Belt_List.map(Belt_List.map(Belt_List.keep(model.goals, (function (category) {
                            return Belt_Array.some(categories, (function (param) {
                                          return param[0] === category.id;
                                        })) === false;
                          })), (function (category) {
                        return category.id;
                      })), (function (categoryMissingFromFilter) {
                    return [
                            categoryMissingFromFilter,
                            0
                          ];
                  })));
        return {
                NAME: "categories",
                VAL: Belt_Array.concat(categories, categoriesMissingFromFilter)
              };
      }
      if (variant === "eventNames") {
        var eventNames = countedGroupFilter.VAL;
        var eventNamesMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.events, (function (param) {
                                    return param.name;
                                  }))))), (function (eventName) {
                    return Belt_Array.some(eventNames, (function (param) {
                                  return param[0] === eventName;
                                })) === false;
                  })), (function (eventNameMissingFromFilter) {
                return [
                        eventNameMissingFromFilter,
                        0
                      ];
              }));
        return {
                NAME: "eventNames",
                VAL: Belt_Array.concat(eventNames, eventNamesMissingFromFilter)
              };
      }
      if (variant === "sources") {
        var sources = countedGroupFilter.VAL;
        var sourcesMissingFromFilter = Belt_Array.map(Belt_Array.map(Belt_Array.keep(inspectorSources, (function (inspectorSource) {
                        return Belt_Array.some(sources, (function (param) {
                                      return param[0] === inspectorSource.id;
                                    })) === false;
                      })), (function (source) {
                    return source.id;
                  })), (function (inspectorSourceMissingFromFilter) {
                return [
                        inspectorSourceMissingFromFilter,
                        0
                      ];
              }));
        return {
                NAME: "sources",
                VAL: Belt_Array.concat(sources, sourcesMissingFromFilter)
              };
      }
      var tags = countedGroupFilter.VAL;
      var tagsMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.map(model.events, (function (param) {
                                      return Belt_List.toArray(param.tags);
                                    })))))), (function (tag) {
                  return Belt_Array.some(tags, (function (param) {
                                return param[0] === tag;
                              })) === false;
                })), (function (tagMissingFromFilter) {
              return [
                      tagMissingFromFilter,
                      0
                    ];
            }));
      return {
              NAME: "tags",
              VAL: Belt_Array.concat(tags, tagsMissingFromFilter)
            };
    };
  };
  var get = function (inspectorSources, model, appVersions, issues) {
    var sourceMap = {};
    var sourceVersionMap = {};
    var categoriesMap = {};
    var tagsMap = {};
    var issueTypesMap = {};
    var eventNamesMap = {};
    var propertyNamesMap = {};
    var releasesMap = {
      contents: {
        latest: 0,
        twoLatest: 0,
        threeLatest: 0
      }
    };
    Belt_Array.forEachU(issues, addItemsToFilterCount(sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap, appVersions));
    return Belt_Map.mapU(Belt_Map.mapU(getAvailableFilterMaps(undefined), addResultsToMap(sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap)), addMissingFilters(model, inspectorSources));
  };
  return {
          getAvailableFilterMaps: getAvailableFilterMaps,
          addVersionsToFilterCount: addVersionsToFilterCount,
          addItemToFilterCount: addItemToFilterCount,
          addSourceItemsToFilterCount: addSourceItemsToFilterCount,
          addReleaseVersionToFilterCount: addReleaseVersionToFilterCount,
          addItemsToFilterCount: addItemsToFilterCount,
          addResultsToMap: addResultsToMap,
          addMissingFilters: addMissingFilters,
          get: get
        };
}

function getVersions(source) {
  return Belt_Array.map(source.versions, (function (param) {
                return param.release;
              }));
}

function getIssueTypeString(issue) {
  return InspectorIssuesFilter.FilterIssueType.toString(InspectorIssuesFilter.FilterIssueType.fromNewIssue(issue));
}

function getImpactedReleases(issue) {
  return Belt_Array.map(issue.source.impactedVersions, (function (param) {
                return param.release;
              }));
}

function getAvailableFilterMaps(param) {
  return Belt_Map.fromArray(Belt_Array.keepMap(InspectorIssuesFilter.FilterType.availableFilterTypes, (function (filterType) {
                    if (filterType === "eventName") {
                      return [
                              "eventName",
                              {
                                NAME: "eventNames",
                                VAL: []
                              }
                            ];
                    } else if (filterType === "tag") {
                      return [
                              "tag",
                              {
                                NAME: "tags",
                                VAL: []
                              }
                            ];
                    } else if (filterType === "issueType") {
                      return [
                              "issueType",
                              {
                                NAME: "issueTypes",
                                VAL: []
                              }
                            ];
                    } else if (filterType === "propertyName") {
                      return [
                              "propertyName",
                              {
                                NAME: "propertyNames",
                                VAL: []
                              }
                            ];
                    } else if (filterType === "category") {
                      return [
                              "category",
                              {
                                NAME: "categories",
                                VAL: []
                              }
                            ];
                    } else if (filterType === "source") {
                      return [
                              "source",
                              {
                                NAME: "sources",
                                VAL: []
                              }
                            ];
                    } else {
                      return [
                              "release",
                              {
                                NAME: "releases",
                                VAL: [
                                  [
                                    "latestRelease",
                                    0
                                  ],
                                  [
                                    "twoLatestReleases",
                                    0
                                  ],
                                  [
                                    "threeLatestReleases",
                                    0
                                  ]
                                ]
                              }
                            ];
                    }
                  })), InspectorIssuesFilter.FilterType.Comparator);
}

function addVersionsToFilterCount(sourceVersionMap, source, version) {
  var versionMap = Js_dict.get(sourceVersionMap, source.id);
  if (versionMap !== undefined) {
    var versionMap$1 = Caml_option.valFromOption(versionMap);
    var count = Js_dict.get(versionMap$1, version);
    if (count !== undefined) {
      versionMap$1[version] = count + 1 | 0;
      return ;
    }
    var newVersionMap = {};
    newVersionMap[version] = 1;
    sourceVersionMap[source.id] = newVersionMap;
    return ;
  }
  var newVersionMap$1 = {};
  newVersionMap$1[version] = 1;
  sourceVersionMap[source.id] = newVersionMap$1;
}

function addItemToFilterCount(map, id) {
  var count = Js_dict.get(map, id);
  if (count !== undefined) {
    map[id] = count + 1 | 0;
  } else {
    map[id] = 1;
  }
}

function addSourceItemsToFilterCount(sourceMap, sourceVersionMap, source) {
  addItemToFilterCount(sourceMap, source.id);
  Belt_Array.forEach(getVersions(source), (function (param) {
          return addVersionsToFilterCount(sourceVersionMap, source, param);
        }));
}

function addReleaseVersionToFilterCount(releasesMap, appVersions, issueVersions, sourceId) {
  var versionsSet = Belt_SetString.fromArray(issueVersions);
  var latestVersions = Belt_Array.map(AppVersionsTypes.getLatestVersions(3, appVersions, sourceId), AppVersionsTypes.print);
  var latestVersion = Belt_Array.get(latestVersions, 0);
  if (latestVersion !== undefined && Belt_SetString.has(versionsSet, latestVersion)) {
    var init = releasesMap.contents;
    releasesMap.contents = {
      latest: releasesMap.contents.latest + 1 | 0,
      twoLatest: init.twoLatest,
      threeLatest: init.threeLatest
    };
  }
  var secondLatestVersion = Belt_Array.get(latestVersions, 1);
  if (secondLatestVersion !== undefined && (Belt_SetString.has(versionsSet, secondLatestVersion) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 0))))) {
    var init$1 = releasesMap.contents;
    releasesMap.contents = {
      latest: init$1.latest,
      twoLatest: releasesMap.contents.twoLatest + 1 | 0,
      threeLatest: init$1.threeLatest
    };
  }
  var thirdLatestVersion = Belt_Array.get(latestVersions, 2);
  if (thirdLatestVersion === undefined) {
    return ;
  }
  if (!(Belt_SetString.has(versionsSet, thirdLatestVersion) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 1))) || Belt_SetString.has(versionsSet, Belt_Option.getExn(Belt_Array.get(latestVersions, 0))))) {
    return ;
  }
  var init$2 = releasesMap.contents;
  releasesMap.contents = {
    latest: init$2.latest,
    twoLatest: init$2.twoLatest,
    threeLatest: releasesMap.contents.threeLatest + 1 | 0
  };
}

function addItemsToFilterCount(sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap, appVersions) {
  return function (issue) {
    addSourceItemsToFilterCount(sourceMap, sourceVersionMap, issue.source);
    Belt_Array.forEach(issue.categories, (function (param) {
            return addItemToFilterCount(categoriesMap, param);
          }));
    Belt_Array.forEach(issue.tags, (function (param) {
            return addItemToFilterCount(tagsMap, param);
          }));
    addItemToFilterCount(issueTypesMap, getIssueTypeString(issue));
    addItemToFilterCount(eventNamesMap, issue.eventName);
    Belt_Option.forEach(issue.propertyName, (function (param) {
            return addItemToFilterCount(propertyNamesMap, param);
          }));
    addReleaseVersionToFilterCount(releasesMap, appVersions, getImpactedReleases(issue), issue.source.id);
  };
}

function addResultsToMap(sourceMap, param, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap) {
  return function (groupedFilter) {
    var variant = groupedFilter.NAME;
    if (variant === "propertyNames") {
      return {
              NAME: "propertyNames",
              VAL: Js_dict.entries(propertyNamesMap)
            };
    } else if (variant === "issueTypes") {
      return {
              NAME: "issueTypes",
              VAL: Belt_Array.keepMap(Js_dict.entries(issueTypesMap), (function (param) {
                      var issueType = InspectorIssuesFilter.FilterIssueType.fromString(param[0]);
                      if (issueType !== undefined) {
                        return [
                                issueType,
                                param[1]
                              ];
                      }
                      
                    }))
            };
    } else if (variant === "releases") {
      return {
              NAME: "releases",
              VAL: [
                [
                  "latestRelease",
                  releasesMap.contents.latest
                ],
                [
                  "twoLatestReleases",
                  releasesMap.contents.twoLatest
                ],
                [
                  "threeLatestReleases",
                  releasesMap.contents.threeLatest
                ]
              ]
            };
    } else if (variant === "categories") {
      return {
              NAME: "categories",
              VAL: Js_dict.entries(categoriesMap)
            };
    } else if (variant === "eventNames") {
      return {
              NAME: "eventNames",
              VAL: Js_dict.entries(eventNamesMap)
            };
    } else if (variant === "sources") {
      return {
              NAME: "sources",
              VAL: Js_dict.entries(sourceMap)
            };
    } else {
      return {
              NAME: "tags",
              VAL: Js_dict.entries(tagsMap)
            };
    }
  };
}

function addMissingFilters(model, inspectorSources) {
  return function (countedGroupFilter) {
    var variant = countedGroupFilter.NAME;
    if (variant === "propertyNames") {
      var propertyNames = countedGroupFilter.VAL;
      var propertyNamesMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.keepMap(model.properties, (function (property) {
                                  if (property.TAG === /* PropertyRef */0) {
                                    return ;
                                  } else {
                                    return property._0.name;
                                  }
                                }))))), (function (propertyName) {
                  return Belt_Array.some(propertyNames, (function (param) {
                                return param[0] === propertyName;
                              })) === false;
                })), (function (propertyNameMissingFromFilter) {
              return [
                      propertyNameMissingFromFilter,
                      0
                    ];
            }));
      return {
              NAME: "propertyNames",
              VAL: Belt_Array.concat(propertyNames, propertyNamesMissingFromFilter)
            };
    }
    if (variant === "issueTypes") {
      var issueTypes = countedGroupFilter.VAL;
      var issueTypesMissingFromFilter = getMissingIssueFilterTypes(issueTypes);
      return {
              NAME: "issueTypes",
              VAL: Belt_Array.concat(issueTypes, issueTypesMissingFromFilter)
            };
    }
    if (variant === "releases") {
      return countedGroupFilter;
    }
    if (variant === "categories") {
      var categories = countedGroupFilter.VAL;
      var categoriesMissingFromFilter = Belt_List.toArray(Belt_List.map(Belt_List.map(Belt_List.keep(model.goals, (function (category) {
                          return Belt_Array.some(categories, (function (param) {
                                        return param[0] === category.id;
                                      })) === false;
                        })), (function (category) {
                      return category.id;
                    })), (function (categoryMissingFromFilter) {
                  return [
                          categoryMissingFromFilter,
                          0
                        ];
                })));
      return {
              NAME: "categories",
              VAL: Belt_Array.concat(categories, categoriesMissingFromFilter)
            };
    }
    if (variant === "eventNames") {
      var eventNames = countedGroupFilter.VAL;
      var eventNamesMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.events, (function (param) {
                                  return param.name;
                                }))))), (function (eventName) {
                  return Belt_Array.some(eventNames, (function (param) {
                                return param[0] === eventName;
                              })) === false;
                })), (function (eventNameMissingFromFilter) {
              return [
                      eventNameMissingFromFilter,
                      0
                    ];
            }));
      return {
              NAME: "eventNames",
              VAL: Belt_Array.concat(eventNames, eventNamesMissingFromFilter)
            };
    }
    if (variant === "sources") {
      var sources = countedGroupFilter.VAL;
      var sourcesMissingFromFilter = Belt_Array.map(Belt_Array.map(Belt_Array.keep(inspectorSources, (function (inspectorSource) {
                      return Belt_Array.some(sources, (function (param) {
                                    return param[0] === inspectorSource.id;
                                  })) === false;
                    })), (function (source) {
                  return source.id;
                })), (function (inspectorSourceMissingFromFilter) {
              return [
                      inspectorSourceMissingFromFilter,
                      0
                    ];
            }));
      return {
              NAME: "sources",
              VAL: Belt_Array.concat(sources, sourcesMissingFromFilter)
            };
    }
    var tags = countedGroupFilter.VAL;
    var tagsMissingFromFilter = Belt_Array.map(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_List.toArray(Belt_List.map(model.events, (function (param) {
                                    return Belt_List.toArray(param.tags);
                                  })))))), (function (tag) {
                return Belt_Array.some(tags, (function (param) {
                              return param[0] === tag;
                            })) === false;
              })), (function (tagMissingFromFilter) {
            return [
                    tagMissingFromFilter,
                    0
                  ];
          }));
    return {
            NAME: "tags",
            VAL: Belt_Array.concat(tags, tagsMissingFromFilter)
          };
  };
}

function get(inspectorSources, model, appVersions, issues) {
  var sourceMap = {};
  var sourceVersionMap = {};
  var categoriesMap = {};
  var tagsMap = {};
  var issueTypesMap = {};
  var eventNamesMap = {};
  var propertyNamesMap = {};
  var releasesMap = {
    contents: {
      latest: 0,
      twoLatest: 0,
      threeLatest: 0
    }
  };
  Belt_Array.forEachU(issues, addItemsToFilterCount(sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap, appVersions));
  return Belt_Map.mapU(Belt_Map.mapU(getAvailableFilterMaps(undefined), addResultsToMap(sourceMap, sourceVersionMap, categoriesMap, tagsMap, issueTypesMap, eventNamesMap, propertyNamesMap, releasesMap)), addMissingFilters(model, inspectorSources));
}

var FilterCounter = {
  getAvailableFilterMaps: getAvailableFilterMaps,
  addVersionsToFilterCount: addVersionsToFilterCount,
  addItemToFilterCount: addItemToFilterCount,
  addSourceItemsToFilterCount: addSourceItemsToFilterCount,
  addReleaseVersionToFilterCount: addReleaseVersionToFilterCount,
  addItemsToFilterCount: addItemsToFilterCount,
  addResultsToMap: addResultsToMap,
  addMissingFilters: addMissingFilters,
  get: get
};

function getFilters(filters, filterType) {
  if (filterType === "eventName") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "eventName";
                  } else {
                    return false;
                  }
                }));
  } else if (filterType === "tag") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "tag";
                  } else {
                    return false;
                  }
                }));
  } else if (filterType === "issueType") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "issueType";
                  } else {
                    return false;
                  }
                }));
  } else if (filterType === "propertyName") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "propertyName";
                  } else {
                    return false;
                  }
                }));
  } else if (filterType === "category") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "category";
                  } else {
                    return false;
                  }
                }));
  } else if (filterType === "source") {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "source";
                  } else {
                    return false;
                  }
                }));
  } else {
    return Belt_Array.keep(filters, (function (filter) {
                  if (typeof filter === "object") {
                    return filter.NAME === "release";
                  } else {
                    return false;
                  }
                }));
  }
}

function hasFilters(filters, filterType) {
  return getFilters(filters, filterType).length !== 0;
}

function getIssueSourceName(sourceId, sources) {
  return Belt_Option.flatMap(Belt_List.getBy(sources, (function (param) {
                    return param.id === sourceId;
                  })), (function (param) {
                return param.name;
              }));
}

function getIssueSourceNameById(sourceId, model) {
  var name = getIssueSourceName(sourceId, model.sources);
  if (name !== undefined) {
    return name;
  } else {
    return Belt_Option.mapWithDefault(getIssueSourceName(sourceId, model.archive.sources), "Untitled source", (function (name) {
                  return "" + name + " (Archived)";
                }));
  }
}

function getCategoryNameById(categoryId, model) {
  var match = Belt_List.getBy(model.goals, (function (param) {
          return param.id === categoryId;
        }));
  if (match !== undefined) {
    return match.name;
  } else {
    return Belt_Option.mapWithDefault(Belt_List.getBy(model.archive.goals, (function (param) {
                      return param.id === categoryId;
                    })), "Untitled category", (function (param) {
                  return "" + param.name + " (Archived)";
                }));
  }
}

function filterToValue(filter, model) {
  var variant = filter.NAME;
  if (variant === "propertyName" || variant === "tag" || variant === "eventName") {
    return filter.VAL;
  } else if (variant === "issueType") {
    return InspectorIssuesFilter.FilterIssueType.toDisplayString(filter.VAL);
  } else if (variant === "category") {
    return getCategoryNameById(filter.VAL, model);
  } else if (variant === "source") {
    return getIssueSourceNameById(filter.VAL, model);
  } else {
    return Curry._1(InspectorIssuesFilter.Filter.Release.toDisplayString, filter.VAL);
  }
}

function filterToString(filter, model) {
  var variant = filter.NAME;
  if (variant === "eventName") {
    return "Event Name: " + filter.VAL;
  } else if (variant === "tag") {
    return "Tag: " + filter.VAL;
  } else if (variant === "issueType") {
    return "Issue Type: " + InspectorIssuesFilter.FilterIssueType.toString(filter.VAL);
  } else if (variant === "propertyName") {
    return "Property Name: " + filter.VAL;
  } else if (variant === "category") {
    return "Category: " + getCategoryNameById(filter.VAL, model);
  } else if (variant === "source") {
    return "Source: " + getIssueSourceNameById(filter.VAL, model);
  } else {
    return "Release: " + Curry._1(InspectorIssuesFilter.Filter.Release.toString, filter.VAL);
  }
}

function groupFilterValuesToArray(groupedFilter, model) {
  var variant = groupedFilter.NAME;
  if (variant === "issueTypes") {
    return Belt_Array.map(groupedFilter.VAL, InspectorIssuesFilter.FilterIssueType.toDisplayString);
  } else if (variant === "releases") {
    return Belt_Array.map(groupedFilter.VAL, InspectorIssuesFilter.Filter.Release.toDisplayString);
  } else if (variant === "categories") {
    return Belt_Array.map(groupedFilter.VAL, (function (categoryId) {
                  return getCategoryNameById(categoryId, model);
                }));
  } else if (variant === "sources") {
    return Belt_Array.map(groupedFilter.VAL, (function (sourceId) {
                  return getIssueSourceNameById(sourceId, model);
                }));
  } else {
    return groupedFilter.VAL;
  }
}

function savedViewOrderToAnalytics(order) {
  if (order === undefined) {
    return "FirstSeen";
  }
  switch (order[0]) {
    case "event" :
        return "EventName";
    case "eventVolume" :
        return "EventVolume";
    case "firstSeen" :
        return "FirstSeen";
    case "issue" :
        return "IssueType";
    case "issuePercentage" :
        return "IssuePercentage";
    case "issueVolume" :
        return "IssueVolume";
    case "lastSeen" :
        return "LastSeen";
    case "property" :
        return "PropertyName";
    default:
      return "FirstSeen";
  }
}

function savedViewOrderDirectionToAnalytics(orderedBy) {
  if (orderedBy === undefined) {
    return "Decending";
  }
  var match = orderedBy[1];
  if (match === "desc" || match !== "asc") {
    return "Decending";
  } else {
    return "Ascending";
  }
}

function orderedByToConfigureItemAnalytics(orderedBy) {
  if (orderedBy === undefined) {
    return "FirstSeen";
  }
  switch (orderedBy[0]) {
    case "event" :
        return "EventName";
    case "eventVolume" :
        return "EventVolume";
    case "firstSeen" :
        return "FirstSeen";
    case "issue" :
        return "IssueType";
    case "issuePercentage" :
        return "IssuePercentage";
    case "issueVolume" :
        return "IssueVolume";
    case "lastSeen" :
        return "LastSeen";
    case "property" :
        return "PropertyName";
    default:
      return "FirstSeen";
  }
}

function groupedFiltersToAnalytics(groupedFilters) {
  return Belt_Array.map(groupedFilters, (function (groupedFilter) {
                var variant = groupedFilter.NAME;
                if (variant === "propertyNames") {
                  return "PropertyName";
                } else if (variant === "issueTypes") {
                  return "IssueType";
                } else if (variant === "releases") {
                  return "Release";
                } else if (variant === "categories") {
                  return "Category";
                } else if (variant === "eventNames") {
                  return "EventName";
                } else if (variant === "sources") {
                  return "Source";
                } else {
                  return "Tag";
                }
              }));
}

function inspectorIssueStatusViewToAnalytics(status) {
  switch (status) {
    case /* Unresolved */0 :
        return "Unresolved";
    case /* Ignored */1 :
        return "Ignored";
    case /* Resolved */2 :
        return "Resolved";
    
  }
}

function savedViewStatusToViewTypeAnalytics(savedViewStatus) {
  if (savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "loading") {
    return "SavedView";
  } else {
    return "AvoCreated";
  }
}

export {
  groupFilters ,
  getScoreThreshold ,
  keepIssueModelByGroupedFilter ,
  keepIssueModel ,
  recalculateNumbersForReleaseFilters ,
  filterIssueViewModels ,
  removeFilters ,
  toggleFilters ,
  getMissingIssueFilterTypes ,
  MakeFilterCounter ,
  FilterCounter ,
  getFilters ,
  hasFilters ,
  getIssueSourceName ,
  getIssueSourceNameById ,
  getCategoryNameById ,
  filterToValue ,
  filterToString ,
  groupFilterValuesToArray ,
  savedViewOrderToAnalytics ,
  savedViewOrderDirectionToAnalytics ,
  orderedByToConfigureItemAnalytics ,
  groupedFiltersToAnalytics ,
  inspectorIssueStatusViewToAnalytics ,
  savedViewStatusToViewTypeAnalytics ,
}
/* Issues Not a pure module */
