// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as GetRegexValidationUseCase from "./GetRegexValidationUseCase.mjs";

function clear(model) {
  return {
          types: model.types,
          properties: Belt_List.mapU(model.properties, (function (property) {
                  if (property.TAG === /* PropertyRef */0) {
                    return {
                            TAG: /* PropertyRef */0,
                            _0: property._0
                          };
                  }
                  var property$1 = property._0;
                  var hasRegex = GetRegexValidationUseCase.get(property$1) !== undefined;
                  var newrecord = Caml_obj.obj_dup(property$1);
                  return {
                          TAG: /* Property */1,
                          _0: (newrecord.eventSpecificAllowedPropertyValues = hasRegex ? [] : property$1.eventSpecificAllowedPropertyValues, newrecord.validations = Belt_List.keepU(property$1.validations, (function (validation) {
                                    if (typeof validation === "object" && validation.NAME === "Matches") {
                                      return !hasRegex;
                                    } else {
                                      return true;
                                    }
                                  })), newrecord)
                        };
                })),
          propertyBundles: model.propertyBundles,
          events: model.events,
          migrations: model.migrations,
          sources: model.sources,
          destinations: model.destinations,
          groupTypes: model.groupTypes,
          goals: model.goals,
          metrics: model.metrics,
          archive: model.archive,
          openBranches: model.openBranches,
          branchPointer: model.branchPointer,
          rules: model.rules,
          integrations: model.integrations,
          globalRequirements: model.globalRequirements
        };
}

export {
  clear ,
}
/* No side effect */
