// Generated by ReScript, PLEASE EDIT WITH CARE


function terminateWorker(prim) {
  prim.terminate();
}

export {
  terminateWorker ,
}
/* No side effect */
