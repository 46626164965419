// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import LodashDebounce from "lodash.debounce";
import * as StringSimilarity from "string-similarity";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as IntelligenceNameFeedbackEndpoint from "../../shared/endpoints/IntelligenceNameFeedbackEndpoint.mjs";

function isValid(validationResult) {
  if (validationResult.isIllegal === false && validationResult.inconsistentCase === false && validationResult.isEmpty === false) {
    return validationResult.conflictingName === undefined;
  } else {
    return false;
  }
}

function validateInput(existingNames, forceAllowName, stateName, commonCase) {
  var existingNames$1 = Belt_Array.keepU(existingNames, (function (item) {
          return Case.to_("Camel", item) !== Case.to_("Camel", forceAllowName);
        }));
  var conflictingName = NameUtils.getConflictingNames__array(existingNames$1, stateName);
  var isEmpty = NameUtils.isEmpty(stateName);
  var isIllegal = Belt_Option.isSome(conflictingName) || isEmpty;
  var similarNames = Belt_Array.mapU(Belt_SortArray.stableSortByU(Belt_Array.keepU(Belt_Array.mapU(existingNames$1, (function (name) {
                      return [
                              name,
                              StringSimilarity.compareTwoStrings(name, stateName)
                            ];
                    })), (function (param) {
                  return param[1] >= 0.8;
                })), (function (param, param$1) {
              var b = param$1[1];
              var a = param[1];
              if (a > b) {
                return 1;
              } else if (a < b) {
                return -1;
              } else {
                return 0;
              }
            })), (function (param) {
          return param[0];
        }));
  var inconsistentCase = !NameUtils.isCorrectCase(commonCase, stateName);
  return {
          isIllegal: isIllegal,
          inconsistentCase: inconsistentCase,
          isEmpty: isEmpty,
          conflictingName: conflictingName,
          similarNames: similarNames
        };
}

function validateInputWithAction(send, existingNames, name, stateName, commonCase) {
  Curry._1(send, {
        TAG: /* UpdateValidation */2,
        _0: {
          NAME: "Result",
          VAL: validateInput(existingNames, name, stateName, commonCase)
        }
      });
}

var debouncedValidation = LodashDebounce(validateInputWithAction, 400, {
      leading: false,
      trailing: true
    });

var debouncedIntelligenceFeedback = LodashDebounce((function (send, param, param$1, existingNames, stateName, commonCase) {
        var namingActionType = param$1[1];
        var itemType = param$1[0];
        var schemaId = param[1];
        var schemaBundle = param[0];
        if (stateName === "") {
          return Curry._1(send, {
                      TAG: /* UpdateIntelligenceFeedback */3,
                      _0: "Init"
                    });
        } else {
          if (param[2]) {
            Curry._1(send, {
                  TAG: /* UpdateIntelligenceFeedback */3,
                  _0: "Loading"
                });
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemType, namingActionType, Js_array.joinWith("\n", existingNames).length / 4 | 0, "UnlimitedAccess", schemaBundle.branchId, schemaId);
            $$Promise.$$catch(Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                          return fetch("" + Firebase.apiUrl + "/intelligence/name", Fetch.RequestInit.make(/* Post */2, [
                                            [
                                              "Accept",
                                              "application/json"
                                            ],
                                            [
                                              "Content-Type",
                                              "application/json"
                                            ],
                                            [
                                              "Authorization",
                                              "Bearer " + userToken + ""
                                            ]
                                          ], Caml_option.some(JSON.stringify(IntelligenceNameFeedbackEndpoint.$$Request.encode({
                                                        schemaId: schemaId,
                                                        nameSpace: existingNames,
                                                        itemType: itemType === "Property" ? /* Property */1 : /* Event */0,
                                                        case: commonCase,
                                                        inputName: stateName
                                                      }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                        }).then(Fetch.$$Response.json).then(function (responseJson) {
                      try {
                        var result = IntelligenceNameFeedbackEndpoint.$$Response.decode(responseJson);
                        return Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: {
                                      NAME: "Result",
                                      VAL: result.feedback
                                    }
                                  });
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Intelligence feedback error", error);
                        return Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: "Init"
                                  });
                      }
                    }), (function (error) {
                    console.error("Intelligence feedback error", error);
                    return Promise.resolve(Curry._1(send, {
                                    TAG: /* UpdateIntelligenceFeedback */3,
                                    _0: "Init"
                                  }));
                  }));
          } else {
            AnalyticsRe.intelligenceNamingFeedbackInitiated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, itemType, namingActionType, Js_array.joinWith("\n", existingNames).length / 4 | 0, "NoAccess", schemaBundle.branchId, schemaId);
          }
          return ;
        }
      }), 1000, {
      leading: false,
      trailing: true
    });

export {
  isValid ,
  validateInput ,
  validateInputWithAction ,
  debouncedValidation ,
  debouncedIntelligenceFeedback ,
}
/* debouncedValidation Not a pure module */
