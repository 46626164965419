// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";
import * as AvoEngagement__Content__SetUpAlertsTour from "./AvoEngagement__Content__SetUpAlertsTour.mjs";

var goForward = AvoEngagement__Types.Block.Transition.goForward;

var next = AvoEngagement__Types.Block.Transition.next;

var previous = AvoEngagement__Types.Block.Transition.previous;

var complete = AvoEngagement__Types.Block.Transition.complete;

var goBack = AvoEngagement__Types.Block.Transition.goBack;

var step1_transitions = [
  Curry._2(goForward, "next-no-issues", {
        NAME: "block",
        VAL: "step-6a"
      }),
  Curry._2(goForward, "next-with-issues", {
        NAME: "block",
        VAL: "step-2"
      })
];

var step1_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "The Issues view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "string",
          VAL: "Here, you'll discover all issues that Inspector has seen in production over the last 24 hours. The default arrangement of issues is set to newest first."
        }
      }
    ]);

var step1 = {
  id: "step-1",
  transitions: step1_transitions,
  fields: step1_fields
};

var step2_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-1"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-3"
      })
];

var step2_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Issue details"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [
            {
              type_: "paragraph",
              children: [{
                  text: "Each issue applies to a single source and the table view provides key information that enables you to identify the issues you might want to act on.",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "For more details about a specific issue and how you might fix it just click it!",
                  italic: false,
                  bold: false
                }]
            }
          ]
        }
      }
    ]);

var step2 = {
  id: "step-2",
  transitions: step2_transitions,
  fields: step2_fields
};

var step3_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-2"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-4"
      })
];

var step3_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Issue details view"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [
            {
              type_: "paragraph",
              children: [{
                  text: "This view provides extensive information on what exactly the issue is, what percentage of events are impacted and which releases are producing it. You can also see whether the impacted event and/or property is present in your tracking plan.",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "Each issue applies to a single source.",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [
                {
                  text: "If the issue is also present on other sources, you can find them listed out under ",
                  italic: false,
                  bold: false
                },
                {
                  text: "ISSUE ACROSS SOURCES",
                  italic: true,
                  bold: false
                },
                {
                  text: ".",
                  italic: false,
                  bold: false
                }
              ]
            }
          ]
        }
      }
    ]);

var step3 = {
  id: "step-3",
  transitions: step3_transitions,
  fields: step3_fields
};

var step4_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-3"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-5"
      })
];

var step4_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Issue status"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [
            {
              type_: "paragraph",
              children: [
                {
                  text: "New Inspector issues are considered ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Unresolved",
                  italic: true,
                  bold: false
                },
                {
                  text: ". You can manually mark them as ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Ignored",
                  italic: true,
                  bold: false
                },
                {
                  text: " or ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Resolved",
                  italic: true,
                  bold: false
                },
                {
                  text: ". Resolved issues are monitored for regressions by Inspector.",
                  italic: false,
                  bold: false
                }
              ]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "When fixing an issue on an Avo branch you can connect that branch to the issue. You can also add links to any outside resources you and your team might be using for project management.",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "",
                  italic: false,
                  bold: false
                }]
            },
            {
              type_: "paragraph",
              children: [{
                  text: "At the bottom of this view you will find an Activity log where all updates are documented and you can add comments!",
                  italic: false,
                  bold: false
                }]
            }
          ]
        }
      }
    ]);

var step4 = {
  id: "step-4",
  transitions: step4_transitions,
  fields: step4_fields
};

var step5_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-4"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-6"
      }),
  Curry._2(goForward, "next-no-issues", {
        NAME: "block",
        VAL: "step-6a"
      })
];

var step5_fields = Curry._1(AvoEngagement__Types.Fields.fromArray, [
      {
        id: "title",
        value: {
          NAME: "string",
          VAL: "Issues tabs"
        }
      },
      {
        id: "body",
        value: {
          NAME: "richText",
          VAL: [{
              type_: "paragraph",
              children: [
                {
                  text: "Once you designate issues as ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Ignored",
                  italic: true,
                  bold: false
                },
                {
                  text: " or ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Resolved",
                  italic: true,
                  bold: false
                },
                {
                  text: ", they are transferred from the ",
                  italic: false,
                  bold: false
                },
                {
                  text: "Unresolved",
                  italic: true,
                  bold: false
                },
                {
                  text: " tab to the corresponding tab view. This helps you monitor the progress of issues you have taken action on and separates them from new ones that require attention.",
                  italic: false,
                  bold: false
                }
              ]
            }]
        }
      }
    ]);

var step5 = {
  id: "step-5",
  transitions: step5_transitions,
  fields: step5_fields
};

var step6_transitions = [
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-5"
      }),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-7"
      })
];

var step6_fields = AvoEngagement__Content__SetUpAlertsTour.step1.fields;

var step6 = {
  id: "step-6",
  transitions: step6_transitions,
  fields: step6_fields
};

var step6a_transitions = [
  Curry._1(next, {
        NAME: "block",
        VAL: "step-7"
      }),
  Curry._2(goForward, "with-filters", {
        NAME: "block",
        VAL: "step-7"
      })
];

var step6a_fields = AvoEngagement__Content__SetUpAlertsTour.step1a.fields;

var step6a = {
  id: "step-6a",
  transitions: step6a_transitions,
  fields: step6a_fields
};

var step7_transitions = [
  Curry._1(complete, undefined),
  Curry._1(next, {
        NAME: "block",
        VAL: "step-8"
      }),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-6"
      }),
  Curry._2(goBack, "previous-no-issues", {
        NAME: "block",
        VAL: "step-6a"
      })
];

var step7_fields = AvoEngagement__Content__SetUpAlertsTour.step2.fields;

var step7 = {
  id: "step-7",
  transitions: step7_transitions,
  fields: step7_fields
};

var step8_transitions = [
  Curry._1(complete, undefined),
  Curry._1(previous, {
        NAME: "block",
        VAL: "step-7"
      })
];

var step8_fields = AvoEngagement__Content__SetUpAlertsTour.step3.fields;

var step8 = {
  id: "step-8",
  transitions: step8_transitions,
  fields: step8_fields
};

var content_blocks = Curry._1(AvoEngagement__Types.Blocks.fromArray, [
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      step6a,
      step7,
      step8
    ]);

var content = {
  id: "inspector-issues-onboarding-flow-v2",
  version: 8,
  rootId: "step-1",
  blocks: content_blocks
};

export {
  goForward ,
  next ,
  previous ,
  complete ,
  goBack ,
  step1 ,
  step2 ,
  step3 ,
  step4 ,
  step5 ,
  step6 ,
  step6a ,
  step7 ,
  step8 ,
  content ,
}
/* step1 Not a pure module */
