// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PropertyType from "./PropertyType.mjs";
import * as PropertyInput from "./PropertyInput.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function MergePropertiesModal$Property(Props) {
  var property = Props.property;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.medium),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(15)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                  tl: {
                                    hd: Css.flexWrap("wrap"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  children: property.name
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexWrap("wrap"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(PropertyType.make, {
                      property: property
                    })));
}

function MergePropertiesModal$AdditionalProperties(Props) {
  var basePropertyId = Props.basePropertyId;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var propertyType = Props.propertyType;
  var selectedPropertyIds = Props.selectedPropertyIds;
  var setSelectedPropertyIds = Props.setSelectedPropertyIds;
  var replacementProperty = Props.replacementProperty;
  var properties = ModelStore.Mapped.useProperties(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var additionalPropertyIdSet = Belt_SetString.fromArray(selectedPropertyIds);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Belt_Array.keepMapU(selectedPropertyIds, (function (additionalPropertyId) {
                    var property = Curry._2(TrackingPlanMappedModel.Properties.get, properties, additionalPropertyId);
                    if (property !== undefined && property.id !== basePropertyId) {
                      return Caml_option.some(React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.alignItems("center"),
                                              tl: {
                                                hd: Css.gap(Css.px(4)),
                                                tl: {
                                                  hd: Css.flexGrow(1.0),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          })
                                    }, React.createElement($$Text.make, {
                                          size: "Tiny",
                                          color: Styles.Color.light08,
                                          children: "and"
                                        }), React.createElement(MergePropertiesModal$Property, {
                                          property: property
                                        }), React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.selector(" button", {
                                                      hd: Css.width(Css.px(28)),
                                                      tl: {
                                                        hd: Css.height(Css.px(28)),
                                                        tl: {
                                                          hd: Css.borderRadius(Css.px(14)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement(Button.make, {
                                              icon: "trash",
                                              onClick: (function (param) {
                                                  Curry._1(setSelectedPropertyIds, (function (currentPropertyIds) {
                                                          return Belt_Array.keepU(currentPropertyIds, (function (id) {
                                                                        return id !== property.id;
                                                                      }));
                                                        }));
                                                }),
                                              size: "tiny",
                                              style: "ghost"
                                            }))));
                    }
                    
                  })), React.createElement(PropertyInput.make, {
                  propertyType: propertyType,
                  options: [[
                      undefined,
                      Curry._2(TrackingPlanMappedModel.Properties.mapToArray, Curry._2(TrackingPlanMappedModel.Properties.keep, properties, (function (param) {
                                  var id = param.id;
                                  if (Caml_obj.equal(param.sendAs, propertyType) && !Belt_SetString.has(additionalPropertyIdSet, id)) {
                                    return Belt_Option.mapWithDefault(replacementProperty, true, (function (param) {
                                                  return param.id !== id;
                                                }));
                                  } else {
                                    return false;
                                  }
                                })), (function (property) {
                              return {
                                      NAME: "Property",
                                      VAL: property
                                    };
                            }))
                    ]],
                  onSelect: (function (item, param) {
                      if (item.NAME === "Group") {
                        return ;
                      }
                      var property = item.VAL;
                      Curry._1(setSelectedPropertyIds, (function (currentPropertyIds) {
                              return Belt_Array.concat(currentPropertyIds, [property.id]);
                            }));
                    }),
                  currentFilters: currentFilters,
                  renderButton: (function (onClick, buttonRef, isOpen) {
                      return React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(Button.make, {
                                      disabled: isOpen,
                                      icon: "plus",
                                      label: "Replace more",
                                      onClick: onClick,
                                      size: "tiny",
                                      ref: buttonRef
                                    }));
                    }),
                  sendActions: sendActions,
                  filters: filters,
                  propertyLocation: "PropertyDetails"
                }));
}

function MergePropertiesModal$ReplacementPropertyButton(Props) {
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var propertyType = Props.propertyType;
  var selectedPropertyIds = Props.selectedPropertyIds;
  var replacementProperty = Props.replacementProperty;
  var setReplacementProperty = Props.setReplacementProperty;
  var properties = ModelStore.Mapped.useProperties(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var selectedPropertyIdsSet = Belt_SetString.fromArray(selectedPropertyIds);
  return React.createElement(PropertyInput.make, {
              propertyType: propertyType,
              options: [[
                  undefined,
                  Curry._2(TrackingPlanMappedModel.Properties.mapToArray, Curry._2(TrackingPlanMappedModel.Properties.keep, properties, (function (param) {
                              if (Caml_obj.equal(param.sendAs, propertyType)) {
                                return !Belt_SetString.has(selectedPropertyIdsSet, param.id);
                              } else {
                                return false;
                              }
                            })), (function (property) {
                          return {
                                  NAME: "Property",
                                  VAL: property
                                };
                        }))
                ]],
              onSelect: (function (item, param) {
                  if (item.NAME === "Group") {
                    return ;
                  }
                  var property = item.VAL;
                  Curry._1(setReplacementProperty, (function (param) {
                          return property;
                        }));
                }),
              currentFilters: currentFilters,
              renderButton: (function (onClick, buttonRef, isOpen) {
                  return React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.textAlign("left"),
                                    tl: /* [] */0
                                  })
                            }, React.createElement("button", {
                                  ref: buttonRef,
                                  className: Curry._1(Css.style, {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.medium),
                                          tl: {
                                            hd: Css.padding2(Css.px(10), Css.px(15)),
                                            tl: {
                                              hd: Css.borderRadius(Styles.Border.radius),
                                              tl: {
                                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                tl: {
                                                  hd: Css.width(Css.pct(100)),
                                                  tl: {
                                                    hd: Css.cursor("pointer"),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }),
                                  disabled: isOpen,
                                  onClick: onClick
                                }, replacementProperty !== undefined ? React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement($$Text.make, {
                                            size: "Medium",
                                            weight: "Semi",
                                            singleLine: true,
                                            children: replacementProperty.name
                                          }), React.createElement("div", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.display("flex"),
                                                  tl: {
                                                    hd: Css.flexWrap("wrap"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                })
                                          }, React.createElement(PropertyType.make, {
                                                property: replacementProperty
                                              }))) : React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Styles.Color.darkBlue),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.textAlign("center"),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            })
                                      }, "Select property...")));
                }),
              sendActions: sendActions,
              filters: filters,
              propertyLocation: "PropertyDetails"
            });
}

function MergePropertiesModal(Props) {
  var propertyId = Props.propertyId;
  var onClose = Props.onClose;
  var currentFilters = Props.currentFilters;
  var filters = Props.filters;
  var properties = ModelStore.Mapped.useProperties(undefined);
  var events = ModelStore.Mapped.useEvents(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return [propertyId];
      });
  var selectedPropertyIds = match[0];
  var match$1 = React.useState(function () {
        
      });
  var replacementProperty = match$1[0];
  var property = Curry._2(TrackingPlanMappedModel.Properties.get, properties, propertyId);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.width(Css.px(600)),
                      tl: {
                        hd: Css.maxWidth(Css.pct(100)),
                        tl: {
                          hd: Css.padding4(Css.px(30), Css.px(30), Css.px(30), Css.px(30)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: {
                              hd: Css.borderRadius(Styles.Border.radius),
                              tl: {
                                hd: Css.cursor("default"),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.fontSize(Styles.FontSize.large),
                          tl: {
                            hd: Css.paddingBottom(Css.px(15)),
                            tl: {
                              hd: Css.textAlign("center"),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, "Archive and Replace Property"), property !== undefined ? React.createElement("div", undefined, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.regular),
                                tl: /* [] */0
                              }
                            })
                      }, "This will archive the property and replace it on all events its part of with the property you select below."), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "Archive..."), React.createElement(MergePropertiesModal$Property, {
                        property: property
                      }), React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement(MergePropertiesModal$AdditionalProperties, {
                        basePropertyId: propertyId,
                        currentFilters: currentFilters,
                        filters: filters,
                        propertyType: property.sendAs,
                        selectedPropertyIds: selectedPropertyIds,
                        setSelectedPropertyIds: match[1],
                        replacementProperty: replacementProperty
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.padding4(Css.px(15), Css.px(0), Css.px(10), Css.px(0)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.semi),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: /* [] */0
                                }
                              }
                            })
                      }, "and replace with..."), React.createElement(MergePropertiesModal$ReplacementPropertyButton, {
                        currentFilters: currentFilters,
                        filters: filters,
                        propertyType: property.sendAs,
                        selectedPropertyIds: selectedPropertyIds,
                        replacementProperty: replacementProperty,
                        setReplacementProperty: match$1[1]
                      }), React.createElement(Spacer.make, {
                        height: 24
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.justifyContent("center"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(Button.make, {
                            label: "Cancel",
                            onClick: (function (param) {
                                Curry._1(onClose, undefined);
                              }),
                            style: "ghost"
                          }), React.createElement(Spacer.make, {
                            width: 16
                          }), React.createElement(Button.make, {
                            disabled: Belt_Option.isNone(replacementProperty),
                            label: "Archive and Replace",
                            onClick: (function (param) {
                                if (replacementProperty === undefined) {
                                  return ;
                                }
                                var propertiesToReplace = Curry._1(TrackingPlanMappedModel.Properties.fromArray, Belt_Array.keepMapU(selectedPropertyIds, (function (selectedPropertyId) {
                                            return Curry._2(TrackingPlanMappedModel.Properties.get, properties, selectedPropertyId);
                                          })));
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "ConfirmModal",
                                        VAL: [
                                          "Replace and archive property",
                                          "Are you sure you would like to archive " + StringExt.joinToSentence(Curry._2(TrackingPlanMappedModel.Properties.mapToArray, propertiesToReplace, (function (param) {
                                                      return param.name;
                                                    }))) + " and replace it with " + replacementProperty.name + "",
                                          "Replace and archive",
                                          (function (param) {
                                              Curry.app(sendActions, [
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    ModelUtils_mapped.getActionsToReplacePropertiesInTrackingPlan(events, properties, selectedPropertyIds, replacementProperty)
                                                  ]);
                                              Router.Schema.pushDrawerItem(undefined, undefined, {
                                                    NAME: "property",
                                                    VAL: [
                                                      replacementProperty.id,
                                                      undefined
                                                    ]
                                                  });
                                              Curry._1(onClose, undefined);
                                            }),
                                          (function (param) {
                                              
                                            })
                                        ]
                                      }
                                    });
                              })
                          }))) : React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.padding(Css.px(25)),
                          tl: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: {
                              hd: Css.color(Styles.Color.red),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, "Property not found."));
}

var make = MergePropertiesModal;

export {
  make ,
}
/* Css Not a pure module */
