// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Title from "../Title.mjs";
import * as React from "react";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "../Markdown.mjs";
import * as AvoLimits from "../AvoLimits.mjs";
import * as IconError from "../IconError.mjs";
import * as NameUtils from "../../../shared/utils/NameUtils.mjs";
import * as StringExt from "../StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "../ModelUtils.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Discrepancies from "../Discrepancies.mjs";
import * as FirebaseUtils from "../firebaseUtils.mjs";
import * as IconCheckmark from "../IconCheckmark.mjs";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as ModalCloseButton from "../ModalCloseButton.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ObjectActivityLog from "../ObjectActivityLog.mjs";
import * as DiscrepancyContext from "../DiscrepancyContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as ValidationConfigV2 from "./ValidationConfigV2.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as GuardRailsConfigUtils from "./GuardRailsConfigUtils.mjs";
import * as NameConventionsConfig from "./NameConventionsConfig.mjs";
import * as GuardRailsConfigComponents from "./GuardRailsConfigComponents.mjs";

function pointOfBlockToAnalytics(pointOfBlock) {
  if (pointOfBlock === "beforeRequestForReview") {
    return "ReadyForReview";
  } else {
    return "BranchMerge";
  }
}

function container(isBeneath) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("column"),
                  tl: {
                    hd: Css.overflow("auto"),
                    tl: {
                      hd: Css.maxWidth(Css.px(1200)),
                      tl: {
                        hd: Css.height(Css.pct(100)),
                        tl: {
                          hd: Css.paddingTop(Css.px(48)),
                          tl: {
                            hd: Css.paddingRight(Css.px(60)),
                            tl: {
                              hd: Css.paddingBottom(Css.px(48)),
                              tl: {
                                hd: Css.paddingLeft(Css.px(60)),
                                tl: {
                                  hd: Css.backgroundColor(isBeneath ? Styles.Color.light04 : Styles.Color.light01),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: 300.0
                                        }, undefined, undefined, "background-color"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var docsLink = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.primaryPurpleShade1),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.transition({
                  NAME: "ms",
                  VAL: Styles.Duration.$$short
                }, undefined, undefined, "color"),
            tl: {
              hd: Css.hover({
                    hd: Css.textDecoration("underline"),
                    tl: /* [] */0
                  }),
              tl: /* [] */0
            }
          }
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.paddingTop(Css.px(40)),
        tl: /* [] */0
      }
    });

var config = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(14)),
      tl: /* [] */0
    });

var sectionHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("flexStart"),
        tl: {
          hd: Css.marginBottom(Css.px(24)),
          tl: /* [] */0
        }
      }
    });

var popupBox = Curry._1(Css.style, {
      hd: Css.alignSelf("flexStart"),
      tl: {
        hd: Css.border(Css.px(2), "solid", Styles.Color.light04),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: {
            hd: Css.marginLeft(Css.px(40)),
            tl: /* [] */0
          }
        }
      }
    });

var sidebar = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: /* [] */0
      }
    });

function GuardRailsConfigView$AuditRuleHeader(Props) {
  return React.createElement("div", {
              className: sectionHeader
            }, React.createElement($$Text.make, {
                  size: "Large",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "Audit rules"
                }));
}

function GuardRailsConfigView$BranchBlockingLocation(Props) {
  var pointOfBlock = Props.pointOfBlock;
  var configUpgradePrompt = Props.configUpgradePrompt;
  var handleBlockingLocationChange = Props.handleBlockingLocationChange;
  var viewerCanEdit = Props.viewerCanEdit;
  var enforceAvailability = Props.enforceAvailability;
  var enforcePlanHint = Props.enforcePlanHint;
  var globalSend = GlobalSendContext.use(undefined);
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: GuardRailsConfigComponents.Style.row
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light11,
                      children: "Branch blocking location"
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(Select.make, {
                      disabled: !viewerCanEdit,
                      onSelect: (function (selected) {
                          if (enforceAvailability === "Unavailable") {
                            return Curry._1(globalSend, configUpgradePrompt);
                          } else {
                            return Curry._1(handleBlockingLocationChange, Belt_Option.getWithDefault(ValidationConfigV2.PointOfBlock.tFromJs(selected), pointOfBlock));
                          }
                        }),
                      options: Belt_Array.map(ValidationConfigV2.PointOfBlock.options, (function (blockLocation) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: ValidationConfigV2.PointOfBlock.toUILabel(blockLocation)
                                      },
                                      ValidationConfigV2.PointOfBlock.tToJs(blockLocation)
                                    ];
                            })),
                      value: ValidationConfigV2.PointOfBlock.tToJs(pointOfBlock)
                    }), React.createElement(Spacer.make, {
                      width: 8
                    }), React.createElement(EntityLightning.make, {
                      availability: enforceAvailability,
                      planHint: enforcePlanHint,
                      fill: Styles.Color.light08
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(320)),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      color: Styles.Color.light10,
                      children: StringExt.widowless("Blocks the " + (
                            pointOfBlock === "beforeMerge" ? "branch from being merged" : "user from requesting a review"
                          ) + " if any branch changes are not compliant with rules that are enforced.")
                    })));
}

function GuardRailsConfigView(Props) {
  var onClose = Props.onClose;
  var overlay = Props.overlay;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var openBranches = Props.openBranches;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var warningCount = match.warningCount;
  var config$1 = match.config;
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var hasBasicNameCasing = ValidationConfigV2.EventsV2.hasBasicNameCasing(config$1.events);
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(config$1.events);
  var issueReportEventCasing = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents);
  var issueReportEventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var configAvailability = AvoLimits.DiscrepancyConfig.computeAvailability(workspace);
  var configPlanHint = AvoLimits.DiscrepancyConfig.availableOnPlan(workspace);
  var canForceBranchAudit = AvoLimits.ForceBranchAudit.computeAvailability(workspace);
  var forceBranchAuditPlanHint = AvoLimits.ForcePreferredCasing.availableOnPlan(workspace);
  var viewerCanEdit = viewerRole === "Admin";
  var issueReportConfigsOn = AnalyticsUtils.configToConfigsOnAnalytics(config$1);
  var numIssueReportConfigsOn = issueReportConfigsOn.length;
  var match$1 = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.computeAvailability, workspace);
  var match$2 = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.CustomStructs.computeAvailability, workspace);
  var eventNamingConventionsAvailability = match$1 === "AvailableDuringTrial" || match$1 === "Available" ? (
      match$2 === "AvailableDuringTrial" || match$2 === "Available" ? "CustomFramework" : "PredefinedFrameworkWithPredefinedValues"
    ) : "PredefinedFramework";
  var advancedNameConfigProperties = AnalyticsUtils.getAdvancedNameConfigProperties(ValidationConfigV2.EventsV2.getNameComponents(config$1.events));
  var advancedEventNameStructureComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesAsString, undefined);
  var advancedEventNameStructureComponentConfig = Curry._1(advancedNameConfigProperties.getComponentTypesAsString, undefined);
  var advancedEventNameComponentCount = Curry._1(advancedNameConfigProperties.getComponentCount, undefined);
  var advancedEventNameComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesIncludingSeparators, undefined);
  var advancedEventNameComponentTypes = Curry._1(advancedNameConfigProperties.getComponentTypesAsArray, undefined);
  var advancedEventNameComponentCasings = Curry._1(advancedNameConfigProperties.getComponentCasings, undefined);
  React.useEffect((function () {
          AnalyticsRe.issueReportConfigOpened(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), numIssueReportConfigsOn, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, AnalyticsUtils.roleToAnalyticsRole(viewerRole), schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var handleRuleChange = function (isUpdatingAdvancedConfigOpt, sendActions, configItem, rule, updatedConfig) {
    var isUpdatingAdvancedConfig = isUpdatingAdvancedConfigOpt !== undefined ? isUpdatingAdvancedConfigOpt : false;
    var action = GuardRailsConfigUtils.ruleToAnalyticsAction(rule);
    FirebaseUtils.setValidationConfigV2(workspace.id, ValidationConfigV2.encode(updatedConfig), configItem, rule.severity, undefined, sendActions);
    var advancedNameConfigProperties = AnalyticsUtils.getAdvancedNameConfigProperties(ValidationConfigV2.EventsV2.getNameComponents(updatedConfig.events));
    var advancedEventNameStructureComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesAsString, undefined);
    var advancedEventNameStructureComponentConfig = Curry._1(advancedNameConfigProperties.getComponentTypesAsString, undefined);
    var advancedEventNameComponentCount = Curry._1(advancedNameConfigProperties.getComponentCount, undefined);
    var advancedEventNameComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesIncludingSeparators, undefined);
    var advancedEventNameComponentTypes = Curry._1(advancedNameConfigProperties.getComponentTypesAsArray, undefined);
    var advancedEventNameComponentCasings = Curry._1(advancedNameConfigProperties.getComponentCasings, undefined);
    if (advancedEventNameStructureComponentNames !== undefined && advancedEventNameStructureComponentConfig !== undefined && advancedEventNameComponentCount !== undefined && advancedEventNameComponentNames !== undefined && advancedEventNameComponentTypes !== undefined && advancedEventNameComponentCasings !== undefined && isUpdatingAdvancedConfig) {
      AnalyticsRe.issueReportConfiguredAdvancedEventNamingConvention(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents), action, AnalyticsUtils.roleToAnalyticsRole(viewerRole), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(config$1), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents));
    }
    var match = rule.value;
    if (typeof match === "object") {
      var variant = match.NAME;
      if (!(variant === "nameComponents" || variant === "blockedValues" || variant === "allowedValues")) {
        var casingAnalytics = GuardRailsConfigUtils.caseToAnalytics(match.VAL);
        return AnalyticsRe.issueReportConfiguredCasing(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), AnalyticsUtils.configToConfigsOnAnalytics(config$1).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents), ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(viewerRole), casingAnalytics, schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(config$1), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents));
      }
      
    }
    AnalyticsRe.issueReportConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), AnalyticsUtils.configToConfigsOnAnalytics(config$1).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents), ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(viewerRole), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(config$1), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents));
  };
  var eventNames = ModelUtils.getEventNames(model.events);
  var commonEventNameCase = NameUtils.getCommonCase(eventNames);
  var propertyNames = ModelUtils.getPropertyNames(model.properties);
  var commonPropertyNameCase = NameUtils.getCommonCase(propertyNames);
  var match$3 = React.useState(function () {
        return overlay;
      });
  var setOverlay = match$3[1];
  var overlay$1 = match$3[0];
  var hasConsistentCase = React.useMemo((function () {
          return ValidationConfigV2.EventsV2.getCase(config$1.events) !== "hybrid";
        }), [config$1.events.nameComponents]);
  var match$4 = ValidationConfigV2.EventsV2.getRuleComplexity(config$1.events);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: container(Belt_Option.isSome(overlay$1)),
                  id: "audit-tour-config"
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.top(Css.px(16)),
                              tl: {
                                hd: Css.right(Css.px(16)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(ModalCloseButton.make, {
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            })
                        })), React.createElement(Title.make, {
                      children: "Tracking Plan Audit Config",
                      size: "Medium"
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement($$Text.make, {
                      size: "Large",
                      color: Styles.Color.light10,
                      maxWidth: Css.px(480),
                      children: null
                    }, "Avo validates your tracking plan in real-time and audits branch changes to help you progress towards better data quality. Read more in the ", React.createElement("a", {
                          className: docsLink,
                          href: "https://www.avo.app/docs/audit/rules"
                        }, "docs"), "."), viewerCanEdit ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.orange,
                            children: "Only members with admin access can edit these workspace settings"
                          })), React.createElement("div", {
                      className: content
                    }, React.createElement("div", {
                          className: config
                        }, React.createElement(GuardRailsConfigView$AuditRuleHeader, {}), React.createElement(GuardRailsConfigComponents.GuardRailContainer.make, {
                              children: null
                            }, React.createElement(GuardRailsConfigComponents.GuardRailSection.make, {
                                  title: "Event rules",
                                  children: null,
                                  rightSection: React.createElement("div", {
                                        className: Curry._1(Css.merge, {
                                              hd: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("end_"),
                                                      tl: {
                                                        hd: Css.gap(Css.px(10)),
                                                        tl: {
                                                          hd: Css.marginRight(Css.px(-2)),
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    }
                                                  }),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Tiny",
                                            weight: "Semi",
                                            textAlign: "center",
                                            color: Styles.Color.light12,
                                            maxWidth: Css.px(40),
                                            children: "Enabled"
                                          }), React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Tiny",
                                            weight: "Semi",
                                            color: Styles.Color.light12,
                                            children: "Enforced"
                                          }))
                                }, React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "Unique event names",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.events.uniqueName,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonEventNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.events;
                                          handleRuleChange(undefined, sendActions, "UniqueEventNames", updatedRule, {
                                                events: {
                                                  nameComponents: init.nameComponents,
                                                  uniqueName: updatedRule,
                                                  description: init.description
                                                },
                                                properties: config$1.properties,
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "Checks that there is only one name being used for each event to avoid confusion and conflicts in downstream tools.",
                                      nameType: "simple"
                                    }), React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "All events have descriptions",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.events.description,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.events;
                                          handleRuleChange(undefined, sendActions, "AllEventsHaveDescriptions", updatedRule, {
                                                events: {
                                                  nameComponents: init.nameComponents,
                                                  uniqueName: init.uniqueName,
                                                  description: updatedRule
                                                },
                                                properties: config$1.properties,
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "A description helps the whole team understand the purpose or context of an event.",
                                      nameType: "simple"
                                    }), React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "No conflicting properties",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.properties.conflictsOnEvent,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.properties;
                                          handleRuleChange(undefined, sendActions, "NoConflictingProperties", updatedRule, {
                                                events: config$1.events,
                                                properties: {
                                                  case: init.case,
                                                  uniqueName: init.uniqueName,
                                                  description: init.description,
                                                  conflictsOnEvent: updatedRule,
                                                  anyType: init.anyType
                                                },
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "Checks that all properties with unique type definitions have a unique name.",
                                      nameType: "simple"
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.flexDirection("column"),
                                              tl: {
                                                hd: Css.gap(Css.px(12)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("flexStart"),
                                                  tl: {
                                                    hd: Css.gap(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: Css.display("flex"),
                                                    tl: {
                                                      hd: Css.alignItems("center"),
                                                      tl: {
                                                        hd: Css.gap(Css.px(2)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Medium",
                                                  color: Styles.Color.light12,
                                                  children: "Event naming conventions"
                                                }), React.createElement(Tooltip.Default.make, {
                                                  size: "small",
                                                  title: "Event naming conventions",
                                                  children: React.createElement(Tooltip.Content.make, {
                                                        children: React.createElement(Markdown.make, {
                                                              source: "Checks that event name casing is consistent across all events to maximize data accessibility."
                                                            })
                                                      })
                                                })), hasBasicNameCasing ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                                    width: 32,
                                                    grow: 1.0
                                                  }), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.display("flex"),
                                                          tl: /* [] */0
                                                        })
                                                  }, React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.position("relative"),
                                                              tl: /* [] */0
                                                            })
                                                      }, React.createElement(Switch.make, {
                                                            value: Curry._1(ValidationConfigV2.Rule.isChecked, config$1.events.nameComponents),
                                                            onChange: (function (_toggled) {
                                                                var init = config$1.events;
                                                                handleRuleChange(undefined, sendActions, "ConsistentEventNameCasing", ValidationConfigV2.Utils.toggleIsSet(config$1.events.nameComponents), {
                                                                      events: {
                                                                        nameComponents: ValidationConfigV2.Utils.toggleIsSet(config$1.events.nameComponents),
                                                                        uniqueName: init.uniqueName,
                                                                        description: init.description
                                                                      },
                                                                      properties: config$1.properties,
                                                                      pointOfBlock: config$1.pointOfBlock
                                                                    });
                                                              }),
                                                            disabled: !viewerCanEdit || Curry._1(ValidationConfigV2.Rule.isEnforced, config$1.events.nameComponents) && canForceBranchAudit === "Available"
                                                          })), React.createElement(Spacer.make, {
                                                        width: 16
                                                      }), React.createElement("div", {
                                                        className: Curry._1(Css.style, {
                                                              hd: Css.position("relative"),
                                                              tl: /* [] */0
                                                            })
                                                      }, React.createElement(Switch.make, {
                                                            value: Curry._1(ValidationConfigV2.Rule.isEnforced, config$1.events.nameComponents),
                                                            onChange: (function (toggled) {
                                                                if (canForceBranchAudit === "Unavailable") {
                                                                  return Curry._1(globalSend, {
                                                                              TAG: /* OpenModal */4,
                                                                              _0: {
                                                                                NAME: "BillingPrompt",
                                                                                VAL: "IssueReportConfig"
                                                                              }
                                                                            });
                                                                }
                                                                var init = config$1.events;
                                                                handleRuleChange(undefined, sendActions, "ConsistentEventNameCasing", ValidationConfigV2.Utils.toggleSeverity(config$1.events.nameComponents, toggled), {
                                                                      events: {
                                                                        nameComponents: ValidationConfigV2.Utils.toggleSeverity(config$1.events.nameComponents, toggled),
                                                                        uniqueName: init.uniqueName,
                                                                        description: init.description
                                                                      },
                                                                      properties: config$1.properties,
                                                                      pointOfBlock: config$1.pointOfBlock
                                                                    });
                                                              }),
                                                            faded: canForceBranchAudit === "Unavailable",
                                                            disabled: !viewerCanEdit || !Curry._1(ValidationConfigV2.Rule.isChecked, config$1.events.nameComponents)
                                                          }), React.createElement("div", {
                                                            className: Curry._1(Css.style, {
                                                                  hd: Css.position("absolute"),
                                                                  tl: {
                                                                    hd: Css.top(Css.px(2)),
                                                                    tl: {
                                                                      hd: Css.right(Css.px(-16)),
                                                                      tl: /* [] */0
                                                                    }
                                                                  }
                                                                })
                                                          }, React.createElement(EntityLightning.make, {
                                                                availability: canForceBranchAudit,
                                                                planHint: configPlanHint,
                                                                fill: Styles.Color.light08
                                                              })))))), React.createElement(GuardRailsConfigComponents.GuardRailSubSection.make, {
                                          children: match$4.NAME === "advanced" ? React.createElement(GuardRailsConfigComponents.AdvancedNameConventions.make, {
                                                  nameComponents: match$4.VAL,
                                                  onAdvancedClick: (function (param) {
                                                      Curry._1(setOverlay, (function (param) {
                                                              return "eventNamingConventions";
                                                            }));
                                                    })
                                                }) : React.createElement(GuardRailsConfigComponents.BasicNameConventions.make, {
                                                  canForceBranchAudit: canForceBranchAudit,
                                                  commonEventNameCase: commonEventNameCase,
                                                  config: config$1,
                                                  configAvailability: "Available",
                                                  configPlanHint: configPlanHint,
                                                  forceBranchAuditPlanHint: forceBranchAuditPlanHint,
                                                  handleRuleChange: (function (analyticsItem, rule, updatedConfig) {
                                                      handleRuleChange(undefined, sendActions, analyticsItem, rule, updatedConfig);
                                                    }),
                                                  rule: match$4.VAL,
                                                  viewerCanEdit: viewerCanEdit,
                                                  onAdvancedClick: (function (param) {
                                                      Curry._1(setOverlay, (function (param) {
                                                              return "eventNamingConventions";
                                                            }));
                                                    })
                                                })
                                        }))), React.createElement(GuardRailsConfigComponents.GuardRailSection.make, {
                                  title: "Property rules",
                                  children: null
                                }, React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "Unique property names",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.properties.uniqueName,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.properties;
                                          handleRuleChange(undefined, sendActions, "UniquePropertyNames", updatedRule, {
                                                events: config$1.events,
                                                properties: {
                                                  case: init.case,
                                                  uniqueName: updatedRule,
                                                  description: init.description,
                                                  conflictsOnEvent: init.conflictsOnEvent,
                                                  anyType: init.anyType
                                                },
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "Checks that there is only one name being used for each property to avoid confusion and conflicts in downstream tools.",
                                      nameType: "simple"
                                    }), React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "All properties have types",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.properties.anyType,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.properties;
                                          handleRuleChange(undefined, sendActions, "AllPropertiesHaveTypes", updatedRule, {
                                                events: config$1.events,
                                                properties: {
                                                  case: init.case,
                                                  uniqueName: init.uniqueName,
                                                  description: init.description,
                                                  conflictsOnEvent: init.conflictsOnEvent,
                                                  anyType: updatedRule
                                                },
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "Checks that each property has at least it’s base type defined. Well defined types for all property values are a major time-saver when querying data and help avoid unexpected problems.",
                                      nameType: "simple"
                                    }), React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "All properties have descriptions",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.properties.description,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.properties;
                                          handleRuleChange(undefined, sendActions, "AllPropertiesHaveDescriptions", updatedRule, {
                                                events: config$1.events,
                                                properties: {
                                                  case: init.case,
                                                  uniqueName: init.uniqueName,
                                                  description: updatedRule,
                                                  conflictsOnEvent: init.conflictsOnEvent,
                                                  anyType: init.anyType
                                                },
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "A description helps the whole team understand what the property is describing, and how it's value should be fetched.",
                                      nameType: "simple"
                                    }), React.createElement(GuardRailsConfigComponents.GuardRailRow.make, {
                                      name: "Property naming conventions",
                                      editAvailability: configAvailability,
                                      editPlanHint: configPlanHint,
                                      enforceAvailability: canForceBranchAudit,
                                      enforcePlanHint: forceBranchAuditPlanHint,
                                      viewerCanEdit: viewerCanEdit,
                                      rule: config$1.properties.case,
                                      configUpgradePrompt: {
                                        TAG: /* OpenModal */4,
                                        _0: {
                                          NAME: "BillingPrompt",
                                          VAL: "IssueReportConfig"
                                        }
                                      },
                                      commonNameCase: commonPropertyNameCase,
                                      handleChange: (function (updatedRule) {
                                          var init = config$1.properties;
                                          handleRuleChange(undefined, sendActions, "ConsistentPropertyNameCasing", updatedRule, {
                                                events: config$1.events,
                                                properties: {
                                                  case: updatedRule,
                                                  uniqueName: init.uniqueName,
                                                  description: init.description,
                                                  conflictsOnEvent: init.conflictsOnEvent,
                                                  anyType: init.anyType
                                                },
                                                pointOfBlock: config$1.pointOfBlock
                                              });
                                        }),
                                      tooltipMarkdown: "Checks that property name casing is consistent across all properties to maximize data accessibility.",
                                      nameType: "simple"
                                    })), React.createElement(GuardRailsConfigComponents.GuardRailSection.make, {
                                  title: "Branch rules",
                                  children: React.createElement(GuardRailsConfigView$BranchBlockingLocation, {
                                        pointOfBlock: config$1.pointOfBlock,
                                        configUpgradePrompt: {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "BillingPrompt",
                                            VAL: "IssueReportConfig"
                                          }
                                        },
                                        handleBlockingLocationChange: (function (pointOfBlock) {
                                            var updatedConfig = {
                                              events: config$1.events,
                                              properties: config$1.properties,
                                              pointOfBlock: pointOfBlock
                                            };
                                            FirebaseUtils.setValidationConfigV2(workspace.id, ValidationConfigV2.encode(updatedConfig), "BranchEnforcementStatus", undefined, updatedConfig.pointOfBlock, sendActions);
                                            AnalyticsRe.issueReportConfiguredBranchBlocking(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(config$1), AnalyticsUtils.configToConfigsOnAnalytics(config$1).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, AnalyticsUtils.roleToAnalyticsRole(viewerRole), pointOfBlockToAnalytics(updatedConfig.pointOfBlock), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(config$1), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(nameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, config$1.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(nameComponents));
                                          }),
                                        viewerCanEdit: viewerCanEdit,
                                        enforceAvailability: canForceBranchAudit,
                                        enforcePlanHint: forceBranchAuditPlanHint
                                      })
                                }))), React.createElement("div", {
                          className: sidebar
                        }, React.createElement("div", {
                              className: popupBox
                            }, React.createElement(Discrepancies.Popup.make, {
                                  discrepancies: match.discrepancies,
                                  hasConsistentCase: hasConsistentCase,
                                  schemaBundle: schemaBundle,
                                  warningCount: warningCount,
                                  headerExtra: warningCount === 0 ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.alignSelf("flexEnd"),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(4)),
                                                  tl: {
                                                    hd: Css.paddingRight(Css.px(6)),
                                                    tl: {
                                                      hd: Css.paddingBottom(Css.px(4)),
                                                      tl: {
                                                        hd: Css.paddingLeft(Css.px(6)),
                                                        tl: {
                                                          hd: Css.borderRadius(Css.px(16)),
                                                          tl: {
                                                            hd: Css.backgroundColor(Styles.Color.green),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, React.createElement(IconCheckmark.make, {
                                              size: 10,
                                              color: Styles.Color.white
                                            })) : React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignSelf("flexEnd"),
                                                  tl: {
                                                    hd: Css.borderRadius(Css.px(16)),
                                                    tl: {
                                                      hd: Css.paddingTop(Css.px(2)),
                                                      tl: {
                                                        hd: Css.paddingRight(Css.px(6)),
                                                        tl: {
                                                          hd: Css.paddingBottom(Css.px(2)),
                                                          tl: {
                                                            hd: Css.paddingLeft(Css.px(6)),
                                                            tl: {
                                                              hd: Css.backgroundColor(Styles.Color.orange),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, React.createElement(IconError.make, {
                                              size: 11,
                                              color: Styles.Color.white
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              color: Styles.Color.white,
                                              children: String(warningCount)
                                            })),
                                  onNavigate: (function (item, discrepancy) {
                                      var variant = discrepancy.NAME;
                                      AnalyticsRe.auditIssueInteracted(item.id, item.name, item.type_, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Sidebar", "OpenIssueItem", variant === "SameEventName" ? "SameNameEvents" : (
                                              variant === "SamePropertyName" ? "SameNameProperty" : (
                                                  variant === "PropertiesWithAnyType" ? "PropertyTypeNotDefined" : (
                                                      variant === "EventsWithoutDescription" ? "EventMissingDescription" : (
                                                          variant === "EventsWithoutExpectedValues" ? "EventAdvancedNamingConventionDiscrepancy" : (
                                                              variant === "EventsWithUnexpectedCase" ? "EventCommonCasingDiscrepancy" : (
                                                                  variant === "PropertyConflictOnEvent" ? "PropertyConflicts" : (
                                                                      variant === "PropertiesWithoutDescription" ? "PropertyMissingDescription" : "PropertyCommonCasingDiscrepancy"
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            ), schemaBundle.schemaId, schemaBundle.branchId);
                                      Curry._1(onClose, undefined);
                                    }),
                                  expectedEventCase: match.expectedEventCase,
                                  expectedPropertyCase: match.expectedPropertyCase
                                })), React.createElement(Spacer.make, {
                              height: 16
                            }), React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.minWidth(Css.px(460)),
                                    tl: {
                                      hd: Css.maxWidth(Css.px(460)),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement(ObjectActivityLog.make, {
                                  object_: "WorkspaceSetting",
                                  objectId: "trackingPlanAudit",
                                  currentBranch: "Master",
                                  openBranches: openBranches,
                                  pageSize: 10
                                }))))), React.createElement(NameConventionsConfig.make, {
                  mount: Caml_obj.equal(overlay$1, "eventNamingConventions"),
                  onClose: (function (param) {
                      Curry._1(setOverlay, (function (param) {
                              
                            }));
                    }),
                  onSave: (function (nameComponents) {
                      var cleanedNameComponents = Belt_Array.map(nameComponents, ValidationConfigV2.NameComponent.clean);
                      var init = config$1.events;
                      var init$1 = config$1.events.nameComponents;
                      handleRuleChange(true, sendActions, "ConsistentEventNameCasing", ValidationConfigV2.NameComponent.getSimplestNameCasing(cleanedNameComponents), {
                            events: {
                              nameComponents: {
                                value: {
                                  NAME: "nameComponents",
                                  VAL: cleanedNameComponents
                                },
                                severity: init$1.severity
                              },
                              uniqueName: init.uniqueName,
                              description: init.description
                            },
                            properties: config$1.properties,
                            pointOfBlock: config$1.pointOfBlock
                          });
                    }),
                  viewerRole: viewerRole
                }));
}

var make = GuardRailsConfigView;

export {
  make ,
}
/* docsLink Not a pure module */
