// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"unified":"unified","split":"split","noDiff":"noDiff"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toAnalyticsDiffType(diffViewType) {
  if (diffViewType === "noDiff") {
    return "NoDiff";
  } else if (diffViewType === "unified") {
    return "Unified";
  } else {
    return "Split";
  }
}

var DiffViewType = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  toAnalyticsDiffType: toAnalyticsDiffType
};

export {
  DiffViewType ,
}
/* No side effect */
