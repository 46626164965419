// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Workspace from "../../model/src/Workspace.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as StripeModels from "../../shared/models/StripeModels.mjs";

function decode(json) {
  if (Json_decode.field("provider", Json_decode.string, json) === "orb") {
    return {
            TAG: /* Orb */0,
            _0: {
              plan: Belt_Option.getWithDefault(Workspace.Instances.planNameFromString(Json_decode.field("plan", Json_decode.string, json)), /* FreeV3 */10),
              customerId: Json_decode.field("customerId", Json_decode.string, json),
              subscriptionId: Json_decode.field("subscriptionId", Json_decode.string, json),
              planId: Json_decode.field("planId", Json_decode.string, json),
              schemaId: Json_decode.field("schemaId", Json_decode.string, json),
              portalUrl: Json_decode.field("portalUrl", Json_decode.string, json),
              active: Json_decode.field("active", Json_decode.bool, json)
            }
          };
  } else {
    return {
            TAG: /* Stripe */1,
            _0: {
              cancelUrl: Json_decode.optional((function (param) {
                      return Json_decode.field("cancelUrl", Json_decode.string, param);
                    }), json),
              schemaId: Json_decode.field("schemaId", Json_decode.string, json),
              subscriptionPlanId: Json_decode.optional((function (param) {
                      return Json_decode.field("subscriptionPlanId", Json_decode.string, param);
                    }), json),
              updateUrl: Json_decode.optional((function (param) {
                      return Json_decode.field("updateUrl", Json_decode.string, param);
                    }), json),
              userSubscriptionId: Json_decode.optional((function (param) {
                      return Json_decode.field("userSubscriptionId", Json_decode.string, param);
                    }), json),
              subscription: Json_decode.field("subscription", StripeModels.Subscription.decode, json),
              active: Json_decode.field("active", Json_decode.bool, json)
            }
          };
  }
}

export {
  decode ,
}
/* Workspace Not a pure module */
