// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconInfinity(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              height: String(size),
              width: String(size),
              fill: "none",
              viewBox: "0 0 24 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 6C0 2.70903 2.63 0 5.90053 0C8.22967 0 9.94741 1.204 11.112 2.35784C11.3799 2.6124 11.5938 2.85028 11.798 3.08799C11.8507 3.14935 11.9028 3.21071 11.955 3.27235L11.9912 3.31512L13.9651 6.00006C14.2563 6.42147 14.6056 6.86294 15.0909 7.34454C15.0909 7.34455 15.0909 7.34454 15.0909 7.34454C15.9449 8.16729 16.9155 8.74916 18.1092 8.74916C19.6134 8.74916 20.8265 7.53512 20.8265 6C20.8265 4.50502 19.6134 3.25084 18.1092 3.25084C16.9155 3.25084 15.8674 3.90301 15.091 4.69565C14.9297 4.852 14.7684 5.02759 14.6184 5.20035L12.7766 2.69523L12.7456 2.65857C12.7197 2.62802 12.6936 2.59718 12.6672 2.56605C12.733 2.49891 12.8029 2.42978 12.8783 2.35786C14.0526 1.18395 15.8188 0 18.1092 0C21.37 0 24 2.70903 24 6C24 9.33111 21.37 12 18.1092 12C15.7897 12 14.072 10.8361 12.9074 9.72241C12.5192 9.34114 12.2572 9.0301 12.0049 8.70903L10.0347 6.0001C10.0347 6.00007 10.0347 6.00013 10.0347 6.0001C9.76297 5.62886 9.29721 5.06689 8.90902 4.69565C8.10352 3.89298 7.07481 3.25084 5.90053 3.25084C4.38658 3.25084 3.16377 4.50502 3.16377 6C3.16377 7.53512 4.38658 8.74916 5.90053 8.74916C7.07481 8.74916 8.02588 8.18729 8.90902 7.34448C9.09203 7.16599 9.25415 6.99257 9.39889 6.82604L11.207 9.31233L11.2185 9.327C11.2559 9.37459 11.2937 9.42219 11.3321 9.46999C11.2566 9.55119 11.1773 9.63485 11.0926 9.72241C9.96684 10.806 8.22968 12 5.90053 12C2.63 12 0 9.33111 0 6Z",
                  fill: Styles.Color.toString(color)
                }));
}

var make = IconInfinity;

export {
  make ,
}
/* react Not a pure module */
