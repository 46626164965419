// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Styles from "./styles.mjs";

function IconWarning(Props) {
  var colorOpt = Props.color;
  var sizeOpt = Props.size;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.orange;
  var size = sizeOpt !== undefined ? sizeOpt : 14;
  return React.createElement("svg", {
              height: String(Math.imul(size, 12) / 14 | 0),
              width: String(size),
              fill: "none",
              viewBox: "0 0 14 12",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M6.02186 0.545454C6.45659 -0.181819 7.54341 -0.181818 7.97814 0.545455L13.847 10.3636C14.2817 11.0909 13.7383 12 12.8689 12H1.13115C0.261686 12 -0.281726 11.0909 0.153004 10.3636L6.02186 0.545454ZM5.79852 3.7911C5.77312 3.63872 5.89063 3.5 6.04511 3.5H7.95489C8.10937 3.5 8.22688 3.63872 8.20148 3.7911L7.53482 7.7911C7.51473 7.91165 7.41043 8 7.28822 8H6.71178C6.58957 8 6.48527 7.91165 6.46518 7.7911L5.79852 3.7911ZM6.5 9C6.36193 9 6.25 9.11193 6.25 9.25V10.25C6.25 10.3881 6.36193 10.5 6.5 10.5H7.5C7.63807 10.5 7.75 10.3881 7.75 10.25V9.25C7.75 9.11193 7.63807 9 7.5 9H6.5Z",
                  fill: Styles.Color.toString(color),
                  fillRule: "evenodd"
                }));
}

var make = IconWarning;

export {
  make ,
}
/* react Not a pure module */
