// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as CopyButton from "../CopyButton.mjs";

function code(docsCodeBoxStyles, docsCodeStyles, docsCodeLightBlueStyles, docsCodeMintGreenStyles, docsCodeOrangeStyles, copyButtonBoxStyles, apiKey, language) {
  if (language === "dart") {
    return React.createElement("div", {
                className: docsCodeBoxStyles
              }, React.createElement("pre", {
                    className: docsCodeStyles
                  }, React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "AvoInspector "), "avoInspector = ", React.createElement("span", {
                        className: docsCodeLightBlueStyles
                      }, "await"), React.createElement("span", {
                        className: docsCodeMintGreenStyles
                      }, " AvoInspector.create"), "(\n", "    apiKey: ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"" + apiKey + "\""), ",\n    env: ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "AvoInspectorEnv.dev"), ",\n    appVersion: ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"1.0\""), ",\n    appName: ", React.createElement("span", {
                        className: docsCodeOrangeStyles
                      }, "\"Hello Flutter\""), ");"), React.createElement("div", {
                    className: copyButtonBoxStyles
                  }, React.createElement(CopyButton.make, {
                        copyString: "AvoInspector avoInspector = await AvoInspector.create(apiKey: \"" + apiKey + "\", env: AvoInspectorEnv.dev, appVersion: \"1.0\", appName: \"Hello Flutter\");"
                      })));
  } else {
    return null;
  }
}

export {
  code ,
}
/* react Not a pure module */
