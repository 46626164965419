// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterStore from "./RouterStore.mjs";
import * as FramerMotion from "framer-motion";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as LearnHub__Content from "./LearnHub__Content.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var container = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.bottom(Css.px(20)),
        tl: {
          hd: Css.right(Css.px(76)),
          tl: {
            hd: Css.zIndex(Styles.ZIndex.aboveAll),
            tl: /* [] */0
          }
        }
      }
    });

var bubble = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.bottom(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.justifyContent("center"),
                tl: {
                  hd: Css.width(Css.px(48)),
                  tl: {
                    hd: Css.height(Css.px(48)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.primaryPurple),
                      tl: {
                        hd: Css.borderRadius(Css.pct(100)),
                        tl: {
                          hd: Css.cursor("pointer"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var panel = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.bottom(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.padding(Css.px(24)),
            tl: {
              hd: Css.width(Css.px(320)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.light01),
                tl: {
                  hd: Css.boxShadow(Styles.Shadow.standard),
                  tl: {
                    hd: Css.borderRadius(Css.px(16)),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var panelHeader = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: /* [] */0
        }
      }
    });

var panelBody = Curry._1(Css.style, {
      hd: Css.marginTop(Css.px(16)),
      tl: {
        hd: Css.marginBottom(Css.px(24)),
        tl: /* [] */0
      }
    });

var panelButtons = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: {
            hd: Css.color(Styles.Color.primaryPurple),
            tl: /* [] */0
          }
        }
      }
    });

function docsLink(primary) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.textDecoration("none"),
                    tl: {
                      hd: Css.width(Css.px(132)),
                      tl: {
                        hd: Css.padding4(Css.px(primary ? 3 : 4), Css.px(15), Css.px(primary ? 3 : 4), Css.px(15)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", primary ? "transparent" : Styles.Color.light04),
                            tl: {
                              hd: Css.color(primary ? Styles.Color.white : Styles.Color.primaryPurple),
                              tl: {
                                hd: Css.backgroundColor(primary ? Styles.Color.primaryPurple : "transparent"),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.borderColor(primary ? "transparent" : Styles.Color.light07),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.transitionList({
                                          hd: Css_Legacy_Core.Transition.shorthand({
                                                NAME: "ms",
                                                VAL: Styles.Duration.$$short
                                              }, undefined, undefined, "all"),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  container: container,
  bubble: bubble,
  panel: panel,
  panelHeader: panelHeader,
  panelBody: panelBody,
  panelButtons: panelButtons,
  docsLink: docsLink
};

function LearnHub(Props) {
  var workspace = WorkspaceContext.use(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useState(function () {
        var match = LearnHub__Content.getContent(schemaRoute);
        var match$1 = workspace.isSandbox;
        if (match !== undefined) {
          if (match$1) {
            return "Open";
          } else {
            return "Closed";
          }
        } else {
          return "Hidden";
        }
      });
  var setPanelState = match[1];
  var panelState = match[0];
  var match$1 = React.useState(function () {
        return LearnHub__Content.getContent(schemaRoute);
      });
  var setContent = match$1[1];
  var maybeContent = match$1[0];
  var prevSchemaRoute = Hooks.usePrevious1(schemaRoute);
  React.useEffect((function () {
          if (maybeContent !== undefined && (panelState === "Open" || panelState === "Closed")) {
            AnalyticsRe.learnHubSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, panelState, maybeContent.title, Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
          }
          
        }), []);
  var timeoutId = React.useRef(undefined);
  React.useEffect((function () {
          var nextContent = LearnHub__Content.getContent(schemaRoute);
          Curry._1(setContent, (function (param) {
                  return nextContent;
                }));
          if (nextContent !== undefined) {
            if (workspace.isSandbox && Caml_obj.notequal(schemaRoute, prevSchemaRoute) && panelState !== "Open") {
              Belt_Option.forEach(timeoutId.current, (function (timeoutId) {
                      clearTimeout(timeoutId);
                    }));
              timeoutId.current = Caml_option.some(setTimeout((function (param) {
                          Curry._1(setPanelState, (function (param) {
                                  return "Open";
                                }));
                        }), schemaRoute === "dashboard" ? 5000 : 400));
            } else if (!workspace.isSandbox && Caml_obj.notequal(schemaRoute, prevSchemaRoute)) {
              Curry._1(setPanelState, (function (param) {
                      return "Closed";
                    }));
            }
            
          } else {
            Curry._1(setPanelState, (function (param) {
                    return "Hidden";
                  }));
          }
          var nextPanelState = nextContent !== undefined ? (
              workspace.isSandbox && Caml_obj.notequal(schemaRoute, prevSchemaRoute) ? "Open" : (
                  !workspace.isSandbox && Caml_obj.notequal(schemaRoute, prevSchemaRoute) ? "Closed" : undefined
                )
            ) : "Hidden";
          if (nextContent !== undefined && nextPanelState !== undefined && (nextPanelState === "Open" || nextPanelState === "Closed") && Caml_obj.notequal(schemaRoute, prevSchemaRoute)) {
            AnalyticsRe.learnHubSeen(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, nextPanelState, nextContent.title, Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
          }
          return Belt_Option.flatMap(timeoutId.current, (function (id) {
                        return (function (param) {
                                  clearTimeout(id);
                                });
                      }));
        }), [schemaRoute]);
  return React.createElement("div", {
              className: container
            }, React.createElement(FramerMotion.motion.div, {
                  animate: panelState === "Open" || panelState === "Hidden" ? "hidden" : "visible",
                  transition: {
                    type: "spring",
                    damping: 27,
                    stiffness: 400
                  },
                  initial: "hidden",
                  variants: {
                    visible: {
                      scale: 1,
                      opacity: 1
                    },
                    hidden: {
                      scale: 0,
                      opacity: 0
                    }
                  },
                  className: bubble,
                  onClick: (function (param) {
                      Curry._1(setPanelState, (function (param) {
                              return "Open";
                            }));
                      try {
                        var content = Belt_Option.getExn(maybeContent);
                        AnalyticsRe.learnHubInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Open", content.title, "Open", Belt_Array.concat(Belt_Option.mapWithDefault(content.docsLink, [], (function (param) {
                                        return ["ReadDocs"];
                                      })), Belt_Option.mapWithDefault(content.tour, [], (function (param) {
                                        return ["TakeTour"];
                                      }))), Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
                        return ;
                      }
                      catch (raw_error){
                        var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                        console.error("Error logging analytics in LearnHub", error);
                        return ;
                      }
                    }),
                  children: React.createElement(Icon.make, {
                        type_: "learnHubQuestionMark",
                        color: Styles.Color.white
                      })
                }), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: maybeContent !== undefined ? React.createElement(FramerMotion.motion.div, {
                          animate: panelState === "Open" ? "visible" : "hidden",
                          transition: {
                            type: "spring",
                            damping: 27,
                            stiffness: 400
                          },
                          initial: "hidden",
                          exit: "hidden",
                          variants: {
                            visible: {
                              scale: 1,
                              opacity: 1
                            },
                            hidden: {
                              scale: 0,
                              opacity: 0,
                              width: "48px",
                              height: "48px",
                              borderRadius: "100%",
                              backgroundColor: Styles.Color.primaryPurple
                            }
                          },
                          key: "panel",
                          children: React.createElement("div", {
                                key: maybeContent.title,
                                className: panel
                              }, React.createElement("div", {
                                    className: panelHeader
                                  }, React.createElement($$Text.make, {
                                        size: "Large",
                                        weight: "Semi",
                                        color: Styles.Color.light12,
                                        children: maybeContent.title
                                      }), React.createElement(Button.make, {
                                        icon: "chevronDown",
                                        onClick: (function (param) {
                                            Curry._1(setPanelState, (function (param) {
                                                    return "Closed";
                                                  }));
                                            AnalyticsRe.learnHubInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Closed", maybeContent.title, "Close", Belt_Array.concat(Belt_Option.mapWithDefault(maybeContent.docsLink, [], (function (param) {
                                                            return ["ReadDocs"];
                                                          })), Belt_Option.mapWithDefault(maybeContent.tour, [], (function (param) {
                                                            return ["TakeTour"];
                                                          }))), Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
                                          }),
                                        size: "small",
                                        style: "ghost"
                                      })), React.createElement("div", {
                                    className: panelBody
                                  }, maybeContent.body), React.createElement("div", {
                                    className: panelButtons
                                  }, Belt_Option.mapWithDefault(maybeContent.docsLink, null, (function (link) {
                                          return React.createElement($$Text.make, {
                                                      element: "Label",
                                                      size: "Small",
                                                      color: Styles.Color.primaryPurple,
                                                      children: React.createElement("a", {
                                                            className: docsLink(Belt_Option.isNone(maybeContent.tour)),
                                                            href: link,
                                                            rel: "noopener",
                                                            target: "_blank",
                                                            onClick: (function (param) {
                                                                AnalyticsRe.learnHubInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Open", maybeContent.title, "ReadDocs", Belt_Array.concat(Belt_Option.mapWithDefault(maybeContent.docsLink, [], (function (param) {
                                                                                return ["ReadDocs"];
                                                                              })), Belt_Option.mapWithDefault(maybeContent.tour, [], (function (param) {
                                                                                return ["TakeTour"];
                                                                              }))), Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
                                                              })
                                                          }, React.createElement("div", undefined, React.createElement(Spacer.make, {
                                                                    height: 3
                                                                  }), React.createElement(Icon.make, {
                                                                    type_: "book",
                                                                    size: "small",
                                                                    color: Belt_Option.isNone(maybeContent.tour) ? Styles.Color.white : Styles.Color.primaryPurple
                                                                  })), React.createElement(Spacer.make, {
                                                                width: 8
                                                              }), React.createElement($$Text.make, {
                                                                size: "Small",
                                                                weight: "Semi",
                                                                children: "Read docs"
                                                              }))
                                                    });
                                        })), React.createElement(Spacer.make, {
                                        width: 8
                                      }), Belt_Option.mapWithDefault(maybeContent.tour, null, (function (tourElement) {
                                          return Curry._1(tourElement, (function (param) {
                                                        AnalyticsRe.learnHubInteracted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "Open", maybeContent.title, "TakeTour", Belt_Array.concat(Belt_Option.mapWithDefault(maybeContent.docsLink, [], (function (param) {
                                                                        return ["ReadDocs"];
                                                                      })), Belt_Option.mapWithDefault(maybeContent.tour, [], (function (param) {
                                                                        return ["TakeTour"];
                                                                      }))), Router.schemaRouteToAnalyticsLocation(schemaRoute, /* [] */0));
                                                      }));
                                        }))))
                        }) : null
                }));
}

var make = LearnHub;

export {
  Style ,
  make ,
}
/* container Not a pure module */
