// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AvoLimits from "../../app/src/AvoLimits.mjs";

function fromBranchStatus(branchStatus) {
  if (typeof branchStatus === "number") {
    if (branchStatus === /* Master */0) {
      return "Approved";
    } else {
      return "Unknown";
    }
  }
  switch (branchStatus.TAG | 0) {
    case /* Merged */1 :
        return "Merged";
    case /* Closed */2 :
        return "Closed";
    case /* Open */0 :
    case /* Draft */3 :
        return "Draft";
    case /* ReadyForReview */4 :
        return "ReadyForReview";
    case /* ChangesRequested */5 :
        return "ChangesRequested";
    case /* Approved */6 :
        return "Approved";
    
  }
}

function toString(status) {
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    return "Changes Requested";
  } else if (status === "Approved") {
    return "Approved";
  } else if (status === "Draft") {
    return "Draft";
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else {
    return "Ready For Review";
  }
}

function enrichStatusWithApprovals(status, approvalsCount, workspace) {
  var match = AvoLimits.ApprovalWorkflow.computeAvailability(workspace);
  var approvalWorkflow = match === "AvailableDuringTrial" || match === "Available";
  if (status === "Closed") {
    return "Closed";
  } else if (status === "ChangesRequested") {
    if (approvalWorkflow) {
      return "ChangesRequested";
    } else {
      return "Open";
    }
  } else if (status === "Approved") {
    if (approvalWorkflow) {
      if (workspace.settings.numRequiredApprovals <= approvalsCount) {
        return "Approved";
      } else {
        return "ReadyForReview";
      }
    } else {
      return "Open";
    }
  } else if (status === "Draft") {
    if (approvalWorkflow) {
      return "Draft";
    } else {
      return "Open";
    }
  } else if (status === "Unknown") {
    return "Unknown";
  } else if (status === "Open") {
    return "Open";
  } else if (status === "Merged") {
    return "Merged";
  } else if (approvalWorkflow) {
    return "ReadyForReview";
  } else {
    return "Open";
  }
}

export {
  fromBranchStatus ,
  toString ,
  enrichStatusWithApprovals ,
}
/* AvoLimits Not a pure module */
