// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Nanoid from "nanoid";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateFns from "date-fns";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var initial_codegen = [
  "Loading",
  {},
  {}
];

var initial_inspector = {
  implementationProdStatus: /* Loading */0,
  implementationDevStatus: /* Loading */0,
  implementationStagingStatus: /* Loading */0
};

var initial = {
  codegen: initial_codegen,
  inspector: initial_inspector
};

var context = React.createContext(initial);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

var Context = {
  makeProps: makeProps,
  make: make
};

var subscriptionContext = React.createContext(function (_subscriptionType, param) {
      
    });

var inspectorDevTimeWindow = "30d";

function makeProps$1(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make$1 = subscriptionContext.Provider;

var SubscriptionContext = {
  makeProps: makeProps$1,
  make: make$1
};

function getInspectorEventSourceImplementationStatus(inspectorImplementationStatus, eventName, sourceId) {
  if (!inspectorImplementationStatus) {
    return /* Loading */0;
  }
  var events = inspectorImplementationStatus._0.events;
  var isSourceInstalled = Belt_Array.someU(Belt_MapString.toArray(events), (function (param) {
          return Belt_Option.mapWithDefault(Belt_MapString.get(param[1].sources, sourceId), false, (function (source) {
                        return source.status !== /* NeverSeenEvent */0;
                      }));
        }));
  if (isSourceInstalled) {
    return /* Ready */{
            _0: {
              NAME: "Installed",
              VAL: Belt_Option.flatMapU(Belt_MapString.get(events, eventName), (function ($$event) {
                      return Belt_MapString.get($$event.sources, sourceId);
                    }))
            }
          };
  } else {
    return /* Ready */{
            _0: "NotInstalled"
          };
  }
}

function ImplementationStatusContext$SubscriptionsManager(Props) {
  var implementationStatus = Props.implementationStatus;
  var children = Props.children;
  var subscriptions = React.useRef(/* [] */0);
  var implementationStatusRef = React.useRef(implementationStatus);
  var match = implementationStatus.codegen;
  var eventDict = match[1];
  var status = match[0];
  var prevStatus = Hooks.useRetained(status);
  var prevEventDict = Hooks.useRetained(Object.assign({}, eventDict));
  var prevEventStatus = prevStatus === "Ready" || Caml_obj.notequal(prevEventDict, {}) ? "Success" : "Loading";
  var eventStatus = status === "Ready" || Caml_obj.notequal(eventDict, {}) ? "Success" : "Loading";
  var inspectorImplementationStatus = implementationStatus.inspector;
  var prevInspectorImplementationProdStatus = Hooks.useRetained(inspectorImplementationStatus.implementationProdStatus);
  var prevInspectorImplementationDevStatus = Hooks.useRetained(inspectorImplementationStatus.implementationDevStatus);
  var prevInspectorImplementationStagingStatus = Hooks.useRetained(inspectorImplementationStatus.implementationStagingStatus);
  var subscribe = React.useRef(function (subscriptionType) {
        var subscriptionId = Nanoid.nanoid();
        var currentSubscriptions = subscriptions.current;
        subscriptions.current = Belt_List.add(currentSubscriptions, [
              subscriptionId,
              subscriptionType
            ]);
        var match = implementationStatusRef.current.codegen;
        var eventDict = match[1];
        var status = match[0];
        var variant = subscriptionType.NAME;
        if (variant === "Event") {
          var match$1 = subscriptionType.VAL;
          var eventStatus = status === "Ready" || Caml_obj.notequal(eventDict, {}) ? "Success" : "Loading";
          var initialState = eventStatus === "Success" ? ({
                NAME: "Success",
                VAL: Belt_Option.getWithDefault(Js_dict.get(eventDict, match$1[0]), /* [] */0)
              }) : "Loading";
          match$1[1](initialState);
        } else if (variant === "EventSourceInspectorImplementationStatus") {
          var match$2 = subscriptionType.VAL;
          var match$3 = match$2[0];
          var sourceId = match$3[1];
          var eventName = match$3[0];
          match$2[1]({
                implementationProdStatus: getInspectorEventSourceImplementationStatus(implementationStatusRef.current.inspector.implementationProdStatus, eventName, sourceId),
                implementationDevStatus: getInspectorEventSourceImplementationStatus(implementationStatusRef.current.inspector.implementationDevStatus, eventName, sourceId),
                implementationStagingStatus: getInspectorEventSourceImplementationStatus(implementationStatusRef.current.inspector.implementationStagingStatus, eventName, sourceId)
              });
        } else {
          var match$4 = subscriptionType.VAL;
          match$4[1](status, Belt_Option.getWithDefault(Js_dict.get(eventDict, match$4[0]), /* [] */0));
        }
        return function (param) {
          var currentSubscriptions = subscriptions.current;
          subscriptions.current = Belt_List.keepU(currentSubscriptions, (function (param) {
                  return param[0] !== subscriptionId;
                }));
        };
      });
  React.useEffect((function () {
          implementationStatusRef.current = implementationStatus;
          var currentSubscriptions = subscriptions.current;
          Belt_List.forEach(currentSubscriptions, (function (param) {
                  var subscriptionType = param[1];
                  var variant = subscriptionType.NAME;
                  if (variant === "Event") {
                    var match = subscriptionType.VAL;
                    var callback = match[1];
                    var eventId = match[0];
                    var prevEvent = prevEventStatus === "Success" ? ({
                          NAME: "Success",
                          VAL: Belt_Option.getWithDefault(Js_dict.get(prevEventDict, eventId), /* [] */0)
                        }) : "Loading";
                    var nextEvent = eventStatus === "Success" ? ({
                          NAME: "Success",
                          VAL: Belt_Option.getWithDefault(Js_dict.get(eventDict, eventId), /* [] */0)
                        }) : "Loading";
                    if (typeof prevEvent === "object") {
                      if (typeof nextEvent === "object" && Belt_List.eqU(prevEvent.VAL, nextEvent.VAL, (function (param, param$1) {
                                return param[0] === param$1[0] && param[1] === param$1[1] && param[2] === param$1[2] ? param[4] === param$1[4] : false;
                              }))) {
                        return ;
                      } else {
                        return callback(nextEvent);
                      }
                    } else if (typeof nextEvent === "object") {
                      return callback(nextEvent);
                    } else {
                      return ;
                    }
                  }
                  if (variant === "EventSourceInspectorImplementationStatus") {
                    var match$1 = subscriptionType.VAL;
                    var match$2 = match$1[0];
                    var sourceId = match$2[1];
                    var eventName = match$2[0];
                    var prevEventSourceImplementationProdStatus = getInspectorEventSourceImplementationStatus(prevInspectorImplementationProdStatus, eventName, sourceId);
                    var prevEventSourceImplementationDevStatus = getInspectorEventSourceImplementationStatus(prevInspectorImplementationDevStatus, eventName, sourceId);
                    var prevEventSourceImplementationStagingStatus = getInspectorEventSourceImplementationStatus(prevInspectorImplementationStagingStatus, eventName, sourceId);
                    var implementationProdStatus = getInspectorEventSourceImplementationStatus(inspectorImplementationStatus.implementationProdStatus, eventName, sourceId);
                    var implementationDevStatus = getInspectorEventSourceImplementationStatus(inspectorImplementationStatus.implementationDevStatus, eventName, sourceId);
                    var implementationStagingStatus = getInspectorEventSourceImplementationStatus(inspectorImplementationStatus.implementationStagingStatus, eventName, sourceId);
                    if (Caml_obj.equal(prevEventSourceImplementationProdStatus, implementationProdStatus) && Caml_obj.equal(prevEventSourceImplementationDevStatus, implementationDevStatus) && Caml_obj.equal(prevEventSourceImplementationStagingStatus, implementationStagingStatus)) {
                      return ;
                    } else {
                      return match$1[1]({
                                  implementationProdStatus: implementationProdStatus,
                                  implementationDevStatus: implementationDevStatus,
                                  implementationStagingStatus: implementationStagingStatus
                                });
                    }
                  }
                  var match$3 = subscriptionType.VAL;
                  var callback$1 = match$3[1];
                  var eventId$1 = match$3[0];
                  if (prevStatus !== status || !Belt_List.eqU(Belt_Option.getWithDefault(Js_dict.get(prevEventDict, eventId$1), /* [] */0), Belt_Option.getWithDefault(Js_dict.get(eventDict, eventId$1), /* [] */0), (function (param, param$1) {
                            return param[0] === param$1[0] && param[1] === param$1[1] && param[2] === param$1[2] ? param[4] === param$1[4] : false;
                          }))) {
                    return callback$1(status, Belt_Option.getWithDefault(Js_dict.get(eventDict, eventId$1), /* [] */0));
                  }
                  
                }));
        }), [implementationStatus]);
  return React.createElement(make$1, makeProps$1(subscribe.current, children, undefined));
}

var SubscriptionsManager = {
  make: ImplementationStatusContext$SubscriptionsManager
};

function getLatestCodegenTimestamps(invocationSources) {
  return Belt_Array.keepMap(Js_dict.entries(invocationSources), (function (param) {
                var sourceId = param[0];
                return Belt_Option.map(Belt_List.head(Belt_List.sort(Belt_List.fromArray(Belt_MapString.valuesToArray(param[1])), (function (prim0, prim1) {
                                      return DateFns.compareDesc(prim0, prim1);
                                    }))), (function (date) {
                              return [
                                      sourceId,
                                      date
                                    ];
                            }));
              }));
}

function useUpdateLastSeenCodegen(implementationStatus) {
  var match = WorkspaceContext.use(undefined);
  var lastSeenCodegen = match.lastSeenCodegen;
  var schemaId = match.id;
  var viewerRole = FirebaseFetcherHooks.useViewerRole(schemaId);
  var match$1 = implementationStatus.codegen;
  if (typeof viewerRole === "object" && viewerRole.NAME === "Success" && viewerRole.VAL !== "Admin") {
    return ;
  }
  if (match$1[0] !== "Ready") {
    return ;
  }
  var sourcesDict = match$1[2];
  if (typeof lastSeenCodegen === "object") {
    var sourceIdsWithDate = lastSeenCodegen.VAL;
    var seenSources = getLatestCodegenTimestamps(sourcesDict);
    if (seenSources.length === 0) {
      return ;
    }
    var shouldUpdate = !Belt_Array.every(seenSources, (function (param) {
            var seenSourceId = param[0];
            var existingSourceAndTimestamp = Belt_Array.getBy(sourceIdsWithDate, (function (param) {
                    return seenSourceId === param[0];
                  }));
            var dateRecentEnough = existingSourceAndTimestamp !== undefined ? DateFns.differenceInDays(existingSourceAndTimestamp[1], param[1]) < 1.0 : false;
            if (Belt_Option.isSome(existingSourceAndTimestamp)) {
              return dateRecentEnough;
            } else {
              return false;
            }
          }));
    if (shouldUpdate) {
      FirebaseUtils.setLastSeenCodegen(schemaId, seenSources);
      return ;
    } else {
      return ;
    }
  }
  if (lastSeenCodegen === "unset") {
    var seenSources$1 = getLatestCodegenTimestamps(sourcesDict);
    FirebaseUtils.setLastSeenCodegen(schemaId, seenSources$1);
    return ;
  }
  var seenSources$2 = getLatestCodegenTimestamps(sourcesDict);
  if (seenSources$2.length !== 0) {
    FirebaseUtils.setLastSeenCodegen(schemaId, seenSources$2);
    return ;
  }
  
}

function ImplementationStatusContext(Props) {
  var implementationStatus = Props.implementationStatus;
  var children = Props.children;
  useUpdateLastSeenCodegen(implementationStatus);
  return React.createElement(ImplementationStatusContext$SubscriptionsManager, {
              implementationStatus: implementationStatus,
              children: React.createElement(make, makeProps(implementationStatus, children, undefined))
            });
}

function ImplementationStatusContext$EventSubscription(Props) {
  var eventId = Props.eventId;
  var children = Props.children;
  var match = React.useState(function () {
        return "Loading";
      });
  var setInvocations = match[1];
  var subscribe = React.useContext(subscriptionContext);
  React.useEffect((function () {
          var unsubscribe = Curry._1(subscribe, {
                NAME: "Event",
                VAL: [
                  eventId,
                  (function (invocations) {
                      Curry._1(setInvocations, (function (param) {
                              return invocations;
                            }));
                    })
                ]
              });
          return (function (param) {
                    Curry._1(unsubscribe, undefined);
                  });
        }), [subscribe]);
  return children(match[0]);
}

var EventSubscription = {
  make: ImplementationStatusContext$EventSubscription
};

function useEventWithRefetchStatusSubscription(eventId) {
  var match = React.useState(function () {
        return [
                "Loading",
                /* [] */0
              ];
      });
  var setInvocations = match[1];
  var subscribe = React.useContext(subscriptionContext);
  React.useEffect((function () {
          var unsubscribe = Curry._1(subscribe, {
                NAME: "EventWithRefetchStatus",
                VAL: [
                  eventId,
                  (function (status, eventInvocations) {
                      Curry._1(setInvocations, (function (param) {
                              return [
                                      status,
                                      eventInvocations
                                    ];
                            }));
                    })
                ]
              });
          return (function (param) {
                    Curry._1(unsubscribe, undefined);
                  });
        }), [subscribe]);
  return match[0];
}

function useEventSourceInspectorImplementationStatusSubscription(eventName, sourceId) {
  var match = React.useState(function () {
        return {
                implementationProdStatus: /* Loading */0,
                implementationDevStatus: /* Loading */0,
                implementationStagingStatus: /* Loading */0
              };
      });
  var setImplementationStatus = match[1];
  var subscribe = React.useContext(subscriptionContext);
  React.useEffect((function () {
          var unsubscribe = Curry._1(subscribe, {
                NAME: "EventSourceInspectorImplementationStatus",
                VAL: [
                  [
                    eventName,
                    sourceId
                  ],
                  (function (implementationStatusPerEnv) {
                      Curry._1(setImplementationStatus, (function (param) {
                              return {
                                      implementationProdStatus: implementationStatusPerEnv.implementationProdStatus,
                                      implementationDevStatus: implementationStatusPerEnv.implementationDevStatus,
                                      implementationStagingStatus: implementationStatusPerEnv.implementationStagingStatus
                                    };
                            }));
                    })
                ]
              });
          return (function (param) {
                    Curry._1(unsubscribe, undefined);
                  });
        }), [subscribe]);
  return match[0];
}

function inspectorStatusFromFetchedInspectorStatus(inspectorEventSourceImplementationStatus, env, inspectorStatusLimit) {
  if (!inspectorEventSourceImplementationStatus) {
    return {
            TAG: /* Inspector */0,
            _0: env,
            _1: /* Loading */0
          };
  }
  var match = inspectorEventSourceImplementationStatus._0;
  if (typeof match === "object") {
    return Belt_Option.getWithDefault(Belt_Option.map(match.VAL, (function (param) {
                      return {
                              TAG: /* Inspector */0,
                              _0: env,
                              _1: /* Loaded */{
                                _0: {
                                  env: env,
                                  period: inspectorStatusLimit ? ({
                                        TAG: /* TimeWindow */1,
                                        _0: inspectorDevTimeWindow
                                      }) : ({
                                        TAG: /* Version */0,
                                        _0: Belt_Option.getWithDefault(param.highestVersion, "?")
                                      }),
                                  eventStatus: param.status
                                }
                              }
                            };
                    })), {
                TAG: /* Inspector */0,
                _0: env,
                _1: /* Unknown */1
              });
  } else {
    return {
            TAG: /* Inspector */0,
            _0: env,
            _1: /* Unknown */1
          };
  }
}

var inspectorProdTimeWindow = "30d";

var make$2 = ImplementationStatusContext;

export {
  initial ,
  context ,
  Context ,
  subscriptionContext ,
  inspectorDevTimeWindow ,
  inspectorProdTimeWindow ,
  SubscriptionContext ,
  getInspectorEventSourceImplementationStatus ,
  SubscriptionsManager ,
  getLatestCodegenTimestamps ,
  useUpdateLastSeenCodegen ,
  make$2 as make,
  EventSubscription ,
  useEventWithRefetchStatusSubscription ,
  useEventSourceInspectorImplementationStatusSubscription ,
  inspectorStatusFromFetchedInspectorStatus ,
}
/* context Not a pure module */
