// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as SideEffectUtils from "./SideEffectUtils.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

function toModel(stateFilter) {
  if (typeof stateFilter !== "object") {
    return ;
  }
  var variant = stateFilter.NAME;
  if (variant === "Source") {
    return {
            NAME: "Source",
            VAL: stateFilter.VAL
          };
  } else if (variant === "Destination") {
    return {
            NAME: "Destination",
            VAL: stateFilter.VAL
          };
  } else if (variant === "Tag") {
    return {
            NAME: "Tag",
            VAL: stateFilter.VAL
          };
  } else {
    return ;
  }
}

function getSourceFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Source") {
                  return item.VAL;
                }
                
              }));
}

function getTagFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Tag") {
                  return item.VAL;
                }
                
              }));
}

function getDestinationFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Destination") {
                  return item.VAL;
                }
                
              }));
}

function getPropertyGroupFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "PropertyGroup") {
                  return item.VAL;
                }
                
              }));
}

function getPropertyFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Property") {
                  return item.VAL;
                }
                
              }));
}

function getActionFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Action") {
                  return item.VAL;
                }
                
              }));
}

function getCategoryFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Category") {
                  return item.VAL;
                }
                
              }));
}

function getDomainFilters(filters) {
  return Belt_Array.keepMapU(filters, (function (item) {
                if (typeof item === "object" && item.NAME === "Domain") {
                  return item.VAL;
                }
                
              }));
}

function filterExcludedEvents(events, domainEvents, model, filters) {
  if (filters.length === 0) {
    return events;
  }
  var sourceFilters = Belt_SetString.fromArray(getSourceFilters(filters));
  var tagFilters = Belt_SetString.fromArray(getTagFilters(filters));
  var destinationFilters = Belt_SetString.fromArray(getDestinationFilters(filters));
  var propertyGroupFilters = Belt_SetString.fromArray(getPropertyGroupFilters(filters));
  var propertyFilters = Belt_SetString.fromArray(getPropertyFilters(filters));
  var actionFilters = Belt_SetString.fromArray(getActionFilters(filters));
  var categoryFilters = getCategoryFilters(filters);
  var modelSources = Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(model.sources, (function (source) {
                  return source.id;
                }))));
  var domainFilters = getDomainFilters(filters);
  var filterBySources = function ($$event) {
    if (Belt_SetString.isEmpty(sourceFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.includeSources, (function (param) {
                    return Belt_SetString.has(sourceFilters, param.id);
                  }));
    }
  };
  var filterByTags = function ($$event) {
    if (Belt_SetString.isEmpty(tagFilters)) {
      return true;
    } else {
      return Belt_List.some($$event.tags, (function (param) {
                    return Belt_SetString.has(tagFilters, param);
                  }));
    }
  };
  var filterByDestinations = function ($$event) {
    if (Belt_SetString.isEmpty(destinationFilters)) {
      return true;
    } else {
      return Belt_List.some($$event.includeDestinations, (function (includeDestination) {
                    var match = includeDestination.VAL;
                    var sourceId = match[0];
                    if (Belt_List.someU($$event.includeSources, (function (includedSource) {
                              return includedSource.id === sourceId;
                            })) && Belt_SetString.has(modelSources, sourceId)) {
                      return Belt_List.some(match[1], (function (param) {
                                    return Belt_SetString.has(destinationFilters, param);
                                  }));
                    } else {
                      return false;
                    }
                  }));
    }
  };
  var filterByPropertyGroups = function ($$event) {
    if (Belt_SetString.isEmpty(propertyGroupFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.propertyBundles, (function (propertyGroup) {
                    return Belt_SetString.has(propertyGroupFilters, propertyGroup.id);
                  }));
    }
  };
  var filterByProperties = function ($$event) {
    if (Belt_SetString.isEmpty(propertyFilters)) {
      return true;
    } else {
      return Belt_List.someU($$event.directPropertyRefs, (function (property) {
                    var tmp;
                    tmp = property._0.id;
                    return Belt_SetString.has(propertyFilters, tmp);
                  }));
    }
  };
  var filterByActions = function ($$event) {
    if (Belt_SetString.isEmpty(actionFilters)) {
      return true;
    } else {
      return Belt_List.someU(SideEffectUtils.getEventSideEffects(undefined, $$event, model), (function (action) {
                    return Belt_SetString.has(actionFilters, SideEffectUtils.getSideEffectLabel(action));
                  }));
    }
  };
  var filterByCategories = function ($$event) {
    if (categoryFilters.length === 0) {
      return true;
    } else {
      return Belt_SetString.has(Belt_SetString.fromArray(Belt_Array.concatMany(Belt_Array.keepMapU(categoryFilters, (function (goalId) {
                                return Belt_Option.mapU(ModelUtils.getGoalById(goalId, model), (function (param) {
                                              return Belt_List.toArray(param.events);
                                            }));
                              })))), $$event.id);
    }
  };
  return Belt_List.keepU(events, (function ($$event) {
                if (filterBySources($$event) && filterByTags($$event) && filterByDestinations($$event) && filterByPropertyGroups($$event) && filterByProperties($$event) && filterByActions($$event) && filterByCategories($$event)) {
                  if (domainFilters.length === 0) {
                    return true;
                  } else {
                    return Belt_Array.someU(domainFilters, (function (domainId) {
                                  return Belt_Option.mapWithDefaultU(Belt_MapString.get(domainEvents, $$event.id), false, (function (domains) {
                                                return Curry._2(Domains.has, domains, domainId);
                                              }));
                                }));
                  }
                } else {
                  return false;
                }
              }));
}

function getSourceFilterNames(globalStateFilters, model) {
  return Belt_Array.map(Belt_Array.keepMapU(Belt_Array.keepMapU(globalStateFilters, (function (filter) {
                        if (filter.NAME === "Source") {
                          return filter.VAL;
                        }
                        
                      })), (function (sourceId) {
                    return Belt_List.getByU(model.sources, (function (source) {
                                  return source.id === sourceId;
                                }));
                  })), AvoConfig.getSourceName);
}

function getSourceFilterNames__mapped(globalStateFilters, sources) {
  return Belt_Array.map(Belt_Array.keepMapU(Belt_Array.keepMapU(globalStateFilters, (function (filter) {
                        if (filter.NAME === "Source") {
                          return filter.VAL;
                        }
                        
                      })), (function (sourceId) {
                    return Curry._2(TrackingPlanMappedModel.Sources.get, sources, sourceId);
                  })), AvoConfig.getSourceName);
}

export {
  toModel ,
  getSourceFilters ,
  getTagFilters ,
  getDestinationFilters ,
  getPropertyGroupFilters ,
  getPropertyFilters ,
  getActionFilters ,
  getCategoryFilters ,
  getDomainFilters ,
  filterExcludedEvents ,
  getSourceFilterNames ,
  getSourceFilterNames__mapped ,
}
/* Domains Not a pure module */
