// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameInput from "./NameInput.mjs";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as RoleUtils from "./RoleUtils.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";

function editNameButton(disabled) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.button),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.margin2(Css.px(0), Css.px(4)),
                      tl: {
                        hd: Css.fontWeight(Styles.FontWeight.semi),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.padding2(Css.px(4), Css.px(8)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.regular),
                              tl: {
                                hd: Css.textAlign("center"),
                                tl: {
                                  hd: Css.position("relative"),
                                  tl: {
                                    hd: Css.minHeight(Css.px(25)),
                                    tl: {
                                      hd: Css.minWidth(Css.px(100)),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }),
                tl: {
                  hd: Curry._1(Css.style, disabled ? ({
                            hd: Css.color(Styles.Color.light10),
                            tl: {
                              hd: Css.cursor("default"),
                              tl: /* [] */0
                            }
                          }) : ({
                            hd: Css.backgroundColor(Styles.Color.lightBlue),
                            tl: {
                              hd: Css.color(Styles.Color.darkBlue),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.color(Styles.Color.deepBlue),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.cursor("text"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })),
                  tl: /* [] */0
                }
              }
            });
}

var removeButton = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(5), Css.px(10)),
              tl: {
                hd: Css.marginLeft(Css.px(5)),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.fontSize(Styles.FontSize.small),
                    tl: {
                      hd: Css.color(Styles.Color.light08),
                      tl: {
                        hd: Css.hover({
                              hd: Css.color(Styles.Color.red),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var editNameContainer = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.bottom(Css.px(0)),
            tl: {
              hd: Css.left(Css.px(0)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.zIndex(Styles.ZIndex.aboveAll),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var nameInputContainer = Curry._1(Css.style, {
      hd: Css.width(Css.px(500)),
      tl: {
        hd: Css.maxWidth(Css.pct(100)),
        tl: {
          hd: Css.height(Css.px(70)),
          tl: {
            hd: Css.position("relative"),
            tl: /* [] */0
          }
        }
      }
    });

var Styles$1 = {
  Color: Styles.Color,
  FontFamily: Styles.FontFamily,
  FontWeight: Styles.FontWeight,
  FontSize: Styles.FontSize,
  LineHeight: Styles.LineHeight,
  Border: Styles.Border,
  Shadow: Styles.Shadow,
  ZIndex: Styles.ZIndex,
  Duration: Styles.Duration,
  input: Styles.input,
  button: Styles.button,
  buttonReset: Styles.buttonReset,
  linkReset: Styles.linkReset,
  select: Styles.select,
  shimmer: Styles.shimmer,
  backgroundColorGuarantee: Styles.backgroundColorGuarantee,
  backgroundColorWithOverlayGuarantee: Styles.backgroundColorWithOverlayGuarantee,
  maxLines: Styles.maxLines,
  prettyWrap: Styles.prettyWrap,
  emptyStyle: Styles.emptyStyle,
  editNameButton: editNameButton,
  removeButton: removeButton,
  editNameContainer: editNameContainer,
  nameInputContainer: nameInputContainer
};

function EventRule$NameMappingControl(Props) {
  var role = Props.role;
  var name = Props.name;
  var disabledOpt = Props.disabled;
  var onSelect = Props.onSelect;
  var options = Props.options;
  var value = Props.value;
  var removeAction = Props.removeAction;
  var updateAction = Props.updateAction;
  var onRemoveSuccess = Props.onRemoveSuccess;
  var onUpdateSuccess = Props.onUpdateSuccess;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var sendActions = SendActionsContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var match = React.useState(function () {
        return /* Closed */0;
      });
  var setStatus = match[1];
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.margin2(Css.px(6), Css.px(0)),
                    tl: {
                      hd: Css.fontSize(Styles.FontSize.small),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, "Send as", React.createElement("button", {
                  className: editNameButton(disabled),
                  disabled: disabled || !RoleUtils.canEdit(role),
                  onClick: (function (param) {
                      Curry._1(setStatus, (function (param) {
                              return /* EditingName */1;
                            }));
                    })
                }, Belt_Option.getWithDefault(name, "")), "to", React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement(Select.make, {
                  disabled: disabled || !RoleUtils.canEdit(role),
                  maxWidth: Css.px(200),
                  onSelect: onSelect,
                  options: options,
                  value: value
                }), disabled || !RoleUtils.canEdit(role) ? null : React.createElement("button", {
                    className: removeButton,
                    onClick: (function (param) {
                        Curry.app(sendActions, [
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              onRemoveSuccess,
                              undefined,
                              [removeAction]
                            ]);
                      })
                  }, "Remove"), match[0] === /* EditingName */1 ? React.createElement("div", {
                    className: editNameContainer
                  }, React.createElement("div", {
                        className: nameInputContainer
                      }, React.createElement(NameInput.make, {
                            name: Belt_Option.getWithDefault(name, ""),
                            autoFocus: true,
                            onChange: (function (nextName, _namingConvention, _correctCase, _onReset) {
                                if (Caml_obj.notequal(name, nextName)) {
                                  Curry.app(sendActions, [
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        onUpdateSuccess,
                                        undefined,
                                        [Curry._1(updateAction, nextName)]
                                      ]);
                                }
                                Curry._1(setStatus, (function (param) {
                                        return /* Closed */0;
                                      }));
                              }),
                            submitLabel: "Update Mapping",
                            placeholder: "Event Name",
                            onClose: (function (param) {
                                Curry._1(setStatus, (function (param) {
                                        return /* Closed */0;
                                      }));
                              }),
                            fullscreen: true,
                            dirty: false,
                            itemType: "PropertyNameMapping",
                            actionType: Belt_Option.isSome(name) ? "Rename" : "Create",
                            getPossibleItemLink: (function (eventName) {
                                return Belt_Option.map(ModelUtils.getEventByName(eventName, model), (function (param) {
                                              return [
                                                      Router.Link.addDrawerItem(undefined, {
                                                            NAME: "event",
                                                            VAL: [
                                                              param.id,
                                                              undefined,
                                                              undefined,
                                                              false
                                                            ]
                                                          }),
                                                      undefined
                                                    ];
                                            }));
                              })
                          }))) : null);
}

var NameMappingControl = {
  Styles: Styles$1,
  make: EventRule$NameMappingControl
};

function sendRemoveSuccessTracking(branch, correctCase, $$event, eventsWithMapping, expectedEventCase, modelEvents, schemaBundle) {
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "RemoveRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(eventsWithMapping)), Belt_List.length(eventsWithMapping), Belt_List.length(Belt_List.keep(eventsWithMapping, (function (id) {
                  return id === $$event.id;
                }))), Belt_List.length(modelEvents), "NameMapping", undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, Case.toAnalytics(expectedEventCase), "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
}

function sendUpdateSuccessTracking(branch, correctCase, $$event, expectedEventCase, model, ruleType, schemaBundle) {
  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
  AnalyticsRe.eventUpdated($$event.id, $$event.name, schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "N/A", "N/A", "UpdateRule", undefined, undefined, undefined, undefined, undefined, Belt_List.length(BeltListExtensions.dedupeString(ModelUtils.eventsWithNameMapping(model))), Belt_List.length(ModelUtils.eventsWithNameMapping(model)), Belt_List.length(Belt_List.keep(ModelUtils.eventsWithNameMapping(model), (function (id) {
                  return id === $$event.id;
                }))), Belt_List.length(model.events), ruleType, undefined, undefined, undefined, "Event", $$event.triggers.length, correctCase, Case.toAnalytics(expectedEventCase), "ItemDetailsView", $$event.id, schemaBundle$1.schemaId, NamedBranch.getId(branch), $$event.id, undefined, $$event.name);
}

var allWorkspacesSelectId = "All Workspaces";

function EventRule(Props) {
  var rule = Props.rule;
  var $$event = Props.event;
  var sendActions = Props.sendActions;
  var model = Props.model;
  var disabledOpt = Props.disabled;
  var role = Props.role;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var expectedEventCase = match.expectedEventCase;
  var correctCase = NameUtils.isCorrectCase(expectedEventCase, $$event.name);
  var eventId = $$event.id;
  var context = {
    eventId: eventId,
    eventQuery: eventId
  };
  var match$1 = rule.definition;
  if (typeof match$1 !== "object") {
    return null;
  }
  var variant = match$1.NAME;
  if (variant === "NameMapping") {
    var match$2 = match$1.VAL;
    var name = match$2.name;
    var destination = match$2.destination;
    var destinationId = destination !== undefined && typeof destination === "object" && destination.NAME === "Destination" ? destination.VAL : undefined;
    var matchesNoDestination = function (id) {
      return Belt_List.every(model.destinations, (function (destination) {
                    return destination.id !== id;
                  }));
    };
    var options = Belt_Array.concatMany([
          destinationId !== undefined ? (
              matchesNoDestination(destinationId) ? [[
                    {
                      NAME: "Label",
                      VAL: "Select Destination"
                    },
                    "None"
                  ]] : []
            ) : [[
                {
                  NAME: "Label",
                  VAL: "Select Destination"
                },
                "None"
              ]],
          Belt_List.toArray(Belt_List.map(Belt_List.keep(model.destinations, (function (destination) {
                          return !Belt_List.some(model.rules, (function (rule) {
                                        var match = rule.item;
                                        var match$1 = rule.definition;
                                        if (typeof match !== "object") {
                                          return false;
                                        }
                                        if (match.NAME !== "Event") {
                                          return false;
                                        }
                                        if (typeof match$1 !== "object") {
                                          return false;
                                        }
                                        if (match$1.NAME !== "NameMapping") {
                                          return false;
                                        }
                                        var match$2 = match$1.VAL.destination;
                                        if (match$2 === undefined) {
                                          return false;
                                        }
                                        if (typeof match$2 !== "object") {
                                          return false;
                                        }
                                        if (match$2.NAME !== "Destination") {
                                          return false;
                                        }
                                        var id = match$2.VAL;
                                        var isCurrentlySelected = Caml_obj.equal(id, destinationId);
                                        var isSameDestination = id === destination.id;
                                        var isSameEvent = match.VAL === $$event.id;
                                        if (!isCurrentlySelected && isSameDestination) {
                                          return isSameEvent;
                                        } else {
                                          return false;
                                        }
                                      }));
                        })), (function (destination) {
                      return [
                              {
                                NAME: "Label",
                                VAL: ModelUtils.getDestinationName(destination) + (
                                  ModelUtils.isEventSentToDestination(model, eventId, destination.id) ? "" : " (event never sent here)"
                                )
                              },
                              destination.id
                            ];
                    })))
        ]);
    var onSelect = function (nextDestinationId) {
      var nextDestinationId$1 = nextDestinationId === "None" ? undefined : nextDestinationId;
      if (Caml_obj.notequal(nextDestinationId$1, destinationId)) {
        return Curry.app(sendActions, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    (function (branch) {
                        sendUpdateSuccessTracking(branch, correctCase, $$event, expectedEventCase, model, "NameMapping", schemaBundle);
                      }),
                    undefined,
                    [[
                        {
                          NAME: "UpdateRule",
                          VAL: [
                            rule.id,
                            {
                              NAME: "Event",
                              VAL: eventId
                            },
                            {
                              NAME: "NameMapping",
                              VAL: {
                                destination: Belt_Option.map(nextDestinationId$1, (function (id) {
                                        return {
                                                NAME: "Destination",
                                                VAL: id
                                              };
                                      })),
                                name: name
                              }
                            }
                          ]
                        },
                        context
                      ]]
                  ]);
      }
      
    };
    var removeAction_0 = {
      NAME: "RemoveRule",
      VAL: [
        rule.id,
        {
          NAME: "Event",
          VAL: eventId
        }
      ]
    };
    var removeAction = [
      removeAction_0,
      context
    ];
    var onRemoveSuccess = function (branch) {
      var eventsWithMapping = Belt_List.keepMap(model.rules, (function (item) {
              var match = item.item;
              var match$1 = item.definition;
              var match$2 = item.id;
              if (typeof match === "object" && match.NAME === "Event" && typeof match$1 === "object" && match$1.NAME === "NameMapping" && match$2 !== rule.id) {
                return match.VAL;
              }
              
            }));
      sendRemoveSuccessTracking(branch, correctCase, $$event, eventsWithMapping, expectedEventCase, model.events, schemaBundle);
    };
    var updateAction = function (nextName) {
      return [
              {
                NAME: "UpdateRule",
                VAL: [
                  rule.id,
                  {
                    NAME: "Event",
                    VAL: eventId
                  },
                  {
                    NAME: "NameMapping",
                    VAL: {
                      destination: Belt_Option.map(destinationId, (function (id) {
                              return {
                                      NAME: "Destination",
                                      VAL: id
                                    };
                            })),
                      name: nextName
                    }
                  }
                ]
              },
              context
            ];
    };
    var onUpdateSuccess = function (branch) {
      sendUpdateSuccessTracking(branch, correctCase, $$event, expectedEventCase, model, "NameMapping", schemaBundle);
    };
    return React.createElement(EventRule$NameMappingControl, {
                role: role,
                name: name,
                disabled: disabled,
                onSelect: onSelect,
                options: options,
                value: Belt_Option.getWithDefault(destinationId, "None"),
                removeAction: removeAction,
                updateAction: updateAction,
                onRemoveSuccess: onRemoveSuccess,
                onUpdateSuccess: onUpdateSuccess
              });
  }
  if (variant !== "NameInLocalWorkspace") {
    return null;
  }
  var match$3 = match$1.VAL;
  var name$1 = match$3.name;
  var workspace = match$3.workspace;
  var workspaces = model.sources;
  var currentWorkspaceId = workspace !== undefined ? (
      typeof workspace === "object" ? workspace.VAL : allWorkspacesSelectId
    ) : undefined;
  var matchesNoWorkspace = function (workspaceSelectId) {
    return Belt_List.every(workspaces, (function (workspace) {
                  return workspace.id !== workspaceSelectId;
                }));
  };
  var options$1 = Belt_Array.concatMany([
        currentWorkspaceId !== undefined ? (
            matchesNoWorkspace(currentWorkspaceId) ? [[
                  {
                    NAME: "Label",
                    VAL: "Select Workspace"
                  },
                  "None"
                ]] : []
          ) : [[
              {
                NAME: "Label",
                VAL: "Select Workspace"
              },
              "None"
            ]],
        Belt_Array.map(Belt_Array.keep(Belt_Array.concat([allWorkspacesSelectId], Belt_List.toArray(Belt_List.map(workspaces, (function (param) {
                                return param.id;
                              })))), (function (selectableWorkspaceId) {
                    return !Belt_Array.some(Belt_List.toArray(model.rules), (function (rule) {
                                  var match = rule.item;
                                  var match$1 = rule.definition;
                                  if (typeof match !== "object") {
                                    return false;
                                  }
                                  if (match.NAME !== "Event") {
                                    return false;
                                  }
                                  if (typeof match$1 !== "object") {
                                    return false;
                                  }
                                  if (match$1.NAME !== "NameInLocalWorkspace") {
                                    return false;
                                  }
                                  var workspaceRuleId = match$1.VAL.workspace;
                                  if (workspaceRuleId === undefined) {
                                    return false;
                                  }
                                  var workspaceSelectId = typeof workspaceRuleId === "object" ? workspaceRuleId.VAL : allWorkspacesSelectId;
                                  var isCurrentlySelected = Caml_obj.equal(workspaceSelectId, currentWorkspaceId);
                                  var isSameWorkspace = workspaceSelectId === selectableWorkspaceId;
                                  var isSameEvent = match.VAL === $$event.id;
                                  if (!isCurrentlySelected && isSameWorkspace) {
                                    return isSameEvent;
                                  } else {
                                    return false;
                                  }
                                }));
                  })), (function (workspaceSelectId) {
                if (workspaceSelectId === allWorkspacesSelectId) {
                  return [
                          {
                            NAME: "Label",
                            VAL: allWorkspacesSelectId
                          },
                          workspaceSelectId
                        ];
                } else {
                  return [
                          {
                            NAME: "Label",
                            VAL: GlobalRequirementsUtils.getWorkspaceNameById(model, workspaceSelectId)
                          },
                          workspaceSelectId
                        ];
                }
              }))
      ]);
  var onSelect$1 = function (nextWorkspaceSelectId) {
    var nextWorkspaceRule = nextWorkspaceSelectId === "None" ? undefined : (
        nextWorkspaceSelectId === allWorkspacesSelectId ? "AllWorkspaces" : ({
              NAME: "Workspace",
              VAL: nextWorkspaceSelectId
            })
      );
    if (Caml_obj.notequal(nextWorkspaceRule, workspace)) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  (function (branch) {
                      sendUpdateSuccessTracking(branch, correctCase, $$event, expectedEventCase, model, "NameInLocalWorkspace", schemaBundle);
                    }),
                  undefined,
                  [[
                      {
                        NAME: "UpdateRule",
                        VAL: [
                          rule.id,
                          {
                            NAME: "Event",
                            VAL: eventId
                          },
                          {
                            NAME: "NameInLocalWorkspace",
                            VAL: {
                              workspace: nextWorkspaceRule,
                              name: name$1
                            }
                          }
                        ]
                      },
                      context
                    ]]
                ]);
    }
    
  };
  var removeAction_0$1 = {
    NAME: "RemoveRule",
    VAL: [
      rule.id,
      {
        NAME: "Event",
        VAL: eventId
      }
    ]
  };
  var removeAction$1 = [
    removeAction_0$1,
    context
  ];
  var onRemoveSuccess$1 = function (branch) {
    var eventsWithMapping = Belt_List.keepMap(model.rules, (function (item) {
            var match = item.item;
            var match$1 = item.definition;
            var match$2 = item.id;
            if (typeof match === "object" && match.NAME === "Event" && typeof match$1 === "object" && match$1.NAME === "NameInLocalWorkspace" && match$2 !== rule.id) {
              return match.VAL;
            }
            
          }));
    sendRemoveSuccessTracking(branch, correctCase, $$event, eventsWithMapping, expectedEventCase, model.events, schemaBundle);
  };
  var updateAction$1 = function (nextName) {
    var tmp;
    tmp = currentWorkspaceId !== undefined && currentWorkspaceId !== "None" ? (
        currentWorkspaceId === allWorkspacesSelectId ? "AllWorkspaces" : ({
              NAME: "Workspace",
              VAL: currentWorkspaceId
            })
      ) : undefined;
    return [
            {
              NAME: "UpdateRule",
              VAL: [
                rule.id,
                {
                  NAME: "Event",
                  VAL: eventId
                },
                {
                  NAME: "NameInLocalWorkspace",
                  VAL: {
                    workspace: tmp,
                    name: nextName
                  }
                }
              ]
            },
            context
          ];
  };
  var onUpdateSuccess$1 = function (branch) {
    sendUpdateSuccessTracking(branch, correctCase, $$event, expectedEventCase, model, "NameInLocalWorkspace", schemaBundle);
  };
  return React.createElement(EventRule$NameMappingControl, {
              role: role,
              name: name$1,
              disabled: disabled,
              onSelect: onSelect$1,
              options: options$1,
              value: Belt_Option.getWithDefault(currentWorkspaceId, "None"),
              removeAction: removeAction$1,
              updateAction: updateAction$1,
              onRemoveSuccess: onRemoveSuccess$1,
              onUpdateSuccess: onUpdateSuccess$1
            });
}

var make = EventRule;

export {
  NameMappingControl ,
  sendRemoveSuccessTracking ,
  sendUpdateSuccessTracking ,
  allWorkspacesSelectId ,
  make ,
}
/* removeButton Not a pure module */
