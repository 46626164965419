// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function getColumnWidthByType(columns, columnType) {
  return Belt_Option.mapWithDefault(Belt_Array.getByU(columns, (function (column) {
                    return column.columnType === columnType;
                  })), 200, (function (param) {
                return param.width;
              }));
}

export {
  getColumnWidthByType ,
}
/* No side effect */
