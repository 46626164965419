// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";

function OpsWithState(Props) {
  var value = Props.value;
  var onFlush = Props.onFlush;
  var onChangeCompleted = Props.onChangeCompleted;
  var children = Props.children;
  var match = Hooks.useOpsWithState(value, onFlush, onChangeCompleted);
  return Curry._4(children, match[0], match[1], match[2], match[3]);
}

var make = OpsWithState;

export {
  make ,
}
/* Hooks Not a pure module */
