// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";

var maxWidthPx = Css.px(736);

function DiffComponents$Row(Props) {
  var label = Props.label;
  var change = Props.change;
  var getChangeColor = function (change) {
    if (change === "Neutral") {
      return Styles.Color.light11;
    } else if (change === "Remove") {
      return Styles.Color.darkRed;
    } else {
      return Styles.Color.darkGreen;
    }
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.paddingTop(Css.px(8)),
                    tl: {
                      hd: Css.paddingRight(Css.px(16)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(8)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(16)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", getChangeColor(change)),
                            tl: {
                              hd: Css.borderRadius(Css.px(6)),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  size: "Small",
                  weight: "Semi",
                  color: getChangeColor(change),
                  children: label
                }));
}

var Row = {
  make: DiffComponents$Row
};

function DiffComponents$Item(Props) {
  var borderColorOpt = Props.borderColor;
  var children = Props.children;
  var onClick = Props.onClick;
  var borderColor = borderColorOpt !== undefined ? borderColorOpt : Styles.Color.light06;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.alignSelf("flexStart"),
                    tl: {
                      hd: Css.flexGrow(1.0),
                      tl: {
                        hd: Css.maxWidth(maxWidthPx),
                        tl: {
                          hd: Css.position("sticky"),
                          tl: {
                            hd: Css.top(Css.px(20)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.pct(100)),
                        tl: {
                          hd: Css.borderRadius(Styles.Border.radius),
                          tl: {
                            hd: Css.padding(Css.px(15)),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.flexDirection("column"),
                                    tl: {
                                      hd: Css.gap(Css.px(4)),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.light01),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", borderColor),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: onClick
                }, children));
}

var Item = {
  make: DiffComponents$Item
};

export {
  maxWidthPx ,
  Row ,
  Item ,
}
/* maxWidthPx Not a pure module */
