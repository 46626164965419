// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "./Text.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as $$Comment from "./Comment.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as ObjectActivityLogAction from "./ObjectActivityLogAction.mjs";

function InspectorIssueActivityItem(Props) {
  var events = Props.events;
  var action = Props.action;
  var userId = Props.userId;
  var userIds = Props.userIds;
  var goToId = Props.goToId;
  var openBranches = Props.openBranches;
  var inspectorIssueName = Props.inspectorIssueName;
  var showComments = Props.showComments;
  var users = FirebaseFetcherHooks.useUsers(userIds);
  if (typeof users !== "object") {
    return null;
  }
  if (users.NAME === "Errored") {
    return null;
  }
  var users$1 = users.VAL;
  var match = action.contents;
  if (typeof match !== "object") {
    return null;
  }
  var variant = match.NAME;
  if (variant === "UpdateInspectorIssueStatus") {
    var match$1 = match.VAL;
    var issueStatus = match$1.issueStatus;
    return React.createElement(ObjectActivityLogAction.Content.make, {
                hideBranchInfo: true,
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, Caml_obj.equal(userIds, ["system"]) ? React.createElement("b", undefined, "Inspector ") : null, "set the issue status to ", React.createElement("b", undefined, match$1.regression && issueStatus.status === /* Unresolved */0 ? "Regression" : InspectorIssueStatus.Status.toStringWithDetails(issueStatus.status)));
  }
  if (variant === "Archive") {
    return null;
  }
  if (variant === "InspectorIssueComment") {
    if (!showComments) {
      return null;
    }
    var match$2 = match.VAL;
    var inspectorIssueId = match$2[0];
    return React.createElement($$Comment.make, {
                threadId: inspectorIssueId,
                itemType: "InspectorIssue",
                itemName: inspectorIssueName,
                commentId: match$2[1],
                events: events,
                userId: userId,
                goToId: goToId,
                action: action,
                link: (function (commentId) {
                    return Router.Link.addDrawerItem(undefined, {
                                NAME: "inspectorIssue",
                                VAL: [
                                  inspectorIssueId,
                                  commentId
                                ]
                              });
                  }),
                openBranches: openBranches
              });
  }
  if (variant === "CreateInspectorIssue") {
    var issueContext = match.VAL;
    var status = InspectorIssueStatus.Status.toStringWithDetails(issueContext.issueStatus.status);
    var tmp;
    if (userIds.length !== 1) {
      tmp = null;
    } else {
      var match$3 = userIds[0];
      tmp = match$3 === "system" ? React.createElement("b", undefined, "Inspector ") : null;
    }
    var _validateIn = issueContext.issueStatus.status;
    var tmp$1;
    tmp$1 = typeof _validateIn === "number" || _validateIn.TAG !== /* Ignored */0 ? null : React.createElement(React.Fragment, undefined, " as ", React.createElement($$Text.make, {
                element: "Span",
                weight: "Semi",
                children: status
              }));
    return React.createElement(ObjectActivityLogAction.Content.make, {
                hideBranchInfo: true,
                action: action,
                users: users$1,
                openBranches: openBranches,
                children: null
              }, tmp, "created the issue", tmp$1);
  }
  if (variant !== "Unarchive") {
    return null;
  }
  return null;
}

var make = InspectorIssueActivityItem;

export {
  make ,
}
/* Text Not a pure module */
