// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function IconLightning(Props) {
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : 12;
  return React.createElement("svg", {
              height: String(Math.imul(size, 11) / 10 | 0),
              width: String(size),
              viewBox: "0 0 10 11",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5.41666 1.00002L4.58332 4.33335H9.58332L4.58332 10.1667L5.41666 6.83335H0.416656L5.41666 1.00002Z",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "0.833333"
                }));
}

var make = IconLightning;

export {
  make ,
}
/* react Not a pure module */
