// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconEventSegmentation(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("svg", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: {
                        hd: Css.transform(Css.scale(-1, 1)),
                        tl: /* [] */0
                      }
                    }
                  }),
              width: String(size),
              viewBox: "0 0 16 16",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("g", undefined, React.createElement("path", {
                      d: "M5.249,7.438l.562.45L6.4,6.553,9.4,8.8a1,1,0,0,0,1.432-.245l4.555-6.832L13.723.613,9.754,6.565,6.6,4.2a1,1,0,0,0-1.514.394L4.016,7A2.009,2.009,0,0,1,5.249,7.438Z"
                    }), React.createElement("polygon", {
                      points: "3.299 11 2.239 11 0.68 14.508 2.508 15.32 4.132 11.666 3.299 11"
                    }), React.createElement("path", {
                      d: "M9,14a1,1,0,0,1-.625-.219L3.649,10H0V8H4a1,1,0,0,1,.625.219l4.457,3.566,5.261-3.157,1.029,1.715L9.515,13.857A1,1,0,0,1,9,14Z"
                    })));
}

var make = IconEventSegmentation;

export {
  make ,
}
/* Css Not a pure module */
