// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Workspace from "../../model/src/Workspace.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as GenericModal from "./GenericModal.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var containerStyles = Curry._1(Css.style, {
      hd: Css.backgroundColor(Styles.Color.light01),
      tl: {
        hd: Css.position("relative"),
        tl: {
          hd: Css.padding(Css.px(32)),
          tl: /* [] */0
        }
      }
    });

var cancelButtonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("absolute"),
              tl: {
                hd: Css.top(Css.px(12)),
                tl: {
                  hd: Css.right(Css.px(12)),
                  tl: {
                    hd: Css.backgroundColor("transparent"),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                      tl: {
                        hd: Css.hover({
                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var messageStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.paddingTop(Css.px(24)),
            tl: {
              hd: Css.paddingRight(Css.px(32)),
              tl: {
                hd: Css.paddingBottom(Css.px(32)),
                tl: {
                  hd: Css.paddingLeft(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var detailContainerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light08),
        tl: {
          hd: Css.padding2(Css.px(8), "zero"),
          tl: {
            hd: Css.selector(" p:first-child", {
                  hd: Css.flexGrow(1.0),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function AddExtraSeatModal$Pricing(Props) {
  var plan = Props.plan;
  var extraAdminEditorSeatCost = Props.extraAdminEditorSeatCost;
  var existingAdminEditorExtraSeats = Props.existingAdminEditorExtraSeats;
  var extraCommenterSeatCost = Props.extraCommenterSeatCost;
  var existingCommenterExtraSeats = Props.existingCommenterExtraSeats;
  var onClose = Props.onClose;
  var role = Props.role;
  var planName = Workspace.printPlanName(plan.name);
  var planMonthlyCost = plan.basePricePerMonth | 0;
  var cost = Math.imul(extraAdminEditorSeatCost | 0, existingAdminEditorExtraSeats) + (
    !(role === "CommentOnly" && extraCommenterSeatCost > 0) ? extraAdminEditorSeatCost | 0 : 0
  ) | 0;
  var tmp;
  if (extraCommenterSeatCost > 0 && (existingCommenterExtraSeats > 0 || role === "CommentOnly")) {
    var cost$1 = Math.imul(extraCommenterSeatCost | 0, existingCommenterExtraSeats) + (extraCommenterSeatCost | 0) | 0;
    tmp = React.createElement("div", {
          className: detailContainerStyles
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              color: Styles.Color.light10,
              children: "Additional Commenter Seats " + (
                existingCommenterExtraSeats > 0 ? "(" + String(existingCommenterExtraSeats + 1 | 0) + "x)" : ""
              )
            }), React.createElement($$Text.make, {
              size: "Medium",
              color: Styles.Color.light10,
              children: "$" + String(cost$1) + "/month"
            }));
  } else {
    tmp = null;
  }
  var cost$2 = (planMonthlyCost + (Math.imul(extraAdminEditorSeatCost | 0, existingAdminEditorExtraSeats) + Math.imul(extraCommenterSeatCost | 0, existingCommenterExtraSeats) | 0) | 0) + (
    role === "CommentOnly" ? extraCommenterSeatCost | 0 : extraAdminEditorSeatCost | 0
  ) | 0;
  return React.createElement("div", {
              className: containerStyles
            }, React.createElement("button", {
                  className: cancelButtonStyles,
                  onClick: onClose
                }, React.createElement(IconX.make, {})), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light08),
                        tl: {
                          hd: Css.marginBottom(Css.px(16)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement("div", {
                      className: detailContainerStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          children: "" + planName + " Plan Base"
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light10,
                          children: "$" + String(planMonthlyCost) + "/month"
                        })), React.createElement("div", {
                      className: detailContainerStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          children: "Additional Admin/Editor Seats " + (
                            existingAdminEditorExtraSeats > 0 ? "(" + String(existingAdminEditorExtraSeats + 1 | 0) + "x)" : ""
                          )
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          color: Styles.Color.light10,
                          children: "$" + String(cost) + "/month"
                        })), tmp, React.createElement("div", {
                      className: detailContainerStyles
                    }, React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.green,
                          children: "Total"
                        }), React.createElement($$Text.make, {
                          size: "Medium",
                          weight: "Semi",
                          color: Styles.Color.darkBlue,
                          children: "$" + String(cost$2) + "/month*"
                        }))), React.createElement($$Text.make, {
                  size: "Small",
                  textAlign: "center",
                  color: Styles.Color.light10,
                  children: "*This will be reflected on your next monthly invoice"
                }));
}

var Pricing = {
  make: AddExtraSeatModal$Pricing
};

function AddExtraSeatModal(Props) {
  var onClose = Props.onClose;
  var submitInvite = Props.submitInvite;
  var email = Props.email;
  var plan = Props.plan;
  var extraAdminEditorSeatCost = Props.extraAdminEditorSeatCost;
  var existingAdminEditorExtraSeats = Props.existingAdminEditorExtraSeats;
  var extraCommenterSeatCost = Props.extraCommenterSeatCost;
  var existingCommenterExtraSeats = Props.existingCommenterExtraSeats;
  var role = Props.role;
  var schemaBundle = SchemaBundleContext.use(undefined);
  React.useEffect((function () {
          AnalyticsRe.addSeatPrompted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var handleClose = function (addSeatPromptInteraction) {
    Curry._1(onClose, undefined);
    AnalyticsRe.addSeatPromptInteraction(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, addSeatPromptInteraction, schemaBundle.schemaId, schemaBundle.branchId);
  };
  return React.createElement(GenericModal.make, {
              onClose: (function (param) {
                  handleClose("Dismiss");
                }),
              children: (function (param) {
                  var match = plan.name;
                  var cost = role === "Admin" || role === "Editor" ? extraAdminEditorSeatCost : (
                      role === "CodegenAccess" ? (
                          plan.name === /* TeamV2Annual */9 ? 25.0 : 30.0
                        ) : (
                          role === "CommentOnly" ? extraCommenterSeatCost : extraAdminEditorSeatCost
                        )
                    );
                  var roleCopy = role === "Editor" ? "an editor" : (
                      role === "CodegenAccess" ? "a codegen access" : (
                          role === "Viewer" ? "a viewer" : (
                              role === "CommentOnly" ? "a commenter" : (
                                  role === "Admin" ? "an admin" : (
                                      role === "NoAccess" ? "" : "a billing"
                                    )
                                )
                            )
                        )
                    );
                  return React.createElement("div", undefined, match === 9 || match === 8 ? React.createElement(AddExtraSeatModal$Pricing, {
                                    plan: plan,
                                    extraAdminEditorSeatCost: extraAdminEditorSeatCost,
                                    existingAdminEditorExtraSeats: existingAdminEditorExtraSeats,
                                    extraCommenterSeatCost: extraCommenterSeatCost,
                                    existingCommenterExtraSeats: existingCommenterExtraSeats,
                                    onClose: (function (param) {
                                        handleClose("Dismiss");
                                      }),
                                    role: role
                                  }) : null, React.createElement("div", {
                                  className: messageStyles
                                }, React.createElement(Title.make, {
                                      children: "Add new member seat?",
                                      size: "Tiny"
                                    }), React.createElement(Spacer.make, {
                                      height: 24
                                    }), React.createElement($$Text.make, {
                                      size: "Medium",
                                      textAlign: "center",
                                      children: "Giving " + email + " " + roleCopy + " seat will add a new seat to your subscription for $" + String(cost) + "/month."
                                    }), React.createElement(Spacer.make, {
                                      height: 24
                                    }), React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("grid"),
                                            tl: {
                                              hd: Css.gridTemplateColumns({
                                                    hd: "auto",
                                                    tl: {
                                                      hd: "auto",
                                                      tl: /* [] */0
                                                    }
                                                  }),
                                              tl: {
                                                hd: Css.gridGap(Css.px(16)),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    }, React.createElement(Button.make, {
                                          label: "Cancel",
                                          onClick: (function (param) {
                                              handleClose("Dismiss");
                                            }),
                                          size: "large",
                                          style: "outline"
                                        }), React.createElement(Button.make, {
                                          label: "Add Seat",
                                          onClick: (function (param) {
                                              Curry._1(submitInvite, undefined);
                                              handleClose("Proceed");
                                            }),
                                          size: "large"
                                        }))));
                })
            });
}

var make = AddExtraSeatModal;

export {
  containerStyles ,
  cancelButtonStyles ,
  messageStyles ,
  detailContainerStyles ,
  Pricing ,
  make ,
}
/* containerStyles Not a pure module */
