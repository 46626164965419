// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(sucess) {
  return Json_encode.object_({
              hd: [
                "ok",
                sucess.ok
              ],
              tl: {
                hd: [
                  "currentEditorSeats",
                  sucess.currentEditorSeats
                ],
                tl: {
                  hd: [
                    "planEditorSeats",
                    sucess.planEditorSeats
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode(json) {
  return Json_decode.oneOf({
              hd: (function (json) {
                  return {
                          TAG: /* Success */0,
                          _0: {
                            ok: Json_decode.field("ok", Json_decode.bool, json),
                            currentEditorSeats: Json_decode.field("currentEditorSeats", Json_decode.$$int, json),
                            planEditorSeats: Json_decode.field("planEditorSeats", Json_decode.$$int, json)
                          }
                        };
                }),
              tl: {
                hd: (function (json) {
                    return {
                            TAG: /* Error */1,
                            _0: {
                              ok: Json_decode.field("ok", Json_decode.bool, json),
                              error: Json_decode.field("error", Json_decode.string, json)
                            }
                          };
                  }),
                tl: /* [] */0
              }
            }, json);
}

export {
  encode ,
  decode ,
}
/* No side effect */
