// Generated by ReScript, PLEASE EDIT WITH CARE


var binOps = [
  "EQ",
  "NEQ",
  "LE",
  "LEQ",
  "GT",
  "GTE"
];

function printBinOp(x) {
  if (x === "GT") {
    return ">";
  } else if (x === "LE") {
    return "<";
  } else if (x === "GTE") {
    return ">=";
  } else if (x === "LEQ") {
    return "<=";
  } else if (x === "NEQ") {
    return "!=";
  } else {
    return "=";
  }
}

export {
  binOps ,
  printBinOp ,
}
/* No side effect */
