// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Text from "../Text.mjs";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorViewInstallDocs from "./InspectorViewInstallDocs.mjs";
import * as InspectorViewInstallDocsRudderStackCodeSnippet from "./InspectorViewInstallDocsRudderStackCodeSnippet.mjs";

function InspectorViewInstallDocsRudderStack(Props) {
  var apiKey = Props.apiKey;
  var sourceGroup = Props.sourceGroup;
  var schemaBundle = SchemaBundleContext.use(undefined);
  return React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Follow the steps below to stream your data from Rudderstack to Avo, no need to add any code to your codebase."
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: "Once you’re set up, the schemas of all events seen in RudderStack will automatically be sent to Inspector!"
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement(InspectorViewInstallDocs.Link.make, {
                  path: "workspace/connect-inspector-to-rudderstack",
                  label: "Inspector Rudderstack integration docs"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: "How to set up Inspector with Rudderstack:"
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 1,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "You finished this step by creating this source. Well done! 🎉"
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 2,
                  children: null
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light12,
                      children: "Create Transformations in RudderStack using these code snippets:"
                    }), InspectorViewInstallDocsRudderStackCodeSnippet.code(apiKey, schemaBundle, sourceGroup)), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 3,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "(Optional) Test the development RudderStack Transformation."
                      })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement(InspectorViewInstallDocs.Step.make, {
                  n: 4,
                  children: React.createElement($$Text.make, {
                        size: "Medium",
                        color: Styles.Color.light12,
                        children: "Connect the RudderStack Transformation to your RudderStack Destinations and Sources."
                      })
                }));
}

var make = InspectorViewInstallDocsRudderStack;

export {
  make ,
}
/* Text Not a pure module */
