// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var intentsKey = "intents";

var _map = {"dontIgnore":"dontIgnore","currentVersion":"currentVersion","nextVersion":"nextVersion","oneHourAfterMerge":"oneHourAfterMerge","twentyFourHoursAfterMerge":"twentyFourHoursAfterMerge"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

function toDisplayString(intent) {
  if (intent === "nextVersion") {
    return "Next version after branch merge";
  } else if (intent === "oneHourAfterMerge") {
    return "One hour after branch merge";
  } else if (intent === "currentVersion") {
    return "Latest version on branch merge";
  } else if (intent === "twentyFourHoursAfterMerge") {
    return "24 hours after branch merge";
  } else {
    return "Don’t ignore";
  }
}

function intent(intent$1) {
  return Json_encode.object_({
              hd: [
                "sourceId",
                intent$1[0]
              ],
              tl: {
                hd: [
                  "intent",
                  intent$1[1]
                ],
                tl: /* [] */0
              }
            });
}

function intents(intents$1) {
  return Json_encode.array(intent, intents$1);
}

var Encode = {
  intent: intent,
  intents: intents
};

function intent$1(json) {
  return {
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          intent: tFromJs(Json_decode.field("intent", Json_decode.string, json))
        };
}

function intents$1(json) {
  return Belt_MapString.fromArray(Belt_Array.keepMap(Json_decode.field(intentsKey, (function (param) {
                        return Json_decode.array(intent$1, param);
                      }), json), (function (sourceIntent) {
                    var intent = sourceIntent.intent;
                    if (intent !== undefined) {
                      return [
                              sourceIntent.sourceId,
                              intent
                            ];
                    }
                    
                  })));
}

var Decode = {
  intent: intent$1,
  intents: intents$1
};

var collection = "inspectorValidationIntents";

var toString = tToJs;

var fromString = tFromJs;

export {
  collection ,
  intentsKey ,
  tToJs ,
  tFromJs ,
  toString ,
  toDisplayString ,
  fromString ,
  Encode ,
  Decode ,
}
/* No side effect */
