// Generated by ReScript, PLEASE EDIT WITH CARE


var eventRowHeight = 50;

var propertyRowHeight = 50;

var fixedColumns = 3;

export {
  eventRowHeight ,
  propertyRowHeight ,
  fixedColumns ,
}
/* No side effect */
