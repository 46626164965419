// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Canny from "./externals/canny.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.mjs";
import * as Hooks from "./Hooks.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as QueryString from "query-string";
import * as React$1 from "@sentry/react";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

function setFirestoreUser(user, clearAnonymousValues) {
  var uid = user.uid;
  var isAnonymous = user.isAnonymous;
  var user$1 = isAnonymous ? ({
        id: uid,
        email: user.email,
        createdAt: new Date(user.metadata.creationTime),
        lastSignIn: new Date(user.metadata.lastSignInTime),
        isAnonymous: true,
        name: "Blue Squirrel",
        picture: {
          height: 5336,
          width: 5336,
          storagePath: "https://storage.googleapis.com/avo-cdn/assets/blue-squirrel.png",
          url: "https://lh3.googleusercontent.com/2iZqRpfhCo7gFehcsSMhjpTvQe6F1LhBK5fuEgTx5mcjY3D8RolsMDqhA_QXkmG3N5colqLqOCMQDnGiffQ-AKh60ElzBjX2iHDV"
        }
      }) : ({
        id: uid,
        email: user.email,
        createdAt: new Date(user.metadata.creationTime),
        lastSignIn: new Date(user.metadata.lastSignInTime),
        isAnonymous: false
      });
  var user$2 = clearAnonymousValues ? Object.assign(user$1, {
          name: null,
          picture: null
        }) : user$1;
  $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(uid).set(user$2, {"merge": true}), (function (error) {
          console.error("Could not update user", error);
          return Promise.resolve(undefined);
        }));
  if (isAnonymous) {
    return Promise.resolve(undefined);
  }
  var emailDomain = Belt_Option.getWithDefault(Belt_Array.get(user.email.split("@"), 1), "");
  return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("userEmailDomains").doc(emailDomain).set({
                  id: emailDomain
                }, {"merge": true}), (function (error) {
                console.error("Could not update email domains", error);
                return Promise.resolve(undefined);
              }));
}

function useUser(auth) {
  var match = React.useState(function () {
        
      });
  var setUser = match[1];
  var user = match[0];
  var reloadInterval = React.useRef(undefined);
  var addToast = Toast.useAddToast(undefined);
  var updateUser = function (user) {
    Curry._1(setUser, (function (param) {
            if (user === undefined) {
              return "Unauthenticated";
            }
            var user$1 = Caml_option.valFromOption(user);
            if (user$1.isAnonymous) {
              return {
                      NAME: "Anonymous",
                      VAL: user$1
                    };
            } else if (user$1.emailVerified) {
              return {
                      NAME: "Authenticated",
                      VAL: user$1
                    };
            } else {
              return {
                      NAME: "Unverified",
                      VAL: user$1
                    };
            }
          }));
  };
  React.useEffect((function () {
          var unsubscribe = Firebase.Auth.onAuthStateChanged(auth, updateUser);
          return (function (param) {
                    unsubscribe();
                  });
        }), []);
  React.useEffect((function () {
          var user = auth.currentUser;
          if (user == null) {
            Promise.resolve(undefined);
          } else {
            $$Promise.$$catch(user.getIdToken(true).then(function (param) {
                      return Promise.resolve(undefined);
                    }), (function (error) {
                    var match = AuthenticationMethods.Utils.getAuthenticationErrorFromException(error);
                    if (match[0] === "RefreshTokenExpired") {
                      console.error("Refresh token expired", error);
                      return AuthenticationMethods.signOut(auth).then(function (result) {
                                  if (result.TAG === /* Ok */0) {
                                    updateUser(undefined);
                                    return Promise.resolve(undefined);
                                  }
                                  Curry._1(addToast, {
                                        message: result._0,
                                        toastType: /* Error */1
                                      });
                                  return Promise.resolve(undefined);
                                });
                    } else {
                      console.error("Error while getting id token", match[1]);
                      return Promise.resolve(undefined);
                    }
                  }));
          }
        }), [user]);
  var prevUser = Hooks.useRetained(user);
  React.useEffect((function () {
          var exit = 0;
          if (user !== undefined && typeof user === "object") {
            var variant = user.NAME;
            if (variant === "Unverified") {
              setFirestoreUser(user.VAL, true);
            } else if (variant === "Authenticated" || variant === "Anonymous") {
              var user$1 = user.VAL;
              var wasAnonymous = prevUser !== undefined && typeof prevUser === "object" && prevUser.NAME === "Anonymous" && typeof user === "object" ? user.NAME === "Authenticated" : false;
              setFirestoreUser(user$1, wasAnonymous).then(function (param) {
                    return $$Promise.$$catch(Firebase.app(undefined).firestore().collection("users").doc(user$1.uid).get().then(function (snap) {
                                    var userData = snap.data();
                                    var userData$1 = (userData == null) ? undefined : Caml_option.some(userData);
                                    var maybeName = Belt_Option.flatMap(userData$1, (function (userData) {
                                            return Caml_option.nullable_to_opt(userData.name);
                                          }));
                                    React$1.setUser({
                                          id: user$1.uid
                                        });
                                    AnalyticsRe.authenticated(user$1.uid, Caml_option.nullable_to_opt(user$1.email), maybeName !== undefined ? maybeName : Caml_option.nullable_to_opt(user$1.displayName), user$1.isAnonymous ? "Anonymous" : "Authenticated", user$1.isAnonymous ? "Anonymous" : "Authenticated");
                                    var email = user$1.email;
                                    if (!(email == null)) {
                                      Firebase.Auth.unsafeGetCurrentUser(Firebase.app(undefined).auth()).getIdToken().then(function (userToken) {
                                                return fetch("" + Firebase.apiUrl + "/getCannyHash", Fetch.RequestInit.make(/* Post */2, [
                                                                  [
                                                                    "Accept",
                                                                    "application/json"
                                                                  ],
                                                                  [
                                                                    "Content-Type",
                                                                    "application/json"
                                                                  ],
                                                                  [
                                                                    "Authorization",
                                                                    "Bearer " + userToken + ""
                                                                  ]
                                                                ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                                              }).then(function (response) {
                                              return Fetch.$$Response.json(response);
                                            }).then(function (json) {
                                            var hash = Json_decode.field("hash", Json_decode.string, json);
                                            var tmp = {
                                              email: email,
                                              name: Belt_Option.getWithDefault(maybeName, email),
                                              id: user$1.uid
                                            };
                                            var tmp$1 = Belt_Option.flatMap(userData$1, (function (userData) {
                                                    return Belt_Option.map(Caml_option.nullable_to_opt(userData.picture), (function (prim) {
                                                                  return prim.url;
                                                                }));
                                                  }));
                                            if (tmp$1 !== undefined) {
                                              tmp.avatarURL = Caml_option.valFromOption(tmp$1);
                                            }
                                            var tmp$2 = Belt_Option.flatMap(userData$1, (function (userData) {
                                                    return Belt_Option.map(Models.toDateOption(userData.createdAt), (function (prim) {
                                                                  return prim.toISOString();
                                                                }));
                                                  }));
                                            if (tmp$2 !== undefined) {
                                              tmp.created = Caml_option.valFromOption(tmp$2);
                                            }
                                            Canny.identify({
                                                  appID: Canny.appID,
                                                  user: tmp,
                                                  hash: hash
                                                });
                                          });
                                    }
                                    return Promise.resolve(undefined);
                                  }), (function (error) {
                                  console.log("Canny error", error);
                                  return Promise.resolve(undefined);
                                }));
                  });
              user$1.getIdToken().then(function (token) {
                          return fetch("" + Firebase.apiUrl + "/authenticateIntercom" + (
                                      process.env.NODE_ENV === "development" ? "?dev=true" : ""
                                    ) + "", Fetch.RequestInit.make(/* Post */2, [
                                            [
                                              "Accept",
                                              "application/json"
                                            ],
                                            [
                                              "Content-Type",
                                              "application/json"
                                            ],
                                            [
                                              "Authorization",
                                              "Bearer " + token + ""
                                            ]
                                          ], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
                        }).then(Fetch.$$Response.json).then(Intercom.decodeAuthJson).then(function (intercomAuth) {
                    Intercom.update({
                          email: user$1.email,
                          user_id: intercomAuth.userId,
                          user_hash: intercomAuth.userHash
                        });
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          if (exit === 1 && prevUser !== undefined) {
            var exit$1 = 0;
            if (typeof prevUser === "object") {
              var variant$1 = prevUser.NAME;
              if ((variant$1 === "Authenticated" || variant$1 === "Unverified") && user !== undefined) {
                if (user === "Unauthenticated") {
                  Intercom.shutdown(undefined);
                }
                
              } else {
                exit$1 = 2;
              }
            } else {
              exit$1 = 2;
            }
            if (exit$1 === 2) {
              if (user !== undefined) {
                
              } else {
                Intercom.shutdown(undefined);
              }
            }
            
          }
          var query = window.location.search.replace("?", "");
          var redirect = QueryString.parse(query).redirect;
          var exit$2 = 0;
          var redirect$1;
          var exit$3 = 0;
          if (prevUser !== undefined) {
            if (typeof prevUser === "object") {
              var variant$2 = prevUser.NAME;
              if (variant$2 === "Authenticated" || variant$2 === "Anonymous" || variant$2 === "Unverified") {
                if (user !== undefined) {
                  if (user === "Unauthenticated") {
                    Router.push(undefined, "/");
                  } else {
                    exit$3 = 2;
                  }
                }
                
              } else {
                exit$3 = 2;
              }
            } else {
              exit$3 = 2;
            }
          }
          if (exit$3 === 2 && !(redirect == null)) {
            if (typeof prevUser === "object") {
              if (prevUser.NAME === "Unverified" && user !== undefined && typeof user === "object" && user.NAME === "Authenticated") {
                redirect$1 = redirect;
                exit$2 = 1;
              }
              
            } else if (prevUser === "Unauthenticated" && user !== undefined && typeof user === "object" && user.NAME === "Authenticated") {
              redirect$1 = redirect;
              exit$2 = 1;
            }
            
          }
          if (exit$2 === 1) {
            if (Js_string.startsWith("/", redirect$1)) {
              Router.push(undefined, decodeURIComponent(redirect$1));
            }
            
          }
          var match = reloadInterval.current;
          var exit$4 = 0;
          if (prevUser !== undefined) {
            if (typeof prevUser === "object") {
              var variant$3 = prevUser.NAME;
              if (variant$3 === "Unverified") {
                if (user !== undefined) {
                  if (typeof user === "object") {
                    if (user.NAME === "Authenticated" && match !== undefined) {
                      AnalyticsRe.signUpVerifyCompleted(undefined);
                      clearInterval(Caml_option.valFromOption(match));
                    }
                    
                  } else if (user === "Unauthenticated" && match !== undefined) {
                    clearInterval(Caml_option.valFromOption(match));
                  }
                  
                }
                
              } else if (variant$3 === "Anonymous") {
                exit$4 = 1;
              }
              
            } else if (prevUser === "Unauthenticated") {
              exit$4 = 1;
            }
            
          } else {
            exit$4 = 1;
          }
          if (exit$4 === 1 && user !== undefined && typeof user === "object" && user.NAME === "Unverified") {
            var user$2 = user.VAL;
            reloadInterval.current = Caml_option.some(setInterval((function (param) {
                        user$2.reload().then(function (param) {
                              updateUser(Caml_option.nullable_to_opt(auth.currentUser));
                            });
                      }), 2000));
          }
          
        }), [user]);
  var forceReload = function (param) {
    var user = auth.currentUser;
    if (!(user == null)) {
      user.reload().then(function (param) {
            updateUser(Caml_option.nullable_to_opt(auth.currentUser));
          });
      return ;
    }
    
  };
  return [
          user,
          forceReload
        ];
}

export {
  setFirestoreUser ,
  useUser ,
}
/* Hooks Not a pure module */
