// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prism from "./externals/Prism.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import ReactMarkdown from "react-markdown";

function Markdown(Props) {
  var source = Props.source;
  var stylesOpt = Props.styles;
  var styles = stylesOpt !== undefined ? stylesOpt : /* [] */0;
  return React.createElement("div", {
              className: Curry._1(Css.style, Belt_List.concat({
                        hd: Css.selector(" .MarkdownBody p", {
                              hd: Css.margin4(Css.px(10), Css.px(0), Css.px(4), Css.px(0)),
                              tl: {
                                hd: Css.lineHeight(Css.pct(150.0)),
                                tl: /* [] */0
                              }
                            }),
                        tl: {
                          hd: Css.selector(" .MarkdownBody h1, .MarkdownBody h2, .MarkdownBody h3, .MarkdownBody h4, .MarkdownBody h5, .MarkdownBody h6", {
                                hd: Css.width(Css.pct(100)),
                                tl: {
                                  hd: Css.paddingTop(Css.px(8)),
                                  tl: {
                                    hd: Css.margin4(Css.px(20), Css.px(0), Css.px(5), Css.px(0)),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.semi),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.selector(" .MarkdownBody strong", {
                                  hd: Css.fontWeight(Styles.FontWeight.semi),
                                  tl: /* [] */0
                                }),
                            tl: {
                              hd: Css.selector(" .MarkdownBody ul", {
                                    hd: Css.paddingLeft(Css.px(24)),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.selector(" .MarkdownBody li", {
                                      hd: Css.padding2(Css.px(0), Css.px(6)),
                                      tl: {
                                        hd: Css.fontSize("inherit_"),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.selector(" .MarkdownBody blockquote", {
                                        hd: Css.unsafe("marginInlineStart", "16px"),
                                        tl: {
                                          hd: Css.position("relative"),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.unsafe("content", "' '"),
                                                  tl: {
                                                    hd: Css.position("absolute"),
                                                    tl: {
                                                      hd: Css.width(Css.px(4)),
                                                      tl: {
                                                        hd: Css.height(Css.pct(100.0)),
                                                        tl: {
                                                          hd: Css.left(Css.px(-18)),
                                                          tl: {
                                                            hd: Css.top("zero"),
                                                            tl: {
                                                              hd: Css.backgroundColor(Styles.Color.light09),
                                                              tl: {
                                                                hd: Css.borderRadius(Css.px(2)),
                                                                tl: /* [] */0
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }, styles))
            }, React.createElement(ReactMarkdown, {
                  className: "MarkdownBody",
                  children: source,
                  components: {
                    pre: (function (props) {
                        try {
                          var codeElementProps = Belt_Option.getExn(Belt_Array.get(props.children, 0)).props;
                          return React.createElement(Prism.make, {
                                      snippet: String(codeElementProps.children).replace(/\n$/, ""),
                                      language: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(/language-(\w+)/.exec(codeElementProps.className)), (function (result) {
                                                          return Belt_Array.get(result, 1);
                                                        })), (function (prim) {
                                                      if (prim == null) {
                                                        return ;
                                                      } else {
                                                        return Caml_option.some(prim);
                                                      }
                                                    })), Prism.languageFromJs), "javascript"),
                                      className: Curry._1(Css.style, {
                                            hd: Css.padding4(Css.px(8), Css.px(16), Css.px(8), Css.px(16)),
                                            tl: {
                                              hd: Css.backgroundColor(Styles.Color.dark04),
                                              tl: {
                                                hd: Css.borderRadius(Styles.Border.radius),
                                                tl: /* [] */0
                                              }
                                            }
                                          })
                                    });
                        }
                        catch (exn){
                          return React.createElement("pre", props);
                        }
                      }),
                    code: (function (props) {
                        try {
                          return React.createElement(Prism.make, {
                                      snippet: String(props.children).replace(/\n$/, ""),
                                      language: Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(/language-(\w+)/.exec(props.className)), (function (result) {
                                                          return Belt_Array.get(result, 1);
                                                        })), (function (prim) {
                                                      if (prim == null) {
                                                        return ;
                                                      } else {
                                                        return Caml_option.some(prim);
                                                      }
                                                    })), Prism.languageFromJs), "javascript"),
                                      className: Curry._1(Css.style, {
                                            hd: Css.padding4(Css.px(1), Css.px(8), Css.px(3), Css.px(8)),
                                            tl: {
                                              hd: Css.display("inline"),
                                              tl: {
                                                hd: Css.fontSize(Css.pct(85.0)),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.dark04),
                                                  tl: {
                                                    hd: Css.borderRadius(Styles.Border.radius),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          })
                                    });
                        }
                        catch (exn){
                          return React.createElement("pre", props);
                        }
                      })
                  }
                }));
}

var make = Markdown;

export {
  make ,
}
/* Css Not a pure module */
