// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Domain__Json from "../../model/src/domains/Domain__Json.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

function getCollectionRef(schemaId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("domains");
}

function getMembersIndexCollectionRef(schemaId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("domainMembersIndex");
}

function getMembersIndexCollectionId(domainId, memberId) {
  return "" + domainId + "::" + memberId + "";
}

var encode = Domain__Json.encode;

function encode$1(member) {
  return Domain__Json.Member.encode(member);
}

var decode = Domain__Json.Member.decode;

var Member = {
  encode: encode$1,
  decode: decode
};

function _addUpdateMetadataToBatch(batch, viewerId, ref) {
  batch.update(ref, {
        lastUpdatedBy: viewerId,
        lastUpdatedAt: Json_encode.date(new Date())
      });
}

function _updateDomainWithTransaction(actionFn, context, updateFn, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  return Firebase.app(undefined).firestore().runTransaction(function (transaction) {
              var ref = getCollectionRef(schemaId).doc(domainId);
              return $$Promise.$$catch(transaction.get(ref).then(function (doc) {
                                if (!doc.exists) {
                                  return Promise.reject(Js_exn.raiseError("Updating Domain: domain does not exist"));
                                }
                                var domain = Domain__Json.decode(doc.data());
                                var init = Curry._1(updateFn, domain);
                                var updatedDomain_id = init.id;
                                var updatedDomain_name = init.name;
                                var updatedDomain_description = init.description;
                                var updatedDomain_items = init.items;
                                var updatedDomain_createdAt = init.createdAt;
                                var updatedDomain_lastUpdatedAt = new Date();
                                var updatedDomain_archived = init.archived;
                                var updatedDomain = {
                                  id: updatedDomain_id,
                                  name: updatedDomain_name,
                                  description: updatedDomain_description,
                                  items: updatedDomain_items,
                                  createdAt: updatedDomain_createdAt,
                                  lastUpdatedAt: updatedDomain_lastUpdatedAt,
                                  lastUpdatedBy: viewerId,
                                  archived: updatedDomain_archived
                                };
                                transaction.update(ref, Domain__Json.encode(updatedDomain));
                                return Promise.resolve([
                                            domain,
                                            updatedDomain
                                          ]);
                              }).then(function (param) {
                              Curry.app(sendActions, [
                                    "Master",
                                    true,
                                    undefined,
                                    undefined,
                                    true,
                                    undefined,
                                    undefined,
                                    onSuccess,
                                    onError,
                                    [[
                                        Curry._2(actionFn, param[0], param[1]),
                                        context
                                      ]]
                                  ]);
                            }), (function (exn) {
                            Curry._1(onError, exn);
                            return Promise.resolve(undefined);
                          }));
            });
}

function useAllDomainMembers(mode, schemaId) {
  var workspace = WorkspaceContext.use(undefined);
  var workspaceMembersMap = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_List.toArray(Belt_List.mapU(workspace.members, (function (member) {
                                return [
                                        member.id,
                                        member
                                      ];
                              }))));
        }), [workspace.members]);
  var membersProgress = FirebaseFetcherHooks.useStreamCollectionWithProgress(getMembersIndexCollectionRef(schemaId), (function (q) {
          return q;
        }), undefined, mode, schemaId, undefined);
  return React.useMemo((function () {
                if (typeof membersProgress === "object") {
                  return {
                          NAME: "Loaded",
                          VAL: Belt_Array.keepMapU(Belt_List.toArray(membersProgress.VAL), (function (memberJson) {
                                  var member = Curry._1(decode, memberJson);
                                  if (Belt_MapString.has(workspaceMembersMap, member.id)) {
                                    return member;
                                  }
                                  
                                }))
                        };
                } else if (membersProgress === "Initial") {
                  return "Initial";
                } else {
                  return "Error";
                }
              }), [membersProgress]);
}

function useDomains(mode, schemaId) {
  var domainProgress = FirebaseFetcherHooks.useStreamCollectionWithProgress(getCollectionRef(schemaId), (function (query) {
          return query.where("archived", "==", false);
        }), undefined, mode, schemaId, undefined);
  return React.useMemo((function () {
                if (typeof domainProgress === "object") {
                  return {
                          NAME: "Loaded",
                          VAL: Belt_Array.mapU(Belt_List.toArray(domainProgress.VAL), Domain__Json.decode)
                        };
                } else if (domainProgress === "Initial") {
                  return "Initial";
                } else {
                  return "Error";
                }
              }), [domainProgress]);
}

function useDomain(schemaId, domainId) {
  var domainProgress = FirebaseFetcherHooks.useDoc(getCollectionRef(schemaId), domainId, true);
  return React.useMemo((function () {
                if (typeof domainProgress === "object") {
                  return {
                          NAME: "Success",
                          VAL: Domain__Json.decode(domainProgress.VAL)
                        };
                } else if (domainProgress === "NotFound") {
                  return "NotFound";
                } else {
                  return "Loading";
                }
              }), [domainProgress]);
}

function createDomain(description, items, name, onErrorOpt, onSuccessOpt, sendActions, viewerId, schemaId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var batch = Firebase.app(undefined).firestore().batch();
  var ref = getCollectionRef(schemaId).doc();
  var date = new Date();
  var id = ref.id;
  var domain = {
    id: id,
    name: name,
    description: description,
    items: items,
    createdAt: date,
    lastUpdatedAt: date,
    lastUpdatedBy: viewerId,
    archived: false
  };
  batch.set(ref, Domain__Json.encode(domain));
  var action_1 = [
    id,
    name
  ];
  var action = {
    NAME: "CreateDomain",
    VAL: action_1
  };
  var context = {
    eventIds: items.events,
    eventsQuery: items.events,
    eventVariantIds: items.eventVariants,
    eventVariantsQuery: items.eventVariants,
    domainId: id,
    domainQuery: id
  };
  return new Promise((function (resolve, reject) {
                Curry.app(sendActions, [
                      "Master",
                      true,
                      Caml_option.some(batch),
                      undefined,
                      true,
                      undefined,
                      undefined,
                      (function (_branch) {
                          Curry._1(onSuccess, id);
                          resolve(id);
                        }),
                      (function (exn) {
                          Curry._1(onError, exn);
                          reject(exn);
                        }),
                      [[
                          action,
                          context
                        ]]
                    ]);
              }));
}

function archiveDomain(onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  return _updateDomainWithTransaction((function (_previousDomain, updatedDomain) {
                return {
                        NAME: "ArchiveDomain",
                        VAL: updatedDomain.id
                      };
              }), {
              domainId: domainId,
              domainQuery: domainId
            }, (function (domain) {
                return {
                        id: domain.id,
                        name: domain.name,
                        description: domain.description,
                        items: domain.items,
                        createdAt: domain.createdAt,
                        lastUpdatedAt: domain.lastUpdatedAt,
                        lastUpdatedBy: domain.lastUpdatedBy,
                        archived: true
                      };
              }), onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function updateDomainName(name, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  return _updateDomainWithTransaction((function (previousDomain, updatedDomain) {
                return {
                        NAME: "UpdateDomainName",
                        VAL: [
                          updatedDomain.id,
                          previousDomain.name,
                          updatedDomain.name
                        ]
                      };
              }), {
              domainId: domainId,
              domainQuery: domainId
            }, (function (domain) {
                return {
                        id: domain.id,
                        name: name,
                        description: domain.description,
                        items: domain.items,
                        createdAt: domain.createdAt,
                        lastUpdatedAt: domain.lastUpdatedAt,
                        lastUpdatedBy: domain.lastUpdatedBy,
                        archived: domain.archived
                      };
              }), onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function updateDomainDescription(description, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  return _updateDomainWithTransaction((function (_previousDomain, updatedDomain) {
                return {
                        NAME: "UpdateDomainDescription",
                        VAL: [
                          updatedDomain.id,
                          updatedDomain.description
                        ]
                      };
              }), {
              domainId: domainId,
              domainQuery: domainId
            }, (function (domain) {
                return {
                        id: domain.id,
                        name: domain.name,
                        description: description,
                        items: domain.items,
                        createdAt: domain.createdAt,
                        lastUpdatedAt: domain.lastUpdatedAt,
                        lastUpdatedBy: domain.lastUpdatedBy,
                        archived: domain.archived
                      };
              }), onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function addDomainMembers(members, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var batch = Firebase.app(undefined).firestore().batch();
  Belt_Array.forEachU(members, (function (member) {
          var ref = getCollectionRef(schemaId).doc(domainId).collection("members").doc(member.id);
          var membersIndexRef = getMembersIndexCollectionRef(schemaId).doc(getMembersIndexCollectionId(domainId, member.id));
          batch.set(ref, Domain__Json.Member.encode(member));
          batch.set(membersIndexRef, Domain__Json.Member.encode(member));
        }));
  _addUpdateMetadataToBatch(batch, viewerId, getCollectionRef(schemaId).doc(domainId));
  Curry.app(sendActions, [
        "Master",
        true,
        Caml_option.some(batch),
        undefined,
        true,
        undefined,
        undefined,
        onSuccess,
        onError,
        [[
            {
              NAME: "AddDomainMembers",
              VAL: [
                domainId,
                Belt_Array.mapU(members, (function (member) {
                        return [
                                member.id,
                                member.role
                              ];
                      }))
              ]
            },
            {
              domainId: domainId,
              domainQuery: domainId
            }
          ]]
      ]);
}

function addDomainMember(member, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  addDomainMembers([member], onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function removeDomainMembers(members, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var batch = Firebase.app(undefined).firestore().batch();
  Belt_Array.forEachU(members, (function (member) {
          var ref = getCollectionRef(schemaId).doc(domainId).collection("members").doc(member.id);
          var membersIndexRef = getMembersIndexCollectionRef(schemaId).doc(getMembersIndexCollectionId(domainId, member.id));
          batch.delete(ref);
          batch.delete(membersIndexRef);
        }));
  _addUpdateMetadataToBatch(batch, viewerId, getCollectionRef(schemaId).doc(domainId));
  Curry.app(sendActions, [
        "Master",
        true,
        Caml_option.some(batch),
        undefined,
        true,
        undefined,
        undefined,
        onSuccess,
        onError,
        [[
            {
              NAME: "RemoveDomainMembers",
              VAL: [
                domainId,
                Belt_Array.mapU(members, (function (member) {
                        return member.id;
                      }))
              ]
            },
            {
              domainId: domainId,
              domainQuery: domainId
            }
          ]]
      ]);
}

function removeDomainMember(member, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  removeDomainMembers([member], onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function updateDomainMember(memberId, role, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var batch = Firebase.app(undefined).firestore().batch();
  var ref = getCollectionRef(schemaId).doc(domainId).collection("members").doc(memberId);
  var membersIndexRef = getMembersIndexCollectionRef(schemaId).doc(getMembersIndexCollectionId(domainId, memberId));
  batch.update(ref, Domain__Json.Member.encode({
            id: memberId,
            role: role,
            domainId: domainId
          }));
  batch.update(membersIndexRef, Domain__Json.Member.encode({
            id: memberId,
            role: role,
            domainId: domainId
          }));
  _addUpdateMetadataToBatch(batch, viewerId, getCollectionRef(schemaId).doc(domainId));
  Curry.app(sendActions, [
        "Master",
        true,
        Caml_option.some(batch),
        undefined,
        true,
        undefined,
        undefined,
        onSuccess,
        onError,
        [[
            {
              NAME: "UpdateDomainMemberRole",
              VAL: [
                domainId,
                memberId,
                role
              ]
            },
            {
              domainId: domainId,
              domainQuery: domainId
            }
          ]]
      ]);
}

function addDomainItems(items, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var eventIds = Belt_Array.keepMapU(items, (function (item) {
          if (item.NAME === "event") {
            return item.VAL;
          }
          
        }));
  var eventVariantIds = Belt_Array.keepMapU(items, (function (item) {
          if (item.NAME === "event") {
            return ;
          } else {
            return item.VAL;
          }
        }));
  return _updateDomainWithTransaction((function (_previousDomain, updatedDomain) {
                return {
                        NAME: "AddDomainItems",
                        VAL: [
                          updatedDomain.id,
                          items
                        ]
                      };
              }), {
              eventIds: eventIds,
              eventsQuery: eventIds,
              eventVariantIds: eventVariantIds,
              eventVariantsQuery: eventVariantIds,
              domainId: domainId,
              domainQuery: domainId
            }, (function (domain) {
                return {
                        id: domain.id,
                        name: domain.name,
                        description: domain.description,
                        items: {
                          events: Belt_Array.concat(domain.items.events, eventIds),
                          eventVariants: Belt_Array.concat(domain.items.eventVariants, eventVariantIds)
                        },
                        createdAt: domain.createdAt,
                        lastUpdatedAt: domain.lastUpdatedAt,
                        lastUpdatedBy: domain.lastUpdatedBy,
                        archived: domain.archived
                      };
              }), onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function removeDomainItems(items, onErrorOpt, onSuccessOpt, schemaId, sendActions, viewerId, domainId) {
  var onError = onErrorOpt !== undefined ? onErrorOpt : (function (param) {
        
      });
  var onSuccess = onSuccessOpt !== undefined ? onSuccessOpt : (function (param) {
        
      });
  var eventIds = Belt_Array.keepMapU(items, (function (item) {
          if (item.NAME === "event") {
            return item.VAL;
          }
          
        }));
  var eventVariantIds = Belt_Array.keepMapU(items, (function (item) {
          if (item.NAME === "event") {
            return ;
          } else {
            return item.VAL;
          }
        }));
  var eventIdsSet = Belt_SetString.fromArray(eventIds);
  var eventVariantIdsSet = Belt_SetString.fromArray(eventVariantIds);
  return _updateDomainWithTransaction((function (_previousDomain, updatedDomain) {
                return {
                        NAME: "RemoveDomainItems",
                        VAL: [
                          updatedDomain.id,
                          items
                        ]
                      };
              }), {
              eventIds: eventIds,
              eventsQuery: eventIds,
              eventVariantIds: eventVariantIds,
              eventVariantsQuery: eventVariantIds,
              domainId: domainId,
              domainQuery: domainId
            }, (function (domain) {
                return {
                        id: domain.id,
                        name: domain.name,
                        description: domain.description,
                        items: {
                          events: Belt_Array.keepU(domain.items.events, (function (id) {
                                  return !Belt_SetString.has(eventIdsSet, id);
                                })),
                          eventVariants: Belt_Array.keepU(domain.items.eventVariants, (function (id) {
                                  return !Belt_SetString.has(eventVariantIdsSet, id);
                                }))
                        },
                        createdAt: domain.createdAt,
                        lastUpdatedAt: domain.lastUpdatedAt,
                        lastUpdatedBy: domain.lastUpdatedBy,
                        archived: domain.archived
                      };
              }), onError, onSuccess, schemaId, sendActions, viewerId, domainId);
}

function getDomains(schemaId) {
  return getCollectionRef(schemaId).where("archived", "==", false).get().then(function (snapshot) {
              return Belt_Array.mapU(snapshot.docs, (function (doc) {
                            return Domain__Json.decode(doc.data());
                          }));
            });
}

var Firebase$1 = {
  getCollectionRef: getCollectionRef,
  getMembersIndexCollectionRef: getMembersIndexCollectionRef,
  getMembersIndexCollectionId: getMembersIndexCollectionId,
  encode: encode,
  Member: Member,
  useAllDomainMembers: useAllDomainMembers,
  useDomains: useDomains,
  useDomain: useDomain,
  createDomain: createDomain,
  archiveDomain: archiveDomain,
  updateDomainName: updateDomainName,
  updateDomainDescription: updateDomainDescription,
  addDomainMembers: addDomainMembers,
  addDomainMember: addDomainMember,
  removeDomainMembers: removeDomainMembers,
  removeDomainMember: removeDomainMember,
  updateDomainMember: updateDomainMember,
  addDomainItems: addDomainItems,
  removeDomainItems: removeDomainItems,
  getDomains: getDomains
};

function getItemCount(domain) {
  return domain.items.events.length + domain.items.eventVariants.length | 0;
}

export {
  Firebase$1 as Firebase,
  getItemCount ,
}
/* react Not a pure module */
