// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";

function LoadingFullscreen(Props) {
  var message = Props.message;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("center"),
                        tl: {
                          hd: Css.flexDirection("column"),
                          tl: {
                            hd: Css.minHeight(Css.vh(100)),
                            tl: {
                              hd: Css.color(Styles.Color.light08),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.medium),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(Icons.AvoLogo.make, {
                  width: 140,
                  fill: Styles.Color.light04
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.padding(Css.px(25)),
                        tl: /* [] */0
                      })
                }, message));
}

var make = LoadingFullscreen;

export {
  make ,
}
/* Css Not a pure module */
