// Generated by ReScript, PLEASE EDIT WITH CARE

import * as StateContext from "./StateContext.mjs";

var Provider = StateContext.Make({
      initial: undefined
    });

var Active;

var SetActive;

export {
  Provider ,
  Active ,
  SetActive ,
}
/* Provider Not a pure module */
