// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as IconGoogle from "./IconGoogle.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function ContinueWithGoogleButton(Props) {
  var onClick = Props.onClick;
  var disabled = Props.disabled;
  var labelOpt = Props.label;
  var backgroundColorOpt = Props.backgroundColor;
  var textColorOpt = Props.textColor;
  var label = labelOpt !== undefined ? labelOpt : "Continue with Google";
  var backgroundColor = backgroundColorOpt !== undefined ? backgroundColorOpt : Styles.Color.avoPink;
  var textColor = textColorOpt !== undefined ? textColorOpt : Styles.Color.white;
  var tmp = {
    className: Curry._1(Css.style, {
          hd: Css.display("flex"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.justifyContent("spaceBetween"),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.margin2(Css.px(5), Css.px(0)),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.borderColor(Styles.Color.darken(backgroundColor, 10)),
                      tl: {
                        hd: Css.borderStyle("solid"),
                        tl: {
                          hd: Css.borderWidth(Css.px(Caml_obj.equal(backgroundColor, Styles.Color.white) ? 1 : 0)),
                          tl: {
                            hd: Css.backgroundColor(backgroundColor),
                            tl: {
                              hd: Css.color(textColor),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.bold),
                                tl: {
                                  hd: Css.outlineColor(backgroundColor),
                                  tl: {
                                    hd: Css.transition({
                                          NAME: "ms",
                                          VAL: Styles.Duration.$$default
                                        }, undefined, undefined, "all"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.hover({
                                              hd: Css.backgroundColor(Styles.Color.darken(backgroundColor, 5)),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.padding2(Css.px(8), Css.px(15)),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.height("auto"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.borderRadius(Css.pct(100)),
                              tl: {
                                hd: Css.width(Css.px(24)),
                                tl: {
                                  hd: Css.height(Css.px(24)),
                                  tl: {
                                    hd: Css.backgroundColor(Styles.Color.white),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(IconGoogle.make, {
                      size: 18
                    })), label, React.createElement("div", undefined));
}

var make = ContinueWithGoogleButton;

export {
  make ,
}
/* Css Not a pure module */
