// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import Downloadjs from "downloadjs";
import * as ExportFormat from "../../shared/models/ExportFormat.mjs";
import * as Core from "@mantine/core";
import * as SimpleTooltip from "./SimpleTooltip.mjs";

function Make(Config) {
  var getLine = function (columns, getCell) {
    return function (item) {
      return Belt_Array.keepMapU(columns, (function (param) {
                      if (param[1]) {
                        return "\"" + Curry._2(getCell, param[0], item) + "\"";
                      }
                      
                    })).join(",");
    };
  };
  var getCsv = function (columns, getColumnName, getCell, items) {
    var header = Belt_Array.keepMapU(columns, (function (param) {
              if (param[1]) {
                return "\"" + Curry._1(getColumnName, param[0]) + "\"";
              }
              
            })).join(",") + "\n";
    var events = Belt_Array.mapU(items, getLine(columns, getCell)).join("\n");
    return header + events;
  };
  var ExportButton$Make = function (Props) {
    var getCell = Props.getCell;
    var getColumnName = Props.getColumnName;
    var getColumns = Props.getColumns;
    var items = Props.items;
    var name = Props.name;
    var tooltip = Props.tooltip;
    var match = React.useState(function () {
          return Belt_Array.mapU(Curry._1(getColumns, undefined), (function (column) {
                        return [
                                column,
                                true
                              ];
                      }));
        });
    var setColumns = match[1];
    var columns = match[0];
    var addToast = Toast.useAddToast(undefined);
    var handleExport = React.useCallback((function (param) {
            var csv = getCsv(columns, getColumnName, getCell, items);
            var match = ExportFormat.toHeader(/* Csv */2);
            var mimeType = match[0];
            Downloadjs(new Blob([csv], {
                      encoding: "UTF-8",
                      type: mimeType
                    }), "" + name + "." + match[1] + "", mimeType);
            Curry._1(addToast, {
                  message: "Export complete!",
                  toastType: /* Success */0
                });
          }), [
          items,
          columns
        ]);
    var match$1 = React.useState(function () {
          return false;
        });
    var setOpened = match$1[1];
    return React.createElement(SimpleTooltip.make, {
                disabled: match$1[0],
                tooltip: tooltip,
                children: React.createElement(Mantine.Menu.make, {
                      onClose: (function (param) {
                          Curry._1(setOpened, (function (param) {
                                  return false;
                                }));
                        }),
                      onOpen: (function (param) {
                          Curry._1(setOpened, (function (param) {
                                  return true;
                                }));
                        }),
                      children: null
                    }, React.createElement(Core.Menu.Target, {
                          children: React.createElement(Button.make, {
                                icon: "share",
                                label: "Export as CSV",
                                style: "outline"
                              })
                        }), React.createElement(Mantine.Menu.Dropdown.make, {
                          additionalStyles: {
                            hd: Css.padding(Css.px(20)),
                            tl: {
                              hd: Css.maxWidth(Css.px(300)),
                              tl: /* [] */0
                            }
                          },
                          children: null
                        }, React.createElement("header", undefined, React.createElement($$Text.make, {
                                  size: "Small",
                                  color: Styles.Color.light09,
                                  children: StringExt.widowless("Use this menu to export items to CSV. The following columns will be included in the export:")
                                })), React.createElement(Spacer.make, {
                              height: 32
                            }), React.createElement("section", undefined, Belt_Array.mapU(columns, (function (param) {
                                    var column = param[0];
                                    return React.createElement("li", {
                                                key: Curry._1(getColumnName, column),
                                                className: Curry._1(Css.style, {
                                                      hd: Css.listStyleType("none"),
                                                      tl: {
                                                        hd: Css.display("flex"),
                                                        tl: {
                                                          hd: Css.alignItems("center"),
                                                          tl: {
                                                            hd: Css.gap(Css.px(8)),
                                                            tl: {
                                                              hd: Css.marginBottom(Css.px(4)),
                                                              tl: {
                                                                hd: Css.lastOfType({
                                                                      hd: Css.marginBottom(Css.px(4)),
                                                                      tl: /* [] */0
                                                                    }),
                                                                tl: {
                                                                  hd: Css.hover({
                                                                        hd: Css.backgroundColor(Styles.Color.light02),
                                                                        tl: /* [] */0
                                                                      }),
                                                                  tl: /* [] */0
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    })
                                              }, React.createElement("input", {
                                                    id: "export-" + Curry._1(getColumnName, column) + "",
                                                    checked: param[1],
                                                    type: "checkbox",
                                                    onClick: (function (param) {
                                                        Curry._1(setColumns, (function (currentColumns) {
                                                                return Belt_Array.mapU(currentColumns, (function (currentColumn) {
                                                                              var c = currentColumn[0];
                                                                              if (Caml_obj.equal(column, c)) {
                                                                                return [
                                                                                        c,
                                                                                        !currentColumn[1]
                                                                                      ];
                                                                              } else {
                                                                                return currentColumn;
                                                                              }
                                                                            }));
                                                              }));
                                                      })
                                                  }), React.createElement("label", {
                                                    htmlFor: "export-" + Curry._1(getColumnName, column) + ""
                                                  }, React.createElement($$Text.make, {
                                                        size: "Small",
                                                        weight: "Semi",
                                                        color: Styles.Color.light11,
                                                        children: Curry._1(getColumnName, column)
                                                      })));
                                  })), React.createElement(Spacer.make, {
                                  height: 16
                                }), React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.justifyContent("flexEnd"),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement(Button.make, {
                                      label: "Export",
                                      onClick: handleExport
                                    })))))
              });
  };
  return {
          getLine: getLine,
          getCsv: getCsv,
          make: ExportButton$Make
        };
}

export {
  Make ,
}
/* Css Not a pure module */
