// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var baseStyles_0 = Css.fontWeight(Styles.FontWeight.semi);

var baseStyles_1 = {
  hd: Css.lineHeight(Css.pct(140.0)),
  tl: {
    hd: Css.margin("zero"),
    tl: /* [] */0
  }
};

var baseStyles = {
  hd: baseStyles_0,
  tl: baseStyles_1
};

function sizeStyles(size) {
  if (size === "Small") {
    return {
            hd: Css.fontSize(Css.px(24)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.019)),
              tl: /* [] */0
            }
          };
  } else if (size === "Huge") {
    return {
            hd: Css.fontSize(Css.px(44)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.022)),
              tl: /* [] */0
            }
          };
  } else if (size === "Medium") {
    return {
            hd: Css.fontSize(Css.px(28)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.02)),
              tl: /* [] */0
            }
          };
  } else if (size === "Tiny") {
    return {
            hd: Css.fontSize(Css.px(20)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.017)),
              tl: /* [] */0
            }
          };
  } else {
    return {
            hd: Css.fontSize(Css.px(32)),
            tl: {
              hd: Css.letterSpacing(Css.em(-0.021)),
              tl: /* [] */0
            }
          };
  }
}

function Title(Props) {
  var children = Props.children;
  var customColorOpt = Props.color;
  var elementOpt = Props.element;
  var shrink = Props.shrink;
  var title = Props.title;
  var singleLineOpt = Props.singleLine;
  var size = Props.size;
  var customColor = customColorOpt !== undefined ? customColorOpt : Styles.Color.light12;
  var element = elementOpt !== undefined ? elementOpt : "H1";
  var singleLine = singleLineOpt !== undefined ? singleLineOpt : false;
  var styles = Belt_List.concatMany([
        sizeStyles(size),
        baseStyles,
        {
          hd: Css.color(customColor),
          tl: /* [] */0
        },
        Belt_Option.mapWithDefault(shrink, /* [] */0, (function (shrink) {
                return {
                        hd: Css.flexShrink(shrink),
                        tl: /* [] */0
                      };
              })),
        singleLine ? ({
              hd: Css.textOverflow("ellipsis"),
              tl: {
                hd: Css.whiteSpace("nowrap"),
                tl: {
                  hd: Css.overflow("hidden"),
                  tl: /* [] */0
                }
              }
            }) : /* [] */0
      ]);
  if (element === "H1") {
    var tmp = {
      className: Curry._1(Css.style, styles)
    };
    if (title !== undefined) {
      tmp.title = Caml_option.valFromOption(title);
    }
    return React.createElement("h1", tmp, children);
  }
  if (element === "H2") {
    var tmp$1 = {
      className: Curry._1(Css.style, styles)
    };
    if (title !== undefined) {
      tmp$1.title = Caml_option.valFromOption(title);
    }
    return React.createElement("h2", tmp$1, children);
  }
  var tmp$2 = {
    className: Curry._1(Css.style, styles)
  };
  if (title !== undefined) {
    tmp$2.title = Caml_option.valFromOption(title);
  }
  return React.createElement("p", tmp$2, children);
}

var make = Title;

export {
  baseStyles ,
  sizeStyles ,
  make ,
}
/* baseStyles Not a pure module */
