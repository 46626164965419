// Generated by ReScript, PLEASE EDIT WITH CARE


function addEventListener(prim0, prim1, prim2, prim3) {
  prim0.addEventListener(prim1, prim2, prim3);
}

function toPromise(signal) {
  return new Promise((function (resolve, _reject) {
                if (signal.aborted) {
                  return resolve("aborted");
                } else {
                  return addEventListener(signal, "abort", (function (param) {
                                resolve("aborted");
                              }), {
                              once: true
                            });
                }
              }));
}

var Signal = {
  addEventListener: addEventListener,
  toPromise: toPromise
};

export {
  Signal ,
}
/* No side effect */
