// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";

function DomainDetails__Components$Card(Props) {
  var children = Props.children;
  return React.createElement("section", {
              className: Curry._1(Css.style, {
                    hd: Css.outline(Css.px(1), "solid", Styles.Color.light06),
                    tl: {
                      hd: Css.borderRadius(Styles.Border.radius),
                      tl: {
                        hd: Css.width(Css.px(640)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var Card = {
  make: DomainDetails__Components$Card
};

function DomainDetails__Components$CardSection(Props) {
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  return React.createElement("div", {
              className: Curry._1(Css.merge, {
                    hd: Curry._1(Css.style, {
                          hd: Css.padding2(Css.px(16), Css.px(32)),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: className,
                      tl: /* [] */0
                    }
                  })
            }, children);
}

var CardSection = {
  make: DomainDetails__Components$CardSection
};

var variantPillStyles = Curry._1(Css.style, {
      hd: Css.padding4(Css.px(3), Css.px(10), Css.px(3), Css.px(8)),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(2)),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light06),
              tl: {
                hd: Css.borderRadius(Css.px(100)),
                tl: {
                  hd: Css.flexShrink(0),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function DomainDetails__Components$VariantPill(Props) {
  return React.createElement("div", {
              className: variantPillStyles
            }, React.createElement($$Text.make, {
                  size: "Tiny",
                  color: Styles.Color.light11,
                  children: "Variant"
                }));
}

var VariantPill = {
  variantPillStyles: variantPillStyles,
  make: DomainDetails__Components$VariantPill
};

export {
  Card ,
  CardSection ,
  VariantPill ,
}
/* variantPillStyles Not a pure module */
