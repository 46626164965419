// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as IconCsv from "./IconCsv.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import Downloadjs from "downloadjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as DomainStore from "./DomainStore.mjs";
import * as KeyListener from "./KeyListener.mjs";
import * as AvoCsvExport from "../../codegen/src/AvoCsvExport.mjs";
import * as ExportFormat from "../../shared/models/ExportFormat.mjs";
import * as AvoJsonSchema from "../../codegen/src/AvoJsonSchema.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as PublishingUtils from "../../codegen/src/PublishingUtils.mjs";
import * as ModalCloseButton from "./ModalCloseButton.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";

var root = Curry._1(Css.style, {
      hd: Css.position("fixed"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.top(Css.px(0)),
              tl: {
                hd: Css.right(Css.px(0)),
                tl: {
                  hd: Css.left(Css.px(0)),
                  tl: {
                    hd: Css.bottom(Css.px(0)),
                    tl: {
                      hd: Css.zIndex(1),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var backdrop = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.top(Css.px(0)),
        tl: {
          hd: Css.right(Css.px(0)),
          tl: {
            hd: Css.left(Css.px(0)),
            tl: {
              hd: Css.bottom(Css.px(0)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var modal = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.borderRadius(Css.px(10)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.paddingTop(Css.px(60)),
                  tl: {
                    hd: Css.paddingRight(Css.px(40)),
                    tl: {
                      hd: Css.paddingBottom(Css.px(40)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: {
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(8), Css.px(32), undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.2))),
                          tl: {
                            hd: Css.zIndex(1),
                            tl: {
                              hd: Css.maxWidth(Css.pct(90)),
                              tl: {
                                hd: Css.overflow("auto"),
                                tl: {
                                  hd: Css.maxHeight(Css.vh(80)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var options = Curry._1(Css.style, {
      hd: Css.media("(max-width: 1100px)", {
            hd: Css.flexDirection("column"),
            tl: /* [] */0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.alignItems("stretch"),
          tl: {
            hd: Css.marginTop(Css.px(32)),
            tl: /* [] */0
          }
        }
      }
    });

var option = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.width(Css.px(320)),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$long
                    }, undefined, undefined, "background"),
                tl: {
                  hd: Css.borderRadius(Styles.Border.radius),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var separator = Curry._1(Css.style, {
      hd: Css.width(Css.px(1)),
      tl: {
        hd: Css.backgroundColor(Styles.Color.light04),
        tl: {
          hd: Css.flexShrink(0.0),
          tl: {
            hd: Css.marginLeft(Css.px(20)),
            tl: {
              hd: Css.marginRight(Css.px(20)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var Style = {
  root: root,
  backdrop: backdrop,
  modal: modal,
  options: options,
  option: option,
  separator: separator
};

function getExportedContent(ofFormat, model, schemaBundle, schemaId, isGlobalWorkspace, eventsToDomains) {
  var branchId = schemaBundle.branchId;
  var branchName = schemaBundle.branchName;
  var makeSourceValid = function (source) {
    return TrackingPlanModel.Source.makeValidWithDefaultValues(source, "Untitled Source", "json", "JsonSchema");
  };
  var validSources = Belt_List.map(model.sources, makeSourceValid);
  var includedSources = PublishingUtils.getIncludedSources(/* [] */0, validSources, model.sources);
  switch (ofFormat) {
    case /* JsonSchema */0 :
        return Curry._2(AvoJsonSchema.generateJsonSchema("Exported Tracking Plan: " + branchName + "", validSources, undefined, includedSources, true, TrackingPlanMappedModel.fromOld(model))(schemaId), branchId, isGlobalWorkspace);
    case /* AvoJson */1 :
        return JSON.stringify(Printer.printModel(model));
    case /* Csv */2 :
        return AvoCsvExport.generateCsvSchema(Belt_List.map(model.sources, makeSourceValid), schemaId, branchId, eventsToDomains, model);
    
  }
}

function ExportModal(Props) {
  var onClose = Props.onClose;
  var model = Props.model;
  var schemaBundle = Props.schemaBundle;
  React.useEffect((function () {
          AnalyticsRe.exportSchemaStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var avoJsonExportEnabled = AppFeatureFlag.useFeatureFlag("ExportAvoJson");
  var eventsToDomains = DomainStore.useDomainEvents(undefined);
  var handleExportClick = React.useCallback(function (ofFormat) {
        return function (_e) {
          AnalyticsRe.exportSchemaFormatSelected(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, ExportFormat.toAnalytics(ofFormat), schemaBundle.branchId, schemaBundle.schemaId);
          var content = getExportedContent(ofFormat, model, schemaBundle, schemaBundle.schemaId, isGlobalWorkspace, eventsToDomains);
          var match = ExportFormat.toHeader(ofFormat);
          var mimeType = match[0];
          Downloadjs(new Blob([content], {
                    encoding: "UTF-8",
                    type: mimeType
                  }), "AvoTrackingPlan." + match[1] + "", mimeType);
          return AnalyticsRe.exportSchemaCompleted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, ExportFormat.toAnalytics(ofFormat), schemaBundle.branchId, schemaBundle.schemaId);
        };
      });
  return React.createElement("div", {
              className: root
            }, React.createElement(KeyListener.make, {
                  keyName: "Escape",
                  onPress: (function (param) {
                      Curry._1(onClose, undefined);
                    })
                }), React.createElement("div", {
                  className: backdrop,
                  onClick: (function (param) {
                      Curry._1(onClose, undefined);
                    })
                }), React.createElement("div", {
                  className: modal
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.position("absolute"),
                            tl: {
                              hd: Css.top(Css.px(20)),
                              tl: {
                                hd: Css.right(Css.px(20)),
                                tl: /* [] */0
                              }
                            }
                          })
                    }, React.createElement(ModalCloseButton.make, {
                          onClick: (function (param) {
                              Curry._1(onClose, undefined);
                            })
                        })), React.createElement(Title.make, {
                      children: "Export your Tracking Plan",
                      size: "Medium"
                    }), React.createElement("div", {
                      className: options
                    }, React.createElement("div", {
                          className: option
                        }, React.createElement(Icons.Code.make, {
                              size: 40,
                              color: Styles.Color.avoPink
                            }), React.createElement(Spacer.make, {
                              height: 8
                            }), React.createElement(Title.make, {
                              children: "JSON Schema",
                              size: "Small"
                            }), React.createElement(Spacer.make, {
                              height: 12
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              textAlign: "center",
                              color: Styles.Color.light10,
                              children: "Export to JSON Schema for a super nice way to implode your tracking plan into a nice little JSON Schema bundle."
                            }), React.createElement(Spacer.make, {
                              height: 20,
                              grow: 1.0
                            }), React.createElement(Button.make, {
                              icon: "arrowDown",
                              label: "Export JSON Schema",
                              onClick: Curry._1(handleExportClick, /* JsonSchema */0),
                              size: "large"
                            })), React.createElement("div", {
                          className: separator
                        }), avoJsonExportEnabled ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: option
                              }, React.createElement(Icons.Code.make, {
                                    size: 40,
                                    color: Styles.Color.avoPink
                                  }), React.createElement(Spacer.make, {
                                    height: 8
                                  }), React.createElement(Title.make, {
                                    children: "Avo JSON",
                                    size: "Small"
                                  }), React.createElement(Spacer.make, {
                                    height: 12
                                  }), React.createElement($$Text.make, {
                                    size: "Medium",
                                    textAlign: "center",
                                    color: Styles.Color.light10,
                                    children: "A convenient way to collect your workspace and everything it contains into an Avo JSON."
                                  }), React.createElement(Spacer.make, {
                                    height: 20,
                                    grow: 1.0
                                  }), React.createElement(Button.make, {
                                    icon: "arrowDown",
                                    label: "Export Avo JSON",
                                    onClick: Curry._1(handleExportClick, /* AvoJson */1),
                                    size: "large"
                                  })), React.createElement("div", {
                                className: separator
                              })) : null, React.createElement("div", {
                          className: option
                        }, React.createElement(IconCsv.make, {
                              size: 40,
                              color: Styles.Color.avoPink
                            }), React.createElement(Spacer.make, {
                              height: 8
                            }), React.createElement(Title.make, {
                              children: "CSV",
                              size: "Small"
                            }), React.createElement(Spacer.make, {
                              height: 16
                            }), React.createElement($$Text.make, {
                              size: "Medium",
                              textAlign: "center",
                              color: Styles.Color.light10,
                              children: "You can use a CSV to handily get an export in a more table-like format."
                            }), React.createElement(Spacer.make, {
                              height: 20,
                              grow: 1.0
                            }), React.createElement(Button.make, {
                              icon: "arrowDown",
                              label: "Export CSV",
                              onClick: Curry._1(handleExportClick, /* Csv */2),
                              size: "large"
                            })))));
}

var make = ExportModal;

export {
  Style ,
  getExportedContent ,
  make ,
}
/* root Not a pure module */
