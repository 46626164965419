// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
            tl: {
              hd: Css.transition({
                    NAME: "ms",
                    VAL: Styles.Duration.$$default
                  }, undefined, undefined, "all"),
              tl: {
                hd: Css.padding2(Css.px(12), Css.px(12)),
                tl: {
                  hd: Css.lastChild({
                        hd: Css.borderBottomWidth(Css.px(0)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(10)),
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var leftStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.flexGrow(1.0),
          tl: {
            hd: Css.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

function SourceSettingsDestination(Props) {
  var destination = Props.destination;
  var source = Props.source;
  var availability = Props.availability;
  var isSupported = Props.isSupported;
  var onChange = Props.onChange;
  var globalSend = GlobalSendContext.use(undefined);
  var destination$1 = destination.type_;
  var destinationType = destination.type_;
  var tmp;
  if (isSupported) {
    tmp = availability === "AvailableDuringTrial" || availability === "Unavailable" ? React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.cursor("pointer"),
                  tl: {
                    hd: Css_Legacy_Core.SVG.fill("transparent"),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                      tl: /* [] */0
                    }
                  }
                }),
            onClick: (function (param) {
                Curry._1(globalSend, {
                      TAG: /* OpenModal */4,
                      _0: {
                        NAME: "BillingPrompt",
                        VAL: "ConfigureEventsPerPlatform"
                      }
                    });
              })
          }, React.createElement(PlanLightning.make, {
                tooltipText: availability === "Unavailable" ? "Upgrade Plan to unlock" : "Available during trial"
              })) : null;
  } else {
    var match = source.platform;
    var match$1 = source.language;
    var match$2 = destination.type_;
    tmp = React.createElement($$Text.make, {
          size: "Small",
          color: Styles.Color.light10,
          children: match !== undefined && match$1 !== undefined && match$2 !== undefined ? "" + AvoConfig.analyticsToolsLabel(match$2) + " is currently not supported on " + AvoConfig.languageLabel(match$1) + " " + AvoConfig.devPlatformLabel(match) + "" : "Finish setting up this source to configure it"
        });
  }
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement("div", {
                  className: leftStyles
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.justifyContent("center"),
                              tl: {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.height(Css.px(24)),
                                  tl: {
                                    hd: Css.width(Css.px(24)),
                                    tl: {
                                      hd: Css.marginRight(Css.px(8)),
                                      tl: {
                                        hd: Css.overflow("hidden"),
                                        tl: {
                                          hd: Css.borderRadius(Css.px(6)),
                                          tl: {
                                            hd: Css.backgroundColor(Styles.Color.light08),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          })
                    }, destination$1 !== undefined ? React.createElement(Icons.Destinations.make, {
                            destination: destination$1,
                            size: 24
                          }) : React.createElement($$Text.make, {
                            size: "Medium",
                            weight: "Semi",
                            color: Styles.Color.white,
                            children: "?"
                          })), React.createElement("div", undefined, React.createElement(Link.make, {
                          path: Router.Link.addDrawerItem(undefined, {
                                NAME: "destination",
                                VAL: destination.id
                              }),
                          className: Curry._1(Css.style, {
                                hd: Css.color(Styles.Color.light12),
                                tl: {
                                  hd: Css.hover({
                                        hd: Css.color(Styles.Color.light12),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.whiteSpace("nowrap"),
                                      tl: {
                                        hd: Css.marginRight(Css.px(8)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }),
                          children: React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                children: ModelUtils.getDestinationName(destination)
                              })
                        }), destinationType !== undefined ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.whiteSpace("nowrap"),
                                  tl: {
                                    hd: Css.marginRight(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                color: Styles.Color.light10,
                                children: AvoConfig.analyticsToolsLabel(destinationType)
                              })) : null)), tmp, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(12)),
                        tl: /* [] */0
                      })
                }), React.createElement(Switch.make, {
                  value: Belt_List.someU(source.destinations, (function (sourceDestination) {
                          return sourceDestination.destinationId === destination.id;
                        })),
                  onChange: (function (isSelected) {
                      if (availability === "AvailableDuringTrial" || availability === "Available") {
                        return Curry._1(onChange, isSelected);
                      } else {
                        return Curry._1(globalSend, {
                                    TAG: /* OpenModal */4,
                                    _0: {
                                      NAME: "BillingPrompt",
                                      VAL: "ConfigureEventsPerPlatform"
                                    }
                                  });
                      }
                    }),
                  faded: availability === "Unavailable",
                  disabled: !isSupported
                }));
}

var make = SourceSettingsDestination;

export {
  rootStyles ,
  leftStyles ,
  make ,
}
/* rootStyles Not a pure module */
