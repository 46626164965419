// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AnalyticsUtils from "../analyticsUtils.mjs";
import * as AppFeatureFlag from "../AppFeatureFlag.mjs";
import * as IntegrationInput from "./IntegrationInput.mjs";
import * as IntegrationUtils from "../../../api/functions/src/IntegrationUtils.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as IntegrationAnalytics from "./IntegrationAnalytics.mjs";
import * as WebhookPayloadFormat from "../../../shared/models/WebhookPayloadFormat.mjs";
import * as AnalyticsUtilsBundles from "../analyticsUtilsBundles.mjs";

function WebhookIntegrationConfig(Props) {
  var sendActions = Props.sendActions;
  var integrations = Props.integrations;
  var globalSend = Props.globalSend;
  var integration = Props.integration;
  var schemaBundle = Props.schemaBundle;
  var config = Props.config;
  var userIsEditor = Props.userIsEditor;
  var workspace = WorkspaceContext.use(undefined);
  var hasExportAvoJsonFeatureFlag = AppFeatureFlag.useFeatureFlag("ExportAvoJson");
  var webhookSecret = FirebaseFetcherHooks.useWebhookSecret(workspace.id, integration.id);
  var hasSecret = typeof webhookSecret === "object" ? true : (
      webhookSecret === "NotFound" ? false : undefined
    );
  var rootStyles = Curry._1(Css.style, {
        hd: Css.display("flex"),
        tl: {
          hd: Css.flexDirection("column"),
          tl: {
            hd: Css.alignItems("stretch"),
            tl: /* [] */0
          }
        }
      });
  var webhookPayloadFormats = Belt_Array.concat([
        /* JsonSchema */0,
        /* SnowplowSchemas */2
      ], hasExportAvoJsonFeatureFlag ? [/* AvoJson */1] : []);
  var match = config.payloadFormat;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(IntegrationInput.IntegrationTextInput.make, {
                  id: "webhook-url",
                  value: Belt_Option.getWithDefault(config.webhookUrl, ""),
                  docsUrl: "https://www.avo.app/docs/publishing/webhook-publishing",
                  onFlush: (function (webhookUrl) {
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamURL");
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "UpdateIntegrationConfig",
                                  VAL: [
                                    integration.id,
                                    {
                                      TAG: /* Webhook */3,
                                      _0: {
                                        webhookUrl: webhookUrl,
                                        payloadFormat: config.payloadFormat
                                      }
                                    }
                                  ]
                                },
                                {
                                  integrationId: integration.id,
                                  integrationQuery: integration.id
                                }
                              ]]
                          ]);
                    }),
                  placeholder: "https://...",
                  onChangeCompleted: (function (param, param$1) {
                      
                    }),
                  children: "Webhook URL"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement(IntegrationInput.IntegrationSelectInput.make, {
                  id: "webhook-payload-type",
                  value: WebhookPayloadFormat.toString(config.payloadFormat),
                  docsUrl: "https://www.avo.app/docs/publishing/webhook-publishing",
                  onSelect: (function (value) {
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            (function (branch) {
                                IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "PayloadFormat");
                              }),
                            undefined,
                            [[
                                {
                                  NAME: "UpdateIntegrationConfig",
                                  VAL: [
                                    integration.id,
                                    {
                                      TAG: /* Webhook */3,
                                      _0: {
                                        webhookUrl: config.webhookUrl,
                                        payloadFormat: WebhookPayloadFormat.Type.toFormat(WebhookPayloadFormat.Type.fromString(value))
                                      }
                                    }
                                  ]
                                },
                                {
                                  integrationId: integration.id,
                                  integrationQuery: integration.id
                                }
                              ]]
                          ]);
                    }),
                  options: Belt_Array.map(webhookPayloadFormats, (function (payloadFormat) {
                          return [
                                  {
                                    NAME: "Label",
                                    VAL: WebhookPayloadFormat.Type.prettyPrint(payloadFormat)
                                  },
                                  WebhookPayloadFormat.Type.toString(payloadFormat)
                                ];
                        })),
                  children: "Webhook Payload Format"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), hasSecret !== undefined ? React.createElement(IntegrationInput.SecretInput.make, {
                    label: "Webhook Secret",
                    docsUrl: "https://www.avo.app/docs/workspace/tracking-plan/webhook-signing",
                    hasValue: hasSecret,
                    disabled: !userIsEditor,
                    onClick: (function (param) {
                        var match = integration.config;
                        var tmp;
                        tmp = match !== undefined && match.TAG === /* Webhook */3 ? WebhookPayloadFormat.toAnalytics(match._0.payloadFormat) : undefined;
                        Curry._1(globalSend, {
                              TAG: /* OpenModal */4,
                              _0: {
                                NAME: "UpdateWebhookSecret",
                                VAL: [
                                  integration.id,
                                  AnalyticsUtilsBundles.integrationConfig(IntegrationUtils.configToAnalyticsType(integration.config), integration.filters === /* [] */0 ? ["None"] : Belt_Array.concatMany([
                                              Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                                          if (typeof filter === "object") {
                                                            return filter.NAME === "Source";
                                                          } else {
                                                            return false;
                                                          }
                                                        })), [], (function (param) {
                                                      return ["Sources"];
                                                    })),
                                              Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                                          if (typeof filter === "object") {
                                                            return filter.NAME === "Destination";
                                                          } else {
                                                            return false;
                                                          }
                                                        })), [], (function (param) {
                                                      return ["Destinations"];
                                                    })),
                                              Belt_Option.mapWithDefault(Belt_List.getByU(integration.filters, (function (filter) {
                                                          if (typeof filter === "object") {
                                                            return filter.NAME === "Tag";
                                                          } else {
                                                            return false;
                                                          }
                                                        })), [], (function (param) {
                                                      return ["Tags"];
                                                    }))
                                            ]), AvoConfig.getIntegrationName(integration), integration.id, tmp),
                                  (function (_encryptedToken) {
                                      
                                    }),
                                  hasSecret
                                ]
                              }
                            });
                      })
                  }) : null, typeof match === "number" ? null : React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 16
                      }), React.createElement(IntegrationInput.IntegrationTextInput.make, {
                        id: "webhook-snowplow-vendor",
                        value: Belt_Option.getWithDefault(match._0.vendor, ""),
                        docsUrl: "https://www.avo.app/docs/publishing/webhook-publishing",
                        onFlush: (function (vendor) {
                            Curry.app(sendActions, [
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  (function (branch) {
                                      IntegrationAnalytics.trackIntegrationConfigured(integrations, integration, AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch), "DownstreamProjectName");
                                    }),
                                  undefined,
                                  [[
                                      {
                                        NAME: "UpdateIntegrationConfig",
                                        VAL: [
                                          integration.id,
                                          {
                                            TAG: /* Webhook */3,
                                            _0: {
                                              webhookUrl: config.webhookUrl,
                                              payloadFormat: /* SnowplowSchemas */{
                                                _0: {
                                                  vendor: vendor === "" ? undefined : vendor
                                                }
                                              }
                                            }
                                          }
                                        ]
                                      },
                                      {
                                        integrationId: integration.id,
                                        integrationQuery: integration.id
                                      }
                                    ]]
                                ]);
                          }),
                        placeholder: "app.avo",
                        onChangeCompleted: (function (param, param$1) {
                            
                          }),
                        children: "Vendor"
                      })));
}

var make = WebhookIntegrationConfig;

export {
  make ,
}
/* Css Not a pure module */
