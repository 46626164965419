// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";

function UserBlot(Props) {
  var userName = Props.userName;
  return React.createElement("span", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(4), Css.px(2)),
                    tl: {
                      hd: Css.color(Styles.Color.avoPink),
                      tl: /* [] */0
                    }
                  })
            }, userName);
}

var make = UserBlot;

var $$default = UserBlot;

export {
  make ,
  $$default ,
  $$default as default,
}
/* Css Not a pure module */
