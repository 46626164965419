// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FramerMotion from "framer-motion";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function setLabelRef(labelRefs, index) {
  return function (ref) {
    labelRefs.current = Belt_Array.mapWithIndex(labelRefs.current, (function (labelRefIndex, labelRef) {
            if (labelRefIndex === index) {
              return ref;
            } else {
              return labelRef;
            }
          }));
  };
}

function getAnimation(position, opacity) {
  return {
          x: position.left,
          y: position.top,
          width: position.width,
          height: position.height,
          opacity: opacity
        };
}

function getPosition(containerRef, labelRefs, tabIndex) {
  var match = containerRef.current;
  var match$1 = Belt_Option.flatMap(Belt_Array.get(labelRefs.current, tabIndex), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  if (match == null) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  var labelRect = Caml_option.valFromOption(match$1).getBoundingClientRect();
  var labelWidth = labelRect.width;
  var labelHeight = labelRect.height;
  var labelTop = labelRect.top;
  var labelLeft = labelRect.left;
  var containerRect = match.getBoundingClientRect();
  var containerTop = containerRect.top;
  var containerLeft = containerRect.left;
  return {
          width: labelWidth + 0.5,
          height: labelHeight,
          top: (labelTop - containerTop | 0) - 1 | 0,
          left: (labelLeft - containerLeft | 0) - 1 | 0
        };
}

var activeTransition = {
  type: "spring",
  stiffness: 350,
  damping: 27,
  mass: 0.85,
  duration: 0
};

var inactiveTransition = {
  type: "ease",
  stiffness: 0,
  damping: 0,
  mass: 0.0,
  duration: 0
};

function root(circular) {
  return Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.gap(Css.px(2)),
                  tl: {
                    hd: Css.flexDirection("row"),
                    tl: {
                      hd: Css.width("fitContent"),
                      tl: {
                        hd: Css.padding("zero"),
                        tl: {
                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                          tl: {
                            hd: Css.borderRadius(Css.px(circular ? 100 : 5)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.light04),
                              tl: {
                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, Css.px(3), undefined, true, Styles.Color.setAlpha(Styles.Color.light12, 0.05))),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function activeSegment(circular) {
  return Curry._1(Css.style, {
              hd: Css.zIndex(0),
              tl: {
                hd: Css.position("absolute"),
                tl: {
                  hd: Css.top(Css.px(0)),
                  tl: {
                    hd: Css.left(Css.px(0)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.borderRadius(Css.px(circular ? 100 : 4)),
                        tl: {
                          hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(1), Css.px(4), undefined, undefined, Styles.Color.setAlpha(Styles.Color.light12, 0.15))),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

var input = Curry._1(Css.style, {
      hd: Css.display("none"),
      tl: {
        hd: Css.selector(":checked + label", {
              hd: Css.color(Styles.Color.light12),
              tl: /* [] */0
            }),
        tl: {
          hd: Css.selector(":not(:checked) + label:hover", {
                hd: Css.color(Styles.Color.light11),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.light03),
                  tl: /* [] */0
                }
              }),
          tl: {
            hd: Css.disabled({
                  hd: Css.selector(" + label", {
                        hd: Css.cursor("default"),
                        tl: /* [] */0
                      }),
                  tl: {
                    hd: Css.selector(":not(:checked) + label", {
                          hd: Css.color(Styles.Color.light09),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }),
            tl: /* [] */0
          }
        }
      }
    });

function label(circular) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.color(Styles.Color.light10),
                  tl: {
                    hd: Css.paddingLeft(Css.px(12)),
                    tl: {
                      hd: Css.paddingRight(Css.px(12)),
                      tl: {
                        hd: Css.paddingTop(Css.px(4)),
                        tl: {
                          hd: Css.paddingBottom(Css.px(6)),
                          tl: {
                            hd: Css.borderRadius(Css.px(circular ? 100 : 4)),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "all"),
                              tl: {
                                hd: Css.cursor("pointer"),
                                tl: {
                                  hd: Css.textAlign("center"),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function SegmentControl(Props) {
  var id = Props.id;
  var options = Props.options;
  var onSelect = Props.onSelect;
  var selected = Props.selected;
  var disabledOpt = Props.disabled;
  var circularOpt = Props.circular;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var circular = circularOpt !== undefined ? circularOpt : false;
  var labelRefs = React.useRef(Belt_Array.make(options.length, null));
  var containerRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setActivePosition = match[1];
  var activePosition = match[0];
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(Belt_Array.getIndexBy(options, (function (param) {
                          return Caml_obj.equal(param[1], selected);
                        })), 0);
      });
  var setActiveIndex = match$1[1];
  var activeIndex = match$1[0];
  React.useEffect((function () {
          Curry._1(setActivePosition, (function (param) {
                  return getPosition(containerRef, labelRefs, activeIndex);
                }));
        }), []);
  React.useEffect((function () {
          var newActiveIndex = Belt_Option.getWithDefault(Belt_Array.getIndexBy(options, (function (param) {
                      return Caml_obj.equal(param[1], selected);
                    })), 0);
          if (newActiveIndex !== activeIndex) {
            Curry._1(setActivePosition, (function (param) {
                    return getPosition(containerRef, labelRefs, newActiveIndex);
                  }));
            Curry._1(setActiveIndex, (function (param) {
                    return newActiveIndex;
                  }));
            Curry._1(onSelect, selected);
          }
          
        }), [selected]);
  var initial = React.useRef(true);
  var match$2 = React.useState(function () {
        
      });
  var setWindowWidth = match$2[1];
  React.useEffect((function () {
          var resizeHandler = function (param) {
            Curry._1(setWindowWidth, (function (param) {
                    return window.innerWidth;
                  }));
          };
          window.addEventListener("resize", resizeHandler);
          return (function (param) {
                    window.removeEventListener("resize", resizeHandler);
                  });
        }), []);
  React.useEffect((function () {
          setTimeout((function (param) {
                  initial.current = true;
                  Curry._1(setActivePosition, (function (param) {
                          return getPosition(containerRef, labelRefs, activeIndex);
                        }));
                }), 0);
        }), [match$2[0]]);
  var tmp = {
    ref: containerRef,
    className: root(circular)
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1;
  if (activePosition !== undefined) {
    var activePosition$1 = Caml_option.valFromOption(activePosition);
    tmp$1 = React.createElement(FramerMotion.motion.div, {
          animate: getAnimation(activePosition$1, 1),
          transition: initial.current ? (initial.current = false, inactiveTransition) : activeTransition,
          exit: getAnimation(activePosition$1, 0),
          className: activeSegment(circular),
          key: "hover"
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", tmp, React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  initial: false,
                  children: tmp$1
                }), Belt_Array.mapWithIndexU(options, (function (index, param) {
                    var value = param[1];
                    var label$1 = param[0];
                    var key = "" + String(index) + "-" + label$1 + "";
                    return React.createElement(React.Fragment, {
                                children: null,
                                key: key
                              }, React.createElement("input", {
                                    className: input,
                                    id: key,
                                    checked: Caml_obj.equal(value, selected),
                                    disabled: disabled,
                                    name: label$1,
                                    type: "radio",
                                    onChange: (function (param) {
                                        if (Caml_obj.notequal(value, selected)) {
                                          Curry._1(setActivePosition, (function (param) {
                                                  return getPosition(containerRef, labelRefs, index);
                                                }));
                                          Curry._1(setActiveIndex, (function (param) {
                                                  return index;
                                                }));
                                          return Curry._1(onSelect, value);
                                        }
                                        
                                      })
                                  }), React.createElement("label", {
                                    ref: setLabelRef(labelRefs, index),
                                    className: label(circular),
                                    htmlFor: key
                                  }, React.createElement("span", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.zIndex(0),
                                              tl: /* [] */0
                                            })
                                      }, React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Semi",
                                            lineHeight: Css.px(16),
                                            children: label$1
                                          }))));
                  })));
}

var make = SegmentControl;

export {
  make ,
}
/* input Not a pure module */
