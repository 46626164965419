// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Domains from "../../model/src/domains/Domains.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainUtils from "./DomainUtils.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as ImportReview__V2__Utils from "./import/ImportReview__V2__Utils.mjs";

function InspectorIssuesView__ImportEventsButton(Props) {
  var appVersionsOpt = Props.appVersions;
  var filteredIssues = Props.filteredIssues;
  var sizeOpt = Props.size;
  var styleOpt = Props.style;
  var postClick = Props.postClick;
  var appVersions = appVersionsOpt !== undefined ? Caml_option.valFromOption(appVersionsOpt) : undefined;
  var size = sizeOpt !== undefined ? sizeOpt : "tiny";
  var style = styleOpt !== undefined ? styleOpt : "outline";
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var loading = match[0];
  var addToast = Toast.useAddToastWithDestroy(undefined);
  var events = ModelStore.useEvents(undefined);
  var eventsByName = React.useMemo((function () {
          return Belt_MapString.fromArray(Belt_Array.mapU(Belt_List.toArray(events), (function ($$event) {
                            return [
                                    $$event.name,
                                    $$event
                                  ];
                          })));
        }), [events]);
  var eventCount = React.useMemo((function () {
          return ImportReview__V2__Utils.getEventNames(appVersions, eventsByName, filteredIssues).length;
        }), [
        appVersions,
        filteredIssues
      ]);
  var schemaId = WorkspaceContext.use(undefined).id;
  var onClick = function (_e) {
    Belt_Option.forEach(postClick, (function (cb) {
            Curry._1(cb, undefined);
          }));
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    var destroyToast = Curry._1(addToast, {
          message: "Fetching event information",
          toastType: /* Loader */3,
          persist: true
        });
    var eventNames = ImportReview__V2__Utils.getEventNames(appVersions, eventsByName, filteredIssues);
    if (eventNames.length !== 0) {
      $$Promise.$$catch(DomainUtils.Firebase.getDomains(schemaId).then(function (domains) {
                      return Belt_Array.reduce(domains, undefined, (function (events, domain) {
                                    return Belt_Array.reduceU(domain.items.events, events, (function (events, eventIdOnDomain) {
                                                  return Belt_MapString.update(events, eventIdOnDomain, (function (currentEventDomains) {
                                                                return Curry._2(Domains.append, Belt_Option.getWithDefault(currentEventDomains, Domains.empty), domain);
                                                              }));
                                                }));
                                  }));
                    }).then(function (domainEvents) {
                    return ImportReview__V2__Utils.handlePrepareImport(eventNames, domainEvents);
                  }).then(function (importModel) {
                  Curry._1(destroyToast, undefined);
                  Curry._1(globalSend, {
                        TAG: /* OpenModal */4,
                        _0: {
                          NAME: "ImportReview",
                          VAL: [
                            importModel,
                            "AddOnly",
                            "InspectorIssuesHeader"
                          ]
                        }
                      });
                }), (function (e) {
                Curry._1(destroyToast, undefined);
                ImportReview__V2__Utils.handleExceptions(e, (function (message, severity) {
                        Curry._1(addToast, {
                              message: message,
                              toastType: severity === "error" ? /* Error */1 : /* Info */2
                            });
                      }));
                return Promise.resolve(undefined);
              })).finally(function (param) {
            Curry._1(destroyToast, undefined);
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
          });
      return ;
    } else {
      Curry._1(destroyToast, undefined);
      Curry._1(addToast, {
            message: "No events to import",
            toastType: /* Info */2
          });
      return Curry._1(setLoading, (function (param) {
                    return false;
                  }));
    }
  };
  return React.createElement(SimpleTooltip.make, {
              arrowOffset: 16,
              maxWidth: 220,
              position: "bottom-end",
              tooltip: eventCount > 0 ? "Review and add " + AvoUtils.plural(undefined, undefined, eventCount, "unexpected event") + " to Tracking plan" : "No unexpected events in issues to import",
              children: React.createElement(Button.make, {
                    disabled: loading || eventCount === 0,
                    icon: "addToTrackingPlan",
                    label: eventCount > 0 ? "Add " + AvoUtils.plural(undefined, undefined, eventCount, "new event") + "" : "Add new events",
                    loading: loading,
                    onClick: onClick,
                    size: size,
                    style: style,
                    singleLine: true
                  })
            });
}

var make = InspectorIssuesView__ImportEventsButton;

export {
  make ,
}
/* Toast Not a pure module */
