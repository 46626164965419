// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

function DidMount(Props) {
  var didMount = Props.didMount;
  var didUnmount = Props.didUnmount;
  React.useEffect((function () {
          Curry._1(didMount, undefined);
          return didUnmount;
        }), []);
  return null;
}

var make = DidMount;

export {
  make ,
}
/* react Not a pure module */
