// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as InternalIssueModel from "../models/InternalIssueModel.mjs";
import * as InspectorIssueStatus from "../models/InspectorIssueStatus.mjs";

function encode(t) {
  return Json_encode.object_({
              hd: [
                "status",
                InspectorIssueStatus.Status.encode(t.status)
              ],
              tl: {
                hd: [
                  "trigger",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), Belt_Option.map(t.trigger, TrackingPlanModel.issueTriggerToJs))
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  try {
    return {
            TAG: /* Ok */0,
            _0: {
              status: Json_decode.field("status", InspectorIssueStatus.Status.decode, json),
              trigger: Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("trigger", Json_decode.string, param);
                        }), json), TrackingPlanModel.issueTriggerFromJs)
            }
          };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    return {
            TAG: /* Error */1,
            _0: error
          };
  }
}

var RequestBody = {
  encode: encode,
  decode: decode
};

function encode$1(t) {
  return InternalIssueModel.TimescaleV2.encode(t);
}

function decode$1(json) {
  return InternalIssueModel.TimescaleV2.decode(json);
}

var ResponseBody = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(t) {
  return Json_encode.object_({
              hd: [
                "issueIds",
                Json_encode.array((function (prim) {
                        return prim;
                      }), t.issueIds)
              ],
              tl: {
                hd: [
                  "status",
                  InspectorIssueStatus.Status.encode(t.status)
                ],
                tl: {
                  hd: [
                    "trigger",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), Belt_Option.map(t.trigger, TrackingPlanModel.issueTriggerToJs))
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$2(json) {
  try {
    return {
            TAG: /* Ok */0,
            _0: {
              issueIds: Json_decode.field("issueIds", (function (param) {
                      return Json_decode.array(Json_decode.string, param);
                    }), json),
              status: Json_decode.field("status", InspectorIssueStatus.Status.decode, json),
              trigger: Belt_Option.flatMap(Json_decode.optional((function (param) {
                          return Json_decode.field("trigger", Json_decode.string, param);
                        }), json), TrackingPlanModel.issueTriggerFromJs)
            }
          };
  }
  catch (raw_error){
    var error = Caml_js_exceptions.internalToOCamlException(raw_error);
    return {
            TAG: /* Error */1,
            _0: error
          };
  }
}

var RequestBody$1 = {
  encode: encode$2,
  decode: decode$2
};

function encode$3(t) {
  return Json_encode.array(InternalIssueModel.TimescaleV2.encode, t);
}

function decode$3(json) {
  return Json_decode.array(InternalIssueModel.TimescaleV2.decode, json);
}

var ResponseBody$1 = {
  encode: encode$3,
  decode: decode$3
};

var MultiUpdate = {
  RequestBody: RequestBody$1,
  ResponseBody: ResponseBody$1
};

export {
  RequestBody ,
  ResponseBody ,
  MultiUpdate ,
}
/* TrackingPlanModel Not a pure module */
