// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as InspectorImplementationStatus from "../InspectorImplementationStatus.mjs";

function encodeStatus(t) {
  return Json_encode.object_({
              hd: [
                "lastUpdated",
                t.lastUpdated.toString()
              ],
              tl: {
                hd: [
                  "implementationStatus",
                  InspectorImplementationStatus.encode(t.implementationStatus)
                ],
                tl: /* [] */0
              }
            });
}

function decodeStatus(json) {
  return {
          lastUpdated: new Date(Json_decode.field("lastUpdated", Json_decode.string, json)),
          implementationStatus: Json_decode.field("implementationStatus", InspectorImplementationStatus.decode, json)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "env",
                t.env
              ],
              tl: {
                hd: [
                  "status",
                  Json_encode.nullable(encodeStatus, t.status)
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          env: Json_decode.field("env", Json_decode.string, json),
          status: Json_decode.optional((function (param) {
                  return Json_decode.field("status", decodeStatus, param);
                }), json)
        };
}

export {
  encodeStatus ,
  decodeStatus ,
  encode ,
  decode ,
}
/* No side effect */
