// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "../styles.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function InspectorImplementationStatusDisclaimer(Props) {
  var expanded = Props.expanded;
  var inspectorImplementationStatuses = Props.inspectorImplementationStatuses;
  var collapsedVisibleImplementationStatuses = Props.collapsedVisibleImplementationStatuses;
  var isDevOrStagingVisibleWhenCollapsed = Belt_Array.someU(collapsedVisibleImplementationStatuses, (function (status) {
          if (status.TAG === /* Inspector */0) {
            return status._0 !== 0;
          } else {
            return false;
          }
        }));
  var tmp;
  var exit = 0;
  if (isDevOrStagingVisibleWhenCollapsed || expanded) {
    exit = 1;
  } else {
    tmp = null;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.paddingTop(Css.px(8)),
                tl: /* [] */0
              })
        }, React.createElement($$Text.make, {
              size: "Tiny",
              weight: "Medium",
              color: Styles.Color.light08,
              children: "Inspector monitors events from all branches, so it may report 'Sometimes sent correctly' for the correctly implemented events if old version is still sent from main or other branches"
            }));
  }
  return React.createElement(React.Fragment, undefined, tmp, Belt_Array.someU(inspectorImplementationStatuses, (function (status) {
                    if (status.TAG === /* Inspector */0 && typeof status._1 !== "number") {
                      return true;
                    } else {
                      return false;
                    }
                  })) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingTop(Css.px(8)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        size: "Tiny",
                        weight: "Medium",
                        color: Styles.Color.light08,
                        children: "Inspector does not validate property value rules such as matches, min or max."
                      })) : null);
}

var make = InspectorImplementationStatusDisclaimer;

export {
  make ,
}
/* Css Not a pure module */
