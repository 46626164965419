// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as Input from "./Input.mjs";
import * as Title from "./Title.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Tooltip from "./Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "./Markdown.mjs";
import * as Tooltips from "./markdown/tooltips/tooltips.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as DrawerHeader from "./detailviews/DrawerHeader.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as IconArrowDown from "./IconArrowDown.mjs";
import * as PlanLightning from "./PlanLightning.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as CollapsedContent from "./CollapsedContent.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as DestinationSources from "./DestinationSources.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

function isDestinationSupported(destination, source) {
  var destinationType = destination.type_;
  if (destinationType !== undefined) {
    return Belt_Array.some(AvoConfig.getSupportedDestinations(source.platform, source.language), (function (destination) {
                  return destination === destinationType;
                }));
  } else {
    return false;
  }
}

function Destination$Label(Props) {
  var text = Props.text;
  var onDeleteSection = Props.onDeleteSection;
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("flexStart"),
                      tl: {
                        hd: Css.paddingLeft(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, Belt_Option.mapWithDefault(onDeleteSection, null, (function (onClick) {
                    return React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.margin4(Css.px(-3), Css.px(0), Css.px(0), Css.px(-5)),
                                              tl: {
                                                hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: onClick
                              }, React.createElement(IconX.make, {}));
                  })), React.createElement("div", undefined, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: text
                    }), Belt_Option.getWithDefault(children, null)));
}

var Label = {
  make: Destination$Label
};

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.height(Css.pct(100.0)),
          tl: /* [] */0
        }
      }
    });

var content = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.overflowY("auto"),
          tl: {
            hd: Css.padding4(Css.px(20), Css.px(20), Css.px(20), Css.px(40)),
            tl: /* [] */0
          }
        }
      }
    });

var title = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.justifyContent("spaceBetween"),
            tl: {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginBottom(Css.px(12)),
                tl: {
                  hd: Css.marginLeft(Css.px(20)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var section = Curry._1(Css.style, {
      hd: Css.marginBottom(Css.px(16)),
      tl: {
        hd: Css.padding4(Css.px(22), Css.px(12), Css.px(12), Css.px(12)),
        tl: {
          hd: Css.borderRadius(Css.px(6)),
          tl: {
            hd: Css.border(Css.px(1), "solid", Styles.Color.light07),
            tl: /* [] */0
          }
        }
      }
    });

var Style = {
  root: root,
  content: content,
  title: title,
  section: section
};

function Destination(Props) {
  var destinationId = Props.destinationId;
  var model = Props.model;
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var workspace = React.useContext(WorkspaceContext.workspaceContext);
  var addToast = Toast.useAddToast(undefined);
  var hasIncludeUserPropertiesWithEventPropertiesFeatureFlag = AppFeatureFlag.useFeatureFlag("IncludeUserPropertiesWithEventProperties");
  var eventSourcesAndDestinationsConfigAvailability = AvoLimits.ConfigEventSourcesAndDestinations.computeAvailability(workspace);
  React.useEffect((function () {
          var match = AvoConfig.destinationById(model, destinationId);
          if (match !== undefined) {
            
          } else {
            Curry._1(globalSend, /* CloseSlideOver */0);
          }
        }), [AvoConfig.destinationById(model, destinationId)]);
  var destination = AvoConfig.destinationById(model, destinationId);
  var tmp;
  if (destination !== undefined) {
    var apiKeys = destination.apiKeys;
    var apiKeysStrings_dev = Belt_Option.getWithDefault(destination.apiKeys.dev, "");
    var apiKeysStrings_prod = Belt_Option.getWithDefault(destination.apiKeys.prod, "");
    var apiKeysStrings_other = destination.apiKeys.other;
    var hasDevKey = Belt_Option.mapWithDefault(apiKeys.dev, false, (function (devKey) {
            return devKey.length !== 0;
          }));
    var hasProdKey = Belt_Option.mapWithDefault(apiKeys.prod, false, (function (devKey) {
            return devKey.length !== 0;
          }));
    var hasDevOrProdKey = hasDevKey || hasProdKey;
    var analyticsSafeDevKey = hasDevKey ? "Redacted" : "None";
    var analyticsSafeProdKey = hasProdKey ? "Redacted" : "None";
    var destinationPlatform = destination.type_;
    var context = {
      destinationId: destinationId,
      destinationQuery: destinationId
    };
    var maybeStagingKeyPair = Belt_List.getBy(apiKeys.other, (function (param) {
            return param[0] === "staging";
          }));
    var analyticsSafeStagingKey = Belt_Option.mapWithDefault(maybeStagingKeyPair, "None", (function (param) {
            if (param[1].length !== 0) {
              return "Redacted";
            } else {
              return "None";
            }
          }));
    var handleDestinationSelect = function (value) {
      var toValue = AvoConfigTypes.analyticsToolFromJs(value);
      if (toValue !== undefined) {
        return Curry.app(sendActions, [
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    (function (branch) {
                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                        AnalyticsRe.dataDestinationUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "AnalyticsTool", Belt_Option.getWithDefault(destination.name, ""), undefined, Caml_obj.equal(apiKeys.dev, apiKeys.prod), analyticsSafeDevKey, undefined, analyticsSafeProdKey, undefined, destinationPlatform, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, undefined, destination.disabledByDefault, toValue, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                      }),
                    undefined,
                    [[
                        {
                          NAME: "UpdateDestinationAnalyticsTool",
                          VAL: [
                            destinationId,
                            toValue
                          ]
                        },
                        context
                      ]]
                  ]);
      }
      
    };
    var match = destination.type_;
    var tmp$1;
    var exit = 0;
    if (match !== undefined && (match === "FirebaseAnalytics" || match === "GoogleAnalytics4" || match === "Braze")) {
      tmp$1 = null;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = React.createElement(CollapsedContent.make, {
            button: (function (onClick, isCollapsed) {
                return React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.justifyContent("flexStart"),
                                    tl: {
                                      hd: Css.alignItems("center"),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("button", {
                                className: Curry._1(Css.merge, {
                                      hd: Curry._1(Css.style, Styles.button),
                                      tl: {
                                        hd: Curry._1(Css.style, {
                                              hd: Css.fontWeight(Styles.FontWeight.semi),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.regular),
                                                tl: {
                                                  hd: Css.color(Styles.Color.darkBlue),
                                                  tl: {
                                                    hd: Css.hover({
                                                          hd: Css.color(Styles.Color.deepBlue),
                                                          tl: /* [] */0
                                                        }),
                                                    tl: {
                                                      hd: Css.display("flex"),
                                                      tl: {
                                                        hd: Css.alignItems("center"),
                                                        tl: {
                                                          hd: Css.justifyContent("flexStart"),
                                                          tl: {
                                                            hd: Css.margin2(Css.px(8), Css.px(0)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                onClick: (function (param) {
                                    Curry._1(onClick, undefined);
                                  })
                              }, isCollapsed ? (
                                  hasDevOrProdKey ? "Show API keys input" : "Document API keys in Avo (optional)"
                                ) : "Hide API keys input    ", React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.marginLeft(Css.px(10)),
                                          tl: {
                                            hd: Css.transform({
                                                  NAME: "rotate",
                                                  VAL: isCollapsed ? ({
                                                        NAME: "deg",
                                                        VAL: 0.0
                                                      }) : ({
                                                        NAME: "deg",
                                                        VAL: 180.0
                                                      })
                                                }),
                                            tl: /* [] */0
                                          }
                                        })
                                  }, React.createElement(IconArrowDown.make, {
                                        size: 12,
                                        color: Styles.Color.darkBlue
                                      }))), React.createElement(Tooltip.WithAnalytics.make, {
                                fromItem: {
                                  itemId: destination.id,
                                  itemName: ModelUtils.getDestinationName(destination),
                                  itemType: "Destination"
                                },
                                title: "Optional API keys",
                                children: React.createElement(Tooltip.Content.make, {
                                      children: React.createElement(Markdown.make, {
                                            source: Tooltips.optionalApiKeys
                                          })
                                    })
                              }));
              }),
            initiallyCollapsed: !hasDevOrProdKey,
            children: (function (param) {
                var tmp;
                if (maybeStagingKeyPair !== undefined) {
                  var envName = maybeStagingKeyPair[0];
                  var tmp$1;
                  var exit = 0;
                  if (destinationPlatform !== undefined) {
                    if (destinationPlatform === "Intercom") {
                      tmp$1 = "Intercom App ID (" + Case.to_("Capital", envName) + ")";
                    } else if (destinationPlatform === "Snowplow") {
                      tmp$1 = "" + Case.to_("Capital", envName) + " URL Endpoint";
                    } else if (destinationPlatform === "FullStory") {
                      tmp$1 = "Fullstory " + Case.to_("Capital", envName) + " Org ID";
                    } else {
                      exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    tmp$1 = "" + Case.to_("Capital", envName) + " API Key";
                  }
                  tmp = React.createElement(React.Fragment, undefined, React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement(Destination$Label, {
                                text: tmp$1,
                                onDeleteSection: (function (param) {
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "RemoveDestinationApiKey",
                                                VAL: [
                                                  destinationId,
                                                  "staging"
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                                  }),
                                children: destinationPlatform !== undefined ? (
                                    destinationPlatform === "Intercom" ? React.createElement("a", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                                  tl: /* [] */0
                                                }),
                                            href: "https://www.intercom.com/help/faqs-and-troubleshooting/getting-set-up/where-can-i-find-my-workspace-id-app-id",
                                            rel: "noopener",
                                            target: "_blank"
                                          }, "Where can I find my Intercom App ID") : (
                                        destinationPlatform === "FullStory" ? React.createElement("span", undefined, React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.marginTop(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, "You find it in the URL: "), React.createElement("a", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.padding(Css.px(0)),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    href: "https://app.fullstory.com/",
                                                    rel: "noopener",
                                                    target: "_blank"
                                                  }, "app.fullstory.com/ui/<YOUR ORG ID>/")) : null
                                      )
                                  ) : null
                              })), React.createElement(Spacer.make, {
                            height: -2
                          }), React.createElement(OpsWithState.make, {
                            value: maybeStagingKeyPair[1],
                            onFlush: (function (key) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateDestinationApiKey",
                                            VAL: [
                                              destinationId,
                                              envName,
                                              key
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              }),
                            onChangeCompleted: (function (value, valueBefore) {
                                AnalyticsRe.dataDestinationUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "StagingApiKey", Belt_Option.getWithDefault(destination.name, ""), undefined, apiKeysStrings_prod === value, analyticsSafeDevKey, undefined, value.length !== 0 ? "Redacted" : "None", undefined, undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, valueBefore.length !== 0 ? "Redacted" : "None", destination.disabledByDefault, destinationPlatform, schemaBundle.branchId, schemaBundle.schemaId);
                              }),
                            children: (function (value, onChange, onBlur, onFocus) {
                                return React.createElement(Input.make, {
                                            value: value,
                                            onChange: onChange,
                                            onBlur: onBlur,
                                            onFocus: onFocus,
                                            className: {
                                              hd: Css.maxWidth(Css.px(400)),
                                              tl: {
                                                hd: Css.fontSize(Styles.FontSize.medium),
                                                tl: {
                                                  hd: Css.fontFamily(Styles.FontFamily.polar),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          });
                              })
                          }));
                } else {
                  tmp = React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.width(Css.pct(100)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement("button", {
                            className: Curry._1(Css.merge, {
                                  hd: Curry._1(Css.style, Styles.button),
                                  tl: {
                                    hd: Curry._1(Css.style, {
                                          hd: Css.fontWeight(Styles.FontWeight.semi),
                                          tl: {
                                            hd: Css.fontSize(Styles.FontSize.regular),
                                            tl: {
                                              hd: Css.color(Styles.Color.darkBlue),
                                              tl: {
                                                hd: Css.margin(Css.px(15)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            onClick: (function (param) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateDestinationApiKey",
                                            VAL: [
                                              destinationId,
                                              "staging",
                                              ""
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              })
                          }, "+ Add Staging Key"));
                }
                return React.createElement(React.Fragment, undefined, React.createElement(Destination$Label, {
                                text: destinationPlatform !== undefined ? (
                                    destinationPlatform === "Intercom" ? "Intercom App ID" : (
                                        destinationPlatform === "Snowplow" ? "Production URL Endpoint" : (
                                            destinationPlatform === "FullStory" ? "Fullstory Org ID" : "Production API Key"
                                          )
                                      )
                                  ) : "Production API Key",
                                children: destinationPlatform !== undefined ? (
                                    destinationPlatform === "Intercom" ? React.createElement("a", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                                  tl: /* [] */0
                                                }),
                                            href: "https://www.intercom.com/help/faqs-and-troubleshooting/getting-set-up/where-can-i-find-my-workspace-id-app-id",
                                            rel: "noopener",
                                            target: "_blank"
                                          }, "Where can I find my Intercom App ID") : (
                                        destinationPlatform === "FullStory" ? React.createElement("span", undefined, React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.marginTop(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, "You find it in the URL: "), React.createElement("a", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.padding(Css.px(0)),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    href: "https://app.fullstory.com/",
                                                    rel: "noopener",
                                                    target: "_blank"
                                                  }, "app.fullstory.com/ui/<YOUR ORG ID>/")) : null
                                      )
                                  ) : null
                              }), React.createElement(Spacer.make, {
                                height: 8
                              }), React.createElement(OpsWithState.make, {
                                value: apiKeysStrings_prod,
                                onFlush: (function (key) {
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdateDestinationProdApiKey",
                                                VAL: [
                                                  destinationId,
                                                  key
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                                  }),
                                onChangeCompleted: (function (value, valueBefore) {
                                    AnalyticsRe.dataDestinationUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "ProductionApiKey", Belt_Option.getWithDefault(destination.name, ""), undefined, apiKeysStrings_dev === value, analyticsSafeDevKey, undefined, value.length !== 0 ? "Redacted" : "None", valueBefore.length !== 0 ? "Redacted" : "None", undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, valueBefore.length !== 0 ? "Redacted" : "None", destination.disabledByDefault, destinationPlatform, schemaBundle.branchId, schemaBundle.schemaId);
                                  }),
                                children: (function (value, onChange, onBlur, onFocus) {
                                    return React.createElement(Input.make, {
                                                value: value,
                                                onChange: onChange,
                                                onBlur: onBlur,
                                                onFocus: onFocus,
                                                className: {
                                                  hd: Css.maxWidth(Css.px(400)),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.medium),
                                                    tl: {
                                                      hd: Css.fontFamily(Styles.FontFamily.polar),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              });
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 12
                              }), React.createElement(Destination$Label, {
                                text: destinationPlatform !== undefined ? (
                                    destinationPlatform === "Intercom" ? "Intercom App ID (Test)" : (
                                        destinationPlatform === "Snowplow" ? "Development URL Endpoint" : (
                                            destinationPlatform === "FullStory" ? "Fullstory Dev Org ID" : (
                                                destinationPlatform === "MParticle" ? "API Secret" : "Development API Key"
                                              )
                                          )
                                      )
                                  ) : "Development API Key",
                                children: destinationPlatform !== undefined ? (
                                    destinationPlatform === "Intercom" ? React.createElement("a", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                                  tl: /* [] */0
                                                }),
                                            href: "https://www.intercom.com/help/configure-intercom-for-your-product-or-site/create-a-test-version-of-intercom/create-a-test-workspace-in-intercom",
                                            rel: "noopener",
                                            target: "_blank"
                                          }, "How to create test workspace in Intercom?") : (
                                        destinationPlatform === "FullStory" ? React.createElement("span", undefined, React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.marginTop(Css.px(4)),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, "You find it in the URL: "), React.createElement("a", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontSize(Styles.FontSize.tiny),
                                                          tl: {
                                                            hd: Css.padding(Css.px(0)),
                                                            tl: /* [] */0
                                                          }
                                                        }),
                                                    href: "https://app.fullstory.com/",
                                                    rel: "noopener",
                                                    target: "_blank"
                                                  }, "app.fullstory.com/ui/<YOUR ORG ID>/")) : null
                                      )
                                  ) : null
                              }), React.createElement(Spacer.make, {
                                height: 8
                              }), React.createElement(OpsWithState.make, {
                                value: apiKeysStrings_dev,
                                onFlush: (function (key) {
                                    Curry.app(sendActions, [
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          [[
                                              {
                                                NAME: "UpdateDestinationDevApiKey",
                                                VAL: [
                                                  destinationId,
                                                  key
                                                ]
                                              },
                                              context
                                            ]]
                                        ]);
                                  }),
                                onChangeCompleted: (function (value, valueBefore) {
                                    AnalyticsRe.dataDestinationUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DevelopmentApiKey", Belt_Option.getWithDefault(destination.name, ""), undefined, value === apiKeysStrings_prod, value.length !== 0 ? "Redacted" : "None", valueBefore.length !== 0 ? "Redacted" : "None", analyticsSafeProdKey, undefined, undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, undefined, destination.disabledByDefault, destinationPlatform, schemaBundle.branchId, schemaBundle.schemaId);
                                  }),
                                children: (function (value, onChange, onBlur, onFocus) {
                                    return React.createElement(Input.make, {
                                                value: value,
                                                onChange: onChange,
                                                onBlur: onBlur,
                                                onFocus: onFocus,
                                                className: {
                                                  hd: Css.maxWidth(Css.px(400)),
                                                  tl: {
                                                    hd: Css.fontSize(Styles.FontSize.medium),
                                                    tl: {
                                                      hd: Css.fontFamily(Styles.FontFamily.polar),
                                                      tl: /* [] */0
                                                    }
                                                  }
                                                }
                                              });
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 12
                              }), tmp);
              })
          });
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(DrawerHeader.make, {
              kind: "Destination",
              options: [{
                  NAME: "Option",
                  VAL: {
                    label: "Archive Destination",
                    onClick: (function (param) {
                        if (window.confirm("Are you sure you want to archive the \"" + ModelUtils.getDestinationName(destination) + "\" destination")) {
                          var match = Router.getStaticRoute(undefined);
                          var newDrawerItems = Belt_List.keep(match.drawerItems, (function (item) {
                                  if (typeof item === "object" && item.NAME === "destination") {
                                    return item.VAL !== destination.id;
                                  } else {
                                    return true;
                                  }
                                }));
                          Router.Schema.replaceDrawerItems(undefined, newDrawerItems);
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                (function (branch) {
                                    var schema = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                    AnalyticsRe.dataDestinationUpdated(schema.schemaId, schema.schemaName, schema.schemaBillingStatus, schema.branchId, schema.branchName, schema.schemaSubscriptionPlan, schema.schemaTrialPlan, schema.authenticationConfig, schema.schemaSubscriptionPlanSlug, schema.schemaTrialPlanSlug, schema.schemaType, "DestinationDeleted", Belt_Option.getWithDefault(destination.name, ""), undefined, Caml_obj.equal(apiKeys.dev, apiKeys.prod), analyticsSafeDevKey, undefined, analyticsSafeProdKey, undefined, undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, undefined, destination.disabledByDefault, destinationPlatform, NamedBranch.getId(branch), schemaBundle.schemaId);
                                  }),
                                undefined,
                                [[
                                    {
                                      NAME: "Archive",
                                      VAL: {
                                        NAME: "Destination",
                                        VAL: destinationId
                                      }
                                    },
                                    context
                                  ]]
                              ]);
                          return Curry._1(addToast, {
                                      message: "The destination has been archived"
                                    });
                        }
                        
                      })
                  }
                }],
              children: React.createElement(Title.make, {
                    children: ModelUtils.getDestinationName(destination),
                    size: "Tiny"
                  })
            }), React.createElement("div", {
              className: content
            }, React.createElement("div", {
                  className: title
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Settings"
                    })), React.createElement("div", {
                  className: section
                }, React.createElement(Destination$Label, {
                      text: "Destination Name"
                    }), React.createElement(OpsWithState.make, {
                      value: Belt_Option.getWithDefault(destination.name, ""),
                      onFlush: (function (name) {
                          Curry.app(sendActions, [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                [[
                                    {
                                      NAME: "UpdateDestinationName",
                                      VAL: [
                                        destinationId,
                                        name
                                      ]
                                    },
                                    context
                                  ]]
                              ]);
                        }),
                      onChangeCompleted: (function (value, valueBefore) {
                          AnalyticsRe.dataDestinationUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "DestinationName", value, valueBefore, Caml_obj.equal(apiKeys.dev, apiKeys.prod), analyticsSafeDevKey, undefined, analyticsSafeProdKey, undefined, undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, undefined, destination.disabledByDefault, destinationPlatform, schemaBundle.branchId, schemaBundle.schemaId);
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(Input.make, {
                                      value: value,
                                      onChange: onChange,
                                      placeholder: "Untitled Destination...",
                                      onBlur: onBlur,
                                      onFocus: onFocus,
                                      className: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.medium),
                                          tl: /* [] */0
                                        }
                                      }
                                    });
                        })
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), React.createElement(Destination$Label, {
                      text: "Analytics Tool"
                    }), React.createElement(Spacer.make, {
                      height: 8
                    }), React.createElement(Select.make, {
                      onSelect: handleDestinationSelect,
                      options: Belt_Array.concat([[
                              {
                                NAME: "Label",
                                VAL: "Select Analytics Tool"
                              },
                              ""
                            ]], Belt_Array.map(AvoConfig.analyticsTools, (function (analyticsTool) {
                                  return [
                                          {
                                            NAME: "Label",
                                            VAL: AvoConfig.analyticsToolsLabel(analyticsTool)
                                          },
                                          AvoConfigTypes.analyticsToolToJs(analyticsTool)
                                        ];
                                }))),
                      value: Belt_Option.mapWithDefault(destination.type_, "", AvoConfigTypes.analyticsToolToJs)
                    }), React.createElement(Spacer.make, {
                      height: 12
                    }), tmp$1, hasIncludeUserPropertiesWithEventPropertiesFeatureFlag ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement(Destination$Label, {
                            text: "Include user properties with event properties"
                          }), React.createElement(Spacer.make, {
                            width: 8
                          }), React.createElement(Switch.make, {
                            value: destination.includeUserPropsWithEventProps,
                            onChange: (function (isActive) {
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          AnalyticsRe.dataDestinationUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "IncludeUserPropsWithEventProps", Belt_Option.getWithDefault(destination.name, ""), undefined, Caml_obj.equal(apiKeys.dev, apiKeys.prod), analyticsSafeDevKey, undefined, analyticsSafeProdKey, undefined, undefined, isActive, analyticsSafeStagingKey, undefined, destination.disabledByDefault, destinationPlatform, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "UpdateDestinationIncludeUserPropsWithEventProps",
                                            VAL: [
                                              destinationId,
                                              isActive
                                            ]
                                          },
                                          context
                                        ]]
                                    ]);
                              })
                          })) : null), React.createElement(Spacer.make, {
                  height: 12
                }), React.createElement(DestinationSources.make, {
                  destination: destination,
                  sources: model.sources,
                  onEnableAll: (function (param) {
                      var eventIds = Belt_List.mapU(model.events, (function ($$event) {
                              return $$event.id;
                            }));
                      Curry.app(sendActions, [
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            Belt_Array.mapU(Belt_Array.keepU(Belt_List.toArray(model.sources), (function (source) {
                                        if (isDestinationSupported(destination, source)) {
                                          return !Belt_List.someU(source.destinations, (function (param) {
                                                        return param.destinationId === destination.id;
                                                      }));
                                        } else {
                                          return false;
                                        }
                                      })), (function (source) {
                                    return [
                                            {
                                              NAME: "IncludeDestinationInSourceV2",
                                              VAL: [
                                                source.id,
                                                destinationId,
                                                eventIds
                                              ]
                                            },
                                            {
                                              eventIds: [],
                                              eventsQuery: [],
                                              sourceId: source.id,
                                              sourceQuery: source.id,
                                              destinationId: destinationId,
                                              destinationQuery: destinationId
                                            }
                                          ];
                                  }))
                          ]);
                    }),
                  onAddSource: (function (param) {
                      Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "CreateSource",
                              VAL: undefined
                            }
                          });
                    }),
                  globalSend: globalSend,
                  onToggleSource: (function (source, isActive) {
                      var match = ModelUtils.getSourceEvents(undefined, model, source.id);
                      if (isActive) {
                        if (match) {
                          return Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "EnableDestinationForSource",
                                        VAL: [
                                          source.id,
                                          destinationId
                                        ]
                                      }
                                    });
                        } else {
                          return Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          AnalyticsRe.dataPathUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.getExn(source.platform), destination.type_, "Enable", Belt_List.length(ModelUtils.getSourceEvents(undefined, model, source.id)), Belt_List.length(ModelUtils.getSourceEvents(undefined, model, source.id)), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                        }),
                                      undefined,
                                      [[
                                          {
                                            NAME: "IncludeDestinationInSourceV2",
                                            VAL: [
                                              source.id,
                                              destinationId,
                                              /* [] */0
                                            ]
                                          },
                                          {
                                            eventIds: [],
                                            eventsQuery: [],
                                            sourceId: source.id,
                                            sourceQuery: source.id,
                                            destinationId: destinationId,
                                            destinationQuery: destinationId
                                          }
                                        ]]
                                    ]);
                        }
                      } else {
                        return Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        AnalyticsRe.dataPathUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, Belt_Option.getExn(source.platform), destination.type_, "Disable", Belt_List.length(ModelUtils.getSourceEvents(undefined, model, source.id)), Belt_List.length(ModelUtils.getSourceEvents(undefined, model, source.id)), NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "ExcludeDestinationFromSource",
                                          VAL: [
                                            source.id,
                                            destinationId
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                      }
                    })
                }), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement("div", {
                  className: title
                }, React.createElement($$Text.make, {
                      element: "H1",
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: "Advanced Settings"
                    })), React.createElement("div", {
                  className: section
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.flexDirection("row"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(8)),
                                  tl: {
                                    hd: Css.paddingRight(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          })
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("grid"),
                                tl: {
                                  hd: Css.gridGap(Css.px(8)),
                                  tl: {
                                    hd: Css.marginRight(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              size: "Medium",
                              weight: "Semi",
                              children: "Include by default"
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              color: Styles.Color.light10,
                              children: "Destination will be included by default when added to new events. You might consider turning this behaviour off if the destination is e.g. an ad platform that should only receive a very limited set of events."
                            })), eventSourcesAndDestinationsConfigAvailability === "Available" ? null : React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css_Legacy_Core.SVG.fill("transparent"),
                                  tl: {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light08),
                                    tl: {
                                      hd: Css.marginRight(Css.px(8)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(PlanLightning.make, {
                                tooltipText: eventSourcesAndDestinationsConfigAvailability === "Unavailable" ? "Upgrade Plan to unlock" : "Available during trial"
                              })), React.createElement(Switch.make, {
                          value: !destination.disabledByDefault,
                          onChange: (function (isActive) {
                              Curry.app(sendActions, [
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                        AnalyticsRe.dataDestinationUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, "DisabledByDefault", Belt_Option.getWithDefault(destination.name, ""), undefined, Caml_obj.equal(apiKeys.dev, apiKeys.prod), analyticsSafeDevKey, undefined, analyticsSafeProdKey, undefined, undefined, destination.includeUserPropsWithEventProps, analyticsSafeStagingKey, undefined, !isActive, destinationPlatform, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                      }),
                                    undefined,
                                    [[
                                        {
                                          NAME: "UpdateDestinationDisabledByDefault",
                                          VAL: [
                                            destinationId,
                                            !isActive
                                          ]
                                        },
                                        context
                                      ]]
                                  ]);
                            }),
                          disabled: eventSourcesAndDestinationsConfigAvailability === "Unavailable"
                        })))));
  } else {
    tmp = React.createElement("div", undefined, "Error loading destination");
  }
  return React.createElement("section", {
              className: root
            }, tmp);
}

var make = Destination;

export {
  isDestinationSupported ,
  Label ,
  Style ,
  make ,
}
/* root Not a pure module */
