// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as ImplementationStatusLight from "./ImplementationStatusLight.mjs";
import * as ImplementationStatusStates from "./ImplementationStatusStates.mjs";

function ImplementationStatusRow(Props) {
  var implementationStatus = Props.implementationStatus;
  var eventName = Props.eventName;
  var longDescription = Props.longDescription;
  var tmp;
  if (implementationStatus.TAG === /* Inspector */0) {
    switch (implementationStatus._0) {
      case /* Prod */0 :
          tmp = longDescription ? "Inspector Prod" : "Prod";
          break;
      case /* Dev */1 :
          tmp = longDescription ? "Inspector Dev" : "Dev";
          break;
      case /* Staging */2 :
          tmp = longDescription ? "Inspector Staging" : "Staging";
          break;
      
    }
  } else {
    tmp = longDescription ? "Codegen Dev" : "Dev";
  }
  var tmp$1;
  tmp$1 = implementationStatus.TAG === /* Inspector */0 ? implementationStatus._0 : /* Dev */1;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("row"),
                      tl: {
                        hd: Css.flexWrap("nowrap"),
                        tl: {
                          hd: Css.alignItems("baseline"),
                          tl: {
                            hd: Css.margin2(Css.px(6), "zero"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.top(Css.px(-1)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(ImplementationStatusLight.make, {
                      implementationStatus: implementationStatus
                    })), React.createElement(Spacer.make, {
                  width: 4
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.flexGrow(1.0),
                        tl: /* [] */0
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Semi",
                      color: Styles.Color.light11,
                      children: tmp
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      weight: "Regular",
                      color: Styles.Color.light11,
                      children: " — "
                    }), React.createElement(ImplementationStatusStates.ImplementationStatusMessage.make, {
                      implementationStatus: implementationStatus,
                      eventName: eventName,
                      env: tmp$1
                    })));
}

var make = ImplementationStatusRow;

export {
  make ,
}
/* Css Not a pure module */
