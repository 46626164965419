// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Issues from "../../shared/models/Issues.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Intercom from "./externals/intercom.mjs";
import * as StringExt from "./StringExt.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AvoEngagement from "./avoEngagement/AvoEngagement.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as InspectorIssueStatus from "../../shared/models/InspectorIssueStatus.mjs";
import * as InspectorIssuesStore from "./inspector/InspectorIssuesStore.mjs";
import * as InspectorIssuesFilterHooks from "./inspector/InspectorIssuesFilterHooks.mjs";
import * as InspectorIssuesFilterUtils from "../../shared/utils/InspectorIssuesFilterUtils.mjs";

function InspectorIssuesView__EmptyState$Imagery$NoIssues(Props) {
  return React.createElement("svg", {
              height: "102",
              width: "118",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M78.201 0H7.931C3.55 0 0 3.139 0 7.01h86.132c0-3.871-3.55-7.01-7.93-7.01Z",
                  fill: "#CCD1D9"
                }), React.createElement("path", {
                  d: "M0 7.012h86.132v59.613c0 2.65-1.159 5.192-3.22 7.066-2.063 1.875-4.86 2.927-7.777 2.927H10.997c-2.917 0-5.714-1.052-7.776-2.927C1.159 71.817 0 69.275 0 66.625V7.012Z",
                  fill: "url(#a)"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "7.011",
                  y: "59.592"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "14.521"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "29.545"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "44.568"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.031",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "33.051",
                  y: "59.592"
                }), React.createElement("rect", {
                  height: "4.006",
                  width: "20.532",
                  fill: "#CCD1D9",
                  rx: "2.003",
                  x: "58.59",
                  y: "59.592"
                }), React.createElement("path", {
                  d: "m91.981 69.892-7.391 8.149 20.834 18.898 7.392-8.149-20.835-18.898Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M99.793 56.406c0 17.048-13.82 30.867-30.867 30.867-17.048 0-30.867-13.82-30.867-30.867S51.879 25.54 68.926 25.54s30.867 13.82 30.867 30.867Zm-4.735 0c0 14.433-11.7 26.133-26.132 26.133-14.433 0-26.133-11.7-26.133-26.133 0-14.432 11.7-26.132 26.133-26.132 14.432 0 26.132 11.7 26.132 26.133Z",
                  fill: "#F87CBC",
                  fillRule: "evenodd"
                }), React.createElement("g", {
                  filter: "url(#b)"
                }, React.createElement("path", {
                      d: "M68.925 82.538c14.433 0 26.133-11.7 26.133-26.132 0-14.433-11.7-26.133-26.133-26.133-14.432 0-26.132 11.7-26.132 26.133 0 14.432 11.7 26.132 26.133 26.132Z",
                      fill: "url(#c)",
                      fillOpacity: ".2"
                    })), React.createElement("path", {
                  d: "m105.423 96.941 7.391-8.148 1.114 1.01a5.497 5.497 0 1 1-7.389 8.146l-1.113-1.01-.003.002Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  d: "M66.046 65.891h6.129v6.13h-6.13v-6.13Zm6.129-5.04v1.976h-6.13V58.23a3.065 3.065 0 0 1 3.065-3.064 4.597 4.597 0 1 0-4.508-5.498l-6.012-1.204A10.73 10.73 0 1 1 72.175 60.85Z",
                  fill: "#FF0EB4"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: "a",
                      gradientUnits: "userSpaceOnUse",
                      x1: "-2.586",
                      x2: "121.837",
                      y1: "59.208",
                      y2: "61.642"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("linearGradient", {
                      id: "c",
                      gradientUnits: "userSpaceOnUse",
                      x1: "68.925",
                      x2: "95.258",
                      y1: "30.273",
                      y2: "98.605"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("filter", {
                      id: "b",
                      height: "68.966",
                      width: "68.965",
                      colorInterpolationFilters: "sRGB",
                      filterUnits: "userSpaceOnUse",
                      x: "34.443",
                      y: "21.923"
                    }, React.createElement("feFlood", {
                          floodOpacity: "0",
                          result: "BackgroundImageFix"
                        }), React.createElement("feGaussianBlur", {
                          in: "BackgroundImageFix",
                          stdDeviation: "4.175"
                        }), React.createElement("feComposite", {
                          in2: "SourceAlpha",
                          operator: "in",
                          result: "effect1_backgroundBlur_589_7213"
                        }), React.createElement("feBlend", {
                          in: "SourceGraphic",
                          in2: "effect1_backgroundBlur_589_7213",
                          result: "shape"
                        }))));
}

var NoIssues = {
  make: InspectorIssuesView__EmptyState$Imagery$NoIssues
};

function InspectorIssuesView__EmptyState$Imagery$Loading(Props) {
  var match = React.useState(function () {
        return Uuid.v4();
      });
  var id = match[0];
  return React.createElement("svg", {
              height: "119",
              width: "119",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("circle", {
                  cx: "59.5",
                  cy: "59.5",
                  fill: "url(#" + id + "-a)",
                  r: "59.5"
                }), React.createElement("path", {
                  d: "M82.8 26.2V39c0 9.5-5.8 17.4-14 21a23 23 0 0 1 14 21v12.7H37V81c0-9.4 5.8-17.4 14-21A23 23 0 0 1 37 39V26.2h45.7Z",
                  fill: "url(#" + id + "-b)"
                }), React.createElement("path", {
                  d: "M59.9 60s-13.6-5.8-13.6-12.8h27.3c-.2 7-13.7 12.8-13.7 12.8ZM44.7 75.4c-1.8 3.9-3 8.7-3 13.6h36.4c0-5.1-1-9.7-3-13.6H44.8Z",
                  fill: "#FF0EB4"
                }), React.createElement("path", {
                  d: "M84.9 93.7H35c-2 0-3.7.8-3.7 2v1.7c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2ZM84.9 20.5H35c-2 0-3.7 1-3.7 2v1.8c0 1 1.7 2 3.7 2H85c2 0 3.7-1 3.7-2v-1.8c0-1-1.7-2-3.7-2Z",
                  fill: "#AAB2BD"
                }), React.createElement("path", {
                  d: "M82.8 26.2H37V29h45.7v-2.8Z",
                  fill: "#CCD1D9"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "64.5",
                  fill: "#FF0EB4",
                  r: "1.8"
                }), React.createElement("circle", {
                  cx: "60",
                  cy: "70.9",
                  fill: "#FF0EB4",
                  r: "1.8"
                }), React.createElement("defs", undefined, React.createElement("linearGradient", {
                      id: "" + id + "-a",
                      gradientUnits: "userSpaceOnUse",
                      x1: "59.5",
                      x2: "59.5",
                      y1: "0",
                      y2: "119"
                    }, React.createElement("stop", {
                          stopColor: "#FAFBFD"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#E6E9ED"
                        })), React.createElement("linearGradient", {
                      id: "" + id + "-b",
                      gradientUnits: "userSpaceOnUse",
                      x1: "60",
                      x2: "60",
                      y1: "26.2",
                      y2: "93.7"
                    }, React.createElement("stop", {
                          stopColor: "#fff"
                        }), React.createElement("stop", {
                          offset: "1",
                          stopColor: "#F5F7FA"
                        }))));
}

var Loading = {
  make: InspectorIssuesView__EmptyState$Imagery$Loading
};

function InspectorIssuesView__EmptyState$Imagery$GetAlerted(Props) {
  return React.createElement("svg", {
              height: "102",
              width: "118",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M45.9 19C30.3 26.3 12.4 37.3 4.7 54.5c-1.3 3-2.3 6-2.9 9.2-1.1 8-1.6 17.5 4.6 24.1 5.6 5.9 14 4 18.2-1.2 10.2-12.9 24.6-17.2 40.8-15.9C70 61.5 58.5 27 46 19.1Z",
                  fill: "#E6E9ED"
                }), React.createElement("path", {
                  d: "M65.5 69.6c3.6-1.4 2-13.5-3.4-27C56.6 29 49.3 19.3 45.8 20.8c-3.6 1.4-2 13.5 3.4 27C54.7 61.3 62 71 65.5 69.6Z",
                  fill: "#CCD1D9",
                  stroke: "#6D7783",
                  strokeMiterlimit: "10",
                  strokeWidth: "4.7"
                }), React.createElement("path", {
                  d: "M72.2 92a2.3 2.3 0 1 0 4.3-1.8l-4.3 1.7Zm4.3-1.8-8.7-21.7-4.3 1.8 8.7 21.6 4.3-1.7Z",
                  fill: "#6D7783"
                }), React.createElement("path", {
                  d: "M69.5 60.5a7.6 7.6 0 1 0 0-15.2 7.6 7.6 0 0 0 0 15.2Z",
                  fill: "#FF0EB4"
                }), React.createElement("path", {
                  d: "M108.1 14.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#C90077"
                }), React.createElement("path", {
                  d: "M66.1 20.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2ZM109.5 52a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z",
                  fill: "#FF0EB4"
                }), React.createElement("path", {
                  d: "M90.1 49.2a3.1 3.1 0 1 0 0-6.2 3.1 3.1 0 0 0 0 6.2Z",
                  fill: "#FEEBF2"
                }), React.createElement("path", {
                  d: "M73.8 36.6a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0 0 7.6Z",
                  fill: "#FF0EB4"
                }), React.createElement("path", {
                  d: "M55.4 38.6a4 4 0 1 0 0-7.9 4 4 0 0 0 0 7.9ZM87.2 26.2a6.1 6.1 0 1 0 0-12.3 6.1 6.1 0 0 0 0 12.3Z",
                  fill: "#F87CBC"
                }), React.createElement("path", {
                  d: "M105.1 37.3a6.1 6.1 0 1 0 0-12.3 6.1 6.1 0 0 0 0 12.3Z",
                  fill: "#FF0EB4"
                }));
}

var GetAlerted = {
  make: InspectorIssuesView__EmptyState$Imagery$GetAlerted
};

var Imagery = {
  NoIssues: NoIssues,
  Loading: Loading,
  GetAlerted: GetAlerted
};

function InspectorIssuesView__EmptyState$Loading(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(80)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView__EmptyState$Imagery$Loading, {}), React.createElement(Spacer.make, {
                  height: 32
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Semi",
                  color: Styles.Color.light11,
                  children: "Loading issues..."
                }), React.createElement(Spacer.make, {
                  height: 8
                }), React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light10,
                  children: "This might take a few seconds"
                }));
}

var Loading$1 = {
  make: InspectorIssuesView__EmptyState$Loading
};

function InspectorIssuesView__EmptyState$Error(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView__EmptyState$Imagery$NoIssues, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.light11,
                  maxWidth: Css.px(300),
                  children: StringExt.widowless("There was a problem loading your issues")
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: null
                    }, "Inspector could not load your issues. Try reloading this page. If the issue persists please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), ".")));
}

var $$Error = {
  make: InspectorIssuesView__EmptyState$Error
};

function InspectorIssuesView__EmptyState$NoIssuesUnderCurrentFilters(Props) {
  var channels = Props.channels;
  var globalSend = Props.globalSend;
  var status = Props.status;
  var match = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var currentSavedView = match.savedView;
  var match$1 = InspectorIssuesFilterHooks.useDraftState(undefined);
  var isDraft = match$1.isDraft;
  var match$2 = AvoEngagement.Engagement.use("how-to-save-a-view-and-get-alerts");
  var intent = match$2[1];
  var match$3 = InspectorIssuesStore.useIssues(undefined, status);
  var issues = match$3.issues;
  var match$4 = InspectorIssuesStore.useAppVersionsInSyncWithIssues(3, undefined, undefined);
  var appVersions = match$4[0];
  var match$5 = InspectorIssuesFilterHooks.useFilters(undefined);
  var filters = match$5.filters;
  var issuesPresentWhenNoReleaseFilter = React.useMemo((function () {
          return Curry._1(Issues.size, InspectorIssuesFilterUtils.filterIssueViewModels(["release"], appVersions, issues, filters)) > 0;
        }), [
        issues,
        filters,
        appVersions
      ]);
  var statusLabel = InspectorIssueStatus.StatusQueryParam.toString(status).toLowerCase();
  var tmp;
  switch (status) {
    case /* Unresolved */0 :
        tmp = channels && !isDraft ? StringExt.widowless(" You’ll get notified on Slack when Inspector detects an issue matching your filter criteria.") : StringExt.widowless(" Set up alerts to get notified when Inspector detects an issue matching your filter criteria.");
        break;
    case /* Ignored */1 :
        tmp = React.createElement(React.Fragment, undefined, "Mark irrelevant issues as ", React.createElement("em", undefined, "Ignored"), " so you can focus un fixing those that matter to your team.");
        break;
    case /* Resolved */2 :
        tmp = channels ? "Don’t worry, if Inspector detects an issue that is expected to be resolved it will be resurfaced as a regression and alert on Slack." : "Don’t worry, if Inspector detects an issue that is expected to be resolved it will be resurfaced as a regression.";
        break;
    
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, issuesPresentWhenNoReleaseFilter ? React.createElement(InspectorIssuesView__EmptyState$Imagery$NoIssues, {}) : (
                channels ? React.createElement(InspectorIssuesView__EmptyState$Imagery$NoIssues, {}) : React.createElement(InspectorIssuesView__EmptyState$Imagery$GetAlerted, {})
              ), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.light11,
                  maxWidth: Css.px(300),
                  children: issuesPresentWhenNoReleaseFilter ? StringExt.widowless("No " + statusLabel + " issues found on filtered release(s)") : (
                      currentSavedView !== undefined ? (
                          channels ? StringExt.widowless("No " + statusLabel + " issues for this view") : StringExt.widowless("Get alerted on new issues for this view")
                        ) : (
                          channels && !isDraft ? StringExt.widowless("No " + statusLabel + " issues for these filters") : StringExt.widowless("Get alerted on new issues for these filters")
                        )
                    )
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: null
                    }, "We found ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "no " + statusLabel + " issues"
                        }), " seen in production" + (
                      status === /* Unresolved */0 ? "" : " in the last 24 hours"
                    ) + " for these filters.", issuesPresentWhenNoReleaseFilter ? React.createElement(React.Fragment, undefined, " This might be due to ", React.createElement($$Text.make, {
                                element: "Span",
                                weight: "Semi",
                                children: "low event volume"
                              }), " on the release(s) you have filtered for.") : null, React.createElement(Spacer.make, {
                          height: 16
                        }), tmp), React.createElement(Spacer.make, {
                      height: 20
                    }), currentSavedView !== undefined ? (
                    channels ? null : React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                icon: "bell",
                                id: "set-up-alerts-empty-state",
                                label: "Set up alerts",
                                onClick: (function (param) {
                                    Curry._1(globalSend, {
                                          TAG: /* OpenModal */4,
                                          _0: {
                                            NAME: "IssueAlertsSetup",
                                            VAL: currentSavedView
                                          }
                                        });
                                  })
                              }), React.createElement(Spacer.make, {
                                height: 20
                              }))
                  ) : (
                    isDraft ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.justifyContent("center"),
                                        tl: {
                                          hd: Css.gap(Css.px(16)),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, React.createElement(Button.make, {
                                    icon: "bell",
                                    id: "set-up-alerts-empty-state",
                                    label: "Set up alerts",
                                    onClick: (function (param) {
                                        Curry._1(globalSend, {
                                              TAG: /* OpenModal */4,
                                              _0: {
                                                NAME: "IssueAlertsSetup",
                                                VAL: undefined
                                              }
                                            });
                                      })
                                  }), React.createElement(Button.make, {
                                    icon: "presentation",
                                    label: "Show me how",
                                    onClick: (function (param) {
                                        Curry._1(intent.start, undefined);
                                      }),
                                    style: {
                                      NAME: "custom",
                                      VAL: {
                                        customColor: Styles.Color.white,
                                        hoverColor: Styles.Color.white,
                                        customBackgroundColor: Styles.Color.primaryPurple,
                                        customBackgroundHoverColor: Styles.Color.darkPurple,
                                        optionalCustomOutlineColor: undefined,
                                        optionalCustomOutlineHoverColor: undefined
                                      }
                                    },
                                    minWidth: 132
                                  })), React.createElement(Spacer.make, {
                                height: 20
                              })) : React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                                icon: "presentation",
                                label: "Show me how",
                                onClick: (function (param) {
                                    Curry._1(intent.start, undefined);
                                  }),
                                style: {
                                  NAME: "custom",
                                  VAL: {
                                    customColor: Styles.Color.white,
                                    hoverColor: Styles.Color.white,
                                    customBackgroundColor: Styles.Color.primaryPurple,
                                    customBackgroundHoverColor: Styles.Color.darkPurple,
                                    optionalCustomOutlineColor: undefined,
                                    optionalCustomOutlineHoverColor: undefined
                                  }
                                },
                                minWidth: 132
                              }), React.createElement(Spacer.make, {
                                height: 20
                              }))
                  ), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: null
                    }, "If this is unexpected, please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), " so we can resolve it with you")));
}

var NoIssuesUnderCurrentFilters = {
  make: InspectorIssuesView__EmptyState$NoIssuesUnderCurrentFilters
};

function InspectorIssuesView__EmptyState$NoIssues(Props) {
  var channels = Props.channels;
  var globalSend = Props.globalSend;
  var status = Props.status;
  var statusLabel = InspectorIssueStatus.StatusQueryParam.toString(status).toLowerCase();
  var tmp;
  switch (status) {
    case /* Unresolved */0 :
        tmp = channels ? StringExt.widowless("You’ll get notified on Slack when Inspector detects an issue.") : StringExt.widowless("Set up alerts to get notified when Inspector detects an issue.");
        break;
    case /* Ignored */1 :
        tmp = React.createElement(React.Fragment, undefined, "Mark irrelevant issues as ", React.createElement("em", undefined, "Ignored"), " so you can focus un fixing those that matter to your team.");
        break;
    case /* Resolved */2 :
        tmp = channels ? "Don’t worry, if Inspector detects an issue that is expected to be resolved it will be resurfaced as a regression and alert on Slack." : "Don’t worry, if Inspector detects an issue that is expected to be resolved it will be resurfaced as a regression.";
        break;
    
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding(Css.px(64)),
                    tl: {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("center"),
                        tl: {
                          hd: Css.justifyContent("center"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorIssuesView__EmptyState$Imagery$NoIssues, {}), React.createElement(Spacer.make, {
                  height: 24
                }), React.createElement($$Text.make, {
                  size: "Huge",
                  weight: "Bold",
                  textAlign: "center",
                  color: Styles.Color.light11,
                  maxWidth: Css.px(300),
                  children: "No " + statusLabel + " issues found"
                }), React.createElement(Spacer.make, {
                  height: 16
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(388)),
                        tl: {
                          hd: Css.textAlign("center"),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: null
                    }, "We found ", React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          children: "no " + statusLabel + " issues"
                        }), " seen in production" + (
                      status === /* Unresolved */0 ? "" : " in the last 24 hours"
                    ) + "."), React.createElement(Spacer.make, {
                      height: 20
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      color: Styles.Color.light10,
                      children: tmp
                    }), React.createElement(Spacer.make, {
                      height: 20
                    }), status === /* Unresolved */0 && channels === /* [] */0 ? React.createElement(React.Fragment, undefined, React.createElement(Button.make, {
                            icon: "bell",
                            label: "Set up alerts",
                            onClick: (function (param) {
                                Curry._1(globalSend, {
                                      TAG: /* OpenModal */4,
                                      _0: {
                                        NAME: "IssueAlertsSetup",
                                        VAL: undefined
                                      }
                                    });
                              })
                          }), React.createElement(Spacer.make, {
                            height: 20
                          })) : null, React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: null
                    }, "If this is unexpected, please ", React.createElement(TextButton.make, {
                          onClick: (function (param) {
                              Intercom.showNewMessage(undefined);
                            }),
                          style: "Blue",
                          children: "reach out"
                        }), " so we can resolve it with you")));
}

var NoIssues$1 = {
  make: InspectorIssuesView__EmptyState$NoIssues
};

function InspectorIssuesView__EmptyState(Props) {
  var filtersCount = Props.filtersCount;
  var globalSend = Props.globalSend;
  var loadingState = Props.loadingState;
  var status = Props.status;
  var match = WorkspaceContext.use(undefined);
  var match$1 = InspectorIssuesFilterHooks.SavedViewStore.useSavedViewState(undefined);
  var currentSavedView = match$1.savedView;
  var savedViewStatus = match$1.savedViewStatus;
  var eventType = React.useMemo((function () {
          if (currentSavedView !== undefined) {
            return /* SavedView */{
                    _0: currentSavedView.id
                  };
          } else {
            return /* InspectorIssues */0;
          }
        }), [currentSavedView]);
  var channelsMode = React.useMemo((function () {
          if (savedViewStatus === "success" || savedViewStatus === "notFound" || savedViewStatus === "noSelection") {
            return "Fetch";
          } else {
            return "Wait";
          }
        }), [savedViewStatus]);
  var channels = FirebaseFetcherHooks.useNotificationChannels(channelsMode, match.id, eventType);
  if (typeof loadingState === "object") {
    if (loadingState.NAME === "error") {
      return React.createElement(InspectorIssuesView__EmptyState$Error, {});
    }
    
  } else if (loadingState === "success" && typeof channels === "object" && channels.NAME === "Loaded") {
    var channels$1 = channels.VAL;
    if (filtersCount > 0 || Belt_Option.isSome(currentSavedView)) {
      return React.createElement(InspectorIssuesView__EmptyState$NoIssuesUnderCurrentFilters, {
                  channels: channels$1,
                  globalSend: globalSend,
                  status: status
                });
    } else {
      return React.createElement(InspectorIssuesView__EmptyState$NoIssues, {
                  channels: channels$1,
                  globalSend: globalSend,
                  status: status
                });
    }
  }
  if (channels === "Error") {
    return React.createElement(InspectorIssuesView__EmptyState$Error, {});
  } else if (channels === "Initial") {
    return React.createElement(InspectorIssuesView__EmptyState$Loading, {});
  } else {
    return React.createElement(InspectorIssuesView__EmptyState$Loading, {});
  }
}

var make = InspectorIssuesView__EmptyState;

export {
  Imagery ,
  Loading$1 as Loading,
  $$Error ,
  NoIssuesUnderCurrentFilters ,
  NoIssues$1 as NoIssues,
  make ,
}
/* Css Not a pure module */
