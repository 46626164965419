// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "./Icons.mjs";
import * as Title from "./Title.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Invite from "./Invite.mjs";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Welcome from "./Welcome.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as SsoUtils from "./SsoUtils.mjs";
import * as UserFlag from "./UserFlag.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as FetchModel from "./FetchModel.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as SchemaMemberHeads from "./SchemaMemberHeads.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as AuthenticationMethods from "./AuthenticationMethods.mjs";

var missingAccessBannerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.justifyContent("spaceBetween"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.padding2(Css.px(8), Css.px(16)),
            tl: {
              hd: Css.backgroundColor(Styles.Color.lightOrange),
              tl: {
                hd: Css.borderRadius(Styles.Border.radius),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var rootStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.flexDirection("column"),
                tl: {
                  hd: Css.alignItems("stretch"),
                  tl: {
                    hd: Css.padding(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.marginBottom(Css.px(10)),
                        tl: {
                          hd: Css.marginRight(Css.px(10)),
                          tl: {
                            hd: Css.width(Css.px(250)),
                            tl: {
                              hd: Css.height(Css.px(150)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.white),
                                  tl: {
                                    hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.transition({
                                              NAME: "ms",
                                              VAL: 200.0
                                            }, undefined, undefined, "box-shadow"),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(8), Css.px(24), Css.px(0), undefined, Css.rgba(0, 0, 0, {
                                                              NAME: "num",
                                                              VAL: 0.08
                                                            }))),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var headerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexGrow(1.0),
        tl: {
          hd: Css.justifyContent("center"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.padding(Css.px(8)),
              tl: {
                hd: Css.paddingBottom(Css.px(4)),
                tl: {
                  hd: Css.unsafe("wordBreak", "break-word"),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var footerStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexShrink(0.0),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.alignItems("center"),
            tl: {
              hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light02),
              tl: {
                hd: Css.padding(Css.px(8)),
                tl: {
                  hd: Css.minHeight(Css.px(41)),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

function Schemas$Workspace(Props) {
  var schemaId = Props.schemaId;
  var schemaState = FirebaseFetcherHooks.useSchema(schemaId, true);
  if (typeof schemaState !== "object") {
    return null;
  }
  var schema = schemaState.VAL;
  var tmp = {
    size: "Medium",
    weight: "Semi",
    textAlign: "center",
    lines: 3,
    color: Styles.Color.light11,
    children: Belt_Option.getWithDefault(Caml_option.nullable_to_opt(schema.name), "Untitled")
  };
  var tmp$1 = Caml_option.nullable_to_opt(schema.name);
  if (tmp$1 !== undefined) {
    tmp.title = Caml_option.valFromOption(tmp$1);
  }
  var createdAt = Models.toDateOption(schema.createdAt);
  return React.createElement("button", {
              className: rootStyles,
              onClick: (function (param) {
                  Router.push(undefined, Router.Link.getBaseRoute(undefined, {
                            NAME: "schema",
                            VAL: schemaId
                          }));
                })
            }, React.createElement("div", {
                  className: headerStyles
                }, React.createElement($$Text.make, tmp)), React.createElement("div", {
                  className: footerStyles
                }, React.createElement($$Text.make, {
                      size: "Small",
                      weight: "Medium",
                      color: Styles.Color.light07,
                      children: createdAt !== undefined ? Caml_option.valFromOption(createdAt).toDateString() : null
                    }), React.createElement(SchemaMemberHeads.make, {
                      schemaId: schemaId,
                      limit: 3
                    })));
}

var Workspace = {
  rootStyles: rootStyles,
  headerStyles: headerStyles,
  footerStyles: footerStyles,
  make: Schemas$Workspace
};

function Schemas(Props) {
  var userId = Props.userId;
  var auth = Firebase.getAuth(undefined);
  var currentUser = Belt_Option.getExn(Caml_option.nullable_to_opt(auth.currentUser));
  var currentUserEmail = currentUser.email;
  var currentUserEmail$1 = (currentUserEmail == null) ? undefined : Caml_option.some(currentUserEmail);
  var userSchemasState = FirebaseFetcherHooks.useUserSchemas(userId);
  var userSchemaInvitesState = FirebaseFetcherHooks.useUserSchemaInvites(currentUserEmail$1);
  var ssoSchemasState = FirebaseFetcherHooks.useSsoSchemasForDomain(Belt_Option.flatMap(currentUserEmail$1, SsoUtils.getEmailDomain));
  var canCreateAdditionalWorkspaces = UserFlag.useFlagEnabledForUserId(userId, "CanCreateAdditionalWorkspaces");
  var autoJoinSsoWorkspacesStatus = Welcome.useAutoJoinSsoWorkspaces(userSchemasState, ssoSchemasState, userId);
  if (userSchemasState === "Initial") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading your workspaces..."
              });
  }
  if (ssoSchemasState === "Initial") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Loading your workspaces..."
              });
  }
  if (autoJoinSsoWorkspacesStatus === "Loading") {
    return React.createElement(LoadingFullscreen.make, {
                message: "Joining new workspaces..."
              });
  }
  if (typeof userSchemasState !== "object") {
    return React.createElement(FetchModel.$$Error.make, {
                message: "Could not load your workspaces"
              });
  }
  var userSchemas = userSchemasState.VAL;
  var tmp;
  if (typeof userSchemaInvitesState === "object") {
    var invites = userSchemaInvitesState.VAL;
    tmp = Belt_List.length(invites) > 0 ? React.createElement("div", {
            className: Curry._1(Css.style, {
                  hd: Css.margin2(Css.px(50), Css.px(0)),
                  tl: /* [] */0
                })
          }, React.createElement(Title.make, {
                children: "Invites",
                size: "Medium"
              }), React.createElement(Spacer.make, {
                height: 20
              }), React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.flexWrap("wrap"),
                        tl: /* [] */0
                      }
                    })
              }, Belt_List.toArray(Belt_List.map(invites, (function (invite) {
                          return React.createElement(Invite.make, {
                                      invite: invite,
                                      userId: userId,
                                      key: invite.id
                                    });
                        }))))) : null;
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.px(900)),
                    tl: {
                      hd: Css.maxWidth(Css.vw(100)),
                      tl: {
                        hd: Css.padding(Css.px(50)),
                        tl: {
                          hd: Css.marginLeft("auto"),
                          tl: {
                            hd: Css.marginRight("auto"),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: /* [] */0
                          }
                        }
                      })
                }, React.createElement(Icons.AvoLogo.make, {
                      width: 100,
                      fill: Styles.Color.light12
                    }), React.createElement(Spacer.make, {
                      width: 20,
                      grow: 1.0
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Semi",
                      color: Styles.Color.light10,
                      children: "Welcome " + Belt_Option.getWithDefault(Caml_option.nullable_to_opt(currentUser.email), AvoConfig.anonymousUserDisplayName) + "!"
                    }), React.createElement(Spacer.make, {
                      width: 20
                    }), React.createElement(Button.make, {
                      label: "Log out",
                      onClick: (function (param) {
                          AuthenticationMethods.signOut(auth);
                        }),
                      style: "outline"
                    })), tmp, Belt_List.length(userSchemas) > 0 ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin2(Css.px(50), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(Title.make, {
                        children: "Your Workspaces",
                        size: "Medium"
                      }), React.createElement(Spacer.make, {
                        height: 20
                      }), autoJoinSsoWorkspacesStatus === "MissingAccess" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                              className: missingAccessBannerStyles
                            }, React.createElement($$Text.make, {
                                  size: "Medium",
                                  children: "Could not join one or more SSO workspaces. Please log out and log in again using SSO."
                                }), React.createElement(Button.make, {
                                  label: "Log out",
                                  onClick: (function (param) {
                                      AuthenticationMethods.signOut(auth);
                                    }),
                                  style: "outline"
                                })), React.createElement(Spacer.make, {
                              height: 20
                            })) : null, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexWrap("wrap"),
                                tl: /* [] */0
                              }
                            })
                      }, Belt_List.toArray(Belt_List.map(userSchemas, (function (schema) {
                                  return React.createElement(Schemas$Workspace, {
                                              schemaId: schema.id,
                                              key: schema.id
                                            });
                                }))))) : null, canCreateAdditionalWorkspaces || FirebaseFetcherHooks.isSuperUser(undefined) ? React.createElement(Button.make, {
                    icon: "plus",
                    label: "New Workspace",
                    onClick: (function (param) {
                        FirebaseUtils.createSchema(currentUser.uid).then(function (schema) {
                              return Promise.resolve(Router.push(undefined, Curry._4(Router.Link.get, {
                                                  NAME: "schema",
                                                  VAL: schema.id
                                                }, "master", "dashboard", /* [] */0)));
                            });
                      }),
                    size: "large"
                  }) : null);
}

var make = Schemas;

export {
  missingAccessBannerStyles ,
  Workspace ,
  make ,
}
/* missingAccessBannerStyles Not a pure module */
