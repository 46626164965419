// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function toString(itemType) {
  if (itemType) {
    return "property";
  } else {
    return "event";
  }
}

function fromString(str) {
  switch (str) {
    case "event" :
        return /* Event */0;
    case "property" :
        return /* Property */1;
    default:
      return /* Event */0;
  }
}

function encode(itemType) {
  if (itemType) {
    return "property";
  } else {
    return "event";
  }
}

function decode(json) {
  return fromString(Json_decode.string(json));
}

var ItemType = {
  toString: toString,
  fromString: fromString,
  encode: encode,
  decode: decode
};

function encode$1(semanticSearchResult) {
  return Json_encode.object_({
              hd: [
                "schemaId",
                semanticSearchResult.schemaId
              ],
              tl: {
                hd: [
                  "itemId",
                  semanticSearchResult.itemId
                ],
                tl: {
                  hd: [
                    "name",
                    semanticSearchResult.name
                  ],
                  tl: {
                    hd: [
                      "description",
                      semanticSearchResult.description
                    ],
                    tl: {
                      hd: [
                        "itemType",
                        semanticSearchResult.itemType ? "property" : "event"
                      ],
                      tl: {
                        hd: [
                          "distance",
                          semanticSearchResult.distance
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function decode$1(json) {
  return {
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          itemId: Json_decode.field("itemId", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          itemType: Json_decode.field("itemType", decode, json),
          distance: Json_decode.field("distance", Json_decode.$$float, json)
        };
}

function decodeFromPostgres(json) {
  return {
          schemaId: Json_decode.field("schema_id", Json_decode.string, json),
          itemId: Json_decode.field("item_id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          itemType: Json_decode.field("item_type", decode, json),
          distance: Json_decode.field("distance", Json_decode.$$float, json)
        };
}

export {
  ItemType ,
  encode$1 as encode,
  decode$1 as decode,
  decodeFromPostgres ,
}
/* No side effect */
