// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Select from "./Select.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as $$Window from "./externals/window.mjs";
import * as AvoModel from "./avoModel.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DidMount from "./DidMount.mjs";
import * as AvoConfig from "../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as OpsWithState from "./OpsWithState.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as AvoConfigTypes from "../../shared/models/AvoConfigTypes.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.mjs";
import * as SourceSettingsEvents from "./SourceSettingsEvents.mjs";
import * as DestinationSegmentConfig from "./DestinationSegmentConfig.mjs";
import * as SourceSettingsDestinations from "./SourceSettingsDestinations.mjs";

function modelSourceConfigToAnalyticsSourceConfig(sourceConfig) {
  return Belt_List.toArray(Belt_List.map(sourceConfig, (function (modelSourceConfig) {
                    if (modelSourceConfig === "IosSwiftUseAmplitudeOver5") {
                      return "IosSwiftUseAmplitude5";
                    } else if (modelSourceConfig === "SwiftConcurrency") {
                      return "SwiftConcurrency";
                    } else if (modelSourceConfig === "ForceFlattenPropertyBundlesInCodegen") {
                      return "ForceFlattenPropertyBundlesInCodegen";
                    } else {
                      return "ValidateAvoEventFunction";
                    }
                  })));
}

var root = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.padding4(Css.px(40), Css.px(20), Css.px(20), Css.px(48)),
          tl: /* [] */0
        }
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(12)),
      tl: /* [] */0
    });

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.justifyContent("spaceBetween"),
          tl: {
            hd: Css.padding2(Css.px(15), Css.px(20)),
            tl: {
              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
              tl: /* [] */0
            }
          }
        }
      }
    });

var label2 = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 1.0
          }),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.semi),
        tl: {
          hd: Css.fontSize(Styles.FontSize.regular),
          tl: /* [] */0
        }
      }
    });

var value = Curry._1(Css.style, {
      hd: Css.flex({
            NAME: "num",
            VAL: 2.0
          }),
      tl: {
        hd: Css.display("flex"),
        tl: {
          hd: Css.justifyContent("flexEnd"),
          tl: /* [] */0
        }
      }
    });

var Style = {
  root: root,
  label: label,
  row: row,
  label2: label2,
  value: value
};

function SourceConfigOverview(Props) {
  var source = Props.source;
  var model = Props.model;
  var sendActions = Props.sendActions;
  var schemaBundle = Props.schemaBundle;
  var globalSend = GlobalSendContext.use(undefined);
  var hasJsonSchemaFeatureFlag = AppFeatureFlag.useFeatureFlag("JsonSchema");
  var hasGtmFeatureFlag = AppFeatureFlag.useFeatureFlag("Gtm");
  var hasSegmentDestinationIntegrationsFeatureFlag = AppFeatureFlag.useFeatureFlag("SegmentDestinationIntegrations");
  var isGlobalWorkspace = OrganizationContext.useIsGlobalWorkspace(undefined);
  var sourceId = source.id;
  var analyticsLanguage = AnalyticsUtils.analyticsLanguage(source.language);
  var libraryName = source.filename;
  var name = Belt_Option.getWithDefault(source.name, "");
  var context = {
    sourceId: sourceId,
    sourceQuery: sourceId
  };
  var onUpdateName = function (name) {
    if (name.trim() === "") {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "AlertModal",
                    VAL: [
                      "Name cannot be empty",
                      "Please choose a unique name for your source",
                      "OK",
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    }
    if (Caml_obj.equal(source.name, name)) {
      return ;
    }
    var isUnique = Belt_List.everyU(Belt_List.keepU(model.sources, (function (param) {
                return param.id !== sourceId;
              })), (function (source) {
            return Caml_obj.notequal(Belt_Option.map(source.name, Case.camel), Case.camel(name));
          }));
    if (isUnique) {
      return Curry.app(sendActions, [
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  [[
                      {
                        NAME: "UpdateSourceName",
                        VAL: [
                          sourceId,
                          name.trim()
                        ]
                      },
                      context
                    ]]
                ]);
    } else {
      return Curry._1(globalSend, {
                  TAG: /* OpenModal */4,
                  _0: {
                    NAME: "AlertModal",
                    VAL: [
                      "\"" + name + "\" already exists",
                      "Please choose a unique name for your source",
                      "OK",
                      (function (param) {
                          
                        })
                    ]
                  }
                });
    }
  };
  return React.createElement("div", {
              className: root
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginBottom(Css.px(16)),
                        tl: /* [] */0
                      })
                }, React.createElement("div", {
                      className: label
                    }, React.createElement($$Text.make, {
                          size: "Small",
                          weight: "Semi",
                          color: Styles.Color.light10,
                          children: "Name"
                        })), React.createElement(OpsWithState.make, {
                      value: name,
                      onFlush: onUpdateName,
                      onChangeCompleted: (function (value, valueBefore) {
                          AnalyticsRe.dataSourceUpdated(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, value, valueBefore, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceName", undefined, modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, schemaBundle.branchId, schemaBundle.schemaId);
                        }),
                      children: (function (value, onChange, onBlur, onFocus) {
                          return React.createElement(Input.make, {
                                      value: value,
                                      onChange: onChange,
                                      placeholder: "iOS, Android, Web, Server...",
                                      onBlur: onBlur,
                                      onFocus: onFocus,
                                      className: {
                                        hd: Css.fontWeight(Styles.FontWeight.semi),
                                        tl: {
                                          hd: Css.fontSize(Styles.FontSize.medium),
                                          tl: /* [] */0
                                        }
                                      },
                                      disabled: isGlobalWorkspace
                                    });
                        })
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("grid"),
                        tl: {
                          hd: Css.gridTemplateColumns({
                                hd: {
                                  NAME: "fr",
                                  VAL: 1
                                },
                                tl: {
                                  hd: {
                                    NAME: "fr",
                                    VAL: 1
                                  },
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.gridGap(Css.px(8)),
                            tl: {
                              hd: Css.marginBottom(Css.px(24)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, isGlobalWorkspace ? null : React.createElement("div", undefined, React.createElement("div", {
                            className: label
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light10,
                                children: "Development Platform"
                              })), React.createElement(Spacer.make, {
                            height: 8
                          }), React.createElement(Select.make, {
                            additionalStyles: {
                              hd: Css.width(Css.pct(100)),
                              tl: /* [] */0
                            },
                            onSelect: (function (value) {
                                var toValue = AvoConfigTypes.devPlatformFromJs(value);
                                if (toValue !== undefined) {
                                  return Curry.app(sendActions, [
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              undefined,
                                              (function (branch) {
                                                  var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                  AnalyticsRe.dataSourceUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, name, undefined, toValue, source.platform, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "DevelopmentPlatform", undefined, modelSourceConfigToAnalyticsSourceConfig(source.config), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                                }),
                                              undefined,
                                              [[
                                                  {
                                                    NAME: "UpdateSourcePlatformV3",
                                                    VAL: [
                                                      sourceId,
                                                      toValue
                                                    ]
                                                  },
                                                  context
                                                ]]
                                            ]);
                                }
                                
                              }),
                            options: Belt_Array.concatMany([
                                  Belt_Option.isNone(source.platform) ? [[
                                        {
                                          NAME: "Label",
                                          VAL: "Select Development Platform"
                                        },
                                        ""
                                      ]] : [],
                                  Belt_Array.map(Belt_Array.concatMany([
                                            AvoConfig.devPlatforms,
                                            hasJsonSchemaFeatureFlag ? ["JsonSchema"] : [],
                                            hasGtmFeatureFlag ? ["Gtm"] : []
                                          ]), (function (platform) {
                                          return [
                                                  {
                                                    NAME: "Label",
                                                    VAL: AvoConfig.devPlatformLabel(platform)
                                                  },
                                                  AvoConfigTypes.devPlatformToJs(platform)
                                                ];
                                        }))
                                ]),
                            value: Belt_Option.mapWithDefault(source.platform, "", AvoConfigTypes.devPlatformToJs)
                          }))), TrackingPlanModel.Source.hasConfig(source.config, "ValidateAvoEventFunction") && (Caml_obj.equal(source.language, "js_v2") || Caml_obj.equal(source.language, "ts")) ? React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.marginBottom(Css.px(12)),
                          tl: {
                            hd: Css.padding(Css.px(12)),
                            tl: {
                              hd: Css.borderRadius(Css.px(12)),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.light02),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.marginBottom(Css.px(12)),
                                tl: /* [] */0
                              }
                            })
                      }, React.createElement("div", {
                            className: label2
                          }, "Event validation function"), React.createElement("div", {
                            className: value
                          }, React.createElement(Switch.make, {
                                value: TrackingPlanModel.Source.hasConfig(source.config, "ValidateAvoEventFunction"),
                                onChange: (function (isActive) {
                                    if (isActive) {
                                      Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                AnalyticsRe.dataSourceUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig({
                                                          hd: "ValidateAvoEventFunction",
                                                          tl: source.config
                                                        }), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "AddSourceConfig",
                                                  VAL: [
                                                    sourceId,
                                                    "ValidateAvoEventFunction"
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                                    } else {
                                      Curry.app(sendActions, [
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            (function (branch) {
                                                var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                                AnalyticsRe.dataSourceUpdated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, NamedBranch.getId(branch), NamedBranch.getName(branch), schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, name, undefined, source.platform, undefined, analyticsLanguage, undefined, libraryName, undefined, source.path, undefined, "SourceConfig", modelSourceConfigToAnalyticsSourceConfig(source.config), modelSourceConfigToAnalyticsSourceConfig(Belt_List.keep(source.config, (function (config) {
                                                                return config !== "ValidateAvoEventFunction";
                                                              }))), undefined, undefined, undefined, undefined, undefined, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                              }),
                                            undefined,
                                            [[
                                                {
                                                  NAME: "RemoveSourceConfig",
                                                  VAL: [
                                                    sourceId,
                                                    "ValidateAvoEventFunction"
                                                  ]
                                                },
                                                context
                                              ]]
                                          ]);
                                    }
                                  })
                              }))), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontSize(Css.px(12)),
                              tl: {
                                hd: Css.fontWeight(Styles.FontWeight.regular),
                                tl: {
                                  hd: Css.marginTop(Css.px(-5)),
                                  tl: {
                                    hd: Css.lineHeight(Css.pct(150.0)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "The Avo.js file will include a function called validateAvoEvent which can be used to validate existing tracking calls. ", React.createElement("a", {
                            href: "/docs/data-validation",
                            target: "blank"
                          }, "See docs"), "  for more info.")) : null, isGlobalWorkspace ? null : React.createElement(SourceSettingsDestinations.make, {
                    model: model,
                    source: source,
                    schemaBundle: schemaBundle
                  }), React.createElement(SourceSettingsEvents.make, {
                  schemaBundle: schemaBundle,
                  model: model,
                  source: source
                }), hasSegmentDestinationIntegrationsFeatureFlag && Belt_List.someU(AvoModel.resolveDestinations(model, Belt_List.mapU(source.destinations, (function (param) {
                            return param.destinationId;
                          }))), (function (destination) {
                    return Caml_obj.equal(destination.type_, "Segment");
                  })) ? React.createElement(DestinationSegmentConfig.make, {
                    source: source,
                    context: context
                  }) : null, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(25)),
                        tl: /* [] */0
                      }),
                  id: "setup"
                }, React.createElement(DidMount.make, {
                      didMount: (function (param) {
                          if (RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).hash === "setup") {
                            return Belt_Option.forEach($$Window.$$Document.getElementById("setup"), (function (prim) {
                                          prim.scrollIntoView();
                                        }));
                          }
                          
                        })
                    })));
}

var make = SourceConfigOverview;

export {
  modelSourceConfigToAnalyticsSourceConfig ,
  Style ,
  make ,
}
/* root Not a pure module */
