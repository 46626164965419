// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Styles from "../styles.mjs";

function rootStyle(selected) {
  return Curry._1(Css.style, selected ? ({
                  hd: Css.selector(" td", {
                        hd: Css.important(Css.backgroundColor(Styles.Color.light02)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }) : /* [] */0);
}

var nameCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingRight(Css.px(4)),
        tl: /* [] */0
      }
    });

var statusCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: /* [] */0
    });

var createdCellStyle = Curry._1(Css.style, {
      hd: Css.paddingLeft(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(8)),
        tl: {
          hd: Css.minWidth(Css.px(88)),
          tl: /* [] */0
        }
      }
    });

var creatorCellStyle = Curry._1(Css.style, {
      hd: Css.padding(Css.px(8)),
      tl: /* [] */0
    });

var collaboratorsCellStyle = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.padding(Css.px(8)),
        tl: /* [] */0
      }
    });

var collaboratorStyle = Curry._1(Css.style, {
      hd: Css.marginLeft(Css.px(-4)),
      tl: /* [] */0
    });

export {
  rootStyle ,
  nameCellStyle ,
  statusCellStyle ,
  createdCellStyle ,
  creatorCellStyle ,
  collaboratorsCellStyle ,
  collaboratorStyle ,
}
/* nameCellStyle Not a pure module */
