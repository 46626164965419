// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

var label = Curry._1(Css.merge, {
      hd: $$Text.baseStyles,
      tl: {
        hd: $$Text.sizeStyles("Small"),
        tl: {
          hd: Curry._1(Css.style, {
                hd: Css.paddingTop(Css.px(2)),
                tl: {
                  hd: Css.marginBottom(Css.px(4)),
                  tl: {
                    hd: Css.fontWeight(Styles.FontWeight.semi),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: /* [] */0
                    }
                  }
                }
              }),
          tl: /* [] */0
        }
      }
    });

function InputLabel(Props) {
  var id = Props.id;
  var htmlFor = Props.htmlFor;
  var children = Props.children;
  var tmp = {
    className: label
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (htmlFor !== undefined) {
    tmp.htmlFor = Caml_option.valFromOption(htmlFor);
  }
  return React.createElement("label", tmp, children);
}

var make = InputLabel;

export {
  label ,
  make ,
}
/* label Not a pure module */
