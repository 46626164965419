// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as InspectorIssueType from "./InspectorIssueType.mjs";
import * as InspectorIssueStatus from "./InspectorIssueStatus.mjs";

function decodeFromPostgres(json) {
  return {
          issueId: Json_decode.field("issue_id", Json_decode.string, json),
          schemaId: Json_decode.field("schema_id", Json_decode.string, json),
          eventName: Json_decode.field("event_name", Json_decode.string, json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("property_name", Json_decode.string, param);
                }), json),
          issueType: Json_decode.field("issue_type", InspectorIssueType.decode, json),
          oldestAppVersion: Json_decode.field("oldest_app_version", Json_decode.string, json),
          newestAppVersion: Json_decode.field("newest_app_version", Json_decode.string, json),
          sharedIssueId: Json_decode.field("shared_issue_id", Json_decode.string, json),
          sourceId: Json_decode.field("source_id", Json_decode.string, json),
          firstSeen: new Date(Json_decode.field("first_seen", Json_decode.string, json)),
          lastSeenDay: new Date(Json_decode.field("last_seen_day", Json_decode.string, json)),
          ignoreIssue: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("ignore_issue", Json_decode.bool, param);
                    }), json), false),
          active: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("property_name", Json_decode.bool, param);
                    }), json), true),
          issueStatus: InspectorIssueStatus.decodeFromPostgres(json),
          regression: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("regression", Json_decode.bool, param);
                    }), json), false),
          branchIds: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("branch_ids", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), [])
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "issueId",
                t.issueId
              ],
              tl: {
                hd: [
                  "schemaId",
                  t.schemaId
                ],
                tl: {
                  hd: [
                    "eventName",
                    t.eventName
                  ],
                  tl: {
                    hd: [
                      "propertyName",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), t.propertyName)
                    ],
                    tl: {
                      hd: [
                        "issueType",
                        InspectorIssueType.encode(t.issueType)
                      ],
                      tl: {
                        hd: [
                          "oldestAppVersion",
                          t.oldestAppVersion
                        ],
                        tl: {
                          hd: [
                            "newestAppVersion",
                            t.newestAppVersion
                          ],
                          tl: {
                            hd: [
                              "sharedIssueId",
                              t.sharedIssueId
                            ],
                            tl: {
                              hd: [
                                "sourceId",
                                t.sourceId
                              ],
                              tl: {
                                hd: [
                                  "firstSeen",
                                  t.firstSeen.toString()
                                ],
                                tl: {
                                  hd: [
                                    "lastSeenDay",
                                    t.lastSeenDay.toString()
                                  ],
                                  tl: {
                                    hd: [
                                      "ignoreIssue",
                                      t.ignoreIssue
                                    ],
                                    tl: {
                                      hd: [
                                        "active",
                                        t.active
                                      ],
                                      tl: {
                                        hd: [
                                          "issueStatus",
                                          InspectorIssueStatus.encode(t.issueStatus)
                                        ],
                                        tl: {
                                          hd: [
                                            "regression",
                                            t.regression
                                          ],
                                          tl: {
                                            hd: [
                                              "branchIds",
                                              Json_encode.array((function (prim) {
                                                      return prim;
                                                    }), t.branchIds)
                                            ],
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode(json) {
  return {
          issueId: Json_decode.field("issueId", Json_decode.string, json),
          schemaId: Json_decode.field("schemaId", Json_decode.string, json),
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          propertyName: Json_decode.optional((function (param) {
                  return Json_decode.field("propertyName", Json_decode.string, param);
                }), json),
          issueType: Json_decode.field("issueType", InspectorIssueType.decode, json),
          oldestAppVersion: Json_decode.field("oldestAppVersion", Json_decode.string, json),
          newestAppVersion: Json_decode.field("newestAppVersion", Json_decode.string, json),
          sharedIssueId: Json_decode.field("sharedIssueId", Json_decode.string, json),
          sourceId: Json_decode.field("sourceId", Json_decode.string, json),
          firstSeen: new Date(Json_decode.field("firstSeen", Json_decode.string, json)),
          lastSeenDay: new Date(Json_decode.field("lastSeenDay", Json_decode.string, json)),
          ignoreIssue: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("ignoreIssue", Json_decode.bool, param);
                    }), json), false),
          active: Json_decode.field("active", Json_decode.bool, json),
          issueStatus: Json_decode.field("issueStatus", InspectorIssueStatus.decode, json),
          regression: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("regression", Json_decode.bool, param);
                    }), json), false),
          branchIds: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("branch_ids", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), param);
                    }), json), [])
        };
}

function toInspectorIssue(eventVariationOpt, t) {
  var eventVariation = eventVariationOpt !== undefined ? Caml_option.valFromOption(eventVariationOpt) : undefined;
  var appVersion = eventVariation !== undefined ? ({
        appVersion: eventVariation.appVersion,
        issueCount: eventVariation.count,
        eventCount: eventVariation.count,
        lastSeen: Caml_option.some(eventVariation.maxCreatedAt)
      }) : ({
        appVersion: t.newestAppVersion,
        issueCount: 1,
        eventCount: 1,
        lastSeen: Caml_option.some(t.lastSeenDay)
      });
  return {
          issueId: t.sharedIssueId,
          schemaId: t.schemaId,
          eventName: t.eventName,
          propertyName: t.propertyName,
          issueType: t.issueType,
          sharedIssueId: t.sharedIssueId,
          sourceId: t.sourceId,
          appVersions: Js_dict.fromArray([[
                  t.newestAppVersion,
                  appVersion
                ]]),
          newestAppVersion: t.newestAppVersion,
          oldestAppVersion: t.oldestAppVersion,
          issueCount: 1,
          eventCount: 1,
          lastSeen: t.lastSeenDay,
          firstSeen: t.firstSeen,
          issueStatus: t.issueStatus,
          regression: t.regression,
          branchIds: t.branchIds
        };
}

var TimescaleV2 = {
  decodeFromPostgres: decodeFromPostgres,
  encode: encode,
  decode: decode,
  toInspectorIssue: toInspectorIssue
};

export {
  TimescaleV2 ,
}
/* InspectorIssueStatus Not a pure module */
