// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Link from "./Link.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import * as Nanoid from "nanoid";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ContextBox from "./ContextBox.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as NamedBranch from "./NamedBranch.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var optionsContainerStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.left(Css.px(0)),
        tl: {
          hd: Css.bottom(Css.px(0)),
          tl: {
            hd: Css.transform(Css.translateY(Css.pct(100))),
            tl: {
              hd: Css.borderRadius(Styles.Border.radius),
              tl: {
                hd: Css.backgroundColor(Styles.Color.white),
                tl: {
                  hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                  tl: {
                    hd: Css.width(Css.pct(200)),
                    tl: {
                      hd: Css.maxHeight(Css.px(300)),
                      tl: {
                        hd: Css.overflow("auto"),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var optionsStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(4)),
      tl: {
        hd: Css.selector(":empty::after", {
              hd: Css.unsafe("content", "'No results...'"),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.padding(Css.px(11)),
                  tl: {
                    hd: Css.paddingLeft(Css.px(16)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.fontSize(Styles.FontSize.regular),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.semi),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var optionStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.padding2(Css.px(10), Css.px(15)),
              tl: {
                hd: Css.width(Css.pct(100)),
                tl: {
                  hd: Css.textAlign("left"),
                  tl: {
                    hd: Css.borderRadius(Styles.Border.radius),
                    tl: {
                      hd: Css.hover({
                            hd: Css.backgroundColor(Styles.Color.light02),
                            tl: {
                              hd: Css.color(Styles.Color.darkBlue),
                              tl: /* [] */0
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var alreadySelectedStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(10), Css.px(15)),
      tl: {
        hd: Css.width(Css.pct(100)),
        tl: /* [] */0
      }
    });

var inputContainerStyles = Curry._1(Css.style, {
      hd: Css.position("sticky"),
      tl: {
        hd: Css.top("zero"),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.flexDirection("row"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                tl: {
                  hd: Css.backgroundColor(Styles.Color.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var searchInputStyles = Curry._1(Css.style, {
      hd: Css.width({
            NAME: "add",
            VAL: [
              Css.pct(100),
              Css.px(8)
            ]
          }),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(18)),
          tl: {
            hd: Css.fontSize(Css.px(14)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButtonStyles(disabled) {
  return Curry._1(Css.style, {
              hd: Css.height(Css.pct(100)),
              tl: {
                hd: Css.padding(Css.px(2)),
                tl: {
                  hd: Css.marginLeft(Css.px(6)),
                  tl: {
                    hd: Css.marginRight(Css.px(6)),
                    tl: {
                      hd: Css.borderWidth(Css.px(0)),
                      tl: {
                        hd: Css.borderRadius(Css.px(2)),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.transition({
                                  NAME: "ms",
                                  VAL: Styles.Duration.$$default
                                }, undefined, undefined, "opacity"),
                            tl: {
                              hd: Css.opacity(disabled ? 0 : 1),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.outlineStyle("none"),
                                  tl: {
                                    hd: Css.cursor("pointer"),
                                    tl: {
                                      hd: Css.active({
                                            hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                            tl: /* [] */0
                                          }),
                                      tl: {
                                        hd: Css.focus({
                                              hd: Css.outlineStyle("none"),
                                              tl: {
                                                hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                tl: /* [] */0
                                              }
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function CategoryInput$InputBody(Props) {
  var options = Props.options;
  var currentCategories = Props.currentCategories;
  var onAdd = Props.onAdd;
  var sendActions = SendActionsContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setSearchString = match[1];
  var searchString = match[0];
  var filteredOptions = Fuzzaldrin.filter(options, searchString, {
        key: "name",
        maxResults: 100
      });
  var searchStringAlreadySelected = Belt_Array.some(currentCategories, (function (param) {
          return param.name.toLowerCase() === searchString.toLowerCase();
        }));
  return React.createElement("div", {
              className: optionsContainerStyles
            }, React.createElement("span", {
                  className: inputContainerStyles
                }, React.createElement("input", {
                      className: searchInputStyles,
                      autoFocus: true,
                      placeholder: "Filter by...",
                      type: "text",
                      value: searchString,
                      onChange: (function (e) {
                          var value = e.target.value;
                          Curry._1(setSearchString, (function (param) {
                                  return value;
                                }));
                        })
                    }), React.createElement("button", {
                      className: eraseButtonStyles(searchString === ""),
                      disabled: searchString === "",
                      onClick: (function (param) {
                          Curry._1(setSearchString, (function (param) {
                                  return "";
                                }));
                        })
                    }, React.createElement(IconErase.make, {
                          color: Styles.Color.light07,
                          hoverColor: Styles.Color.red
                        }))), React.createElement("div", {
                  className: optionsStyles
                }, Belt_Array.map(filteredOptions, (function (category) {
                        return React.createElement("button", {
                                    key: category.id,
                                    className: optionStyles,
                                    onClick: (function (param) {
                                        Curry._2(onAdd, category.id, category.name);
                                      })
                                  }, React.createElement($$Text.make, {
                                        size: "Medium",
                                        weight: "Semi",
                                        children: category.name === "" ? "Untitled Category" : category.name
                                      }));
                      })), Caml_obj.equal(filteredOptions, []) && searchString !== "" ? (
                    searchStringAlreadySelected ? React.createElement("div", {
                            className: alreadySelectedStyles
                          }, React.createElement($$Text.make, {
                                size: "Small",
                                weight: "Semi",
                                color: Styles.Color.light12,
                                children: "\"" + searchString + "\" is already selected"
                              })) : React.createElement("button", {
                            className: optionStyles,
                            onClick: (function (param) {
                                var id = Nanoid.nanoid();
                                Curry.app(sendActions, [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      (function (branch) {
                                          var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
                                          Curry._1(addToast, {
                                                message: "New category created",
                                                toastType: /* Success */0,
                                                action: {
                                                  message: "Open",
                                                  toastCallback: (function (param) {
                                                      Router.Schema.pushDrawerItem(undefined, undefined, {
                                                            NAME: "category",
                                                            VAL: [
                                                              id,
                                                              undefined
                                                            ]
                                                          });
                                                    })
                                                }
                                              });
                                          AnalyticsRe.categoryCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, id, searchString, NamedBranch.getId(branch), schemaBundle$1.schemaId);
                                          Curry._2(onAdd, id, searchString);
                                        }),
                                      undefined,
                                      [
                                        [
                                          {
                                            NAME: "AddGoal",
                                            VAL: id
                                          },
                                          {
                                            goalId: id,
                                            goalQuery: id
                                          }
                                        ],
                                        [
                                          {
                                            NAME: "UpdateGoalName",
                                            VAL: [
                                              id,
                                              searchString
                                            ]
                                          },
                                          {
                                            goalId: id,
                                            goalQuery: id
                                          }
                                        ]
                                      ]
                                    ]);
                              })
                          }, React.createElement($$Text.make, {
                                size: "Medium",
                                weight: "Semi",
                                lines: 1,
                                children: "+ Add Category \"" + searchString + "\""
                              }))
                  ) : null));
}

var rootStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.marginLeft(Css.px(10)),
          tl: {
            hd: Css.flexWrap("wrap"),
            tl: {
              hd: Css.gap(Css.px(4)),
              tl: /* [] */0
            }
          }
        }
      }
    });

var categoryStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.padding2(Css.px(5), Css.px(10)),
          tl: {
            hd: Css.borderRadius(Styles.Border.radius),
            tl: {
              hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
              tl: {
                hd: Css.color(Styles.Color.light12),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var categoryNameStyles = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.light12),
      tl: {
        hd: Css.hover({
              hd: Css.color(Styles.Color.deepBlue),
              tl: /* [] */0
            }),
        tl: /* [] */0
      }
    });

var categoryCloseStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.justifyContent("center"),
                  tl: {
                    hd: Css.width(Css.px(16)),
                    tl: {
                      hd: Css.height(Css.px(16)),
                      tl: {
                        hd: Css.padding(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.pct(100)),
                          tl: {
                            hd: Css.border(Css.px(1), "solid", Styles.Color.light02),
                            tl: {
                              hd: Css.selector(" svg", {
                                    hd: Css_Legacy_Core.SVG.stroke(Styles.Color.light07),
                                    tl: /* [] */0
                                  }),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.borderColor(Styles.Color.red),
                                      tl: {
                                        hd: Css.selector(" svg", {
                                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.red),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }
                                    }),
                                tl: {
                                  hd: Css.marginLeft(Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var addCategoryStyles = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(5), Css.px(10)),
      tl: /* [] */0
    });

function CategoryInput(Props) {
  var categories = Props.categories;
  var currentCategories = Props.currentCategories;
  var viewerCanEdit = Props.viewerCanEdit;
  var onRemove = Props.onRemove;
  var onAdd = Props.onAdd;
  var options = Belt_List.toArray(Belt_List.sort(Belt_List.keep(categories, (function (category) {
                  return !Belt_Array.some(currentCategories, (function (param) {
                                return param.id === category.id;
                              }));
                })), (function (a, b) {
              return Caml.string_compare(a.name, b.name);
            })));
  return React.createElement("div", {
              className: rootStyles
            }, currentCategories.length !== 0 ? Belt_Array.map(currentCategories, (function (category) {
                      return React.createElement("div", {
                                  key: category.id,
                                  className: categoryStyles
                                }, React.createElement(Link.make, {
                                      path: Router.Link.addDrawerItem(undefined, {
                                            NAME: "category",
                                            VAL: [
                                              category.id,
                                              undefined
                                            ]
                                          }),
                                      className: categoryNameStyles,
                                      children: React.createElement($$Text.make, {
                                            size: "Small",
                                            weight: "Semi",
                                            children: category.name === "" ? "Untitled Category" : category.name
                                          })
                                    }), React.createElement("button", {
                                      className: categoryCloseStyles,
                                      onClick: (function (param) {
                                          Curry._1(onRemove, category);
                                        })
                                    }, React.createElement(IconX.make, {
                                          size: 8
                                        })));
                    })) : React.createElement("div", {
                    className: categoryStyles
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light08,
                        children: "Uncategorized"
                      })), viewerCanEdit ? React.createElement(ContextBox.make, {
                    children: (function (_isOpen, param, close, buttonRef) {
                        return [
                                React.createElement(TextButton.make, {
                                      style: "Blue",
                                      size: "Small",
                                      children: React.createElement("span", {
                                            className: addCategoryStyles
                                          }, Caml_obj.equal(options, []) ? "+ Add to Category" : "+ Add Category"),
                                      ref: buttonRef
                                    }),
                                React.createElement(CategoryInput$InputBody, {
                                      options: options,
                                      currentCategories: currentCategories,
                                      onAdd: (function (id, name) {
                                          Curry._2(onAdd, id, name);
                                          Curry._1(close, undefined);
                                        })
                                    })
                              ];
                      })
                  }) : null);
}

var make = CategoryInput;

export {
  make ,
}
/* optionsContainerStyles Not a pure module */
