// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Models from "./Models.mjs";
import * as Router from "./Router.mjs";
import * as Sentry from "./externals/Sentry.mjs";
import * as Schemas from "./Schemas.mjs";
import * as Welcome from "./Welcome.mjs";
import * as AvoState from "./AvoState.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as NoAccess from "./NoAccess.mjs";
import * as Redirect from "./Redirect.mjs";
import * as AvoRoutes from "./AvoRoutes.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FetchModel from "./FetchModel.mjs";
import * as ModelStore from "./ModelStore.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChangesHook from "./ChangesHook.mjs";
import * as RouterStore from "./RouterStore.mjs";
import * as TimeMachine from "./TimeMachine.mjs";
import * as CmdKProvider from "./CmdKProvider.mjs";
import * as FocusContext from "./FocusContext.mjs";
import * as App from "firebase/app";
import * as ViewerContext from "./ViewerContext.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as AppFeatureFlag from "./AppFeatureFlag.mjs";
import * as CliFeatureFlag from "../../shared/CliFeatureFlag.mjs";
import * as InspectorStats from "./inspector/InspectorStats.mjs";
import * as DrawerContainer from "./DrawerContainer.mjs";
import * as StateFilterUtils from "./StateFilterUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as GlobalStateContext from "./GlobalStateContext.mjs";
import * as BranchDiscrepancies from "./BranchDiscrepancies.mjs";
import * as OrganizationContext from "./OrganizationContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as BranchChangesContext from "./BranchChangesContext.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as FetchImplementationStatus from "./implementationStatus/FetchImplementationStatus.mjs";
import * as BranchActionDiffCalculator from "./BranchActionDiffCalculator.mjs";

function LoggedIn$RoutesWithDrawer(Props) {
  var branchStatus = Props.branchStatus;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var renderModel = Props.renderModel;
  var role = Props.role;
  var schema = Props.schema;
  CliFeatureFlag.initFeatureFlags(false, schema, []);
  var globalState = GlobalStateContext.use(undefined);
  var currentFilters = AnalyticsUtils.currentFilters(model, globalState.filters, globalState.eventSort);
  var filters = React.useMemo((function () {
          return Belt_List.fromArray(Belt_Array.keepMapU(globalState.filters, StateFilterUtils.toModel));
        }), [globalState.filters]);
  return React.createElement(React.Fragment, undefined, React.createElement(AvoRoutes.make, {
                  branchStatus: branchStatus,
                  latestAction: latestAction,
                  latestMasterAction: latestMasterAction,
                  masterModel: masterModel,
                  model: model,
                  renderModel: renderModel,
                  role: role,
                  schema: schema
                }), React.createElement(DrawerContainer.make, {
                  branchStatus: branchStatus,
                  currentFilters: currentFilters,
                  filters: filters,
                  masterModel: masterModel,
                  member: member,
                  model: model,
                  renderModel: renderModel,
                  role: role,
                  schema: schema
                }));
}

var RoutesWithDrawer = {
  make: LoggedIn$RoutesWithDrawer
};

function LoggedIn$RenderModel(Props) {
  var schema = Props.schema;
  var branch = Props.branch;
  var mappedModel = Props.mappedModel;
  var model = Props.model;
  var mappedMasterModel = Props.mappedMasterModel;
  var masterModel = Props.masterModel;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var role = Props.role;
  var numberOfActions = Props.numberOfActions;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var member = Props.member;
  var branchStatus = Props.branchStatus;
  var settingsQuery = Props.settingsQuery;
  var workspace = WorkspaceContext.use(undefined);
  var currentBranchId = typeof branch === "object" ? branch.VAL : "master";
  var maybeToAndFromModels = BranchActionDiffCalculator.useToAndFromModels(branch === "master" ? "Skip" : undefined, workspace.id, currentBranchId, model, latestAction, masterModel, latestMasterAction, branchStatus);
  var changeState = ChangesHook.useChanges(Belt_Option.map(maybeToAndFromModels, (function (toAndFromModels) {
              return toAndFromModels.from.model;
            })), Belt_Option.map(maybeToAndFromModels, (function (toAndFromModels) {
              return toAndFromModels.to.model;
            })), (function (error) {
          Sentry.captureException(error, {
                message: "Failed to resolve changes from Worker in LoggedIn.RenderModel",
                schemaId: workspace.id,
                branchId: currentBranchId
              });
        }));
  var currentBranchName;
  if (typeof branch === "object") {
    var branchId = branch.VAL;
    currentBranchName = Belt_Option.mapWithDefault(Belt_List.getBy(masterModel.openBranches, (function (param) {
                return branchId === param[0];
              })), "N/A", (function (param) {
            return param[1];
          }));
  } else {
    currentBranchName = "main";
  }
  var updateMainModels = ModelStore.useUpdateMainModels(undefined);
  var updateCurrentBranchModels = ModelStore.useUpdateCurrentBranchModels(undefined);
  var modelStoreIsInitialized = ModelStore.useModelIsInitialized(undefined);
  React.useEffect((function () {
          Curry._3(updateMainModels, masterModel, mappedMasterModel, Belt_Option.map(latestMasterAction, Models.Action.getId));
        }), [masterModel]);
  React.useEffect((function () {
          if (typeof branch === "object") {
            Curry._2(updateCurrentBranchModels, [
                  model,
                  mappedModel
                ], Belt_Option.map(latestAction, Models.Action.getId));
          } else {
            Curry._2(updateCurrentBranchModels, undefined, undefined);
          }
        }), [
        model,
        branch
      ]);
  if (modelStoreIsInitialized) {
    return React.createElement(BranchChangesContext.make, {
                changes: changeState,
                children: React.createElement(FetchImplementationStatus.make, {
                      schema: schema,
                      model: model,
                      branchId: currentBranchId,
                      children: React.createElement(InspectorStats.Context.make, {
                            children: React.createElement(SchemaBundleContext.make, {
                                  currentBranchId: currentBranchId,
                                  currentBranchName: currentBranchName,
                                  children: React.createElement(CmdKProvider.make, {
                                        children: React.createElement(AvoState.make, {
                                              schemaId: schema.id,
                                              model: model,
                                              masterModel: masterModel,
                                              latestAction: latestAction,
                                              latestMasterAction: latestMasterAction,
                                              numberOfActions: numberOfActions,
                                              numberOfMasterActions: numberOfMasterActions,
                                              role: role,
                                              member: member,
                                              branchStatus: branchStatus,
                                              children: (function (renderModel) {
                                                  return React.createElement(LoggedIn$RoutesWithDrawer, {
                                                              branchStatus: branchStatus,
                                                              latestAction: latestAction,
                                                              latestMasterAction: latestMasterAction,
                                                              masterModel: masterModel,
                                                              member: member,
                                                              model: model,
                                                              renderModel: renderModel,
                                                              role: role,
                                                              schema: schema
                                                            });
                                                }),
                                              settingsQuery: settingsQuery
                                            })
                                      })
                                })
                          })
                    })
              });
  } else {
    return null;
  }
}

var make = React.memo(LoggedIn$RenderModel, (function (props, nextProps) {
        if (Caml_obj.equal(props.schema, nextProps.schema) && Caml_obj.equal(props.branch, nextProps.branch) && Caml_obj.equal(props.mappedModel, nextProps.mappedModel) && Caml_obj.equal(props.model, nextProps.model) && Caml_obj.equal(props.masterModel, nextProps.masterModel) && Caml_obj.equal(props.latestAction, nextProps.latestAction) && Caml_obj.equal(props.latestMasterAction, nextProps.latestMasterAction) && props.role === nextProps.role && props.numberOfActions === nextProps.numberOfActions && props.numberOfMasterActions === nextProps.numberOfMasterActions && Caml_obj.equal(props.member, nextProps.member) && Caml_obj.equal(props.branchStatus, nextProps.branchStatus)) {
          return props.settingsQuery === nextProps.settingsQuery;
        } else {
          return false;
        }
      }));

var RenderModel = {
  make: make
};

function LoggedIn$Branch(Props) {
  var branch = Props.branch;
  var latestMasterAction = Props.latestMasterAction;
  var mappedMasterModel = Props.mappedMasterModel;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var role = Props.role;
  var schema = Props.schema;
  var settingsQuery = Props.settingsQuery;
  var match = WorkspaceContext.use(undefined);
  var fetchModel = FetchModel.use(undefined, undefined, undefined, branch);
  var branchId = typeof branch === "object" ? branch.VAL : "master";
  switch (fetchModel.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(LoadingFullscreen.make, {
                    message: fetchModel._0
                  });
    case /* Error */1 :
        return React.createElement(FetchModel.$$Error.make, {
                    message: fetchModel._0
                  });
    case /* Result */2 :
        var branchModel = fetchModel._0;
        return React.createElement(DiscrepancyContext.make, {
                    config: schema.validationConfig,
                    configV2: schema.validationConfigV2,
                    model: branchModel,
                    forceAuditV1: match.settings.forceBranchAudit,
                    children: React.createElement(BranchDiscrepancies.Provider.make, {
                          branchId: typeof branch === "object" ? branch.VAL : "main",
                          schemaId: schema.id,
                          children: React.createElement(make, {
                                schema: schema,
                                branch: branch,
                                mappedModel: fetchModel._1,
                                model: branchModel,
                                mappedMasterModel: mappedMasterModel,
                                masterModel: masterModel,
                                latestAction: fetchModel._2,
                                latestMasterAction: latestMasterAction,
                                role: role,
                                numberOfActions: fetchModel._3,
                                numberOfMasterActions: numberOfMasterActions,
                                member: member,
                                branchStatus: fetchModel._4,
                                settingsQuery: settingsQuery,
                                key: "RenderModel-" + branchId + ""
                              })
                        })
                  });
    
  }
}

var Branch = {
  make: LoggedIn$Branch
};

function LoggedIn$Main(Props) {
  var branch = Props.branch;
  var branchStatus = Props.branchStatus;
  var latestAction = Props.latestAction;
  var latestMasterAction = Props.latestMasterAction;
  var mappedMasterModel = Props.mappedMasterModel;
  var mappedModel = Props.mappedModel;
  var masterModel = Props.masterModel;
  var member = Props.member;
  var model = Props.model;
  var numberOfActions = Props.numberOfActions;
  var numberOfMasterActions = Props.numberOfMasterActions;
  var role = Props.role;
  var schema = Props.schema;
  var settingsQuery = Props.settingsQuery;
  var match = WorkspaceContext.use(undefined);
  return React.createElement(DiscrepancyContext.make, {
              config: schema.validationConfig,
              configV2: schema.validationConfigV2,
              model: model,
              forceAuditV1: match.settings.forceBranchAudit,
              children: React.createElement(make, {
                    schema: schema,
                    branch: branch,
                    mappedModel: mappedModel,
                    model: model,
                    mappedMasterModel: mappedMasterModel,
                    masterModel: masterModel,
                    latestAction: latestAction,
                    latestMasterAction: latestMasterAction,
                    role: role,
                    numberOfActions: numberOfActions,
                    numberOfMasterActions: numberOfMasterActions,
                    member: member,
                    branchStatus: branchStatus,
                    settingsQuery: settingsQuery
                  })
            });
}

var Main = {
  make: LoggedIn$Main
};

function LoggedIn$Schema(Props) {
  var role = Props.role;
  var member = Props.member;
  var user = Props.user;
  var schemaId = Props.schemaId;
  var branch = Props.branch;
  var settingsQuery = Props.settingsQuery;
  var state = FirebaseFetcherHooks.useSchema(schemaId, true);
  var fetchModel = FetchModel.use(undefined, undefined, undefined, "master");
  if (typeof state !== "object") {
    if (state === "NotFound") {
      return null;
    } else {
      return React.createElement(LoadingFullscreen.make, {
                  message: "Loading workspace..."
                });
    }
  }
  var schema = state.VAL;
  switch (fetchModel.TAG | 0) {
    case /* Loading */0 :
    case /* Error */1 :
        break;
    case /* Result */2 :
        var numberOfMasterActions = fetchModel._3;
        var latestMasterAction = fetchModel._2;
        var mappedMasterModel = fetchModel._1;
        var masterModel = fetchModel._0;
        return React.createElement(WorkspaceContext.make, {
                    schema: schema,
                    children: React.createElement(OrganizationContext.make, {
                          schema: schema,
                          children: React.createElement(ViewerContext.make, {
                                user: user,
                                children: React.createElement(AppFeatureFlag.Provider.make, {
                                      schema: schema,
                                      children: typeof branch === "object" ? React.createElement(LoggedIn$Branch, {
                                              branch: branch,
                                              latestMasterAction: latestMasterAction,
                                              mappedMasterModel: mappedMasterModel,
                                              masterModel: masterModel,
                                              member: member,
                                              numberOfMasterActions: numberOfMasterActions,
                                              role: role,
                                              schema: schema,
                                              settingsQuery: settingsQuery,
                                              key: "Branch-" + schemaId + "-" + branch.VAL + ""
                                            }) : React.createElement(LoggedIn$Main, {
                                              branch: branch,
                                              branchStatus: fetchModel._4,
                                              latestAction: latestMasterAction,
                                              latestMasterAction: latestMasterAction,
                                              mappedMasterModel: mappedMasterModel,
                                              mappedModel: mappedMasterModel,
                                              masterModel: masterModel,
                                              member: member,
                                              model: masterModel,
                                              numberOfActions: numberOfMasterActions,
                                              numberOfMasterActions: numberOfMasterActions,
                                              role: role,
                                              schema: schema,
                                              settingsQuery: settingsQuery,
                                              key: "RenderModel-" + schemaId + "-main"
                                            })
                                    })
                              })
                        })
                  });
    
  }
  if (fetchModel.TAG === /* Loading */0) {
    return React.createElement(LoadingFullscreen.make, {
                message: fetchModel._0
              });
  } else {
    return React.createElement(FetchModel.$$Error.make, {
                message: fetchModel._0
              });
  }
}

var Schema = {
  make: LoggedIn$Schema
};

function LoggedIn$SchemaWrapperMainRedirect(Props) {
  var match = RouterStore.Schema.useSlices(function (state) {
        return [
                state.baseRoute,
                state.schemaRoute,
                state.drawerItems
              ];
      });
  return React.createElement(Redirect.make, {
              path: Curry._4(Router.Link.get, match[0], "master", match[1], match[2])
            });
}

var SchemaWrapperMainRedirect = {
  make: LoggedIn$SchemaWrapperMainRedirect
};

function LoggedIn$SchemaWrapper(Props) {
  var authState = Props.authState;
  var userId = Props.userId;
  var settingsQuery = Props.settingsQuery;
  var schemaId = RouterStore.Schema.useSchemaId(undefined);
  var branch = RouterStore.Schema.useBranch(undefined);
  var userState = FirebaseFetcherHooks.useUser(userId);
  var memberState = FirebaseFetcherHooks.useMember(schemaId, userId, true);
  var exit = 0;
  if (typeof branch === "object" && branch.NAME === "branch") {
    if (branch.VAL === "master") {
      return React.createElement(LoggedIn$SchemaWrapperMainRedirect, {});
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    var exit$2 = 0;
    if (typeof memberState === "object") {
      if (typeof userState === "object") {
        if (userState.NAME === "Success") {
          var member = memberState.VAL;
          var role = Belt_Option.getWithDefault(Models.Role.tFromJs(member.role), "NoAccess");
          if (role === "NoAccess") {
            return React.createElement(NoAccess.make, {
                        schemaId: schemaId,
                        userId: userId
                      });
          } else {
            return React.createElement(LoggedIn$Schema, {
                        role: role,
                        member: member,
                        user: userState.VAL,
                        schemaId: schemaId,
                        branch: branch,
                        settingsQuery: settingsQuery,
                        key: "Schema-" + schemaId + ""
                      });
          }
        }
        exit$2 = 3;
      } else {
        exit$2 = 3;
      }
    } else {
      if (memberState !== "NotFound") {
        return React.createElement(LoadingFullscreen.make, {
                    message: "Authenticating..."
                  });
      }
      if (typeof userState === "object" && userState.NAME === "Success") {
        if (typeof authState === "object" && authState.NAME === "Authenticated") {
          var user = userState.VAL;
          if (FirebaseFetcherHooks.isSuperUser(undefined)) {
            var superUserMember = {
              id: authState.VAL.uid,
              role: "Admin",
              type_: "user",
              createdAt: App.default.firestore.Timestamp.fromDate(new Date()),
              email: Belt_Option.getExn(Caml_option.nullable_to_opt(user.email))
            };
            return React.createElement(LoggedIn$Schema, {
                        role: "Admin",
                        member: superUserMember,
                        user: user,
                        schemaId: schemaId,
                        branch: branch,
                        settingsQuery: settingsQuery,
                        key: "Schema-" + schemaId + ""
                      });
          }
          exit$1 = 2;
        } else {
          exit$1 = 2;
        }
      } else {
        exit$2 = 3;
      }
    }
    if (exit$2 === 3) {
      if (userState === "Loading") {
        return React.createElement(LoadingFullscreen.make, {
                    message: "Authenticating..."
                  });
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      return React.createElement(NoAccess.make, {
                  schemaId: schemaId,
                  userId: userId
                });
    }
    
  }
  
}

var SchemaWrapper = {
  make: LoggedIn$SchemaWrapper
};

function LoggedIn(Props) {
  var userId = Props.userId;
  var authState = Props.authState;
  var baseRoute = Props.baseRoute;
  var settingsQuery = Props.settingsQuery;
  var tmp;
  var exit = 0;
  if (typeof baseRoute === "object") {
    if (baseRoute.NAME === "schema") {
      tmp = React.createElement(TimeMachine.Provider.make, {
            children: React.createElement(LoggedIn$SchemaWrapper, {
                  authState: authState,
                  userId: userId,
                  settingsQuery: settingsQuery
                })
          });
    } else {
      exit = 1;
    }
  } else if (baseRoute === "welcome") {
    tmp = React.createElement(Welcome.make, {
          userId: userId
        });
  } else if (baseRoute === "allSchemas") {
    tmp = React.createElement(Schemas.make, {
          userId: userId
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Redirect.make, {
          path: "/welcome"
        });
  }
  return React.createElement(FocusContext.make, {
              children: tmp
            });
}

var make$1 = LoggedIn;

export {
  RoutesWithDrawer ,
  RenderModel ,
  Branch ,
  Main ,
  Schema ,
  SchemaWrapperMainRedirect ,
  SchemaWrapper ,
  make$1 as make,
}
/* make Not a pure module */
