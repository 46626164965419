// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MappedModules from "../../../shared/MappedModules.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as InspectorIssueType from "../../../shared/models/InspectorIssueType.mjs";
import * as InspectorEventShape from "../../../shared/models/InspectorEventShape.mjs";

function makeSourcePresentable(source) {
  return {
          sourcePresence: InspectorEventShape.SourcePresence.encode(source.sourcePresence),
          issues: Belt_Array.mapU(source.issues, InspectorIssueType.getIssueTypeAsText)
        };
}

var PropertySource = {
  makeSourcePresentable: makeSourcePresentable
};

function makeSourcePresentable$1(source) {
  return {
          totalVolume: source.totalVolume,
          issues: Belt_Array.mapU(source.issues, InspectorIssueType.getIssueTypeAsText)
        };
}

var $$EventSource = {
  makeSourcePresentable: makeSourcePresentable$1
};

function makePropertyPresentable(param) {
  return {
          propertyName: param.propertyName,
          propertyShape: param.propertyShape,
          issues: Belt_Array.mapU(Belt_MapString.toArray(param.issues), (function (param) {
                  return [
                          param[0],
                          makeSourcePresentable(param[1])
                        ];
                })),
          nestedProperties: Belt_Array.mapU(param.nestedProperties, makePropertyPresentable)
        };
}

var Property = {
  makePropertyPresentable: makePropertyPresentable
};

function makeEventPresentable(param) {
  return {
          eventName: param.eventName,
          totalVolume: param.totalVolume,
          eventShape: InspectorEventShape.encode(param.eventShape),
          properties: Belt_Array.mapU(param.properties, makePropertyPresentable),
          issues: Belt_Array.mapU(Belt_MapString.toArray(param.issues), (function (param) {
                  return [
                          param[0],
                          makeSourcePresentable$1(param[1])
                        ];
                }))
        };
}

var $$Event = {
  makeEventPresentable: makeEventPresentable
};

function getId(e) {
  return e.eventName;
}

function eq(a, b) {
  return Caml.string_compare(a.eventName, b.eventName) === 0;
}

var include = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

var MapModule = include.MapModule;

var has = include.has;

var get = include.get;

var getExn = include.getExn;

var getAtIndex = include.getAtIndex;

var getBy = include.getBy;

var toArray = include.toArray;

var fromArray = include.fromArray;

var toList = include.toList;

var fromList = include.fromList;

var keys = include.keys;

var empty = include.empty;

var update = include.update;

var updateExisting = include.updateExisting;

var prepend = include.prepend;

var append = include.append;

var updateMany = include.updateMany;

var updateManyExisting = include.updateManyExisting;

var mergeMany = include.mergeMany;

var remove = include.remove;

var reduce = include.reduce;

var mapToArray = include.mapToArray;

var mapToMap = include.mapToMap;

var mapToT = include.mapToT;

var forEach = include.forEach;

var keep = include.keep;

var keepMap = include.keepMap;

var some = include.some;

var every = include.every;

var size = include.size;

var sizeToString = include.sizeToString;

var reorderV2 = include.reorderV2;

var reorderBuggy = include.reorderBuggy;

var eq$1 = include.eq;

var toArrayWithKeys = include.toArrayWithKeys;

var encodeDict = include.encodeDict;

var slice = include.slice;

var take = include.take;

export {
  PropertySource ,
  $$EventSource ,
  Property ,
  $$Event ,
  MapModule ,
  has ,
  get ,
  getExn ,
  getAtIndex ,
  getBy ,
  toArray ,
  fromArray ,
  toList ,
  fromList ,
  keys ,
  empty ,
  update ,
  updateExisting ,
  prepend ,
  append ,
  updateMany ,
  updateManyExisting ,
  mergeMany ,
  remove ,
  reduce ,
  mapToArray ,
  mapToMap ,
  mapToT ,
  forEach ,
  keep ,
  keepMap ,
  some ,
  every ,
  size ,
  sizeToString ,
  reorderV2 ,
  reorderBuggy ,
  eq$1 as eq,
  toArrayWithKeys ,
  encodeDict ,
  slice ,
  take ,
}
/* include Not a pure module */
