// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Icons from "../Icons.mjs";
import * as Toast from "../Toast.mjs";
import * as React from "react";
import * as Issues from "../../../shared/models/Issues.mjs";
import * as Router from "../Router.mjs";
import * as Styles from "../styles.mjs";
import * as Actions from "../actions.mjs";
import * as Domains from "../../../model/src/domains/Domains.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import * as Firebase from "../../../bs-firestore/src/Firebase.mjs";
import * as Redirect from "../Redirect.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as PromiseExt from "../externals/PromiseExt.mjs";
import * as TextButton from "../TextButton.mjs";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as ImportUtils from "../ImportUtils.mjs";
import * as NamedBranch from "../NamedBranch.mjs";
import * as RouterStore from "../RouterStore.mjs";
import * as ImportAction from "./ImportAction.mjs";
import * as ImportReview from "./ImportReview.mjs";
import * as ImportLanding from "./ImportLanding.mjs";
import * as SandboxBanner from "../SandboxBanner.mjs";
import * as ViewerContext from "../ViewerContext.mjs";
import * as FramerMotion from "framer-motion";
import * as NewBranchModal from "../NewBranchModal.mjs";
import * as WorkspaceContext from "../WorkspaceContext.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as SendActionsContext from "../SendActionsContext.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as FirebaseFetcherHooks from "../FirebaseFetcherHooks.mjs";
import * as GlobalRequirementsUtils from "../../../model/src/GlobalRequirementsUtils.mjs";
import * as ImportReview__V2__Utils from "./ImportReview__V2__Utils.mjs";

function logoStyles(withSandboxBanner) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, Styles.buttonReset),
              tl: {
                hd: Curry._1(Css.style, {
                      hd: Css.position("fixed"),
                      tl: {
                        hd: Css.left(Css.px(22)),
                        tl: {
                          hd: Css.top(Css.px(26 + SandboxBanner.getHeight(withSandboxBanner) | 0)),
                          tl: {
                            hd: Css.zIndex(2),
                            tl: /* [] */0
                          }
                        }
                      }
                    }),
                tl: /* [] */0
              }
            });
}

function closeStyles(withSandboxBanner) {
  return Curry._1(Css.style, {
              hd: Css.position("fixed"),
              tl: {
                hd: Css.right(Css.px(22)),
                tl: {
                  hd: Css.top(Css.px(20 + SandboxBanner.getHeight(withSandboxBanner) | 0)),
                  tl: {
                    hd: Css.zIndex(2),
                    tl: /* [] */0
                  }
                }
              }
            });
}

var contentStyles = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: {
          hd: Css.alignItems("center"),
          tl: /* [] */0
        }
      }
    });

function lastActionOnBranchQuery(schemaId, branchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).orderBy("createdAt", "desc").limit(1);
}

function proceedWithImport(selectedBranch, parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, schemaBundle, workspace, model, importStartedLocation, addToast, issues, viewerId, onSuccess) {
  var createBranchAsPromise = function (branchName, sendActions, schemaBundle) {
    return new Promise((function (resolve, reject) {
                  NewBranchModal.createBranch(branchName, "Import", sendActions, schemaBundle, (function (error) {
                          reject(error);
                        }), (function (branchId) {
                          resolve(branchId);
                        }), undefined);
                }));
  };
  $$Promise.$$catch((
              typeof selectedBranch === "object" ? (
                  selectedBranch.NAME === "Create" ? createBranchAsPromise(selectedBranch.VAL, sendActions, schemaBundle).then(function (branchId) {
                          return Promise.resolve({
                                      NAME: "Branch",
                                      VAL: branchId
                                    });
                        }) : Promise.resolve({
                          NAME: "Branch",
                          VAL: selectedBranch.VAL.id
                        })
                ) : Promise.resolve("Master")
            ).then(function (writeToBranch) {
                return new Promise((function (resolve, reject) {
                              Curry.app(sendActions, [
                                    writeToBranch,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    (function (branch) {
                                        resolve(branch);
                                      }),
                                    (function (error) {
                                        reject(error);
                                      }),
                                    [[
                                        "StartedImport",
                                        {}
                                      ]]
                                  ]);
                            }));
              }).then(function (branch) {
              return lastActionOnBranchQuery(workspace.id, NamedBranch.getId(branch)).get().then(function (snapshot) {
                          var lastAction = Belt_Option.mapU(Belt_Array.get(snapshot.docs, 0), (function (doc) {
                                  return FirebaseFetcherHooks.transformAction(doc.data());
                                }));
                          return [
                                  branch,
                                  lastAction
                                ];
                        });
            }).then(function (param) {
            var modelWithImport = Belt_Array.reduceU(importActions, model, Actions.reduce);
            return ImportUtils.snapshotAndWriteImportActions(workspace, param[0], modelWithImport, param[1], parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, importStartedLocation, viewerId, onSuccess, schemaBundle, issues, model, addToast).then(function (param) {
                        return Promise.resolve(undefined);
                      });
          }), (function (error) {
          console.error(error);
          return Promise.resolve(Curry._1(addToast, {
                          message: "Something went wrong when importing the tracking plan.\n" + PromiseExt.getErrorMessage(error) + "",
                          toastType: /* Error */1,
                          persist: true
                        }));
        }));
}

function Import(Props) {
  var currentBranch = Props.currentBranch;
  var openBranches = Props.openBranches;
  var model = Props.model;
  var viewerRole = Props.viewerRole;
  var workspace = WorkspaceContext.use(undefined);
  var mappedGlobalRequirements = Curry._1(ModelStore.Mapped.GlobalRequirements.use, undefined);
  var mappedProperties = ModelStore.Mapped.useProperties(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var schemaRoute = RouterStore.Schema.useSchemaRoute(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var importStartedLocationQueryParam = ImportReview__V2__Utils.useImportStartedLocationQueryParam(undefined);
  var existingDomains = DomainStore.useDomains(undefined);
  var viewerId = ViewerContext.use(undefined).id;
  var match = React.useState(function () {
        
      });
  var parsedTrackingPlan = match[0];
  var match$1 = React.useState(function () {
        return "AddOnly";
      });
  var setImportMethod = match$1[1];
  var importMethod = match$1[0];
  React.useEffect((function () {
          AnalyticsRe.importStarted(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Belt_List.length(model.events), Belt_List.length(model.properties), importStartedLocationQueryParam.value, schemaBundle.branchId, schemaBundle.schemaId);
        }), []);
  var handleSuccess = function (param, param$1, param$2, param$3, param$4, branch) {
    Router.push(undefined, Router.Schema.getImportRoute(branch ? ({
                  NAME: "branch",
                  VAL: branch._0
                }) : "master", importStartedLocationQueryParam.value, {
              NAME: "import",
              VAL: "success"
            }));
  };
  var onReviewAccepted = function (selectedBranch, parsedTrackingPlan) {
    var newAndUpdatedProperties = Belt_Array.concat(parsedTrackingPlan.newProperties, parsedTrackingPlan.updatedProperties);
    var importActions = Belt_List.toArray(ImportAction.buildActions(parsedTrackingPlan, model, importMethod, Curry._1(Domains.toArray, existingDomains)));
    var permissionLevel = GlobalRequirementsUtils.getActionPermissionLevel(mappedGlobalRequirements, mappedProperties, viewerRole, Belt_Array.map(importActions, (function (action) {
                return [
                        action,
                        {}
                      ];
              })));
    if (permissionLevel !== "Allowed") {
      Promise.resolve(Curry._1(globalSend, {
                TAG: /* OpenModal */4,
                _0: GlobalRequirementsUtils.globalRequirementsBlockedModal(viewerRole, permissionLevel, (function (param) {
                        proceedWithImport(selectedBranch, parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, schemaBundle, workspace, model, importStartedLocationQueryParam.value, addToast, Issues.empty, viewerId, handleSuccess);
                      }), (function (param) {
                        
                      }))
              }));
    } else {
      Promise.resolve(proceedWithImport(selectedBranch, parsedTrackingPlan, newAndUpdatedProperties, importActions, sendActions, schemaBundle, workspace, model, importStartedLocationQueryParam.value, addToast, Issues.empty, viewerId, handleSuccess));
    }
  };
  var handleClose = function (param) {
    if (schemaRoute !== ({
          NAME: "import",
          VAL: "review"
        }) || window.confirm("You have an unfinished import draft. Are you sure you want to discard it?")) {
      return Router.Schema.pushSchemaRoute(undefined, undefined, "dashboard");
    }
    
  };
  var hasEventsOnModel = React.useMemo((function () {
          return model.events !== /* [] */0;
        }), []);
  var tmp;
  var exit = 0;
  if (typeof schemaRoute === "object" && schemaRoute.NAME === "import") {
    var importSubRoute = schemaRoute.VAL;
    tmp = importSubRoute === "success" ? React.createElement(Redirect.make, {
            path: Router.Link.getSchemaRouteLink(undefined, "events")
          }) : (
        importSubRoute === "index" ? React.createElement(ImportLanding.make, {
                model: model,
                setParsedTrackingPlan: match[1],
                branchId: Actions.branchToId(currentBranch),
                importMethod: importMethod,
                onImportMethodChange: (function (importMethod) {
                    Curry._1(setImportMethod, (function (param) {
                            return Belt_Option.getWithDefault(importMethod, "AddOnly");
                          }));
                  })
              }) : (
            parsedTrackingPlan !== undefined ? React.createElement(ImportReview.make, {
                    parsedTrackingPlan: parsedTrackingPlan,
                    onContinue: onReviewAccepted,
                    hasEventsOnModel: hasEventsOnModel,
                    currentBranch: currentBranch,
                    openBranches: openBranches,
                    model: model,
                    viewerRole: viewerRole,
                    importMethod: importMethod
                  }) : React.createElement(Redirect.make, {
                    path: Router.Schema.getImportRoute(undefined, importStartedLocationQueryParam.value, {
                          NAME: "import",
                          VAL: "index"
                        })
                  })
          )
      );
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Redirect.make, {
          path: Router.Link.getSchemaRouteLink(undefined, "dashboard")
        });
  }
  return React.createElement("div", undefined, React.createElement("button", {
                  className: logoStyles(workspace.isSandbox),
                  onClick: handleClose
                }, React.createElement(Icons.AvoLogo.make, {
                      width: 60,
                      fill: Styles.Color.light12
                    })), React.createElement("div", {
                  className: closeStyles(workspace.isSandbox)
                }, React.createElement(TextButton.make, {
                      onClick: handleClose,
                      size: "Small",
                      children: "Close"
                    })), React.createElement(FramerMotion.AnimatePresence, {
                  exitBeforeEnter: true,
                  children: React.createElement(FramerMotion.motion.div, {
                        animate: {
                          opacity: 1,
                          x: 0
                        },
                        transition: {
                          duration: 0.3
                        },
                        initial: {
                          opacity: 0,
                          x: 100
                        },
                        exit: {
                          opacity: 0,
                          x: -100
                        },
                        className: contentStyles,
                        key: schemaRoute === ({
                            NAME: "import",
                            VAL: "index"
                          }) ? "index" : "review",
                        children: tmp
                      })
                }));
}

var make = Import;

export {
  logoStyles ,
  closeStyles ,
  contentStyles ,
  lastActionOnBranchQuery ,
  proceedWithImport ,
  make ,
}
/* contentStyles Not a pure module */
