// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MappedModules from "../../../shared/MappedModules.mjs";

function getId(member) {
  return member.domainId + "::" + member.id;
}

function eq(a, b) {
  return a.domainId + "::" + a.id === b.domainId + "::" + b.id;
}

var DomainMembers = MappedModules.MakeOrderedMapModule({
      getId: getId,
      eq: eq
    });

function getUserIds(members) {
  return Curry._2(DomainMembers.mapToArray, members, (function (param) {
                return param.id;
              }));
}

var MapModule = DomainMembers.MapModule;

var has = DomainMembers.has;

var get = DomainMembers.get;

var getExn = DomainMembers.getExn;

var getAtIndex = DomainMembers.getAtIndex;

var getBy = DomainMembers.getBy;

var toArray = DomainMembers.toArray;

var fromArray = DomainMembers.fromArray;

var toList = DomainMembers.toList;

var fromList = DomainMembers.fromList;

var keys = DomainMembers.keys;

var empty = DomainMembers.empty;

var update = DomainMembers.update;

var updateExisting = DomainMembers.updateExisting;

var prepend = DomainMembers.prepend;

var append = DomainMembers.append;

var updateMany = DomainMembers.updateMany;

var updateManyExisting = DomainMembers.updateManyExisting;

var mergeMany = DomainMembers.mergeMany;

var remove = DomainMembers.remove;

var reduce = DomainMembers.reduce;

var mapToArray = DomainMembers.mapToArray;

var mapToMap = DomainMembers.mapToMap;

var mapToT = DomainMembers.mapToT;

var forEach = DomainMembers.forEach;

var keep = DomainMembers.keep;

var keepMap = DomainMembers.keepMap;

var some = DomainMembers.some;

var every = DomainMembers.every;

var size = DomainMembers.size;

var sizeToString = DomainMembers.sizeToString;

var reorderV2 = DomainMembers.reorderV2;

var reorderBuggy = DomainMembers.reorderBuggy;

var eq$1 = DomainMembers.eq;

var toArrayWithKeys = DomainMembers.toArrayWithKeys;

var encodeDict = DomainMembers.encodeDict;

var slice = DomainMembers.slice;

var take = DomainMembers.take;

export {
  DomainMembers ,
  getUserIds ,
  MapModule ,
  has ,
  get ,
  getExn ,
  getAtIndex ,
  getBy ,
  toArray ,
  fromArray ,
  toList ,
  fromList ,
  keys ,
  empty ,
  update ,
  updateExisting ,
  prepend ,
  append ,
  updateMany ,
  updateManyExisting ,
  mergeMany ,
  remove ,
  reduce ,
  mapToArray ,
  mapToMap ,
  mapToT ,
  forEach ,
  keep ,
  keepMap ,
  some ,
  every ,
  size ,
  sizeToString ,
  reorderV2 ,
  reorderBuggy ,
  eq$1 as eq,
  toArrayWithKeys ,
  encodeDict ,
  slice ,
  take ,
}
/* DomainMembers Not a pure module */
