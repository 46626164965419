// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PropertyValidationUtils from "../../../app/src/PropertyValidationUtils.mjs";
import * as GetAllPropertyValuesUseCase from "./GetAllPropertyValuesUseCase.mjs";

function validate(pinnedValue, property, hasEventSpecificAllowedPropertyValuesMigration) {
  var match = property.type_;
  switch (match) {
    case "bool" :
        if (typeof pinnedValue === "object") {
          return pinnedValue.NAME === "BooleanLit";
        } else {
          return false;
        }
    case "float" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "FloatLit") {
          return false;
        }
        var value = pinnedValue.VAL;
        var min = PropertyValidationUtils.getMinFloat(property);
        if (!(
            min !== undefined ? value >= min : true
          )) {
          return false;
        }
        var max = PropertyValidationUtils.getMaxFloat(property);
        if (max !== undefined) {
          return value <= max;
        } else {
          return true;
        }
    case "int" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "IntLit") {
          return false;
        }
        var value$1 = pinnedValue.VAL;
        var min$1 = PropertyValidationUtils.getMinInt(property);
        if (!(
            min$1 !== undefined ? value$1 >= min$1 : true
          )) {
          return false;
        }
        var max$1 = PropertyValidationUtils.getMaxInt(property);
        if (max$1 !== undefined) {
          return value$1 <= max$1;
        } else {
          return true;
        }
    case "string" :
        if (typeof pinnedValue !== "object") {
          return false;
        }
        if (pinnedValue.NAME !== "StringLit") {
          return false;
        }
        var value$2 = pinnedValue.VAL;
        var allowedValues = GetAllPropertyValuesUseCase.getWithLegacyFallback(hasEventSpecificAllowedPropertyValuesMigration, property);
        if (allowedValues.length !== 0) {
          return Belt_Array.some(allowedValues, (function (allowedValue) {
                        return allowedValue === value$2;
                      }));
        } else {
          return true;
        }
    default:
      return false;
  }
}

export {
  validate ,
}
/* PropertyValidationUtils Not a pure module */
