// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as AvoModel from "./avoModel.mjs";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CodegenRules from "../../codegen/src/CodegenRules.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ModelUtils_mapped from "./ModelUtils_mapped.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as TrackingPlanMappedModel from "../../model/src/TrackingPlanMappedModel.mjs";
import * as PropertyAbsenceViewHelpers from "./PropertyAbsenceViewHelpers.mjs";
import * as GetAllPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/GetAllPropertyValuesUseCase.mjs";
import * as ValidatePinnedValueWithPropertyValuesUseCase from "../../model/src/eventSpecificPropertyValues/ValidatePinnedValueWithPropertyValuesUseCase.mjs";

function valueType(item) {
  var match = item.value;
  return match.VAL.type_;
}

function printValueType(item) {
  return TrackingPlanModel.Type.tToJs(valueType(item));
}

function printValue(item) {
  var type_ = TrackingPlanModel.Type.tToJs(valueType(item));
  return "" + (
          item.list ? "list of " : ""
        ) + "" + (
          item.optional ? "optional " : ""
        ) + "" + type_ + "";
}

function printPinnedValue(literal) {
  var variant = literal.NAME;
  if (variant === "BooleanLit") {
    if (literal.VAL) {
      return "TRUE";
    } else {
      return "FALSE";
    }
  } else if (variant === "StringLit") {
    return "\"" + literal.VAL + "\"";
  } else if (variant === "FloatLit") {
    return literal.VAL.toString();
  } else {
    return String(literal.VAL);
  }
}

function getSystemProps(props) {
  return Belt_List.keepMap(props, (function (prop) {
                if (prop.TAG === /* PropertyRef */0) {
                  return ;
                }
                var p = prop._0;
                if (p.sendAs === /* SystemProperty */0) {
                  return p;
                }
                
              }));
}

function collectNestedProperties(model, property) {
  var go = function (acc, property) {
    if (property.type_ !== "object") {
      return {
              hd: property,
              tl: acc
            };
    }
    var nestedProperties = BeltListExtensions.flatMap(property.validations, (function (x) {
            if (typeof x === "object" && x.NAME === "NestedProperty") {
              return Belt_List.keepMap(x.VAL, (function (propertyRef) {
                            return AvoModel.getPropertyById(model, propertyRef.id);
                          }));
            } else {
              return /* [] */0;
            }
          }));
    return Belt_List.reduce(nestedProperties, {
                hd: property,
                tl: acc
              }, (function (acc, property) {
                  if (Belt_List.some(acc, (function (p) {
                            return p.id === property.id;
                          }))) {
                    return acc;
                  } else {
                    return {
                            hd: property,
                            tl: go(acc, property)
                          };
                  }
                }));
  };
  return go(/* [] */0, property);
}

function deduplicateProperties(properties) {
  var cmp = function (a, b) {
    return Caml.string_compare(a.id, b.id);
  };
  var PropertyComparator = Belt_Id.MakeComparable({
        cmp: cmp
      });
  return Belt_Set.toList(Belt_Set.fromArray(Belt_List.toArray(properties), PropertyComparator));
}

function getAllPropertiesFromEvents(eventFilterOpt, model) {
  var eventFilter = eventFilterOpt !== undefined ? eventFilterOpt : (function (param) {
        return true;
      });
  return deduplicateProperties(BeltListExtensions.flatMap(Belt_List.concat(BeltListExtensions.flatMap(Belt_List.keep(model.events, eventFilter), (function ($$event) {
                            var propertyGroups = BeltListExtensions.dedupeOrdered(BeltListExtensions.flatMap(AvoModel.resolvePropertyBundleRefs(model.propertyBundles, $$event.propertyBundles), (function (group) {
                                        return AvoModel.resolvePropertyIds(model.properties, group.properties);
                                      })), (function (property) {
                                    return property.id;
                                  }));
                            var match = AvoModel.getProperties(model, $$event.directPropertyRefs);
                            return Belt_List.concatMany([
                                        match.eventProperties,
                                        match.userProperties,
                                        match.groupProperties,
                                        propertyGroups
                                      ]);
                          })), getSystemProps(model.properties)), (function (property) {
                    return collectNestedProperties(model, property);
                  })));
}

function regexToLabels(model, regexValidation, eventRegexFormatter) {
  return {
          globalRegex: Belt_Option.mapU(regexValidation.propertyRule, (function (propertyRule) {
                  return "default regex: " + propertyRule.regex + "";
                })),
          eventSecificRegex: Belt_MapString.fromArray(Belt_Array.keepMapU(Belt_MapString.toArray(regexValidation.eventOverrides), (function (param) {
                      var eventId = param[0];
                      var maybeEvent = ModelUtils.getEventByIdWithArchive(eventId, model);
                      var label = Curry._2(eventRegexFormatter, Belt_Option.mapWithDefaultU(maybeEvent, "", (function ($$event) {
                                  return $$event.name;
                                })), param[1].regex);
                      return [
                              eventId,
                              label
                            ];
                    })))
        };
}

function mostSpecificPropertyRegex(model, $$event, regexValidation) {
  var regexToLabels$1 = regexToLabels(model, regexValidation, (function (_eventName, regex) {
          return "regex (on this event): " + regex + "";
        }));
  if ($$event === undefined) {
    return regexToLabels$1.globalRegex;
  }
  var eventSpecificRegex = Belt_MapString.get(regexToLabels$1.eventSecificRegex, $$event.id);
  if (eventSpecificRegex !== undefined) {
    return eventSpecificRegex;
  } else {
    return regexToLabels$1.globalRegex;
  }
}

function allPropertyRegex(model, regexValidation) {
  var regexToLabels$1 = regexToLabels(model, regexValidation, (function (eventName, regex) {
          return "" + eventName + " regex: " + regex + "";
        }));
  var regex = regexToLabels$1.globalRegex;
  var globalRegexStringArray = regex !== undefined ? [regex] : [];
  var eventRegexOverridesArray = Belt_MapString.valuesToArray(regexToLabels$1.eventSecificRegex);
  return Belt_Array.concat(globalRegexStringArray, eventRegexOverridesArray);
}

function printPropertyType(property, optionalOverwrite, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, withAllowedValuesOpt, param) {
  var withAllowedValues = withAllowedValuesOpt !== undefined ? withAllowedValuesOpt : true;
  var match = property.type_;
  var tmp;
  var exit = 0;
  if (match === "string" && withAllowedValues) {
    var matches = GetAllPropertyValuesUseCase.get(property);
    tmp = matches.length !== 0 ? ({
          hd: "matches " + matches.join(", ") + "",
          tl: /* [] */0
        }) : /* [] */0;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = Belt_List.map(property.validations, (function (validation) {
            var variant = validation.NAME;
            if (variant === "EndsWith" || variant === "Contains" || variant === "StartsWith") {
              return "";
            }
            if (variant === "Regex") {
              return allPropertyRegex(model, validation.VAL).join(", ");
            }
            if (variant === "Max") {
              var match = validation.VAL;
              if (typeof match !== "object") {
                return "";
              }
              var variant$1 = match.NAME;
              if (variant$1 === "IntLit") {
                return "max " + String(match.VAL) + "";
              } else if (variant$1 === "FloatLit") {
                return "max " + String(match.VAL) + "";
              } else {
                return "";
              }
            }
            if (variant === "Min") {
              var match$1 = validation.VAL;
              if (typeof match$1 !== "object") {
                return "";
              }
              var variant$2 = match$1.NAME;
              if (variant$2 === "IntLit") {
                return "min " + String(match$1.VAL) + "";
              } else if (variant$2 === "FloatLit") {
                return "min " + String(match$1.VAL) + "";
              } else {
                return "";
              }
            }
            if (variant === "NestedProperty") {
              var nestedPropertyRefs = validation.VAL;
              if (nestedPropertyRefs !== /* [] */0) {
                return "{" + Belt_List.toArray(Belt_List.keepMap(nestedPropertyRefs, (function (nestedPropertyRef) {
                                    var nestedProperty = ModelUtils.resolvePropertyById(nestedPropertyRef.id, model);
                                    if (nestedProperty === undefined) {
                                      return ;
                                    }
                                    var maybeValidPinnedValue = Belt_Option.flatMap(nestedPropertyRef.pinnedValue, (function (pinnedValue) {
                                            if (ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, nestedProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
                                              return printPinnedValue(pinnedValue) + " (pinned)";
                                            }
                                            
                                          }));
                                    var value = Belt_Option.getWithDefault(maybeValidPinnedValue, printPropertyType(nestedProperty, nestedProperty.optionalWhenInObject, model, hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, undefined, undefined));
                                    return "" + nestedProperty.name + ": " + value + "";
                                  }))).join(", ") + "}";
              } else {
                return "";
              }
            }
            if (variant !== "Shape") {
              return "";
            }
            var items = validation.VAL;
            if (items === /* [] */0) {
              return "";
            }
            var items$1 = Belt_List.toArray(Belt_List.map(items, (function (item) {
                          return "" + item.key + ": " + printValue(item) + "";
                        }))).join(", ");
            return "{" + items$1 + "}";
          }));
  }
  var validations = Belt_List.toArray(tmp).join(" ");
  var optionality = optionalOverwrite !== undefined ? (
      optionalOverwrite ? "optional " : ""
    ) : Belt_Option.mapWithDefault(PropertyAbsenceViewHelpers.getPropertyAbsenceString(model, undefined, property, undefined), "", (function (string) {
            return "" + string + ", ";
          }));
  return "" + optionality + "" + (
          property.list ? "list of " : ""
        ) + "" + property.type_ + "" + (
          validations === "" ? "" : " (" + validations + ")"
        ) + "";
}

function printPropertyTypeWithoutValidations(property, optional) {
  return "" + (
          optional ? "optional " : ""
        ) + "" + (
          property.list ? "list of " : ""
        ) + "" + property.type_ + "";
}

function getPropertyTypes(hasAnyValuesFeatureFlag, hasLongValuesFeatureFlag, property) {
  return Belt_Array.concatMany([
              hasAnyValuesFeatureFlag || property.type_ === "any" ? ["any"] : [],
              [
                "string",
                "int"
              ],
              hasLongValuesFeatureFlag || property.type_ === "long" ? ["long"] : [],
              [
                "float",
                "bool"
              ],
              ["object"]
            ]);
}

function getPinnedValues(propertyRef, propertyId, eventId, model) {
  var maybeProperty = ModelUtils.resolvePropertyById(propertyId, model);
  var maybeEvent = ModelUtils.getEventById(eventId, model);
  var propertyRef$1 = propertyRef !== undefined ? propertyRef : (
      maybeEvent !== undefined ? Belt_List.head(Belt_List.keepMapU(maybeEvent.directPropertyRefs, (function (property) {
                    if (property.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propRef = property._0;
                    if (propRef.id === propertyId) {
                      return propRef;
                    }
                    
                  }))) : undefined
    );
  if (maybeEvent !== undefined) {
    if (maybeProperty === undefined) {
      return /* [] */0;
    }
    if (maybeProperty.type_ === "object") {
      var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(maybeProperty.validations, (function (validation) {
                      if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                        return validation.VAL;
                      }
                      
                    }))), /* [] */0);
      return Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                    var maybeNestedProperty = ModelUtils.resolvePropertyById(propertyRef.id, model);
                    var maybePinnedValue = CodegenRules.getNestedPropertyPinnedValue(propertyRef, maybeProperty.id, maybeEvent.id, model);
                    if (maybePinnedValue === undefined) {
                      return ;
                    }
                    if (maybeNestedProperty === undefined) {
                      return ;
                    }
                    var pinnedValue = maybePinnedValue[0];
                    if (ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, maybeNestedProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
                      return maybeNestedProperty.name + (": " + (printPinnedValue(pinnedValue) + (
                                  maybePinnedValue[1] === /* EventSpecific */0 ? " (on this event)" : " (on all events)"
                                )));
                    }
                    
                  }));
    }
    
  }
  if (maybeProperty === undefined) {
    return /* [] */0;
  }
  if (propertyRef$1 === undefined) {
    return /* [] */0;
  }
  var pinnedValue = propertyRef$1.pinnedValue;
  if (pinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, maybeProperty, ModelUtils.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return {
            hd: printPinnedValue(pinnedValue) + " (on this event)",
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function getPinnedValues_mapped(propertyRef, propertyId, eventId, withLocationLabelOpt, model, param) {
  var withLocationLabel = withLocationLabelOpt !== undefined ? withLocationLabelOpt : true;
  var maybeProperty = Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, propertyId);
  var maybeEvent = Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
  var propertyRef$1 = propertyRef !== undefined ? propertyRef : (
      maybeEvent !== undefined ? Belt_List.head(Belt_List.keepMapU(maybeEvent.directPropertyRefs, (function (property) {
                    if (property.TAG !== /* PropertyRef */0) {
                      return ;
                    }
                    var propRef = property._0;
                    if (propRef.id === propertyId) {
                      return propRef;
                    }
                    
                  }))) : undefined
    );
  if (maybeEvent !== undefined) {
    if (maybeProperty === undefined) {
      return /* [] */0;
    }
    if (maybeProperty.type_ === "object") {
      var propertyRefs = Belt_Option.getWithDefault(Belt_List.head(Belt_List.keepMapU(maybeProperty.validations, (function (validation) {
                      if (typeof validation === "object" && validation.NAME === "NestedProperty") {
                        return validation.VAL;
                      }
                      
                    }))), /* [] */0);
      return Belt_List.keepMap(propertyRefs, (function (propertyRef) {
                    var maybeNestedProperty = Curry._2(TrackingPlanMappedModel.Properties.get, model.properties, propertyRef.id);
                    var maybePinnedValue = CodegenRules.getNestedPropertyPinnedValue_mapped(propertyRef, maybeProperty.id, maybeEvent.id, model.properties, model.rules, model);
                    if (maybePinnedValue === undefined) {
                      return ;
                    }
                    if (maybeNestedProperty === undefined) {
                      return ;
                    }
                    var pinnedValue = maybePinnedValue[0];
                    if (ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, maybeNestedProperty, ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
                      return maybeNestedProperty.name + (": " + (printPinnedValue(pinnedValue) + (
                                  withLocationLabel ? (
                                      maybePinnedValue[1] === /* EventSpecific */0 ? " (on this event)" : " (on all events)"
                                    ) : ""
                                )));
                    }
                    
                  }));
    }
    
  }
  if (maybeProperty === undefined) {
    return /* [] */0;
  }
  if (propertyRef$1 === undefined) {
    return /* [] */0;
  }
  var pinnedValue = propertyRef$1.pinnedValue;
  if (pinnedValue !== undefined && ValidatePinnedValueWithPropertyValuesUseCase.validate(pinnedValue, maybeProperty, ModelUtils_mapped.hasMigrated(model, "EventSpecificAllowedPropertyValues"))) {
    return {
            hd: printPinnedValue(pinnedValue) + (
              withLocationLabel ? " (on this event)" : ""
            ),
            tl: /* [] */0
          };
  } else {
    return /* [] */0;
  }
}

function sourcesSendingProperty(property, model) {
  var propertyEvents = ModelUtils_mapped.eventsSendingProperty(model.events, model.propertyBundles, undefined, property.id);
  if (property.sendAs === /* SystemProperty */0) {
    return Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Sources.toArray, model.sources), (function (source) {
                  return source.id;
                }));
  } else {
    return Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.mapU(Belt_Array.concatMany(Belt_Array.mapU(Curry._1(TrackingPlanMappedModel.Events.toArray, propertyEvents), (function ($$event) {
                                  return Belt_List.toArray($$event.includeSources);
                                }))), (function (includeSource) {
                          return includeSource.id;
                        }))));
  }
}

function getRegexValidation(property) {
  return Belt_List.head(Belt_List.keepMap(property.validations, (function (validation) {
                    if (typeof validation === "object" && validation.NAME === "Regex") {
                      return validation.VAL;
                    }
                    
                  })));
}

var eventSpecificValuesLimit = 300;

export {
  valueType ,
  printValueType ,
  printValue ,
  printPinnedValue ,
  getSystemProps ,
  collectNestedProperties ,
  deduplicateProperties ,
  getAllPropertiesFromEvents ,
  mostSpecificPropertyRegex ,
  allPropertyRegex ,
  printPropertyType ,
  printPropertyTypeWithoutValidations ,
  getPropertyTypes ,
  getPinnedValues ,
  getPinnedValues_mapped ,
  sourcesSendingProperty ,
  eventSpecificValuesLimit ,
  getRegexValidation ,
}
/* AvoModel Not a pure module */
