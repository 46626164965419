// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as JsDiff from "./externals/JsDiff.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconSendAs from "./IconSendAs.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function DiffEventNameMapping(Props) {
  var actions = Props.actions;
  var branchModel = Props.branchModel;
  var masterModel = Props.masterModel;
  var removedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "RemoveRule") {
                return ;
              }
              var match = action.VAL;
              var match$1 = match[1];
              if (typeof match$1 === "object" && match$1.NAME === "Event") {
                return match[0];
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(ModelUtils.getRuleById(ruleId, masterModel), (function (rule) {
                        return {
                                change: /* Remove */1,
                                before: rule,
                                after: undefined
                              };
                      }));
        }));
  var addedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "AddRule") {
                return ;
              }
              var match = action.VAL;
              var _ruleItem = match[1];
              if (typeof _ruleItem === "object" && _ruleItem.NAME === "Event") {
                var match$1 = match[2];
                if (typeof match$1 === "object") {
                  var variant = match$1.NAME;
                  if (variant === "NameInLocalWorkspace" || variant === "NameMapping") {
                    return match[0];
                  }
                  
                }
                
              }
              var match$2 = match[2];
              if (typeof match$2 !== "object") {
                return ;
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(ModelUtils.getRuleById(ruleId, branchModel), (function (rule) {
                        return {
                                change: /* Add */0,
                                before: undefined,
                                after: rule
                              };
                      }));
        }));
  var updatedNameMappings = Belt_List.keepMap(Belt_List.keepMapU(actions, (function (action) {
              if (typeof action !== "object") {
                return ;
              }
              if (action.NAME !== "UpdateRule") {
                return ;
              }
              var match = action.VAL;
              var match$1 = match[1];
              if (typeof match$1 !== "object") {
                return ;
              }
              if (match$1.NAME !== "Event") {
                return ;
              }
              var match$2 = match[2];
              if (typeof match$2 === "object" && match$2.NAME === "NameMapping") {
                return match[0];
              }
              
            })), (function (ruleId) {
          return Belt_Option.map(ModelUtils.getRuleById(ruleId, branchModel), (function (rule) {
                        return {
                                change: /* Update */2,
                                before: ModelUtils.getRuleById(rule.id, masterModel),
                                after: rule
                              };
                      }));
        }));
  var changes = Belt_List.concatMany([
        removedNameMappings,
        addedNameMappings,
        updatedNameMappings
      ]);
  if (changes === /* [] */0) {
    return null;
  } else {
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexStart"),
                        tl: {
                          hd: Css.fontWeight(Styles.FontWeight.regular),
                          tl: {
                            hd: Css.fontSize(Styles.FontSize.small),
                            tl: {
                              hd: Css.padding2(Css.px(10), Css.px(0)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    })
              }, React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.margin4(Css.px(2), Css.px(10), Css.px(0), Css.px(0)),
                          tl: /* [] */0
                        })
                  }, React.createElement(IconSendAs.make, {
                        size: 14
                      })), React.createElement("div", {
                    className: Curry._1(Css.style, {
                          hd: Css.paddingTop(Css.px(2)),
                          tl: /* [] */0
                        })
                  }, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.light11,
                        children: "Event name mapping"
                      }), React.createElement(Spacer.make, {
                        height: 4
                      }), Belt_List.toArray(Belt_List.map(changes, (function (param) {
                              var after = param.after;
                              var before = param.before;
                              var change = param.change;
                              var match = ModelUtils.getNameMappingNameAndDestination(before, masterModel);
                              var destinationBefore = match[1];
                              var nameBefore = match[0];
                              var match$1 = ModelUtils.getNameMappingNameAndDestination(after, branchModel);
                              var destinationAfter = match$1[1];
                              var nameAfter = match$1[0];
                              var tmp;
                              switch (change) {
                                case /* Add */0 :
                                    tmp = Styles.Color.darkGreen;
                                    break;
                                case /* Remove */1 :
                                    tmp = Styles.Color.darkRed;
                                    break;
                                case /* Update */2 :
                                    tmp = Styles.Color.light12;
                                    break;
                                
                              }
                              var tmp$1;
                              switch (change) {
                                case /* Add */0 :
                                    tmp$1 = "Send as \"" + nameAfter + "\" to " + destinationAfter + "";
                                    break;
                                case /* Remove */1 :
                                    tmp$1 = "Send as \"" + nameBefore + "\" to " + destinationBefore + "";
                                    break;
                                case /* Update */2 :
                                    tmp$1 = JsDiff.renderStringDiff(undefined, true, true, "Send as \"" + nameBefore + "\" to " + destinationBefore + "", "Send as \"" + nameAfter + "\" to " + destinationAfter + "");
                                    break;
                                
                              }
                              return React.createElement("div", {
                                          key: before !== undefined ? before.id : (
                                              after !== undefined ? after.id : "none-none"
                                            ),
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding2(Css.px(2), Css.px(0)),
                                                tl: /* [] */0
                                              })
                                        }, React.createElement($$Text.make, {
                                              size: "Small",
                                              weight: "Semi",
                                              color: tmp,
                                              children: tmp$1
                                            }));
                            })))));
  }
}

var make = DiffEventNameMapping;

export {
  make ,
}
/* Css Not a pure module */
