// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as DiffGoal from "./DiffGoal.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as DiffEvent from "./DiffEvent.mjs";
import * as StringExt from "./StringExt.mjs";
import * as DiffMetric from "./DiffMetric.mjs";
import * as DiffSource from "./DiffSource.mjs";
import * as ModelUtils from "./ModelUtils.mjs";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DiffComments from "./DiffComments.mjs";
import * as DiffProperty from "./DiffProperty.mjs";
import * as DiffGroupType from "./DiffGroupType.mjs";
import * as DiffComponents from "./DiffComponents.mjs";
import * as DiffDestination from "./DiffDestination.mjs";
import * as DiffIntegration from "./DiffIntegration.mjs";
import * as DiffPropertyGroup from "./DiffPropertyGroup.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as DiffGlobalRequirements from "./DiffGlobalRequirements.mjs";
import * as GlobalRequirementsUtils from "../../model/src/GlobalRequirementsUtils.mjs";

var container = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: /* [] */0
    });

function innerContainer(withComments) {
  return Curry._1(Css.style, {
              hd: Css.display("flex"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("sticky"),
                  tl: {
                    hd: Css.top(Css.px(0)),
                    tl: {
                      hd: Css.marginTop(Css.px(20)),
                      tl: {
                        hd: Css.marginRight(Css.px(withComments ? (DiffComments.commentsWidth + 20 | 0) - 1 | 0 : 0)),
                        tl: {
                          hd: Css.marginLeft(Css.px(-1)),
                          tl: {
                            hd: Css.marginBottom(Css.px(2)),
                            tl: {
                              hd: Css.fontSize(Styles.FontSize.large),
                              tl: {
                                hd: Css.color(Styles.Color.light11),
                                tl: {
                                  hd: Css.fontWeight(Styles.FontWeight.bold),
                                  tl: {
                                    hd: Css.padding2(Css.px(13), Css.px(20)),
                                    tl: {
                                      hd: Css.maxWidth(DiffComponents.maxWidthPx),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.light02, 0.5)),
                                        tl: {
                                          hd: Css.zIndex(1),
                                          tl: {
                                            hd: Css.backdropFilter({
                                                  hd: {
                                                    NAME: "blur",
                                                    VAL: Css.px(10)
                                                  },
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function DiffViewUtils$GlobalRequirementsHeader(Props) {
  var withComments = Props.withComments;
  var globalRequirements = Props.globalRequirements;
  var tmp;
  if (globalRequirements !== undefined) {
    var match = globalRequirements.metadata;
    tmp = React.createElement("a", {
          className: Curry._1(Css.style, {
                hd: Css.transition({
                      NAME: "ms",
                      VAL: Styles.Duration.$$short
                    }, undefined, undefined, "color"),
                tl: {
                  hd: Css.color(Styles.Color.darkBlue),
                  tl: {
                    hd: Css.hover({
                          hd: Css.color(Styles.Color.deepBlue),
                          tl: /* [] */0
                        }),
                    tl: /* [] */0
                  }
                }
              }),
          href: Curry._4(Router.Link.getFullPath, {
                NAME: "schema",
                VAL: match.schemaId
              }, {
                NAME: "branch",
                VAL: match.branchId
              }, {
                NAME: "diff",
                VAL: "index"
              }, /* [] */0),
          target: "_blank"
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Regular",
              children: "View global branch"
            }));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: container
            }, React.createElement("div", {
                  className: Curry._1(Css.merge, {
                        hd: innerContainer(withComments),
                        tl: {
                          hd: Curry._1(Css.style, {
                                hd: Css.backgroundColor(Styles.Color.lightBlue),
                                tl: {
                                  hd: Css.borderRadius(Css.px(8)),
                                  tl: {
                                    hd: Css.color(Styles.Color.deepBlue),
                                    tl: {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.alignItems("flexStart"),
                                        tl: {
                                          hd: Css.flexDirection("column"),
                                          tl: {
                                            hd: Css.gap(Css.px(4)),
                                            tl: {
                                              hd: Css.padding(Css.px(32)),
                                              tl: {
                                                hd: Css.paddingRight(Css.px(16)),
                                                tl: {
                                                  hd: Css.boxShadow(Styles.Shadow.subtle),
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement($$Text.make, {
                      size: "Large",
                      weight: "Semi",
                      children: "Global requirements branch"
                    }), React.createElement(Spacer.make, {
                      height: 4
                    }), React.createElement($$Text.make, {
                      size: "Medium",
                      weight: "Regular",
                      children: StringExt.widowless("This branch was pushed from your organisation’s Global Workspace.")
                    }), tmp));
}

var GlobalRequirementsHeader = {
  make: DiffViewUtils$GlobalRequirementsHeader
};

function DiffViewUtils$RenderObjectsDiff(Props) {
  var branchStatus = Props.branchStatus;
  var fromModel = Props.fromModel;
  var toModel = Props.toModel;
  var actions = Props.actions;
  var branchId = Props.branchId;
  var goToCommentId = Props.goToCommentId;
  var withCommentsOpt = Props.withComments;
  var events = Props.events;
  var withComments = withCommentsOpt !== undefined ? withCommentsOpt : true;
  var isGlobalRequirementsBranch = GlobalRequirementsUtils.isGlobalBranch(branchId);
  var globalConversionsActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[1];
          if (typeof match !== "object") {
            return false;
          }
          var variant = match.NAME;
          if (variant === "ConvertPropertyBundleToGlobalPropertyBundle" || variant === "ConvertEventToGlobalEventV2" || variant === "ConvertEventToGlobalEvent" || variant === "ConvertPropertyToGlobalPropertyV2") {
            return true;
          } else {
            return variant === "ConvertPropertyToGlobalProperty";
          }
        }));
  var eventActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Event";
          } else {
            return false;
          }
        }));
  var eventPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(ModelUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          } else {
                            return property._0.sendAs === 1;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var userPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(ModelUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          }
                          var match = property._0.sendAs;
                          if (typeof match === "number") {
                            return match >= 2;
                          } else {
                            return false;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var systemPropertyActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(ModelUtils.getPropertyByIdWithArchive(match.VAL, toModel), false, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return false;
                          } else {
                            return property._0.sendAs === 0;
                          }
                        }));
          } else {
            return false;
          }
        }));
  var __x = Belt_List.keepMap(actions, (function (action) {
          if (!action) {
            return ;
          }
          var match = action.hd[0];
          if (typeof match === "object" && match.NAME === "Property") {
            return Belt_Option.mapWithDefault(ModelUtils.getPropertyByIdWithArchive(match.VAL, toModel), undefined, (function (property) {
                          if (property.TAG === /* PropertyRef */0) {
                            return ;
                          }
                          var groupTypeId = property._0.sendAs;
                          if (typeof groupTypeId === "number") {
                            return ;
                          } else {
                            return [
                                    groupTypeId._0,
                                    action
                                  ];
                          }
                        }));
          }
          
        }));
  var groupedGroupTypePropertyActions = Belt_List.map(BeltListExtensions.group((function (a, b) {
              return Caml.string_compare(a[0], b[0]);
            }), __x), (function (actions) {
          return Belt_List.map(actions, (function (param) {
                        return param[1];
                      }));
        }));
  var propertyGroupActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "PropertyGroup";
          } else {
            return false;
          }
        }));
  var sourceActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Source";
          } else {
            return false;
          }
        }));
  var destinationActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Destination";
          } else {
            return false;
          }
        }));
  var goalActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Goal";
          } else {
            return false;
          }
        }));
  var metricActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Metric";
          } else {
            return false;
          }
        }));
  var integrationActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "Integration";
          } else {
            return false;
          }
        }));
  var groupTypeActions = Belt_List.keep(actions, (function (action) {
          if (!action) {
            return false;
          }
          var match = action.hd[0];
          if (typeof match === "object") {
            return match.NAME === "GroupType";
          } else {
            return false;
          }
        }));
  var partial_arg = withComments;
  var renderObjectDiff = function (param) {
    var withComments = partial_arg !== undefined ? partial_arg : true;
    if (param === /* [] */0) {
      return null;
    }
    var tmp;
    if (param) {
      var match = param.hd;
      if (match) {
        var match$1 = match.hd[0];
        if (typeof match$1 === "object") {
          var variant = match$1.NAME;
          tmp = variant === "Source" ? "Source changes" : (
              variant === "Destination" ? "Destination changes" : (
                  variant === "Integration" ? "Integration changes" : (
                      variant === "GroupType" ? "Group Type changes" : (
                          variant === "Property" ? Belt_Option.mapWithDefault(ModelUtils.getPropertyByIdWithArchive(match$1.VAL, toModel), "Property changes", (function (property) {
                                    if (property.TAG === /* PropertyRef */0) {
                                      return "Property changes";
                                    }
                                    var groupTypeId = property._0.sendAs;
                                    if (typeof groupTypeId !== "number") {
                                      return Belt_Option.getWithDefault(Belt_Option.map(ModelUtils.getGroupTypeNameWithArchive(groupTypeId._0, toModel), (function (groupTypeName) {
                                                        return groupTypeName + " Group Type Property changes";
                                                      })), "Group Type Property changes");
                                    }
                                    switch (groupTypeId) {
                                      case /* SystemProperty */0 :
                                          return "System Property changes";
                                      case /* EventProperty */1 :
                                          return "Event Property changes";
                                      case /* UserProperty */2 :
                                          return "User Property changes";
                                      
                                    }
                                  })) : (
                              variant === "Event" ? "Event changes" : (
                                  variant === "PropertyGroup" ? "Property Bundle changes" : (
                                      variant === "Goal" ? "Category changes" : (
                                          variant === "Metric" ? "Metric changes" : Pervasives.failwith("Unknown diff type")
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            );
        } else {
          tmp = Pervasives.failwith("Unknown diff type");
        }
      } else {
        tmp = Pervasives.failwith("Unknown diff type");
      }
    } else {
      tmp = Pervasives.failwith("Unknown diff type");
    }
    var match$2 = Belt_List.length(param);
    var tmp$1;
    var exit = 0;
    if (match$2 !== 1) {
      exit = 1;
    } else if (param) {
      var match$3 = param.hd;
      if (match$3) {
        var match$4 = match$3.hd[0];
        if (typeof match$4 === "object") {
          var variant$1 = match$4.NAME;
          if (variant$1 === "Source") {
            tmp$1 = "source";
          } else if (variant$1 === "Destination") {
            tmp$1 = "destination";
          } else if (variant$1 === "Integration") {
            tmp$1 = "integration";
          } else if (variant$1 === "GroupType") {
            tmp$1 = "group type";
          } else if (variant$1 === "Property") {
            tmp$1 = "property";
          } else if (variant$1 === "Event") {
            tmp$1 = "event";
          } else if (variant$1 === "PropertyGroup") {
            tmp$1 = "property bundle";
          } else if (variant$1 === "Goal") {
            tmp$1 = "category";
          } else if (variant$1 === "Metric") {
            tmp$1 = "metric";
          } else {
            exit = 1;
          }
        } else {
          exit = 1;
        }
      } else {
        tmp$1 = Pervasives.failwith("Unknown diff type");
      }
    } else {
      tmp$1 = Pervasives.failwith("Unknown diff type");
    }
    if (exit === 1) {
      if (param) {
        var match$5 = param.hd;
        if (match$5) {
          var match$6 = match$5.hd[0];
          if (typeof match$6 === "object") {
            var variant$2 = match$6.NAME;
            tmp$1 = variant$2 === "Source" ? "sources" : (
                variant$2 === "Destination" ? "destinations" : (
                    variant$2 === "Integration" ? "integrations" : (
                        variant$2 === "GroupType" ? "group types" : (
                            variant$2 === "Property" ? "properties" : (
                                variant$2 === "Event" ? "events" : (
                                    variant$2 === "PropertyGroup" ? "property bundles" : (
                                        variant$2 === "Goal" ? "categories" : (
                                            variant$2 === "Metric" ? "metrics" : Pervasives.failwith("Unknown diff type")
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              );
          } else {
            tmp$1 = Pervasives.failwith("Unknown diff type");
          }
        } else {
          tmp$1 = Pervasives.failwith("Unknown diff type");
        }
      } else {
        tmp$1 = Pervasives.failwith("Unknown diff type");
      }
    }
    return React.createElement("div", {
                className: container
              }, React.createElement("div", {
                    className: innerContainer(withComments)
                  }, React.createElement($$Text.make, {
                        size: "Large",
                        weight: "Semi",
                        children: tmp
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.fontSize(Styles.FontSize.small),
                                tl: {
                                  hd: Css.margin2(Css.px(2), Css.px(2)),
                                  tl: {
                                    hd: Css.borderRadius(Css.px(100)),
                                    tl: {
                                      hd: Css.color(Styles.Color.light01),
                                      tl: {
                                        hd: Css.backgroundColor(Styles.Color.light10),
                                        tl: {
                                          hd: Css.marginLeft(Css.px(8)),
                                          tl: {
                                            hd: Css.marginTop(Css.px(4)),
                                            tl: {
                                              hd: Css.padding2(Css.px(2), Css.px(8)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            })
                      }, String(Belt_List.length(param)) + " " + tmp$1 + " changed")), Belt_List.toArray(Belt_List.map(param, (function (objectActions) {
                          var tmp;
                          if (objectActions) {
                            var match = objectActions.hd[0];
                            if (typeof match === "object") {
                              var variant = match.NAME;
                              tmp = variant === "Metric" || variant === "Goal" || variant === "PropertyGroup" || variant === "Event" || variant === "Property" || variant === "GroupType" || variant === "Integration" || variant === "Destination" || variant === "Source" ? match.VAL : Pervasives.failwith("Unknown diff type");
                            } else {
                              tmp = Pervasives.failwith("Unknown diff type");
                            }
                          } else {
                            tmp = Pervasives.failwith("Unknown diff type");
                          }
                          var tmp$1;
                          if (objectActions) {
                            var match$1 = objectActions.hd[0];
                            if (typeof match$1 === "object") {
                              var variant$1 = match$1.NAME;
                              if (variant$1 === "Source") {
                                tmp$1 = React.createElement(DiffSource.make, {
                                      masterModel: fromModel,
                                      branchModel: toModel,
                                      actions: objectActions
                                    });
                              } else if (variant$1 === "Destination") {
                                tmp$1 = React.createElement(DiffDestination.make, {
                                      masterModel: fromModel,
                                      branchModel: toModel,
                                      actions: objectActions
                                    });
                              } else if (variant$1 === "Integration") {
                                var tmp$2 = {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  withComments: withComments,
                                  events: events
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$2.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffIntegration.make, tmp$2);
                              } else if (variant$1 === "GroupType") {
                                tmp$1 = React.createElement(DiffGroupType.make, {
                                      masterModel: fromModel,
                                      branchModel: toModel,
                                      actions: objectActions
                                    });
                              } else if (variant$1 === "Property") {
                                var tmp$3 = {
                                  branchStatus: branchStatus,
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  withComments: withComments,
                                  events: events
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$3.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffProperty.make, tmp$3);
                              } else if (variant$1 === "Event") {
                                var tmp$4 = {
                                  branchStatus: branchStatus,
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  events: events,
                                  withComments: withComments
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$4.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffEvent.make, tmp$4);
                              } else if (variant$1 === "PropertyGroup") {
                                var tmp$5 = {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  withComments: withComments,
                                  events: events
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$5.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffPropertyGroup.make, tmp$5);
                              } else if (variant$1 === "Goal") {
                                var tmp$6 = {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  withComments: withComments,
                                  events: events
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$6.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffGoal.make, tmp$6);
                              } else if (variant$1 === "Metric") {
                                var tmp$7 = {
                                  masterModel: fromModel,
                                  branchModel: toModel,
                                  actions: objectActions,
                                  branchId: branchId,
                                  withComments: withComments,
                                  events: events
                                };
                                if (goToCommentId !== undefined) {
                                  tmp$7.goToCommentId = Caml_option.valFromOption(goToCommentId);
                                }
                                tmp$1 = React.createElement(DiffMetric.make, tmp$7);
                              } else {
                                tmp$1 = Pervasives.failwith("Unknown diff type");
                              }
                            } else {
                              tmp$1 = Pervasives.failwith("Unknown diff type");
                            }
                          } else {
                            tmp$1 = Pervasives.failwith("Unknown diff type");
                          }
                          return React.createElement("div", {
                                      key: tmp,
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginBottom(Css.px(16)),
                                            tl: {
                                              hd: Css.width(Css.pct(100)),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, tmp$1);
                        }))));
  };
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.width(Css.pct(100)),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  })
            }, isGlobalRequirementsBranch ? React.createElement(DiffViewUtils$GlobalRequirementsHeader, {
                    withComments: false,
                    globalRequirements: toModel.globalRequirements
                  }) : null, Belt_List.size(globalConversionsActions) > 0 && isGlobalRequirementsBranch ? React.createElement(DiffGlobalRequirements.make, {
                    actions: actions,
                    toModel: toModel,
                    withComments: withComments
                  }) : null, Curry._1(renderObjectDiff, goalActions), Curry._1(renderObjectDiff, metricActions), Curry._1(renderObjectDiff, groupTypeActions), Curry._1(renderObjectDiff, eventActions), Curry._1(renderObjectDiff, eventPropertyActions), Curry._1(renderObjectDiff, userPropertyActions), Curry._1(renderObjectDiff, systemPropertyActions), Belt_List.toArray(Belt_List.map(groupedGroupTypePropertyActions, Curry.__1(renderObjectDiff))), Curry._1(renderObjectDiff, propertyGroupActions), Curry._1(renderObjectDiff, sourceActions), Curry._1(renderObjectDiff, destinationActions), Curry._1(renderObjectDiff, integrationActions));
}

var RenderObjectsDiff = {
  make: DiffViewUtils$RenderObjectsDiff
};

var Styles$1 = {
  container: container,
  innerContainer: innerContainer
};

export {
  Styles$1 as Styles,
  GlobalRequirementsHeader ,
  RenderObjectsDiff ,
}
/* container Not a pure module */
