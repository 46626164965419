// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function IconNavigate(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : 16;
  var color = colorOpt !== undefined ? colorOpt : Styles.Color.light10;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css_Legacy_Core.SVG.fill(color),
                    tl: {
                      hd: Css_Legacy_Core.SVG.stroke(color),
                      tl: /* [] */0
                    }
                  })
            }, React.createElement("svg", {
                  width: String(size),
                  viewBox: "0 0 16 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("g", {
                      className: "nc-icon-wrapper",
                      strokeWidth: "1"
                    }, React.createElement("polyline", {
                          fill: "none",
                          points: "8.5 3.5 8.5 0.5 5.5 0.5 5.5 3.5",
                          strokeLinecap: "round"
                        }), React.createElement("polygon", {
                          fill: "none",
                          points: "0.5 8.5 12.5 8.5 15.5 6 12.5 3.5 0.5 3.5 0.5 8.5",
                          strokeLinecap: "round"
                        }), React.createElement("polyline", {
                          fill: "none",
                          points: "5.5 10.5 5.5 15.5 8.5 15.5 8.5 10.5",
                          strokeLinecap: "round"
                        }))));
}

var make = IconNavigate;

export {
  make ,
}
/* Css Not a pure module */
