// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as TrackingPlanMappedModel from "../TrackingPlanMappedModel.mjs";
import * as GetEventIdsSendingPropertyForEventSpecificValuesUseCase from "./GetEventIdsSendingPropertyForEventSpecificValuesUseCase.mjs";

function convert(model, property, sourceIdsToAllowedEventIds) {
  var eventIdsSendingProperty = GetEventIdsSendingPropertyForEventSpecificValuesUseCase.getFromMappedModel(model, property);
  var sourceIdsToAllowedEventIds$1 = property.sendAs === /* SystemProperty */0 ? Belt_MapString.mapU(sourceIdsToAllowedEventIds, (function (allowedEvents) {
            if (Belt_SetString.isEmpty(allowedEvents)) {
              return allowedEvents;
            } else {
              return Belt_SetString.add(allowedEvents, GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey);
            }
          })) : sourceIdsToAllowedEventIds;
  var processEventId = function (result, eventId) {
    var eventIncludedSourceIds;
    if (eventId === GetEventIdsSendingPropertyForEventSpecificValuesUseCase.propertyValuesAllEventsKey) {
      eventIncludedSourceIds = Belt_SetString.fromArray(Curry._1(TrackingPlanMappedModel.Sources.keys, model.sources));
    } else {
      var $$event = Curry._2(TrackingPlanMappedModel.Events.get, model.events, eventId);
      eventIncludedSourceIds = $$event !== undefined ? Belt_SetString.fromArray(Belt_List.toArray(Belt_List.mapU($$event.includeSources, (function (source) {
                        return source.id;
                      })))) : undefined;
    }
    var allowedSourceIds = Belt_SetString.keepU(eventIncludedSourceIds, (function (sourceId) {
            var allowedEvents = Belt_MapString.get(sourceIdsToAllowedEventIds$1, sourceId);
            if (allowedEvents !== undefined) {
              return Belt_SetString.has(Caml_option.valFromOption(allowedEvents), eventId);
            } else {
              return false;
            }
          }));
    var disallowedSourceIds = Belt_SetString.diff(eventIncludedSourceIds, allowedSourceIds);
    if (Belt_SetString.isEmpty(disallowedSourceIds)) {
      return result;
    } else if (Belt_SetString.eq(disallowedSourceIds, eventIncludedSourceIds)) {
      return Belt_MapString.set(result, eventId, /* Disallowed */0);
    } else {
      return Belt_MapString.set(result, eventId, /* DisallowedSources */{
                  _0: disallowedSourceIds
                });
    }
  };
  switch (eventIdsSendingProperty.TAG | 0) {
    case /* SystemProperty */0 :
        return processEventId(undefined, eventIdsSendingProperty._0);
    case /* SystemAndNotSystemProperty */1 :
    case /* NotSystemProperty */2 :
        break;
    
  }
  return Belt_Array.reduceU(eventIdsSendingProperty._0, undefined, processEventId);
}

export {
  convert ,
}
/* TrackingPlanMappedModel Not a pure module */
