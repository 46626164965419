// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function newTextBuilder(param) {
  return [];
}

function addFormatAction(textBuilder, action) {
  textBuilder.push(action);
}

function addOffsetC(textBuilder, offset) {
  textBuilder.push({
        TAG: /* AddOffset */0,
        _0: offset
      });
  return textBuilder;
}

function addOffset(textBuilder, offset) {
  textBuilder.push({
        TAG: /* AddOffset */0,
        _0: offset
      });
}

function removeOffsetC(textBuilder, offset) {
  textBuilder.push({
        TAG: /* RemoveOffset */1,
        _0: offset
      });
  return textBuilder;
}

function removeOffset(textBuilder, offset) {
  textBuilder.push({
        TAG: /* RemoveOffset */1,
        _0: offset
      });
}

function resetOffsetC(textBuilder) {
  textBuilder.push(/* ResetOffset */0);
  return textBuilder;
}

function resetOffset(textBuilder) {
  textBuilder.push(/* ResetOffset */0);
}

function addTextC(textBuilder, line) {
  textBuilder.push({
        TAG: /* AddText */2,
        _0: line
      });
  return textBuilder;
}

function addText(textBuilder, line) {
  textBuilder.push({
        TAG: /* AddText */2,
        _0: line
      });
}

function newLineC(textBuilder) {
  textBuilder.push(/* NewLine */1);
  return textBuilder;
}

function newLine(textBuilder) {
  textBuilder.push(/* NewLine */1);
}

function buildText(textBuilder) {
  return Belt_Array.reduceU(textBuilder, [
                "",
                0
              ], (function (param, action) {
                  var offset = param[1];
                  var resultString = param[0];
                  if (typeof action === "number") {
                    if (action === /* ResetOffset */0) {
                      return [
                              resultString,
                              0
                            ];
                    }
                    var offsetString = "";
                    for(var _for = 1; _for <= offset; ++_for){
                      offsetString = offsetString + " ";
                    }
                    if (resultString === "") {
                      return [
                              "\n" + offsetString,
                              offset
                            ];
                    } else {
                      return [
                              "" + resultString + "\n" + offsetString + "",
                              offset
                            ];
                    }
                  } else {
                    switch (action.TAG | 0) {
                      case /* AddOffset */0 :
                          return [
                                  resultString,
                                  offset + action._0 | 0
                                ];
                      case /* RemoveOffset */1 :
                          return [
                                  resultString,
                                  Caml.int_max(0, offset - action._0 | 0)
                                ];
                      case /* AddText */2 :
                          return [
                                  resultString + action._0,
                                  offset
                                ];
                      
                    }
                  }
                }))[0];
}

var defaultFprintfOffset = {
  contents: 2
};

function fprintF(f, fmt, recursiveFunctions, args) {
  var currentFprintFCode = {
    contents: ""
  };
  var currentArgStringIndex = {
    contents: 0
  };
  var currentRecursiveFunctionsIndex = {
    contents: 0
  };
  Belt_Array.forEachU(fmt.split(""), (function ($$char) {
          currentFprintFCode.contents = currentFprintFCode.contents + $$char;
          var string = currentFprintFCode.contents;
          var exit = 0;
          switch (string) {
            case "%%" :
                f.push({
                      TAG: /* AddText */2,
                      _0: "%"
                    });
                currentFprintFCode.contents = "";
                return ;
            case "%a" :
                if (recursiveFunctions === undefined) {
                  Js_exn.raiseError("Missing recursive functions");
                }
                var recursiveFunctions$1 = Belt_Option.getExn(recursiveFunctions);
                if (recursiveFunctions$1.length <= currentRecursiveFunctionsIndex.contents) {
                  Js_exn.raiseError("Not enough recursive functions provided");
                }
                var recursiveFunction = recursiveFunctions$1[currentRecursiveFunctionsIndex.contents];
                Curry._1(recursiveFunction, f);
                currentRecursiveFunctionsIndex.contents = currentRecursiveFunctionsIndex.contents + 1 | 0;
                currentFprintFCode.contents = "";
                return ;
            case "%f" :
                if (args.length < currentArgStringIndex.contents) {
                  Js_exn.raiseError("Not enough arguments provided");
                }
                var arg = args[currentArgStringIndex.contents];
                if (arg.includes(".")) {
                  f.push({
                        TAG: /* AddText */2,
                        _0: arg
                      });
                } else {
                  f.push({
                        TAG: /* AddText */2,
                        _0: arg + ".000000"
                      });
                }
                currentArgStringIndex.contents = currentArgStringIndex.contents + 1 | 0;
                currentFprintFCode.contents = "";
                return ;
            case "%d" :
            case "%s" :
                exit = 1;
                break;
            case "%" :
            case "@" :
                return ;
            case "@ " :
            case "@," :
                f.push(/* NewLine */1);
                currentFprintFCode.contents = "";
                return ;
            case "@;<1 -2>@]" :
                var textBuilder = removeOffsetC(f, 2);
                textBuilder.push(/* NewLine */1);
                currentFprintFCode.contents = "";
                return ;
            case "@;<1 -4>@]" :
                var textBuilder$1 = removeOffsetC(f, 4);
                textBuilder$1.push(/* NewLine */1);
                currentFprintFCode.contents = "";
                return ;
            case "@;<v -2>@]" :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: -2
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@;<v -4>@]" :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: -4
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@;<v -8>@]" :
                f.push({
                      TAG: /* RemoveOffset */1,
                      _0: 8
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@@" :
                f.push({
                      TAG: /* AddText */2,
                      _0: "@"
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@[<v 0>" :
                currentFprintFCode.contents = "";
                return ;
            case "@[<v 2>" :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: 2
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@[<hov 4>" :
            case "@[<v 4>" :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: 4
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@[<v 8>" :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: 8
                    });
                currentFprintFCode.contents = "";
                return ;
            case "@[<hov 0>" :
            case "@[<v>" :
                exit = 2;
                break;
            case "@]" :
                f.push({
                      TAG: /* RemoveOffset */1,
                      _0: defaultFprintfOffset.contents
                    });
                currentFprintFCode.contents = "";
                return ;
            default:
              if (string.startsWith("@;") || string.startsWith("@[")) {
                currentFprintFCode.contents = currentFprintFCode.contents;
              } else {
                f.push({
                      TAG: /* AddText */2,
                      _0: string
                    });
                currentFprintFCode.contents = "";
              }
              return ;
          }
          switch (exit) {
            case 1 :
                if (args.length <= currentArgStringIndex.contents) {
                  Js_exn.raiseError("Not enough arguments provided");
                }
                var arg$1 = args[currentArgStringIndex.contents];
                f.push({
                      TAG: /* AddText */2,
                      _0: arg$1
                    });
                currentArgStringIndex.contents = currentArgStringIndex.contents + 1 | 0;
                currentFprintFCode.contents = "";
                return ;
            case 2 :
                f.push({
                      TAG: /* AddOffset */0,
                      _0: defaultFprintfOffset.contents
                    });
                currentFprintFCode.contents = "";
                return ;
            
          }
        }));
  if (currentFprintFCode.contents.length > 0) {
    Js_exn.raiseError("Unknown token: " + currentFprintFCode.contents);
  }
  if (currentArgStringIndex.contents < args.length) {
    Js_exn.raiseError("Too many arguments provided");
  }
  if (currentRecursiveFunctionsIndex.contents < Belt_Option.getWithDefault(recursiveFunctions, []).length) {
    return Js_exn.raiseError("Too many recursive functions provided");
  }
  
}

export {
  newTextBuilder ,
  addFormatAction ,
  addOffsetC ,
  addOffset ,
  removeOffsetC ,
  removeOffset ,
  resetOffsetC ,
  resetOffset ,
  addTextC ,
  addText ,
  newLineC ,
  newLine ,
  buildText ,
  defaultFprintfOffset ,
  fprintF ,
}
/* No side effect */
