// Generated by ReScript, PLEASE EDIT WITH CARE


var User = {};

var IdentifyOptions = {
  User: User
};

var ChangelogOptions = {};

function identify(options) {
  Canny("identify", options);
}

function initChangelog(options) {
  Canny("initChangelog", options);
}

var appID = "5d19e8b067da142623784aa2";

export {
  appID ,
  IdentifyOptions ,
  ChangelogOptions ,
  identify ,
  initChangelog ,
}
/* No side effect */
