// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Models from "./Models.mjs";
import * as Parser from "../../model/src/Parser.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Actions from "./actions.mjs";
import * as DateFns from "./DateFns.mjs";
import * as Printer from "../../model/src/Printer.mjs";
import * as AvoState from "./AvoState.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FetchModel from "./FetchModel.mjs";
import * as TextButton from "./TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ActivityItem from "./ActivityItem.mjs";
import * as NewEventModal from "./NewEventModal.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as LoadingFullscreen from "./LoadingFullscreen.mjs";
import * as TrackingPlanModel from "../../model/src/TrackingPlanModel.mjs";
import * as BeltListExtensions from "./BeltListExtensions.mjs";
import * as ActivityItemLoading from "./ActivityItemLoading.mjs";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";

var rootStyles = Curry._1(Css.style, {
      hd: Css.padding(Css.px(16)),
      tl: {
        hd: Css.paddingTop(Css.px(0)),
        tl: {
          hd: Css.position("relative"),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var fadeStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.bottom(Css.pct(100.0)),
        tl: {
          hd: Css.left(Css.px(0)),
          tl: {
            hd: Css.right(Css.px(0)),
            tl: {
              hd: Css.height(Css.px(150)),
              tl: {
                hd: Css.background({
                      NAME: "linearGradient",
                      VAL: [
                        {
                          NAME: "deg",
                          VAL: 180.0
                        },
                        [
                          [
                            Css.pct(0.0),
                            Styles.Color.setAlpha(Styles.Color.white, 0.0)
                          ],
                          [
                            Css.pct(100.0),
                            Styles.Color.setAlpha(Styles.Color.white, 0.9)
                          ]
                        ]
                      ]
                    }),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

function ActivityLog$Upgrade(Props) {
  var globalSend = Props.globalSend;
  return React.createElement("div", {
              className: rootStyles
            }, React.createElement(Button.make, {
                  icon: "lightning",
                  label: "See more",
                  onClick: (function (param) {
                      Curry._1(globalSend, {
                            TAG: /* OpenModal */4,
                            _0: {
                              NAME: "BillingPrompt",
                              VAL: "ExpandActivityLog"
                            }
                          });
                    })
                }), React.createElement("div", {
                  className: fadeStyles
                }));
}

var Upgrade = {
  rootStyles: rootStyles,
  fadeStyles: fadeStyles,
  make: ActivityLog$Upgrade
};

function extractNestedActions(actions) {
  return Belt_List.flatten(Belt_List.map(actions, (function (action) {
                    var _action = action.contents;
                    if (typeof _action === "object" && _action.NAME === "CreateDemoBranch") {
                      return {
                              hd: action,
                              tl: Belt_List.map(_action.VAL, (function (demoBranchAction) {
                                      var tmp = {
                                        id: action.id,
                                        contents: demoBranchAction,
                                        contentsJson: Printer.printAction(demoBranchAction),
                                        createdAt: action.createdAt,
                                        createdBy: action.createdBy,
                                        audit: action.audit,
                                        actionImpactLevel: action.actionImpactLevel,
                                        order: action.order
                                      };
                                      var tmp$1 = Caml_option.undefined_to_opt(action.system);
                                      if (tmp$1 !== undefined) {
                                        tmp.system = Caml_option.valFromOption(tmp$1);
                                      }
                                      var tmp$2 = Caml_option.undefined_to_opt(action.context);
                                      if (tmp$2 !== undefined) {
                                        tmp.context = Caml_option.valFromOption(tmp$2);
                                      }
                                      var tmp$3 = Caml_option.undefined_to_opt(action.branchId);
                                      if (tmp$3 !== undefined) {
                                        tmp.branchId = Caml_option.valFromOption(tmp$3);
                                      }
                                      return tmp;
                                    }))
                            };
                    } else {
                      return {
                              hd: action,
                              tl: /* [] */0
                            };
                    }
                  })));
}

function ActivityLog$ActionsRender(Props) {
  var actions = Props.actions;
  var availability = Props.availability;
  var globalSend = Props.globalSend;
  var model = Props.model;
  var modelBefore = Props.modelBefore;
  var openBranches = Props.openBranches;
  var match = Belt_List.reduceU(extractNestedActions(actions), [
        modelBefore,
        /* [] */0
      ], (function (param, action) {
          var modelBefore = param[0];
          var modelAfter = Actions.reduce(modelBefore, action.contents);
          return [
                  modelAfter,
                  Belt_List.concat(param[1], {
                        hd: [
                          modelBefore,
                          action
                        ],
                        tl: /* [] */0
                      })
                ];
        }));
  var actionsWithModel = Belt_List.reverse(match[1]);
  var dateGroups = BeltListExtensions.groupConsecutive(Belt_List.keepU(actionsWithModel, (function (param) {
              return !Belt_Option.getWithDefault(Caml_option.undefined_to_opt(param[1].system), false);
            })), (function (param, param$1) {
          return DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(param[1].createdAt), new Date())) === DateFns.format("yyyy.MM.dd", Belt_Option.getWithDefault(Models.toDateOption(param$1[1].createdAt), new Date()));
        }));
  var groupedByActions = Belt_List.flatten(Belt_List.mapReverseU(dateGroups, (function (dateGroup) {
              return Belt_List.reverse(BeltListExtensions.groupConsecutive(dateGroup, (function (param, param$1) {
                                return Actions.groupCompare(param[1], param$1[1]);
                              })));
            })));
  var limitedList = availability === "Unavailable" ? Belt_Option.getWithDefault(Belt_List.take(groupedByActions, 4), groupedByActions) : groupedByActions;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.minHeight(Css.px(108)),
                        tl: /* [] */0
                      })
                }, Belt_List.toArray(Belt_List.mapU(limitedList, (function (actionGroup) {
                            var match = Belt_List.headExn(Belt_List.reverse(actionGroup));
                            var match$1 = Belt_List.headExn(actionGroup);
                            var userIds = Belt_List.toArray(Belt_List.mapU(BeltListExtensions.groupConsecutive(actionGroup, (function (param, param$1) {
                                            return param[1].createdBy === param$1[1].createdBy;
                                          })), (function (userActions) {
                                        var match = Belt_List.headExn(userActions);
                                        return match[1].createdBy;
                                      })));
                            return React.createElement(ActivityItem.make, {
                                        modelBeforeAction: match[0],
                                        currentModel: model,
                                        action: match$1[1],
                                        userIds: userIds,
                                        openBranches: openBranches,
                                        globalSend: globalSend,
                                        key: match[1].id
                                      });
                          })))), availability === "Unavailable" ? React.createElement(ActivityLog$Upgrade, {
                    globalSend: globalSend
                  }) : null);
}

var ActionsRender = {
  make: ActivityLog$ActionsRender
};

function ActivityLog$WithFetchModel(Props) {
  var availability = Props.availability;
  var globalSend = Props.globalSend;
  var items = Props.items;
  var model = Props.model;
  var openBranches = Props.openBranches;
  var branch = Props.branch;
  var beforeAndEqualsDate = Props.beforeAndEqualsDate;
  var beforeDate = Props.beforeDate;
  var fetchState = FetchModel.use(beforeDate, beforeAndEqualsDate, undefined, branch);
  switch (fetchState.TAG | 0) {
    case /* Loading */0 :
        return React.createElement(LoadingFullscreen.make, {
                    message: fetchState._0
                  });
    case /* Error */1 :
        return React.createElement(FetchModel.$$Error.make, {
                    message: fetchState._0
                  });
    case /* Result */2 :
        return React.createElement(ActivityLog$ActionsRender, {
                    actions: items,
                    availability: availability,
                    globalSend: globalSend,
                    model: model,
                    modelBefore: fetchState._0,
                    openBranches: openBranches
                  });
    
  }
}

var WithFetchModel = {
  make: ActivityLog$WithFetchModel
};

function ActivityLog(Props) {
  var availability = Props.availability;
  var items = Props.items;
  var status = Props.status;
  var getMore = Props.getMore;
  var openBranches = Props.openBranches;
  var globalSend = Props.globalSend;
  var currentBranch = Props.currentBranch;
  var model = Props.model;
  var workspace = WorkspaceContext.use(undefined);
  var lastActionTimestamp = Belt_Option.map(Belt_Option.flatMapU(Belt_List.head(items), (function (lastAction) {
              return Caml_option.nullable_to_opt(lastAction.createdAt);
            })), (function (prim) {
          return prim.toDate();
        }));
  var latestVersionFetchMode = status === "Loading" ? "Wait" : (
      items ? "Fetch" : "Skip"
    );
  var branchId = Actions.branchToId(currentBranch);
  var currentBranchSnapshots = FirebaseFetcherHooks.useLatestVersion(workspace.id, lastActionTimestamp, undefined, branchId, latestVersionFetchMode, undefined);
  var actionsFetchMode;
  if (latestVersionFetchMode === "Wait" || latestVersionFetchMode === "Skip") {
    actionsFetchMode = latestVersionFetchMode;
  } else if (typeof currentBranchSnapshots === "object") {
    var match = currentBranchSnapshots.VAL;
    actionsFetchMode = match && !match.tl ? "Fetch" : "Skip";
  } else {
    actionsFetchMode = currentBranchSnapshots === "Errored" || currentBranchSnapshots === "Initial" ? "Wait" : "Skip";
  }
  var tmp;
  if (typeof currentBranchSnapshots === "object" && currentBranchSnapshots.NAME === "Loaded") {
    var match$1 = currentBranchSnapshots.VAL;
    tmp = match$1 && !match$1.tl ? Caml_option.some(match$1.hd) : undefined;
  } else {
    tmp = undefined;
  }
  var match$2 = FirebaseFetcherHooks.useActions(tmp, workspace.id, branchId, lastActionTimestamp, undefined, false, "ActivityLogs", actionsFetchMode, undefined);
  var currentBranchActions = match$2[1];
  var branchOpenAction = FirebaseFetcherHooks.useBranchOpenAction(workspace.id, branchId, "NonDemo", typeof currentBranch === "object" ? "Fetch" : "Skip", undefined);
  var demoBranchOpenAction = FirebaseFetcherHooks.useBranchOpenAction(workspace.id, branchId, "Demo", typeof currentBranch === "object" ? "Fetch" : "Skip", undefined);
  var branchOpenAction$1 = branchOpenAction !== undefined ? branchOpenAction : (
      demoBranchOpenAction !== undefined ? demoBranchOpenAction : undefined
    );
  var openNewEventModal = NewEventModal.useOpen("ActivityLog");
  var tmp$1;
  var exit = 0;
  if (status === "Loading") {
    if (items) {
      exit = 1;
    } else {
      tmp$1 = null;
    }
  } else if (items) {
    exit = 1;
  } else {
    tmp$1 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.justifyContent("center"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.padding(Css.px(40)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement($$Text.make, {
              size: "Medium",
              weight: "Semi",
              color: Styles.Color.light11,
              children: "No activity yet! 👀"
            }), React.createElement(Spacer.make, {
              height: 20
            }), React.createElement(Button.make, {
              label: "Add event",
              onClick: (function (param) {
                  Curry._1(openNewEventModal, undefined);
                })
            }));
  }
  if (exit === 1) {
    if (typeof currentBranchSnapshots === "object") {
      var match$3 = currentBranchSnapshots.VAL;
      var exit$1 = 0;
      if (match$3 && !match$3.tl) {
        if (typeof currentBranchActions === "object") {
          if (currentBranchActions.NAME === "Success") {
            var modelBefore = AvoState.computeCurrentModelFromActions(Parser.parseSchemaExn(match$3.hd.contents), currentBranchActions.VAL);
            tmp$1 = React.createElement(ActivityLog$ActionsRender, {
                  actions: items,
                  availability: availability,
                  globalSend: globalSend,
                  model: model,
                  modelBefore: modelBefore,
                  openBranches: openBranches
                });
          } else {
            exit$1 = 2;
          }
        } else if (currentBranchActions === "Initial") {
          tmp$1 = Belt_Array.makeBy(10, (function (index) {
                  return React.createElement(ActivityItemLoading.make, {
                              shimmer: true,
                              key: "loading-" + String(index) + ""
                            });
                }));
        } else {
          exit$1 = 2;
        }
      } else {
        exit$1 = 2;
      }
      if (exit$1 === 2) {
        var match$4 = model.branchPointer;
        if (typeof currentBranch === "object") {
          if (match$4 !== undefined) {
            var branchTimestamp = match$4[1];
            tmp$1 = React.createElement(ActivityLog$WithFetchModel, {
                  availability: availability,
                  globalSend: globalSend,
                  items: items,
                  model: model,
                  openBranches: openBranches,
                  branch: "master",
                  beforeAndEqualsDate: Caml_option.some(new Date(branchTimestamp)),
                  beforeDate: undefined,
                  key: "masterModelRefetchKey-" + branchTimestamp + ""
                });
          } else if (branchOpenAction$1 !== undefined) {
            var date = Belt_Option.mapWithDefault(Models.toDateOption(Caml_option.valFromOption(branchOpenAction$1).createdAt), new Date().toISOString(), (function (prim) {
                    return prim.toISOString();
                  }));
            tmp$1 = React.createElement(ActivityLog$WithFetchModel, {
                  availability: availability,
                  globalSend: globalSend,
                  items: items,
                  model: model,
                  openBranches: openBranches,
                  branch: "master",
                  beforeAndEqualsDate: Caml_option.some(new Date(date)),
                  beforeDate: undefined,
                  key: "masterModelRefetchKey-" + date + ""
                });
          } else {
            tmp$1 = null;
          }
        } else {
          tmp$1 = React.createElement(ActivityLog$ActionsRender, {
                actions: items,
                availability: availability,
                globalSend: globalSend,
                model: model,
                modelBefore: TrackingPlanModel.empty,
                openBranches: openBranches
              });
        }
      }
      
    } else {
      tmp$1 = currentBranchSnapshots === "Initial" ? Belt_Array.makeBy(10, (function (index) {
                return React.createElement(ActivityItemLoading.make, {
                            key: "loading-" + String(index) + ""
                          });
              })) : null;
    }
  }
  return React.createElement("div", undefined, tmp$1, status === "Loading" ? Belt_Array.makeBy(10, (function (index) {
                      return React.createElement(ActivityItemLoading.make, {
                                  key: "loading-" + String(index) + ""
                                });
                    })) : (
                (availability === "AvailableDuringTrial" || availability === "Available") && status === "HasMore" ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.padding(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement(TextButton.make, {
                            onClick: (function (param) {
                                Curry._1(getMore, undefined);
                              }),
                            style: "Blue",
                            size: "Medium",
                            children: "Load older activity..."
                          })) : null
              ));
}

var make = ActivityLog;

export {
  Upgrade ,
  extractNestedActions ,
  ActionsRender ,
  WithFetchModel ,
  make ,
}
/* rootStyles Not a pure module */
