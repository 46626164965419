// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as InspectorPropertyType from "./InspectorPropertyType.mjs";

function encode(sourcePresence) {
  return Json_encode.object_({
              hd: [
                "totalVolume",
                sourcePresence.totalVolume
              ],
              tl: {
                hd: [
                  "percentagePresent",
                  sourcePresence.percentagePresent
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          totalVolume: Json_decode.field("totalVolume", Json_decode.$$float, json),
          percentagePresent: Json_decode.field("percentagePresent", Json_decode.$$float, json)
        };
}

var SourcePresence = {
  encode: encode,
  decode: decode
};

function encode$1(propertyType) {
  return Json_encode.object_({
              hd: [
                "type",
                InspectorPropertyType.encode(propertyType.type_)
              ],
              tl: {
                hd: [
                  "isList",
                  propertyType.isList
                ],
                tl: {
                  hd: [
                    "totalVolume",
                    propertyType.totalVolume
                  ],
                  tl: {
                    hd: [
                      "percentagePresent",
                      propertyType.percentagePresent
                    ],
                    tl: {
                      hd: [
                        "presenceBySource",
                        Json_encode.dict(encode, propertyType.presenceBySource)
                      ],
                      tl: {
                        hd: [
                          "nestedProperties",
                          Json_encode.array(PropertyPresenceStats.encode, propertyType.nestedProperties)
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function decode$1(json) {
  return {
          type_: Json_decode.field("type", InspectorPropertyType.decode, json),
          isList: Json_decode.field("isList", Json_decode.bool, json),
          totalVolume: Json_decode.field("totalVolume", Json_decode.$$float, json),
          percentagePresent: Json_decode.field("percentagePresent", Json_decode.$$float, json),
          presenceBySource: Json_decode.field("presenceBySource", (function (param) {
                  return Json_decode.dict(decode, param);
                }), json),
          nestedProperties: Belt_Option.getWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("nestedProperties", (function (param) {
                                    return Json_decode.array(PropertyPresenceStats.decode, param);
                                  }), param);
                    }), json), [])
        };
}

var PropertyType = {
  encode: encode$1,
  decode: decode$1
};

function encode$2(presence) {
  return Json_encode.object_({
              hd: [
                "totalVolume",
                presence.totalVolume
              ],
              tl: {
                hd: [
                  "percentagePresent",
                  presence.percentagePresent
                ],
                tl: {
                  hd: [
                    "presenceBySource",
                    Json_encode.dict(encode, presence.presenceBySource)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$2(json) {
  return {
          totalVolume: Json_decode.field("totalVolume", Json_decode.$$float, json),
          percentagePresent: Json_decode.field("percentagePresent", Json_decode.$$float, json),
          presenceBySource: Json_decode.field("presenceBySource", (function (param) {
                  return Json_decode.dict(decode, param);
                }), json)
        };
}

var Presence = {
  encode: encode$2,
  decode: decode$2
};

function encode$3(propertyPresenceStats) {
  return Json_encode.object_({
              hd: [
                "propertyName",
                propertyPresenceStats.propertyName
              ],
              tl: {
                hd: [
                  "propertyTypes",
                  Json_encode.array(encode$1, propertyPresenceStats.propertyTypes)
                ],
                tl: {
                  hd: [
                    "presence",
                    Curry._1(encode$2, propertyPresenceStats.presence)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$3(json) {
  return {
          propertyName: Json_decode.field("propertyName", Json_decode.string, json),
          propertyTypes: Json_decode.field("propertyTypes", (function (param) {
                  return Json_decode.array(decode$1, param);
                }), json),
          presence: Json_decode.field("presence", decode$2, json)
        };
}

var PropertyPresenceStats = {
  encode: encode$3,
  decode: decode$3
};

function encode$4(eventPresenceStats) {
  return Json_encode.object_({
              hd: [
                "eventName",
                eventPresenceStats.eventName
              ],
              tl: {
                hd: [
                  "totalVolume",
                  eventPresenceStats.totalVolume
                ],
                tl: {
                  hd: [
                    "properties",
                    Json_encode.array(encode$3, eventPresenceStats.properties)
                  ],
                  tl: {
                    hd: [
                      "presenceBySource",
                      Json_encode.dict((function (prim) {
                              return prim;
                            }), eventPresenceStats.presenceBySource)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function decode$4(json) {
  var presenceBySource;
  try {
    presenceBySource = Json_decode.field("presenceBySource", (function (param) {
            return Json_decode.dict(Json_decode.$$float, param);
          }), json);
  }
  catch (exn){
    var presence = Json_decode.field("presenceBySource", (function (param) {
            return Json_decode.dict(decode, param);
          }), json);
    presenceBySource = Js_dict.fromArray(Belt_Array.map(Js_dict.entries(presence), (function (param) {
                return [
                        param[0],
                        param[1].totalVolume
                      ];
              })));
  }
  return {
          eventName: Json_decode.field("eventName", Json_decode.string, json),
          totalVolume: Json_decode.field("totalVolume", Json_decode.$$float, json),
          properties: Json_decode.field("properties", (function (param) {
                  return Json_decode.array(decode$3, param);
                }), json),
          presenceBySource: presenceBySource
        };
}

export {
  SourcePresence ,
  PropertyType ,
  Presence ,
  PropertyPresenceStats ,
  encode$4 as encode,
  decode$4 as decode,
}
/* No side effect */
