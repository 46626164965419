// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";

function encode(promotionCode) {
  return Json_encode.object_({
              hd: [
                "id",
                promotionCode.id
              ],
              tl: {
                hd: [
                  "amount_off",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), promotionCode.amount_off)
                ],
                tl: {
                  hd: [
                    "duration",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), promotionCode.duration)
                  ],
                  tl: {
                    hd: [
                      "duration_in_months",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), promotionCode.duration_in_months)
                    ],
                    tl: {
                      hd: [
                        "livemode",
                        promotionCode.livemode
                      ],
                      tl: {
                        hd: [
                          "name",
                          promotionCode.name
                        ],
                        tl: {
                          hd: [
                            "percent_off",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), promotionCode.percent_off)
                          ],
                          tl: {
                            hd: [
                              "valid",
                              promotionCode.valid
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          amount_off: Json_decode.optional((function (param) {
                  return Json_decode.field("amount_off", Json_decode.$$float, param);
                }), json),
          duration: Json_decode.optional((function (param) {
                  return Json_decode.field("duration", Json_decode.string, param);
                }), json),
          duration_in_months: Json_decode.optional((function (param) {
                  return Json_decode.field("duration_in_months", Json_decode.$$int, param);
                }), json),
          livemode: Json_decode.field("livemode", Json_decode.bool, json),
          name: Json_decode.field("name", Json_decode.string, json),
          percent_off: Json_decode.optional((function (param) {
                  return Json_decode.field("percent_off", Json_decode.$$float, param);
                }), json),
          valid: Json_decode.field("valid", Json_decode.bool, json)
        };
}

var Coupon = {
  encode: encode,
  decode: decode
};

function encode$1(promotionCode) {
  return Json_encode.object_({
              hd: [
                "id",
                promotionCode.id
              ],
              tl: {
                hd: [
                  "active",
                  promotionCode.active
                ],
                tl: {
                  hd: [
                    "coupon",
                    encode(promotionCode.coupon)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decode$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          active: Json_decode.field("active", Json_decode.bool, json),
          coupon: Json_decode.field("coupon", decode, json)
        };
}

var PromotionCode = {
  encode: encode$1,
  decode: decode$1
};

function decodeSubscriptionItemPrice(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          recurring: Json_decode.field("recurring", (function (json) {
                  return {
                          interval: Json_decode.field("interval", Json_decode.string, json),
                          interval_count: Json_decode.field("interval_count", Json_decode.$$int, json)
                        };
                }), json)
        };
}

function decodeSubscriptionItem(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          quantity: Json_decode.field("quantity", Json_decode.$$int, json),
          price: Json_decode.field("price", decodeSubscriptionItemPrice, json)
        };
}

function decode$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          customer: Json_decode.field("customer", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          livemode: Json_decode.field("livemode", Json_decode.bool, json),
          items: Json_decode.field("items", (function (json) {
                  return {
                          data: Json_decode.field("data", (function (param) {
                                  return Json_decode.array(decodeSubscriptionItem, param);
                                }), json)
                        };
                }), json)
        };
}

function encode$2(subscription) {
  return Json_encode.object_({
              hd: [
                "id",
                subscription.id
              ],
              tl: {
                hd: [
                  "customer",
                  subscription.customer
                ],
                tl: {
                  hd: [
                    "status",
                    subscription.status
                  ],
                  tl: {
                    hd: [
                      "livemode",
                      subscription.livemode
                    ],
                    tl: {
                      hd: [
                        "items",
                        Json_encode.object_({
                              hd: [
                                "data",
                                Json_encode.array((function (item) {
                                        return Json_encode.object_({
                                                    hd: [
                                                      "id",
                                                      item.id
                                                    ],
                                                    tl: {
                                                      hd: [
                                                        "quantity",
                                                        item.quantity
                                                      ],
                                                      tl: {
                                                        hd: [
                                                          "price",
                                                          Json_encode.object_({
                                                                hd: [
                                                                  "id",
                                                                  item.price.id
                                                                ],
                                                                tl: {
                                                                  hd: [
                                                                    "recurring",
                                                                    Json_encode.object_({
                                                                          hd: [
                                                                            "interval",
                                                                            item.price.recurring.interval
                                                                          ],
                                                                          tl: {
                                                                            hd: [
                                                                              "interval_count",
                                                                              item.price.recurring.interval_count
                                                                            ],
                                                                            tl: /* [] */0
                                                                          }
                                                                        })
                                                                  ],
                                                                  tl: /* [] */0
                                                                }
                                                              })
                                                        ],
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  });
                                      }), subscription.items.data)
                              ],
                              tl: /* [] */0
                            })
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

var Subscription = {
  decodeSubscriptionItemPrice: decodeSubscriptionItemPrice,
  decodeSubscriptionItem: decodeSubscriptionItem,
  decode: decode$2,
  encode: encode$2
};

export {
  Coupon ,
  PromotionCode ,
  Subscription ,
}
/* No side effect */
