// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Case from "../../bs-case/src/case.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.mjs";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_MutableMapInt from "rescript/lib/es6/belt_MutableMapInt.js";
import * as ValidationConfigV2 from "../../app/src/guardrails/ValidationConfigV2.mjs";

function make(name, value) {
  return {
          componentName: name,
          componentValue: value
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "componentName",
                t.componentName
              ],
              tl: {
                hd: [
                  "componentValue",
                  t.componentValue
                ],
                tl: /* [] */0
              }
            });
}

function decode(json) {
  return {
          componentName: Json_decode.field("componentName", Json_decode.string, json),
          componentValue: Json_decode.field("componentValue", Json_decode.string, json)
        };
}

function encodeArray(arr) {
  return Json_encode.array(encode, arr);
}

function decodeArray(json) {
  return Json_decode.array(decode, json);
}

function toString(validity) {
  if (validity === "valid") {
    return "valid";
  } else if (validity === "invalid") {
    return "invalid";
  } else {
    return "inconclusive";
  }
}

function fromString(str) {
  switch (str) {
    case "inconclusive" :
        return "inconclusive";
    case "invalid" :
        return "invalid";
    case "valid" :
        return "valid";
    default:
      return "inconclusive";
  }
}

var Validity = {
  toString: toString,
  fromString: fromString
};

function encode$1(t) {
  return Json_encode.object_({
              hd: [
                "separator",
                t.separator
              ],
              tl: {
                hd: [
                  "validity",
                  toString(t.validity)
                ],
                tl: /* [] */0
              }
            });
}

function decode$1(json) {
  return {
          separator: Json_decode.field("separator", Json_decode.string, json),
          validity: fromString(Json_decode.field("validity", Json_decode.string, json))
        };
}

var SeparatorState = {
  Validity: Validity,
  encode: encode$1,
  decode: decode$1
};

function getPositionsOfSubstring(str, fromMinimumOpt, substring) {
  var fromMinimum = fromMinimumOpt !== undefined ? fromMinimumOpt : 0;
  var positions = [];
  var firstPosition = str.indexOf(substring, fromMinimum);
  if (firstPosition !== -1) {
    positions.push(firstPosition);
    var _param;
    while(true) {
      var position = Belt_Array.get(positions, positions.length - 1 | 0);
      var nextPosition;
      if (position !== undefined) {
        var index = str.indexOf(substring, position + substring.length | 0);
        nextPosition = index !== -1 ? index : undefined;
      } else {
        nextPosition = undefined;
      }
      if (nextPosition === undefined) {
        return positions;
      }
      positions.push(nextPosition);
      _param = undefined;
      continue ;
    };
  } else {
    return [];
  }
}

function getSeparatorStates(nameComponents, eventName) {
  var separators = ValidationConfigV2.NameComponent.getSeparators(nameComponents);
  var fromMinimum = {
    contents: 0
  };
  var allPossiblePositions = separators.map(function (separator) {
        var positions = getPositionsOfSubstring(eventName, fromMinimum.contents, separator);
        var possiblePositions = positions.length !== 0 ? positions : undefined;
        var possiblePositionsMin = Core__Option.flatMap(possiblePositions, (function (possiblePositions) {
                return possiblePositions[0];
              }));
        if (possiblePositionsMin !== undefined) {
          fromMinimum.contents = possiblePositionsMin + separator.length | 0;
        }
        return [
                separator,
                possiblePositions,
                possiblePositionsMin
              ];
      });
  if (allPossiblePositions.length !== 1) {
    return allPossiblePositions.map(function (param) {
                return {
                        separator: param[0],
                        validity: Core__Option.isSome(param[1]) ? "valid" : "invalid"
                      };
              });
  }
  var match = allPossiblePositions[0];
  var possiblePositions = match[1];
  var separator = match[0];
  if (possiblePositions !== undefined) {
    if (possiblePositions.length === 1) {
      return [{
                separator: separator,
                validity: "valid"
              }];
    } else {
      return [{
                separator: separator,
                validity: "inconclusive"
              }];
    }
  } else {
    return [{
              separator: separator,
              validity: "invalid"
            }];
  }
}

function getSeparatorsAreKnown(nameComponents, eventName) {
  return Belt_Array.every(getSeparatorStates(nameComponents, eventName), (function (param) {
                return param.validity === "valid";
              }));
}

function analyzeComponentsFromKnownSeparators_caseIgnore(nameComponents, eventName) {
  var separators = ValidationConfigV2.NameComponent.getSeparators(nameComponents);
  var match = Belt_Array.reduceWithIndex(separators, [
        [],
        eventName
      ], (function (param, separator, reduceIndex) {
          var remainingEventName = param[1];
          var index = remainingEventName.indexOf(separator);
          var component = remainingEventName.slice(0, index);
          var remainingEventName$1 = remainingEventName.slice(index + separator.length | 0);
          var result = Belt_Array.concat(param[0], [component]);
          if (reduceIndex === (separators.length - 1 | 0)) {
            return [
                    Belt_Array.concat(result, [remainingEventName$1]),
                    ""
                  ];
          } else {
            return [
                    result,
                    remainingEventName$1
                  ];
          }
        }));
  var components = match[0];
  var nameComponents$1 = ValidationConfigV2.NameComponent.getBuildingBlocks(nameComponents);
  if (nameComponents$1.length === components.length) {
    return Belt_Array.map(Belt_Array.zip(nameComponents$1, components), (function (param) {
                  var match = param[0];
                  var $$case = Curry._1(ValidationConfigV2.Value.getCase, match.case.value);
                  return {
                          componentName: match.name,
                          componentValue: Case.to_($$case, param[1])
                        };
                }));
  }
  
}

function getEventNameComponentFromNameBasedOnSeparators(nameComponents, eventName) {
  if (getSeparatorsAreKnown(nameComponents, eventName)) {
    return analyzeComponentsFromKnownSeparators_caseIgnore(nameComponents, eventName);
  }
  
}

function getAllowedValuesMap(nameComponents) {
  var nameBuildingBlockIndex = {
    contents: 0
  };
  return Belt_Array.reduceWithIndex(nameComponents, undefined, (function (allowedValuesMap, nameComponent, index) {
                if (nameComponent.NAME === "separator") {
                  return allowedValuesMap;
                }
                var result = Belt_Array.reduce(Curry._1(ValidationConfigV2.Value.getAllowedValues, nameComponent.VAL.allowedValues.value), allowedValuesMap, (function (allowedValuesMap, allowedValue) {
                        return Belt_MapString.update(allowedValuesMap, allowedValue, (function (maybeName) {
                                      if (maybeName !== undefined) {
                                        return Pervasives.failwith("Allowed value already exists");
                                      } else {
                                        return [
                                                nameComponent,
                                                index,
                                                nameBuildingBlockIndex.contents
                                              ];
                                      }
                                    }));
                      }));
                nameBuildingBlockIndex.contents = nameBuildingBlockIndex.contents + 1 | 0;
                return result;
              }));
}

function guessEventNameComponentsBasedOnAllowedValues(nameComponents, eventName) {
  try {
    var allowedValuesMap = getAllowedValuesMap(nameComponents);
    var matchedMap = Belt_MutableMapInt.fromArray([]);
    Belt_MapString.forEach(allowedValuesMap, (function (allowedValue, param) {
            var nameBuildingBlockIndex = param[2];
            if (eventName.includes(allowedValue) && !Belt_MutableMapInt.has(matchedMap, nameBuildingBlockIndex)) {
              return Belt_MutableMapInt.set(matchedMap, nameBuildingBlockIndex, [
                          param[0],
                          param[1],
                          eventName.indexOf(allowedValue),
                          allowedValue
                        ]);
            }
            
          }));
    return Belt_Array.mapWithIndex(ValidationConfigV2.NameComponent.getBuildingBlocks(nameComponents), (function (index, nameBuildingBlock) {
                  var hasMatch = Belt_MutableMapInt.has(matchedMap, index);
                  var hasNextMatch = Belt_MutableMapInt.has(matchedMap, index + 1 | 0);
                  var hasPreviousMatch = Belt_MutableMapInt.has(matchedMap, index - 1 | 0);
                  var atPenultimateIndex = index === (nameComponents.length - 2 | 0);
                  if (!hasMatch && (hasNextMatch || atPenultimateIndex || hasPreviousMatch || index === 0)) {
                    var nextMatched = Belt_MutableMapInt.get(matchedMap, index + 1 | 0);
                    var previousMatched = Belt_MutableMapInt.get(matchedMap, index - 1 | 0);
                    if (previousMatched !== undefined) {
                      if (nextMatched !== undefined) {
                        return {
                                componentName: nameBuildingBlock.name,
                                componentValue: ""
                              };
                      }
                      var substring = eventName.slice(previousMatched[2] + previousMatched[3].length | 0);
                      var match = Belt_Array.get(nameComponents, previousMatched[1] + 1 | 0);
                      var substringWithoutPossibleSeparatorAtStart;
                      if (match !== undefined && typeof match === "object" && match.NAME === "separator") {
                        var separator = match.VAL;
                        substringWithoutPossibleSeparatorAtStart = substring.startsWith(separator) ? substring.slice(separator.length) : substring;
                      } else {
                        substringWithoutPossibleSeparatorAtStart = substring;
                      }
                      return {
                              componentName: nameBuildingBlock.name,
                              componentValue: substringWithoutPossibleSeparatorAtStart
                            };
                    }
                    if (nextMatched === undefined) {
                      return {
                              componentName: nameBuildingBlock.name,
                              componentValue: ""
                            };
                    }
                    var substring$1 = eventName.slice(0, nextMatched[2]);
                    var match$1 = Belt_Array.get(nameComponents, nextMatched[1] - 1 | 0);
                    var substringWithoutPossibleSeparatorAtEnd;
                    if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "separator") {
                      var separator$1 = match$1.VAL;
                      if (substring$1.endsWith(separator$1)) {
                        var indexOfSeparator = substring$1.lastIndexOf(separator$1);
                        substringWithoutPossibleSeparatorAtEnd = substring$1.slice(0, indexOfSeparator);
                      } else {
                        substringWithoutPossibleSeparatorAtEnd = substring$1;
                      }
                    } else {
                      substringWithoutPossibleSeparatorAtEnd = substring$1;
                    }
                    return {
                            componentName: nameBuildingBlock.name,
                            componentValue: substringWithoutPossibleSeparatorAtEnd
                          };
                  }
                  var match$2 = Belt_MutableMapInt.get(matchedMap, index);
                  if (match$2 !== undefined) {
                    return {
                            componentName: match$2[0].VAL.name,
                            componentValue: match$2[3]
                          };
                  } else {
                    return {
                            componentName: nameBuildingBlock.name,
                            componentValue: ""
                          };
                  }
                }));
  }
  catch (exn){
    return ;
  }
}

function getValidations(eventNameComponents, nameComponents) {
  return Belt_Array.map(Belt_Array.zip(ValidationConfigV2.NameComponent.getBuildingBlocks(nameComponents), eventNameComponents), (function (param) {
                var eventNameComponent = param[1];
                var $$case = Curry._1(ValidationConfigV2.Value.getCase, param[0].case.value);
                var valid = Case.to_($$case, eventNameComponent.componentValue) === eventNameComponent.componentValue && eventNameComponent.componentValue !== "";
                return {
                        valid: valid,
                        suggestion: valid ? undefined : Case.to_($$case, eventNameComponent.componentValue)
                      };
              }));
}

function isValid(validations) {
  return Belt_Array.every(validations, (function (param) {
                return param.valid;
              }));
}

function extractComponentsFromName(eventName, nameComponents) {
  var allowedValuesGuess = guessEventNameComponentsBasedOnAllowedValues(nameComponents, eventName);
  var separatorsGuess = getEventNameComponentFromNameBasedOnSeparators(nameComponents, eventName);
  if (Caml_obj.equal(allowedValuesGuess, separatorsGuess)) {
    return allowedValuesGuess;
  }
  if (allowedValuesGuess === undefined) {
    if (separatorsGuess !== undefined) {
      return separatorsGuess;
    } else {
      return ;
    }
  }
  if (separatorsGuess === undefined) {
    return allowedValuesGuess;
  }
  if (isValid(getValidations(allowedValuesGuess, nameComponents))) {
    return allowedValuesGuess;
  }
  if (isValid(getValidations(separatorsGuess, nameComponents))) {
    return separatorsGuess;
  }
  var zipped = Belt_Array.map(Belt_Array.zip(allowedValuesGuess, separatorsGuess), (function (param) {
          var componentB = param[1];
          var componentA = param[0];
          if (componentA.componentValue === "" && componentB.componentValue !== "") {
            return componentB;
          } else {
            return componentA;
          }
        }));
  if (isValid(getValidations(zipped, nameComponents))) {
    return zipped;
  }
  
}

function getEventNameComponentFromName(eventName, nameComponents) {
  var seperators = ValidationConfigV2.NameComponent.getSeparators(nameComponents);
  var match = Belt_Array.reduce(seperators, [
        eventName,
        []
      ], (function (param, seperator) {
          var components = param[1];
          var name = param[0];
          var sepLength = seperator.length;
          var index = name.indexOf(seperator);
          if (index === -1) {
            return [
                    name,
                    components
                  ];
          }
          var component = name.slice(0, index);
          var name$1 = name.slice(index + sepLength | 0);
          return [
                  name$1,
                  Belt_Array.concat(components, [component])
                ];
        }));
  var components = match[1];
  var lastComponent = match[0];
  var hasAdditionalSeperators = Belt_Array.some(seperators, (function (seperator) {
          return lastComponent.includes(seperator);
        }));
  var components$1 = hasAdditionalSeperators ? [] : (
      lastComponent !== "" ? Belt_Array.concat(components, [lastComponent]) : components
    );
  var buildingBlocks = ValidationConfigV2.NameComponent.getBuildingBlocks(nameComponents);
  var fullEventName = Belt_Array.reduceWithIndex(components$1, "", (function (name, component, index) {
          var separator = Belt_Array.get(seperators, index);
          if (separator !== undefined) {
            return name + component + separator;
          } else {
            return name + component;
          }
        }));
  if (buildingBlocks.length === components$1.length && fullEventName === eventName) {
    return Belt_Array.map(Belt_Array.zip(buildingBlocks, components$1), (function (param) {
                  var buildingBlock = param[0];
                  return {
                          componentName: buildingBlock.name,
                          componentValue: Case.to_(Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value), param[1])
                        };
                }));
  }
  
}

export {
  make ,
  encode ,
  decode ,
  encodeArray ,
  decodeArray ,
  SeparatorState ,
  getPositionsOfSubstring ,
  getSeparatorStates ,
  getSeparatorsAreKnown ,
  analyzeComponentsFromKnownSeparators_caseIgnore ,
  getEventNameComponentFromNameBasedOnSeparators ,
  getAllowedValuesMap ,
  guessEventNameComponentsBasedOnAllowedValues ,
  getValidations ,
  isValid ,
  extractComponentsFromName ,
  getEventNameComponentFromName ,
}
/* Case Not a pure module */
