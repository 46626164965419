// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";

function Spacer(props) {
  var display = props.display;
  var display$1 = display !== undefined ? display : "block";
  return JsxRuntime.jsx("span", {
              className: Curry._1(Css.style, Belt_List.concatMany([
                        {
                          hd: Css.display(display$1),
                          tl: {
                            hd: Css.flexShrink(0.0),
                            tl: /* [] */0
                          }
                        },
                        Belt_Option.mapWithDefault(props.width, /* [] */0, (function (x) {
                                return {
                                        hd: Css.width(Css.px(x)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(props.height, /* [] */0, (function (y) {
                                return {
                                        hd: Css.height(Css.px(y)),
                                        tl: /* [] */0
                                      };
                              })),
                        Belt_Option.mapWithDefault(props.grow, /* [] */0, (function (grow) {
                                return {
                                        hd: Css.flexGrow(grow),
                                        tl: /* [] */0
                                      };
                              }))
                      ]))
            });
}

var make = Spacer;

export {
  make ,
}
/* Css Not a pure module */
