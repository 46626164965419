// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "../Hooks.mjs";
import * as React from "react";
import * as Router from "../Router.mjs";
import * as $$String from "rescript/lib/es6/string.js";
import * as Styles from "../styles.mjs";
import * as $$Window from "../externals/window.mjs";
import * as Nanoid from "nanoid";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as AvoConfig from "../../../shared/utils/AvoConfig.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModelStore from "../ModelStore.mjs";
import * as Fuzzaldrin from "fuzzaldrin";
import * as AnalyticsRe from "../analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DomainStore from "../DomainStore.mjs";
import * as QueryString from "query-string";
import * as ReactWindow from "react-window";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as InspectorViewCells from "./InspectorViewCells.mjs";
import * as InspectorViewModel from "./InspectorViewModel.mjs";
import * as InspectorViewConfig from "./InspectorViewConfig.mjs";
import * as SchemaBundleContext from "../SchemaBundleContext.mjs";
import * as InspectorEventsStore from "./InspectorEventsStore.mjs";
import * as InspectorViewSelectors from "./InspectorViewSelectors.mjs";
import * as ImportReview__V2__Utils from "../import/ImportReview__V2__Utils.mjs";
import * as InspectorEventViewModel from "./InspectorEventViewModel.mjs";
import * as TrackingPlanMappedModel from "../../../model/src/TrackingPlanMappedModel.mjs";
import * as InspectorIssuesGenerator from "../../../api/functions/src/inspectorPipeline/utils/InspectorIssuesGenerator.mjs";
import * as InspectorEventViewModelUtils from "./InspectorEventViewModelUtils.mjs";
import ReactVirtualizedAutoSizer from "react-virtualized-auto-sizer";
import * as InspectorViewContextSidebar__V2 from "./InspectorViewContextSidebar__V2.mjs";

var cmp = $$String.compare;

var ExpandedEventsSet = Belt_Id.MakeComparable({
      cmp: cmp
    });

function cmp$1(arrayA, arrayB) {
  return Belt_Array.cmpU(arrayA, arrayB, $$String.compare);
}

var ExpandedPropertyNamesSet = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var propertiesSet = Belt_Set.make(ExpandedPropertyNamesSet);

function isRowExpanded(row, isExpanded) {
  if (typeof row !== "object") {
    return false;
  }
  var variant = row.NAME;
  if (variant === "error") {
    return false;
  } else if (variant === "event") {
    return Belt_Option.isSome(Belt_SetString.get(isExpanded, row.VAL.eventName));
  } else {
    return Belt_Option.isSome(Belt_SetString.get(isExpanded, row.VAL[0].eventName));
  }
}

function isPropertyRowExpanded(row, propertyIsExpanded) {
  if (typeof row !== "object") {
    return false;
  }
  var variant = row.NAME;
  if (variant === "event" || variant === "error") {
    return false;
  } else if (variant === "property") {
    return Belt_Set.has(propertyIsExpanded, [row.VAL[1].propertyName]);
  } else {
    return Belt_Set.has(propertyIsExpanded, row.VAL[2]);
  }
}

function makeCellKey(a, b, c) {
  return "" + a + "-" + String(b) + "-" + String(c) + "";
}

function cellMemo(component) {
  return React.memo(component, (function (before, after) {
                return makeCellKey(before.requestId, before.rowIndex, before.columnIndex) === makeCellKey(after.requestId, after.rowIndex, after.columnIndex);
              }));
}

function getRowStyle(row) {
  if (typeof row === "object" && row.NAME === "event") {
    return Curry._1(Css.style, {
                hd: Css.borderTop(Css.px(1), "solid", Styles.Color.light04),
                tl: /* [] */0
              });
  } else {
    return Curry._1(Css.style, /* [] */0);
  }
}

var make = cellMemo(function (Props) {
      var environment = Props.environment;
      var rowIndex = Props.rowIndex;
      var columnIndex = Props.columnIndex;
      var style = Props.style;
      var data = Props.data;
      var onClick = Props.onClick;
      var onAddEventToTrackingPlan = Props.onAddEventToTrackingPlan;
      var onAddPropertyToTrackingPlan = Props.onAddPropertyToTrackingPlan;
      var mappedEvents = Props.mappedEvents;
      var rules = Props.rules;
      var mappedProperties = Props.mappedProperties;
      var mappedDestinations = Props.mappedDestinations;
      var cell = InspectorViewSelectors.getCell_(data.list, data.sources, rowIndex, columnIndex);
      var row = Belt_Option.getWithDefault(Belt_Array.get(data.list, rowIndex), "loading");
      var isExpanded = isRowExpanded(row, data.isExpanded);
      var isPropertyExpanded = isPropertyRowExpanded(row, data.propertyIsExpanded);
      var match = data.activeCell;
      var isActiveCell;
      if (match !== undefined) {
        var column = match.column;
        var propertyNames = match.propertyNames;
        var eventName = match.eventName;
        isActiveCell = (function ($$event, maybePropertyNames, overlap) {
            if ((column === columnIndex || column === (columnIndex + overlap | 0)) && eventName === $$event.eventName) {
              return Caml_obj.equal(propertyNames, maybePropertyNames);
            } else {
              return false;
            }
          });
      } else {
        isActiveCell = (function (param, param$1, param$2) {
            return false;
          });
      }
      var propertyNames$1;
      if (typeof row === "object") {
        var variant = row.NAME;
        propertyNames$1 = variant === "error" || variant === "event" ? undefined : (
            variant === "property" ? [row.VAL[1].propertyName] : row.VAL[2]
          );
      } else {
        propertyNames$1 = undefined;
      }
      var tmp;
      var exit = 0;
      if (typeof cell === "object") {
        var variant$1 = cell.NAME;
        if (variant$1 === "EventSourceVolume") {
          var match$1 = cell.VAL;
          var $$event = match$1[0];
          tmp = React.createElement(InspectorViewCells.EventSourceVolumeCell_.make, {
                totalSourceVolume: Belt_Option.mapU(match$1[1], (function (sourceId) {
                        return InspectorEventViewModelUtils.$$Event.getTotalCountInt([sourceId], $$event);
                      })),
                isActiveCell: Curry._3(isActiveCell, $$event, undefined, 1),
                isNew: false,
                onClick: (function (param) {
                    Curry._4(onClick, columnIndex, $$event, undefined, propertyNames$1);
                  })
              });
        } else if (variant$1 === "EventSourceIssues") {
          var match$2 = cell.VAL;
          var $$event$1 = match$2[0];
          tmp = React.createElement(InspectorViewCells.EventSourceIssuesCell_.make, {
                event: $$event$1,
                sourceId: match$2[1],
                isActiveCell: Curry._3(isActiveCell, $$event$1, undefined, -1),
                onClick: (function (param) {
                    Curry._4(onClick, columnIndex, $$event$1, undefined, propertyNames$1);
                  })
              });
        } else if (variant$1 === "Error") {
          if (columnIndex === 0) {
            var errorType = cell.VAL[1];
            console.log("ERROR", errorType);
            var tmp$1;
            var exit$1 = 0;
            if (errorType !== undefined && errorType) {
              var env = InspectorViewModel.environmentToJs(environment);
              tmp$1 = React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                        size: "Small",
                        weight: "Semi",
                        color: Styles.Color.red,
                        children: null
                      }, "Inspector for env: " + env + " has not been initialized.", React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.light12,
                            children: "To Initialize, start sending events to the inspector API on " + env + ""
                          })));
            } else {
              exit$1 = 2;
            }
            if (exit$1 === 2) {
              tmp$1 = React.createElement($$Text.make, {
                    size: "Small",
                    weight: "Semi",
                    color: Styles.Color.red,
                    children: "Could not complete loading events."
                  });
            }
            tmp = React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("flexStart"),
                            tl: {
                              hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                              tl: {
                                hd: Css.paddingLeft(columnIndex === 0 ? Css.px(40) : Css.px(0)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, tmp$1);
          } else {
            tmp = null;
          }
        } else if (variant$1 === "EventVolume") {
          var $$event$2 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventVolumeCell.make, {
                eventTotalCount: InspectorEventViewModelUtils.$$Event.getTotalCountInt(undefined, $$event$2),
                isNew: false,
                isActiveCell: Curry._3(isActiveCell, $$event$2, undefined, 1),
                onClick: (function (param) {
                    Curry._4(onClick, columnIndex, $$event$2, undefined, propertyNames$1);
                  })
              });
        } else if (variant$1 === "EventName") {
          var $$event$3 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventNameCell_.make, {
                eventName: $$event$3.eventName,
                isExpanded: isExpanded,
                isActive: Belt_Option.mapWithDefault(data.activeCell, false, (function (param) {
                        return param.eventName === $$event$3.eventName;
                      })),
                isActiveCell: Curry._3(isActiveCell, $$event$3, undefined, 0),
                onClick: (function (param) {
                    Curry._4(onClick, columnIndex, $$event$3, undefined, propertyNames$1);
                  }),
                onAddToTrackingPlan: (function (param) {
                    Curry._1(onAddEventToTrackingPlan, $$event$3);
                  }),
                mappedEvents: mappedEvents,
                rules: rules
              });
        } else if (variant$1 === "EventIssues") {
          var $$event$4 = cell.VAL;
          tmp = React.createElement(InspectorViewCells.EventIssuesCell_.make, {
                event: $$event$4,
                isActiveCell: Curry._3(isActiveCell, $$event$4, undefined, -1),
                onClick: (function (param) {
                    Curry._4(onClick, columnIndex, $$event$4, undefined, propertyNames$1);
                  })
              });
        } else {
          exit = 1;
        }
      } else if (cell === "Loading") {
        tmp = React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.justifyContent("flexStart"),
                        tl: {
                          hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                          tl: {
                            hd: Css.paddingLeft(columnIndex === 0 ? Css.px(40) : Css.px(0)),
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, Belt_List.concat(Styles.shimmer, {
                            hd: Css.width(Css.px(columnIndex === 0 ? 250 : 50)),
                            tl: {
                              hd: Css.height(Css.px(5)),
                              tl: {
                                hd: Css.borderRadius(Styles.Border.radius),
                                tl: /* [] */0
                              }
                            }
                          }))
                }));
      } else if (cell === "NotFound") {
        tmp = null;
      } else {
        exit = 1;
      }
      if (exit === 1) {
        if (isExpanded) {
          var variant$2 = cell.NAME;
          if (variant$2 === "PropertySourceIssues") {
            var match$3 = cell.VAL;
            var sourceId = match$3[2];
            var property = match$3[1];
            var $$event$5 = match$3[0];
            var propertyIssueTypeCount = Belt_Option.mapWithDefaultU(sourceId, 0, (function (sourceId) {
                    return InspectorEventViewModelUtils.Property.getIssueTypeCount(sourceId, property) + InspectorEventViewModelUtils.Property.getNestedIssuesTypeCount(sourceId, property) | 0;
                  }));
            var showDashForZero = Belt_Option.mapWithDefaultU(sourceId, true, (function (sourceId) {
                    return InspectorEventViewModelUtils.$$Event.getTotalCountInt([sourceId], $$event$5) === 0;
                  }));
            tmp = React.createElement(InspectorViewCells.PropertySourceIssuesCell_.make, {
                  propertyIssueTypeCount: propertyIssueTypeCount,
                  showDashForZero: showDashForZero,
                  isActiveCell: Curry._3(isActiveCell, $$event$5, [property.propertyName], -1),
                  onClick: (function (param) {
                      Curry._4(onClick, columnIndex, $$event$5, property, propertyNames$1);
                    }),
                  propertyNames: Belt_Option.getWithDefault(propertyNames$1, [property.propertyName])
                });
          } else if (variant$2 === "PropertyName") {
            var match$4 = cell.VAL;
            var property$1 = match$4[1];
            var $$event$6 = match$4[0];
            tmp = React.createElement(InspectorViewCells.PropertyNameCell_.make, {
                  event: $$event$6,
                  property: property$1,
                  isActiveCell: Curry._3(isActiveCell, $$event$6, propertyNames$1, 0),
                  onClick: (function (param) {
                      Curry._4(onClick, columnIndex, $$event$6, property$1, propertyNames$1);
                    }),
                  onAddToTrackingPlan: (function (param) {
                      Curry._2(onAddPropertyToTrackingPlan, $$event$6, property$1);
                    }),
                  rules: rules,
                  propertyIsExpanded: isPropertyExpanded,
                  mappedDestinations: mappedDestinations,
                  mappedEvents: mappedEvents,
                  mappedProperties: mappedProperties,
                  propertyNames: Belt_Option.getWithDefault(propertyNames$1, [property$1.propertyName])
                });
          } else if (variant$2 === "PropertyVolume") {
            var match$5 = cell.VAL;
            var property$2 = match$5[1];
            var $$event$7 = match$5[0];
            tmp = React.createElement(InspectorViewCells.PropertyVolumeCell.make, {
                  eventCount: InspectorEventViewModelUtils.$$Event.getTotalCountInt(undefined, $$event$7),
                  currentPropertyVolume: InspectorEventViewModelUtils.Property.getTotalCountInt(InspectorEventViewModelUtils.$$Event.getSourceIds($$event$7), property$2),
                  hasNoIssues: InspectorEventViewModelUtils.Property.getIssueTypeCount(undefined, property$2) === 0,
                  isNew: false,
                  isActiveCell: Curry._3(isActiveCell, $$event$7, [property$2.propertyName], 1),
                  onClick: (function (param) {
                      Curry._4(onClick, columnIndex, $$event$7, property$2, propertyNames$1);
                    }),
                  propertyNames: Belt_Option.getWithDefault(propertyNames$1, [property$2.propertyName])
                });
          } else if (variant$2 === "PropertySourceVolume") {
            var match$6 = cell.VAL;
            var sourceId$1 = match$6[2];
            var property$3 = match$6[1];
            var $$event$8 = match$6[0];
            var currentPropertyVolume = Belt_Option.mapU(sourceId$1, (function (sourceId) {
                    return InspectorEventViewModelUtils.Property.getTotalCountInt([sourceId], property$3);
                  }));
            var eventCount = Belt_Option.mapWithDefaultU(sourceId$1, InspectorEventViewModelUtils.$$Event.getTotalCountInt(undefined, $$event$8), (function (sourceId) {
                    return InspectorEventViewModelUtils.$$Event.getTotalCountInt([sourceId], $$event$8);
                  }));
            var showDashForZero$1 = Belt_Option.mapWithDefaultU(sourceId$1, true, (function (sourceId) {
                    return InspectorEventViewModelUtils.Property.getIssueTypeCount(sourceId, property$3) === 0;
                  }));
            tmp = React.createElement(InspectorViewCells.PropertySourceVolumeCell.make, {
                  currentPropertyVolume: currentPropertyVolume,
                  eventCount: eventCount,
                  showDashForZero: showDashForZero$1,
                  hasNoIssues: InspectorEventViewModelUtils.Property.getIssueTypeCount(undefined, property$3) === 0,
                  isActiveCell: Curry._3(isActiveCell, $$event$8, [property$3.propertyName], 1),
                  isNew: false,
                  onClick: (function (param) {
                      Curry._4(onClick, columnIndex, $$event$8, property$3, propertyNames$1);
                    }),
                  propertyNames: Belt_Option.getWithDefault(propertyNames$1, [property$3.propertyName])
                });
          } else {
            var match$7 = cell.VAL;
            var property$4 = match$7[1];
            var $$event$9 = match$7[0];
            tmp = React.createElement(InspectorViewCells.PropertyIssuesCell_.make, {
                  propertyIssueTypeCount: InspectorEventViewModelUtils.Property.getIssueTypeCount(undefined, property$4) + InspectorEventViewModelUtils.Property.getNestedIssuesTypeCount(undefined, property$4) | 0,
                  isActiveCell: Curry._3(isActiveCell, $$event$9, [property$4.propertyName], -1),
                  onClick: (function (param) {
                      Curry._4(onClick, columnIndex, $$event$9, property$4, propertyNames$1);
                    }),
                  propertyNames: Belt_Option.getWithDefault(propertyNames$1, [property$4.propertyName])
                });
          }
        } else {
          tmp = null;
        }
      }
      return React.createElement("div", {
                  className: getRowStyle(row),
                  style: style
                }, tmp);
    });

var make$1 = React.memo(make, (function (prim0, prim1) {
        return ReactWindow.areEqual(prim0, prim1);
      }));

var Cell = {
  make: make$1
};

var orderLabelBase = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.button),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.textTransform("uppercase"),
              tl: {
                hd: Css.textAlign("left"),
                tl: {
                  hd: Css.fontWeight(Styles.FontWeight.semi),
                  tl: {
                    hd: Css.padding(Css.px(0)),
                    tl: {
                      hd: Css.hover({
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: /* [] */0
                          }),
                      tl: {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexShrink(0.0),
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var activeOrderLabel = Curry._1(Css.style, {
      hd: Css.color(Styles.Color.darkBlue),
      tl: {
        hd: Css.fontWeight(Styles.FontWeight.bold),
        tl: /* [] */0
      }
    });

function InspectorViewEventsList__V2$LabelRowButton(Props) {
  var smallOpt = Props.small;
  var label = Props.label;
  var orderBy = Props.orderBy;
  var ordering = Props.ordering;
  var customWidth = Props.customWidth;
  var customPaddingLeftOpt = Props.customPaddingLeft;
  var setOrdering = Props.setOrdering;
  var orderBySourceId = Props.orderBySourceId;
  var small = smallOpt !== undefined ? smallOpt : false;
  var customPaddingLeft = customPaddingLeftOpt !== undefined ? customPaddingLeftOpt : 8;
  var selectedOrder = ordering[0];
  var isSelected = Caml_obj.equal(orderBy, ordering[1]) && Caml_obj.equal(orderBySourceId, ordering[2]);
  return React.createElement("button", {
              className: Curry._1(Css.merge, {
                    hd: orderLabelBase,
                    tl: {
                      hd: Curry._1(Css.style, {
                            hd: Css.width(Css.px(customWidth)),
                            tl: {
                              hd: Css.paddingLeft(Css.px(small ? 0 : customPaddingLeft)),
                              tl: {
                                hd: Css.fontSize(small ? Css.px(9) : Styles.FontSize.tiny),
                                tl: {
                                  hd: Css.color(small ? Styles.Color.light08 : Styles.Color.light12),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }),
                      tl: {
                        hd: isSelected ? activeOrderLabel : Curry._1(Css.style, /* [] */0),
                        tl: /* [] */0
                      }
                    }
                  }),
              onClick: (function (param) {
                  Curry._1(setOrdering, (function (param) {
                          return [
                                  isSelected ? (
                                      selectedOrder === "Descending" ? "Ascending" : "Descending"
                                    ) : (
                                      orderBy === "EventName" ? "Ascending" : "Descending"
                                    ),
                                  orderBy,
                                  orderBySourceId
                                ];
                        }));
                })
            }, label, isSelected ? (
                selectedOrder === "Descending" ? " ↑" : " ↓"
              ) : null);
}

var LabelRowButton = {
  orderLabelBase: orderLabelBase,
  activeOrderLabel: activeOrderLabel,
  make: InspectorViewEventsList__V2$LabelRowButton
};

function InspectorViewEventsList__V2$LabelRow(Props) {
  var rowHeight = Props.rowHeight;
  var getColumnWidth = Props.getColumnWidth;
  var sources = Props.sources;
  var sourceIds = Props.sourceIds;
  var ordering = Props.ordering;
  var setOrdering = Props.setOrdering;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("sticky"),
                    tl: {
                      hd: Css.top(Css.px(0)),
                      tl: {
                        hd: Css.zIndex(2),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.alignItems("center"),
                            tl: {
                              hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light04),
                              tl: {
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.tiny),
                                  tl: {
                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                    tl: {
                                      hd: Css.padding2(Css.px(10), Css.px(0)),
                                      tl: {
                                        hd: Css.height(Css.px(rowHeight)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement(InspectorViewEventsList__V2$LabelRowButton, {
                  label: "Event Name",
                  orderBy: "EventName",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 0),
                  customPaddingLeft: 40,
                  setOrdering: setOrdering
                }), React.createElement(InspectorViewEventsList__V2$LabelRowButton, {
                  label: "Event Volume",
                  orderBy: "EventVolume",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 1),
                  setOrdering: setOrdering
                }), React.createElement(InspectorViewEventsList__V2$LabelRowButton, {
                  label: "Issues",
                  orderBy: "Issues",
                  ordering: ordering,
                  customWidth: Curry._1(getColumnWidth, 2),
                  setOrdering: setOrdering
                }), Belt_Array.mapWithIndexU(sourceIds, (function (index, sourceId) {
                    var volumeCellWidth = Curry._1(getColumnWidth, InspectorViewConfig.fixedColumns + (index << 1) | 0);
                    var issuesCellWidth = Curry._1(getColumnWidth, (InspectorViewConfig.fixedColumns + (index << 1) | 0) + 1 | 0);
                    return React.createElement(React.Fragment, {
                                children: React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.width(Css.px(volumeCellWidth + issuesCellWidth | 0)),
                                            tl: {
                                              hd: Css.paddingLeft(Css.px(8)),
                                              tl: /* [] */0
                                            }
                                          })
                                    }, React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.fontSize(Styles.FontSize.small),
                                                tl: /* [] */0
                                              })
                                        }, Belt_Option.mapWithDefault(Belt_List.getByU(sources, (function (source) {
                                                    return sourceId === source.id;
                                                  })), "Unknown Source", AvoConfig.getSourceName)), React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.paddingTop(Css.px(4)),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(InspectorViewEventsList__V2$LabelRowButton, {
                                              small: true,
                                              label: "Event Volume",
                                              orderBy: "SourceEventVolume",
                                              ordering: ordering,
                                              customWidth: volumeCellWidth,
                                              setOrdering: setOrdering,
                                              orderBySourceId: sourceId
                                            }), React.createElement(InspectorViewEventsList__V2$LabelRowButton, {
                                              small: true,
                                              label: "Issues",
                                              orderBy: "SourceIssues",
                                              ordering: ordering,
                                              customWidth: issuesCellWidth,
                                              setOrdering: setOrdering,
                                              orderBySourceId: sourceId
                                            }))),
                                key: sourceId
                              });
                  })));
}

var LabelRow = {
  make: InspectorViewEventsList__V2$LabelRow
};

function orderEvents(events, param) {
  var orderBySourceId = param[2];
  var orderBy = param[1];
  var order = param[0];
  return Curry._1(InspectorEventViewModel.fromArray, Belt_SortArray.stableSortByU(Curry._1(InspectorEventViewModel.toArray, events), (function (a, b) {
                    if (order === "Ascending") {
                      if (orderBy === "EventVolume") {
                        return Caml.float_compare(a.totalVolume, b.totalVolume);
                      }
                      if (orderBy !== "EventName") {
                        if (orderBy === "SourceEventVolume") {
                          if (orderBySourceId !== undefined) {
                            return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getTotalCountInt([orderBySourceId], a), InspectorEventViewModelUtils.$$Event.getTotalCountInt([orderBySourceId], b));
                          }
                          
                        } else {
                          if (orderBy === "Issues") {
                            return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getIssueTypeCount(undefined, a), InspectorEventViewModelUtils.$$Event.getIssueTypeCount(undefined, b));
                          }
                          if (orderBy === "SourceIssues" && orderBySourceId !== undefined) {
                            return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getIssueTypeCount(orderBySourceId, a), InspectorEventViewModelUtils.$$Event.getIssueTypeCount(orderBySourceId, b));
                          }
                          
                        }
                      }
                      
                    } else if (order === "Descending") {
                      if (orderBy === "EventVolume") {
                        return Caml.float_compare(b.totalVolume, a.totalVolume);
                      }
                      if (orderBy === "EventName") {
                        return Caml.string_compare(b.eventName.toLowerCase(), a.eventName.toLowerCase());
                      }
                      if (orderBy === "SourceEventVolume") {
                        if (orderBySourceId !== undefined) {
                          return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getTotalCountInt([orderBySourceId], b), InspectorEventViewModelUtils.$$Event.getTotalCountInt([orderBySourceId], a));
                        }
                        
                      } else {
                        if (orderBy === "Issues") {
                          return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getIssueTypeCount(undefined, b), InspectorEventViewModelUtils.$$Event.getIssueTypeCount(undefined, a));
                        }
                        if (orderBy === "SourceIssues" && orderBySourceId !== undefined) {
                          return Caml.int_compare(InspectorEventViewModelUtils.$$Event.getIssueTypeCount(orderBySourceId, b), InspectorEventViewModelUtils.$$Event.getIssueTypeCount(orderBySourceId, a));
                        }
                        
                      }
                    }
                    return Caml.string_compare(a.eventName.toLowerCase(), b.eventName.toLowerCase());
                  })));
}

function filterEvents(events, filter) {
  var isQuoted = filter.startsWith("\"") && filter.endsWith("\"");
  if (isQuoted) {
    return Curry._2(InspectorEventViewModel.keep, events, (function (param) {
                  return "\"" + param.eventName + "\"" === filter;
                }));
  }
  if (filter === "") {
    return events;
  }
  var lowerCaseFilter = filter.toLowerCase();
  return Curry._2(InspectorEventViewModel.keep, events, (function (param) {
                var eventName = param.eventName;
                if (Fuzzaldrin.score(eventName, filter) > 0.1) {
                  return true;
                } else {
                  return eventName.toLowerCase().includes(lowerCaseFilter);
                }
              }));
}

function buildNestedProperties($$event, property, expandedProperties, parentPropertyNames) {
  var currentPropertyNames = Belt_Array.concat(parentPropertyNames, [property.propertyName]);
  if (property.nestedProperties.length !== 0 && Belt_Set.has(expandedProperties, currentPropertyNames)) {
    return Belt_Array.concatMany(Belt_Array.mapU(property.nestedProperties, (function (nestedProperty) {
                      var nestedPropertyNames = Belt_Array.concat(currentPropertyNames, [nestedProperty.propertyName]);
                      return Belt_Array.concat([{
                                    NAME: "nestedProperty",
                                    VAL: [
                                      $$event,
                                      nestedProperty,
                                      nestedPropertyNames
                                    ]
                                  }], buildNestedProperties($$event, nestedProperty, expandedProperties, currentPropertyNames));
                    })));
  } else {
    return [];
  }
}

function buildList(events, loadingStatus, expandedEvents, expandedProperties) {
  return Belt_Array.concat(Belt_Array.concatMany(Curry._2(InspectorEventViewModel.mapToArray, events, (function ($$event) {
                        return Belt_Array.concat([{
                                      NAME: "event",
                                      VAL: $$event
                                    }], Belt_SetString.has(expandedEvents, $$event.eventName) ? Belt_Array.concatMany(Belt_Array.mapU($$event.properties, (function (property) {
                                                return Belt_Array.concat([{
                                                              NAME: "property",
                                                              VAL: [
                                                                $$event,
                                                                property
                                                              ]
                                                            }], buildNestedProperties($$event, property, expandedProperties, []));
                                              }))) : []);
                      }))), typeof loadingStatus === "object" ? [] : ["loading"]);
}

function getColumnWidth(columnIndex) {
  if (columnIndex > 0) {
    if (columnIndex >= 3) {
      return 90;
    } else {
      return 120;
    }
  } else if (columnIndex >= 0) {
    return 400;
  } else {
    return 90;
  }
}

function getParsedUrlQuery(param) {
  return QueryString.parse(decodeURI(window.location.search.replace("?", "")));
}

function updateUrlQueryEffect(updates) {
  $$Window.$$History.replaceStateSilent("?" + encodeURI(QueryString.stringify(Object.assign(Object.assign({}, getParsedUrlQuery(undefined)), updates))));
}

function useQueryOrder(param) {
  var defaultOrder = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(getParsedUrlQuery(undefined).order), InspectorViewModel.orderFromJs), "Ascending");
  var defaultOrderBy = Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_option.nullable_to_opt(getParsedUrlQuery(undefined).orderBy), InspectorViewModel.orderByFromJs), "EventName");
  var defaultSourceId = getParsedUrlQuery(undefined).sourceId;
  var defaultSourceId$1 = (defaultSourceId == null) ? undefined : Caml_option.some(defaultSourceId);
  var match = React.useState(function () {
        return [
                defaultOrder,
                defaultOrderBy,
                defaultSourceId$1
              ];
      });
  var match$1 = match[0];
  var sourceId = match$1[2];
  var orderBy = match$1[1];
  var order = match$1[0];
  React.useEffect((function () {
          updateUrlQueryEffect({
                order: InspectorViewModel.orderToJs(order),
                orderBy: InspectorViewModel.orderByToJs(orderBy),
                sourceId: Js_null_undefined.fromOption(sourceId)
              });
        }), [
        order,
        orderBy,
        sourceId
      ]);
  return [
          [
            order,
            orderBy,
            sourceId
          ],
          match[1]
        ];
}

function stringifyActiveCell(x) {
  if (x === undefined) {
    return ;
  }
  var propNames = x.propertyNames;
  var eventName = x.eventName;
  if (propNames !== undefined) {
    return "" + eventName + ",[" + Belt_Array.joinWith(propNames, ";", (function (i) {
                  return i;
                })) + "]," + String(x.column) + "";
  } else {
    return "" + eventName + ",," + String(x.column) + "";
  }
}

function useActiveCellQuery(param) {
  var stringToActiveCell = function (activeCellString) {
    if (activeCellString === undefined) {
      return ;
    }
    var match = Js_string.split(",", activeCellString);
    if (match.length !== 3) {
      return ;
    }
    var eventName = match[0];
    var propNames = match[1];
    var y = match[2];
    if (!Number.isNaN(Number(y))) {
      return {
              eventName: eventName,
              propertyNames: propNames.split(";") === [""] ? undefined : propNames.split(";"),
              column: Number(y) | 0
            };
    }
    
  };
  return Hooks.useUrlQueryParam("selected", stringToActiveCell, stringifyActiveCell);
}

function inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated(orderBy) {
  if (orderBy === "EventName") {
    return "OrderByEventName";
  } else if (orderBy === "SourceEventVolume") {
    return "OrderBySourceEventVolume";
  } else if (orderBy === "Issues") {
    return "OrderByIssues";
  } else if (orderBy === "SourceIssues") {
    return "OrderBySourceIssues";
  } else {
    return "OrderByEventVolume";
  }
}

function currentInspectorFilters(model, order, orderBy, sourceId, eventNameFilter, timeWindow, environment) {
  return {
          inspectorEventNameFilter: eventNameFilter === "" ? undefined : eventNameFilter,
          inspectorOrder: order,
          inspectorColumnOrderedBy: orderBy,
          inspectorSourceIdOrderedBy: sourceId,
          inspectorTimeWindow: timeWindow,
          inspectorEnvironment: environment,
          inspectorSourceNameOrderedBy: Belt_Option.map(sourceId, (function (sourceId) {
                  return AvoConfig.getSourceNameById(model, sourceId);
                }))
        };
}

function selectedCellIndexToAnalyticsInspectorColumn(selectedCellIndex) {
  switch (selectedCellIndex) {
    case 0 :
        return "EventName";
    case 1 :
        return "EventVolume";
    case 2 :
        return "Issues";
    default:
      if (selectedCellIndex % 2 === 0) {
        return "SourceEventVolume";
      } else {
        return "SourceIssues";
      }
  }
}

function currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan) {
  return {
          inspectorEventNameSelected: Belt_Option.map(activeCell, (function (cell) {
                  return cell.eventName;
                })),
          inspectorColumnSelected: Belt_Option.map(activeCell, (function (cell) {
                  return selectedCellIndexToAnalyticsInspectorColumn(cell.column);
                })),
          inspectorPropertyNameSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  return Belt_Option.map(cell.propertyNames, (function (propNames) {
                                return Belt_Array.joinWith(propNames, ".", (function (i) {
                                              return i;
                                            }));
                              }));
                })),
          inspectorSourceIdSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  var sourceIndex = (cell.column - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
                  return Belt_Array.get(sourceIds, sourceIndex);
                })),
          inspectorSourceNameSelected: Belt_Option.flatMap(activeCell, (function (cell) {
                  var sourceIndex = (cell.column - InspectorViewConfig.fixedColumns | 0) / 2 | 0;
                  return Belt_Option.flatMap(Belt_Array.get(sourceIds, sourceIndex), (function (sourceId) {
                                return AvoConfig.sourceIdToName(sourceId, sources);
                              }));
                })),
          numPropertiesNotFoundOnEventInTrackingPlan: numPropertiesNotFoundOnEventInTrackingPlan
        };
}

function inspectorFilterTypeUpdated(param, param$1) {
  if (Caml_obj.notequal(param$1[0], param[0])) {
    return "SearchForEvent";
  }
  var orderBy = param$1[1];
  if (orderBy !== param[1] || Caml_obj.notequal(param$1[2], param[2])) {
    return inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated(orderBy);
  } else if (Caml_obj.notequal(param$1[3], param[3])) {
    return "Environment";
  } else if (Caml_obj.notequal(param$1[4], param[4])) {
    return "TimeWindow";
  } else {
    return ;
  }
}

function getNumPropertiesNotFoundOnEventInTrackingPlan(activeInspectorEvent, mappedEvents, rules, mappedProperties) {
  if (activeInspectorEvent === undefined) {
    return ;
  }
  var modelEvent = InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedEvents, rules, activeInspectorEvent.eventName);
  return Belt_Option.map(modelEvent, (function ($$event) {
                var propertiesSet = Belt_MapString.fromArray(Belt_List.toArray(Belt_List.keepMap($$event.directPropertyRefs, (function (property) {
                                if (property.TAG === /* PropertyRef */0) {
                                  return Belt_Option.map(Curry._2(TrackingPlanMappedModel.Properties.get, mappedProperties, property._0.id), (function (property) {
                                                return [
                                                        property.name,
                                                        property
                                                      ];
                                              }));
                                }
                                var p = property._0;
                                return [
                                        p.name,
                                        p
                                      ];
                              }))));
                return Belt_Array.keepU(activeInspectorEvent.properties, (function (inspectorProp) {
                              return Belt_MapString.get(propertiesSet, inspectorProp.propertyName) === undefined;
                            })).length;
              }));
}

function useRegisterHooksForTracking(model, order, orderBy, sourceId, activeCell, activeInspectorEvent, filterByEventName, timeWindow, environment, events, debounceFilterByEventName, sourceIds, sources, initialShareId, generatedShareId, utmMedium, utmCampaign, mappedEvents, rules) {
  var match = Hooks.usePrevious2([
        events,
        sourceIds
      ]);
  var prevSourceIds = match[1];
  var prevEvents = match[0];
  var mappedProperties = ModelStore.Mapped.useProperties(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var numPropertiesNotFoundOnEventInTrackingPlan = getNumPropertiesNotFoundOnEventInTrackingPlan(activeInspectorEvent, mappedEvents, rules, mappedProperties);
  React.useEffect((function () {
          var justLoadedLastArrayOfTwoAndBothAreNotEmpty = Curry._1(InspectorEventViewModel.size, prevEvents) === 0 && Curry._1(InspectorEventViewModel.size, events) > 0 && Caml_obj.notequal(sourceIds, []) || Caml_obj.equal(prevSourceIds, []) && Caml_obj.notequal(sourceIds, []) && Curry._1(InspectorEventViewModel.size, events) > 0;
          var numInspectorEventsNotFoundInTrackingPlan = Curry._1(InspectorEventViewModel.size, Curry._2(InspectorEventViewModel.keep, events, (function (param) {
                      return Belt_Option.isNone(InspectorIssuesGenerator.getTrackingPlanEvent_mappedModel(mappedEvents, rules, param.eventName));
                    })));
          var proportionOfInspectorEventsNotFoundInTrackingPlan = numInspectorEventsNotFoundInTrackingPlan / Curry._1(InspectorEventViewModel.size, events);
          if (justLoadedLastArrayOfTwoAndBothAreNotEmpty) {
            var inspectorEventsViewFilters = currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment);
            var inspectorTablePosition = currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan);
            AnalyticsRe.inspectorEventsViewed(inspectorEventsViewFilters.inspectorEventNameFilter, inspectorEventsViewFilters.inspectorOrder, inspectorEventsViewFilters.inspectorColumnOrderedBy, inspectorEventsViewFilters.inspectorSourceIdOrderedBy, inspectorEventsViewFilters.inspectorTimeWindow, inspectorEventsViewFilters.inspectorEnvironment, inspectorEventsViewFilters.inspectorSourceNameOrderedBy, inspectorTablePosition.inspectorEventNameSelected, inspectorTablePosition.inspectorColumnSelected, inspectorTablePosition.inspectorPropertyNameSelected, inspectorTablePosition.inspectorSourceIdSelected, inspectorTablePosition.inspectorSourceNameSelected, inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, "EventsList", Curry._1(InspectorEventViewModel.size, events), initialShareId, generatedShareId, utmMedium, utmCampaign, numInspectorEventsNotFoundInTrackingPlan, proportionOfInspectorEventsNotFoundInTrackingPlan, schemaBundle.branchId, schemaBundle.schemaId, schemaBundle.schemaId, Curry._1(InspectorEventViewModel.size, events) > 0 ? "EventsReceived" : undefined);
            if (environment === "Development") {
              AnalyticsRe.updateNumInspectorDevelopmentSources(schemaBundle.schemaId, sourceIds.length);
            } else if (environment === "Production") {
              AnalyticsRe.updateNumInspectorProductionSources(schemaBundle.schemaId, sourceIds.length);
            } else {
              AnalyticsRe.updateNumInspectorStagingSources(schemaBundle.schemaId, sourceIds.length);
            }
          }
          
        }), [
        events,
        sourceIds
      ]);
  var match$1 = Hooks.usePrevious5([
        debounceFilterByEventName,
        orderBy,
        order,
        environment,
        timeWindow
      ]);
  var prevTimeWindow = match$1[4];
  var prevEnvironment = match$1[3];
  var prevOrder = match$1[2];
  var prevOrderBy = match$1[1];
  var prevDebounceFilterByEventName = match$1[0];
  React.useEffect((function () {
          var maybeInspectorFilterTypeUpdated = inspectorFilterTypeUpdated([
                prevDebounceFilterByEventName,
                prevOrderBy,
                prevOrder,
                prevEnvironment,
                prevTimeWindow
              ], [
                debounceFilterByEventName,
                orderBy,
                order,
                environment,
                timeWindow
              ]);
          var inspectorEventsViewFilters = currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment);
          if (maybeInspectorFilterTypeUpdated !== undefined) {
            AnalyticsRe.inspectorFilterUpdated(inspectorEventsViewFilters.inspectorEventNameFilter, inspectorEventsViewFilters.inspectorOrder, inspectorEventsViewFilters.inspectorColumnOrderedBy, inspectorEventsViewFilters.inspectorSourceIdOrderedBy, inspectorEventsViewFilters.inspectorTimeWindow, inspectorEventsViewFilters.inspectorEnvironment, inspectorEventsViewFilters.inspectorSourceNameOrderedBy, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, maybeInspectorFilterTypeUpdated, schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [
        debounceFilterByEventName,
        orderBy,
        order,
        environment,
        timeWindow
      ]);
  var prevActiveCell = Hooks.usePrevious1(activeCell);
  var inspectorEventsViewFilters = currentInspectorFilters(model, order, orderBy, sourceId, filterByEventName, timeWindow, environment);
  var inspectorTablePosition = currentInspectorTablePosition(activeCell, sourceIds, sources, numPropertiesNotFoundOnEventInTrackingPlan);
  React.useEffect((function () {
          if (Caml_obj.notequal(activeCell, prevActiveCell)) {
            AnalyticsRe.inspectorEventsNavigated(inspectorEventsViewFilters.inspectorEventNameFilter, inspectorEventsViewFilters.inspectorOrder, inspectorEventsViewFilters.inspectorColumnOrderedBy, inspectorEventsViewFilters.inspectorSourceIdOrderedBy, inspectorEventsViewFilters.inspectorTimeWindow, inspectorEventsViewFilters.inspectorEnvironment, inspectorEventsViewFilters.inspectorSourceNameOrderedBy, inspectorTablePosition.inspectorEventNameSelected, inspectorTablePosition.inspectorColumnSelected, inspectorTablePosition.inspectorPropertyNameSelected, inspectorTablePosition.inspectorSourceIdSelected, inspectorTablePosition.inspectorSourceNameSelected, inspectorTablePosition.numPropertiesNotFoundOnEventInTrackingPlan, schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, Curry._1(InspectorEventViewModel.size, events), schemaBundle.branchId, schemaBundle.schemaId);
          }
          
        }), [activeCell]);
}

function useShareId(param) {
  var initialShareId = React.useMemo((function () {
          return Js_dict.get(Hooks.getQueryParams(undefined), "shareId");
        }), []);
  var generatedShareId = React.useMemo((function () {
          if (initialShareId !== undefined) {
            return ;
          } else {
            return Nanoid.nanoid();
          }
        }), []);
  React.useEffect((function () {
          if (generatedShareId !== undefined) {
            var params = Hooks.getQueryParams(undefined);
            params["shareId"] = generatedShareId;
            $$Window.$$History.replaceStateSilent("?" + encodeURI(QueryString.stringify(params)));
          }
          
        }), []);
  return [
          initialShareId,
          generatedShareId
        ];
}

function InspectorViewEventsList__V2(Props) {
  var timeWindow = Props.timeWindow;
  var environment = Props.environment;
  var events = Props.events;
  var loadingStatus = Props.loadingStatus;
  var filterByEventName = Props.filterByEventName;
  var mappedEvents = Props.mappedEvents;
  var rules = Props.rules;
  var globalSend = GlobalSendContext.use(undefined);
  var model = ModelStore.useModel(undefined);
  var mappedProperties = ModelStore.Mapped.useProperties(undefined);
  var mappedSources = ModelStore.Mapped.useSources(undefined);
  var mappedDestinations = ModelStore.Mapped.useDestinations(undefined);
  var match = Router.QueryParams.useStringKey("utm_medium");
  var match$1 = Router.QueryParams.useStringKey("utm_campaign");
  var match$2 = useQueryOrder(undefined);
  var setOrdering = match$2[1];
  var ordering = match$2[0];
  var match$3 = React.useMemo((function () {
          return ordering;
        }), [ordering]);
  var sourceId = match$3[2];
  var orderBy = match$3[1];
  var order = match$3[0];
  var match$4 = useShareId(undefined);
  var domainEvents = DomainStore.useDomainEvents(undefined);
  var debounceFilterByEventName = Hooks.useDebounced((function (param) {
          return filterByEventName;
        }), 500);
  var orderedEvents = React.useMemo((function () {
          return orderEvents(events, ordering);
        }), [
        events,
        order,
        orderBy,
        sourceId
      ]);
  var filteredOrderedEvents = React.useMemo((function () {
          return filterEvents(orderedEvents, filterByEventName);
        }), [
        orderedEvents,
        debounceFilterByEventName
      ]);
  var match$5 = useActiveCellQuery(undefined);
  var setActiveCell = match$5[1];
  var activeCell = match$5[0];
  var match$6 = React.useState(function () {
        if (activeCell !== undefined && activeCell.propertyNames !== undefined) {
          return Belt_SetString.fromArray([activeCell.eventName]);
        }
        
      });
  var setIsExpanded = match$6[1];
  var isExpanded = match$6[0];
  var match$7 = React.useState(function () {
        return propertiesSet;
      });
  var setPropertyIsExpanded = match$7[1];
  var expandedProperties = match$7[0];
  var match$8 = React.useState(function () {
        return buildList(filteredOrderedEvents, loadingStatus, isExpanded, expandedProperties);
      });
  var updateList = match$8[1];
  var list = match$8[0];
  React.useEffect((function () {
          Curry._1(updateList, (function (param) {
                  return buildList(filteredOrderedEvents, loadingStatus, isExpanded, expandedProperties);
                }));
        }), [
        filteredOrderedEvents,
        loadingStatus,
        isExpanded,
        expandedProperties
      ]);
  var rowCount = list.length;
  var sourceIds = React.useMemo((function () {
          return Belt_SetString.toArray(Belt_Array.reduceU(Belt_Array.concatMany(Belt_Array.mapU(list, (function (row) {
                                    if (typeof row === "object") {
                                      if (row.NAME === "event") {
                                        return InspectorEventViewModelUtils.$$Event.getSourceIds(row.VAL);
                                      } else {
                                        return [];
                                      }
                                    } else {
                                      return [];
                                    }
                                  }))), undefined, Belt_SetString.add));
        }), [list]);
  useRegisterHooksForTracking(model, order, orderBy, sourceId, activeCell, Belt_Option.flatMap(activeCell, (function (cell) {
              return Curry._2(InspectorEventViewModel.get, events, cell.eventName);
            })), filterByEventName, timeWindow, environment, events, debounceFilterByEventName, sourceIds, model.sources, match$4[0], match$4[1], match.value, match$1.value, mappedEvents, rules);
  var columnCount = InspectorViewConfig.fixedColumns + (sourceIds.length << 1) | 0;
  var activeCell_ = React.useMemo((function () {
          return Belt_Option.map(activeCell, (function (param) {
                        return {
                                eventName: param.eventName,
                                propertyNames: param.propertyNames,
                                column: Math.min(param.column, Math.max(columnCount - 1 | 0, 0))
                              };
                      }));
        }), [activeCell]);
  var cellData = React.useMemo((function () {
          return {
                  activeCell: activeCell_,
                  isExpanded: isExpanded,
                  propertyIsExpanded: expandedProperties,
                  list: list,
                  sources: sourceIds,
                  model: model,
                  columnCount: columnCount
                };
        }), [
        list,
        model,
        activeCell_,
        expandedProperties
      ]);
  var sidebarCell = React.useMemo((function () {
          return Belt_Option.flatMap(cellData.activeCell, (function (param) {
                        var column = param.column;
                        var propertyNames = param.propertyNames;
                        var eventName = param.eventName;
                        var row = Belt_Option.map(Belt_Option.flatMap(Belt_Array.getByU(Belt_Array.mapWithIndexU(list, (function (index, row) {
                                            if (typeof row !== "object") {
                                              return ;
                                            }
                                            var variant = row.NAME;
                                            if (variant === "nestedProperty") {
                                              var match = row.VAL;
                                              return [
                                                      index,
                                                      match[0].eventName,
                                                      match[2]
                                                    ];
                                            }
                                            if (variant === "event") {
                                              return [
                                                      index,
                                                      row.VAL.eventName,
                                                      undefined
                                                    ];
                                            }
                                            if (variant !== "property") {
                                              return ;
                                            }
                                            var match$1 = row.VAL;
                                            return [
                                                    index,
                                                    match$1[0].eventName,
                                                    [match$1[1].propertyName]
                                                  ];
                                          })), (function (item) {
                                        if (item !== undefined && item[1] === eventName) {
                                          return Caml_obj.equal(item[2], propertyNames);
                                        } else {
                                          return false;
                                        }
                                      })), (function (maybeMaybeRow) {
                                    return maybeMaybeRow;
                                  })), (function (param) {
                                return param[0];
                              }));
                        return Belt_Option.map(row, (function (row) {
                                      return InspectorViewSelectors.getCell_(list, sourceIds, row, column);
                                    }));
                      }));
        }), [
        activeCell,
        list,
        sourceIds
      ]);
  var match$9 = React.useState(function () {
        return false;
      });
  var setListIsMounted = match$9[1];
  var listIsMounted = match$9[0];
  var tmp;
  if (typeof loadingStatus === "object") {
    var requestConfig = loadingStatus.VAL;
    tmp = React.createElement(ReactVirtualizedAutoSizer, {
          children: (function (size) {
              return React.createElement(ReactWindow.VariableSizeGrid, {
                          width: size.width,
                          height: size.height,
                          columnCount: columnCount,
                          columnWidth: getColumnWidth,
                          rowCount: rowCount,
                          rowHeight: (function (rowIndex) {
                              var row = Belt_Option.getWithDefault(Belt_Array.get(list, rowIndex), "loading");
                              if (typeof row !== "object") {
                                return InspectorViewConfig.eventRowHeight;
                              }
                              var variant = row.NAME;
                              if (variant === "error" || variant === "event") {
                                return InspectorViewConfig.eventRowHeight;
                              } else {
                                return InspectorViewConfig.propertyRowHeight;
                              }
                            }),
                          itemData: cellData,
                          overscanColumnCount: 10,
                          overscanRowCount: 100,
                          ref: (function (domRef) {
                              if (!listIsMounted) {
                                Curry._1(setListIsMounted, (function (param) {
                                        return true;
                                      }));
                                var match = cellData.activeCell;
                                if (match === undefined) {
                                  return ;
                                }
                                var propertyNames = match.propertyNames;
                                var eventName = match.eventName;
                                var maybeRowIndex = list.findIndex(function (row) {
                                      if (propertyNames === undefined) {
                                        if (typeof row === "object" && row.NAME === "event") {
                                          return row.VAL.eventName === eventName;
                                        } else {
                                          return false;
                                        }
                                      }
                                      if (typeof row !== "object") {
                                        return false;
                                      }
                                      if (row.NAME !== "property") {
                                        return false;
                                      }
                                      var match = row.VAL;
                                      if (match[0].eventName === eventName) {
                                        return match[1].propertyName === Belt_Option.getWithDefault(Belt_Array.get(propertyNames, 0), "");
                                      } else {
                                        return false;
                                      }
                                    });
                                if (maybeRowIndex !== -1) {
                                  var columnIndex = match.column;
                                  return Belt_Option.forEach((domRef == null) ? undefined : Caml_option.some(domRef), (function (gridRef) {
                                                gridRef.scrollToItem({
                                                      align: "start",
                                                      columnIndex: columnIndex,
                                                      rowIndex: maybeRowIndex
                                                    });
                                              }));
                                } else {
                                  return ;
                                }
                              }
                              
                            }),
                          innerElementType: React.forwardRef(function (props, reactRef) {
                                return React.cloneElement(React.createElement("div", {
                                                ref: reactRef,
                                                style: props.style
                                              }, React.createElement(InspectorViewEventsList__V2$LabelRow, {
                                                    rowHeight: 51,
                                                    getColumnWidth: getColumnWidth,
                                                    sources: model.sources,
                                                    sourceIds: sourceIds,
                                                    ordering: ordering,
                                                    setOrdering: setOrdering
                                                  }), React.createElement("div", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.position("relative"),
                                                          tl: /* [] */0
                                                        })
                                                  }, props.children)), []);
                              }),
                          children: (function (cell) {
                              return React.createElement(make$1, {
                                          environment: environment,
                                          rowIndex: cell.rowIndex,
                                          columnIndex: cell.columnIndex,
                                          style: cell.style,
                                          data: cell.data,
                                          onClick: (function (column, $$event, property, propertyNames) {
                                              Curry._1(setActiveCell, (function (param) {
                                                      return {
                                                              eventName: $$event.eventName,
                                                              propertyNames: propertyNames,
                                                              column: column
                                                            };
                                                    }));
                                              if (property !== undefined) {
                                                if (column !== 0 || property.nestedProperties.length === 0) {
                                                  return ;
                                                } else {
                                                  return Belt_Option.forEach(propertyNames, (function (propertyNames) {
                                                                Curry._1(setPropertyIsExpanded, (function (expanded) {
                                                                        if (Belt_Set.has(expanded, propertyNames)) {
                                                                          return Belt_Set.remove(expanded, propertyNames);
                                                                        } else {
                                                                          return Belt_Set.add(expanded, propertyNames);
                                                                        }
                                                                      }));
                                                              }));
                                                }
                                              } else if (column !== 0) {
                                                return ;
                                              } else {
                                                return Curry._1(setIsExpanded, (function (expanded) {
                                                              if (Belt_SetString.has(isExpanded, $$event.eventName)) {
                                                                return Belt_SetString.remove(expanded, $$event.eventName);
                                                              } else {
                                                                return Belt_SetString.add(expanded, $$event.eventName);
                                                              }
                                                            }));
                                              }
                                            }),
                                          onAddEventToTrackingPlan: (function ($$event) {
                                              var importModel = ImportReview__V2__Utils.toImportModel((function (prim) {
                                                      return Nanoid.nanoid();
                                                    }), model, mappedProperties, mappedSources, [$$event.eventShape], "AddOnly", domainEvents);
                                              Curry._1(globalSend, {
                                                    TAG: /* OpenModal */4,
                                                    _0: {
                                                      NAME: "ImportReview",
                                                      VAL: [
                                                        importModel,
                                                        "AddOnly",
                                                        "InspectorEventsTableRow"
                                                      ]
                                                    }
                                                  });
                                            }),
                                          onAddPropertyToTrackingPlan: (function (param, param$1) {
                                              
                                            }),
                                          mappedEvents: mappedEvents,
                                          rules: rules,
                                          mappedProperties: mappedProperties,
                                          mappedDestinations: mappedDestinations,
                                          requestId: Curry._1(InspectorEventsStore.$$Request.Utils.makeRequestId, requestConfig)
                                        });
                            })
                        });
            })
        });
  } else {
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.height(Css.px(InspectorViewConfig.eventRowHeight)),
                    tl: {
                      hd: Css.borderBottom(Css.px(1), "solid", Styles.Color.light02),
                      tl: {
                        hd: Css.paddingLeft(Css.px(40)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement("div", {
              className: Curry._1(Css.style, Belt_List.concat(Styles.shimmer, {
                        hd: Css.width(Css.px(250)),
                        tl: {
                          hd: Css.height(Css.px(5)),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: /* [] */0
                          }
                        }
                      }))
            }));
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.height(Css.pct(100.0)),
                      tl: {
                        hd: Css.overflow("auto"),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.width(Css.pct(100)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.white),
                            tl: /* [] */0
                          }
                        }
                      })
                }, tmp), React.createElement(InspectorViewContextSidebar__V2.make, {
                  cell: sidebarCell,
                  modelSources: model.sources,
                  modelEvents: mappedEvents,
                  rules: rules,
                  modelProperties: mappedProperties,
                  modelDestinations: mappedDestinations
                }));
}

var EventsByName;

var PropertiesByName;

var make$2 = InspectorViewEventsList__V2;

export {
  EventsByName ,
  PropertiesByName ,
  ExpandedEventsSet ,
  ExpandedPropertyNamesSet ,
  propertiesSet ,
  isRowExpanded ,
  isPropertyRowExpanded ,
  makeCellKey ,
  cellMemo ,
  getRowStyle ,
  Cell ,
  LabelRowButton ,
  LabelRow ,
  orderEvents ,
  filterEvents ,
  buildNestedProperties ,
  buildList ,
  getColumnWidth ,
  getParsedUrlQuery ,
  updateUrlQueryEffect ,
  useQueryOrder ,
  stringifyActiveCell ,
  useActiveCellQuery ,
  inspectorViewModelOrderByToAnalyticsInspectorFilterTypeUpdated ,
  currentInspectorFilters ,
  selectedCellIndexToAnalyticsInspectorColumn ,
  currentInspectorTablePosition ,
  inspectorFilterTypeUpdated ,
  getNumPropertiesNotFoundOnEventInTrackingPlan ,
  useRegisterHooksForTracking ,
  useShareId ,
  make$2 as make,
}
/* ExpandedEventsSet Not a pure module */
