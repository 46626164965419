// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PropertyUtils from "../PropertyUtils.mjs";

function oneLine(overrideType) {
  var absenceOverride;
  var allowedValues;
  var regexValidation;
  if (!overrideType) {
    return "Excluded from this variant";
  }
  var match = overrideType._0;
  var match$1 = match.pinnedValue;
  if (match$1) {
    var literal = match$1._0;
    if (literal !== undefined) {
      return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
    }
    var absenceOverride$1 = match.absence;
    var exit = 0;
    if (absenceOverride$1 || match.allowedValues) {
      exit = 2;
    } else {
      if (!match.regexValidation) {
        return "Pinned value removed on this variant";
      }
      exit = 2;
    }
    if (exit === 2) {
      absenceOverride = absenceOverride$1;
      allowedValues = match.allowedValues;
      regexValidation = match.regexValidation;
    }
    
  } else {
    var absenceOverride$2 = match.absence;
    var exit$1 = 0;
    if (absenceOverride$2 || match.allowedValues) {
      exit$1 = 2;
    } else {
      if (!match.regexValidation) {
        return "Included in this variant";
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      absenceOverride = absenceOverride$2;
      allowedValues = match.allowedValues;
      regexValidation = match.regexValidation;
    }
    
  }
  var absenceDiffString;
  if (absenceOverride) {
    var tmp;
    switch (absenceOverride._0) {
      case /* AlwaysSent */0 :
          tmp = "always sent";
          break;
      case /* SometimesSent */1 :
          tmp = "sometimes sent";
          break;
      case /* NeverSent */2 :
          tmp = "never sent";
          break;
      
    }
    absenceDiffString = "presence set to " + tmp + "";
  } else {
    absenceDiffString = undefined;
  }
  var regexDiffString;
  if (regexValidation) {
    var regex = regexValidation._0;
    regexDiffString = regex !== undefined ? "regex set to \"" + regex.regex + "\"" : "regex override removed";
  } else {
    regexDiffString = undefined;
  }
  var allowedValuesDiffString = allowedValues ? "allowed values override added" : undefined;
  var allDiffStrings = Belt_Array.keepMap([
        absenceDiffString,
        regexDiffString,
        allowedValuesDiffString
      ], (function (x) {
          return x;
        }));
  var allDiffLabel = allDiffStrings.join(", ");
  return "" + allDiffLabel.charAt(0).toUpperCase() + "" + allDiffLabel.slice(1) + " on this variant";
}

function includeExcludeOverride(overrideType) {
  if (overrideType) {
    return "Included in this variant";
  } else {
    return "Excluded from this variant";
  }
}

function pinOverride(overrideType) {
  if (!overrideType) {
    return "";
  }
  var match = overrideType._0.pinnedValue;
  if (!match) {
    return "No pinned value override on this variant";
  }
  var literal = match._0;
  if (literal !== undefined) {
    return "Pinned to " + PropertyUtils.printPinnedValue(literal) + " on this variant";
  } else {
    return "Pinned value override removed on this variant";
  }
}

function absenceOverride(overrideType) {
  if (!overrideType) {
    return "";
  }
  var absenceOverride$1 = overrideType._0.absence;
  if (!absenceOverride$1) {
    return "No presence override on this variant";
  }
  var tmp;
  switch (absenceOverride$1._0) {
    case /* AlwaysSent */0 :
        tmp = "Always Sent";
        break;
    case /* SometimesSent */1 :
        tmp = "Sometimes Sent";
        break;
    case /* NeverSent */2 :
        tmp = "Never Sent";
        break;
    
  }
  return "Presence override set to " + tmp + " on this variant";
}

function regexOverride(overrideType) {
  if (!overrideType) {
    return "";
  }
  var match = overrideType._0.regexValidation;
  if (!match) {
    return "No regex override on this variant";
  }
  var regex = match._0;
  if (regex !== undefined) {
    return "Regex set to \"" + regex.regex + "\" on this variant";
  } else {
    return "Regex override removed on this variant";
  }
}

export {
  oneLine ,
  includeExcludeOverride ,
  pinOverride ,
  absenceOverride ,
  regexOverride ,
}
/* PropertyUtils Not a pure module */
