// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Zustand from "../Zustand.mjs";
import * as Zustand$1 from "zustand";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as AvoEngagement__Types from "./AvoEngagement__Types.mjs";
import * as AvoEngagement__Content__CreateMoreTour from "./AvoEngagement__Content__CreateMoreTour.mjs";
import * as AvoEngagement__Content__DemoBranchTour from "./AvoEngagement__Content__DemoBranchTour.mjs";
import * as AvoEngagement__Content__SetUpAlertsTour from "./AvoEngagement__Content__SetUpAlertsTour.mjs";
import * as AvoEngagement__Content__InspectorIssuesTour from "./AvoEngagement__Content__InspectorIssuesTour.mjs";
import * as AvoEngagement__Content__HowToSaveViewAndGetAlertsTour from "./AvoEngagement__Content__HowToSaveViewAndGetAlertsTour.mjs";

var emptyBlock_transitions = [];

var emptyBlock_fields = AvoEngagement__Types.Fields.empty;

var emptyBlock = {
  id: "empty-block",
  transitions: emptyBlock_transitions,
  fields: emptyBlock_fields
};

var emptyEngagement_blocks = AvoEngagement__Types.Blocks.empty;

var emptyEngagement = {
  id: "empty-engagement",
  version: 0,
  rootId: "no-block",
  blocks: emptyEngagement_blocks
};

var StoreConfig = {};

var ZustandStore = Zustand.MakeStore(StoreConfig);

var store = Zustand$1.create(function (set, _get) {
      return {
              engagements: Curry._1(AvoEngagement__Types.Engagements.fromArray, [
                    AvoEngagement__Content__DemoBranchTour.content,
                    AvoEngagement__Content__InspectorIssuesTour.content,
                    AvoEngagement__Content__SetUpAlertsTour.content,
                    AvoEngagement__Content__CreateMoreTour.content,
                    AvoEngagement__Content__HowToSaveViewAndGetAlertsTour.content
                  ]),
              startEngagement: (function (startFromBlockId, engagementId) {
                  set(function (state) {
                        var engagement = Curry._2(AvoEngagement__Types.Engagements.get, state.engagements, engagementId);
                        var tmp;
                        if (engagement !== undefined) {
                          var blocks = engagement.blocks;
                          var rootId = engagement.rootId;
                          if (Curry._1(AvoEngagement__Types.Blocks.size, blocks) > 0) {
                            var startFromBlockId$1;
                            var exit = 0;
                            if (startFromBlockId !== undefined && Curry._2(AvoEngagement__Types.Blocks.has, blocks, startFromBlockId)) {
                              startFromBlockId$1 = startFromBlockId;
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              if (Curry._2(AvoEngagement__Types.Blocks.has, blocks, rootId)) {
                                startFromBlockId$1 = rootId;
                              } else {
                                console.error("Avo Engagement: No root block found for engagement with id '" + engagementId + "'");
                                startFromBlockId$1 = undefined;
                              }
                            }
                            if (startFromBlockId$1 !== undefined) {
                              tmp = {
                                engagementId: engagement.id,
                                state: {
                                  active: true,
                                  completed: false,
                                  blocksState: Curry._2(AvoEngagement__Types.Blocks.map, engagement.blocks, (function (block) {
                                          if (block.id === startFromBlockId$1) {
                                            return {
                                                    active: true,
                                                    completed: false
                                                  };
                                          } else {
                                            return {
                                                    active: false,
                                                    completed: false
                                                  };
                                          }
                                        }))
                                }
                              };
                            } else {
                              console.error("Avo Engagement: Block id doesn't exist for engagement with id '" + engagementId + "'");
                              tmp = undefined;
                            }
                          } else {
                            console.error("Avo Engagement: No blocks found for engagement with id '" + engagementId + "'");
                            tmp = undefined;
                          }
                        } else {
                          console.error("Avo Engagement: Error retrieveing engagement with id '" + engagementId + "'");
                          tmp = undefined;
                        }
                        return {
                                engagements: state.engagements,
                                startEngagement: state.startEngagement,
                                exitEngagement: state.exitEngagement,
                                transitionEngagement: state.transitionEngagement,
                                activeEngagement: tmp,
                                completedEngagements: state.completedEngagements
                              };
                      });
                }),
              exitEngagement: (function (engagementId) {
                  set(function (state) {
                        var match = state.activeEngagement;
                        if (match !== undefined && engagementId === match.engagementId) {
                          return {
                                  engagements: state.engagements,
                                  startEngagement: state.startEngagement,
                                  exitEngagement: state.exitEngagement,
                                  transitionEngagement: state.transitionEngagement,
                                  activeEngagement: undefined,
                                  completedEngagements: state.completedEngagements
                                };
                        }
                        console.error("Avo Engagement: Engagement with id '" + engagementId + "' not active, unable to exit");
                        return state;
                      });
                }),
              transitionEngagement: (function (engagementId, blockId, transitionKey) {
                  set(function (state) {
                        var activeEngagement = state.activeEngagement;
                        if (activeEngagement !== undefined) {
                          var blocksState = activeEngagement.state.blocksState;
                          if (engagementId === activeEngagement.engagementId && Belt_Option.mapWithDefault(Belt_MapString.get(blocksState, blockId), false, (function (param) {
                                    return param.active;
                                  }))) {
                            var match = Curry._2(AvoEngagement__Types.Engagements.get, state.engagements, engagementId);
                            if (match !== undefined) {
                              var blocks = match.blocks;
                              var match$1 = Curry._2(AvoEngagement__Types.Blocks.get, blocks, blockId);
                              if (match$1 !== undefined) {
                                var match$2 = Belt_Array.getBy(match$1.transitions, (function (param) {
                                        return param.key === transitionKey;
                                      }));
                                if (match$2 !== undefined) {
                                  var match$3 = match$2.target;
                                  if (typeof match$3 !== "object") {
                                    return {
                                            engagements: state.engagements,
                                            startEngagement: state.startEngagement,
                                            exitEngagement: state.exitEngagement,
                                            transitionEngagement: state.transitionEngagement,
                                            activeEngagement: undefined,
                                            completedEngagements: Belt_SetString.toArray(Belt_SetString.add(Belt_SetString.fromArray(state.completedEngagements), engagementId))
                                          };
                                  }
                                  var nextBlockId = match$3.VAL;
                                  if (Curry._2(AvoEngagement__Types.Blocks.has, blocks, nextBlockId)) {
                                    var init = activeEngagement.state;
                                    return {
                                            engagements: state.engagements,
                                            startEngagement: state.startEngagement,
                                            exitEngagement: state.exitEngagement,
                                            transitionEngagement: state.transitionEngagement,
                                            activeEngagement: {
                                              engagementId: activeEngagement.engagementId,
                                              state: {
                                                active: init.active,
                                                completed: init.completed,
                                                blocksState: Belt_MapString.mapWithKey(blocksState, (function (bid, block) {
                                                        if (bid === nextBlockId) {
                                                          return {
                                                                  active: true,
                                                                  completed: block.completed
                                                                };
                                                        } else if (bid === blockId) {
                                                          return {
                                                                  active: false,
                                                                  completed: true
                                                                };
                                                        } else {
                                                          return block;
                                                        }
                                                      }))
                                              }
                                            },
                                            completedEngagements: state.completedEngagements
                                          };
                                  }
                                  
                                }
                                console.error("Avo Engagement: No transition found with key " + transitionKey + " for block with id " + blockId + "");
                                return state;
                              }
                              console.error("Avo Engagement: Block with id '" + blockId + "' not found in engagement with id '" + engagementId + "'");
                              return state;
                            }
                            console.error("Avo Engagement: Engagement with id '" + engagementId + "' not found");
                            return state;
                          }
                          console.error("Avo Engagement: No active engagement, unable to transition");
                          return state;
                        }
                        console.error("Avo Engagement: No active engagement, unable to transition");
                        return state;
                      });
                }),
              activeEngagement: undefined,
              completedEngagements: []
            };
    });

var empty_transitions = [];

var empty_fields = AvoEngagement__Types.Fields.empty;

var empty_state = {
  active: false,
  completed: false
};

var empty = {
  id: "empty-block",
  transitions: empty_transitions,
  fields: empty_fields,
  state: empty_state
};

var Container = {
  empty: empty
};

function use(engagementId, id) {
  var engagements = Curry._2(ZustandStore.use, store, (function (state) {
          return state.engagements;
        }));
  var engagement = React.useMemo((function () {
          return Curry._2(AvoEngagement__Types.Engagements.get, engagements, engagementId);
        }), [
        engagements,
        engagementId
      ]);
  var activeEngagement = Curry._2(ZustandStore.use, store, (function (state) {
          return state.activeEngagement;
        }));
  var transitionFn = Curry._2(ZustandStore.use, store, (function (state) {
          return state.transitionEngagement;
        }));
  var state = React.useMemo((function () {
          if (activeEngagement !== undefined && engagementId === activeEngagement.engagementId) {
            return Belt_Option.getWithDefault(Belt_MapString.get(activeEngagement.state.blocksState, id), {
                        active: false,
                        completed: false
                      });
          } else {
            return {
                    active: false,
                    completed: false
                  };
          }
        }), [activeEngagement]);
  return React.useMemo((function () {
                if (engagement !== undefined) {
                  var match = Curry._2(AvoEngagement__Types.Blocks.get, engagement.blocks, id);
                  if (match !== undefined) {
                    var id$1 = match.id;
                    return [
                            {
                              id: id$1,
                              transitions: match.transitions,
                              fields: match.fields,
                              state: state
                            },
                            (function (transitionKey) {
                                Curry._3(transitionFn, engagementId, id$1, transitionKey);
                              })
                          ];
                  }
                  console.error("Avo Engagement: No block found with id '" + id + "' in engagement with id '" + engagementId + "'");
                  return [
                          {
                            id: "empty-block",
                            transitions: emptyBlock_transitions,
                            fields: emptyBlock_fields,
                            state: {
                              active: false,
                              completed: false
                            }
                          },
                          (function (_transitionKey) {
                              console.error("Avo Engagement: No block found with id '" + id + "' in engagement with id '" + engagementId + "'");
                            })
                        ];
                }
                console.error("Avo Engagement: Unable to retrieve blocks because engagement with id '" + engagementId + "' doesn't exist");
                return [
                        {
                          id: "empty-block",
                          transitions: emptyBlock_transitions,
                          fields: emptyBlock_fields,
                          state: {
                            active: false,
                            completed: false
                          }
                        },
                        (function (_transitionKey) {
                            console.error("Avo Engagement: Unable to retrieve blocks because engagement with id '" + engagementId + "' doesn't exist");
                          })
                      ];
              }), [
              engagement,
              activeEngagement,
              state
            ]);
}

var Block = {
  Container: Container,
  use: use
};

var empty_state$1 = {
  active: false,
  completed: false,
  blocksState: undefined
};

var empty$1 = {
  id: "empty-engagement",
  version: 0,
  blocks: undefined,
  state: empty_state$1
};

var Container$1 = {
  empty: empty$1
};

function noIntent_start(param) {
  
}

function noIntent_startFrom(param) {
  
}

function noIntent_exit(param) {
  
}

var noIntent = {
  start: noIntent_start,
  startFrom: noIntent_startFrom,
  exit: noIntent_exit
};

var Intent = {
  noIntent: noIntent
};

function getStepNumber(blockNumberId) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(blockNumberId.match(/\d+/)), (function (arr) {
                        return Belt_Array.get(arr, 0);
                      })), (function (i) {
                    return i;
                  })), Belt_Int.fromString);
}

function getStepChar(blockNumberId) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(blockNumberId.match(/[a-z]$/)), (function (arr) {
                    return Belt_Array.get(arr, 0);
                  })), (function (i) {
                return i;
              }));
}

function isRootStep(blockId) {
  return /^(pre-)?step-\d+$/.test(blockId);
}

function compareSteps(a, b) {
  var _a = a.id;
  var _b = b.id;
  while(true) {
    var b$1 = _b;
    var a$1 = _a;
    if (a$1.startsWith("pre") && b$1.startsWith("pre") || a$1.startsWith("step-") && b$1.startsWith("step-")) {
      var aIdArr = a$1.split("step-");
      var aId = Belt_Option.getWithDefault(Belt_Array.get(aIdArr, aIdArr.length - 1 | 0), "0");
      var bIdArr = b$1.split("step-");
      var bId = Belt_Option.getWithDefault(Belt_Array.get(bIdArr, bIdArr.length - 1 | 0), "0");
      _b = bId;
      _a = aId;
      continue ;
    }
    if (a$1.startsWith("pre") && b$1.startsWith("step-")) {
      return -1;
    }
    if (a$1.startsWith("step-") && b$1.startsWith("pre")) {
      return 1;
    }
    if (a$1 === b$1) {
      return 0;
    }
    switch (a$1) {
      case "complete" :
      case "done" :
      case "finish" :
          return 1;
      default:
        switch (b$1) {
          case "complete" :
          case "done" :
          case "finish" :
              return -1;
          default:
            var match = getStepNumber(a$1);
            var match$1 = getStepNumber(b$1);
            if (match !== undefined) {
              if (match$1 !== undefined) {
                if (match === match$1) {
                  return Caml_obj.compare(getStepChar(a$1), getStepChar(b$1));
                } else {
                  return Caml.int_compare(match, match$1);
                }
              } else {
                return -1;
              }
            } else if (match$1 !== undefined) {
              return 1;
            } else {
              return Caml.string_compare(a$1, b$1);
            }
        }
    }
  };
}

function getOrderedBlocks(container) {
  var match = Belt_Array.partition(Belt_MapString.valuesToArray(container.blocks), (function (block) {
          return block.state.active;
        }));
  var match$1 = Belt_Array.partition(Belt_MapString.valuesToArray(container.blocks), (function (block) {
          if (block.id.startsWith("pre")) {
            return true;
          } else {
            return block.id.startsWith("step-");
          }
        }));
  var sortedSteps = Belt_SortArray.stableSortBy(match$1[0], compareSteps);
  return {
          steps: sortedSteps,
          active: match[0],
          inactive: match[1],
          numSteps: Belt_Array.keep(sortedSteps, (function (block) {
                  return isRootStep(block.id);
                })).length,
          others: match$1[1]
        };
}

var SortedBlocks = {
  getStepNumber: getStepNumber,
  getStepChar: getStepChar,
  isRootStep: isRootStep,
  compareSteps: compareSteps,
  getOrderedBlocks: getOrderedBlocks
};

function use$1(id) {
  var engagements = Curry._2(ZustandStore.use, store, (function (state) {
          return state.engagements;
        }));
  var engagement = React.useMemo((function () {
          return Curry._2(AvoEngagement__Types.Engagements.get, engagements, id);
        }), [
        engagements,
        id
      ]);
  var start = Curry._2(ZustandStore.use, store, (function (state) {
          return state.startEngagement;
        }));
  var exit = Curry._2(ZustandStore.use, store, (function (state) {
          return state.exitEngagement;
        }));
  var activeEngagement = Curry._2(ZustandStore.use, store, (function (state) {
          return state.activeEngagement;
        }));
  var completedEngagements = Curry._2(ZustandStore.use, store, (function (state) {
          return state.completedEngagements;
        }));
  var completed = Belt_Array.some(completedEngagements, (function (completedEngagement) {
          return completedEngagement === id;
        }));
  var state = React.useMemo((function () {
          if (activeEngagement !== undefined && id === activeEngagement.engagementId) {
            return activeEngagement.state;
          } else {
            return {
                    active: false,
                    completed: completed,
                    blocksState: undefined
                  };
          }
        }), [
        activeEngagement,
        completed
      ]);
  return React.useMemo((function () {
                if (engagement === undefined) {
                  return [
                          {
                            id: "empty-engagement",
                            version: 0,
                            blocks: undefined,
                            state: state
                          },
                          {
                            start: (function (param) {
                                console.error("Avo Engagement: No engagement found with id '" + id + "'");
                              }),
                            startFrom: (function (param) {
                                console.error("Avo Engagement: No engagement found with id '" + id + "'");
                              }),
                            exit: (function (param) {
                                console.error("Avo Engagement: No engagement found with id '" + id + "'");
                              })
                          }
                        ];
                }
                var blocks = engagement.blocks;
                var version = engagement.version;
                var id$1 = engagement.id;
                if (activeEngagement !== undefined) {
                  return [
                          {
                            id: id$1,
                            version: version,
                            blocks: Curry._2(AvoEngagement__Types.Blocks.map, blocks, (function (block) {
                                    return {
                                            id: block.id,
                                            transitions: block.transitions,
                                            fields: block.fields,
                                            state: Belt_Option.getWithDefault(Belt_MapString.get(activeEngagement.state.blocksState, block.id), {
                                                  active: false,
                                                  completed: false
                                                })
                                          };
                                  })),
                            state: state
                          },
                          {
                            start: (function (param) {
                                Curry._2(start, undefined, id$1);
                              }),
                            startFrom: (function (startFromBlockId) {
                                Curry._2(start, startFromBlockId, id$1);
                              }),
                            exit: (function (param) {
                                Curry._1(exit, id$1);
                              })
                          }
                        ];
                } else {
                  return [
                          {
                            id: id$1,
                            version: version,
                            blocks: Curry._2(AvoEngagement__Types.Blocks.map, blocks, (function (block) {
                                    return {
                                            id: block.id,
                                            transitions: block.transitions,
                                            fields: block.fields,
                                            state: {
                                              active: false,
                                              completed: false
                                            }
                                          };
                                  })),
                            state: state
                          },
                          {
                            start: (function (param) {
                                Curry._2(start, undefined, id$1);
                              }),
                            startFrom: (function (startFromBlockId) {
                                Curry._2(start, startFromBlockId, id$1);
                              }),
                            exit: (function (param) {
                                Curry._1(exit, id$1);
                              })
                          }
                        ];
                }
              }), [
              engagement,
              activeEngagement,
              state
            ]);
}

var Engagement = {
  Container: Container$1,
  Intent: Intent,
  SortedBlocks: SortedBlocks,
  use: use$1
};

var T;

export {
  T ,
  emptyBlock ,
  emptyEngagement ,
  StoreConfig ,
  ZustandStore ,
  store ,
  Block ,
  Engagement ,
}
/* ZustandStore Not a pure module */
