// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as IconX from "./IconX.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PortalTooltip from "./PortalTooltip.mjs";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

function Tag$TooltipWrapper(Props) {
  var tooltip = Props.tooltip;
  var children = Props.children;
  if (tooltip !== undefined) {
    return React.createElement(PortalTooltip.make, {
                renderTooltip: (function (param) {
                    return React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                    height: 4
                                  }), React.createElement($$Text.make, {
                                    size: "Small",
                                    color: Styles.Color.white,
                                    children: tooltip
                                  }));
                  }),
                position: "Bottom",
                children: children
              });
  } else {
    return children;
  }
}

var TooltipWrapper = {
  make: Tag$TooltipWrapper
};

function Tag(Props) {
  var tag = Props.tag;
  var onRemove = Props.onRemove;
  var hue = Props.hue;
  var tooltip = Props.tooltip;
  var hue$1 = Belt_Option.getWithDefault(hue, Styles.Color.getHueFromString(tag));
  var tmp = {
    children: React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.paddingTop(Css.px(3)),
                    tl: {
                      hd: Css.paddingRight(Css.px(8)),
                      tl: {
                        hd: Css.paddingBottom(Css.px(5)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(8)),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.getSecondaryColor(hue$1, "pale")),
                            tl: {
                              hd: Css.borderRadius(Css.px(99)),
                              tl: {
                                hd: Css.margin(Css.px(2)),
                                tl: {
                                  hd: Css.color(Styles.Color.getSecondaryColor(hue$1, "deep")),
                                  tl: {
                                    hd: Css.fontFamily(Styles.FontFamily.polar),
                                    tl: {
                                      hd: Css.fontWeight(Styles.FontWeight.medium),
                                      tl: {
                                        hd: Css.fontSize(Styles.FontSize.small),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              })
        }, tag, onRemove !== undefined ? React.createElement("button", {
                className: Curry._1(Css.merge, {
                      hd: Curry._1(Css.style, Styles.button),
                      tl: {
                        hd: Curry._1(Css.style, {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.alignItems("center"),
                                tl: {
                                  hd: Css.justifyContent("center"),
                                  tl: {
                                    hd: Css.width(Css.px(16)),
                                    tl: {
                                      hd: Css.height(Css.px(16)),
                                      tl: {
                                        hd: Css.padding(Css.px(0)),
                                        tl: {
                                          hd: Css.margin4(Css.px(0), Css.px(-2), Css.px(-2), Css.px(4)),
                                          tl: {
                                            hd: Css.borderRadius(Css.pct(50)),
                                            tl: {
                                              hd: Css_Legacy_Core.SVG.stroke(Styles.Color.getSecondaryColor(hue$1, "deep")),
                                              tl: {
                                                hd: Css.hover({
                                                      hd: Css.backgroundColor(Styles.Color.white),
                                                      tl: /* [] */0
                                                    }),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }),
                onClick: (function (param) {
                    Curry._1(onRemove, undefined);
                  })
              }, React.createElement(IconX.make, {
                    size: 8
                  })) : null)
  };
  if (tooltip !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(tooltip);
  }
  return React.createElement(Tag$TooltipWrapper, tmp);
}

var make = Tag;

export {
  TooltipWrapper ,
  make ,
}
/* Css Not a pure module */
